import fb from './firebase'
import { getLastQuarter, addDaysDate, getWC, dateCustomFormat, getDayAbrFromIndex, isCurrentWeek, isSameWeek, inFuture } from '../utils/time'
import { getNextRecMeeting, isEnded } from '../utils/meetings'
import moment from 'moment'
import { generateHTML } from '@tiptap/html'
import { extensionList } from '../utils/tiptapex'

// Team Listeners
let teamListener
let teamProfileListener
let teamDetailsListener
let teamRolesListener
let workspaceRolesListener
let workspaceInitiativeListener
let personalInitiativeListener
let teamTempoListener
let teamNotesListener
let teamUpdatesListener
let updateCommentListener
let taskListListener
let taskListener
let taskPlanListener
let taskCommentListener
let taskActivityListener
let notificationListener
let recommendationListener
let taskListCommentListener
let meetingListener
let meetingRecListener
let meetingPageListener
let dailyPageListener
let pageLinkListener
let lookingForwardBlockCommentsListener
let lookingForwardPageListener
let preferenceListener
let requestListener
let notePageListener
let notePageShareInitiativeListeners = {}
let notePageShareUserListener
let defaultScheduleListener
let historyScheduleListener
let subscriptionListener
let emptyPrefCheck = 0

// Schedules set by selected week
let scheduleListener
// Current Weeks schedules
let currentScheduleListener
// current week topics and teammates
let currentTopicsAndPeopleListener
// Mini Profile Schedules set by selected week
let miniScheduleListener
// admin locations listener
let locationsListener
// interetst listener - others who have selected user for the currently loaded week
let interestListener
// desk activity - user desk usage per day
let deskActivityListener

const resetTeamListener = () => {
  if (teamListener) {
    teamListener()
  }
}
const resetProfileListener = () => {
  if (teamProfileListener) {
    teamProfileListener()
  }
}
const resetPreferenceListener = () => {
  if (preferenceListener) {
    preferenceListener()
  }
}
const resetTeamTempoListener = () => {
  if (teamNotesListener) {
    teamNotesListener()
  }
}
const resetRequestListener = () => {
  if (requestListener) {
    requestListener()
  }
}
const resetNotificationListener = () => {
  if (notificationListener) {
    notificationListener()
  }
}
const resetRecommendationListener = () => {
  if (recommendationListener) {
    recommendationListener()
  }
}
const resetLookingForwardBlockCommentsListener = () => {
  if (lookingForwardBlockCommentsListener) {
    lookingForwardBlockCommentsListener()
  }
}

const resetWorkspaceInitiativeListener = () => {
  if (workspaceInitiativeListener) {
    workspaceInitiativeListener()
  }
}

const resetTeamDetailsListener = () => {
  if (teamDetailsListener) {
    teamDetailsListener()
  }
}

const resetTeamRolesListener = () => {
  if (teamRolesListener) {
    teamRolesListener()
  }
}
const resetWorkspaceRolesListener = () => {
  if (workspaceRolesListener) {
    workspaceRolesListener()
  }
}
const resetPersonalInitiativeListener = () => {
  if (personalInitiativeListener) {
    personalInitiativeListener()
  }
}

const resetTeamNotesListener = () => {
  if (teamNotesListener) {
    teamNotesListener()
  }
}

const resetTeamUpdatesListener = () => {
  if (teamUpdatesListener) {
    teamUpdatesListener()
  }
}

const resetUpdateCommentListener = () => {
  if (updateCommentListener) {
    updateCommentListener()
  }
}

const resetTaskListListener = () => {
  if (taskListListener) {
    taskListListener()
  }
}

const resetTaskListCommentListener = () => {
  if (taskListCommentListener) {
    taskListCommentListener()
  }
}

const resetTaskPlanListener = () => {
  if (taskPlanListener) {
    taskPlanListener()
  }
}

const resetMeetingListener = () => {
  if (meetingListener) {
    meetingListener()
  }
}

const resetMeetingRecListener = () => {
  if (meetingRecListener) {
    meetingRecListener()
  }
}

const resetMeetingPageListener = () => {
  if (meetingPageListener) {
    meetingPageListener()
  }
}

const resetNotePageListener = () => {
  if (notePageListener) {
    notePageListener()
  }
}

const resetLookingForwardPageListener = () => {
  if (lookingForwardPageListener) {
    lookingForwardPageListener()
  }
}

const resetDailPageListener = () => {
  if (dailyPageListener) {
    dailyPageListener()
  }
}

const resetPageLinkListener = () => {
  if (pageLinkListener) {
    pageLinkListener()
  }
}

const resetDefaultScheduleListener = () => {
  if (defaultScheduleListener) {
    defaultScheduleListener()
  }
}
const resetHistoryScheduleListener = () => {
  if (historyScheduleListener) {
    historyScheduleListener()
  }
}

const resetScheduleListener = () => {
  if (scheduleListener) {
    scheduleListener()
  }
}
const resetCurrentScheduleListener = () => {
  if (currentScheduleListener) {
    currentScheduleListener()
  }
}

const resetCurrentTopicsAndPeopleListener = () => {
  if (currentTopicsAndPeopleListener) {
    currentTopicsAndPeopleListener()
  }
}

const resetMiniScheduleListener = () => {
  if (miniScheduleListener) {
    miniScheduleListener()
  }
}

const resetLocationsListener = () => {
  if (locationsListener) {
    locationsListener()
  }
}

const resetSubscriptionListener = () => {
  if (subscriptionListener) {
    subscriptionListener()
  }
}

const resetInterestListener = () => {
  if (interestListener) {
    interestListener()
  }
}

const resetDeskActivityListener = () => {
  if (deskActivityListener) {
    deskActivityListener()
  }
}

const workspaceRef = fb.firestore.collection('workspaces')

const state = {
  teams: null,
  teamsLoaded: false,
  team: {},
  workspaceRoles: null,
  teamRoles: null,
  teamProfiles: null,
  initiatives: [],
  workspaceInitiatives: [],
  personalInitiatives: [],
  taskLists: [],
  tempos: [],
  teamNotes: null,
  teamUpdates: [],
  teamUpdatesLookingForward: [],
  teamUpdatesRoundTable: [],
  requests: [],
  updateComments: [],
  tasks: [],
  tasksByList: {},
  taskPlans: [],
  taskComments: [],
  taskActivity: [],
  notifications: [],
  taskListComments: [],
  saveUpdateStatus: 'unknown',
  meetings: null,
  meetingsRec: false,
  meetingsLoaded: false,
  notePages: false,
  notePagesByUser: [],
  notePagesByShareUser: [],
  notePagesByInitiative: {},
  meetingPages: [],
  dailyPages: [],
  pageLinks: [],
  preferences: null,
  preferencesWeekCommencing: false,
  lookingForwardBlockComments: null,
  lookingForwardPages: null,
  lastRecommendation: null,
  bestDaysCalculating: false,
  defaultSchedules: null,
  historySchedules: null,
  currentSchedules: null,
  currentTopicsAndPeople: null,
  schedules: null,
  miniSchedules: null,
  locations: null,
  editingSchedule: null,
  subscriptions: null,
  pastPrefRec: null,
  loadedSchedulesDateStr: '',
  schedulesCurrentDateStr: '',
  usersMeeting: [],
  loadAdditional: false,
  userLocations: false,
  interest: false,
  deskActivity: false
}

const JSONtoBlocksLinksTasks = (json) => {
  if (json) {
    // console.log('store.JSONtoBlocksLinks', json)
    const isLink = (node) => {
      if (node) {
        return node.type === 'pagelink'
      }
      return false
    }
    const isTask = (node) => {
      if (node) {
        return node.type === 'taskItem' && node.content
      }
      return false
    }
    const isTag = (node) => {
      if (node) {
        return node.type === 'hashtag'
      }
      return false
    }
    const isMention = (node) => {
      if (node) {
        return node.type === 'mention'
      }
      return false
    }
    const getTaskData = (node) => {
      let text = ''
      const assigned = []
      const initiatives = []
      if (node) {
        if (node.type === 'taskItem' && node.content) {
          for (const content of node.content) {
            if (content.content) {
              for (const subcontent of content.content) {
                // console.log('getTaskData.node.subcontent', node, subcontent)
                if (subcontent.text) {
                  if (subcontent.text !== ' ') {
                    text += subcontent.text
                  }
                }
                if (subcontent.type) {
                  if (subcontent.type === 'mention') {
                    const mentionName = subcontent.attrs['data-label']
                    assigned.push(mentionName)
                  } else if (subcontent.type === 'hashtag') {
                    const tagName = subcontent.attrs['data-hashtag']
                    initiatives.push(tagName)
                  }
                }
              }
            }
            if (content.text) {
              text += content.text
            }
          }
        }
      }
      return { taskId: node.attrs['data-task-id'], completed: node.attrs.checked, content: text, assigned, initiatives }
    }
    const isNoteBlock = (node) => {
      if (node) {
        return node.type === 'noteblock' && node.content
      }
      return false
    }
    const getLinkData = (node, parentBlock, parentText) => {
      if (node) {
        if (node.type === 'pagelink') {
          return { linkId: node.attrs['data-link-id'], parentBlock, parentText }
        }
      }
      return {}
    }
    const getTagData = (node, parentBlock, parentText) => {
      if (node) {
        if (node.type === 'hashtag') {
          return { tag: node.attrs['data-hashtag'], tagId: node.attrs['data-hashtagid'], parentBlock, parentText }
        }
      }
      return {}
    }
    const getMentionData = (node, parentBlock, parentText) => {
      if (node) {
        if (node.type === 'mention') {
          return { profileId: node.attrs.id || '', name: node.attrs.label || '', parentBlock, parentText }
        }
      }
      return {}
    }
    const getNoteBlockData = (node, parentBlock, parentText) => {
      // console.log('getNoteBlockData', node, parentBlock)
      if (node) {
        if (node.type === 'noteblock' && node.content) {
          let text = ''
          const tags = []
          const mentions = []
          for (const content of node.content) {
            if (content.type !== 'noteblock' && content.content) {
              for (const subcontent of content.content) {
                if (subcontent.text) {
                  text += subcontent.text
                }
                if (isTag(subcontent)) {
                  text += subcontent.attrs['data-hashtag']
                  tags.push(subcontent.attrs['data-hashtag'])
                }
                if (isMention(subcontent)) {
                  text += subcontent.attrs.label
                  mentions.push(subcontent.attrs.id)
                }
              }
            }
            if (content.text) {
              text += content.text
            }
            if (isTag(content)) {
              text += content.attrs['data-hashtag']
              tags.push(content.attrs['data-hashtag'])
            }
            if (isMention(content)) {
              text += content.attrs.label
              mentions.push(content.attrs.id)
            }
          }
          if (!parentBlock) {
            parentBlock = null
          }
          const html = generateHTML(node, extensionList())
          // console.log('getNoteBlockData.return', { noteBlockId: node.attrs['data-note-id'], content: text, html, parentBlock, parentText, tags })
          return { noteBlockId: node.attrs['data-note-id'], content: text, html, parentBlock, parentText, tags, mentions }
        }
      }
      return {}
    }
    const getTextLinkNodes = (json, parentBlock, parentText) => {
      let textNodes = []
      let linkNodes = []
      let noteBlocks = []
      let taskNodes = []
      let tagNodes = []
      let mentionNodes = []

      if (!parentBlock) {
        parentBlock = ''
      }
      if (!parentText) {
        parentText = ''
      }
      if (json.content) {
        for (const node of json.content) {
          if (isTask(node)) {
            taskNodes.push(getTaskData(node))
          }
          if (isTag(node)) {
            tagNodes.push(getTagData(node, parentBlock, parentText))
          } else if (isMention(node)) {
            mentionNodes.push(getMentionData(node, parentBlock, parentText))
          } else if (isLink(node)) {
            linkNodes.push(getLinkData(node, parentBlock, parentText))
          } else if (isNoteBlock(node)) {
            const noteBlock = getNoteBlockData(node, parentBlock, parentText)
            noteBlocks.push(noteBlock)
            if (node.content) {
              const parentNoteBlock = noteBlock.noteBlockId
              const parentNoteText = noteBlock.content
              const nodeContent = getTextLinkNodes(node, parentNoteBlock, parentNoteText)
              textNodes = [...textNodes, ...nodeContent.textNodes]
              taskNodes = [...taskNodes, ...nodeContent.taskNodes]
              linkNodes = [...linkNodes, ...nodeContent.linkNodes]
              tagNodes = [...tagNodes, ...nodeContent.tagNodes]
              noteBlocks = [...noteBlocks, ...nodeContent.noteBlocks]
              mentionNodes = [...mentionNodes, ...nodeContent.mentionNodes]
            }
          } else {
            if (node.text) {
              textNodes.push(node.text)
              parentText = node.text
            }
            if (node.content) {
              const nodeContent = getTextLinkNodes(node, parentBlock, parentText)
              textNodes = [...textNodes, ...nodeContent.textNodes]
              taskNodes = [...taskNodes, ...nodeContent.taskNodes]
              linkNodes = [...linkNodes, ...nodeContent.linkNodes]
              tagNodes = [...tagNodes, ...nodeContent.tagNodes]
              noteBlocks = [...noteBlocks, ...nodeContent.noteBlocks]
              mentionNodes = [...mentionNodes, ...nodeContent.mentionNodes]
            }
          }
        }
        return { textNodes, taskNodes, linkNodes, tagNodes, noteBlocks, mentionNodes }
      } else {
        if (isTask(json)) {
          taskNodes.push(getTaskData(json))
        }
        if (isTag(json)) {
          tagNodes.push(getTagData(json, parentBlock, parentText))
        } else if (isMention(json)) {
          mentionNodes.push(getMentionData(json, parentBlock, parentText))
        } else if (isLink(json)) {
          linkNodes.push(getLinkData(json, parentBlock))
        } else if (isNoteBlock(json)) {
          noteBlocks.push(getNoteBlockData(json, parentBlock))
        } else {
          if (json.text) {
            textNodes.push(json.text)
          }
        }
        return { textNodes, taskNodes, linkNodes, tagNodes, noteBlocks, mentionNodes }
      }
    }
    const textlinks = getTextLinkNodes(json)
    // console.log('JSONtoBlocksLinksTasks.tasks', textlinks.taskNodes)
    // console.log('JSONtoBlocksLinksTasks.mentions', textlinks.mentionNodes)
    return { blocks: textlinks.textNodes, links: textlinks.linkNodes, noteBlocks: textlinks.noteBlocks, tasks: textlinks.taskNodes, tags: textlinks.tagNodes, mentions: textlinks.mentionNodes }
  }
  return { blocks: [], links: [], noteBlocks: [], tasks: [], tags: [], mentions: [] }
}

const getters = {
  getLinkedMentions: (state, getters, rootState) => (page) => {
    // Replace with solr search
    const mentions = []
    const pageId = page.id
    for (const notePage of state.notePages) {
      // console.log('getlinkedMentions NotePage', notePage.pageName)
      if (notePage.id !== pageId && notePage.links) {
        for (const link of notePage.links) {
          if (link.linkId === pageId) {
            mentions.push({ ...link, pageId: notePage.id, pageDate: notePage.created.toDate(), pageLabel: notePage.pageName, pageName: page.pageName, type: 'notePage' })
          }
        }
      }
    }
    for (const meetingPage of state.meetingPages) {
      if (meetingPage.id !== pageId && meetingPage.links) {
        for (const link of meetingPage.links) {
          if (link.linkId === pageId) {
            mentions.push({ ...link, pageId: meetingPage.id, pageDate: meetingPage.created.toDate(), pageLabel: meetingPage.pageName, pageName: page.pageName, type: 'meetingPage' })
          }
        }
      }
    }
    for (const dailyPage of state.dailyPages) {
      if (dailyPage.links) {
        for (const link of dailyPage.links) {
          if (link.linkId === pageId) {
            const pageDate = dateCustomFormat(dailyPage.date.toDate(), 'MMMM DD, YYYY')
            const pageName = `Daily Notes ${pageDate}`
            mentions.push({ ...link, pageId: dailyPage.id, pageDate: dailyPage.date.toDate(), pageLabel: pageName, pageName: page.pageName, type: 'dailyPage' })
          }
        }
      }
    }
    // console.log('store.getLinkedMentions', page.pageLink, mentions)
    return mentions
  },
  getMyTeams: (state, getters, rootState) => async () => {
    // console.log('teams/getMyTeams', rootState.workspace.id)
    const teams = []
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId).collection('teams')
      const snapshot = await teamsRef.where('members', 'array-contains', rootState.profile.email).get()
      snapshot.forEach(doc => {
        teams.push({
          ...doc.data(),
          workspaceId,
          id: doc.id
        })
      })
    } else {
      const snapshot = await workspaceRef.where('members', 'array-contains', rootState.profile.email).get()
      snapshot.forEach(async (doc) => {
        const workspaceId = doc.id
        const teamsRef = workspaceRef.doc(workspaceId).collection('teams')
        const teamSnapshot = await teamsRef.where('members', 'array-contains', rootState.profile.email).get()
        teamSnapshot.forEach(teamDoc => {
          teams.push({
            ...teamDoc.data(),
            workspaceId: doc.id,
            id: teamDoc.id
          })
        })
      })
    }
    return teams
  },
  getTaskById: (state) => async (taskId) => {
    const tasks = state.tasks.filter(task => task.id === taskId)
    if (tasks) {
      return tasks[0]
    }
    return null
  },
  getTeam: (state, getters, rootState) => async (teamId) => {
    // console.log('teams/getTeam', rootState.workspace.id, teamId)
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId).collection('teams')
      const teamDoc = await teamsRef.doc(teamId).get()
      return {
        id: teamDoc.id,
        ...teamDoc.data()
      }
    }
    return {}
  },
  getTeamName: (state) => (teamId) => {
    if (state.teams && teamId) {
      const ft = state.teams.filter((t) => {
        return t.id === teamId
      })[0] || {}
      return ft.name
    }
  },
  getLocationFromCurrentSchedules: (state, getters, rootState) => (userId, date, slot) => {
    // Location getter return from store default and current schedules
    let response = ''
    const day = moment(date).format('ddd').toUpperCase()
    slot = slot ? slot.toUpperCase() : ''

    // Location from defaults
    if (state.profileDefaultSchedules) {
      const profileDefaultSchedules = state.profileDefaultSchedules.filter((ds) => { return ds.fromId === userId }) || {}
      if (profileDefaultSchedules.length) {
        const profileDefaultSchedule = profileDefaultSchedules[0]
        if (profileDefaultSchedule.schedules) {
          if (profileDefaultSchedule.schedules[day]) {
            const sday = profileDefaultSchedule.schedules[day]
            if (!sday.split) {
              response = sday.response
            } else {
              if (slot === 'AM') {
                response = sday.amResponse
              } else if (slot === 'PM') {
                response = sday.pmResponse
              } else {
                response = sday.response
              }
            }
          }
        }
      }
    }

    // Location from current schedule
    if (state.currentScheule) {
      const currentSchedules = state.currentSchedules.filter((cs) => { return cs.fromId === userId })
      if (currentSchedules.length) {
        const currentSchedule = currentSchedules[0]
        if (currentSchedule.schedules) {
          if (currentSchedule.schedules[day]) {
            const sday = currentSchedule.schedules[day]
            if (!sday.split) {
              response = sday.response
            } else {
              if (slot === 'AM') {
                response = sday.amResponse
              } else if (slot === 'PM') {
                response = sday.pmResponse
              } else {
                response = sday.response
              }
            }
          }
        }
      }
    }
    return response
  },
  getLocationFromSchedules: (state, getters, rootState) => (userId, date, slot) => {
    // Location getter return from store default and schedules loaded for a future / historical week
    let response = ''
    const day = moment(date).format('ddd').toUpperCase()
    slot = slot ? slot.toUpperCase() : ''

    // Location from defaults
    if (state.profileDefaultSchedules) {
      const profileDefaultSchedules = state.profileDefaultSchedules.filter((ds) => { return ds.fromId === userId })
      if (profileDefaultSchedules.length) {
        const profileDefaultSchedule = profileDefaultSchedules[0]
        if (profileDefaultSchedule.schedules) {
          if (profileDefaultSchedule.schedules[day]) {
            const sday = profileDefaultSchedule.schedules[day]
            if (!sday.split) {
              response = sday.response
            } else {
              if (slot === 'AM') {
                response = sday.amResponse
              } else if (slot === 'PM') {
                response = sday.pmResponse
              } else {
                response = sday.response
              }
            }
          }
        }
      }
    }

    // Location from future / historical schedule
    if (state.schedules) {
      const schedules = [...state.schedules].filter(s => { return s.fromId === userId })
      if (schedules.length) {
        const schedule = schedules[0]
        if (schedule.schedules) {
          if (schedule.schedules[day]) {
            const sday = schedule.schedules[day]
            if (!sday.split) {
              response = sday.response
            } else {
              if (slot === 'AM') {
                response = sday.amResponse
              } else if (slot === 'PM') {
                response = sday.pmResponse
              } else {
                response = sday.response
              }
            }
          }
        }
      }
    }
    return response
  }
}

const actions = {
  setSaveUpdateStatus ({ commit }, status) {
    commit('setSaveUpdateStatus', status)
  },
  setBestDaysCalculating ({ commit }, value) {
    commit('setBestDaysCalculating', value)
  },
  setEditingSchedule ({ commit }, schedule) {
    commit('setEditingSchedule', schedule)
  },
  async getProfileById ({ rootState }, { profileId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id

      if (rootState.profile) {
        if (rootState.profile.id === profileId) {
          return rootState.profile
        }
      }
      if (rootState.profiles) {
        const profile = rootState.profiles.filter(pr => profileId === pr.id)
        if (profile[0]) {
          return profile[0]
        }
      }

      const profilesRef = workspaceRef.doc(workspaceId).collection('profiles')
      const profileDoc = await profilesRef.doc(profileId).get()
      return { ...profileDoc.data(), id: profileDoc.id }
    }
  },
  loadTeamProfiles ({ rootState, state, commit }, { teamId }) {
    if (rootState.workspace.id) {
      if (state.team && rootState.profiles) {
        if (state.team.id === teamId) {
          const members = state.team.members
          const memberProfiles = [rootState.profile, ...rootState.profiles.filter(pr => members.includes(pr.id))]
          const profiles = {}
          for (const profileData of memberProfiles) {
            const profile = {
              id: profileData.id,
              displayName: profileData.displayName,
              photoURL: profileData.photoURL,
              firstName: profileData.firstName,
              email: profileData.email,
              lastName: profileData.lastName,
              headline: profileData.headline,
              aboutMe: profileData.aboutMe,
              location: profileData.location,
              flexschedule: profileData.flexschedule,
              defaultScheduleSubmitted: profileData.defaultScheduleSubmitted || false
            }
            profiles[profile.id] = profile
          }
          commit('setTeamProfiles', Object.values(profiles))
        }
      }
    }
  },
  async loadTeamDetails ({ commit }, { workspaceId, teamId }) {
    // console.log('loadTeamDetails.workspaceId', workspaceId, teamId)
    if (teamDetailsListener) { teamDetailsListener() }
    teamDetailsListener = await workspaceRef.doc(workspaceId).collection('teams').doc(teamId)
      .onSnapshot(querySnapshot => {
        const team = { ...querySnapshot.data(), id: querySnapshot.id }
        commit('setTeam', team)
      }, (error) => {
        console.error('store.teams.loadTeamDetails listenerError', error)
      })
  },
  loadWorkspaceRoles ({ commit }, { workspaceId }) {
    if (workspaceRolesListener) { workspaceRolesListener() }
    const workspaceRolesRef = workspaceRef.doc(workspaceId).collection('teamRoles')
    workspaceRolesListener = workspaceRolesRef
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        })
        commit('setWorkspaceRoles', results)
      }, (error) => {
        console.error('store.teams.loadWorkspaceRoleslistenerError', error)
      })
  },
  loadTeamRoles ({ commit }, { workspaceId, teamId }) {
    // console.log('loadTeamDetails.workspaceId', workspaceId, teamId)
    if (teamRolesListener) { teamRolesListener() }
    const teamRolesRef = workspaceRef.doc(workspaceId).collection('teamRoles')
    teamRolesListener = teamRolesRef
      .where('teamId', '==', teamId)
      .onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        })
        commit('setTeamRoles', results)
      }, (error) => {
        console.error('store.teams.loadTeamRoles listenerError', error)
      })
  },
  async loadTeams ({ rootState, state, commit, dispatch }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId).collection('teams')
      if (teamListener) { teamListener() }
      teamListener = teamsRef
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
            if (state.team && rootState.team.id === doc.id) {
              dispatch('loadTeamProfiles', { teamId: doc.id })
            }
          })
          commit('setTeams', results)
        }, (error) => {
          console.error('store.teams.loadTeams listenerError', error)
        })
    }
  },
  async loadTeam ({ rootState, commit, dispatch }, { teamId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const wc = getWC(new Date())
      // console.log('store.teams.loadTeam', workspaceId, userId, teamId, wc)

      if (workspaceId) {
        const teamWorkspaceRef = workspaceRef.doc(workspaceId)
        const teamDoc = await teamWorkspaceRef.collection('teams').doc(teamId).get()
        const team = { id: teamDoc.id, ...teamDoc.data() }
        commit('setTeam', team)
        // console.log('store.loadTeam.setTeam')

        // Priority loading

        // team profiles
        dispatch('loadTeamProfiles', { teamId })
        // console.log('store.teams.loadTeam.loadTeamProfiles')

        dispatch('loadAllDefaultSchedules')
        // console.log('store.teams.loadTeam.loadAllDefaultSchedules')

        // load current week schedules
        dispatch('loadCurrentSchedules')
        // console.log('store.teams.loadTeam.loadCurrentSchedules')

        dispatch('loadTopicsAndTeamMatesPreferences', { userId, fromDate: wc })
        // console.log('store.teams.loadTeam.loadTopicsAndTeamMatesPreferences')

        // load self current week topics and people
        dispatch('loadCurrentTopicsAndPeople', { userId })
        // console.log('store.teams.loadTeam.loadCurrentTopicsAndPeople')

        // load locations
        dispatch('loadLocations')
        // console.log('store.teams.loadTeam.loadLocations')

        // team tempos
        if (teamTempoListener) { teamTempoListener() }
        teamTempoListener = teamWorkspaceRef
          .collection('tempos')
          .orderBy('name', 'asc')
          .onSnapshot(querySnapshot => {
            const results = []
            querySnapshot.forEach((doc) => {
              const tempo = { ...doc.data(), id: doc.id }
              if (tempo.id === 'workspace-schedules' || tempo.teamId === teamId) {
                results.push(tempo)
              }
            })
            commit('setTempos', results)
          }, (error) => {
            console.error('store.teams.loadTeam teamTempoListener', error)
          })

        // team initiatives
        if (workspaceInitiativeListener) { workspaceInitiativeListener() }
        workspaceInitiativeListener = teamWorkspaceRef
          .collection('initiatives')
          .where('visibility', '==', 'Workspace')
          .orderBy('name', 'asc')
          .onSnapshot(querySnapshot => {
            const results = []
            querySnapshot.forEach((doc) => {
              results.push({ ...doc.data(), id: doc.id })
            })
            commit('setWorkspaceInitiatives', results)
          }, (error) => {
            console.error('store.teams.loadTeam workspaceInitiativeListener', error)
          })
        if (personalInitiativeListener) { personalInitiativeListener() }
        personalInitiativeListener = teamWorkspaceRef
          .collection('initiatives')
          .where('members', 'array-contains', userId)
          .orderBy('name', 'asc')
          .onSnapshot(querySnapshot => {
            const results = []
            querySnapshot.forEach((doc) => {
              results.push({ ...doc.data(), id: doc.id })
            })
            commit('setPersonalInitiatives', results)
            // dispatch('loadNotePages', { teamId })
          }, (error) => {
            console.error('store.teams.loadTeam personalInitiativeListener', error)
          })

        // load plans
        dispatch('loadTaskPlans', { teamId, userId: rootState.user.uid })

        // Priority loading finished

        // Additional loading
        if (team) {
          setTimeout(() => {
            // console.log('store.teams.loadTeam - Running additional Loaders')
            dispatch('loadAdditionalTeam', { teamId })
          }, 2000)
        }
      }
    }
  },
  loadAdditionalTeam ({ rootState, commit, dispatch }, { teamId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamWorkspaceRef = workspaceRef.doc(workspaceId)

      // additional team requests
      dispatch('loadRequests', { teamId })
      dispatch('loadTeamDetails', { workspaceId, teamId })
      // console.log('store.teams.loadTeam.loadTeamDetails')

      // load notifications
      dispatch('loadNotifications', { teamId })

      // Load other teams
      dispatch('loadTeams')
      // console.log('store.teams.loadTeam.loadTeams')

      // load calendar subscriptions & meetings
      dispatch('loadSubscriptions')
      dispatch('loadMeetings', { teamId })

      // team notes for personal captured notes relating to team
      if (teamNotesListener) { teamNotesListener() }
      teamNotesListener = teamWorkspaceRef
        .collection('updates')
        .where('teamId', '==', teamId)
        .where('group', '==', 'notes')
        .where('fromId', '==', rootState.user.uid)
        .orderBy('date', 'asc')
        .orderBy('order', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            const update = { ...doc.data(), id: doc.id }
            if (!update.deleted) {
              results.push(update)
            }
          })
          commit('setTeamNotes', results)
        }, (error) => {
          console.error('store.teams.loadTeam teamNotesListener', error)
        })

      // team updates
      const lastQuarter = getLastQuarter()
      if (teamUpdatesListener) { teamUpdatesListener() }
      teamUpdatesListener = teamWorkspaceRef
        .collection('updates')
        .where('teamId', '==', teamId)
        .where('date', '>=', lastQuarter)
        .orderBy('date', 'asc')
        .orderBy('order', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          const resultsLF = []
          const resultsRT = []
          querySnapshot.forEach((doc) => {
            const update = { ...doc.data(), id: doc.id }
            if (!update.deleted) {
              if (update.group !== 'notes') {
                results.push(update)
                if (update.group === 'lookingForward') {
                  resultsLF.push(update)
                } else if (update.group === 'roundTable') {
                  resultsRT.push(update)
                }
              }
            }
          })
          commit('setTeamUpdates', results)
          commit('setTeamLookingForward', resultsLF)
          commit('setTeamRoundTable', resultsRT)
        }, (error) => {
          console.error('store.teams.loadTeam teamUpdatesListener', error)
        })

      const start = moment().startOf('day').toDate()
      const end = moment().startOf('day').add(1, 'days').toDate()
      // console.log('loadTeam.loadUpdatesPages', teamId, start, end)
      dispatch('loadUpdatesPages', {
        teamId,
        startDate: start,
        endDate: end
      })

      dispatch('loadTeamRoles', { workspaceId, teamId })

      dispatch('loadWorkspaceRoles', { workspaceId })

      // load meetingpages
      dispatch('loadMeetingPages', { teamId })

      // load notepages
      // dispatch('loadNotePages', { teamId })

      // load daily pages
      // dispatch('loadDailyPages', { teamId })

      // load pagelinks
      dispatch('loadPageLinks', { teamId })
    }
  },
  loadUpdatesPages ({ rootState, commit }, { teamId, startDate, endDate }) {
    if (lookingForwardPageListener) { lookingForwardPageListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const lookingForwardPageRef = workspaceRef.doc(workspaceId).collection('lookingForwardPages')
      lookingForwardPageListener = lookingForwardPageRef
        .where('teamId', '==', teamId)
        .where('date', '>=', startDate)
        .where('date', '<=', endDate)
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            if (data.docState) {
              data.docState = data.docState.toUint8Array()
            }
            results.push({ ...data, id: doc.id })
          })
          commit('setLookingForwardPages', results)
        }, (error) => {
          console.error('store.teams.loadUpdatesPages lookingForwardPageListener', error)
        })
    }
  },
  loadUpdateComments ({ rootState, commit }, { teamId, updateId }) {
    // console.log('teams/loadUpdateComments', rootState.workspace.id, teamId, updateId)
    if (updateCommentListener) { updateCommentListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const updatesRef = workspaceRef.doc(workspaceId).collection('updates')
      updateCommentListener = updatesRef
        .doc(updateId)
        .collection('comments')
        .orderBy('date', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() })
          })
          commit('setUpdateComments', results)
        }, (error) => {
          console.error('store.teams.loadUpdateComments updateCommentListener', error)
        })
    }
  },
  clearUpdateComments ({ commit }) {
    commit('setUpdateComments', null)
  },
  loadTaskListComments ({ rootState, commit }, { teamId, taskListId }) {
    // console.log('teams/loadTaskListComments', rootState.workspace.id, teamId, taskListId)
    if (taskListCommentListener) { taskListCommentListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const taskListRef = workspaceRef.doc(workspaceId).collection('comments')
      taskListCommentListener = taskListRef
        .where('taskListId', '==', taskListId)
        .orderBy('date', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          // console.log('teams.loadTaskListComments', results)
          commit('setTaskListComments', results)
        }, (error) => {
          console.error('store.teams.loadTaskListComments taskListCommentListener', error)
        })
    }
  },
  loadTaskActivity ({ rootState, commit }, { teamId, taskId }) {
    // console.log('teams/loadTaskActivity', rootState.workspace.id, teamId, taskId)
    if (taskActivityListener) { taskActivityListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const taskListRef = workspaceRef.doc(workspaceId).collection('activity')
      taskActivityListener = taskListRef
        .where('taskId', '==', taskId)
        .orderBy('created', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id, isActivity: true })
          })
          commit('setTaskActivity', results)
        }, (error) => {
          console.error('store.teams.loadTaskActivity taskActivityListener', error)
        })
    }
  },
  updateNotification ({ rootState, commit }, { notificationId, update }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamRef = workspaceRef.doc(workspaceId)
      return teamRef.collection('notifications').doc(notificationId).update(update)
    }
  },
  loadNotifications ({ rootState, commit }, { teamId }) {
    if (notificationListener) { notificationListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      // console.log('teams/loadNotifications', rootState.workspace.id, teamId, userId)
      const notificationtRef = workspaceRef.doc(workspaceId).collection('notifications')
      notificationListener = notificationtRef
        .where('teamId', '==', teamId)
        .where('userId', '==', userId)
        .orderBy('created', 'desc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setNotifications', results)
        }, (error) => {
          console.error('store.teams.loadNotifications notificationListener', error)
        })
      recommendationListener = notificationtRef
        .where('teamId', '==', teamId)
        .where('userId', '==', userId)
        .where('type', '==', 'recommendation')
        .where('read', '==', false)
        .where('expires', '>=', moment().startOf('day').toDate())
        .orderBy('expires', 'asc')
        .orderBy('created', 'desc')
        .limit(1)
        .onSnapshot(querySnapshot => {
          let result = null
          querySnapshot.forEach((doc) => {
            result = { ...doc.data(), id: doc.id }
          })
          commit('setLastRecommendation', result)
        }, (error) => {
          console.error('store.teams.loadNotifications recommendationListener', error)
        })
    }
  },
  loadTaskComments ({ rootState, commit }, { teamId, taskId }) {
    // console.log('teams/loadTaskComments', rootState.workspace.id, teamId, taskId)
    if (taskCommentListener) { taskCommentListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const taskListRef = workspaceRef.doc(workspaceId).collection('comments')
      taskCommentListener = taskListRef
        .where('taskId', '==', taskId)
        .orderBy('date', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id, isComment: true })
          })
          // console.log('teams.loadTaskComments', results)
          commit('setTaskComments', results)
        }, (error) => {
          console.error('store.teams.loadTaskComments taskCommentListener', error)
        })
    }
  },
  loadLookingForwardBlockComments ({ rootState, commit }, { noteBlockId }) {
    if (lookingForwardBlockCommentsListener) { lookingForwardBlockCommentsListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      let noteBlockCommentsRef = workspaceRef.doc(workspaceId).collection('noteBlockComments')
      noteBlockCommentsRef = noteBlockCommentsRef.where('noteBlockId', '==', noteBlockId)
      noteBlockCommentsRef = noteBlockCommentsRef.orderBy('date', 'asc')
      lookingForwardBlockCommentsListener = noteBlockCommentsRef.onSnapshot(querySnapshot => {
        const results = []
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        })
        commit('setLookingForwardBlockComments', results)
      }, (error) => {
        console.error('store.teams.loadLookingForwardBlockComments lookingForwardBlockCommentsListener', error)
      })
    }
  },
  saveLookingForwardBlockComments ({ rootState, commit }, { teamId, noteBlockId, commentId, comment }) {
    if (rootState.workspace.id) {
      comment.noteBlockId = noteBlockId
      comment.teamId = teamId
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      if (commentId) {
        return teamsRef.collection('noteBlockComments').doc(commentId).update(comment)
      } else {
        return teamsRef.collection('noteBlockComments').add(comment)
      }
    }
  },
  async loadNotePages ({ rootState, commit }, { teamId }) {
    if (notePageListener) { notePageListener() }
    if (notePageShareUserListener) { notePageShareUserListener() }
    if (notePageShareInitiativeListeners) {
      const listeners = { ...notePageShareInitiativeListeners }
      for (const listener of Object.values(listeners)) {
        listener()
      }
      notePageShareInitiativeListeners = {}
    }
    commit('resetNotePages')

    if (rootState.workspace.id) {
      const userId = rootState.user.uid
      const workspaceId = rootState.workspace.id
      const notePageRef = workspaceRef.doc(workspaceId).collection('notePages')
      const initiativeRef = workspaceRef.doc(workspaceId).collection('initiatives')
      const initiatives = []
      const initiativeDocs = await initiativeRef
        .where('members', 'array-contains', userId)
        .get()
      for (const initiativeDoc of initiativeDocs.docs) {
        initiatives.push({ ...initiativeDoc.data(), id: initiativeDoc.id })
      }

      if (initiatives) {
        for (const initiative of initiatives) {
          notePageShareInitiativeListeners[initiative.id] = notePageRef
            .where('shareInitiatives', 'array-contains', initiative.id)
            .orderBy('pageName', 'asc')
            .onSnapshot(querySnapshot => {
              const results = []
              querySnapshot.forEach((doc) => {
                const data = doc.data()
                if (data.docState) {
                  data.docState = data.docState.toUint8Array()
                }
                results.push({ ...data, pageType: 'notesPage', id: doc.id })
              })
              commit('setShareInitiativeNotePages', { initiativeId: initiative.id, notePages: results })
            }, (error) => {
              console.error('store.teams.loadNotePages notePageShareInitiativeListeners', error)
            })
        }
      }

      notePageShareUserListener = notePageRef
        .where('shareUsers', 'array-contains', userId)
        .orderBy('pageName', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            if (data.docState) {
              data.docState = data.docState.toUint8Array()
            }
            results.push({ ...data, pageType: 'notesPage', id: doc.id })
          })
          commit('setShareUserNotePages', results)
        }, (error) => {
          console.error('store.teams.loadNotePages notePageShareUserListener', error)
        })

      notePageListener = notePageRef
        .where('userId', '==', userId)
        .orderBy('pageName', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            if (data.docState) {
              data.docState = data.docState.toUint8Array()
            }
            results.push({ ...data, pageType: 'notesPage', id: doc.id })
          })
          commit('setUserNotePages', results)
        }, (error) => {
          console.error('store.teams.loadNotePages notePageListener', error)
        })
    }
  },
  loadDailyPages ({ rootState, commit }, { teamId }) {
    if (dailyPageListener) { dailyPageListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      // console.log('loadDailyPages', workspaceId, teamId, userId)
      const dailyPageRef = workspaceRef.doc(workspaceId).collection('dailyPages')
      dailyPageListener = dailyPageRef
        .where('teamId', '==', teamId)
        .where('userId', '==', userId)
        .orderBy('date', 'desc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            if (data.docState) {
              data.docState = data.docState.toUint8Array()
            }
            if (!data.pageName) {
              const m = moment(data.date.toDate())
              data.pageName = m.format().split('T')[0]
            }
            results.push({ ...data, pageType: 'dailyPage', id: doc.id })
          })
          commit('setDailyPages', results)
        }, (error) => {
          console.error('store.teams.loadDailyPages dailyPageListener', error)
        })
    }
  },
  loadPageLinks ({ rootState, commit }, { teamId }) {
    if (pageLinkListener) { pageLinkListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      // console.log('loadPageLinks', workspaceId, teamId, userId)
      const pageLinkgRef = workspaceRef.doc(workspaceId).collection('pageLinks')
      pageLinkListener = pageLinkgRef
        .where('userId', '==', userId)
        .orderBy('pageName', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setPageLinks', results)
        }, (error) => {
          console.error('store.teams.loadPageLinks pageLinkListener', error)
        })
    }
  },
  loadMeetingPages ({ rootState, commit }, { teamId }) {
    if (meetingPageListener) { meetingPageListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // eslint-disable-next-line no-unused-vars
      const userId = rootState.user.uid
      // console.log('loadMeetingPages', workspaceId, teamId, userId)
      const meetingRef = workspaceRef.doc(workspaceId).collection('meetingPages')
      meetingPageListener = meetingRef
        .where('teamId', '==', teamId)
        .orderBy('pageName', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            if (data.docState) {
              data.docState = data.docState.toUint8Array()
            }
            results.push({ ...data, pageType: 'meetingPage', id: doc.id })
          })
          commit('setMeetingPages', results)
        }, (error) => {
          console.error('store.teams.loadMeetingPages meetingPageListener', error)
        })
    }
  },
  loadSubscriptions ({ rootState, commit }) {
    if (subscriptionListener) { subscriptionListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const subRef = workspaceRef.doc(workspaceId).collection('subscriptions')
      subscriptionListener = subRef
        .where('userId', '==', userId)
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setSubscriptions', results)
        }, (error) => {
          console.error('store.teams.loadSubscriptions subscriptionListener', error)
        })
    }
  },
  loadMeetings ({ rootState, commit }, { teamId }) {
    if (meetingListener) { meetingListener() }
    if (meetingRecListener) { meetingRecListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      // console.log('loadMeetings', workspaceId, teamId, userId)
      const meetingRef = workspaceRef.doc(workspaceId).collection('meetings')
      meetingListener = meetingRef
        .where('userId', '==', userId)
        .where('isMaster', '==', false)
        .orderBy('date', 'asc')
        .orderBy('startTime', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setMeetings', results)
        }, (error) => {
          console.error('store.teams.loadMeetings meetingListener', error)
        })
      // Listen to master for recurring meetings
      meetingRecListener = meetingRef
        .where('userId', '==', userId)
        .where('isMaster', '==', true)
        .orderBy('date', 'asc')
        .orderBy('startTime', 'asc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setMeetingsRec', results)
        }, (error) => {
          console.error('store.teams.loadMeetings meetingRecListener', error)
        })
    }
  },
  updateMeeting ({ rootState, commit }, { meetingId, meeting }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamRef = workspaceRef.doc(workspaceId)
      teamRef.collection('meetings').doc(meetingId).update(meeting)
    }
  },
  async deleteFutureMeetings ({ rootState, commit }) {
    if (rootState.workspace.id) {
      const userId = rootState.user.uid
      const workspaceId = rootState.workspace.id
      const teamRef = workspaceRef.doc(workspaceId)
      const meetingDocs = await teamRef.collection('meetings')
        .where('userId', '==', userId)
        .where('startDateTime', '>', new Date())
        .get()
      if (meetingDocs.docs) {
        for (const meetingDoc of meetingDocs.docs) {
          const meeting = { ...meetingDoc.data(), id: meetingDoc.id }
          if (!meeting.meetingPageId && meeting.meetingSource !== 'Google') {
            await teamRef.collection('meetings').doc(meeting.id).delete()
          } else if (meeting.meetingSource !== 'Google') {
            await teamRef.collection('meetings').doc(meeting.id).update({ ended: new Date() })
          }
        }
      }
      const meetinRecgDocs = await teamRef.collection('meetings')
        .where('userId', '==', userId)
        .where('isMaster', '==', true)
        .get()
      if (meetinRecgDocs.docs) {
        // cancel active recurring meetings
        for (const meetingDoc of meetinRecgDocs.docs) {
          const meeting = { ...meetingDoc.data(), id: meetingDoc.id }
          if (meeting.meetingSource !== 'Google') {
            await teamRef.collection('meetings').doc(meeting.id).update({ ended: new Date() })
          }
        }
      }
    }
  },
  async deleteFutureGoogleMeetings ({ rootState, commit }) {
    if (rootState.workspace.id) {
      const userId = rootState.user.uid
      const workspaceId = rootState.workspace.id
      const teamRef = workspaceRef.doc(workspaceId)
      const meetingDocs = await teamRef.collection('meetings')
        .where('userId', '==', userId)
        .where('startDateTime', '>', new Date())
        .get()
      if (meetingDocs.docs) {
        for (const meetingDoc of meetingDocs.docs) {
          const meeting = { ...meetingDoc.data(), id: meetingDoc.id }
          if (!meeting.meetingPageId && meeting.meetingSource === 'Google') {
            await teamRef.collection('meetings').doc(meeting.id).delete()
          } else if (meeting.meetingSource === 'Google') {
            await teamRef.collection('meetings').doc(meeting.id).update({ ended: new Date() })
          }
        }
      }
      const meetinRecgDocs = await teamRef.collection('meetings')
        .where('userId', '==', userId)
        .where('isMaster', '==', true)
        .get()
      if (meetinRecgDocs.docs) {
        // cancel active recurring meetings
        for (const meetingDoc of meetinRecgDocs.docs) {
          const meeting = { ...meetingDoc.data(), id: meetingDoc.id }
          if (meeting.meetingSource === 'Google') {
            await teamRef.collection('meetings').doc(meeting.id).update({ ended: new Date() })
          }
        }
      }
    }
  },
  setMeetings ({ rootState, commit }, { teamId, meetings }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      const batch = fb.firestore.batch()
      const batchRef = teamsRef.collection('meetings')
      for (const meeting of meetings) {
        meeting.teamId = teamId
        if (meeting.id) {
          batch.set(batchRef.doc(meeting.id), meeting)
        } else {
          const newRef = batchRef.doc()
          batch.set(newRef, meeting)
        }
      }
      // Commit the batch
      return batch.commit()
    }
  },
  loadRequests ({ rootState, commit }, { teamId }) {
    if (requestListener) { requestListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // console.log('loadRequests', workspaceId, teamId)
      const requestRef = workspaceRef.doc(workspaceId).collection('teams').doc(teamId).collection('requests')
      requestListener = requestRef
        .where('completed', '==', false)
        .orderBy('created', 'desc')
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id })
          })
          commit('setRequests', results)
        }, (error) => {
          console.error('store.teams.loadRequests requestListener', error)
        })
    }
  },
  updateRequest ({ rootState, commit }, { teamId, requestId, request }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // console.log('updateRequest', workspaceId, teamId, requestId)
      const requestRef = workspaceRef.doc(workspaceId).collection('teams').doc(teamId).collection('requests')
      return requestRef.doc(requestId).update(request)
    }
  },
  clearTaskListComments ({ commit }) {
    commit('setTaskListComments', null)
  },
  clearTaskComments ({ commit }) {
    commit('setTaskComments', null)
  },
  async getUpdate ({ rootState }, { teamId, updateId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const updateRef = workspaceRef.doc(workspaceId).collection('updates')
      const teamUpdateDoc = await updateRef.doc(updateId).get()
      if (teamUpdateDoc.exists) {
        return { ...teamUpdateDoc.data(), id: teamUpdateDoc.id }
      }
      return null
    }
    return null
  },
  saveTaskList ({ rootState }, { teamId, initiativeIds, taskListId, taskList }) {
    // console.log('saveTaskList', teamId, initiativeIds, taskListId, taskList)
    if (rootState.workspace.id) {
      taskList.teamId = teamId
      taskList.initiativeId = initiativeIds
      const workspaceId = rootState.workspace.id
      const tasklistRef = workspaceRef.doc(workspaceId).collection('tasklists')
      if (taskListId) {
        return tasklistRef
          .doc(taskListId)
          .update(taskList)
      } else {
        return tasklistRef
          .add(taskList)
      }
    }
  },
  deleteTaskList ({ rootState, commit }, { taskListId }) {
    if (rootState.workspace.id) {
      // console.log(taskListId)
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      return teamsRef.collection('tasklists').doc(taskListId).update({ deleted: true })
    }
  },
  updateTasksTaskListId ({ rootState, commit }, { tasks, defaultTaskListId }) {
    if (rootState.workspace.id) {
      // console.log(tasks, defaultTaskListId)
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      const batch = fb.firestore.batch()
      const batchRef = teamsRef.collection('tasks')
      for (const task of tasks) {
        task.taskListIds = [defaultTaskListId]
        if (task.id) {
          batch.update(batchRef.doc(task.id), task)
        }
      }
      return batch.commit()
    }
  },
  getTasks ({ rootState, commit }, { initiativeId, teamId }) {
    // console.log('teams.getTasks', initiativeId, teamId)
    if (taskListener) { taskListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // console.log('getTasks', workspaceId, teamId, initiativeId)
      let taskRef = workspaceRef.doc(workspaceId).collection('tasks')
      if (initiativeId) {
        taskRef = taskRef.where('initiativeIds', 'array-contains', initiativeId)
      }
      // if (teamId) {
      //   taskRef = taskRef.where('teamId', '==', teamId)
      // }
      taskRef = taskRef.orderBy('due', 'asc')
      taskRef = taskRef.orderBy('text', 'asc')
      taskListener = taskRef.onSnapshot((querySnapshot) => {
        const results = []
        const resultsByList = {}
        querySnapshot.forEach(async (doc) => {
          const task = { ...doc.data(), id: doc.id }
          if (!task.deleted) {
            results.push(task)
            if (task.taskListIds) {
              for (const listId of task.taskListIds) {
                if (resultsByList[listId]) {
                  resultsByList[listId].push(task)
                } else {
                  resultsByList[listId] = [task]
                }
              }
            }
          }
        })
        commit('setTasks', results)
        for (const listId in resultsByList) {
          const taskList = resultsByList[listId]
          taskList.sort((x, y) => {
            return x.order - y.order
          })
        }
        commit('setTasksByList', resultsByList)
      }, (error) => {
        console.error('store.teams.getTasks taskListener', error)
      })
    }
  },
  getTaskLists  ({ rootState, commit }, { teamId, initiativeId }) {
    // console.log('teams.getTaskLists', teamId, initiativeId)
    if (taskListListener) { taskListListener() }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // console.log('getTaskLists', workspaceId, teamId, initiativeId)
      let tasklistRef = workspaceRef.doc(workspaceId).collection('tasklists')
      if (initiativeId) {
        tasklistRef = tasklistRef.where('initiativeId', 'array-contains', initiativeId)
      }
      // } else if (teamId) {
      //  tasklistRef = tasklistRef.where('teamId', '==', teamId)
      // }
      tasklistRef = tasklistRef.orderBy('order', 'asc')
      taskListListener = tasklistRef
        .onSnapshot((querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (doc) => {
            const taskList = { ...doc.data(), id: doc.id }
            if (!taskList.deleted) {
              results.push(taskList)
            }
          })
          commit('setTaskLists', results)
          // console.log('setTaskLists', results)
        }, (error) => {
          console.error('store.teams.getTaskLists taskListListener', error)
        })
    }
  },
  clearTasksLists ({ commit }) {
    commit('setTaskLists', [])
  },
  getUpdatesByTag ({ rootState }, { teamId, tag }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const updateRef = workspaceRef.doc(workspaceId).collection('updates')
      return updateRef
        .where('teamId', '==', teamId)
        .where('tags', 'array-contains', tag)
        .orderBy('date', 'desc')
        .get().then((querySnapshot) => {
          const results = []
          querySnapshot.forEach((doc) => {
            results.push({ id: doc.id, ...doc.data() })
          })
          return results
        }).catch(error => {
          console.error('store.teams.getUpdatesByTag error', error)
          throw error
        })
    }
  },
  createTeam ({ rootState, dispatch }, { teamName }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const team = {
        name: teamName,
        owner: rootState.profile.email,
        ownerName: rootState.profile.displayName,
        created: fb.serverTime(),
        invites: [],
        members: [rootState.profile.email]
      }
      return workspaceRef.doc(workspaceId).collection('teams').add(team).then((doc) => {
        dispatch('addTeamChannels', { workspaceId, teamId: doc.id }, { root: true })
        dispatch('updateProfile', { profile: { team: doc.id } }, { root: true })
        dispatch('createDefaultTempos', { teamId: doc.id })
        return doc
      })
    } else {
      // Create workspace for team
      const newWorkspace = {
        generated: true,
        name: teamName,
        created: fb.serverTime(),
        createdBy: rootState.user.uid,
        createdByEmail: rootState.profile.email,
        createdByName: rootState.profile.displayName,
        members: [rootState.profile.email]
      }
      return workspaceRef.add(newWorkspace).then((doc) => {
        const workspaceId = doc.id
        rootState.workspace = {
          ...newWorkspace,
          id: workspaceId
        }
        dispatch('loadAssignedWorkspace', { workspaceId }, { root: true })
        workspaceRef.doc(workspaceId).collection('data').doc('totals').set(
          {
            jobsInterest: 0,
            profileVisible: 0,
            signups: 0,
            roles: {},
            skills: {}
          }
        )
        const team = {
          name: teamName,
          owner: rootState.profile.email,
          ownerName: rootState.profile.displayName,
          createdBy: rootState.user.uid,
          created: fb.serverTime(),
          invites: [],
          members: [rootState.profile.email]
        }
        return workspaceRef.doc(workspaceId).collection('teams').add(team).then(async (doc) => {
          dispatch('addTeamChannels', { workspaceId, teamId: doc.id }, { root: true })
          dispatch('updateProfile', { profile: { team: doc.id } }, { root: true })
          dispatch('createDefaultTempos', { teamId: doc.id })
          // refresh token to update claim
          await fb.auth.currentUser.getIdToken(true)
          return doc
        })
      })
    }
  },
  async inviteToTeam ({ rootState, commit }, { teamId, email }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId).collection('teams')
      const workspaceDoc = await workspaceRef.doc(workspaceId).get()
      const workspace = { ...workspaceDoc.data(), id: workspaceDoc.id }
      return teamsRef.doc(teamId).get().then(async (doc) => {
        const team = doc.data()
        if (!team.members.includes(email)) {
          const teamUpdates = {
            members: [...team.members, email],
            invites: [...team.invites, email]
          }
          const workspaceUpdates = {
            members: [...workspace.members, email]
          }
          // console.log('team.inviteToTeam', workspaceId, teamId, email, workspace, workspaceUpdates)
          await workspaceRef.doc(workspaceId).update(workspaceUpdates)
          await teamsRef.doc(teamId).update(teamUpdates)
          return `${email} invited to team`
        } else {
          return `${email} already invited to team`
        }
      }).catch(error => {
        console.error('store.teams.inviteToTeam error', error)
        throw error
      })
    }
  },
  joinTeam ({ rootState, dispatch }, { workspaceId, teamId }) {
    // console.log('teams.joinTeam', rootState.workspace.id, workspaceId, teamId)
    if (workspaceId) {
      const teamsRef = workspaceRef.doc(workspaceId).collection('teams')
      return teamsRef.doc(teamId).get().then(async (doc) => {
        const team = doc.data()
        if (team.invites.includes(rootState.profile.email)) {
          const teamUpdates = {
            invites: team.invites.filter(invite => invite !== rootState.profile.email)
          }
          await teamsRef.doc(teamId).update(teamUpdates)
        }
        rootState.workspace = { id: workspaceId }
        dispatch('updateProfile', { profile: { team: doc.id, workspaceId } }, { root: true })
        // refresh token to update claim
        await fb.auth.currentUser.getIdToken(true)
        dispatch('loadTeam', { teamId })

        return team
      }).catch(error => {
        console.error('store.teams.joinTeam 1 error', error)
        throw error
      })
    } else if (rootState.workspace.id) {
      workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId).collection('teams')
      return teamsRef.doc(teamId).get().then(async (doc) => {
        const team = doc.data()
        if (team.invites.includes(rootState.profile.email)) {
          const teamUpdates = {
            invites: team.invites.filter(invite => invite !== rootState.profile.email)
          }
          await teamsRef.doc(teamId).update(teamUpdates)
        }
        dispatch('loadTeam', { teamId })
        dispatch('updateProfile', { profile: { team: doc.id } }, { root: true })
        return team
      }).catch(error => {
        console.error('store.teams.joinTeam 2 error', error)
        throw error
      })
    }
  },
  saveInitiative  ({ rootState, commit }, { teamId, initiativeId, initiative }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      if (teamId && !initiativeId) {
        initiative.teamId = teamId
      }
      const initiativesRef = workspaceRef.doc(workspaceId).collection('initiatives')
      // console.log('store.saveInitiative', teamId, initiativeId, initiative)
      if (initiativeId) {
        return initiativesRef.doc(initiativeId).update(initiative)
      } else {
        return initiativesRef.add(initiative)
      }
    }
  },
  createDefaultTempos ({ rootState, dispatch }, { teamId }) {
    if (rootState.workspace.id) {
      const defaults = [
        {
          active: false,
          days: [],
          frequency: 'Weekly',
          members: [],
          name: 'Working From',
          question: 'Do you plan to work in the office next week?',
          time: '9:00',
          title: 'WHERE IS THE TEAM',
          type: 'schedule'
        },
        {
          active: false,
          day: 'Monday',
          days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          frequency: 'Weekly',
          members: [],
          name: 'Looking Forward',
          question: 'What are you planning to work on?',
          time: '9:00',
          title: 'WHATS BEING WORKED ON',
          type: 'updates'
        },
        {
          active: false,
          day: 'Friday',
          days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          frequency: 'Weekly',
          members: [],
          name: 'Round Table',
          question: 'How was it? Update the team on progress or other key info.',
          time: '9:00',
          title: 'HOW`S PROGRESS',
          type: 'updates'
        },
        {
          active: false,
          condition: 'remote',
          day: '',
          days: ['Monday', 'Tuesday'],
          frequency: 'Day remote',
          members: [],
          name: 'Remote Today',
          question: 'Hi - You are scheduled to be working remotely, connect to others working remote. ',
          time: '9:00',
          title: 'ENGAGE REMOTE TEAM'
        }
      ]
      for (const tempo of defaults) {
        dispatch('saveTempo', { teamId, tempo })
      }
    }
  },
  saveTempo  ({ rootState, commit }, { teamId, tempoId, tempo }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      tempo.teamId = teamId
      const tempoRef = workspaceRef.doc(workspaceId).collection('tempos')
      if (tempoId) {
        return tempoRef.doc(tempoId).update(tempo)
      } else {
        return tempoRef.add(tempo)
      }
    }
  },
  saveWorkspaceTempo  ({ rootState, commit }, { tempo }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      tempo.setByWorkspace = true
      // Temp Fix - set teamId against workspace schedule tempo
      tempo.teamId = 'workspace'
      const tempoRef = workspaceRef.doc(workspaceId).collection('tempos')
      return tempoRef.doc('workspace-' + tempo.type).set(tempo)
    }
  },
  setRecommendationNotificationPaused ({ rootState, commit }, { preferenceId, pausedNotifications }) {
    if (rootState.workspace.id) {
      // console.log('store.teams.setRecommendationNotificationPaused', preferenceId, pausedNotifications)
      const workspaceId = rootState.workspace.id
      const preferencesRef = workspaceRef.doc(workspaceId).collection('topicsPeoplePreferences').doc(preferenceId)
      preferencesRef.update({ pausedNotifications })
    }
  },
  saveComment ({ rootState, commit }, { teamId, updateId, commentId, comment }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      comment.teamId = teamId
      comment.updateId = updateId
      const updatedRef = workspaceRef.doc(workspaceId).collection('updates')
      if (commentId) {
        return updatedRef.doc(updateId).collection('comments').doc(commentId).update(comment)
      } else {
        return updatedRef.doc(updateId).collection('comments').add(comment)
      }
    }
  },
  async getTaskPlan ({ rootState, commit }, { teamId, userId, taskId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const taskPlansRef = workspaceRef.doc(workspaceId).collection('taskPlans')
      const results = []
      const taskPlansDocs = await taskPlansRef
        .where('userId', '==', userId)
        .where('taskId', '==', taskId)
        .get()
      if (!taskPlansDocs.empty) {
        for (const taskPlansDoc of taskPlansDocs.docs) {
          results.push({ ...taskPlansDoc.data(), id: taskPlansDoc.id })
        }
      }
      return results
    }
    return []
  },
  loadTaskPlans ({ rootState, commit }, { teamId, userId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // console.log('loadTaskPlans', workspaceId, teamId, userId)
      const taskPlansRef = workspaceRef.doc(workspaceId).collection('taskPlans')
      const userTaskPlanRef = taskPlansRef
        .where('userId', '==', userId)
        .orderBy('planDate', 'asc')
      taskPlanListener = userTaskPlanRef
        .onSnapshot((querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (doc) => {
            const taskplan = { ...doc.data(), id: doc.id }
            results.push(taskplan)
          })
          commit('setTaskPlans', results)
        }, (error) => {
          console.error('store.teams.loadTaskPlans taskPlanListener', error)
        })
    }
  },
  async getSubscriptionEvents ({ rootState, commit }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const eventRef = workspaceRef.doc(workspaceId).collection('subscriptionEvents')
      const results = []
      const eventDocs = await eventRef
        .where('userId', '==', userId)
        .orderBy('date', 'desc')
        .get()
      if (!eventDocs.empty) {
        for (const eventDoc of eventDocs.docs) {
          results.push({ ...eventDoc.data(), id: eventDoc.id })
        }
      }
      return results
    }
    return []
  },
  saveTaskPlan ({ rootState, commit }, { teamId, userId, id, taskId, planDate, type }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const taskPlansRef = workspaceRef.doc(workspaceId).collection('taskPlans')
      if (id) {
        return taskPlansRef.doc(id).update({ userId, planDate, type })
      } else {
        return taskPlansRef.add({ teamId, userId, planDate, type, taskId })
      }
    }
  },
  processTasks ({ dispatch }, { teamId, tasks }) {
    for (const task of tasks) {
      if (task.taskId) {
        // console.log('processTasks', teamId, task)
        const taskUpdate = {
          text: `<p>${task.content}</p>`,
          taskId: task.taskId,
          order: 0,
          initiatives: task.initiatives,
          assigned: task.assigned,
          lastUpdate: new Date(),
          completed: task.completed,
          due: ''
        }
        dispatch('saveTask', { teamId, task: taskUpdate, taskId: task.taskId })
      }
    }
  },
  saveTask ({ rootState, state, dispatch }, { teamId, shareInitiatives, taskListIds, taskId, task, assignedIds }) {
    // console.log('teams.saveTask', teamId, initiativeIds, taskId, task, assignedIds)
    if (rootState.workspace.id) {
      const userId = rootState.user.uid
      if (shareInitiatives) {
        task.shareInitiatives = shareInitiatives
      }
      if (taskListIds) {
        task.taskListIds = taskListIds
      }
      if (task.shareInitiatives && !task.taskListIds) {
        task.taskListIds = []
        for (const initiativeId of task.shareInitiatives) {
          const initiative = state.initiatives.filter(init => init.id === initiativeId)
          if (initiative) {
            task.taskListIds.push(initiative[0].defaultTaskListId)
          }
        }
      }
      // console.log('saveTask', taskId, task.shareInitiatives, taskListIds, task.taskListIds)
      if (teamId) {
        task.teamId = teamId
      }
      if (assignedIds) {
        task.assignedIds = assignedIds
      }
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      task.updatedOn = new Date()
      task.lastEditedBy = userId
      if (taskId) {
        return teamsRef.collection('tasks').doc(taskId).set(task, { merge: true })
      } else {
        return teamsRef.collection('tasks').add(task).then((taskDoc) => {
          // console.log('store.saveTask', task, taskDoc.id)
          dispatch('saveTaskActivity', { activity: { taskId: taskDoc.id, newTask: new Date() } })
          return { ...task, id: taskDoc.id }
        })
      }
    }
  },
  saveTaskActivity ({ rootState, commit }, { activity }) {
    if (rootState.workspace.id) {
      activity.userId = rootState.user.uid
      activity.type = 'task'
      activity.created = new Date()
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      return teamsRef.collection('activity').add(activity)
    }
  },
  saveTaskComment ({ rootState, commit }, { teamId, shareInitiatives, taskId, commentId, comment }) {
    // console.log('teams.saveTaskListComment', teamId, initiativeId, initiativeId, commentId)
    if (rootState.workspace.id) {
      comment.shareInitiatives = shareInitiatives
      comment.taskId = taskId
      comment.teamId = teamId
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      if (commentId) {
        return teamsRef.collection('comments').doc(commentId).update(comment)
      } else {
        return teamsRef.collection('comments').add(comment)
      }
    }
  },
  saveTaskListComment ({ rootState, commit }, { teamId, initiativeId, taskListId, commentId, comment }) {
    // console.log('teams.saveTaskListComment', teamId, initiativeId, initiativeId, commentId)
    if (rootState.workspace.id) {
      comment.initiativeId = initiativeId
      comment.taskListId = taskListId
      comment.teamId = teamId
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      if (commentId) {
        return teamsRef.collection('comments').doc(commentId).update(comment)
      } else {
        return teamsRef.collection('comments').add(comment)
      }
    }
  },
  saveUpdateMessage ({ rootState, commit }, { teamId, updateId, messageParam }) {
    // console.log('teams.saveUpdateMessage', teamId, updateId, messageParam)
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const updateRef = workspaceRef.doc(workspaceId).collection('updates')
      if (updateId) {
        return updateRef.doc(updateId).update({ message: messageParam })
      }
    }
  },
  saveUpdate  ({ rootState, commit }, { teamId, updateId, update }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const updateRef = workspaceRef.doc(workspaceId).collection('updates')
      const updateCopy = { ...update, teamId }
      delete updateCopy.changed
      if (updateId) {
        return updateRef.doc(updateId).update(updateCopy)
      } else {
        return updateRef.add(updateCopy)
      }
    }
  },
  saveUpdatesBatch ({ rootState, commit }, { teamId, updates }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      const batch = fb.firestore.batch()
      const batchRef = teamsRef.collection('updates')
      for (const update of updates) {
        update.teamId = teamId
        delete update.changed
        if (update.id) {
          batch.update(batchRef.doc(update.id), update)
        } else {
          const newRef = batchRef.doc()
          batch.set(newRef, update)
        }
      }
      // Commit the batch
      return batch.commit()
    }
  },
  saveTasksBatch ({ rootState, commit }, { teamId, tasks }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      const batch = fb.firestore.batch()
      const batchRef = teamsRef.collection('tasks')
      for (const task of tasks) {
        // console.log('saveTasksBatch', task)
        if (task.id) {
          batch.update(batchRef.doc(task.id), task)
        } else {
          const newRef = batchRef.doc()
          batch.set(newRef, task)
        }
      }
      // Commit the batch
      return batch.commit()
    }
  },
  saveTaskListsBatch ({ rootState, commit }, { teamId, tasklists }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      commit('setTaskLists', tasklists)
      const batch = fb.firestore.batch()
      const batchRef = teamsRef.collection('tasklists')
      for (const tasklist of tasklists) {
        if (tasklist.id) {
          batch.update(batchRef.doc(tasklist.id), tasklist)
        } else {
          const newRef = batchRef.doc()
          batch.set(newRef, tasklist)
        }
      }
      // Commit the batch
      return batch.commit()
    }
  },
  deleteUpdate ({ rootState, commit }, { teamId, updateId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId)
      return teamsRef.collection('updates').doc(updateId).update({ deleted: true, nodeId: null })
    }
  },
  deleteTask ({ rootState, commit }, { taskId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // console.log('deleteTask', '/workspaces/', workspaceId, '/tasks/', taskId)
      const teamsRef = workspaceRef.doc(workspaceId)
      return teamsRef.collection('tasks').doc(taskId).update({ deleted: true })
    }
  },
  updateTeam ({ rootState, commit }, { teamId, updates }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const teamsRef = workspaceRef.doc(workspaceId).collection('teams')
      return teamsRef.doc(teamId).update(updates)
    }
  },
  async updateDailyPage  ({ rootState, dispatch }, { teamId, dailyPageId, dailyPage }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      // console.log('updateDailyPage', workspaceId, teamId, userId, dailyPageId)
      if (dailyPage.json) {
        // get blocks and links from json
        const blocksLinks = JSONtoBlocksLinksTasks(dailyPage.json)
        dailyPage.blocks = blocksLinks.blocks
        dailyPage.noteBlocks = blocksLinks.noteBlocks
        dailyPage.links = blocksLinks.links
        dailyPage.tags = blocksLinks.tags
        dailyPage.mentions = blocksLinks.mentions
        // console.log('updateDailyPage.processTasks', teamId)
        dispatch('processTasks', { teamId, tasks: blocksLinks.tasks })
        // console.log('updateDailyPage.JSONtoBlocksLinks', blocksLinks)
      }
      if (dailyPage.docState) {
        dailyPage.docState = fb.Blob.fromUint8Array(dailyPage.docState)
      }
      const dailyPageRef = workspaceRef.doc(workspaceId).collection('dailyPages')
      const dailyPageDoc = await dailyPageRef.doc(dailyPageId).get()
      const dailyPageObj = { ...dailyPageDoc.data(), id: dailyPageDoc.id }
      const m = moment(dailyPageObj.date.toDate())
      if (!dailyPageObj.pageName) {
        dailyPage.pageName = m.format().split('T')[0]
      }
      // console.log('teams.updateDailyPage', dailyPageObj.pageLink, m.format().split('T')[0], dailyPageObj.date.toDate())
      if (dailyPage.blocks) {
        if (dailyPage.blocks.length >= 1) {
          if (!dailyPageObj.pageLink) {
            const pageName = m.format().split('T')[0]
            const pageLinkRef = workspaceRef.doc(workspaceId).collection('pageLinks')
            pageLinkRef.doc(dailyPageId).set({
              link: `daily/${dailyPageId}`,
              linkId: dailyPageId,
              type: 'dailyPage',
              pageName,
              userId
            })
            dailyPage.pageLink = dailyPageId
          }
        }
      }
      dailyPageRef.doc(dailyPageId).update(dailyPage)
    }
  },
  async updateLookingForwardPage  ({ rootState, dispatch }, { teamId, lookingForwardPageId, lookingForwardPage, saveComment }) {
    // console.log(lookingForwardPageId, lookingForwardPage, saveComment)
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // eslint-disable-next-line no-unused-vars
      const userId = rootState.user.uid
      // console.log('updateDailyPage', workspaceId, teamId, userId, lookingForwardPageId)
      if (lookingForwardPage.json && !saveComment) {
        // get blocks and links from json
        const blocksLinks = JSONtoBlocksLinksTasks(lookingForwardPage.json)
        lookingForwardPage.blocks = blocksLinks.blocks
        lookingForwardPage.noteBlocks = blocksLinks.noteBlocks
        lookingForwardPage.links = blocksLinks.links
        lookingForwardPage.tags = blocksLinks.tags
        lookingForwardPage.mentions = blocksLinks.mentions
        lookingForwardPage.updatedOn = new Date()
      }
      if (lookingForwardPage.docState) {
        lookingForwardPage.docState = fb.Blob.fromUint8Array(lookingForwardPage.docState)
      }
      lookingForwardPage.lastEditedBy = userId
      const lookingForwardPageRef = workspaceRef.doc(workspaceId).collection('lookingForwardPages')
      lookingForwardPageRef.doc(lookingForwardPageId).update(lookingForwardPage)
    }
  },
  async getLookingForwardPage ({ rootState, commit }, { teamId, date }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const dateStr = dateCustomFormat(date, 'YYYY-MM-DD')
      const titleDateStr = dateCustomFormat(date, 'MMMM DD, YYYY')
      const pageId = `${dateStr}-${userId}`
      const lookingForwardPageRef = workspaceRef.doc(workspaceId).collection('lookingForwardPages')
      let lookingForwardPageDoc = await lookingForwardPageRef.doc(pageId).get()
      if (!lookingForwardPageDoc.exists) {
        const lookingForwardPageInput = {
          teamId,
          date,
          dateStr,
          userId,
          userName: rootState.user.displayName,
          pageName: `${titleDateStr} ${rootState.user.displayName}`,
          html: '',
          json: '{}',
          docState: null,
          blocks: [],
          links: []
        }
        await lookingForwardPageRef.doc(pageId).set(lookingForwardPageInput)
        lookingForwardPageDoc = await lookingForwardPageRef.doc(pageId).get()
      }
      const lookingForwardPage = { id: lookingForwardPageDoc.id, ...lookingForwardPageDoc.data() }
      if (lookingForwardPage.docState) {
        lookingForwardPage.docState = lookingForwardPage.docState.toUint8Array()
      }
      return lookingForwardPage
    }
  },
  async getLookingForwardPageById ({ rootState, commit }, { pageId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const lookingForwardPageRef = workspaceRef.doc(workspaceId).collection('lookingForwardPages')
      const lookingForwardPageDoc = await lookingForwardPageRef.doc(pageId).get()
      const lookingForwardPage = { id: lookingForwardPageDoc.id, ...lookingForwardPageDoc.data() }
      if (lookingForwardPage.docState) {
        lookingForwardPage.docState = lookingForwardPage.docState.toUint8Array()
      }
      return lookingForwardPage
    }
  },
  async getDailyPageById ({ rootState, commit }, { pageId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const dailyPageRef = workspaceRef.doc(workspaceId).collection('dailyPages')
      const dailyPageDoc = await dailyPageRef.doc(pageId).get()
      const dailyPage = { id: dailyPageDoc.id, ...dailyPageDoc.data() }
      if (dailyPage.docState) {
        dailyPage.docState = dailyPage.docState.toUint8Array()
      }
      return dailyPage
    }
  },
  async getDailyPage ({ rootState, commit }, { teamId, date }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const dateStr = dateCustomFormat(date, 'YYYY-MM-DD')
      const titleDateStr = dateCustomFormat(date, 'MMMM DD, YYYY')
      const pageId = `${dateStr}-${userId}`
      // console.log('store.getDailyPage', teamId, date, workspaceId, userId, dateStr, pageId)
      const dailyPageRef = workspaceRef.doc(workspaceId).collection('dailyPages')
      const dailyPageType = 'daily'
      let dailyPageDoc = await dailyPageRef.doc(pageId).get()
      if (!dailyPageDoc.exists) {
        const dailyPageInput = {
          teamId,
          date,
          dateStr,
          pageName: titleDateStr,
          userId,
          dailyPageType,
          userName: rootState.user.displayName,
          html: '',
          json: '{}',
          docState: null,
          blocks: [],
          links: [],
          lastEditedBy: userId
        }
        await dailyPageRef.doc(pageId).set(dailyPageInput)
        dailyPageDoc = await dailyPageRef.doc(pageId).get()
      }
      const dailyPage = { id: dailyPageDoc.id, ...dailyPageDoc.data() }
      if (dailyPage.docState) {
        dailyPage.docState = dailyPage.docState.toUint8Array()
      }
      return dailyPage
    }
  },
  updateNotePage ({ rootState, dispatch }, { teamId, notePageId, notePage }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      notePage.lastEditedBy = userId
      if (notePage.json) {
        // get blocks and links from json
        const blocksLinks = JSONtoBlocksLinksTasks(notePage.json)
        notePage.blocks = blocksLinks.blocks
        notePage.noteBlocks = blocksLinks.noteBlocks
        notePage.links = blocksLinks.links
        notePage.tags = blocksLinks.tags
        notePage.mentions = blocksLinks.mentions
        notePage.updatedOn = new Date()
        // console.log('updateNotePage.processTasks', teamId)
        dispatch('processTasks', { teamId, tasks: blocksLinks.tasks })
      }
      if (notePage.docState) {
        notePage.docState = fb.Blob.fromUint8Array(notePage.docState)
      }
      const notePageRef = workspaceRef.doc(workspaceId).collection('notePages')
      notePageRef.doc(notePageId).update(notePage)
    }
  },
  async getNotePageById ({ rootState }, { pageId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const notePageRef = workspaceRef.doc(workspaceId).collection('notePages')
      const notePageDoc = await notePageRef.doc(pageId).get()
      if (notePageDoc.exists) {
        const notePage = { id: notePageDoc.id, ...notePageDoc.data() }
        if (notePage.docState) {
          notePage.docState = notePage.docState.toUint8Array()
        }
        return notePage
      }
    }
  },
  async getNotePage ({ rootState, commit }, { teamId, id, notePageName, notePageLink, notePageType }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      notePageLink = notePageLink.toLowerCase()
      notePageName = notePageName.charAt(0).toUpperCase() + notePageName.slice(1).toLowerCase()
      const notePageRef = workspaceRef.doc(workspaceId).collection('notePages')
      notePageType = 'team' // Temp TODO: allow different levels
      // console.log('store.getNotePage', { teamId, notePageName, notePageLink, notePageType })
      const notePageDocs = await notePageRef
        .where('teamId', '==', teamId)
        .where('pageLink', '==', notePageLink).get()
      if (!notePageDocs.empty) {
        const notePageDoc = notePageDocs.docs[0]
        const notePage = { id: notePageDoc.id, ...notePageDoc.data() }
        if (notePage.docState) {
          notePage.docState = notePage.docState.toUint8Array()
        }
        return notePage
      } else {
        const notePageInput = {
          teamId,
          pageName: notePageName,
          pageLink: notePageLink,
          notePageType,
          created: new Date(),
          userId: rootState.user.uid,
          userName: rootState.user.displayName,
          html: '',
          json: '{}',
          docState: null,
          blocks: [],
          links: []
        }
        await notePageRef.doc(id).set(notePageInput)
        const notePageDoc = await notePageRef.doc(id).get()
        const notePage = { id: notePageDoc.id, ...notePageDoc.data() }
        // console.log('store.teams.getNotePage added', notePage)
        return notePage
      }
    }
  },
  async updateMeetingsByUid ({ rootState }, { uid, meeting }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const meetingPageRef = workspaceRef.doc(workspaceId).collection('meetings')
      const meetingPageDocs = await meetingPageRef
        .where('iCalUId', '==', uid).get()
      if (!meetingPageDocs.empty) {
        for (const meetingDoc of meetingPageDocs.docs) {
          meetingPageRef.doc(meetingDoc.id).update(meeting)
        }
      }
    }
  },
  async getNextWorkingAt ({ rootState, state }, { location }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const wc = moment().startOf('week')
      const startOfWeek = wc.toDate()

      const isLocation = (location, required) => {
        if (required === 'remote') {
          if (location === 'home' || location === 'thirdspace') {
            return true
          }
          return false
        } else {
          return required === location
        }
      }

      const scheduleRef = workspaceRef.doc(workspaceId).collection('schedules')
      const scheduleDocs = await scheduleRef.where('fromId', '==', userId)
        .where('weekCommencingDate', '>=', startOfWeek)
        .orderBy('weekCommencingDate', 'asc')
        .get()
      // console.log('store.getNextWorkingAt.scheduleDocs', location, scheduleDocs.docs.length)
      if (!scheduleDocs.empty) {
        for (const scheduleDoc of scheduleDocs.docs) {
          const schedule = { ...scheduleDoc.data(), id: scheduleDoc.id }
          const today = moment().isoWeekday()
          const daySequency = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
          if (schedule.weekCommencingDate) {
            if (wc.isSame(schedule.weekCommencingDate.toDate(), 'date')) {
              for (const day of daySequency) {
                const dayIndex = daySequency.indexOf(day) + 1
                if (today <= dayIndex) {
                  const sd = schedule.schedules[day]
                  if (sd && sd.date) {
                    if (isLocation(sd.response, location) ||
                        isLocation(sd.amResponse, location) ||
                        isLocation(sd.pmResponse, location)) {
                      // console.log('store.getNextWorkingAt schedule.currentWeek', location, schedule.schedules[day].date.toDate())
                      return schedule.schedules[day].date.toDate()
                    }
                  }
                }
              }
            } else {
              for (const day of daySequency) {
                const sd = schedule.schedules[day]
                if (sd && sd.date) {
                  if (isLocation(sd.response, location) ||
                      isLocation(sd.amResponse, location) ||
                      isLocation(sd.pmResponse, location)) {
                    // console.log('store.getNextWorkingAt schedule.futureWeek', location, schedule.schedules[day].date.toDate())
                    return schedule.schedules[day].date.toDate()
                  }
                }
              }
            }
          }
        }
      }
      // try defaults
      if (state.profileDefaultSchedules) {
        const profileDefaultSchedules = state.profileDefaultSchedules.filter((ds) => { return ds.fromId === userId })
        if (profileDefaultSchedules.length) {
          const profileDefaultSchedule = profileDefaultSchedules[0]
          // console.log('profileDefaultSchedule', profileDefaultSchedule)
          if (profileDefaultSchedule.schedules) {
            const daySequency = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
            const today = moment().isoWeekday()
            for (const day of daySequency) {
              const dsd = profileDefaultSchedule.schedules[day]
              if (dsd) {
                if (isLocation(dsd.response, location) ||
                    isLocation(dsd.amResponse, location) ||
                    isLocation(dsd.pmResponse, location)) {
                  const dayRequired = daySequency.indexOf(day) + 1
                  if (today <= dayRequired) {
                    // console.log('store.getNextWorkingAt defaultSchedule.currentWeek', location, moment().isoWeekday(dayRequired))
                    return moment().isoWeekday(dayRequired)
                  } else {
                    // console.log('store.getNextWorkingAt defaultSchedule.futureWeek', location, moment().add(1, 'weeks').isoWeekday(dayRequired))
                    return moment().add(1, 'weeks').isoWeekday(dayRequired)
                  }
                }
              }
            }
          }
        }
      }
    }
    return null
  },
  async getNextMeetingWithUser ({ rootState }, { msUserEmail, googleUserEmail }) {
    if (rootState.workspace.id) {
      const emailParams = []
      if (msUserEmail) {
        emailParams.push(msUserEmail)
      }
      if (googleUserEmail) {
        emailParams.push(googleUserEmail)
      }
      if (emailParams.length < 1) {
        return null
      }

      const profile = rootState.profile
      if (profile) {
        const mscal = profile.calendarsSelected
        const gcal = profile.googleCalendarsSelected
        if ((!mscal || mscal.length === 0) && (!gcal || gcal.length === 0)) {
          return null
        }
      }

      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const meetingRef = workspaceRef.doc(workspaceId).collection('meetings')
      const fromDate = moment().startOf('day').toDate()
      const meetingDocs = await meetingRef
        .where('userId', '==', userId)
        .where('attendeeEmails', 'array-contains-any', emailParams)
        .where('startDateTime', '>=', fromDate)
        .orderBy('startDateTime', 'asc')
        .get()

      let firstScheduledMeeting = false
      let firstScheduledMeetingTime = false
      let firstRecMeeting = false
      let firstRecMeetingTime = false

      if (!meetingDocs.empty) {
        for (const firstScheduledMeetingDoc of meetingDocs.docs) {
          const meeting = { ...firstScheduledMeetingDoc.data(), id: firstScheduledMeetingDoc.id }
          if (!meeting.ended) {
            firstScheduledMeeting = meeting
            firstScheduledMeetingTime = firstScheduledMeeting.startDateTime
            break
          }
        }
      }

      const meetingRecDocs = await meetingRef
        .where('userId', '==', userId)
        .where('attendeeEmails', 'array-contains-any', emailParams)
        .get()
      if (!meetingRecDocs.empty) {
        const meetingRecs = []
        for (const meetingRecDoc of meetingRecDocs.docs) {
          meetingRecs.push({ ...meetingRecDoc.data(), id: meetingRecDoc.id })
        }
        const result = getNextRecMeeting(fromDate, meetingRecs)

        firstRecMeeting = result.nextMeeting
        firstRecMeetingTime = result.nextMeetingDate
      }

      // console.log('getNextMeetingWithUser sch rec', emailParams, firstScheduledMeetingTime, firstRecMeetingTime)

      if (firstScheduledMeeting && firstRecMeeting) {
        if (moment(firstScheduledMeetingTime).isBefore(moment(firstRecMeetingTime))) {
          return firstScheduledMeeting
        } else {
          return firstRecMeeting
        }
      } else if (firstScheduledMeeting) {
        return firstScheduledMeeting
      } else if (firstRecMeeting) {
        return firstRecMeeting
      }
    }
    return null
  },
  updateMeetingPage ({ rootState, dispatch }, { teamId, meetingPageId, meetingPage }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      if (meetingPage.json) {
        // get blocks and links from json
        const blocksLinks = JSONtoBlocksLinksTasks(meetingPage.json)
        meetingPage.blocks = blocksLinks.blocks
        meetingPage.noteBlocks = blocksLinks.noteBlocks
        meetingPage.links = blocksLinks.links
        meetingPage.tags = blocksLinks.tags
        meetingPage.mentions = blocksLinks.mentions
        meetingPage.updatedOn = new Date()
        // console.log('updateMeetingPage.processTasks', teamId)
        dispatch('processTasks', { teamId, tasks: blocksLinks.tasks })
      }
      meetingPage.lastEditedBy = userId
      if (meetingPage.docState) {
        meetingPage.docState = fb.Blob.fromUint8Array(meetingPage.docState)
      }
      const meetingPageRef = workspaceRef.doc(workspaceId).collection('meetingPages')
      meetingPageRef.doc(meetingPageId).update(meetingPage)
    }
  },
  async getMeetingByPageId ({ rootState }, { pageId, userId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const meetingRef = workspaceRef.doc(workspaceId).collection('meetings')
      const meetingDocs = await meetingRef
        .where('meetingPageId', '==', pageId)
        .where('userId', '==', userId).get()
      if (!meetingDocs.empty) {
        const meetingDoc = meetingDocs.docs[0]
        const meeting = { id: meetingDoc.id, ...meetingDoc.data() }
        return meeting
      }
    }
    return null
  },
  async getMeetingPageById ({ rootState }, { pageId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const meetingPageRef = workspaceRef.doc(workspaceId).collection('meetingPages')
      const meetingPageDoc = await meetingPageRef.doc(pageId).get()
      if (meetingPageDoc.exists) {
        const meetingPage = { id: meetingPageDoc.id, ...meetingPageDoc.data() }
        if (meetingPage.docState) {
          meetingPage.docState = meetingPage.docState.toUint8Array()
        }
        return meetingPage
      }
    }
  },
  async getMeetingPage ({ rootState, commit }, { teamId, meetingPageName, meetingPageLink, meetingPageType, subject, when }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      meetingPageLink = meetingPageLink.toLowerCase()
      meetingPageName = meetingPageName.charAt(0).toUpperCase() + meetingPageName.slice(1).toLowerCase()
      const meetingPageRef = workspaceRef.doc(workspaceId).collection('meetingPages')
      meetingPageType = 'team' // Temp TODO: allow different levels
      const meetingPageDocs = await meetingPageRef
        .where('teamId', '==', teamId)
        .where('pageLink', '==', meetingPageLink).get()
      if (!meetingPageDocs.empty) {
        const meetingPageDoc = meetingPageDocs.docs[0]
        const meetingPage = { id: meetingPageDoc.id, ...meetingPageDoc.data() }
        if (meetingPage.docState) {
          meetingPage.docState = meetingPage.docState.toUint8Array()
        }
        return meetingPage
      } else {
        const meetingPageInput = {
          teamId,
          pageName: meetingPageName,
          pageLink: meetingPageLink,
          meetingPageType,
          created: new Date(),
          userId: rootState.user.uid,
          userName: rootState.user.displayName,
          html: '',
          json: '{}',
          docState: null,
          blocks: [],
          links: []
        }
        const meetingPageDocRef = await meetingPageRef.add(meetingPageInput)
        const meetingPageDoc = await meetingPageDocRef.get()
        const meetingPage = { id: meetingPageDoc.id, ...meetingPageDoc.data() }
        return meetingPage
      }
    }
  },
  saveSubscriptionEvent ({ rootState, commit }, { event }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      return workspaceRef.doc(workspaceId).collection('subscriptionEvents').add(event)
    }
  },
  saveDefaultSchedule ({ rootState, dispatch }, { schedule, responses }) {
    if (rootState.workspace.id) {
      // console.log('store/saveDefaultSchedule', schedule, responses)
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const defaultScheduleRef = workspaceRef.doc(workspaceId).collection('profileDefaultSchedules')
      if (schedule || responses) {
        if (Object.values(schedule).length === 0) {
          schedule = {
            fromId: userId,
            schedules: {}
          }
        }
        if (responses) {
          for (const dayIndex in Object.keys(responses)) {
            const dayAbr = getDayAbrFromIndex(dayIndex)
            const response = responses[dayIndex][0]
            // console.log('store/saveDefaultSchedule', dayAbr, response)
            schedule.schedules[dayAbr] = {
              day: dayAbr,
              type: response.type || '',
              split: (response.type && response.type === 'split') || false,
              amResponse: response.amResponse || '',
              pmResponse: response.pmResponse || '',
              response: response.response || '',
              index: response.index,
              thirdspaceLocation: response.thirdspaceLocation || '',
              officeLocation: response.officeLocation || '',
              splitThirdspaceLocation: response.splitThirdspaceLocation || '',
              splitOfficeLocation: response.splitOfficeLocation || '',
              locationType: response.locationType || '',
              locationId: response.locationId || '',
              buildingId: response.buildingId || '',
              floorId: response.floorId || '',
              desk: response.desk || ''
            }
          }
        }
        schedule.lastUpdated = new Date()
        // console.log('store/saveDefaultSchedule', schedule)
        if (schedule.id) {
          defaultScheduleRef.doc(schedule.id).update(schedule)
        } else {
          defaultScheduleRef.add(schedule)
        }
        if (!rootState.profile.defaultScheduleSubmitted) {
          dispatch('updateProfile', { profile: { defaultScheduleSubmitted: true }, noSnack: true }, { root: true })
        }
      }
    }
  },
  async saveSchedule ({ rootState, commit, dispatch }, { date, schedule, responses, saveFor, issuesResolved }) {
    if (rootState.workspace.id) {
      // console.log('store/saveSchedule', date, Object.values(schedule).length, schedule, responses)
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const scheduleRef = workspaceRef.doc(workspaceId).collection('schedules')
      if (schedule || responses) {
        if (Object.values(schedule).length === 0) {
          schedule = {
            weekCommencingDate: date,
            fromId: userId,
            schedules: {},
            confirmed: false
          }
        }
        if (schedule.weekCommencingDate) {
          // midday to avoid DST
          const wcd = schedule.weekCommencingDate.toDate ? schedule.weekCommencingDate.toDate() : schedule.weekCommencingDate
          const wsTS = moment(wcd).add('12', 'hours')
          schedule.weekCommencingDateStr = wsTS.format('YYYY-MM-DD')
        }
        if (responses) {
          for (const dayIndex in Object.keys(responses)) {
            const dayAbr = getDayAbrFromIndex(dayIndex)
            const response = responses[dayIndex][0]
            schedule.schedules[dayAbr] = {
              day: dayAbr,
              date: response.date,
              type: response.type || '',
              split: (response.type && response.type === 'split') || false,
              amResponse: response.amResponse || '',
              pmResponse: response.pmResponse || '',
              response: response.response || '',
              index: response.index,
              submittedOn: response.submittedOn || '',
              thirdspaceLocation: response.thirdspaceLocation || '',
              officeLocation: response.officeLocation || '',
              splitThirdspaceLocation: response.splitThirdspaceLocation || '',
              splitOfficeLocation: response.splitOfficeLocation || '',
              editedOn: response.editedOn || '',
              locationType: response.locationType || '',
              locationId: response.locationId || '',
              buildingId: response.buildingId || '',
              floorId: response.floorId || '',
              desk: response.desk || ''
            }
            if (response.issueResolved) {
              schedule.schedules[dayAbr].issueResolved = true
            }
            if (response.desk) {
              dispatch('saveDeskActivity', { date: response.date, location: response.locationId, desk: response.desk })
            } else {
              // Clear previous deskActivity (if it exists)
              const scheduleDate = moment(response.date).format('YYYY-MM-DD')
              const daKey = `${scheduleDate}-${userId}`
              await workspaceRef.doc(workspaceId).collection('deskActivity').doc(daKey).delete()
            }
          }
        }
        schedule.lastUpdated = new Date()
        if (issuesResolved) {
          // All Issues resolved past confirmation date
          schedule.issuesResolved = new Date()
          schedule.confirmed = true
          schedule.confirmedDate = new Date()
        }

        if (schedule.id) {
          scheduleRef.doc(schedule.id).update(schedule)
          // save history
          if (saveFor !== 'location') {
            const responseHistoryRef = workspaceRef.doc(workspaceId).collection('scheduleHistory')
            const history = { ...schedule }
            delete history.id
            history.updateDate = new Date()
            responseHistoryRef.add(history)
          }
        } else {
          // only set submittedOn date for initial submit
          schedule.submittedOn = new Date()
          scheduleRef.add(schedule)
        }
      }
    }
  },
  async loadWeeksDeskActivity ({ rootState, commit }, { weekCommencingDate }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const deskRef = workspaceRef.doc(workspaceId).collection('deskActivity')
      const activeDeskRef = deskRef.where('weekCommencingDate', '==', weekCommencingDate)
      if (deskActivityListener) {
        deskActivityListener()
      }
      deskActivityListener = activeDeskRef
        .onSnapshot((querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (doc) => {
            const res = { ...doc.data(), id: doc.id }
            results.push(res)
          })
          commit('setDeskActivity', results)
        }, (error) => {
          console.error('store.teams.loadWeeksDeskActivity deskActivityListener', error)
        })
    }
  },
  async setScheduleLocationDesk ({ rootState, dispatch, state }, { locationId, buildingId, floorId, officeLocation, date, desk }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const dateDay = moment(date).format('ddd').toUpperCase()
      const scheduleRef = workspaceRef.doc(workspaceId).collection('schedules')
      let scheduleResponse = []
      const wsTS = getWC(moment(date).toDate())
      const weekCommencingDateStr = moment(wsTS).format('YYYY-MM-DD')
      let loadedScheduleWeek = -1
      if (this.state.schedules && this.state.schedules.length) {
        loadedScheduleWeek = this.state.schedules[0].weekCommencingDate.toDate()
      }

      console.log('setScheduleLocationDesk', { userId, locationId, buildingId, floorId, officeLocation, date, desk }, loadedScheduleWeek, isSameWeek(date, loadedScheduleWeek))
      if (isCurrentWeek(date)) {
      // current week
        scheduleResponse = [...state.currentSchedules].filter(sc => {
          return sc.fromId === userId && sc.weekCommencingDateStr === weekCommencingDateStr
        })
        console.log('setScheduleLocationDesk.current', scheduleResponse)
      } else if (isSameWeek(date, loadedScheduleWeek)) {
      // loaded week
        scheduleResponse = [...state.schedules].filter(sc => {
          return sc.fromId === userId && sc.weekCommencingDateStr === weekCommencingDateStr
        })
        console.log('setScheduleLocationDesk.loaded', scheduleResponse)
      } else {
        const scheduleDocs = await scheduleRef
          .where('weekCommencingDateStr', '==', weekCommencingDateStr)
          .where('fromId', '==', userId)
          .get()

        for (const scheduleDoc of scheduleDocs.docs) {
          scheduleResponse.push({ ...scheduleDoc.data(), id: scheduleDoc.id })
        }
        console.log('setScheduleLocationDesk.query', scheduleResponse)
      }

      if (scheduleResponse.length > 0) {
        console.log('setScheduleLocationDesk.updateSchedule')

        // update schedule
        const scheduleToUpdate = scheduleResponse[0]
        let scheduleDay = scheduleToUpdate.schedules[dateDay]
        scheduleDay = {
          ...scheduleDay,
          locationId,
          buildingId,
          floorId,
          desk,
          officeLocation,
          type: 'full',
          split: false,
          response: 'office',
          editedOn: new Date()
        }
        scheduleToUpdate.schedules[dateDay] = scheduleDay
        scheduleToUpdate.lastUpdated = new Date()
        console.log('setScheduleLocationDesk.updateSchedule', scheduleToUpdate.schedules)
        await scheduleRef.doc(scheduleToUpdate.id).update(scheduleToUpdate)
      } else {
        // unsaved schedule
        console.log('setScheduleLocationDesk.newSchedule', dateDay)
        const scheduleToSave = {
          confirmed: false,
          fromId: userId,
          submittedOn: new Date(),
          partialSubmit: true,
          weekCommencingDate: wsTS,
          weekCommencingDateStr,
          default: false
        }
        const scheduleDay = {
          date,
          locationId,
          buildingId,
          floorId,
          desk,
          officeLocation,
          type: 'full',
          split: false,
          response: 'office',
          editedOn: new Date()
        }
        const schedules = {}
        schedules[dateDay] = scheduleDay
        scheduleToSave.schedules = schedules
        console.log('setScheduleLocationDesk.newSchedule', scheduleToSave.schedules)
        await scheduleRef.add(scheduleToSave)
      }

      // check location
      const location = state.locations.filter(loc => loc.id === locationId)[0]
      const assignedDesks = location.assignedDesks || false
      if (assignedDesks) {
        dispatch('saveDeskActivity', { date, location: locationId, desk })
      } else {
        dispatch('saveDeskActivity', { date, location: locationId, desk: '' })
      }
    }
  },
  async saveDeskActivity ({ rootState }, { date, location, desk }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const weekCommencingDateMom = moment(date).startOf('isoWeek')
      const weekCommencingDate = weekCommencingDateMom.format('YYYY-MM-DD')
      const dateString = moment(date).format('YYYY-MM-DD')
      const UsersDeskRef = workspaceRef.doc(workspaceId).collection('deskActivity').doc(dateString + '-' + userId)
      await UsersDeskRef.set({ userId, location, date, weekCommencingDate, desk, lastUpdated: new Date() })
    }
  },
  async saveUserLocation ({ rootState }, { date, location }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split('T')[0]
      const UsersLocRef = workspaceRef.doc(workspaceId).collection('userLocation').doc(userId + '-' + dateString)
      UsersLocRef.set({ userId, location, date })
    }
  },
  async getUserLocations ({ rootState, state, commit }) {
    if (rootState.workspace.id) {
      if (state.usersAtLocation) {
        return state.usersAtLocation
      }
      const results = []
      const workspaceId = rootState.workspace.id
      const UsersLocRef = workspaceRef.doc(workspaceId).collection('usersAtLocation')
      const oneYearAgo = moment().subtract(12, 'month').format('YYYY-MM-DD')
      const locQuery = UsersLocRef.where('location', '==', 'office').where('dateStr', '>=', oneYearAgo)
      const locDocs = await locQuery.orderBy('dateStr', 'desc').get()
      if (!locDocs.empty) {
        for (const locDoc of locDocs.docs) {
          const ul = { ...locDoc.data(), id: locDoc.id }
          results.push(ul)
        }
        commit('setUserLocations', results)
      }
      return results
    }
    return null
  },
  async getLastSameLocation ({ rootState }, { users }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const UsersLocRef = workspaceRef.doc(workspaceId).collection('usersAtLocation')
      const oneMonthAgo = moment().subtract(1, 'month').format('YYYY-MM-DD')
      const includesAll = (arr, target) => target.every(v => arr.includes(v))
      const lastLocQuery = UsersLocRef.where('location', '==', 'office').where('dateStr', '>=', oneMonthAgo)
      const lastLocDocs = await lastLocQuery.orderBy('dateStr', 'desc').get()
      if (!lastLocDocs.empty) {
        for (const lastLocDoc of lastLocDocs.docs) {
          const ul = { ...lastLocDoc.data(), id: lastLocDoc.id }
          if (ul.users) {
            if (includesAll(Object.keys(ul.users), users)) {
              // console.log('store.teams.getLastSameLocation', ul)
              if (ul.recordDate) {
                return ul.recordDate.toDate()
              } else {
                return moment(ul.dateStr).toDate()
              }
            }
          }
        }
      }
    }
    return null
  },
  async getCurrentSchedules ({ rootState, commit }, { teamId, date }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const scheduleRef = workspaceRef.doc(workspaceId).collection('tempoRequests')
      const results = []
      const scheduleDocs = await scheduleRef
        .where('from', '>=', date)
        .get()
      if (!scheduleDocs.empty) {
        for (const scheduleDoc of scheduleDocs.docs) {
          results.push({ ...scheduleDoc.data(), id: scheduleDoc.id })
        }
      }
      return results
    }
    return []
  },
  async loadAllDefaultSchedules ({ rootState, commit }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const scheduleRef = workspaceRef.doc(workspaceId).collection('profileDefaultSchedules')
      if (defaultScheduleListener) {
        defaultScheduleListener()
      }
      defaultScheduleListener = scheduleRef
        .onSnapshot((querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (doc) => {
            const res = { ...doc.data(), id: doc.id }
            results.push(res)
          })
          commit('setDefaultSchedules', results)
        }, (error) => {
          console.error('store.teams.loadAllDefaultSchedules defaultScheduleListener', error)
        })
    }
  },
  async getProfileDefaultSchedules ({ rootState, commit }, { fromId }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const scheduleRef = workspaceRef.doc(workspaceId).collection('profileDefaultSchedules')
      const results = []
      const scheduleDocs = await scheduleRef
        .where('fromId', '==', fromId)
        .get()
      if (!scheduleDocs.empty) {
        for (const scheduleDoc of scheduleDocs.docs) {
          results.push({ ...scheduleDoc.data(), id: scheduleDoc.id })
        }
      }
      return results
    }
    return []
  },
  async saveProfileDefaultSchedules ({ rootState, commit, dispatch }, { schedule }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // console.log('saveProfileDefaultSchedules', workspaceId)
      const scheduleRef = workspaceRef.doc(workspaceId).collection('profileDefaultSchedules')
      dispatch('updateProfile', { profile: { defaultScheduleSubmitted: true }, noSnack: true }, { root: true })
      if (schedule.id) {
        return scheduleRef.doc(schedule.id).update(schedule)
      } else {
        return scheduleRef.add(schedule).then((doc) => {
          return { ...schedule, id: doc.id }
        })
      }
    }
    return {}
  },
  async saveTopicsAndPeople ({ rootState }, { preferences }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // console.log('saveTopicsAndPeople', workspaceId, preferences.id)
      const preferencesRef = workspaceRef.doc(workspaceId).collection('topicsPeoplePreferences')
      if (preferences.fromDate) {
        const fromDateObj = preferences.fromDate ? preferences.fromDate.toDate ? preferences.fromDate.toDate() : preferences.fromDate : preferences.fromDate
        // console.log('store.teams.saveTopicsAndPeople', fromDateObj)
        const dateStr = moment(fromDateObj).format('YYYY-MM-DD')
        preferences.dateStr = dateStr
      }

      if (preferences && preferences.id) {
        return preferencesRef.doc(preferences.id).update(preferences)
      } else {
        return preferencesRef.add(preferences).then((doc) => {
          return { ...preferences, id: doc.id }
        })
      }
    }
    return {}
  },
  async clearUserScheduleSeenState ({ rootState }, { preferenceId, userIds }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const preferencesDoc = await workspaceRef.doc(workspaceId)
        .collection('topicsPeoplePreferences')
        .doc(preferenceId)
        .get()
      if (preferencesDoc.exists) {
        const preferences = { ...preferencesDoc.data(), id: preferencesDoc.id }
        const userDailyLocation = { ...preferences.userDailyLocation }
        for (const user in preferences.userDailyLocation) {
          if (userIds.includes(user)) {
            for (const day in preferences.userDailyLocation[user]) {
              userDailyLocation[user][day].seen = true
            }
          }
        }
        preferencesDoc.ref.update({ userDailyLocation })
      }
    }
  },
  updateCurrentUserRecommendation ({ rootState }, { preferenceId, recommendationId, recommendation }) {
    if (rootState.workspace.id) {
      // console.log('store.teams.updateCurrentUserRecommendation', { preferenceId, recommendationId, recommendation })
      const workspaceId = rootState.workspace.id
      return workspaceRef.doc(workspaceId)
        .collection('topicsPeoplePreferences')
        .doc(preferenceId)
        .collection('currentUserSuggestions')
        .doc(recommendationId)
        .update(recommendation)
    }
  },
  async loadHistorySchedules ({ rootState, commit }, { weekCommencingDate }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const wsTS = moment(weekCommencingDate).add('12', 'hours')
      const weekCommencingDateStr = wsTS.format('YYYY-MM-DD')
      const scheduleResponseRef = workspaceRef.doc(workspaceId)
        .collection('scheduleHistory')
        .where('weekCommencingDateStr', '==', weekCommencingDateStr)
      if (historyScheduleListener) {
        historyScheduleListener()
      }
      historyScheduleListener = scheduleResponseRef
        .onSnapshot((querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (doc) => {
            const res = { ...doc.data(), id: doc.id }
            results.push(res)
          })
          commit('setHistorySchedules', results)
        }, (error) => {
          console.error('store.teams.loadHistorySchedules historyScheduleListener', error)
        })
    }
  },
  loadMiniSchedules ({ rootState, state, commit }, { weekCommencingDate }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const wsTS = moment(weekCommencingDate).add('12', 'hours')
      const weekCommencingDateStr = wsTS.format('YYYY-MM-DD')
      const scheduleRef = workspaceRef.doc(workspaceId)
        .collection('schedules')
        .where('weekCommencingDateStr', '==', weekCommencingDateStr)
      if (miniScheduleListener) {
        miniScheduleListener()
      }
      miniScheduleListener = scheduleRef
        .onSnapshot(async (querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (scheduleDoc) => {
            results.push({ ...scheduleDoc.data(), id: scheduleDoc.id })
          })
          commit('setMiniSchedules', results)
        })
    }
  },
  loadSchedules ({ rootState, state, commit, dispatch }, { weekCommencingDate }) {
    // console.log('teams/loadSchedules', weekCommencingDate)
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const wsTS = moment(weekCommencingDate).add('12', 'hours')
      const weekCommencingDateStr = wsTS.format('YYYY-MM-DD')
      const isCurrent = weekCommencingDateStr === state.schedulesCurrentDateStr
      const isLoaded = weekCommencingDateStr === state.loadedSchedulesDateStr
      // console.log('store.teams.loadSchedules', weekCommencingDateStr, isCurrent, state.schedulesCurrentDateStr, isLoaded, state.loadedSchedulesDateStr, state.currentSchedules)

      // Week schedules already loaded
      if (isLoaded) {
        return
      }

      // If loading current use current
      if (isCurrent) {
        // stop scheduleListener
        if (scheduleListener) {
          scheduleListener()
        }
        commit('setSchedules', { results: state.currentSchedules, dateStr: weekCommencingDateStr })
      } else {
        // stop scheduleListener
        if (scheduleListener) {
          scheduleListener()
        }
        const scheduleRef = workspaceRef.doc(workspaceId)
          .collection('schedules')
          .where('weekCommencingDateStr', '==', weekCommencingDateStr)
        if (scheduleListener) {
          scheduleListener()
        }
        scheduleListener = scheduleRef
          .onSnapshot(async (querySnapshot) => {
            const results = []
            querySnapshot.forEach(async (scheduleDoc) => {
              results.push({ ...scheduleDoc.data(), id: scheduleDoc.id })
            })
            commit('setSchedules', { results, dateStr: weekCommencingDateStr })
          })
      }
      dispatch('loadInterest', { weekCommencingDate: weekCommencingDateStr })
      dispatch('loadWeeksDeskActivity', { weekCommencingDate: weekCommencingDateStr })
    }
  },
  loadInterest ({ rootState, commit }, { weekCommencingDate }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid

      if (interestListener) { interestListener() }
      const interestRef = workspaceRef.doc(workspaceId)
        .collection('topicsPeoplePreferences')
        .where('dateStr', '==', weekCommencingDate)
        .where('teamMatesId', 'array-contains', userId)
      interestListener = interestRef
        .onSnapshot(async (querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (interestDoc) => {
            const interest = { ...interestDoc.data(), id: interestDoc.id }
            const currentUserSuggestionRef = interestDoc.ref.collection('currentUserSuggestions').doc(userId)
            const userSuggestionDoc = await currentUserSuggestionRef.get()
            if (userSuggestionDoc.exists) {
              const userSuggestion = { ...userSuggestionDoc.data(), id: userSuggestionDoc.id }
              interest.userSuggestions = {}
              interest.userSuggestions[userId] = userSuggestion
            }
            results.push(interest)
          })
          commit('setInterest', results)
        })
    }
  },
  loadCurrentSchedules ({ rootState, state, commit }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const weekCommencingDate = getWC(new Date())
      const wsTS = moment(weekCommencingDate).add('12', 'hours')
      const weekCommencingDateStr = wsTS.format('YYYY-MM-DD')
      // console.log('store.teams.loadCurrentSchedules', weekCommencingDateStr)

      const scheduleRef = workspaceRef.doc(workspaceId)
        .collection('schedules')
        .where('weekCommencingDateStr', '==', weekCommencingDateStr)
      if (currentScheduleListener) {
        currentScheduleListener()
      }
      currentScheduleListener = scheduleRef
        .onSnapshot(async (querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (scheduleDoc) => {
            results.push({ ...scheduleDoc.data(), id: scheduleDoc.id })
          })
          commit('setCurrentSchedules', { results, dateStr: weekCommencingDateStr })
          if (!state.loadedSchedulesDateStr || weekCommencingDateStr === state.loadedSchedulesDateStr) {
            commit('setSchedules', { results, dateStr: weekCommencingDateStr })
          }
        })
    }
  },
  loadCurrentTopicsAndPeople ({ rootState, state, commit }, { userId }) {
    if (rootState.workspace.id) {
      // console.log('store.teams.loadCurrentTopicsAndPeople', userId)
      const workspaceId = rootState.workspace.id
      const weekFromDate = getWC(new Date())
      const preferencesRef = workspaceRef.doc(workspaceId)
        .collection('topicsPeoplePreferences')
        .where('fromDate', '==', weekFromDate)
        .where('userId', '==', userId)
        .limit(1)
      if (currentTopicsAndPeopleListener) {
        currentTopicsAndPeopleListener()
      }
      currentTopicsAndPeopleListener = preferencesRef
        .onSnapshot(async (querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (prefrenceDoc) => {
            results.push({ ...prefrenceDoc.data(), id: prefrenceDoc.id })
          })
          commit('setCurrentTopicsAndPeople', results)
        })
    }
  },
  async loadPreferencesForPastRecommendations ({ rootState, commit, dispatch }, { userId, date, type }) {
    if (type !== 'recur') {
      emptyPrefCheck = 0
    }
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const weekFromDate = getWC(date)
      const previousWeek = moment(weekFromDate).utc().subtract(7, 'days').toDate()
      const preferencesRef = await workspaceRef.doc(workspaceId)
        .collection('topicsPeoplePreferences')
        .where('userId', '==', userId)
        .where('fromDate', '==', previousWeek)
        .limit(1)
      const prefDoc = await preferencesRef.get()
      if (prefDoc.empty) {
        if (emptyPrefCheck <= 5) {
          dispatch('loadPreferencesForPastRecommendations', { userId, date: previousWeek, type: 'recur' })
          emptyPrefCheck++
        }
        if (emptyPrefCheck >= 5) {
          commit('setPastPrefRec', null)
        }
      } else {
        preferencesRef.onSnapshot(async (querySnapshot) => {
          let result = null
          querySnapshot.forEach(async (preferenceDoc) => {
            const currentInitiativeSuggestionRef = preferenceDoc.ref.collection('currentInitiativeSuggestions')
            const currentUserSuggestionRef = preferenceDoc.ref.collection('currentUserSuggestions')
            const currentInitSuggestionsDocs = await currentInitiativeSuggestionRef.get()
            const currentUserSuggestionsDocs = await currentUserSuggestionRef.get()
            const initSuggestions = {}
            const userSuggestions = {}
            if (!currentInitSuggestionsDocs.empty) {
              for (const sug of currentInitSuggestionsDocs.docs) {
                const suggestion = sug.data()
                initSuggestions[sug.id] = { ...suggestion, id: sug.id }
              }
            }
            if (!currentUserSuggestionsDocs.empty) {
              for (const sug of currentUserSuggestionsDocs.docs) {
                const suggestion = sug.data()
                userSuggestions[sug.id] = { ...suggestion, id: sug.id }
              }
            }
            if (Object.keys(initSuggestions).length === 0 && Object.keys(userSuggestions).length === 0) {
              dispatch('loadPreferencesForPastRecommendations', { userId, date: previousWeek, type: 'recur' })
            } else {
              result = { ...preferenceDoc.data(), id: preferenceDoc.id, initSuggestions, userSuggestions }
            }
            commit('setPastPrefRec', result)
          })
        })
      }
    }
  },
  loadTopicsAndTeamMatesPreferences ({ rootState, state, commit }, { userId, fromDate, from }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const loadedWC = state.preferencesWeekCommencing
      // console.log('store.teams.loadTopicsAndTeamMatesPreferences', userId, fromDate, loadedWC, fromDate === loadedWC)

      if (fromDate !== loadedWC) {
        const preferencesRef = workspaceRef.doc(workspaceId)
          .collection('topicsPeoplePreferences')
          .where('userId', '==', userId)
          .where('fromDate', '==', fromDate)

        if (preferenceListener) {
          preferenceListener()
        }
        preferenceListener = preferencesRef
          .onSnapshot(async (querySnapshot) => {
            const results = []
            const fromParam = from
            let prefSet = false
            if (querySnapshot.size === 0) {
              commit('setPreferences', { results, fromParam }) /* setting the empty preference (need for suggestions navigation) */
            } else {
              querySnapshot.forEach(async (preferenceDoc) => {
                if (!prefSet) {
                  const currentInitiativeSuggestionRef = preferenceDoc.ref.collection('currentInitiativeSuggestions')
                  const currentUserSuggestionRef = preferenceDoc.ref.collection('currentUserSuggestions')
                  const currentInitSuggestionsDocs = await currentInitiativeSuggestionRef.get()
                  const currentUserSuggestionsDocs = await currentUserSuggestionRef.get()
                  const initSuggestions = {}
                  const userSuggestions = {}
                  if (!currentInitSuggestionsDocs.empty) {
                    for (const sug of currentInitSuggestionsDocs.docs) {
                      const suggestion = sug.data()
                      initSuggestions[sug.id] = { ...suggestion, id: sug.id }
                    }
                  }
                  if (!currentUserSuggestionsDocs.empty) {
                    for (const sug of currentUserSuggestionsDocs.docs) {
                      const suggestion = sug.data()
                      userSuggestions[sug.id] = { ...suggestion, id: sug.id }
                    }
                  }
                  const result = { ...preferenceDoc.data(), id: preferenceDoc.id, initSuggestions, userSuggestions }
                  results.push(result)
                  const lastSD = state.preferences && state.preferences[0] ? state.preferences[0].lastSuggestionDate ? state.preferences[0].lastSuggestionDate.toDate() : false : false
                  const currentSD = result ? result.lastSuggestionDate ? result.lastSuggestionDate.toDate() : false : false
                  // console.log('teams.loadTopicsAndTeamMatesPreferences', lastSD, currentSD, currentSD > lastSD || !lastSD)
                  if (currentSD > lastSD || !lastSD) {
                    commit('setBestDaysCalculating', false)
                  }
                  commit('setPreferences', { results, fromParam, fromDate })
                  prefSet = true
                }
              })
            }
          }, (error) => {
            console.error('store.teams.loadTopicsAndTeamMatesPreferences preferenceListener', error)
          })
      }
    }
  },
  async getMembersWeekScheduleByDate ({ rootState, state, commit }, { fromId, scheduleDate }) {
    if (rootState.workspace.id) {
      let scheduleResponse = []
      if (scheduleDate) {
        const workspaceId = rootState.workspace.id
        const wsTS = getWC(moment(scheduleDate).toDate())
        const weekCommencingDateStr = moment(wsTS).format('YYYY-MM-DD')
        let loadedScheduleWeek = -1
        if (this.state.schedules && this.state.schedules.length) {
          loadedScheduleWeek = this.state.schedules[0].weekCommencingDate.toDate()
        }
        if (isCurrentWeek(scheduleDate)) {
        // current week
          scheduleResponse = [...state.currentSchedules].filter(sc => sc.fromId === fromId)
        } else if (isSameWeek(scheduleDate, loadedScheduleWeek)) {
        // loaded week
          scheduleResponse = [...state.schedules].filter(sc => sc.fromId === fromId)
        } else {
          const scheduleRef = workspaceRef.doc(workspaceId)
            .collection('schedules')
            .where('weekCommencingDateStr', '==', weekCommencingDateStr)
            .where('fromId', '==', fromId)

          const scheduleDocs = await scheduleRef.get()

          for (const scheduleDoc of scheduleDocs.docs) {
            scheduleResponse.push({ ...scheduleDoc.data() })
          }
        }
        return scheduleResponse
      }
    }
  },
  async getScheduleByDate ({ rootState, state, commit }, { teamId, fromId, scheduleDate, meetingTimeSlotAM }) {
    if (rootState.workspace.id) {
      let dayResponse = ''
      let scheduleResponse = []

      if (scheduleDate) {
        const workspaceId = rootState.workspace.id
        const dateDay = moment(scheduleDate).format('ddd').toUpperCase()
        const wsTS = getWC(moment(scheduleDate).toDate())
        const weekCommencingDateStr = moment(wsTS).format('YYYY-MM-DD')
        let loadedScheduleWeek = -1
        if (this.state.schedules && this.state.schedules.length) {
          loadedScheduleWeek = this.state.schedules[0].weekCommencingDate.toDate()
        }

        if (isCurrentWeek(scheduleDate)) {
        // current week
          scheduleResponse = [...state.currentSchedules].filter(sc => sc.fromId === fromId)
        } else if (isSameWeek(scheduleDate, loadedScheduleWeek)) {
        // loaded week
          scheduleResponse = [...state.schedules].filter(sc => sc.fromId === fromId)
        } else {
          const scheduleRef = workspaceRef.doc(workspaceId)
            .collection('schedules')
            .where('weekCommencingDateStr', '==', weekCommencingDateStr)
            .where('fromId', '==', fromId)

          const scheduleDocs = await scheduleRef.get()

          for (const scheduleDoc of scheduleDocs.docs) {
            scheduleResponse.push({ ...scheduleDoc.data() })
          }
        }

        if (scheduleResponse.length > 0) {
          const memberDatedSchedule = scheduleResponse[0].schedules
          const daySchedule = memberDatedSchedule[dateDay]
          if (daySchedule) {
            if (daySchedule.split) {
              if (meetingTimeSlotAM) {
                dayResponse = daySchedule.amResponse || ''
              } else {
                dayResponse = daySchedule.pmResponse || ''
              }
            } else {
              dayResponse = daySchedule.response || ''
            }
          } else {
            dayResponse = ''
          }
        }

        if ((!dayResponse) && state.defaultSchedules) {
          const memberDefaultSchedules = [...state.defaultSchedules].filter(schedule => schedule.fromId === fromId && schedule.teamId === teamId)
          if (memberDefaultSchedules.length > 0) {
            const memberDefaultSchedule = memberDefaultSchedules[0].schedules
            const defaultDaySchedule = memberDefaultSchedule[dateDay]
            if (defaultDaySchedule.split) {
              if (meetingTimeSlotAM) {
                dayResponse = defaultDaySchedule.amResponse || ''
              } else {
                dayResponse = defaultDaySchedule.pmResponse || ''
              }
            } else {
              dayResponse = defaultDaySchedule.response || ''
            }
          }
        }
        return dayResponse
      }
    }
    return ''
  },
  async getScheduleByRequest ({ rootState, commit }, { teamId, requestId }) {
    // Depreciated
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const scheduleRef = workspaceRef.doc(workspaceId).collection('tempoRequests')
      let scheduleType = 'Weekly'
      let dayLimit = 8
      const tempoDocs = await scheduleRef
        .where('teamId', '==', teamId)
        .where('name', '==', 'Working From')
        .limit(1)
        .get()

      if (!tempoDocs.empty) {
        const tempoDoc = tempoDocs.docs[0]
        const tempo = { id: tempoDoc.id, ...tempoDoc.data() }
        scheduleType = tempo.frequency
        if (scheduleType === 'Every other week') {
          dayLimit = 15
        }
      }
      const scheduleDocs = await scheduleRef
        .where('teamId', '==', teamId)
        .where('requestId', '==', requestId)
        .where('userId', '==', rootState.user.uid)
        .limit(1)
        .get()

      if (!scheduleDocs.empty) {
        const scheduleDoc = scheduleDocs.docs[0]
        return { id: scheduleDoc.id, ...scheduleDoc.data() }
      } else {
        let blankweek = {}
        let hasDefault = false
        if (rootState.profile.defaultSchedules) {
          if (rootState.profile.defaultSchedules[teamId]) {
            blankweek = rootState.profile.defaultSchedules[teamId]
            hasDefault = true
          }
        }
        if (!hasDefault) {
          const blankday = { remote: [], office: [], tbc: [rootState.user.uid] }
          const limit = dayLimit
          for (let index = 1; index < limit; index++) {
            blankweek[index] = { ...blankday }
          }
        }
        const wc = getWC(new Date())
        return { requestId, userId: rootState.user.uid, created: new Date(), dates: blankweek, from: wc, to: addDaysDate(wc, 7), type: scheduleType }
      }
    }
  },
  async deleteSubscriptions ({ rootState }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const subRef = fb.firestore.collection('workspaces').doc(workspaceId).collection('subscriptions')

      const subs = await subRef
        .where('userId', '==', userId)
        .where('workspace', '==', workspaceId)
        .get()
      const batch = fb.firestore.batch()
      subs.forEach(doc => {
        const sub = { ...doc.data() }
        if (sub.type !== 'Google') {
          batch.delete(doc.ref)
        }
      })
      return batch.commit()
    }
  },
  async deleteGoogleSubscriptions ({ rootState }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      const userId = rootState.user.uid
      const subRef = fb.firestore.collection('workspaces').doc(workspaceId).collection('subscriptions')

      const subs = await subRef
        .where('userId', '==', userId)
        .where('workspace', '==', workspaceId)
        .where('type', '==', 'Google')
        .get()
      const batch = fb.firestore.batch()
      subs.forEach(doc => {
        batch.delete(doc.ref)
      })
      return batch.commit()
    }
  },
  async addSubscription ({ rootState }, { expiry, msEmail, googeEmail, type, calendarId }) {
    if (rootState.workspace.id) {
      const workspace = rootState.workspace.id
      const userId = rootState.user.uid
      const created = new Date()
      const subRef = fb.firestore.collection('workspaces').doc(workspace).collection('subscriptions')
      const sub = { created, workspace, userId }
      if (expiry) {
        sub.expiry = expiry
      }
      if (msEmail) {
        sub.msEmail = msEmail
      }
      if (googeEmail) {
        sub.googeEmail = googeEmail
      }
      if (type) {
        sub.type = type
      }
      if (calendarId) {
        sub.calendarId = calendarId
      }
      return await subRef.add(sub)
    }
  },
  async loadLocations ({ rootState, commit }) {
    // console.log('load locations')
    const workspace = rootState.workspace.id
    if (workspace) {
      const locationsRef = workspaceRef.doc(workspace).collection('locations')
      if (locationsListener) {
        locationsListener()
      }
      locationsListener = locationsRef
        .onSnapshot(async (querySnapshot) => {
          const results = []
          querySnapshot.forEach(async (locationDoc) => {
            const locationDetail = { ...locationDoc.data(), id: locationDoc.id }
            if (!locationDetail.deleted) {
              results.push(locationDetail)
            }
          })
          commit('setLocations', results)
        })
    }
  },
  async saveLocations ({ rootState }, { locationId, location }) {
    if (rootState.workspace.id) {
      const workspaceId = rootState.workspace.id
      // console.log('saveLocations', workspaceId, location)
      if (location.name !== '') {
        const locationsRef = workspaceRef.doc(workspaceId).collection('locations')
        if (locationId) {
          return locationsRef.doc(locationId).update(location)
        } else {
          return locationsRef.add(location).then((doc) => {
            return { ...location, id: doc.id }
          })
        }
      }
    }
    return {}
  },
  async deleteLocations ({ rootState }, { locationId }) {
    const workspaceId = rootState.workspace.id
    const teamsRef = workspaceRef.doc(workspaceId)
    return teamsRef.collection('locations').doc(locationId).update({ deleted: true })
  }
}

const setNotePagesFromShares = (state) => {
  const allNotePageMap = {}
  if (state.notePagesByInitiative) {
    for (const initiativePages of Object.values(state.notePagesByInitiative)) {
      for (const initiativePage of initiativePages) {
        allNotePageMap[initiativePage.id] = initiativePage
      }
    }
  }
  if (state.notePagesByShareUser) {
    for (const notePage of state.notePagesByShareUser) {
      allNotePageMap[notePage.id] = notePage
    }
  }
  if (state.notePagesByUser) {
    for (const notePage of state.notePagesByUser) {
      allNotePageMap[notePage.id] = notePage
    }
  }
  state.notePages = Object.values(allNotePageMap)
}

const mutations = {
  setBestDaysCalculating: (state, value) => {
    state.bestDaysCalculating = value
  },
  setEditingSchedule: (state, value) => {
    state.editingSchedule = value
  },
  setTeams: (state, results) => {
    state.teams = results
    state.teamsLoaded = true
  },
  setTeamRoles: (state, results) => {
    state.teamRoles = results
  },
  setWorkspaceRoles: (state, results) => {
    state.workspaceRoles = results
  },
  setTeam: (state, team) => {
    state.team = team
  },
  setTeamProfiles: (state, teamProfiles) => {
    state.teamProfiles = teamProfiles
  },
  setTasks: (state, tasks) => {
    state.tasks = tasks
  },
  setTaskLists: (state, taskLists) => {
    state.taskLists = taskLists
  },
  setTasksByList: (state, tasksByList) => {
    state.tasksByList = tasksByList
  },
  setDefaultSchedules: (state, results) => {
    state.defaultSchedules = results
  },
  setHistorySchedules: (state, results) => {
    state.historySchedules = results
  },
  setInitiatives: (state, initiatives) => {
    state.initiatives = initiatives
  },
  setPersonalInitiatives: (state, initiatives) => {
    state.personalInitiatives = initiatives
    const arr = [...state.personalInitiatives, ...state.workspaceInitiatives]
    const initMap = arr.reduce(function (map, obj) {
      map[obj.id] = obj
      return map
    }, {})
    state.initiatives = Object.values(initMap)
  },
  setWorkspaceInitiatives: (state, initiatives) => {
    state.workspaceInitiatives = initiatives
    const arr = [...state.personalInitiatives, ...state.workspaceInitiatives]
    const initMap = arr.reduce(function (map, obj) {
      map[obj.id] = obj
      return map
    }, {})
    state.initiatives = Object.values(initMap)
  },
  setTempos: (state, tempos) => {
    state.tempos = tempos
  },
  setTeamNotes: (state, notes) => {
    state.teamNotes = notes
  },
  setUpdateComments: (state, comments) => {
    state.updateComments = comments
  },
  setTaskListComments: (state, comments) => {
    state.taskListComments = comments
  },
  setTaskComments: (state, comments) => {
    state.taskComments = comments
  },
  setTaskActivity: (state, activity) => {
    state.taskActivity = activity
  },
  setTaskPlans: (state, plans) => {
    state.taskPlans = plans
  },
  setTeamUpdates: (state, updates) => {
    state.teamUpdates = updates
  },
  setTeamRoundTable: (state, updates) => {
    state.teamUpdatesRoundTable = updates
  },
  setTeamLookingForward: (state, updates) => {
    state.teamUpdatesLookingForward = updates
  },
  setSaveUpdateStatus: (state, status) => {
    state.saveUpdateStatus = status
  },
  setSubscriptions: (state, subscriptions) => {
    state.subscriptions = subscriptions
  },
  setMeetings: (state, meetings) => {
    state.meetings = meetings
    const meetingWithUsers = []
    const sMeetings = state.meetings || []
    const sMeetingsRec = state.meetingsRec || []
    const allMeetings = [...sMeetings, ...sMeetingsRec]
    for (const meeting of allMeetings) {
      if (meeting.status !== 'cancelled' && !meeting.ended && (meeting.isMaster || (meeting.startDateTime && inFuture(meeting.startDateTime.toDate())))) {
        if (meeting.attendeeEmails) {
          if (!meeting.isMaster || !isEnded(meeting)) {
            for (const email of meeting.attendeeEmails) {
              if (!meetingWithUsers.includes(email)) {
                meetingWithUsers.push(email)
              }
            }
          }
        }
      }
    }
    state.usersMeeting = meetingWithUsers
    state.meetingsLoaded = true
  },
  setMeetingsRec: (state, meetings) => {
    state.meetingsRec = meetings
    const meetingWithUsers = []
    const sMeetings = state.meetings || []
    const sMeetingsRec = state.meetingsRec || []
    const allMeetings = [...sMeetings, ...sMeetingsRec]
    for (const meeting of allMeetings) {
      if (meeting.status !== 'cancelled' && !meeting.ended && (meeting.isMaster || (meeting.startDateTime && inFuture(meeting.startDateTime.toDate())))) {
        if (meeting.attendeeEmails) {
          if (!meeting.isMaster || !isEnded(meeting)) {
            for (const email of meeting.attendeeEmails) {
              if (!meetingWithUsers.includes(email)) {
                meetingWithUsers.push(email)
              }
            }
          }
        }
      }
    }
    state.usersMeeting = meetingWithUsers
  },
  setRequests: (state, requests) => {
    state.requests = requests
  },
  setNotifications: (state, notifications) => {
    state.notifications = notifications
  },
  setLastRecommendation: (state, lastRecommendation) => {
    state.lastRecommendation = lastRecommendation
  },
  setLookingForwardPages: (state, lookingForwardPages) => {
    state.lookingForwardPages = lookingForwardPages
  },
  setMeetingPages: (state, meetingPages) => {
    state.meetingPages = meetingPages
  },
  setNotePages: (state, notePages) => {
    state.notePages = notePages
  },
  setDailyPages: (state, dailyPages) => {
    state.dailyPages = dailyPages
  },
  setPageLinks: (state, pageLinks) => {
    state.pageLinks = pageLinks
  },
  setLookingForwardBlockComments: (state, noteBlockComments) => {
    state.lookingForwardBlockComments = noteBlockComments
  },
  setUserNotePages: (state, notePages) => {
    state.notePagesByUser = notePages
    setNotePagesFromShares(state)
  },
  setShareUserNotePages: (state, notePages) => {
    state.notePagesByShareUser = notePages
    setNotePagesFromShares(state)
  },
  setShareInitiativeNotePages: (state, { initiativeId, notePages }) => {
    const newNotePagesByInitiative = { ...state.notePagesByInitiative }
    newNotePagesByInitiative[initiativeId] = notePages
    state.notePagesByInitiative = newNotePagesByInitiative
    setNotePagesFromShares(state)
  },
  resetNotePages: (state) => {
    state.notePagesByInitiative = {}
    state.notePagesByShareUser = []
    state.notePagesByUser = []
  },
  setMiniSchedules: (state, schedules) => {
    state.miniSchedules = schedules
  },
  setSchedules: (state, { results, dateStr }) => {
    // console.log('store.teams.setSchedules', dateStr)
    state.schedules = results
    state.loadedSchedulesDateStr = dateStr
  },
  setInterest: (state, results) => {
    state.interest = results
  },
  setCurrentSchedules: (state, { results, dateStr }) => {
    // console.log('store.teams.setCurrentSchedules', dateStr)
    state.currentSchedules = results
    state.schedulesCurrentDateStr = dateStr
  },
  setCurrentTopicsAndPeople: (state, preferences) => {
    state.currentTopicsAndPeople = preferences
  },
  setPreferences: (state, preferences) => {
    state.preferences = preferences.results
    state.preferencesWeekCommencing = preferences.fromDate
  },
  setLocations: (state, locations) => {
    state.locations = locations
  },
  setPastPrefRec: (state, preferences) => {
    // console.log('set past pref rec', preferences)
    state.pastPrefRec = preferences
  },
  setUserLocations: (state, userLocations) => {
    state.userLocations = userLocations
  },
  setDeskActivity: (state, deskActivity) => {
    state.deskActivity = deskActivity
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  resetTeamListener,
  resetProfileListener,
  resetTeamTempoListener,
  resetWorkspaceInitiativeListener,
  resetTeamDetailsListener,
  resetTeamRolesListener,
  resetWorkspaceRolesListener,
  resetPersonalInitiativeListener,
  resetTeamNotesListener,
  resetTeamUpdatesListener,
  resetUpdateCommentListener,
  resetTaskListListener,
  resetTaskListCommentListener,
  resetTaskPlanListener,
  resetMeetingListener,
  resetMeetingRecListener,
  resetMeetingPageListener,
  resetNotePageListener,
  resetDailPageListener,
  resetPageLinkListener,
  JSONtoBlocksLinksTasks,
  resetLookingForwardBlockCommentsListener,
  resetLookingForwardPageListener,
  resetPreferenceListener,
  resetRequestListener,
  resetNotificationListener,
  resetRecommendationListener,
  resetDefaultScheduleListener,
  resetHistoryScheduleListener,
  resetCurrentScheduleListener,
  resetCurrentTopicsAndPeopleListener,
  resetScheduleListener,
  resetMiniScheduleListener,
  resetLocationsListener,
  resetSubscriptionListener,
  resetInterestListener,
  resetDeskActivityListener
}
