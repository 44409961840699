<template>
  <div class="message-area-wrapper">
    <profile-initials :profileId="profileId" :date="forDate" />
    <template v-if="allowMentions && mentions">
      <at-ta
        role="menu"
        ref="at"
        tabSelect
        style="width:100%;display:flex;"
        :members="mentions" name-key="displayName">
        <textarea
        role="textbox"
        ref="messageInput"
        class="message-area-input ta"
        @keydown.enter.exact.capture="sendMessage"
        @keydown.enter.shift.exact="addHeight"
        :placeholder="placeholderText"
        rows='1'
        v-model="typedText"
        ></textarea>
      </at-ta>
    </template>
    <textarea-autosize
      v-else
      role="textbox"
      ref="messageInput"
      class="message-area-input"
      @keydown.native.enter.exact.capture.prevent.stop="sendMessage"
      :placeholder="placeholderText"
      :rows="1"
      v-model="typedText"
      ></textarea-autosize>
      <mdc-button role="button" :aria-label="sendTextLabel" raised @click.native="sendMessage()" :disabled="typedText === '' && disableSend">
        <mdc-text tag="span">{{ sendTextLabel }}</mdc-text>
      </mdc-button>
  </div>
</template>

<script>
import ProfileInitials from './ProfileInitials.vue'
import AtTa from 'vue-at/dist/vue-at-textarea'

export default {
  name: 'MessageArea',
  components: {
    ProfileInitials,
    AtTa
  },
  props: {
    allowMentions: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: 'Type some text'
    },
    profileId: {
      type: String,
      default: ''
    },
    forDate: { // today
      type: Object,
      default: () => {
        return {}
      }
    },
    sendTextLabel: {
      type: String,
      default: 'Send'
    },
    disableSend: {
      type: Boolean,
      default: false
    },
    mentions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      typedText: ''
    }
  },
  computed: {
    mentionNames () {
      return this.mentions.map(mention => mention.displayName)
    }
  },
  methods: {
    calcHeight () {
      const ta = this.$refs.messageInput.$el || this.$refs.messageInput
      ta.style.height = 'auto'
      const taHeight = ta.scrollHeight
      ta.style.height = `${taHeight}px`
    },
    resetHeight () {
      const ta = this.$refs.messageInput
      ta.style.height = 'auto'
      ta.style.height = '27px'
    },
    addHeight () {
      const ta = this.$refs.messageInput
      ta.style.height = 'auto'
      const taHeight = ta.scrollHeight + 21
      ta.style.height = `${taHeight}px`
    },
    setFocus (val) {
      this.inputFocus = val
      this.$emit('focus', val)
    },
    getMentionsFromText (text) {
      const foundMentions = []
      for (const mention of this.mentions) {
        if (text.indexOf(`@${mention.displayName}`) >= 0) {
          foundMentions.push({
            profileId: mention.id,
            name: mention.displayName
          })
        }
      }
      return foundMentions
    },
    sendMessage (evt) {
      const self = this
      const timewait = 200
      if (this.$refs.at) {
        if (this.$refs.at.atwho) {
          // mentions open
          return true
        } else {
          // mentions closed
          if (this.$refs.messageInput) {
            const updatedValue = this.$refs.messageInput.value
            if (updatedValue !== this.typedText) {
              this.typedText = updatedValue
            }
          }
        }
      }
      if (evt) {
        evt.preventDefault()
        evt.stopPropagation()
      }
      setTimeout(() => {
        if (self.typedText !== '') {
          self.$nextTick(() => {
            const message = {
              fromId: self.profileId,
              text: self.typedText,
              mentions: self.getMentionsFromText(self.typedText)
            }
            if (this.connectionId) {
              message.connectionId = this.connectionId
            }
            self.$emit('send', message)
            self.typedText = ''
            this.resetHeight()
          })
        }
      }, timewait)
    }
  },
  watch: {
    typedText () {
      if (this.typedText) {
        this.calcHeight()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.message-area-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  textarea {
    flex: 1;
    border: none;
    line-height: 22px;
    font-size: 14px;
    margin-top: 3px;
    margin-left: 5px;
    &::placeholder {
      color: #A0A0A0;
      font-weight: 500;
    }
  }
  .message-area-input.ta {
    resize: none;
    overflow-x: hidden;
    overflow-y: auto;
    width:calc(100% - 15px);
    max-width:calc(100% - 15px);
    height:27px;
    line-height:25px
  }
  .profile-redirect {
    margin: auto;
  }
  .mdc-button {
    max-height: 30px;
    min-width: 60px;
    max-width: 70px;
    background: #4175B1;
    &.mdc-button--disabled {
      background-color: rgba(0,0,0,.12);
    }
  }

  .atwho-li {
    color:#6184ad !important;
    background: white !important;
    font-size: 14px;
  }
  .atwho-cur {
    background: #ebf3fc !important;
  }

  .grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
  }
  .grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }
  .grow-wrap > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
  }
  .grow-wrap > textarea,
  .grow-wrap::after {
    /* Identical styling required!! */
    border: 1px solid black;
    padding: 0.5rem;
    font: inherit;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }
}
</style>
