<template>
  <div class="svg-wrapper" role="presentation">
    <svg :width="width" :style="{ 'background-color': svgBgColor }" :height="height" class="progress-ring" :class="showBackground ? 'show-bg' : ''" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle :id="id" :r="cRadius" :cx="centerX" :cy="centerY" :style="{'stroke-dashoffset': calculated && strokeDashoffset, 'stroke-dasharray': calculated && strokeDasharray }" stroke-linecap="round" :stroke="strokeColor" stroke-width="5" fill="none" class="progress-ring__circle"></circle>
      <foreignObject v-if="showText" :style="{ 'text-align': tAlign, 'color': tFill, 'font-weight': tFontWeight, 'font-size': tFontSize }" :class="textClass" class="about-text" :x="tPosX" :y="tPosY" :width="tWidth" :height="tHeight">
        <tspan v-html="showText"></tspan>
      </foreignObject>
      <text v-else font-size="8" font-weight="300" :x="tPosX" :y="tPosY" :fill="tFill">
        <tspan :style="{ 'font-size': tFontSize, 'font-weight': tFontWeight }">{{ percentage }}</tspan>%
      </text>
    </svg>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SvgPercentRing',
  components: {},
  props: {
    tAlign: {
      type: String,
      default: 'center'
    },
    textClass: {
      type: String,
      default: ''
    },
    tFontWeight: {
      type: Number,
      default: 300
    },
    tFontSize: {
      type: Number,
      default: 12
    },
    tPosX: {
      type: String,
      default: ''
    },
    tPosY: {
      type: String,
      default: ''
    },
    tWidth: {
      type: Number,
      default: 10
    },
    tHeight: {
      type: Number,
      default: 10
    },
    tFill: {
      type: String,
      default: '#2B8AFB'
    },
    svgBgColor: {
      type: String,
      default: '#F9F9F9'
    },
    showText: {
      type: String,
      default: ''
    },
    strokeColor: {
      type: String,
      default: '#2B8AFB'
    },
    cRadius: {
      type: Number,
      default: 26
    },
    centerX: {
      type: Number,
      default: 29
    },
    centerY: {
      type: Number,
      default: 29
    },
    viewBox: {
      type: String,
      default: '0 0 58 58'
    },
    height: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    percentage: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: ''
    },
    showBackground: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      strokeDasharray: 0,
      strokeDashoffset: 0,
      circumference: 0,
      radius: 0,
      calculated: false
    }
  },
  beforeDestroy () {},
  created () {},
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    }),
    reset () {
      this.strokeDasharray = 0
      this.strokeDashoffset = 0
      this.circumference = 0
      this.radius = 0
      this.calculated = false
    },
    initialize () {
      this.reset()
      const circle = document?.getElementById(this.id)
      const radius = circle && circle.r && circle.r.baseVal.value
      this.radius = radius
      const circumference = radius * 2 * Math.PI
      this.circumference = circumference
      this.strokeDasharray = `${circumference} ${circumference}`
      const self = this
      setTimeout(() => {
        self.calculateStroke()
        self.calculated = true
      }, 50)
    },
    calculateStroke () {
      const offset = this.circumference - this.percentage / 100 * this.circumference
      this.strokeDashoffset = offset
    }
  },
  watch: {
    percentage: {
      handler (newP, oldP) {
        this.initialize()
      }
    }
  },
  computed: {
    ...mapState([
    ]),
    ...mapState({
    })
  }
}
</script>

<style lang="scss">
.svg-wrapper {
  display: flex;
  .progress-ring {
    border-radius: 50%;
    &.show-bg {
      background-image: url('/static/img/icons/flexibly/avatar-white.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: auto 43px;
    }
    text {
      text-anchor: middle;
    }
    circle {
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  }
}
</style>
