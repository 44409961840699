<template>
  <div class="dashboard-wrapper">
    <app-layout :dynamicStyle="dynamicStyle" :isMobile="isMobile" />
    <first-login-teams v-if="teamMode" required :firstModalOpen="firstOpen"></first-login-teams>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AppLayout from '../../components/flexibly/AppLayout'
import FirstLoginTeams from '../../components/FirstLoginTeams'

export default {
  name: 'Dashboard',
  computed: {
    ...mapState([
      'teamMode',
      'profile',
      'composerView'
    ])
  },
  components: {
    AppLayout,
    FirstLoginTeams
  },
  data () {
    return {
      firstOpen: false
    }
  },
  props: {
    desktop: {
      type: Boolean
    },
    isMobile: {
      type: Boolean
    },
    isTablet: {
      type: Boolean
    },
    isDesktopLarge: {
      type: Boolean
    },
    isTabletLarge: {
      type: Boolean
    },
    dynamicStyle: {}
  },
  methods: {
    ...mapActions([
      'setComposerView'
    ])
  },
  mounted () {
    this.$root.$emit('enableSearch', false)
    this.$emit('setNavActive', 'Planner')
    if (this.profile) {
      if (!this.profile.seenIntro) {
        this.firstOpen = true
      }
    }
  },
  activated () {
    this.$emit('setNavActive', 'Planner')
    if (this.composerView === 'default') {
      // on activated (after routing)
      this.currentView = this.composerView
      this.$root.$emit('changeComposerView', 'default')
      this.setComposerView('')
    }
  },
  watch: {
    composerView: {
      handler (newV, oldV) {
        // handle if already active page but click Journal
        if (newV === 'default') {
          this.$root.$emit('changeComposerView', 'default')
          this.setComposerView('')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.dashboard-wrapper {
  height: 100%;
  margin-bottom: 30px;
  .flex-col-layout {
    .composer-app-layout {
      .section-title {
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
