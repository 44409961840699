<template>
  <div class="signin" :class="{ 'hidden-consent': hideConsent }">
    <div class="signin-contents">
      <div v-if="stage !== 'signin'" class="page-logo">
        <img v-if="isBranded" class="signup-logo" :src="workspace.logo" :alt="workspace.name" />
        <img v-else class="signup-logo" src="/static/img/duome-logo-blacklogo.svg" alt="Duome" />
      </div>
      <transition name="fade">
        <div class="section-wrapper" v-if="stage === 'not-in-domain'" key="not-in-domain">
          <div class="signin-section not-in-domain">
            <modal-dialog v-model="openNotInDomainModal" @close="switchToSignup" disableClose class="not-in-domain-modal">
              <base-svg-icon name="duome-logo-blacklogo" logo />
              <mdc-text class="thank-you-text" typo="body2" tag="span">Thank you for your interest in DuoMe for Hybrid Teams.</mdc-text>
              <mdc-text class="explanation" typo="body2">Your email address is not authorised for access so we have added you to our waitlist and will be in contact shortly. Feel free to reach out to us at <a class="support-link" target="_blank" href="mailto:info@duome.co">info@duome.co</a></mdc-text>
              <mdc-button @click.native="gotoHomePage">Return to home page</mdc-button>
            </modal-dialog>
          </div>
        </div>
        <div class="section-wrapper" v-if="stage === 'check'" key="check">
          <div class="signin-section join-team">
            <mdc-text tag="span" typo="headline4" class="heading">Join DuoMe for Hybrid Teams {{ inviteTeamName ? 'at ' + inviteTeamName : '' }}</mdc-text>
            <mdc-text tag="span" typo="caption" class="disclaimer">By signing up you agree to the Duome <a href="https://duome.co/privacy-policy.html" target="_blank" class="link">Privacy Policy</a> and <a class="link" href="https://duome.co/terms.html" target="_blank">Terms of service</a></mdc-text>
            <mdc-textfield type="text"
              class="email-input"
              outlined
              required
              @focus="focused = true"
              @keypress.enter="checkEmail"
              placeholder="Enter your work email"
              iconOutlined
              :valid="isValid"
              v-model="email">
            </mdc-textfield>

            <mdc-body v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>
            <mdc-button class="sign-up-button" :disabled="working" unelevated @click="checkEmail">
              <div class="loader" v-if="working"></div>
              <mdc-text tag="span">Sign up</mdc-text>
            </mdc-button>

            <mdc-body typo="body2" class="signin-links">
              Already have an account? <a @click="switchToSignin" class="link">Sign in</a>
            </mdc-body>
          </div>
        </div>
        <div class="section-wrapper" v-if="stage === 'verify'" key="verify">
          <div class="signin-section verify-signin" >
              <mdc-text tag="span" typo="headline4" class="heading">Please verify your email address</mdc-text>
              <div class="social-buttons-wrapper verify-actions">
                <mdc-button class="social-button" :disabled="working" @click="verifyGoogle">
                  <img slot="leading-icon" class="leading-icon" src="/static/img/logos/google.svg">
                  <mdc-text tag="span" typo="body2">Verify with Google</mdc-text>
                </mdc-button>
                <mdc-button class="social-button" :disabled="working" @click="verifyMS">
                  <img slot="leading-icon" class="leading-icon" src="/static/img/logos/ms.svg">
                  <mdc-text tag="span" typo="body2">Verify with Microsoft</mdc-text>
                </mdc-button>
              </div>
              <mdc-body typo="body2" class="or">
                <mdc-body typo="body1" tag="span">or</mdc-body>
              </mdc-body>
              <div v-if="!emailVerifySent" class="verify-action">
                <mdc-button class="verify-button" :disabled="working && emailVerifySent" @click="sendVerify">
                  <mdc-text typo="body2" v-if="working">Sending...</mdc-text>
                  <mdc-text typo="body2" v-else>Verify via Email</mdc-text>
                </mdc-button>
              </div>
              <div v-else class="verify-otp">
                <mdc-text tag="span" typo="body2">Verification code</mdc-text>
                <mdc-textfield
                  @keypress.enter="verify"
                  required
                  outlined
                  iconOutlined
                  :valid="isValid"
                  helptext-persistent
                  v-model="vcode">
                </mdc-textfield>
                <mdc-body v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>
                <mdc-button :disabled="working" unelevated @click="verify">
                  <div class="loader" v-if="working"></div>
                  <mdc-text tag="span">Verify</mdc-text>
                </mdc-button>
              </div>
              <div class="verify-send-again" v-if="emailVerifySent">
                <mdc-text tag="span" typo="body2">Check your inbox for a verification email</mdc-text>
                <mdc-text tag="span" typo="body2">Didn’t receive it? <a class="link" @click="verifyResend">Send again</a></mdc-text>
              </div>
              <a class="back-link" @click="stage='check'">GO BACK</a>
          </div>
        </div>
        <div class="section-wrapper" v-if="stage === 'detail'" key="detail">
          <div class="signin-section left-section details-info" >
            <mdc-text tag="span" typo="headline2" class="heading">
              Welcome to DuoMe. <br/>
              Tell us more about yourself
            </mdc-text>

            <div class="details-info-inputs-wrapper">
              <div class="input-fullname input-type">
                <mdc-text tag="span" typo="body2">Full name</mdc-text>
                <mdc-textfield
                  @keypress.enter="signupPassword"
                  required
                  outlined
                  iconOutlined
                  :valid="isValid"
                  helptext-persistent
                  v-model="fullname">
                </mdc-textfield>
              </div>

              <div class="input-password input-type">
                <mdc-text tag="span" typo="body2">Password</mdc-text>
                <mdc-text tag="span" typo="body2" class="password-helptext">Password must have atleast 8 characters</mdc-text>
                <mdc-textfield
                  @keypress.enter="signupPassword"
                  @focus="focused = true"
                  type="password"
                  required minlength=8
                  outlined
                  iconOutlined
                  :valid="isValid"
                  helptext-persistent
                  v-model="password">
                </mdc-textfield>
              </div>
              <div class="details-feature-mail">
                <mdc-checkbox :valid="emailFeatures" v-model="emailFeatures"/>
                <mdc-text tag="span" typo="caption">Get feature updates and tips via email (recommended)</mdc-text>
              </div>
            </div>
              <mdc-body v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>

              <mdc-button class="next-button" :disabled="working" unelevated @click="signupPassword">
                <div class="loader" v-if="working"></div>
                <mdc-text tag="span">Next</mdc-text>
              </mdc-button>
          </div>
          <div v-if="showRight" key="right" class="right-section">
            <div class="right-info"></div>
          </div>
        </div>

        <div class="section-wrapper" v-if="stage === 'status'" key="status">
          <div class="signin-section left-section request-sent">
            <div v-if="myRequests.length" key="requests" class="requests">
              <div v-for="request in myRequests" class="invite" style="padding-bottom:20px;margin-bottom:20px;border-bottom:1px solid silver" :key="request.id">
                <h2 style="margin-bottom:20px;width: 400px;text-align: left;">
                  {{request.teamName}} - Join request awaiting approval
                </h2>
                <mdc-text tag="span" typo="body2" class="requested-detail">
                    A request to join this team has been sent,
                    the team admin will be able to view and approve this request
                    after which you will be notified by email.
                </mdc-text>
              </div>
            </div>
            <div v-if="myInvites.length" key="invites" class="invites">
              <div v-for="invite in myInvites" class="invite" style="padding-bottom:20px;margin-bottom:20px;border-bottom:1px solid silver" :key="invite.id">
              <h2 style="margin-bottom:20px;width: 400px;text-align: left;">
                {{invite.teamName}} - Invitation to join team
              </h2>
              <mdc-body style="margin-bottom:20px;" typo="body2">
                You have been invited to join {{invite.teamName}}. Please confirm you wish to join this team.
              </mdc-body>
              <mdc-button class="join-create-team confirm" :disabled="working" unelevated @click="selectInvite(invite.id)">
                <div class="loader" v-if="working"></div>
                <mdc-text tag="span" typo="body1">Confirm</mdc-text>
              </mdc-button>
              </div>
            </div>
            <div v-if="continueAllowed">
                <h2 style="margin-bottom:20px;width: 400px;text-align: left;">
                  Join or create a new Team
                </h2>
                <mdc-body v-if="myRequests.length || myInvites.length" style="margin-bottom:20px;" typo="body2">
                  Alternatively you may create or request to join another team
                </mdc-body>
                <mdc-body v-else style="margin-bottom:20px;" typo="body2">
                  To complete you signup please continue to create or join a team.
                </mdc-body>
                <mdc-button :disabled="working" unelevated @click="joinOrCreate" class="join-create-team">
                  <div class="loader" v-if="working"></div>
                  <mdc-text tag="span" typo="body1">Join or Create team</mdc-text>
                </mdc-button>
            </div>
            <div v-else-if="notInDomain">
              <h2 style="margin-bottom:20px;width: 400px;text-align: left;">
                  Join or create a new Team
              </h2>
              <mdc-body style="margin-bottom:20px;" typo="body2">
                We are currently operating a wait list for access and will get in touch with you to provide access soon.
              </mdc-body>
            <mdc-button :disabled="true" class="join-create-team">Join or Create Team</mdc-button>

            </div>
            <div v-else-if="freeDomain">
              <h2 style="margin-bottom:20px;width: 400px;text-align: left;">
                Join or create a new Team
              </h2>
              <mdc-body style="margin-bottom:20px;" typo="body2">
                Your account uses a public or shared email domain. Unless awaiting an invitation to join a team, please retry using you work email.
              </mdc-body>
            <mdc-button :disabled="false" @click="signout" unelevated>Sign out</mdc-button>
            </div>

          </div>
          <div class="right-section">
            <div class="right-info">
              <img src="/static/img/team-background.png" />
            </div>
          </div>
        </div>

        <div class="section-wrapper" v-if="stage === 'reason'" key="reason">
          <div class="signin-section left-section reason-options">
            <mdc-text tag="span" typo="headline2" class="heading">
              What brings you here?
            </mdc-text>
            <mdc-button @click="stage = 'create'" class="option-button">
              I’m the first from my team in DuoMe.<br>
              I want to see if this tool is right for us.
            </mdc-button>
            <mdc-button  @click="stage = 'join'" class="option-button">
              My team mates already use DuoMe.<br>
              I want to find them and join their team.
            </mdc-button>
            <span class="pending-invites-notice" v-if="myInvites.length > 0">
              <base-svg-icon name="important-tag-icon" />
              <mdc-text tag="span" typo="caption">Invite Pending</mdc-text>
            </span>

            <mdc-text tag="span" typo="caption" class="back-link" v-if="myRequests.length >= 1 || myInvites.length >= 1" @click.native="stage='status'">GO BACK</mdc-text>
          </div>
          <div v-if="showRight" key="right" class="right-section">
            <div class="right-info"></div>
          </div>
        </div>
        <div class="section-wrapper" v-if="stage === 'create'" key="create">
          <div class="signin-section left-section create-step team-name">
            <mdc-text tag="span" typo="body2" class="heading">Create a team name</mdc-text>
            <mdc-text tag="span" typo="body2" class="create-step-detail">
              Start by entering a name for your team. This will be how people find your team, so make sure it is clear, concise and recognisable.
            </mdc-text>
            <mdc-textfield
              @keypress.enter="createTeam"
              placeholder="Enter a team name"
              required
              outlined
              iconOutlined
              :valid="isValid"
              helptext-persistent
              v-model="teamName">
            </mdc-textfield>
            <mdc-body v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>
            <div class="action-buttons create-team-actions">
              <a v-if="teams" @click="stage = 'reason'" class="back-link">GO BACK</a>
              <mdc-button :disabled="working || disableCreate" unelevated @click="createTeam" class="create-team">
                <div class="loader" v-if="working"></div>
                <mdc-text tag="span" typo="body2">Create a team name</mdc-text>
              </mdc-button>
            </div>
          </div>
          <div v-if="showRight" key="right" class="right-section">
            <div class="right-info"></div>
            <img src="/static/img/team-background.png" />
          </div>
        </div>
        <div class="section-wrapper" v-if="stage === 'create2'" key="create2">
          <div class="signin-section left-section create-step team-does">
            <div v-if="isWorkspaceAdminSetup" class="workspace-admin-location-setup">
              <mdc-text tag="span" typo="body2" class="heading">Add your office locations</mdc-text>
              <mdc-text tag="span" typo="body2" class="create-step-detail">
                Start adding your office locations. Add at least one, you can add more locations at a later time.
              </mdc-text>

              <workspace-locations onboarding @locationsUpdate="locationsUpdate" />
            </div>
            <div v-else class="default-location-select">
              <mdc-text tag="span" typo="body2" class="heading">Select your default office location</mdc-text>
              <mdc-text tag="span" typo="body2" class="create-step-detail" style="margin-bottom:30px;">
                Set your default office location. You can change this and add more locations at any time.
              </mdc-text>

              <div v-if="defaultLocation" class="default-location-row">
              <div class="mdc-text-field mdc-text-field-outlined">
                <span v-html="defaultLocationText"></span>
              </div>
              <base-svg-icon :clickable="true" @click.native="clearDefaultLocation" name="cross-thin" />
              </div>
              <locations-details v-else style="height:190px;width:100%;" outlined hideActivityDetails :date="false" locationType="office" :locationList="locations" onboarding @saveOfficeLocation="setOfficeLocation" showFor="full" />
            </div>

            <div class="action-buttons team-desc-actions">
              <a @click="stage = 'create'" class="back-link">GO BACK</a>
              <div class="button-row team-does">
                <a @click="createTeamSkip" class="link">I'll do this later</a>
                <mdc-button :disabled="working || (!newTeam.defaultLocation && !isWorkspaceAdminSetup)" unelevated @click="createTeamSkip()">
                  <div v-if="working" class="loader"></div>
                  <mdc-text tag="span" typo="body2">Next</mdc-text>
                </mdc-button>
              </div>
            </div>
          </div>
          <div v-if="showRight" key="right" class="right-section">
            <div class="right-info">
              <div class="team-info" v-if="newTeam.name">
                <mdc-text tag="span" class="info-label">Your team name</mdc-text>
                <a class="link" @click="stage='create'">{{newTeam.name}}</a>
              </div>
              <div class="team-info" v-if="showDesc && (newTeam.description || newTeam.selectedDescription)">
                <mdc-text tag="span" class="info-label">What your team does</mdc-text>
                <div class="team-desc-list">
                  <!-- <a class="link" @click="stage='create2'"> {{newTeam.description}}</a> -->
                  <template v-for="(selDesc, index) in newTeam.selectedDescription">
                    <a class="link selected-desc" :key="'selDesc-'+index" @click="stage='create2'">{{selDesc + `${newTeam.selectedDescription.length !== 1 && index !== newTeam.selectedDescription.length - 1 ? ',' : ''}` }}</a>
                  </template>
                </div>
              </div>
            </div>
            <img src="/static/img/team-background.png" />
          </div>
        </div>
        <div class="section-wrapper" v-if="stage === 'create3'" key="create3">
          <div class="signin-section left-section create-step invites-members">
            <mdc-text tag="span" typo="body2" class="heading"> Invite team members to join you.</mdc-text>
            <mdc-text tag="span" typo="body2" class="create-step-detail">
              Invite them via email
            </mdc-text>
            <textarea-autosize
              :minHeight="82"
              :maxHeight="300"
              placeholder="Enter email addresses, separated by a comma"
              label="Message"
              ref="messageInput"
              v-model="emailList"
              outlined
              >
            </textarea-autosize>
            <mdc-body v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>
            <div class="action-buttons invite-email">
              <a class="back-link" @click="stage='create2'">GO BACK</a>
              <div class="button-row">
                <a @click="createTeamSkip" class="link">I'll do this later</a>
                <mdc-button class="send-invite" :disabled="working" unelevated @click="createTeamAndInvites">
                  <div v-if="working" class="loader"></div>
                  <span v-if="working && emailList">SENDING</span>
                  <span v-else>SEND INVITATION</span>
                </mdc-button>
              </div>
            </div>
          </div>
          <div v-if="showRight" key="right" class="right-section">
            <div class="right-info">
              <div v-if="newTeam.name" class="team-info">
                <mdc-text tag="span" class="info-label">Your team name</mdc-text>
                <a class="link" @click="stage='create'">{{newTeam.name}}</a>
              </div>
              <div v-if="showDesc && (newTeam.description || newTeam.selectedDescription)" class="team-info">
                <mdc-text tag="span" class="info-label">What your team does</mdc-text>
                <div class="team-desc-list">
                  <a class="link" @click="stage='create2'"> {{newTeam.description}}</a>
                  <template v-for="(selDesc, index) in newTeam.selectedDescription">
                    <a class="link selected-desc" v-if="selDesc !== newTeam.description" :key="'selDesc-'+index" @click="stage='create2'">{{selDesc + `${newTeam.selectedDescription.length !== 1 && index !== newTeam.selectedDescription.length - 1 ? ',' : ''}` }}</a>
                  </template>
                </div>
              </div>
            </div>
            <img src="/static/img/team-background.png" />
          </div>
        </div>
        <div class="section-wrapper" v-if="stage === 'join'" key="join">
          <div class="signin-section left-section team-join join1">
            <a @click="stage = 'reason'" style="margin-top:40px;" class="back-link fixed">GO BACK</a>

            <mdc-text tag="span" typo="body2" class="heading">Find and join a team</mdc-text>
            <mdc-text tag="span" typo="body2" class="team-join-detail">
              Simply enter a team or a team mate’s name, and select it from the list. Or select from teams below.
            </mdc-text>
            <mdc-textfield
              placeholder="Start typing to find a team or a team mate"
              outlined
              iconOutlined
              :valid="isValid"
              helptext-persistent
              v-model="searchTeam">
            </mdc-textfield>

            <div v-if="invitesFiltered.length || teamsFiltered.length" class="teams-list-wrapper">
              <div class="team-row"
              v-for="(inv, index) in invitesFiltered"
              :class="{'disabled': working}"
              :key="'team-row-inv-' + index">
                <div class="team-details">
                  <mdc-text tag="span" typo="body1">{{inv.teamName}}</mdc-text>
                  <mdc-text tag="span" typo="body2">{{inv.teamDescription}}</mdc-text>
                  <mdc-text tag="span" typo="caption" class="owner">{{inv.ownerName}}</mdc-text>
                </div>
                <span>
                  <mdc-button class="accept-invite" unelevated :disabled="working" @click="selectInvite(inv.id)">Accept Invite</mdc-button>
                </span>
              </div>
              <div class="team-row"
              v-for="(team, index) in teamsFiltered"
              :class="{'disabled': working}"
              :key="'team-row-' + index">
                <div v-if="team.teamId">
                  <div class="team-details" v-if="team.teamId">
                    <mdc-text tag="span" typo="body1">{{team.teamName}}</mdc-text>
                    <div v-if="team.profileDetails && team.profileDetails.length > 0" class="profiles-wrapper">
                      <template v-for="profile in team.profileDetails">
                        <profile-initials v-if="profile.photoUrl" forUser :key="profile.userId + 'onboard'" :photoURL="profile.photoUrl" />
                        <profile-initials v-else-if="profile.displayName" forUser :key="profile.userId + 'onboard'" :userInitials="getMemberInitials(profile.displayName)" />
                      </template>
                    </div>
                  </div>
                  <span v-if="teamJoinAllowed(team.teamId).state === 'allowed'">
                    <mdc-button class="request-to-join" :disabled="working" @click="selectTeam(team.teamId)" >Request to Join</mdc-button>
                  </span>
                  <span class="request-sent" v-else-if="teamJoinAllowed(team.teamId).state === 'requested'">
                    <mdc-text tag="span" typo="body2" style="color:#61ad8e">Request Sent</mdc-text>
                    <mdc-icon icon="check_circle_outline" />
                  </span>
                </div>
              </div>
            </div>
            <div v-else class="no-team">
              <mdc-body tag="span" typo="body2">Sorry no teams found. If you wish to create a team please press the Back button and select create team.</mdc-body>
            </div>
          </div>
          <div v-if="showRight" key="right" class="right-section" style="position:absolute;">
            <div class="right-info">
            </div>
            <img src="/static/img/team-background.png" />
          </div>
        </div>

        <div v-if="stage === 'requested-location'" class="default-location-select section-wrapper" key="requested-location">
          <div class="join-section left-section team-join join2">
            <mdc-text tag="span" typo="body2" class="heading">Select your default office location</mdc-text>
            <mdc-text tag="span" typo="body2" class="create-step-detail" style="margin-bottom:30px;">
              Set your default office location. You can change this and add more locations at any time.
            </mdc-text>

            <div v-if="defaultLocation" class="default-location-row">
            <div class="mdc-text-field mdc-text-field-outlined">
              <span v-html="defaultLocationText"></span>
            </div>
            <base-svg-icon :clickable="true" @click.native="clearDefaultLocation" name="cross-thin" />
            </div>
            <locations-details v-else style="height:190px;width:100%;" outlined hideActivityDetails :date="false" locationType="office" :locationList="locations" onboarding @saveOfficeLocation="setOfficeLocation" showFor="full" />

            <div class="action-buttons team-desc-actions">
              <a @click="stage = 'status'" class="back-link">GO BACK</a>
              <div class="button-row team-does">
                <a @click="sendTeamRequestSkip" class="link">I'll do this later</a>
                <mdc-button :disabled="working || !defaultLocation" class="join-team-btn" unelevated @click="sendTeamRequest">
                  <div style="left:80%" v-if="working" class="loader"></div>
                  <mdc-text tag="span" typo="body2">Next</mdc-text>
                </mdc-button>
              </div>
            </div>
          </div>
        </div>

        <div class="section-wrapper" v-if="stage === 'requested'" key="requested">
          <div class="signin-section left-section invite-requested">
            <mdc-text tag="span" typo="body2" class="heading">Team request sent</mdc-text>
            <mdc-text tag="span" typo="body2" class="requested-detail">
               A request to join the team has been sent,
              the team admin will be able to view and approve this request
              after which an invite link will be sent to your email.
            </mdc-text>
            <a @click="stage = 'status'" class="back-link">GO BACK</a>
          </div>
          <div v-if="showRight" key="right" class="right-section">
            <div class="right-info">
            </div>
            <img src="/static/img/team-background.png" />
          </div>
        </div>

        <div v-if="stage === 'join-location'" class="default-location-select section-wrapper" key="join-location">
          <div class="join-section left-section team-join join2">
            <mdc-text tag="span" typo="body2" class="heading">Select your default office location</mdc-text>
            <mdc-text tag="span" typo="body2" class="create-step-detail" style="margin-bottom:30px;">
              Set your default office location. You can change this and add more locations at any time.
            </mdc-text>

            <div v-if="defaultLocation" class="default-location-row">
            <div class="mdc-text-field mdc-text-field-outlined">
              <span v-html="defaultLocationText"></span>
            </div>
            <base-svg-icon :clickable="true" @click.native="clearDefaultLocation" name="cross-thin" />
            </div>
            <locations-details v-else style="height:190px;width:100%;" outlined hideActivityDetails :date="false" locationType="office" :locationList="locations" onboarding @saveOfficeLocation="setOfficeLocation" showFor="full" />

            <div class="action-buttons team-desc-actions">
              <a @click="stage = 'join'" class="back-link">GO BACK</a>
              <div class="button-row team-does">
                <a @click="joinFromInviteSkip" class="link">I'll do this later</a>
                <mdc-button :disabled="working || !defaultLocation" class="join-team-btn" unelevated @click="joinFromInvite">
                  <div style="left:80%" v-if="working" class="loader"></div>
                  <mdc-text tag="span" typo="body2">Next</mdc-text>
                </mdc-button>
              </div>
            </div>
          </div>
        </div>

        <div class="section-wrapper" v-if="stage === 'join-confirm'" key="join-confirm">
           <div class="signin-section left-section team-join confirm-join">
            <h2 style="margin-bottom:50px;width: 400px;text-align: left;">
              Join Team: {{team.teamName}}
            </h2>
            <mdc-body style="margin-bottom:20px;" typo="body2">
              You have been invited to join {{team.teamName}}. Please confirm you wish to join this team to complete your sign up.
            </mdc-body>
            <mdc-button :disabled="working" unelevated @click="joinTeamfromInvite" class="confirm-button">
              <div class="loader" v-if="working"></div>
              <mdc-text tag="span" typo="body2">Confirm</mdc-text>
            </mdc-button>
          </div>
          <div v-if="showRight" key="right" class="right-section">
            <div class="right-info">
            </div>
            <img src="/static/img/team-background.png" />
          </div>
        </div>
        <div class="section-wrapper" v-if="stage === 'signin'" key="signin">
          <div class="signin-section signin-form">
              <div v-if="loaded" class="signin-card" >
                <header>
                  <img v-if="isBranded" class="signup-logo" :src="workspace.logo" :alt="workspace.name" />
                  <img v-else class="signup-logo" src="/static/img/duome-logo-blacklogo.svg" alt="Duome" />
                </header>
                <div class="signin-content-wrapper">
                  <div class="social-buttons-wrapper signin-actions">
                    <mdc-button class="social-button" :disabled="working" @click="signInGoogle">
                      <img slot="leading-icon" class="leading-icon" src="/static/img/logos/google.svg">
                      <mdc-text tag="span" typo="body2">Sign in with Google</mdc-text>
                    </mdc-button>
                    <mdc-button class="social-button" :disabled="working" @click="signInMS">
                      <img slot="leading-icon" class="leading-icon" src="/static/img/logos/ms.svg">
                      <mdc-text tag="span" typo="body2">Sign in with Microsoft</mdc-text>
                    </mdc-button>
                  </div>
                  <mdc-body typo="body2" class="or">
                    <mdc-body tag="span" typo="body1">or</mdc-body>
                  </mdc-body>
                  <div class="signin-inputs-wrapper">
                    <div class="input-email signin-input">
                      <mdc-text tag="span" typo="body2">Email address</mdc-text>
                      <mdc-textfield type="text"
                        @keypress.enter="signInPassword"
                        outlined
                        required
                        @focus="focused = true"
                        :valid="isValid"
                        v-model="email">
                      </mdc-textfield>
                    </div>
                    <div class="input-password signin-input">
                      <mdc-text tag="span" typo="body2">Password</mdc-text>
                      <mdc-textfield
                          @keypress.enter="signInPassword"
                          @focus="focused = true"
                          type="password"
                          required minlength=8
                          outlined
                          iconOutlined
                          :valid="isValid"
                          helptext-persistent
                          v-model="password">
                      </mdc-textfield>
                      <mdc-text tag="span" typo="caption"  class="forgot-password-link" :disabled="!email"  @click.native="forgotPassword">Forgotten password?</mdc-text>
                    </div>
                  </div>
                  <mdc-body @click.native="helpTextClick" v-if="helptext" typo="body2" class="helptext" v-html="helptext"></mdc-body>
                  <mdc-button class="signin-button" :disabled="working" @click="signInPassword">
                    <div class="loader" v-if="working"></div>
                    <mdc-text tag="span">Sign in</mdc-text>
                  </mdc-button>

                  <mdc-body typo="body2" tag="span" class="signup-link">
                    Don’t have an account? <a @click="switchToSignup" class="link">Sign up</a>
                  </mdc-body>

                  <mdc-text tag="span" typo="caption" class="signin-disclaimer">By signing in you agree to DuoMe’s Privacy Policy and Terms and Conditions</mdc-text>

                </div>
              </div>
          </div>
        </div>
      </transition>
      </div>
    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import { firebaseSettings } from '../settings'
import { mapState, mapActions } from 'vuex'
import { onboarding, mail } from '../utils/backend'
import ModalDialog from '../components/flexibly/ModalDialog.vue'
import BaseSvgIcon from '../components/flexibly/BaseSvgIcon.vue'
import ProfileInitials from '../components/flexibly/ProfileInitials.vue'
import WorkspaceLocations from '../components/flexibly/WorkspaceLocations.vue'
import LocationsDetails from '../components/flexibly/LocationsDetails.vue'
import orderBy from 'lodash/orderBy'
import { getNameInitials } from '../utils/profile'
const _ = { orderBy }

export default {
  name: 'signin-onboarding',
  props: {
    desktop: {
      type: Boolean
    }
  },
  data () {
    return {
      newDescOptions: [],
      disableCreate: false,
      openNotInDomainModal: false,
      loaded: false,
      working: false,
      isValid: true,
      firstNameValid: true,
      lastNameValid: true,
      emailValid: true,
      passwordValid: true,
      firstName: '',
      lastName: '',
      fullname: '',
      email: '',
      password: null,
      msg: 'Home',
      isSignin: true,
      emailSignin: false,
      consent: false,
      consentTimeStamp: '',
      helptext: '',
      focused: false,
      hideConsent: false,
      stage: 'check',
      mode: 'create',
      emailVerifySent: false,
      inviteId: null,
      vcode: '',
      userVerified: false,
      emailFeatures: true,
      teamName: '',
      teamDesc: '',
      showDesc: false,
      descOptions: [
        'Marketing',
        'Development',
        'Design',
        'Accounting',
        'Products',
        'Compliance',
        'Product management'
      ],
      selectedDesc: [
      ],
      emailList: '',
      newTeam: {
        name: '',
        description: '',
        selectedDescription: [],
        newLocations: [],
        defaultLocation: false
      },
      showRight: false,
      optResponse: null,
      searchTeam: '',
      team: null,
      teams: [],
      locations: [],
      inviteTeamName: '',
      invites: [],
      freeDomain: false,
      notInDomain: false,
      continueAllowed: false,
      updatingToken: false,
      defaultLocationText: '',
      defaultLocation: false,
      selectedTeam: false,
      selectedInvite: false
    }
  },
  components: {
    ModalDialog,
    BaseSvgIcon,
    ProfileInitials,
    WorkspaceLocations,
    LocationsDetails
  },
  computed: {
    ...mapState([
      'isBranded',
      'teamMode',
      'user',
      'profile',
      'workspace',
      'myInvites',
      'myRequests'
    ]),
    validPassword () {
      if (this.password && this.password.length >= 8) {
        return true
      }
      return false
    },
    invitesFiltered () {
      if (this.searchTeam) {
        const query = this.searchTeam.toLowerCase()
        return [...this.myInvites].filter((inv) => {
          const teamName = inv.teamName.toLowerCase()
          if (teamName.startsWith(query)) {
            return true
          }
          return false
        })
      } else {
        return this.myInvites
      }
    },
    teamsFiltered () {
      if (this.searchTeam) {
        const query = this.searchTeam.toLowerCase()
        const teams = this.teams.filter((team) => {
          if (this.teamJoinAllowed(team.teamId).state === 'invited') {
            return false
          }
          const teamName = team.teamName.toLowerCase()
          const memberNames = team.profileDetails.map(profile => {
            return profile.displayName.replace(/\s+/g, '').toLowerCase()
          })
          if (teamName.includes(query)) {
            return true
          }
          if (memberNames.some(name => name.includes(query))) {
            return true
          }
          return false
        })
        const orderedTeams = teams.map((team) => {
          return { ...team, joinState: this.teamJoinAllowed(team.teamId).state }
        })
        return _.orderBy(orderedTeams, 'joinState', 'desc')
      } else {
        const teams = this.teams.map((team) => {
          if (this.teamJoinAllowed(team.teamId).state !== 'invited') {
            return { ...team, joinState: this.teamJoinAllowed(team.teamId).state }
          }
          return {}
        })
        return _.orderBy(teams, 'joinState', 'desc')
      }
    },
    isWorkspaceAdminSetup () {
      // first team setup - workspace admin
      if (!this.teams || this.teams.length === 0) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      'setSnackMessage',
      'setPageLoad',
      'loadListeners',
      'getTeamDetailsFromInvite',
      'completeRequest'
    ]),
    clearDefaultLocation () {
      this.newTeam.defaultLocation = false
      this.defaultLocation = false
      this.defaultLocationText = ''
    },
    setOfficeLocation (val, showFor, text) {
      this.defaultLocation = val
      this.newTeam.defaultLocation = val
      this.defaultLocationText = text
    },
    locationsUpdate (locations) {
      this.newTeam.newLocations = locations
    },
    helpTextClick (event) {
      if (event.target.classList.contains('signup-link')) {
        this.switchToSignup()
      }
    },
    teamJoinAllowed (teamId) {
      if (this.myInvites) {
        const invited = this.myInvites.filter((inv) => { return inv.team === teamId })
        if (invited.length > 0) {
          return { state: 'invited', inviteId: invited[0].id }
        }
      }
      if (this.myRequests) {
        const requested = this.myRequests.filter((req) => { return req.teamId === teamId })
        if (requested.length > 0) {
          return { state: 'requested' }
        }
      }
      return { state: 'allowed' }
    },
    getMemberInitials (name) {
      return getNameInitials(name)
    },
    switchToSignup () {
      this.stage = 'check'
      this.helptext = ''
      this.openNotInDomainModal = false
      this.email = ''
    },
    gotoHomePage () {
      window.location = 'https://duome.co'
    },
    switchToSignin () {
      this.stage = 'signin'
      this.helptext = ''
    },
    onSelected (idx) {
      this.isSignin = (idx === 0)
    },
    checkConsent () {
      this.helptext = ''
      if (!this.consent) {
        this.helptext = 'Please agree to Flexibly\'s terms and conditions to continue'
      }
      return this.consent
    },
    resetValidation () {
      this.isValid = true
      this.firstNameValid = true
      this.lastNameValid = true
      this.emailValid = true
      this.passwordValid = true
      this.helptext = ''
    },
    async signupPassword () {
      this.working = true
      this.helptext = ''
      if (!this.fullname || !this.password) {
        this.helptext = 'Please enter username and password'
        this.working = false
      } else if (!this.validPassword) {
        this.helptext = 'Please select a stronger password'
        this.working = false
      } else {
        try {
          await firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
            .then(async () => {
              const user = firebase.auth().currentUser
              const vparams = {
                email: user.email,
                userId: user.uid,
                otp: this.vcode
              }
              await onboarding('set-verified', vparams)
              await user.updateProfile({ displayName: this.fullname })
              this.showRight = true
              let nextStep = 'status'
              if (this.myRequests.length === 0 && this.myInvites.length === 0) {
                nextStep = 'reason'
                const response = await onboarding('get-teams', { email: this.email, userId: this.user.uid })
                if (response.value) {
                  this.teams = response.value
                }
                await onboarding('get-locations', { email: this.email, userId: this.user.uid }).then((response) => {
                  this.locations = response.value
                })
              }
              this.stage = nextStep
              this.working = false
            }, (error) => {
              if (error.message && error.code) {
                this.helptext = error.message
              }
              this.working = false
            })
        } catch (error) {
          console.log('details page', error)
          this.working = false
        }
      }
    },
    async joinOrCreate () {
      this.working = true
      let nextStep = 'reason'
      const response = await onboarding('get-teams', { email: this.email, userId: this.user.uid })
      if (response.value) {
        this.teams = response.value
        if (this.teams.length === 0) {
          nextStep = 'create'
        }
      } else {
        nextStep = 'create'
      }
      this.stage = nextStep
      this.working = false
    },
    signInPassword () {
      this.working = true
      if (!this.email || !this.password) {
        this.helptext = 'Please enter your email and password to signin'
        this.working = false
        return
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!this.email.match(emailRegex)) {
        this.helptext = 'Please enter a valid email'
        this.working = false
        return
      }
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then(async (userCredential) => {
          this.working = false
          setTimeout(async () => {
            this.userVerified = await firebase.auth().currentUser
            if (this.userVerified.claims) {
              if (!this.userVerified.claims.workspace || !this.userVerified.claims.team) {
                setTimeout(() => {
                  this.$router.replace('/join')
                }, 1000)
              } else {
                setTimeout(() => {
                  this.$router.replace('/people')
                }, 100)
              }
            }
          }, 500)
        })
        .catch(err => {
          this.showAlertMessage(err)
          this.working = false
        })
    },
    async forgotPassword () {
      this.helptext = 'Sending password reset email'
      if (!this.email) {
        this.helptext = 'Please enter your email to reset your password'
      } else {
        const mailBody = {
          email: this.email,
          url: `https://${firebaseSettings.authDomain}`
        }
        mail('password-reset', mailBody).then((res) => {
          if (res.error) {
            console.error('forgotPassword.error', res.error)
            this.helptext = 'Error sending password reset email'
          } else {
            this.helptext = 'Email with password reset link has been sent, please check your mail'
          }
        })
          .catch(err => {
            console.error('forgotPassword.error', err)
            this.helptext = 'Error sending password reset email'
          })
      }
    },
    toggleTeamDescOption (option) {
      if (this.selectedDesc.includes(option)) {
        this.selectedDesc = this.selectedDesc.filter((opt) => opt !== option)
      } else {
        this.selectedDesc = [...this.selectedDesc, option]
      }
      this.newTeam.selectedDescription = this.selectedDesc
    },
    isSelectedDesc (option) {
      return this.selectedDesc.includes(option)
    },
    skipTeamDesc () {
      this.newTeam.description = ''
      this.newTeam.selectedDescription = this.selectedDesc
      this.stage = 'create3'
    },
    setTeamDesc () {
      this.newTeam.description = this.teamDesc
      if (this.teamDesc && !this.descOptions.includes(this.teamDesc)) {
        const descIndex = this.newDescOptions.findIndex(desc => desc.toLowerCase() === this.teamDesc.toLowerCase())
        const descIndexInOptions = this.descOptions.findIndex(desc => desc.toLowerCase() === this.teamDesc.toLowerCase())
        if (descIndex === -1 && descIndexInOptions === -1) {
          this.descOptions.push(this.teamDesc)
          this.newDescOptions.push(this.teamDesc)
          this.toggleTeamDescOption(this.teamDesc)
        }
      }
      this.newTeam.selectedDescription = this.selectedDesc
      this.teamDesc = ''
    },
    clearDesc (option) {
      const descIndex = this.newDescOptions.findIndex(desc => desc.toLowerCase() === option.toLowerCase())
      const descIndexInOptions = this.descOptions.findIndex(desc => desc.toLowerCase() === option.toLowerCase())
      if (descIndex !== -1 && descIndexInOptions !== -1) {
        this.newDescOptions.splice(descIndex, 1)
        this.descOptions.splice(descIndexInOptions, 1)
        this.toggleTeamDescOption(option)
      }
    },
    goToCreate3 () {
      this.helptext = ''
      if (!this.newTeam.description && this.newTeam.selectedDescription.length === 0) {
        this.helptext = 'Please select what your team does or skip this step'
      } else {
        this.stage = 'create3'
      }
    },
    joinFromInviteSkip () {
      this.joinTeamfromInvite(this.selectedInvite)
    },
    joinFromInvite () {
      this.joinTeamfromInvite(this.selectedInvite, this.defaultLocation)
    },
    async joinTeamfromInvite (inviteId, defaultLocation) {
      if (inviteId) {
        this.inviteId = inviteId
      }
      if (this.inviteId) {
        this.working = true
        const params = {
          userId: this.user.uid,
          email: this.user.email,
          inviteId: this.inviteId
        }
        if (defaultLocation) {
          params.defaultLocation = defaultLocation
        }
        let response
        try {
          response = await onboarding('join-team', params)
          setTimeout(async () => {
            await firebase.auth().currentUser.getIdToken(true)
            this.loadListeners()
            setTimeout(() => {
              this.$router.replace('/onboarding')
            }, 1000)
          }, 1000)
        } catch (exception) {
          console.error('joinTeam', exception, response)
          this.working = false
        }
      }
    },
    async joinTeam (team, defaultLocation) {
      if (team && !this.working) {
        this.working = true
        this.selectedTeam = team
        const params = {
          userId: this.user.uid,
          email: this.user.email,
          teamId: team,
          userName: this.user.displayName
        }
        if (defaultLocation) {
          params.defaultLocation = defaultLocation
        }
        try {
          const response = await onboarding('join-team', params)
          if (response.value === 'requested') {
            this.stage = 'requested'
          } else {
            this.helptext = response.value
          }
        } catch (exception) {
          console.error('joinTeam', exception)
        } finally {
          this.working = false
        }
      }
    },
    selectInvite (invite) {
      this.selectedInvite = invite
      if (this.locations && this.locations.length > 0) {
        this.stage = 'join-location'
      } else {
        this.joinFromInviteSkip()
      }
    },
    selectTeam (team) {
      this.selectedTeam = team
      if (this.locations && this.locations.length > 0) {
        this.stage = 'requested-location'
      } else {
        this.sendTeamRequestSkip()
      }
    },
    async sendTeamRequestSkip () {
      this.joinTeam(this.selectedTeam)
    },
    async sendTeamRequest () {
      this.joinTeam(this.selectedTeam, this.defaultLocation)
    },
    async createTeamSkip () {
      this.working = true
      this.emailList = ''
      if (!this.newTeam.description && this.newTeam.selectedDescription.length > 0) {
        this.newTeam.description = this.newTeam.selectedDescription[0]
      }
      const params = {
        userId: this.user.uid,
        email: this.user.email,
        displayName: this.user.displayName,
        teamName: this.newTeam.name,
        teamDescription: this.newTeam.description,
        teamDescriptionSelected: this.newTeam.selectedDescription,
        locations: this.newTeam.newLocations,
        defaultLocation: this.newTeam.defaultLocation,
        invites: []
      }
      let response
      try {
        response = await onboarding('create-team', params)
        // Refresh ID to bring in claims
        await firebase.auth().currentUser.getIdToken(true)
        this.loadListeners()
        setTimeout(() => {
          this.$router.replace('/onboarding')
        }, 1000)
      } catch (error) {
        console.error('createTeamAndInvites', error, response)
      } finally {
        this.working = false
      }
    },
    async createTeamAndInvites () {
      this.working = true
      const emailArr = this.emailList.replaceAll(' ', '').split(',').filter((email) => email !== '')
      if (emailArr.length > 0) {
        if (!this.newTeam.description && this.newTeam.selectedDescription.length > 0) {
          this.newTeam.description = this.newTeam.selectedDescription[0]
        }
        const params = {
          userId: this.user.uid,
          email: this.user.email,
          displayName: this.user.displayName,
          teamName: this.newTeam.name,
          teamDescription: this.newTeam.description,
          teamDescriptionSelected: this.newTeam.selectedDescription,
          locations: this.newTeam.newLocations,
          defaultLocation: this.newTeam.defaultLocation,
          invites: emailArr
        }
        let response
        try {
          response = await onboarding('create-team', params)
          // Refresh ID to bring in claims
          await firebase.auth().currentUser.getIdToken(true)
          this.loadListeners()
          setTimeout(() => {
            this.$router.replace('/onboarding')
          }, 1000)
        } catch (error) {
          console.error('createTeamAndInvites', error, response)
        } finally {
          this.working = false
        }
      } else {
        this.working = false
        this.helptext = 'Please enter email addresses seperated by a comma, or skip this step'
      }
    },
    async checkEmail () {
      if (!this.email) {
        this.helptext = 'Please enter your work email'
        return
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!this.email.match(emailRegex)) {
        this.helptext = 'Please enter a valid work email'
        return
      }
      if (!this.working) {
        this.working = true
        const response = await onboarding('check-email', { email: this.email })
        // console.log('checkEmail', this.email, response)
        this.working = false
        if (response.value === 'user-complete') {
          setTimeout(() => {
            this.helptext = 'An account has already been created for this email.<br> Please sign in to continue'
          }, 500)
          this.stage = 'signin'
        } else if (response.value === 'user-join') {
          setTimeout(() => {
            this.helptext = 'An account has already been created for this email.<br> Please sign in to join or create your team'
          }, 500)
          this.stage = 'signin'
        } else if (response.value === 'invited') {
          this.helptext = 'You have been invited to join a workspace'
          if (response.invites) {
            this.invites = response.invites
          }
          if (response.freeDom) {
            this.freeDomain = response.freeDom
          } else if (response.notInDomain) {
            this.notInDomain = response.notInDomain
          }
          if (!this.freeDomain && !this.notInDomain) {
            this.continueAllowed = true
          }
          this.stage = 'verify'
          this.mode = 'join'
        } else if (response.value === 'continue') {
          this.helptext = 'Continue to create a new workspace'
          this.continueAllowed = true
          this.stage = 'verify'
        } else if (response.value === 'not-in-domains') {
          this.stage = 'not-in-domain'
          this.notInDomain = true
          this.continueAllowed = false
          this.openNotInDomainModal = true
          this.helptext = 'We are currently operating a wait list for access and will get in touch with you to provide access soon'
        } else if (response.value === 'free-domain') {
          this.freeDomain = true
          this.continueAllowed = false
          this.helptext = 'This is a public or shared email domain, please retry using you work email'
        } else {
          this.helptext = 'An error has occurred, please check your email address is entered correctly'
        }
      }
    },
    async sendVerify () {
      this.working = true
      const response = await onboarding('send-otp', { email: this.email })
      if (response.value === 'OK') {
        this.emailVerifySent = true
        this.working = false
      }
    },
    async verify () {
      this.working = true
      const response = await onboarding('check-otp', { email: this.email, otp: this.vcode })
      this.optResponse = response
      this.vcode = ''
      if (response.value === 'verified') {
        this.stage = 'detail'
        this.showRight = true
        this.helptext = ''
      } else {
        this.helptext = 'Code incorrect, please check and try again or resend a new code'
      }
      this.working = false
    },
    verifyResend () {
      this.helptext = ''
      this.vcode = ''
      this.sendVerify()
      setTimeout(() => {
        this.helptext = 'Code resent, please verify within 15 minutes'
      }, 1000)
    },
    verifyGoogle () {
      const provider = new firebase.auth.GoogleAuthProvider()
      provider.setCustomParameters({
        // Target specific email with login hint.
        login_hint: this.email
      })
      firebase.auth().signInWithPopup(provider).then(async (result) => {
        /** @type {firebase.auth.OAuthCredential} */
        // const credential = result.credential

        // This gives you a Google Access Token. You can use it to access the Google API.
        // const token = credential.accessToken
        // The signed-in user info.
        // const guser = result.user

        this.userVerified = await firebase.auth().currentUser
        if (this.userVerified.email.toLowerCase() !== this.email.toLowerCase()) {
          // verified but with email
          this.helptext = 'Please verify with the same email'
          // log out user
          try {
            await firebase.auth().signOut()
            // signed out
          } catch (err) {
            // an error
            console.error('signout error', err)
          }
        } else {
          this.showRight = true
          let nextStep = 'status'
          if (this.myRequests.length === 0 && this.myInvites.length === 0) {
            nextStep = 'reason'
            const response = await onboarding('get-teams', { email: this.email, userId: this.userVerified.uid })
            if (response.value) {
              this.teams = response.value
            }
            await onboarding('get-locations', { email: this.email, userId: this.userVerified.uid }).then((response) => {
              this.locations = response.value
            })
          }
          this.stage = nextStep
          this.working = false
        }
      })
    },
    signInGoogle () {
      const provider = new firebase.auth.GoogleAuthProvider()
      provider.setCustomParameters({
        // Target specific email with login hint.
        login_hint: this.email
      })
      firebase.auth().signInWithPopup(provider).then(async (result) => {
        /** @type {firebase.auth.OAuthCredential} */
        // const credential = result.credential

        // This gives you a Google Access Token. You can use it to access the Google API.
        // const token = credential.accessToken
        // The signed-in user info.
        // const guser = result.user
        this.userVerified = await firebase.auth().currentUser

        // User should exist and have workspace / team
        // User primary email should also match provider email
        const providerEmail = `${result.additionalUserInfo.profile.email}`.toLowerCase()
        const newUser = result.additionalUserInfo.isNewUser
        if (newUser) {
          const err = 'User not found, click the signup link below.'
          this.helptext = err
          this.showAlertMessage(err)
          await this.userVerified.delete().then(() => {
            firebase.auth().signOut()
          })
        } else if (this.userVerified.email === providerEmail) {
          setTimeout(() => {
            this.$router.replace('/people')
          }, 100)
        } else {
          // log out user
          await firebase.auth().signOut()
          const err = 'User not found, click the signup link below.'
          this.helptext = err
          this.showAlertMessage(err)
        }
      })
    },
    verifyMS () {
      const provider = new firebase.auth.OAuthProvider('microsoft.com')
      provider.setCustomParameters({
        // Force re-consent.
        prompt: 'consent',
        // Target specific email with login hint.
        login_hint: this.email
      })
      firebase.auth().signInWithPopup(provider).then(async (result) => {
        // IdP data available in result.additionalUserInfo.profile.

        /** @type {firebase.auth.OAuthCredential} */
        // const credential = result.credentia          // console.log({ ...this.userVerified.claims })l

        // OAuth access and id tokens can also be retrieved:
        // const accessToken = credential.accessToken
        // const idToken = credential.idToken
        this.userVerified = await firebase.auth().currentUser
        if (this.userVerified.email.toLowerCase() !== this.email.toLowerCase()) {
          // verified but with email
          this.helptext = 'Please verify with the same email'
          // log out user
          try {
            await firebase.auth().signOut()
            // signed out
          } catch (err) {
            // an error
            console.error('signout error', err)
          }
        } else {
          this.showRight = true
          let nextStep = 'status'
          if (this.myRequests.length === 0 && this.myInvites.length === 0) {
            nextStep = 'reason'
            const response = await onboarding('get-teams', { email: this.email, userId: this.userVerified.uid })
            if (response.value) {
              this.teams = response.value
            }
            await onboarding('get-locations', { email: this.email, userId: this.userVerified.uid }).then((response) => {
              this.locations = response.value
            })
          }
          this.stage = nextStep
          this.working = false
        }
      })
    },
    signInMS () {
      const provider = new firebase.auth.OAuthProvider('microsoft.com')
      provider.setCustomParameters({
        // Target specific email with login hint.
        login_hint: this.email
      })
      firebase.auth().signInWithPopup(provider).then(async (result) => {
        // IdP data available in result.additionalUserInfo.profile.

        /** @type {firebase.auth.OAuthCredential} */
        // const credential = result.credential

        // OAuth access and id tokens can also be retrieved:
        // const accessToken = credential.accessToken
        // const idToken = credential.idToken
        this.userVerified = await firebase.auth().currentUser

        // result.additionalUserInfo.isNewUser

        // User should exist and have workspace / team
        // User primary email should also match provider email
        setTimeout(async () => {
          const providerEmail = `${result.additionalUserInfo.profile.userPrincipalName}`.toLowerCase()
          const newUser = result.additionalUserInfo.isNewUser
          if (newUser) {
            const err = 'User not found, click the signup link below.'
            this.helptext = err
            this.showAlertMessage(err)
            await this.userVerified.delete().then(() => {
              firebase.auth().signOut()
            })
          } else if (this.userVerified.email === providerEmail) {
            setTimeout(() => {
              this.$router.replace('/people')
            }, 1000)
          } else {
            // log out user
            await firebase.auth().signOut()
            this.helptext = 'User not found, click the signup link below.'
          }
        }, 1000)
      })
    },
    createTeam () {
      if (!this.teamName) {
        this.helptext = 'Please enter a team name'
        return
      }
      if (this.disableCreate) {
        return
      }
      this.newTeam = {
        name: this.teamName,
        description: '',
        selectedDescription: [],
        newLocations: [],
        defaultLocation: ''
      }
      this.stage = 'create2'
    },
    userFriendlyMessage (err) {
      if (err.code === 'auth/user-not-found') {
        const helpText = 'We don\'t recognise that email, have you got another email or do you need to signup for the first time?'
        const newHelpText = helpText.replace(/signup/g, '<a class="signup-link link">sign up</a>')
        return newHelpText
      } else if (err.code === 'auth/wrong-password') {
        return 'The password is invalid'
      }
      return (err.message) ? err.message : err
    },
    showAlertMessage (err) {
      this.isValid = false
      this.helptext = this.userFriendlyMessage(err)
      this.setSnackMessage(this.helptext)
    }
  },
  created () {
    this.inviteId = (this.$route) ? this.$route.params.invite : null
    const isSignIn = (this.$route) ? this.$route.meta.signin : false
    if (isSignIn) {
      this.stage = 'signin'
    }
  },
  async mounted () {
    /* eslint-disable no-useless-escape */
    if (this.inviteId) {
      const params = {
        inviteId: this.inviteId
      }
      const response = await onboarding('get-team-from-invite', params)
      if (response.value) {
        this.team = response.value
        this.inviteTeamName = this.team ? this.team.teamName : ''
      }
    }
    this.loaded = false
    this.$emit('hideBottomNav', true)
    this.$emit('hideTopNav', true)
    /* eslint-enable no-useless-escape */

    const container = document.querySelector('.mdc-layout-app')
    if (container) {
      container.classList.add('noscroll')
    }
    this.emailSignin = true
    this.loaded = true
    this.setPageLoad(false)
  },
  beforeDestroy () {
    this.$emit('hideBottomNav', false)
    this.$emit('hideTopNav', false)
    const container = document.querySelector('.mdc-layout-app')
    if (container) {
      container.classList.remove('noscroll')
    }
  },
  watch: {
    teamName: {
      handler () {
        const teamNames = this.teams.map(team => team.teamName.toLowerCase())
        if (teamNames.includes(this.teamName.toLowerCase())) {
          this.helptext = 'Team name entered is already taken, try a different one'
          this.disableCreate = true
        } else {
          this.helptext = ''
          this.disableCreate = false
        }
      }
    },
    myRequests: {
      async handler (newR, oldR) {
        if (this.myRequests) {
          let updateToken = false
          for (const req of this.myRequests) {
            if (req.status === 'approved' && !req.done) {
              await this.completeRequest({ requestId: req.id })
              updateToken = true
            }
          }
          if (updateToken && !this.updatingToken) {
            this.updatingToken = true
            // console.log('SigningOnboarding.watch myRequests updateToken')
            await firebase.auth().currentUser.getIdToken(true)
            setTimeout(async () => {
              await this.loadListeners()
              setTimeout(() => {
                this.updatingToken = false
                this.$router.replace('/onboarding', () => {})
              }, 500)
            }, 500)
          }
        }
      },
      immediate: true
    },
    consent: function (newCon, oldCon) {
      if (this.consent) {
        this.helptext = ''
        this.consentTimeStamp = new Date().toISOString()
      } else {
        this.consentTimeStamp = ''
      }
    },
    stage: {
      handler () {
        this.helptext = ''
        this.password = ''
      }
    }
  }
}
</script>

<style>
.signin .loader {
  border: 2px solid #f3f3f3;
  animation: spin 1s linear infinite;
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 65%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.signin .section-wrapper {
    width:100%;
    height: 100%;
    text-align: center;
    display:flex;
}

.signin .signin-section.left-section {
  align-items: flex-start;
}

.signin .signin-section.left-section * {
  text-align: left;
}

.signin .signin-section.left-section {
  align-items: flex-start;
}

.signin .signin-section.left-section * {
  text-align: left;
}

.signin .signin-section.left-section .workspace-admin-location-setup,
.signin .signin-section.left-section .default-location-select {
  align-items: flex-start;
}
.signin .signin-section.left-section .workspace-admin-location-setup *,
.signin .signin-section.left-section .default-location-select * {
  text-align: left;
}

.pending-invites-notice {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -87px;
  margin-left: 335px;
  background: #FFE973;
  border-radius: 10px;
  padding: 1px 2px;
  font-family: var(--mdc-typography-headline2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
}
.pending-invites-notice .icon-wrapper {
  height: 14px;
  width: 14px;
  margin-left: 3px;
}
.pending-invites-notice .icon-wrapper img {
  width: 100%;
  height: 100%;
}
.pending-invites-notice .mdc-text {
  font-size: 11px;
  font-weight: 700;
  padding: 0 3px;
  line-height: 14px;
}

.signin .helptext {
  color: #b00020;
}
.signin .helptext * {
  color: #b00020 !important;
}

.mdc-layout-app.noscroll {
  overflow:hidden;
}
.mdc-layout-app.noscroll .mdc-layout-app--main-container {
  height:100%;
  overflow:hidden;
}

.signin .mdc-tab--active {
  background-color: #F2F2F2;
  border-radius: 5px 5px 0px 0px;
}
.signin .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.signin .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.signin .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #6200ee);
}
.signin .mdc-textfield.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
  width: 100%;
  border-bottom-color: rgba(0, 0, 0, 0.42);
  background-color: whitesmoke;
  height: 50px;
  min-height: 50px;
  margin-top:8px;
}
.signin .mdc-textfield.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) input.mdc-text-field__input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
</style>
<style scoped lang="scss">
.signin {
  text-align: center;
  min-height: 500px;
  background:white !important;
  margin-bottom: 0;
  height: 100%;
}

.signin .join-create-team {
  background: #00498D;
  text-transform: none;
  width: 200px;
  .loader {
    left: 85%;
  }
  &.confirm {
    width: 120px;
    .loader {
      left: 75%;
    }
  }
}

.signin-contents {
  height: 100vh;
  z-index: 999;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.page-logo {
  max-width: 300px;
  height: 100px;
  margin: 30px 50px;
  display: flex;
  align-items: center;
}
.signup-logo {
  height:30px;
  text-align: center;
}

@media (max-height: 740px) {
  .signup-logo {
    height: 30px;
    width: unset;
    text-align: center;
    padding: 10px;
  }
}

</style>
<style>
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #00498D !important;
    background-color: #00498D !important;
}
@media (max-width: 570px), (max-height: 570px){
  .signin .or::before{
    margin-bottom: 6px;
  }
  .signin .mdc-tab {
    height:48px
  }
}
</style>
<style lang="scss">

.right-section {
  background-color: #F8F8F8;
  position: relative;
  margin-top: -140px;
  right: -50px;
  height: calc(100% + 140px);
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  .right-info {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: flex-start;
    text-align: left;
    margin-left: 15px;
    .team-info {
      margin-bottom: 15px;
      .info-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        color:#404040;
        font-size: 18px;
        margin-bottom: 5px;
        &::before {
          content: '';
          width: 24px;
          border-radius: 50%;
          background: #ACECBE;
          height: 24px;
          display: block;
          margin-right: 10px;
        }
      }
      .link {
        text-decoration: none;
        cursor: pointer;
        color: #6184AD;
        font-size: 18px;
        margin-left: 30px;
        font-weight: 600;
        text-transform: capitalize;
        &.disabled {
          color:rgba(0,0,0,.34);
          pointer-events: none;
          cursor: default;
        }
      }
    }
    .team-desc-list {
      display:flex;
      flex-flow: row;
      flex-wrap: wrap;
      width:100%;
      max-width: 250px;
      margin-left: 30px;
      .link {
        margin: 0;
      }
      .selected-desc {
        margin-right: 5px;
      }
    }
  }
  img {
    border-style: none;
    width: 350px;
  }
}
.signin-section {
  display:flex;
  flex-direction: column;
  max-width: 600px;
  margin:auto;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-width: 440px;

  .workspace-admin-location-setup {
    display:flex;
    flex-direction: column;
    position: relative;
    margin:auto;
    justify-content: flex-start;
    align-items: center;
  }

  .default-location-select {
    display:flex;
    flex-direction: column;
    position: relative;
    margin:auto;
    justify-content: flex-start;
    align-items: center;
  }

  /* common */
  .mdc-button {
    border-radius: 10px;
    &--disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .heading {
    color: #404040;
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
  }
  .back-link {
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    margin-top: 80px;
    text-align: left;
    color: #BDBDBD;

    &.fixed {
      position:fixed;
      bottom:40px;
      width:100px;
      margin-left:-110px;
    }
  }

  .social-buttons-wrapper {
    .mdc-button {
      max-width: 300px;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      min-height: 50px;
      font-size: 16px;
      background-color:white;
      border: 0.5px solid #828282;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 0 40px;
      text-transform: none;
      .mdc-text {
        color: #6e747b;
        font-weight: 600;
        margin-left: 15px;
      }
      &:last-of-type {
        margin-top: 10px;
      }
    }
    &.verify-actions {
      .mdc-text {
        color: #404040;
        font-weight: 600;
      }
    }
  }

  .or {
    margin-top: 20px;
    margin-bottom:0px;
    span {
      width:auto;
      margin-left:auto;
      margin-right:auto;
      background:white;
      padding-left:10px;
      padding-right:10px;
      position: relative;
      color: #BDBDBD;
    }
    &::before {
      content: '';
      position: absolute;
      width: calc(100% - 140px);
      border-bottom: 1px solid #E7E3E3;
      left: 0;
      height: 10px;
      margin: 3px auto auto auto;
      z-index: 0;
      text-align: center;
      right: 0;
    }
  }
  &.not-in-domain {
    .not-in-domain-modal {
      .mdc-dialog {
        &__surface {
          max-width: 500px;
        }
        &__content {
          border-bottom: none;
        }
      }
      .icon-wrapper {
        height: 40px;
        margin-bottom: 30px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .mdc-text {
        color: #404040;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
      .thank-you-text {
        font-weight: 700;
      }
      .explanation {
        text-align: justify;
        display: block;
        font-weight: 600;
        .support-link {
          color: #6184AD;
          font-weight: 600;
          text-decoration: none;
        }
      }
      .mdc-button {
        text-transform: none;
        color: #ffffff;
        background: #00498D;
        border: 1px solid #00498D;
        border-radius: 8px;
        width: max-content;
      }
    }
  }
  /* signin page */
  .signin-card {
    padding: 20px;
    box-shadow: 0px 0px 14px 4px rgb(0 0 0 / 7%);
    border-radius: 10px;
    width: calc(100% - 40px);
    header {
      margin-top: 15px;
      .signup-logo {
        padding: 0;
      }
    }
    .helptext {
      margin-top: 30px;
    }
    .signin-content-wrapper {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 410px;
      .signin-inputs-wrapper {
        margin-top: 20px;
        .signin-input {
          display: flex;
          flex-direction: column;
          &:last-of-type {
            margin-top: 15px;
          }
          > .mdc-text {
            text-align: left;
            color: #404040;
            margin-bottom: 5px;
          }
          .mdc-text-field-wrapper {
            width: 300px;
            .mdc-text-field {
              &--outlined {
                height: 40px;
                .mdc-notched-outline {
                  &__leading {
                    border-radius: 0;
                  }
                  &__trailing {
                    border-radius: 0;
                  }
                }
              }
              &__input {
                font-size: 14px;
                color: #404040;
              }
            }
          }
          &.input-password {
            .forgot-password-link {
              cursor: pointer;
              color: #404040;
              text-decoration: underline;
            }
          }
        }
      }
      .mdc-button {
        text-transform: none;
        position: relative;
        &.signin-button, &.accept-invite {
          width: 300px;
          color: #fff;
          height: 40px;
          background: #00498D;
          border-radius: 10px;
          box-sizing: border-box;
          margin: 25px 0;
          font-weight: 600;
          .loader {
            left: 65%;
          }
        }
      }
      .signup-link {
        margin-bottom: 35px;
        .link {
          text-decoration: none;
          color: #00498D;
          font-weight: 600;
        }
      }
    }
  }
  &.join-team {
    .heading {
      margin-bottom: 35px;
    }
    .helptext {
      margin: 20px 0;
    }
    .disclaimer {
      margin-bottom: 15px;
      color: #828282;
      .link {
        text-decoration: none;
        color: #00498D;
      }
    }
    .mdc-text-field-wrapper {
      margin-bottom: 25px;
      width: 400px;
      padding-top: 0;
      .mdc-text-field {
        &--outlined {
          height: 50px;
          .mdc-notched-outline {
            &__leading {
              border-radius: 0;
            }
            &__trailing {
              border-radius: 0;
            }
          }
        }
        &__input {
          font-size: 16px;
          color: #404040;
          &::placeholder {
            color: #bdbdbd;
            opacity: 1;
          }
          &.focus-visible {
            &::placeholder {
              opacity: 0;
            }
          }
        }
      }
    }
    .mdc-button {
      position: relative;
      background: #00498D;
      border: 1px solid #00498D;
      box-sizing: border-box;
      border-radius: 10px;
      text-transform: none;
      font-weight: 600;
      width: 400px;
      height: 50px;
      color: #fff;
    }
    .signin-links {
      color: #828282;
      .link {
        text-decoration: none;
        color: #00498D;
      }
    }
  }
  /* verify-signin */
  &.verify-signin {
    .heading {
      margin-bottom: 40px;
    }
    .verify-action {
      margin-top: 25px;
      .mdc-button {
        max-width: 300px;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 50px;
        min-height: 50px;
        font-size: 16px;
        background-color:white;
        border: 0.5px solid #828282;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0 60px;
        text-transform: none;
        .mdc-text {
          color: #404040;
          font-weight: 600;
          margin: auto;
          text-align: center;
        }
      }
    }
    .verify-otp {
      width: 300px;
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      > .mdc-text {
        margin-bottom: 5px;
        color: #404040;
        text-align: left;
        font-weight: 600;
      }
      .mdc-text-field-wrapper {
        width: 300px;
        .mdc-text-field {
          &--outlined {
            height: 50px;
            .mdc-notched-outline {
              &__leading {
                border-radius: 0;
              }
              &__trailing {
                border-radius: 0;
              }
            }
          }
          &__input {
            font-size: 16px;
            color: #404040;
            &::placeholder {
              color: #bdbdbd;
              opacity: 1;
            }
            &.focus-visible {
              &::placeholder {
                opacity: 0;
              }
            }
          }
        }
      }
      .mdc-button {
        position: relative;
        background: #00498D;
        border: 1px solid #00498D;
        box-sizing: border-box;
        border-radius: 10px;
        text-transform: none;
        font-weight: 600;
        width: 300px;
        height: 50px;
        margin-top: 15px;
        color: #fff;
      }
    }
    .verify-send-again {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .mdc-text {
        color: #404040;
        font-weight: 700;
        font-size: 16px;
        .link {
          text-decoration: none;
          color: #00498D;
        }
        &:last-of-type {
          margin-top: 10px;
        }
      }
    }
  }
  /* details-infor */
  &.details-info {
    margin: auto;
    .heading {
      margin-bottom: 55px;
    }
    .details-info-inputs-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      .input-type {
        display: flex;
        flex-direction: column;
        > .mdc-text {
          color: #404040;
          font-weight: 600;
          text-align: left;
        }
        .password-helptext {
          color: #404040;
          font-weight: 300;
          text-align: left;
        }
        .mdc-text-field-wrapper {
          width: 450px;
          .mdc-text-field {
            &--outlined {
              height: 40px;
              .mdc-notched-outline {
                &__leading {
                  border-radius: 0;
                }
                &__trailing {
                  border-radius: 0;
                }
              }
            }
            &__input {
              font-size: 14px;
              color: #404040;
            }
          }
        }
        &.input-password {
          margin-top: 10px;
        }
      }
      .details-feature-mail {
        align-self: flex-start;
        .mdc-form-field {
          .mdc-checkbox {
            margin-left: -10px;
          }
        }
      }
    }
    .next-button {
      font-size: 16px;
      background: #00498D;
      border: 1px solid #00498D;
      box-sizing: border-box;
      border-radius: 8px;
      align-self: flex-end;
      color: #fff;
      position: relative;
      min-width: 100px;
      .loader {
        left: 75%;
      }
    }
  }

  /* reason-options */
  &.reason-options {
    margin: auto;
    .heading {
      margin-bottom: 55px;
    }
    .option-button {
      text-transform: none;
      background-color:#478FD1;
      font-weight: 600;
      color: white;
      min-width: 450px;
      padding:40px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 80px;
      font-weight: 600;
      font-size: 20px;
      &:hover {
        background-color:#00498D;
      }
      &:last-of-type {
        margin-top: 20px;
      }
    }
  }
  /* create-step */
  &.create-step {
    margin: auto;
    max-width: 530px;
    .create-step-number {
      color: #404040;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    .heading {
      margin-bottom: 20px;
    }
    .create-step-detail {
      color: #404040;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 55px;
      line-height: 30px;
    }
    .mdc-text-field-wrapper {
      max-width: 530px;
      width: 100%;
      margin-bottom: 20px;
      .mdc-text-field {
        &--outlined {
          height: 40px;
          .mdc-notched-outline {
            &__leading {
              border-radius: 0;
            }
            &__trailing {
              border-radius: 0;
            }
          }
        }
        &__input {
          font-size: 16px;
          color: #404040;
          &::placeholder {
            color: #bdbdbd;
            opacity: 1;
          }
          &.focus-visible {
            &::placeholder {
              opacity: 0;
            }
          }
        }
      }
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .create-team {
        position: relative;
        background: #00498D;
        border: 1px solid #00498D;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 14px;
        max-width: 300px;
        width: 250px;
        align-self: flex-end;
        color: #fff;
        .loader {
          left: 85%;
        }
      }
      .join-team-btn {
        position: relative;
        background: #00498D;
        border: 1px solid #00498D;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        max-width: 300px;
        min-width: 100px;
        margin-left:20px;
        align-self: flex-end;
        color: #fff;
        .loader {
          left: 85%;
          margin-right:5px;
        }
      }
      .back-link {
        margin-top: 0;
      }
      .team-does {
        margin-top: 0;
        width: unset;
      }
      &.invite-email {
        margin-top: 20px;
        .button-row {
          width: unset;
        }
      }
      &.team-desc-actions {
        margin-top: 20px;
      }
    }
    .toggle-option-row {
      display:flex;
      flex-flow: row;
      flex-wrap: wrap;
      width:100%;
      .toggle-option-button {
        background-color:white;
        border: 0.5px solid #828282;
        box-sizing: border-box;
        border-radius: 10px;
        margin-right:10px;
        max-width:180px;
        text-transform: capitalize;
        color: #828282;
        font-size: 16px;
        margin-bottom: 10px;
        height: 35px;
        &.active {
          background-color:#6184AD;
          color: white;
        }
        .clear-desc {
          position: absolute;
          top: 6px;
          right: 6px;
          height: 7px;
          width: 7px;
          img {
            filter: invert(1);
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .button-row {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      width: 100%;
      .mdc-button {
        position: relative;
        margin-top:0;
        margin-right:0;
        margin-left:20px;
        width: auto;
        background: #00498D;
        border: 1px solid #00498D;
        box-sizing: border-box;
        border-radius: 8px;
        color: #fff;
        min-width: 100px;
        .loader {
          left: 75%;
        }
        &.send-invite {
          min-width: 150px;
        }
      }
      .link {
        color: #00498D;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
      }
    }
    &.invites-members {
      max-width: 560px;
      .create-step-detail {
        margin-bottom: 20px;
      }
      textarea {
        max-width: 560px;
        width: 100%;
        padding: 10px 0 0 10px;
        &::placeholder {
          color: #bdbdbd;
          opacity: 1;
        }
        &.focus-visible {
          &::placeholder {
            opacity: 0;
          }
        }
      }
    }
  }
  /* team join */
  &.team-join {
    margin: auto;
    max-width: 600px;
    padding-left:50px;
    padding-right:50px;
    padding-bottom: 20px;
    .heading {
      margin-bottom: 20px;
    }
    .team-join-detail {
      margin-bottom: 20px;
      color: #404040;
    }
    .mdc-text-field-wrapper {
      margin-bottom: 25px;
      max-width: 500px;
      padding-top: 0;
      height: 50px;
      min-width: 400px;
      .mdc-text-field {
        &--outlined {
          height: 50px;
          .mdc-notched-outline {
            &__leading {
              border-radius: 0;
            }
            &__trailing {
              border-radius: 0;
            }
          }
        }
        &__input {
          font-size: 16px;
          color: #404040;
          &::placeholder {
            color: #bdbdbd;
            opacity: 1;
          }
          &.focus-visible {
            &::placeholder {
              opacity: 0;
            }
          }
        }
      }
    }
    .teams-list-wrapper {
      display: flex;
      flex-direction: column;
      .team-row {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 3px rgb(0 0 0 / 35%);
        border-radius: 10px;
        padding: 20px;
        max-width: 550px;
        width: 100%;
        min-width: 320px;
        margin-bottom: 15px;
        .team-details {
          display: flex;
          flex-direction: column;
          flex: 1;
          span:not(:empty):not(.profile-initials) {
            color: #404040;
            font-weight: 600;
            margin-bottom: 5px;
          }
          .profiles-wrapper {
            display: flex;
            align-items: center;
            .user-initials {
              margin-right: 5px;
              margin-bottom: 0 !important;
              max-height: 18px;
              .profile-initials {
                border: none;
              }
            }
          }
        }
        .request-sent {
          display: flex;
          align-items: center;
          margin-top: 10px;
          .mdc-icon {
            margin-left: 5px;
            color:#61ad8e;
            font-size: 16px;
          }
        }
        .request-to-join {
          border: 0.5px solid #404040;
          box-sizing: border-box;
          border-radius: 8px;
          font-size: 14px;
          color: #404040;
          height: 30px;
          min-width: 130px;
          text-transform: none;
          margin-top: 10px;
        }
        .accept-invite {
          box-sizing: border-box;
          text-transform: none;
          color: #ffffff;
          background: #00498D;
          border: 0.5px solid #00498D;
          border-radius: 8px;
          height: 30px;
          min-width: 130px;
          font-size: 14px;
        }
        &.disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .create-team {
        position: relative;
        background: #00498D;
        border: 1px solid #00498D;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        max-width: 300px;
        width: 250px;
        margin-left:20px;
        align-self: flex-end;
        color: #fff;
        .loader {
          left: 85%;
        }
      }
      .join-team-btn {
        position: relative;
        background: #00498D;
        border: 1px solid #00498D;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        max-width: 300px;
        min-width: 100px;
        margin-left:20px;
        align-self: flex-end;
        color: #fff;
        .loader {
          left: 85%;
          margin-right:5px;
        }
      }
      .link {
        color: #00498D;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        text-transform: capitalize;
      }
      .back-link {
        margin-top: 0;
      }
      .team-does {
        margin-top: 0;
        width: unset;
      }
      &.invite-email {
        margin-top: 20px;
        .button-row {
          width: unset;
        }
      }
      &.team-desc-actions {
        margin-top: 20px;
      }
    }
  }
  /* invite-requested */
  &.invite-requested {
    margin: auto;
    max-width: 500px;
    .heading {
      margin-bottom: 20px;
    }
    .requested-detail {
      margin-bottom: 20px;
      color: #404040;
    }
  }
  &.confirm-join {
    .confirm-button {
      width: auto;
      background: #00498D;
      border: 1px solid #00498D;
      box-sizing: border-box;
      border-radius: 10px;
      color: #fff;
      min-width: 130px;
    }
  }

 /* location section */
 .field-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .label {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      text-transform: uppercase;
      .mdc-text {
        margin-right: 5px;
        color: #828282;
      }
      .icon-wrapper {
        &.edit-icon {
          height: 12px;
          width: 12px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .building-fields-wrapper {
    margin-bottom: 40px;
    min-height: 57px;
    .mdc-button {
      &.add-location {
        border: 0.5px solid #6184AD;
        border-radius: 4px;
        .mdc-text {
          margin-left: 5px;
          color: #6184AD;
        }
      }
    }
    .add-building-fields {
      display: flex;
      align-items: center;
      .field-wrapper {
        margin-right: 20px;
        margin-bottom: 0;
        .label {
          text-transform: initial;
          margin-bottom: 5px;
        }
        input {
          max-width: 200px;
          border-radius: 2px;
          border: 1px solid #e0e0e0;
          padding: 5px;
          font-size: 14px;
          color: #4f4f4f;
          font-weight: 600;
          height: 20px;
          &.office-name-field {
            width: 200px;
          }
          &.capacity-field {
            max-width: 55px;
            text-align: center;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          &::placeholder {
            color: #BDBDBD;
            font-weight: 600;
          }
        }
      }
      .building-details-actions {
        align-self: flex-end;
        .mdc-button {
          color: #6184AD;
          font-weight: 400;
          &.cancel-name-button {
            text-transform: none;
          }
          &.save-name-button {
            .mdc-text {
              font-weight: 600;
            }
            &.disabled {
              pointer-events: none;
              color: #e0e0e0;
            }
          }
        }
      }
    }
  }
  .location-input-error {
    margin-top: 5px;
    .mdc-text {
      font-weight: 600;
      color: #b10000;
    }
  }
  .all-locations-list {
    .location-details-wrapper {
      &.non-edit-mode {
        display: grid;
        grid-template-columns: 300px 115px 30px;
        align-items: center;
        border-bottom: 0.5px solid #E0E0E0;
        max-width: 450px;
        &:first-of-type {
          border-top: 0.5px solid #e0e0e0;
        }
        .add-floor-action {
          justify-self: flex-end;
          margin-right: 15px;
          align-self: unset;
        }
        .remove-location {
          align-self: unset;
        }
        .remove-location-prompt {
          margin-bottom:5px;
        }
      }
      &.edit-mode {
        border: 0.5px solid #E0E0E0;
        box-shadow: 0px 0px 7px rgb(0 0 0 / 31%);
        border-radius: 4px;
        padding: 15px 25px;
        .close-edit {
          img {
            margin-right: 0;
          }
        }
        .edit-building-details-wrapper {
          display: flex;
          flex-direction: column;
          .edit-building-fields {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .field-wrapper {
              margin-right: 20px;
              margin-bottom: 0;
              .label {
                text-transform: initial;
                margin-bottom: 5px;
              }
              input {
                max-width: 200px;
                border-radius: 2px;
                border: 1px solid #e0e0e0;
                padding: 5px;
                font-size: 14px;
                color: #4f4f4f;
                font-weight: 600;
                height: 20px;
                &.readonly {
                  opacity: 0.5;
                }
                &.office-name-field {
                  width: 200px;
                }
                &.capacity-field {
                  max-width: 55px;
                  text-align: center;
                  -moz-appearance: textfield;
                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                }
                &::placeholder {
                  color: #BDBDBD;
                  font-weight: 600;
                }
              }
            }
            .building-details-actions {
              align-self: flex-end;
              .mdc-button {
                color: #6184AD;
                font-weight: 400;
                &.cancel-name-button {
                  text-transform: none;
                }
              }
            }
            + .location-input-error {
              margin-top: -15px;
              margin-bottom: 20px;
            }
          }
          .name-capacity {
            margin-bottom: 20px;
            .mdc-text {
              color: #404040;
              font-weight: 600;
              &.capacity-label {
                color: #828282;
                font-weight: 400;
              }
            }
            .edit-building {
              margin-left: 15px;
            }
          }
          .floor-add:not(:empty) {
            margin-top: 15px;
          }
          .floor-details-wrapper:not(:empty) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #F2F9FF;
            border-radius: 4px;
            padding: 5px 15px;
            margin-bottom: 5px;
            .floor-name-capacity {
              color: #6184AD;
              text-transform: capitalize;
              display: inline-flex;
              flex-wrap: wrap;
              .floor-details {
                margin-right: 10px;
              }
              .areas-names {
                display: inline-flex;
                flex-wrap: wrap;
                span:not(:last-of-type) {
                  &:after {
                    content: ','
                  }
                }
                .area-name {
                  margin-left: 3px;
                  color: #828282;
                  font-style: italic;
                }
              }
            }
            .floor-action-buttons {
              display: flex;
              align-items: center;
              .icon-wrapper:first-of-type {
                margin-right: 20px;
              }
            }
          }
          .floor-button-fields {
            &:not(:empty) {
              border-bottom: 0.5px dashed #e0e0e0;
            }
            .add-floor-fields {
              display: flex;
              align-items: center;
              padding-bottom: 8px;
              .field-wrapper {
                margin-right: 20px;
                margin-bottom: 0;
                .label {
                  text-transform: initial;
                  margin-bottom: 5px;
                }
                input {
                  max-width: 200px;
                  border-radius: 2px;
                  border: 1px solid #e0e0e0;
                  padding: 5px;
                  font-size: 14px;
                  color: #4f4f4f;
                  font-weight: 600;
                  height: 20px;
                  &.floor-name-field {
                    width: 200px;
                  }
                  &.capacity-field {
                    max-width: 55px;
                    text-align: center;
                    -moz-appearance: textfield;
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                  &::placeholder {
                    color: #BDBDBD;
                    font-weight: 600;
                  }
                  &.readonly {
                    opacity: 0.5;
                  }
                }
              }
              .action-buttons {
                align-self: flex-end;
                margin-bottom: 5px;
                margin-left: 20px;
                .mdc-button {
                  color: #6184AD;
                  font-weight: 400;
                  &.add-area {
                    border: 0.5px solid #6184AD;
                    border-radius: 4px;
                    text-transform: none;
                    width: 90px;
                    height: 24px;
                    .icon-wrapper {
                      margin-right: 5px;
                    }
                    .mdc-text {
                      color: #6184AD;
                    }
                  }
                }
              }
              .remove-menu-wrapper {
                display: flex;
                position: relative;
              }
              .remove-floor-actions {
                align-self: flex-end;
                margin-left: auto;
                .mdc-button {
                  color: #6184AD;
                  text-transform: none;
                }
              }
            }
            .floor-input-error {
              .mdc-text {
                font-weight: 600;
                color: #b10000;
              }
            }
          }
          .add-area-fields {
            display: flex;
            align-items: center;
            border-bottom: 0.5px solid #E0E0E0;
            padding: 5px 0;
            .area-label {
              color: #828282;
              margin-right: 10px;
            }
            .remove-menu-wrapper {
                display: flex;
                position: relative;
              }
            .remove-area-actions {
              align-self: flex-end;
              min-height:15px;
              margin-right:10px;
              margin-left: auto;
              .mdc-button {
                color: #6184AD;
                text-transform: none;
              }
            }
            .field-wrapper {
              margin-right: 20px;
              margin-bottom: 0;
              input {
                max-width: 200px;
                border-radius: 2px;
                border: 1px solid #e0e0e0;
                padding: 5px;
                font-size: 14px;
                color: #4f4f4f;
                font-weight: 600;
                height: 20px;
                &.area-name-field {
                  width: 155px;
                }
                &.capacity-field {
                  max-width: 55px;
                  text-align: center;
                  -moz-appearance: textfield;
                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                }
                &::placeholder {
                  color: #BDBDBD;
                  font-weight: 600;
                }
              }
            }
          }
          .area-input-error {
            .mdc-text {
              font-weight: 600;
              color: #b10000;
            }
          }
          .save-location-actions {
            align-self: flex-end;
            margin-top: 35px;
            .mdc-button {
              border: 0.5px solid #6184AD;
              border-radius: 4px;
              height: 30px;
              .mdc-text {
                font-weight: 700;
              }
              &.cancel-button {
                margin-right: 15px;
                .mdc-text {
                  color: #6184AD;
                }
              }
              &.save-button {
                background: #6184AD;
                .mdc-text {
                  color: #fff;
                }
              }
            }
          }
        }
      }
      padding: 15px 0;
      .name-capacity {
        display: flex;
        align-items: center;
        &.all-names {
          flex-direction: column;
          align-items: baseline;
          .floor-area {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            color: #404040;
            .floor-name {
              color: #404040;
              font-weight: 400;
            }
            .areas-names {
              display: inline-flex;
              flex-wrap: wrap;
              span:not(:last-of-type) {
                &:after {
                  content: ','
                }
              }
              .area-name {
                color: #404040;
                font-style: italic;
                font-weight: 300;
                margin-left: 3px;
              }
            }
          }
        }
        .mdc-text {
          color: #4175B1;
          &.office-name {
            font-weight: 600;
            margin-right: 30px;
            min-width: 100px;
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
      .add-floor-action {
        justify-self: flex-end;
        margin-right: 15px;
        align-self: flex-start;
      }
      .remove-menu-wrapper {
        display: flex;
        position: relative;
      }
      .remove-location {
        align-self: flex-start;
      }
      .mdc-button {
        &.add-floor-button {
          border: 0.5px solid #6184AD;
          border-radius: 4px;
          height: 24px;
          text-transform: initial;
          .mdc-text {
            margin-left: 5px;
            color: #6184AD;
          }
        }
      }
    }
  }
  .default-location-row {
    display:flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom:40px;
    width:100%;

    .mdc-text-field-wrapper {
      margin: 0;
      margin-right: 10px;
    }
  }
}
</style>
