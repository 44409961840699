<template>
  <div v-if="initiativeId" class="initiative-wrapper" @click.stop="emitClick()" role="contentinfo" aria-label="init-name" :class="{'clickable' : clickable}">
    <mdc-text :title="initiative.name" :typo="fontType" tag="span" class="initiative-name" :class="[showHash ? 'show-hash' : '', showHash && greyHash ? 'grey-hash' : '', showHash && thinHash ? 'thin-hash' : '', applyTextOverflow ? 'txt-overflow' : '' ]" role="definition">
      <span role="definition" aria-label="team-name" v-if="showTeam" class="team-name detail">{{ getTeamName(initiative.teamId) }}</span>
      <span role="definition" aria-label="init-name" class="name-text detail">{{ useTag ? initiative.tag : initiative.name }}</span>
    </mdc-text>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'InitiativeName',
  components: {},
  props: {
    applyTextOverflow: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    useTag: {
      type: Boolean,
      default: false
    },
    initiativeId: {
      type: String,
      default: ''
    },
    showTeam: {
      type: Boolean,
      default: false
    },
    fontType: {
      type: String,
      default: 'body1'
    },
    showHash: {
      type: Boolean,
      default: false
    },
    greyHash: {
      type: Boolean,
      default: false
    },
    thinHash: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  beforeDestroy () {},
  created () {},
  mounted () {},
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
      initiatives: state => state.teams.initiatives,
      teams: state => state.teams.teams
    }),
    initiative () {
      if (this.initiatives) {
        const initiativeDetail = this.initiatives.filter(initiative => initiative.id === this.initiativeId)
        if (initiativeDetail.length > 0) {
          return initiativeDetail[0]
        }
      }
      return {}
    }
  },
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    }),
    getTeamName (teamId) {
      if (teamId && teamId !== this.profile.team) {
        if (this.teams) {
          const ft = this.teams.filter((t) => {
            return t.id === teamId
          })[0] || {}
          return `${ft.name || ''}` + ' ' + '/ '
        }
      }
      return ''
    },
    emitClick () {
      this.$emit('initiativeClick', this.initiativeId)
    }
  },
  watch: {}
}
</script>

<style lang="scss">
.initiative-wrapper {
  &.clickable {
    cursor: pointer;
  }
  .initiative-name {
    &.txt-overflow {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.show-hash {
      &::before {
        color: #404040;
        content: "#";
      }
      &.grey-hash {
        &::before {
          opacity: 0.7
        }
      }
      &.thin-hash {
        &::before {
          font-weight: 100;
        }
      }
    }
  }
}
</style>
