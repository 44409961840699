<template>
  <div class="profile-card profile-card-mobile" v-bind:class="{ 'hover' : hoverStyle }">
    <div class="profile-card-wrapper">
      <div class="swap-container" v-bind:class="{ 'hover' : hover, 'hover-enabled': !myprofile && hoverStyle && profile.headline && profile.aboutMe}">
        <div class="no-swapper">
          <div class="front">
          <mdc-card class="profile-wrapper">
            <div :class="'profile ' + profile.cardDesign">
              <div :class="'profile-bg ' + profile.cardDesignBackground">
                <div class="profile-image">
                  <span v-if="profile" class="avatar">
                    <img v-if="profile.photoURL" :src="profile.photoURL">
                    <default-avatar v-else :profile="profile"/>
                  </span>
                </div>

                <div style="flex: 0;min-width: 50px;" v-if="bookmarked">
                  <round-button ripple style="margin-top:0;margin-bottom:50px;box-shadow:none !important" background="transparent !important" class="bookmark" @click.prevent.stop="unbookmark()" label="unbookmark" title="unbookmark">
                      <img slot="leading-icon" class="icon bookmark-icon" src="/static/img/icons/bookmark-on.svg">
                  </round-button>
                </div>
                <div style="flex: 0;min-width: 50px;" v-else>
                  <round-button ripple style="margin-top:0;margin-bottom:50px;box-shadow:none !important" background="transparent !important" class="bookmark" @click.prevent.stop="bookmark()" label="bookmark" title="bookmark">
                      <img slot="leading-icon" class="icon bookmark-icon" src="/static/img/icons/bookmark-off.svg">
                  </round-button>
                </div>
              </div>
            </div>
          </mdc-card>
          <div @click.native.prevent.stop v-if="detail" style="cursor:default; width: 200px;" class="profile-text">
            <mdc-subheading class="displayname">{{profile.displayName}}</mdc-subheading>
            <mdc-body class="profile-status" typo="body2">{{profile.role || profile.headline || ''}}</mdc-body>
            <div>
              <mdc-body style="font-size: 10px;" tag="span" v-if="profile.locationFull" :title="profile.locationFull" class="value">{{profile.location}}</mdc-body>
              <mdc-body style="font-size: 10px;" tag="span" v-else class="value">{{profile.location}}</mdc-body>
              <span class="dot-spacer"> • </span>
              <mdc-body style="font-size: 10px;" tag="span" class="value">{{flexDisplay}}</mdc-body>
              <span class="dot-spacer"> • </span>
              <mdc-body style="font-size: 10px;" tag="span" class="value">{{profile.workstyle}}</mdc-body>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RoundButton from '../../components/RoundButton.vue'
import DefaultAvatar from '../../components/DefaultAvatar'
import { publicProfilesUrl } from '../../settings'

export default {
  name: 'ProfileCardMobileBasic',
  props: {
    profile: {
      type: Object,
      default: function () {
        return {}
      }
    },
    detail: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isActive: {
      type: Boolean,
      default: false
    },
    hoverStyle: {
      type: Boolean,
      default: true
    },
    inProfile: {
      type: Boolean,
      default: false
    },
    showMore: {
      type: Boolean,
      default: true
    }
  },
  components: {
    RoundButton,
    DefaultAvatar
  },
  computed: {
    ...mapState([
      'workspace'
    ]),
    ...mapState({
      ownProfile: (state) => { return state.profile }
    }),
    myprofile () {
      return this.profile.id === this.user.uid
    },
    flexDisplay () {
      const flexschedule = '' + (this.profile.flexschedule || '3')
      if (flexschedule.indexOf('Days') === -1) {
        return flexschedule + ' Days'
      }
      return flexschedule
    },
    flexStyle () {
      const flexschedule = this.profile.flexschedule || '3'
      return ('' + flexschedule).replace(' Days', '').replace('.', '-')
    }
  },
  data () {
    return {
      hover: false,
      shareopen: false,
      publicProfilesUrl,
      bookmarked: false
    }
  },
  mounted () {
    this.bookmarked = this.isBookmarked()
  },
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    avatarClick () {
      this.$emit('avatar')
    },
    isBookmarked () {
      if (this.ownProfile.bookmarks) {
        return this.profile.id in this.ownProfile.bookmarks
      }
      return false
    },
    bookmark () {
      if (!this.ownProfile.bookmarks) {
        this.ownProfile.bookmarks = {}
      }
      this.ownProfile.bookmarks[this.profile.id] = { type: 'profile' }
      this.bookmarked = true
      this.updateProfile({ profile: { bookmarks: this.ownProfile.bookmarks }, noSnack: true })
    },
    unbookmark () {
      if (this.ownProfile.bookmarks) {
        delete this.ownProfile.bookmarks[this.profile.id]
      }
      this.bookmarked = false
      this.updateProfile({ profile: { bookmarks: this.ownProfile.bookmarks }, noSnack: true })
    },
    analytics (payload) {
      // eslint-disable-next-line no-undef
      // analytics.track('shareProfile', {
      //  id: this.profile.id,
      //  platform: payload
      // })
    }
  }
}
</script>

<style scoped>
.dot-spacer {
  position: relative;
  top: 2px;
}
.profile-card-mobile .bookmark-icon {
  min-width:20px;
  width:20px;
  min-height:20px;
  height:20px;
}
.profile-card-mobile {
  margin-left: auto;
  margin-right: auto;
}
.profile-card-mobile .front {
  display:flex;
  flex-direction: row;
  align-items: center;
}
.front .mdc-card {
  height: 82px;
  width:124px;
  min-width:124px;
  max-width:unset;
}

.profile-card-mobile .profile-image {
  width: 65%;
}

.profile-card-mobile .profile-text {
    padding-top:0 !important;
}

.displayname {
  display:inline-block;
  overflow-wrap: break-word;
  max-width:100%;
}
.sharemenu {
  min-width: 170px;
}
/* entire container, keeps perspective */

.open-card-design .mdc-dialog__body .profile-card {
  width: 46%;
  display: inline-block;
  margin: 2%;
  cursor: pointer;
}

.profile-stat .hover-info {
  display:none;
  position:absolute;
  color:#fff;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  margin-top: 40px;
  border-radius:5px;
  font-size:12px;
  z-index:9999;
}
.profile-stat:hover .hover-info {
  display:flex;
}

.flip-container {
  perspective: 1000px;
  transform-style: preserve-3d;
}

/*  flip the pane when hovered */
.flip-container.hover-enabled:hover .back, .flip-container.hover-enabled.hover .back {
  transform: rotateY(0deg);
}
.flip-container.hover-enabled:hover .front, .flip-container.hover-enabled.hover .front  {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 100%;
  min-height:80px;
  height: auto;
}

.no-swapper {
  padding-left:16px;
  padding-right:16px;
}

.swapper .back .profile .back-profile-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.5);
  color:#fff !important;
  position:absolute;
  overflow: hidden;
  z-index:1;
}

.swapper .back .profile .back-profile-overlay p {
  color:#fff !important;
}

.swapper .back .profile .profile-card-design {
  height:100%;
  width:100%;
  position:absolute;
  background:none;
  filter:blur(1px);
}

/* hide back of pane during swap */
.flipper .front, .flipper .back {
  backface-visibility: hidden;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  top: 0;
  left: 0;
}

.back {
  margin-top:-65%;
}

.back .profile-bg {
  filter:blur(1px);
  margin-left:-2px;
}

.flipper .back .profile .back-profile-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.3);
  color:#fff !important;
  position:absolute;
  overflow: hidden;
  z-index:1;
}

/* front pane, placed above back */
.flipper .front {
  z-index: 2;
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.flipper .back {
  transform: rotateY(-180deg);
}

.flipper .back .profile .profile-card-design {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  filter:blur(1px);
}

.back .profile .back-profile-overlay .back-profile-content{
  margin-top:16px;
  margin-left:6px;
  margin-right:6px;
  overflow: hidden;
}

.profile-wrapper {
  background-color:white;
  border-radius: 13px;
  width:100%;
  overflow: hidden;
  position: relative;
}

.safari .profile-wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.profile, .profile-bg {
  display:flex;
  flex-flow: row;
  position: absolute;
  top: 0px;
  left: 0;
  width: 101%;
  height: 100%;
  align-items: center;
}

.profile {
  top:-8px;
  background-size: cover;
  height:calc(100% + 16px);
}

/* Background position this goes on the same div as profile-bg class */
div[class*="profile-bg-"] .profile-text {
  padding-bottom: 0px;
  padding-left:0px;
}

div[class*="profile-bg-"] .profile-text img {
  display: none;
}

.profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #DEDBD6 0%);
}
.profile-bg-top {
  background: linear-gradient(#F2EEE6 85%, transparent 0%);
}
.profile-bg-top .profile-text {
  padding-top: 0;
  padding-bottom: 20px;
}
.profile-card:hover .profile-bg-top {
  background: linear-gradient(#DEDBD6 85%, transparent 0%);
}
.profile-bg-bottom {
  background: linear-gradient(transparent 40%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-bottom {
  background: linear-gradient(transparent 40%, #DEDBD6 0%);
}
.profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #DEDBD6 0%);
}

div[class*="profile-bg-"] .profile-text {
  padding-bottom: 0px;
}

div[class*="profile-bg-"] .profile-text img {
  display: none;
}

/* Background colour/bg options this goes on the same div a profile class */
.profile-colour-cyan {
  background-color: #2cbcb6;
}
.profile-colour-magenta {
  background-color: #C10457;
}
.profile-colour-orange {
  background-color: #f4854d;
}
.profile-colour-yellow {
  background-color: #ffd12b;
}
.profile-colour-blue {
  background-color: #3560AF;
}
.profile-design-1-cyan {
  background-image: url('/static/img/card-designs/profile-design-1-cyan.svg');;
}
.profile-design-1-magenta {
  background-image: url('/static/img/card-designs/profile-design-1-magenta.svg');
}
.profile-design-1-orange {
  background-image: url('/static/img/card-designs/profile-design-1-orange.svg');
}
.profile-design-1-yellow {
  background-image: url('/static/img/card-designs/profile-design-1-yellow.svg');
}
.profile-design-2-cyan {
  background-image: url('/static/img/card-designs/profile-design-2-cyan.svg');
}
.profile-design-2-white {
  background-image: url('/static/img/card-designs/profile-design-2-white.svg');
}
.profile-design-2-magenta {
  background-image: url('/static/img/card-designs/profile-design-2-magenta.svg');
}
.profile-design-2-orange {
  background-image: url('/static/img/card-designs/profile-design-2-orange.svg');
}
.profile-design-2-yellow {
  background-image: url('/static/img/card-designs/profile-design-2-yellow.svg');
}
.profile-design-3-cyan {
  background-image: url('/static/img/card-designs/profile-design-3-cyan.svg');
}
.profile-design-3-magenta {
  background-image: url('/static/img/card-designs/profile-design-3-magenta.svg');
}
.profile-design-3-orange {
  background-image: url('/static/img/card-designs/profile-design-3-orange.svg');
}
.profile-design-3-yellow {
  background-image: url('/static/img/card-designs/profile-design-3-yellow.svg');
}
.profile-design-4-cyan {
  background-image: url('/static/img/card-designs/profile-design-4-cyan.svg');
}
.profile-design-4-magenta {
  background-image: url('/static/img/card-designs/profile-design-4-magenta.svg');
}
.profile-design-4-orange {
  background-image: url('/static/img/card-designs/profile-design-4-orange.svg');
}
.profile-design-4-yellow {
  background-image: url('/static/img/card-designs/profile-design-4-yellow.svg');
}
.profile-design-5-cyan {
  background-image: url('/static/img/card-designs/profile-design-5-cyan.svg');
}
.profile-design-5-white {
  background-image: url('/static/img/card-designs/profile-design-5-white.svg');
}
.profile-design-5-magenta {
  background-image: url('/static/img/card-designs/profile-design-5-magenta.svg');
}
.profile-design-5-orange {
  background-image: url('/static/img/card-designs/profile-design-5-orange.svg');
}
.profile-design-5-yellow {
  background-image: url('/static/img/card-designs/profile-design-5-yellow.svg');
}
.profile-design-6-cyan {
  background-image: url('/static/img/card-designs/profile-design-6-cyan.svg');
}
.profile-design-6-white {
  background-image: url('/static/img/card-designs/profile-design-6-white.svg');
}
.profile-design-6-magenta {
  background-image: url('/static/img/card-designs/profile-design-6-magenta.svg');
}
.profile-design-6-orange {
  background-image: url('/static/img/card-designs/profile-design-6-orange.svg');
}
.profile-design-6-yellow {
  background-image: url('/static/img/card-designs/profile-design-6-yellow.svg');
}
.profile-design-6-multi {
  background-image: url('/static/img/card-designs/profile-design-6-multi.svg');
}
.profile-design-7-cyan {
  background-image: url('/static/img/card-designs/profile-design-7-cyan.svg');
}
.profile-design-7-white {
  background-image: url('/static/img/card-designs/profile-design-7-white.svg');
}
.profile-design-7-magenta {
  background-image: url('/static/img/card-designs/profile-design-7-magenta.svg');
}
.profile-design-7-orange {
  background-image: url('/static/img/card-designs/profile-design-7-orange.svg');
}
.profile-design-7-yellow {
  background-image: url('/static/img/card-designs/profile-design-7-yellow.svg');
}

.avatar {
  width: 80%;
  margin: 10%;
  padding-top: 80%;
  position: relative;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: block;
  overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;;
    left: 0;
    object-fit: cover;
}
.profile-text {
  width: 52%;
  height: 50%;
  text-align: left;
  padding: 0;
  padding-left:26px;
  overflow: hidden;
}
.link-row {
  display: flex;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
}
.link-row-top > div {
  flex: 0;
}
.profile-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
}
.profile-headline {
  font-style:italic;
  font-size: 14px;
  color: #333333;
}
.profile-card .profile-image {
  position:relative;
}
.profile-card .mdc-body.profile-status {
  max-width: calc(100% + 12px);
  overflow: hidden;
}
.profile-card .profile-image .profile-status {
  color:gray;
}
.profile-card .profile-image.editable:hover {
  cursor:pointer;
}
.profile-card .profile-image.editable .overlay{
  display:none;
  position:absolute;
  width:100%;
  height:100%;
}
.profile-card .profile-image.editable:hover .overlay{
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/424395/camera.svg");
  background-size: 50px 41px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
  z-index:9;
  display:block;
}
.profile-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.profile-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1.1;
}
span[class^="days-"] {
  margin-top: 3px;
  width: 55px;
  white-space: nowrap;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 8px;
  margin-left: 1.5px;
  margin-right: 1.5px;
}
span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}

span.days-1-5 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2-5 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3-5 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4-5 .day:nth-of-type(-n+4) {
  background: #AD1457;
}

span.days-0-5 .day:nth-of-type(1) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-1-5 .day:nth-of-type(2) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-2-5 .day:nth-of-type(3) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-3-5 .day:nth-of-type(4) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-4-5 .day:nth-of-type(5) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}

.profile-status-bar {
  display:flex;
  width:100%
}
.profile-details {
  padding: 0% 16px 0 16px;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.profile-details .duome-icon img {
  width:20px;
  height: 20px;
}
.profile .edit-icon {
  background: #fff;
  position: absolute;
  top: 18px;
  right: 17px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0;
}
.profile .edit-icon .mdc-icon.mdc-icon--material {
  font-size: 20px;
  line-height: 25px;
  color: #888;
  margin-right: 0;
}
.profile .edit-icon:hover {
  cursor:pointer;
}
.profile .edit-icon + .hover-info {
  display:none;
  position:absolute;
  color:white;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  border-radius:5px;
  font-size:12px;
  z-index: 101;
  left: 30px;
  bottom: 25px;
}
.profile .edit-icon:hover + .hover-info {
  display:flex;
}
.profile-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
    display: inline;
}
.profile-details .field {
  font-style: italic;
  margin-top: 3px;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.location .profile-stat .value {
  font-style:italic;
  font-size: 14px;
}
.role {
  font-weight: 500;
  font-style:italic;
  font-size: 14px;
}
.mdc-icon {
  margin-right: 5px;
}
.profile-card:not(:hover) .link-row-bottom .mdc-button {
  background-color: #ffffff;
  box-shadow: none;
}
.profile-card .link-row-bottom .mdc-button .mdc-icon {
  margin: 0 10px 5px 0;
}
.profile-details .mdc-button img {
  margin-right:6px;
}
.profile-details .mdc-button img.icon-active {
  display:none;
}
.profile-card.hover:hover .profile-wrapper {
  background: #F6F6F6;
}
.about > div {
  font-size: 14px;
  margin: 10px 0;
}
.action {
  cursor: pointer;
}
.bookmark {
  cursor: pointer;
  color: #333333;
  margin-top: 0px;
  margin-bottom: 50px;
  width: 26px;
  min-width: 26px;
  height: 26px;
  min-height: 26px;
  margin-left: auto;
  margin-right: 8px;
}
.modal .mdc-dialog__container {
  width: calc(100% - 20px);
  min-width: 340px !important;
  max-width: 885px !important;
}

@media (max-width: 570px) {
  .no-swapper {
    padding-left:0px;
    padding-right:0px;
  }
  .profile-details {
    padding: 0;
  }
}
</style>

<style>
/** Not Scoped for hover styling sub component **/

.profile-card .open-card-design .mdc-dialog__surface {
  height: calc(100vh - 80px);
  min-height: 400px;
}
.profile-card .open-card-design .mdc-dialog__body--scrollable {
  height:100%;
  max-height:100%;
}
.profile-card .button-row {
  display: flex;
  align-items: center;
}
.profile-card .button-row .verify {
  color:#b00020;
  margin-left:8px;
}

.profile-card .button-row .verifed {
  color:#00b020;
  margin-left:8px;
}
</style>
