<template>
  <section id="commentsection" @click.prevent.stop role="section" aria-label="comment-box">
      <div v-if="commentMode" class="block-comment-box">
        <div class="block-comments">
          <template v-for="(comment, index) in lookingForwardBlockComments" >
            <div :ref="'looking-forward' + comment.id" class="comment-wrapper" :key="comment.noteBlockId + index" v-if="comment.noteBlockId === selectedNoteBlock">
              <div class="comment-from-details">
                <profile-initials :profileId="comment.fromId" :date="appDate" showDisplayName/>
                <span v-if="comment.date" class="comment-time">
                  <time-ago refresh long :datetime="comment.date.toDate()"></time-ago>
                </span>
              </div>
              <div class="comment-message">
                {{comment.message}}
              </div>
            </div>
          </template>
        </div>
        <message-area class="block-message-area" allowMentions :mentions="teamMentions" @send="sendBlockComments" :placeholderText="'Type a comment'" :profileId="profile.id" :forDate="appDate" :sendTextLabel="'POST'" :disableSend="true" />
      </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProfileInitials from './ProfileInitials.vue'
import TimeAgo from '../TimeAgo'
import MessageArea from './MessageArea'

export default {
  name: 'PeopleUpdates',
  computed: {
    ...mapState({
      appDate: state => state.appDate,
      profile: state => state.profile,
      teamProfiles: state => state.teams.teamProfiles,
      lookingForwardBlockComments: state => state.teams.lookingForwardBlockComments
    }),
    teamMentions () {
      if (this.teamProfiles) {
        return this.teamProfiles.map((prof) => {
          return { ...prof, name: prof.displayName, initials: this.initials(prof) }
        })
      }
      return []
    }
  },
  components: {
    ProfileInitials,
    TimeAgo,
    MessageArea
  },
  data () {
    return {
    }
  },
  props: {
    commentMode: {
      type: Boolean,
      default: false
    },
    selectedNoteBlock: {},
    pageDetails: {}
  },
  methods: {
    ...mapActions({
      saveLookingForwardBlockComments: 'teams/saveLookingForwardBlockComments',
      updateLookingForwardPage: 'teams/updateLookingForwardPage'
    }),
    initials (profile) {
      if (profile) {
        let initialFirst = ''
        let initialLast = ''
        let displayNameParts = ['', '']
        displayNameParts = profile.displayName.split(' ')
        if (profile.firstName) {
          initialFirst = profile.firstName.trim().substring(0, 1)
        } else {
          initialFirst = displayNameParts[0].substring(0, 1)
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${initialFirst}${initialLast}`.toUpperCase()
      }
      return 'U'
    },
    getProfileFromId (id) {
      if (this.teamProfiles) {
        const profile = this.teamProfiles.filter(prof => prof.id === id)
        if (profile.length > 0) {
          return profile[0].displayName
        }
      }
      return ''
    },
    async sendBlockComments (message) {
      const comment = {
        fromId: message.fromId,
        message: message.text,
        created: new Date(),
        date: new Date(),
        mentions: message.mentions || [],
        pageId: this.pageDetails.id,
        type: 'Looking Forward'
      }
      await this.saveLookingForwardBlockComments({
        teamId: this.profile.team,
        noteBlockId: this.selectedNoteBlock,
        commentId: comment.id,
        comment
      })
      const blockCommentCount = this.pageDetails.noteBlocksCommentCount && this.pageDetails.noteBlocksCommentCount[this.selectedNoteBlock] && this.pageDetails.noteBlocksCommentCount[this.selectedNoteBlock].commentCount ? this.pageDetails.noteBlocksCommentCount[this.selectedNoteBlock].commentCount + 1 : 1
      const noteBlock = this.pageDetails.noteBlocksCommentCount || {}
      noteBlock[this.selectedNoteBlock] = { commentCount: blockCommentCount }
      const lookingForwardPage = { ...this.pageDetails, noteBlocksCommentCount: noteBlock }
      this.updateLookingForwardPage({ teamId: this.profile.team, lookingForwardPageId: this.pageDetails.id, lookingForwardPage, saveComment: true })
      this.$emit('updateLookingForwardBlock', blockCommentCount)
    }
  }
}
</script>

<style lang="scss">
#commentsection {
  .block-comment-box {
    margin-top: 15px;
    padding: 16px 10px;
    background: rgba(234,234,234,0.26);
    .block-comments {
      overflow: auto;
      height: auto;
      max-height: 140px;
      cursor: default;
      margin-bottom: 10px;
      & > div.comment-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        .comment-from-details {
          display: flex;
          align-items: center;
        }
      }
      .mdc-text.mdc-typography--body2 {
        color: #333333;
        font-weight: 700;
      }
      .comment-time {
        color: rgba(51,51,51,0.4);
        font-size: 12px;
      }
      .profile-initials {
        margin: 0;
        margin-left: 2px;
        margin-top: 2px;
      }
      .photo-wrapper {
        margin-left: 2px;
        margin-top: 2px;
        .profile-initials {
          margin: 0;
        }
      }
      .comment-message {
        color: #404040;
        font-size: 14px;
        margin-left: 26px;
        line-height: 22px;
        margin-top: 3px;
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #828282;
      }
      > :last-child {
        margin-bottom: 5px;
      }
    }
    .block-message-area {
      display: flex;
      align-items: center;
      border: 0.5px solid #828282;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 7px 10px;
      background: #ffffff;
      .profile-redirect {
        margin: auto;
      }
    }
  }
  .block-comment-section {
    display: none;
  }
}
</style>
<style lang="scss">
.comment-from-details {
  .profile-redirect {
    .profile-display-name {
      color: #404040;
      font-weight: 600;
    }
  }
}
</style>
