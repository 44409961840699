<template>
  <section v-if="show && (header || text)" role="section" aria-label="onboarding-tooltip" class="onboarding-tooltip-wrapper" :class="direction">
    <div class="tooltip-header">
        <mdc-text typo="body2" tag="div" >{{header}}</mdc-text>
        <base-svg-icon v-if="closeable" name="cross-thin-white" class="close-icon" @click.native="closeTooltip" clickable />
    </div>
    <mdc-text typo="body2" tag="div" >{{text}}</mdc-text>
    <div class="tooltip-footer" v-if="steps">
      <base-svg-icon name="thin-left-arrow-grey" v-if="backdDisabled" label="back step disabled" class="back-step-icon disabled" />
      <base-svg-icon name="thin-left-arrow-white" v-else label="back step" class="back-step-icon" @click.native.stop.prevent="goBackward()" clickable />
      <div :title="step + ' of ' + steps" class="steps">
        <div class="step" v-for="stepVal in parseInt(steps)" :class="{ current: step === stepVal }" :key="'step' + stepVal"></div>
      </div>
      <base-svg-icon name="thin-right-arrow-grey" v-if="forwardDisabled" label="forward step disabled" class="forward-step-icon disabled" />
      <base-svg-icon name="thin-right-arrow-white" v-else label="forward step" class="forward-step-icon" @click.native.stop.prevent="goForwards()" clickable />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from '../../components/flexibly/BaseSvgIcon.vue'

export default {
  name: 'OnboardingTooltip',
  components: {
    BaseSvgIcon
  },
  props: {
    header: {
      type: String
    },
    text: {
      type: String
    },
    direction: {
      type: String
    },
    closeable: {
      type: Boolean,
      default: true
    },
    steps: {},
    step: {}
  },
  computed: {
    ...mapState([
      'profile',
      'onboardingStage'
    ]),
    forwardDisabled () {
      if (this.steps && this.step) {
        return this.step >= this.steps
      }
      return true
    },
    backdDisabled () {
      if (this.steps && this.step) {
        return this.step <= 1
      }
      return true
    }
  },
  data () {
    return {
      show: true
    }
  },
  beforeDestroy () {},
  created () {},
  mounted () {},
  methods: {
    ...mapActions({
    }),
    closeTooltip () {
      this.show = false
    },
    openTooltip () {
      this.show = true
    },
    goBackward () {
      if (this.step > 1) {
        const val = this.step - 1
        this.$emit('change', val)
      }
    },
    goForwards () {
      if (this.step < this.steps) {
        const val = this.step + 1
        this.$emit('change', val)
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss">
  .onboarding-tooltip-wrapper {
    position:relative;
    background-color:#0055A3;
    padding:15px;
    width:fit-content;
    display:flex;
    flex-flow:column;
    min-width:200px;
    max-width:250px;
    border-radius: 6px;
    color:#ffffff;
    .mdc-text{
      color:#ffffff;
    }
    .tooltip-header {
      display:flex;
      flex-flow:row;
      align-items: flex-start;
      margin-bottom: 15px;
      .mdc-text {
        color:#ffffff;
        font-weight:700;
        margin-right:10px;
      }
      .close-icon {
        margin-left: auto;
        img {
          color: #ffffff;
        }
      }
    }
    &.left:before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right:8px solid #0055A3;
        left:-8px;
        top:20px;
    }
    &.right:before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left:8px solid #0055A3;
        right:-8px;
        top:20px;
    }
    &.top:before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom:8px solid #0055A3;
        left:50%;
        top:-8px;
    }
    &.bottom:before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top:8px solid #0055A3;
        left:50%;
        bottom:-8px;
    }
    .tooltip-footer {
      margin-top:20px;
      display:flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      .forward-step-icon, .back-step-icon {
        cursor:pointer;
        &.disabled {
          pointer-events: none;
        }
      }
      .steps {
        flex: 1;
        padding-left:10px;
        padding-right:10px;
        display:flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;

        .step {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: rgba(255,255,255,0.5);
          margin: 5px;
          &.current {
            background-color: #ffffff;
          }
        }
      }
    }
  }
</style>
