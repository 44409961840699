import { mergeAttributes, nodeInputRule, Node } from '@tiptap/core'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { Plugin, PluginKey } from 'prosemirror-state'

export const inputRegex = /((?:\[\[)((?:[^[\]]+)))/g
export const inputRegexFull = /((?:\[\[)((?:[^[]+))(?:\]\]))/g

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export const getPageLink = (linkText) => {
  return 'notepage/' + encodeURIComponent(linkText.replaceAll('[[', '').replaceAll(']]', '').replaceAll(' ', '-').replaceAll('.', ''))
}
export const getPageLabel = (linkText) => {
  return linkText.replaceAll('[[', '').replaceAll(']]', '')
}

const findLinkMatch = ({ char, regex, $position }) => {
  // cancel if top level node
  if ($position.depth <= 0) {
    return null
  }

  // Matching expressions used for later
  const regexp = new RegExp(regex)

  const textFrom = $position.before()
  const textTo = $position.pos
  const text = $position.doc.textBetween(textFrom, textTo, '\0', '\0')
  const match = Array.from(text.matchAll(regexp)).pop()
  if (!match || match.input === undefined || match.index === undefined) {
    return null
  }

  // JavaScript doesn't have lookbehinds; this hacks a check that first character is " "
  // or the line beginning
  const matchPrefix = match.input.slice(Math.max(0, match.index - 1), match.index)

  if (!/^[\s\0]?$/.test(matchPrefix)) {
    return null
  }

  // The absolute position of the match in the document
  const from = match.index + $position.start()
  const to = from + match[0].length

  // If the $position is located within the matched substring, return that range
  if (from < $position.pos && to >= $position.pos) {
    return {
      range: {
        from,
        to
      },
      query: match[0].slice(char.length),
      text: match[0]
    }
  }

  return null
}

const LinkSuggestion = ({
  editor,
  char = '[[',
  regex = '',
  decorationTag = 'span',
  decorationClass = 'suggestion',
  command = () => null,
  items = () => [],
  render = () => ({})
}) => {
  const renderer = render?.()

  return new Plugin({
    key: new PluginKey('linksuggestion'),

    view () {
      return {
        update: async (view, prevState) => {
          const prev = this.key?.getState(prevState)
          const next = this.key?.getState(view.state)

          // See how the state changed
          const moved = prev.active && next.active && prev.range.from !== next.range.from
          const started = !prev.active && next.active
          const stopped = prev.active && !next.active
          const changed = !started && !stopped && prev.query !== next.query
          const handleStart = started || moved
          const handleChange = changed && !moved
          const handleExit = stopped || moved

          // Cancel when suggestion isn't active
          if (!handleStart && !handleChange && !handleExit) {
            return
          }

          const state = handleExit ? prev : next
          const decorationNode = document.querySelector(`[data-decoration-id="${state.decorationId}"]`)
          const props = {
            editor,
            range: state.range,
            query: state.query,
            text: state.text,
            items: (handleChange || handleStart)
              ? await items(state.query)
              : [],
            command: commandProps => {
              command({
                editor,
                range: state.range,
                props: commandProps
              })
            },
            decorationNode,
            // virtual node for popper.js or tippy.js
            // this can be used for building popups without a DOM node
            clientRect: () => decorationNode?.getBoundingClientRect() || null
          }

          if (handleExit) {
            renderer?.onExit?.(props)
          }

          if (handleChange) {
            renderer?.onUpdate?.(props)
          }

          if (handleStart) {
            renderer?.onStart?.(props)
          }
        }
      }
    },

    state: {
      // Initialize the plugin's internal state.
      init () {
        return {
          active: false,
          range: {},
          query: null,
          text: null
        }
      },

      // Apply changes to the plugin state from a view transaction.
      apply (transaction, prev) {
        const { selection } = transaction
        const next = { ...prev }

        // We can only be suggesting if there is no selection
        if (selection.from === selection.to) {
          // Reset active state if we just left the previous suggestion range
          if (selection.from < prev.range.from || selection.from > prev.range.to) {
            next.active = false
          }

          // Try to match against where our cursor currently is
          const match = findLinkMatch({
            char,
            regex,
            $position: selection.$from
          })
          const decorationId = `id_${Math.floor(Math.random() * 0xFFFFFFFF)}`

          // If we found a match, update the current state to show it
          if (match) {
            next.active = true
            next.decorationId = prev.decorationId ? prev.decorationId : decorationId
            next.range = match.range
            next.query = match.query
            next.text = match.text
          } else {
            next.active = false
          }
        } else {
          next.active = false
        }

        // Make sure to empty the range if suggestion is inactive
        if (!next.active) {
          next.decorationId = null
          next.range = {}
          next.query = null
          next.text = null
        }

        return next
      }
    },

    props: {
      // Call the keydown hook if suggestion is active.
      handleKeyDown (view, event) {
        const { active, range } = this.getState(view.state)

        if (!active) {
          return false
        }

        return renderer?.onKeyDown?.({ view, event, range }) || false
      },

      // Setup decorator on the currently active suggestion.
      decorations (state) {
        const { active, range, decorationId } = this.getState(state)

        if (!active) {
          return null
        }

        return DecorationSet.create(state.doc, [
          Decoration.inline(range.from, range.to, {
            nodeName: decorationTag,
            class: decorationClass,
            'data-decoration-id': decorationId
          })
        ])
      }
    }
  })
}

export const PageLink = Node.create({
  name: 'pagelink',

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addOptions () {
    return {
      HTMLAttributes: {
        target: '',
        class: 'pagelink'
      },
      suggestion: {
        // eslint-disable-next-line no-useless-escape
        char: '[[',
        regex: inputRegex,
        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, { type: 'pagelink', attrs: props })
            .insertContent(' ')
            .run()
        }
      }
    }
  },

  renderHTML ({ node, HTMLAttributes }) {
    const linkId = node.attrs['data-link-id']
    const backupLinkText = node.attrs['data-link-text']
    let link = { pageName: backupLinkText }
    if (this.options.getPageLinkById) {
      link = this.options.getPageLinkById(linkId) || { pageName: backupLinkText }
    }
    // console.log('PageLinkSuggestion.renderHTML', this.options.HTMLAttributes, HTMLAttributes, linkId, link)
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), `${link.pageName}`]
  },

  renderText ({ node }) {
    const linkId = node.attrs['data-link-id']
    const backupLinkText = node.attrs['data-link-text']
    let link = { pageName: backupLinkText }
    if (this.options.getPageLinkById) {
      link = this.options.getPageLinkById(linkId) || { pageName: backupLinkText }
    }
    return `[[${link.pageName}]]`
  },

  parseHTML () {
    return [
      { tag: 'span[data-link-id]' }
    ]
  },

  addAttributes () {
    return {
      'data-link-id': {
        default: null
      },
      'data-link-text': {
        default: ''
      }
    }
  },

  addKeyboardShortcuts () {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isPagelink = false
        const { selection } = state
        const { empty, anchor } = selection

        if (!empty) {
          return false
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === 'pagelink') {
            isPagelink = true
            tr.insertContent(this.options.suggestion.char || '', pos, pos + node.nodeSize)
            return false
          }
        })

        return isPagelink
      })
    }
  },

  addInputRules () {
    const ext = this
    return [
      nodeInputRule({
        find: inputRegexFull,
        type: this.type,
        getAttributes (url) {
          let pageId = uuidv4()
          let pageLabel = getPageLabel(url[0])
          const pageLink = getPageLink(url[0])
          const items = ext.options.suggestion.items(pageLabel)
          // console.log('PageLinkSuggestion.nodeInputRule', pageLabel)
          if (items) {
            if (items[0]) {
              if (items[0].pageName === pageLabel) {
                pageId = items[0].id
                pageLabel = items[0].pageName
                // console.log('PageLinkSuggestion.nodeInputRule from items', ext, pageLabel, items)
                return { 'data-link-id': pageId, 'data-link-text': pageLabel }
              }
            }
          }

          if (ext) {
            if (ext.options) {
              if (ext.options.onPageLinkCreate) {
                // console.log('PageLinkSuggestion.nodeInputRule from create', ext, pageLabel, items)
                ext.options.onPageLinkCreate({ id: pageId, link: pageLink, label: pageLabel })
                return { 'data-link-id': pageId, 'data-link-text': pageLabel }
              } else {
                return { 'data-link-id': pageId, 'data-link-text': pageLabel }
              }
            } else {
              return { 'data-link-id': pageId, 'data-link-text': pageLabel }
            }
          } else {
            return { 'data-link-id': pageId, 'data-link-text': pageLabel }
          }
        }
      })
    ]
  },

  addProseMirrorPlugins () {
    return [
      LinkSuggestion({
        editor: this.editor,
        ...this.options.suggestion
      })
    ]
  }

})

export default PageLink
