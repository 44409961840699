<template>
  <header>
    <div class="main-content search-bar-detailed">
      <div class="main-wrapper">

        <div class="mdc-top-app-bar searchbar-detailed mdc-top-app-bar--dense mdc-top-app-bar--fixed" style="flex-flow:column;">
          <div style="width:100%;display:flex;flex-flow:row;align-items:flex-start">
            <span class="nav-icon-wrapper">

              <mdc-button style="padding:0;min-width:0;" v-if="back" class="backbutton" @click="goBack">
                <mdc-animated-icon class="desktopicon" firstIcon="blank" :selected="backIconSelected" />
                <mdc-animated-icon style="margin-right:8px;" firstIcon="menu" class="tableticon" :selected="backIconSelected" />
              </mdc-button>

              <mdc-button v-else class="burgerbar" @click="toggleSidebar">
                <mdc-animated-icon :selected="0" />
              </mdc-button>
            </span>

            <span class="before-wrapper">
              <span
                v-for="(item, index) in beforeFiltered"
                :key="index"
                >
                <mdc-icon v-if="item.icon" :icon="item.icon" />
              </span>
            </span>
            <div style="flex:1; display:flex; position:relative">
            <auto-complete-outline
              ref="searchInput"
              format="image-text"
              imagefield="icon"
              @keyup.escape="onClear"
              @keyup.backspace="onDelete"
              @keyup.delete="onDelete"
              @set="onSearch"
              autocompleteId="'search'"
              class="searchbar_field"
              v-model="search"
              field="name"
              :placeholder="placeholder"
              :items="allSuggestions"
              @focus="onFocus"
              @blur="onBlur"
              :onEnterSelectTop="false"
              :min="1"
              box
              dense>
            </auto-complete-outline>
            <round-button noHover title="Clear" background="transparent" class="clear-button" v-if="search || value || searchFilter" @click="onClear" icon="clear" />
            </div>
            <span class="after-wrapper">
              <span
                v-for="(item, index) in afterFiltered"
                :key="index">
                <mdc-icon v-if="item.icon" :icon="item.icon" />
                <mdc-select @change="$emit('filter' + item.filter.label, $event)" v-else-if="item.filter" :label="item.filter.label">
                  <select-item v-for="(opt, index) in item.filter.options" :key="index" :value="opt" />
                </mdc-select>
                <span class="profile" v-else-if="item.profile">
                  <img style="cursor:pointer;margin-right:0" @click="showDetail = !showDetail" v-if="item.profile.photoURL" class="avatar" :src="item.profile.photoURL">
                  <img style="cursor:pointer;margin-right:0" @click="showDetail = !showDetail" v-else class="avatar" src="/static/img/user.jpg">
                  <div class="profile-detail-wrapper mobile-only">
                    <div class="profile-detail" :class="{'show-detail': showDetail}">
                      <div class="detail-name">{{item.profile.displayName}}</div>
                      <div class="detail-status">{{item.profile.status}}</div>
                    </div>
                  </div>
                  <mdc-chip-set class="desktop-only" v-if="item.profile.status">
                    <mdc-chip class="status">{{item.profile.status}}</mdc-chip>
                  </mdc-chip-set>
                </span>
                <component
                v-else-if="item.component"
                v-bind:is="item.component"
                v-bind="item.props"
                >
                </component>
              </span>
            </span>
          </div>

          <div style="width:100%;" v-show="focused">

            <div v-if="profile" v-show="search === ''">
              <mdc-body typo="body2" style="color:#666666;margin-bottom:6px;margin-top:4px;">Recent Searches</mdc-body>
              <div v-if="profile.recentSearches" style="display:flex;flex-flow:column;margin-bottom:12px;">
                  <span v-for="(rs, index) in profile.recentSearches" :key="index" @click="onSearch({ name: rs })"
                  class="recent-search">
                    <mdc-icon style="color:#666666;margin-left:13px;margin-right:12px;" icon="search"/>
                    <mdc-body typo="body2" tag="span"
                    class="recent-row">{{rs}}</mdc-body>
                    <round-button noHover title="Clear" background="transparent" style="margin:0;margin-left:auto;" @click.prevent.stop="removeRecent(index)" icon="clear" />
                  </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
   </header>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import AutoCompleteOutline from '../components/AutoCompleteOutline.vue'
export default {
  name: 'AppSearchDetailed',
  data () {
    return {
      tabindex: 1,
      searchable: false,
      search: '',
      results: [],
      spellcheck: {},
      highlighting: {},
      profileResults: [],
      postResults: [],
      posthighlighting: {},
      jobResults: [],
      searchTimer: null,
      backIconSelected: 0,
      showDetail: false,
      allSuggestions: [],
      focused: false,
      postSearchDisabled: true,
      backselected: 1
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    before: Array,
    after: Array,
    context: String,
    desktop: Boolean,
    back: Boolean,
    backUrl: String,
    backAction: String,
    searchFilter: {
      type: String,
      default: ''
    },
    filterJobs: {
      type: Boolean,
      default: false
    },
    filterPartners: {
      type: Boolean,
      default: false
    },
    filterPosts: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AutoCompleteOutline
  },
  mounted () {
  },
  computed: {
    ...mapState([
      'profile',
      'profiles',
      'roles',
      'industries',
      'locations'
    ]),
    placeholder () {
      if (this.searchFilter && this.value) {
        return `${this.value} ● ${this.searchFilter}`
      } else if (this.value) {
        return `${this.value}`
      } else if (this.searchFilter) {
        return `Search ${this.searchFilter} ...`
      } else {
        return 'Search For ...'
      }
    },
    beforeFiltered () {
      if (this.before) {
        return this.before.filter(before => before)
      }
      return []
    },
    afterFiltered () {
      if (this.after) {
        return this.after.filter(after => after)
      }
      return []
    },
    hasResults () {
      let resultsCount = 0
      if (this.profileResults) {
        resultsCount += this.profileResults.length
      }
      if (this.postResults) {
        resultsCount += this.postResults.length
      }
      if (this.results) {
        resultsCount += this.results.length
      }
      if (this.jobResults) {
        resultsCount += this.jobResults.length
      }
      return resultsCount > 0
    }
  },
  methods: {
    ...mapActions([
      'updateProfile',
      'setPageLoad'
    ]),
    goBack () {
      this.backselected = 0
      setTimeout(() => {
        if (this.backAction) {
          // console.log('goBack', this.backAction)
          if (this.backAction === 'clearFilter') {
            this.onClear()
          }
        } else if (this.backUrl) {
          this.$router.push(this.backUrl).catch(err => {
            console.error('AppSearchDetailed.goBack Error', this.backUrl, err)
          })
        } else {
          this.$router.go(-1)
        }
      }, 200)
    },
    searchCollation () {
      this.search = this.spellcheck.collations[1]
      this.onSearch()
    },
    setSuggestions () {
      let all = []

      if (this.roles) {
        all = [...this.roles.map(x => {
          x.suggestionType = 'role'
          x.icon = '/static/img/icons/people_outline_light.svg'
          x.name = x.role
          return x
        })]
      }
      if (this.industries) {
        all = [...all, ...this.industries.map(x => {
          x.suggestionType = 'industry'
          x.icon = '/static/img/icons/business-outline-light.svg'
          return x
        })]
      }
      if (this.locations) {
        all = [...all, ...this.locations.map(x => {
          x.suggestionType = 'location'
          x.icon = '/static/img/icons/location_outline_light.svg'
          return x
        })]
      }
      this.allSuggestions = all
    },
    removeRecent (index) {
      if (this.profile.recentSearches) {
        if (this.profile.recentSearches[index]) {
          this.profile.recentSearches.splice(index, 1)
          this.updateProfile({ profile: { recentSearches: this.profile.recentSearches }, noSnack: true })
        }
      }
    },
    async onSearch (val) {
      this.$refs.searchInput.blur()
      if (val) {
        this.search = val.name
      }

      this.search = `${this.search || ''}`.trim()

      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }

      if (!this.profile.recentSearches) {
        this.profile.recentSearches = []
      }
      if (!this.profile.recentSearches.includes(this.search) && (this.search !== '')) {
        if (this.profile.recentSearches.length > 2) {
          this.profile.recentSearches = this.profile.recentSearches.slice(0, 2)
        }
        this.profile.recentSearches.unshift(this.search)
        this.updateProfile({ profile: { recentSearches: this.profile.recentSearches }, noSnack: true })
      }
      // console.log('AppSearchDetailed.onSearch', this.search)
      this.$emit('search', `${this.search}`)
      this.search = ''
    },

    async onClear () {
      this.search = ''
      // console.log('AppSearchDetail.onClear')
      this.$emit('clearFilter', 'jobs')
      this.$emit('clear')
      this.onBlur()
    },
    activate () {
      this.searchable = true
    },
    deactivate () {
      this.searchable = false
    },
    toggleSidebar () {
      this.$root.$emit('toggleSidebar')
    },
    filterAll () {
      this.emitResults()
    },
    onBlur () {
      this.$emit('hideBottomNav', false)
      if (this.search === this.value) {
        this.search = ''
      }
      const self = this
      setTimeout(() => {
        self.focused = false
      }, 300)
    },
    onFocus () {
      if (this.search === '') {
        this.search = this.value
      }
      this.$emit('hideBottomNav', true)
      this.focused = true
      if (this.allSuggestions.length === 0) {
        this.setSuggestions()
      }
    },
    onDelete (event) {
      if (this.search === '') {
        this.$emit('input', this.search)
      }
    }
  },
  watch: {
    back: {
      handler () {
        if (this.backIconSelected !== this.back) {
          this.$nextTick(() => {
            if (this.back) {
              this.backIconSelected = 1
            } else {
              this.backIconSelected = 0
            }
          })
        }
      },
      immediate: true
    },
    backselected: {
      handler () {
        if (this.backIconSelected !== this.backselected) {
          this.$nextTick(() => {
            if (this.backselected) {
              this.backIconSelected = 1
            } else {
              this.backIconSelected = 0
            }
          })
        }
      },
      immediate: true
    },
    search: function (news, olds) {
      if (this.search) {
        this.$emit('input', this.search)
      }
    },
    filterJobs: function () {
      const hasResults = this.jobResults.length || this.profileResults.length
      if (hasResults) {
        this.emitResults()
      }
    },
    filterPartners: function () {
      const hasResults = this.jobResults.length || this.profileResults.length
      if (hasResults) {
        this.emitResults()
      }
    }
  }
}
</script>
<style>
.main-content.search-bar-detailed {
  height:0px;
  overflow: visible;
  min-height: unset;
}
.main-content.search-bar-detailed .main-wrapper {
  margin-left:0 !important;
  z-index: 100;
}

.search-bar-detailed .mdc-text-field-helper-line {
  margin-bottom:2px !important;
}

.mdc-top-app-bar.searchbar-detailed {
  color: #333333;
}

.mdc-top-app-bar.searchbar-detailed .autocomplete-results {
    position: relative;
    border: none;
    box-shadow: none;
    margin-top: 45px;
    height: auto !important;
    min-width: 100%;
    max-height:190px;
}

.mdc-top-app-bar.searchbar-detailed .autocomplete {
  min-height: 32px;
  padding-top:0px;
  margin-top:0px;
  margin-bottom:2px;
  padding-bottom:0;
}

.mdc-top-app-bar.searchbar-detailed .autocomplete .autocomplete.mdc-text-field.mdc-text-field--upgraded.outlined{
  height:auto;
  min-height: 32px;
  padding-top:0px;
  margin-top:0px !important;
  margin-bottom:0px !important;
  padding-bottom:0px;
}

.mdc-top-app-bar.searchbar-detailed .autocomplete.mdc-text-field.outlined .mdc-text-field__input.autocomplete-input {
  padding-left:38px;
  background-image: url(/static/img/search-icon.svg);
  background-repeat: no-repeat;
  background-position: 10px 4px;
  background-size: 23px;
  background-color:#F5F5F5;
  font-size: 14px;
}

.mdc-top-app-bar.searchbar-detailed .autocomplete-result {
  padding: 1px 12px;
}

.mdc-top-app-bar.searchbar-detailed .autocomplete-result > div {
  display:flex;
  align-items: center;
  font-size: 14px;
  height:26px;
}

.mdc-top-app-bar.searchbar-detailed .nav-icon-wrapper {
  padding:0;
  margin-top:6px;
  margin-bottom:6px;
  margin-right: 6px;
  margin-left: -6px;
}

.mdc-top-app-bar.searchbar-detailed .recent-search {
  display:flex;
  border-left: 3px solid transparent;
  align-items: center;
  font-size: 14px;
}
.mdc-top-app-bar.searchbar-detailed .recent-search:hover {
  background-color: #F5F5F5 !important;
  border-left: 3px solid var(--mdc-theme-primary, #2D9CDB) !important;
}

.mdc-top-app-bar.searchbar-detailed .autocomplete.mdc-text-field.outlined .mdc-text-field__input.autocomplete-input,
.mdc-top-app-bar.searchbar-detailed .autocomplete .mdc-notched-outline {
  height:32px;
  padding-top:0px;
  padding-bottom:0px;
  max-width: 100%;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  margin-top:1px;
  margin-left:0;
}
.mdc-top-app-bar.searchbar-detailed .autocomplete .mdc-notched-outline {
    display:none
}

.mdc-top-app-bar.searchbar-detailed .autocomplete.mdc-text-field.outlined .mdc-text-field__input.autocomplete-input::placeholder {
  color: #979797 !important;
  opacity:1 !important;
}

.recent-row {
  color:#666666;
  cursor:pointer;
  font-style:italic;
  text-decoration:none;
  margin-right:16px;
  height:28px;
  display:flex;
  align-items: center;
  white-space:nowrap;
  flex:1;
}

.round-button.clear-button {
  margin-left: 6px;
  margin-right: 0px;
  position: absolute;
  right: 6px;
  z-index: 9999;
  margin-top: 6px;
  min-width: 28px;
  max-width: 28px;
  height: 28px;
}

.desktopicon {
  display:none !important;
}
@media (min-width: 840px) {
  .desktopicon {
    display:inline-block !important;
  }
  .tableticon {
    display:none !important;
  }
}
@media (min-width: 1500px) {
  .main-content.search-bar-detailed {
    max-width: 1200px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.profile-detail-wrapper {
  overflow: hidden;
  height:50px;
  top:34px;
  right:-18px;
  position:absolute;
  padding:6px;
}

.profile-detail {
  position:relative;
  top:-1px;
  display: inline-block;
  padding-left: 6px;
  padding-right: 6px;
  padding-top:6px;
  padding-bottom:4px;
  font-size: 0.8em;
  color: #999;
  background:white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.5s ease-in-out;
  top:-60px
}

.profile-detail.show-detail {
  top:-1px;
}

.profile-detail .detail-name {
  font-weight: 500;
}

.profile-detail .detail-status {
  font-style: italic;
  font-size: 0.85em;
}

.mdc-textfield-wrapper {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-flow: column;
  flex-flow: column;
  padding-top: 4px;
}
.searchbar_field .mdc-floating-label {
  top: 6px;
}
.searchbar_field .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  height: auto;
}
.searchbar-detailed {
  z-index:10;
}

.searchbar-detailed .backbutton::before, .searchbar-detailed .burgerbar::before {
  display:none !important;
}

.mdc-grid-tile {
  width: 50px;
}
.searchbar-detailed > span {
  padding-left:6px;
  padding-right:6px;
}
.searchbar-detailed .burgerbar {
  display:none;
  color: #7D7D7D;
  height:28px
}
.searchbar-detailed .backbutton {
  display:block;
  min-width: 24px;
  padding-left: 0;
  height:28px;
  color: #7D7D7D;
}

.searchbar-detailed .before-wrapper, .searchbar-detailed .after-wrapper {
  flex:0;
  flex-flow:row;
  display:flex;
  align-items: center;
  padding-right:0;
}
.searchbar-detailed .before-wrapper span, .searchbar-detailed .after-wrapper span{
  flex:0;
  flex-flow:row;
  justify-content: flex-start;
  position: relative;
}
.searchbar-detailed .after-wrapper span.profile .mdc-chip-set {
  padding: 3px;
}
.searchbar-detailed .mdc-layout-grid {
  padding-bottom: 0px;
}
.searchbar-detailed.mdc-top-app-bar {
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-flow:row;
  justify-content: flex-start;
  align-items: center;
  transition: all 250ms ease-in-out 0s;
  width: calc(100% - 240px - 4*(var(--mdc-layout-grid-margin-desktop, 24px)));
  max-width: 1152px;
  padding-left:12px;
  padding-right:12px;
}
.searchbar-results {
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 2px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-flow:column;
  justify-content: flex-start;
  align-items: left;
  top: 45px;
  position:absolute;
  transition: all 250ms ease-in-out 0s;
  width: calc(100% - 24px);
  max-width: 1152px;
  padding:13px;
  padding-top:0px;
  padding-bottom:0px;
  margin-left:-12px;
  height:auto !important;
  max-height:400px;
  overflow-y:auto;
  overflow-x:hidden;
}

.searchbar-results .mdc-grid-tile__primary {
  height: 50px;
  width: 50px;
  margin: 5%;
  position: relative;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: block;
  overflow: hidden;
  padding-bottom: 0;
}
.searchbar-detailed .searchbar_field {
  flex:1;
  justify-content: center;
}

.searchbar-detailed .profile {
  white-space: nowrap;
  display:flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  margin-right: 0px;
}
.searchbar-detailed .profile .avatar {
  height:26px;
  width:26px;
  position:absolute;
  margin: 6px 0 5px 11px;
  top: 0;
  left: 0;
}
.searchbar-detailed .profile .status {
  padding: 10px 12px 10px 18px;
  background-color: #abe0f0;
  position: relative;
  z-index: -1;
  margin: 0 4px;
  color: #333333;
  font-size: 14px;
  letter-spacing: 0px;
  padding-left: 36px;
}
.searchbar-detailed .mdc-select--box {
    margin: 4px 0 5px 0;
    height: 30px;
}
.searchbar-detailed .mdc-select--box select {
  height:30px;
  padding-top:4px;
  width: 150px;
  border-bottom: none;
}
.searchbar-detailed .mdc-select--box .mdc-floating-label {
    bottom: 4px;
    line-height: 1.5rem;
    color: #979797;
}
.searchbar-detailed .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-floating-label {
  color: #979797;
}

.mobile-only {
    display:none;
}

/* Removes width of grid padding */
@media (max-width: 480px) {
  .searchbar-detailed.mdc-top-app-bar, .searchbar-results {
      width: calc(100% - 2*(var(--mdc-layout-grid-margin-phone, 16px)));
      left:16px;
  }
  .mobile-only {
    display:block;
  }
}
/* Removes width of grid padding and collapsed sidebar */
@media (min-width: 481px) and (max-width: 570px) {
  .searchbar-detailed.mdc-top-app-bar, .searchbar-results {
      width: calc(100% - 4*(var(--mdc-layout-grid-margin-phone, 16px)));
  }
  .mobile-only {
    display:block;
  }
}

/* Removes width of grid padding, wrapper padding and collapsed sidebar */
@media (min-width: 571px) and (max-width: 840px) {
  .searchbar-detailed.mdc-top-app-bar, .searchbar-results {
      width: calc(100% - 46px - 2*(var(--mdc-layout-grid-margin-phone, 16px)));
  }
  .mobile-only {
    display:block;
  }
}

@media (max-width: 840px) {
  .searchbar-detailed .before-wrapper {
    display:none;
  }
  .searchbar-detailed .nav-icon-wrapper {
    margin-right:0px !important;
  }
  .searchbar-detailed .profile {
    margin-left: 6px;
    margin-right:0;
  }
  .searchbar-detailed .profile .status {
    display:none;
  }
  .searchbar-detailed .profile .avatar {
    position:relative;
    margin-left:0;
  }
  .searchbar-detailed .burgerbar {
    display:block;
    min-width: 24px;
    padding-left: 0;
  }
  .app-headroom--unpinned .searchbar-detailed.mdc-top-app-bar {
    top: 10px;
  }
  .snippet {
    max-height: 30px;
  }
  .desktop-only {
    display:none !important;
  }

}
</style>
