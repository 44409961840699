<template>
  <div class="initiatives-cards-wrapper">
    <div class="cards-grid">
      <router-link v-for="(initiative, index) in initiativesFiltered" :key="initiative.id + index + 'card'" class="card-link" :to="{ name: 'InitiativeDetails', params: {id: initiative.id} }">
        <initiative :cardDetails="initiative" />
      </router-link>
      <div class="card-link" @click="addInitiativeClick()" role="button">
        <div class="card-wrapper add-new-wrapper">
          <div>
            <mdc-icon icon="add" class="add-initiative"></mdc-icon>
            <mdc-text tag="span" typo="subtitle2">Add new initiative</mdc-text>
          </div>
        </div>
      </div>
    </div>
    <modal-dialog
      disableChange
      :imageName="'initiative-red'"
      v-model="addNewInitiative"
      :pauseFocus="isMobile"
      class="add-init-modal"
      @save="addInitiative"
      :saveText="'CREATE INITIATIVE'"
      :title="'Add a new initiative'"
      :disableSave="newInitiative.name === '' || disableCreate || addingInitiative"
      :showSpinner="addingInitiative"
      >
      <div class="fields">
        <div class="name input-wrapper">
          <div class="title-switch">
            <label for="new-init-name">NAME OF INITIATIVE</label>
            <span class="status-switch-wrapper">
              <mdc-text tag="span" class="switch-text" typo="caption">{{ newInitiative.visibility === 'Workspace' ? 'Initiative is visible' : 'Initiative is private'}}</mdc-text>
              <label class="switch" for="initiative-switch">
                <input v-model="initiativeVisible" type="checkbox" id="initiative-switch" @change="toggleVisibilitySwitch" />
                <div class="slider round"></div>
              </label>
            </span>
          </div>
          <input id="new-init-name" v-model="newInitiative.name" type="text" placeholder="Type a name for your initiative"/>
          <mdc-text tag="span" class="help-text" typo="caption">{{errorMsg}}</mdc-text>
        </div>
        <div class="description input-wrapper">
          <label for="new-init-desc">DESCRIPTION</label>
          <textarea id="new-init-desc" v-model="newInitiative.description" type="text" placeholder="Type a description for your initiative, so others can understand what it is about."/>
        </div>
        <div v-if="profiles" class="add-members-wrapper">
          <label>ADD MEMBERS (Optional)</label>
          <div class="member-select-wrapper">
            <v-select :dropdown-should-open="dropdownShouldOpen" v-model="memberInput" ref="memberSelect" placeholder="Start typing a name" class="members-select" :options="profiles.filter(profile => !newInitiative.members.includes(profile.id))" :reduce="profile => profile.id" label="displayName" @input="setSearchedMember">
              <template slot="open-indicator">
                <mdc-icon class="members-search-icon" icon="search" filled/>
              </template>
              <template #selected-option="{ displayName }">
                <strong>{{ displayName }}</strong>
              </template>
            </v-select>
            <mdc-text class="help-text" tag="span" typo="caption">{{ helpText }}</mdc-text>
          </div>
        </div>
        <div v-if="newInitiative.members !== 0" class="selected-members-wrapper">
          <span class="members-list" v-for="(memberId) in newInitiative.members" :key="memberId + 'newInitiative'">
            <profile-initials noProfileDetailClick :profileId="memberId" :date="appDate" showNameAndInitial showYou />
            <mdc-icon icon="clear" :class="newInitiative.members.length > 1 ? 'enabled' : 'disabled'" class="clear-member" @click.native="setSelectedMember(memberId, 'fromClear')" />
          </span>
        </div>
        <div class="quick-select-wrapper">
          <div class="quick-select-heading">
            <label>Quick select team members:</label>
            <mdc-text v-if="reducedTeamProfiles().length > membersDisplayCount" typo="body2" tag="span" class="show-all-text" @click.native="showAllTeamMatesClick()">{{ !showAllMembersClicked ? 'Show all' : 'Show less' }}</mdc-text>
          </div>
          <div class="select-team">
            <template v-if="showAllMembersClicked">
              <profile-initials noProfileDetailClick @click.native="setSelectedMember(profile.id)" v-for="profile in reducedTeamProfiles()" :key="profile.id" :profileId="profile.id" :date="appDate" showNameAndInitial />
            </template>
            <template v-else>
              <profile-initials noProfileDetailClick @click.native="setSelectedMember(profile.id)" v-for="profile in reducedTeamProfiles().slice(0, membersDisplayCount)" :key="profile.id" :profileId="profile.id" :date="appDate" showNameAndInitial />
            </template>
          </div>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProfileInitials from '../../components/flexibly/ProfileInitials'
import ModalDialog from '../../components/flexibly/ModalDialog.vue'
import moment from 'moment'
import fb from '../../store/firebase'
import Initiative from '../../components/flexibly/Initiative.vue'

export default {
  name: 'InitiativesCards',
  computed: {
    ...mapState([
      'profile',
      'workspace',
      'appDate'
    ]),
    ...mapState({
      initiatives: state => state.teams.initiatives,
      teamProfiles: state => state.teams.teamProfiles,
      profiles: state => state.profiles
    }),
    initiativesFiltered () {
      return this.initiatives.filter((initiative) => {
        return initiative.teamId === this.profile.team || initiative.members.includes(this.profile.id)
      })
    },
    teamInitiatives () {
      return this.initiatives.filter((initiative) => {
        return initiative.teamId === this.profile.team
      })
    }
  },
  components: {
    ProfileInitials,
    ModalDialog,
    Initiative
  },
  data () {
    return {
      addingInitiative: false,
      errorMsg: '',
      disableCreate: false,
      membersDisplayCount: 9,
      showAllMembersClicked: false,
      helpText: '',
      memberInput: null,
      addNewInitiative: false,
      initiativeVisible: true,
      newInitiative: {
        name: '',
        description: '',
        visibility: 'Workspace',
        members: [],
        due: ''
      }
    }
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    showAddNewInitiative: {
      type: Boolean,
      default: false
    }
  },
  created () {
  },
  mounted () {
    if (this.showAddNewInitiative) {
      this.addInitiativeClick()
    }
  },
  watch: {
    'newInitiative.name': {
      handler () {
        const teamNames = this.teamInitiatives.map(initiative => initiative.name.toLowerCase())
        if (teamNames.includes(this.newInitiative.name.toLowerCase())) {
          this.errorMsg = 'Initiative name already in use by team, try something different'
          this.disableCreate = true
        } else {
          this.errorMsg = ''
          this.disableCreate = false
        }
      }
    },
    showAddNewInitiative: {
      handler () {
        if (this.showAddNewInitiative) {
          this.addInitiativeClick()
        }
      }
    },
    addNewInitiative: {
      handler () {
        if (!this.addNewInitiative) {
          this.$emit('addNewInitiativeModalClose', this.addNewInitiative)
        }
      }
    }
  },
  methods: {
    ...mapActions({
      saveInitiative: 'teams/saveInitiative',
      saveTaskList: 'teams/saveTaskList',
      setSnackMessage: 'setSnackMessage'
    }),
    showAllTeamMatesClick () {
      this.showAllMembersClicked = !this.showAllMembersClicked
    },
    dropdownShouldOpen (VueSelect) {
      if (this.memberInput !== null) {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    reducedTeamProfiles () {
      return this.teamProfiles.filter(profile => !this.newInitiative.members.includes(profile.id))
    },
    getMemberName (profileId) {
      if (this.profiles && this.profile) {
        if (profileId) {
          const allProfiles = [...this.profiles, this.profile]
          return allProfiles.filter(profile => profile.id === profileId).map(profile => profile.displayName)
        }
      }
    },
    setSelectedMember (profileId, from) {
      this.helpText = ''
      if (from === 'fromClear') {
        if (this.newInitiative.members.length === 1) {
          this.helpText = 'Initiatives require atleast one member'
          return
        }
      }
      const memberAtIndex = this.newInitiative.members.findIndex(teamMateId => teamMateId === profileId)
      if (memberAtIndex !== -1) {
        this.newInitiative.members.splice(memberAtIndex, 1)
      } else {
        this.newInitiative.members.push(profileId)
      }
    },
    setSearchedMember (value) {
      if (value) {
        this.setSelectedMember(value)
        this.$refs.memberSelect.clearSelection()
      }
    },
    getDate (date) {
      return moment(date).format('ddd D MMMM')
    },
    dateSelected (dated) {
      this.newInitiative.due = dated.toDate()
    },
    addInitiativeClick () {
      this.addNewInitiative = true
      this.setSelectedMember(this.profile.id)
    },
    getTag (name) {
      if (!name) {
        return '<span class="hashtag">#myInitiative</span>'
      }
      return `<span class="hashtag">#${name.replace(' ', '-')}</span>`
    },
    toggleVisibilitySwitch () {
      console.log(this.newInitiative.visibility)
      if (this.newInitiative.visibility === 'Workspace') {
        this.newInitiative.visibility = 'User'
        this.initiativeVisible = false
      } else {
        this.newInitiative.visibility = 'Workspace'
        this.initiativeVisible = true
      }
    },
    async addInitiative () {
      this.addingInitiative = true
      const initiative = { ...this.newInitiative }
      initiative.tag = `#${initiative.name.replace(' ', '-')}`
      initiative.key = `${this.profile.team}-${initiative.name.replace(' ', '-').toLowerCase()}`
      const self = this

      // Check for existing
      const isInitiativeUnique = fb.functions.httpsCallable('isInitiativeUnique')
      const params = { initiativeKey: initiative.key, workspaceId: this.workspace.id }
      console.log('InitiativeCards.isInitiativeUnique params', params)
      await isInitiativeUnique(params).then((res) => {
        console.log('isInitiativeUnique', res)
        if (res.data.result === 'unique') {
          this.saveInitiative({ teamId: this.profile.team, initiative }).then((doc) => {
            const initiativeId = doc.id
            // console.log('saveInitiative new - add default list', initiativeId)
            // add default taskList
            const taskList = {
              title: 'To Do',
              name: 'To Do',
              description: '',
              default: true,
              order: 0
            }
            self.saveTaskList({ teamId: self.profile.team, initiativeIds: [initiativeId], taskListId: false, taskList }).then((doc) => {
              this.saveInitiative({ initiativeId, initiative: { defaultTaskListId: doc.id } })
              setTimeout(() => {
                self.addingInitiative = false
                self.addNewInitiative = false
                self.newInitiative = {
                  name: '',
                  description: '',
                  visibility: 'Workspace',
                  members: [],
                  due: ''
                }
              }, 700)
            })
          })
        } else {
          // reopen
          self.setSnackMessage('Initiative names need to be unique for a team')
          setTimeout(() => {
            self.addNewInitiative = true
            self.addingInitiative = false
          }, 700)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.initiatives-cards-wrapper {
  width: 100%;
  .cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 320px);
    gap: 15px;
    justify-content: center;
    .card-link {
      text-decoration: none;
      color: #333;
      background: #ffffff;
      border: 0.5px solid #BDBDBD;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.20);
      border-radius: 10px;
      min-height: 210px;
      max-height: 220px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
$color-ontrack: #01CB52; /**ontrack */
$color-incomplete: #FFB11B; /**atrisk */
$color-overdue: #FF0F00; /**offtrack */
$color-onhold: #A0A0A0;
$color-completed: #404040;
.add-init-modal {
  .help-text {
    color: $color-overdue;
  }
  .mdc-dialog__surface {
    width: 650px;
    max-width: 680px;
    @media only screen and (max-width: 645px) {
      max-width: calc(100vw - 32px);
    }
  }
  &.modal-dialog {
    .mdc-dialog__content {
      .title-wrapper {
        .title {
          line-height: 20px;
        }
        .close-modal {
          padding: 0;
          width: 36px;
          height: 20px;
        }
      }
    }
  }
  .fields {
    .name-switch {
      display: flex;
      align-items: center;
    }
    .status-switch-wrapper {
      min-width: 150px;
      display: flex;
      align-items: center;
      .switch-text {
        color: #4F4F4F;
      }
      .switch {
        margin-left: 10px;
        display: inline-block;
        height: 10px;
        position: relative;
        width: 40px;
        vertical-align: middle;
        margin-bottom: unset;
        input {
          display:none;
          &:checked {
            + .slider {
              background-color: #4F4F4F;
              &:before {
                transform: translateX(26px);
                background-image: url('/static/img/icons/flexibly/opened-eye.svg');
                background-position: center;
                background-repeat: no-repeat;
              }
            }
          }
        }
        .slider {
          background-color: #ccc;
          bottom: 0;
          cursor: pointer;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: .2s;
          &:before {
            background-color: #fff;
            bottom: -3px;
            content: "";
            height: 18px;
            position: absolute;
            transition: .2s;
            width: 18px;
          }
          &.round {
            border-radius: 18px;
            &:before {
              border-radius: 50%;
              background-image: url('/static/img/icons/flexibly/closed-eye.svg'), url('/static/img/icons/flexibly/closed-eye-part2.svg');
              background-repeat: no-repeat;
              background-position: center, 6.5px 6px;
              box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
    .input-wrapper {
      display: flex;
      flex-direction: column;
      border-bottom: 0.5px dashed #BDBDBD;
      margin-bottom: 30px;
      &.name {
        .title-switch {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      label {
        font-size: 14px;
        color: #404040;
        text-transform: uppercase;
        margin-bottom: 5px;
      }
      input {
        border: none;
        padding: 0;
        color: #404040;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 20px;
        &::placeholder {
          color: #BDBDBD;
          font-weight: 500;
        }
      }
      textarea {
        resize: none;
        color: #404040;
        border: none;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 20px;
        height: 80px;
        max-height: 80px;
        &::placeholder {
          color: #A0A0A0;
          font-weight: 500;
        }
      }
    }
    .add-members-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 20px;
      label {
        font-size: 14px;
        color: #404040;
      }
      .members-select {
        max-width: 350px;
        width: 200px;
        .vs__dropdown-toggle {
          // border-radius: 20px;
          border: 0.5px solid transparent;
          background: #F8F7F7;
          .vs__search {
            padding-left: 20px;
            font-size: 14px;
            color: #404040;
            font-weight: 600;
            &::placeholder {
              color: #a0a0a0;
              font-size: 14px;
              font-weight: 600;
            }
          }
          .vs__actions {
            display: none;
          }
        }
        .vs__dropdown-menu {
          background: #FEFEFE;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);
          border-radius: 6px;
          width: 180px;
          padding: 0;
          transform: translateY(-100%);
          margin-top: -31px;
          li {
            color: #4175B1;
            font-size: 14px;
            &.vs__dropdown-option--highlight {
              background: #EBF3FC;
            }
          }
        }
      }
    }
    .selected-members-wrapper {
      margin-bottom: 20px;
      display: inline-flex;
      min-height: 25px;
      flex-flow: wrap;
      .members-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 25px;
        padding: 5px 0 5px 5px;
        border: 0.5px solid transparent;
        position: relative;
        margin-right: 10px;
        .profile-redirect {
          margin-right: 0px;
          .profile-firstname-initial {
            color: #4F4F4F !important;
            font-size: 12px;
            font-weight: 600;
          }
          .you-text {
            color: #4F4F4F !important;
            font-size: 12px;
            font-weight: 600;
          }
        }
        .clear-member {
          display: none;
          opacity: 0;
          font-size: 19px;
          color: #cc0000;
          margin-top: -15px;
          cursor: pointer;
          &.disabled {
            color: #404040;
            opacity: 0.6 !important;
          }
        }
        &:hover {
          .clear-member {
            opacity: 1;
            display: block;
          }
          background: #FFFFFF;
          border: 0.5px solid rgba(141, 166, 195, 0.190887);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.50);
          border-radius: 2px;
        }
      }
    }
    .quick-select-wrapper {
      .quick-select-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px dashed #000000;
      }
      label {
        display: flex;
        padding-bottom: 3px;
        color: #4f4f4f;
      }
      .select-team {
        margin-top: 7px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .profile-redirect {
          max-height: 25px;
          height: 25px;
          width: max-content;
          justify-content: center;
          padding: 5px;
          margin-bottom: 5px;
          margin-right: 5px;
          border: 0.5px solid transparent;
          &:hover {
            background: #FFFFFF;
            border: 0.5px solid rgba(141, 166, 195, 0.190887);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.50);
            border-radius: 2px;
          }
          .profile-firstname-initial {
            color: #4F4F4F !important;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.initiatives-cards-wrapper {
  .ontrack {
    color: $color-ontrack;
  }
  .completed {
    color: $color-completed;
  }
  .atrisk {
    color: $color-incomplete;
  }
  .onhold {
    color: $color-onhold;
  }
  .offtrack {
    color: $color-overdue;
  }
  .nostatus {
    color: #BDBDBD;
  }
}
</style>
