<template>
  <div v-show="!saving" :data-taskid="taskCopy.id" :id="taskCopy.id" :ref="'task' + taskCopy.id" class="task-line" :class="{ active: editing, 'taskmodal': showTaskAsModal, 'new-task-line': !taskCopy.id, 'task-line-draggable': !task.completed }" :draggable="!task.completed && !editing && draggable" v-if="taskCopy">
    <div class="inner-scrim" v-show="(showCalendar || showSubscribersList) && editing"></div>
    <div class="task-scrim" v-show="showTaskAsModal && editing" @click.prevent="showTaskModal('fromScrim')"></div>
    <div class="task-row">
      <div class="task-detail" @click.prevent="!editing && !task.completed && switchToTipTap($event, taskCopy)">
        <base-svg-icon
          name="round_unchecked"
          :id="'taskCheckBox' + task.id"
          v-if="!task.completed"
          @click.native="completeTask(task)"
          class="task-check"
          v-bind:class="{ 'new-task' : !task.id }"
          outlined />
        <base-svg-icon
          :draggable="false"
          name="round_checked"
          :id="'taskCheckBox' + task.id"
          v-else
          @click.native="incompleteTask(task)"
          class="task-check-closed"
          v-bind:class="{ 'new-task' : !task.id }"
          outlined />
        <div class="task-text-initiatives">
          <div v-if="task.completed" :id="'taskText' + task.id" class="task-content completed" v-html="task.text"></div>
          <div v-else-if="!editing" class="task-text-row">
            <span :id="'taskText' + task.id" class="task-content" v-html="task.text" v-bind:class="{'fade' : contentWidthChanged }"></span>
            <template v-if="!contentWidthChanged && !task.completed && !editing">
              <div v-if="task.shareInitiatives.length > 0" style="display: flex;align-items: center;" class="task-initiatives-wrapper">
                <mdc-button v-for="(name, index) in getInitiativesName(task.shareInitiatives)" :key="name + index" class="task-initiatives">
                  <base-svg-icon name="tagpicker-on" />
                  <mdc-text typo="caption" style="display:none" tag="span">&nbsp;#</mdc-text>
                  <mdc-text typo="caption" tag="span">{{ name }}</mdc-text>
                </mdc-button>
              </div>
              <profile-initials noProfileDetailClick :date="appDate" :profileId="assignedId" v-for="(assignedId, index) in task.shareUsers" :key="assignedId + index" />
            </template>
            <div :id="'taskAttributes' + task.id" class="task-indicator-row" v-show="!contentWidthChanged && !task.completed && !editing">
              <div class="attribute-icons-text">
                <div  v-if="task.due && !isEmpty" class="task-due">
                  <base-svg-icon :name="getDueDate(task).icon" />
                  <mdc-text tag="span" :style="'color' + ':' + getDueDate(task).color">{{ getDueDate(task).text }}</mdc-text>
                </div>
                <div v-if="task.commentCount" class="task-comment">
                  <base-svg-icon name="comment-grey" />
                  <span>{{task.commentCount}}</span>
                </div>
              </div >
            </div>
          </div>
          <div v-else v-show="!saving">
            <tiptap-editor
              @focus="resetCalendarAndMenu"
              reducedOptions
              emitBackspace @backSpace="deleteOnEmptyState"
              emitEnter @editorEnter="preventNextLineInTitle"
              :user="user"
              :ref="'editor-' + taskCopy.id"
              class="task-tiptap task-text"
              :hashtags="initiativeTags"
              :mentionSuggestions="teamMentions"
              @onTextUpdate="onTaskUpdateSelected"
              :teamId="profile.team"
              :pagelinks="pageLinks"
              :content="taskCopy.text"
              hideMenu
              :title="'Task'"
              :contentKey="'idx-' + taskCopy.id">
            </tiptap-editor>
            <tiptap-editor
              v-if="tabClicked === 'details'"
              @focus="resetCalendarAndMenu"
              reducedOptions
              :user="user"
              :ref="'editor-desc-' + taskCopy.id"
              class="task-tiptap task-description"
              disableTags
              :mentionSuggestions="teamMentions"
              @onTextUpdate="onTaskDescriptionUpdateSelected"
              :teamId="profile.team"
              hideMenu
              :content="taskCopy.description ? ''+taskCopy.description : '<p></p>'"
              :title="'Description'"
              :contentKey="'idx-desc' + taskCopy.id">
            </tiptap-editor>
          </div>
          <base-svg-icon v-if="editing && !taskModalMode" :name="!showTaskAsModal ? 'modal' : 'cross-thin'" :class="!showTaskAsModal ? 'task-modal-icon' : 'cross-icon'" class="modal-icon" @click.native="showTaskModal" clickable />
          <base-svg-icon v-if="editing && taskModalMode" name="cross-thin" class="modal-icon cross-icon" @click.native="$emit('clickOutside')" clickable />
        </div>
        <div>
          <div v-if="taskCopy.completed"></div>
          <div class="task-indicator-row long-width-attributes" v-show="contentWidthChanged && !editing && !taskCopy.completed">
            <div class="attribute-icons-text">
              <!-- due
              comment
              -->
              <div  v-if="taskCopy.due" class="task-due">
                <base-svg-icon :name="getDueDate().icon" />
                <mdc-text tag="span" :style="'color' + ':' + getDueDate().color">{{ getDueDate().text }}</mdc-text>
              </div>
              <div v-if="taskCopy.commentCount" class="task-comment">
                <base-svg-icon name="comment-grey" />
                <span>{{taskCopy.commentCount}}</span>
              </div>
            </div >
          </div>
        </div>
      </div>
      <div class="subscribers-details" v-if="editing && taskCopy.id" v-show="!saving">
        <template v-if="taskCopy.subscribers && taskCopy.subscribers.length !== 0">
          <span class="count-label-wrapper">
            <span class="count">{{ taskCopy.subscribers.length }}</span>
            <span class="label">{{ taskCopy.subscribers.length === 1 ? 'SUBSCRIBER' : 'SUBSCRIBERS' }}</span>
          </span>
          <span class="subscribe-action">
            <mdc-button unraised @click.native="unSubscribeClick()">{{ isMyIdSubscribed() ? 'Unsubscribe' : 'Subscribe' }}</mdc-button>
            <base-svg-icon name="task-ellipse" clickable @click.native="showSubscribers()" />
            <section class="subscribers-list" v-if="showSubscribersList">
              <div class="heading">
                <mdc-text tag="span" typo="caption" >Subscribers</mdc-text>
              </div>
              <div class="subscribers-initials">
                <profile-initials :date="appDate" :profileId="subscriber" v-for="subscriber in taskCopy.subscribers" :key="subscriber" showDisplayName />
              </div>
            </section>
          </span>
        </template>
        <template v-else>
          <span v-if="taskCopy.shareInitiatives && taskCopy.shareInitiatives.length === 0" class="private-label">PRIVATE TASK</span>
        </template>
      </div>
      <div v-if="editing && tabClicked === 'details'" v-show="!saving" class="editing-wrapper">
        <div class="select-options"><!-- selected search -->
          <v-select v-if="taskCopy.shareInitiatives && taskCopy.shareInitiatives.length > 0" class="selected-task-attributes" id="selected-task-initiatives" multiple :value="taskCopy.shareInitiatives" :options="initiatives.filter(initiative => initiative.name)" :reduce="initiative => initiative.id" label="name" @input="setSelectedInitiative" @search:focus="initiativeMenuFocus()" placeholder="Start typing">
            <template #option="{ name }">
              <base-svg-icon name="tagpicker-on" />
              <mdc-text typo="caption" tag="span">{{ name }}</mdc-text>
            </template>
            <div slot="open-indicator">
            </div>
            <template #selected-option="{ name }">
              <div class="tags-selection-options">
                <mdc-button class="task-initiatives">
                  <base-svg-icon name="tagpicker-on" />
                  <mdc-text typo="caption" tag="span">{{ name }}</mdc-text>
                </mdc-button>
                <base-svg-icon name="add-attributes" class="add-icon" />
              </div>
            </template>
          </v-select>
          <v-select v-if="taskCopy.shareUsers && taskCopy.shareUsers.length > 0" id="selected-task-assignedids" class="selected-assignee selected-task-attributes" multiple :value="taskCopy.shareUsers" :options="teamMentions.filter(profile => profile.displayName)" :reduce="profile => profile.id" label="displayName" @input="setSelectedAssignee" @search:focus="assignedMenuFocus()" placeholder="Start typing">
            <template #option="{ name }">
              <base-svg-icon name="taskassignee-on"/>
              <mdc-text typo="caption" tag="span">{{ name }}</mdc-text>
            </template>
            <template #selected-option="{ id }">
              <div class="tags-selection-options">
                <profile-initials :date="appDate" :profileId="id" />
                <base-svg-icon name="add-attributes" class="add-icon" />
              </div>
            </template>
            <div slot="open-indicator">
            </div>
          </v-select>
        </div>
        <div class="editing-attributes" style="display:inline-flex;">
          <div class="when-due">
            <div v-if="isTaskPlan()" class="plan-details">
              <span class="task-when" @click="whenDateClick()">
                <base-svg-icon name="datepicker-on" />
                <tooltip :tooltipText="'When'" class="selected-when-tooltip" />
                <mdc-text typo="caption" tag="span" class="date">
                  {{ selfTaskPlan[0].type && selfTaskPlan[0].type !== '' ? selfTaskPlan[0].type : formatDate(selfTaskPlan[0].planDate) }}
                </mdc-text>
                <span @click="clearPlanDate($event)" class="clear-date-icon">
                  <mdc-icon title="clear" icon="clear" />
                </span>
              </span>
            </div>

            <div v-if="taskCopy.due" class="attribute-icons-text due editing-due">
              <span class="task-due" @click="dueDateClick()">
                <base-svg-icon :name="getDueDate().bigicon" />
                <tooltip :tooltipText="'Deadline'" class="selected-due-tooltip" />
                <mdc-text typo="caption" tag="span" :style="'color' + ':' + getDueDate().color">{{ displayDueDateStatus() }}</mdc-text>
                <span @click="clearDueDate($event)" class="clear-date-icon">
                  <mdc-icon title="clear" icon="clear" />
                </span>
              </span>
              <!-- <mdc-text typo="caption" tag="span" class="due-date-status">{{displayDueDateStatus()}}</mdc-text> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="editing && tabClicked === 'details'" v-show="!saving" class="editing-icons">
        <div class="editing-icon-wrapper">
          <v-select :dropdown-should-open="tagDropdownShouldOpen" v-model="tagInput" @search:focus="calendarFocusOutOnSelect" @search:blur="checkcalendarFocus" placeholder="Start typing" v-if="!taskCopy.shareInitiatives || taskCopy.shareInitiatives && taskCopy.shareInitiatives.length === 0" class="editing-task-attributes initiative" :options="initiatives.filter(initiative => initiative.name)" :reduce="initiative => initiative.id" label="name" @input="setSelectedInitiative" multiple>
            <template #option="{ name }">
              <base-svg-icon name="tagpicker-on" />
              <mdc-text typo="caption" tag="span">{{ name }}</mdc-text>
            </template>
            <template slot="open-indicator">
              <base-svg-icon name="tagpicker-off" :title="'Initiative'"/>
            </template>
          </v-select>
          <tooltip :tooltipText="'Initiative'" class="initiative-tooltip" />
        </div>

        <div class="editing-icon-wrapper" v-if="!isTaskPlan()">
          <div v-if="!isTaskPlan()" class="editing-when-icon">
            <mdc-button id="when-button" v-if="!isTaskPlan()" @click.prevent="whenIconClick($event)">
              <base-svg-icon name="datepicker-off" :title="'When'"/>
            </mdc-button>
          </div>
          <tooltip :tooltipText="'When'" class="when-tooltip" />
        </div>

        <div class="editing-icon-wrapper">
          <div v-if="blankDue" class="editing-due-icon">
            <mdc-button id="due-button" @click="dueIconClick($event)">
              <base-svg-icon name="deadlinepicker-off" :title="'Deadline'"/>
            </mdc-button>
          </div>
          <tooltip :tooltipText="'Deadline'" class="due-tooltip" />
        </div>

        <div class="editing-icon-wrapper">
          <v-select :dropdown-should-open="assigneeDropdownShouldOpen" v-model="assigneeInput" @search:focus="calendarFocusOutOnSelect" @search:blur="checkcalendarFocus" placeholder="Start typing" v-if="!taskCopy.shareUsers || (taskCopy.shareUsers && taskCopy.shareUsers.length === 0)" class="editing-task-attributes assigned" :options="teamMentions.filter(profile => profile.displayName)" :reduce="profile => profile.id" label="displayName" @input="setSelectedAssignee" multiple>
            <template #option="{ name }">
              <base-svg-icon name="taskassignee-on"/>
              <mdc-text typo="caption" tag="span">{{ name }}</mdc-text>
            </template>
            <template slot="open-indicator">
              <div>
                <base-svg-icon name="taskassignee-off" :title="'Assignee'"/>
              </div>
            </template>
          </v-select>
          <tooltip :tooltipText="'Assignee'" class="assignee-tooltip" />
        </div>
        </div>
      <task-comments v-if="editing && tabClicked === 'activity'" v-show="!saving" :taskCopy="taskCopy" @addCommentTask="addCommentTask" />
      <calendar
        :disablePastDates="calendarClicked === 'when'"
        :id="calendarClicked + showCalendar"
        :displayCalendar="showCalendar && editing"
        :showPresets="showPresets"
        :showLocationPresets="showPresets"
        class="task-calendar"
        @selected="dateSelected"
        :resetDates="resetDates"></calendar>
      <div class="task-tabs" v-if="editing && showTaskTabs" v-show="!saving">
        <mdc-tab-bar class="mobile-vertical" @change="onTabSelection">
          <mdc-tab :active="tabClicked === 'details'">
            <base-svg-icon :name="tabClicked === 'details' ? 'round_checked_red' : 'round_checked_bluegray'" />
            <mdc-text tag="span" typo="caption">TASK DETAILS</mdc-text>
          </mdc-tab>
          <mdc-tab v-bind:class="disableButton() ? 'disabled': ''" :active="tabClicked === 'activity'">
            <base-svg-icon :name="tabClicked === 'activity' ? 'comment-red' : 'comment-grey'" />
            <mdc-text tag="span" typo="caption">ACTIVITY</mdc-text>
          </mdc-tab>
        </mdc-tab-bar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TextSelection } from 'prosemirror-state'
import moment from 'moment'
import Calendar from './Calendar.vue'
import ProfileInitials from '../../components/flexibly/ProfileInitials'
import BaseSvgIcon from './BaseSvgIcon'
import { datetimeFormat } from '../../utils/time'
import Tooltip from './Tooltip'
import TaskComments from './TaskComments.vue'
export default {
  name: 'Task',
  components: {
    TiptapEditor: () => import('../../components/Tiptap'),
    Calendar,
    ProfileInitials,
    BaseSvgIcon,
    Tooltip,
    TaskComments
  },
  props: {
    taskModalMode: {
      type: Boolean,
      default: false
    },
    emitClickOutside: {
      type: Boolean,
      default: false
    },
    showTaskTabs: {
      type: Boolean,
      default: false
    },
    editState: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    task: {},
    editTask: {},
    taskPlan: {},
    fromInitiative: {
      type: Array,
      default: function () {
        return []
      }
    },
    taskListId: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapState([
      'appDate'
    ]),
    ...mapState({
      user: state => state.user,
      profile: state => state.profile,
      profiles: state => state.profiles,
      team: state => state.teams.team,
      initiatives: state => state.teams.initiatives,
      personalInitiatives: state => state.teams.personalInitiatives,
      notePages: state => state.teams.notePages,
      taskComments: state => state.teams.taskComments,
      taskActivity: state => state.teams.taskActivity,
      teams: state => state.teams.teams
    }),
    allProfiles () {
      const wsProfiles = [...this.profiles].sort((a, b) => {
        const adn = a?.displayName?.toLowerCase() || ''
        const bdn = b?.displayName?.toLowerCase() || ''
        if (adn > bdn) {
          return 1
        } else if (bdn > adn) {
          return -1
        }
        return 0
      })
      return [this.profile, ...wsProfiles]
    },
    taskCommentsActivity () {
      const comments = this.taskComments || []
      const activity = this.taskActivity || []
      let commentActivity = [...activity, ...comments]
      commentActivity = [...commentActivity.sort((a, b) => {
        const acDate = a.created ? a.created.toDate() : -1
        const bcDate = b.created ? b.created.toDate() : -1
        return acDate > bcDate ? 1 : -1
      })]
      return commentActivity
    },
    pageLinks () {
      return this.notePages
    },
    teamMentions () {
      if (this.allProfiles) {
        return this.allProfiles.map((prof) => {
          return { ...prof, name: prof.displayName, initials: this.initials(prof) }
        })
      }
      return []
    },
    initiativeTags () {
      let tags = []
      for (const init of this.personalInitiatives) {
        if (init.teamId !== this.profile.team) {
          const tagName = `${this.getTeamName(init.teamId)}${init.name.replace(' ', '-')}`
          tags.push({ ...init, tagName })
        } else {
          const tagName = `${init.name.replace(' ', '-')}`
          tags.push({ ...init, tagName })
        }
      }
      tags = tags.sort((a, b) => a.tagName.localeCompare(b.tagName))
      return tags
    },
    blankDue () {
      if (this.taskCopy) {
        if (this.taskCopy.due) {
          if (this.taskCopy.due !== '') {
            return false
          }
        }
      }
      return true
    },
    isEmpty () {
      if (this.taskCopy.text) {
        if (this.taskCopy.text !== '') {
          if (this.taskCopy.text !== '<p></p>' && this.taskCopy.text !== '<p style="text-align: left"></p>') {
            return false
          }
        }
      }
      return true
    }
  },
  mounted () {
    const self = this
    this.taskCopy = { ...this.task }
    if (this.taskModalMode) {
      this.switchToTipTap(null, this.task)
      this.showTaskModal()
    }
    setTimeout(function () { self.checkContentWidth() }, 100)
    if (this.editTask) {
      if (this.editTask.id === this.task.id) {
        this.editing = true
        this.loadTaskActivity({ teamId: this.profile.team, taskId: this.taskCopy.id })
        this.loadTaskComments({ teamId: this.profile.team, taskId: this.taskCopy.id }).then(() => {
          if (!this.editTask.showDetail) {
            this.tabClicked = 'activity'
          }
        })
      }
    }
  },
  data () {
    return {
      assigneeInput: '',
      tagInput: '',
      showSubscribersList: false,
      showTaskAsModal: false,
      contentWidthChanged: false,
      cursorPosition: 0,
      editing: false,
      resetDates: false,
      showCalendar: false,
      showPresets: false,
      calendarClicked: '',
      inputTags: '',
      selectMenuFocus: false,
      selfTaskPlan: [],
      selectedTask: {},
      taskCopy: null,
      tabClicked: 'details',
      activityChanges: {},
      saving: false
    }
  },
  methods: {
    ...mapActions({
      saveTask: 'teams/saveTask',
      saveTaskPlan: 'teams/saveTaskPlan',
      saveUpdateMessage: 'teams/saveUpdateMessage',
      loadTaskComments: 'teams/loadTaskComments',
      loadTaskActivity: 'teams/loadTaskActivity',
      getNotePage: 'teams/getNotePage',
      deleteTask: 'teams/deleteTask',
      getTaskPlan: 'teams/getTaskPlan',
      saveTaskComment: 'teams/saveTaskComment',
      saveTaskActivity: 'teams/saveTaskActivity'
    }),
    getTeamName (teamId) {
      if (teamId !== this.profile.team) {
        if (this.teams) {
          const ft = this.teams.filter((t) => {
            return t.id === teamId
          })[0] || {}
          return `${ft.name || ''}/`
        }
      }
      return ''
    },
    tagDropdownShouldOpen (VueSelect) {
      if (this.tagInput !== '') {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    assigneeDropdownShouldOpen (VueSelect) {
      if (this.assigneeInput !== '') {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    showSubscribers () {
      this.showSubscribersList = !this.showSubscribersList
    },
    isMyIdSubscribed () {
      return this.taskCopy.subscribers.includes(this.profile.id)
    },
    unSubscribeClick () {
      const myIdIndex = this.taskCopy.subscribers.findIndex(id => id === this.profile.id)
      if (myIdIndex !== -1) {
        this.taskCopy.subscribers.splice(myIdIndex, 1)
      } else {
        this.taskCopy.subscribers = [...this.taskCopy.subscribers, this.profile.id]
      }
    },
    getActivityMessage (activity) {
      const fieldsArr = []
      let fields = ''
      if (activity.newTask) {
        fieldsArr.push(`Task created at ${datetimeFormat(activity.newTask.toDate())}`)
      }
      if (activity.title) {
        fieldsArr.push('Title ' + activity.title)
      }
      if (activity.description) {
        fieldsArr.push('Description updated')
      }
      if (activity.completed) {
        fieldsArr.push(`Marked this task as complete ${datetimeFormat(activity.completed.toDate())}`)
      }
      if (activity.uncompleted) {
        fieldsArr.push(`Marked this task as incomplete ${datetimeFormat(activity.uncompleted.toDate())}`)
      }
      if (activity.assigned) {
        let connective = 'were'
        if (activity.assigned.length === 1) {
          connective = 'was'
        }
        fieldsArr.push(activity.assigned.join(', ') + ` ${connective} assigned`)
      }
      if (activity.initiatives) {
        let connective = 'were'
        if (activity.initiatives.length === 1) {
          connective = 'was'
        }
        fieldsArr.push(activity.initiatives.join(', ') + ` ${connective} tagged`)
      }
      if (activity.due) {
        fieldsArr.push('Deadline was ' + activity.due)
      }
      if (fieldsArr.length < 1) {
        fields = 'Task Updated'
      } else {
        fields = fieldsArr.join(' \n')
      }
      return `${fields}`
    },
    isActivityBlank (activity) {
      if (activity) {
        return Object.keys(activity).length === 0
      }
      return true
    },
    disableButton () {
      if (this.taskCopy.text === '<p></p>' || this.taskCopy.text === '<p style="text-align: left"></p>') {
        return true
      }
      return false
    },
    showTaskModal (triggered) {
      this.$nextTick(() => {
        const modalNode = document.getElementsByClassName('taskmodal')
        if (modalNode && modalNode[0]) {
          modalNode[0].scrollIntoView(false)
        }
        this.showTaskAsModal = !this.showTaskAsModal
      })
      if (triggered === 'fromScrim') {
        this.resetCalendarAndMenu()
      }
    },
    addCommentTask (message) {
      const comment = {
        fromId: message.fromId,
        message: message.text,
        mentions: message.mentions || [],
        created: new Date(),
        date: new Date()
      }
      if (this.taskCopy.text !== '<p></p>' && this.taskCopy.text !== '<p style="text-align: left"></p>') {
        if (this.taskCopy.id) {
          this.saveTaskComment({
            teamId: this.profile.team,
            shareInitiatives: this.taskCopy.shareInitiatives,
            taskId: this.taskCopy.id,
            commentId: comment.id,
            comment
          })
          if (!this.taskCopy.commentCount) {
            if (this.taskComments) {
              this.taskCopy.commentCount = this.taskComments.length
            } else {
              this.taskCopy.commentCount = 0
            }
          }
          this.taskCopy.commentCount = this.taskCopy.commentCount + 1
          this.saveTask({
            teamId: this.profile.team,
            taskId: this.taskCopy.id,
            task: this.taskCopy
          }).then(() => {
            this.editing = true
          })
        } else {
          this.taskCopy.commentCount = this.taskCopy.commentCount ? this.taskCopy.commentCount + 1 : 0 + 1
          this.saveTask({ teamId: this.profile.team, taskId: this.taskCopy.id, task: this.taskCopy }).then((response) => {
            const taskId = this.taskCopy.id ? this.taskCopy.id : null
            if (taskId) {
              if (!this.isActivityBlank(this.activityChanges)) {
                this.saveTaskActivity({ activity: { ...this.activityChanges, taskId } }).then(() => {
                  this.activityChanges = {}
                })
              }
            } else {
              this.activityChanges = {}
            }
            this.taskCopy.id = this.taskCopy.id || response.id
            if (this.selfTaskPlan && this.selfTaskPlan[0]) {
              const tp = {
                teamId: this.profile.team,
                userId: this.profile.id,
                id: this.selfTaskPlan[0].id || false,
                taskId: this.taskCopy.id ? this.taskCopy.id : response && response.id ? response.id : 'no task id',
                planDate: this.selfTaskPlan[0].planDate && this.selfTaskPlan[0].planDate.seconds ? this.selfTaskPlan[0].planDate.toDate() : this.selfTaskPlan[0].planDate,
                type: this.selfTaskPlan[0].type
              }
              // console.log('saveTaskPlan', tp)
              this.saveTaskPlan(tp).then(() => {
                if (this.selfTaskPlan[0]) {
                  if (this.selfTaskPlan[0].planDate || this.selfTaskPlan[0].type) {
                    this.taskCopy.isPlanPresent[this.profile.id] = true
                  } else {
                    this.taskCopy.isPlanPresent[this.profile.id] = false
                  }
                } else {
                  this.taskCopy.isPlanPresent[this.profile.id] = false
                }
                this.saveTask({ teamId: this.profile.team, taskId: this.taskCopy.id, task: this.taskCopy })
              })
            }
            const self = this
            this.$nextTick(() => {
              this.$emit('editChange', self.taskCopy)
            })
            this.saveTaskComment({
              teamId: this.profile.team,
              shareInitiatives: this.taskCopy.shareInitiatives,
              taskId: this.taskCopy.id,
              commentId: comment.id,
              comment
            })
          })
        }
        setTimeout(() => {
          const taskComments = document.getElementsByClassName('task-comments')
          if (taskComments && taskComments[0]) {
            taskComments[0].scrollTop = taskComments[0].scrollHeight
          }
        }, 200)
      }
    },
    async onTabSelection (index) {
      this.showCalendar = false
      if (index === 1) {
        if (this.isTaskUpdated()) {
          await this.saveTaskFromCopy()
          this.loadTaskActivity({ teamId: this.profile.team, taskId: this.taskCopy.id })
          this.loadTaskComments({ teamId: this.profile.team, taskId: this.taskCopy.id }).then(() => {
            this.tabClicked = 'activity'
          })
        }
        this.tabClicked = 'activity'
        return
      }
      this.tabClicked = 'details'
    },
    checkcalendarFocus () {
      this.selectMenuFocus = false
      this.$nextTick(() => {
        const calendarWhenIconButton = document.querySelectorAll('#when-button')
        const calendarDueIconButton = document.querySelectorAll('#due-button')
        if (calendarWhenIconButton && calendarWhenIconButton[0]) {
          const wheniconclicked = calendarWhenIconButton[0].classList.contains('mdc-ripple-upgraded--foreground-activation')
          if (wheniconclicked) {
            const self = this
            setTimeout(function () { self.whenIconClick() }, 350)
          }
        }
        if (calendarDueIconButton && calendarDueIconButton[0]) {
          const dueiconclicked = calendarDueIconButton[0].classList.contains('mdc-ripple-upgraded--foreground-activation')
          if (dueiconclicked) {
            const self = this
            setTimeout(function () { self.dueIconClick() }, 350)
          }
        }
      })
    },
    async preventNextLineInTitle (value) {
      await this.checkTaskSaveOrClose()
      if (this.emitClickOutside) {
        this.$emit('clickOutside')
      }
      return false
    },
    deleteOnEmptyState () {
      if (this.taskCopy.id) {
        this.deleteTask({ taskId: this.taskCopy.id })
      } else {
        this.editing = false
        this.showTaskAsModal = false
      }
    },
    resetCalendarAndMenu () {
      this.selectMenuFocus = false
      this.resetCalendar()
      this.showSubscribersList = false
    },
    calendarFocusOutOnSelect () {
      this.selectMenuFocus = true
      this.resetCalendar()
    },
    onNotePageCreate ({ link }) {
      this.getNotePage({ teamId: this.profile.team, notePageName: link.label, notePageLink: link.link })
    },
    getProfileFromId (id) {
      if (this.allProfiles) {
        const profile = this.allProfiles.filter(prof => prof.id === id)
        if (profile.length > 0) {
          return profile[0].displayName
        }
      }
      return ''
    },
    checkContentWidth () {
      if (this.taskCopy) {
        if (this.taskCopy.id) {
          const self = this
          setTimeout(function () {
            const dom = document.querySelector('#taskText' + self.taskCopy.id + ' ' + 'p')
            if (dom) {
              if (dom.scrollWidth > dom.clientWidth) {
                self.contentWidthChanged = true
                return true
              }
              self.contentWidthChanged = false
              return false
            }
          }, 100)
        }
      }
    },
    checkIsToday () {
      if (this.taskCopy.due) {
        if (this.taskCopy.due.seconds) {
          const dueDate = moment.unix(this.taskCopy.due.seconds).toDate()
          return moment(dueDate).isSame(moment(), 'day')
        }
        return moment(this.taskCopy.due).isSame(moment(), 'day')
      }
    },
    isTaskPlan () {
      if (this.selfTaskPlan.length !== 0) {
        if (this.selfTaskPlan[0]) {
          if (this.selfTaskPlan[0].type && (this.selfTaskPlan[0].type !== '' || this.selfTaskPlan[0].type !== undefined)) {
            return true
          } else if (this.selfTaskPlan[0].planDate && (this.selfTaskPlan[0].planDate !== null || this.selfTaskPlan[0].planDate !== undefined)) {
            return true
          } else {
            return false
          }
        }
        return false
      }
      return false
    },
    initiativeMenuFocus (id) {
      this.calendarFocusOutOnSelect()
      const list = document.querySelector('#selected-task-initiatives input')
      setTimeout(function () {
        const selectedInput = document.querySelector('#selected-task-initiatives .vs__dropdown-menu')
        if (selectedInput) {
          selectedInput.style.left = `${list.offsetLeft}px`
        }
      }, 0)
    },
    assignedMenuFocus () {
      this.calendarFocusOutOnSelect()
      const list = document.querySelector('#selected-task-assignedids input')
      setTimeout(function () {
        const selectedInput = document.querySelector('#selected-task-assignedids .vs__dropdown-menu')
        if (selectedInput) {
          selectedInput.style.left = `${list.offsetLeft}px`
        }
      }, 0)
    },
    clearDueDate ($event) {
      if (this.selectMenuFocus) {
        this.selectMenuFocus = false
      }
      $event.cancelBubble = true
      const self = this
      setTimeout(function () { self.taskCopy.due = '' }, 350)
      if (this.activityChanges.due) {
        delete this.activityChanges.due
      } else {
        this.activityChanges = { ...this.activityChanges, due: 'removed' }
      }
      this.resetCalendar()
    },
    clearPlanDate ($event) {
      if (this.selectMenuFocus) {
        this.selectMenuFocus = false
      }
      $event.cancelBubble = true
      if (this.selfTaskPlan && this.selfTaskPlan[0]) {
        this.selfTaskPlan[0].planDate = null
        this.selfTaskPlan[0].type = ''
      }
      this.resetCalendar()
    },
    resetCalendar () {
      const calendarElement = document.querySelector('.task-calendar')
      if (calendarElement) {
        calendarElement.style.removeProperty('left')
        calendarElement.style.removeProperty('top')
        calendarElement.style.removeProperty('opacity')
      }
      this.showCalendar = false
      this.resetDates = !this.resetDates
      this.calendarClicked = ''
    },
    animateCalendar () {
      if (this.selectMenuFocus) {
        this.selectMenuFocus = false
      }
      const calendarElement = document.querySelector('.task-calendar')
      if (calendarElement) {
        calendarElement.style.transition = 'opacity 0.05s ease-in'
        calendarElement.style.opacity = 1
      }
    },
    whenIconClick () {
      this.resetCalendar()
      this.$nextTick(() => {
        this.calendarClicked = 'when'
        this.showCalendar = true
        this.showPresets = true
        const self = this
        setTimeout(function () {
          self.positionIconCalendar('when')
          self.animateCalendar()
        }, 60)
      })
    },
    dueIconClick () {
      this.resetCalendar()
      this.$nextTick(() => {
        this.calendarClicked = 'due'
        this.showCalendar = true
        this.showPresets = false
        const self = this
        setTimeout(function () {
          self.positionIconCalendar('due')
          self.animateCalendar()
        }, 60)
      })
    },
    dueDateClick () {
      this.resetCalendar()
      this.$nextTick(() => {
        if (this.showCalendar && this.calendarClicked !== 'when') {
          this.showCalendar = false
        } else {
          const self = this
          this.showCalendar = true
          this.showPresets = false
          this.calendarClicked = 'due'
          setTimeout(function () {
            self.positionCalendar('due')
            self.animateCalendar()
          }, 60)
        }
      })
    },
    whenDateClick () {
      this.resetCalendar()
      this.$nextTick(() => {
        if (this.showCalendar && this.calendarClicked !== 'due') {
          this.showCalendar = false
        } else {
          const self = this
          this.showCalendar = true
          this.showPresets = true
          this.calendarClicked = 'when'
          setTimeout(function () {
            self.positionCalendar('when')
            self.animateCalendar()
          }, 60)
        }
      })
    },
    positionCalendar (type) {
      let dateElement
      if (type === 'when') {
        dateElement = document.querySelector('.plan-details .task-when')
      } else if (type === 'due') {
        dateElement = document.querySelector('.due .task-due')
      }
      const dateElementOffsetTop = dateElement ? dateElement.offsetTop : 0
      const dateElementOffsetLeft = dateElement ? dateElement.offsetLeft : 0
      const calendarElement = document.querySelector('.task-calendar')
      if (calendarElement) {
        if (!this.isInViewport(calendarElement)) {
          calendarElement.style.left = `${dateElementOffsetLeft}px`
          calendarElement.style.top = `${dateElementOffsetTop - calendarElement.clientHeight}px`
        } else {
          calendarElement.style.left = `${dateElementOffsetLeft}px`
          calendarElement.style.top = `${dateElementOffsetTop + 20}px`
        }
      }
    },
    positionIconCalendar (type) {
      let icon
      if (type === 'when') {
        icon = document.querySelector('.editing-when-icon')
      } else if (type === 'due') {
        icon = document.querySelector('.editing-due-icon')
      }
      const iconOffsetTop = icon ? icon.offsetParent.offsetTop : 0
      const iconOffsetLeft = icon ? icon.offsetParent.offsetLeft : 0
      const calendarElement = document.querySelector('.task-calendar')
      if (calendarElement) {
        if (!this.isInViewport(calendarElement)) {
          calendarElement.style.left = `${iconOffsetLeft}px`
          calendarElement.style.top = `${iconOffsetTop - calendarElement.clientHeight}px`
        } else {
          calendarElement.style.left = `${iconOffsetLeft}px`
          calendarElement.style.top = `${iconOffsetTop + 20}px`
        }
      }
    },
    isInViewport (element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    dateSelected (dated, type) {
      // console.log('Task.dateSelected', type, dated)
      if (this.calendarClicked === 'due') {
        const prev = this.taskCopy.due
        this.taskCopy.due = dated ? moment(dated).toDate() : ''
        if (prev) {
          this.activityChanges = { ...this.activityChanges, due: `changed from ${this.getDate(prev)} to ${this.getDate(dated)}` }
        } else {
          this.activityChanges = { ...this.activityChanges, due: `set to ${this.getDate(dated)}` }
        }
      }
      if (this.calendarClicked === 'when') {
        if (this.selfTaskPlan && this.selfTaskPlan[0]) {
          this.selfTaskPlan[0].planDate = dated ? moment(dated).toDate() : ''
          this.selfTaskPlan[0].type = type
        } else {
          this.selfTaskPlan.push({
            planDate: dated ? moment(dated).toDate() : '',
            type
          })
        }
      }
      const self = this
      setTimeout(function () { self.showCalendar = false }, 400)
    },
    formatDate (date) {
      if (date) {
        if (typeof date.seconds === 'number') {
          const dateParam = moment.unix(date.seconds).toDate()
          return moment(dateParam).format('ddd, D MMM')
        }
        return moment(date).format('ddd, D MMM')
      }
      return ''
    },
    getDueDate (fromTask) {
      if (!fromTask) {
        fromTask = this.taskCopy
      }
      let dateText = {}
      const diffInDays = this.compareDueDateWithToday(fromTask)
      switch (true) {
        case (diffInDays === 0):
          dateText = {
            text: this.checkIsToday() ? 'Today' : 'Tomorrow',
            color: '#FC5555',
            icon: 'deadline-red',
            bigicon: 'deadlinepicker-red'
          }
          return dateText
        case (diffInDays === 1):
          dateText = {
            text: this.compareDueDateWithToday() + ' day',
            color: '#FC5555',
            icon: 'deadline-red',
            bigicon: 'deadline-red'
          }
          return dateText
        case (diffInDays > 1 && diffInDays <= 5):
          dateText = {
            text: this.compareDueDateWithToday() + ' days',
            color: '#FF985E',
            icon: 'deadline-amber',
            bigicon: 'deadline-amber'
          }
          return dateText
        case (diffInDays > 5 && diffInDays <= 14):
          dateText = {
            text: this.compareDueDateWithToday() + ' days',
            color: '#6184AD',
            icon: 'deadline-grey',
            bigicon: 'deadlinepicker-on'
          }
          return dateText
        case (diffInDays < 0):
          dateText = {
            text: 'Overdue',
            color: '#FC5555',
            icon: 'deadline-red',
            bigicon: 'deadline-red'
          }
          return dateText
        default:
          dateText = {
            text: this.formatDate(this.taskCopy.due),
            color: '#6184AD',
            icon: 'deadline-grey',
            bigicon: 'deadlinepicker-on'
          }
          return dateText
      }
    },
    compareDueDateWithToday (fromTask) {
      if (!fromTask) {
        fromTask = this.taskCopy
      }
      if (fromTask.due) {
        if (fromTask.due.seconds) {
          const dueDate = moment.unix(fromTask.due.seconds).toDate()
          return moment(dueDate).diff(moment(), 'days')
        }
        return moment(fromTask.due).diff(moment(), 'day')
      }
    },
    displayDueDateStatus () {
      const dueDateDays = this.compareDueDateWithToday()
      if (dueDateDays < 0) {
        const pastDueDays = dueDateDays * -1
        return pastDueDays === 1 ? pastDueDays + ' day ago' : pastDueDays + ' days ago'
      } else if (dueDateDays === 0) {
        return this.checkIsToday() ? 'Today' : 'Tomorrow'
      } else {
        return this.formatDate(this.taskCopy.due)
      }
    },
    async switchToTipTap (event, task) {
      if (event && (event.target.classList.contains('task-check') || event.target.parentNode.classList.contains('task-check'))) {
        return false
      }
      this.resetCalendarAndMenu()
      this.selectedTask = { ...task }
      this.editing = true
      if (event) {
        this.getClickPosition(event)
      }
      // this.$emit('editChange', task)
      const self = this
      if (this.taskCopy.id) {
        this.loadTaskActivity({ teamId: this.profile.team, taskId: this.taskCopy.id })
        this.loadTaskComments({ teamId: this.profile.team, taskId: this.taskCopy.id })
        this.selfTaskPlan = await this.getTaskPlan({ teamId: this.profile.team, userId: this.profile.id, taskId: this.taskCopy.id })
      } else {
        if (this.taskPlan) {
          if (this.taskPlan.date) {
            this.selfTaskPlan = [{
              planDate: this.taskPlan.date,
              type: ''
            }]
          }
        }
      }
      this.$nextTick(() => {
        setTimeout(function () {
          if (!this.showTaskAsModal) {
            if (self.cursorPosition === 0) {
              self.cursorPosition = document.querySelectorAll('.task-line.active .ProseMirror p')[0]?.innerText.length
            }
            // console.log(self.taskCopy.id, self.$refs['editor-' + self.taskCopy.id])
            const view = self.$refs['editor-' + self.taskCopy.id] ? self.$refs['editor-' + self.taskCopy.id].editor.view : ''
            if (view) {
              try {
                view.dispatch(view.state.tr.setSelection(TextSelection.near(view.state.doc.resolve(self.cursorPosition + 1))))
                view.focus()
              } catch {
                self.$refs['editor-' + self.taskCopy.id].editor.commands.focus('end')
              }
            }
          }
        }, 500)
      })
    },
    getClickPosition (event) {
      const selection = window.getSelection()
      const position = selection.focusOffset
      let focusNode = selection.focusNode
      if (focusNode && event) {
        while (focusNode.parentElement && !focusNode.parentElement.classList.contains('task-text-initiatives')) {
          focusNode = focusNode.parentElement
        }
      }
      const nodeOffset = 0
      this.cursorPosition = nodeOffset + position
      const taskText = document.querySelector('#taskText' + this.taskCopy.id)
      if (!taskText.contains(event.target) || taskText.classList.contains('fade')) {
        this.cursorPosition = 0
      }
    },
    onTaskUpdateSelected (value, key) {
      const editor = this.$refs['editor-' + this.taskCopy.id]
      const editorDesc = this.$refs['editor-desc-' + this.taskCopy.id]
      let tags = []
      let mentions = []
      let descMentions = []
      if (editor) {
        tags = editor.getTags()
        mentions = editor.getMentions()
      }
      if (editorDesc) {
        descMentions = editorDesc.getMentions()
      }
      const allMentions = [...new Set([...descMentions, ...mentions])]
      this.taskCopy.mentions = allMentions
      // console.log('onTaskUpdateSelected', value, key, editor, tags, allMentions)
      if (this.selectedTask) {
        const extractContent = (s) => {
          const span = document.createElement('span')
          span.innerHTML = s
          return span.textContent || span.innerText
        }
        for (const tag of tags) {
          const initiative = this.initiatives.filter(initiative => initiative.name === tag)
          if (initiative) {
            if (!this.taskCopy.shareInitiatives) {
              this.taskCopy.shareInitiatives = []
            }
            if (!this.taskCopy.shareInitiatives.includes(initiative[0].id)) {
              this.taskCopy.shareInitiatives.push(initiative[0].id)
            }
          }
        }
        const prev = '' + extractContent(this.taskCopy.text)
        this.taskCopy.text = value
        if (prev) {
          this.activityChanges = { ...this.activityChanges, title: `changed from &nbsp;<i>'${prev}'</i>` }
        } else {
          this.activityChanges = { ...this.activityChanges, title: 'updated' }
        }
      }
    },
    onTaskDescriptionUpdateSelected (value, key) {
      const editor = this.$refs['editor-' + this.taskCopy.id]
      const editorDesc = this.$refs['editor-desc-' + this.taskCopy.id]
      let mentions = []
      let descMentions = []
      // let tagIds = []
      if (editor) {
        mentions = editor.getMentions()
      }
      if (editorDesc) {
        descMentions = editorDesc.getMentions()
      }
      const allMentions = [...new Set([...descMentions, ...mentions])]
      this.taskCopy.mentions = allMentions
      // console.log('onTaskDescriptionUpdateSelected', value, key, editor, allMentions)

      if (this.selectedTask) {
        this.taskCopy.description = value
        this.activityChanges = { ...this.activityChanges, description: true }
      }
    },
    onTaskUpdate (value, key, editor) {
      if (!editor) {
        editor = ''
      }
      const selectedTaskId = key.replace('idx-', '')
      // let tags = []
      // let tagIds = []
      // let mentions = []
      // let dates = []
      const editorRef = this.$refs['editor-' + selectedTaskId]
      if (editorRef) {
        // tags = editorRef.getTags()
        // tagIds = editorRef.getTagIds()
        // mentions = editorRef.getMentions()
        // dates = editorRef.getDates()
      }
    },
    saveSelected (task) {
      this.$emit('saveSelected', task)
    },
    setSelectedInitiative (value) {
      this.tagInput = ''
      const self = this
      this.taskCopy.shareInitiatives = value
      const initiativeNames = this.getInitiativesName(value)
      this.activityChanges = { ...this.activityChanges, initiatives: initiativeNames }
      setTimeout(function () { self.initiativeMenuFocus() }, 5)
    },
    setSelectedAssignee (value) {
      this.assigneeInput = ''
      const self = this
      this.taskCopy.shareUsers = value
      if (value.length > 0) {
        if (!value.includes(this.profile.id)) {
          this.taskCopy.subscribers = [...value, this.profile.id]
        } else {
          this.taskCopy.subscribers = value
        }
      } else {
        this.taskCopy.subscribers = []
      }
      const userNames = this.getUsersName(value)
      this.activityChanges = { ...this.activityChanges, assigned: userNames }
      setTimeout(function () { self.assignedMenuFocus() }, 5)
    },
    completeTask (task) {
      // console.log('completeTask', task)
      if (!task.id) {
        return false
      }
      this.taskCopy.completed = true
      this.taskCopy.completedOn = new Date()
      this.taskCopy.completedBy = this.profile.id
      this.activityChanges = { ...this.activityChanges, completed: new Date() }
      this.$emit('taskCompleted')
      this.saveSelected(this.taskCopy)
      this.$nextTick(function () {
        this.selectedTaskId = false
        this.lastTaskValue = ''
        this.selectedTask = null
        this.saveTask({ teamId: this.taskCopy.teamId, shareInitiatives: this.taskCopy.shareInitiatives, taskListIds: this.taskCopy.taskListIds, taskId: this.taskCopy.id, task: this.taskCopy, shareUsers: this.taskCopy.shareUsers }).then((response) => {
          const taskId = this.taskCopy.id ? this.taskCopy.id : null
          if (taskId) {
            if (!this.isActivityBlank(this.activityChanges)) {
              this.saveTaskActivity({ activity: { ...this.activityChanges, taskId } }).then(() => {
                this.activityChanges = {}
              })
            }
          } else {
            this.activityChanges = {}
          }
        })
        if (this.taskCopy.noteId) {
          this.saveUpdateMessage({ teamId: this.taskCopy.teamId, updateId: this.taskCopy.noteId, messageParam: this.taskCopy.text })
        }
      })
    },
    incompleteTask (task) {
      // console.log('incompleteTask', task)
      this.taskCopy.completed = false
      this.taskCopy.completedOn = null
      this.activityChanges = { ...this.activityChanges, uncompleted: new Date() }
      this.$emit('taskUncompleted')
      this.saveSelected(this.taskCopy)
      this.$nextTick(function () {
        this.selectedTaskId = false
        this.lastTaskValue = ''
        this.selectedTask = null
        this.saveTask({ teamId: this.taskCopy.teamId, shareInitiatives: this.taskCopy.shareInitiatives, taskListIds: this.taskCopy.taskListIds, taskId: this.taskCopy.id, task: this.taskCopy, shareUsers: this.taskCopy.shareUsers }).then((response) => {
          const taskId = this.taskCopy.id ? this.taskCopy.id : null
          if (taskId) {
            if (!this.isActivityBlank(this.activityChanges)) {
              this.saveTaskActivity({ activity: { ...this.activityChanges, taskId } }).then(() => {
                this.activityChanges = {}
              })
            }
          } else {
            this.activityChanges = {}
          }
        })
        if (this.taskCopy.noteId) {
          this.saveUpdateMessage({ teamId: this.taskCopy.teamId, updateId: this.taskCopy.noteId, messageParam: this.taskCopy.text })
        }
      })
    },
    getUsersName (userIds) {
      const userNames = []
      if (this.teamProfiles) {
        if (userIds) {
          for (const userId of userIds) {
            userNames.push(this.teamProfiles.find((user) => userId === user.id).displayName)
          }
        }
      }
      return userNames
    },
    getInitiativesName (initiativeIds) {
      const initiativeNames = []
      if (this.initiatives) {
        if (initiativeIds) {
          for (const initiativeId of initiativeIds) {
            if (this.initiatives.find((initiative) => initiativeId === initiative.id)) {
              initiativeNames.push(this.initiatives.find((initiative) => initiativeId === initiative.id).name)
            }
          }
        }
      }
      return initiativeNames
    },
    getDate (dObj) {
      if (dObj) {
        if (dObj instanceof Date) {
          return this.dateShortDisplayFormat(dObj)
        }
        if (dObj.toDate) {
          return this.dateShortDisplayFormat(dObj.toDate())
        }
      }
      return dObj
    },
    dateShortDisplayFormat (timestamp) {
      if (timestamp) {
        return moment.utc(timestamp).format('DD MMM')
      } else {
        return ''
      }
    },
    initials (profile) {
      if (profile) {
        let initialFirst = ''
        let initialLast = ''
        let displayNameParts = ['', '']
        displayNameParts = profile.displayName.split(' ')
        if (profile.firstName) {
          initialFirst = profile.firstName.trim().substring(0, 1)
        } else {
          initialFirst = displayNameParts[0].substring(0, 1)
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${initialFirst}${initialLast}`.toUpperCase()
      }
      return 'U'
    },
    isTaskUpdated () {
      // compare task copy to task to see if changes have been made
      if (this.task.text !== this.taskCopy.text) {
        return true
      }
      if (this.task.shareInitiatives !== this.taskCopy.shareInitiatives) {
        return true
      }
      if (this.task.subscribers !== this.taskCopy.subscribers) {
        return true
      }
      if (this.task.due !== this.taskCopy.due) {
        return true
      }
      if (this.task.description !== this.taskCopy.description) {
        return true
      }
      if (this.task.shareUsers !== this.taskCopy.shareUsers) {
        return true
      }
      return false
    },
    async saveTaskFromCopy () {
      if (this.taskCopy.text !== '<p></p>' && this.taskCopy.text !== '<p style="text-align: left"></p>') {
        if (this.taskListId && this.fromInitiative.length > 0) {
          const initiative = this.taskCopy.shareInitiatives.some(taskInitiative => this.taskCopy.shareInitiatives.indexOf(taskInitiative) >= 0)
          if (!initiative) {
            this.taskCopy.shareInitiatives = [...this.taskCopy.shareInitiatives, ...this.fromInitiative]
          }
          this.taskCopy.taskListIds = this.taskListId
        }
        if ((this.taskCopy.shareInitiatives && this.taskCopy.shareInitiatives.length !== 0) || (this.taskCopy.shareUsers && this.taskCopy.shareUsers.length !== 0)) {
          this.taskCopy.shareEnabled = true
        } else {
          this.taskCopy.shareEnabled = false // private user
        }
        await this.saveTask({ teamId: this.profile.team, taskId: this.taskCopy.id, task: this.taskCopy }).then((response) => {
          // console.log('saveTask', this.taskCopy.id, response)
          const taskId = this.taskCopy.id ? this.taskCopy.id : null
          if (taskId) {
            if (!this.isActivityBlank(this.activityChanges)) {
              this.saveTaskActivity({ activity: { ...this.activityChanges, taskId } }).then(() => {
                this.activityChanges = {}
              })
            }
          } else {
            this.activityChanges = {}
          }
          const taskResponseId = this.taskCopy.id || response.id
          this.taskCopy.id = taskResponseId
          // console.log('Task.saveTaskFromCopy saveTaskPlan?', this.selfTaskPlan, this.taskCopy.id)
          if (this.selfTaskPlan && this.selfTaskPlan[0]) {
            const tp = {
              teamId: this.profile.team,
              userId: this.profile.id,
              id: this.selfTaskPlan[0].id || false,
              taskId: taskResponseId || 'no task id',
              planDate: this.selfTaskPlan[0].planDate ? this.selfTaskPlan[0].planDate.seconds ? this.selfTaskPlan[0].planDate.toDate() : this.selfTaskPlan[0].planDate : '',
              type: this.selfTaskPlan[0].type
            }
            // console.log('Task.saveTaskFromCopy saveTaskPlan', tp)
            this.saveTaskPlan(tp).then(() => {
              if (this.selfTaskPlan[0]) {
                if (this.selfTaskPlan[0].planDate || this.selfTaskPlan[0].type) {
                  this.taskCopy.isPlanPresent[this.profile.id] = true
                } else {
                  this.taskCopy.isPlanPresent[this.profile.id] = false
                }
              } else {
                this.taskCopy.isPlanPresent[this.profile.id] = false
              }
              // console.log('Task.saveTaskFromCopy saveTaskPlan', this.taskCopy)
              this.saveTask({ teamId: this.profile.team, taskId: taskResponseId, task: { ...this.taskCopy, id: taskResponseId } })
            })
          }
        })
        return false
      } else {
        return true
      }
    },
    checkTaskSaveOrClose () {
      const self = this
      setTimeout(async function () {
        // console.log('checkTaskSaveOrClose', self.taskCopy.id)
        if (!self.taskCopy.id) {
          self.saving = true
        }
        const resetCloseTask = await self.saveTaskFromCopy()
        if (resetCloseTask) {
          setTimeout(function () {
            console.log('Task.checkTaskSaveOrClose.resetCloseTask', self.taskCopy)
            self.editing = false
            self.showTaskAsModal = false
            self.checkContentWidth()
            self.resetCalendarAndMenu()
            if (self.taskCopy.text === '' || self.taskCopy.text === '<p></p>' || self.taskCopy.text === '<p style="text-align: left" ></p>') {
              self.selfTaskPlan = []
            }
            self.saving = false
          }, 1000)
        } else {
          setTimeout(function () {
            console.log('Task.checkTaskSaveOrClose.close', self.taskCopy)
            self.editing = false
            self.showTaskAsModal = false
            self.showSubscribersList = false
            if (!self.taskCopy.id) {
              self.taskCopy = { ...self.task }
            }
            self.checkContentWidth()
            self.saving = false
          }, 1000)
        }
      }, 300)
    },
    async handleClickOutside (event) {
      console.log('Task.handleClickOutside', this.taskCopy.id, this.emitClickOutside)
      // console.dir(event.target)
      let isMention = false
      if (event.target.parentNode) {
        if (event.target.parentNode.classList.contains('atwho-li')) {
          isMention = true
        }
      }
      if (!(this.$el === event.target || this.$el.contains(event.target) || isMention) || event.target.classList.contains('task-scrim')) {
        if (this.showCalendar || this.selectMenuFocus) {
          this.resetCalendarAndMenu()
        } else {
          if (this.emitClickOutside) {
            this.$emit('clickOutside')
            await this.saveTaskFromCopy()
          } else {
            this.checkTaskSaveOrClose()
          }
        }
      } else if (event.target.classList.contains('inner-scrim')) {
        this.resetCalendarAndMenu()
      }
    },
    handleKeyDown (event) {
      const self = this
      if (event.key === 'Escape') {
        self.showCalendar = false
        self.showTaskAsModal = false
        self.editing = false
        if (this.emitClickOutside) {
          this.$emit('clickOutside')
        }
        // console.log(self.task.text, self.taskCopy.text)
        self.taskCopy = { ...self.task }
      }
    }
  },
  watch: {
    editing: {
      handler () {
        this.tagInput = ''
        this.assigneeInput = ''
        if (this.editing) {
          this.$emit('editing', this.task.id || 'new')
          // use timeout so the fist click event (clicking into the task) doesnt fire
          const self = this
          this.taskCopy = { ...this.task }
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
            document.addEventListener('keydown', self.handleKeyDown)
          }, 300)
        } else {
          this.$emit('editing', false)
          // use timeout so the last click event has time to fire
          const self = this
          this.taskCopy = { ...this.task }
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
            document.removeEventListener('keydown', self.handleKeyDown)
          }, 300)
        }
      },
      immediate: true
    },
    'task.completed': {
      handler () {
        this.checkContentWidth()
      }
    },
    showTaskAsModal: {
      handler () {
        const container = document.querySelector('body')
        if (container) {
          this.$nextTick(() => {
            if (!this.showTaskAsModal) {
              container.classList.remove('scroll-lock')
            } else {
              container.classList.add('scroll-lock')
            }
          })
        }
      },
      immediate: true
    }
  },
  beforeDestroy () {
    // remove any listeners
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style scoped>
.task-line {
  flex-flow:column;
  display:flex;
  justify-content: center;
  min-height: 22px;
  border-radius: 10px;
  background: #EEF2F7;
  margin: 0 20px 3px 20px;
}
.task-line.ghost {
  border-radius: 12px 12px 0 0;
  border-bottom:1px solid #B10000;
}
.task-line.dragging {
  width:200px;
}
.task-line .task-detail {
  display: flex;
  cursor: text;
  align-items: center;
  padding: 0.5px 10px;
}
.task-text-row {
  display:flex !important;
  width:100%;
  align-items:center;
  line-height:27px;
}
.task-line .display-initiatives:not(:empty) {
  display: flex;
}
.task-indicator-row:not(:empty) {
  margin: 0;
  display:flex;
  align-items: center;
  width: max-content;
  position: relative;
  flex: 1;
  justify-content: flex-end;
}
.task-indicator-row > div {
  display:flex;
  align-items: center;
  height:22px;
  font-size:14px;
  margin-left:10px;
  position: relative;
}
.task-indicator-row.long-width-attributes > span {
  margin-left: 0px
}
.task-line .task-check,  .task-line .task-check-closed {
  cursor: pointer;
  margin-right: 10px;
}
.task-line.active .task-check, .task-line .task-check-closed {
  align-self: baseline;
  margin-top: 5px;
}
</style>
<style>
.task-line .editing-initiatives {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.task-line .initiative-name {
  margin:4px;
  background: #e0e0e0;
  padding:0px 4px;
  border: 1px solid #ccc;
  border-radius: 3px;
  display:flex;
  align-items: center;
}
.task-line .initiative-input {
  flex: 1 1 auto;
  width:30px;
  border: none;
  outline: none;
}
.task-line .hashtag .avatar {
  height: 18px;
  width: 18px;
  margin-left:-18px;
}

.task-line .task-content.fade p::after {
  content: ' ';
  display: inline-block;
  position: absolute;
  z-index: 10;
  min-width: 100px;
  height: 100%;
  right: 0;
  width: 200px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 0%, rgba(238, 242, 247, 0.4) 58.95%, #EEF2F7 100%);
}
.task-line .task-text-initiatives {
  display: flex;
  align-items: center;
  min-width: 10px;
  cursor: text;
  width: 100%;
}
.task-line .task-text-initiatives p .hashtag {
  display: none;
}
.task-line.active .task-text-initiatives p .hashtag {
  margin-bottom: 0;
  height: 14px;
  font-size: 12px;
  flex: unset;
  display: inline-flex;
  padding-left: 0;
  padding-right: 7px;
  color: var(--hashtagtext-color);
  position: relative;
  border: .5px solid var(--hashtagtext-color);
  border-radius: 9px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  line-height: 16px;
  transform: translateZ(0);
  text-indent: 14px;
}
.task-line .task-text-initiatives p .hashtag::before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 17px;
  height: 17px;
  z-index: 10;
  background: white;
  opacity: 1;
  margin: 0;
  background-image: url(/img/tagpicker-on.c30cf0a7.svg);
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 2px;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
}
.task-line .task-text-initiatives p .pagelink::before {
  content: '[[';
  color: #BDBDBD;
}
.task-line .task-text-initiatives p .pagelink::after {
  content: ']]';
  color: #BDBDBD;
}
.task-line .task-text-initiatives p .pagelink {
  color: #404040;
}
.task-line .task-calendar {
  position: absolute;
  z-index: 40;
}
.app-section-wrapper.fullscreen .task-line .task-row {
  position: relative;
}
</style>

<style>
.task-tiptap {
  min-height: 40px;
  /* margin-bottom: 20px; */
  margin-right: 22px;
}
.task-tiptap .editor {
  border: none;
  display: inline-flex;
  flex-direction: row-reverse;
}
.task-line.active .task-tiptap p {
  color: #404040;
  font-size:14px;
  margin: 0;
  word-break: break-word;
  line-height: 24px;
  margin-bottom: 10px;
}
.task-tiptap p.is-empty.is-editor-empty {
  min-width:130px;
}
.task-tiptap.task-text .editor p.is-editor-empty:first-child::before {
  content: 'New task';
  position:absolute;
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  opacity: 0.33;
  line-height: 24px;
}
.task-tiptap.task-text .editor p.is-editor-empty:first-child::after,
.task-tiptap.task-description .editor p.is-editor-empty:first-child::after {
  content: ''
}
.task-tiptap.task-description .editor p.is-editor-empty:first-child::before {
  content: 'Description';
  position: absolute;
  font-size: 14px;
  color: #333333;
  opacity: 0.28;
  font-style: normal;
}
.task-line.active .mention {
  top: 6px;
}
.task-tiptap .editor__content {
  overflow: hidden;
}
.task-tiptap.task-text p {
  font-weight: 600;
}
.task-tiptap.task-description p {
  font-weight: 400;
}
</style>

<style lang="scss">
/* menu related styles */
.task-line .editing-icons .v-select,
.task-line .editing-wrapper .v-select {
  .vs__dropdown-toggle {
    border: none;
  }
  .vs__selected {
    background-color: transparent;
    border: none;
    line-height: 0;
    margin: 0;
    padding: 0;
    margin-bottom: 3px;
    .vs__deselect {
      display: none;
    }
    .tags-selection-options {
      display: flex;
      align-items: center;
      .add-icon {
        display: none;
      }
    }
    &:nth-last-of-type(1) {
      .tags-selection-options {
        .add-icon {
          display: flex;
        }
      }
    }
  }
  .vs__dropdown-toggle .vs__selected-options {
    padding: 0;
    input {
      margin: 0;
      font-size: 14px;
      padding: 0 0 0 1px;
      color: #4175B1;
      margin-bottom: 5px;
      &::-webkit-input-placeholder {
        color: #959EA7;
        font-size: 14px;
        padding-left: 5px;
      }
      &::-webkit-textfield-decoration-container {
        padding-left: 5px;
      }
    }
  }
  .vs__dropdown-menu {
    margin: 0;
    list-style: none;
    z-index: 11;
    background: #fff;
    border-radius: 6px;
    width: min-content;
    color: #6184ad;
    position: absolute;
    border: none;
    left: 12px; /*match padding of ul */
    max-height: 150px;
    .vs__dropdown-option {
      padding: 5px 12px;
      display: inline-flex;
      align-items: baseline;
      width: 100%;
      cursor: pointer;
      color: #6184ad;
      white-space: normal;
      .icon-wrapper {
        margin-right: 5px;
        height: 10px;
        width: 10px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .vs__dropdown-option:hover {
      background: #EBF3FC;
    }
    .vs__dropdown-option--highlight {
      background: #EBF3FC;
    }
  }
}

/* editing-icons v-select */
.task-line .editing-icons .editing-task-attributes.v-select  {
  .vs__dropdown-toggle {
    display: flex;
    width: 18px;
    flex-direction: row-reverse;
    padding: 0;
    cursor: pointer;
    transition: width .5s ease-in-out;
    &:focus-within {
      width: 140px;
      border-radius: 2px;
    }
  }
  .vs__selected-options,
  .vs__actions {
    padding: 0;
  }
  .vs__selected-options {
    input {
      margin-bottom: 0;
    }
  }
  &.initiative {
    margin-right: 15px;
    &:hover:not(:focus-within) {
      + .initiative-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.assigned {
    margin-right: 15px;
    &:hover:not(:focus-within) {
      + .assignee-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.task-line .editing-wrapper  {
  margin-left: 35px;
  margin-bottom: 15px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  .v-select {
    margin-right: 30px;
    margin-bottom: 10px;
    .vs__dropdown-toggle {
      padding: 0;
      cursor: pointer;
      transition: width .5s ease-in-out;
      &:focus-within {
        input {
          width: 140px;
        }
        .tags-selection-options {
          .add-icon {
            display: none;
          }
        }
      }
    }
    .vs__selected-options{
      padding: 0;
    }
    &.initiative {
      margin-right: 15px;
    }
  }
}
</style>

<style lang="scss" scoped>
.task-line .editing-icons:not(:empty) {
  display: flex;
  align-items: flex-start;
  width: fit-content;
  margin-left: 35px;
  margin-bottom: 15px;
  > *:not(:empty) {
    .editing-due-icon {
      margin-right: 15px;
      &:hover {
        + .due-tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .editing-when-icon {
      margin-right: 15px;
      &:hover {
        + .when-tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .mdc-button {
    min-width: 20px;
    height: auto;
    padding: 0;
  }
  .editing-icon-wrapper {
    position: relative;
    .initiative-tooltip {
      visibility: hidden;
      opacity: 0;
      bottom: 100%;
      right: 0px;
      margin-bottom: 5px;
    }
    .due-tooltip {
      visibility: hidden;
      opacity: 0;
      bottom: 100%;
      right: 2px;
      margin-bottom: 5px;
    }
    .when-tooltip {
      visibility: hidden;
      opacity: 0;
      bottom: 100%;
      right: 7px;
      margin-bottom: 5px;
    }
    .assignee-tooltip {
      visibility: hidden;
      opacity: 0;
      bottom: 100%;
      right: 2px;
      margin-bottom: 5px;
    }
  }
}
</style>
<style lang="scss">
.task-line .task-tabs {
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.07);
  .mdc-tab {
    .mdc-tab-indicator {
      &__content {
        &--underline {
          border-color: transparent;
        }
      }
    }
    &__text-label {
      color: #6184AD;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      .icon-wrapper {
        margin-right: 7px;
        height: 14px;
        width: 14px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    &.disabled {
      background: rgba(234, 234, 234, 0.6);
      pointer-events: none;
      .mdc-tab__text-label {
        opacity: 0.5;
      }
    }
    &--active {
      .mdc-tab__text-label {
        color: #828282;
      }
    }
  }
  .mdc-tab-scroller__scroll-area--scroll {
    border-radius: 10px;
  }
}
.task-line .inner-scrim {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: transparent;
  top: -25px;
  left: 0;
  z-index: 14;
  opacity: 0;
}
.task-line .attribute-icons-text {
  > :last-child {
    margin-right: 0px !important;
  }
  span {
    :last-child {
      margin-right: 0px !important;
    }
  }
  .task-comment {
    font-size: 10px;
    display: flex;
    color: #6184AD;
    margin-right: 10px;
    span {
      line-height: 10px;
      margin-left: 2px;
    }
  }
  .task-due { /* task-text line's task-due */
    display: flex;
    font-size: 10px;
    align-items: center;
    margin-top: -3px;
    margin-right: 10px;
    span.mdc-text {
      font-size: 10px;
      margin-left: 5px;
      margin-top: 2px;
      font-weight: 600;
      min-width: 30px;
      width: max-content;
    }
  }
  &.editing-due {
    display: flex;
    // margin-bottom: 15px;
    align-items: center;
    .task-due {
      font-size: 12px;
      cursor: pointer;
      span.mdc-text {
        font-size: 12px;
      }
      .icon-wrapper {
        img {
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}

/** when and due date styling -- text */
.task-line .when-due:not(:empty) {
  display: inline-flex;
  align-items: baseline;
  .due {
    .task-due { /* inside editing box */
      margin: 0;
      position: relative;
      .selected-due-tooltip {
        visibility: hidden;
        opacity: 0;
        bottom: 100%;
        left: -20px;
        margin-bottom: 5px;
        font-size: 9px;
      }
      > .icon-wrapper {
        display: block;
        &:hover {
          +.selected-due-tooltip {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      &:hover {
        span.clear-date-icon {
          opacity: 1;
        }
        + span.due-date-status {
          opacity: 0;
        }
      }
      &:focus-within {
        + span.due-date-status {
          opacity: 0;
        }
      }
    }
  }
  .plan-details {
    font-size: 11px;
    .task-when {
      width: fit-content;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      > .icon-wrapper {
        display: block;
        &:hover {
          +.selected-when-tooltip {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .selected-when-tooltip {
        visibility: hidden;
        opacity: 0;
        bottom: 100%;
        left: -9px;
        margin-bottom: 5px;
      }
      &:hover {
        .clear-date-icon {
          opacity: 1;
        }
      }
    }
    .date {
      text-transform: capitalize;
      font-weight: 600;
      color: #6184AD;
      margin-left: 5px;
    }
    & > span {
      display: flex;
      align-items: center;
    }
  }
  .clear-date-icon {
    opacity: 0;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    .mdc-icon {
      color: #333333;
      font-size: 14px;
    }
  }
}

.task-line.active {
  background: #fff;
  box-shadow: 0 0 2px 1px rgba(0,0,0,0.24);
  min-height: 100px;
  margin-bottom: 5px;
  position: relative;
  .task-detail {
    cursor: auto;
  }
  .task-text-initiatives {
    cursor: auto;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    .modal-icon {
      &.cross-icon {
        opacity: 0.5;
      }
    }
  }
  .task-detail {
    margin-top: 13px;
  }
  &.taskmodal {
    width: 100%;
    z-index: 30;
    box-shadow: 0 0 10px 1px rgba(0,0,0,0.24);
    background: #fafafa;
    flex-flow: unset; /* override column */
    border-radius: 10px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto !important;
    min-height: max-content;
    max-height: max-content;
    min-width: 300px;
    max-width: 500px;
    .task-row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      background: white;
      z-index: 13;
      border-radius: 10px;
      .task-comments {
        max-height: 250px;
      }
    }
    .task-scrim {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      background: rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
  }
  .task-tiptap {
    &.task-text {
      p {
        font-weight: 700;
      }
    }
  }
  .subscribers-details {
    margin-left: 35px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .count-label-wrapper {
      display: flex;
      align-items: center;
      font-size: 10px;
      .count {
        color: #b10000;
        font-weight: 800;
        font-size: 10px;
        margin-right: 5px;
      }
      .label {
        color: #828282;
        font-weight: 300;
        font-size: 10px;
      }
    }
    .subscribe-action {
      display: flex;
      align-items: center;
      position: relative;
      .mdc-button {
        font-size: 10px;
        height: 20px;
        text-transform: initial;
        color: #6184AD;
      }
      .subscribers-list {
        display: flex;
        flex-direction: column;
        position: absolute;
        background: #fff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        min-height: 70px;
        top: 20px;
        margin: 0 0 0 70px;
        padding: 10px;
        z-index: 29;
        .heading {
          display: flex;
          align-items: center;
          padding-bottom: 5px;
          border-bottom: 0.5px dashed #959EA7;
          .mdc-text {
            color: #959EA7;
            font-weight: 700;
          }
        }
        .subscribers-initials {
          padding-top: 10px;
          .profile-redirect {
            margin-bottom: 10px;
            .profile-display-name {
              color: #6184AD;
              font-weight: 600;
            }
          }
        }
      }
    }
    .private-label {
      text-transform: uppercase;
      font-size: 10px;
      color: #828282;
    }
  }
}
.task-line {
  .task-content {
    white-space: nowrap;
    font-size:14px;
    height: max-content;
    color: #666666;
    width: max-content;
    overflow: hidden;
    margin-right: 10px;
    p {
      margin: 0;
      color: #404040;
      position: relative;
      font-weight: 600;
      &:not(:first-child) {
        display: none;
      }
      &:empty:before {
        content: 'Type a new task';
        color: #959EA7;
        font-weight: normal;
      }
    }
    &.completed {
      color:#bdbdbd;
      font-size:14px;
      padding: 3px;
      text-decoration: line-through;
      text-decoration-color: #000;
      text-decoration-thickness: 0;
      .mention {
        all: unset;
        &::before {
          all: unset;
        }
      }
      .hashtag {
        all: unset;
        &::before {
          all: unset;
        }
      }
      p {
        color: #bdbdbd;
      }
    }
  }
  &.new-task-line {
    background: #FFFFFF;
    &:not(.active) {
      border: 0.5px dashed rgba(149, 158, 167, 0.58);
    }
    border-radius: 10px;
    .task-check {
      opacity: 0.4;
    }
  }
}
.task-line .task-row .task-initiatives {
  &.mdc-button {
    height: 16px;
    color: #6184AD;
    display: flex;
    margin: 0 10px 0 0;
    border-radius: 9px;
    text-transform: none;
    width: max-content;
    min-width: 20px;
    border: 0.5px solid #4175B1;
    font-size: 11px;
    background: #fff;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding: 1px 8px;
    > .icon-wrapper {
      height: 10px;
      width: 10px;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
