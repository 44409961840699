<template>
  <div class="meeting-notes" v-bind:class="{ 'fullscreen-mode': expandedView }">
    <header>
      <h1>Meeting Notes</h1>
      <mdc-icon-button  @click.native="closeMeetingNotes">clear</mdc-icon-button>
    </header>
    <div class="meeting-info">
      <mdc-text typo="subtitle1" class="subject-label" tag="span">{{meetingDetails.subject}}</mdc-text>
      <mdc-text typo="body2" tag="span">{{getMeetingDateInFormat(meetingDetails.date)}} - {{ meetingDetails.isAllDay ? 'All day' : getTimeFormat(meetingDetails.startDateTime) + ' - ' + getTimeFormat(meetingDetails.endDateTime) }}</mdc-text>
    </div>
    <tiptap-menu-dialog
      :formatIconPosition="formatIconPosition"
      @closeFormatControlMenu="closeFormatControlMenu"
      :showFormatControlsMenu="openFormatControls && (composerView === 'meetingPage')"
      :editor="composerEditor"
      noteBlocksEnabled />
    <tiptap-settings-dialog
      v-if="!externalMenu"
      :settingsIconPosition="settingsIconPosition"
      @closeSettingsMenu="closeSettingsMenu"
      :showSettingsMenu="!externalMenu && openSettings && composerView === 'meetingPage'"
      :editor="composerEditor"
      noteBlocksEnabled  />
    <tiptap-editor
        :disableNoteBlockSharing="disableNoteBlockSharing"
        v-if="accessToken"
        class="meetingnotes-tiptap"
        :doc="meetingPage.pageLink"
        :docState="meetingPage.docState"
        :noteBlocks="meetingPage.noteBlocks"
        addDefaultNoteBlock
        noteBlocksEnabled
        @editorReady="composerEditor = $event"
        :hashtags="initiativeTags"
        :user="user"
        :mentionSuggestions="teamMentions"
        content=''
        :title='titleLabel'
        :accessToken="accessToken"
        :workspaceId="workspace.id"
        useWebSocketProvider
        externalMenu
        @onPageLinkCreate="onNotePageCreate"
        @onTextUpdate="meetingPageUpdate"
        usePageLinkSuggestions
        :pagelinks="pageLinks"
        :teamId="profile.team"
      ></tiptap-editor>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as Y from 'yjs'
import TiptapMenuDialog from './TiptapMenuDialog.vue'
import TiptapSettingsDialog from '../../components/flexibly/TiptapSettingsDialog.vue'
import moment from 'moment'

export default {
  name: 'MeetingNotes',
  components: {
    TiptapEditor: () => import('../../components/Tiptap'),
    TiptapMenuDialog,
    TiptapSettingsDialog
  },
  data () {
    return {
      composerEditor: null,
      debouncedMeetingPageUpdate: null,
      meetingPageText: '',
      usersConnectedToPage: [],
      accessToken: false
    }
  },
  props: {
    disableNoteBlockSharing: {
      type: Boolean,
      default: false
    },
    externalMenu: {
      type: Boolean,
      default: false
    },
    titleLabel: {
      type: String,
      default: ''
    },
    formatIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    settingsIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    openFormatControls: {
      type: Boolean,
      default: false
    },
    openSettings: {
      type: Boolean,
      default: false
    },
    composerView: {
      type: String,
      default: ''
    },
    expandedView: {
      type: Boolean,
      default: false
    },
    teamMentions: {
      type: Array,
      default: () => {
        return []
      }
    },
    meetingPage: {
      type: Object,
      default: () => {
        return {}
      }
    },
    meetingDetails: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  async created () {
  },
  beforeDestroy () {
    this.frameConnectedUsers('destroy')
  },
  async mounted () {
    this.accessToken = await this.user.getIdToken(true)
    this.frameConnectedUsers('mounted')
  },
  computed: {
    ...mapState([
      'user',
      'workspace',
      'profile'
    ]),
    ...mapState({
      personalInitiatives: state => state.teams.personalInitiatives,
      notePages: state => state.teams.notePages,
      meetings: state => state.teams.meetings,
      teams: state => state.teams.teams
    }),
    pageLinks () {
      return this.notePages
    },
    initiativeTags () {
      let tags = []
      for (const init of this.personalInitiatives) {
        if (init.teamId !== this.profile.team) {
          const tagName = `${this.getTeamName(init.teamId)}${init.name.replace(' ', '-')}`
          tags.push({ ...init, tagName })
        } else {
          const tagName = `${init.name.replace(' ', '-')}`
          tags.push({ ...init, tagName })
        }
      }
      tags = tags.sort((a, b) => a.tagName.localeCompare(b.tagName))
      return tags
    }
  },
  methods: {
    ...mapActions({
      updateMeetingPage: 'teams/updateMeetingPage',
      updateMeetingsByUid: 'teams/updateMeetingsByUid'
    }),
    getTeamName (teamId) {
      if (teamId !== this.profile.team) {
        if (this.teams) {
          const ft = this.teams.filter((t) => {
            return t.id === teamId
          })[0] || {}
          return `${ft.name || ''}/`
        }
      }
      return ''
    },
    getTimeFormat (timestamp) {
      return moment(timestamp.toDate()).format('HH:mm')
    },
    async frameConnectedUsers (from) {
      const meetingDetails = this.meetings.find(meeting => meeting.id === this.meetingDetails.id)
      let meeting = null
      if (meetingDetails) {
        meeting = meetingDetails
      }
      let users = null
      if (meeting) {
        if (from === 'mounted') {
          if (meeting && meeting.connectedUsers && meeting.connectedUsers.length > 0) {
            users = [...meeting.connectedUsers]
            const memberAtIndex = users.findIndex(profileId => this.profile.id === profileId)
            if (memberAtIndex === -1) {
              users.push(this.profile.id)
            }
            users = { connectedUsers: [...users] }
          } else {
            users = { connectedUsers: [this.profile.id] }
          }
        }
        if (from === 'destroy') {
          if (meeting.connectedUsers && meeting.connectedUsers.length > 0) {
            const memberAtIndex = meeting.connectedUsers.findIndex(profileId => this.profile.id === profileId)
            if (memberAtIndex !== -1) {
              users = [...meeting.connectedUsers]
              users.splice(memberAtIndex, 1)
              users = { connectedUsers: [...users] }
            }
          } else {
            users = { connectedUsers: [] }
          }
        }
        this.usersConnectedToPage = { ...users }
        await this.updateMeetingsByUid({ uid: this.meetingDetails.iCalUId, meeting: { ...users } })
      }
    },
    closeMeetingNotes () {
      this.$root.$emit('changeComposerView', 'default', 'COMPOSER')
    },
    getMeetingDateInFormat (dateString) {
      const meetingDate = moment(dateString, 'DD/MM/YYYY').format('MMMM DD, YYYY')
      return meetingDate
    },
    closeFormatControlMenu (value) {
      this.$emit('closeFormatControlsMenu', value)
    },
    closeSettingsMenu (value) {
      this.$emit('closeSettingsMenu', value)
    },
    onNotePageCreate ({ link }) {
      this.getNotePage({ teamId: this.profile.team, notePageName: link.label, notePageLink: link.link })
    },
    isEmpty (text) {
      if (text) {
        if (text !== '') {
          if (text !== '<p></p>' && text !== '<p style="text-align: left"></p>') {
            return false
          }
        }
      }
      return true
    },
    saveMeetingPageUpdate (meetingPageId, html, json, doc) {
      let docState
      const meetingPage = { html, json }
      if (doc) {
        docState = Y.encodeStateAsUpdate(doc)
        meetingPage.docState = docState
      }
      meetingPage.meetingId = this.meetingDetails.id
      this.updateMeetingPage({ teamId: this.profile.team, meetingPageId, meetingPage }).then(() => {
        if (meetingPage && meetingPage.blocks.length === 0) {
          const meetingUpdate = { meetingPageId: '' }
          this.updateMeetingsByUid({ uid: this.meetingDetails.iCalUId, meeting: meetingUpdate })
        } else {
          const meetingUpdate = { meetingPageId }
          this.updateMeetingsByUid({ uid: this.meetingDetails.iCalUId, meeting: meetingUpdate })
        }
      })
    },
    meetingPageUpdate (text, key) {
      const self = this
      if (this.debouncedMeetingPageUpdate) {
        clearTimeout(this.debouncedMeetingPageUpdate)
      }
      this.meetingPageText = text
      this.debouncedMeetingPageUpdate = setTimeout(() => {
        const jsonContent = self.composerEditor.getJSON()
        const htmlContent = text
        const doc = self.composerEditor.options.ydoc
        // console.log('Composer.meetingPageUpdate', this.meetingPage.id, htmlContent, jsonContent, doc, this.composerEditor)
        self.saveMeetingPageUpdate(this.meetingPage.id, htmlContent, jsonContent, doc)
      }, 1000)
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.meeting-notes {
  color: #404040;
  padding: 30px 30px 30px 35px;
  position: relative;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .meetingnotes-tiptap {
    margin-top: 20px;
    .editor {
      min-height: 150px;
      .editor__content {
        p {
          color: #394A5D;
          font-size: 14px;
          line-height: 23px;
          .hashtag {
            margin-bottom: 0;
            height: 15px;
            font-size: 14px;
            flex: unset;
            display: inline-flex;
            padding: 1px 2px;
            color: var(--hashtagtext-color);
            position: relative;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            line-height: 17px; /* height + padding(2) + border(1) */
            font-weight: normal;
            &::before {
              content: '#';
              color: var(--hashtag-color);
              font-weight: 400;
              position: absolute;
            }
          }
          .mention {
            vertical-align: middle;
            top: 0;
            line-height: 18px;
          }
        }
      }
    }
  }
  &.fullscreen-mode {
    padding: 30px 0;
    margin: 0;
    .dialog-menu {
      margin-top: -40px;
      margin-left: -85px;
    }
  }
  h1 {
    font-size: 30px;
    color: #404040;
  }
  .meeting-info {
    display: flex;
    flex-direction: column;
    color: #333333;
    .subject-label {
      font-weight: 600;
    }
  }
}
</style>
<style>
.sidebar-view .meeting-notes.fullscreen-mode .dialog-menu {
  margin-top: -30px;
  margin-left: 0;
}
</style>
