<template>
  <div v-if="profile" class="app-layout">
    <task-details-dialog
      v-if="displayTask"
      :id="'detailsfortask'+ displayTask.id"
      :task="displayTask" />
    <meeting-dialog
      v-if="displayMeeting"
      :id="'detailsforMeeting'"
      @close="closeMeetingDialog()"
      :showDialog="displayMeeting && displayMeeting.id" />
    <!-- <location-indicator-dialog class="loc-ind-dialog" v-if="locationIndicatorDialogDate" /> -->
    <section  v-if="openProfileDetails && detailsForProfile" class="profile-details app-layout-dialog" style="z-index: 40">
      <div class="dialog-scrim"></div>
      <profile-details-dialog
        :id="'detailsforprofile'+ detailsForProfile.profileId"
        @closeDialog="closeProfileDetails()"
        :profileDetails="detailsForProfile"
        :showDialog="openProfileDetails" />
    </section>
    <section v-if="openUpdateComments && displayUpdate && displayNoteBlock && displayUpdateComments" class="task-details app-layout-dialog">
      <div class="dialog-scrim" @click="closeUpdateCommentsDialog()"></div>
      <update-comments-dialog
        :pageDetails="displayUpdate"
        :commentMode="true"
        :selectedNoteBlock="displayNoteBlock"
        @closeDialog="closeUpdateCommentsDialog()"
      />
    </section>

    <section v-if="memberRequestDisplay" class="member-request-details app-layout-dialog">
      <div class="dialog-scrim"></div>
      <member-request-dialog :showDialog="memberRequestDisplay" />
    </section>
    <section  v-if="teamInvitesDisplay" class="team-invite-details app-layout-dialog">
      <div class="dialog-scrim"></div>
      <team-invite-dialog :showDialog="teamInvitesDisplay" />
    </section>
    <slot name="layout-content">
      <div class="flex-col-layout"
        v-bind:class="{
          'full-view': expandedView || schedulerFullScreen,
          'expanded-view': expandedView, // COMPOSER FULL VIEW
          'sidebar-view': showMentionsSideBar,
          'scheduler-full-view': schedulerFullScreen,
          'notification-mode': notificationMode === 'on'
        }">
        <slot name="section-content">
          <app-section
            enableSchedulerFullScreen
            cogMenu
            :composerView="composerView"
            @closeSetupCalendar="closeSetupCalendar"
            :showSettingsCogMenu="showSettingsCogMenu"
            :showSettingsMenuFor="showSettingsMenuFor"
            @enableSetupCalendar="enableSetupCalendar"
            @settingsCogClick="showSettingsCogMenuForScheduler"
            @closeSettingsCogMenu="closeSettingsCogMenu"
            @schedulerFullScreen="setSchedulerFullScreen"
            @fullscreen="setFullScreen"
            @toggleMentionsSideBar="setShowMentionsSideBar"
            class="admin-section schedule-app-layout left-side"
            :editable="false"
            :composerSectionExpanded="expandedView">
              <header class="mywork-title" v-if="!expandedView">
                <base-svg-icon name="mywork-red-on" />
                <mdc-text tag="span">MY WORK</mdc-text>
                <base-svg-icon name="task-ellipse" class="meeting-menu" clickable @click.native="showSettingsCogMenuForScheduler()" ref="meetingMenu" />
              </header>
              <scheduler
                ref="scheduler"
                @closeSetupCalendar="closeSetupCalendar"
                :enableSetupCalendarInScheduler="enableSetupCalendarInScheduler"
                :schedulerFullScreen="schedulerFullScreen"
                v-if="teamProfiles && teamProfiles.length && !expandedView"
                :composerView="composerView"
                @changeComposerView="changeComposerView"
                @dateToComposer="passDateToComposer"
                :isMobile="isMobile" />
          </app-section>
          <app-section
            class="admin-section composer-app-layout right-side"
            :composerView="composerView"
            @toggleMentionsSideBar="setShowMentionsSideBar"
            fullscreenEnabled
            :editable="false"
            :expandedView="expandedView"
            :openFormatControls="openFormatControls"
            :openSettings="openSettings"
            :notesSearch="!schedulerFullScreen"
            :pages="pages"
            :tasks="tasks"
            @titleNavClick="onTitleNavClick"
            @fullscreen="setFullScreen"
            @openFormatControlsMenu="openComposerFormatControls"
            @openSettingsMenu="openComposerSettings"
            :mentionsShown="expandedView && showAllLinks"
            :showMentionsSideBar="showMentionsSideBar"
            :title="schedulerFullScreen ? '' : composerTitle">
            <div v-if="expandedView && showAllLinks">
              <mentions
                :key="'mentions'+showMentionsSideBar"
                :showMentionsSideBar="showMentionsSideBar"
                @toggleMentionsSideBar="setShowMentionsSideBar"
                :commments="composerComments"
                :linkedMentions="composerLinkedMentions"
                />
            </div>
            <composer
              :showMentionsSideBar="showMentionsSideBar"
              @closeFormatMenu="openComposerFormatControls"
              @closeSettingsMenu="openComposerSettings"
              :openFormatControls="openFormatControls"
              :openSettings="openSettings"
              :formatIconPosition="formatIconPosition"
              v-if="!schedulerFullScreen"
              @titleChange="changeTitle"
              @setComposerData="setComposerData"
              :composerView="composerView"
              :composerData="composerData"
              :date="date"
              :expandedView="expandedView" />
          </app-section>
          <app-section
            class="admin-section mentions-side-bar-app-layout"
            :editable="false"
            :showMentionsSideBar="showMentionsSideBar"
            v-if="expandedView && showMentionsSideBar">
            <div v-if="expandedView && showAllLinks">
              <mentions-side-bar
                :mentionsTypeSelected="mentionsTypeSelected"
                :commments="composerComments"
                :linkedMentions="composerLinkedMentions"
              />
            </div>
          </app-section>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AppSection from './AppSection'
import Composer from './Composer'
import Scheduler from './Scheduler'
import Mentions from './Mentions'
import MentionsSideBar from './MentionsSideBar.vue'
import ProfileDetailsDialog from './ProfileDetailsDialog.vue'
import UpdateCommentsDialog from './UpdateCommentsDialog.vue'
import MemberRequestDialog from './MemberRequestDialog.vue'
import TeamInviteDialog from './TeamInviteDialog.vue'
import MeetingDialog from './MeetingDialog.vue'
// import LocationIndicatorDialog from './LocationIndicatorDialog.vue'
import TaskDetailsDialog from './TaskDetailsDialog.vue'
import BaseSvgIcon from './BaseSvgIcon.vue'
import moment from 'moment'
export default {
  name: 'AppLayout',
  components: {
    AppSection,
    Composer,
    Scheduler,
    Mentions,
    MentionsSideBar,
    ProfileDetailsDialog,
    MemberRequestDialog,
    TeamInviteDialog,
    UpdateCommentsDialog,
    MeetingDialog,
    // LocationIndicatorDialog,
    TaskDetailsDialog,
    BaseSvgIcon
  },
  data () {
    return {
      showAllLinks: false,
      notesSearch: false,
      enableSetupCalendarInScheduler: false,
      showSettingsCogMenu: false,
      showSettingsMenuFor: '',
      openProfileDetails: false,
      detailsForProfile: false,
      openUpdateComments: false,
      displayUpdate: false,
      displayNoteBlock: false,
      composerTitle: 'COMPOSER',
      composerView: 'default', // default or daily notes view
      composerData: {},
      date: {},
      composerComments: {},
      composerLinkedMentions: {},
      expandedView: false,
      showMentionsSideBar: false,
      mentionsTypeSelected: '',
      schedulerFullScreen: false,
      openFormatControls: false,
      openSettings: false,
      formatIconPosition: {},
      settingsIconPosition: {}
    }
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'profile',
      'displayProfile',
      'displayUpdateComments',
      'notificationMode',
      'memberRequestDisplay',
      'teamInvitesDisplay',
      'displayMeeting',
      'locationIndicatorDialogDate',
      'displayTask'
    ]),
    ...mapState({
      teamProfiles: state => state.teams.teamProfiles,
      notePages: state => state.teams.notePages,
      dailyPages: state => state.teams.dailyPages,
      meetingPages: state => state.teams.meetingPages,
      tasks: state => state.teams.tasks
    }),
    pages () {
      return [...(this.meetingPages ? this.meetingPages : []), ...(this.dailyPages ? this.dailyPages : []), ...(this.notePages ? this.notePages : [])]
    }
  },
  methods: {
    ...mapActions([
      'setDisplayUpdateComments',
      'setDisplayMeeting'
    ]),
    ...mapActions({
      getTasks: 'teams/getTasks',
      getLastSameLocation: 'teams/getLastSameLocation',
      getNextMeetingWithUser: 'teams/getNextMeetingWithUser',
      getProfileById: 'teams/getProfileById'
    }),
    enableSetupCalendar () {
      this.enableSetupCalendarInScheduler = true
      // console.log('enableSetupCalendar.changeComposerView', 'default', 'COMPOSER', { date: new Date() })
      this.changeComposerView('default', 'COMPOSER', { date: new Date() })

      this.$refs.scheduler.resetToCurrentDay()
    },
    closeSetupCalendar () {
      this.enableSetupCalendarInScheduler = false
    },
    showSettingsCogMenuForScheduler () {
      this.showSettingsCogMenu = true
      this.showSettingsMenuFor = 'scheduler'
      this.$nextTick(() => {
        const menuIconRect = this.$refs.meetingMenu.$el
        if (menuIconRect) {
          const menuElement = document.querySelectorAll('.settings-cog-menu')
          if (menuElement && menuElement[0]) {
            menuElement[0].style.top = this.$refs.meetingMenu.$el.offsetTop + 20 + 'px'
            menuElement[0].style.left = this.$refs.meetingMenu.$el.offsetLeft + 'px'
          }
        }
      })
    },
    closeSettingsCogMenu () {
      this.showSettingsCogMenu = false
      this.showSettingsMenuFor = ''
    },
    async openProfileDetailsDialog (profile) {
      this.openProfileDetails = true
      this.detailsForProfile = await this.displayProfileDetails({ ...profile })
    },
    closeProfileDetails () {
      this.openProfileDetails = false
    },
    openUpdateCommentsDialog (update, noteBlockId) {
      this.displayUpdate = { ...update }
      this.displayNoteBlock = noteBlockId
      this.openUpdateComments = true
    },
    closeUpdateCommentsDialog () {
      this.openUpdateComments = false
      this.displayUpdate = false
      this.displayNoteBlock = false
      this.setDisplayUpdateComments(false)
    },
    closeMeetingDialog () {
      this.setDisplayMeeting(false)
    },
    showMentionsLinks () {
      if (this.composerView !== 'allTasks') {
        this.showAllLinks = true
        return true
      }
      this.showAllLinks = false
      if (this.showMentionsSideBar) {
        this.showMentionsSideBar = false
      }
      return false
    },
    setShowMentionsSideBar (value, type) {
      this.showMentionsSideBar = value
      this.mentionsTypeSelected = type
    },
    setComposerData (composerData) {
      // console.log('AppLayout.setComposerData', composerData)
      this.composerComments = {}
      this.composerLinkedMentions = {}
      if (composerData.comments) {
        this.composerComments = composerData.comments
      }
      if (composerData.linkedMentions) {
        this.composerLinkedMentions = composerData.linkedMentions
      }
    },
    changeTitle (title) {
      if (title) {
        this.composerTitle = title
      }
    },
    onTitleNavClick (title) {
      this.composerTitle = 'COMPOSER'
      this.composerView = 'default'
    },
    changeComposerView (view, title, data) {
      // console.log('AppLayout.changeComposerView', view, title, data)
      this.composerTitle = title
      this.composerView = view
      this.$nextTick(() => {
        this.composerData = data
        // console.log('changeComposerView.noteSearch', data)
        if (data && data.notesSearch) {
          this.notesSearch = data.notesSearch
        }
      })
      this.openComposerFormatControls(false)
      this.openComposerSettings(false)
      // this.expandedView = false
      // this.showMentionsSideBar = false
    },
    passDateToComposer (date) {
      this.date = date
    },
    setFullScreen (value) {
      this.expandedView = value
      if (value) {
        this.showMentionsLinks()
      }
    },
    setSchedulerFullScreen (value) {
      this.schedulerFullScreen = value
    },
    openComposerFormatControls (value, top, left) {
      this.openFormatControls = value
      if ((left || left === 0) && (top || top === 0)) {
        this.formatIconPosition = {
          left,
          top
        }
      }
    },
    openComposerSettings (value, top, left) {
      // console.log('AppLayout.openComposerSettings', this.openSettings, value)
      this.openSettings = value
      if ((left || left === 0) && (top || top === 0)) {
        this.settingsIconPosition = {
          left,
          top
        }
      }
    },
    async displayProfileDetails (displayProfile) {
      const profileId = displayProfile.profileId
      let detailsForProfile = false
      const lastInLoc = await this.getLastSameLocation({ users: [this.profile.id, profileId] })
      let lastInLocation = ''
      if (lastInLoc) {
        lastInLocation = moment(lastInLoc).format('Do MMMM YYYY')
      }
      detailsForProfile = await this.getProfileById({ profileId }).then(async (userProfile) => {
        let nextMeetingDate = ''
        const msUserEmail = userProfile.msEmail || userProfile.email
        const googleUserEmail = userProfile.googleEmail
        const nextMeeting = await this.getNextMeetingWithUser({ msUserEmail, googleUserEmail })
        // console.log('applayout.displayProfileDetails.nextMeeting', nextMeeting)
        if (nextMeeting) {
          if (nextMeeting.startDateTime) {
            const mdate = nextMeeting.startDateTime.toDate()
            nextMeetingDate = moment(mdate).format('Do MMMM YYYY h:mm A')
          }
        }
        return {
          profileId,
          workingAt: null,
          name: this.getDisplayName(userProfile),
          lastInLocation,
          nextMeeting: nextMeetingDate
        }
      })
      return detailsForProfile
    },
    getDisplayName (profile) {
      if (profile) {
        return profile.displayName ? profile.displayName : ''
      }
      return ''
    }
  },
  mounted () {},
  created () {},
  watch: {
    profile: {
      handler (newPro, oldPro) {
        if (newPro) {
          if (!oldPro) {
            this.getTasks({ initiativeId: '', teamId: this.profile.team })
          }
        }
      },
      immediate: true
    },

    displayProfile () {
      const container = document.querySelector('body')
      if (container) {
        this.$nextTick(() => {
          if (!this.displayProfile) {
            container.classList.remove('scroll-lock')
          } else {
            container.classList.add('scroll-lock')
          }
        })
      }
      if (this.displayProfile) {
        this.openProfileDetailsDialog(this.displayProfile)
      } else {
        this.closeProfileDetails()
      }
    },
    displayUpdateComments: {
      handler () {
        if (this.displayUpdateComments) {
        // console.log('applayut.watch.displayUpdateComments', this.displayUpdateComments)
          this.openUpdateCommentsDialog(this.displayUpdateComments.update, this.displayUpdateComments.noteBlockId)
        }
      }
    },
    composerView: {
      handler () {
        if (this.expandedView) {
          this.showMentionsLinks()
        }
      }
    }
  },
  activated () {
    // console.log('AppLayout.activated')
    this.$root.$on('changeComposerView', (view, title, data) => {
      this.changeComposerView(view, title, data)
    })
  },
  deactivated () {
    // console.log('AppLayout.deactivated')
    this.$root.$off('changeComposerView')
  },
  beforeDestroy () {
    this.$root.$off('changeComposerView')
  }
}
</script>

<style scoped>
.main-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
}
.app-layout {
  background: var(--app-background);
  height:100%;
  flex:1;
}
.app-layout-content {
  height: 100%;
}
.one-col-layout {
  width: calc(100% - 80px);
  margin: 0px 40px 30px 40px;
  max-width: 1512px;
  min-height: 678px;
  height: 100%;
  display: grid;
}
.flex-col-layout {
  width: calc(100% - 80px);
  margin: 0px 40px 30px 40px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 1em;
  background: var(--app-background);
  transition: height 5ms ease-in-out;
  height: 100%;
  max-width: 1512px;
  min-height: 678px;
}
.flex-col-layout.expanded-view {
  grid-template-columns: 40px 1fr;
}
.flex-col-layout.expanded-view.sidebar-view {
  grid-template-columns: 40px 2fr 1fr;
}
.flex-col-layout.scheduler-full-view {
  grid-template-columns: 1fr 40px;
}
.flex-col-layout.notification-mode:not(.full-view) {
  background: #fff;
}
@media (max-width: 720px){
  .flex-col-layout {
    width: calc(100% - 20px);
    margin: 10px 10px 60px 10px;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    /* column-gap: 10px; */
    row-gap: 1em;
    background: var(--app-background);
  }
  .flex-col-layout.notification-mode {
    margin-top: 0;
    background: var(--app-background) !important;
  }
}
</style>

<style>
.mdc-drawer-app-content, .main-content {
  display:flex;
  flex-flow:column;
  flex:1;
  background:  var(--app-background);
}
.mdc-drawer-app-content.notification-mode {
  z-index: 24;
}
</style>
<style lang="scss">
.app-layout {
  .mywork-title {
    display: flex;
    align-items: center;
    margin: 0 10px 30px 20px;
    .icon-wrapper {
      height: 17px;
      width: 17px;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .meeting-menu {
      margin-left: 8px;
      margin-top: 3px;
      height: 24px;
      width: 13px;
    }
    .mdc-text {
      color: #0F3860;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .profile-details {
    display: flex;
    justify-content: center;
    margin-top: -75px; /* header height */
  }
  .app-layout-dialog {
    position: fixed;
    height: 100%;
    z-index: 30;
    left: 0;
    right: 0;
    .dialog-scrim {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .member-request-details {
    display: flex;
    justify-content: center;
  }
  .team-invite-details {
    display: flex;
    justify-content: center;
  }
  .task-details {
    display: flex;
    justify-content: center;
    margin-top: -75px; /* header height */
  }
}

</style>
