<template>
  <div class="initiative-details-wrapper">
    <app-layout>
      <div class="one-col-layout" slot="layout-content">
        <app-section :editable="false">
          <div class="initiative-details-content-wrapper">
            <div class="details-header-search-wrapper">
              <section class="breadcrumb" :class="tabDetailClicked === section.updates ? 'updates-section' : '' " v-if="initiativeDetails && initiativeDetails.tag">
                <router-link class="page-link" :to="{ name: 'Initiatives' }">
                  <mdc-text tag="span" typo="subtitle1" class="page1">Initiatives</mdc-text>
                </router-link>
                <mdc-icon icon="navigate_next"></mdc-icon>
                <initiative-name class="currentPage"  :initiativeId="initiativeDetails.id" showTeam :fontType="'subtitle1'" showHash />
              </section>
              <div class="app-section-search" v-if="tabDetailClicked !== section.updates">
                <auto-complete-outline
                  ref="initSearch"
                  autocompleteId="'initiative-details-search'"
                  class="initiative-details-search-autocomplete"
                  :items="initiativesKeys"
                  field="name"
                  displayField="key"
                  @set="routeToInitiative"
                  placeholder="Type here to find initiatives"
                  >
                </auto-complete-outline>
              </div>
            </div>
            <div class="initiative-details-content" :class="{tasks: tabDetailClicked === section.tasksLists}">
              <section class="header-tabs">
                <div class="header-status-switch" v-if="initiativeDetails">
                  <initiative-name v-if="initiativeDetails" :initiativeId="initiativeDetails.id" showTeam :fontType="'headline5'" showHash />
                  <span class="status-switch-wrapper">
                    <mdc-text tag="span" v-bind:class="initiativeDetails.status.replace(' ','').toLowerCase()" v-if="initiativeDetails.status" typo="caption" class="status">{{initiativeDetails.status}}</mdc-text>
                    <mdc-text tag="span" class="switch-text" typo="caption">{{ !initiativeVisible ? 'Initiative is private' : 'Initiative is visible'}}</mdc-text>
                    <label v-if="userInInitiative()" class="switch" for="initiative-switch">
                      <input v-model="initiativeVisible" type="checkbox" id="initiative-switch" @change="toggleVisibilitySwitch" />
                      <div class="slider round"></div>
                    </label>
                  </span>
                </div>
                <div class="initiative-details-tab">
                  <mdc-tab-bar class="mobile-vertical">
                    <mdc-tab :class="{clicked: tabDetailClicked === section.overview}" @click.native="tabDetailSelection(section.overview)">Overview</mdc-tab>
                    <mdc-tab v-if="userInInitiative()" :class="{clicked: tabDetailClicked === section.tasksLists }" @click.native="tabDetailSelection(section.tasksLists)">Task Lists</mdc-tab>
                  </mdc-tab-bar>
                </div>
              </section>
              <section v-if="tabDetailClicked === section.tasksLists" class="tasks-lists-section detail-type">
                <tasks-lists-details
                  :tasksLists="mergeTasksIntoTaskList"
                  :initiativeId="initiativeId"
                  :isMobile="isMobile" />
              </section>
              <section v-if="tabDetailClicked === section.updates" class="updates-section detail-type">
                <initiative-updates :initiative="initiativeDetails" />
              </section>
              <section v-if="tabDetailClicked === section.overview" class="overview-section detail-type">
                <initiative-overview
                  :key="'init-ov-' + initiativeDetails.id"
                  v-if="initiativeDetails && teamProfiles !== null && teamProfiles.length"
                  :tasksLists="mergeTasksIntoTaskList"
                  :initiativeDetails="initiativeDetails"
                  :isMobile="isMobile" />
              </section>
            </div>
          </div>
        </app-section>
      </div>
    </app-layout>
  </div>
</template>
<script>
import AppLayout from '../../components/flexibly/AppLayout'
import AppSection from '../../components/flexibly/AppSection'
import { mapState, mapActions } from 'vuex'
import TasksListsDetails from '../../components/flexibly/TasksListsDetails'
import InitiativeOverview from '../../components/flexibly/InitiativeOverview'
import InitiativeUpdates from '../../components/flexibly/InitiativeUpdates'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'
import InitiativeName from '../../components/flexibly/InitiativeName.vue'
export default {
  name: 'InitiativeDetails',
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
      initiatives: state => state.teams.initiatives,
      taskLists: state => state.teams.taskLists,
      tasks: state => state.teams.tasks,
      updates: state => state.teams.updates,
      teamProfiles: state => state.teams.teamProfiles,
      teams: state => state.teams.teams
    }),
    initiativesKeys () {
      return this.initiatives.map((init) => {
        const key = `#${this.getTeamName(init.teamId)}${init.name.replace(' ', '-')}`
        return { ...init, key }
      })
    },
    mergeTasksIntoTaskList () {
      if (this.taskLists) {
        return this.taskLists.map(taskList => {
          const filteredTasks = [...this.tasks].filter(task => task.shareInitiatives && task.shareInitiatives.includes(this.initiativeDetails.id) && task.taskListIds && task.taskListIds.includes(taskList.id))
          filteredTasks.sort((x, y) => {
            if (x.completed === y.completed) {
              return x.order - y.order
            } else {
              return (x.completed === y.completed) ? 0 : x.completed ? 1 : -1
            }
          })
          // console.log('mergeTasksIntoTaskList', { ...taskList, tasks: filteredTasks })
          return { ...taskList, tasks: filteredTasks }
        })
      }
      return []
    }
  },
  components: {
    AppLayout,
    AppSection,
    TasksListsDetails,
    InitiativeOverview,
    InitiativeUpdates,
    AutoCompleteOutline,
    InitiativeName
  },
  data () {
    return {
      initiativeId: '',
      initiativeDetails: {},
      tabDetailClicked: '',
      section: {
        tasksLists: 'taskslists',
        overview: 'overview',
        updates: 'updates'
      },
      initiativeVisible: false,
      searchVal: ''
    }
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  async beforeRouteUpdate (to, from, next) {
    this.$root.$emit('enableSearch', false)
    this.initiativeId = to.params.id
    this.setInitiativeDetails()
    next()
  },
  mounted () {
    this.$root.$emit('enableSearch', false)
    this.$emit('setNavActive', 'Initiatives')
    this.initiativeId = (this.$route) ? this.$route.params.id : ''
    this.setInitiativeDetails()
    this.tabDetailClicked = this.section.overview
  },
  beforeDestroy () {
    this.$emit('setNavActive', null)
  },
  watch: {
    initiatives: {
      handler () {
        this.setInitiativeDetails()
      }
    }
  },
  methods: {
    ...mapActions({
      getTaskLists: 'teams/getTaskLists',
      saveInitiative: 'teams/saveInitiative'
    }),
    getTeamName (teamId) {
      if (teamId !== this.profile.team) {
        if (this.teams) {
          const ft = this.teams.filter((team) => team.id === teamId)[0] || {}
          return `${ft.name || ''}/`
        }
      }
      return ''
    },
    userInInitiative () {
      if (this.initiativeDetails) {
        if (this.initiativeDetails.members) {
          return this.initiativeDetails.members.includes(this.profile.id)
        }
      }
      return false
    },
    routeToInitiative (value, initiative) {
      if (initiative) {
        this.$router.push(`/initiativedetails/${initiative.id}`).catch(err => { console.error(err) })
        this.$refs.initSearch.clearSearch()
      }
    },
    async setInitiativeDetails () {
      this.initiativeDetails = this.initiatives.filter(initiative => initiative.id === this.initiativeId)[0]
      if (this.initiativeDetails) {
        await this.getTaskLists({ teamId: this.profile.team, initiativeId: this.initiativeDetails.id })
        this.initiativeVisible = this.initiativeDetails.visibility === 'Workspace'
      }
    },
    tabDetailSelection (type) {
      this.tabDetailClicked = type
    },
    toggleVisibilitySwitch () {
      // console.log(this.initiativeVisible)
      let visibility = 'User'
      if (this.initiativeVisible) {
        visibility = 'Workspace'
      }
      this.saveInitiative({ initiativeId: this.initiativeDetails.id, initiative: { visibility } })
    }
  }
}
</script>
<style lang="scss">
$color-ontrack: #01CB52; /**ontrack */
$color-incomplete: #FFB11B; /**atrisk */
$color-overdue: #FF0F00; /**offtrack */
$color-onhold: #A0A0A0;
$color-completed: #404040;

.initiative-details-wrapper {
  height: 100%;
  margin-bottom: 30px;
  .one-col-layout {
    .mdc-card {
      border-radius: 0;
    }
    .section-title {
      padding: 30px 20px 0 20px !important;
    }
    .initiative-details-content-wrapper {
      width: 75%;
      margin: auto;
      position: relative;
      .breadcrumb {
        display: inline-flex;
        align-items: center;
        .mdc-text {
          color: #404040;
          font-weight: 600;
          font-size: 16px;
          &.page1 {
            color: #00498D;
          }
        }
        .page-link {
          text-decoration: none;
        }
      }
      .details-header-search-wrapper {
        display: flex;
        align-items: center;
        height: 37px;
        .app-section-search {
          flex: unset;
          margin-right: unset;
          .autocomplete-input {
            padding-right: unset;
          }
        }
      }
    }
  }
  .initiative-details-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 420px;
    max-width: 800px;
    margin: 50px auto;
    &.tasks {
      position: relative; /* for task modal */
    }
    .header-tabs {
      .header-status-switch {
        display: inline-flex;
        align-items: baseline;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px;
        flex-wrap: wrap;
        .initiative-name {
          color: #404040;
          text-transform: uppercase;
          font-weight: 700;
        }
        .status-switch-wrapper {
          min-width: 250px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .status {
            margin-right: 20px;
            &.ontrack {
              color: $color-ontrack;
            }
            &.completed {
              color: $color-completed;
            }
            &.atrisk {
              color: $color-incomplete;
            }
            &.onhold {
              color: $color-onhold;
            }
            &.offtrack {
              color: $color-overdue;
            }
          }
          .switch-text {
            color: #4F4F4F;
          }
          .switch {
            margin-left: 10px;
            display: inline-block;
            height: 10px;
            position: relative;
            width: 40px;
            vertical-align: middle;
            input {
              display:none;
              &:checked {
                + .slider {
                  background-color: #4F4F4F;
                  &:before {
                    transform: translateX(26px);
                    background-image: url('/static/img/icons/flexibly/opened-eye.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                  }
                }
              }
            }
            .slider {
              background-color: #ccc;
              bottom: 0;
              cursor: pointer;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              transition: .2s;
              &:before {
                background-color: #fff;
                bottom: -3px;
                content: "";
                height: 18px;
                position: absolute;
                transition: .2s;
                width: 18px;
              }
              &.round {
                border-radius: 18px;
                &:before {
                  border-radius: 50%;
                  background-image: url('/static/img/icons/flexibly/closed-eye.svg'), url('/static/img/icons/flexibly/closed-eye-part2.svg');
                  background-repeat: no-repeat;
                  background-position: center, 6.5px 6px;
                  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
                }
              }
            }
          }
        }
      }
      .initiative-details-tab {
        position: relative;
        height: 25px;
        border-bottom: 1px solid #cc0000;
        display: flex;
        align-items: center;
        .mdc-tab-bar {
          margin: 0;
          position: absolute;
          .mdc-tab {
            padding: 0 12px;
            height: 27px;
            font-size: 12px;
            max-width: 40px;
            .mdc-tab__text-label {
              color: #404040;
              font-size: 14px;
              text-transform: capitalize;
            }
            .mdc-tab-indicator {
              &--active {
                .mdc-tab-indicator__content {
                  opacity: 0;
                }
              }
            }
            &:first-child {
              padding-left: 0;
            }
            &.clicked {
              .mdc-tab__text-label {
                color: #404040;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    .detail-type {
      min-height: 500px;
    }
  }
}
</style>
