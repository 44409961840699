<template>
  <div ref="settingsMenu" class="dialog-menu" v-if="showSettingsMenu">
    <div
        class="editor__menu menubar"
        :class="{inactive: !editor}"
    >

    </div>
  </div>
</template>

<script>

export default {
  name: 'TiptapSettingsDialog',
  props: {
    settingsIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editor: {
    },
    showSettingsMenu: {
      type: Boolean,
      default: false
    },
    reducedOptions: {
      type: Boolean,
      default: false
    },
    noteBlocksEnabled: {
      type: Boolean,
      default: false
    },
    annotationsEnabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      platform: null,
      keys: {
        mod: null,
        otherMods: null
      },
      showShortcutKeys: false
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
    window.removeEventListener('resize', self.positionSettingsMenu)
  },
  created () {
    const userAgent = window.navigator.userAgent
    const platform = window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
    } else if (/Android/.test(userAgent)) {
      os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux'
    }
    this.platform = os
    this.frameShortcutKeys()
  },
  methods: {
    frameShortcutKeys () {
      switch (this.platform) {
        case 'Windows':
        case 'Linux':
          this.showShortcutKeys = true
          this.keys.mod = 'Ctrl'
          this.keys.otherMods = 'Alt'
          this.keys.shift = '&#8679;'
          return
        case 'Mac':
          this.showShortcutKeys = true
          this.keys.mod = '&#8984;'
          this.keys.otherMods = '&#8997;'
          this.keys.shift = '&#8679;'
          return
        default:
          this.showShortcutKeys = false
          this.keys.mod = ''
          this.keys.otherMods = ''
          this.keys.shift = ''
      }
    },
    can (button) {
      if (this.editor) {
        if (this.annotationsEnabled) {
          if (button === 'annotation') {
            return this.editor.can().addAnnotation()
          }
        }
      }
      return false
    },
    isActive (button) {
      if (this.editor) {
        if (!button) {
          return this.editor.isActive
        }
        if (button === 'history') {
          return this.editor.options.history
        }
        return this.editor.isActive(button)
      }
      return false
    },
    toggleHistory () {
      if (this.editor) {
        // console.log('toggleHistory', this.editor.options.history)
        this.editor.options.history = !this.editor.options.history
      }
    },
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target))) {
        // console.log('TipTapSettingsMenu.clickoutside')
        this.$emit('closeSettingsMenu', false)
      }
    },
    positionSettingsMenu () {
      const menuElement = this.$refs.settingsMenu
      if (menuElement) {
        const rect = menuElement.getBoundingClientRect()
        const isInViewport = rect.top >= 0 &&
                            rect.left >= 0 &&
                            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        if (!isInViewport) {
          if (rect.left <= 0) {
            menuElement.style.left = -1 * this.settingsIconPosition.left + 'px'
          }
        } else {
          menuElement.style.left = -155 + 'px'
        }
      }
    }
  },
  watch: {
    showSettingsMenu: {
      handler () {
        if (this.showSettingsMenu) {
          const self = this
          this.$nextTick(() => {
            this.positionSettingsMenu()
          })
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
            window.addEventListener('resize', self.positionSettingsMenu)
          }, 300)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
            window.removeEventListener('resize', self.positionSettingsMenu)
          }, 300)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
$color-black: #000000;
$color-white: #ffffff;
.dialog-menu {
  position: absolute;
  display: flex;
  z-index: 13;
  left: -155px;
  background: #FBFBFB;
  box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  top: -45px;
  height: 70px;
  overflow: auto;
  align-items: center;
  .menubar {
    display: flex;
    flex-direction: column;
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
    position:relative;
    background: #ffffff;
    margin:1px;
    border-bottom: none;
    min-width: 200px;
    padding: 1rem;
    margin-top: auto;

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &.is-focused {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.2s, opacity 0.2s;
    }

    &.inactive {
      opacity: 0.5;
    }
    &__button {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      border: 0;
      color: $color-black !important;
      padding: 2px !important;
      margin: 5px;
      border-radius: 3px;
      cursor: pointer;

      /* &:hover { */
      /* background-color: rgba($color-black, 0.05);
      } */
      .mdc-icon.mdc-icon--material {
        font-size: 20px;
      }

      &.is-active {
        background-color: rgba($color-black, 0.1);
      }
      .format-wrapper {
        display: flex;
        align-items: center;
        .format-text {
          margin-left: 10px;
          margin-right: 10px;
          &.bold {
            color: #333333;
            &:before {
              content: '*';
              color: #CC0000;
            }
            &:after {
              content: '*';
              color: #CC0000;
            }
          }
          &.italic {
            &:before {
              content: '/';
              color: #CC0000;
            }
            &:after {
              content: '/';
              color: #CC0000;
            }
          }
          &.underline {
            &:before {
              content: '_';
              color: #CC0000;
            }
            &:after {
              content: '_';
              color: #CC0000;
            }
          }
          &.strike {
            &:before {
              content: '-';
              color: #CC0000;
            }
            &:after {
              content: '-';
              color: #CC0000;
            }
          }
          &.ordered-list {
            &:before {
              content: '1.';
              color: #CC0000;
            }
          }
          &.bullet-list {
            &:before {
              content: '*';
              color: #CC0000;
            }
          }
          &.blockquote {
            &:before {
              content: '>';
              color: #CC0000;
            }
          }
          &.task-list {
            &:before {
              content: '-';
              color: #CC0000;
            }
          }
          &.headingOne {
            &:before {
              content: '# ';
              color: #cc0000;
            }
          }
          &.headingTwo {
            &:before {
              content: '## ';
              color: #cc0000;
            }
          }
          &.headingThree {
            &:before {
              content: '### ';
              color: #cc0000;
            }
          }
          &.horizontal-rule {
            &:before {
              content: '--- ';
              color: #cc0000;
            }
          }
        }
      }
      .shortcut-keys {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        line-height: 20px;
        > *:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #828282;
  }
}
</style>
