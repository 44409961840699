<template>
  <section role="section" aria-label="location-indicator" class="location-indicator-wrapper" :class="[clickable ? 'clickable' : '', showBox ? 'showbox' : '', schedules && myLocation ? 'loaded' : '', locationIndicatorDialogDate ? 'expanded' : '']">
    <base-svg-icon @click.native="emitStarClick" v-if="showFav" :name="locationId === profile.favOfficeLocationId ? 'favourite-star-on' : 'favourite-star-off'" class="fav-location icon-type clickable" />
    <template v-if="activityText"> <!-- when capacity is available and based on that, occupied vs capacity spaces are shown-->
      <div class="activity-spaces-wrapper">
          <activity-box class="loc-act-box" :activityText="activityText" :fontSize="remainingCapacity === 0 ? '16px' : '26px'" :fontColor="remainingCapacity === 0 ? '#FC5555' : '#2B8AFB'" :fontWeight="'700'" :typeFontSize="'11px'" :typeFontColor="'#2B8AFB'" :typeFontWeight="'500'" :activityType="remainingCapacity !== 0 ? 'Spaces' : ''" :bgColor="'#F9F9F9'" :height="'34px'" :width="'36px'" />
          <mdc-text aria-label="capacity-text" role="definition" v-if="capacitytext" class="loc-state-ring" :style="{ color: capacityColor }" tag="span" typo="caption">{{capacitytext}}</mdc-text>
        <!-- <svg-percent-ring class="loc-state-ring" :tAlign="'center'" :tFill="capacityColor" :tFontSize="9" :tFontWeight="700" :tWidth="40" :tHeight="20" :tPosX="'7%'" :tPosY="'30%'" :strokeColor="capacityColor" :height="48" :width="48" :viewBox="'0 0 48 48'" :cRadius="22" :centerX="24" :centerY="24" :percentage="occupiedPer" :showText="capacitytext" :id="'location'" :textClass="'location-status-capLevel'" /> -->
      </div>
      <div class="location-name">
        <base-svg-icon class="loc-pin" name="location-pin-black" />
        <location-name v-if="myLocation" :locationDetails="myLocation" />
      </div>
    </template>
    <template v-else-if="myLocation && !myLocation.capacity"> <!-- when no capacity-->
      <div class="activity-spaces-wrapper office-count" v-if="occupied >= 0">
        <activity-box class="loc-act-box" :activityText="occupied" :fontSize="'26px'" :fontColor="'#2B8AFB'" :fontWeight="'700'" :typeFontSize="'11px'" :typeFontColor="'#2B8AFB'" :typeFontWeight="'500'" :activityType="occupied === 1 ? 'person in the office' : 'people in the office'" :bgColor="'#F9F9F9'" :height="'34px'" :width="'80px'" />
      </div>
      <div class="location-name">
        <base-svg-icon class="loc-pin" name="location-pin-black" />
        <location-name :locationDetails="myLocation" />
      </div>
    </template>
    <template v-else> <!-- when no office locations set, display ? and click on set open preferences -->
      <div class="activity-spaces-wrapper office-count">
        <activity-box class="loc-act-box" :activityText="'?'" :fontSize="'26px'" :fontColor="'#2B8AFB'" :fontWeight="'700'" :typeFontSize="'11px'" :typeFontColor="'#2B8AFB'" :typeFontWeight="'500'" :activityType="occupied === 1 ? 'person in the office' : 'people in the office'" :bgColor="'#F9F9F9'" :height="'34px'" :width="'80px'" />
      </div>
      <div class="location-name" @click.stop.prevent="openLocationPreferencesDay()">
        <base-svg-icon class="loc-pin" name="location-pin-black" />
        <mdc-text tag="span" typo="caption" class="set-location-link">Set office location</mdc-text>
      </div>
    </template>
    <base-svg-icon :clickable="locationId !== selectedLocationId" v-if="showTick" @click.native="emitTickClick" :name="selectedLocationId === locationId ? 'round_checked' : 'round_unchecked'" class="select-location icon-type" />
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ActivityBox from './ActivityBox.vue'
import moment from 'moment'
import BaseSvgIcon from './BaseSvgIcon.vue'
import LocationName from './LocationName.vue'
export default {
  name: 'LocationIndicator',
  components: {
    ActivityBox,
    BaseSvgIcon,
    LocationName
  },
  props: {
    date: {},
    clickable: {
      type: Boolean,
      default: false
    },
    showBox: {
      type: Boolean,
      default: false
    },
    locationId: {
      type: String,
      default: ''
    },
    showFav: {
      type: Boolean,
      default: false
    },
    showTick: {
      type: Boolean,
      default: false
    },
    selectedLocationId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState([
      'profile',
      'locationIndicatorDialogDate'
    ]),
    ...mapState({
      schedules: state => state.teams.schedules,
      defaultSchedules: state => state.teams.defaultSchedules,
      locations: state => state.teams.locations
    }),
    dailyAverage () {
      if (this.myLocation && this.myLocation.dailyAverage && this.dayAbv) {
        return this.myLocation.dailyAverage[this.dayAbv]
      }
      return -1
    },
    mySchedule () {
      if (this.schedules) {
        const mySchedules = this.schedules.filter((schedule) => {
          return schedule.fromId === this.profile.id
        })
        return mySchedules[0]
      }
      return false
    },
    myDefaultSchedule () {
      if (this.defaultSchedules) {
        const mySchedules = this.defaultSchedules.filter((schedule) => {
          return schedule.fromId === this.profile.id
        })
        return mySchedules[0]
      }
      return false
    },
    myLocation () {
      let selectedLocation = false
      if (this.locationId && this.locations) {
        selectedLocation = this.locations.filter(loc => loc.id === this.locationId)[0]
      }
      if (this.mySchedule && this.dayAbv) {
        const location = this.mySchedule.schedules[this.dayAbv]
        // console.log('locationstatus.myLocation', this.dayAbv, location)
        const locationId = location ? location.locationId : false
        if (this.locations && locationId) {
          if (!selectedLocation) {
            selectedLocation = this.locations.filter(loc => loc.id === locationId)[0]
          }
        }
      }
      if (this.myDefaultSchedule && this.dayAbv) {
        const location = this.myDefaultSchedule.schedules[this.dayAbv]
        // console.log('locationstatus.myLocation default', this.dayAbv, location)
        const locationId = location ? location.locationId : false
        if (this.locations && locationId) {
          if (!selectedLocation) {
            selectedLocation = this.locations.filter(loc => loc.id === locationId)[0]
          }
        }
      }
      if (this.profile.favOfficeLocationId) {
        if (this.locations) {
          // console.log('locationstatus.myLocation fav', this.profile.favOfficeLocationId)
          if (!selectedLocation) {
            selectedLocation = this.locations.filter(loc => loc.id === this.profile.favOfficeLocationId)[0]
          }
        }
      }
      return selectedLocation
    },
    dayAbv () {
      if (this.date) {
        return moment(this.date).format('ddd').toUpperCase()
      }
      return ''
    },
    occupiedPer () {
      if (this.myLocation && this.myLocation.capacity) {
        if (this.occupied) {
          const perc = (this.occupied / this.myLocation.capacity) * 100
          if (perc > 100) {
            return 100
          }
          return perc
        }
      }
      return 0
    },
    remainingCapacity () {
      if (this.myLocation && this.myLocation.capacity) {
        let disabledDesks = 0
        if (this.myLocation.assignedDesks && this.myLocation.desks) {
          disabledDesks = this.myLocation.desks.filter(desk => desk.enabled === false).length
        }
        if (this.occupied >= 0) {
          if (this.myLocation.capacity > this.occupied) {
            return this.myLocation.capacity - this.occupied - disabledDesks
          }
          return 0
        } else {
          return this.myLocation.capacity - disabledDesks
        }
      }
      return ''
    },
    activityText () {
      if (this.myLocation && this.myLocation.capacity) {
        if (this.remainingCapacity >= 0) {
          if (this.remainingCapacity === 0) {
            if (this.occupied > this.myLocation.capacity && this.occupied >= 0) {
              return 'FULL' + `<span class="overbooked">${this.occupied - this.myLocation.capacity} over</span>`
            }
            return 'FULL'
          }
          return this.remainingCapacity + `<span class="capacity">/${this.myLocation.capacity}</span>`
        }
      }
      return ''
    },
    occupied () {
      if (this.schedules && this.myLocation && this.dayAbv) {
        const atLocationOnDateSchedules = this.schedules.filter((schedule) => {
          if (schedule.schedules) {
            if (schedule.schedules[this.dayAbv]) {
              const inOffice = (schedule.schedules[this.dayAbv].type === 'full' && schedule.schedules[this.dayAbv].response === 'office') ||
                (schedule.schedules[this.dayAbv].split &&
                (schedule.schedules[this.dayAbv].amResponse === 'office' ||
                schedule.schedules[this.dayAbv].pmResponse === 'office'))
              if (inOffice && schedule.schedules[this.dayAbv].locationId === this.myLocation.id) {
                return true
              }
            }
          }
          return false
        })
        return atLocationOnDateSchedules.length
      }
      return 0
    },
    capacityPer () {
      if (this.myLocation && this.myLocation.capacity && this.dailyAverage > 0) {
        const capPer = (this.dailyAverage / this.myLocation.capacity) * 100
        if (capPer > 100) {
          return 100
        }
        return capPer
      }
      return 0
    },
    capacityPerFormat () {
      return this.capacityPer.toFixed(2)
    },
    capacityLevel () {
      if (this.myLocation && this.dailyAverage) {
        const capPer = this.capacityPer
        if (capPer < 0) {
          return 'low'
        } else if (capPer === 0) {
          return 'low'
        } else if (capPer <= 25) {
          return 'low'
        } else if (capPer >= 75) {
          return 'high'
        } else {
          return 'mid'
        }
      }
      return 'low'
    },
    capacitytext () {
      if (this.capacityLevel === 'high') {
        return 'Usually busy'
      } else if (this.capacityLevel === 'mid') {
        return 'Usually active'
      } if (this.capacityLevel === 'low') {
        return 'Usually quiet'
      } else {
        return 'No Activity'
      }
    },
    capacityColor () {
      switch (this.capacityLevel) {
        case 'high':
          return '#FC5555'
        case 'mid':
          return '#FF9901'
        case 'low':
        default:
          return '#0F395F'
      }
    },
    inOffice () {
      if (this.schedules) {
        const inOfficeMembers = this.schedules.filter((schedule) => {
          if (schedule.schedules) {
            if (schedule.schedules[this.dayAbv]) {
              const inOffice = (schedule.schedules[this.dayAbv].response === 'office') ||
                (schedule.schedules[this.dayAbv].split &&
                (schedule.schedules[this.dayAbv].amResponse === 'office' ||
                schedule.schedules[this.dayAbv].pmResponse === 'office'))
              if (inOffice) {
                return true
              }
            }
          }
          return false
        })
        return inOfficeMembers.length
      }
      return false
    }
  },
  data () {
    return {
    }
  },
  beforeDestroy () {},
  created () {},
  mounted () {},
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    }),
    openLocationPreferencesDay () {
      this.$emit('setLocationClick')
    },
    emitStarClick () {
      this.$emit('favIconClick', this.locationId)
    },
    emitTickClick () {
      this.$emit('selectIconClick', this.locationId)
    }
  },
  watch: {}
}
</script>

<style lang="scss">
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.location-indicator-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  &.showbox {
    background: #FFFFFF;
    border: 1px solid #E1E5E9;
    border-radius: 4px;
  }
  .icon-type {
    height: 14px;
    width: 14px;
    img {
      height: 100%;
      width: 100%;
    }
    &.select-location {
      margin: auto 5px 5px auto;
    }
    &.fav-location {
      margin: 1px 0 2px 2px;
    }
  }
  .activity-spaces-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    &.office-count {
      .loc-act-box {
        .activity-type {
          white-space: nowrap;
        }
      }
    }
    .loc-act-box {
      .capacity {
        font-size: 8px;
        font-weight: 400;
        margin-left: 2px;
      }
      .overbooked {
        font-size: 10px;
        font-weight: 500;
        white-space: nowrap;
        display: flex;
        text-align: center;
        justify-content: center;
      }
    }
    .loc-state-ring {
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      text-align: center;
      max-width: 50px;
    }
  }
  .location-name {
    display: flex;
    align-items: center;
    margin: auto 0 0 0;
    .loc-pin {
      margin-right: 4px;
    }
    .loc-name-det {
      color: #404040;
      font-weight: 300;
      display: flex;
      flex-direction: row;
      line-height: unset;
      flex-wrap: wrap;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      .building-name {
        margin-right: 3px;
      }
      .building-name, i {
        white-space: nowrap;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .set-location-link {
      color: #4175B1;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
</style>
