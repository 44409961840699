<template>
    <div v-if="workspace" class="whos-where-when-wrapper schedule-search" :class="{ 'pin-team' : pinnedQuery }">
      <setup-schedules v-if="displaySchedulesSetup" @closeSetupSchedules="closeSetup()" displayFor="page" :from="'scheduleSearch'" :workspaceAdmin="$workspaceAdmin()" />
      <template v-else>
        <div v-if="(searchClient || !useSearchClient) && teamProfiles" class="container">
          <template v-if="!pinnedQuery">
            <header class="whos-where-when-title">
              <base-svg-icon name="whoswherewhen-red-on" />
              <mdc-text tag="span">WHO'S WHERE WHEN</mdc-text>
              <base-svg-icon v-if="($teamLeader() || $workspaceAdmin) && !onboarding" name="task-ellipse" @click.native="emitSettingsCogClick()" class="whos-where-when-menu" clickable/>
              <span class="icon-spacer" v-else></span>
            </header>
            <div class="recommendations-content">
              <div class="set-pref-content notif-content"  v-if="$teamLeader() && !onboarding && !profile.schedulesSetupSeen && setupDetailsFromTempo === 0" @click.stop="setSetupSchedules('notif')">
                <mdc-text class="seen-status status" tag="span" typo="caption">NEW</mdc-text>
                <mdc-text class="ask-text text" tag="span" typo="caption">Start asking the team to submit where they plan to work</mdc-text>
                <base-svg-icon label="dismiss notification" name="cross-thin-red" aria-label="Dismiss Notification" class="bestday-recomm dismiss-notification" @click.stop.native="markFirstTimeSet()" clickable/>
              </div>
              <member-request-note v-if="!onboarding" :displayFor="'schedule-search'" />
              <team-invite-note v-if="!onboarding" :displayFor="'schedule-search'"/>
            </div>
            <div id="autocomplete-people" class="ais-Autocomplete"></div>
          </template>
          <ais-instant-search v-if="searchClient || useSearchClient" ref="instantSearch" :search-client="searchClient" :search-function="searchFunction" :index-name="workspace.id+'-profiles'">
            <ais-configure
              :query="query"
            />
          </ais-instant-search>
          <template v-if="schedules">
          <div class="location-schedule-nav-wrapper" v-if="!pinnedQuery" :class="windowWidth <= 1251 ? 'align-vertical' : ''">
            <location-indicator :clickable="!onboarding" v-if="hasLocations" @setLocationClick="openLocPrefDay" @click.native="openLocationIndicatorDialoge(selectedDay)" :date="selectedDay" :class="locationIndicatorDialogDate ? 'loc-ind-opened': 'loc-ind-closed'"/>
            <mdc-text v-else tag="span" typo="caption" style="visibility: hidden">No location set</mdc-text>
            <div class="schedule-nav-wrapper">
              <section class="days-section">
                <div role="nav" aria-label="week navigation" class="week-details">
                  <base-svg-icon name="thin-left-arrow-grey" label="back week" class="week-nav-icon" @click.native.stop.prevent="goBackward()" clickable />
                  <span class="week-range" v-if="weekDetail">
                    <mdc-text typo="caption" tag="span" class="week-number week-dates-range" :class="[isSelectedDayPastWeek() ? 'past' : '', isSelectedDayFutureWeek() ? 'future': '']">{{ windowWidth > 1295 ? 'WEEK' : 'W' }} {{weekDetail.weekNumber}}</mdc-text>
                    <mdc-text typo="caption" tag="span" class="week-dates week-dates-range">{{ weekDetail.startOfWeek.format('D') }} - {{ weekDetail.endOfWeek.format('D MMM\' YY') }}</mdc-text>
                  </span>
                  <base-svg-icon name="thin-right-arrow-grey" label="forward week"  class="week-nav-icon" @click.native.stop.prevent="goForward()" clickable />
                </div>
                <div class="week-nav-buttons">
                  <mdc-button outlined aria-label="this week" @click="weekClick('this')">
                    <mdc-text typo="caption" tag="span">THIS {{ windowWidth > 1000 ? 'WEEK' : 'WK' }}</mdc-text>
                  </mdc-button>
                  <mdc-button outlined aria-label="next week" @click="weekClick('next')">
                    <mdc-text typo="caption" tag="span">NEXT {{ windowWidth > 1000 ? 'WEEK' : 'WK' }}</mdc-text>
                  </mdc-button>
                </div>
              </section>
              <div role="nav" aria-label="day navigation" class="days-label-row">
                <base-svg-icon name="thin-left-arrow-grey" label="back day" class="day-nav-icon previous" @click.native="goToPreviousDay()" clickable />
                <div style="display:grid;grid-gap:2px;position:relative;">
                  <div class="day-grid" v-if="tableWeekDetails">
                    <div role="button" :aria-label="dayLabel(date)" :class="[ isDateToday(date) ? 'today' : null, isSelectedDay(date) ? 'selected' : null, isSelectedDayPast() ? 'past' : '', isSelectedDayFuture() ? 'future': '']" class="days-text-wrapper clickable" v-for="(date, index) in tableWeekDetails" :key="date + index" @click="onDaySelection(date)">
                      <mdc-text typo="caption" tag="span">{{ formatDate(date) }}</mdc-text>
                    </div>
                  </div>
                </div>
                <base-svg-icon name="thin-right-arrow-grey" label="forward day"  class="day-nav-icon next" @click.native="goToNextDay()" clickable />
              </div>
            </div>
          </div>
          <section class="whos-where-when-content" :class="windowWidth <= 1251 ? 'align-vertical' : ''">
            <div class="table-contents" :class="[profile.teamExpanded && query.length === 0 ? 'expand-mode' : '', query ? 'query-contents expand-mode': '']">
              <div v-if="query" :class="!pinnedQuery ? 'team-details' : ''">
                <header v-if="!pinnedQuery">
                  <div class="team-info">
                    <base-svg-icon v-if="queryType === 'name'" name="person-404040" class="people-icon" />
                    <base-svg-icon v-else-if="queryType === 'initiative'" name="rocket-black" class="people-icon" />
                    <base-svg-icon v-else-if="queryType === 'team'" name="people-black" class="people-icon" />
                    <base-svg-icon v-else name="search-lens" class="people-icon" />
                    <mdc-text class="team-name" tag="span" typo="body2">{{query}}</mdc-text>
                  </div>
                  <div class="empty-wrapper-grid" :class="allSearchResultsNotSet ? 'all-not-set' : ''">
                    <div class="empty-days-grid">
                      <template v-if="sortedSearchResults.length > 0">
                        <div v-for="date in tableWeekDetails" :key="date + 'empty-grid'" :class="isDateToday(date) ? 'today' : ''" :style="{ 'height': isDateToday(date) ? todayHeight : 0 }"></div>
                      </template>
                    </div>
                  </div>
                </header>
                <div class="query-row" v-for="item in sortedSearchResults" :key="item.objectID">
                  <div class="profile-response-columns" v-if="checkProfileNotSet(item.objectID) || item.objectID === profile.id" slot="item" :key="item.objectID + '-row'">
                    <div class="profile-content">
                      <profile-initials @emitEditProfile='editProfileClick' :class="{'you' : profile.id === item.objectID }" :key="item.objectID + 'profiles'" :profileId="item.objectID" :date="appDate" showNameAndInitial showYou :onlyMe="item.objectID === profile.id" :noProfileDetailClick="profile.id === item.objectID" :noEditProfile="onboarding"/>
                    </div>
                    <div class="responses-days-grid query-results" v-bind:class="{ 'animate': allSchedulesLoaded }">
                      <div class="response-row" v-if="computedMembersWeeklySchedule.allProfiles && computedMembersWeeklySchedule.allProfiles[item.objectID]">
                        <div class="schedule-type" @click.prevent.stop="showOtherInfoTooltip(response, index)" v-for="(response, index) of computedMembersWeeklySchedule.allProfiles[item.objectID]" :key="item.objectID + '-' + index" :class="[getBoxFillClass(response, true), locationPinClickedFor.id === response.id && locationPinClickedFor.index === response.dayIndex - 1 ? 'pin-clicked' : '', isResponseDatedToday(response.dated) ? 'today' : '', displayOtherInfoIcon(response) ? 'clickable' : '']">
                          <div class="other-info" v-if="displayOtherInfoIcon(response)">
                            <base-svg-icon class="grid-tooltip" name="grid-tooltip" label="location dialog" @click.native="locationPinClicked(response, index)" clickable/>
                          </div>
                          <div class="response-icons-wrapper">
                            <base-svg-icon class="day-schedule" v-if=" response.type === 'full' && response.response" :name="getResponseIcon(response.response)"/>
                            <span class="answer split-day" v-else-if="response.type === 'split' && response.amResponse && response.pmResponse">
                              <div class="split-day-row">
                                <div class="am-wrapper slot-type" :class="getBoxFillClass(response, false, response.amResponse)">
                                  <template v-if="response.amResponse">
                                    <base-svg-icon :name="response.amResponse" />
                                  </template>
                                </div>
                                <div class="pm-wrapper slot-type" :class="getBoxFillClass(response, false, response.pmResponse)">
                                  <template v-if="response.pmResponse">
                                    <base-svg-icon :name="response.pmResponse" />
                                  </template>
                                </div>
                              </div>
                            </span>
                            <base-svg-icon class="default-schedule" v-else-if="!response.type && response.defaultResponse && response.defaultResponseType === 'full'" :name="getResponseIcon(response.defaultResponse)"/>
                            <span class="answer split-day default" v-else-if="!response.type && response.defaultResponseType === 'split' && response.defaultResponseAm && response.defaultResponsePm">
                              <div class="split-day-row">
                                <div class="am-wrapper slot-type notset-fill">
                                  <template v-if="response.defaultResponseAm">
                                    <base-svg-icon :name="getResponseIcon(response.defaultResponseAm)" />
                                  </template>
                                </div>
                                <div class="pm-wrapper slot-type notset-fill">
                                  <template v-if="response.defaultResponsePm">
                                    <base-svg-icon :name="getResponseIcon(response.defaultResponsePm)" />
                                  </template>
                                </div>
                              </div>
                            </span>
                            <mdc-text class="not-set-text" tag="span" typo="caption" v-else>Not set</mdc-text>
                          </div>
                          <profile-schedule-tooltip :class="locationPinClickedFor.id" :profileId="response.id" :memberDaySchedule="response" v-if="locationPinClickedFor.id === response.id && locationPinClickedFor.index === response.dayIndex - 1" :tooltipDate="response.dated" showFooter />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <section v-if="computedMembersWeeklySchedule.profilesNotset && computedMembersWeeklySchedule.profilesNotset.length > 0" class="not-set-profiles query-row">
                  <div class="notset-initials">
                    <template v-for="(profileId, index) in sortedSearchResults.filter(profileId => profileId.id !== profile.id)">
                      <mdc-text v-if="(index === 0)" :key="'notset-text' + index" tag="span" typo="caption" class="notset-text">Not set:</mdc-text>
                      <template v-if="!checkProfileNotSet(profileId.objectID)">
                        <profile-initials :profileId="profileId.objectID" :date="appDate" :key="profileId.objectID + 'notset'"/>
                      </template>
                    </template>
                  </div>
                </section>
              </div>
              <div v-else class="default-row" :class="[profile.teamExpanded ? 'expand-mode' : '']">
                <pinned-team :minProfilesDisplay="8" @expandScheduleSearch="showWeeklyMode" :teamId="profile.team" :forDate="scheduleSearchWeekDetail.selectedDay" v-if="!profile.teamExpanded && schedules" defaultTeam />
                <div class="team-details" v-if="profile.teamExpanded" style="margin-bottom: 0">
                  <header>
                    <div class="team-info">
                      <base-svg-icon name="heart-black" />
                      <mdc-text role="button" aria-label="collapse team" class="team-name" v-if="teamDetails && teamDetails.name" @click.native.stop="showWeeklyMode('default')" tag="span" typo="body2">{{teamDetails.name}}</mdc-text>
                      <base-svg-icon v-if="$teamLeader() && !onboarding" name="add-member-black" class="add-member-icon" @click.native="addUserIconClick" clickable />
                    </div>
                    <mdc-text class="date-text" tag="span" typo="caption" v-if="!profile.teamExpanded">{{selectedDay.format('ddd D MMM')}}</mdc-text>
                    <div class="empty-wrapper-grid">
                      <div class="empty-days-grid">
                        <div v-for="date in tableWeekDetails" :key="date + 'empty-grid'" :class="isDateToday(date) ? 'today' : ''" :style="{ 'height': isDateToday(date) ? todayHeight : 0 }"></div>
                      </div>
                    </div>
                  </header>
                  <section class="weekly-schedules" style="display:flex;flex-direction: column">
                    <template v-for="item in teamProfilesTodaySchedule">
                      <div class="profile-response-columns" v-if="checkProfileNotSet(item.id) || item.id === profile.id" slot="item" :key="item.id + '-row'">
                        <div class="profile-content">
                          <profile-initials @emitEditProfile='editProfileClick' :class="{'you' :profile.id === item.id }" :key="item.id + 'profiles'" :profileId="item.id" :date="appDate" showNameAndInitial showYou :onlyMe="item.id === profile.id" :noProfileDetailClick="profile.id === item.id" :noEditProfile="onboarding"/>
                        </div>
                        <div class="responses-days-grid" v-bind:class="{ 'animate': allSchedulesLoaded }">
                          <div class="response-row" v-if="computedMembersWeeklySchedule.allProfiles && computedMembersWeeklySchedule.allProfiles[item.id]">
                            <div class="schedule-type" @click.prevent.stop="showOtherInfoTooltip(response, index)" v-for="(response, index) of computedMembersWeeklySchedule.allProfiles[item.id]" :key="item.id + '-' + index" :class="[getBoxFillClass(response, true), locationPinClickedFor.id === response.id && locationPinClickedFor.index === response.dayIndex - 1 ? 'pin-clicked' : '', isDateToday(response.dated) ? 'today' : null, displayOtherInfoIcon(response) ? 'clickable' : '']">
                              <div class="other-info" v-if="displayOtherInfoIcon(response)">
                                <base-svg-icon class="grid-tooltip" label="location dialog" name="grid-tooltip" @click.native="locationPinClicked(response, index)" clickable/>
                              </div>
                              <div class="response-icons-wrapper">
                                <base-svg-icon class="day-schedule" v-if=" response.type === 'full' && response.response" :name="getResponseIcon(response.response)"/>
                                <span class="answer split-day" v-else-if="response.type === 'split' && response.amResponse && response.pmResponse">
                                  <div class="split-day-row">
                                    <div class="am-wrapper slot-type" :class="getBoxFillClass(response, false, response.amResponse)">
                                      <template v-if="response.amResponse">
                                        <base-svg-icon :name="response.amResponse" />
                                      </template>
                                    </div>
                                    <div class="pm-wrapper slot-type" :class="getBoxFillClass(response, false, response.pmResponse)">
                                      <template v-if="response.pmResponse">
                                        <base-svg-icon :name="response.pmResponse" />
                                      </template>
                                    </div>
                                  </div>
                                </span>
                                <base-svg-icon class="default-schedule" v-else-if="response.defaultResponse && response.defaultResponseType === 'full'" :name="getResponseIcon(response.defaultResponse)"/>
                                <span class="answer split-day default" v-else-if="response.defaultResponseType === 'split' && response.defaultResponseAm && response.defaultResponsePm">
                                  <div class="split-day-row">
                                    <div class="am-wrapper slot-type notset-fill">
                                      <template v-if="response.defaultResponseAm">
                                        <base-svg-icon :name="getResponseIcon(response.defaultResponseAm)" />
                                      </template>
                                    </div>
                                    <div class="pm-wrapper slot-type notset-fill">
                                      <template v-if="response.defaultResponsePm">
                                        <base-svg-icon :name="getResponseIcon(response.defaultResponsePm)" />
                                      </template>
                                    </div>
                                  </div>
                                </span>
                                <mdc-text class="not-set-text" tag="span" typo="caption" v-else>Not set</mdc-text>
                              </div>
                              <profile-schedule-tooltip :class="locationPinClickedFor.id" :profileId="response.id" :memberDaySchedule="response" v-if="locationPinClickedFor.id === response.id && locationPinClickedFor.index === response.dayIndex - 1" :tooltipDate="response.dated" showFooter />
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <section v-if="computedMembersWeeklySchedule.profilesNotset && computedMembersWeeklySchedule.profilesNotset.length > 0" class="not-set-profiles" style="position: unset;">
                      <div class="notset-initials">
                        <template v-for="(profileId, index) in teamProfilesTodaySchedule">
                          <template v-if="profileId.id !== profile.id">
                            <mdc-text v-if="teamProfilesTodaySchedule.length === 1 && index === 0 || teamProfilesTodaySchedule.length > 1 && index === 1" :key="'notset-text' + index" tag="span" typo="caption" class="notset-text">Not set:</mdc-text>
                            <template v-if="!checkProfileNotSet(profileId.id)" >
                              <profile-initials :profileId="profileId.id" :date="appDate" :key="profileId.id + 'notset'"/>
                            </template>
                          </template>
                        </template>
                      </div>
                    </section>
                  </section>
                </div>
                <div class="first-time-seen" v-if="$teamLeader() && setupDetailsFromTempo === 0 && !pinnedQuery && profile.teamExpanded">
                  <mdc-text tag="span" typo="body2">TIP: Start automatically asking the team to submit where they plan to work <span class="link" @click="setSetupSchedules">here</span>.</mdc-text>
                </div>
              </div>
            </div>
          </section>
        </template>
        <div v-else class="loading-schedules"></div>
        </div>
        <div v-else class="loading-schedules">
          <mdc-text tag="span" typo="body2">Please wait, the schedule is being built, this is a one time process </mdc-text>
        </div>
      </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import fb from '../../store/firebase'
import { appSettings } from '../../settings'
import { autocomplete } from '@algolia/autocomplete-js'
import '@algolia/autocomplete-theme-classic'
import algoliasearch from 'algoliasearch/lite'
import 'instantsearch.css/themes/satellite-min.css'
import { isToday, sameDay, inPast, inFuture } from '../../utils/time'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { getResponseIconName } from '../../utils/profile'
import ProfileScheduleTooltip from './ProfileScheduleTooltip.vue'
import SetupSchedules from './SetupSchedules.vue'
import MemberRequestNote from './MemberRequestNote.vue'
import TeamInviteNote from './TeamInviteNote.vue'
import LocationIndicator from './LocationIndicator.vue'
import BaseSvgIcon from './BaseSvgIcon.vue'
import ProfileInitials from './ProfileInitials.vue'
import PinnedTeam from './PinnedTeam.vue'
const _ = { groupBy, orderBy }

export default {
  name: 'ScheduleSearch',
  components: {
    SetupSchedules,
    MemberRequestNote,
    TeamInviteNote,
    LocationIndicator,
    BaseSvgIcon,
    ProfileInitials,
    PinnedTeam,
    ProfileScheduleTooltip
  },
  props: {
    pinnedQuery: {
      type: String,
      default: ''
    },
    pinnedInitiativeId: {
    },
    pinnedTeamId: {
    },
    displaySchedulesSetup: {
      type: Boolean,
      default: false
    },
    expandedView: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isTablet: {
      type: Boolean,
      default: false
    },
    setWeekDetail: {
      type: Boolean,
      default: false
    },
    useSearchClient: {
      type: Boolean,
      default: true
    },
    showDate: {},
    onboarding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const self = this
    return {
      todayHeight: null,
      allSearchResultsNotSet: false,
      scheduleTypes: [
        'office',
        'home',
        'thirdspace',
        'nonworking',
        'notset'
      ],
      selectedDay: '',
      today: moment(),
      defaultWeekDetail: {
        startOfWeek: moment().startOf('isoWeek'),
        endOfWeek: moment().endOf('isoWeek'),
        weekNumber: moment().week()
      },
      weekDetail: null,
      scheduleMap: {},
      allProfilesSchedulesWeekly: [],
      tableWeekDetails: [],
      windowWidth: window.innerWidth,
      allSchedulesLoaded: false,
      query: '',
      queryType: '',
      lastQuery: '',
      searchClient: null,
      searchResults: [],
      initiativeId: false,
      teamId: false,
      lastQueryId: false,
      locationPinClickedFor: {},
      searchFunction (helper) {
        // console.log('searchFunction', self.teamId, self.initiativeId, self.pinnedQuery, helper.state.query, self.lastQueryId)
        if (self.teamId && self.lastQuery !== self.pinnedQuery && self.lastQueryId !== self.teamId) {
          self.lastQueryId = self.teamId
          self.initiativeId = false
          self.lastQuery = self.pinnedQuery || helper.state.query
          const searchResults = []
          if (self.teams) {
            const team = self.teams.filter(t => t.id === self.teamId)[0]
            if (team) {
              for (const memId of team.members) {
                const hit = self.getProfile(memId)
                if (hit) {
                  const result = {
                    displayName: hit.displayName,
                    initiatives: [],
                    objectID: hit.id,
                    photoURL: hit.photoURL,
                    teams: [self.teamId]
                  }
                  searchResults.push(result)
                }
              }
            }
          }
          self.searchResults = searchResults
        } else if (self.initiativeId && self.lastQuery !== self.pinnedQuery && self.lastQueryId !== self.initiativeId) {
          self.lastQueryId = self.initiativeId
          self.teamId = false
          self.lastQuery = self.pinnedQuery || helper.state.query
          const searchResults = []
          if (self.initiatives) {
            const initiative = self.initiatives.filter(t => t.id === self.initiativeId)[0]
            if (initiative) {
              for (const memId of initiative.members) {
                const hit = self.getProfile(memId)
                if (hit) {
                  const result = {
                    displayName: hit.displayName,
                    initiatives: [self.initiativeId],
                    objectID: hit.id,
                    photoURL: hit.photoURL,
                    teams: []
                  }
                  searchResults.push(result)
                }
              }
            }
          }
          self.searchResults = searchResults
        } else if (helper.state.query && helper.state.query !== self.lastQuery) {
          helper.search()
          setTimeout(() => {
            const tohelper = self.$refs.instantSearch.instantSearchInstance.helper
            if (tohelper.lastResults) {
              const hits = [...tohelper.lastResults.hits]
              // console.log('searchFunction.helper', tohelper, hits)
              const searchResults = []
              for (const hit of hits) {
                // console.log('searchFunction.hit', hit)
                const result = {
                  displayName: hit.displayName,
                  initiatives: hit.initiatives,
                  objectID: hit.objectID,
                  photoURL: hit.photoURL,
                  teams: hit.teams
                }
                searchResults.push(result)
              }
              self.lastQuery = helper.state.query
              self.searchResults = searchResults
            }
          }, 200)
        }
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.callResize)
    window.removeEventListener('click', this.handleClickOutside)
    window.removeEventListener('keydown', this.handleKeyDown)
    this.setScheduleSearchWeekDetail(false)
  },
  created () {},
  computed: {
    ...mapState([
      'scheduleSearchWeekDetail',
      'appDate',
      'profile',
      'profiles',
      'workspace',
      'locationIndicatorDialogDate'
    ]),
    ...mapState({
      teamProfiles: state => state.teams.teamProfiles,
      initiatives: state => state.teams.initiatives,
      teams: state => state.teams.teams,
      defaultSchedules: state => state.teams.defaultSchedules,
      historySchedules: state => state.teams.historySchedules,
      schedules: state => state.teams.schedules,
      currentSchedules: state => state.teams.currentSchedules,
      tempos: state => state.teams.tempos,
      teamDetails: state => state.teams.team,
      lastRecommendation: state => state.teams.lastRecommendation,
      locations: state => state.teams.locations,
      interest: state => state.teams.interest
    }),
    hasLocations () {
      if (this.locations) {
        const locLength = this.locations.filter(loc => {
          return (loc.name !== '') && (!loc.deleted)
        })
        return locLength.length > 0
      }
      return false
    },
    setupDetailsFromTempo () {
      if (!this.workspace || !this.profile || this.tempos.length === 0) {
        // not loaded yet
        return -1
      }
      if (this.workspace.schedulesSetByWorkspace) {
        const preferences = this.tempos.filter(tempo => tempo.type === 'schedules' && tempo.setByWorkspace)
        return preferences.length
      } else if (this.tempos.length > 0 && this.profile) {
        const preferences = this.tempos.filter(tempo => tempo.type === 'schedules' && tempo.teamId === this.profile.team)
        return preferences.length
      }
      return 0
    },
    lastRecommendationUnread () {
      if (this.lastRecommendation) {
        return !this.lastRecommendation.read
      }
      return false
    },
    lastRecommendationStatus () {
      if (this.lastRecommendation) {
        if (`${this.lastRecommendation.detail}`.startsWith('New')) {
          return 'NEW'
        }
        return 'UPDATED'
      }
      return ''
    },
    lastRecommendationText () {
      if (this.lastRecommendation) {
        if (`${this.lastRecommendation.detail}`.startsWith('New')) {
          return this.lastRecommendation.detail.replace('New -', '')
        }
        return this.lastRecommendation.detail.replace('Updated -', '')
      }
      return ''
    },
    sortedSearchResults () {
      if (this.searchResults.length > 0) {
        const weekdayAbv = moment(this.scheduleSearchWeekDetail.selectedDay).format('ddd').toUpperCase()
        if (this.currentSchedules && this.allProfiles) {
          const resultsProfiles = this.searchResults.map((profile) => {
            const profileDetails = this.allProfiles.find((result) => result.id === profile.objectID)
            return { ...profile, ...profileDetails }
          })
          const resultsProfilesWithSchedule = resultsProfiles.map((profile) => {
            const profilesCurrentSchedule = this.currentSchedules.filter(scheduleProfile => scheduleProfile.fromId === profile.id)
            const isAM = moment(this.scheduleSearchWeekDetail.selectedDay).format('a') === 'am'
            const profileResponseObj = profilesCurrentSchedule[0] ? profilesCurrentSchedule[0].schedules[weekdayAbv] ? profilesCurrentSchedule[0].schedules[weekdayAbv] : {} : {}
            let profileResponse = ''
            if (profileResponseObj.split) {
              if (isAM) {
                profileResponse = profileResponseObj.amResponse || ''
              } else {
                profileResponse = profileResponseObj.pmResponse || ''
              }
            } else {
              profileResponse = profileResponseObj.response || ''
            }
            return { ...profile, workingAt: profileResponse }
          }).sort((a, b) => {
            if (!a.displayName) {
              a.displayName = ''
            }
            if (!b.displayName) {
              b.displayName = ''
            }
            if (a.displayName < b.displayName) return -1
            return a.displayName > b.displayName ? 1 : 0
          })
          const profilesGroupedBy = _.groupBy(resultsProfilesWithSchedule, 'workingAt')
          const allResultProfiles = Object.entries(profilesGroupedBy).flatMap(arr => Array(...arr[1]))
          const myProfile = allResultProfiles.filter((schedule) => schedule.id === this.profile.id)
          const mySchedule = myProfile[0] ? myProfile[0].workingAt : ''
          const profilesWithMySchedule = mySchedule ? allResultProfiles.filter(schedule => schedule.workingAt === mySchedule && schedule.id !== this.profile.id) : []
          if (profilesWithMySchedule.length > 0) {
            profilesWithMySchedule.forEach((profile, i) => {
              const profileIndex = allResultProfiles.findIndex(allProfile => allProfile.id === profile.id)
              allResultProfiles.splice(profileIndex, 1)
            })
            allResultProfiles.unshift(...profilesWithMySchedule)
          }
          let arrayLength = allResultProfiles.length
          // push today notset profiles to last
          for (let i = 0; i < arrayLength; i++) {
            if (allResultProfiles[i].workingAt === '' && allResultProfiles[i].id !== this.profile.id) {
              allResultProfiles.push(allResultProfiles.splice(i, 1)[0])
              i--
              arrayLength--
            }
          }
          // push today and no default schedules to last
          const orderedAllResultProfiles = [...allResultProfiles]
          let allOrderedProfilesLength = orderedAllResultProfiles.length
          for (let i = 0; i < allOrderedProfilesLength; i++) {
            if (orderedAllResultProfiles[i].workingAt === '' && orderedAllResultProfiles[i].id !== this.profile.id && !orderedAllResultProfiles[i].defaultScheduleSubmitted) {
              orderedAllResultProfiles.push(orderedAllResultProfiles.splice(i, 1)[0])
              i--
              allOrderedProfilesLength--
            }
          }
          // push self to first
          function unShiftSelfProfileFirst (array, selfProfileId) {
            if (array.length > 0) {
              const myProfileIndex = array.findIndex(profileSchedule => profileSchedule.id === selfProfileId)
              const myProfile = array.filter(profileSchedule => profileSchedule.id === selfProfileId)
              array.splice(myProfileIndex, 1)
              array.unshift(...myProfile)
              return array
            }
          }
          if (orderedAllResultProfiles.findIndex(profile => profile.id === this.profile.id) > -1) {
            return unShiftSelfProfileFirst(orderedAllResultProfiles, this.profile.id)
          }
          return orderedAllResultProfiles
        }
        return this.searchResults
      }
      return []
    },
    allProfiles () {
      if (this.profiles.map(p => p.id).includes(this.profile.id)) {
        return [...this.profiles]
      }
      return [...this.profiles, this.profile]
    },
    teamProfilesTodaySchedule () {
      /* adding workingAt from currentSchedules,
      sorting by name, unshift selfProfile and
      sort by workingAt and pushing all notset to last */
      const weekdayAbv = moment(this.scheduleSearchWeekDetail.selectedDay).format('ddd').toUpperCase()
      if (this.currentSchedules && this.teamProfiles) {
        const teamProfilesWithSchedule = this.teamProfiles
          .map((prof) => {
            const profileSchedule = this.currentSchedules.filter(profile => profile.fromId === prof.id)
            const isAM = moment(this.scheduleSearchWeekDetail.selectedDay).format('a') === 'am'
            const profileResponseObj = profileSchedule[0] ? profileSchedule[0].schedules[weekdayAbv] ? profileSchedule[0].schedules[weekdayAbv] : {} : {}
            let profileResponse
            if (profileResponseObj.split) {
              if (isAM) {
                profileResponse = profileResponseObj.amResponse || ''
              } else {
                profileResponse = profileResponseObj.pmResponse || ''
              }
            } else {
              profileResponse = profileResponseObj.response || ''
            }
            return { ...prof, workingAt: profileResponse }
          }).sort((a, b) => a.displayName.localeCompare(b.displayName))

        const profilesGroupedBy = _.groupBy(teamProfilesWithSchedule, 'workingAt')
        const teamAllProfiles = Object.entries(profilesGroupedBy).flatMap(arr => Array(...arr[1]))
        const myProfile = teamAllProfiles.filter((schedule) => schedule.id === this.profile.id)
        const mySchedule = myProfile[0] ? myProfile[0].workingAt : ''
        const profilesWithMySchedule = mySchedule ? teamAllProfiles.filter(schedule => schedule.workingAt === mySchedule && schedule.id !== this.profile.id) : []
        if (profilesWithMySchedule.length > 0) {
          profilesWithMySchedule.forEach((profile, i) => {
            const profileIndex = teamAllProfiles.findIndex(allProfile => allProfile.id === profile.id)
            teamAllProfiles.splice(profileIndex, 1)
          })
          teamAllProfiles.unshift(...profilesWithMySchedule)
        }
        let arrayLength = teamAllProfiles.length
        // push today notset profiles to last
        for (let i = 0; i < arrayLength; i++) {
          if (teamAllProfiles[i].workingAt === '' && teamAllProfiles[i].id !== this.profile.id) {
            teamAllProfiles.push(teamAllProfiles.splice(i, 1)[0])
            i--
            arrayLength--
          }
        }
        // push today and no default schedules to last
        const orderedTeamProfiles = [...teamAllProfiles]

        let allOrderedProfilesLength = orderedTeamProfiles.length
        for (let i = 0; i < allOrderedProfilesLength; i++) {
          if (orderedTeamProfiles[i].workingAt === '' && orderedTeamProfiles[i].id !== this.profile.id && !orderedTeamProfiles[i].defaultScheduleSubmitted) {
            orderedTeamProfiles.push(orderedTeamProfiles.splice(i, 1)[0])
            i--
            allOrderedProfilesLength--
          }
        }

        // push self to first
        function unShiftSelfProfileFirst (array, selfProfileId) {
          if (array.length > 0) {
            const myProfileIndex = array.findIndex(profileSchedule => profileSchedule.id === selfProfileId)
            const myProfile = array.filter(profileSchedule => profileSchedule.id === selfProfileId)
            array.splice(myProfileIndex, 1)
            array.unshift(...myProfile)
            return array
          }
        }
        if (orderedTeamProfiles.findIndex(profile => profile.id === this.profile.id) > -1) {
          return unShiftSelfProfileFirst(orderedTeamProfiles, this.profile.id)
        }

        return orderedTeamProfiles
      }
      return []
    },
    computedMembersWeeklySchedule () {
      if (this.allProfiles && this.schedules && this.historySchedules && this.defaultSchedules) {
        const profileDatedResponse = []
        this.allProfiles.forEach((profile, index) => {
          const profileWeeklySchedules = this.schedules.filter((schedule) => {
            return schedule.fromId === profile.id
          })
          const profileDefaultSchedules = this.defaultSchedules.filter((schedule) => {
            return schedule.fromId === profile.id
          })
          const profileHistorySchedules = this.historySchedules.filter(schedule => {
            return schedule.fromId === profile.id
          }).sort((a, b) => a.updateDate - b.updateDate)

          this.tableWeekDetails.map(async date => {
            const weekdayAbv = moment(date).format('ddd').toUpperCase()
            let datedSchedule = {}
            if (profileWeeklySchedules && profileWeeklySchedules[0]) {
              datedSchedule = profileWeeklySchedules[0].schedules[weekdayAbv] || {}
            }
            let historySchedule = {}
            if (profileHistorySchedules.length >= 2) {
              historySchedule = profileHistorySchedules[profileHistorySchedules.length - 2].schedules[weekdayAbv] || {}
            }
            let defaultSchedule = ''
            let defaultResponseAm = ''
            let defaultResponsePm = ''
            let defaultResponseType = ''
            let defaultResponseLocation = ''
            let defaultResponseLocationSplit = ''
            let defaultResponseThirdspaceLocation = ''
            let defaultResponseThirdspaceLocationSplit = ''
            let defaultResponseDesk = ''
            if (profileDefaultSchedules && profileDefaultSchedules[0]) {
              if (profileDefaultSchedules[0].schedules && profileDefaultSchedules[0].schedules[weekdayAbv]) {
                defaultSchedule = profileDefaultSchedules[0].schedules[weekdayAbv].response || ''
                defaultResponseType = profileDefaultSchedules[0].schedules[weekdayAbv].type || ''
                defaultResponseAm = profileDefaultSchedules[0].schedules[weekdayAbv].amResponse || ''
                defaultResponsePm = profileDefaultSchedules[0].schedules[weekdayAbv].pmResponse || ''
                defaultResponseLocation = profileDefaultSchedules[0].schedules[weekdayAbv].officeLocation || ''
                defaultResponseLocationSplit = profileDefaultSchedules[0].schedules[weekdayAbv].splitOfficeLocation || ''
                defaultResponseThirdspaceLocation = profileDefaultSchedules[0].schedules[weekdayAbv].thirdspaceLocation || ''
                defaultResponseThirdspaceLocationSplit = profileDefaultSchedules[0].schedules[weekdayAbv].splitThirdspaceLocation || ''
                defaultResponseDesk = ''
                // profileDefaultSchedules[0].schedules[weekdayAbv].desk
              }
            }
            const pdresp = {
              submittedOn: datedSchedule.submittedOn || '',
              editedOn: datedSchedule.editedOn || '',
              response: datedSchedule.response || '',
              amResponse: datedSchedule.amResponse || '',
              pmResponse: datedSchedule.pmResponse || '',
              split: datedSchedule.split || false,
              type: datedSchedule.type || '',
              dated: date,
              confirmed: datedSchedule.confirmed || false,
              id: datedSchedule.fromId || profile.id,
              day: moment(date).format('dddd'),
              dayIndex: moment(date).isoWeekday(),
              thirdspaceLocation: datedSchedule.thirdspaceLocation || '',
              locationId: datedSchedule.locationId || '',
              desk: datedSchedule.desk ? `, ${datedSchedule.desk}` : '',
              officeLocation: datedSchedule.officeLocation || '',
              splitThirdspaceLocation: datedSchedule.splitThirdspaceLocation || '',
              splitOfficeLocation: datedSchedule.splitOfficeLocation || '',
              defaultResponse: defaultSchedule,
              defaultResponseAm,
              defaultResponsePm,
              defaultResponseType,
              defaultResponseLocation,
              defaultResponseDesk: defaultResponseDesk ? `, ${defaultResponseDesk}` : '',
              defaultResponseLocationSplit,
              defaultResponseThirdspaceLocation,
              defaultResponseThirdspaceLocationSplit,
              historyResponse: historySchedule.response || '',
              historyResponseAm: historySchedule.amResponse || '',
              historyResponsePm: historySchedule.pmResponse || '',
              historyResponseType: historySchedule.type || ''
            }
            profileDatedResponse.push(pdresp)
          })
        })
        const allProfilesDatedResponse = _.groupBy(profileDatedResponse, 'id')
        const mapIndex = Object.values(allProfilesDatedResponse)
        const notsetProfiles = []
        for (const profile in mapIndex) {
          if (mapIndex[profile].every(response => !response.type && !response.defaultResponseType)) {
            notsetProfiles.push(mapIndex[profile][0].id)
          }
        }
        // console.log(notsetProfiles.length)
        return { allProfiles: _.groupBy(profileDatedResponse, 'id'), profilesNotset: notsetProfiles }
      }
      return {}
    }
  },
  mounted () {
    if (this.useSearchClient) {
      this.getSearchClient()
    }
    window.addEventListener('resize', this.callResize)
    window.addEventListener('click', this.handleClickOutside)
    window.addEventListener('keydown', this.handleKeyDown)
    if (this.scheduleSearchWeekDetail) {
      this.weekDetail = { ...this.scheduleSearchWeekDetail }
      this.selectedDay = this.scheduleSearchWeekDetail.selectedDay
    } else if (this.showDate) {
      const showDate = moment(this.showDate)
      const setWeekDetail = {
        startOfWeek: moment(showDate).startOf('isoWeek'),
        endOfWeek: moment(showDate).endOf('isoWeek'),
        weekNumber: moment(showDate).week()
      }
      this.weekDetail = { ...setWeekDetail }
      this.selectedDay = moment(showDate)
      // this.loadWeekSchedules('schedulesearch.mounted')
    } else {
      this.weekDetail = { ...this.defaultWeekDetail }
      this.selectedDay = moment(this.appDate)
    }
    this.calculateTableWeekDetails()
    // this.loadWeekSchedules('schedulesearch.mounted')
    if (this.pinnedQuery) {
      this.query = this.pinnedQuery
    }
    this.calculateTodayHighlightHeight()
  },
  methods: {
    ...mapActions([
      'updateProfile',
      'setMemberRequestDisplay',
      'setLocationIndicatorDialogDate'
    ]),
    ...mapActions({
      loadHistorySchedules: 'teams/loadHistorySchedules',
      loadTopicsAndTeamMatesPreferences: 'teams/loadTopicsAndTeamMatesPreferences',
      setScheduleSearchWeekDetail: 'setScheduleSearchWeekDetail',
      loadSchedules: 'teams/loadSchedules',
      updateNotification: 'teams/updateNotification',
      loadPreferencesForPastRecommendations: 'teams/loadPreferencesForPastRecommendations'
    }),
    openLocationIndicatorDialoge (date) {
      if (!this.onboarding) {
        this.setLocationIndicatorDialogDate(date)
      }
    },
    calculateTodayHighlightHeight () {
      const self = this
      setTimeout(() => {
        const element = document.querySelector('.whos-where-when-wrapper .whos-where-when-content')
        const addHeight = self.windowWidth <= 1251 ? 35 : 45
        self.todayHeight = (element?.offsetHeight + addHeight) + 'px'
      }, 1000)
    },
    positionLocationDetailsTooltip () {
      if (this.locationPinClickedFor?.id) {
        const tooltipId = this.locationPinClickedFor.id
        setTimeout(() => {
          const tooltip = document.getElementsByClassName(tooltipId)
          tooltip[0]?.scrollIntoViewIfNeeded()
        }, 500)
      }
    },
    closeMemberRequest (event) {
      event.stopPropagation() // to avoid parent click opening the modal
      this.showMemberRequest = false
    },
    showMemberRequestModal () {
      this.setMemberRequestDisplay(true)
    },
    getProfile (profileId) {
      if (this.profile.id === profileId) {
        return this.profile
      }
      return [...this.profiles].filter(prof => prof.id === profileId)[0]
    },
    getTeamName (init) {
      const teamId = init.teamId
      if (teamId !== this.profile.team) {
        if (this.teams) {
          const ft = this.teams.filter((t) => {
            return t.id === teamId
          })[0] || {}
          return `${ft.name || ''}/`
        }
      }
      return ''
    },
    checkProfileNotSet (profileId) {
      return this.computedMembersWeeklySchedule.profilesNotset && !this.computedMembersWeeklySchedule.profilesNotset.includes(profileId)
    },
    getTooltipText (submittedOn, editedOn, response) {
      let tooltipText = ''
      if (submittedOn) {
        tooltipText = 'Submitted ' + this.formatSubmittedDate(submittedOn)
      }
      if (editedOn) {
        tooltipText = tooltipText + '<br/>' + 'Edited ' + this.formatSubmittedDate(editedOn)
      }
      return tooltipText
    },
    addUserIconClick () {
      this.$root.$emit('setRightSectionType', 'addNewUserModal')
    },
    markFirstTimeSet () {
      this.updateProfile({ profile: { schedulesSetupSeen: true }, noSnack: true })
    },
    markAsRead (notification) {
      this.updateNotification({ notificationId: notification.id, update: { read: true } })
    },
    showOtherInfoTooltip (response, index) {
      if (this.displayOtherInfoIcon(response)) {
        this.locationPinClicked(response, index)
        return true
      }
      return false
    },
    displayOtherInfoIcon (response) {
      // Full
      if (response.type === 'full' && response.response) {
        if (response.response === 'thirdspace' && response.thirdspaceLocation) {
          return true
        }
        if (response.response === 'office' && response.officeLocation) {
          return true
        }
      }

      // Split
      if (response.type === 'split' && response.amResponse && response.pmResponse) {
        if ((response.amResponse === 'thirdspace' || response.pmResponse === 'thirdspace') && response.splitThirdspaceLocation) {
          return true
        }
        if ((response.amResponse === 'office' || response.pmResponse === 'office') && response.splitOfficeLocation) {
          return true
        }
      }

      // From History
      if (response.historyResponseType) {
        if (response.type !== response.historyResponseType) {
          return true
        }
        if (response.type === 'full' && response.response !== response.historyResponse) {
          return true
        }
        if (response.type === 'split' && (response.amResponse !== response.historyResponseAm || response.pmResponse !== response.historyResponsePm)) {
          return true
        }
      }

      // From defaults
      if (!response.type && response.defaultResponseType === 'full' && response.defaultResponse === 'office' && response.defaultResponseLocation) {
        return true
      }
      if (!response.type && response.defaultResponseType === 'split' && response.defaultResponseLocationSplit) {
        return true
      }
      if (!response.type && response.defaultResponseType === 'full' && response.defaultResponse === 'thirdspace' && response.defaultResponseThirdspaceLocation) {
        return true
      }
      if (!response.type && response.defaultResponseType === 'split' && (response.defaultResponseAm === 'thirdspace' || response.defaultResponsePm === 'thirdspace') && response.defaultResponseThirdspaceLocationSplit) {
        return true
      }
      return false
    },
    showWeeklyMode (from) {
      if (from && from === 'default') {
        this.updateProfile({ profile: { teamExpanded: false }, noSnack: true })
      } else {
        this.calculateTodayHighlightHeight()
      }
      this.locationPinClickedFor = {}
    },
    isResponseDatedToday (dated) {
      return sameDay(dated, new Date())
    },
    goToPreviousDay () {
      let offset = 1
      if (moment(this.selectedDay).format('ddd').toUpperCase() === 'MON') {
        offset = 3
      }
      this.selectedDay = moment(this.selectedDay).startOf('day').subtract(offset, 'days')
    },
    goToNextDay () {
      let offset = 1
      if (moment(this.selectedDay).format('ddd').toUpperCase() === 'FRI') {
        offset = 3
      }
      this.selectedDay = moment(this.selectedDay).startOf('day').add(offset, 'days')
    },
    setSetupSchedules (from) {
      this.$emit('enableSetupSchedules')
      if (from === 'notif') {
        this.markFirstTimeSet()
      }
    },
    isSelectedDayPastWeek () {
      if (this.weekDetail) {
        return moment().isAfter(this.weekDetail.startOfWeek, 'week')
      }
      return false
    },
    isSelectedDayFutureWeek () {
      if (this.weekDetail) {
        return moment().isBefore(this.weekDetail.startOfWeek, 'week')
      }
      return false
    },
    isSelectedDayPast () {
      return inPast(this.selectedDay)
    },
    isSelectedDayFuture () {
      return inFuture(this.selectedDay)
    },
    closeSetup () {
      this.$emit('closeSetup')
      if (this.useSearchClient) {
        this.getSearchClient() // call for autocomplete input
      }
    },
    emitSettingsCogClick () {
      this.$emit('emitLeftSideCog')
    },
    editProfileClick () {
      this.$root.$emit('setRightSectionType', { section: 'weeklyPreferences', weeklyPreferences: true })
    },
    openLocPrefDay () {
      if (!this.onboarding) {
        this.$root.$emit('setRightSectionType', { section: 'weeklyPreferences', weeklyPreferences: true, openDate: true })
      }
    },
    dayLabel (date) {
      const selected = this.isSelectedDay(date) ? 'selected' : ''
      return `day ${moment(date).format('ddd')} ${selected}`
    },
    formatDate (date) {
      if (this.windowWidth <= 1145) {
        return moment(date).format('dd D')
      }
      return moment(date).format('ddd D')
    },
    formatSubmittedDate (date) {
      if (date) {
        if (date.seconds) {
          return moment.unix(date).format('MMM D [at] HH:mm')
        }
      }
      return ''
    },
    weekClick (type) {
      if (type === 'next') {
        this.weekDetail.startOfWeek = moment().startOf('isoWeek').add(1, 'weeks')
        this.weekDetail.endOfWeek = moment().startOf('isoWeek').add(1, 'weeks').endOf('isoWeek').subtract('2', 'days')
        this.weekDetail.weekNumber = moment().add(1, 'weeks').week()
      } else {
        this.weekDetail.startOfWeek = moment().startOf('isoWeek')
        this.weekDetail.endOfWeek = moment().endOf('isoWeek').subtract('2', 'days')
        this.weekDetail.weekNumber = moment().week()
      }
      if (!this.isCurrentWeek(this.weekDetail.startOfWeek)) {
        this.selectedDay = moment(this.weekDetail.startOfWeek).startOf('day')
      } else {
        this.selectedDay = moment(this.appDate)
      }
    },
    resetLocationPinClicked () {
      this.locationPinClickedFor = {}
      this.$emit('resetLocationPinClicked')
    },
    locationPinClicked (response, index) {
      this.locationPinClickedFor = { id: response.id, index }
      this.$emit('tooltipLocation')
    },
    onSelect (selected) {
      // console.log('onSelect', selected)
      if (selected) {
        const { query } = selected.item
        this.query = query
      }
    },
    indicesToSuggestions (indices) {
      // console.log('indicesToSuggestions')
      return indices
        .filter(
          ({ indexName }) =>
            indexName === `${this.workspace.id}-profiles_query_suggestions`
        )
        .map(({ hits }) => ({
          data: hits.map(hit => {
            return {
              ...hit,
              name: hit.query
            }
          })
        }))
    },
    pinSearchedProfile (profileId) {
      // console.log('pinSearchedProfile', profileId)
      if (this.profile.pinnedProfiles && this.profile.pinnedProfiles.length > 0 && !this.profile.pinnedProfiles.includes(profileId)) {
        this.updateProfile({ profile: { pinnedProfiles: [...this.profile.pinnedProfiles, profileId] }, noSnack: true })
      } else {
        this.updateProfile({ profile: { pinnedProfiles: [profileId] }, noSnack: true })
      }
      this.clearAutocomplete()
    },
    pinSearchedTeam (teamId) {
      // console.log('pinSearchedTeam', teamId)
      if (this.profile.pinnedTeams && this.profile.pinnedTeams.length > 0 && !this.profile.pinnedTeams.includes(teamId)) {
        this.updateProfile({ profile: { pinnedTeams: [...this.profile.pinnedTeams, teamId] }, noSnack: true })
      } else {
        this.updateProfile({ profile: { pinnedTeams: [teamId] }, noSnack: true })
      }
      this.clearAutocomplete()
    },
    pinSearchedInitiative (initiativeId) {
      // console.log('pinSearchedInitiative', initiativeId)
      if (this.profile.pinnedInitiatives && this.profile.pinnedInitiatives.length > 0 && !this.profile.pinnedInitiatives.includes(initiativeId)) {
        this.updateProfile({ profile: { pinnedInitiatives: [...this.profile.pinnedInitiatives, initiativeId] }, noSnack: true })
      } else {
        this.updateProfile({ profile: { pinnedInitiatives: [initiativeId] }, noSnack: true })
      }
      this.clearAutocomplete()
    },
    setSearchState ({ query, type, itemId }) {
      this.$emit('setQuerySearch', query)
      this.query = query
      this.queryType = type || ''
    },
    getBoxFillClass (responseParam, forWrapper, responseSplit) {
      // console.log('schedulesearch.getBoxFillClass', responseParam, forWrapper, responseSplit)
      let response = ''
      if (forWrapper && Object.keys(responseParam).length > 0) {
        if (!responseParam.type && responseParam.defaultResponseType) {
          if (responseParam.defaultResponseType === 'split') {
            response = 'split'
          } else if (responseParam.defaultResponseType === 'full') {
            response = responseParam.response
          } else {
            response = 'notset'
          }
        }
        if (responseParam.type) {
          if (responseParam.type === 'split') {
            response = 'split'
          } else if (responseParam.type === 'full' && responseParam.response) {
            response = responseParam.response
          } else {
            response = 'notset'
          }
        }
      } else if (responseSplit) {
        response = responseSplit
      } else {
        response = responseParam
      }
      if (responseParam.default) {
        return 'notset-fill'
      }
      if (responseParam.addedByConfirmation) {
        return 'notset-fill'
      }
      if (response && response !== null && response !== '' && response !== undefined) {
        switch (response.toLowerCase()) {
          case 'home':
            return 'home-fill'
          case 'office':
            return 'office-fill'
          case 'nonworking':
            return 'nonworking-fill'
          case 'notset':
            return 'notset-fill'
          case 'thirdspace':
            return 'thirdspace-fill'
          case 'split':
            return 'split-fill'
          default:
            return ''
        }
      }
    },
    getResponseIcon (response) {
      return getResponseIconName(response)
    },
    calculateTableWeekDetails () {
      // console.log('calculateTableWeekDetails', this.weekDetail.startOfWeek)
      this.tableWeekDetails = []
      for (let i = 0; i <= 4; i++) {
        this.tableWeekDetails.push(moment(this.weekDetail.startOfWeek).startOf('day').add(i, 'days').toDate())
      }
    },
    isCurrentWeek (day) {
      if (moment(day, 'week').isSame(new Date(), 'week')) {
        return true
      }
      return false
    },
    isSelectedDay (day) {
      return sameDay(this.selectedDay, day)
    },
    isDateToday (date) {
      return isToday(date)
    },
    onDaySelection (date) {
      if (!this.isSelectedDay(date)) {
        if (isToday(date)) {
          this.selectedDay = moment(this.appDate)
        } else {
          this.selectedDay = moment(date).startOf('day')
        }
      }
    },
    goForward () {
      this.weekDetail.startOfWeek = moment(this.weekDetail.startOfWeek).add(1, 'weeks')
      this.weekDetail.endOfWeek = moment(this.weekDetail.endOfWeek).add(1, 'weeks')
      this.weekDetail.weekNumber = moment(this.weekDetail.startOfWeek).week()
      if (!this.isCurrentWeek(this.weekDetail.startOfWeek)) {
        this.selectedDay = moment(this.weekDetail.startOfWeek).startOf('day')
      } else {
        this.selectedDay = moment(this.appDate)
      }
    },
    goBackward () {
      this.weekDetail.startOfWeek = moment(this.weekDetail.startOfWeek).subtract(1, 'weeks')
      this.weekDetail.endOfWeek = moment(this.weekDetail.endOfWeek).subtract(1, 'weeks')
      this.weekDetail.weekNumber = moment(this.weekDetail.startOfWeek).week()
      if (!this.isCurrentWeek(this.weekDetail.startOfWeek)) {
        this.selectedDay = moment(this.weekDetail.startOfWeek).startOf('day')
      } else {
        this.selectedDay = moment(this.appDate)
      }
    },
    async loadWeekSchedules (from) {
      this.allSchedulesLoaded = false
      // console.log('schedulesearch.loadWeekSchedules', from, this.weekDetail)
      await this.loadSchedules({ weekCommencingDate: moment(this.weekDetail.startOfWeek).toDate() })
      await this.loadTopicsAndTeamMatesPreferences({
        userId: this.profile.id,
        fromDate: moment(this.weekDetail.startOfWeek).toDate()
      })
      this.loadPreferencesForPastRecommendations({
        userId: this.profile.id,
        date: moment(this.weekDetail.startOfWeek).toDate()
      })
      this.loadHistorySchedules({
        weekCommencingDate: moment(this.weekDetail.startOfWeek).toDate()
      })
      if (this.setWeekDetail || !this.scheduleSearchWeekDetail || this.scheduleSearchWeekDetail.weekNumber !== this.weekDetail.weekNumber) {
        // console.log('* scheduleSearch.loadWeekSchedules -> setScheduleSearchWeekDetail', this.weekDetail)
        this.setScheduleSearchWeekDetail({
          startOfWeek: moment(this.weekDetail.startOfWeek),
          endOfWeek: moment(this.weekDetail.endOfWeek),
          weekNumber: this.weekDetail.weekNumber,
          selectedDay: this.selectedDay,
          datesOfWeek: this.tableWeekDetails
        })
      }
      this.allSchedulesLoaded = true
    },
    getScheduleStatus (profileId) {
      if (this.schedules && this.schedules.length) {
        const schedule = this.schedules.filter((sch) => { return sch.fromId === profileId })
        if (schedule[0]) {
          const scheduleConfired = schedule[0].confirmed
          if (scheduleConfired) {
            return 'confirmed'
          } else {
            return 'not-confirmed'
          }
        }
      }
      return 'unset'
    },
    callResize () {
      this.getWindowWidth()
      this.calculateTodayHighlightHeight()
    },
    getWindowWidth () {
      this.windowWidth = window.innerWidth
    },
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        if (Object.keys(this.locationPinClickedFor).length > 0) {
          this.locationPinClickedFor = {}
        }
      }
    },
    handleClickOutside (event) {
      if (event) {
        if (event.target) {
          let isAllowed = false
          if (event.target.classList) {
            isAllowed = isAllowed || event.target.classList.contains('grid-tooltip') ||
              event.target.classList.contains('schedule-type') ||
              event.target.classList.contains('day-schedule')
          }
          if (!isAllowed && event.target.tagName && event.target.src) {
            isAllowed = isAllowed || (event.target.tagName.toLowerCase() === 'img' &&
              event.target.src.includes('grid-tooltip'))
          }
          if (!isAllowed && event.target.parentNode && event.target.parentNode.classList) {
            isAllowed = isAllowed || (event.target.parentNode.classList.contains('other-info') ||
              event.target.parentNode.classList.contains('schedule-type') ||
              (event.target.tagName.toLowerCase() === 'img' && event.target.parentNode.classList.contains('day-schedule')))
          }
          if (!isAllowed) {
            this.locationPinClickedFor = {}
          }
        }
      }
    },
    clearAutocomplete () {
      // console.log('clearAutocomplete', this.autocomplete)
      this.autocomplete.setQuery('')
    },
    setupAutocomplete () {
      if (!this.pinnedQuery) {
        const self = this
        this.$nextTick(() => {
          self.autocomplete = autocomplete({
            container: '#autocomplete-people',
            plugins: [],
            openOnFocus: true,
            placeholder: 'Type a name, team or initiative',
            getSources () {
              return [
                {
                  sourceId: 'profiles',
                  getItems ({ query }) {
                    if (query === '') {
                      return []
                    }
                    return self.allProfiles.filter((tp) => tp.displayName && tp.displayName.toLowerCase().includes(query.toLowerCase()))
                  },
                  getItemInputValue ({ item }) {
                    return item.displayName
                  },
                  onSelect ({ event, item }) {
                    self.teamId = false
                    self.initiativeId = false
                    if (event && (event.target.classList.contains('mdc-button') || event.target.parentNode.classList.contains('mdc-button') || event.target.parentNode.parentNode.classList.contains('mdc-button'))) {
                      if (item.id !== self.profile.id) {
                        self.pinSearchedProfile(item.id)
                      }
                      return
                    }
                    self.setSearchState({ query: item.displayName, type: 'name', itemId: item.id })
                  },
                  templates: {
                    item ({ item, createElement }) {
                      return createElement('div', { class: 'name-result result-type' },
                        createElement('span', { class: 'item-name profile-name' }, item.displayName),
                        createElement('span', { class: 'mdc-button add-to-page' },
                          createElement('span', { class: 'icon-wrapper clickable' },
                            createElement('img', { class: 'image', src: `${require('../../assets/flexibly/icons/plus_blue.svg')}` })
                          ),
                          createElement('span', { class: 'text mdc-text' }, 'Add to page')
                        )
                      )
                    }
                  }
                },
                {
                  sourceId: 'initiatives',
                  getItems ({ query }) {
                    if (query === '') {
                      return []
                    }
                    return self.initiatives.filter((init) => init.name && init.name.toLowerCase().includes(query.toLowerCase()))
                  },
                  getItemInputValue ({ item }) {
                    return item.name
                  },
                  onSelect ({ event, item }) {
                    if (event && (event.target.classList.contains('mdc-button') || event.target.parentNode.classList.contains('mdc-button') || event.target.parentNode.parentNode.classList.contains('mdc-button'))) {
                      if (item.id !== self.profile.team) {
                        self.pinSearchedInitiative(item.id)
                      }
                      return
                    }
                    self.initiativeId = item.id
                    self.setSearchState({ query: item.name, type: 'initiative', itemId: item.id })
                  },
                  templates: {
                    item ({ item, createElement }) {
                      const itemName = '#' + self.getTeamName(item) + item.name
                      return createElement('div', { class: 'initiative-result result-type' },
                        createElement('span', { class: 'item-name initiative-name' }, itemName),
                        createElement('span', { class: 'mdc-button add-to-page' },
                          createElement('span', { class: 'icon-wrapper clickable' },
                            createElement('img', { class: 'image', src: `${require('../../assets/flexibly/icons/plus_blue.svg')}` })
                          ),
                          createElement('span', { class: 'text mdc-text' }, 'Add to page')
                        )
                      )
                    }
                  }
                },
                {
                  sourceId: 'teams',
                  getItems ({ query }) {
                    if (query === '') {
                      return []
                    }
                    return self.teams.filter((team) => team.name && team.name.toLowerCase().includes(query.toLowerCase()))
                  },
                  getItemInputValue ({ item }) {
                    return item.name
                  },
                  onSelect ({ event, item }) {
                    if (event && (event.target.classList.contains('mdc-button') || event.target.parentNode.classList.contains('mdc-button') || event.target.parentNode.parentNode.classList.contains('mdc-button'))) {
                      if (item.id !== self.profile.team) {
                        self.pinSearchedTeam(item.id)
                      }
                      return
                    }
                    self.teamId = item.id
                    self.setSearchState({ query: item.name, type: 'team', itemId: item.id })
                  },
                  templates: {
                    item ({ item, createElement }) {
                      return createElement('div', { class: 'team-result result-type' },
                        createElement('span', { class: 'item-name team-name' }, item.name),
                        createElement('span', { class: 'mdc-button add-to-page' },
                          createElement('span', { class: 'icon-wrapper clickable' },
                            createElement('img', { class: 'image', src: `${require('../../assets/flexibly/icons/plus_blue.svg')}` })
                          ),
                          createElement('span', { class: 'text mdc-text' }, 'Add to page')
                        )
                      )
                    }
                  }
                }
              ]
            },
            onSubmit (event) {
              self.teamId = false
              self.initiativeId = false
              self.setSearchState(event.state)
            },
            onReset (event) {
              self.teamId = false
              self.initiativeId = false
              self.setSearchState({ query: '' })
            },
            onStateChange ({ prevState, state }) {
              if (state.query === '') {
                self.setSearchState({ query: '' })
              }
            }
          })
        })
      }
    },
    async getSearchClient () {
      const algoliaId = appSettings.algoliaId
      const algoliaKey = appSettings.algoliaKey
      if (!this.profile.searchKey) {
        const generateSearchKey = fb.functions.httpsCallable('generateSearchKey')
        const params = { userId: this.profile.id, teamId: this.profile.team, workspaceId: this.workspace.id }
        // console.log('Search.generateSearchKey params', params)
        await generateSearchKey(params).then((res) => {
          // console.log('Search.generateSearchKey', res, res.data)
          if (res.data.error) {
            // Fallback to all
            this.searchClient = algoliasearch(
              algoliaId,
              algoliaKey
            )
            this.setupAutocomplete()
          }
          if (res.data) {
            if (res.data.searchKey) {
              this.profile.searchKey = res.data.searchKey
              this.searchClient = algoliasearch(
                algoliaId,
                this.profile.searchKey
              )
              this.setupAutocomplete()
            }
          }
        })
      } else {
        this.searchClient = algoliasearch(
          algoliaId,
          this.profile.searchKey
        )
        this.setupAutocomplete()
      }
    }
  },
  watch: {
    showDate: {
      handler () {
        if (this.showDate) {
          const showDate = moment(this.showDate)
          this.weekDetail = {
            startOfWeek: moment(showDate).startOf('isoWeek'),
            endOfWeek: moment(showDate).endOf('isoWeek'),
            weekNumber: moment(showDate).week()
          }
          this.selectedDay = moment(showDate)
        }
      }
    },
    locationPinClickedFor: {
      handler () {
        if (Object.keys(this.locationPinClickedFor).length > 0) {
          this.positionLocationDetailsTooltip()
        }
      }
    },
    pinnedInitiativeId: {
      handler () {
        if (this.pinnedInitiativeId) {
          this.initiativeId = this.pinnedInitiativeId
        }
      },
      immediate: true
    },
    pinnedTeamId: {
      handler () {
        if (this.pinnedTeamId) {
          this.teamId = this.pinnedTeamId
        }
      },
      immediate: true
    },
    searchResults: {
      handler () {
        // console.log('scheduleSearch.watch searchResults', this.searchResults)
        // for not showing days grid in pin details expand mode
        const profilesNotSet = this.computedMembersWeeklySchedule.profilesNotset
        const searchResults = this.searchResults
        if (searchResults.filter((result) => result.objectID).every((id) => profilesNotSet.includes(id.objectID))) {
          this.allSearchResultsNotSet = true
          this.$emit('allSearchResultsNotSet')
        } else {
          this.allSearchResultsNotSet = false
        }
      }
    },
    selectedDay: {
      handler (newDay, oldDay) {
        if (this.selectedDay && this.setWeekDetail) {
          this.weekDetail = {
            startOfWeek: moment(this.selectedDay).startOf('isoWeek'),
            endOfWeek: moment(this.selectedDay).endOf('isoWeek'),
            weekNumber: moment(this.selectedDay).week()
          }
          // console.log('scheduleSearch.watch.selectedDay setScheduleSearchWeekDetail')
          this.setScheduleSearchWeekDetail({
            startOfWeek: moment(this.weekDetail.startOfWeek),
            endOfWeek: moment(this.weekDetail.endOfWeek),
            weekNumber: this.weekDetail.weekNumber,
            selectedDay: this.selectedDay,
            datesOfWeek: this.tableWeekDetails
          })
        }
      }
    },
    scheduleSearchWeekDetail: {
      handler () {
        if (this.scheduleSearchWeekDetail) {
          // console.log('sheduleSearch.watch.scheduleSearchWeekDetail', this.weekDetail.weekNumber, this.scheduleSearchWeekDetail.weekNumber)
          if (this.scheduleSearchWeekDetail.selectedDay) {
            this.onDaySelection(this.scheduleSearchWeekDetail.selectedDay)
          }
          if (this.weekDetail.weekNumber !== this.scheduleSearchWeekDetail.weekNumber) {
            this.weekDetail = { ...this.scheduleSearchWeekDetail }
          }
        } else {
          this.weekDetail = { ...this.defaultWeekDetail }
        }
      }
    },
    'weekDetail.startOfWeek': {
      handler (newf, oldf) {
        // console.log('watch.weekDetail.startOfWeek', this.weekDetail)
        if (this.weekDetail && this.weekDetail.startOfWeek && this.setWeekDetail) {
          this.calculateTableWeekDetails()
          this.loadWeekSchedules('schedulesearch.watch.sow')
        }
      }
    },
    query: {
      handler () {
        this.calculateTodayHighlightHeight()
      }
    }
  }
}
</script>

<style lang="scss">
.default-row,
.query-contents {
  .team-details {
    width: 100%;
    header {
      display: flex;
      margin-bottom: 5px;
      .team-info {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .people-icon {
          height: 15px;
          width: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .team-name {
          &::first-letter {
            text-transform: capitalize;
          }
          text-transform: lowercase;
          cursor: pointer;
          color: #404040;
          font-size: 16px;
          font-weight: 600;
          width: 150px;
          white-space: nowrap;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0 0px 0 5px;
        }
        .add-member-icon {
        }
      }
      .date-text {
        font-weight: 600;
      }
    }
  }
  &.expand-mode {
    .team-details {
      padding-top: 0;
      margin-bottom: 20px;
    }
    header {
      display: grid;
      grid-template-columns: 135px auto 44px;
      width: 100%;
      position: relative;
      grid-gap: 2px;
      margin-bottom: 2px;
      .team-info {
        .people-icon {
          height: 15px;
          width: 20px;
        }
      }
      @media all and (max-width: 1195px) {
        grid-template-columns: 120px auto 24px;
      }
      .empty-wrapper-grid {
        visibility: visible;
        display: grid;
        grid-gap: 2px;
        position: relative;
        background: white;
        .empty-days-grid {
          display: grid;
          grid-template-columns: repeat(5, minmax(auto, 1fr));
          height: 36px;
          grid-gap: 5px;
          background: white;
          position: relative;
          margin: 0 5px;
          .today {
            position:relative;
            background: rgba(255, 0, 46, 0.07);
            // background: linear-gradient(180deg, rgba(255, 117, 98, 0.06) 72.86%, rgba(255, 117, 98, 0.0373636) 94.12%, rgba(255, 117, 98, 0.0102) 102.12%);
            mix-blend-mode: multiply;
            top: -40px;
          }
        }
        &.all-not-set {
          visibility: hidden;
        }
      }
    }
  }
}
$color-grey: #828F9C;
.result-type {
  background-position: 0%;
  background-repeat: no-repeat;
  padding-left:25px;
  text-indent: 1px;
  color: #6184AD;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  .item-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
    margin-right: 10px;
  }
  .add-to-page {
    min-width: 103px;
    height: 25px;
    text-transform: initial;
    visibility: hidden;
    border-radius: 4px;
    background: #fff;
    margin-left: auto;
    .icon-wrapper {
      margin-bottom: 2px;
    }
    .mdc-text {
      font-size: 12px;
      text-transform: initial;
      color: #6184AD;
      font-weight: 600;
      margin-left: 3px;
    }
  }
}
.name-result {
  // display: unset;
  background-image: url(../../assets/flexibly/icons/taskassignee-on.svg);
}
.initiative-result {
  background-image: url(../../assets/flexibly/icons/rocket.svg);
}
.team-result {
  background-image: url(../../assets/flexibly/icons/team.svg);
}
.whos-where-when-wrapper {
  height: 100%;
  &:not(.pin-team) {
    margin-top: -20px;
  }
  .loading-schedules {
    position: relative;
    top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    .mdc-text {
      color: #959EA7;
    }
    &:after {
      content: '  .';
      animation: dots 1s steps(5, end) infinite;
      font-weight: 600;
      font-size: 18px;
      color: #959EA7;
    }
  }
  .container {
    position: relative;
  }
  .whos-where-when-title {
    display: flex;
    align-items: center;
    padding: 15px 20px 5px;
    .icon-spacer {
      height: 17px;
      width: 17px;
      margin-right: 5px;
    }
    .icon-wrapper {
      height: 17px;
      width: 17px;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
      &.whos-where-when-menu {
        margin-left: 8px;
        margin-top: 3px;
        height: 24px;
        width: 13px;
      }
    }
    .mdc-text {
      color: #0F3860;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .recommendations-content {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-bottom: 10px;
    .member-request-approve {
      width: max-content;
    }
    .notif-content {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: max-content;
      &:first-of-type {
        margin-top: 10px;
      }
      &:nth-child(2) {
        margin-top: 8px;
      }
      &.set-pref-content {
        cursor: pointer;
      }
      .status {
        background: #FFE973;
        font-weight: 800;
        font-size: 9px;
        color: #000;
        margin-right: 5px;
        border-radius: 12px;
        text-align: center;
        line-height: 14px;
        padding-left: 4px;
        padding-right: 4px;
        &.seen-status {
          background: #DAC0FC;
          font-weight: 800;
          margin: 0 5px 0 4px;
          padding: 1px 4px;
          min-width: 30px;
        }
      }
      .text {
        color: #000;
        font-weight: 700;
      }
      .icon-wrapper {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .location-schedule-nav-wrapper {
    display: grid;
    grid-template-columns: 115px auto;
    margin-top: 20px;
    margin-left: 25px;
    .location-status-wrapper {
      margin-left: 10px;
    }
    .location-indicator-wrapper {
      top: -5px; // activity box has 5px extra
      right: 8px;
      border: 1px dashed transparent;
      .activity-spaces-wrapper {
        margin: 5px;
      }
      .location-name {
        margin: 0 0 5px 5px;
        i {
          white-space: nowrap;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.loc-ind-opened,
      &:hover {
        // box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
        border-radius: 10px;
        transition: all 0.15s ease-out;
        background: #FFFFFF;
        border: 1px dashed #828F9C;
      }
    }
    &.align-vertical {
      display: flex;
      flex-direction: column;
      .location-indicator-wrapper {
        max-width: 225px;
        display: flex;
        flex-direction: row-reverse;
        .location-name {
          justify-content: center;
        }
      }
      .days-section {
      }
      .days-label-row {
        grid-template-columns: 135px auto 18px;
        @media all and (max-width: 1195px) {
          grid-template-columns: 120px auto 9px;
          margin-right: 9px;
        }
        .icon-wrapper {
          &.previous {
            justify-self: flex-end;
            margin-right: 9px;
          }
        }
      }
      .location-status-wrapper {
        margin-bottom: -10px;
        .location-status {
          all: unset;
          .status-content {
            display: flex;
            align-items: center;
            > * {
              margin-right: 10px;
            }
          }
        }
      }
      .week-nav-buttons {
        margin-right: 30px;
      }
    }
  }
  .schedule-nav-wrapper {
    display: flex;
    flex-direction: column;
  }
  .days-section {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    .week-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .week-range {
        display: flex;
        color:  $color-grey;
        border-radius: 4px;
        margin: 0 18px;
        padding: 5px 10px;
        justify-content: center;
        background: #FFFFFF;
        border: 0.5px solid rgba(149, 162, 176, 0.5);
        box-sizing: border-box;
        width: 180px;
        max-width: 180px;
        @media all and (max-width: 1195px) {
          padding: 5px;
        }
        .mdc-text {
          white-space: nowrap;
          color:  #828F9C;
          &:not(:last-of-type) {
            padding-right: 15px;
          }
        }
        .week-dates {
          font-style: italic;
        }
        .week-number {
          color: #B10000;
          &.future {
            color: #404040;
          }
          &.past {
            color: #828282;
          }
        }
        @media all and (max-width: 1195px) {
          margin: 0 10px;
        }
      }
    }
    .week-nav-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 50px;
      .mdc-button {
        padding: 0 5px;
        max-width: 110px;
        height: 30px;
        border: 0.5px solid rgba(149, 162, 176, 0.5);
        .mdc-text {
          white-space: nowrap;
          color: $color-grey;
        }
        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }
  }
  .days-label-row {
    display: grid;
    grid-template-columns: 19px auto 19px;
    margin: 10px 20px 0 0;
    grid-gap: 5px;
    .icon-wrapper {
      &.previous {
        margin-right: 15px;
      }
      &.next {
      }
    }
    .day-grid {
      display: grid;
      grid-template-columns: repeat(5, minmax(auto, 1fr));
      grid-gap: 5px;
      z-index: 10;
      margin: 0 5px;
      right: 2px;
      position: relative;
      height: 30px;
      .days-text-wrapper {
        padding: 4px;
        border-radius: 4px;
        min-width: 20px;
        height: auto;
        background: #FFFFFF;
        border: 0.5px solid rgba(149, 162, 176, 0.5);
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        .mdc-text {
          color: $color-grey;
          white-space: nowrap;
        }
        &.today {
          border: 0.2px solid #B10000;
        }
        &.selected {
          .mdc-text {
            color: $color-grey;
            font-weight: 700;
          }
          &.past {
            .mdc-text {
              color: #828282;
            }
          }
          &.future {
            .mdc-text {
              color: #404040;
            }
          }
        }
        /* priority to .selected class */
        &.today {
          .mdc-text {
            color: #B10000;
            font-weight: 700;
          }
        }
      }
    }
    @media all and (max-width: 1195px) {
      grid-template-columns: 30px auto 30px;
    }
  }
  .whos-where-when-content {
    background: #FFFFFF;
    position: relative;
    display: flex;
    flex-direction: column;
    &.align-vertical {
      .empty-wrapper-grid {
        .empty-days-grid {
          .today {
            top: -35px;
          }
        }
      }
    }
    .table-contents {
      .search-hits {
        margin: 10px 0;
      }
      &.expand-mode,
      &.query-contents {
        margin: 0 0 0 25px;
        display: flex;
        flex-direction: column;
        .query-row {
          &:empty {
            display: none;
          }
        }
      }
      &.expand-mode {
        position: relative;
      }
      .not-set-profiles {
        display: flex;
        align-items: center;
        // position: absolute;
        margin: 5px 15px 3px 0;
        bottom: 0;
        .notset-text {
          text-transform: uppercase;
          color: #959EA7;
          font-style: italic;
          margin-right: 10px;
        }
        .notset-text:only-child {
          display: none;
        }
        .notset-initials {
          display: flex;
          align-items: center;
        }
      }
      .profile-response-columns {
        display: grid;
        grid-template-columns: 135px auto 44px;
        width: 100%;
        position: relative;
        grid-gap: 2px;
        .profile-content {
          z-index: 4;
          display: grid;
          grid-gap: 2px;
          padding: 0;
          margin-top: 2px;
          .profile-redirect {
            height: 36px;
            max-height: 36px;
            background: #f8f8f8;
            margin: 0;
            padding-left: 15px;
            padding-right: 15px;
            width: calc(100% - 30px);
            .profile-firstname-initial {
              color: #4f4f4f !important;
              font-weight: 400;
              white-space: nowrap;
              max-width: 75px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .you-text {
              color: #333333 !important;
              font-weight: 700;
            }
            &.you {
              .profile-firstname-initial {
                color: #333333 !important;
                font-weight: 700;
              }
              .you-text {
                color: #333333 !important;
                font-weight: 700;
              }
            }
          }
        }
        .responses-days-grid {
          display: grid;
          grid-gap: 2px;
          position: relative;
          // background: #fcfcfc;
          > div { /*response-row*/
            display: grid;
            grid-template-columns: repeat(5, minmax(auto, 1fr));
            height: 36px;
            grid-gap: 5px;
            // background: #fcfcfc;
            margin: 2px 5px;
            > div { /*schedule-type*/
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              box-sizing: border-box;
              position: relative;
              background-color: var(--notset-fill);
              &.clickable {
                cursor: pointer;
              }
              &.today {
                box-shadow: 0 0 5px 0 #ffeded;
              }
              &.thirdspace-fill {
                background-color: var(--thirdspace-fill);
              }
              &.home-fill {
                background-color: var(--home-fill);
              }
              &.office-fill {
                background-color: var(--office-fill);
              }
              &.nonworking-fill {
                background: var(--nonworking-fill);
              }
              &.split-fill {
                width: 100%;
                height: 100%;
                background: white;
                .response-icons-wrapper {
                  width: 100%;
                  height: 100%;
                }
              }
              &.notset-fill {
                background-color: var(--notset-fill);
              }
              .not-set-text {
                color: #bdbdbd;
                text-align: center;
                font-style: italic;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 600;
              }
              .split-day {
                display: flex;
                flex-flow: row;
                align-items: baseline;
                width:100%;
                height:100%;
                .split-day-row {
                  display: flex;
                  height: 100%;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  .am-wrapper {
                    flex:1;
                    border-radius: 4px 0 0 4px;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    .icon-wrapper {
                      width: 100% !important;
                      height: 100% !important;
                      img {
                        width:15px !important;
                        height:15px !important;
                      }
                    }
                  }
                  .pm-wrapper {
                    flex:1;
                    border-radius: 0 4px 4px 0;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    .icon-wrapper {
                      width: 100% !important;
                      height: 100% !important;
                      img {
                        width:15px !important;
                        height:15px !important;
                      }
                    }
                  }
                  .slot-text {
                    font-size: 8px;
                  }
                  .slot-type {
                    .icon-wrapper {
                      width: 20px;
                      height: 20px;
                      img {
                        width: 10px;
                        height: 10px;
                      }
                    }
                    &.thirdspace-fill {
                      background-color: var(--thirdspace-fill);
                    }
                    &.home-fill {
                      background-color: var(--home-fill);
                    }
                    &.office-fill {
                      background-color: var(--office-fill);
                    }
                    &.nonworking-fill {
                      background: var(--nonworking-fill);
                    }
                    &.notset-fill {
                      background-color: var(--notset-fill);
                    }
                  }
                }
              }
              .icon-wrapper {
                width: 15px;
                height: 15px;
                img {
                  width: 100%;
                  height: 100%;
                }
                &.location-pin {
                  position: absolute;
                  top: 0;
                  right: 0;
                  padding: 1px;
                  width: 9px;
                  height: 9px;
                  margin: 2px;
                  border-radius: 50%;
                  box-shadow: 0px 0px 1px rgb(0 0 0 / 25%);
                  z-index: 10;
                }
                &.history-response {
                  width: 8px;
                  height: 8px;
                }
                &.history-arrow {
                  width: 5px;
                  height: 5px;
                }
              }
              .response-icons-wrapper {
                display: flex;
                align-items: center;
                flex-direction: row;
                position: relative;
                .history-wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  right: 100%;
                }
                .icon-wrapper {
                  margin-right: 3px;
                }
                > :nth-child(3) {
                  margin-right: 18px;
                }
              }
              .location-details-tooltip:empty {
                display: none;
              }
              .profile-schedule-tooltip-wrapper:not(:empty) {
                position: absolute;
                background: white;
                transform: translate(0px, 5px);
                top: 100%;
                align-items: flex-start;
                z-index: 101;
                box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 59%);
                border-radius: 8px;
                &:before {
                  border-bottom: 14px solid #fff;
                  border-left: 18px solid transparent;
                  bottom: 99%;
                  left: 40%;
                  content: '';
                  position: absolute;
                  width: 0;
                  height: 0;
                }
              }
              .location-details-tooltip:not(:empty) {
                .profile-redirect {
                  align-self: center;
                  padding-top: 30px;
                  .profile-display-name {
                    color: #4f4f4f;
                  }
                }
                .tooltip-date {
                  color: #404040;
                  align-self: center;
                  margin: 10px 0 0px 0;
                  font-size: 13px;
                }
                .tooltip-locations-wrapper {
                  width: 100%;
                  margin-top: 15px;
                  .location-details {
                    display: flex;
                    flex-direction: column;
                    &.full {
                      background: #F9F9F9;
                      min-height: 100px;
                      max-height: 110px;
                    }
                    .slot-details {
                      &:not(:first-of-type) {
                        margin-top: 3px;
                      }
                      background: #F9F9F9;
                      min-height: 100px;
                      max-height: 110px;
                    }
                    .location-detail:not(:empty) {
                      display: flex;
                      align-items: center;
                      margin-bottom: 8px;
                      margin-top: 20px;
                      margin-left: 15px;
                      margin-right: 5px;
                      .location-icon {
                        width: 14px;
                        height: 14px;
                        img {
                          width: 100%;
                          height: 100%;
                        }
                      }
                      .nolocationid {
                        color:#828282;
                      }
                      .location-text {
                        font-weight: 700;
                        margin-left: 5px;
                      }
                      .tooltip-slot-text {
                        background: #FFFFFF;
                        border: 0.5px solid #C4C4C4;
                        line-height: unset;
                        display: flex;
                        align-items: center;
                        padding: 1px;
                        margin-right: 6px;
                        .mdc-text {
                          color: #C4C4C4;
                          font-weight: 600;
                          font-size: 10px;
                        }
                      }
                    }
                    .location-detail {
                      display: flex;
                      align-items: center;
                      margin-left: 15px;
                      .icon-wrapper {
                        margin-right: 5px;
                        min-height: 9px;
                        min-width: 9px;
                        max-width: 10px;
                        max-height: 12px;
                        img {
                          max-width: 15px;
                          max-height: 15px;
                        }
                      }
                      .mdc-text {
                        line-height: 16px;
                        i {
                          text-transform: capitalize;
                        }
                      }
                    }
                  }
                }
                .tooltip-footer {
                  display: flex;
                  align-items: center;
                  margin: 10px 20px;
                  .edited-date {
                    color: #828282;
                    margin-right: 10px;
                  }
                  .changed-responses {
                    display: flex;
                    align-items: center;
                    .arrow-mark {
                      margin: 0 5px;
                      &.icon-wrapper {
                        height: 20px;
                        width: 20px;
                        img {
                          width: 100%;
                          height: 100%;
                        }
                      }
                    }
                    .mdc-text {
                      margin-left: 5px;
                    }
                    .history-response-wrapper {
                      .answer {
                        &.full-day {
                          display: flex;
                          align-items: baseline;
                          .icon-wrapper {
                            border-radius: 4px;
                            img {
                              width: 10px;
                              height: 10px;
                            }
                          }
                        }
                        &.split-day {
                          display: flex;
                          align-items: baseline;
                          flex-wrap: wrap;
                          .split-day-grid {
                            display: grid;
                            grid-template-columns: 20px 20px;
                            height: 20px;
                            align-items: center;
                            grid-gap: 0.5px;
                            .am-wrapper {
                              border-radius: 4px 0 0 4px;
                            }
                            .pm-wrapper {
                              border-radius: 0 4px 4px 0;
                            }
                            .slot-text {
                              font-size: 8px;
                            }
                            .slot-type {
                              .icon-wrapper {
                                width: 20px;
                                height: 20px;
                                img {
                                  width: 10px;
                                  height: 10px;
                                }
                              }
                              &.thirdspace-fill {
                                background-color: var(--thirdspace-fill);
                              }
                              &.home-fill {
                                background-color: var(--home-fill);
                              }
                              &.office-fill {
                                background-color: var(--office-fill);
                              }
                              &.nonworking-fill {
                                background: var(--nonworking-fill);
                              }
                              &.notset-fill {
                                background-color: var(--notset-fill);
                              }
                              &.default {
                                background-color: var(--notset-fill);
                              }
                            }
                          }
                        }
                      }
                    }
                    .current-response-wrapper {
                      .answer {
                        &.full-day {
                          display: flex;
                          align-items: baseline;
                          .icon-wrapper {
                            width: 15px;
                            height: 15px;
                            border-radius: 4px;
                            img {
                              width: 10px;
                              height: 10px;
                            }
                            &.thirdspace-fill {
                              background-color: var(--thirdspace-fill);
                            }
                            &.home-fill {
                              background-color: var(--home-fill);
                            }
                            &.office-fill {
                              background-color: var(--office-fill);
                            }
                            &.nonworking-fill {
                              background: var(--nonworking-fill);
                            }
                            &.notset-fill {
                              background-color: var(--notset-fill);
                            }
                          }
                        }
                        &.split-day {
                          display: flex;
                          align-items: baseline;
                          flex-wrap: wrap;
                          .split-day-grid {
                            display: grid;
                            grid-template-columns: 20px 20px;
                            height: 20px;
                            align-items: center;
                            grid-gap: 0.5px;
                            .am-wrapper {
                              border-radius: 4px 0 0 4px;
                            }
                            .pm-wrapper {
                              border-radius: 0 4px 4px 0;
                            }
                            .slot-text {
                              font-size: 8px;
                            }
                            .slot-type {
                              .icon-wrapper {
                                width: 20px;
                                height: 20px;
                                img {
                                  width: 10px;
                                  height: 10px;
                                }
                              }
                              &.thirdspace-fill {
                                background-color: var(--thirdspace-fill);
                              }
                              &.home-fill {
                                background-color: var(--home-fill);
                              }
                              &.office-fill {
                                background-color: var(--office-fill);
                              }
                              &.nonworking-fill {
                                background: var(--nonworking-fill);
                              }
                              &.notset-fill {
                                background-color: var(--notset-fill);
                              }
                              &.default {
                                background-color: var(--notset-fill);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .mdc-text {
                  line-height: 14px;
                  font-weight: 600;
                }
                &::after {
                  content: '';
                  position: absolute;
                  width: 0;
                  height: 0;
                  border-top: 4px solid transparent;
                  border-bottom: 4px solid transparent;
                  border-left: 10px solid white;
                  right: 50%;
                  bottom: 100%;
                  margin: auto;
                  transform: rotate(270deg);
                }
              }
              .tooltip-text {
                opacity: 0;
                bottom: 100%;
                visibility: hidden;
                &.date-info {
                  min-width: 115px;
                }
              }
              &:hover {
                >.tooltip-text {
                  visibility: visible;
                  opacity: 1;
                }
                .other-info {
                  .icon-wrapper {
                    img {
                      filter: contrast(200%);
                    }
                  }
                }
              }
              &.pin-clicked {
                &:hover {
                  >.tooltip-text {
                    opacity: 0;
                    visibility: hidden;
                  }
                }
              }
              .other-info {
                position: absolute;
                bottom: 0;
                right: 0px;
                z-index: 10;
                .icon-wrapper {
                  width: 10px;
                  height: 10px;
                  img {
                    width: 100%;
                    height: 100%;
                    filter: contrast(0);
                  }
                }
              }
            }
          }
          &.animate {
            opacity: 1;
          }
        }
        .schedule-submitted-status {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 2px;
          margin-top: 2px;
          background: #F8F8F8;
          &:hover {
            +.confirmed-text {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        .confirmed-text {
          opacity: 0;
          bottom: 100%;
          right: 0;
          margin-right: -26px;
          visibility: hidden;
        }
        &:last-of-type {
          .responses-days-grid {
            > div {
              .today {
                box-shadow: 0 0 100px 0 #FFF6FA;
                &::before {
                  height: 100px;
                }
                &::after {
                  height: 100px;
                }
              }
            }
          }
        }
        @media all and (max-width: 1195px) {
          grid-template-columns: 120px auto 24px;
        }
      }
    }
  }
}
</style>
<style>
.aa-Panel {
  z-index:99999;
  font-family: "Nunito Sans", sans-serif;
  font-size:14px;
  width: max-content !important;
  max-width: 400px !important;
  min-width: 300px;
}
.schedule-search .ais-Hits-item {
  padding:0px !important;
}
.schedule-search .ais-Hits-item h4, .schedule-search .ais-Hits-item p {
  margin: 3px;
}
.schedule-search .ais-Hits-item, .ais-InfiniteHits-item {
  box-shadow: none;
}
</style>
<style lang="scss">
.schedule-search {
  #autocomplete-people {
    margin-left: auto !important;
    margin-right: auto !important;
    position: absolute;
    top: 5px;
    z-index: 19;
    margin: auto;
    right: 20px;
    .aa-Autocomplete {
      border-radius: 3px;
      border: 2px solid transparent;
      width: 300px;
      margin-left: auto;
      @media all and (max-width: 1115px) {
        width: 250px;
      }
      @media all and (max-width: 1010px) {
        width: 200px;
      }
      .aa-Form {
        border: 1px solid transparent;
        box-shadow: none;
        height: 40px;
        background: #F8F7F7;
        .aa-InputWrapperPrefix {
          display: none; /* search icon hide */
        }
        .aa-InputWrapperSuffix {
          .aa-ClearButton {
            padding-left: 0;
          }
        }
        .aa-InputWrapper {
          padding: 0 4px 0 16px;
          .aa-Input {
            font-size: 14px;
            &::placeholder {
              font-size: 14px;
              color: #BDBDBD;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
.aa-Panel {
  @media all and (max-width: 1115px) {
    min-width: 250px;
  }
  @media all and (max-width: 1010px) {
    min-width: 200px;
  }
  .aa-PanelLayout {
    padding: 0;
    max-height: 280px;
    .aa-Item {
      padding: 9px 15px;
      border-radius: 0;
      .aa-ItemIcon,
      .aa-ItemActions {
        display: none; /*icons hide */
      }
      .aa-ItemContentBody {
        * {
          font-size: 14px;
          color: #4175B1;
          font-family: 'Nunito Sans', sans-serif;
          font-weight: 600;
        }
      }
      &[aria-selected="true"] {
        background-color: #EBF3FC !important;
        .add-to-page {
          visibility: visible;
        }
      }
    }
  }
}
</style>
