<template>
  <div class="setup-schedules-wrapper" :class="from">
    <div class="setup-schedules-sections">
      <div class="close-setup-schedules" v-if="displayFor === 'page'">
        <mdc-icon-button  @click.native="closeSetupSection">clear</mdc-icon-button>
      </div>
      <div v-if="workspaceAdmin" class="workspace-set manage" >
        <mdc-text typo="body2" class="workspace-set-text">
          Manage the location schedule setup by workspace vs by team
        </mdc-text>

        <div class="workspace-set-switch">
          <mdc-text typo="body2" class="workspace-set-label">Team</mdc-text>
          <mdc-switch @change="saveScheduleSetByWorkspace({ schedulesSetByWorkspace: setByWorkspace })" v-model="setByWorkspace" class="switch-row">
          </mdc-switch>
          <mdc-text typo="body2" class="workspace-set-label">Workspace</mdc-text>
        </div>
      </div>
      <template>
        <section v-if="showSection === 'intro'" class="setup-intro-section schedules-section">
          <header class="intro-header section-header" v-if="displayFor === 'page'">
            <base-svg-icon name="settings-cog-red" />
            <h4>Automatically ask the team to submit their location</h4>
          </header>
          <div class="intro-content">
            <mdc-text typo="body1" class="about">
              DuoMe will automatically ask the team to submit where they plan to work each week. In three simple steps configure when to ask, how to confirm, and if you require people to attend the office a min or max number of days of each week.
            </mdc-text>
            <mdc-text typo="body1" class="about">
              As teams now work from different locations, sharing where they plan to work helps people plan the best days to be in the office.
            </mdc-text>
            <mdc-button v-if="workspaceAdmin || !workspace.schedulesSetByWorkspace" class="get-started-button" @click.native="getStartedClick()">
              <mdc-text tag="span">GET STARTED</mdc-text>
              <base-svg-icon :name="'bluegray-arrow-right'" />
            </mdc-button>
            <div v-else-if="workspace.schedulesSetByWorkspace && !workspaceAdmin" class="workspace-set admin-note padded">
              <div class="day-change-content notif-content">
                <mdc-text class="status" tag="span" typo="caption">Note</mdc-text>
                <mdc-text class="text" tag="span" typo="caption">This is set by your workspace admin</mdc-text>
              </div>
            </div>
          </div>
        </section>
        <section v-if="showSection === 'preferences'" class="setup-preferences-section schedules-section">
          <header class="preferences-header section-header" v-if="displayFor === 'page'">
            <base-svg-icon name="settings-cog-red" />
            <h4>Configure your team’s location schedule</h4>
          </header>
          <div v-if="workspace.schedulesSetByWorkspace && !workspaceAdmin" class="workspace-set admin-note">
            <div class="day-change-content notif-content">
              <mdc-text class="status" tag="span" typo="caption">Note</mdc-text>
              <mdc-text class="text" tag="span" typo="caption">This is set by your workspace admin</mdc-text>
            </div>
          </div>
          <section class="preferences-section what-schedule-wrapper">
            <div class="review-section" v-if="isSchedulesWhatSet && preferenceTypeSection !== '' || preferenceTypeSection === '' && reviewPreferences && isSchedulesWhatSet ">
              <div class="frequency review-answer">
                <mdc-icon icon="check_circle_outline" />
                <mdc-text typo="headline6" tag="span" class="number">1.</mdc-text>
                <mdc-text typo="subtitle1" tag="span" class="review-about">Ask the team's location:</mdc-text>
                <mdc-text typo="subtitle1" tag="span" class="answer">{{ getSchedulesFrequencyText() }}</mdc-text>
                <base-svg-icon v-if="editMode  && (workspaceAdmin || !workspace.schedulesSetByWorkspace)" name="pen-edit" clickable @click.native="setEditModeFor('what')" />
              </div>
              <div class="day review-answer">
                <mdc-text typo="subtitle1" tag="span" class="review-about">On:</mdc-text>
                <mdc-text typo="subtitle1" tag="span" class="answer">{{ schedulePreferences.day }}s</mdc-text>
              </div>
              <div class="time review-answer">
                <mdc-text typo="subtitle1" tag="span" class="review-about">At:</mdc-text>
                <mdc-text typo="subtitle1" tag="span" class="answer">{{ schedulePreferences.time }}</mdc-text>
              </div>
            </div>
            <div class="preference-type-content" v-else>
              <header class="question-wrapper what-schedule">
                <mdc-text tag="span" class="question">1. On what schedule do you want to ask people about their working location?</mdc-text>
                <mdc-text tag="span" class="question-about">This will ask your team to say when they plan to be in the office</mdc-text>
              </header>
              <template  v-if="preferenceTypeSection === 'what'">
                <div class="answers-options-wrapper">
                  <div class="answer-options frequency">
                    <mdc-radio :checked="schedulePreferences.frequency === 'weekBefore'" @change="setSchedulesFrequency('weekBefore')" :value="'week'" name="radio-freq" :label="'One week before'" class="radio-option" />
                    <mdc-radio :checked="schedulePreferences.frequency === 'twoWeeksBefore'" @change="setSchedulesFrequency('twoWeeksBefore')" :value="'twoWeeks'" name="radio-freq" :label="'Two weeks before'" class="radio-option" />
                  </div>
                  <div class="answer-options day">
                    <mdc-text tag="span" class="question">On which day?</mdc-text>
                    <template v-for="(day, index) in daysOptions">
                      <mdc-radio :checked="schedulePreferences.day === day"  @change="setSchedulesDay(day)" :key="day + index +'option'" :value="day" name="radio-day" :label="day" />
                    </template>
                  </div>
                  <div class="answer-options time">
                    <mdc-text tag="span" class="question">At what time ?</mdc-text>
                    <div class="time-slots">
                      <mdc-radio :checked="schedulePreferences.time === '09:00'" @change="setSchedulesTime('09:00')" :value="'09:00'" name="radio-time" :label="'Start of day (9:00)'" />
                      <mdc-radio :checked="schedulePreferences.time === '16:30'" @change="setSchedulesTime('16:30')" :value="'16:30'" name="radio-time" :label="'End of day (16:30)'" />
                      <mdc-radio :checked="schedulePreferences.time !== '' && schedulePreferences.time !== '09:00' && schedulePreferences.time !== '16:30'" @change="setSchedulesTime('09:01')" :value="'09:01'" name="radio-time" :label="'Custom time'" />
                      <input v-if="schedulePreferences.time !== '' && schedulePreferences.time !== '09:00' && schedulePreferences.time !== '16:30'" type="time" v-model="schedulePreferences.time" class="custom-time">
                    </div>
                  </div>
                  <base-svg-icon v-if="editMode && (workspaceAdmin || !workspace.schedulesSetByWorkspace)" name="pen-edit" clickable @click.native="setEditModeFor('when')" />
                </div>
                <div class="what-schedule-navigation navigate-button-wrapper">
                  <mdc-button class="section-button what-schedule-next-button" @click.native="gotoPreferenceTypeSection('when', 'what' )" v-bind:class="validateSchedulesPreferences('what') ? '' : 'disabled'">
                    <mdc-text class="section-button-label" typo="headline5" tag="span">NEXT</mdc-text>
                  </mdc-button>
                </div>
              </template>
            </div>
          </section>
          <section class="preferences-section confirm-schedules-wrapper">
            <div class="review-section" v-if="isSchedulesWhenSet && preferenceTypeSection !== '' || preferenceTypeSection === '' && reviewPreferences && isSchedulesWhenSet">
              <div class="review-answer">
                <mdc-icon icon="check_circle_outline" />
                <mdc-text typo="headline6" tag="span" class="number">2.</mdc-text>
                <mdc-text typo="subtitle1" tag="span" class="review-about">Confirm schedule on:</mdc-text>
                <mdc-text typo="subtitle1" tag="span" class="answer">{{ schedulePreferences.confirmWhenTimings.day }}s at {{ schedulePreferences.confirmWhenTimings.time }}</mdc-text>
                <base-svg-icon v-if="editMode  && (workspaceAdmin || !workspace.schedulesSetByWorkspace)" name="pen-edit" clickable @click.native="setEditModeFor('when')" />
              </div>
            </div>
            <div class="preference-type-content" v-else>
              <header class="question-wrapper when-schedule">
                <mdc-text tag="span" class="question">2. At what time do you want to confirm the schedule?</mdc-text>
                <mdc-text tag="span" class="question-about">This notifies people what the upcoming schedule is.</mdc-text>
              </header>
              <template  v-if="preferenceTypeSection === 'when'">
                <div class="answers-options-wrapper">
                  <div class="answer-options yes-confirm-wrapper">
                    <div class="when-to-confirm">
                      <div class="timing-content">
                        <div class="timing-select-options">
                          <mdc-select outlined v-model="schedulePreferences.confirmWhenTimings.day" label="Day">
                            <select-item v-for="(day, index) in daysOptions" :key="index" :value="day" />
                          </mdc-select>
                          <div class="time-input">
                            <input type="time" v-model="schedulePreferences.confirmWhenTimings.time" class="custom-time">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="confirm-schedule-navigation navigate-button-wrapper">
                  <mdc-button class="section-button confirm-schedule-back-button" @click.native="goBackToPreferenceTypeSection('what', 'when')">
                    <mdc-text class="section-button-label" typo="headline5" tag="span">BACK</mdc-text>
                  </mdc-button>
                  <mdc-button class="section-button confirm-schedule-next-button" @click.native="gotoPreferenceTypeSection('pattern', 'when' )" v-bind:class="validateSchedulesPreferences('when') ? '' : 'disabled' ">
                    <mdc-text class="section-button-label" typo="headline5" tag="span">NEXT</mdc-text>
                  </mdc-button>
                </div>
              </template>
            </div>
          </section>
          <section class="preferences-section pattern-schedule-wrapper">
            <div class="review-section" v-if="isSchedulesPatternSet && preferenceTypeSection !== '' || preferenceTypeSection === '' && reviewPreferences && isSchedulesPatternSet ">
              <div class="frequency review-answer">
                <mdc-icon icon="check_circle_outline" />
                <mdc-text typo="headline6" tag="span" class="number">3.</mdc-text>
                <mdc-text typo="subtitle1" tag="span" class="review-about">Request attend office</mdc-text>
                <template v-if="schedulePreferences.pattern.isOpted === 'no'">
                  <div class="no review-answer">
                    <mdc-text typo="subtitle1" tag="span" class="answer">None</mdc-text>
                  </div>
                </template>
                <base-svg-icon v-if="editMode  && (workspaceAdmin || !workspace.schedulesSetByWorkspace)" name="pen-edit" clickable @click.native="setEditModeFor('pattern')" />
              </div>
              <template v-if="schedulePreferences.pattern.isOpted === 'yes'">
                <div class="min review-answer" v-if="schedulePreferences.pattern.min">
                  <mdc-text typo="subtitle1" tag="span" class="review-about">min:</mdc-text>
                  <mdc-text typo="subtitle1" tag="span" class="answer">{{ schedulePreferences.pattern.min }} {{ schedulePreferences.pattern.min !== 'None' ? 'days' : '' }} </mdc-text>
                </div>
                <div class="max review-answer" v-if="schedulePreferences.pattern.max">
                  <mdc-text typo="subtitle1" tag="span" class="review-about">And max:</mdc-text>
                  <mdc-text typo="subtitle1" tag="span" class="answer">{{ schedulePreferences.pattern.max }} {{ schedulePreferences.pattern.max !== 'None' ? 'days' : '' }}</mdc-text>
                </div>
              </template>
            </div>
            <div class="preference-type-content" v-else>
              <header class="question-wrapper what-schedule">
                <mdc-text tag="span" class="question">3. Do you request the team attend office based on a pattern?</mdc-text>
                <mdc-text tag="span" class="question-about">This will recommend the team submit an amount of office days each week</mdc-text>
              </header>
              <template  v-if="preferenceTypeSection === 'pattern'">
                <div class="answers-options-wrapper">
                  <div class="answer-options yes-confirm-wrapper">
                    <mdc-radio :checked="schedulePreferences.pattern && schedulePreferences.pattern.isOpted === 'yes'" @change="setPatternOpted('yes')" :value="'yes'" name="radio-pattern" :label="'Yes - Require team members submit office days:'" />
                    <div class="pattern-to-confirm">
                      <div class="days-select-options" v-if="schedulePreferences.pattern && schedulePreferences.pattern.isOpted === 'yes'">
                        <mdc-select outlined v-model="schedulePreferences.pattern.min" label="Min">
                          <select-item v-for="(day, index) in patternDays" :key="index" :value="day" />
                        </mdc-select>
                        <mdc-select outlined v-model="schedulePreferences.pattern.max" label="Max">
                          <select-item v-for="(day, index) in patternDays" :key="index" :value="day" />
                        </mdc-select>
                      </div>
                    </div>
                  </div>
                  <div class="answer-options no-confirm-wrapper">
                    <mdc-radio :checked="schedulePreferences.pattern && schedulePreferences.pattern.isOpted === 'no'" @change="setPatternOpted('no')" :value="'no'" name="radio-pattern" :label="'No - Do not require a pattern of office days'" />
                    <mdc-text tag="span" class="answer-about">Users will be able to submit any number of days per week</mdc-text>
                  </div>
                </div>
                <div class="confirm-schedule-navigation navigate-button-wrapper">
                  <mdc-button class="section-button pattern-schedule-back-button" @click.native="goBackToPreferenceTypeSection('when', 'pattern')">
                    <mdc-text class="section-button-label" typo="headline5" tag="span">BACK</mdc-text>
                  </mdc-button>
                  <mdc-button class="section-button pattern-schedule-next-button" @click.native="gotoReviewAllPreferencesSection('reviewSection', 'pattern' )" v-bind:class="validateSchedulesPreferences('pattern') ? '' : 'disabled' ">
                    <mdc-text class="section-button-label" typo="headline5" tag="span">REVIEW YOUR SETTINGS</mdc-text>
                  </mdc-button>
                </div>
              </template>
            </div>
          </section>
          <section class="all-preferences-submit navigate-button-wrapper" v-if="isSchedulesWhenSet && isSchedulesWhatSet && reviewPreferences && preferenceTypeSection === ''">
            <mdc-button v-if="!editMode && (((from === 'teamAdmin' || from === 'scheduleSearch') && !workspace.schedulesSetByWorkspace) || ((from === 'workspaceAdmin' || from === 'scheduleSearch') && workspaceAdmin))" class="section-button review-back-button" @click.native="goBackToPreferenceTypeSection('pattern', 'reviewSection')">
              <mdc-text class="section-button-label" typo="headline5" tag="span">BACK</mdc-text>
            </mdc-button>
            <mdc-button v-if="((from === 'teamAdmin' || from === 'scheduleSearch') && !workspace.schedulesSetByWorkspace) || ((from === 'workspaceAdmin' || from === 'scheduleSearch') && workspaceAdmin)" class="section-button review-save-button" @click.native="saveSchedulesPreferences()">
              <mdc-text class="section-button-label" typo="headline5" tag="span">{{ !editMode ? 'SAVE PREFERENCES' : 'EDIT PREFERENCES' }}</mdc-text>
            </mdc-button>
          </section>
        </section>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon'
import { getTimeslotsBetween } from '../../utils/time.js'

export default {
  name: 'SetupSchedules',
  components: {
    BaseSvgIcon
  },
  data () {
    return {
      setByWorkspace: false,
      isSchedulesWhatSet: false,
      isSchedulesWhenSet: false,
      isSchedulesPatternSet: false,
      reviewPreferences: false,
      showSection: '',
      preferenceTypeSection: 'what',
      schedulePreferences: {
        frequency: 'weekBefore',
        day: 'Monday',
        time: '09:00',
        type: 'schedules',
        name: 'Team schedules',
        active: true,
        preferencesSubmitted: false,
        id: false,
        confirm: 'yes',
        confirmWhen: ['timing'],
        confirmWhenTimings: {
          day: 'Friday',
          time: '09:00'
        },
        pattern: {
          isOpted: 'no',
          min: '',
          max: ''
        }
      },
      daysOptions: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      timeslots: [],
      patternDays: [
        'None',
        '1',
        '2',
        '3',
        '4',
        '5'
      ],
      editMode: false
    }
  },
  props: {
    displayFor: {
      type: String,
      default: ''
    },
    workspaceAdmin: {
      type: Boolean,
      default: false
    },
    from: {
      type: String,
      default: ''
    }
  },
  created () {
  },
  beforeDestroy () {},
  mounted () {
    this.timeslots = this.getTimeslots()
    this.getSetupDetailsFromTempo()
  },
  watch: {
    tempos: {
      handler (newTempos, oldTempos) {
        this.getSetupDetailsFromTempo()
      }
    },
    workspace: {
      handler (newWS, oldWS) {
        if (newWS) {
          if (oldWS) {
            if (newWS.schedulesSetByWorkspace !== oldWS.schedulesSetByWorkspace) {
              this.setByWorkspace = newWS.schedulesSetByWorkspace
            }
          } else {
            this.setByWorkspace = newWS.schedulesSetByWorkspace
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      workspace: state => state.workspace,
      teamProfiles: state => state.teams.teamProfiles,
      profile: state => state.profile,
      tempos: state => state.teams.tempos
    })
  },
  methods: {
    ...mapActions({
      saveTempo: 'teams/saveTempo',
      saveWorkspaceTempo: 'teams/saveWorkspaceTempo',
      saveScheduleSetByWorkspace: 'saveScheduleSetByWorkspace'
    }),
    initialize () {
      if (this.schedulePreferences.preferencesSubmitted) {
        this.showSection = 'preferences'
        this.reviewPreferences = true
        this.isSchedulesWhatSet = true
        this.isSchedulesWhenSet = true
        this.isSchedulesPatternSet = true
        this.preferenceTypeSection = ''
        this.editMode = true
      } else {
        this.showSection = 'intro'
      }
    },
    validateSchedulesPreferences (sectionName) {
      switch (sectionName) {
        case 'what':
          if (this.schedulePreferences.frequency && this.schedulePreferences.day && this.schedulePreferences.time) {
            return true
          }
          return false
        case 'when':
          if (this.schedulePreferences.confirm) {
            if (this.schedulePreferences.confirm === 'yes') {
              if (this.schedulePreferences.confirmWhen.length > 0) {
                if (this.schedulePreferences.confirmWhen.includes('timing')) {
                  if (this.schedulePreferences.confirmWhenTimings.day && this.schedulePreferences.confirmWhenTimings.time) {
                    return this.isBefore(this.schedulePreferences.day, this.schedulePreferences.time, this.schedulePreferences.confirmWhenTimings.day, this.schedulePreferences.confirmWhenTimings.time)
                  }
                  return false
                }
                return true
              }
              return false
            }
            return true
          }
          return false
        case 'pattern':
          if (this.schedulePreferences.pattern) {
            if (this.schedulePreferences.pattern.isOpted) {
              if (this.schedulePreferences.pattern.isOpted === 'yes') {
                if (this.schedulePreferences.pattern.min && this.schedulePreferences.pattern.max) {
                  return true
                }
                return false
              }
              return true
            }
          }
          return false
        default:
          return false
      }
    },
    getSchedulesConfirmText () {
      // to revisit the text
      switch (this.schedulePreferences.confirm) {
        case 'yes':
          if (this.schedulePreferences.confirmWhen.length > 0) {
            if (this.schedulePreferences.confirmWhen.length === 1) {
              switch (this.schedulePreferences.confirmWhen[0]) {
                case 'allSchedules':
                  return 'All have been submitted'
                case 'timing':
                  return 'Specific time'
                default:
                  return ''
              }
            }
            if (this.schedulePreferences.confirmWhen.length === 2) {
              return 'All have been submitted at specific time'
            }
          }
          return ''
        case 'no':
          return 'No notification confirmed' // need changes
        default:
          return ''
      }
    },
    isBefore (d1, t1, d2, t2) {
      if (this.daysOptions.indexOf(d1) < this.daysOptions.indexOf(d2)) {
        return true
      } else if (this.daysOptions.indexOf(d1) > this.daysOptions.indexOf(d2)) {
        return false
      } else {
        return t1 < t2
      }
    },
    getSchedulesFrequencyText () {
      switch (this.schedulePreferences.frequency) {
        case 'weekBefore':
          return 'One week before'
        case 'twoWeeksBefore':
          return 'Two weeks before'
        default:
          return ''
      }
    },
    saveSchedulesPreferences () {
      if (this.editMode) {
        this.editMode = false
        this.showSection = 'preferences'
        this.reviewPreferences = false
        this.isSchedulesWhatSet = false
        this.isSchedulesWhenSet = false
        this.isSchedulesPatternSet = false
        this.preferenceTypeSection = 'what'
      } else {
        if (this.workspaceAdmin) {
          this.schedulePreferences.preferencesSubmitted = true
          this.schedulePreferences.name = 'Workspace Schedules'
          this.saveWorkspaceTempo({ tempo: this.schedulePreferences }).then((response) => {
            this.reviewPreferences = false
            this.initialize()
          })
        } else {
          this.schedulePreferences.preferencesSubmitted = true
          this.saveTempo({ teamId: this.profile.team, tempoId: this.schedulePreferences.id, tempo: this.schedulePreferences }).then((response) => {
            this.reviewPreferences = false
            if (this.from === 'teamAdmin') {
              this.initialize()
            }
            this.closeSetupSection()
          })
        }
      }
    },
    resetPreferencesSetSection (sectionName) {
      switch (sectionName) {
        case 'what':
          if (this.isSchedulesWhatSet) {
            this.isSchedulesWhatSet = false
          }
          break
        case 'when':
          if (this.isSchedulesWhenSet) {
            this.isSchedulesWhenSet = false
          }
          break
        case 'pattern':
          if (this.isSchedulesPatternSet) {
            this.isSchedulesPatternSet = false
          }
          break
        default:
          break
      }
    },
    setEditModeFor (sectionName) {
      this.preferenceTypeSection = sectionName
      this.reviewPreferences = false
      switch (sectionName) {
        case 'what':
          this.isSchedulesWhatSet = false
          this.isSchedulesWhenSet = false
          this.isSchedulesPatternSet = false
          break
        case 'when':
          this.isSchedulesWhenSet = false
          this.isSchedulesPatternSet = false
          break
        case 'pattern':
          this.isSchedulesPatternSet = false
          break
        default:
          break
      }
    },
    checkPreferenceTypeSet (sectionName) {
      switch (sectionName) {
        case 'what':
          if (this.validateSchedulesPreferences(sectionName)) {
            this.isSchedulesWhatSet = true
            return true
          }
          return false
        case 'when':
          if (this.validateSchedulesPreferences(sectionName)) {
            this.isSchedulesWhenSet = true
            return true
          }
          return false
        case 'pattern':
          if (this.validateSchedulesPreferences(sectionName)) {
            this.isSchedulesPatternSet = true
            return true
          }
          return false
        default:
          return false
      }
    },
    gotoReviewAllPreferencesSection (toSectionName, fromSectionName) {
      if (this.checkPreferenceTypeSet(fromSectionName)) {
        this.reviewPreferences = true
        this.preferenceTypeSection = ''
      }
      if (this.editMode) {
        this.editMode = false
      }
    },
    goBackToPreferenceTypeSection (toSectionName, fromSectionName) {
      if (this.reviewPreferences) {
        this.reviewPreferences = false
      }
      this.resetPreferencesSetSection(toSectionName)
      this.preferenceTypeSection = toSectionName
    },
    gotoPreferenceTypeSection (toSectionName, fromSectionName) {
      if (this.checkPreferenceTypeSet(fromSectionName)) {
        this.preferenceTypeSection = toSectionName
      }
    },
    getStartedClick () {
      this.showSection = 'preferences'
    },
    getSetupDetailsFromTempo () {
      if (this.tempos.length > 0 && this.profile) {
        let preferences = []
        if (this.workspaceAdmin || this.workspace.schedulesSetByWorkspace) {
          preferences = this.tempos.filter(tempo => tempo.id === 'workspace-schedules')
        } else {
          preferences = this.tempos.filter(tempo => tempo.type === 'schedules' && tempo.teamId === this.profile.team)
        }
        if (preferences.length > 0) {
          this.schedulePreferences = preferences[0]
          // For existing no confirm
          if (this.schedulePreferences.confirm !== 'yes') {
            this.schedulePreferences.confirm = 'yes'
          }
          if (!this.schedulePreferences.confirmWhen.includes('timing')) {
            this.schedulePreferences.confirmWhen.push('timing')
          }
          if (!this.schedulePreferences.confirmWhenTimings || !this.schedulePreferences.confirmWhenTimings.day) {
            this.schedulePreferences.confirmWhenTimings = {
              day: 'Friday',
              time: '13:00'
            }
          }
        }
      }
      this.initialize()
    },
    closeSetupSection () {
      this.$emit('closeSetupSchedules', false)
    },
    setSchedulesFrequency (frequency) {
      this.schedulePreferences.frequency = frequency
    },
    setSchedulesDay (day) {
      this.schedulePreferences.day = day
    },
    setSchedulesTime (time) {
      this.schedulePreferences.time = time
    },
    setConfirmSchedules (option) {
      this.schedulePreferences.confirm = option
      if (option === 'no') {
        this.schedulePreferences.confirmWhen = []
        this.schedulePreferences.confirmWhenTimings = {}
      }
    },
    setPatternOpted (option) {
      // console.log('setupSchedules.setPatternOpted', this.schedulePreferences.id, option)
      this.schedulePreferences.pattern.isOpted = option
      if (option === 'no') {
        this.schedulePreferences.pattern.min = ''
        this.schedulePreferences.pattern.max = ''
      }
    },
    toggleConfirmWhen (option) {
      if (this.schedulePreferences.confirmWhen.length > 0) {
        const optionAtIndex = this.schedulePreferences.confirmWhen.findIndex(confirmOption => confirmOption === option)
        if (optionAtIndex !== -1) {
          this.schedulePreferences.confirmWhen.splice(optionAtIndex, 1)
          if (option === 'timing') {
            this.schedulePreferences.confirmWhenTimings = {}
          }
        } else {
          this.schedulePreferences.confirmWhen.push(option)
        }
        return
      }
      this.schedulePreferences.confirmWhen.push(option)
      this.schedulePreferences.confirm = 'yes'
    },
    checkConfirmWhen (option) {
      const optionAtIndex = this.schedulePreferences.confirmWhen.findIndex(confirmOption => confirmOption === option)
      if (optionAtIndex !== -1) {
        return true
      } else {
        return false
      }
    },
    getTimeslots () {
      const start = '09:30'
      const end = '16:30'
      const timeslots = getTimeslotsBetween(start, end, 30, 'm').map(a => a.startTime)
      return timeslots
    }
  }
}
</script>

<style lang="scss">
.setup-schedules-wrapper:not(:empty) {
  margin-top: 30px;
  min-height: 600px;
  &.teamAdmin,
  &.workspaceAdmin {
    .setup-schedules-sections {
      .schedules-section {
        margin: 0;
      }
    }
  }
  .setup-schedules-sections {
    .close-setup-schedules {
      display: flex;
      justify-content: flex-end;
      margin: 0 20px 0 0;
    }
    .navigate-button-wrapper {
      margin: 25px 0 25px 0;
      display: flex;
      justify-content: space-between;
      .section-button {
        padding: 0;
        height: 20px;
        .section-button-label {
          color: #00498D;
          font-size: 18px;
          font-weight: 700;
        }
        &.disabled {
          pointer-events: none;
          background-color: transparent;
          .section-button-label {
            color: #828282;
          }
          .mdc-text {
            color: #828282;
          }
        }
      }
      &.what-schedule-navigation {
        justify-content: flex-end;
      }
      &.all-preferences-submit {
        padding: 25px 40px 25px 40px;
      }
    }
    .workspace-set {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-bottom:20px;
      &.manage {
        margin: 0 40px;
      }
      &.admin-note {
        justify-content: center;
        margin-top: -20px;
        .status {
          background: #FFE973;
          font-weight: 800;
          font-size: 9px;
          color: #000;
          margin-right: 5px;
          border-radius: 12px;
          text-align: center;
          line-height: 14px;
          padding-left: 4px;
          padding-right: 4px;
        }
        .text {
          color: #000;
          font-weight: 700;
        }
      }
      &.padded {
        margin-top:10px;
        .status {
          font-size: 12px;
          padding: 2px;
          padding-left: 8px;
          padding-right: 8px;
          color: #707880;
        }
        .text {
          color: #959EA7;
          font-size: 14px;
          font-weight: 100;
        }
      }
      .workspace-set-text {
        flex:1;
        color:#959EA7;
      }
      .workspace-set-switch {
        display: flex;
        flex-flow: row;
        align-items: center;
        .workspace-set-label {
          margin:10px;
          padding:0;
          color:#959EA7;
        }
      }
    }
    .schedules-section {
      min-height: 400px;
      margin: 0 40px;
      header {
        &.section-header {
          display: flex;
          align-items: baseline;
          color: #959EA7;
          font-size: 24px;
          justify-content: center;
          margin-bottom: 40px;
          h4 {
            margin: 0;
            font-weight: 700;
            margin-left: 5px;
          }
        }
      }
      &.setup-intro-section {
        .intro-content {
          margin: 50px 50px 50px 30px;
          display: flex;
          flex-direction: column;
          .about {
            color: #959EA7;
            font-size: 18px;
            margin-top: 0;
          }
        }
        .get-started-button {
          display: flex;
          align-items: center;
          color: #00498D;
          padding: 0;
          margin-top: 40px;
          .mdc-text {
            font-size: 24px;
            margin-right: 5px;
          }
        }
        .intro-icons {
          display: flex;
          align-items: center;
          margin-top: 25px;
          margin-left: 50px;
          span {
            display: block;
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background: linear-gradient(166.81deg, #B4BCC4 -14.51%, #919AA2 98.26%);
            margin-right: 20px;
          }
        }
      }
      &.setup-preferences-section {
        padding-bottom: 100px;
        .preferences-section:not(:empty) {
          padding: 15px 0;
          border-bottom: 0.5px solid #979797;
          .preference-type-content {
            .question-wrapper {
              display: flex;
              flex-direction: column;
              .question {
                color: #959EA7;
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 5px;
              }
              .question-about {
                color: #959EA7;
              }
            }
            .answers-options-wrapper {
                display: flex;
                flex-direction: column;
              .answer-options {
                display: flex;
                flex-direction: column;
                padding: 15px 0;
                border-bottom: 0.5px dashed #979797;
                &.time {
                  .custom-time {
                    border-radius: 6px;
                    border: 1px solid silver;
                    padding: 5px;
                    max-width: 105px;
                    margin-left: 50px;
                  }
                }
                .mdc-form-field {
                  &.mdc-radio-wrapper {
                    margin-left: 20px;
                    label {
                      color: #959EA7;
                      font-size: 16px;
                      font-weight: 700;
                    }
                  }
                  .mdc-radio {
                    height: 16px;
                    width: 16px;
                    padding: 10px 10px 10px 0;
                    &__background {
                      height: 16px;
                      width: 16px;
                    }
                    &__outer-circle {
                      border: 1px solid rgba(149, 158, 167, 0.9);
                    }
                    &__inner-circle{
                      border-width: 8px;
                    }
                  }
                  .mdc-checkbox {
                    width: 15px;
                    height: 15px;
                    --mdc-ripple-fg-size: 0px !important;
                    --mdc-ripple-fg-scale: 0 !important;
                    --mdc-ripple-left: 0 !important;
                    --mdc-ripple-top: 0 !important;
                    &__background {
                      width: 15px;
                      height: 15px;
                      border-color:rgba(149, 158, 167, 0.9);
                      background: white !important;
                      transition: none;
                      border-width: 1px;
                    }
                    &__native-control {
                      &:enabled {
                        border-color: rgba(149, 158, 167, 0.9);
                        &:checked~.mdc-checkbox__background {
                          .mdc-checkbox__checkmark {
                            color: #cc0000;
                            border-color: rgba(149, 158, 167, 0.9);
                          }
                        }
                      }
                    }
                  }
                }
                .mdc-select {
                  min-width: 140px;
                  max-width: 140px;
                  margin-right: 20px;
                  margin-left: 20px;
                  &__anchor {
                    .mdc-notched-outline {
                      &__notch {
                        .mdc-floating-label {
                          color: #4f4f4f;
                          font-weight: 600;
                          font-size: 14px;
                          background: transparent;
                        }
                      }
                    }
                  }
                  &__selected-text {
                    color: #404040;
                    font-weight: 600;
                    font-size: 14px;
                  }
                }
                .question {
                  color: #959EA7;
                  font-size: 18px;
                  font-weight: 600;
                  padding-bottom: 8px;
                }
                .answer-about {
                  color: #959EA7;
                  margin-left: 50px;
                }
              }
            }
          }
          &.pattern-schedule-wrapper {
            .answer-options {
              &.yes-confirm-wrapper {
                border-bottom: none;
                padding-bottom: 0;
              }
            }
          }
          &.what-schedule-wrapper {
            padding-top: 0;
          }
          &.confirm-schedules-wrapper {
            .answer-options {
              &.yes-confirm-wrapper {
                border-bottom: none;
                padding-bottom: 0;
              }
            }
            .when-to-confirm {
              display: flex;
              flex-direction: column;
              .timing-content {
                display: flex;
                flex-direction: column;
                .timing-select-options {
                  margin-left: 80px;
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  .time-input {
                    height: 56px;
                    margin-top: 12px;
                    display: flex;
                    input {
                      border: 0.5px solid rgba(0, 0, 0, 0.25);
                      border-radius: 4px;
                      padding: 5px;
                    }
                  }
                }
              }
            }
            .confirm-when-checkbox {
              .mdc-checkbox {
                margin-left: 40px;
              }
              label {
                color: #959EA7;
                font-weight: 600;
                font-size: 14px;
              }
              &.admin {
                opacity: 0.6;
                pointer-events: none;
              }
            }
          }
          .review-section {
            color: #959EA7;
            display: flex;
            flex-direction: column;
            .mdc-icon {
              color: #01CB52;
            }
            .review-answer {
              display: flex;
              align-items: center;
              position: relative;
              &.day {
                margin-left: 50px;
              }
              &.time {
                margin-left: 50px;
              }
              &.schedule {
                margin-left: 50px;
              }
              &.min {
                margin-left: 50px;
              }
              &.max {
                margin-left: 50px;
              }
              .icon-wrapper {
                position: absolute;
                right: 0;
                img {
                  margin: unset;
                }
              }
            }
            .review-about {
              flex: 1;
              max-width: 185px;
              color: #959EA7;
            }
            .number {
              margin: 0 5px;
            }
            .answer {
              font-weight: 600;
              color: #01CB52;
            }
          }
        }
      }
    }
  }
}
</style>
