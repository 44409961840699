<template>
  <div class="note-page" v-bind:class="{ 'fullscreen-mode': expandedView }">
    <div class="close-page">
      <mdc-icon-button  @click.native="closeNotesPage">clear</mdc-icon-button>
    </div>

    <tiptap-menu-dialog
    :formatIconPosition="formatIconPosition"
    @closeFormatControlMenu="closeFormatControlMenu"
    :showFormatControlsMenu="openFormatControls && (composerView === 'notesPage')"
    :editor="composerEditor"
    noteBlocksEnabled
    />
    <tiptap-settings-dialog
      v-if="!externalMenu"
      :settingsIconPosition="settingsIconPosition"
      @closeSettingsMenu="closeSettingsMenu"
      :showSettingsMenu="openSettings && composerView === 'notesPage'"
      :editor="composerEditor"
      noteBlocksEnabled />
    <tiptap-editor
      class="notepage-tiptap heading"
      :content='noteLabel'
      externalMenu
      emitEnter @editorEnter="preventEnterInHeading"
      @onTextUpdate="saveNotePageHeading"
      :title="'notePageHeading'"
      :contentKey="'idx-' + noteLabel">
    </tiptap-editor>
    <share-menu
      class="page-share-menu"
      :menuForPageId="notePage.id"
      :item="notePage"
      :showNoteBlockMenu="showSharingMenu"
      @closeNoteBlockMenu="closeNoteBlockMenu"
      @setShare="setShare"
      @removeShare="removeShare"
      @setShareEnabled="setShareEnabled"
    />
    <span class="page-sharing-icon" @click="togglePageSharingMenu()">
      <img src="/static/img/icons/flexibly/sharing-icon.svg" />
    </span>
    <tiptap-editor
        :disableNoteBlockSharing="disableNoteBlockSharing"
        v-if="accessToken"
        class="notepage-tiptap page-body"
        :doc="notePage.pageLink"
        :docState="notePage.docState"
        :noteBlocks="notePage.noteBlocks"
        @editorReady="composerEditor = $event"
        :hashtags="initiativeTags"
        :user="user"
        :mentionSuggestions="teamMentions"
        content=''
        :title="'notePageBody'"
        externalMenu
        :accessToken="accessToken"
        :workspaceId="workspace.id"
        useWebSocketProvider
        @onPageLinkCreate="onNotePageCreate"
        @onTextUpdate="notePageUpdate"
        usePageLinkSuggestions
        :pagelinks="pageLinks"
        noteBlocksEnabled
        addDefaultNoteBlock
        :teamId="profile.team"
      ></tiptap-editor>
      <section v-if="isEmpty && enableTemplates" class="template-selection">
        <mdc-text class="title" typo="body2">SELECT A TEMPLATE</mdc-text>
        <div class="template-options">
          <mdc-text class="option" typo="body2">Meeting template</mdc-text>
          <mdc-text class="option" typo="body2">Personal note</mdc-text>
          <mdc-text class="option" typo="body2">Multi-player note</mdc-text>
        </div>
      </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as Y from 'yjs'
import TiptapMenuDialog from '../../components/flexibly/TiptapMenuDialog.vue'
import TiptapSettingsDialog from '../../components/flexibly/TiptapSettingsDialog.vue'
import ShareMenu from '../../components/flexibly/ShareMenu.vue'

export default {
  name: 'NotePage',
  components: {
    TiptapEditor: () => import('../../components/Tiptap'),
    TiptapMenuDialog,
    TiptapSettingsDialog,
    ShareMenu
  },
  data () {
    return {
      composerEditor: null,
      debouncedNoteHeadingUpdate: null,
      debouncedNotePageUpdate: null,
      isEmpty: false,
      showSharingMenu: false,
      accessToken: false
    }
  },
  props: {
    enableTemplates: {
      type: Boolean,
      default: false
    },
    formatIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    settingsIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disableNoteBlockSharing: {
      type: Boolean,
      default: true
    },
    openFormatControls: {
      type: Boolean,
      default: false
    },
    openSettings: {
      type: Boolean,
      default: false
    },
    composerView: {
      type: String,
      default: ''
    },
    expandedView: {
      type: Boolean,
      default: false
    },
    teamMentions: {
      type: Array,
      default: () => {
        return []
      }
    },
    notePage: {
      type: Object,
      default: () => {
        return {}
      }
    },
    noteLabel: {
      type: String,
      default: ''
    },
    externalMenu: {
      type: Boolean,
      default: false
    }
  },
  created () {
  },
  beforeDestroy () {},
  async mounted () {
    this.accessToken = await this.user.getIdToken(true)
    if (this.notePage) {
      if (this.notePage.blocks.length > 0) {
        this.isEmpty = false
      } else {
        this.isEmpty = true
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'workspace',
      'profile'
    ]),
    ...mapState({
      personalInitiatives: state => state.teams.personalInitiatives,
      notePages: state => state.teams.notePages,
      teams: state => state.teams.teams
    }),
    pageLinks () {
      return this.notePages
    },
    initiativeTags () {
      let tags = []
      for (const init of this.personalInitiatives) {
        if (init.teamId !== this.profile.team) {
          const tagName = `${this.getTeamName(init.teamId)}${init.name.replace(' ', '-')}`
          tags.push({ ...init, tagName })
        } else {
          const tagName = `${init.name.replace(' ', '-')}`
          tags.push({ ...init, tagName })
        }
      }
      tags = tags.sort((a, b) => a.tagName.localeCompare(b.tagName))
      return tags
    }
  },
  methods: {
    ...mapActions({
      getNotePage: 'teams/getNotePage',
      updateNotePage: 'teams/updateNotePage'
    }),
    getTeamName (teamId) {
      if (teamId !== this.profile.team) {
        if (this.teams) {
          const ft = this.teams.filter((t) => {
            return t.id === teamId
          })[0] || {}
          return `${ft.name || ''}/`
        }
      }
      return ''
    },
    setShare (selected, item) {
      // console.log('NotePage.setShare', selected, item)
      if (item.type === 'tag') {
        this.notePage.shareInitiatives = [item.id]
        this.notePage.notePageType = 'initiative'
        this.notePage.initiativeId = item.id
      }
      if (item.type === 'user') {
        if (!this.notePage.shareUsers) {
          this.notePage.shareUsers = []
        }
        this.notePage.shareUsers = [...this.notePage.shareUsers, item.id]
      }
      const notePageUpdate = { ...this.notePage }
      this.updateNotePage({ teamId: this.profile.team, notePageId: this.notePage.id, notePage: notePageUpdate })
    },
    removeShare (type, itemId) {
      // console.log('NotePage.removeShare', type, itemId, this.notePage.shareInitiatives, this.notePage.shareUsers)
      if (type === 'tag') {
        this.notePage.shareInitiatives = this.notePage.shareInitiatives.filter((i) => { return i !== itemId })
      }
      if (type === 'user') {
        const filteredUsers = this.notePage.shareUsers.filter((u) => {
          return u !== itemId
        })
        this.notePage.shareUsers = filteredUsers
      }
      const notePageUpdate = { ...this.notePage }
      this.updateNotePage({ teamId: this.profile.team, notePageId: this.notePage.id, notePage: notePageUpdate })
    },
    setShareEnabled  (value) {
      // console.log('NotePage.setShareEnabled', value)
      this.notePage.shareEnabled = value
      const notePageUpdate = { ...this.notePage }
      this.updateNotePage({ teamId: this.profile.team, notePageId: this.notePage.id, notePage: notePageUpdate })
    },
    closeSharingMenu (value) {
      this.showSharingMenu = value
    },
    toggleSharingMenu (noteId) {
      this.showSharingMenu = !this.showSharingMenu
    },
    closeNotesPage () {
      this.$root.$emit('changeComposerView', 'default', 'COMPOSER')
    },
    closeFormatControlMenu (value) {
      this.$emit('closeFormatControlsMenu', value)
    },
    closeSettingsMenu (value) {
      this.$emit('closeSettingsMenu', value)
    },
    togglePageSharingMenu () {
      this.showSharingMenu = !this.showSharingMenu
    },
    closeNoteBlockMenu (value) {
      this.showSharingMenu = value
    },
    onNotePageCreate ({ link }) {
      this.getNotePage({ teamId: this.profile.team, notePageName: link.label, notePageLink: link.link })
    },
    saveNotePageUpdate (notePageId, html, json, doc) {
      let docState
      const notePage = { html, json }
      if (doc) {
        docState = Y.encodeStateAsUpdate(doc)
        // console.log('Composer.saveNotePageUpdate.docState', docState)
        notePage.docState = docState
      }
      this.updateNotePage({ teamId: this.profile.team, notePageId, notePage })
    },
    notePageUpdate (text, key) {
      const self = this
      if (text) {
        if (text !== '<p></p>' && text !== '') {
          this.isEmpty = false
        } else {
          this.isEmpty = true
        }
      }
      if (this.debouncedNotePageUpdate) {
        clearTimeout(this.debouncedNotePageUpdate)
      }
      this.debouncedNotePageUpdate = setTimeout(() => {
        const jsonContent = self.composerEditor.getJSON()
        const htmlContent = text
        const doc = self.composerEditor.options.ydoc
        // console.log('Composer.notePageUpdate', this.notePage.id, htmlContent, jsonContent, doc, this.composerEditor, this.notePage)
        self.saveNotePageUpdate(this.notePage.id, htmlContent, jsonContent, doc)
      }, 1000)
    },
    preventEnterInHeading () {
      return false
    },
    saveNotePageHeading (text) {
      const self = this
      if (this.debouncedNoteHeadingUpdate) {
        clearTimeout(this.debouncedNoteHeadingUpdate)
      }
      this.debouncedNoteHeadingUpdate = setTimeout(() => {
        const htmlContent = text
        const d = document.createElement('div')
        d.innerHTML = htmlContent
        const convertedText = d.innerText
        self.notePage.pageName = convertedText
        // console.log('Composer.notePageUpdate', self.notePage.id, htmlContent, self.composerEditor, self.notePage)
        self.updateNotePage({ teamId: this.profile.team, notePageId: self.notePage.id, notePage: self.notePage })
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.note-page {
  padding: 30px 30px 30px 35px;
  position: relative;
  .close-page {
    float: right;
    position: relative;
    bottom: 20px;
  }
  .userlist {
    display: none;
  }
  .menubar {
    justify-content: center;
  }
  .page-sharing-icon {
    position: absolute;
    z-index: 10;
    left: 8px;
    margin-top: -48px;
    display: block;
    top: 105px;
    padding: 5px;
    cursor: pointer;
  }
  .notepage-tiptap {
    &.heading {
      display: block;
      &:focus-within {
        + .page-sharing-icon {
          display: block;
        }
      }
      &:hover {
        + .page-sharing-icon {
          display: block;
        }
      }
      .editor__content {
        overflow: visible;
        p {
          font-style: normal;
          font-size: 25px;
          font-weight: bold;
          color: #4F4F4F;
          &.is-empty:first-child::before {
            content: 'Untitled Page';
            color: #C8C8C8;
            font-style: normal;
          }
          &::after {
            content: ''
          }
        }
      }
    }
    &.page-body {
      min-height: 90px;
      margin-top: 25px;
      .editor__content {
        overflow: visible;
        p {
          font-size: 14px;
          color: #333333;
          line-height: 23px;
          &.is-empty:first-child:only-child::before {
            content: 'Start typing or type / for commands';
            color: #C3C2C0;
            font-style: normal;
          }
          &::after {
            content: '';
          }
          .hashtag {
            margin-bottom: 0;
            height: 15px;
            font-size: 14px;
            flex: unset;
            display: inline-flex;
            padding: 1px 2px;
            color: var(--hashtagtext-color);
            position: relative;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            line-height: 17px;  /* height + padding(2) + border(1) */
            font-weight: normal;
            &::before {
              content: '#';
              color: var(--hashtag-color);
              font-weight: 400;
              position: absolute;
            }
          }
          .mention {
            vertical-align: middle;
            top: 0;
            line-height: 18px;
          }
        }
      }
    }
  }
  .template-selection {
    margin-top: 30px;
    .title {
      text-transform: uppercase;
      color: #C3C2C0;
      margin-bottom: 20px;
    }
    .template-options {
      * {
        cursor: pointer;
      }
      .option {
        color: #C3C2C0;
        margin: 3px 0;
        border: 1px solid transparent;
        &:hover {
          background: #EBF3FC;
          border-radius: 2px;
        }
      }
    }
  }
  &.fullscreen-mode {
    padding: 30px 0;
    .page-sharing-icon {
      left: -30px;
    }
    .dialog-menu {
      margin-top: -40px;
      margin-left: -105px;
    }
    .page-share-menu {
      margin-right: 35px;
    }
  }
}
</style>
<style>
.sidebar-view .note-page.fullscreen-mode .dialog-menu {
  margin-top: -30px;
  margin-left: 0;
}
</style>
