<template>
  <div ref="formatControlsMenu" class="dialog-menu" v-if="showFormatControlsMenu">
    <div
        class="editor__menu menubar"
        :class="{inactive: !editor}"
    >
        <button
            class="menubar__button"
            :class="{ 'is-active': isActive('bold') }"
            @click="toggleBold()"
            title="Bold"
        >
          <span class="format-wrapper">
            <mdc-icon icon="format_bold"/>
            <mdc-text typo="body2 bold format-text" tag="span">Bold</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <mdc-text typo="body2" tag="span">B</mdc-text>
          </span>
        </button>

        <button
            class="menubar__button"
            :class="{ 'is-active': isActive('italic') }"
            @click="toggleItalic()"
            title="Italic"
        >
          <span class="format-wrapper">
            <mdc-icon icon="format_italic"/>
            <mdc-text typo="body2 italic format-text" tag="span">Italic</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <mdc-text typo="body2" tag="span">I</mdc-text>
          </span>
        </button>

        <button
            class="menubar__button"
            :class="{ 'is-active': isActive('underline') }"
            @click="toggleUnderline()"
            title="Underline"
        >
          <span class="format-wrapper">
            <mdc-icon icon="format_underline"/>
            <mdc-text typo="body2 underline format-text" tag="span">Underline</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <mdc-text typo="body2" tag="span">U</mdc-text>
          </span>
        </button>

        <button
            class="menubar__button"
            :class="{ 'is-active': isActive('strike') }"
            @click="toggleStrike()"
        >
          <span class="format-wrapper">
            <mdc-icon icon="format_strikethrough"/>
            <mdc-text typo="body2 strike format-text" tag="span">Strike</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.shift" />
            <mdc-text typo="body2" tag="span">X</mdc-text>
          </span>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          @click="toggleHeadingOne()"
          title="Heading 1"
        >
          <span class="format-wrapper">
            <mdc-text typo="body2" tag="span">H1</mdc-text>
            <mdc-text typo="body2 headingOne format-text" tag="span">Heading 1</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.otherMods" />
            <mdc-text typo="body2" tag="span">1</mdc-text>
          </span>
        </button>

        <button
          class="menubar__button"
         :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          @click="toggleHeadingTwo()"
          title="Heading 2"
        >
          <span class="format-wrapper">
            <mdc-text typo="body2" tag="span">H2</mdc-text>
            <mdc-text typo="body2 headingTwo format-text" tag="span">Heading 2</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.otherMods" />
            <mdc-text typo="body2" tag="span">2</mdc-text>
          </span>
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          @click="toggleHeadingThree()"
          title="Heading 3"
        >
          <span class="format-wrapper">
            <mdc-text typo="body2" tag="span">H3</mdc-text>
            <mdc-text typo="body2 headingThree format-text" tag="span">Heading 3</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.otherMods" />
            <mdc-text typo="body2" tag="span">3</mdc-text>
          </span>
        </button>
        <button
            v-if="!reducedOptions"
            class="menubar__button"
            :class="{ 'is-active': isActive('task_list') }"
            @click="toggleTaskList()"
            title="Task"
        >
          <span class="format-wrapper">
            <mdc-icon outlined icon="check_box"/>
            <mdc-text typo="body2 task-list format-text" tag="span">Task list</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.otherMods" />
            <mdc-text typo="body2" tag="span">4</mdc-text>
          </span>
        </button>
        <button
            v-if="!reducedOptions"
            class="menubar__button"
            :class="{ 'is-active': isActive('blockquote') }"
            @click="toggleBlockquote()"
            title="Quote"
        >
          <span class="format-wrapper">
            <mdc-icon outlined icon="format_quote"/>
            <mdc-text typo="body2 blockquote format-text" tag="span">Quote</mdc-text>
          </span>
         <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.shift" />
            <mdc-text typo="body2" tag="span">B</mdc-text>
          </span>
        </button>
        <button
            v-if="!reducedOptions"
            class="menubar__button"
            :class="{ 'is-active': isActive('bulletList') }"
            @click="toggleBulletList()"
            title="List"
        >
          <span class="format-wrapper">
            <mdc-icon outlined icon="format_list_bulleted"/>
            <mdc-text typo="body2 bullet-list format-text" tag="span"> List</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.shift" />
            <mdc-text typo="body2" tag="span">8</mdc-text>
          </span>
        </button>
        <button
            v-if="!reducedOptions"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
            @click="toggleLeftAlign()"
            title="Align left"
        >
          <span class="format-wrapper">
            <mdc-icon outlined icon="format_align_left"/>
            <mdc-text typo="body2 left-align format-text" tag="span">Left align</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.shift" />
            <mdc-text typo="body2" tag="span">L</mdc-text>
          </span>
        </button>
        <button
            v-if="!reducedOptions"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
            @click="toggleCenterAlign()"
            title="Align center"
        >
          <span class="format-wrapper">
            <mdc-icon outlined icon="format_align_center"/>
            <mdc-text typo="body2 center-align format-text" tag="span">Center align</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.shift" />
            <mdc-text typo="body2" tag="span">E</mdc-text>
          </span>
        </button>
        <button
            v-if="!reducedOptions"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
            @click="toggleRightAlign()"
            title="Right align"
        >
          <span class="format-wrapper">
            <mdc-icon outlined icon="format_align_right"/>
            <mdc-text typo="body2 right-align format-text" tag="span">Right align</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <span v-html="keys.shift" />
            <mdc-text typo="body2" tag="span">R</mdc-text>
          </span>
        </button>
        <button
            v-if="!reducedOptions"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
            @click="toggleJustify()"
            title="Justify"
        >
            <span class="format-wrapper">
              <mdc-icon outlined icon="format_align_justify"/>
              <mdc-text typo="body2 justify format-text" tag="span">Justify</mdc-text>
            </span>
            <span class="shortcut-keys" v-if="showShortcutKeys">
              <span v-html="keys.mod" />
              <span v-html="keys.shift" />
              <mdc-text typo="body2" tag="span">J</mdc-text>
            </span>
        </button>

        <button
            class="menubar__button"
            @click="undo()"
            title="Undo"
        >
          <span class="format-wrapper">
            <mdc-icon outlined icon="undo"/>
            <mdc-text typo="body2 format-text" tag="span">Undo</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <mdc-text typo="body2" tag="span">Z</mdc-text>
          </span>
        </button>

        <button
            class="menubar__button"
            @click="redo()"
            title="Redo"
        >
          <span class="format-wrapper">
            <mdc-icon outlined icon="redo"/>
            <mdc-text typo="body2 format-text" tag="span">Redo</mdc-text>
          </span>
          <span class="shortcut-keys" v-if="showShortcutKeys">
            <span v-html="keys.mod" />
            <mdc-text typo="body2" tag="span">Y</mdc-text>
          </span>
        </button>

        <button
            v-if="annotationsEnabled"
            class="menubar__button"
            :disabled="!can('annotation')"
            @click="addComment()"
            title="Comment"
        >
            <span class="format-wrapper">
              <mdc-icon outlined icon="chat"/>
              <mdc-text typo="body2 format-text" tag="span">Comment</mdc-text>
            </span>
            <mdc-text typo="body2" tag="span"></mdc-text>
        </button>

    </div>
  </div>
</template>

<script>

export default {
  name: 'TiptapMenuDialog',
  props: {
    formatIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editor: {
    },
    showFormatControlsMenu: {
      type: Boolean,
      default: false
    },
    reducedOptions: {
      type: Boolean,
      default: false
    },
    noteBlocksEnabled: {
      type: Boolean,
      default: false
    },
    annotationsEnabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      platform: null,
      keys: {
        mod: null,
        otherMods: null
      },
      showShortcutKeys: false
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
    window.removeEventListener('resize', self.positionFormatControlMenu)
  },
  created () {
    const userAgent = window.navigator.userAgent
    const platform = window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
    } else if (/Android/.test(userAgent)) {
      os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux'
    }
    this.platform = os
    this.frameShortcutKeys()
  },
  methods: {
    frameShortcutKeys () {
      switch (this.platform) {
        case 'Windows':
        case 'Linux':
          this.showShortcutKeys = true
          this.keys.mod = 'Ctrl'
          this.keys.otherMods = 'Alt'
          this.keys.shift = '&#8679;'
          return
        case 'Mac':
          this.showShortcutKeys = true
          this.keys.mod = '&#8984;'
          this.keys.otherMods = '&#8997;'
          this.keys.shift = '&#8679;'
          return
        default:
          this.showShortcutKeys = false
          this.keys.mod = ''
          this.keys.otherMods = ''
          this.keys.shift = ''
      }
    },
    can (button) {
      if (this.editor) {
        if (this.annotationsEnabled) {
          if (button === 'annotation') {
            return this.editor.can().addAnnotation()
          }
        }
      }
      return false
    },
    isActive (button) {
      if (this.editor) {
        if (!button) {
          return this.editor.isActive
        }
        if (button === 'history') {
          return this.editor.options.history
        }
        return this.editor.isActive(button)
      }
      return false
    },
    addComment () {
      const data = prompt('Comment', '')
      this.editor.commands.addAnnotation(data)
    },
    toggleHeadingOne () {
      if (this.editor) {
        return this.editor.chain().focus().toggleHeading({ level: 1 }).focus().run()
      }
      return false
    },
    toggleHeadingTwo () {
      if (this.editor) {
        return this.editor.chain().focus().toggleHeading({ level: 2 }).focus().run()
      }
      return false
    },
    toggleHeadingThree () {
      if (this.editor) {
        return this.editor.chain().focus().toggleHeading({ level: 3 }).focus().run()
      }
      return false
    },
    toggleBold () {
      if (this.editor) {
        return this.editor.chain().focus().toggleBold().focus().run()
      }
      return false
    },
    toggleItalic () {
      if (this.editor) {
        return this.editor.chain().focus().toggleItalic().focus().run()
      }
      return false
    },
    toggleStrike () {
      if (this.editor) {
        return this.editor.chain().focus().toggleStrike().focus().run()
      }
      return false
    },
    toggleUnderline () {
      if (this.editor) {
        return this.editor.chain().focus().toggleUnderline().focus().run()
      }
      return false
    },
    toggleTaskList () {
      if (this.editor) {
        return this.editor.chain().focus().toggleTaskList().run()
      }
      return false
    },
    togglePrivate () {
      if (this.editor) {
        return this.editor.chain().focus().togglePrivate().focus().run()
      }
      return false
    },
    toggleNoteBlock () {
      if (this.editor) {
        return this.editor.chain().focus().insertNoteBlock().focus().run()
      }
      return false
    },
    toggleHistory () {
      if (this.editor) {
        // console.log('toggleHistory', this.editor.options.history)
        this.editor.options.history = !this.editor.options.history
      }
    },
    undo () {
      if (this.editor) {
        return this.editor.chain().focus().undo().run()
      }
      return false
    },
    redo () {
      if (this.editor) {
        return this.editor.chain().focus().redo().run()
      }
      return false
    },
    toggleOrderedList () {
      if (this.editor) {
        return this.editor.chain().focus().toggleOrderedList().run()
      }
      return false
    },
    toggleHorizontalRule () {
      if (this.editor) {
        return this.editor.chain().focus().setHorizontalRule().run()
      }
      return false
    },
    toggleBlockquote () {
      if (this.editor) {
        return this.editor.chain().focus().toggleBlockquote().focus().run()
      }
      return false
    },
    toggleBulletList () {
      if (this.editor) {
        return this.editor.chain().focus().toggleBulletList().run()
      }
      return false
    },
    toggleLeftAlign () {
      if (this.editor) {
        return this.editor.chain().focus().setTextAlign('left').run()
      }
      return false
    },
    toggleCenterAlign () {
      if (this.editor) {
        return this.editor.chain().focus().setTextAlign('center').run()
      }
      return false
    },
    toggleRightAlign () {
      if (this.editor) {
        return this.editor.chain().focus().setTextAlign('right').run()
      }
      return false
    },
    toggleJustify () {
      if (this.editor) {
        return this.editor.chain().focus().setTextAlign('justify').run()
      }
      return false
    },
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target))) {
        // console.log('tiptap format menu outside click', event.target, this.$el)
        this.$emit('closeFormatControlMenu', false)
      } else {
        // console.log('format control menu inside click received')
      }
    },
    positionFormatControlMenu () {
      const formatControlMenuElement = this.$refs.formatControlsMenu
      if (formatControlMenuElement) {
        const rect = formatControlMenuElement.getBoundingClientRect()
        const isInViewport = rect.top >= 0 &&
                            rect.left >= 0 &&
                            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        if (!isInViewport) {
          if (rect.left <= 0) {
            formatControlMenuElement.style.left = -1 * this.formatIconPosition.left + 'px'
          }
        } else {
          formatControlMenuElement.style.left = -195 + 'px'
        }
      }
    }
  },
  watch: {
    showFormatControlsMenu: {
      handler () {
        if (this.showFormatControlsMenu) {
          const self = this
          this.$nextTick(() => {
            this.positionFormatControlMenu()
          })
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
            window.addEventListener('resize', self.positionFormatControlMenu)
          }, 300)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
            window.removeEventListener('resize', self.positionFormatControlMenu)
          }, 300)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
$color-black: #000000;
$color-white: #ffffff;
.dialog-menu {
  position: absolute;
  display: flex;
  z-index: 13;
  left: -195px;
  background: #FBFBFB;
  box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  top: -45px;
  height: 600px;
  overflow: auto;
  align-items: center;
  .menubar {
    display: flex;
    flex-direction: column;
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
    position:relative;
    background: #ffffff;
    margin:1px;
    border-bottom: none;
    min-width: 200px;
    padding: 1rem;
    margin-top: auto;

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &.is-focused {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.2s, opacity 0.2s;
    }

    &.inactive {
      opacity: 0.5;
    }
    &__button {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      border: 0;
      color: $color-black !important;
      padding: 2px !important;
      margin: 5px;
      border-radius: 3px;
      cursor: pointer;

      /* &:hover { */
      /* background-color: rgba($color-black, 0.05);
      } */
      .mdc-icon.mdc-icon--material {
        font-size: 20px;
      }

      &.is-active {
        background-color: rgba($color-black, 0.1);
      }
      .format-wrapper {
        display: flex;
        align-items: center;
        .format-text {
          margin-left: 10px;
          margin-right: 10px;
          &.bold {
            color: #333333;
            &:before {
              content: '*';
              color: #CC0000;
            }
            &:after {
              content: '*';
              color: #CC0000;
            }
          }
          &.italic {
            &:before {
              content: '/';
              color: #CC0000;
            }
            &:after {
              content: '/';
              color: #CC0000;
            }
          }
          &.underline {
            &:before {
              content: '_';
              color: #CC0000;
            }
            &:after {
              content: '_';
              color: #CC0000;
            }
          }
          &.strike {
            &:before {
              content: '-';
              color: #CC0000;
            }
            &:after {
              content: '-';
              color: #CC0000;
            }
          }
          &.ordered-list {
            &:before {
              content: '1.';
              color: #CC0000;
            }
          }
          &.bullet-list {
            &:before {
              content: '*';
              color: #CC0000;
            }
          }
          &.blockquote {
            &:before {
              content: '>';
              color: #CC0000;
            }
          }
          &.task-list {
            &:before {
              content: '-';
              color: #CC0000;
            }
          }
          &.headingOne {
            &:before {
              content: '# ';
              color: #cc0000;
            }
          }
          &.headingTwo {
            &:before {
              content: '## ';
              color: #cc0000;
            }
          }
          &.headingThree {
            &:before {
              content: '### ';
              color: #cc0000;
            }
          }
          &.horizontal-rule {
            &:before {
              content: '--- ';
              color: #cc0000;
            }
          }
        }
      }
      .shortcut-keys {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        line-height: 20px;
        > *:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #828282;
  }
}
</style>
