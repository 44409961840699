<template>
  <div ref="user-settings" v-if="showUserSettings" class="user-settings-wrapper" role="content">
    <photo-modal :openPhoto="avatarmodalopen" @upload="uploadAvatarFile" @change="changeAvatarModalOpen" :profile="profile" />
    <section class="settings user-settings-section">
      <div class="profile-avatar">
        <profile-initials :profileId="profile.id" :workingAt="myLocationNow" noProfileDetailClick />
        <div class="settings-actions">
          <div class="name-and-upload">
            <mdc-text role="generic" class="name" tag="span" typo="body2">{{profile.displayName}}</mdc-text>
            <mdc-button aria-label="update-picture" class="upload-button" @click.native="openUploadDialog">Update picture</mdc-button>
          </div>
          <div v-if="hasUserTeams" class="switch-teams">
            <mdc-text role="generic" class="switch-label" tag="span" typo="body2">Switch team</mdc-text>
            <div class="switch-details-wrapper" v-if="teams">
              <mdc-button aria-label="team-name-btn" class="name-btn" @click.native="toggleTeamOptions()">
                <mdc-text class="team-name" typo="caption" tag="span">{{ userTeamName }}</mdc-text>
                <base-svg-icon clickable class="down-arrow" name="thin-down-arrow-4175b1" />
              </mdc-button>
              <div class="team-name-options" role="menu" name="team-options-menu" v-if="teamOptionsOpen">
                <mdc-text @click.native="switchTeam(team)" tag="span" typo="body2" class="option-type clickable" v-for="(team, index) in userTeamsDetail" :key="index" >
                  {{ team.name }}
                </mdc-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="profile-select-location user-settings-section">
      <header>QUICK SELECT LOCATION</header>
      <div class="current-location" v-if="myLocationNow">
        <mdc-text tag="span" typo="caption" class="label">Your current location is set to</mdc-text>
        <mdc-text tag="span" typo="caption" class="response" :class="myLocationNow">{{ myLocationNow === 'thirdspace' || myLocationNow === 'home' ? 'REMOTE' : myLocationNow}}</mdc-text>
      </div>
      <div class="current-location" v-else>
        <mdc-text tag="span" typo="caption" class="label">Set location for today:</mdc-text>
      </div>
      <div class="location-type-grid" role="grid">
        <div class="home-fill type" @click="setSelectedResponse('home')" v-bind:class="{ selected: myLocationNow === 'home' }">
            <base-svg-icon name="home"/>
        </div>
        <div class="thirdspace-fill type" @click="setSelectedResponse('thirdspace')" v-bind:class="{ selected: myLocationNow === 'thirdspace' }">
            <base-svg-icon name="thirdspace"/>
        </div>
        <div class="office-fill type" @click="setSelectedResponse('office')" v-bind:class="{ selected: myLocationNow === 'office' }">
            <base-svg-icon name="office"/>
        </div>
        <div class="nonworking-fill type" @click="setSelectedResponse('nonworking')" v-bind:class="{ selected: myLocationNow === 'nonworking' }">
            <base-svg-icon name="nonworking"/>
        </div>
      </div>
      <section class="select-location-field" v-if="myLocationNow === 'office' || myLocationNow === 'thirdspace'">
        <span v-if="(myLocationNow === 'thirdspace' && myTodaySchedule.thirdspaceLocation) || (myLocationNow === 'office' && myTodaySchedule.officeLocation)">
          <mdc-text tag="span" typo="caption">{{ myLocationNow === 'thirdspace' ? 'Remote location:' : 'Office location:' }}</mdc-text>
          <span class="location-place-value">
            <base-svg-icon name="location-pin-bluegray" />
            <template v-if="myLocationNow === 'thirdspace' && myTodaySchedule.thirdspaceLocation">
              <mdc-text class="location-text" tag="span" typo="caption" @click.native.stop="openLocationSettingInput()">{{ myTodaySchedule.thirdspaceLocation }}</mdc-text>
              <base-svg-icon class="clear-text" name="cross-thin-blue" clickable @click.native.stop="setThirdspaceLocation('')" />
            </template>
            <template v-if="myLocationNow === 'office' && myTodaySchedule.officeLocation">
              <mdc-text class="location-text" tag="span" typo="caption" @click.native.stop.capture="openLocationSettingInput()" v-html="myTodaySchedule.officeLocation" />
              <base-svg-icon class="clear-text" name="cross-thin-blue" clickable @click.native.stop="setOfficeLocation('')" />
            </template>
          </span>
        </span>
        <mdc-button class="set-location-button" v-else @click.native="openLocationSettingInput()">Which location?</mdc-button>
      </section>
      <div class="location-details" v-if="openLocationDetails">
        <locations-details :date="appDate" @emitClose="closeLocationDetails" @saveThirdspaceLocation="setThirdspaceLocation" @saveOfficeLocation="setOfficeLocation" :locationType="myLocationNow" :showFor="myTodaySchedule.type" />
      </div>
    </section>
    <section class="settings-extras-menu-items user-settings-section other-menus">
      <div class="settings-extras-sub-menus sub-menus">
        <div class="settings-menu submenuitem"  @click="gotoPage('settings')">
          <base-svg-icon name="" class="sub-menu-icon"/>
          <mdc-text tag="span" typo="body2" class="sub-menu-label">Settings</mdc-text>
        </div>
        <div class="legal-menu submenuitem"  @click="gotoPage('legal')">
          <base-svg-icon name="" class="sub-menu-icon" />
          <mdc-text tag="span" typo="body2" class="sub-menu-label">Legal</mdc-text>
        </div>
        <div class="support-menu submenuitem"  @click="gotoPage('support')">
          <base-svg-icon name="" class="sub-menu-icon" />
          <mdc-text tag="span" typo="body2" class="sub-menu-label">Support</mdc-text>
        </div>
        <div class="support-menu submenuitem"  v-if="userClaimAdmin" @click="gotoPage('admin')">
          <base-svg-icon name="" class="sub-menu-icon" />
          <mdc-text tag="span" typo="body2" class="sub-menu-label">Admin</mdc-text>
        </div>
      </div>
    </section>
    <section class="sign-out other-menus">
      <div class="signout-sub-menus sub-menus" @click="signOutClick">
        <div class="signout-menu submenuitem">
          <mdc-text tag="span" typo="body2" class="sub-menu-label">Signout</mdc-text>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import ProfileInitials from './ProfileInitials.vue'
import PhotoModal from '../../components/profile-v2/PhotoModal'
import { mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import BaseSvgIcon from './BaseSvgIcon'
import fb from '../../store/firebase'
import { getWC } from '../../utils/time'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import LocationsDetails from './LocationsDetails.vue'
import { onboarding, mail } from '../../utils/backend'

const _ = { groupBy, sortBy }
export default {
  name: 'UserSettings',
  components: {
    ProfileInitials,
    PhotoModal,
    BaseSvgIcon,
    LocationsDetails
  },
  props: {
    showUserSettings: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      avatarmodalopen: false,
      openLocationDetails: false,
      userTeamSelected: false,
      switchingTeam: false,
      teamOptionsOpen: false,
      userTeamName: ''
    }
  },
  beforeDestroy () {
    const pac = document.getElementsByClassName('pac-container')[0]
    const body = document.getElementsByClassName('body')[0]
    if (body) {
      if (pac) {
        pac.parentNode.removeChild(pac)
        body.appendChild(pac)
      }
    }
  },
  created () {
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions([
      'uploadAvatar',
      'updateProfile',
      'loadTeamListeners',
      'setPageLoad'
    ]),
    ...mapActions({
      saveSchedule: 'teams/saveSchedule',
      saveTopicsAndPeople: 'teams/saveTopicsAndPeople',
      setBestDaysCalculating: 'teams/setBestDaysCalculating'
    }),
    toggleTeamOptions () {
      this.teamOptionsOpen = !this.teamOptionsOpen
    },
    closeLocationDetails () {
      this.openLocationDetails = false
    },
    openLocationSettingInput () {
      this.openLocationDetails = true
    },
    initialize () {
      this.userTeamName = this.getTeamName(this.profile.team)
      if (this.user && this.profile) {
        this.userTeamSelected = this.profile.team
      }
    },
    gotoPage (pageName) {
      this.$emit('triggerClose', false)
      if (this.$router) {
        if (pageName === 'support') {
          window.open('https://duome.zendesk.com/hc/en-gb', '_blank')
          return
        }
        if (pageName === 'legal') {
          window.open('https://duome.co/privacy-policy.html', '_blank')
          return
        }
        const hash = '/' + pageName
        this.$router.push(hash)
      }
    },
    async switchTeam (team) {
      this.teamOptionsOpen = false
      this.userTeamName = team.name
      if (team.id !== this.profile.team) {
        console.log('switchTeam', team)
        this.switchingTeam = true
        try {
          const params = {
            workspaceId: this.workspace.id,
            teamId: team.id,
            userId: this.profile.id
          }
          this.setPageLoad(true)
          const response = await onboarding('switch-team', params)
          console.log('UserSettings.switchTeam', response, params)
          await firebase.auth().currentUser.getIdToken(true)
          setTimeout(async () => {
            await this.loadTeamListeners()
            setTimeout(() => {
              this.updatingToken = false
              this.$router.replace('/people', () => {})
            }, 500)
            this.setPageLoad(false)
          }, 1000)
        } catch (err) {
          console.error('UserSettings.switchTeam.error', err)
        } finally {
          this.switchingTeam = false
        }
      }
    },
    signOutClick () {
      this.$emit('triggerClose', false)
      this.$emit('signOut')
    },
    handleClickOutside (event) {
      if (!event.target.closest('.switch-details-wrapper')) {
        if (this.teamOptionsOpen) {
          this.teamOptionsOpen = false
        }
      }
      if (!(event.target.classList.contains('option-type') || this.$el === event.target || this.$el.contains(event.target))) {
        this.$emit('triggerClose', false)
      }
    },
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        if (this.teamOptionsOpen) {
          this.teamOptionsOpen = false
          return
        }
        this.$emit('triggerClose', false)
      }
    },
    locationCapacityExSelf (locationId, dayAbv) {
      let totalCap = 0
      for (const schedule of this.currentSchedules.filter(sc => sc.fromId !== this.profile.id)) {
        if (schedule.schedules) {
          const sday = schedule.schedules[dayAbv]
          if (sday) {
            if (sday.locationId === locationId) {
              totalCap++
            }
          }
        }
      }
      return totalCap
    },
    allLocationsValid (schedules) {
      let allValid = true
      this.scheduleIssue = false
      // console.log('allLocationsValid 1', this.schedulesByDay)
      if (schedules) {
        for (const key of Object.keys(schedules)) {
          if (schedules[key]) {
            const scheduleDay = schedules[key]
            if (scheduleDay.date) {
              const scheduleDate = scheduleDay.date.toDate ? scheduleDay.date.toDate() : scheduleDay.date
              if (moment().isBefore(moment(scheduleDate), 'day')) {
                // ignore past dates
                scheduleDay.issue = false
                const inOffice = (scheduleDay.type === 'full' && scheduleDay.response === 'office') || (scheduleDay.split && (scheduleDay.amResponse === 'office' || scheduleDay.pmResponse === 'office'))
                if (inOffice && scheduleDay.locationId) {
                  const locationId = scheduleDay.locationId
                  const location = this.locations.filter(loc => loc.id === locationId)[0]
                  if (location) {
                    if (location.buildingId) {
                      const building = this.locations.filter(loc => loc.id === location.buildingId)[0]
                      location.capacityManage = building.capacityManage
                    }
                    // console.log(`allLocationsValid.${key}`, scheduleDay, location, location.capacityManage)
                    if (location.capacityManage) {
                      const locationCapacity = this.locationCapacityExSelf(locationId)
                      // console.log(`allLocationsValid.${key}.locationCapacity`, locationCapacity, location)
                      if (locationCapacity >= location.capacity) {
                        allValid = false
                      }
                    }
                    if (location.assignedDesks) {
                      if (!scheduleDay.desk) {
                        allValid = false
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return allValid
    },
    getIssuesResolved (schedule) {
      const isAfterConfirm = true // today will always be after confirmation
      let previousIssue = false
      const mySchedule = this.mySchedule[0]
      if (!schedule || !mySchedule) {
        return false
      }
      const allValid = this.allLocationsValid(schedule.schedules) // current changes are valid for this week
      for (const key of Object.keys(mySchedule.schedules)) {
        if (mySchedule.schedules[key]) {
          const scheduleDay = mySchedule.schedules[key]
          if (scheduleDay && scheduleDay.issue) {
            previousIssue = true
          }
        }
      }
      const issuesResolved = isAfterConfirm && previousIssue && allValid
      return issuesResolved
    },
    async setSelectedResponse (type) {
      const date = moment().startOf('isoWeek').toDate()
      const week = moment(date).format('YYYY-MM-DD')
      let schedule = {}
      if (this.mySchedule) {
        schedule = { ...this.mySchedule[0] }
      }
      const appDate = moment(this.appDate)
      const weekdayAbv = moment(appDate).format('ddd').toUpperCase()
      const dayIndex = moment(appDate).isoWeekday() - 1
      if (schedule.id) {
        if (schedule.schedules[weekdayAbv]) {
          schedule.schedules[weekdayAbv].date = appDate.toDate()
          schedule.schedules[weekdayAbv].response = type
          schedule.schedules[weekdayAbv].type = 'full'
          schedule.schedules[weekdayAbv].split = false
          schedule.schedules[weekdayAbv].amResponse = ''
          schedule.schedules[weekdayAbv].pmResponse = ''
          schedule.schedules[weekdayAbv].editedOn = new Date()
          schedule.schedules[weekdayAbv].submittedOn = schedule.schedules[weekdayAbv].submittedOn || new Date()
        } else { // if any one day's schedule is already present
          schedule.schedules[weekdayAbv] = {
            day: weekdayAbv,
            date: appDate.toDate(),
            type: 'full',
            split: false,
            amResponse: '',
            pmResponse: '',
            response: type,
            index: dayIndex,
            submittedOn: new Date()
          }
        }
      } else {
        schedule = {
          weekCommencingDate: date,
          fromId: this.profile.id,
          schedules: {},
          confirmed: false
        }
        // TODO - Allow half day once UI quick select allows
        schedule.schedules[weekdayAbv] = {
          day: weekdayAbv,
          date: appDate.toDate(),
          type: 'full',
          split: false,
          amResponse: '',
          pmResponse: '',
          response: type,
          index: dayIndex,
          submittedOn: new Date()
        }
      }
      const issuesResolved = this.getIssuesResolved(schedule)
      await this.saveSchedule({ schedule, issuesResolved }).then(async () => {
        // Trigger suggestions recalc
        const wsTS = moment(date).add('12', 'hours').toDate()
        const params = { date: wsTS, workspaceId: this.profile.workspaceId }
        this.setBestDaysCalculating(true)

        if (issuesResolved) {
          const params = {}
          const email = this.profile.email
          const url = `send-confirmation/${this.workspace.id}/${this.profile.id}/${week}/${email}`
          const response = await mail(url, params)
          console.log('UserSettings.setSelectedResponse issuesResolvedMail', url, response)
        }

        const calcWorkspaceSchedules = fb.functions.httpsCallable('calcSchedules')
        calcWorkspaceSchedules(params)
        let preferencesToSet
        const dayFormatToday = appDate.format('ddd').toUpperCase()
        if (this.preferences && this.preferences[0]) {
          if (this.preferences[0].userDailyLocation && this.preferences[0].userDailyLocation[this.profile.id]) {
            this.preferences[0].userDailyLocation[this.profile.id][dayFormatToday] = { location: type }
            preferencesToSet = this.preferences[0]
          } else {
            this.preferences[0].userDailyLocation[this.profile.id] = {}
            this.preferences[0].userDailyLocation[this.profile.id][dayFormatToday] = { location: type }
            preferencesToSet = this.preferences[0]
          }
        } else {
          preferencesToSet = {
            fromDate: moment().startOf('isoWeek').toDate(),
            toDate: moment().endOf('isoWeek').subtract(2, 'days').toDate(),
            teamId: this.profile.team,
            userId: this.profile.id,
            teamMatesId: [],
            topicsId: [],
            id: false,
            userDailyLocation: {}
          }
          preferencesToSet.userDailyLocation[this.profile.id] = {}
          preferencesToSet.userDailyLocation[this.profile.id][dayFormatToday] = { location: type }
        }
        this.saveTopicsAndPeople({ preferences: preferencesToSet })
        this.initialize()
      })
    },
    openUploadDialog () {
      this.avatarmodalopen = true
    },
    uploadAvatarFile (file, filename) {
      const self = this
      this.uploadAvatar({ file, filename }).then(() => {
        self.avatarmodalopen = false
      })
    },
    changeAvatarModalOpen ($event) {
      this.avatarmodalopen = $event
    },
    async setThirdspaceLocation (val) {
      console.log('set thirdspace location', val)
      const date = moment().startOf('isoWeek').toDate()
      const week = moment(date).format('YYYY-MM-DD')

      const weekdayAbv = moment(this.appDate).format('ddd').toUpperCase()
      const weekSchedule = { ...this.mySchedule }
      let todaySchedule = { ...this.myTodaySchedule, locationId: '', floorId: '', buildingId: '', locationType: '' }
      if (weekSchedule[0] && weekSchedule[0].id) {
        if (Object.keys(todaySchedule).length) {
          if (this.myTodaySchedule && this.myTodaySchedule.type === 'full') {
            todaySchedule = { ...todaySchedule, thirdspaceLocation: val }
          } else if (this.myTodaySchedule && this.myTodaySchedule.type === 'split') {
            todaySchedule = { ...todaySchedule, splitThirdspaceLocation: val }
          }
        }
        weekSchedule[0].schedules[weekdayAbv] = { ...todaySchedule }
      }
      const issuesResolved = this.getIssuesResolved(weekSchedule[0])
      await this.saveSchedule({ date: getWC(new Date()), schedule: weekSchedule[0], responses: _.groupBy(weekSchedule[0].schedules, 'index'), saveFor: 'location', issuesResolved })
      if (issuesResolved) {
        console.log('UserSettings.setThirdspaceLocation scheduleId issuesResolved', weekSchedule[0].id, issuesResolved)
        const params = {}
        const email = this.profile.email
        const url = `send-confirmation/${this.workspace.id}/${this.profile.id}/${week}/${email}`
        const response = await mail(url, params)
        console.log('UserSettings.setThirdspaceLocation issuesResolvedMail', url, response)
      }
      this.closeLocationDetails()
    },
    setOfficeLocation (val, shownFor, text, type, location) {
      console.log('setOfficeLocation', val, shownFor, text, type, location)
      const date = moment().startOf('isoWeek').toDate()
      const week = moment(date).format('YYYY-MM-DD')

      const weekdayAbv = moment(this.appDate).format('ddd').toUpperCase()
      const weekSchedule = { ...this.mySchedule }
      let todaySchedule = { ...this.myTodaySchedule, locationId: val || '', floorId: (location && location.floorId) || '', buildingId: (location && location.buildingId) || '', locationType: type || '' }
      if (weekSchedule[0] && weekSchedule[0].id) {
        if (Object.keys(todaySchedule).length) {
          if (this.myTodaySchedule && this.myTodaySchedule.type === 'full') {
            todaySchedule = { ...todaySchedule, officeLocation: text || '' }
          } else if (this.myTodaySchedule && this.myTodaySchedule.type === 'split') {
            todaySchedule = { ...todaySchedule, splitOfficeLocation: text || '' }
          }
        }
        weekSchedule[0].schedules[weekdayAbv] = { ...todaySchedule }
      }
      const issuesResolved = this.getIssuesResolved(weekSchedule[0])
      this.saveSchedule({ date: getWC(new Date()), schedule: weekSchedule[0], responses: _.groupBy(weekSchedule[0].schedules, 'index'), saveFor: 'location', issuesResolved }).then(async () => {
        if (val) {
          const locationIndex = this.profile.officeLocations ? this.profile.officeLocations.findIndex(location => location.id === val) : -1
          if (locationIndex === -1) {
            const officeLocation = { date: new Date(), id: val || '', type: type || '', text: text || '' }
            let officeLocationsToSave
            if (this.profile.officeLocations && this.profile.officeLocations.length) {
              const favLast = _.sortBy(this.profile.officeLocations, ({ id }) => id === this.profile.favOfficeLocationId ? 1 : 0)
              officeLocationsToSave = [...favLast.slice(-3), officeLocation]
            } else {
              officeLocationsToSave = [officeLocation]
            }
            this.updateProfile({ profile: { officeLocations: officeLocationsToSave }, noSnack: true })
          }
        }
        if (issuesResolved) {
          const params = {}
          const email = this.profile.email
          const url = `send-confirmation/${this.workspace.id}/${this.profile.id}/${week}/${email}`
          const response = await mail(url, params)
          console.log('UserSettings.setOfficeLocation issuesResolvedMail', url, response)
        }
      })
      this.closeLocationDetails()
    }
  },
  watch: {
    showUserSettings: {
      handler () {
        if (this.showUserSettings) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
            document.addEventListener('keyup', self.handleKeyDown)
          }, 0)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
            document.removeEventListener('keyup', self.handleKeyDown)
          }, 0)
        }
      },
      immediate: true
    },
    profile: {
      handler () {
        if (this.profile && this.profile.team) {
          this.userTeamName = this.getTeamName(this.profile.team)
          if (this.userTeamSelected !== this.profile.team) {
            this.userTeamSelected = this.profile.team
          }
        }
      },
      deep: true
    },
    teams: {
      handler () {
        this.userTeamName = this.getTeamName(this.profile.team)
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'userTeams',
      'scheduleSearchWeekDetail',
      'appDate',
      'workspace'
    ]),
    ...mapState({
      profile: state => state.profile,
      currentSchedules: state => state.teams.currentSchedules,
      preferences: state => state.teams.preferences,
      teams: state => state.teams.teams,
      locations: state => state.teams.locations
    }),
    ...mapGetters({
      getTeamName: 'teams/getTeamName'
    }),
    hasUserTeams () {
      if (this.userTeams) {
        if (this.userTeams.length > 1) {
          return true
        }
      }
      return false
    },
    userTeamsDetail () {
      const teamDetails = []
      if (this.userTeams) {
        for (const ut of this.userTeams) {
          const team = this.teams.filter(t => t.id === ut)
          if (team.length) {
            teamDetails.push({ id: team[0].id, name: team[0].name })
          }
        }
      }
      return teamDetails
    },
    userClaimAdmin () {
      if (this.user) {
        if (this.user.claims) {
          return this.user.claims.admin
        }
      }
      return false
    },
    mySchedule () {
      return this.currentSchedules.filter(schedule => schedule.fromId === this.profile.id)
    },
    myTodaySchedule () {
      const weekdayAbv = moment(this.appDate).format('ddd').toUpperCase()
      if (this.mySchedule.length > 0) {
        return this.mySchedule[0].schedules[weekdayAbv] || {}
      }
      return {}
    },
    myLocationNow () {
      const todaySchedule = this.myTodaySchedule
      if (Object.keys(todaySchedule).length > 0 && todaySchedule.type) {
        if (todaySchedule.type === 'full') {
          return todaySchedule.response
        }
        if (todaySchedule.type === 'split') {
          const slotNow = moment(this.appDate).format('a')
          if (slotNow === 'am') {
            return todaySchedule.amResponse || ''
          }
          if (slotNow === 'pm') {
            return todaySchedule.pmResponse || ''
          }
          return ''
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
$color-black: #000000;
$color-white: #ffffff;
.user-settings-wrapper {
  position: absolute;
  display: flex;
  z-index: 93;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  top: 55px;
  color: $color-black;
  right: 35px;
  width: 390px;
  margin: 0;
  flex-direction: column;
  &::before {
    position: absolute;
    left: 95%;
    top: -10px;
    width: 0;
    height: 0;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid white;
  }
  .user-settings-section {
    border-bottom: 0.5px solid #E0E0E0;
  }
  .settings {
    padding: 20px 20px 15px 20px;
    display: flex;
    .profile-avatar {
      display: flex;
      width:100%;
      align-items: center;
      .profile-redirect {
        max-height: 67px;
        margin-right: 20px;
        cursor: default;
        .profile-initials {
          height: 67px;
          width: 67px;
          max-width: 67px;
          max-height: 67px;
          &::before {
            height: 67px;
            width: 67px;
            max-width: 67px;
            max-height: 67px;
          }
          &.profile-schedule {
            background-size: 65px 65px;
            font-size: 28px;
          }
        }
        .photo-wrapper {
          &::before {
            height: 67px;
            width: 67px;
            max-width: 67px;
            max-height: 67px;
            border: 2px solid;
          }
          .profile-initials {
            height: 67px;
            width: 67px;
            max-width: 67px;
            max-height: 67px;
            &::before {
              height: 67px;
              width: 67px;
              max-width: 67px;
              max-height: 67px;
            }
          }
        }
      }
      .settings-actions {
        width:100%;
      }
      .switch-teams {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        .switch-label {
          font-size: 12px;
          white-space: nowrap;
        }
        .switch-details-wrapper {
          position: relative;
          justify-self: flex-end;
          display: flex;
          .name-btn {
            height: 20px;
            .team-name {
              margin-right: 20px;
              color: #00498D;
              font-weight: 600;
              text-transform: capitalize;
            }
            .icon-wrapper {
              height: 12px;
              width: 12px;
              img {
                height: 100%;
                width: 100%;
              }
            }
          }
          .team-name-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            border: 0.5px solid #C4C4C4;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            width: 200px;
            top: 100%;
            right: 0;
            .mdc-text {
              padding: 8px 12px;
              &:hover {
                background: rgba(235, 243, 252, 0.58);
              }
            }
          }
        }
      }
      .name-and-upload {
        display: flex;
        flex-direction: column;
        .name {
          font-size: 18px;
          font-weight: 700;
          color: #333333;
        }
        .upload-button {
          height: 15px;
          padding: 0;
          display: block;
          text-align: left;
          font-size: 12px;
          text-transform: none;
          color: #00498D;
          font-weight: 600;
          margin: 5px 0;
          width: max-content;
          line-height: 12px;
        }
      }
    }
  }
  .profile-select-location {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    header {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      padding: 0 20px;
    }
    .current-location {
      padding: 0 20px;
      margin: 5px 0;
      .label {
        font-weight: 600;
        color: #404040;
        margin-right: 5px;
      }
      .response {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .location-type-grid {
      padding: 0 20px;
      display: grid;
      grid-template-columns: repeat(4, minmax(auto, 1fr));
      height: 46px;
      grid-gap: 2px;
      background-color: #fcfcfc;
      margin-bottom: 20px;
      .type {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        &.home-fill {
          background-color: var(--home-fill);
          border: 0.5px solid var(--home-fill);
          &.selected {
            background-image: url('/static/img/icons/flexibly/home-selected.svg');
          }
        }
        &.office-fill {
          background-color: var(--office-fill);
          border: 0.5px solid var(--office-fill);
          &.selected {
            background-image: url('/static/img/icons/flexibly/office-selected.svg');
          }
        }
        &.nonworking-fill {
          background: var(--nonworking-fill);
          border: 0.5px solid var(--nonworking-fill);
          &.selected {
            background-image: url('/static/img/icons/flexibly/nonworking-selected.svg');
          }
        }
        &.thirdspace-fill {
          background-color: var(--thirdspace-fill);
          border: 0.5px solid var(--thirdspace-fill);
          &.selected {
            background-image: url('/static/img/icons/flexibly/home-selected.svg');
          }
        }
        &.selected {
          background-size: contain;
          background-repeat: no-repeat;
        }
        .icon-wrapper {
          img {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
    .sub-menus {
      display: flex;
      flex-direction: column;
      .submenuitem {
        display: flex;
        cursor: pointer;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        padding: 0 20px;
        .sub-menu-icon {
          margin-right: 5px;
        }
        .sub-menu-label {
          font-weight: 700;
          color: #404040;
        }
      }
      &.mylocation-sub-menus {
        .thirdspace-input {
          position: relative;
          input {
            background: #FBFBFB;
            border: 0.5px solid #828282;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 15px;
            height: 40px;
            font-size: 14px;
          }
          ::placeholder {
            opacity: 1 !important;
            color: #BDBDBD;
            font-size: 14px;
          }
          .pac-container {
            top: unset !important;
            left: 0 !important;
            border-radius: 0 0 4px 4px;
            z-index: 9999;
            .pac-item {
              padding: 0 5px;
              .pac-icon {
                background-image: url('/static/img/icons/flexibly/location_fill.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }
      }
    }
    .select-location-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 10px 20px;
      > span {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .location-place-value {
          margin: 0 5px;
          display: flex;
          align-items: center;
          .icon-wrapper {
            margin-right: 3px;
          }
          .mdc-text {
            cursor: pointer;
            font-weight: 600;
            text-transform: capitalize;
            color:#4175B1;
            &.location-text {
              i {
                font-weight: 400;
              }
            }
          }
          .clear-text {
            margin: 0 8px;
            height: 8px;
            width: 8px;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
      .mdc-button {
        height: 20px;
        text-transform: none;
        color: #4175B1;
        font-size: 12px;
        font-weight: 400;
        padding: 0;
      }
    }
    .location-details {
      margin: 0 20px 10px 20px;
      background: #FBFBFB;
      .locations-details-wrapper .section .location-input input {
        background: #FBFBFB;
      }
    }
  }
  .other-menus {
    // padding: 10px 20px;
    .sub-menus {
      flex-direction: column;
      .submenuitem {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 7px 20px;
        .sub-menu-icon {
          margin-right: 5px;
          img {
            width: 12px;
            height: 12px;
          }
        }
        .sub-menu-label {
          font-weight: 600;
          color: #404040;
        }
        &:hover {
          background: #EBF3FC;
        }
      }
    }
    &.sign-out {
      .sub-menus {
        .submenuitem {
          .sub-menu-label {
            font-weight: 700;
          }
          &:hover {
            background: #EBF3FC;
            border-radius: 0 0 4px 4px;
          }
        }
      }
    }
  }
}
</style>
