<template>
  <div class="initiatives-wrapper">
      <app-layout key="initiativeLayout">
        <div class="one-col-layout" slot="layout-content">
          <app-section :editable="false">
            <div class="initiatives-content-wrapper">
              <div class="header-search-wrapper">
                <header class="initiatives-title">
                  <base-svg-icon name="rocket-on" />
                  <mdc-text tag="span">INITIATIVES</mdc-text>
                </header>
                <div class="app-section-search">
                  <auto-complete-outline
                    itemIcon="rocket"
                    autocompleteId="'initiative-search'"
                    class="initiative-search-autocomplete"
                    :items="initiativesKeys"
                    field="name"
                    displayField="key"
                    @set="routeToInitiative"
                    placeholder="Type here to find initiatives"
                    format="item-icon"
                    >
                  </auto-complete-outline>
                </div>
              </div>
              <section class="initiatives-content">
                <initiatives-cards @addNewInitiativeModalClose="setNewInitiativeModal" :showAddNewInitiative="showAddNewInitiativeModal" :isMobile="isMobile" v-if="teamProfiles && teamProfiles.length" />
              </section>
            </div>
          </app-section>
        </div>
      </app-layout>
  </div>
</template>

<script>
import AppLayout from '../../components/flexibly/AppLayout'
import AppSection from '../../components/flexibly/AppSection'
import { mapState } from 'vuex'
import InitiativesCards from '../../components/flexibly/InitiativesCards.vue'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'
import BaseSvgIcon from '../../components/flexibly/BaseSvgIcon.vue'
export default {
  name: 'Initiatives',
  computed: {
    ...mapState({
      profile: state => state.profile,
      teamProfiles: state => state.teams.teamProfiles,
      teams: state => state.teams.teams,
      initiatives: state => state.teams.initiatives
    }),
    initiativesKeys () {
      return this.initiatives.map((init) => {
        const key = `#${this.getTeamName(init.teamId)}${init.name.replace(' ', '-')}`
        return { ...init, key }
      })
    }
  },
  components: {
    AppLayout,
    AppSection,
    InitiativesCards,
    AutoCompleteOutline,
    BaseSvgIcon
  },
  data () {
    return {
      showAddNewInitiativeModal: false
    }
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.$root.$on('setNewInitiativeModal', (viewType) => {
      this.setNewInitiativeModal(viewType)
    })
  },

  beforeDestroy () {
    this.$root.$off('setNewInitiativeModal', (viewType) => {
      this.setNewInitiativeModal(viewType)
    })
  },
  mounted () {
    this.$root.$emit('enableSearch', false)
    this.$emit('setNavActive', 'Initiatives')
    if (this.$route) {
      if (this.$route.params) {
        if (this.$route.params.openModal === 'showAddNewInitiativeModal') {
          this.setNewInitiativeModal(true)
        }
      }
    }
  },
  methods: {
    getTeamName (teamId) {
      if (teamId !== this.profile.team) {
        if (this.teams) {
          const ft = this.teams.filter((team) => team.id === teamId)[0] || {}
          return `${ft.name || ''}/`
        }
      }
      return ''
    },
    setNewInitiativeModal (display) {
      this.showAddNewInitiativeModal = display
    },
    routeToInitiative (value, initiative) {
      if (initiative) {
        this.$router.push(`/initiativedetails/${initiative.id}`).catch(err => { console.error(err) })
      }
    }
  }
}
</script>

<style lang="scss">
.initiatives-wrapper {
  height: 100%;
  margin-bottom: 30px;
  .one-col-layout {
    .mdc-card {
      border-radius: 0;
    }
  }
  .initiatives-content-wrapper {
    .header-search-wrapper {
      display: grid;
      grid-template-rows: auto auto;
      margin: 30px 150px 0 150px;
      grid-template-columns: 200px 1fr;
      justify-content: center;
      align-content: center;
      .app-section-search {
        flex: unset;
        margin-right: unset;
      }
      .initiatives-title {
        display: flex;
        align-items: center;
        .icon-wrapper {
          height: 25px;
          width: 25px;
          margin-right: 5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .mdc-text {
          color: #0F3860;
          font-weight: 600;
          font-size: 16px;
          text-transform: uppercase;
        }
      }
    }
  }
  .initiatives-content {
    margin: 50px 100px 100px 100px;
    h1 {
      font-size: 30px;
      color: #4f4f4f;
      margin-bottom: 35px;
      text-align: center;
    }
  }
}
</style>
