<template>
  <div class="task-comment-box">
    <div class="task-comments">
      <template v-for="(activity, index) in taskCommentsActivity">
        <div v-if="activity.taskId === taskCopy.id && activity.isComment" :ref="'task' + activity.id" class="comment-wrapper" :key="activity.taskId + index">
          <div>
            <profile-initials :profileId="activity.fromId" :date="appDate" />
            <mdc-text typo="body2" tag="span">{{ getProfileFromId(activity.fromId) }}</mdc-text>
            <span v-if="activity.date" class="comment-time">
              <time-ago refresh long :datetime="activity.date.toDate()"></time-ago>
            </span>
          </div>
          <div class="comment-message">{{activity.message}}</div>
        </div>
        <div v-else-if="activity.taskId === taskCopy.id" :key="activity.taskId + index + 'else'">
            <div>
            <base-svg-icon name="task-activity-icon" />
            <mdc-text typo="body2" tag="span">{{ getProfileFromId(activity.userId) }}</mdc-text>

            <mdc-text v-if="activity.newTask" typo="body2" tag="span" style="font-weight:400;font-size:14px;"> created this task</mdc-text>
            <mdc-text v-else typo="body2" tag="span" style="font-weight:400;font-size:14px;"> updated this task</mdc-text>

            <span v-if="activity.created" class="comment-time">
              <time-ago v-if="activity.created" refresh long :datetime="activity.created.toDate()"></time-ago>
            </span>
          </div>
          <div class="comment-message" style="white-space: pre-line;" v-html="getActivityMessage(activity)">
          </div>
        </div>
      </template>
    </div>
    <message-area class="task-message-area" allowMentions :mentions="teamMentions" @send="sendComment" :placeholderText="'Type a comment'" :profileId="profile.id" :forDate="appDate" :sendTextLabel="'POST'" :disableSend="true" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import TimeAgo from '../TimeAgo'
import MessageArea from './MessageArea'
import { datetimeFormat } from '../../utils/time'
import ProfileInitials from '../../components/flexibly/ProfileInitials'
import BaseSvgIcon from './BaseSvgIcon.vue'
export default {
  name: 'TaskComments',
  computed: {
    ...mapState({
      appDate: state => state.appDate,
      profile: state => state.profile,
      teamProfiles: state => state.teams.teamProfiles,
      taskComments: state => state.teams.taskComments,
      taskActivity: state => state.teams.taskActivity
    }),
    taskCommentsActivity () {
      const comments = this.taskComments || []
      const activity = this.taskActivity || []
      let commentActivity = [...activity, ...comments]
      commentActivity = [...commentActivity.sort((a, b) => {
        const acDate = a.created ? a.created.toDate() : -1
        const bcDate = b.created ? b.created.toDate() : -1
        return acDate > bcDate ? 1 : -1
      })]
      return commentActivity
    },
    teamMentions () {
      if (this.teamProfiles) {
        return this.teamProfiles.map((prof) => {
          return { ...prof, name: prof.displayName, initials: this.initials(prof) }
        })
      }
      return []
    }
  },
  components: {
    ProfileInitials,
    TimeAgo,
    MessageArea,
    BaseSvgIcon
  },
  data () {
    return {
    }
  },
  props: {
    taskCopy: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    ...mapActions({
    }),
    sendComment (message) {
      this.$emit('addCommentTask', message)
    },
    getActivityMessage (activity) {
      const fieldsArr = []
      let fields = ''
      if (activity.newTask) {
        fieldsArr.push(`Task created at ${datetimeFormat(activity.newTask.toDate())}`)
      }
      if (activity.title) {
        fieldsArr.push('Title ' + activity.title)
      }
      if (activity.description) {
        fieldsArr.push('Description updated')
      }
      if (activity.completed) {
        fieldsArr.push(`Marked this task as complete ${datetimeFormat(activity.completed.toDate())}`)
      }
      if (activity.uncompleted) {
        fieldsArr.push(`Marked this task as incomplete ${datetimeFormat(activity.uncompleted.toDate())}`)
      }
      if (activity.assigned) {
        let connective = 'were'
        if (activity.assigned.length === 1) {
          connective = 'was'
        }
        fieldsArr.push(activity.assigned.join(', ') + ` ${connective} assigned`)
      }
      if (activity.initiatives) {
        let connective = 'were'
        if (activity.initiatives.length === 1) {
          connective = 'was'
        }
        fieldsArr.push(activity.initiatives.join(', ') + ` ${connective} tagged`)
      }
      if (activity.due) {
        fieldsArr.push('Deadline was ' + activity.due)
      }
      if (fieldsArr.length < 1) {
        fields = 'Task Updated'
      } else {
        fields = fieldsArr.join(' \n')
      }
      return `${fields}`
    },
    initials (profile) {
      if (profile) {
        let initialFirst = ''
        let initialLast = ''
        let displayNameParts = ['', '']
        displayNameParts = profile.displayName.split(' ')
        if (profile.firstName) {
          initialFirst = profile.firstName.trim().substring(0, 1)
        } else {
          initialFirst = displayNameParts[0].substring(0, 1)
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${initialFirst}${initialLast}`.toUpperCase()
      }
      return 'U'
    },
    getProfileFromId (id) {
      if (this.teamProfiles) {
        const profile = this.teamProfiles.filter(prof => prof.id === id)
        if (profile.length > 0) {
          return profile[0].displayName
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.task-comment-box {
  padding: 16px 20px;
  background: rgba(234,234,234,0.26);
  margin-top: auto;
  .task-message-area {
    display: flex;
    align-items: center;
    border: 0.5px solid #828282;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 10px;
    background: #ffffff;
    .profile-redirect {
      margin: auto;
    }
  }
  .task-comments {
    overflow: auto;
    height: auto;
    max-height: 140px;
    cursor: default;
    margin-bottom: 10px;
    & > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      & > div {
        display: flex;
        align-items: center;
      }
    }
    .mdc-text.mdc-typography--body2 {
      color: #333333;
      font-weight: 700;
    }
    * {
      margin-right: 5px;
    }
    .comment-time {
      color: rgba(51,51,51,0.4);
      font-size: 12px;
    }
    .profile-initials {
      margin: 0;
      margin-left: 2px;
    }
    .photo-wrapper {
      margin-left: 2px;
      margin-top: 2px;
      .profile-initials {
        margin: 0;
      }
    }
    .profile-redirect {
      margin-left: 3px;
      margin-right: 8px;
    }
    .comment-message {
      color: #404040;
      font-size: 14px;
      margin-left: 28px;
      line-height: 22px;
      margin-top: 3px;
      white-space: pre-wrap;
      width: calc(100% - 28px);
      word-break: break-word;
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #828282;
    }
    > :last-child {
      margin-bottom: 5px;
    }
  }
}
</style>
