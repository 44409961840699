<template>
  <mdc-button :title="title" class="button-detail" v-bind:class="{'selected': selected, 'profile': buttonType === 'profile', 'skeleton': profileSkeleton}" >
    <div v-if="profileSkeleton" class="profile-skeleton">
      <base-svg-icon name="avatar-no-profile-white" class="no-avatar"/>
      <span class="blank-bar"></span>
    </div>
    <div v-if="initiativeSkeleton" class="initiative-skeleton">
      <base-svg-icon name="rocket-lightgray" class="no-avatar"/>
      <span class="blank-bar"></span>
    </div>
    <template v-else>
      <profile-initials v-if="buttonType === 'profile'" :profileId="profileId" :date="appDate" showNameAndInitial noProfileDetailClick :showYou="onlyMe" :onlyMe="onlyMe && profileId === profile.id" noEditProfile/>
      <base-svg-icon v-else-if="buttonType === 'image'" :name="imageName" class="button-image"/>
      <mdc-text v-if="buttonText && buttonType !== 'profile'" tag="span" typo="caption" class="button-text">{{ buttonText }}</mdc-text>
      <base-svg-icon v-if="tickSelect" class="tick-select" :name="selected ? 'round_checked' : 'round_unchecked'"/>
    </template>
  </mdc-button>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProfileInitials from './ProfileInitials.vue'
import BaseSvgIcon from './BaseSvgIcon.vue'

export default {
  name: 'Templates',
  components: {
    ProfileInitials,
    BaseSvgIcon
  },
  props: {
    profileSkeleton: {
      type: Boolean,
      default: false
    },
    initiativeSkeleton: {
      type: Boolean,
      default: false
    },
    onlyMe: {
      type: Boolean,
      default: false
    },
    imageName: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: '' // profile, image
    },
    profileId: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    tickSelect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  beforeDestroy () {},
  created () {},
  computed: {
    ...mapState([
      'appDate',
      'profile'
    ]),
    ...mapState({
    })
  },
  mounted () {},
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    })
  },
  watch: {
  }
}
</script>

<style lang="scss">
  .button-detail {
    min-width: 20px;
    text-transform: none;
    border: 1px solid #E1E5E9;
    width: 95px;
    height: 32px;
    padding: 0 5px 0 7px;
    justify-content: flex-start;
    position: relative;
    background: #fff !important;
    &.skeleton {
      pointer-events: none;
    }
    .profile-skeleton {
      display: flex;
      align-items: center;
      .no-avatar {
        margin-right: 6px;
      }
      .blank-bar {
        height: 8px;
        background: #f1f1f1;
        width: 50px;
        max-width: 55px;
      }
    }
    .initiative-skeleton {
      display: flex;
      align-items: center;
      .no-avatar {
        margin-right: 6px;
      }
      .blank-bar {
        height: 8px;
        background: #f1f1f1;
        width: 50px;
        max-width: 55px;
      }
    }
    &.selected {
      border-color: #404040;
    }
    .profile-redirect {
      margin-right: 0;
      .profile-firstname-initial {
        max-width: 50px;
        font-size: 12px;
        color: #404040;
        font-weight: 600;
      }
    }
    .button-image {
      margin-right: 6px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .tick-select {
      position: absolute;
      top: -5px;
      right: -5px;
    }
    .button-text {
      color: #404040;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 90px;
      overflow: hidden;
      text-transform: capitalize;
    }
  }
</style>
