<template>
  <task
    v-if="task"
    taskModalMode
    emitClickOutside
    :editTask="editTask"
    :showTaskTabs="showTaskTabs"
    :taskListId="taskListId"
    :fromInitiative="fromInitiative"
    :location="location"
    :taskPlan="{ date: assignDate }"
    @saveSelected="saveSelectedTask"
    @taskCompleted="editTask = false"
    @editChange="setTaskToEditDuringComment"
    @clickOutside="clickOutside"
    :task="task"
    :key="'task-' + task.id" />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Task from '../../components/flexibly/Task.vue'

export default {
  name: 'TaskDetailsDialog',
  components: {
    Task
  },
  data () {
    return {
      showTaskTabs: true,
      taskListId: [],
      fromInitiative: [],
      location: false,
      assignDate: false
    }
  },
  props: {
    task: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  created () {},
  mounted () {
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    editTask () {
      if (this.task) {
        return { ...this.task, showDetail: true }
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      setDisplayTask: 'setDisplayTask'
    }),
    saveSelectedTask () {
    },
    setTaskToEditDuringComment () {
    },
    clickOutside () {
      this.closeDialog()
    },
    closeDialog () {
      this.setDisplayTask(false)
    }
  },
  watch: {
    taskDetails: {
      handler (newf, oldf) {
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss">
.task-details-dialog {
}
</style>
