<template>
  <div v-if="workspace" class="search-wrapper" @keydown.esc="closeSearch($event)" tabindex="0">
  <div class="search-scrim"></div>
  <div v-if="searchClient" class="container all-search">
    <ais-instant-search :search-client="searchClient" :index-name="workspace.id+'-blocks'">
      <stat @nbHitsChange="hitsChange($event, 'notes')" />

      <ais-configure v-if="!typeFilter" :hits-per-page.camel="5" />
      <ais-configure v-else :hits-per-page.camel="10" />

        <ais-search-box ref="searchInput" class="search-box-input" v-model="query" />
        <div class="search-result-box">
        <div class="search-results">
        <ais-state-results>
          <template slot-scope="{ state: { query } }">

            <div v-if="query"  class="result-section">

              <div v-if="query" class="result-facets">Showing results for "{{query}}" 1 to {{getLeast(totalHits, 15)}}  of {{totalHits}}</div>

              <div class="result-facets">
                <span class="result-facet-item"
                @click="typeFilter = null"
                :class="{ active: !typeFilter }">All ({{totalHits}})</span>
                <span
                  v-for="(value, name) in hitTotalsFiltered"
                  :key="'facet-item-' + name"
                  class="result-facet-item"
                  @click="typeFilter = name"
                  :class="{ active: typeFilter === name }">
                  <tempate v-if="name === 'notes'">
                    Updates
                  </tempate>
                  <template v-else>{{name}}</template> ({{value}})
                </span>
              </div>

              <section v-if="!typeFilter || typeFilter === 'notes'">
                <div v-if="hitTotalsFiltered.notes" class="result-section">
              <div class="results-heading">UPDATES</div>

              <ais-hits v-if="query">
              <div @click="routeNote(item)" @mouseover="previewNote(item)" slot="item" slot-scope="{ item }">
                  <img class="resut-icon" :src="getIcon('note', item)"/>
                  <div>
                    <h4>{{ item.pageName }} {{ item.content }}</h4>
                    <p>Updates > {{getNoteType(item.type)}} > {{item.pageName}}</p>
                  </div>
              </div>
              </ais-hits>
              </div>
              </section>
            </div>
            <div v-else>
            </div>

            <ais-index v-if="query" :search-client="searchClient" :index-name="workspace.id+'-tasks'">
            <stat @nbHitsChange="hitsChange($event, 'tasks')" />
            <section v-if="!typeFilter || typeFilter === 'tasks'">
            <div v-if="hitTotalsFiltered.tasks" class="result-section">
              <div class="results-heading">TASKS</div>
              <ais-hits :key="'task-hits'">
                  <div @click="routeTask(item)" @mouseover="previewTask(item)" slot="item" slot-scope="{ item }">
                  <img class="resut-icon" :src="getIcon('task', item)"/>
                  <div>
                    <h4 v-html="item.text" ></h4>
                    <p>Tasks</p>
                  </div>
                  </div>
              </ais-hits>
            </div>
             </section>
            </ais-index>

            <ais-index v-if="query" :search-client="searchClient" :index-name="workspace.id+'-profiles'">
              <stat @nbHitsChange="hitsChange($event, 'users')" />
              <section v-if="!typeFilter || typeFilter === 'users'">
              <div class="result-section">
                <div  v-if="hitTotalsFiltered.users" class="results-heading">USERS</div>
                <ais-hits :key="'user-hits'">
                  <div @click="routeUser(item)" @mouseover="previewUser(item)" slot="item" slot-scope="{ item }">
                    <img class="resut-icon" :src="getIcon('user', item)"/>
                    <div>
                      <h4>{{ item.displayName }}</h4>
                      <p>Users > {{item.displayName}}</p>
                    </div>
                  </div>
                </ais-hits>
              </div>
              </section>
            </ais-index>

          </template>
        </ais-state-results>
      </div>
          <div class="search-preview">
            <div class="preview-box" v-if="preview">
              <div v-if="lookingForward && preview === 'lookingForward'" class="looking-forward-note">
                <h4>{{lookingForward.pageName}}</h4>
                <div v-html="lookingForward.html"/>
              </div>
              <div v-if="meetingNote && preview === 'meetingnote'" class="meeting-note">
                <h4>{{meetingNote.pageName}}</h4>
                <div v-html="meetingNote.html"/>
              </div>
              <div v-if="task && preview === 'task'" class="task">
                <h4 v-html="task.text"></h4>
                <div v-html="task.description"/>
              </div>
              <div v-if="user && detailsForProfile && preview === 'user'" class="user-preview">
                <h4>{{user.displayName}}</h4>
                <profile-details-dialog
                  :dialogStyle="false"
                  :id="'detailsforprofile'+ detailsForProfile.profileId"
                  :profileDetails="detailsForProfile"
                  :showDialog="true" />
              </div>
            </div>
          </div>
      </div>
    </ais-instant-search>
  </div>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite'
import 'instantsearch.css/themes/satellite-min.css'
import Stat from '../algolia//Stat'
import { mapState, mapActions, mapGetters } from 'vuex'
import ProfileDetailsDialog from './ProfileDetailsDialog.vue'
import moment from 'moment'
import fb from '../../store/firebase'
import { appSettings } from '../../settings'

export default {
  name: 'Search',
  components: {
    Stat,
    ProfileDetailsDialog
  },
  computed: {
    ...mapState({
      teamProfiles: state => state.teams.teamProfiles,
      profile: state => state.profile,
      workspace: state => state.workspace
    }),
    ...mapGetters({
      getTaskById: 'teams/getTaskById'
    }),
    hitTotalsFiltered () {
      Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate))
      const filtered = Object.filter(this.hitTotals, ([name, hits]) => {
        console.log('name hits', name, hits)
        return hits >= 1
      })
      return filtered
    },
    totalHits () {
      let total = 0
      if (this.hitTotals) {
        for (const indexHits of Object.values(this.hitTotals)) {
          total = total + indexHits
        }
      }
      return total
    }
  },
  data () {
    return {
      preview: false,
      lookingForward: null,
      meetingNote: null,
      hitTotals: {},
      typeFilter: null,
      task: null,
      user: null,
      detailsForProfile: null,
      query: '',
      searchClient: null
    }
  },
  methods: {
    ...mapActions({
      getLookingForwardPageById: 'teams/getLookingForwardPageById',
      getMeetingPageById: 'teams/getMeetingPageById',
      getMeetingByPageId: 'teams/getMeetingByPageId',
      getProfileById: 'teams/getProfileById',
      getNextMeetingWithUser: 'teams/getNextMeetingWithUser',
      getLastSameLocation: 'teams/getLastSameLocation'
    }),
    getLeast (a, b) {
      if (a > b) {
        return b
      }
      return a
    },
    closeSearch (event) {
      if (!this.query) {
        this.$emit('closeSearch')
      } else {
        this.query = ''
      }
    },
    async displayProfileDetails () {
      const profileId = this.user.objectID
      this.detailsForProfile = null
      const lastInLoc = await this.getLastSameLocation({ users: [this.profile.id, profileId] })
      let lastInLocation = ''
      if (lastInLoc) {
        lastInLocation = moment(lastInLoc).format('Do MMMM YYYY')
      }
      this.getProfileById({ profileId }).then(async (userProfile) => {
        let nextMeetingDate = ''
        const msUserEmail = userProfile.msEmail || userProfile.email
        const googleUserEmail = userProfile.googleEmail
        const nextMeeting = await this.getNextMeetingWithUser({ msUserEmail, googleUserEmail })
        if (nextMeeting) {
          const mdate = nextMeeting.date + ' ' + nextMeeting.startTime
          nextMeetingDate = moment(mdate, 'DD/MM/YYYY h:mm').format('Do MMMM YYYY h:mm')
        }
        this.detailsForProfile = {
          profileId,
          workingAt: null,
          name: this.getDisplayName(userProfile),
          lastInLocation,
          nextMeeting: nextMeetingDate
        }
      })
    },
    getDisplayName (profile) {
      if (profile) {
        return profile.displayName ? profile.displayName : ''
      }
      return ''
    },
    previewUser (user) {
      if (this.user) {
        if (user.objectID !== this.user.objectID) {
          this.meetingNote = null
          this.task = null
          this.user = user
          this.preview = 'user'
          this.displayProfileDetails()
        }
      } else {
        this.meetingNote = null
        this.task = null
        this.user = user
        this.preview = 'user'
        this.displayProfileDetails()
      }
    },
    getNoteType (noteType) {
      const convertedTypes = {
        meetingPages: 'Meeting Notes',
        lookingForwardPages: 'Team Updates'
      }
      return convertedTypes[noteType] || noteType
    },
    previewNote (note) {
      // console.log('Search.previewNote', note)
      if (note) {
        const mId = this.meetingNote ? this.meetingNote.id : null
        const lfId = this.lookingForward ? this.lookingForward.id : null

        if (note.pageId !== mId && note.pageId !== lfId) {
          this.lookingForward = null
          this.meetingNote = null
          this.task = null
          this.user = null

          const pageId = note.pageId
          const pageType = note.type
          if (pageType === 'meetingPages') {
            this.preview = 'meetingnote'
            this.getMeetingPageById({
              teamId: this.profile.team,
              pageId
            }).then((meetingPage) => {
              this.meetingNote = meetingPage
            })
          } else if (pageType === 'lookingForwardPages') {
            this.preview = 'lookingForward'
            this.getLookingForwardPageById({
              teamId: this.profile.team,
              pageId
            }).then((lookingForward) => {
              this.lookingForward = lookingForward
            })
          }
        }
      }
    },
    routeNote (note) {
      console.log('routeNote', note)
      const pageType = note.type
      if (pageType === 'meetingPages') {
        if (this.$route.name !== 'Dashboard') {
          this.$router.push('/dashboard')
        }
        setTimeout(() => {
          this.getMeetingByPageId({
            userId: this.profile.id,
            pageId: note.pageId
          }).then((meeting) => {
            console.log('getMeetingByPageId', meeting)
            if (meeting) {
              const uid = meeting.iCalUId
              const meetingLabel = `${meeting.subject}`
              const meetingLink = 'meetingpage/' + uid
              this.$root.$emit('changeComposerView', 'meetingPage', meetingLabel, { meetingId: meeting.id, link: meetingLink, label: meetingLabel, subject: meeting.subject, when: meeting.date, meetingDetails: [meeting] })
            }
          })
        }, 300)
      } else if (pageType === 'lookingForwardPages') {
        if (this.$route.name === 'People') {
          this.$root.$emit('setRightSectionDate', moment(note.date * 1000).toDate())
          this.$root.$emit('setRightSectionType', 'teamUpdates')
        } else {
          this.$router.push({ name: 'People', params: { changeView: 'teamUpdates', showDate: moment(note.date * 1000).toDate() } })
        }
      }
      setTimeout(() => {
        this.query = ''
        this.$emit('closeSearch')
      }, 100)
    },
    routeUser (user) {
      // TODO: Routing to user screen?
    },
    routeTask (task) {
      if (this.$route.name !== 'Dashboard') {
        this.$router.push('/dashboard')
      }
      setTimeout(() => {
        this.$root.$emit('changeComposerView', 'allTasks', 'ALL TASKS')
        setTimeout(() => {
          this.$emit('closeSearch')
        }, 100)
      }, 300)
    },
    previewTask (task) {
      // console.log('Search.previewTask', task)
      this.meetingNote = null
      this.task = null
      this.user = null
      this.preview = 'task'
      this.getTaskById(task.objectID).then((task) => {
        this.task = task
      })
    },
    getIcon (type, item) {
      return `/static/img/search-icons/${type}-search.svg`
    },
    hitsChange (hits, indexName) {
      const newHits = { ...this.hitTotals }
      newHits[indexName] = hits
      this.hitTotals = newHits
      this.preview = false
    }
  },
  async mounted () {
    const algoliaId = appSettings.algoliaId
    const algoliaKey = appSettings.algoliaKey
    if (!this.profile.searchKey) {
      const generateSearchKey = fb.functions.httpsCallable('generateSearchKey')
      const params = { userId: this.profile.id, teamId: this.profile.team, workspaceId: this.workspace.id }
      await generateSearchKey(params).then((res) => {
        if (res.data.error) {
          // Fallback to all
          this.searchClient = algoliasearch(
            algoliaId,
            algoliaKey
          )
        }
        if (res.data) {
          if (res.data.searchKey) {
            this.profile.searchKey = res.data.searchKey
            this.searchClient = algoliasearch(
              algoliaId,
              this.profile.searchKey
            )
          }
        }
      })
    } else {
      this.searchClient = algoliasearch(
        algoliaId,
        this.profile.searchKey
      )
    }
  }
}
</script>

<style>
.search-scrim {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity:0.4;
  background-color:#7A95B5;
  z-index: 98;
  cursor:pointer;
}
.all-search {
  position:absolute;
  width: calc(100% - 47px);
  margin-left:20px;
  margin-right:20px;
  background-color:#F5F5F5;
  top:0;
  z-index:99;
  color: #404040;
  max-height: calc(100% - 50px);
  padding-bottom:20px;

}

.all-search .ais-InstantSearch {
  max-height: calc(100% - 50px);
}

.all-search .search-box-input {
  padding:30px;
}

.all-search .ais-Hits-item {
  padding:5px;
  border: none;
  box-shadow: none;
}
.all-search .ais-Hits-item:hover {
  cursor:pointer;
  background: #EEF6FF !important;
}
.all-search .ais-Hits-item > div {
  width:100%;
  display:flex;
}
.all-search .ais-Hits-item > div > div {
  flex:1;
}

.all-search .ais-Hits-item h4, .ais-Hits-item p {
  margin: 3px;
}
.all-search .search-result-box {
  display:flex;
  background: white;
}
.all-search .result-facets {
  padding:10px;
  display:flex;
  width:100%;
  padding-bottom: 20px;
  border-bottom: 1px solid silver;
  width: calc(100% - 40px);
  margin-left: 10px;
}

.all-search .result-facets .result-facet-item {
  color:#00498D;
  cursor:pointer;
  padding-left:10px;
  padding-right:10px;
  text-transform: capitalize;
}

.all-search .result-facets:first-child {
  padding-left:0px !important;
}

.all-search .result-facets .result-facet-item.active {
  color: #404040;
  font-weight:bold;
}

.all-search .results-heading {
  padding:10px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #404040;
}

.all-search .search-result-box .search-results, .all-search .search-result-box .search-preview {
  width:50%;
  max-height: 500px;
  overflow: auto;
}

.all-search .search-result-box .search-preview {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.13) 0%, rgba(248, 248, 248, 1) 2.45%);
}
.all-search .search-result-box .search-preview .preview-box {
  padding:30px;
}
.preview-box h4 {
  text-align: center;
}
</style>

<style lang="scss">
  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;

      > input {
        flex: 0 0 auto;
        margin-right: 0.5rem;
      }

      &[data-checked="true"] {
        * {
          text-decoration: line-through;
        }
      }

      p {
        margin-top: 3px;
        margin-bottom:3px;
      }
    }
  }

  .all-search {
    [data-type="note-block"] {
      position: relative;
      &.comment-mode {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px 1px rgba(0,0,0, 0.36);
        border-radius: 4px;
        min-height: 100px;
        align-items: baseline;
        display: block;
        p {
          padding-top: 5px;
        }
        .block-comment-box {
          margin-top: 15px;
          padding: 16px 10px;
          background: rgba(234,234,234,0.26);
          .block-comments {
            overflow: auto;
            height: auto;
            max-height: 140px;
            cursor: default;
            margin-bottom: 10px;
            & > div.comment-wrapper {
              display: flex;
              flex-direction: column;
              margin-bottom: 15px;
              .comment-from-details {
                display: flex;
                align-items: center;
              }
            }
            .mdc-text.mdc-typography--body2 {
              color: #333333;
              font-weight: 700;
            }
            .comment-time {
              color: rgba(51,51,51,0.4);
              font-size: 12px;
            }
            .profile-initials {
              margin: 0;
            }
            .comment-message {
              color: #404040;
              font-size: 14px;
              margin-left: 28px;
              line-height: 22px;
              margin-top: 3px;
            }
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-track {
              background: transparent;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: #828282;
            }
            > :last-child {
              margin-bottom: 5px;
            }
          }
          .block-message-area {
            display: flex;
            align-items: center;
            border: 0.5px solid #828282;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 7px 10px;
            background: #ffffff;
            .profile-redirect {
              margin: auto;
            }
          }
        }
        .block-comment-section {
          display: none;
        }
      }
      p {
        margin: 0;
        color: #404040;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        padding-left: 10px;
        padding-top: 5px;
        &::before {
          content: '';
          margin-right: 10px;
          width: 4px;
          height: 4px;
          background-color: #cc0000;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          margin-bottom: 3px;
        }
        span {
          flex: inherit;
          margin-top: -3px;
          &.hashtag {
            margin-bottom: 0;
            height: 15px;
            font-size: 14px;
            flex: unset;
            display: inline-flex;
            padding: 1px 2px;
            color: var(--hashtagtext-color);
            position: relative;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            line-height: 17px;
            font-weight: normal;
            font-weight: normal;
            &::before {
              content: '#';
              color: var(--hashtag-color);
              font-weight: 400;
              position: absolute;
            }
          }
        }
        .block-comment-section {
          display: inline-flex;
          vertical-align: bottom;
          align-items: center;
          margin-left: 15px;
          .block-comment-count {
            display: inline-flex;
            align-items: center;
            line-height: 21px;
            span {
              display: flex;
              align-items: center;
              color: #7A95B5;
              &.count {
                font-size: 10px;
                font-weight: 600;
                text-indent: 2px;
              }
            }
          }
        }
      }
      [data-type="note-block"] {
        margin-left: 20px;
      }
    }
  }
</style>
