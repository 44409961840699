<template>
  <div class="tasks-count-wrapper">
    <section class="completed-new-counts count-types">
      <svg-percent-ring class="completed-tasks-count" :title="'' + completePercent + '% ' + completedCount + '/' + totalCount" :tHeight="60" :tWidth="60" :tFontWeight="700" :tFontSize="24" :tFill="'#959EA7'" :tPosX="'5%'" :tPosY="'20%'" :svgBgColor="'#FAFAFA'" :strokeColor="'#959EA7'" :height="70" :width="70" :viewBox="'0 0 70 70'" :cRadius="32" :centerX="35" :centerY="35" :percentage="completePercent" :id="'completed-tasks'" :showText="completedText" :textClass="''"/>
      <activity-box class="new-tasks-count" :activityText="newCountText" :fontSize="'38px'" :fontColor="'#FFC700'" :fontWeight="'700'" :typeFontSize="'8px'" :typeFontColor="'#FFC700'" :typeFontWeight="'700'" :activityType="'assigned'" :bgColor="'#F9F9F9'" :height="'50px'" :width="'50px'" />
    </section>
    <section class="office-home-counts count-types">
      <activity-box
        :class="[isDropTarget('office') ? 'highlight office' : null ]"
        @dragenter.native.prevent="setDropTarget('office', $event)"
        @dragover.native.prevent
        @dragleave.native="setDropTarget(false)"
        @drop.native.prevent="scheduleDrop"
        class="office-tasks-count" :activityText="officeTasks" :fontSize="'36px'" :fontColor="'#2B8AFB'" :fontWeight="'700'" :bgColor="'#fff'" :borderStyle="'dashed'" :borderColor="'#2B8AFB'" :height="'50px'" :width="'50px'" />
      <activity-box
        :class="[isDropTarget('remote') ? 'highlight remote' : null ]"
        @dragenter.native.prevent="setDropTarget('remote', $event)"
        @dragover.native.prevent
        @dragleave.native="setDropTarget(false)"
        @drop.native.prevent="scheduleDrop"
        class="home-tasks-count" :activityText="homeTasks" :fontSize="'36px'" :fontColor="'#00BE13'" :fontWeight="'700'" :bgColor="'#fff'" :borderStyle="'dashed'" :borderColor="'#00BE13'" :height="'50px'" :width="'50px'" />
      <div class="drag-text-wrapper" v-if="profile.showDragHelpText">
        <mdc-text tag="span" typo="caption">Drag your tasks to your preferred location</mdc-text>
        <base-svg-icon name="cross-bg" clickable @click.native="closeDrag"/>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import SvgPercentRing from './SvgPercentRing.vue'
import ActivityBox from './ActivityBox.vue'
import BaseSvgIcon from './BaseSvgIcon.vue'
import moment from 'moment'
export default {
  name: 'TasksCountDetails',
  components: {
    SvgPercentRing,
    ActivityBox,
    BaseSvgIcon
  },
  props: {
    plannedTasks: [],
    unplannedTasks: []
  },
  data () {
    return {
      dropTarget: false
    }
  },
  beforeDestroy () {},
  created () {},
  computed: {
    ...mapState([
      'profile',
      'plannerWeekDetail'
    ]),
    ...mapState({
      tasks: state => state.teams.tasks
    }),
    ...mapGetters({
      getTaskById: 'teams/getTaskById'
    }),
    allTasks () {
      if (this.plannedTasks?.length >= 0 || this.unplannedTasks?.length >= 0) {
        const allTasks = [...this.plannedTasks, ...this.unplannedTasks]
        return allTasks
      }
      return []
    },
    homeTasks () {
      if (this.plannedTasks && this.plannedTasks.length > 0) {
        return this.plannedTasks.filter(tasks => tasks.scheduleDate === 'next at home').length
      }
      return 0
    },
    officeTasks () {
      if (this.plannedTasks && this.plannedTasks.length > 0) {
        return this.plannedTasks.filter(tasks => tasks.scheduleDate === 'next at office').length
      }
      return 0
    },
    completedTasks () {
      if (this.tasks) {
        return this.tasks.filter(task => task.completed)
      }
      return []
    },
    completedCount () {
      const currentWeekCompletedTask = this.completedTasks.filter(task => {
        const startDate = moment(this.plannerWeekDetail?.startOfWeek)
        const endDate = moment(this.plannerWeekDetail?.endOfWeek)
        const completedOn = task.completedOn ? task.completedOn.toDate ? task.completedOn.toDate() : task.completedOn : false
        if (!completedOn) {
          return false
        }
        const complDate = moment(completedOn).startOf('day')
        return complDate.isBetween(startDate, endDate, 'days', true) && (task.selfAssignedTo === this.profile.id || task.shareUsers?.includes(this.profile.id))
      })
      return currentWeekCompletedTask.length
    },
    totalCount () {
      const completed = this.completedCount
      const totalTasks = this.allTasks.length
      return completed + totalTasks
    },
    completedText () {
      return `<span class="completed-count">${this.completedCount}<span class="done-text">Done</span></span>`
    },
    completePercent () {
      const completed = this.completedCount
      const totalTasks = this.totalCount
      if (completed >= 0 && totalTasks >= 0) {
        return Math.ceil((completed / totalTasks) * 100)
      }
      return 0
    },
    inCompletePercent () {
      const completed = this.completedCount
      const totalTasks = this.allTasks.length
      if (completed >= 0 && totalTasks >= 0) {
        const inComplTasks = totalTasks - completed
        return Math.ceil((inComplTasks / totalTasks) * 100)
      }
      return 0
    },
    newCount () {
      const createdOnTasks = this.tasks.filter(task => task.createdOn)
      const currentWeekCreatedTask = createdOnTasks.filter(task => {
        const startDate = moment(this.plannerWeekDetail?.startOfWeek)
        const endDate = moment(this.plannerWeekDetail?.endOfWeek)
        const createdDate = moment(task.createdOn.toDate()).startOf('day')
        // console.log(task.id, task.text, task.createdOn.toDate(), createdDate.isBetween(startDate, endDate, 'day', true), createdDate.isBetween(startDate, endDate, 'days', true) && (task.selfAssignedTo === this.profile.id || task.shareUsers?.includes(this.profile.id)))
        return createdDate.isBetween(startDate, endDate, 'days', true) && (task.selfAssignedTo === this.profile.id || task.shareUsers?.includes(this.profile.id))
      })
      return currentWeekCreatedTask.length
    },
    newCountText () {
      return `<span class="new-count">${this.newCount}<span class="new-text">NEW</span></span>`
    }
  },
  mounted () {},
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    ...mapActions({
      saveTask: 'teams/saveTask',
      saveTaskPlan: 'teams/saveTaskPlan',
      getTaskPlan: 'teams/getTaskPlan'
    }),
    closeDrag () {
      this.updateProfile({ profile: { showDragHelpText: false }, noSnack: true })
    },
    setDropTarget (schedule, event) {
      // console.log('setDropTarget', day, event)
      this.dropTarget = schedule
    },
    isDropTarget (schedule) {
      return this.dropTarget === schedule
    },
    async scheduleDrop (event) {
      const scheduleType = this.dropTarget
      const taskId = event.dataTransfer.getData('taskId')
      if (taskId && scheduleType) {
        const task = await this.getTaskById(taskId)
        const existingTaskPlan = await this.getTaskPlan({ teamId: this.profile.team, userId: this.profile.id, taskId })
        if (existingTaskPlan[0] && existingTaskPlan[0].type) {
          if (existingTaskPlan[0].type === scheduleType) {
            return
          }
        }
        const tp = {
          teamId: this.profile.team,
          userId: this.profile.id,
          id: existingTaskPlan[0] ? existingTaskPlan[0].id || false : false,
          taskId: taskId || 'no task id',
          planDate: '',
          type: scheduleType
        }
        this.saveTaskPlan(tp).then(() => {
          if (scheduleType) {
            task.isPlanPresent[this.profile.id] = true
          } else {
            task.isPlanPresent[this.profile.id] = false
          }
          const taskUpdates = { isPlanPresent: task.isPlanPresent }
          if (!(task.selfAssignedTo === this.profile.id) && (!task.shareUsers.includes(this.profile.id))) {
            task.shareUsers.push(this.profile.id)
            taskUpdates.shareUsers = task.shareUsers
          }
          this.saveTask({ teamId: this.profile.team, taskId, task: taskUpdates })
        })
      }
      this.dropTarget = false
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
  .tasks-count-wrapper {
    margin-top: 25px;
    margin-left: 35px;
    display: flex;
    .count-types {
      display: flex;
      align-items: center;
      &:not(:last-of-type) {
        margin-right: 70px;
      }
      &.completed-new-counts {
        .completed-tasks-count {
          margin-right: 25px;
          .completed-count {
            display: flex;
            align-items: center;
            flex-direction: column;
            .done-text {
              font-size: 10px;
              font-weight: 500;
              color: #959EA7;
            }

          }
        }
        .new-tasks-count {
          .new-count {
            display: flex;
            align-items: center;
            flex-direction: column;
            .new-text {
              font-weight: 700;
              font-size: 12px;
            }
          }
        }
      }
      &.office-home-counts {
        position: relative;
        > * {
          border-radius: 10px;
          border: 0.5px;
        }
        .highlight {
          &.office {
            box-shadow: 0 0 5px var(--office-icon);
          }
          &.remote {
            box-shadow: 0 0 5px var(--home-icon);
          }
        }
        .office-tasks-count {
          margin-right: 25px;
          position: relative;
          &:before {
            background-image: url('../../assets/flexibly/icons/office.svg');
            background-size: 50px 50px;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.1;
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
          }
        }
        .home-tasks-count {
          position:relative;
          &:before {
            background-image: url('../../assets/flexibly/icons/home.svg');
            background-size: 50px 50px;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.1;
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
          }
        }
        .drag-text-wrapper {
          display: flex;
          align-items: center;
          top: 100%;
          position: absolute;
          white-space: nowrap;
          .mdc-text {
            font-size: 10px;
            font-weight: 300;
            color: #404040;
          }
          .icon-wrapper {
            margin-left: 3px;
            align-self: flex-start;
          }
        }
      }
    }
  }
</style>
