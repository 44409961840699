import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import NoteBlock from './NoteBlock.vue'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state'
import { NodeRange } from 'prosemirror-model'
import { joinPoint } from 'prosemirror-transform'
// import { joinPoint, findWrapping } from 'prosemirror-transform'
// import { InputRule } from 'prosemirror-inputrules'

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export const inputRegex = /^\s*([-+*])\s$/

const hasNoteBlock = (fragment) => {
  // console.log('NoteBlockExtention.hasNoteBlock', fragment)

  if (fragment.type) {
    return false
  }

  for (const nodeContent of fragment.content) {
    if (nodeContent.type) {
      if (nodeContent.type.name === 'noteblock') {
        return true
      }
    }
  }
  return false
}

const BlockSelection = () => {
  return new Plugin({
    key: new PluginKey('blockselection'),
    props: {
      decorations (state) {
        const selection = state.selection
        const decorations = []
        let lastNoteBlock = false
        state.doc.nodesBetween(selection.from, selection.to, (node, position) => {
          if (node.isBlock && node.type.name === 'noteblock') {
            lastNoteBlock = {
              start: position,
              end: position + node.nodeSize,
              node
            }
          }
        })
        if (lastNoteBlock) {
          // console.log('BlockSelection.lastNoteBlock', lastNoteBlock)
          decorations[0] = (Decoration.node(lastNoteBlock.start, lastNoteBlock.end, { class: 'focused' }))
        }
        return DecorationSet.create(state.doc, decorations)
      }
    }
  })
}

export default Node.create({
  name: 'noteblock',

  group: 'block',

  content: 'block+',

  selectable: false,

  draggable: true,

  parseHTML () {
    return [
      {
        tag: 'div[data-type="note-block"]'
      }
    ]
  },

  renderHTML ({ node, HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'note-block' }), 0]
  },

  addAttributes () {
    return {
      'data-note-id': {
        default: uuidv4()
      }
    }
  },

  addCommands () {
    return {
      /**
       * Toggle wrap a NoteBlock node
       */
      insertNoteBlock: () => ({ commands }) => {
        // console.log('NoteBlockExtension.toggleWrap', commands)
        // console.log('NoteBlockExtension.insertNoteBlock', noteId)
        const uid = uuidv4()
        return commands.toggleWrap(this.type, { 'data-note-id': uid })
      },
      insertNoteBlockAfter: (textSelection) => ({ tr, dispatch, commands }) => {
        if (dispatch) {
          // console.log('NoteBlockExtension.insertNoteBlockAfter', textSelection, cursorPos)
          const schema = this.editor.schema
          const state = this.editor.view.state
          const { $from } = state.selection // pos of parent block
          const endOfBlock = $from.end()
          const uid = uuidv4()
          // console.log('insertNoteBlockAfter', state, $from, $to, endOfBlock, uid)
          const newNoteBlock = schema.nodes.noteblock.createAndFill({ 'data-note-id': uid })
          tr.insert(endOfBlock + 1, newNoteBlock)
          // console.log('insertNoteBlockAfter', state, endOfBlock, insertTrans)
          const transaction = tr.setSelection(new TextSelection(tr.doc.resolve(endOfBlock + 3)))
          dispatch(transaction)
          // Move slice cursorPOS to endOfBlock to new noteblock
        }
        return true
      },
      joinNoteBlockBackwards: (textSelection) => ({ tr, dispatch, commands }) => {
        if (dispatch) {
          // console.log('joinNoteBlockBackwards', tr)
          const state = this.editor.view.state
          const { $from, $to } = this.editor.view.state.selection
          // console.log('joinNoteBlockBackwards', state)
          if (state.selection.$anchor.nodeBefore) {
            const slice = state.selection.node
            const startOfBlock = $from.pos
            const endOfBlock = $to.pos
            // console.log('joinNoteBlockBackwards', this.editor.view.state, startOfBlock, endOfBlock, endOfBlock - 3, slice, textSelection)
            tr.deleteRange(startOfBlock, endOfBlock)
            tr.insert(startOfBlock - 1, slice)
            const transaction = tr.setSelection(new TextSelection(tr.doc.resolve(textSelection.$anchor.pos - 1)))
            // console.log('transaction', transaction)
            dispatch(transaction)
          }
        }
        return true
      },
      joinNoteBlockContentBackwards: (textSelection, parentSelection) => ({ tr, dispatch, commands }) => {
        if (dispatch) {
          const state = this.editor.view.state
          const { $from, $to, $anchor } = this.editor.view.state.selection
          let nodeBefore = state.selection.$anchor.nodeBefore
          // eslint-disable-next-line no-unused-vars
          const nodeAfter = state.selection.$anchor.nodeAfter
          const afterEmpty = nodeAfter.content.size <= 2
          console.log('joinNoteBlockContentBackwards 1', state.selection, textSelection, nodeBefore, nodeAfter, afterEmpty, $anchor)
          if (nodeBefore) {
            if (nodeBefore.type.name === 'noteblock') {
              let offset = 2
              let depthOffset = 2 + $anchor.depth
              if (nodeBefore.lastChild.type.name === 'noteblock') {
                while (nodeBefore.lastChild.type.name === 'noteblock') {
                  offset = offset + 1
                  nodeBefore = nodeBefore.lastChild
                  // console.log('Joining up', nodeBefore, offset, depthOffset)
                  depthOffset = depthOffset + 1
                }
              } else {
                offset = 2
              }

              const slice = state.selection.node
              const startOfBlock = $from.pos
              const endOfBlock = $to.pos
              // console.log('joinNoteBlockContentBackwards 2', $from, $to)

              tr.deleteRange(startOfBlock, endOfBlock)
              if (!('' + nodeBefore.textContent).endsWith(' ')) {
                tr.insertText(' ', startOfBlock - offset)
                offset = offset - 1
              }
              // eslint-disable-next-line no-unused-vars
              const insertTrans = tr.insert(startOfBlock - offset, slice)
              const depth = depthOffset
              const range = new NodeRange(tr.doc.resolve(startOfBlock - offset + 2), tr.doc.resolve(startOfBlock - offset + slice.nodeSize - 2), depth)
              // console.log('joinNoteBlockContentBackwards lifting 3', insertTrans, depth, startOfBlock, slice, range, offset)
              // eslint-disable-next-line no-unused-vars
              const liftTrans = tr.lift(range, depth - 1)
              // console.log('joinNoteBlockContentBackwards liftTrans 4', liftTrans)
              // eslint-disable-next-line no-unused-vars
              const pointa = joinPoint(tr.doc, startOfBlock - offset, 1)
              // eslint-disable-next-line no-unused-vars
              const pointb = joinPoint(tr.doc, startOfBlock - offset, -1)
              // console.log('Found Join point, joining around?', pointa, pointb, slice.nodeSize, pointb + slice.nodeSize + 1, startOfBlock - offset)
              // const joinTrans = tr.join(point)
              // eslint-disable-next-line no-unused-vars
              const joinTrans = tr.join(startOfBlock - offset + 1)
              // console.log('END POS', startOfBlock, offset, textSelection.$anchor.pos - offset)
              let newFocusPos = startOfBlock - offset
              if (afterEmpty) {
                newFocusPos = newFocusPos - 2
              }
              const focusTrans = tr.setSelection(new TextSelection(tr.doc.resolve(newFocusPos)))

              if (parentSelection.$head.nodeAfter) {
                // console.log('joinNoteBlockContentBackwards 5 hasSiblingsAfter', parentSelection, parentSelection.to + 2)
                // const siblingjoinTrans = tr.join(parentSelection.to + 2)
                // console.log(siblingjoinTrans)
              }

              dispatch(focusTrans)
            } else {
              // console.log('liftingbackwards')
              dispatch(commands.liftNoteBlock(textSelection, parentSelection))
            }
          }
        }
        return true
      },
      liftNoteBlock: (textSelection, parentSelection) => ({ tr, dispatch, commands }) => {
        // lift note block needs to check not at top level
        const { $from, $to, $anchor, $head } = this.editor.view.state.selection
        const depth = $anchor.depth
        // eslint-disable-next-line no-unused-vars
        const endOfBlock = $from.end()
        // console.log('NoteBlockExtension.liftNoteBlock', this.editor.view.state.selection, depth, endOfBlock, $anchor, textSelection)
        if (depth > 0) {
          const range = new NodeRange($from, $to, depth)
          tr.lift(range, depth - 1)
          // tr.setSelection(new TextSelection(tr.doc.resolve(endOfBlock - 1)))
          const transaction = tr.setSelection(new TextSelection(tr.doc.resolve(textSelection.$anchor.pos + 1)))
          // shift tab needs to take any siblings after with it
          if ($head.nodeAfter) {
            // console.log('NoteBlockExtension.liftNoteBlock.hasSiblingsAfter', $head.nodeAfter)
            const pointa = joinPoint(transaction.doc, parentSelection.to, 1)
            // eslint-disable-next-line no-unused-vars
            const pointb = joinPoint(transaction.doc, parentSelection.to, -1)
            // console.log('liftNoteBlock Found Join point, joining around?', pointa, pointb, range, parentSelection.to + 2)
            // eslint-disable-next-line no-unused-vars
            const joinTrans = tr.join(pointa)
            // console.log(joinTrans)
          }
          dispatch(transaction)
        }
        return true
      },
      removeNoteBlock: () => ({ commands }) => {
        return commands.lift()
      }
    }
  },

  addNodeView () {
    return VueNodeViewRenderer(NoteBlock)
  },

  addKeyboardShortcuts () {
    return {
      'Shift-Enter': () => {
        // console.log('NoteBlockExtension.keys.Shift-Enter')
        return this.editor.chain().focus().splitBlock().run()
      },
      Enter: () => {
        if (!this.editor.options.inMenu) {
          const textSelection = this.editor.view.state.selection
          // Test if its enter inside a noteblock
          if (textSelection.$anchor) {
            const parentParent = textSelection.$anchor.path[textSelection.$anchor.path.length - 6]
            // console.log('NoteBlockExtension.keys.Enter selection parent.parent', textSelection, parentParent)
            if (parentParent.type.name === 'noteblock') {
              //  const selection = this.editor.view.state.selection
              if (textSelection.$anchor.depth > 2 && parentParent.content.size <= 2) {
                this.editor.commands.selectParentNode() // out of text
                const parentSelection = this.editor.view.state.selection
                this.editor.commands.selectParentNode() // out of p
                // console.log('NoteBlockExtension.keys.Enter empty node with depth', textSelection, parentParent)
                this.editor.chain().focus()
                  .liftNoteBlock(textSelection, parentSelection)
                  .run()
              } else if (hasNoteBlock(parentParent.content)) {
                // has noteblock children - create new block at start
                // console.log('NoteBlockExtension.keys.Enter non empty node with child NoteBlocks', textSelection, parentParent)
                this.editor.chain()
                  .splitBlock()
                  .insertNoteBlock()
                  .focus()
                  .run()
              } else if (textSelection.$anchor.depth > 2) {
                // this.editor.commands.selectParentNode() // out of text
                // this.editor.commands.selectParentNode() // out of noteblock
                // console.log('NoteBlockExtension.keys.Enter non empty node with depth', textSelection, parentParent)
                const selection = this.editor.view.state.selection
                // eslint-disable-next-line no-unused-vars
                const parent = selection.$anchor.parent
                // console.log('NoteBlockExtension.keys.Enter non empty', selection, parent)
                this.editor.chain()
                  .splitBlock()
                  .lift()
                  .insertNoteBlock()
                  .focus()
                  .run()
              } else {
                // this.editor.commands.selectParentNode() // out of text
                // this.editor.commands.selectParentNode() // out of noteblock
                // console.log('NoteBlockExtension.keys.Enter else', textSelection, parentParent)
                const selection = this.editor.view.state.selection
                // eslint-disable-next-line no-unused-vars
                const parent = selection.$anchor.parent
                // console.log('NoteBlockExtension.keys.Enter else', selection, parent)
                this.editor.chain()
                  .splitBlock()
                  .selectParentNode()
                  .insertNoteBlock()
                  .selectParentNode()
                  .lift()
                  .focus()
                  .run()
                const newselection = this.editor.view.state.selection
                // console.log('NoteBlockExtension.keys.Enter else after', newselection)
                this.editor.commands.focus(newselection.$anchor.pos + 2)
              }
              return true
            }
          }
        }
        return false
      },
      'Mod-Shift-8': () => {
        this.editor.commands.selectParentNode() // out to P
        this.editor.commands.selectParentNode() // out to NoteBlock
        // const selection = this.editor.view.state.selection
        // console.log('NoteBlockExtension.keys.Mod-Shift-8', selection)
        return this.editor.chain()
          .focus()
          .joinNoteBlockBackwards()
          .run()
      },
      Tab: () => {
        if (!this.editor.options.inMenu) {
          const textSelection = this.editor.view.state.selection
          // Test if its enter inside a noteblock
          const parentParent = textSelection.$anchor.path[textSelection.$anchor.path.length - 6]
          const parentParentOffset = textSelection.$anchor.path[textSelection.$anchor.path.length - 8]
          let limit = 1
          // console.log('NoteBlockExtension.keys.Tab parent parent', textSelection, parentParent, parentParentOffset)
          if (textSelection.$anchor.depth > 2) {
            limit = 2
          }
          // child of noteblock but not first child
          if (parentParent.type.name === 'noteblock') {
            if (parentParentOffset >= limit) {
              this.editor.commands.selectParentNode() // out to P
              this.editor.commands.selectParentNode() // out to NoteBlock
              // const selection = this.editor.view.state.selection
              // console.log('NoteBlockExtension.keys.Tab', selection
              return this.editor.chain()
                .focus()
                .joinNoteBlockBackwards(textSelection)
                .run()
            } else {
              return true
            }
          }
        }
      },
      'Shift-Tab': () => {
        // console.log('NoteBlockExtension.keys.Shift-Tab')
        const textSelection = this.editor.view.state.selection
        const parentParent = textSelection.$anchor.path[textSelection.$anchor.path.length - 6]
        // console.log('NoteBlockExtension.keys.Shift-Tab', selection, parent)
        if (parentParent.type) {
          if (parentParent.type.name === 'noteblock') {
            //  const selection = this.editor.view.state.selection
            if (textSelection.$anchor.depth > 2) {
              this.editor.commands.selectParentNode() // out of p
              const selection = this.editor.view.state.selection
              const parent = selection.$anchor.parent
              const parentSelection = this.editor.view.state.selection
              // console.log('NoteBlockExtension.keys.Shift-Tab empty node')
              // Empty note block
              if (parent.type.name === 'noteblock') {
                this.editor.commands.selectParentNode() // out to noteblock
              }
              this.editor.chain().focus()
                .liftNoteBlock(textSelection, parentSelection)
                .run()
              return true
            } else {
              return true
            }
          }
        }
        return false
      },
      Delete: () => {
        const docView = this.editor.view.docView
        const docViewSize = 0 + docView.node.content.size
        // console.log('NoteBlockExtension.keys.Backspace', docView, docViewSize, selection, parent)
        if (docViewSize <= 4) {
          if (docViewSize < 3) {
            this.editor.chain().focus()
              .insertNoteBlock() // change paragraph to NoteBlock
              .run()
          }
          return true
        }
      },
      Backspace: () => {
        const selection = this.editor.view.state.selection
        const parent = selection.$anchor.parent
        const docView = this.editor.view.docView
        const docViewSize = 0 + docView.node.content.size
        const parentOffset = selection.$anchor.path[selection.$anchor.path.length - 5]
        const parentParent = selection.$anchor.path[selection.$anchor.path.length - 6]
        let focusPos = selection.$anchor.path[selection.$anchor.path.length - 7] - 2
        if (selection.$anchor.path[selection.$anchor.path.length - 8] === 1) {
          focusPos = focusPos + 1
        }
        // console.log('NoteBlockExtension.keys.Backspace 1', selection, parentParent, focusPos)
        if (docViewSize <= 4) {
          if (docViewSize < 3) {
            this.editor.chain().focus()
              .insertNoteBlock() // change paragraph to NoteBlock
              .run()
          }
          return true
        } else if (parentParent.content.size <= 2) {
          if (parentParent.type.name === 'noteblock') {
            // console.log('NoteBlockExtension.keys.Backspace 2 parentDelete check')
            this.editor.chain()
              .selectParentNode()
              .selectParentNode()
              .deleteSelection()
              .run()
            // const nodeBefore = this.editor.state.selection.$anchor.nodeBefore
            // console.log('NoteBlockExtension.keys.Backspace 2 parentDelete nodebefore', nodeBefore)
            this.editor.commands.focus(focusPos)
            return true
          }
        } else if (selection.$anchor.parentOffset === 0 && parentOffset === 0) {
          // at the start of NoteBlock

          if (selection.$anchor.depth <= 2) {
            focusPos = focusPos - 1
          }

          if (parent.type.name === 'paragraph' && parentParent.type.name === 'noteblock') {
            this.editor.commands.selectParentNode() // out to P
            const parentSelection = this.editor.view.state.selection
            this.editor.commands.selectParentNode() // out to NoteBlock
            // console.log('NoteBlockExtension.keys.Backspace 3 joinBackwards check', this.editor.view.state.selection, parentSelection)

            if (this.editor.state.selection.$anchor.nodeBefore) {
              if (this.editor.state.selection.$anchor.nodeBefore.type.name !== 'noteblock') {
                focusPos = focusPos + 4
              }
            }

            if (this.editor.state.selection.$anchor.nodeBefore) {
              let nodeBefore = this.editor.state.selection.$anchor.nodeBefore
              if (nodeBefore.lastChild.type.name === 'noteblock') {
                while (nodeBefore.lastChild && nodeBefore.lastChild.type.name === 'noteblock') {
                  focusPos = focusPos - 1
                  nodeBefore = nodeBefore.lastChild
                }
                // console.log('NodeBefore:', nodeBefore)
              } else {
                // console.log('NodeBefore.lastChild Not noteblock', nodeBefore)
              }
              if (this.editor.state.selection.$anchor.nodeBefore.type.name === 'noteblock') {
                const text = nodeBefore.textContent
                // console.log(text)
                if (!('' + text).endsWith(' ')) {
                  // console.log('shifting focus due to adding space')
                  focusPos = focusPos + 1
                }
              }
            }
            // console.log('NoteBlockExtension.keys.Backspace 4 joinBackwards check', selection, parentParent)
            this.editor.chain()
              .joinNoteBlockContentBackwards(selection, parentSelection)
              .run()
            return true
          }
        }
        return false
      }
    }
  },

  // addInputRules () {
  //   return [
  //     new InputRule(inputRegex, (state, match, start, end) => {
  //       // console.log('CustomTaskItem.InputRule', state, match, start, end)
  //       const tr = state.tr.delete(start, end)
  //       const $start = tr.doc.resolve(start)
  //       const range = $start.blockRange()
  //       const wrapping = range && findWrapping(range, this.type, {})
  //       if (!wrapping) {
  //         return null
  //       }
  //       tr.wrap(range, wrapping)
  //       const newP = state.schema.nodes.paragraph.createAndFill()
  //       tr.insert(start - 1, newP)
  //       return tr
  //     })
  //   ]
  // },

  addProseMirrorPlugins () {
    return [
      BlockSelection()
    ]
  }
})
