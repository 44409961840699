<template>
  <div class="calendar-connect-wrapper" :class="displayFor">
    <base-svg-icon v-if="displayFor === 'scheduler'" :class="{ disabled: subscriptionLoadingGoogle || subscriptionLoading }" class="close-icon" name="cross-thin" clickable @click.native="laterClick()" />
    <section class="cal-lists cal-con-section" :class="[connectCal !== '' ? connectCal : '', modalDisplay ? 'modal-cal' : '', signUpStage ? 'sign-up' : '']">
      <base-svg-icon v-if="connectCal" name="cross-thin" clickable @click.native="closeModal('close')" class="close-icon"/> <!-- for modal dialog -->
      <div class="cal-type outlook" :class="{ highlighted: msNeedsReconnect}">
        <div class="cal-name-connect outlook">
          <base-svg-icon name="outlook" class="cal-logo outlook" />
          <mdc-button class="connect-btn" v-if="calendars.length === 0 && subscribedCalendars.length === 0" :class="{ 'disabled connecting': calendarsLoadingFor === 'MS' }" @click.native.stop="connectToOutlookCalendars()">
            <mdc-text typo="body1" tag="span" class="conn-text">Connect</mdc-text>
            <span class="loader" v-if="calendarsLoadingFor === 'MS'"></span>
          </mdc-button>
        </div>
        <template v-if="((calendarsLoadingFor !== 'MS' && calendars.length > 0) || subscribedCalendars.length > 0)">
          <mdc-text tag="span" typo="body2" class="signed-in MS">
            <i>Signed in as {{this.profile.msEmail}}</i>
          </mdc-text>
          <div class="microsoft-calendars select-calendars">
            <template v-if="subscribedCalendars.length > 0 && !msNeedsReconnect">
              <div class="calendar-types-wrapper subscribed" v-for="(calendar, index) in subscribedCalendars" :key="'calendars' + index " >
                <div class="calendar-type">
                  <div class="calendar-details">
                    <base-svg-icon :name="'round_checked'" class="check-round"/>
                    <mdc-text class="calendar-name" tag="span" typo="body2" >{{calendar.name}}</mdc-text>
                    <span class="calendar-color" v-if="calendar.hexColor !== ''"  :style="{ 'background-color': calendar.hexColor }" />
                    <base-svg-icon class="down-arrow" name="thin-down-arrow" clickable @click.native="colorBlockClick(calendar.calendarId)"/>
                    <color-palette @emitSelect="selectColor" @emitClose="closeColorPalette" :colorPaletteFor="'MS'" :showColorPalette="colorPaletteClickedFor === calendar.calendarId" :paletteId="calendar.calendarId" subscribed/>
                  </div>
                  <div class="calendar-default-type">
                    <mdc-text tag="span" typo="caption" v-if="defaultCalendar === calendar.calendarId">Default</mdc-text>
                    <base-svg-icon :name="defaultCalendar === calendar.calendarId ? 'favourite-star-on' : 'favourite-star-c4c4c4'" clickable @click.native="markCalendarDefaultSubscribed(calendar.calendarId)" />
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="calendar-types-wrapper" v-for="(calendar, index) in calendars" :key="'calendars' + index " >
                <div class="calendar-type" :class="isCalendarSelected(value.id) ? 'selected' : ''" v-for="(value, index) in calendar.value" :key="'value' + index">
                  <div class="calendar-details">
                    <base-svg-icon :name="isCalendarSelected(value.id) ? 'round_checked' : 'round_unchecked'" clickable @click.native.stop="toggleCalendar(value)" class="check-round" />
                    <mdc-text class="calendar-name" tag="span" typo="body2" >{{value.name}}</mdc-text>
                    <span class="calendar-color" v-if="value.hexColor !== ''"  :style="{ 'background-color': value.hexColor }"/>
                    <base-svg-icon v-if="isCalendarSelected(value.id)" class="down-arrow" name="thin-down-arrow" clickable @click.native="colorBlockClick(value.id)"/>
                    <color-palette :disableColors="usedColors" @emitSelect="selectColor" @emitClose="closeColorPalette" :colorPaletteFor="'MS'" :showColorPalette="colorPaletteClickedFor === value.id" :paletteId="value.id" />
                  </div>
                  <div class="calendar-default-type">
                    <mdc-text tag="span" typo="caption" v-if="defaultCalendar === value.id">Default</mdc-text>
                    <base-svg-icon :name="defaultCalendar === value.id ? 'favourite-star-on' : 'favourite-star-c4c4c4'" clickable @click.native="markCalendarDefault(value.id)"/>
                  </div>
                </div>
              </div>
            </template>
            <mdc-text tag="span" typo="body2" v-if="msNeedsReconnect">
              Unable to connect to your calendar, please reconnect to synchronise your meetings.
            </mdc-text>
            <mdc-button class="subscribe-btn MS" :class="{'disabled subscribing' : subscriptionLoading, 'disabled': selectedCalendars.length === 0 }" v-if="calendarsLoadingFor !== 'MS' && calendars.length > 0 && subscribedCalendars.length === 0" @click.native.stop="submitCalendarstoSubscribe()">
              <mdc-text tag="span" typo="body1">Subscribe</mdc-text>
              <span class="loader" v-if="subscriptionLoading"></span>
            </mdc-button>
            <mdc-button class="subscribe-btn MS" :class="{ reconnecting: subscriptionDeleting }" v-else-if="msNeedsReconnect" @click.native.stop="reconnectMS()">
              <mdc-text tag="span" typo="body1">Reconnect</mdc-text>
              <span class="loader" v-if="subscriptionDeleting"></span>
            </mdc-button>
            <mdc-button class="subscribe-btn MS" :class="{'disabled unsubscribing' : subscriptionDeleting, 'unsubscribe' : subscribedCalendars.length > 0 && !msNeedsReconnect }" v-else-if="calendarsLoadingFor !== 'MS' && subscribedCalendars.length > 0" @click.native.stop="deleteAllSubscriptions()">
              <mdc-text tag="span" typo="body1">Unsubscribe</mdc-text>
              <span class="loader" v-if="subscriptionDeleting"></span>
            </mdc-button>
          </div>
        </template>
      </div>
      <div class="cal-type google" :class="{ highlighted: googleNeedsReconnect }">
        <div class="cal-name-connect google">
          <base-svg-icon name="google" class="cal-logo google" />
          <mdc-button class="connect-btn" v-if="googleCalendars.length === 0 && subscribedGoogleCalendars.length === 0" :class="{ 'disabled connecting': calendarsLoadingFor === 'google' }" @click.native.stop.prevent="connectToGoogleCalendars()">
            <mdc-text typo="body1" tag="span" class="conn-text">Connect</mdc-text>
            <span class="loader" v-if="calendarsLoadingFor === 'google'"></span>
          </mdc-button>
        </div>
        <template v-if="calendarsLoadingFor !== 'google' && (googleCalendars.length > 0 || subscribedGoogleCalendars.length > 0)">
          <mdc-text tag="span" typo="body2" class="signed-in google">
            <i>Signed in as {{this.profile.googleEmail}}</i>
          </mdc-text>
          <div class="google-calendars select-calendars" >
            <template v-if="subscribedGoogleCalendars.length > 0 && !googleNeedsReconnect">
              <div class="calendar-types-wrapper subscribed" v-for="(calendar, index) in subscribedGoogleCalendars" :key="'calendars' + index " >
                <div class="calendar-type">
                  <div class="calendar-details">
                    <base-svg-icon :name="'round_checked'" class="check-round" />
                    <mdc-text class="calendar-name" tag="span" typo="body2" >{{calendar.name}}</mdc-text>
                    <span class="calendar-color" v-if="calendar.hexColor !== ''"  :style="{ 'background-color': calendar.hexColor }" />
                    <base-svg-icon class="down-arrow" name="thin-down-arrow" clickable @click.native="colorBlockClick(calendar.calendarId)"/>
                    <color-palette @emitSelect="selectColor" @emitClose="closeColorPalette" :colorPaletteFor="'google'" :showColorPalette="colorPaletteClickedFor === calendar.calendarId" :paletteId="calendar.calendarId" subscribed />
                  </div>
                  <div class="calendar-default-type">
                    <mdc-text tag="span" typo="caption" v-if="defaultCalendar === calendar.calendarId">Default</mdc-text>
                    <base-svg-icon :name="defaultCalendar === calendar.calendarId ? 'favourite-star-on' : 'favourite-star-c4c4c4'" clickable @click.native="markCalendarDefaultSubscribed(calendar.calendarId)"/>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="calendar-types-wrapper" v-for="(calendar, index) in googleCalendars" :key="'calendars' + index " >
                  <div class="calendar-type" :key="'value' + index">
                    <div class="calendar-details">
                      <base-svg-icon :name="isGoogleCalendarSelected(calendar.id) ? 'round_checked' : 'round_unchecked'" clickable @click.native.stop="toggleGoogleCalendar(calendar)" class="check-round" />
                      <mdc-text class="calendar-name" tag="span" typo="body2" >{{calendar.summary}}</mdc-text>
                      <span class="calendar-color" :data-color="calendar.hexColor" v-if="calendar.hexColor !== ''"  :style="{ 'background-color': calendar.hexColor }"/>
                      <base-svg-icon  v-if="isGoogleCalendarSelected(calendar.id)" class="down-arrow" name="thin-down-arrow" clickable @click.native="colorBlockClick(calendar.id)"/>
                      <color-palette :disableColors="usedColors" @emitSelect="selectColor" @emitClose="closeColorPalette" :colorPaletteFor="'google'" :showColorPalette="colorPaletteClickedFor === calendar.id" :paletteId="calendar.id" />
                    </div>
                    <div class="calendar-default-type">
                      <mdc-text tag="span" typo="caption" v-if="defaultCalendar === calendar.id">Default</mdc-text>
                      <base-svg-icon :name="defaultCalendar === calendar.id ? 'favourite-star-on' : 'favourite-star-c4c4c4'" clickable @click.native="markCalendarDefault(calendar.id)"/>
                    </div>
                  </div>
              </div>
            </template>
            <mdc-text tag="span" typo="body2" v-if="googleNeedsReconnect">
              Unable to connect to your calendar, please reconnect to synchronise your meetings.
            </mdc-text>
            <mdc-button class="subscribe-btn google" :class="{'disabled subscribing' : subscriptionLoadingGoogle, 'disabled': googleSelectedCalendars.length === 0 }" v-if="calendarsLoadingFor !== 'google' && googleCalendars.length > 0 && subscribedGoogleCalendars.length === 0" @click.native.stop="subscribeToGoogleCalendars()">
              <mdc-text tag="span" typo="body1">Subscribe</mdc-text>
              <span class="loader" v-if="subscriptionLoadingGoogle"></span>
            </mdc-button>
            <mdc-button class="subscribe-btn MS" :class="{ reconnecting: subscriptionDeletingGoogle }" v-else-if="googleNeedsReconnect" @click.native.stop="reconnectGoogle()">
              <mdc-text tag="span" typo="body1">Reconnect</mdc-text>
              <span class="loader" v-if="subscriptionDeletingGoogle"></span>
            </mdc-button>
            <mdc-button class="subscribe-btn google" :class="{'disabled unsubscribing' : subscriptionDeletingGoogle, 'unsubscribe' : subscribedGoogleCalendars.length > 0 && !googleNeedsReconnect }" v-else-if="calendarsLoadingFor !== 'google' && subscribedGoogleCalendars.length > 0" @click.native.stop="deleteAllGoogleSubscriptions()">
              <mdc-text tag="span" typo="body1">Unsubscribe</mdc-text>
              <span class="loader" v-if="subscriptionDeletingGoogle"></span>
            </mdc-button>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import { functionsUrl, gaeURL } from '../../../src/settings'
import { calendar } from '../../utils/backend'
import ColorPalette from './ColorPalette.vue'
import { colorPalette, colorNameToHex } from '../../utils/colors'
export default {
  name: 'CalendarConnect',
  components: {
    BaseSvgIcon,
    ColorPalette
  },
  props: {
    connectCal: {
      default: ''
    },
    modalDisplay: {
      default: false,
      type: Boolean
    },
    displayFor: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      usedColors: [],
      colorPalette: [],
      accessToken: false,
      selectedCalendars: [],
      googleSelectedCalendars: [],
      showSetupTypeSection: '',
      calendarGroups: [],
      calendars: [],
      googleCalendars: [],
      nextAutoColor: 0,
      autoColors: [
        'lightBlue',
        'lightGreen',
        'lightSalmon',
        'lightGray',
        'yellow',
        'lightSeagreen',
        'lightPink',
        'lightBrown',
        'lightRed'
      ],
      events: '',
      people: '',
      analytics: '',
      user: null,
      showProviderData: false,
      subscription: '',
      subscribedCalendars: [],
      subscribedGoogleCalendars: [],
      subscriptionLoading: false,
      subscriptionLoadingGoogle: false,
      subscriptionDeleting: false,
      subscriptionDeletingGoogle: false,
      calendarsLoadingFor: '',
      failOnException: false,
      defaultCalendar: false,
      colorPaletteClickedFor: '',
      signUpStage: false,
      windowOpened: null
    }
  },
  beforeDestroy () {
    this.closeModal('close')
  },
  created () {
    this.colorPalette = colorPalette()
    this.connectCalModal()
  },
  computed: {
    ...mapState([
      'profile',
      'workspace'
    ]),
    ...mapState({
      subscriptions: state => state.teams.subscriptions
    }),
    msNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type !== 'Google' && sub.msNeedsReconnect) {
              return true
            }
          }
        }
      }
      return false
    },
    googleNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type === 'Google' && sub.googleNeedsReconnect) {
              return true
            }
          }
        }
      }
      return false
    }
  },
  mounted () {
    this.user = firebase.auth().currentUser
    this.selectedCalendars = []
    this.googleSelectedCalendars = []
    // console.log('mounted.calendarsSelected', this.profile.calendarsSelected)
    this.subscribedCalendars = this.profile.calendarsSelected || []
    this.subscribedGoogleCalendars = this.profile.googleCalendarsSelected || []
    this.defaultCalendar = this.profile.defaultCalendar || false
    this.updateUsedColors()
  },
  methods: {
    ...mapActions([
      'updateProfile',
      'setPageLoad',
      'setSnackMessage',
      'loadProfile'
    ]),
    ...mapActions({
      setSetupCalendarLater: 'setSetupCalendarLater',
      deleteFutureMeetings: 'teams/deleteFutureMeetings',
      deleteFutureGoogleMeetings: 'teams/deleteFutureGoogleMeetings',
      saveSubscriptionEvent: 'teams/saveSubscriptionEvent',
      addSubscription: 'teams/addSubscription',
      deleteSubscriptions: 'teams/deleteSubscriptions',
      deleteGoogleSubscriptions: 'teams/deleteGoogleSubscriptions'
    }),
    connectCalModal () {
      if (this.connectCal) {
        if (this.connectCal === 'MS') {
          this.connectToOutlookCalendars()
        } else {
          this.connectToGoogleCalendars()
        }
      }
    },
    closeModal (from) {
      this.signUpStage = false
      this.$emit('closeModal', from)
    },
    updateUsedColors () {
      this.usedColors = []
      const subscribedCalendars = [...this.subscribedGoogleCalendars, ...this.subscribedCalendars, ...this.selectedCalendars, ...this.googleSelectedCalendars]
      const colors = subscribedCalendars.filter(cal => cal.hexColor).map((cal) => cal.hexColor)
      colors.forEach(color => {
        const cPIndex = this.colorPalette.findIndex(cP => cP.hexColor === color)
        if (cPIndex >= 0) {
          this.usedColors.push(this.colorPalette[cPIndex])
        }
      })
    },
    generateRandomColor () {
      const randomIndex = Math.floor((Math.random() * 12))
      const randomColor = this.colorPalette[randomIndex]
      const colorIndex = this.usedColors.findIndex(colorPalette => colorPalette.hexColor === randomColor.hexColor)
      if (colorIndex === -1) {
        return randomColor
      }
      return this.generateRandomColor()
    },
    selectColor (colorDetails, id, calendarType, isSubscribed, connectColorUpdate) {
      // console.log('select color', colorDetails, id, calendarType, isSubscribed, connectColorUpdate)
      if (id === this.colorPaletteClickedFor || connectColorUpdate) {
        if (calendarType === 'MS') {
          if (isSubscribed) {
            if (this.profile.calendarsSelected.length > 0) {
              const calendarIndex = this.profile.calendarsSelected.findIndex(calendar => calendar.calendarId === id)
              if (calendarIndex > -1) {
                const allCalendars = [...this.profile.calendarsSelected]
                const calendarModified = { ...allCalendars[calendarIndex], hexColor: colorDetails.hexColor }
                allCalendars.splice(calendarIndex, 1, calendarModified)
                this.profile.calendarsSelected[calendarIndex].hexColor = colorDetails.hexColor
                this.updateProfile({ profile: { calendarsSelected: allCalendars }, noSnack: true }).then((response) => {
                  this.closeColorPalette()
                })
              }
            }
          } else {
            if (this.selectedCalendars.length > 0) {
              const calendarIndex = this.selectedCalendars.findIndex(calendar => calendar.id === id)
              if (calendarIndex >= 0) {
                const calendarModified = { ...this.selectedCalendars[calendarIndex], hexColor: colorDetails.hexColor, colorModified: true }
                this.selectedCalendars.splice(calendarIndex, 1, calendarModified)
                const selectedIndexInCalendars = this.calendars.findIndex(calendar => {
                  return calendar.value.find(valueDetail => valueDetail.id === id)
                })
                if (selectedIndexInCalendars >= 0) {
                  const valueCalendarIndex = this.calendars[selectedIndexInCalendars].value.findIndex(valueCal => valueCal.id === id)
                  if (valueCalendarIndex > -1) {
                    const valueCalendarModified = { ...this.calendars[selectedIndexInCalendars].value[valueCalendarIndex], hexColor: colorDetails.hexColor, colorModified: true }
                    this.calendars[selectedIndexInCalendars].value.splice(valueCalendarIndex, 1, valueCalendarModified)
                  }
                }
                if (id === this.colorPaletteClickedFor) {
                  this.updateUsedColors()
                }
                this.closeColorPalette()
              }
            }
          }
        }
        if (calendarType === 'google') {
          if (isSubscribed) {
            if (this.profile.googleCalendarsSelected.length > 0) {
              const calendarIndex = this.profile.googleCalendarsSelected.findIndex(calendar => calendar.calendarId === id)
              if (calendarIndex > -1) {
                const allCalendars = [...this.profile.googleCalendarsSelected]
                const calendarModified = { ...allCalendars[calendarIndex], hexColor: colorDetails.hexColor }
                allCalendars.splice(calendarIndex, 1, calendarModified)
                this.profile.googleCalendarsSelected[calendarIndex].hexColor = colorDetails.hexColor
                this.updateProfile({ profile: { googleCalendarsSelected: allCalendars }, noSnack: true }).then(() => {
                  this.closeColorPalette()
                })
              }
            }
          } else {
            if (this.googleSelectedCalendars.length > 0) {
              const calendarIndex = this.googleSelectedCalendars.findIndex(calendar => calendar.id === id)
              if (calendarIndex > -1) {
                const calendarModified = { ...this.googleSelectedCalendars[calendarIndex], hexColor: colorDetails.hexColor, colorModified: true }
                this.googleSelectedCalendars.splice(calendarIndex, 1, calendarModified)
                const selectedIndexInGoogleCalendars = this.googleCalendars.findIndex(calendar => calendar.id === id)
                if (selectedIndexInGoogleCalendars > -1) {
                  const googleCalendarsModified = { ...this.googleCalendars[selectedIndexInGoogleCalendars], hexColor: colorDetails.hexColor }
                  this.googleCalendars.splice(selectedIndexInGoogleCalendars, 1, googleCalendarsModified)
                }
                if (id === this.colorPaletteClickedFor) {
                  this.updateUsedColors()
                }
                this.closeColorPalette()
              }
            }
          }
        }
      }
    },
    colorBlockClick (calendarId) {
      this.colorPaletteClickedFor = calendarId
    },
    closeColorPalette () {
      this.colorPaletteClickedFor = ''
    },
    markCalendarDefault (calendarId) {
      // console.log('CalendarConnect.markCalendarDefault', calendarId, this.profile.defaultCalendar)
      if (calendarId !== this.profile.defaultCalendar) {
        if (this.isGoogleCalendarSelected(calendarId) || this.isCalendarSelected(calendarId)) {
          this.defaultCalendar = calendarId
          this.updateProfile({ profile: { defaultCalendar: this.defaultCalendar }, noSnack: true }).then(() => {
            // this.updateDefaultCalColor(calendarId)
          })
        }
      }
    },
    markCalendarDefaultSubscribed (calendarId) {
      // console.log('CalendarConnect.markCalendarDefaultSubscribed', calendarId, this.profile.defaultCalendar)
      if (calendarId !== this.profile.defaultCalendar) {
        if (this.isGoogleCalendarSubscribed(calendarId) || this.isCalendarSubscribed(calendarId)) {
          this.defaultCalendar = calendarId
          this.updateProfile({ profile: { defaultCalendar: this.defaultCalendar }, noSnack: true })
        }
      }
    },
    async reconnectMS () {
      if (!this.subscriptionDeleting) {
        await this.deleteAllSubscriptions()
        await this.connectToOutlookCalendars()
      }
    },
    async reconnectGoogle () {
      if (!this.subscriptionDeletingGoogle) {
        await this.deleteAllGoogleSubscriptions()
        await this.connectToGoogleCalendars()
      }
    },
    async deleteAllSubscriptions () {
      this.subscriptionDeleting = true
      const response = await calendar('delete-subscriptions', { userId: this.profile.id })
      // console.log('CalendarConnect.deleteAllSubscriptions', response)
      if (response) {
        const event = {
          userId: this.profile.id,
          userName: this.profile.displayName,
          date: new Date(),
          event: 'Delete',
          data: null,
          result: response
        }
        this.saveSubscriptionEvent({ event })
        await this.deleteSubscriptions()
        if (response.message === 'subscriptions deleted') {
          this.selectedCalendars = []
          this.subscribedCalendars = []
          this.nextAutoColor = 0
          this.deleteFutureMeetings()
          // console.log('CalendarConnect.deleteAllSubscriptions', this.profile.defaultCalendar, this.profile.calendarsSelected)
          for (const cal of this.profile.calendarsSelected) {
            if (this.profile.defaultCalendar === cal.calendarId) {
              this.defaultCalendar = false
              // console.log('CalendarConnect.deleteAllSubscriptions', this.profile.defaultCalendar, cal.calendarId)
              if (this.profile.googleCalendarsSelected && this.profile.googleCalendarsSelected.length) {
                // Make default primary google calendar
                for (const gcal of this.profile.googleCalendarsSelected) {
                  if (gcal.isDefaultCalendar) {
                    this.defaultCalendar = gcal.calendarId
                  }
                }
                if (!this.defaultCalendar) {
                  // no primary calendar selected -- fallback to first calendar
                  this.defaultCalendar = this.profile.googleCalendarsSelected[0].calendarId
                }
              }
            }
          }
          if (!this.defaultCalendar) {
            this.defaultCalendar = null
          }
          this.updateProfile({ profile: { calendarsSelected: [], defaultCalendar: this.defaultCalendar }, noSnack: true }).then(() => {
            this.updateUsedColors()
          })
        }
      }
      this.calendars = []
      this.subscriptionDeleting = false
    },
    async deleteAllGoogleSubscriptions () {
      this.subscriptionDeletingGoogle = true
      const response = await calendar('delete-google-subscriptions', { userId: this.profile.id })
      // console.log('CalendarConnect.deleteAllGoogleSubscriptions', response)
      if (response) {
        const event = {
          userId: this.profile.id,
          userName: this.profile.displayName,
          date: new Date(),
          event: 'Delete',
          data: null,
          result: JSON.stringify(response)
        }
        this.saveSubscriptionEvent({ event })
        await this.deleteGoogleSubscriptions()
        if (response.message === 'subscriptions deleted') {
          this.googleSelectedCalendars = []
          this.subscribedGoogleCalendars = []
          this.nextAutoColor = 0
          this.deleteFutureGoogleMeetings()
          // console.log('CalendarConnect.deleteAllGoogleSubscriptions', this.profile.defaultCalendar, this.profile.googleCalendarsSelected)
          for (const cal of this.profile.googleCalendarsSelected) {
            if (this.profile.defaultCalendar === cal.calendarId) {
              this.defaultCalendar = false
              // console.log('CalendarConnect.deleteAllGoogleSubscriptions', this.profile.defaultCalendar, cal.calendarId)
              if (this.profile.calendarsSelected && this.profile.calendarsSelected.length) {
                // Make default default MS calendar
                for (const mscal of this.profile.calendarsSelected) {
                  // console.log('CalendarConnect.deleteAllGoogleSubscriptions', mscal)
                  if (mscal.isDefaultCalendar) {
                    this.defaultCalendar = mscal.calendarId
                  }
                }
                if (!this.defaultCalendar) {
                  // no default calendar selected -- fallback to first calendar
                  // console.log('CalendarConnect.deleteAllGoogleSubscriptions.fallback', this.profile.calendarsSelected[0].calendarId)
                  this.defaultCalendar = this.profile.calendarsSelected[0].calendarId
                }
              }
            }
          }
          if (!this.defaultCalendar) {
            this.defaultCalendar = null
          }
          this.updateProfile({ profile: { googleCalendarsSelected: [], defaultCalendar: this.defaultCalendar }, noSnack: true }).then(() => {
            this.updateUsedColors()
          })
        }
      }
      this.googleCalendars = []
      this.subscriptionDeletingGoogle = false
    },
    getAutoColor () {
      const autoColor = this.autoColors[this.nextAutoColor]
      this.nextAutoColor = (this.nextAutoColor + 1) % this.autoColors.length
      return autoColor
    },
    convertToCssColor (msColorName) {
      if (msColorName.toLowerCase() === 'lightorange') {
        msColorName = 'lightsalmon'
      }
      if (msColorName.toLowerCase() === 'lightteal') {
        msColorName = 'lightseagreen'
      }
      if (msColorName.toLowerCase() === 'lightyellow') {
        msColorName = 'yellow'
      }
      return msColorName
    },
    async submitCalendarstoSubscribe () {
      // delete existing subscriptions before subscribing again
      this.subscriptionLoading = true

      if (this.profile.msEmail) {
        // skip delete existing for new profile
        const response = await calendar('delete-subscriptions', { userId: this.profile.id })
        // console.log('submitCalendarstoSubscribe.deleteAllSubscriptions', response)
        if (response) {
          await this.deleteSubscriptions()
        }
      }

      const calendarSubscribePromises = this.selectedCalendars.map(async (calendar) => {
        const subscribedCalendar = {}
        const subscriptionStatus = await this.subscriptionClick(calendar.id, calendar.name).then(() => {
          if (this.subscription.id) {
            subscribedCalendar.subscriptionId = this.subscription.id
            subscribedCalendar.calendarId = calendar.id
            subscribedCalendar.name = calendar.name
            subscribedCalendar.isDefaultCalendar = calendar.isDefaultCalendar || false
            subscribedCalendar.hexColor = calendar.hexColor
            delete calendar.colorModified
            return subscribedCalendar
          }
          // console.log(this.subscription)
          return {}
        })
        return subscriptionStatus
      })
      Promise.all(calendarSubscribePromises).then(async responses => {
        this.subscribedCalendars = []
        this.subscribedCalendars.push(...responses)
        if (this.subscribedCalendars.length > 0) {
          // Expiry for renewal (2 days)
          const expiry = new Date(new Date().setDate(new Date().getDate() + 2))
          // Trigger full event loading
          // console.log('submitCalendarstoSubscribe.subscribed promises complete - import events')
          await this.addSubscription({ expiry, msEmail: this.profile.msEmail, type: 'MS' })
          await this.updateProfile({ profile: { calendarsSelected: this.subscribedCalendars, defaultCalendar: this.defaultCalendar, lastCalendarImport: 'now' }, noSnack: true })
          // Do full import after calendars saved to profile.
        }
      })
      this.subscriptionLoading = false
      if (this.connectCal === 'MS') {
        this.closeModal()
      }
    },
    async subscriptionClick (calendarId, calendarName) {
      // Expire 2 Days
      const exp = new Date(new Date().setDate(new Date().getDate() + 2))
      // Add 12 hours to Expire
      exp.setTime(exp.getTime() + (12 * 60 * 60 * 1000))
      // Should result in 60 hours expiry on MS subscription

      // Quick import of next weeks meetings
      await calendar('import-next-week', { userId: this.profile.id, calendarId, calendarName })
      this.$emit('nextWeekImportCompleted')

      const subscription = {
        changeType: 'created,updated,deleted',
        notificationUrl: functionsUrl + '/calendar/notify?userId=' + this.user.uid + '&calendarId=' + calendarId,
        lifecycleNotificationUrl: functionsUrl + '/calendar/lifecycle-notify?userId=' + this.user.uid + '&calendarId=' + calendarId,
        resource: `me/calendars/${calendarId}/events`,
        expirationDateTime: exp
      }
      const subJson = JSON.stringify(subscription)
      // console.log('CalendarConnect.subscriptionClick:', subJson)
      const subscribeResult = await fetch('https://graph.microsoft.com/v1.0/subscriptions', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.profile.officeToken
        },
        body: subJson
      })

      const subRes = await subscribeResult.json()
      this.subscription = subRes
      const event = {
        userId: this.profile.id,
        userName: this.profile.displayName,
        date: new Date(),
        event: 'Subscribe',
        data: subscription,
        result: subRes
      }
      this.saveSubscriptionEvent({ event })
    },
    async subscribeToGoogleCalendars () {
      const accessToken = this.profile.accessToken
      this.subscriptionLoadingGoogle = true
      const calendarSubscribePromises = this.googleSelectedCalendars.map(async (calendar) => {
        const subscribedCalendar = {}
        // console.log('subscribeToGoogleCalendars', calendar)
        const subscriptionStatus = await this.googleSub(calendar.id, calendar.summary, accessToken).then((subRes) => {
          if (subRes.id) {
            subscribedCalendar.subscriptionId = subRes.id
            subscribedCalendar.calendarId = calendar.id
            subscribedCalendar.name = calendar.summary
            subscribedCalendar.isDefaultCalendar = calendar.primary || false
            if (!calendar.colorModified) {
              if (calendar.hexColor) {
                subscribedCalendar.hexColor = calendar.hexColor
              } else if (calendar.backgroundColor) {
                subscribedCalendar.hexColor = calendar.backgroundColor
              } else {
                subscribedCalendar.hexColor = this.getAutoColor()
              }
            } else {
              subscribedCalendar.hexColor = calendar.hexColor
            }
            delete calendar.colorModified
            return subscribedCalendar
          }
          // console.log(this.subscription)
          return {}
        })
        return subscriptionStatus
      })
      Promise.all(calendarSubscribePromises).then(async responses => {
        this.subscribedGoogleCalendars = []
        this.subscribedGoogleCalendars.push(...responses)
        if (this.subscribedGoogleCalendars.length > 0) {
          // Trigger full event loading
          // console.log('submitCalendarstoSubscribe.subscribed promises complete - import events')
          await this.updateProfile({ profile: { googleCalendarsSelected: this.subscribedGoogleCalendars, defaultCalendar: this.defaultCalendar, lastGoogleCalendarImport: 'now' }, noSnack: true })
          // Full import after calendars saved.
          this.subscriptionLoadingGoogle = false
        }
      })
      this.subscriptionLoadingGoogle = false
      if (this.connectCal === 'google') {
        this.closeModal()
      }
    },
    async googleSub (calendarId, calendarName, token) {
      const exp = new Date()
      const expSec = 2592000 // 30 Days
      exp.setTime(exp.getTime() + (expSec * 1000) - (60 * 60 * 1000)) // 30 days - 1 hour
      const expiry = exp

      // Quick import of next weeks meetings
      await calendar('import-google-next-week', { userId: this.user.uid, calendarId, calendarName })
      this.$emit('nextWeekImportCompleted')

      const watchUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/watch`
      const subDoc = await this.addSubscription({ expiry, googleEmail: this.profile.googleEmail, type: 'Google', calendarId })
      const subId = subDoc.id
      const subscription = {
        id: subId, // Your channel ID.
        type: 'web_hook',
        params: {
          ttl: expSec
        },
        address: functionsUrl + '/calendar/notify-google?userId=' + this.user.uid + '&calendarId=' + calendarId
      }
      const subJson = JSON.stringify(subscription)
      // console.log('CalendarConnect.googleSub:', subJson)
      const subscribeResult = await fetch(watchUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: subJson
      })
      const subRes = await subscribeResult.json()
      this.subscription = subRes
      const event = {
        userId: this.profile.id,
        userName: this.profile.displayName,
        date: new Date(),
        event: 'SubscribeGoogle',
        data: JSON.stringify(subscription),
        result: subRes
      }
      await this.saveSubscriptionEvent({ event })
      return subRes
    },
    isGoogleCalendarSelected (calendarId) {
      const calendarIdIndex = this.googleSelectedCalendars.findIndex(calendarType => calendarType.id === calendarId)
      if (calendarIdIndex >= 0) {
        return true
      } else {
        return false
      }
    },
    isGoogleCalendarSubscribed (calendarId) {
      const calendarIdIndex = this.profile.googleCalendarsSelected.findIndex(calendarType => calendarType.calendarId === calendarId)
      if (calendarIdIndex >= 0) {
        return true
      } else {
        return false
      }
    },
    toggleGoogleCalendar (calendar) {
      const calendarIdIndex = this.googleSelectedCalendars.findIndex(calendarType => calendarType.id === calendar.id)
      if (calendarIdIndex >= 0) {
        this.googleSelectedCalendars.splice(calendarIdIndex, 1)
        const usedColorIndex = this.usedColors.findIndex(color => color.hexColor === calendar.hexColor)
        if (usedColorIndex >= 0) {
          this.usedColors.splice(usedColorIndex, 1)
        }
      } else {
        const randomColor = this.generateRandomColor()
        this.googleSelectedCalendars.push({ ...calendar, hexColor: randomColor.hexColor })
        this.selectColor(randomColor, calendar.id, 'google', false, true)
        this.usedColors.push(randomColor)
      }
      if (calendar.id === this.defaultCalendar) {
        this.defaultCalendar = false
        for (const gcal of this.googleSelectedCalendars) {
          if (gcal.isDefaultCalendar) {
            this.defaultCalendar = gcal.id
          }
        }
        if (!this.defaultCalendar) {
          // no primary calendar selected -- fallback to first calendar
          if (this.googleSelectedCalendars[0]) {
            this.defaultCalendar = this.googleSelectedCalendars[0].id
          }
        }
      }
    },
    isCalendarSelected (calendarId) {
      const calendarIdIndex = this.selectedCalendars.findIndex(calendarType => calendarType.id === calendarId)
      if (calendarIdIndex >= 0) {
        return true
      } else {
        return false
      }
    },
    isCalendarSubscribed (calendarId) {
      const calendarIdIndex = this.profile.calendarsSelected.findIndex(calendarType => calendarType.calendarId === calendarId)
      if (calendarIdIndex >= 0) {
        return true
      } else {
        return false
      }
    },
    toggleCalendar (calendar) {
      const calendarIdIndex = this.selectedCalendars.findIndex(calendarType => calendarType.id === calendar.id)
      if (calendarIdIndex >= 0) {
        this.selectedCalendars.splice(calendarIdIndex, 1)
        const usedColorIndex = this.usedColors.findIndex(color => color.hexColor === calendar.hexColor)
        if (usedColorIndex >= 0) {
          this.usedColors.splice(usedColorIndex, 1)
        }
      } else {
        let randomColor
        if (calendar.id === this.defaultCalendar) {
          const defaultColorDetails = this.colorPalette[0]
          const defaultUsedColorIndex = this.usedColors.findIndex(color => color.hexColor === this.colorPalette[0].hexColor)
          randomColor = defaultUsedColorIndex === -1 ? defaultColorDetails : this.generateRandomColor()
        } else {
          randomColor = this.generateRandomColor()
        }
        this.selectedCalendars.push({ ...calendar, hexColor: randomColor.hexColor })
        this.selectColor(randomColor, calendar.id, 'MS', false, true)
        this.usedColors.push(randomColor)
      }
      if (calendar.id === this.defaultCalendar) {
        this.defaultCalendar = false
        for (const mscal of this.selectedCalendars) {
          if (mscal.isDefaultCalendar) {
            this.defaultCalendar = mscal.id
          }
        }
        if (!this.defaultCalendar) {
          // no primary calendar selected -- fallback to first calendar
          if (this.profile.selectedCalendars && this.profile.selectedCalendars[0]) {
            this.defaultCalendar = this.profile.selectedCalendars[0].id
          }
        }
      }
    },
    laterClick () {
      if (!this.subscriptionLoadingGoogle && !this.subscriptionLoading) {
        this.$emit('closeSetupCalendar')
        this.setSetupCalendarLater(true)
      }
    },
    connectToGoogleCalendars () {
      this.signUpGoogle()
    },
    connectToOutlookCalendars () {
      if (this.profile.msEmail) {
        this.getMicrosoftCalendars()
      } else {
        this.signUpMicrosoft()
      }
    },
    signUpMicrosoft () {
      this.signUpStage = true
      let windowObjectReference = null
      if (windowObjectReference == null || windowObjectReference.closed) {
        windowObjectReference = window.open(`${gaeURL}/ms-login`, 'MsLogin', 'width=800,height=600')
      } else {
        windowObjectReference.focus()
      }
      this.windowOpened = windowObjectReference
      window.addEventListener('message', async event => {
        if (event.origin.startsWith(gaeURL)) {
          const officeToken = event.data.officeToken
          const msEmail = event.data.msEmail
          const msUniqueId = event.data.uniqueId
          const msUserId = event.data.msUserId
          if (officeToken) {
            await this.updateProfile({ profile: { officeToken, msEmail, msUniqueId, msUserId }, noSnack: true })
            await this.loadProfile(this.workspace.id)
            this.getMicrosoftCalendars()
          }
        }
      }, false)
    },
    signUpGoogle () {
      this.signUpStage = true
      let windowObjectReference = null
      if (windowObjectReference == null || windowObjectReference.closed) {
        windowObjectReference = window.open(`${gaeURL}/google-login`, 'googleLogin', 'width=800,height=600')
      } else {
        windowObjectReference.focus()
      }
      this.windowOpened = windowObjectReference
      window.addEventListener('message', async event => {
        if (event.origin.startsWith(gaeURL)) {
          const accessToken = event.data.accessToken
          const googleEmail = event.data.googleEmail
          const uniqueId = event.data.uniqueId
          const googleUserId = event.data.subId
          if (accessToken) {
            // console.log('Google Signup successful - ', { accessToken, googleEmail, uniqueId, googleUserId })
            await this.updateProfile({ profile: { accessToken, googleEmail, uniqueId, googleUserId }, noSnack: true })
            await this.loadProfile(this.workspace.id)
            await this.getGoogleCalendars(accessToken)
          }
        }
      }, false)
    },
    signOut () {
      firebase.auth().signOut().then(() => {
        // console.log('CalendarConnect.signOut')
      })
    },
    async getMicrosoftCalendars () {
      this.calendarsLoadingFor = 'MS'
      let result
      try {
        result = await fetch('https://graph.microsoft.com/v1.0/me/calendarGroups', {
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.profile.officeToken
          }
        })
        // console.log('CalendarConnect.getMicrosoftCalendars.result', result)
        if (result) {
          if (result.status === 401) {
            this.signUpMicrosoft()
          }
          if (result.status === 200) {
            this.calendarGroups = await result.json()
            this.calendars = []
            this.selectedCalendars = []
            if (this.calendarGroups.value) {
              for (const cg of this.calendarGroups.value) {
                const calendarGroupId = cg.id
                const calendarURL = `me/calendarGroups/${calendarGroupId}/calendars`
                // console.log('CalendarConnect.getMicrosoftCalendars', cg, calendarURL)
                const calResult = await fetch(`https://graph.microsoft.com/v1.0/${calendarURL}`, {
                  method: 'get',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.profile.officeToken
                  }
                })
                const calendarRes = await calResult.json()

                if (calendarRes.value.length > 0) {
                  // default calendar color blue assigning
                  const defaultCalendarIndex = calendarRes.value.findIndex(cal => cal.isDefaultCalendar)
                  if (defaultCalendarIndex > -1) {
                    const defaultUsedColorIndex = this.usedColors.findIndex(color => color.hexColor === this.colorPalette[0].hexColor)
                    const colorDetails = defaultUsedColorIndex === -1 ? this.colorPalette[0] : this.generateRandomColor()
                    calendarRes.value[defaultCalendarIndex] = { ...calendarRes.value[defaultCalendarIndex], hexColor: colorDetails.hexColor }
                    this.usedColors.push(colorDetails)
                  }
                  // calendars without hexColor - assigning from color or autoColor gen then convert to hex
                  const calsWithoutColor = calendarRes.value.filter(cal => !cal.hexColor)
                  if (calsWithoutColor.length > 0) {
                    for (const res of calsWithoutColor) {
                      console.log(res)
                      const calIndex = calendarRes.value.findIndex(cal => cal.id === res.id)
                      if (calIndex > -1) {
                        calendarRes.value[calIndex].hexColor = res.color && res.color !== 'auto' ? colorNameToHex(res.color.toLowerCase()) || colorNameToHex(this.convertToCssColor(res.color)) : colorNameToHex(this.getAutoColor().toLowerCase())
                      }
                    }
                  }
                }
                if (calendarRes.value) {
                  // default calendar star assigning
                  for (const res of calendarRes.value) {
                    if (res.isDefaultCalendar) {
                      this.selectedCalendars.push(res)
                      if (!this.defaultCalendar) {
                        this.defaultCalendar = res.id
                      }
                    }
                  }
                }
                // console.log('calendarConnect.getMicrosoftCalendars.res', calendarRes)
                this.calendars.push(calendarRes)
              }
              this.calendarsLoadingFor = ''
              this.subscriptionLoading = false
              this.signUpStage = false
            }
          }
        }
      } catch (err) {
        console.error('getMicrosoftCalendars.error', err, this.failOnException)
        if (this.failOnException) {
          this.failOnException = false
        } else {
          this.signUpMicrosoft()
          this.failOnException = true
        }
      }
    },
    async getGoogleCalendars (accessToken) {
      this.calendarsLoadingFor = 'google'
      // console.log('Getting Google calendars', accessToken)
      const request = await fetch(
        'https://www.googleapis.com/calendar/v3/users/me/calendarList',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      const data = await request.json()
      // console.log('calendarConnect.getGoogleCalendars calendarList', data)
      const defaultCalendarIndex = data.items.findIndex(cal => cal.primary)
      if (defaultCalendarIndex > -1) {
        const defaultUsedColorIndex = this.usedColors.findIndex(color => color.hexColor === this.colorPalette[0].hexColor)
        const colorDetails = defaultUsedColorIndex === -1 ? this.colorPalette[0] : this.generateRandomColor()
        data.items[defaultCalendarIndex] = { ...data.items[defaultCalendarIndex], hexColor: colorDetails.hexColor }
        this.usedColors.push(colorDetails)
      }
      this.googleCalendars = []
      this.googleSelectedCalendars = []
      for (const cal of data.items) {
        if (cal.primary) {
          this.googleSelectedCalendars.push(cal)
          if (!this.defaultCalendar) {
            this.defaultCalendar = cal.id
          }
        }
        this.googleCalendars.push({ ...cal, hexColor: cal.hexColor || cal.backgroundColor })
      }
      this.calendarsLoadingFor = ''
      this.subscriptionLoadingGoogle = false
      this.signUpStage = false
    },
    eventsToMeetings () {
      const meetings = []
      const events = this.events.value
      if (events.length > 0) {
        for (let i = 0; i < events.length; i++) {
          const event = events[i]
          let when = new Date(event.start.dateTime)
          const eventDate = when.toLocaleString('en-GB', { timeZone: 'UTC' }).substr(0, 10)
          if (when) {
            when = when.toLocaleTimeString().substr(0, 5)
          } else {
            when = event.start.date
          }
          let end = new Date(event.end.dateTime)
          if (end) {
            end = end.toLocaleTimeString().substr(0, 5)
          } else {
            end = event.end.date
          }
          meetings.push({
            id: event.id,
            startTime: when,
            endTime: end,
            date: eventDate,
            status: event.isCancelled ? 'cancelled' : 'active',
            subject: event.subject,
            location: event.location.displayName,
            description: event.bodyPreview,
            attendees: event.attendees
          })
        }
        // console.log('CalendarConnect.eventsToMeetings', meetings)
      }
    }
  },
  watch: {
    'profile.calendarsSelected': {
      handler (oldC, newC) {
        this.subscribedCalendars = []
        if (this.profile.calendarsSelected) {
          this.subscribedCalendars = [...this.profile.calendarsSelected]
        }
      },
      immediate: true
    },
    'profile.googleCalendarsSelected': {
      handler (oldC, newC) {
        this.subscribedGoogleCalendars = []
        if (this.profile.googleCalendarsSelected) {
          this.subscribedGoogleCalendars = [...this.profile.googleCalendarsSelected]
        }
      },
      immediate: true
    },
    windowOpened: {
      handler (oldW, newW) {
        const intervalId = setInterval(() => {
          if (this.windowOpened.closed) {
            if (this.signUpStage) {
              this.signUpStage = false
              clearInterval(intervalId)
            }
          }
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss">
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.calendar-connect-wrapper {
  display: flex;
  flex-direction: column;
  .close-icon {
    align-self: flex-end;
    padding: 10px;
    margin-right: 10px;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &.scheduler {
    .cal-lists {
      align-items: center;
    }
  }
  .cal-lists {
    display: flex;
    flex-direction: column;
    &.sign-up {
      display: none; // not displaying the modal with connect btn (avoid modal on top modal)
    }
    &.modal-cal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      background: transparent;
      .close-icon {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 0;
        margin:0;
        z-index: 100;
      }
      &.google {
        .outlook {
          display: none;
        }
      }
      &.MS {
        .google {
          display: none;
        }
      }
    }
    .cal-type {
      width: 415px;
      background: #FFFFFF;
      border: 1px solid #D6D6D6;
      border-radius: 8px;
      display: flex;
      padding: 25px 30px 24px 26px;
      flex-direction: column;
      .mdc-button {
        .loader {
          animation: spin 1s linear infinite;
          border-top: 2px solid transparent;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          position: absolute;
        }
      }
      &.highlighted {
        border-color:#6184AD;
      }
      &:first-of-type {
        margin-bottom: 10px;
      }
      &.google {
        padding: 19px 30px 19px 26px;
      }
      .cal-name-connect {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        min-height: 33px;
        .cal-logo {
          img {
            height: 100%;
          }
        }
        .connect-btn {
          height: 33px;
          width: 110px;
          background: #FFFFFF;
          border: 1px solid #4175B1;
          border-radius: 4px;
          position: relative;
          &.connecting {
            align-items: center;
            justify-content: space-between;
          }
          &.disabled {
            opacity: 0.9;;
            pointer-events: none;
            cursor: default;
          }
          .loader {
            border: 2px solid #4175B1;
            border-top: 2px solid transparent;
            right: 10%;
          }
          .conn-text {
            font-weight: 700;
            font-size: 16px;
            color: #4175B1;
            text-transform: initial;
          }
        }
      }
      .calendar-types-wrapper:not(:empty) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.subscribed {
          &:first-of-type {
            margin-top: 10px;
          }
          .calendar-type {
            border: none;
            padding: 0;
            background: none;
            margin-bottom: 7px;
          }
          &:last-of-type {
            margin-bottom: 20px;
            border-bottom: 0.5px solid #E3E3E3;
            padding-bottom: 10px;
          }
        }
        .calendar-type {
          &.selected {
            background: #F8F8F8;
          }
          margin-bottom: 5px;
          background: #FFFFFF;
          display: flex;
          flex-direction: row;
          padding: 11px;
          max-width: 415px;
          justify-content: space-between;
          border: 1px solid #C9C9C9;
          border-radius: 2px;
          .calendar-default-type {
            display: flex;
            align-items: center;
            .mdc-text {
              margin-right: 10px;
              color: #404040;
              text-align: right;
            }
          }
          .calendar-details {
            display: flex;
            align-items: center;
            position: relative;
            .check-round {
              width: 14px;
              height: 14px;
            }
            .calendar-name {
              margin: 0 10px;
              color: #404040;
              font-weight: 600;
              text-transform: initial;
              text-align: left;
            }
            .calendar-color {
              width: 8px;
              height: 8px;
              border-radius: 1px;
            }
            .down-arrow {
              width: 10px;
              height: 10px;
              margin-left: 10px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .signed-in {
        margin-top: 5px;
        color: #404040;
        font-weight: 300;
      }
      .select-calendars {
        margin-top: 20px;
        display:flex;
        width: 100%;
        flex-direction: column;
        .subscribe-btn {
          margin-left: auto;
          background: #4175B1;
          border-radius: 4px;
          width: 110px;
          margin-top: 15px;
          &.reconnecting {
            width: 125px;
            background: #fff !important;
            border: 1px solid #4175B1;
            align-items: center;
            justify-content: space-between;
            .mdc-text {
              color: #4175b1;
            }
            .loader {
              margin-left:10px;
              border: 2px solid #4175B1;
              border-top: 2px solid transparent;
            }
          }
          &.unsubscribe {
            width: 145px;
            background: #fff !important;
            border: 1px solid #4175B1;
            .mdc-text {
              color: #4175b1;
            }
            .loader {
              border: 2px solid #4175B1;
              border-top: 2px solid transparent;
            }
          }
          .loader {
            border: 2px solid #fff;
            border-top: 2px solid transparent;
            right: 10%;
          }
          &.unsubscribing {
            align-items: center;
            justify-content: space-between;
            background: #fff !important;
          }
          &.subscribing {
            align-items: center;
            justify-content: space-between;
            background: #4175B1 !important;
          }
          &.disabled {
            opacity: 0.9;
            pointer-events: none;
            cursor: default;
            background: #e0e0e0;
          }
          .mdc-text {
            color: #fff;
            text-transform: initial;
            font-weight: 700;
          }
        }
      }
      &:hover {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.31);
        transition: box-shadow ease-in-out 0.3s;
      }
    }
  }
}
</style>
