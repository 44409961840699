import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/performance'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import { region, firebaseSettings } from '../settings'

firebase.initializeApp(firebaseSettings)
const perf = firebase.performance()
const auth = firebase.auth()
const storage = firebase.storage()
const functions = firebase.app().functions(region)
const firestore = firebase.firestore()
firestore.settings({
  experimentalForceLongPolling: true, // to handle proxy / endpoint protected networks
  useFetchStreams: false,
  merge: true
})
const serverTime = firebase.firestore.FieldValue.serverTimestamp

const getCollection = (ref) => {
  return ref.get().then(querySnapShot => {
    const results = []
    querySnapShot.forEach(doc => {
      results.push({ id: doc.id, ...doc.data() })
    })
    return results
  })
}

const getDocument = (ref) => {
  return ref.get().then(doc => {
    if (doc.exists) {
      return doc.data()
    } else {
      return null
    }
  })
}

const setDocument = (ref, doc) => {
  return ref.set(doc, { merge: true })
}

export default {
  perf,
  auth,
  storage,
  firestore,
  Blob: firebase.firestore.Blob,
  functions,
  serverTime,
  getCollection,
  getDocument,
  setDocument
}
