<template>
<node-view-wrapper>
<div class="noteblockcommentmode-scrim" v-if="commentModeFor !== null" @click.prevent.stop="showCommentMode()"></div>
<div
  class="block-wrapper"
  v-bind:class="{'comment-mode' : commentModeFor === noteId, 'menu-opened' : showSharingMenu, selected : noteId === selectedNote, 'is-empty': node.content.size == 2, 'task-note': isTaskNode }"
  @mouseover="onMouseEnter"
  >
  <note-block-menu
    :menuForNoteId="node.attrs['data-note-id']"
    :showNoteBlockMenu="showSharingMenu"
    @closeNoteBlockMenu="closeNoteBlockMenu"
  />
  <span v-if="!editor.options.disableNoteBlockSharing"
    :id="'icon-for-' + node.attrs['data-note-id']"
    class="block-sharing-icon"
    @click="toggleSharingMenu(node.attrs['data-note-id'])"
    @mouseover="onMouseEnter"
    >
    <img src="/static/img/icons/flexibly/sharing-icon.svg" />
  </span>
  <div
    class="draggable-item"
    v-bind:class="{'menu-opened' : showSharingMenu}"
    :data-note-id="node.attrs['data-note-id']"
    @mouseover="onMouseEnter"
    :id="'noteblock-' + noteId"
  >
    <section v-if="commentModeFor === this.noteId" id="noteblockcommentsection" @click.prevent.stop="cancelBubble" @mouseover.prevent.stop="cancelBubble">
      <div class="block-comment-box">
        <div class="comment-bullet" v-html="commentBlockText"></div>
        <div class="block-comments">
          <template v-for="(comment, index) in lookingForwardBlockComments" >
            <div :ref="'looking-forward' + comment.id" class="comment-wrapper" :key="comment.noteBlockId + index" v-if="comment.noteBlockId === noteId">
              <div class="comment-from-details">
                <profile-initials :profileId="comment.fromId" :date="appDate" />
                <mdc-text typo="body2" tag="span">{{ getProfileFromId(comment.fromId) }}</mdc-text>
                <span v-if="comment.date" class="comment-time">
                  <time-ago refresh long :datetime="comment.date.toDate()"></time-ago>
                </span>
              </div>
              <div class="comment-message">
                {{comment.message}}
              </div>
            </div>
          </template>
        </div>
        <message-area class="block-message-area" allowMentions :mentions="teamMentions" @send="sendBlockComments" :placeholderText="'Type a comment'" :profileId="profile.id" :forDate="appDate" :sendTextLabel="'POST'" :disableSend="true" />
      </div>
    </section>
    <div
      class="drag-handle"
      contenteditable="false"
      draggable="true"
      data-drag-handle
    />
    <node-view-content class="content" ref="content" />
    <div class="comment-icon on-hover" @click.prevent.stop="showCommentMode(node.attrs['data-note-id'])" v-if="getCommentCount(node.attrs['data-note-id']) === 0 && isTextPresent() && selectedNote === node.attrs['data-note-id']">
        <base-svg-icon name="comment_hover" v-bind:class="node.attrs['data-note-id']" />
    </div>
    <div class="comment-icon count" @click.prevent.stop="showCommentMode(node.attrs['data-note-id'])" v-else-if="getCommentCount(node.attrs['data-note-id']) > 0 && isTextPresent()">
      <base-svg-icon name="comment-grey" v-bind:class="node.attrs['data-note-id']" />
      <mdc-text tag="span">{{ getCommentCount(node.attrs['data-note-id']) }}</mdc-text>
    </div>
  </div>
</div>
</node-view-wrapper>
</template>

<script>
import NoteBlockMenu from '../../../utils/tiptap/v2/NoteBlockMenu.vue'
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-2'
import BaseSvgIcon from '../../../components/flexibly/BaseSvgIcon'
import { mapActions, mapState } from 'vuex'
import ProfileInitials from '../../../components/flexibly/ProfileInitials'
import MessageArea from '../../../components/flexibly/MessageArea'
import TimeAgo from '../../../components/TimeAgo'
import moment from 'moment'
import { sameDay } from '../../../utils/time'

export default {
  name: 'noteblock',
  components: {
    NoteBlockMenu,
    NodeViewWrapper,
    NodeViewContent,
    BaseSvgIcon,
    ProfileInitials,
    MessageArea,
    TimeAgo
  },
  data () {
    return {
      commentBlockText: '',
      commentModeFor: null,
      showSharingMenu: false,
      clickedMenuFor: null,
      noteId: null,
      isTaskNode: false
    }
  },
  props: {
    node: {
      type: Object
    },
    editor: {
      type: Object
    }
  },
  computed: {
    mySchedule () {
      return this.currentSchedules.filter(schedule => schedule.fromId === this.profile.id)
    },
    myTodaySchedule () {
      const weekdayAbv = moment(this.appDate).format('ddd').toUpperCase()
      if (this.mySchedule.length) {
        return this.mySchedule[0].schedules[weekdayAbv].response
      }
      return ''
    },
    level () {
      let level = 0
      let currentnode = this.$el.parentNode
      while (currentnode) {
        if (currentnode.classList.contains('ProseMirror')) {
          break
        } else {
          level++
          currentnode = currentnode.parentNode
        }
      }
      // console.log('noteblock.level', this.$el.parentNode)
      return level / 2
    },
    selectedNote () {
      if (this.editor) {
        if (this.editor.options) {
          return this.editor.options.selectedNote
        }
      }
      return false
    },
    ...mapState([
      'profile',
      'profiles',
      'appDate'
    ]),
    ...mapState({
      lookingForwardBlockComments: state => state.teams.lookingForwardBlockComments,
      currentSchedules: state => state.teams.currentSchedules,
      lookingForwardPages: state => state.teams.lookingForwardPages,
      teamProfiles: state => state.teams.teamProfiles
    }),
    teamMentions () {
      if (this.teamProfiles) {
        return this.teamProfiles.map((prof) => {
          return { ...prof, name: prof.displayName, initials: this.initials(prof) }
        })
      }
      return []
    }
  },
  mounted () {
    this.noteId = this.node.attrs['data-note-id']
    this.loadLookingForwardBlockComments({ noteBlockId: this.noteId })
  },
  methods: {
    ...mapActions({
      saveLookingForwardBlockComments: 'teams/saveLookingForwardBlockComments',
      loadLookingForwardBlockComments: 'teams/loadLookingForwardBlockComments',
      updateLookingForwardPage: 'teams/updateLookingForwardPage'
    }),
    initials (profile) {
      if (profile) {
        let initialFirst = ''
        let initialLast = ''
        let displayNameParts = ['', '']
        displayNameParts = profile.displayName.split(' ')
        if (profile.firstName) {
          initialFirst = profile.firstName.trim().substring(0, 1)
        } else {
          initialFirst = displayNameParts[0].substring(0, 1)
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${initialFirst}${initialLast}`.toUpperCase()
      }
      return 'U'
    },
    getCommentCount (noteBlockId) {
      if (this.editor.options.forDate) {
        const filteredUpdatePage = this.lookingForwardPages.filter(page => page.userId === this.profile.id && sameDay(moment(page.date.toDate()).startOf('day').toDate(), this.editor.options.forDate.startOf('day').toDate()))
        let updatePage = null
        if (filteredUpdatePage.length > 0) {
          updatePage = filteredUpdatePage[0]
        }
        if (updatePage) {
          const commentCount = updatePage.noteBlocksCommentCount && updatePage.noteBlocksCommentCount[noteBlockId] ? updatePage.noteBlocksCommentCount[noteBlockId].commentCount : 0
          return commentCount
        }
        return 0
      }
    },
    isTextPresent () {
      if (this.$el) {
        if (!this.$el.classList.contains('is-empty')) {
          const blockWrapper = this.$el.querySelector('.block-wrapper')
          if (blockWrapper) {
            if (!blockWrapper.classList.contains('is-empty')) {
              return true
            }
          }
          return false
        } else {
          return false
        }
      }
      return false
    },
    cancelBubble (event) { // maintaining focus always
      const commentText = document.getElementsByClassName('message-area-input')
      if (commentText) {
        setTimeout(function () {
          commentText[0].focus()
        }, 10)
      }
      return false
    },
    getProfileFromId (id) {
      let allProfiles = []
      if (this.profiles) {
        allProfiles = [...this.profiles]
      }
      allProfiles = [...allProfiles, this.profile]
      if (allProfiles) {
        const profileDetails = allProfiles.filter(prof => prof.id === id)
        if (profileDetails.length > 0) {
          return profileDetails[0].displayName
        } else {
          return ''
        }
      }
      return ''
    },
    async sendBlockComments (message) {
      const comment = {
        fromId: message.fromId,
        message: message.text,
        created: new Date(),
        date: new Date(),
        mentions: message.mentions || [],
        type: 'Looking Forward'
      }
      // console.log(this.editor.options.forDate)
      const filteredUpdatePage = this.lookingForwardPages.filter(page => page.userId === this.profile.id && sameDay(moment(page.date.toDate()).startOf('day').toDate(), this.editor.options.forDate.startOf('day').toDate()))
      let updatePage = null
      if (filteredUpdatePage.length > 0) {
        updatePage = filteredUpdatePage[0]
      }
      if (updatePage) {
        const blockCommentCount = updatePage.noteBlocksCommentCount && updatePage.noteBlocksCommentCount[this.noteId] && updatePage.noteBlocksCommentCount[this.noteId].commentCount ? updatePage.noteBlocksCommentCount[this.noteId].commentCount + 1 : 1
        const noteBlock = updatePage.noteBlocksCommentCount || {}
        noteBlock[this.noteId] = { commentCount: blockCommentCount }
        const lookingForwardPage = { ...updatePage, noteBlocksCommentCount: noteBlock }
        comment.pageId = updatePage.id
        await this.saveLookingForwardBlockComments({
          teamId: this.profile.team,
          noteBlockId: this.noteId,
          commentId: comment.id,
          comment
        })
        this.updateLookingForwardPage({ teamId: this.profile.team, lookingForwardPageId: updatePage.id, lookingForwardPage, saveComment: true })
      } else {
        await this.saveLookingForwardBlockComments({
          teamId: this.profile.team,
          noteBlockId: this.noteId,
          commentId: comment.id,
          comment
        })
      }
    },
    async showCommentMode (nodeId) {
      if (nodeId) {
        await this.loadLookingForwardBlockComments({ noteBlockId: this.noteId })
        this.commentModeFor = nodeId
        const noteBlock = document.getElementById('noteblock-' + nodeId)
        if (noteBlock) {
          this.commentBlockText = noteBlock.querySelector('p').innerHTML
        }
        const commentText = document.getElementsByClassName('message-area-input')
        if (commentText) {
          setTimeout(function () {
            commentText[0].focus()
          }, 100)
        }
        return
      }
      this.commentModeFor = null
      this.commentBlockText = ''
    },
    closeNoteBlockMenu (value) {
      this.showSharingMenu = value
      this.resetData()
    },
    resetData () {
      this.clickedMenuFor = null
    },
    toggleSharingMenu (noteId) {
      this.showSharingMenu = !this.showSharingMenu
      this.clickedMenuFor = noteId
    },
    onMouseEnter (event) {
      event.stopPropagation()
      this.editor.options.selectedNote = this.noteId
      this.$emit('mouseEnterBlock', this.level, this.$el)
    },
    onMouseLeave (event) {
      // console.log('NoteBlock.onMouseLeave', this.level)
      event.stopPropagation()
      this.$emit('mouseLeaveBlock', this.level, this.$el)
      this.editor.options.selectedNote = false
    }
  },
  watch: {
    node: {
      handler () {
        if (this.node) {
          // console.log('NoteBlock.Watch.node', this.node)
          if (this.node.firstChild) {
            if (this.node.firstChild.type.name === 'taskList') {
              this.isTaskNode = true
            } else {
              this.isTaskNode = false
            }
          } else {
            this.isTaskNode = false
          }
        } else {
          this.isTaskNode = false
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.noteblockcommentmode-scrim {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.2);
  z-index: 23;
}
.block-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  .draggable-item {
    display: flex;
    padding-top: 10px;
    padding-bottom: 0px;
    margin: 0;
    background: white;
    position: relative;
    width: 100%;
    border: none;
    .drag-handle {
      display: block;
      margin-left: 5px;
      &::before {
        content: ' ';
        margin-right: 10px;
        width: 4px;
        height: 4px;
        background-color: #cc0000;
        border-radius: 50%;
        display: inline-block;
        position: relative;
      }
    }

    p {
        margin:0px;
    }

    .content {
      flex: 1 1 auto;
    }

    .draggable-item {
      .drag-handle {
        display: block;
        margin-left: 5px;
        &::before {
          content: ' ';
          margin-right: 10px;
          width: 4px;
          height: 4px;
          background-color: #cc0000;
          border-radius: 50%;
          display: inline-block;
          position: relative;
        }
      }
    }
  }

  .block-sharing-icon {
    opacity:0;
    cursor: pointer;
    position: absolute;
    height: 15px;
    width: 15px;
    top: 11px;
    z-index: 1;
    left: -25px;
    padding: 5px;
    img {
      height: 15px;
      width: 15px;
    }
  }
  .comment-icon {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    height: 15px;
    width: 15px;
    top: 10px;
    z-index: 2;
    right: -20px;
    display: flex;
    align-items: center;
    color: #6184AD;
    font-size: 10px;
    .mdc-text {
      color: #6184AD;
      font-size: 10px;
      margin-left: 3px;
    }
    &.count {
      opacity: 1;
    }
  }

  #noteblockcommentsection {
    position: absolute;
    z-index: 24;
    background: white;
    top: 33px;
    min-width: 500px;
    border-radius: 5px;
    .comment-bullet {
      padding: 16px;
      &::before {
        content: ' ';
        margin-right: 10px;
        width: 4px;
        height: 4px;
        background-color: #cc0000;
        border-radius: 50%;
        display: inline-block;
        position: relative;
      }
    }
    .block-comment-box {
      .block-comments {
        padding: 10px 0 0 0;
        background: rgba(234,234,234,0.26);
        overflow: auto;
        height: auto;
        max-height: 140px;
        cursor: default;
        & > div.comment-wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          padding: 0 16px;
          .comment-from-details {
            display: flex;
            align-items: center;
          }
        }
        .mdc-text.mdc-typography--body2 {
          color: #333333;
          font-weight: 700;
        }
        .comment-time {
          color: rgba(51,51,51,0.4);
          font-size: 12px;
        }
        .profile-initials {
          margin: 0;
          margin-left: 2px;
        }
        .photo-wrapper {
          margin-left: 2px;
          margin-top: 2px;
          .profile-initials {
            margin: 0;
          }
        }
        .comment-message {
          color: #404040;
          font-size: 14px;
          margin-left: 28px;
          line-height: 22px;
          margin-top: 3px;
        }
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #828282;
        }
        > :last-child {
          margin-bottom: 5px;
        }
      }
      .block-message-area {
        display: flex;
        align-items: center;
        border: 0.5px solid #828282;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 7px 10px;
        background: #ffffff;
        margin: 0 16px 16px;
        width: unset;
        .profile-redirect {
          margin: auto;
        }
      }
    }
  }

  &.task-note {
   > .draggable-item {
     > .drag-handle {
        opacity:0;
        visibility: hidden;
      }
    }
  }

  &.menu-opened {
    background: #FFFBDB;
    .draggable-item {
      background: #FFFBDB;
      &:hover {
        background-color: #FFFBDB;
        .draggable-item {
          background-color: #FFFBDB;
        }
      }
    }
  }

  &.selected {
    > .block-sharing-icon {
      opacity:1;
    }
    &:hover {
      > .draggable-item {
        > .comment-icon.on-hover {
          opacity: 1;
        }
      }
    }
  }

  &.comment-mode {
    .draggable-item {
      .comment-icon.on-hover {
        opacity: 1;
      }
    }
  }

  &.menu-opened {
    > .block-sharing-icon {
      opacity:1;
    }
  }
}
</style>
