<template>
  <div class="no-rec-card-types">
    <div v-if="skeleton" class="skeleton-card-wrapper" :class="skeletonType">
      <template v-if="skeletonType === 'initiative'">
        <span class="empty-circle init-percentage"></span>
        <span class="empty-bar init-name"></span>
        <div class="empty-rounds-list members-list">
          <span v-for="index in 5" :key="index + 'empty-round'" class="empty-round-detail member-detail"></span>
        </div>
        <div class="empty-footer location-details">
          <base-svg-icon name="location-pin-fff" />
          <span class="empty-bar location-name"></span>
        </div>
      </template>
      <template v-if="skeletonType === 'people'">
        <span class="empty-circle profile-details"></span>
        <span class="empty-bar profile-name"></span>
        <div class="empty-ovals-list timings-list">
          <span v-for="index in 3" :key="index + 'empty-oval'" class="empty-oval-detail timing"></span>
        </div>
        <div class="empty-footer location-details">
          <base-svg-icon name="location-pin-fff" />
          <span class="empty-bar location-name"></span>
        </div>
      </template>
    </div>
    <div v-else class="no-rec-card-wrapper">
      <mdc-text tag="span" typo="body2" class="question-text">?</mdc-text>
      <div class="empty-content"></div>
    </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
export default {
  name: 'NoRecommendationCard',
  components: {
    BaseSvgIcon
  },
  props: {
    skeleton: {
      default: false,
      type: Boolean
    },
    skeletonType: {
      default: 'people',
      type: String
    }
  },
  data () {
    return {}
  },
  beforeDestroy () {},
  created () {},
  mounted () {},
  computed: {
    ...mapState([
    ]),
    ...mapState({
    })
  },
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    }),
    handleClickOutside (event) {
    }
  },
  watch: {}
}
</script>

<style lang="scss">
$empty-bgcolor: #f1f1f1;
.skeleton-card-wrapper {
  background: #FFFFFF;
  border: 0.3px solid #ADBCCD;
  border-radius: 4px;
  max-width: 143px;
  max-height: 193px;
  min-width: 143px;
  min-height: 193px;
  .empty-footer {
    width: 100%;
    height: 35px;
    margin-top: auto;
    background: #BDBDBD;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    align-items: center;
    .icon-wrapper {
      margin-left: 12px;
      margin-right: 15px;
    }
    .empty-bar {
      background: #F1F1F1;
      height: 8px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.initiative {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .empty-circle {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: $empty-bgcolor;
      margin: 15px 0 8px 0;
    }
    > .empty-bar {
      height: 16px;
      width: 80px;
      background: $empty-bgcolor;
    }
    .empty-rounds-list {
      display: flex;
      align-items: center;
      margin-top: 15px;
      .empty-round-detail {
        display: flex;
        align-items: center;
        height: 18px;
        width: 18px;
        margin-right: 5px;
        background: $empty-bgcolor;
        border-radius: 50%;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    .empty-footer {}
  }
  &.people {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .empty-circle {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $empty-bgcolor;
      margin: 15px 0 8px 0;
    }
    > .empty-bar {
      height: 16px;
      width: 80px;
      background: $empty-bgcolor;
    }
    .empty-ovals-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 15px;
      .empty-oval-detail {
        height: 18px;
        width: 55px;
        background: $empty-bgcolor;
        margin-bottom: 3px;
        border-radius: 12px;
      }
    }
    .empty-footer {}
  }
}
.no-rec-card-wrapper {
  border: 0.2px dashed #BDBDBD;
  border-radius: 4px;
  max-width: 145px;
  max-height: 195px;
  min-width: 145px;
  min-height: 195px;
  display: flex;
  flex-direction: column;
  .question-text {
    font-size: 53px;
    margin: auto;
    color: #EEEEEE;
    font-weight: 200;
  }
  .empty-content {
    border-top: 0.2px dashed #BDBDBD;
    height: 34px;
  }
}
</style>
