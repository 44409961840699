import { Mark, markInputRule, mergeAttributes } from '@tiptap/core'

export const hypenInputRegex = /(?:^|\s)((?:-)((?:[^*]+))(?:-))$/gm
const StrikeMark = Mark.create({
  name: 'strikemark',
  addOptions () {
    return {
      HTMLAttributes: {}
    }
  },
  parseHTML () {
    return [
      {
        tag: 's'
      },
      {
        tag: 'del'
      },
      {
        tag: 'strike'
      },
      {
        style: 'text-decoration=line-through'
      }
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return ['s', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addInputRules () {
    return [
      markInputRule({
        find: hypenInputRegex,
        type: this.type
      })
    ]
  }
})
export default StrikeMark
