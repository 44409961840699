import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Strike from '@tiptap/extension-strike'
import Italic from '@tiptap/extension-italic'
import { Underline } from '@tiptap/extension-underline'
import Heading from '@tiptap/extension-heading'
import Blockquote from '@tiptap/extension-blockquote'
import TextAlign from '@tiptap/extension-text-align'
import Gapcursor from '@tiptap/extension-gapcursor'

import NoteDocument from '../utils/tiptap/v2/NoteDocument'
import Mention from '@tiptap/extension-mention'
import Hashtag from '../utils/tiptap/v2/Hashtag'
import PageLinkSuggestion from '../utils/tiptap/v2/PageLinkSuggestion'
import PlaceHolder from '../utils/tiptap/v2/PlaceHolder'

import NoteBlockExtension from '../utils/tiptap/v2/NoteBlockExtension'

import BoldMark from '../utils/tiptap/v2/BoldMark'
import ItalicMark from '../utils/tiptap/v2/ItalicMark'
import UnderlineMark from '../utils/tiptap/v2/UnderlineMark'
import StrikeMark from '../utils/tiptap/v2/StrikeMark'

import TaskList from '@tiptap/extension-task-list'
import CustomTaskItem from '../utils/tiptap/v2/CustomTaskItem'

export const extensionList = () => {
  return [
    NoteDocument,
    Paragraph,
    Text,
    Bold,
    Italic,
    Strike,
    Underline,
    PlaceHolder,
    Heading,
    Blockquote,
    TextAlign,
    BoldMark,
    ItalicMark,
    UnderlineMark,
    StrikeMark,
    TaskList,
    CustomTaskItem,
    Gapcursor,
    Mention,
    Hashtag,
    PageLinkSuggestion,
    NoteBlockExtension
  ]
}
