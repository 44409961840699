<template>
  <div class="modal-dialog mdc-dialog"
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="my-dialog-title"
      aria-describedby="my-dialog-content"
      @MDCDialog:opened="onOpened"
      @MDCDialog:closed="onClosed">
    <div class="mdc-dialog__container">
      <div class="mdc-dialog__surface">
        <div v-if="open" class="mdc-dialog__content">
          <span class="title-wrapper">
            <span class="title" v-if="title">
              <base-svg-icon v-if="imageName" :name="imageName" />
              <mdc-icon v-if="iconName" :icon="iconName" outlined />
              <mdc-text tag="span" class="title" typo="headline6" v-if="title">{{title}}</mdc-text>
            </span>
            <mdc-icon-button v-if="!disableClose" class="close-modal" @click.native="close">clear</mdc-icon-button>
          </span>
          <slot/>
        </div>
        <div v-if="saveText" class="mdc-dialog__actions footer-wrapper">
          <mdc-button role="button" aria- v-bind:class="{'disabled' : disableSave}" @click="saveAction" raised>
            {{saveText}}
            <div class="loader" v-if="showSpinner" role="loader"></div>
          </mdc-button>
          <mdc-button class="cancel-button" v-if="enableCancel" @click="close" raised>CANCEL</mdc-button>
        </div>
      </div>
    </div>
    <div class="mdc-dialog__scrim"></div>
  </div>
</template>

<script>
import { MDCDialog } from '@material/dialog'
import BaseSvgIcon from './BaseSvgIcon.vue'
export default {
  model: {
    prop: 'open',
    event: 'change'
  },
  props: {
    showSpinner: {
      type: Boolean,
      default: false
    },
    disableChange: {
      type: Boolean,
      default: false
    },
    enableCancel: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    pauseFocus: {
      type: Boolean,
      default: false
    },
    saveText: {
      type: String,
      default: ''
    },
    disableSave: {
      type: Boolean,
      default: false
    },
    disableClose: {
      type: Boolean,
      default: false
    },
    imageName: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseSvgIcon
  },
  data () {
    return {
      dialog: null,
      opened: false
    }
  },
  mounted () {
    this.dialog = MDCDialog.attachTo(this.$el)
    if (this.open) {
      this.dialog.open()
    }
    this.dialog.escapeKeyAction = this.required ? '' : 'close'
    this.dialog.scrimClickAction = this.required ? '' : 'close'
  },
  watch: {
    open: {
      handler: function () {
        if (this.dialog) {
          if (this.open) {
            this.dialog.open()
          } else {
            this.dialog.close()
          }
        }
      },
      immediate: true
    },
    pauseFocus () {
      if (this.pauseFocus) {
        this.pauseFocusTrap()
      } else {
        this.unpauseFocusTrap()
      }
    }
  },
  methods: {
    getImageSource () {
      if (this.imageName) {
        return '/static/img/icons/flexibly/' + this.imageName
      }
      return ''
    },
    close () {
      this.dialog.close()
    },
    pauseFocusTrap () {
      if (this.opened) {
        if (this.dialog.focusTrap_.pause) {
          this.dialog.focusTrap_.pause()
        }
      }
    },
    unpauseFocusTrap () {
      if (this.opened) {
        if (this.dialog.focusTrap_.unpause) {
          this.dialog.focusTrap_.unpause()
        }
      }
    },
    onOpened (event) {
      this.opened = true
      if (this.pauseFocus) {
        this.pauseFocusTrap()
      }
    },
    onClosed (event) {
      this.opened = false
      if (this.open) {
        this.$emit('close', event)
      }
      this.$emit('change', false)
      if (event.detail.action) {
        if (event.detail.action !== 'close') {
          this.$emit(event.detail.action)
        }
      }
    },
    saveAction () {
      this.$emit('save')
      if (!this.disableChange) {
        this.$emit('change', false)
      }
    }
  }
}
</script>

<style lang="scss">
.modal-dialog {
  &.mdc-dialog {
    z-index: 15;
  }
  .mdc-dialog__content {
    overflow: visible;
    .title-wrapper:not(:empty) {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      justify-content: space-between;
      .title {
        color: #4f4f4f;
        font-weight: 600;
        display: flex;
        align-items: center;
        span {
          margin-left: 8px;
        }
        .mdc-icon {
          margin: 0;
          color: #cc0000;
          font-size: 18px;
        }
      }
      .close-modal {
        padding-right: 0;
        margin-right: -12px;
        &::before,
        &::after {
          --mdc-ripple-fg-size: 0;
          width: auto;
          height: auto;
        }
      }
    }
  }
  .mdc-dialog__scrim {
    background: rgba(0, 0, 0, 0.2);
  }
  .mdc-dialog__surface {
    background: #FFFFFF;
    border: 0.5px solid #BDBDBD;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .footer-wrapper {
    min-height: 70px;
    justify-content: center;
    .mdc-button {
      color: #ffffff;
      background: #00498D;
      border: 1px solid #00498D;
      border-radius: 8px;
      width: max-content;
      .loader {
        border: 2px solid #f3f3f3;
        animation: spin 1s linear infinite;
        border-top: 2px solid transparent;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }
      &.disabled {
        background: #BDBDBD;
        border: 1px solid #BDBDBD;
        pointer-events: none;
      }
      &.cancel-button {
        margin-left: 10px;
      }
    }
  }
}
</style>
