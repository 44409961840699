<template>
  <section role="section" aria-label="onboarding-progress" class="onboarding-progress-wrapper">
    <mdc-text typo="headline5" class="progress-header" tag="div" >{{headerText}}</mdc-text>

    <div role="progressbar" class="progress-container">
      <div :style="getProgressStyle()" class="progress-bar" >
      </div>
    </div>

    <div class="stage-wrapper">
      <div class="stage" v-for="(text, index) of stageText"
        :key="'stage' + index"
        :class="{ past: index + 1 < onboardingStage, selected: index + 1 === onboardingStage }">
        <span class="stage-icon">
          <base-svg-icon name="tick" v-if="index + 1 < onboardingStage" />
          <template v-else>{{ index + 1 }}</template>
        </span>
        <mdc-text class="stage-text" typo="body2" tag="div" >{{text}}</mdc-text>
      </div>
    </div>
    <mdc-button class="exit-button" @click="$emit('exit')" :class="{ 'last' : onboardingStage === stages && onboardingStageStep === 2 }">Exit tutorial</mdc-button>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
export default {
  name: 'OnboardingProgress',
  components: {
    BaseSvgIcon
  },
  props: {
    headerText: {
      default: 'Let\'s set you up!'
    },
    stages: {
      default: 4
    },
    stageText: {
      default () {
        return [
          'Add your location',
          'Who you want to work with',
          'What initiatives',
          'Get Results'
        ]
      }
    }
  },
  computed: {
    ...mapState([
      'profile',
      'onboardingStage',
      'onboardingStageStep'
    ])
  },
  data () {
    return {
    }
  },
  beforeDestroy () {},
  created () {},
  mounted () {},
  methods: {
    ...mapActions([
    ]),
    getProgressStyle () {
      const onStep = this.onboardingStage || 1
      const transform = `scaleX(${(1.0 / this.stages) * onStep})`
      return { transform }
    }
  },
  watch: {}
}
</script>

<style lang="scss">
  .onboarding-progress-wrapper {
    background-color:#ffffff;
    border-radius: 4px;
    padding: 15px;
    min-width:250px;
    width: fit-content;

  .progress-header {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #404040;
    margin-bottom: 15px;
  }

  .progress-container {
    width:100%;
    height:10px;
    background-color: #DFFDD8;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden; // added for border radius fix

    .progress-bar {
      animation: 1s in-out forwards;
      transform-origin: left center;
      height:10px;
      width:100%;
      max-width: 100%;
      min-width: 0%;
      background-color: #91E27C;
      border-radius: 8px 0 0 8px;
    }
  }
  .stage-wrapper {
    display:flex;
    flex-flow: column;
    .stage {
      display:flex;
      flex-flow: row;
      padding-top:5px;
      padding-bottom:5px;

      .stage-icon {
        border-radius: 100%;
        color:#404040;
        border: 1px solid #404040;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        margin-right: 8px;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-wrapper {
          height: 9px;
          width: 12px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .stage-text {
        font-weight: 600;
      }
      &.past {
        .stage-icon {
          background-color: #01CB52;
          color:#ffffff;
          border: none;
        }
      }
      &.selected {
        .stage-icon {
          background-color: #404040;
          color:#ffffff;
        }
      }
    }
  }

  .exit-button {
    margin-top:40px;
    color: #0055A3;
    &.last {
      background: #0055A3;
      color: #fff;
    }
  }
}
</style>
