<template>
  <div>
    <div class="card-header">
      <div v-if="cardDetails.due" class="initiative-due">
        <base-svg-icon name="deadline-grey" />
        <mdc-text typo="caption" tag="span">{{ getDiffOfDue().text }}</mdc-text>
      </div>
      <mdc-text role="contentinfo" aria-label="init-status" tag="span" typo="caption" v-bind:class="cardDetails.status ? cardDetails.status.replace(' ','').toLowerCase() : 'nostatus'" class="initiative-status">{{cardDetails.status ? cardDetails.status : 'NO STATUS'}}</mdc-text>
    </div>
    <div class="card-wrapper">
      <initiative-name :initiativeId="cardDetails.id" showTeam greyHash thinHash showHash />
      <mdc-text tag="span" class="initiative-desc" typo="subtitle2">{{cardDetails.description}}</mdc-text>
      <div class="initiative-members" :ref="cardDetails.id + 'initiativeId'">
        <span class="members-wrapper" v-if="initiativeMembersTodaySchedule.membersWithSchedule.length > showMembersCount">
          <profile-initials v-for="(member, index) in initiativeMembersTodaySchedule.membersWithSchedule" :key="member.id + cardDetails.id + index" :profileId="member.id" :workingAt="member.workingAt" />
          <mdc-text tag="span" typo="caption" class="load-members-count">+{{cardDetails.members.length - showMembersCount}}</mdc-text>
        </span>
        <span v-else class="members-wrapper" role="list" aria-label="init-members">
          <profile-initials v-for="(member, index) in initiativeMembersTodaySchedule.membersWithSchedule" :key="member.id + cardDetails.id + index" :profileId="member.id" :workingAt="member.workingAt" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon'
import ProfileInitials from '../../components/flexibly/ProfileInitials'
import moment from 'moment'
import InitiativeName from './InitiativeName.vue'

export default {
  name: 'Initiative',
  computed: {
    ...mapState([
      'appDate'
    ]),
    ...mapState({
      teamProfiles: state => state.teams.teamProfiles,
      currentSchedules: state => state.teams.currentSchedules,
      profiles: state => state.profiles,
      profile: state => state.profile
    }),
    allProfiles () {
      if (this.profile && this.profiles) {
        return [this.profile, ...this.profiles]
      }
      return []
    },
    initiativeMembersTodaySchedule () {
      /* adding workingAt from currentSchedules
      sorting by name, unshift selfProfile and reduce by workingAt */
      const members = this.cardDetails.members
      // console.log('initiativeMembersTodaySchedule', this.cardDetails.name, members)
      const weekdayAbv = moment(this.appDate).format('ddd').toUpperCase()
      const isAM = moment(this.appDate).format('a') === 'am'
      if (this.currentSchedules && members.length > 0) {
        const membersWithSchedule = [...members].map((member, index) => {
          const profileSchedule = this.currentSchedules.filter(profile => profile.fromId === member)
          const thisProfile = this.allProfiles.filter(profile => profile.id === member)
          const name = thisProfile[0] ? thisProfile[0].firstName : ''
          const profileResponseObj = profileSchedule[0] ? profileSchedule[0].schedules[weekdayAbv] ? profileSchedule[0].schedules[weekdayAbv] : {} : {}
          let profileResponse
          if (profileResponseObj.split) {
            if (isAM) {
              profileResponse = profileResponseObj.amResponse || ''
            } else {
              profileResponse = profileResponseObj.pmResponse || ''
            }
          } else {
            profileResponse = profileResponseObj.response || ''
          }
          return { id: member, workingAt: profileResponse, name }
        })
        const sortedMembersWithSchedule = membersWithSchedule.sort((a, b) => a.name.localeCompare(b.name))
        function unShiftSelfProfileFirst (array, selfProfileId) {
          const copy = [...array]
          if (copy.length > 0) {
            const myProfileIndex = array.findIndex(profileSchedule => profileSchedule.id === selfProfileId)
            const myProfile = array[myProfileIndex]
            if (myProfileIndex > 0) {
              copy.splice(myProfileIndex, 1)
              copy.unshift(myProfile)
              return copy
            }
          }
          return array
        }
        const selfProfileFirst = unShiftSelfProfileFirst(sortedMembersWithSchedule, this.profile.id)
        const frequencyMap = selfProfileFirst.reduce(
          (acc, value) => {
            if (!acc[value.workingAt]) {
              acc[value.workingAt] = []
            }
            acc[value.workingAt].push(value)
            return acc
          }, {}
        )
        // console.log('initiativeMembersTodaySchedule.frequencyMap', this.cardDetails.name, frequencyMap)
        const finalInitiativeMembersWithSchedule = Object.entries(frequencyMap).flatMap(arr => Array(...arr[1]))
        // console.log('initiativeMembersTodaySchedule.finalInitiativeMembersWithSchedule', this.cardDetails.name, finalInitiativeMembersWithSchedule)
        let arrayLength = finalInitiativeMembersWithSchedule.length
        for (let i = 0; i < arrayLength; i++) {
          if (finalInitiativeMembersWithSchedule[i].workingAt === 'notset' && finalInitiativeMembersWithSchedule[i].id !== this.profile.id) {
            finalInitiativeMembersWithSchedule.push(finalInitiativeMembersWithSchedule.splice(i, 1)[0])
            i--
            arrayLength--
          }
        }
        const initiativeWithSchedule = { ...this.initiative, membersWithSchedule: finalInitiativeMembersWithSchedule }
        return initiativeWithSchedule
      }
      return []
    }
  },
  components: {
    BaseSvgIcon,
    ProfileInitials,
    InitiativeName
  },
  data () {
    return {
      showMembersCount: 8
    }
  },
  props: {
    cardDetails: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created () {
  },
  watch: {
  },
  methods: {
    ...mapActions({
    }),
    checkIsToday (date) {
      return moment(date).isSame(moment(), 'day')
    },
    getDiffOfDue () {
      const dueDate = this.cardDetails.due
      if (dueDate) {
        const diffInDays = moment(dueDate.toDate()).diff(moment(), 'days')
        let dateText = {}
        switch (true) {
          case (diffInDays === 0):
            dateText = {
              text: this.checkIsToday(dueDate.toDate()) ? 'Today' : 'Tomorrow',
              color: '#cc0000'
            }
            return dateText
          case (diffInDays === 1):
            dateText = {
              text: diffInDays + ' day',
              color: '#cc0000'
            }
            return dateText
          case (diffInDays > 1 && diffInDays <= 5):
            dateText = {
              text: diffInDays + ' days',
              color: '#FC5555'
            }
            return dateText
          case (diffInDays > 5 && diffInDays <= 14):
            dateText = {
              text: diffInDays + ' days',
              color: '#8C9ABD'
            }
            return dateText
          case (diffInDays < 0):
            dateText = {
              text: 'Overdue',
              color: '#cc0000'
            }
            return dateText
          default:
            dateText = {
              text: diffInDays + ' days',
              color: '#333333'
            }
            return dateText
        }
      }
    }
  }

}
</script>
<style lang="scss">
$color-lists: #00498D;
$color-ontrack: #01CB52; /**ontrack */
$color-incomplete: #FFB11B; /**atrisk */
$color-overdue: #FF0F00; /**offtrack */
$color-onhold: #A0A0A0;
$color-completed: #404040;
.card-header {
  height: 28px;
  display: flex;
  justify-content: flex-end;
  padding: 8px 15px 0 15px;
  flex-direction: row;
  align-items: center;
  background: #F9F9F9;
  border-radius: 10px 10px 0 0;
  position: relative;
  overflow: hidden;
  .initiative-due {
    display: flex;
    font-size: 10px;
    margin-right: 20px;
    color: #cc0000;
    align-items: center;
    .mdc-icon {
      font-size: 11px;
      color: #cc0000;
      margin-right: 3px;
      line-height: 12px;
    }
    .mdc-text {
      font-size: 10px;
      color:#7A95B5;
      margin-left: 3px;
      margin-top: 1px;
    }
  }
  .initiative-status {
    font-size: 10px;
    font-weight: 600;
    &.ontrack {
      color: $color-ontrack;
    }
    &.completed {
      color: $color-completed;
    }
    &.atrisk {
      color: $color-incomplete;
    }
    &.onhold {
      color: $color-onhold;
    }
    &.offtrack {
      color: $color-overdue;
    }
  }
}
.card-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  .initiative-name {
    font-size: 18px;
    color: #404040;
    font-weight: 600;
    max-height: 50px;
    min-height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
  }
  .initiative-desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 50px;
    min-height: 40px;
    margin-bottom: 20px;
  }
  .initiative-members {
    .members-wrapper {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      .profile-redirect {
        margin-bottom: 3px;
      }
    }
  }
  &.add-new-wrapper {
    height: calc(100% - 30px);
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      color: #8C9ABD;
      .add-initiative {
        font-size: 96px;
        color: #8C9ABD;
        font-weight: 100;
      }
    }
  }
}
</style>
