<template>
  <div class="profile-card" v-bind:class="{ 'hover' : hoverStyle }">
    <div class="profile-card-wrapper">
      <div class="swap-container" v-bind:class="{ 'hover' : hover, 'hover-enabled': !myprofile && hoverStyle && profile.role && profile.aboutMe}">
        <div class="swapper">
          <div class="front">
          <mdc-card class="profile-wrapper">
            <div :class="'profile ' + profile.cardDesign">
              <div :class="'profile-bg ' + profile.cardDesignBackground">
                <span v-if="myprofile" @click="openCardDesign" class="mdc-icon duome-icon edit-area">
                  <hotspot
                    @shown="updateHotspot('cardDesign')"
                    :visible="!profile.hotspotClicked.cardDesign && (profile.hotspotSeen.cardDesign <= 5 || !profile.hotspotSeen.cardDesign)" :diameter="32" :borderWidth="8">
                    <span style="margin-top:4px;" class="edit-icon">
                      <span class="mdc-icon mdc-icon--material material-icons">create</span>
                    </span>
                  </hotspot>
                  <span v-if="myprofile" class="hover-info">Edit Design</span>

                </span>
                <div v-if="myprofile" @click="avatarClick" class="profile-image editable">
                  <span v-if="profile" class="avatar">
                    <img v-if="profile.photoURL" :src="profile.photoURL">
                    <default-avatar v-else :profile="profile" showCover />
                  </span>
                </div>
                <div v-else class="profile-image">
                  <span v-if="profile" class="avatar">
                    <img v-if="profile.photoURL" :src="profile.photoURL">
                    <default-avatar v-else :profile="profile"/>
                  </span>
                </div>

                <div v-if="!myprofile && !hideBookmark" style="position: absolute;top: 16px;right:6px;">
                  <div style="flex: 0;min-width: 50px;" v-if="bookmarked">
                    <round-button ripple style="margin-top:0;margin-bottom:50px;box-shadow:none !important" background="transparent !important" class="bookmark" @click.prevent.stop="unbookmark()" label="unbookmark" title="unbookmark">
                        <img slot="leading-icon" class="icon bookmark-icon" src="/static/img/icons/bookmark-on.svg">
                    </round-button>
                  </div>
                  <div style="flex: 0;min-width: 50px;" v-else>
                    <round-button ripple style="margin-top:0;margin-bottom:50px;box-shadow:none !important" background="transparent !important" class="bookmark" @click.prevent.stop="bookmark()" label="bookmark" title="bookmark">
                        <img slot="leading-icon" class="icon bookmark-icon" src="/static/img/icons/bookmark-off.svg">
                    </round-button>
                  </div>
                </div>

                <div class="profile-text">
                  <div style="display:flex;align-items: center;justify-content: center;">
                  <mdc-subheading class="displayname">{{profile.displayName}}</mdc-subheading>
                  </div>
                  <mdc-body class="profile-status" typo="body2">{{profile.role || profile.headline || ''}}</mdc-body>
                </div>

                <span class="same-workspace" v-if="workspace.assignedSubWorkspace && profile.subWorkspaceId && !myprofile">
                    <v-popover
                      v-if="profile.subWorkspaceId.includes(workspace.assignedSubWorkspace.id)"
                      offset="1"
                      delay="100"
                      popoverClass="profilecard-workspace-tooltip"
                      style="display:absolute"
                      placement='bottom-end'
                      :disabled="false"
                      trigger="hover focus"
                      tag="span"
                      autoHide
                      :open="showTooltip"
                       @hide="showTooltip = false"
                    >
                    <span class="tooltip-target" @click.prevent.stop="showTooltip = true">
                      <img
                      src="/static/img/icons/corporate-fare.svg"
                      style="width:20px;height:20px;"
                      />
                    </span>
                      <template slot="popover">
                        <div>
                          <p>Works at your workspace</p>
                        </div>
                      </template>
                    </v-popover>
                  </span>

              </div>
            </div>
          </mdc-card>
          </div>
        </div>
      </div>
      <mdc-card @click.native.prevent.stop v-if="detail" class="profile-details" style="cursor:default" :class="{ hover : hoverStyle }">
        <div class="link-row link-row-top">
          <div>
            <span class="mdc-icon duome-icon">
              <img style="width:18px;" src="/static/img/icons/location_outline_light.svg">
            </span>
            <div class="profile-stat">
              <mdc-body class="value">{{profile.location}}</mdc-body>
              <div v-if="profile.locationFull" class="hover-info">{{profile.locationFull}}</div>
              <span><img src="/static/img/separator.svg"></span>
              <mdc-body tag="span" class="field" typo="body2">Location</mdc-body>

            </div>
          </div>
          <div>
            <mdc-icon>
              <img style="height:18px;" src="/static/img/icons/watch_outline_light.svg">
            </mdc-icon>
            <div class="profile-stat">
              <mdc-body tag="span" class="value">{{flexDisplay}}</mdc-body>
              <span :class="'days-' + flexStyle">
                <span class="day"></span>
                <span class="day"></span>
                <span class="day"></span>
                <span class="day"></span>
                <span class="day"></span>
              </span>
              <mdc-body tag="span" class="field" typo="body2">Flex Schedule</mdc-body>
            </div>
          </div>
          <div>
            <mdc-icon>
              <img style="width:18px;" src="/static/img/icons/home-work-outline-light.svg">
            </mdc-icon>
            <div class="profile-stat">
              <mdc-body tag="span" class="value">{{profile.workstyle}}</mdc-body>
              <span><img src="/static/img/separator.svg"></span>
              <mdc-body tag="span" class="field" typo="body2">Work Style</mdc-body>
            </div>
          </div>
        </div>
        <div class="link-row link-row-bottom" style="">
          <div v-if="!myprofile">
            <connection-modal :medium="medium" :hoverHigh="hoverHigh" :cta="inProfile" :profile="profile"></connection-modal>
          </div>
          <div style="margin-left:auto" v-if="showStatus">
            <div class="status-tag" v-if="statusRemote">
              <span slot="leading-icon" class="material-icons mdc-list-item__graphic">home_work</span>
              Remote
            </div>
            <div class="status-tag" v-if="statusOffice">
              <span slot="leading-icon" class="material-icons mdc-list-item__graphic">business</span>
              Office
            </div>
            <div class="status-tag" v-if="statusSabbatical">
              <span slot="leading-icon" class="material-icons mdc-list-item__graphic">emoji_people</span>
              Sabbatical
            </div>
            <div class="status-tag" v-if="statusParental">
              <span slot="leading-icon" class="material-icons mdc-list-item__graphic">emoji_people</span>
              Parental
            </div>
          </div>
          <div style="margin-left:auto" v-if="!hideShare && !profile.workspaceId && myprofile">
            <span>

              <round-button @click.prevent.stop="openMenu" background="transparent" class="share" title="Share" iconOutline icon="share" label="Share"/>

              <div class="mdc-menu-surface--anchor">
                <mdc-menu class="sharemenu" v-model="shareopen">
                  <social-sharing :url="`${publicProfilesUrl}/me/${profile.id}`" inline-template network-tag="li">
                    <div>
                    <network class="mdc-menu-item mdc-list-item" network="email">
                      <font-awesome-icon :icon="['fa', 'envelope']" /> Email
                    </network>
                    <network class="mdc-menu-item mdc-list-item" network="facebook">
                      <font-awesome-icon :icon="['fab', 'facebook']" /> Facebook
                    </network>
                    <network class="mdc-menu-item mdc-list-item" network="linkedin">
                      <font-awesome-icon :icon="['fab', 'linkedin']" /> Linkedin
                    </network>
                    <network class="mdc-menu-item mdc-list-item" network="twitter">
                      <font-awesome-icon :icon="['fab', 'twitter']" /> Twitter
                    </network>
                    <network class="mdc-menu-item mdc-list-item" network="whatsapp">
                      <font-awesome-icon :icon="['fab', 'whatsapp']" /> Whatsapp
                    </network>
                    </div>
                  </social-sharing>
                </mdc-menu>
              </div>
            </span>
          </div>

          <div v-if="!myprofile && hoverStyle && !hideMore" class="mobileonly" @click.prevent.stop="hover = !hover" >
            <mdc-icon class="action" icon="more_horiz">
            </mdc-icon>
          </div>
        </div>

        <div v-if="inProfile">
          <div class="button-row" v-if="!profile.emailVerified">
            <round-button background="transparent" class="verify" title="Not Verified" iconOutline icon="verified_user" label="Verify"/>
            <span>Account is <span class="emphasis">Not Verified</span></span>
          </div>
          <div class="button-row" v-else>
            <round-button background="transparent" class="verifed" title="Verifed" iconOutline icon="verified_user" label="Verifed"/>
            <span>Account is <span class="emphasis">Verified</span></span>
          </div>
        </div>

      </mdc-card>

    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConnectionModal from '../../components/ConnectionModal.vue'
import RoundButton from '../../components/RoundButton.vue'
import DefaultAvatar from '../../components/DefaultAvatar'
import { publicProfilesUrl } from '../../settings'
import Hotspot from '../../components/Hotspot'

export default {
  name: 'ProfileCard',
  props: {
    profile: {
      type: Object,
      default: function () {
        return {}
      }
    },
    detail: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isActive: {
      type: Boolean,
      default: false
    },
    hoverStyle: {
      type: Boolean,
      default: true
    },
    inProfile: {
      type: Boolean,
      default: false
    },
    hideShare: {
      type: Boolean,
      default: false
    },
    hideBookmark: {
      type: Boolean,
      default: false
    },
    hideMore: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    hoverHigh: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ConnectionModal,
    RoundButton,
    DefaultAvatar,
    Hotspot
  },
  computed: {
    ...mapState([
      'workspace'
    ]),
    ...mapState({
      ownProfile: (state) => { return state.profile }
    }),
    myprofile () {
      return this.profile.id === this.user.uid
    },
    flexDisplay () {
      const flexschedule = '' + (this.profile.flexschedule || '')
      if (flexschedule !== '') {
        if (flexschedule.indexOf('Days') === -1) {
          return flexschedule + ' Days'
        }
      }
      return flexschedule
    },
    flexStyle () {
      const flexschedule = this.profile.flexschedule || ''
      return ('' + flexschedule).replace(' Days', '').replace('.', '-')
    },
    statusRemote () {
      if (this.profile.currentStatus) {
        if (this.profile.currentStatus.includes('Remote')) {
          return true
        }
      }
      return false
    },
    statusOffice () {
      if (this.profile.currentStatus) {
        if (this.profile.currentStatus.includes('Office')) {
          return true
        }
      }
      return false
    },
    statusSabbatical () {
      if (this.profile.currentStatus) {
        if (this.profile.currentStatus.includes('Sabbatical')) {
          return true
        }
      }
      return false
    },
    statusParental () {
      if (this.profile.currentStatus) {
        if (this.profile.currentStatus.includes('Parental Leave')) {
          return true
        }
      }
      return false
    }
  },
  data () {
    return {
      showTooltip: false,
      hover: false,
      shareopen: false,
      publicProfilesUrl,
      bookmarked: false
    }
  },
  mounted () {
    this.bookmarked = this.isBookmarked()
  },
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    avatarClick () {
      this.$emit('avatar')
    },
    isBookmarked () {
      if (this.ownProfile) {
        if (this.ownProfile.bookmarks) {
          return this.profile.id in this.ownProfile.bookmarks
        }
      }
      return false
    },
    bookmark () {
      if (!this.ownProfile.bookmarks) {
        this.ownProfile.bookmarks = {}
      }
      this.ownProfile.bookmarks[this.profile.id] = { type: 'profile' }
      this.bookmarked = true
      this.updateProfile({ profile: { bookmarks: this.ownProfile.bookmarks }, noSnack: true })
    },
    unbookmark () {
      if (this.ownProfile.bookmarks) {
        delete this.ownProfile.bookmarks[this.profile.id]
      }
      this.bookmarked = false
      this.updateProfile({ profile: { bookmarks: this.ownProfile.bookmarks }, noSnack: true })
    },
    openMenu () {
      // console.log(window.navigator)
      if (window.navigator.share) {
        window.navigator.share({
          title: `DuoMe: ${this.profile.displayName}`,
          url: `${this.publicProfilesUrl}/me/${this.profile.id}`
        }).then(() => {
          // console.log('Thanks for sharing!')
        })
          .catch(console.error)
      } else {
        this.shareopen = true
      }
    },
    openCardDesign () {
      this.$emit('openCardDesign')
      this.profile.hotspotClicked.cardDesign = true
      this.updateProfile({ profile: { hotspotClicked: this.profile.hotspotClicked }, noSnack: true })
    },
    updateHotspot (hotspot) {
      if (!this.profile.hotspotSeen[hotspot]) {
        this.profile.hotspotSeen[hotspot] = 1
      } else {
        this.profile.hotspotSeen[hotspot] = this.profile.hotspotSeen[hotspot] + 1
      }
      this.updateProfile({ profile: { hotspotSeen: this.profile.hotspotSeen }, noSnack: true })
    },
    analytics (payload) {
      // eslint-disable-next-line no-undef
      // analytics.track('shareProfile', {
      //  id: this.profile.id,
      //  platform: payload
      // })
    }
  }
}
</script>

<style scoped>

.profile-card {
  min-width: 255px;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.profile-card .status-tag {
  display:flex;
  justify-content: center;
  align-items: center;
}

.profile-card .same-workspace {
  position: absolute;
  right:24px;
  bottom:20px;
  z-index:9;
  width:20px;
  height:20px;
}

.profile-card .status-tag .mdc-list-item__graphic {
  margin-right:6px;
}

@media (min-width: 662px){
  .profile-card .default-avatar .default-text {
    font-size: 8vw;
  }
}

@media (min-width: 840px){
  .profile-card .default-avatar .default-text {
    font-size: 5vw;
  }
}

@media (min-width: 1180px){
  .profile-card .default-avatar .default-text {
    font-size: 4vw;
  }
}

@media (min-width: 1480px){
  .profile-card .default-avatar .default-text {
    font-size: 3vw;
  }
}

.displayname {
  display:inline-block;
  overflow-wrap: break-word;
  max-width:100%;
}
.sharemenu {
  min-width: 170px;
}
/* entire container, keeps perspective */

.open-card-design .mdc-dialog__body .profile-card {
  width: 46%;
  display: inline-block;
  margin: 2%;
  cursor: pointer;
}

.profile-stat .hover-info {
  display:none;
  position:absolute;
  color:#fff;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  margin-top: 40px;
  border-radius:5px;
  font-size:12px;
  z-index:9999;
}
.profile-stat:hover .hover-info {
  display:flex;
}

.profile-card.hover:hover .swapper .profile-wrapper {
  background:none;
}

.profile-wrapper {
  background-color:white;
  border-radius: 13px;
  width:100%;
  overflow: hidden;
  padding-top: calc(154 / 238 * 100%);
  position: relative;
}

.safari .profile-wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.profile, .profile-bg {
  display:flex;
  flex-flow: row;
  position: absolute;
  top: 0px;
  left: 0;
  width: 101%;
  height: 100%;
  align-items: center;
}

.profile {
  top:-8px;
  background-size: cover;
  height:calc(100% + 16px);
}

/* Background position this goes on the same div as profile-bg class */
div[class*="profile-bg-"] .profile-text {
  padding-bottom: 0px;
  padding-left:0px;
}

div[class*="profile-bg-"] .profile-text img {
  display: none;
}

.profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #DEDBD6 0%);
}
.profile-bg-top {
  background: linear-gradient(#F2EEE6 85%, transparent 0%);
}
.profile-bg-top .profile-text {
  padding-top: 0;
  padding-bottom: 20px;
}
.profile-card:hover .profile-bg-top {
  background: linear-gradient(#DEDBD6 85%, transparent 0%);
}
.profile-bg-bottom {
  background: linear-gradient(transparent 40%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-bottom {
  background: linear-gradient(transparent 40%, #DEDBD6 0%);
}
.profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #F2EEE6 0%);
}
.profile-card:hover .profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #DEDBD6 0%);
}

div[class*="profile-bg-"] .profile-text {
  padding-bottom: 0px;
}

div[class*="profile-bg-"] .profile-text img {
  display: none;
}

/* Background colour/bg options this goes on the same div a profile class */
.profile-colour-cyan {
  background-color: #2cbcb6;
}
.profile-colour-magenta {
  background-color: #C10457;
}
.profile-colour-orange {
  background-color: #f4854d;
}
.profile-colour-yellow {
  background-color: #ffd12b;
}
.profile-colour-blue {
  background-color: #3560AF;
}
.profile-design-1-cyan {
  background-image: url('/static/img/card-designs/profile-design-1-cyan.svg');;
}
.profile-design-1-magenta {
  background-image: url('/static/img/card-designs/profile-design-1-magenta.svg');
}
.profile-design-1-orange {
  background-image: url('/static/img/card-designs/profile-design-1-orange.svg');
}
.profile-design-1-yellow {
  background-image: url('/static/img/card-designs/profile-design-1-yellow.svg');
}
.profile-design-2-cyan {
  background-image: url('/static/img/card-designs/profile-design-2-cyan.svg');
}
.profile-design-2-white {
  background-image: url('/static/img/card-designs/profile-design-2-white.svg');
}
.profile-design-2-magenta {
  background-image: url('/static/img/card-designs/profile-design-2-magenta.svg');
}
.profile-design-2-orange {
  background-image: url('/static/img/card-designs/profile-design-2-orange.svg');
}
.profile-design-2-yellow {
  background-image: url('/static/img/card-designs/profile-design-2-yellow.svg');
}
.profile-design-3-cyan {
  background-image: url('/static/img/card-designs/profile-design-3-cyan.svg');
}
.profile-design-3-magenta {
  background-image: url('/static/img/card-designs/profile-design-3-magenta.svg');
}
.profile-design-3-orange {
  background-image: url('/static/img/card-designs/profile-design-3-orange.svg');
}
.profile-design-3-yellow {
  background-image: url('/static/img/card-designs/profile-design-3-yellow.svg');
}
.profile-design-4-cyan {
  background-image: url('/static/img/card-designs/profile-design-4-cyan.svg');
}
.profile-design-4-magenta {
  background-image: url('/static/img/card-designs/profile-design-4-magenta.svg');
}
.profile-design-4-orange {
  background-image: url('/static/img/card-designs/profile-design-4-orange.svg');
}
.profile-design-4-yellow {
  background-image: url('/static/img/card-designs/profile-design-4-yellow.svg');
}
.profile-design-5-cyan {
  background-image: url('/static/img/card-designs/profile-design-5-cyan.svg');
}
.profile-design-5-white {
  background-image: url('/static/img/card-designs/profile-design-5-white.svg');
}
.profile-design-5-magenta {
  background-image: url('/static/img/card-designs/profile-design-5-magenta.svg');
}
.profile-design-5-orange {
  background-image: url('/static/img/card-designs/profile-design-5-orange.svg');
}
.profile-design-5-yellow {
  background-image: url('/static/img/card-designs/profile-design-5-yellow.svg');
}
.profile-design-6-cyan {
  background-image: url('/static/img/card-designs/profile-design-6-cyan.svg');
}
.profile-design-6-white {
  background-image: url('/static/img/card-designs/profile-design-6-white.svg');
}
.profile-design-6-magenta {
  background-image: url('/static/img/card-designs/profile-design-6-magenta.svg');
}
.profile-design-6-orange {
  background-image: url('/static/img/card-designs/profile-design-6-orange.svg');
}
.profile-design-6-yellow {
  background-image: url('/static/img/card-designs/profile-design-6-yellow.svg');
}
.profile-design-6-multi {
  background-image: url('/static/img/card-designs/profile-design-6-multi.svg');
}
.profile-design-7-cyan {
  background-image: url('/static/img/card-designs/profile-design-7-cyan.svg');
}
.profile-design-7-white {
  background-image: url('/static/img/card-designs/profile-design-7-white.svg');
}
.profile-design-7-magenta {
  background-image: url('/static/img/card-designs/profile-design-7-magenta.svg');
}
.profile-design-7-orange {
  background-image: url('/static/img/card-designs/profile-design-7-orange.svg');
}
.profile-design-7-yellow {
  background-image: url('/static/img/card-designs/profile-design-7-yellow.svg');
}
.profile-image {
  width: 48%;
}
.avatar {
  width: 80%;
  margin: 10%;
  padding-top: 80%;
  position: relative;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: block;
  overflow: hidden;
}
.avatar img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;;
    left: 0;
    object-fit: cover;
}
.profile-text {
  width: 52%;
  height: 50%;
  text-align: center;
  padding: 5%;
  padding-top: 30% !important;
  overflow: hidden;
}
.link-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.link-row-top > div {
  flex: 0;
}
.profile-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
}
.profile-headline {
  font-style:italic;
  font-size: 14px;
  color: #333333;
}
.profile-card .profile-image {
  position:relative;
}
.profile-card .mdc-body.profile-status {
  max-width: calc(100% + 12px);
  overflow: hidden;
}
.profile-card .profile-image .profile-status {
  color:gray;
}
.profile-card .profile-image.editable:hover {
  cursor:pointer;
}
.profile-card .profile-image.editable .overlay{
  display:none;
  position:absolute;
  width:100%;
  height:100%;
}
.profile-card .profile-image.editable:hover .overlay{
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/424395/camera.svg");
  background-size: 50px 41px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
  z-index:9;
  display:block;
}
.profile-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.profile-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1.1;
}
span[class^="days-"] {
  margin-top: 3px;
  width: 55px;
  white-space: nowrap;
}
span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 8px;
  margin-left: 1.5px;
  margin-right: 1.5px;
}
span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}
span.days- .day:nth-of-type(-n+5) {
  background: #AD1457;
}

span.days-1-5 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
span.days-2-5 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
span.days-3-5 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
span.days-4-5 .day:nth-of-type(-n+4) {
  background: #AD1457;
}

span.days-0-5 .day:nth-of-type(1) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-1-5 .day:nth-of-type(2) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-2-5 .day:nth-of-type(3) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-3-5 .day:nth-of-type(4) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
span.days-4-5 .day:nth-of-type(5) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}

.profile-status-bar {
  display:flex;
  width:100%
}
.profile-details {
  padding: 5% 0 0 0;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.profile-details .duome-icon img {
  width:20px;
  height: 20px;
}
.profile .edit-area {
  position: absolute;
  top: 17px;
  right: 17px;
  width:30px;
  height:30px;
}
.profile .edit-icon {
  background: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0;
}
.profile .edit-icon .mdc-icon.mdc-icon--material {
  font-size: 20px;
  line-height: 25px;
  color: #888;
  margin-right: 0;
}
.profile .edit-icon:hover {
  cursor:pointer;
}
.profile .edit-area .hover-info {
  display:none;
  position:absolute;
  color:white;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 6px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 101;
  left: -40px;
  bottom: -30px;
  white-space: nowrap;
  line-height: 20px;
}
.profile .edit-area:hover .hover-info {
  display:flex;
}
.profile-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
}
.profile-details .field {
  font-style: italic;
  margin-top: 3px;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.location .profile-stat .value {
  font-style:italic;
  font-size: 14px;
}
.role {
  font-weight: 500;
  font-style:italic;
  font-size: 14px;
}
.mdc-icon {
  margin-right: 5px;
}
.profile-card:not(:hover) .link-row-bottom .mdc-button {
  background-color: #ffffff;
  box-shadow: none;
}
.profile-card .link-row-bottom .mdc-button .mdc-icon {
  margin: 0 10px 5px 0;
}
.profile-card .link-row-bottom {
  justify-content: flex-start;
}
.profile-card .link-row-bottom > div {
  flex:0;
}
.profile-details .mdc-button img {
  margin-right:6px;
}
.profile-details .mdc-button img.icon-active {
  display:none;
}
.profile-card.hover:hover .profile-wrapper {
  background: #F6F6F6;
}
.about > div {
  font-size: 14px;
  margin: 10px 0;
}
.action {
  cursor: pointer;
}
.bookmark {
  cursor: pointer;
  color: #333333;
}
.modal .mdc-dialog__container {
  width: calc(100% - 20px);
  min-width: 340px !important;
  max-width: 885px !important;
}
</style>

<style>
/** Not Scoped for hover styling sub component **/

.profilecard-workspace-tooltip .popover-inner {
    padding: 10px !important;
    height: 25px !important;
    line-height: 0px !important;
}

.profile-card .open-card-design .mdc-dialog__surface {
  height: calc(100vh - 80px);
  min-height: 400px;
}
.profile-card .open-card-design .mdc-dialog__body--scrollable {
  height:100%;
  max-height:100%;
}
.profile-card .button-row {
  display: flex;
  align-items: center;
}
.profile-card .button-row .verify {
  color:#b00020;
  margin-left:8px;
}

.profile-card .button-row .verifed {
  color:#00b020;
  margin-left:8px;
}
</style>
