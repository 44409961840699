<template>
  <div class="member-request-dialog" v-if="showDialog" tabindex="0">
    <header>
      <template v-if="filteredRequests.length > 0">
        <base-svg-icon name="people-red" />
        <mdc-text v-if="myTeam" tag="span" typo="headline6">Team member requests for {{myTeam.name}}</mdc-text>
      </template>
      <template v-else>
        <mdc-text tag="span" typo="headline6">No team member requests</mdc-text>
      </template>
      <mdc-icon-button aria-label="close" class="close-modal" @click.native="closeDialog()">clear</mdc-icon-button>
    </header>
    <section class="member-requests-content" v-if="filteredRequests.length > 0">
      <header>
        <mdc-text tag="span" typo="body2">REQUESTS</mdc-text>
      </header>
      <div class="requests-list-wrapper" role="list">
        <div class="request-details-actions" v-for="request in filteredRequests" :key="request.id">
          <div class="details">
            <profile-initials forUser :userInitials="getMemberInitials(request.userName)" />
            <mdc-text tag="span" typo="body2" class="member-name">{{request.userName}} -</mdc-text>
            <mdc-text tag="span" typo="body2" class="member-email">{{request.email}}</mdc-text>
          </div>
          <mdc-text v-if="helpTextAdd" tag="span" typo="caption">{{helpTextAdd}}</mdc-text>
          <div class="actions">
            <mdc-button raised aria-label="approve" class="approve" @click.native="addInvite(request.email, request.id)" :class="sendingInvite ? 'disabled' : ''">Approve</mdc-button>
            <mdc-button raised aria-label="deny" class="deny" @click.native="denyRequest(request.email, request.id)" :class="sendingInvite ? 'disabled' : ''">Deny</mdc-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import { onboarding } from '../../utils/backend'
import ProfileInitials from '../../components/flexibly/ProfileInitials.vue'
import { getNameInitials } from '../../utils/profile'
export default {
  name: 'MemberRequestDialog',
  components: {
    BaseSvgIcon,
    ProfileInitials
  },
  data () {
    return {
      sendingInvite: false,
      helpTextAdd: ''
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  beforeDestroy () {
  },
  created () {},
  mounted () {
  },
  computed: {
    ...mapState([
      'user',
      'memberRequestDisplay',
      'profile',
      'workspace'
    ]),
    ...mapState({
      myTeam: state => state.teams.team,
      requests: state => state.teams.requests,
      notifications: state => state.teams.notifications
    }),
    filteredRequests () {
      return this.requests.filter((req) => !req.completed)
    }
  },
  methods: {
    ...mapActions([
      'setMemberRequestDisplay'
    ]),
    ...mapActions({
      updateRequest: 'teams/updateRequest',
      loadTeam: 'teams/loadTeam',
      updateNotification: 'teams/updateNotification'
    }),
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        this.closeDialog()
      }
    },
    getMemberInitials (name) {
      return getNameInitials(name)
    },
    closeDialog () {
      this.setMemberRequestDisplay(false)
    },
    checkClose () {
      const self = this
      setTimeout(() => {
        // console.log('checkClose', self.filteredRequests.length)
        if (self.filteredRequests.length === 0) {
          self.closeDialog()
        }
      }, 1000)
    },
    inDomains (email) {
      const emailDomain = email.split('@')[1].toLowerCase()
      if (this.workspace) {
        if (this.workspace.domains) {
          for (const domain of this.workspace.domainUrls) {
            if (emailDomain === domain) {
              return true
            }
          }
        } else {
          return this.workspace.domainUrl === emailDomain
        }
      }
      return false
    },
    async addInvite (memberEmail) {
      this.sendingInvite = true
      this.helpTextAdd = ''
      memberEmail = memberEmail.toLowerCase()
      const guestsAllowed = this.workspace.guestAccessWorkspace

      console.log('addInvite.send-invites', memberEmail, this.user.uid)
      const params = {
        userId: this.user.uid,
        invites: [memberEmail]
      }
      const paramsAdd = {
        userId: this.user.uid,
        emails: [memberEmail]
      }
      const paramsRequest = {
        email: memberEmail,
        workspaceId: this.workspace.id,
        teamId: this.profile.team
      }

      const inWorkspace = this.workspace.members.includes(this.memberEmail)

      try {
        // eslint-disable-next-line no-unused-vars
        const matchingRequest = this.requests.filter((req) => req.email.toLowerCase() === memberEmail)
        if (matchingRequest[0]) {
          paramsRequest.requestId = matchingRequest[0].id
          paramsRequest.defaultLocation = matchingRequest[0].defaultLocation || ''
          const response = await onboarding('approve-request', paramsRequest)
          console.log('MemberRequestDialog.addInvite.approve-request', response, paramsRequest)
          if (response.value === 'approved') {
            if (!matchingRequest[0].completed) {
              this.updateRequest({ teamId: this.profile.team, requestId: matchingRequest[0].id, request: { completed: true, status: 'approved', statusDoneByProfileId: this.profile.id, statusDoneByProfileName: this.profile.displayName } })
              const self = this
              setTimeout(() => {
                self.sendingInvite = false
              }, 1000)
            }
            this.loadTeam({ teamId: this.profile.team })
            this.checkClose()
          }
        } else {
          // No request - send invite
          if (inWorkspace) {
            const response = await onboarding('invite-to-team', paramsAdd)
            console.log('MemberRequestDialog.addInvite.invite-to-team', response, params)
          } else {
            if (!guestsAllowed) {
              if (!this.inDomains(memberEmail)) {
                this.helpTextAdd = `Your workspace does not allow adding of guests. Please enabled this option or enter a valid email: ${memberEmail}`
                return
              }
            }

            const response = await onboarding('send-invites', params)
            console.log('MemberRequestDialog.addInvite.send-invites', response, params)
          }
          this.checkClose()
        }
      } catch (error) {
        console.error('addInvite.send-invites', error)
      }
      this.$nextTick(() => {
        this.sendingInvite = false
        const notification = this.notifications.filter(notification => notification.type === 'request' && notification.requestFrom === memberEmail)
        if (notification.length && notification[0]) {
          this.updateNotification({ notificationId: notification[0].id, update: { read: true } })
        }
      })
    },
    async denyRequest (memberEmail) {
      memberEmail = memberEmail.toLowerCase()
      const matchingRequest = this.requests.filter((req) => req.email.toLowerCase() === memberEmail)
      if (matchingRequest[0]) {
        if (!matchingRequest[0].completed) {
          await this.updateRequest({ teamId: this.profile.team, requestId: matchingRequest[0].id, request: { done: true, status: 'denied', statusDoneByProfileId: this.profile.id, statusDoneByProfileName: this.profile.displayName } })
          this.checkClose()
        }
      }
      const notification = this.notifications.filter(notification => notification.type === 'request' && notification.requestFrom === memberEmail)
      if (notification.length && notification[0]) {
        this.updateNotification({ notificationId: notification[0].id, update: { read: true } })
      }
    },
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target) || event.target.classList.contains('member-request-approve') || event.target.parentNode.classList.contains('member-request-approve'))) {
        this.closeDialog()
      }
    }
  },
  watch: {
    showDialog: {
      handler () {
        if (this.showDialog) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
            document.addEventListener('keyup', self.handleKeyDown)
          }, 300)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
            document.removeEventListener('keyup', self.handleKeyDown)
          }, 300)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.member-request-dialog {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  background: #FFFFFF;
  border: 0.5px solid #BDBDBD;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);
  border-radius: 10px;
  height: max-content;
  z-index: 35;
  > header {
    margin: 20px 35px;
    display: flex;
    align-items: center;
    .mdc-text {
      margin-left: 5px;
      flex: 1;
    }
    &.no-requests {
      display: flex;
      justify-content: center;
    }
    .close-modal {
      &::before,
        &::after {
          --mdc-ripple-fg-size: 0;
          width: auto;
          height: auto;
        }
    }
  }
  .member-requests-content {
    display: flex;
    flex-direction: column;
    margin: 0 35px 20px 35px;
    > header {
      margin-bottom: 10px;
      .mdc-text {
        color: #404040;
      }
    }
    .requests-list-wrapper {
      .request-details-actions {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .details {
          flex: 1;
          display: flex;
          align-items: center;
          *:not(:empty) {
            margin-right: 5px;
          }
          .mdc-text:not(.profile-initials) {
            color: #404040;
            font-weight: 600;
          }
        }
        .actions {
          display: flex;
          align-items: center;
          .mdc-button {
            margin-right: 5px;
            height: 25px;
            border-radius: 8px;
            font-size: 12px;
            text-transform: none;
            width: max-content;
            &.approve {
              background: #00498D;
            }
            &.deny {
              background: #FFFFFF;
              border: 0.5px solid #404040;
              box-sizing: border-box;
              color: #404040;
            }
            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
</style>
