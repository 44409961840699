const url = 'https://learn-ghost.duome.co/ghost/api/v2/content'
const creds = 'key=56ec02fca1990a9f473c31660f'

const doFetch = async (endpt, params = '') => {
  const result = await fetch(`${url}${endpt}?${creds}${params}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  const res = await result.json()
  return res
}

export const posts = (tag) => {
  if (tag) {
    return doFetch('/posts/', `&include=authors&filter=tags:[${tag}]`)
  }
  return doFetch('/posts/', '&include=authors')
}

export const post = (id) => {
  return doFetch(`/posts/${id}/`)
}

export const postBySlug = (slug) => {
  return doFetch(`/posts/slug/${slug}/`)
}

export const page = (id) => {
  return doFetch(`/pages/${id}/`)
}

export const pageBySlug = (slug) => {
  return doFetch(`/pages/slug/${slug}/`)
}
