<template>
  <div class="profile-schedule-tooltip-wrapper">
    <profile-initials :profileId="profileId" :date="appDate" showDisplayName noProfileDetailClick/>
    <mdc-text tag="span" class="tooltip-date" typo="body2">{{ getDate() }}</mdc-text>
    <div class="schedule-details" v-if="memberDaySchedule">
      <div class="full-day-response" v-if="(memberDaySchedule.type === 'full' && memberDaySchedule.response) || (!memberDaySchedule.type && memberDaySchedule.defaultResponseType === 'full' && memberDaySchedule.defaultResponse)">
        <div class="schedule-response">
          <base-svg-icon class="schedule-icon" :name="memberDaySchedule.response || memberDaySchedule.defaultResponse" />
          <mdc-text class="schedule-text" tag="span" typo="body1" :class="memberDaySchedule.response || memberDaySchedule.defaultResponse">{{ memberDaySchedule.response || memberDaySchedule.defaultResponse }}</mdc-text>
        </div>
        <mdc-text class="location-text" v-if="(memberDaySchedule.response === 'office' && memberDaySchedule.officeLocation)" tag="span" typo="caption" v-html="memberDaySchedule.officeLocation" />
        <mdc-text class="location-text" v-else-if="(!memberDaySchedule.type && memberDaySchedule.defaultResponse === 'office' && memberDaySchedule.defaultResponseLocation)" tag="span" typo="caption" v-html="memberDaySchedule.defaultResponseLocation" />
        <mdc-text class="location-text" v-else-if="memberDaySchedule.response === 'thirdspace' && memberDaySchedule.thirdspaceLocation" tag="span" typo="caption" v-html="memberDaySchedule.thirdspaceLocation" />
        <mdc-text class="location-text" v-else-if="(!memberDaySchedule.type && memberDaySchedule.defaultResponse === 'thirdspace' && memberDaySchedule.defaultResponseThirdspaceLocation)" tag="span" typo="caption" v-html="memberDaySchedule.defaultResponseThirdspaceLocation" />
        <mdc-text class="desk-text" v-if="(memberDaySchedule.response === 'office' && memberDaySchedule.officeLocation && memberDaySchedule.desk)" tag="span" typo="caption" v-html="memberDaySchedule.desk" />
        <mdc-text class="desk-text" v-else-if="!memberDaySchedule.type && memberDaySchedule.defaultResponse === 'office' && memberDaySchedule.defaultResponseLocation && memberDaySchedule.defaultResponseDesk" tag="span" typo="caption" v-html="memberDaySchedule.defaultResponseDesk" />
      </div>
      <div class="split-day-response" v-else-if="(memberDaySchedule.type === 'split' && memberDaySchedule.amResponse && memberDaySchedule.pmResponse) || (!memberDaySchedule.type && memberDaySchedule.defaultResponseType === 'split' && memberDaySchedule.defaultResponseAm && memberDaySchedule.defaultResponsePm)">
        <div class="slot-detail am">
          <div class="schedule-response am">
            <mdc-text class="schedule-slot" tag="span" typo="caption">AM</mdc-text>
            <base-svg-icon class="schedule-icon" :name="memberDaySchedule.amResponse || memberDaySchedule.defaultResponseAm" />
            <mdc-text class="schedule-text" tag="span" typo="body1" :class="memberDaySchedule.amResponse || memberDaySchedule.defaultResponseAm">{{ memberDaySchedule.amResponse || memberDaySchedule.defaultResponseAm }}</mdc-text>
          </div>
          <mdc-text class="location-text" v-if="memberDaySchedule.amResponse === 'office' && memberDaySchedule.splitOfficeLocation" tag="span" typo="caption" v-html="memberDaySchedule.splitOfficeLocation" />
          <mdc-text class="location-text" v-else-if="!memberDaySchedule.type && memberDaySchedule.defaultResponseAm === 'office' && memberDaySchedule.defaultResponseLocationSplit" tag="span" typo="caption" v-html="memberDaySchedule.defaultResponseLocationSplit" />
          <mdc-text class="location-text" v-else-if="memberDaySchedule.amResponse === 'thirdspace' && memberDaySchedule.splitThirdspaceLocation" tag="span" typo="caption" v-html="memberDaySchedule.splitThirdspaceLocation" />
          <mdc-text class="location-text" v-else-if="!memberDaySchedule.type && memberDaySchedule.defaultResponseAm === 'thirdspace' && memberDaySchedule.defaultResponseThirdspaceLocationSplit" tag="span" typo="caption" v-html="memberDaySchedule.defaultResponseThirdspaceLocationSplit" />
          <mdc-text class="desk-text" v-if="(memberDaySchedule.amResponse === 'office' && memberDaySchedule.splitOfficeLocation && memberDaySchedule.desk)" tag="span" typo="caption" v-html="memberDaySchedule.desk" />
          <mdc-text class="desk-text" v-else-if="!memberDaySchedule.type && memberDaySchedule.defaultResponseAm === 'office' && memberDaySchedule.defaultResponseLocationSplit && memberDaySchedule.defaultResponseDesk" tag="span" typo="caption" v-html="memberDaySchedule.defaultResponseDesk" />

        </div>
        <div class="slot-detail pm">
          <div class="schedule-response pm">
            <mdc-text class="schedule-slot" tag="span" typo="caption">PM</mdc-text>
            <base-svg-icon class="schedule-icon" :name="memberDaySchedule.pmResponse || memberDaySchedule.defaultResponsePm" />
            <mdc-text class="schedule-text" tag="span" typo="body1" :class="memberDaySchedule.pmResponse || memberDaySchedule.defaultResponsePm">{{ memberDaySchedule.pmResponse || memberDaySchedule.defaultResponsePm }}</mdc-text>
          </div>
          <mdc-text class="location-text" v-if="memberDaySchedule.pmResponse === 'office' && memberDaySchedule.splitOfficeLocation" tag="span" typo="caption" v-html="memberDaySchedule.splitOfficeLocation" />
          <mdc-text class="location-text" v-else-if="!memberDaySchedule.type && memberDaySchedule.defaultResponsePm === 'office' && memberDaySchedule.defaultResponseLocationSplit" tag="span" typo="caption" v-html="memberDaySchedule.defaultResponseLocationSplit" />
          <mdc-text class="location-text" v-else-if="memberDaySchedule.pmResponse === 'thirdspace' && memberDaySchedule.splitThirdspaceLocation" tag="span" typo="caption" v-html="memberDaySchedule.splitThirdspaceLocation" />
          <mdc-text class="location-text" v-else-if="!memberDaySchedule.type && memberDaySchedule.defaultResponsePm === 'thirdspace' && memberDaySchedule.defaultResponseThirdspaceLocationSplit" tag="span" typo="caption" v-html="memberDaySchedule.defaultResponseThirdspaceLocationSplit" />
          <mdc-text class="desk-text" v-if="(memberDaySchedule.pmResponse === 'office' && memberDaySchedule.splitOfficeLocation && memberDaySchedule.desk)" tag="span" typo="caption" v-html="memberDaySchedule.desk" />
          <mdc-text class="desk-text" v-else-if="!memberDaySchedule.type && memberDaySchedule.defaultResponsePm === 'office' && memberDaySchedule.defaultResponseLocationSplit && memberDaySchedule.defaultResponseDesk" tag="span" typo="caption" v-html="memberDaySchedule.defaultResponseDesk" />

        </div>
      </div>
    </div>
    <mdc-text tag="span" v-else typo="caption not-set">Schedule not set for the day</mdc-text>
    <section class="tooltip-footer" v-if="showFooter">
      <mdc-text tag="span" typo="caption" v-if="memberDaySchedule.submittedOn" class="edited-date">{{ displayHistoryDetails() ? 'Edited' : 'Submitted' }} {{ formatTooltipSubmittedDate(memberDaySchedule.submittedOn) }}</mdc-text>
      <div class="changed-responses" v-if="displayHistoryDetails()">
        <div class="history-response-wrapper" v-if="memberDaySchedule.historyResponseType">
          <span v-if="memberDaySchedule.historyResponseType && memberDaySchedule.historyResponseType === 'full' && memberDaySchedule.historyResponse" class="answer full-day">
            <base-svg-icon :name="memberDaySchedule.historyResponse" :class="[getWorkingAtClass(memberDaySchedule.historyResponse)]" />
          </span>
          <span v-if="memberDaySchedule.historyResponseType === 'split' && memberDaySchedule.historyResponseAm && memberDaySchedule.historyResponsePm" class="answer split-day">
            <div class="split-day-grid">
              <div v-if="memberDaySchedule.historyResponseAm" class="am-wrapper slot-type" :class="getBoxFillClass(memberDaySchedule, false, memberDaySchedule.historyResponseAm)">
                <base-svg-icon :name="memberDaySchedule.historyResponseAm" />
              </div>
              <div v-if="memberDaySchedule.historyResponsePm" class="pm-wrapper slot-type" :class="getBoxFillClass(memberDaySchedule, false, memberDaySchedule.historyResponsePm)">
                <base-svg-icon :name="memberDaySchedule.historyResponsePm" />
              </div>
            </div>
          </span>
        </div>
        <base-svg-icon name="greyarrowright" class="arrow-mark" />
        <div class="current-response-wrapper" v-if="memberDaySchedule.type">
          <span v-if="memberDaySchedule.type && memberDaySchedule.type === 'full' && memberDaySchedule.response" class="answer full-day">
            <base-svg-icon :name="memberDaySchedule.response" :class="[getWorkingAtClass(memberDaySchedule.response)]" />
          </span>
          <span v-if="memberDaySchedule.type === 'split' && memberDaySchedule.amResponse && memberDaySchedule.pmResponse" class="answer split-day">
            <div class="split-day-grid">
              <div v-if="memberDaySchedule.amResponse" class="am-wrapper slot-type" :class="getBoxFillClass(memberDaySchedule, false, memberDaySchedule.amResponse)">
                <base-svg-icon :name="memberDaySchedule.amResponse" />
              </div>
              <div v-if="memberDaySchedule.pmResponse" class="pm-wrapper slot-type" :class="getBoxFillClass(memberDaySchedule, false, memberDaySchedule.pmResponse)">
                <base-svg-icon :name="memberDaySchedule.pmResponse" />
              </div>
            </div>
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import ProfileInitials from './ProfileInitials.vue'
import { dayDateFormat } from '../../utils/time'
import moment from 'moment'
export default {
  name: 'ProfileScheduleTooltip',
  components: {
    BaseSvgIcon,
    ProfileInitials
  },
  props: {
    memberDaySchedule: {
      default: () => {
        return {}
      }
    },
    profileId: {
      type: String,
      default: ''
    },
    tooltipDate: {
    },
    showFooter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  beforeDestroy () {},
  created () {},
  computed: {
    ...mapState([
      'appDate',
      'scheduleSearchWeekDetail'
    ]),
    ...mapState({
    })
  },
  mounted () {},
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    }),
    getDate () {
      return dayDateFormat(this.tooltipDate, 'ddd D MMMM')
    },
    formatTooltipSubmittedDate (date) {
      if (date && date.seconds) {
        return moment.unix(date.seconds).format('D/MM/yy')
      }
      return ''
    },
    displayHistoryDetails () {
      const response = this.memberDaySchedule
      if (response.historyResponseType) {
        if (response.type !== response.historyResponseType) {
          return true
        }
        if (response.type === 'full' && response.response !== response.historyResponse) {
          return true
        }
        if (response.type === 'split' && (response.amResponse !== response.historyResponseAm || response.pmResponse !== response.historyResponsePm)) {
          return true
        }
      }
    },
    getWorkingAtClass (response) {
      if (response && response !== null && response !== '' && response !== undefined) {
        switch (response.toLowerCase()) {
          case 'home':
            return 'home'
          case 'office':
            return 'office'
          case 'nonworking':
            return 'nonworking'
          case 'thirdspace':
            return 'thirdspace'
          default:
            return ''
        }
      }
    },
    getBoxFillClass (responseParam, forWrapper, responseSplit) {
      // console.log('schedulesearch.getBoxFillClass', responseParam, forWrapper, responseSplit)
      let response = ''
      if (forWrapper && Object.keys(responseParam).length > 0) {
        if (!responseParam.type && responseParam.defaultResponseType) {
          if (responseParam.defaultResponseType === 'split') {
            response = 'split'
          } else if (responseParam.defaultResponseType === 'full') {
            response = responseParam.response
          } else {
            response = 'notset'
          }
        }
        if (responseParam.type) {
          if (responseParam.type === 'split') {
            response = 'split'
          } else if (responseParam.type === 'full' && responseParam.response) {
            response = responseParam.response
          } else {
            response = 'notset'
          }
        }
      } else if (responseSplit) {
        response = responseSplit
      } else {
        response = responseParam
      }
      if (responseParam.default) {
        return 'notset-fill'
      }
      if (responseParam.addedByConfirmation) {
        return 'notset-fill'
      }
      if (response && response !== null && response !== '' && response !== undefined) {
        switch (response.toLowerCase()) {
          case 'home':
            return 'home-fill'
          case 'office':
            return 'office-fill'
          case 'nonworking':
            return 'nonworking-fill'
          case 'notset':
            return 'notset-fill'
          case 'thirdspace':
            return 'thirdspace-fill'
          case 'split':
            return 'split-fill'
          default:
            return ''
        }
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.profile-schedule-tooltip-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 220px;
  .profile-redirect {
    margin: 15px 20px;
    .profile-display-name {
      font-weight: 700;
      font-size: 14px;
      color: #4f4f4f;
    }
  }
  .not-set {
    margin:5px;
    margin-left:10px;
  }
  .tooltip-date {
    border-top: 0.2px solid #BDBDBD;
    border-bottom: 0.2px solid #BDBDBD;
    padding: 8px 20px;
    font-weight: 600;
    color: #404040;
    width: -webkit-fill-available;
    white-space: nowrap;
  }
  .schedule-details {
    .full-day-response {
      margin: 10px 20px;
      .schedule-response {
        display: flex;
        align-items: center;
        .schedule-icon {
          margin-right: 5px;
        }
        .schedule-text {
          font-weight: 600;
          color: #404040;
          text-transform: capitalize;
        }
      }
      .location-text {
        color: #4175B1;
        font-size: 10px;
        line-height: 12px;
      }
      .desk-text {
        color: #4175B1;
        font-size: 10px;
        line-height: 12px;
      }
    }
    .split-day-response {
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      .slot-detail {
        display: flex;
        flex-direction: column;
        .schedule-response {
          display: flex;
          align-items: center;
          .schedule-slot {
            width: 19px;
            height: 11px;
            background: #f3f3f3;
            color: #ACacac;
            font-weight: 800;
            font-size: 8px;
            margin-right: 5px;
            text-align: center;
            line-height: 11px;
          }
          .schedule-icon {
            margin-right: 5px;
          }
          .schedule-text {
            font-weight: 600;
            color: #404040;
            text-transform: capitalize;
          }
        }
        .location-text {
          color: #4175B1;
          font-size: 10px;
        }
        .desk-text {
          color: #4175B1;
          font-size: 10px;
          line-height: 12px;
        }
        &:nth-of-type(2) {
          margin-top: 20px;
        }
      }
    }
  }
  .tooltip-footer:empty {
    display: none;
  }
  .tooltip-footer:not(:empty) {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: -webkit-fill-available;
    border-top: 0.2px solid #BDBDBD;

    .edited-date {
      color: #ACACAC;
      font-weight: 400;
      margin-right: 10px;
      white-space: nowrap;
    }
    .changed-responses {
      display: flex;
      align-items: center;
      .arrow-mark {
        margin: 0 5px;
        &.icon-wrapper {
          height: 20px;
          width: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .mdc-text {
        margin-left: 5px;
      }
      .history-response-wrapper {
        .answer {
          &.full-day {
            display: flex;
            align-items: baseline;
            .icon-wrapper {
              border-radius: 4px;
              img {
                width: 10px;
                height: 10px;
              }
            }
          }
          &.split-day {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            .split-day-grid {
              display: grid;
              grid-template-columns: 20px 20px;
              height: 20px;
              align-items: center;
              grid-gap: 0.5px;
              .am-wrapper {
                border-radius: 4px 0 0 4px;
              }
              .pm-wrapper {
                border-radius: 0 4px 4px 0;
              }
              .slot-text {
                font-size: 8px;
              }
              .slot-type {
                .icon-wrapper {
                  width: 20px;
                  height: 20px;
                  img {
                    width: 10px;
                    height: 10px;
                  }
                }
                &.thirdspace-fill {
                  background-color: var(--thirdspace-fill);
                }
                &.home-fill {
                  background-color: var(--home-fill);
                }
                &.office-fill {
                  background-color: var(--office-fill);
                }
                &.nonworking-fill {
                  background: var(--nonworking-fill);
                }
                &.notset-fill {
                  background-color: var(--notset-fill);
                }
                &.default {
                  background-color: var(--notset-fill);
                }
              }
            }
          }
        }
      }
      .current-response-wrapper {
        .answer {
          &.full-day {
            display: flex;
            align-items: baseline;
            .icon-wrapper {
              width: 15px;
              height: 15px;
              border-radius: 4px;
              img {
                width: 10px;
                height: 10px;
              }
              &.thirdspace-fill {
                background-color: var(--thirdspace-fill);
              }
              &.home-fill {
                background-color: var(--home-fill);
              }
              &.office-fill {
                background-color: var(--office-fill);
              }
              &.nonworking-fill {
                background: var(--nonworking-fill);
              }
              &.notset-fill {
                background-color: var(--notset-fill);
              }
            }
          }
          &.split-day {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            .split-day-grid {
              display: grid;
              grid-template-columns: 20px 20px;
              height: 20px;
              align-items: center;
              grid-gap: 0.5px;
              .am-wrapper {
                border-radius: 4px 0 0 4px;
              }
              .pm-wrapper {
                border-radius: 0 4px 4px 0;
              }
              .slot-text {
                font-size: 8px;
              }
              .slot-type {
                .icon-wrapper {
                  width: 20px;
                  height: 20px;
                  img {
                    width: 10px;
                    height: 10px;
                  }
                }
                &.thirdspace-fill {
                  background-color: var(--thirdspace-fill);
                }
                &.home-fill {
                  background-color: var(--home-fill);
                }
                &.office-fill {
                  background-color: var(--office-fill);
                }
                &.nonworking-fill {
                  background: var(--nonworking-fill);
                }
                &.notset-fill {
                  background-color: var(--notset-fill);
                }
                &.default {
                  background-color: var(--notset-fill);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
