<template>
  <div class="taskgroup"
    >
    <div v-if="isDropTarget() && dropFunction"
      ref="dropzone"
      class="dropzone" style="pointer-events:none;background-color:#6184AD;opacity:0.1;position:absolute;height:100%;width:100%;top:0;left:0;"></div>

    <div class="taskgroup-tasks"
      ref="taskwrapper"
      v-if="!hideGroup"
      @dragenter.prevent
      @dragover.prevent="timeoutDropTarget(true, $event)"
      @drop.prevent="listDrop"
    >
      <task
        v-if="!disableAdd"
        :editTask="editTask"
        :showTaskTabs="showTaskTabs"
        :taskListId="taskListId"
        :fromInitiative="fromInitiative"
        :location="location"
        :taskPlan="{ date: assignDate }"
        @editing="editing = $event"
        @saveSelected="saveSelectedTask"
        @editChange="setTaskToEditDuringComment"
        :task="newTask" :key="'task-' + newTask.id " />
      <template v-if="!onlyNew">
        <draggable
          :id=taskListId ? taskListId[0]: false
          class="tasks-wrapper"
          :sort="!dragClone"
          :disabled="!!editing"
          :dragoverBubble="true"
          @dragstart.native="setDragData"
          @start="startDrag"
          @end="endDrag"
          @add="onDragAdd"
          @remove="onDragRemove"
          @update="onDragUpdate"
          :delay="200"
          :delay-on-touch-only="true"
          :group="{ name: 'tasks', pull: !dragClone, put: !dragClone }"
          dragClass="dragging"
          ghostClass="ghost"
          :draggable="'.task-line-draggable'"
          :handle="'.task-check'">
            <template v-for="(task, index) in filteredTasks">
              <task
                :draggable="!editing"
                :editTask="editTask"
                :showTaskTabs="showTaskTabs"
                :taskListId="taskListId"
                :fromInitiative="fromInitiative"
                :location="location"
                :taskPlan="{ date: assignDate }"
                @editing="editing = $event"
                @saveSelected="saveSelectedTask"
                @taskCompleted="completeTask(task)"
                @taskUncompleted="uncompleteTask(task)"
                @editChange="setTaskToEditDuringComment"
                :task="task" :key="'task-' + task.id + '-' + index" />
            </template>
        </draggable>
        <template v-if="limitTasksDisplay">
          <div class="toggle-tasks" :class="{ 'more-clicked' : showMore }" v-if="tasks.length > 0 && tasks.length >= tasksDisplay" @click="showMoreClicked()">
            <base-svg-icon name="arrow-down-wide" v-if="tasks.length > tasksDisplay" />
            <base-svg-icon name="arrow-up-wide" v-else-if="showMore" />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'
import Task from '../../components/flexibly/Task.vue'
import { inPast } from '../../utils/time'
import BaseSvgIcon from './BaseSvgIcon.vue'

export default {
  name: 'TaskGroup',
  components: {
    Task,
    draggable: () => import('vuedraggable'),
    BaseSvgIcon
  },
  mounted () {
  },
  props: {
    onlyNew: {
      type: Boolean,
      default: false
    },
    limitTasksDisplay: {
      type: Boolean,
      default: false
    },
    location: {},
    tasks: {},
    composerView: {
      type: String,
      default: 'default'
    },
    date: {},
    selfAssign: {
      type: Boolean,
      default: false
    },
    disableAdd: {
      type: Boolean,
      default: false
    },
    assignDate: {},
    fromInitiative: {
      type: Array,
      default: function () {
        return []
      }
    },
    taskListId: {
      type: Array,
      default: function () {
        return []
      }
    },
    showTaskTabs: {
      type: Boolean,
      default: false
    },
    dragClone: {
      default: false
    },
    dropFunction: {
    },
    ordered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      team: state => state.teams.team,
      teamProfiles: state => state.teams.teamProfiles,
      profile: state => state.profile
    }),
    ...mapGetters({
      getTaskById: 'teams/getTaskById'
    }),
    newTask () {
      const newTask = {
        id: '',
        complete: false,
        completed: false,
        text: '<p></p>',
        due: null,
        shareUsers: [],
        mentions: [],
        order: this.tasks.length,
        shareInitiatives: [],
        userId: this.profile.id,
        createdOn: new Date(),
        isPlanPresent: {}
      }
      if (this.selfAssign) {
        newTask.selfAssignedTo = this.profile.id
      }
      // console.log(newTask)
      return newTask
    },
    filteredTasks () {
      // console.log('filteredTasks', this.ordered, this.tasks)
      if (!this.ordered) {
        const allTasks = [...this.tasks]
        // const newTask = { ...this.newTask }
        if (this.assignDate) {
          if (inPast(this.assignDate)) {
            return allTasks.sort((a, b) => a.completed - b.completed)
          }
        }
        allTasks.sort((a, b) => a.completed - b.completed)
        if (this.limitTasksDisplay) {
          const slicedTasks = allTasks.slice(0, this.tasksDisplay)
          return slicedTasks
        }
        // allTasks.push(newTask)
        return allTasks
      }
      return this.tasks
    }
  },
  data () {
    return {
      dragging: false,
      editing: false,
      editTask: null,
      showMore: false,
      tasksDisplay: 5,
      dropTarget: false,
      timeout: false,
      hideGroup: false
    }
  },
  watch: {
    assignDate: {
      handler () {
        this.resetShowMore()
      }
    }
  },
  methods: {
    ...mapActions({
      saveTask: 'teams/saveTask',
      saveTasksBatch: 'teams/saveTasksBatch',
      saveTaskPlan: 'teams/saveTaskPlan',
      getTaskPlan: 'teams/getTaskPlan',
      saveTaskActivity: 'teams/saveTaskActivity'
    }),
    timeoutDropTarget (val, event) {
      if (this.dropFunction) {
        if (this.dropTarget !== val) {
          this.dropTarget = val
        }
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.dropTarget = false
        }, 500)
      }
    },
    setDropTarget (val, event) {
      if (val) {
        this.dropTarget = val
      } else {
        const from = event.fromElement
        if (from.classList.contains('taskgroup-tasks') || from.classList.contains('tasks-wrapper')) {
          // console.log('setDropTarget', event, from)
          this.dropTarget = val
        }
      }
    },
    isDropTarget (el) {
      return this.dropTarget
    },
    async listDrop (event) {
      this.dropTarget = false
      const taskId = event.dataTransfer.getData('taskId')
      if (this.dropFunction) {
        if (this.dropFunction === 'updateWhen') {
          const task = await this.getTaskById(taskId)
          const existingTaskPlan = await this.getTaskPlan({ teamId: this.profile.team, userId: this.profile.id, taskId })
          let planType = ''
          let planDate = false
          if (this.assignDate === 'next at office') {
            planType = 'office'
          } else if (this.assignDate === 'next at home') {
            planType = 'remote'
          } else {
            planDate = this.assignDate ? this.assignDate.toDate() : false
            if (existingTaskPlan[0] && existingTaskPlan[0].planDate && planDate) {
              if (moment(existingTaskPlan[0].planDate.toDate()).isSame(planDate, 'date')) {
                return
              }
            }
          }
          const tp = {
            teamId: this.profile.team,
            userId: this.profile.id,
            id: existingTaskPlan[0] ? existingTaskPlan[0].id || false : false,
            taskId: taskId || 'no task id',
            planDate: planDate || '',
            type: planType
          }
          this.saveTaskPlan(tp).then(() => {
            if (planDate) {
              task.isPlanPresent[this.profile.id] = true
            } else {
              task.isPlanPresent[this.profile.id] = false
            }
            const taskUpdates = { isPlanPresent: task.isPlanPresent }
            if (!(task.selfAssignedTo === this.profile.id) && (!task.shareUsers.includes(this.profile.id))) {
              task.shareUsers.push(this.profile.id)
              taskUpdates.shareUsers = task.shareUsers
            }
            this.saveTask({ teamId: this.profile.team, taskId, task: taskUpdates })
          })
        }
      }
    },
    showMoreClicked () {
      if (this.tasks && (this.tasks.length > this.tasksDisplay)) {
        this.showMore = true
        this.tasksDisplay = this.tasks.length
      } else {
        this.showMore = false
        this.tasksDisplay = 5
      }
    },
    saveSelectedTask (task) {
    },
    completeTask (task) {
      task.completed = true
      task.completedOn = new Date()
      task.completedBy = this.profile.id
      this.editTask = false
    },
    uncompleteTask (task) {
      task.completed = false
      task.completedOn = null
      task.completedBy = ''
    },
    setTaskToEditDuringComment (task) {
      if (task) {
        this.editTask = task
      }
    },
    getInCompleteTasks () {
      return this.tasks.filter(task => !task.completed)
    },
    reorderList (tasklist) {
      const orderedList = [...tasklist]
      for (let index = 0; index < orderedList.length; index++) {
        const order = 0 + index
        orderedList[index].order = order
      }
      return orderedList
    },
    startDrag (event) {
      // console.log('startDrag')
      this.dragging = true
    },
    setDragData (event) {
      // console.log('setDragData', event)
      const taskId = event.target.id
      event.dataTransfer.setData('taskId', taskId)
      // console.log('setDragData', taskId, event, event.dataTransfer.getData('taskId'))
    },
    endDrag (event) {
      // console.log('endDrag', event)
      this.dragging = false
    },
    onDragUpdate (event) {
      // console.log('onDragUpdate', event)
      if (!this.dragClone) {
        const item = event.item.id.replace('task-', '')
        const newIndex = event.newIndex
        // const to = event.to.id.replace('tasklist-', '')
        const task = this.tasks.filter(task => task.id === item)[0]
        // console.log('onDragUpdate.item', item, task)
        let taskInList = this.tasks.filter(task => {
          // console.log('onDragUpdate', task, task.taskListIds, to)
          return task.id !== item
        }).sort((a, b) => a.order - b.order)
        if (task) {
          // Reorder Task List
          taskInList.splice(newIndex, 0, task)
          taskInList = this.reorderList(taskInList)
          this.saveTasksBatch({ tasks: taskInList })
        }
      }
      return false
    },
    async onDragAdd (event) {
      // console.log('onDragAdd', event)
      if (!this.dragClone) {
        const item = event.item.id.replace('task-', '')
        const newIndex = event.newIndex
        const from = event.from.id.replace('tasklist-', '')
        const to = event.to.id.replace('tasklist-', '')
        const task = await this.getTaskById(item)
        let taskInList = [...this.tasks]
        // console.log('onDragAdd', item, task)
        if (task) {
          // Swap Task List
          const taskListIds = task.taskListIds.filter(tl => tl !== from)
          if (!taskListIds.includes(to)) {
            taskListIds.push(to)
            task.taskListIds = taskListIds
          }
          // Reorder Task List
          taskInList.splice(newIndex, 0, task)
          taskInList = this.reorderList(taskInList)
          this.saveTasksBatch({ tasks: taskInList })

          // TODO: Find out why vue-draggable isnt updating with task change - this isnt a good solution
          this.hideGroup = true
          this.$nextTick(() => {
            this.hideGroup = false
          })
        }
      }
      return false
    },
    onDragRemove (event) {
      // console.log('onDragRemove', this.tasks)
      // const item = event.item.id.replace('task-', '')
      // const oldIndex = event.oldIndex
      // const newIndex = event.newIndex
      // const from = event.from.id.replace('tasklist-', '')
      // const to = event.to.id.replace('tasklist-', '')

      // TODO: Find out why vue-draggable isnt updating with task change - this isnt a good solution
      this.hideGroup = true
      this.$nextTick(() => {
        this.hideGroup = false
      })
    },
    resetShowMore () {
      this.tasksDisplay = 5
      this.showMore = false
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes wipe-in-down {
  from {
  clip-path: inset(0 0 100% 0);
  }
  to {
  clip-path: inset(0 0 0 0);
  }
}
@keyframes wipe-in-up {
  from {
  clip-path: inset(100% 0 0 0);
  }
  to {
  clip-path: inset(0 0 0 0);
  }
}
.taskgroup {
  position: relative;
}
.taskgroup.highlight .tasks-wrapper {
  box-shadow: 0 0 5px #6184AD;
}
.planned-task-group {
  .tasks-wrapper {
    margin-top: 0;
  }
}
.taskgroup-tasks {
   &.wipe-down {
    animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-down both;
  }
  &.wipe-up {
    animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-up both;
  }
}
.tasks-wrapper:not(:empty) {
  margin: 3px 0 10px 0;
}
.toggle-tasks:not(:empty) {
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-top: -10px;
  &:not(.more-clicked) {
    &:before {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 42.71%, #FFFFFF 100%);
      content: '';
      height: 60px;
      position: absolute;
      width: 100%;
      bottom: 18px;
    }
  }
}
</style>
