<template>
  <div role="dialog" class="meeting-dialog" ref="meeting-dialog" tabindex="0" :style="{ 'max-height': composerHeight }" :class="displayMeeting.showFor">
    <base-svg-icon class="close-icon" name="cross-thin" clickable @click.native="closeDialog()" />
    <header>
      <div class="heading">
        <span class="calendar-color" :style="{ 'background-color': calColor }"></span>
        <div class="subject-link">
          <mdc-text tag="span" class="meeting-subject-text clickable" @click.native="redirectToCalendar()" typo="headline6">
            {{displayMeeting.subject}}
          </mdc-text>
        </div>
      </div>
      <div class="meeting-info" v-if="displayMeeting.isAllDay">
        <mdc-text tag="span" typo="body2" class="meeting-date">{{ getDateFormat(displayMeeting.startDateTime) }}</mdc-text>
        <span class="separator">•</span>
        <mdc-text tag="span" typo="body2" class="meeting-date">ALL DAY</mdc-text>
      </div>
      <div class="meeting-info" v-else>
        <mdc-text tag="span" typo="body2" class="meeting-date">{{ getDateFormat(displayMeeting.startDateTime) }}</mdc-text>
        <span class="separator">•</span>
        <mdc-text tag="span" typo="body2" class="meeting-time">{{ getTimeFormat(displayMeeting.startDateTime) }} - {{ getTimeFormat(displayMeeting.endDateTime) }}</mdc-text>
      </div>
      <div class="frequency" v-if="repeatFrequency">
        <mdc-text tag="span" class="detail-type freq" typo="caption">{{repeatFrequency}}</mdc-text>
      </div>
    </header>
    <section class="meeting-dialogs-content" >
      <div class="meeting-details">
        <div class="detail-row url" v-if="displayMeeting.joinUrl">
          <base-svg-icon class="type-icon" name="camera-gray" />
          <mdc-text @focus="$event.target.select()" :ref="'joinUrl'" tag="span" class="detail-type joinurl clickable" typo="body2" :title="displayMeeting.joinUrl" @click.native="redirectToCalendar(displayMeeting.joinUrl)">{{displayMeeting.joinUrl}}</mdc-text>
          <base-svg-icon class="copy-icon" name="copy" clickable @click.native="copyUrl(displayMeeting.joinUrl)"/>
        </div>
        <div class="detail-row location">
          <base-svg-icon class="type-icon" name="location-pin-gray" />
          <mdc-text tag="span" class="detail-type location" :class="displayMeeting.location ? 'set' : 'notset'" typo="body2">{{displayMeeting.location ? displayMeeting.location : 'Not set'}}</mdc-text>
        </div>
        <div class="detail-row notiftext">
          <base-svg-icon class="type-icon" name="bell-gray" />
          <mdc-text tag="span" class="detail-type notiftext" typo="body2">{{notificationText}}</mdc-text>
        </div>
        <div class="detail-row showas" v-if="showAs">
          <base-svg-icon class="type-icon" name="eye-c1c7ce" />
          <mdc-text tag="span" class="detail-type showas" typo="body2">{{showAs}}</mdc-text>
        </div>
      </div>
      <div class="attendees-details">
        <div class="detail-row">
          <base-svg-icon class="type-icon" name="people-c1c7ce" />
          <mdc-text tag="span" class="detail-type attendeesinfo" typo="body2">
            {{attendeesCount}}
            <span class="breakdown">{{attendeesBreakdown}}</span>
          </mdc-text>
        </div>
      </div>
      <div class="attendees-schedules-wrapper" :style="{ 'min-height': calcAttendeeHeight }">
        <div class="attendee" :key="displayMeeting.id + '-' + attendee.response + '-' + attendee.id" v-for="attendee in allTypeAttendees">
          <base-svg-icon :name="getResponseClass(attendee.response)" v-if="getResponseClass(attendee.response)" class="attendee-resp" />
          <profile-initials :profileId="attendee.id" :date="appDate" showDisplayName :displayName="attendee.displayName || attendee.email" :showProfileDetails="attendee.id !== '-1'"/>
          <base-svg-icon :name="attendee.schedule" />
          <mdc-text class="org-profile" v-if="attendee.organizer" tag="span" typo="caption">Organizer</mdc-text>
        </div>
      </div>
      <div class="org-details" v-if="calendarName || calendarEmail">
        <div class="detail-row">
          <base-svg-icon class="type-icon" name="calendar-c1c7ce" />
          <mdc-text tag="span" class="detail-type orgname" typo="body2">{{ calendarName || calendarEmail }}</mdc-text>
          <mdc-text tag="span" class="detail-type orgemail" typo="caption">{{ calendarEmail }}</mdc-text>
        </div>
      </div>
    </section>
    <footer>
      <div :class="attendingOptionsFor" class="attending-options" role="menu" name="attending-options-menu" v-if="attendingOptionsFor">
        <template v-if="displayMeeting.meetingSource === 'Google'">
          <mdc-text @click.native="optionTypeClick(option.type)" tag="span" typo="body2" class="option-type clickable" v-for="option in googleOptions" :key="'google' + option.type">{{ option.text }}</mdc-text>
        </template>
        <template v-else>
          <mdc-text @click.native="optionTypeClick(option.type)" tag="span" typo="body2" class="option-type clickable" v-for="option in msOptions" :key="'ms' + option.type">{{ option.text }}</mdc-text>
        </template>
      </div>
      <div class="request-details-actions">
        <div class="actions">
          <mdc-text tag="span" class="attending-label" typo="body2">
            <span>Attending</span>
            <span v-show="sendingResponse" class="loader"></span>
          </mdc-text>
          <mdc-button v-if="showActions" aria-label="attending" class="attending att-type" @click.native="handleAttendingResponse('yes')" :class="{ 'active': (myAttendence === 'accepted' || myAttendence === 'organizer'), 'disabled': sendingResponse}">
            Yes
            <base-svg-icon clickable class="down-arrow" v-if="repeatFrequency" :name="handleArrowsForAttendingResponse('yes')" />
          </mdc-button>
          <mdc-button v-else aria-label="attending" class="attending att-type active disabled">
            Yes
          </mdc-button>
          <mdc-button v-if="showActions" aria-label="not-attending" class="not-attending att-type" @click.native="handleAttendingResponse('no')" :class="{ 'active': myAttendence === 'declined', 'disabled': sendingResponse}">
            No
            <base-svg-icon clickable class="down-arrow" v-if="repeatFrequency" :name="handleArrowsForAttendingResponse('no')" />
          </mdc-button>
          <mdc-button v-if="showActions" aria-label="maybe-attending" class="maybe-attending att-type" @click.native="handleAttendingResponse('maybe')" :class="{ 'active': myAttendence.indexOf('tentative') >= 0, 'disabled': sendingResponse}">
            Maybe
            <base-svg-icon clickable class="down-arrow" v-if="repeatFrequency" :name="handleArrowsForAttendingResponse('maybe')" />
          </mdc-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import moment from 'moment'
import BaseSvgIcon from './BaseSvgIcon.vue'
import ProfileInitials from '../../components/flexibly/ProfileInitials'
import { calendar } from '../../utils/backend'
import { getRecurringPatternText } from '../../utils/meetings'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'MeetingDialog',
  components: {
    BaseSvgIcon,
    ProfileInitials
  },
  data () {
    return {
      allTypeAttendees: [],
      attendeesBreakdown: '',
      composerHeight: null,
      composerWidth: null,
      accepted: [],
      declined: [],
      unknown: [],
      mayBe: [],
      sendingResponse: false,
      calColor: '#062FB1',
      attendingOptionsFor: '',
      googleOptions: [
        {
          type: 'this',
          text: 'This event'
        },
        {
          type: 'all',
          text: 'All events'
        }
      ],
      msOptions: [
        {
          type: 'this',
          text: 'This event'
        },
        {
          type: 'all',
          text: 'All events in the series'
        }
      ]
    }
  },
  props: {
    showDialog: {
    }
  },
  beforeDestroy () {
    // console.log('MeetingDialog.beforeDestroy')
    this.setDisplayMeeting(false)
    document.removeEventListener('keyup', this.handleKeyDown)
    window.removeEventListener('resize', this.checkComposerDim)
    document.removeEventListener('click', this.handleClickOutside)
  },
  created () {},
  mounted () {
    window.addEventListener('resize', this.checkComposerDim)
    this.setCalColor()
    this.setAttendees()
    this.checkComposerDim()
  },
  computed: {
    ...mapState([
      'appDate',
      'user',
      'displayMeeting',
      'workspace',
      'displayProfile'
    ]),
    ...mapState({
      meetingsRec: state => state.teams.meetingsRec,
      profiles: state => state.profiles,
      profile: state => state.profile,
      schedules: state => state.teams.schedules
    }),
    ...mapGetters([
      'getCalendarColor'
    ]),
    showActions () {
      if (this.displayMeeting) {
        // Cannot respond to own meetings for MS
        if (this.displayMeeting.meetingSource !== 'Google' && this.displayMeeting.organizer?.address === this.calendarEmail) {
          return false
        }
        return true
      }
      return false
    },
    calcAttendeeHeight () {
      let attCount = 1
      if (this.displayMeeting) {
        const allAttendees = this.getMeetingAttendeesEmail()
        attCount = allAttendees.length
      }
      const height = (attCount * 30) + 20
      // console.log('calcAttendeeHeight', this.attendeesCount, height)
      return `${height}px`
    },
    calendarName () {
      if (this.profile) {
        const calendar = this.displayMeeting?.meetingSource === 'Google' ? this.profile.googleCalendarsSelected : this.profile.calendarsSelected
        if (calendar) {
          const calendarDetails = calendar.find(cal => cal.calendarId === this.displayMeeting.calendarId)
          return calendarDetails?.name
        }
        return ''
      }
      return ''
    },
    calendarEmail () {
      if (this.profile && this.displayMeeting) {
        return this.displayMeeting?.meetingSource === 'Google' ? this.profile.googleEmail : this.profile.msEmail
      }
      return ''
    },
    organizerDetails () {
      if (this.displayMeeting) {
        if (this.displayMeeting.meetingSource === 'Google') {
          if (this.displayMeeting.organizer) {
            return {
              name: this.displayMeeting.organizer.displayName,
              email: this.displayMeeting.organizer.email
            }
          } else {
            return { name: '', email: '' }
          }
        } else {
          if (this.displayMeeting.organizer) {
            return {
              name: this.displayMeeting.organizer.name,
              email: this.displayMeeting.organizer.address
            }
          } else {
            return { name: '', email: '' }
          }
        }
      }
      return false
    },
    myAttendence () {
      if (this.displayMeeting) {
        const rspMap = this.getAttendeeResponseMap()
        return rspMap[this.myMeetingEmail] || ''
      }
      return ''
    },
    myMeetingEmail () {
      if (this.displayMeeting) {
        const emails = this.getMeetingAttendeesEmail()
        for (const email of emails) {
          if (email?.toLowerCase() === this.profile.email?.toLowerCase() || email?.toLowerCase() === this.profile.googleEmail?.toLowerCase() || email?.toLowerCase() === this.profile.msEmail?.toLowerCase()) {
            return email?.toLowerCase()
          }
        }
      }
      return ''
    },
    repeatFrequency () {
      if (this.displayMeeting) {
        if (this.displayMeeting.meetingSource === 'Google') {
          if (this.displayMeeting.reccurrencePattern && this.displayMeeting.reccurrencePattern.length > 0) {
            return getRecurringPatternText(this.displayMeeting.reccurrencePattern, this.displayMeeting.meetingSource)
          } else if (this.displayMeeting.recurringEventId) {
            const masterMeeting = this.meetingsRec.filter(meeting => {
              return meeting.id === this.displayMeeting.userId + '-' + this.displayMeeting.recurringEventId
            })
            // console.log('recurring event id master event', masterMeeting)
            if (masterMeeting.length > 0) {
              return getRecurringPatternText(masterMeeting[0].reccurrencePattern, this.displayMeeting.meetingSource)
            }
          }
        } else {
          if (this.displayMeeting.reccurrencePattern) {
            return getRecurringPatternText(this.displayMeeting.reccurrencePattern, this.displayMeeting.meetingSource)
          } else if (this.displayMeeting.masterEvent) {
            const masterMeeting = this.meetingsRec.filter(meeting => meeting.id === this.displayMeeting.masterEvent)
            // console.log('master event', masterMeeting)
            if (masterMeeting.length > 0) {
              return getRecurringPatternText(masterMeeting[0].reccurrencePattern, this.displayMeeting.meetingSource)
            }
          }
        }
      }
      return ''
    },
    showAs () {
      if (this.displayMeeting) {
        if (this.displayMeeting.msEventData) {
          if (this.displayMeeting.msEventData.showAs) {
            return this.displayMeeting.msEventData.showAs
          }
        }
      }
      return ''
    },
    notificationText () {
      if (this.displayMeeting) {
        if (this.displayMeeting.msEventData) {
          if (this.displayMeeting.msEventData.reminderMinutesBeforeStart) {
            return `${this.displayMeeting.msEventData.reminderMinutesBeforeStart} minutes before`
          }
        }
        if (this.displayMeeting.gEventData) {
          if (this.displayMeeting.gEventData.reminders) {
            if (this.displayMeeting.gEventData.reminders.useDefault) {
              return '15 minutes before'
            } else {
              if (this.displayMeeting.gEventData.reminders.overrides) {
                return `${this.displayMeeting.gEventData.reminders.overrides[0].minutes} minutes before`
              }
            }
          }
        }
      }
      return 'No Notification'
    },
    attendeesCount () {
      let attendeesStatus = ''
      if (this.displayMeeting) {
        const allAttendees = this.getMeetingAttendeesEmail()
        const attendeeText = allAttendees.length > 1 ? ' Attendees' : ' Attendee'
        attendeesStatus = `${allAttendees.length}` + attendeeText
        return attendeesStatus
      }
      return ''
    }
  },
  methods: {
    ...mapActions([
      'setSnackMessage'
    ]),
    ...mapActions({
      setDisplayMeeting: 'setDisplayMeeting',
      getScheduleByDate: 'teams/getScheduleByDate',
      updateMeeting: 'teams/updateMeeting'
    }),
    checkComposerDim () {
      const composerHeight = document.querySelector('.right-side')?.clientHeight - 100
      this.composerHeight = `${composerHeight}px`
      const width = document.querySelector('.right-side')?.clientWidth - 100
      this.composerWidth = `${width}px`
    },
    positionDialog () {
      const dialogHeight = document.querySelector('.meeting-dialog')?.clientHeight
      const layoutElement = document.querySelector('.mdc-layout-app')
      const leftSide = document.querySelector('.left-side')?.clientWidth
      let clickedMeeting = null
      if (this.displayMeeting.showFor === 'recommendation' || this.displayMeeting.showFor === 'officeActivity') {
        clickedMeeting = document.getElementsByClassName('meeting-clicked')[0]
      } else {
        clickedMeeting = document.getElementsByClassName('meeting clicked')[0]
      }
      // console.log('MeetingDialog.positionDialog.clickedMeeting', clickedMeeting)
      const meetingElement = clickedMeeting?.getBoundingClientRect()
      const self = this
      setTimeout(() => {
        let top = meetingElement?.bottom - 61 + layoutElement?.scrollTop
        let left = leftSide - 420
        left = left < 40 ? 40 : left

        if (meetingElement?.bottom < dialogHeight) {
          top = top + (dialogHeight - meetingElement?.bottom) + 20
        }

        if (top - dialogHeight < 0) {
          top = 30
        } else {
          top = top - dialogHeight
        }

        // console.log('MeetingDialog.positionDialog dh mb st t', dialogHeight, meetingElement?.bottom, layoutElement?.scrollTop, top)
        self.$el.style.top = top + 'px'

        self.$el.style.opacity = 1
        self.$el.style.left = this.displayMeeting.showFor === 'recommendation' || this.displayMeeting.showFor === 'officeActivity' ? left + 'px' : '51%'
      }, 20)
    },
    openAttendingOptions (type) {
      if (this.attendingOptionsFor) {
        this.attendingOptionsFor = ''
      }
      setTimeout(() => {
        this.attendingOptionsFor = type
      }, 20)
    },
    optionTypeClick (type) {
      // console.log(this.displayMeeting.meetingSource, this.attendingOptionsFor, type)
      const response = this.attendingOptionsFor
      this.setResponse(response, type)
      this.attendingOptionsFor = ''
    },
    copyUrl (joinUrl) {
      navigator.clipboard.writeText(joinUrl)
      this.setSnackMessage('Meeting url copied')
    },
    async setCalColor () {
      if (this.displayMeeting) {
        this.calColor = await this.getCalendarColor(this.displayMeeting.calendarId, this.displayMeeting.meetingSource, this.displayMeeting.subject)
      }
    },
    async setAttendees () {
      if (this.displayMeeting) {
        const declined = []
        const mayBe = []
        const accepted = []
        const unknown = []
        const attendeeEmails = this.getMeetingAttendeesEmail()
        const responseMap = this.getAttendeeResponseMap()
        for (const attendee of attendeeEmails) {
          const resp = responseMap[attendee.toLowerCase()] || 'unknown'
          const profileDetails = this.getMeetingAttendeesProfile(attendee.toLowerCase())
          const profile = profileDetails && Object.keys(profileDetails).length > 0 ? profileDetails : { id: '-1', displayName: this.getMeetingAttendeeNameOrEmail(attendee), email: attendee, response: resp, schedule: 'not set' }
          const isOrganizer = this.organizerDetails.email?.toLowerCase() === attendee?.toLowerCase()
          if (profile) {
            const scheduleResp = await this.getProfileScheduleByDate(profile.id) || 'notset'
            if (resp === 'declined') {
              declined.push({ ...profile, response: resp, schedule: scheduleResp, organizer: isOrganizer })
            } else if (resp.indexOf('tentative') >= 0) {
              mayBe.push({ ...profile, response: resp, schedule: scheduleResp, organizer: isOrganizer })
            } else if (resp === 'accepted' || resp === 'organizer') {
              accepted.push({ ...profile, response: resp, schedule: scheduleResp, organizer: isOrganizer })
            } else if (resp === 'none' || resp === 'needsAction' || resp === 'notResponded') {
              unknown.push({ ...profile, response: resp, schedule: scheduleResp, organizer: isOrganizer })
            } else {
              unknown.push({ ...profile, response: resp, schedule: scheduleResp, organizer: isOrganizer })
            }
          }
        }
        // console.log(accepted, declined, mayBe, unknown)
        this.accepted = accepted
        this.mayBe = mayBe
        this.declined = declined
        this.unknown = unknown
        this.allTypeAttendees = [...accepted, ...mayBe, ...declined, ...unknown]
        const breakDownText = []
        if (this.accepted.length > 0) {
          breakDownText.push(this.accepted.length + ' accepted')
        }
        if (this.mayBe.length > 0) {
          breakDownText.push(this.mayBe.length + ' tentative')
        }
        if (this.declined.length > 0) {
          breakDownText.push(this.declined.length + ' declined')
        }
        if (this.unknown.length > 0) {
          breakDownText.push(this.unknown.length + ' unknown')
        }
        this.attendeesBreakdown = breakDownText.join(', ')
      }
    },
    async getProfileScheduleByDate (member) {
      if (this.displayMeeting && this.displayMeeting.startDateTime) {
        const meetingTimeSlotAM = moment(this.displayMeeting.startDateTime.toDate()).format('a') === 'am'
        return await this.getScheduleByDate({ teamId: this.profile.team, fromId: member, scheduleDate: this.displayMeeting.startDateTime.toDate(), meetingTimeSlotAM })
      }
      return ''
    },
    getAttendeeResponseMap () {
      const responseMap = {}
      if (this.displayMeeting) {
        if (this.displayMeeting.attendees) {
          for (const attendee of this.displayMeeting.attendees) {
            if (attendee.status && attendee.emailAddress) {
              // MS
              responseMap[attendee.emailAddress.address] = attendee.status.response
            } else if (attendee.responseStatus && attendee.email) {
              // Google
              responseMap[attendee.email] = attendee.responseStatus
            }
          }
        }
        if (this.displayMeeting.organizer) {
          const email = this.displayMeeting.organizer.address || this.displayMeeting.organizer.email
          if (!responseMap[email]) {
            // unless responded set response as organizer for organizer
            responseMap[email] = 'organizer'
          }
        }
      }
      return responseMap
    },
    getMeetingAttendeeNameOrEmail (email) {
      if (this.displayMeeting) {
        if (this.displayMeeting.attendees) {
          for (const attendee of this.displayMeeting.attendees) {
            if (attendee.email && attendee.email === email) {
              if (attendee.displayName) {
                return attendee.displayName
              } else {
                return email.toLowerCase()
              }
            } else if (attendee.emailAddress && attendee.emailAddress.address === email) {
              if (attendee.emailAddress.name) {
                return attendee.emailAddress.name
              } else {
                return email.toLowerCase()
              }
            }
          }
        }
        if (this.displayMeeting.organizer) {
          // no attendee data - use organizer if matching
          if (this.displayMeeting.organizer.address === email) {
            return this.displayMeeting.organizer.name || email?.toLowerCase()
          } else if (this.displayMeeting.organizer.email === email) {
            return this.displayMeeting.organizer.displayName || email?.toLowerCase()
          }
        }
      }
      return email
    },
    getMeetingAttendeesEmail () {
      const meeting = this.displayMeeting
      if (meeting?.meetingSource === 'Google') {
        if (meeting.attendeeEmails?.length === 0) {
          return [meeting.organizer.email]
        } else if (!meeting.attendeeEmails.includes(meeting.organizer.email)) {
          return [...meeting.attendeeEmails, meeting.organizer.email]
        }
        return meeting.attendeeEmails || []
      } else {
        if (meeting.attendeeEmails?.length === 0) {
          return [meeting.organizer.address]
        } else if (!meeting.attendeeEmails.includes(meeting.organizer.address)) {
          return [...meeting.attendeeEmails, meeting.organizer.address]
        }
        return meeting.attendeeEmails || []
      }
    },
    getMeetingAttendeesProfile (attendeeEmail) {
      const allProfiles = [this.profile, ...this.profiles]
      const mainProfile = allProfiles.filter(pr => {
        return pr.email === attendeeEmail
      })
      if (mainProfile.length) {
        return mainProfile[0]
      }
      const calendarProfiles = allProfiles.filter(pr => {
        let msMatch = false
        if (pr.msEmail) {
          msMatch = pr.msEmail?.toLowerCase() === attendeeEmail
        }
        let googleMatch = false
        if (pr.googleEmail) {
          googleMatch = pr.googleEmail?.toLowerCase() === attendeeEmail
        }
        return msMatch || googleMatch
      })
      if (calendarProfiles.length) {
        return calendarProfiles[0]
      }
      return null
    },
    getMeetingAttendeesProfiles (attendeeEmails) {
      const allProfiles = [this.profile, ...this.profiles]
      return allProfiles.filter(profile => {
        const emails = [profile.msEmail, profile.googleEmail, profile.email]
        return emails.some(el => attendeeEmails.includes(el))
      })
    },
    getDateFormat (timestamp) {
      return moment(timestamp.toDate()).format('dddd D MMMM')
    },
    getTimeFormat (timestamp) {
      return moment(timestamp.toDate()).format('HH:mm')
    },
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        if (!this.displayProfile) {
          this.closeDialog()
        }
      }
    },
    handleAttendingResponse (response) {
      if (this.repeatFrequency) {
        this.openAttendingOptions(response)
      } else {
        this.setResponse(response)
      }
    },
    handleArrowsForAttendingResponse (response) {
      if (response) {
        switch (response) {
          case 'yes':
            if (this.myAttendence === 'accepted' || this.myAttendence === 'organizer') {
              if (this.attendingOptionsFor === response) {
                return 'thin-up-arrow-white'
              }
              return 'thin-down-arrow-white'
            }
            if (this.attendingOptionsFor === response) {
              return 'thin-up-arrow-4175b1'
            }
            return 'thin-down-arrow-4175b1'
          case 'no':
            if (this.myAttendence === 'declined') {
              if (this.attendingOptionsFor === response) {
                return 'thin-up-arrow-white'
              }
              return 'thin-down-arrow-white'
            }
            if (this.attendingOptionsFor === response) {
              return 'thin-up-arrow-4175b1'
            }
            return 'thin-down-arrow-4175b1'
          case 'maybe':
            if (this.myAttendence.indexOf('tentative') >= 0) {
              if (this.attendingOptionsFor === response) {
                return 'thin-up-arrow-white'
              }
              return 'thin-down-arrow-white'
            }
            if (this.attendingOptionsFor === response) {
              return 'thin-up-arrow-4175b1'
            }
            return 'thin-down-arrow-4175b1'
          default:
            if (this.attendingOptionsFor) {
              return 'thin-up-arrow-4175b1'
            }
            return 'thin-down-arrow-4175b1'
        }
      }
    },
    async setResponse (response, recType) {
      // console.log('MeetingDialog.setResponse', response, this.displayMeeting.meetingSource)
      if (this.displayMeeting.meetingSource === 'Google') {
        // Google
        let instanceStart = false
        let instanceEnd = false
        let instanceId = false
        let eventId = this.displayMeeting.id.split('-')[1]
        if (recType) {
          if (this.displayMeeting.masterEvent) {
            // is an instance already
            if (recType === 'this') {
              // single
              instanceId = this.displayMeeting.id.split('-')[1]
            } else {
              // all
              eventId = this.displayMeeting.masterEvent
            }
          } else if (this.displayMeeting.generatedFromMaster) {
            // is Rec event instance generated from master event
            // has no instanceId since generated - use start end date
            if (recType === 'this') {
              instanceStart = moment(this.displayMeeting.startDateTime.toDate()).startOf('day').format('YYYY-MM-DD[T00:00:00Z]')
              instanceEnd = moment(this.displayMeeting.startDateTime.toDate()).endOf('day').format('YYYY-MM-DD[T23:59:59Z]')
            }
          }
          // console.log('MeetingDialog.setResponse.google', eventId, instanceStart, instanceEnd, instanceId, response)
        }
        const calId = this.displayMeeting.calendarId
        // console.log('MeetingDialog.setResponse.set-response-google', { userId: this.profile.id, eventId, calId, email: this.myMeetingEmail, response })
        this.sendingResponse = true
        // console.log('MeetingDialog.setResponse', resp)
        const meetingAttendees = (this.displayMeeting.attendees?.length > 0 && this.displayMeeting.attendees) || [{ email: this.myMeetingEmail, response: '' }]
        let myAttendence = [...meetingAttendees].filter(attendee => attendee.email === this.myMeetingEmail)[0]
        const responseStatus = response === 'yes' ? 'accepted' : response === 'no' ? 'declined' : response === 'maybe' ? 'tentative' : 'none'
        if (myAttendence && myAttendence.responseStatus) {
          myAttendence.responseStatus = responseStatus
        } else {
          if (!myAttendence) {
            myAttendence = { email: this.myMeetingEmail, response: '' }
          }
          myAttendence = { ...myAttendence, responseStatus }
        }
        const updatedAttendees = [...meetingAttendees].filter(attendee => attendee.email !== this.myMeetingEmail)
        updatedAttendees.push(myAttendence)
        // console.log('setResponse.displayMeeting:', JSON.stringify(this.displayMeeting))
        this.displayMeeting.attendees = updatedAttendees
        if (recType !== 'this' || instanceId) {
          // Not a generated instance
          this.updateMeeting({ meetingId: this.displayMeeting.id, meeting: { attendees: updatedAttendees } })
        }
        this.setAttendees()
        this.sendingResponse = false
        await calendar('set-response-google', { userId: this.profile.id, eventId, calId, email: this.myMeetingEmail, response, instanceStart, instanceEnd, instanceId })
      } else {
        // MS
        let instanceStart = false
        let instanceEnd = false
        let instanceId = false
        let eventId = this.displayMeeting.id
        const calId = this.displayMeeting.calendarId
        if (recType) {
          if (this.displayMeeting.masterEvent) {
            // is an instance already
            if (recType === 'this') {
              instanceId = this.displayMeeting.id
            } else {
              // all
              eventId = this.displayMeeting.masterEvent
            }
          } else if (this.displayMeeting.generatedFromMaster) {
            // is Rec event instance generated from master event
            // has no instanceId since generated - use start end date
            if (recType === 'single') {
              instanceStart = moment(this.displayMeeting.startDateTime.toDate()).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[.0000000]')
              instanceEnd = moment(this.displayMeeting.startDateTime.toDate()).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss[.0000000]')
            }
          }
          // console.log('MeetingDialog.setResponse.ms', eventId, instanceStart, instanceEnd, instanceId, response)
        }
        // console.log('MeetingDialog.setResponse.set-response', { userId: this.profile.id, eventId, calId, email: this.myMeetingEmail, response })
        this.sendingResponse = true
        let selfAttendance
        const responseType = response === 'yes' ? 'accepted' : response === 'no' ? 'declined' : response === 'maybe' ? 'tentativelyAccepted' : 'none'
        // console.log('MeetingDialog.setResponse', resp)
        if (this.displayMeeting.attendees.length > 0) {
          const selfAttendanceDetails = [...this.displayMeeting.attendees].filter(attendee => attendee.emailAddress.address === this.myMeetingEmail)
          if (selfAttendanceDetails.length > 0) {
            selfAttendance = selfAttendanceDetails[0]
          } else {
            selfAttendance = { emailAddress: { address: this.myMeetingEmail, name: this.profile.displayName }, status: { response: '' } }
          }
        } else {
          selfAttendance = {
            emailAddress: {
              address: this.myMeetingEmail,
              name: this.profile.displayName
            },
            status: {
              response: ''
            }
          }
        }
        selfAttendance.status.response = responseType
        const updatedAttendees = [...this.displayMeeting.attendees].filter(attendee => attendee.emailAddress.address !== this.myMeetingEmail)
        updatedAttendees.push(selfAttendance)
        this.displayMeeting.attendees = updatedAttendees
        this.updateMeeting({ meetingId: this.displayMeeting.id, meeting: { attendees: updatedAttendees, responseStatus: { response: responseType } } })
        this.setAttendees()
        this.sendingResponse = false
        await calendar('set-response', { userId: this.profile.id, eventId, calId, email: this.myMeetingEmail, response, instanceStart, instanceEnd, instanceId })
      }
    },
    getResponseClass (resp) {
      if (resp === 'accepted' || resp === 'organizer') {
        return 'meeting-accepted'
      } else if (resp === 'declined') {
        return 'meeting-declined'
      } else if (resp.indexOf('tentative') >= 0) {
        return 'meeting-tentative'
      }
      return false
    },
    closeDialog () {
      this.$emit('close')
    },
    handleClickOutside (event) {
      // console.log('MeetingDialog.handleClickOutside', this, this.attendingOptionsFor, event)
      if (this.attendingOptionsFor) {
        if (event.target) {
          if (!(event.target.classList && event.target.classList.contains('acc-type')) || !(event.target.parentNode.classList && event.target.parentNode.classList.contains('acc-type')) || !event.target.classList.contains('option-type')) {
            this.attendingOptionsFor = ''
          }
        }
      }
      if (event.target.classList.contains('dialog-scrim') || event.target.classList.contains('close-icon') || event.target.classList.contains('close-dialog')) {
        return false
      }
      let a = event.target
      let parentMeeting = false
      while (a) {
        if (a.classList && a.classList.contains('meeting')) {
          parentMeeting = true
          break
        }
        a = a.parentNode
      }

      let clickInsideMeetingDialog = false
      if (event.path) {
        for (const node of event.path) {
          if (node.id === 'detailsforMeeting') {
            clickInsideMeetingDialog = true
          }
        }
      }

      // meeting
      // console.log('MeetingDialog.handleClickOutside meeting: cimd, is, con, pm, opt', event.target, clickInsideMeetingDialog, this.$el === event.target, this.$el.contains(event.target), parentMeeting, event.target.classList.contains('option-type'))
      if (!(clickInsideMeetingDialog || this.$el === event.target || this.$el.contains(event.target) || parentMeeting || event.target.classList.contains('option-type'))) {
        this.closeDialog()
      }
    },
    redirectToCalendar (link) {
      if (link) {
        window.open(link, '_blank')
      } else {
        window.open(this.displayMeeting.webLink, '_blank')
      }
    }
  },
  watch: {
    displayMeeting: {
      handler () {
        this.$el.style.opacity = 0
        // console.log('MeetingDialog.watch displayMeeting', this.displayMeeting)
        this.accepted = []
        this.declined = []
        this.unknown = []
        this.mayBe = []
        this.allTypeAttendees = []
        if (this.displayMeeting) {
          this.setCalColor()
          this.setAttendees()
          this.checkComposerDim()
          const self = this
          setTimeout(function () {
            self.positionDialog()
          }, 50)
        }
      }
    },
    showDialog: {
      handler () {
        if (this.showDialog) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
            document.addEventListener('keyup', self.handleKeyDown)
            self.positionDialog()
          }, 300)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
            document.removeEventListener('keyup', self.handleKeyDown)
          }, 300)
        }
      },
      immediate: true
    },
    composerWidth: {
      handler () {
        if (this.showDialog) {
          this.positionDialog()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.meeting-dialog {
  &.recommendation {
    left: 30%;
  }
  &.officeActivity {
    left: 30%;
  }
  opacity: 0;
  top: 30px;
  transition: left 0.10s linear;
  position: absolute;
  left: 48%;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  background: #FFFFFF;
  border: 0.5px solid #BDBDBD;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);
  border-radius: 10px;
  z-index: 40;
  min-width: 450px;
  max-width: 450px;
  .close-icon {
    margin: 20px 12px 0 0;
    height: 12px;
    width: 12px;
    align-self: flex-end;
    z-index: 20;
    img {
      height: 100%;
      width: 100%;
    }
  }
  > header {
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    border-bottom: 0.2px solid #bdbdbd;
    > div {
      &:last-of-type {
        margin-bottom: 10px;
      }
    }
    .heading {
      display: flex;
      align-items: center;
      margin-left: 25px;
      .subject-link {
        display: flex;
        align-items: center;
        margin-right: 25px;
        .meeting-subject-text {
          font-size: 16px;
          font-weight: 700;
          color: #404040;
          line-height: 20px;
          &:hover {
            &:after {
              content: ' ';
              background: url('../../assets/flexibly/icons/newwindow.svg') no-repeat;
              width: 26px;
              height: 17px;
              display: inline-flex;
              align-items: center;
              vertical-align: middle;
            }
          }
        }
      }
      .calendar-color {
        height: 6px;
        border-radius: 1px;
        margin-right: 8px;
        width: 100%;
        max-width: 6px;
        min-width: 6px;
      }
      .open-link {
        opacity: 0;
      }
    }
    .meeting-info {
      margin-left: 25px;
      color: #404040;
      font-size: 14px;
      display: flex;
      align-items: center;
      line-height: 24px;
      .mdc-text {
        font-weight: 600;
      }
      .separator {
        color: #404040;
        margin: 0 20px;
      }
    }
    .frequency {
      margin-left: 25px;
      line-height: 20px;
      .mdc-text {
        font-weight: 300;
      }
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .meeting-dialogs-content {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    overflow-y: overlay;
    &::-webkit-scrollbar {
      width: 30px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ADBCCD;
      border: 13px solid transparent;
      background-clip: padding-box;
      border-radius: 26px;
    }
    .meeting-details {
      display: flex;
      flex-direction: column;
      border-bottom: 0.2px solid #BDBDBD;
      .detail-row {
        display: flex;
        align-items: center;
        margin: 0 25px 7px 25px;
        .type-icon {
          flex: 0 0 45px;
          width: 14px;
          height: 14px;
          img {
            margin: 0;
            width: 14px;
            height: 14px;
          }
        }
        .type-text {
          color: #C1C7CE;
          font-weight: 700;
          flex: 0 0 45px;
        }
        .detail-type {
          &.joinurl {
            color: #4175B1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-weight: 400;
            margin-right: 25px;
          }
          &.location {
            &.notset {
              color: #BDBDBD;
              font-style: italic;
              font-weight: 300;
            }
          }
          &.notiftext {}
          &.showas {
            text-transform: capitalize;
          }
        }
        &:last-of-type {
          margin-bottom: 15px;
        }
        &:first-of-type {
          margin-top: 12px;
        }
      }
    }
    .org-details {
      border-top: 0.2px solid #BDBDBD;
      .detail-row {
        display: flex;
        align-items: center;
        margin: 10px 25px;
        .type-icon {
          flex: 0 0 45px;
          width: 14px;
          height: 14px;
          img {
            margin: 0;
            width: 14px;
            height: 14px;
          }
        }
        .detail-type {
          &.orgname {}
          &.orgemail {
            margin-left: auto;
            margin-right: 13px;
            font-weight: 300;
          }
        }
      }
    }
    .attendees-details {
      .detail-row {
        display: flex;
        align-items: center;
        margin: 15px 25px;
        .type-icon {
          flex: 0 0 45px;
          width: 14px;
          height: 14px;
          img {
            margin: 0;
            width: 14px;
            height: 14px;
          }
        }
        .detail-type {
          &.attendeesinfo {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .breakdown {
              font-size: 11px;
              font-weight: 300;
              margin-right: 13px;
            }
          }
        }
      }
    }
    .attendees-schedules-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 70px; // 45px type-icon + 25mrgn
      margin-right: 38px;
      .attendee {
        display: flex;
        height:20px;
        margin-bottom: 10px;
        align-items: center;
        position: relative;
        &:last-of-type {
          margin-bottom: 20px;
        }
        .attendee-resp {
          position: absolute;
          z-index: 4;
          margin-left: 12px;
          top: -5px;
        }
        .profile-redirect {
          margin-right: 5px;
          .profile-display-name {
            color: #404040;
            font-size: 14px;
            margin-left: 15px;
          }
        }
        .icon-wrapper:not(.attendee-resp) {
          height: 11px;
          width: 11px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .org-profile {
          margin-left: auto;
          font-weight: 300;
        }
      }
    }
  }
  > footer {
    background:#f7f7f7;
    border-bottom: 0.5px solid #BDBDBD;
    box-shadow: 0px 0px 8px -8px rgb(0 0 0 / 20%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: auto;
    position:relative;
    .attending-options {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 100%;
      background: #FFFFFF;
      border: 0.5px solid #C4C4C4;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      right: 70px;
      width: 200px;
      height: 100%;
      top: -65px;
      &.no {
        right: -12px;
      }
      &.maybe {
        right: -98px;
        @media (max-width: 1125px) {
          right: -95px;
        }
        @media (min-width: 1065px) and (max-width: 1120px) {
          right: -70px;
        }
        @media (min-width: 1025px) and (max-width: 1065px) {
          right: -50px;
        }
        @media (max-width: 1025px) {
          right: -30px;
        }
      }
    }
    .option-type {
      padding: 8px 12px;
      &:hover {
        background: rgba(235, 243, 252, 0.58);
      }
    }
    .request-details-actions {
      display: flex;
      align-items: center;
      margin: 20px 20px 10px 20px;
      .actions {
        display: flex;
        align-items: center;
        width: 100%;
        .attending-label {
          margin-right: auto;
          display:flex;
          align-items: center;
        }
        .loader {
          animation: spin 1s linear infinite;
          border-top: 2px solid transparent;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          margin-left:10px;
          border: 2px solid #4175B1;
          border-top: 2px solid transparent;
        }
        .mdc-button {
          margin-right: 20px;
          border-radius: 4px;
          text-transform: none;
          width: max-content;
          background: #FFFFFF;
          border: 1px solid #4175B1;
          color: #4175B1;
          font-size: 13px;
          font-weight: 600;
          .down-arrow {
            margin-left: 10px;
          }
          &.active {
            background: #4175B1;
            color:white;
          }
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
