<template>
  <app-dialog v-model="open"
      title="Update Card Design"
      subtitle="Select a new card design."
      accept=""
      cancel="cancel"
      class="open-card-design modal fullsize-mobile"
      scrollable>
      <span slot="post-title">
        <round-button @click="$emit('openPhoto')" background="transparent" class="photo" title="Profile Photo" iconOutline icon="add_a_photo" label="Profile Photo"/>
      </span>
      <div class="mdc-dialog__body">

        <mdc-layout-grid style="padding:0" >
          <mdc-layout-cell desktop="3" tablet="2" mobile="2"
            v-for="(cld, index) in cardLayoutDesigns" :key="index"
            style="cursor:pointer"
            @click.native="selectCardDesign(cld.design, cld.layout)"
            >
              <div class="profile-card-wrapper">
                <mdc-card class="profile-wrapper">
                  <div :class="'profile ' + cld.design">
                    <div :class="'profile-bg ' + cld.layout">
                      <div class="profile-image">
                        <span v-if="profile" class="avatar">
                          <img v-if="profile.photoURL" :src="profile.photoURL">
                          <default-avatar v-else :profile="profile"/>
                        </span>
                      </div>
                      <div class="profile-text"></div>
                    </div>
                  </div>
                </mdc-card>
              </div>
          </mdc-layout-cell>
        </mdc-layout-grid>

        <mdc-body v-if="!advancedEnabled" typo="body2">Complete your profile to unlock more interesting designs</mdc-body>
        <div v-else class="spacer" />

        <mdc-layout-grid v-if="advancedEnabled" style="padding:0">
          <mdc-layout-cell desktop="3" tablet="2" mobile="2"
            v-for="(cld, index) in extraCardLayoutDesigns" :key="index"
            style="cursor:pointer"
            @click.native="selectCardDesign(cld.design, cld.layout)"
            >
            <div class="profile-card-wrapper">
                <mdc-card class="profile-wrapper">
                  <div :class="'profile ' + cld.design">
                    <div :class="'profile-bg ' + cld.layout">
                      <div class="profile-image">
                        <span v-if="profile" class="avatar">
                          <img v-if="profile.photoURL" :src="profile.photoURL">
                          <default-avatar v-else :profile="profile"/>
                        </span>
                      </div>
                      <div class="profile-text"></div>
                    </div>
                  </div>
                </mdc-card>
              </div>
          </mdc-layout-cell>
        </mdc-layout-grid>
        <mdc-layout-grid v-else style="padding:0">
          <mdc-layout-cell desktop="3" tablet="2" mobile="2"
            v-for="(cld, index) in extraCardLayoutDesigns" :key="index"
            style="cursor:pointer"
            @click.native="$emit('openTasks');open = false"
            >
            <div class="profile-card-wrapper">
                <mdc-card class="profile-wrapper">
                  <div class="gray" :class="'profile ' + cld.design">
                    <div :class="'profile-bg ' + cld.layout">
                      <div class="profile-image">
                        <span v-if="profile" class="avatar">
                          <img class="gray" v-if="profile.photoURL" :src="profile.photoURL">
                          <default-avatar v-else :profile="profile"/>
                        </span>
                      </div>
                      <div class="profile-text"></div>
                    </div>
                  </div>
                </mdc-card>
              </div>
          </mdc-layout-cell>
        </mdc-layout-grid>

      </div>
    </app-dialog>
</template>

<script>
import { mapState } from 'vuex'
import RoundButton from '../../components/RoundButton'
import DefaultAvatar from '../../components/DefaultAvatar'
import AppDialog from '../../components/AppDialog'
export default {
  name: 'CardDesignModal',
  props: {
    openCardDesign: {
      type: Boolean,
      default: false
    },
    advancedEnabled: {
      type: Boolean,
      default: false
    },
    profile: {}
  },
  components: {
    AppDialog,
    RoundButton,
    DefaultAvatar
  },
  mounted () {
  },
  computed: {
    ...mapState([
      'workspace'
    ]),
    carddesign () {
      const basicCarddesign = ['profile-colour-magenta', 'profile-colour-orange', 'profile-colour-yellow', 'profile-colour-cyan', 'profile-colour-blue']
      const extraCarddesign = ['profile-design-6-cyan', 'profile-design-1-yellow', 'profile-design-2-orange', 'profile-design-3-magenta']
      if (this.workspace.assignedWorkspace && this.workspace.assignedWorkspace.extraCarddesigns) {
        return [...extraCarddesign, ...basicCarddesign]
      }
      return basicCarddesign
    },
    extraCarddesign () {
      const extraCarddesign = ['profile-design-6-cyan', 'profile-design-1-yellow', 'profile-design-2-orange', 'profile-design-3-magenta']
      return extraCarddesign
    },
    extraCardLayoutDesigns () {
      const extraCardLayoutDesigns = []
      for (const cd of this.extraCarddesign) {
        for (const cl of this.cardlayout) {
          extraCardLayoutDesigns.push({ design: cd, layout: cl })
        }
      }
      return extraCardLayoutDesigns
    },
    cardLayoutDesigns () {
      const cardLayoutDesigns = []
      for (const cd of this.carddesign) {
        for (const cl of this.cardlayout) {
          cardLayoutDesigns.push({ design: cd, layout: cl })
        }
      }
      return cardLayoutDesigns
    }

  },
  data () {
    return {
      open: false,
      cardlayout: ['profile-bg-diagonal', 'profile-bg-top', 'profile-bg-bottom', 'profile-bg-left']
    }
  },
  methods: {
    selectCardDesign (design, layout) {
      this.$emit('change', false)
      this.$emit('cardDesign', design, layout)
    },
    analytics (payload) {
      // eslint-disable-next-line no-undef
      analytics.track('shareProfile', {
        id: this.profile.id,
        role: this.profile.role,
        workspace: this.profile.workspace,
        journeyStage: this.profile.journeyStage,
        platform: payload
      })
    }
  },
  watch: {
    openCardDesign: {
      handler (newo, oldo) {
        this.open = this.openCardDesign
      },
      immediate: true
    },
    open (newo, oldo) {
      if (this.open !== this.openCardDesign) {
        this.$emit('change', this.open)
      }
    }
  }
}

</script>

<style>
.open-card-design .gray {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
.open-card-design .profile-card {
  min-width:100px;
  margin:6px !important;
}
.open-card-design .displayname {
  display:inline-block;
  overflow-wrap: break-word;
  max-width:100%;
}
.open-card-design .sharemenu {
  min-width: 170px;
}
/* entire container, keeps perspective */

.open-card-design .mdc-dialog__body .profile-card {
  width: 46%;
  display: inline-block;
  margin: 2%;
  cursor: pointer;
}

.open-card-design .profile-stat .hover-info {
  display:none;
  position:absolute;
  color:#fff;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  margin-top: 40px;
  border-radius:5px;
  font-size:12px;
  z-index:9999;
}
.open-card-design .profile-stat:hover .hover-info {
  display:flex;
}

.open-card-design .flip-container {
  perspective: 1000px;
  transform-style: preserve-3d;
}

/*  flip the pane when hovered */
.open-card-design .flip-container.hover-enabled:hover .back, .flip-container.hover-enabled.hover .back {
  transform: rotateY(0deg);
}
.open-card-design .flip-container.hover-enabled:hover .front, .flip-container.hover-enabled.hover .front  {
  transform: rotateY(180deg);
}

.open-card-design .flip-container, .front, .back {
  width: 100%;
  min-height:100px;
  height: auto;
}

/* flip speed goes here */
.open-card-design .flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.open-card-design .swap-container .back, .swap-container .back {
  visibility: hidden;
  z-index:-1;
}
.open-card-design .swap-container.hover-enabled:hover .back, .swap-container.hover-enabled.hover .back {
  visibility: visible;
  z-index:4;
}

.open-card-design .swap-container.hover-enabled:hover .front, .swap-container.hover-enabled.hover .front {
  filter:blur(2px);
}

.open-card-design .swapper .back .profile-wrapper {
  background-color: rgba(110, 110, 110, 0.5);
}

.open-card-design .swapper .back .profile-bg {
  display:none;
}

.open-card-design .profile-card.hover:hover .swapper .profile-wrapper {
  background:none;
}

.open-card-design .swapper .back .profile .back-profile-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.5);
  color:#fff !important;
  position:absolute;
  overflow: hidden;
  z-index:1;
}

.open-card-design .swapper .back .profile .back-profile-overlay p {
  color:#fff !important;
}

.open-card-design .swapper .back .profile .profile-card-design {
  height:100%;
  width:100%;
  position:absolute;
  background:none;
  filter:blur(1px);
}

/* hide back of pane during swap */
.open-card-design .flipper .front, .flipper .back {
  backface-visibility: hidden;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  top: 0;
  left: 0;
}

.open-card-design .back {
  margin-top:-65%;
}

.open-card-design .back .profile-bg {
  filter:blur(1px);
  margin-left:-2px;
}

.open-card-design .flipper .back .profile .back-profile-overlay {
  height:100%;
  width:100%;
  background-color:rgba(0,0,0,0.3);
  color:#fff !important;
  position:absolute;
  overflow: hidden;
  z-index:1;
}

/* front pane, placed above back */
.open-card-design .flipper .front {
  z-index: 2;
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.open-card-design .flipper .back {
  transform: rotateY(-180deg);
}

.open-card-design .flipper .back .profile .profile-card-design {
  height:100%;
  width:100%;
  position:absolute;
  background-size: cover;
  filter:blur(1px);
}

.open-card-design .back .profile .back-profile-overlay .back-profile-content{
  margin-top:16px;
  margin-left:6px;
  margin-right:6px;
  overflow: hidden;
}

.open-card-design .profile-wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  background-color:white;
  position: relative;
  border-radius: 13px;
  width:100%;
  overflow: hidden;
  padding-top: calc(154 / 238 * 100%);
  position: relative;
}
.open-card-design .profile, .profile-bg {
  display:flex;
  flex-flow: row;
  position: absolute;
  top: 0px;
  left: 0;
  width: 101%;
  height: 100%;
  align-items: center;
}

.open-card-design .profile {
  top:-8px;
  background-size: cover;
  height:calc(100% + 16px)
}

/* Background position this goes on the same div as profile-bg class */
.open-card-design div[class*="profile-bg-"] .profile-text {
  padding-bottom: 0px;
  padding-left:0px;
}

.open-card-design div[class*="profile-bg-"] .profile-text img {
  display: none;
}

.open-card-design .profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #F2EEE6 0%);
}
.open-card-design .profile-card:hover .profile-bg-diagonal {
  background: linear-gradient(70deg, transparent 35%, #DEDBD6 0%);
}
.open-card-design .profile-bg-top {
  background: linear-gradient(#F2EEE6 85%, transparent 0%);
}
.open-card-design .profile-bg-top .profile-text {
  padding-top: 0;
  padding-bottom: 20px;
}
.open-card-design .profile-card:hover .profile-bg-top {
  background: linear-gradient(#DEDBD6 85%, transparent 0%);
}
.open-card-design .profile-bg-bottom {
  background: linear-gradient(transparent 40%, #F2EEE6 0%);
}
.open-card-design .profile-card:hover .profile-bg-bottom {
  background: linear-gradient(transparent 40%, #DEDBD6 0%);
}
.open-card-design .profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #F2EEE6 0%);
}
.open-card-design .profile-card:hover .profile-bg-left {
  background: linear-gradient(90deg, transparent 22%, #DEDBD6 0%);
}

.open-card-design div[class*="profile-bg-"] .profile-text {
  padding-bottom: 0px;
}

.open-card-design div[class*="profile-bg-"] .profile-text img {
  display: none;
}

/* Background colour/bg options this goes on the same div a profile class */
.open-card-design .profile-colour-magenta {
  background-color: #C10457;
}
.open-card-design .profile-colour-orange {
  background-color: #f4854d;
}
.open-card-design .profile-colour-yellow {
  background-color: #ffd12b;
}
.open-card-design .profile-colour-cyan {
  background-color: #2cbcb6;
}
.open-card-design .profile-colour-blue {
  background-color: #3560AF;
}
.open-card-design .profile-design-1-cyan {
  background-image: url('/static/img/card-designs/profile-design-1-cyan.svg');;
}
.open-card-design .profile-design-1-magenta {
  background-image: url('/static/img/card-designs/profile-design-1-magenta.svg');
}
.open-card-design .profile-design-1-orange {
  background-image: url('/static/img/card-designs/profile-design-1-orange.svg');
}
.open-card-design .profile-design-1-yellow {
  background-image: url('/static/img/card-designs/profile-design-1-yellow.svg');
}
.open-card-design .profile-design-2-cyan {
  background-image: url('/static/img/card-designs/profile-design-2-cyan.svg');
}
.open-card-design .profile-design-2-white {
  background-image: url('/static/img/card-designs/profile-design-2-white.svg');
}
.open-card-design .profile-design-2-magenta {
  background-image: url('/static/img/card-designs/profile-design-2-magenta.svg');
}
.open-card-design .profile-design-2-orange {
  background-image: url('/static/img/card-designs/profile-design-2-orange.svg');
}
.open-card-design .profile-design-2-yellow {
  background-image: url('/static/img/card-designs/profile-design-2-yellow.svg');
}
.open-card-design .profile-design-3-cyan {
  background-image: url('/static/img/card-designs/profile-design-3-cyan.svg');
}
.open-card-design .profile-design-3-magenta {
  background-image: url('/static/img/card-designs/profile-design-3-magenta-jpg.jpg');
}
.open-card-design .profile-design-3-orange {
  background-image: url('/static/img/card-designs/profile-design-3-orange.svg');
}
.open-card-design .profile-design-3-yellow {
  background-image: url('/static/img/card-designs/profile-design-3-yellow.svg');
}
.open-card-design .profile-design-4-cyan {
  background-image: url('/static/img/card-designs/profile-design-4-cyan.svg');
}
.open-card-design .profile-design-4-magenta {
  background-image: url('/static/img/card-designs/profile-design-4-magenta.svg');
}
.open-card-design .profile-design-4-orange {
  background-image: url('/static/img/card-designs/profile-design-4-orange.svg');
}
.open-card-design .profile-design-4-yellow {
  background-image: url('/static/img/card-designs/profile-design-4-yellow.svg');
}
.open-card-design .profile-design-5-cyan {
  background-image: url('/static/img/card-designs/profile-design-5-cyan.svg');
}
.open-card-design .profile-design-5-white {
  background-image: url('/static/img/card-designs/profile-design-5-white.svg');
}
.open-card-design .profile-design-5-magenta {
  background-image: url('/static/img/card-designs/profile-design-5-magenta.svg');
}
.open-card-design .profile-design-5-orange {
  background-image: url('/static/img/card-designs/profile-design-5-orange.svg');
}
.open-card-design .profile-design-5-yellow {
  background-image: url('/static/img/card-designs/profile-design-5-yellow.svg');
}
.open-card-design .profile-design-6-cyan {
  background-image: url('/static/img/card-designs/profile-design-6-cyan.svg');
}
.open-card-design .profile-design-6-white {
  background-image: url('/static/img/card-designs/profile-design-6-white.svg');
}
.open-card-design .profile-design-6-magenta {
  background-image: url('/static/img/card-designs/profile-design-6-magenta.svg');
}
.open-card-design .profile-design-6-orange {
  background-image: url('/static/img/card-designs/profile-design-6-orange.svg');
}
.open-card-design .profile-design-6-yellow {
  background-image: url('/static/img/card-designs/profile-design-6-yellow.svg');
}
.open-card-design .profile-design-6-multi {
  background-image: url('/static/img/card-designs/profile-design-6-multi.svg');
}
.open-card-design .profile-design-7-cyan {
  background-image: url('/static/img/card-designs/profile-design-7-cyan.svg');
}
.open-card-design .profile-design-7-white {
  background-image: url('/static/img/card-designs/profile-design-7-white.svg');
}
.open-card-design .profile-design-7-magenta {
  background-image: url('/static/img/card-designs/profile-design-7-magenta.svg');
}
.open-card-design .profile-design-7-orange {
  background-image: url('/static/img/card-designs/profile-design-7-orange.svg');
}
.open-card-design .profile-design-7-yellow {
  background-image: url('/static/img/card-designs/profile-design-7-yellow.svg');
}
.open-card-design .profile-image {
  width: 48%;
}
.open-card-design .avatar {
  width: 80%;
  margin: 10%;
  padding-top: 80%;
  position: relative;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: block;
  overflow: hidden;
}
.open-card-design .avatar img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;;
    left: 0;
    object-fit: cover;
}
.open-card-design .profile-text {
  width: 52%;
  height: 50%;
  text-align: center;
  padding: 5%;
  padding-top: 30% !important;
  overflow: hidden;
}
.open-card-design .link-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.open-card-design .link-row > div {
  flex: 1;
  flex-direction: row;
  display: flex;
  text-align: center;
  align-items: center;
}
.open-card-design .link-row-top > div {
  flex: 0;
}
.open-card-design .link-row-bottom > div:nth-of-type(3) {
    flex: 0 0 30%;
    min-width: 30px;
    justify-content: flex-end;
}
.open-card-design .link-row-bottom > div:nth-of-type(4) {
    flex: 0 0 15%;
    min-width: 30px;
    justify-content: flex-end;
}
.open-card-design .profile-stat {
    display: flex;
    flex-direction: column;
    line-height: 0;
}
.open-card-design .profile-headline {
  font-style:italic;
  font-size: 14px;
  color: #333333;
}
.open-card-design .profile-card .profile-image {
  position:relative;
}
.open-card-design .profile-card .mdc-body.profile-status {
  max-width: calc(100% + 12px);
  overflow: hidden;
}
.open-card-design .profile-card .profile-image .profile-status {
  color:gray;
}
.open-card-design .profile-card .profile-image.editable:hover {
  cursor:pointer;
}
.open-card-design .profile-card .profile-image.editable .overlay{
  display:none;
  position:absolute;
  width:100%;
  height:100%;
}
.open-card-design .profile-card .profile-image.editable:hover .overlay{
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/424395/camera.svg");
  background-size: 50px 41px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
  z-index:9;
  display:block;
}
.open-card-design .profile-card .mdc-subheading {
  margin:0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.open-card-design .profile-card .mdc-body {
  margin:0;
  font-size: 12px;
  line-height: 1;
}
.open-card-design span[class^="days-"] {
  margin-top: 3px;
  width: 55px;
  white-space: nowrap;
}
.open-card-design span[class^="days-"] .day {
  height: 2px;
  background: #E0E0E0;
  display: inline-block;
  width: 8px;
  margin-left: 1.5px;
  margin-right: 1.5px;
}
.open-card-design span.days-1 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
.open-card-design span.days-2 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
.open-card-design span.days-3 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
.open-card-design span.days-4 .day:nth-of-type(-n+4) {
  background: #AD1457;
}
.open-card-design span.days-5 .day:nth-of-type(-n+5) {
  background: #AD1457;
}

.open-card-design span.days-1-5 .day:nth-of-type(-n+1) {
  background: #AD1457;
}
.open-card-design span.days-2-5 .day:nth-of-type(-n+2) {
  background: #AD1457;
}
.open-card-design span.days-3-5 .day:nth-of-type(-n+3) {
  background: #AD1457;
}
.open-card-design span.days-4-5 .day:nth-of-type(-n+4) {
  background: #AD1457;
}

.open-card-design span.days-0-5 .day:nth-of-type(1) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
.open-card-design span.days-1-5 .day:nth-of-type(2) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
.open-card-design span.days-2-5 .day:nth-of-type(3) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
.open-card-design span.days-3-5 .day:nth-of-type(4) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}
.open-card-design span.days-4-5 .day:nth-of-type(5) {
  background: #AD1457;
  width: 4px;
  border-right: 4px solid #E0E0E0;
}

.open-card-design .profile-status-bar {
  display:flex;
  width:100%
}
.open-card-design .profile-details {
  padding: 5% 2% 0 2%;
  font-family: 'Roboto', sans-serif;
  background: none;
  box-shadow: none;
}
.open-card-design .profile-details .duome-icon img {
  width:20px;
  height: 20px;
}
.open-card-design .profile .edit-icon {
  background: #fff;
  position: absolute;
  bottom: 12px;
  left: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0;
}
.open-card-design .profile .edit-icon .mdc-icon.mdc-icon--material {
  font-size: 20px;
  line-height: 25px;
  color: #888;
  margin-right: 0;
}
.open-card-design .profile .edit-icon:hover {
  cursor:pointer;
}
.open-card-design .profile .edit-icon + .hover-info {
  display:none;
  position:absolute;
  color:white;
  height:20px;
  background-color: rgba(0,0,0,1);
  align-items: center;
  padding: 2px 12px;
  border-radius:5px;
  font-size:12px;
  z-index: 101;
  left: 30px;
  bottom: 25px;
}
.open-card-design .profile .edit-icon:hover + .hover-info {
  display:flex;
}
.open-card-design .profile-details .value {
    display: block;
    line-height: 1;
    color: #333333;
    min-height: 9px;
}
.open-card-design .profile-details .field {
  font-style: italic;
  margin-top: 3px;
}
.open-card-design .location {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.open-card-design .location .profile-stat .value {
  font-style:italic;
  font-size: 14px;
}
.open-card-design .role {
  font-weight: 500;
  font-style:italic;
  font-size: 14px;
}
.open-card-design .mdc-icon {
  margin-right: 5px;
}
.open-card-design .profile-card:not(:hover) .link-row-bottom .mdc-button {
  background-color: #ffffff;
  box-shadow: none;
  color: #333333;
}
.open-card-design .profile-card .link-row-bottom .mdc-button .mdc-icon {
  margin: 0 10px 5px 0;
}
.open-card-design .profile-details .mdc-button {
  color: black;
}
.open-card-design .profile-details .mdc-button img {
  margin-right:6px;
}
.open-card-design .profile-details .mdc-button img.icon-active {
  display:none;
}
.open-card-design .profile-card.hover:hover .profile-wrapper {
  background: #F6F6F6;
}
.open-card-design .about > div {
  font-size: 14px;
  margin: 10px 0;
}
.open-card-design .action {
  cursor: pointer;
}
.open-card-design .bookmark {
  display:none;
  cursor: pointer;
}
.open-card-design .bookmark .icon {
  display: block;
}
.open-card-design .bookmark .icon-active {
  display:none;
}
.open-card-design .active .bookmark .icon-active {
  display: block;
}
.open-card-design .active .bookmark .icon {
  display: none;
}
.open-card-design .spacer {
  width:100%;
  height: var(--mdc-layout-grid-margin-desktop, 24px);
}
@media (max-width: 840px) {
  .open-card-design .spacer {
    height: var(--mdc-layout-grid-margin-phone, 16px);
  }
}
</style>
