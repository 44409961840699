<template>
  <section class="notification-settings" :class="displayFor">
    <header class="settings-header"  v-if="displayFor === 'panel'">
      <mdc-text tag="span" typo="body">NOTIFICATION SETTINGS</mdc-text>
      <div class="close-settings">
        <mdc-icon-button  @click.native.stop="closeSettings()">clear</mdc-icon-button>
      </div>
    </header>
    <mdc-text tag="span" typo="body" class="about-what">About what?</mdc-text>
    <div class="fields">
      <div class="all-or-some-types">
          <mdc-text tag="span" typo="body2">CONTROL WHAT YOU RECEIVE</mdc-text>
          <div class="all-or-some-radios">
              <mdc-radio :checked="notificationsSettings.notifyAbout === 'all'" @click.native.stop="setupWhat('all')" :value="notificationsSettings.notifyAbout" name="radio-notify" :label="'Notify me about everything'" />
              <mdc-radio :checked="notificationsSettings.notifyAbout === 'some'" @click.native.stop="setupWhat('some')" :value="notificationsSettings.notifyAbout" name="radio-notify" :label="'Only notify about these things'" />
          </div>
      </div>
      <div class="notify-some-types" v-if="notificationsSettings.notifyAbout === 'some'">
        <section class="types-and-places">
          <div class="headings">
            <span class="heading-text">
              <mdc-text tag="span" typo="body2">NOTIFY ABOUT</mdc-text>
            </span>
            <span class="where-text">
              <mdc-text tag="span" typo="body2">App <br><small>(required)</small></mdc-text>
              <mdc-text tag="span" typo="body2">Email</mdc-text>
            </span>
          </div>
          <div class="types-and-places-fields">
            <div class="location-updates notify-type">
              <span class="heading-text">
                <mdc-text tag="span" typo="body2">Location updates</mdc-text>
              </span>
              <span class="checkboxes">
                <base-svg-icon class="app-type-check" :name="getWhereToNotify('locationupdates', 'app')" />
                <base-svg-icon @click.native.stop="toggleWhereToNotify('locationupdates', 'email')" :name="getWhereToNotify('locationupdates', 'email')" clickable />
              </span>
            </div>
            <div class="location-updates notify-type">
              <span class="heading-text">
                <mdc-text tag="span" typo="body2">Tasks</mdc-text>
              </span>
              <span class="checkboxes">
                <base-svg-icon class="app-type-check" :name="getWhereToNotify('tasks', 'app')" />
                <base-svg-icon @click.native.stop="toggleWhereToNotify('tasks', 'email')" :name="getWhereToNotify('tasks', 'email')" clickable />
              </span>
            </div>
            <div class="location-updates notify-type">
              <span class="heading-text">
                <mdc-text tag="span" typo="body2">Notes</mdc-text>
              </span>
              <span class="checkboxes">
                <base-svg-icon  class="app-type-check" :name="getWhereToNotify('notes', 'app')" />
                <base-svg-icon @click.native.stop="toggleWhereToNotify('notes', 'email')" :name="getWhereToNotify('notes', 'email')" clickable />
              </span>
            </div>
            <div class="location-updates notify-type">
              <span class="heading-text">
                <mdc-text tag="span" typo="body2">Mentions</mdc-text>
              </span>
              <span class="checkboxes">
                <base-svg-icon class="app-type-check" :name="getWhereToNotify('mentions', 'app')" />
                <base-svg-icon @click.native.stop="toggleWhereToNotify('mentions', 'email')" :name="getWhereToNotify('mentions', 'email')" clickable />
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>
    <mdc-text tag="span" typo="body" class="notify-how">HOW OFTEN?</mdc-text>
    <div class="notify-when">
      <div class="notify-when-radios">
        <mdc-radio :checked="notificationsSettings.notifyWhen === 'anytime'" @click.native.stop="setupHowOften('anytime')" :value="notificationsSettings.notifyWhen" name="radio-notify-when" :label="'Always send me notification, at any time'" />
        <mdc-radio :checked="notificationsSettings.notifyWhen === 'between'" @click.native.stop="setupHowOften('between')" :value="notificationsSettings.notifyWhen" name="radio-notify-when" :label="'Only notifiy me during working hours'" />
      </div>
    </div>
    <div v-if="notificationsSettings.notifyWhen === 'between'">

      <div class="notify-between-row time">
        <input type="time" id="notifyfrom" name="notifyfrom" v-model="notificationsSettings.notifyWhenFields.notifyWhenTime.from">
        <span> to</span>
        <input type="time" id="notifyto" name="notifyto" v-model="notificationsSettings.notifyWhenFields.notifyWhenTime.to">
        <span><mdc-text tag="span" style="margin:0" typo="body2"> {{timezone}}</mdc-text></span>
        <span class="timezone-help-info">Timezone is automatically set based on your browser or system time</span>
      </div>
      <div class="notify-between-row">
        <div class="notify-between-day" v-for="day in days" :key="day" >
          <span class="checkboxes">
            <base-svg-icon @click.native.stop="notificationsSettings.notifyWhenFields.notifyWhenDays[day] = !notificationsSettings.notifyWhenFields.notifyWhenDays[day]" :name="getWhenDay(day)" clickable />
          </span>
          <span class="heading-text">
            <mdc-text tag="span" typo="body2">{{day}}</mdc-text>
          </span>
        </div>
      </div>

    </div>
    <span class="save-settings-button">
      <mdc-button class="save-button" @click.native.stop="updateProfileNotificationSettings()" raised :disabled="checkFieldsSelected()">Save settings</mdc-button>
    </span>
  </section>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import BaseSvgIcon from '../flexibly/BaseSvgIcon'
export default {
  name: 'NotificationSettings',
  components: {
    BaseSvgIcon
  },
  created () {
    this.initialize()
  },
  beforeDestroy () {},
  mounted () {
  },
  computed: {
    ...mapState([
      'profile',
      'timezone'
    ])
  },
  props: {
    displayFor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      notificationsSettings: {},
      fields: [
        'locationupdates',
        'tasks',
        'mentions',
        'notes'
      ],
      days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
    }
  },
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    checkFieldsSelected () {
      const checkFieldsResponse = this.fields.map(field => { return this.notificationsSettings.notifyAboutFields[field].length > 0 })
      return checkFieldsResponse.every(state => !state)
    },
    updateProfileNotificationSettings () {
      this.updateProfile({ profile: { notificationsSettings: { ...this.notificationsSettings, updatedOn: moment().toDate() } }, snackMessage: 'Notification settings saved' })
    },
    toggleHowToNotify (type) {
      if (this.notificationsSettings.notifyHow) {
        if (this.notificationsSettings.notifyHow.includes(type)) {
          const index = this.notificationsSettings.notifyHow.findIndex(notifyType => notifyType === type)
          this.notificationsSettings.notifyHow.splice(index, 1)
        } else {
          this.notificationsSettings.notifyHow.push(type)
        }
      }
    },
    toggleWhereToNotify (type, location) {
      if (this.notificationsSettings.notifyAboutFields) {
        if (this.notificationsSettings.notifyAboutFields[type]) {
          if (this.notificationsSettings.notifyAboutFields[type].includes(location)) {
            const index = this.notificationsSettings.notifyAboutFields[type].findIndex(locationType => locationType === location)
            this.notificationsSettings.notifyAboutFields[type].splice(index, 1)
          } else {
            this.notificationsSettings.notifyAboutFields[type].push(location)
          }
        }
      }
    },
    getWhereToNotify (type, location) {
      if (this.notificationsSettings.notifyAboutFields) {
        if (this.notificationsSettings.notifyAboutFields[type]) {
          if (this.notificationsSettings.notifyAboutFields[type].includes(location)) {
            return 'checkbox_checked'
          }
          return 'checkbox_unchecked'
        }
      }
      return 'checkbox_unchecked'
    },
    setupHowOften (choice) {
      this.notificationsSettings.notifyWhen = choice
      if (choice === 'between') {
        if (this.profile.notificationsSettings && this.profile.notificationsSettings.notifyWhenFields) {
          this.notificationsSettings.notifyWhenFields = { ...this.profile.notificationsSettings.notifyWhenFields }
        }
      }
    },
    setupWhat (type) {
      this.notificationsSettings.notifyAbout = type
      if (type === 'all') {
        this.notificationsSettings.notifyAboutFields = {
          tasks: ['app', 'email'],
          locationupdates: ['app', 'email'],
          notes: ['app', 'email'],
          mentions: ['app', 'email']
        }
      } else {
        if (this.profile.notificationsSettings && this.profile.notificationsSettings.notifyAboutFields) {
          this.notificationsSettings.notifyAboutFields = { ...this.profile.notificationsSettings.notifyAboutFields }
        }
      }
    },
    convertLocaltoUTCTime (time) {
      return moment(time, 'HH:mm').utc().format('HH:mm')
    },
    getWhenDay (day) {
      if (this.notificationsSettings.notifyWhenFields) {
        if (this.notificationsSettings.notifyWhenFields.notifyWhenDays) {
          if (this.notificationsSettings.notifyWhenFields.notifyWhenDays[day]) {
            return 'checkbox_checked'
          }
        }
      }
      return 'checkbox_unchecked'
    },
    closeSettings () {
      this.$emit('emitCloseSettings')
    },
    initialize () {
      const defaultSettings = {
        notifyAbout: 'all', // radio
        notifyAboutFields: {
          tasks: ['app', 'email'],
          locationupdates: ['app', 'email'],
          notes: ['app', 'email'],
          mentions: ['app', 'email']
        },
        notifyWhen: 'anytime',
        notifyWhenFields: {
          notifyWhenDays: {
            MON: true,
            TUE: true,
            WED: true,
            THU: true,
            FRI: true,
            SAT: true,
            SUN: true
          },
          notifyWhenTime: {
            from: '09:00',
            to: '17:00'
          },
          notifyWhenTimeUTC: {
            from: '09:00',
            to: '17:00'
          },
          notifyWhenTimezone: this.timezone
        },
        updatedOn: moment().toDate()
      }
      this.notificationsSettings = { ...defaultSettings }
      if (this.profile) {
        if (this.profile.notificationsSettings) {
          this.notificationsSettings = { ...defaultSettings, ...this.profile.notificationsSettings }
        }
      }
    }
  },
  watch: {
    'notificationsSettings.notifyWhenFields': {
      handler () {
        if (this.notificationsSettings) {
          if (this.notificationsSettings.notifyWhenFields) {
            if (!this.notificationsSettings.notifyWhenFields.notifyWhenTimeUTC) {
              this.notificationsSettings.notifyWhenFields.notifyWhenTimeUTC = {}
            }
            this.notificationsSettings.notifyWhenFields.notifyWhenTimeUTC.from = this.convertLocaltoUTCTime(this.notificationsSettings.notifyWhenFields.notifyWhenTime.from)
            this.notificationsSettings.notifyWhenFields.notifyWhenTimeUTC.to = this.convertLocaltoUTCTime(this.notificationsSettings.notifyWhenFields.notifyWhenTime.to)
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.notification-settings {
  display: flex;
  flex-direction: column;
  .settings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .mdc-text {
      font-weight: 800;
      font-size: 16px;
      color: #404040;
    }
  }
  .about-what {
    color: #b10000;
    padding: 0 30px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .fields {
    padding: 20px 30px;
    .all-or-some-types {
      > .mdc-text {
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
      }
      .all-or-some-radios {
        margin-top: 10px;
        .mdc-radio {
          height: 16px;
          width: 16px;
          padding: 10px 10px 10px 0;
          &__background {
            height: 16px;
            width: 16px;
          }
          &__outer-circle {
            border-color: rgba(149, 158, 167, 0.9) !important;
            border: 1px solid rgba(149, 158, 167, 0.9);
          }
          &__inner-circle{
            border-width: 8px;
          }
        }
        .mdc-radio-wrapper {
          label {
            color: #000;
            font-size: 14px;
          }
        }
      }
    }
    .notify-some-types {
      margin-top: 20px;
      > .mdc-text {
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
      }
      .types-and-places {
        margin-top: 10px;
        .headings {
          display: flex;
          align-items: center;
          padding: 5px 0;
          border-bottom: 1px dashed;
          .mdc-text {
            font-weight: 600;
          }
          .heading-text {
            display: flex;
            align-items: center;
            flex: 75%;
          }
          .where-text {
            display: flex;
            align-items: center;
            flex: 50%;
            justify-content: space-evenly;
          }
        }
        .types-and-places-fields {
          display: flex;
          flex-direction: column;
          .notify-type {
            display: flex;
            align-items: center;
            padding: 5px 5px;
            .mdc-text {
              font-weight: 600;
            }
            .heading-text {
              display: flex;
              align-items: center;
              flex: 75%;
            }
            .checkboxes {
              display: flex;
              align-items: center;
              flex: 50%;
              justify-content: space-evenly;
              .app-type-check {
                opacity: 0.4;
                pointer-events: none;
              }
              img {
                height: 15px;
                width: 15px;
              }
            }
          }
        }
      }
    }
  }
  .notify-how {
    color: #b10000;
    padding: 0 30px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .notify-when {
    padding: 0 30px;

    .notify-when-radios {
        margin-top: 10px;
        .mdc-radio {
          height: 16px;
          width: 16px;
          padding: 10px 10px 10px 0;
          &__background {
            height: 16px;
            width: 16px;
          }
          &__outer-circle {
            border-color: rgba(149, 158, 167, 0.9) !important;
            border: 1px solid rgba(149, 158, 167, 0.9);
          }
          &__inner-circle{
            border-width: 8px;
          }
        }
        .mdc-radio-wrapper {
          label {
            color: #000;
            font-size: 14px;
          }
        }
    }
  }
  .timezone-help-info {
      margin-top: 5px;
      font-size: 10px;
      color: #404040;
      font-weight: 400;
      margin-right: 3px;
  }
  .notify-between-row {
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
    width:100%;
    margin-top:20px;
    margin-bottom:20px;

    &.time {
      input {
        border-radius: 6px;
        border: 1px solid silver;
        padding: 5px;
      }
      span {
        margin:10px;
      }
    }

    .notify-between-day {
      display:flex;
      width:75px;
      .checkboxes {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-right:10px;
        margin-left: 10px;
        img {
          height: 15px;
          width: 15px;
        }
      }
    }
  }
  .notify-how-types {
    .type {
      display: flex;
      align-items: center;
      padding: 5px 30px;
      *:not(:last-child) {
        margin-right: 5px;
      }
      .mdc-text {
        font-weight: 400;
      }
      .icon-wrapper {
        height: 15px;
        width: 15px;
      }
    }
  }
  .save-settings-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    .save-button {
      background-color: #00498D;
      &.mdc-button--disabled {
        background-color: rgba(0,0,0,.12);
        pointer-events: none;
      }
    }
  }
  &.settings {
    .about-what {
      padding: 0;
    }
    .fields {
      padding: 20px 0;
    }
    .notify-how {
      padding: 0;
    }
    .notify-how-types {
      .type {
        padding: 5px 0;
      }
    }
    .notify-when {
      padding: 0;
    }
    .notify-between-row {
      padding: 0;
    }
  }
}
</style>
