<template>
  <div class="planner" v-if="schedules">
    <div class="planner-scrim" v-if="showCalendar" @click="toggleCalendar('fromScrim')"></div>
    <section class="days-wrapper" :class="windowWidth <= 1344 ? 'count-days-vertical': 'count-days-horizontal'">
      <section class="planner-days-section">
        <div class="planner-week-day-ranges">
          <div class="week-range">
            <base-svg-icon name="thin-left-arrow-grey" class="week-nav-icon" @click.native="goBackward()" clickable />
            <span class="number-range-wrapper" @click="toggleCalendar()">
              <mdc-text class="week-number" tag="span" :class="[isSelectedDayPastWeek() ? 'past' : '', isSelectedDayFutureWeek() ? 'future': '']"> {{ windowWidth > 1195 ? 'WEEK' : 'W' }} {{ currentWeek.weekNumber }} </mdc-text>
              <mdc-text class="week-dates" tag="span"> {{ currentWeek.firstDay.format('D') }} - {{currentWeek.lastDay.format('D') }} {{ windowWidth > 1195 ? currentWeek.lastDay.format('MMMM YYYY').toUpperCase() : currentWeek.lastDay.format('MMM YYYY').toUpperCase() }}</mdc-text>
              <calendar
                v-if="showCalendar"
                class="planner-calendar"
                :id="'planner-calendar' + showCalendar"
                :displayCalendar="showCalendar"
                @selected="dateSelected"
                :resetDates="resetDates" />
            </span>
            <base-svg-icon name="thin-right-arrow-grey" class="week-nav-icon" @click.native="goForward()" clickable />
          </div>
          <div class="week-nav-buttons">
            <mdc-button aria-label="this-week" outlined @click.native="weekClick('this')">
              <mdc-text typo="caption" tag="span">THIS WEEK</mdc-text>
            </mdc-button>
            <mdc-button aria-label="next-week" outlined @click.native="weekClick('next')">
              <mdc-text typo="caption" tag="span">NEXT WEEK</mdc-text>
            </mdc-button>
          </div>
        </div>
        <div class="day-nav">
          <base-svg-icon name="thin-left-arrow-grey" class="day-nav-icon" @click.native="goToPreviousDay()" clickable />
          <div class="day-buttons">
            <div
              :class="[ isDropTarget(day) ? 'highlight' : null, isToday(day) ? 'today' : null, isSelectedDay(day) ? 'selected' : null, isSelectedDayPast() ? 'past' : '', isSelectedDayFuture() ? 'future': '']"
              class="day-name clickable" v-for="day in datesOfWeek" :key="day + 'day-name'"
              @dragenter.prevent="setDropTarget(day, $event)"
              @dragover.prevent
              @dragleave="setDropTarget(false)"
              @drop.prevent="dayDrop"
              @click="onDaySelection(day)">
              <mdc-text style="pointer-events: none;" tag="span" typo="caption">{{ getDateFormat(day) }}</mdc-text>
            </div>
          </div>
          <base-svg-icon name="thin-right-arrow-grey" class="day-nav-icon" @click.native="goToNextDay()" clickable />
        </div>
      </section>
    </section>
    <section class="planner-details-section">
      <section class="day-date-task-label">
        <div class="day-date">
          <div class="day-prefix" v-if="selectedDayPrefix && (selectedDayPrefix.toLowerCase() === 'today' || selectedDayPrefix.toLowerCase() === 'yesterday' || selectedDayPrefix.toLowerCase() === 'tomorrow')">
            <mdc-text tag="span"
              class="prefix-text"
              :class="[selectedDayPrefix && selectedDayPrefix.toLowerCase() === 'today' ? 'today' : null,
              selectedDayPrefix && (selectedDayPrefix.toLowerCase() === 'today' || selectedDayPrefix.toLowerCase() === 'yesterday' || selectedDayPrefix.toLowerCase() === 'tomorrow') ? 'prefix-day' : 'day-date']">{{ selectedDayPrefix }}</mdc-text>
            <mdc-text tag="span" class="dates-of-prefix">{{ selectedDay.format('ddd, D MMM') }}</mdc-text>
          </div>
          <div class="past-date" v-else-if="inPast(selectedDay)">
            <mdc-text class="day" tag="span" typo="body1">{{ selectedDay.format('dddd')}}</mdc-text>
            <mdc-text class="date" tag="span">{{ selectedDay.format('D MMM') }}</mdc-text>
          </div>
          <div class="future-date" v-else-if="inFuture(selectedDay)">
            <mdc-text class="date" tag="span" typo="body1">{{ selectedDay.format('D')}}</mdc-text>
            <mdc-text class="day" tag="span" typo="body2">{{ selectedDay.format('dddd')}}</mdc-text>
          </div>
        </div>
        <!-- <div class="taskgroup-footer">
          <mdc-button class="view-all-button" @click.native="viewAllTaskClick()">
            <mdc-text typo="caption" tag="span" >All tasks</mdc-text>
            <base-svg-icon class="icon" name="bluegray-arrow-right" />
          </mdc-button>
        </div> -->
      </section>
      <task-group
        v-if="!inPast(selectedDay)"
        :dragClone="true"
        dropFunction="updateWhen"
        limitTasksDisplay
        showTaskTabs
        :composerView="composerView"
        :tasks="currentFiltered"
        :location="currentWeek.scheduleDetails && currentWeek.scheduleDetails.myWorkingFrom"
        selfAssign
        :assignDate="selectedDay"
        :detail="selectedDay"
        onlyNew></task-group>
      <div class="recommendation-change-content" v-if="lastRecommendationUnread">
        <mdc-text class="change-status" tag="span" typo="caption">{{lastRecommendationStatus}}</mdc-text>
        <mdc-text class="change-text" tag="span" typo="caption">{{lastRecommendationText}}</mdc-text>
        <mdc-text class="clear-all" tag="span" @click.native="markAllAsRead()" typo="caption">Clear all</mdc-text>
        <base-svg-icon name="cross-thin" class="bestday-recomm" @click.native="markAsRead(lastRecommendation)" clickable/>
      </div>
      <member-request-note :displayFor="'planner'"/>
      <team-invite-note :displayFor="'planner'"/>
      <section class="all-day-meetings" v-if="allDayMeetings.length > 0">
        <meeting-event allDay :meeting="meeting" v-for="meeting in allDayMeetings" :key="meeting.id + 'all-day'" :scheduleDate="selectedDay" />
      </section>
      <section v-if="inPast(selectedDay)" class="taskgroup-wrapper">
        <task-group
        limitTasksDisplay
        :composerView="composerView"
        :tasks="filterSelfTasksPast(tasks, selectedDay)"
        :location="currentWeek.scheduleDetails && currentWeek.scheduleDetails.myWorkingFrom"
        selfAssign
        :assignDate="selectedDay"
        disableAdd
        :date="selectedDay"></task-group>
      </section>
      <section v-else-if="isToday(selectedDay)" class="taskgroup-wrapper">
        <task-group
        disableAdd
        :dragClone="true"
        dropFunction="updateWhen"
        limitTasksDisplay
        showTaskTabs
        :composerView="composerView"
        :tasks="currentFiltered"
        :location="currentWeek.scheduleDetails && currentWeek.scheduleDetails.myWorkingFrom"
        selfAssign
        :assignDate="selectedDay"
        :detail="selectedDay"></task-group>
      </section>
      <section v-else class="taskgroup-wrapper">
        <task-group
        disableAdd
        :dragClone="true"
        dropFunction="updateWhen"
        limitTasksDisplay
        showTaskTabs
        :composerView="composerView"
        :tasks="futureFiltered"
        :location="currentWeek.scheduleDetails && currentWeek.scheduleDetails.myWorkingFrom"
        selfAssign
        :assignDate="selectedDay"
        :detail="selectedDay"></task-group>
      </section>
    </section>
    <section class="planner-meetings-section" :class="!showCalendarConnect && meetings ? 'meetings' : ''">
      <calendar-connect-prompt></calendar-connect-prompt>
      <calendar-connect v-if="showCalendarConnect" displayFor="scheduler" @closeSetupCalendar="closeSetupCalendar"/>
      <meeting v-else-if="selectedDay && meetings" :meetings="meetings" :meetingsRec="meetingsRec" :scheduleDate="selectedDay" :composerView="composerView"/>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import Meeting from './Meeting'
import CalendarConnectPrompt from './CalendarConnectPrompt'
import TaskGroup from './TaskGroup'
import { datesBetween, inFuture, inPast, isToday, sameDay } from '../../utils/time'
import BaseSvgIcon from './BaseSvgIcon.vue'
import CalendarConnect from './CalendarConnect'
import Calendar from './Calendar.vue'
import MemberRequestNote from './MemberRequestNote.vue'
import TeamInviteNote from './TeamInviteNote.vue'
import { getMeetingForDate } from '../../utils/meetings'
import MeetingEvent from './MeetingEvent.vue'
import { getResponseIconName } from '../../utils/profile'

export default {
  name: 'Scheduler',
  components: {
    Meeting,
    TaskGroup,
    BaseSvgIcon,
    Calendar,
    CalendarConnect,
    CalendarConnectPrompt,
    MemberRequestNote,
    TeamInviteNote,
    MeetingEvent
  },
  props: {
    schedulerFullScreen: {
      type: Boolean,
      default: false
    },
    enableSetupCalendarInScheduler: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    composerView: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    ...mapState([
      'profile',
      'setupCalendarLater',
      'appDate',
      'plannerWeekDetail'
    ]),
    ...mapState({
      meetings: state => state.teams.meetings,
      meetingsRec: state => state.teams.meetingsRec,
      tasks: state => state.teams.tasks,
      taskPlans: state => state.teams.taskPlans,
      schedules: state => state.teams.schedules,
      lastRecommendation: state => state.teams.lastRecommendation,
      notifications: state => state.teams.notifications
    }),
    ...mapGetters({
      getTaskById: 'teams/getTaskById'
    }),
    unreadNotifications () {
      return this.notifications.filter((notif) => !notif.read)
    },
    allDayMeetings () {
      const allMeetings = getMeetingForDate(this.selectedDay, this.meetings, this.meetingsRec)
      return allMeetings.filter(meeting => meeting.isAllDay)
    },
    showCalendarConnect () {
      const isCurrentDay = this.isSelectedDay(this.currentDate)
      const msConnected = this.profile.msEmail
      const googleConnected = this.profile.googleEmail
      // Is current day + neither google or ms connected + setupLater not pressed  OR  presed to setup Calender in scheduler
      return (isCurrentDay && !msConnected && !googleConnected && !this.setupCalendarLater) || this.enableSetupCalendarInScheduler
    },
    unplannedTasks () {
      const unplanned = this.tasks.filter((task) => {
        return (task.isPlanPresent && !task.isPlanPresent[this.profile.id]) && task.text !== '<p></p>' && (task.selfAssignedTo === this.profile.id || task.userId === this.profile.id || task.shareUsers.includes(this.profile.id)) && !task.completed
      })
      return unplanned
    },
    assignedTasks () {
      return this.unplannedTasks.filter((task) => (task.shareUsers && task.shareUsers.includes(this.profile.id)))
    },
    currentDate () {
      return moment(this.appDate).toDate()
    },
    lastRecommendationUnread () {
      if (this.lastRecommendation) {
        return !this.lastRecommendation.read
      }
      return false
    },
    lastRecommendationStatus () {
      if (this.lastRecommendation) {
        if (`${this.lastRecommendation.detail}`.startsWith('New')) {
          return 'NEW'
        }
        return 'UPDATED'
      }
      return ''
    },
    lastRecommendationText () {
      if (this.lastRecommendation) {
        if (`${this.lastRecommendation.detail}`.startsWith('New')) {
          return this.lastRecommendation.detail.replace('New -', '')
        }
        return this.lastRecommendation.detail.replace('Updated -', '')
      }
      return ''
    }
  },
  mounted () {
    window.addEventListener('resize', this.getWindowWidth)
    this.getDatesOfWeek()
    this.selectedDay = moment(this.appDate).startOf('day')
    this.selectedDayDiff()
    if (this.taskPlans.length) {
      this.getFilterSelfTasksToday()
      this.getFilterSelfTasksFuture()
    }
  },
  activated () {
    this.getSchedulesByDate()
    this.updateAppDate()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      resetDates: false,
      selectedDayPrefix: null,
      selectedDay: '',
      currentWeek: {
        firstDay: moment().startOf('isoWeek'),
        lastDay: moment().endOf('isoWeek'),
        weekNumber: moment().week(),
        scheduleDetails: []
      },
      datesOfWeek: [],
      showCalendar: false,
      showBestDay: true,
      currentFiltered: [],
      futureFiltered: [],
      dropTarget: false
    }
  },
  methods: {
    ...mapActions([
      'setPlannerWeekDetail',
      'updateAppDate'
    ]),
    ...mapActions({
      updateNotification: 'teams/updateNotification',
      loadSchedules: 'teams/loadSchedules',
      getNextWorkingAt: 'teams/getNextWorkingAt',
      saveTask: 'teams/saveTask',
      saveTaskPlan: 'teams/saveTaskPlan',
      getTaskPlan: 'teams/getTaskPlan',
      saveTaskActivity: 'teams/saveTaskActivity'
    }),
    setDropTarget (day, event) {
      // console.log('scheduler.setDropTarget', day, event)
      this.dropTarget = day
    },
    isDropTarget (day) {
      return this.dropTarget === day
    },
    async dayDrop (event) {
      const planDate = this.dropTarget
      const taskId = event.dataTransfer.getData('taskId')
      if (taskId && planDate) {
        const task = await this.getTaskById(taskId)
        const existingTaskPlan = await this.getTaskPlan({ teamId: this.profile.team, userId: this.profile.id, taskId })
        if (existingTaskPlan[0] && existingTaskPlan[0].planDate) {
          if (moment(existingTaskPlan[0].planDate.toDate()).isSame(planDate, 'date')) {
            return
          }
        }
        const tp = {
          teamId: this.profile.team,
          userId: this.profile.id,
          id: existingTaskPlan[0] ? existingTaskPlan[0].id || false : false,
          taskId: taskId || 'no task id',
          planDate: planDate || '',
          type: ''
        }
        this.saveTaskPlan(tp).then(() => {
          if (planDate) {
            task.isPlanPresent[this.profile.id] = true
          } else {
            task.isPlanPresent[this.profile.id] = false
          }
          const taskUpdates = { isPlanPresent: task.isPlanPresent }
          if (!(task.selfAssignedTo === this.profile.id) && (!task.shareUsers.includes(this.profile.id))) {
            task.shareUsers.push(this.profile.id)
            taskUpdates.shareUsers = task.shareUsers
          }
          this.saveTask({ teamId: this.profile.team, taskId, task: taskUpdates })
        })
      }
      this.dropTarget = false
    },
    getWindowWidth () {
      this.windowWidth = window.innerWidth
    },
    markAsRead (notification) {
      this.updateNotification({ notificationId: notification.id, update: { read: true } })
    },
    markAllAsRead () {
      const notifications = this.unreadNotifications
      notifications.forEach(notification => {
        this.markAsRead(notification)
      })
    },
    isSelectedDayPast () {
      return inPast(this.selectedDay)
    },
    isSelectedDayFuture () {
      return inFuture(this.selectedDay)
    },
    isSelectedDayPastWeek () {
      if (this.currentWeek) {
        return moment().isAfter(this.currentWeek.firstDay, 'week')
      }
      return false
    },
    isSelectedDayFutureWeek () {
      if (this.currentWeek) {
        return moment().isBefore(this.currentWeek.firstDay, 'week')
      }
      return false
    },
    closeBestDay () {
      this.showBestDay = false
    },
    toggleCalendar (from) {
      if (from === 'fromScrim' && this.showCalendar) {
        this.showCalendar = false
      } else {
        this.showCalendar = true
      }
    },
    dateSelected (dated) {
      const self = this
      this.selectedDay = dated
      setTimeout(function () { self.showCalendar = false }, 400)
    },
    selectedDayDiff () {
      const diffInNumber = this.selectedDay.diff(moment(this.appDate).startOf('day'), 'days')
      switch (diffInNumber) {
        case 0:
          this.selectedDayPrefix = 'Today'
          break
        case 1:
          this.selectedDayPrefix = 'Tomorrow'
          break
        case -1:
          this.selectedDayPrefix = 'Yesterday'
          break
        default:
          this.selectedDayPrefix = this.selectedDay.format('D, ddd MMM')
          break
      }
    },
    resetToCurrentDay () {
      this.selectedDay = moment(this.appDate).startOf('day')
      this.currentWeek = {
        firstDay: moment().startOf('isoWeek'),
        lastDay: moment().endOf('isoWeek'),
        weekNumber: moment().week(),
        scheduleDetails: []
      }
    },
    getDatesOfWeek () {
      this.datesOfWeek = datesBetween(this.currentWeek.firstDay, this.currentWeek.lastDay)
    },
    getDateFormat (day) {
      if (this.windowWidth < 1345) {
        return moment(day).format('dd D')
      }
      return moment(day).format('ddd D')
    },
    getMeetingDateFormat () {
      return moment(this.selectedDay).format('ddd, DD MMM')
    },
    weekClick (from) {
      if (from === 'this') {
        this.goToToday()
      } else {
        this.currentWeek = {
          firstDay: moment().startOf('isoWeek').add(1, 'weeks'),
          lastDay: moment().startOf('isoWeek').add(1, 'weeks').endOf('isoWeek'),
          weekNumber: moment().add(1, 'weeks').week()
        }
        this.selectedDay = moment().startOf('isoWeek').add(1, 'weeks').startOf('day')
      }
    },
    goToToday () {
      this.currentWeek = {
        firstDay: moment().startOf('isoWeek'),
        lastDay: moment().endOf('isoWeek'),
        weekNumber: moment().week()
      }
      this.selectedDay = moment(this.appDate).startOf('day')
    },
    goToPreviousDay () {
      this.selectedDay = moment(this.selectedDay).startOf('day').subtract(1, 'days')
    },
    goToNextDay () {
      this.selectedDay = moment(this.selectedDay).startOf('day').add(1, 'days')
    },
    closeSetupCalendar () {
      this.$emit('closeSetupCalendar', false)
    },
    filterSelfTasksPast (allTasks, mdate) {
      if (allTasks && mdate) {
        const filterDate = mdate.toDate()
        const tasks = allTasks.filter((task) => {
          if (!task.completedOn) {
            return false
          }
          const completedDate = task.completedOn ? task.completedOn.toDate ? task.completedOn.toDate() : task.completedOn : null
          const ownTask = task.selfAssignedTo === this.profile.id
          const assignedTask = task.assingedIds ? task.assingedIds.includes(this.profile.id) : false
          const completedTask = task.completedBy === this.profile.id
          const isTaskPast = sameDay(completedDate, filterDate)
          return (ownTask || assignedTask || completedTask) && isTaskPast
        })
        return tasks
      }
      return []
    },
    async getFilterSelfTasksToday () {
      const allTasks = [...this.tasks]
      // const mdate = this.selectedDay
      if (allTasks) {
        const filterDate = this.appDate.toDate()
        const predicate = async (task) => {
          const ownTask = task.selfAssignedTo === this.profile.id
          const assignedTask = task.shareUsers ? task.shareUsers.includes(this.profile.id) : false
          if (!ownTask && !assignedTask) {
            return false
          }
          // console.log('scheduler.getFilterSelfTasksToday ID', task.id)
          const dueDate = task.due ? task.due.toDate() : null
          const planType = this.getPlanType(task.id)
          let planDate = false
          if (planType !== 'someday') {
            try {
              planDate = await this.getPlanDate(task.id)
            } catch (e) {
              console.error(`getFilterSelfTasksToday Error for task plan in task ${task.id}`, e)
            }
          }
          const completedDate = task.completedOn ? task.completedOn.toDate ? task.completedOn.toDate() : task.completedOn : null
          const completedToday = completedDate ? sameDay(completedDate, filterDate) : false

          // console.log('scheduler.getFilterSelfTasksToday ID planDate planType dueDate', task.id, planDate, planType, dueDate)

          if (completedToday) {
            return completedToday
          }
          if (completedDate && !completedToday) {
            return false
          }

          if (planType === 'someday') {
            return false
          }

          if (planType === 'remote' || planType === 'office') {
            if (!planDate) {
              return false
            }
          }

          let compareDate = planDate
          if (dueDate) {
            if (!planDate) {
              compareDate = dueDate
            } else {
              if (!inPast(dueDate)) {
                if (dueDate < planDate) {
                  compareDate = dueDate
                }
              }
            }
          }

          const isTaskToday = compareDate ? sameDay(compareDate, filterDate) : false
          const dueInPast = inPast(compareDate) && !task.completed
          // console.log('filterSelfTasksToday', task.id, task.text, planDate, compareDate, filterDate, isTaskToday, dueInPast)
          return (isTaskToday || dueInPast)
        }

        const results = await Promise.all(allTasks.map(predicate))
        const tasks = allTasks.filter((_v, index) => results[index])
        this.currentFiltered = tasks.sort((x, y) => {
          if (x.completed === y.completed) {
            return x.createdOn < y.createdOn ? 1 : -1
          } else {
            return (x.completed === y.completed) ? 0 : x.completed ? 1 : -1
          }
        })
        // console.log('Scheduler.currentFiltered', this.currentFiltered)
      } else {
        this.currentFiltered = []
      }
    },
    async getFilterSelfTasksFuture () {
      const allTasks = [...this.tasks]
      const mdate = this.selectedDay
      if (allTasks && mdate) {
        const filterDate = mdate.toDate()
        const predicate = async (task) => {
          const dueDate = task.due ? task.due.toDate() : null
          let planDate = false
          try {
            planDate = await this.getPlanDate(task.id)
          } catch (e) {
            console.error(`getFilterSelfTasksFuture Error for task plan in task ${task.id}`, e)
          }
          const planType = this.getPlanType(task.id)
          const ownTask = task.selfAssignedTo === this.profile.id
          const assignedTask = task.shareUsers ? task.shareUsers.includes(this.profile.id) : false
          if (!ownTask && !assignedTask) {
            return false
          }

          if (planType === 'remote' || planType === 'office') {
            if (!planDate) {
              return false
            }
          }

          let compareDate = planDate
          if (dueDate) {
            if (!planDate) {
              compareDate = dueDate
            } else {
              if (!inPast(dueDate)) {
                if (dueDate < planDate) {
                  compareDate = dueDate
                }
              }
            }
          }
          if (!compareDate) {
            return false
          }
          // console.log('getFilterSelfTasksFuture:', compareDate, filterDate, sameDay(compareDate, filterDate))
          const isTaskFuture = sameDay(compareDate, filterDate) && !task.completed
          return isTaskFuture
        }

        const results = await Promise.all(allTasks.map(predicate))
        const tasks = allTasks.filter((_v, index) => results[index])
        this.futureFiltered = tasks.sort((x, y) => {
          if (x.completed === y.completed) {
            return x.createdOn < y.createdOn ? 1 : -1
          } else {
            return (x.completed === y.completed) ? 0 : x.completed ? 1 : -1
          }
        })
      } else {
        this.futureFiltered = []
      }
    },
    async getSchedulesByDate () {
      this.loadSchedules({ weekCommencingDate: this.currentWeek.firstDay.toDate() })
    },
    async getPlanDate (taskId) {
      const taskPlan = this.taskPlans.find((plan) => plan.taskId === taskId && plan.userId === this.profile.id)
      if (taskPlan) {
        // console.log('getPlanDate', taskId, JSON.stringify({ planDate: taskPlan.planDate, location: taskPlan.type }))
        if (taskPlan.planDate) {
          return taskPlan.planDate.toDate()
        } else if (taskPlan.type === 'office' || taskPlan.type === 'remote') {
          // find next date of type
          // console.log('getPlanDate getNextWorkingAt', taskId, taskPlan.type)
          const nwa = await this.getNextWorkingAt({ location: taskPlan.type }) || null
          // console.log('getPlanDate getNextWorkingAt Res', nwa)
          return nwa
        }
      } else {
        return null
      }
    },
    getPlanType (taskId) {
      const taskPlan = this.taskPlans.find((plan) => plan.taskId === taskId)
      if (taskPlan) {
        if (taskPlan.type) {
          return taskPlan.type
        }
      }
      return null
    },
    isSelectedDay (day) {
      return sameDay(this.selectedDay, day)
    },
    inPast (date) {
      return inPast(date)
    },
    inFuture (date) {
      return inFuture(date)
    },
    isToday (date) {
      return isToday(date)
    },
    onDaySelection (date) {
      this.selectedDay = moment(date).startOf('day')
    },
    getWorkingAtClass () {
      if (this.currentWeek.scheduleDetails.myWorkingFrom !== null && this.currentWeek.scheduleDetails.myWorkingFrom !== '' && this.currentWeek.scheduleDetails.myWorkingFrom !== undefined) {
        switch (this.currentWeek.scheduleDetails.myWorkingFrom.toLowerCase()) {
          case 'thirdspace':
            return 'thirdspace-icon'
          case 'home':
            return 'home-icon'
          case 'office':
            return 'office-icon'
          case 'nonworking':
            return 'nonworking-icon'
          default:
            return ''
        }
      }
    },
    getWorkingAtText () {
      if (this.currentWeek.scheduleDetails.myWorkingFrom !== null && this.currentWeek.scheduleDetails.myWorkingFrom !== '' && this.currentWeek.scheduleDetails.myWorkingFrom !== undefined) {
        switch (this.currentWeek.scheduleDetails.myWorkingFrom.toLowerCase()) {
          case 'home':
            return 'home'
          case 'office':
            return 'office'
          case 'nonworking':
            return 'nonworking'
          case 'thirdspace':
            return 'thirdspace'
          default:
            return ''
        }
      }
    },
    getWorkingAtIcon (workingFromResponse) {
      return getResponseIconName(workingFromResponse)
    },
    isCurrentWeek (day) {
      if (moment(day, 'week').isSame(new Date(), 'week')) {
        return true
      }
      return false
    },
    goForward () {
      this.currentWeek.firstDay = moment(this.currentWeek.firstDay).add(1, 'weeks')
      this.currentWeek.lastDay = moment(this.currentWeek.lastDay).add(1, 'weeks')
      this.currentWeek.weekNumber = moment(this.currentWeek.firstDay).week()

      if (!this.isCurrentWeek(this.currentWeek.firstDay)) {
        this.selectedDay = moment(this.currentWeek.firstDay).startOf('day')
      } else {
        this.selectedDay = moment(this.appDate).startOf('day')
      }
    },
    goBackward () {
      this.currentWeek.firstDay = moment(this.currentWeek.firstDay).subtract(1, 'weeks')
      this.currentWeek.lastDay = moment(this.currentWeek.lastDay).subtract(1, 'weeks')
      this.currentWeek.weekNumber = moment(this.currentWeek.firstDay).week()

      if (!this.isCurrentWeek(this.currentWeek.firstDay)) {
        this.selectedDay = moment(this.currentWeek.firstDay).startOf('day')
      } else {
        this.selectedDay = moment(this.appDate).startOf('day')
      }
    },
    getTodayYestTomor (day) {
      const dayFormat = day.date()
      let checkDifferenceFromDay
      if (day && moment().isSame(day, 'month')) {
        checkDifferenceFromDay = dayFormat - moment(this.appDate)
      }
      switch (checkDifferenceFromDay) {
        case 0:
          return 'Today'
        case -1:
          return 'Yesterday'
        case 1:
          return 'Tomorrow'
        default:
          return ''
      }
    }
    /* viewAllTaskClick () {
      this.$emit('changeComposerView', 'allTasks', 'ALL TASKS')
      this.$emit('dateToComposer', this.selectedDay)
    } */
  },
  watch: {
    appDate (nd, od) {
      if (nd && od) {
        if (!moment(nd).isSame(od, 'date')) {
          // console.log('appDate day changed')
          this.goToToday()
          if (this.taskPlans.length) {
            this.getFilterSelfTasksToday()
            this.getFilterSelfTasksFuture()
          }
        }
      }
    },
    'currentWeek.firstDay': {
      handler (newf, oldf) {
        this.getSchedulesByDate()
        this.getDatesOfWeek()
      },
      immediate: true,
      deep: true
    },
    tasks () {
      // console.log('Scheduler.watch.tasks')
      if (this.tasks) {
        if (this.taskPlans.length) {
          this.getFilterSelfTasksToday()
          this.getFilterSelfTasksFuture()
        }
      }
    },
    taskPlans () {
      // console.log('Scheduler.watch.taskPlans')
      if (this.taskPlans.length) {
        this.getFilterSelfTasksToday()
        this.getFilterSelfTasksFuture()
      }
    },
    selectedDay: {
      handler (newDay, oldDay) {
        if (this.selectedDay) {
          this.currentWeek = {
            firstDay: moment(this.selectedDay).startOf('isoWeek'),
            lastDay: moment(this.selectedDay).endOf('isoWeek'),
            weekNumber: moment(this.selectedDay).week()
          }
          this.$emit('changeComposerView', 'default', 'COMPOSER', { date: this.selectedDay.toDate() })
          this.selectedDayDiff()
          if (this.taskPlans.length) {
            this.getFilterSelfTasksToday()
            this.getFilterSelfTasksFuture()
          }
          this.setPlannerWeekDetail({
            startOfWeek: moment(this.selectedDay).startOf('isoWeek'),
            endOfWeek: moment(this.selectedDay).endOf('isoWeek'),
            weekNumber: moment(this.selectedDay).week(),
            selectedDay: moment(this.selectedDay).startOf('day'),
            datesOfWeek: this.datesOfWeek
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
$color-grey: #828F9C;
.planner {
  .planner-scrim {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 11;
  }
  .planner-calendar {
    position: absolute;
    z-index: 40;
    top: 40px;
    opacity: 1;
  }
  .days-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .planner-days-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      .planner-week-day-ranges {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        margin-bottom: 10px;
        .week-range {
          display: grid;
          grid-template-columns: 50px minmax(auto, 1fr) 50px;
          .week-nav-icon {
            margin: 0 20px;
          }
          .number-range-wrapper {
            min-height: 30px;
            position: relative;
            cursor: pointer;
            text-transform: uppercase;
            padding: 3px 15px;
            // min-width: 180px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            /* margin-bottom: 5px; */
            background: #FFFFFF;
            border: 0.5px solid rgba(149, 162, 176, 0.5);
            box-sizing: border-box;
            box-shadow: 0px 0px 2px rgb(0 0 0 / 13%);
            border-radius: 4px;
            .week-number {
              font-size: 13px;
              color: #B10000;
              font-weight: 600;
              margin-right: 10px;
              &.future {
                color: #404040;
              }
              &.past {
                color: #828282;
              }
            }
            .week-dates {
              color: $color-grey;
              font-size: 13px;
            }
            .mdc-text {
              white-space: nowrap;
            }
            @media all and (max-width: 1195px) {
              padding: 0 10px;
            }
          }
        }
        .week-nav-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 49px;
          @media all and (max-width: 1280px) {
            margin-right: 20px;
          }
          .mdc-button {
            padding: 0 5px;
            max-width: 110px;
            height: 30px;
            border: 0.5px solid rgba(149, 162, 176, 0.5);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.13);
            border-radius: 4px;
            .mdc-text {
              white-space: nowrap;
              color: $color-grey;
            }
            &:not(:last-of-type) {
              margin-right: 20px;
              @media all and (max-width: 1195px) {
                margin-right: 10px;
              }
            }
            @media all and (max-width: 1280px) {
              width: 85px;
            }
            @media all and (max-width: 1150px) {
              width: unset;
            }
          }
        }
      }
      .day-nav {
        display: flex;
        margin: 0 20px;
        justify-content: space-between;

        .day-nav-icon {
          margin: 0;
          img {
            margin: 0;
          }
          &.selected {
            .mdc-text {
              color: #000;
              font-weight: 700;
            }
          }
        }

        .day-buttons {
          display: flex;
          justify-content: space-between;
          margin: 0 20px;
          flex: 1 0 70px;
          grid-gap: 5px;
          div {
            position: relative;
            padding: 3px;
            max-width: 70px;
            width: 70px;
            height: auto;
            background: #FFFFFF;
            border: 0.5px solid rgba(149, 162, 176, 0.5);
            box-sizing: border-box;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.13);
            border-radius: 4px;
            text-align: center;
            .mdc-text {
              color: $color-grey;
              text-transform: uppercase;
              white-space: nowrap;
            }
            &:last-of-type {
              margin-right: 0;
            }
            &.selected {
              font-weight: 700;
              .mdc-text {
                color: #000;
                font-weight: 700;
              }
            }
            &.past {
              .mdc-text {
                color: #828282;
              }
            }
            &.future {
              .mdc-text {
                color: #404040;
              }
            }
            &.highlight {
              border-color: #6184AD;
              box-shadow: 0 0 5px #6184AD;
              .mdc-text {
                color: #6184AD;
                font-weight: 600;
              }
            }
            /* priority to .selected class */
            &.today {
              .mdc-text {
                color: #B10000;
                font-weight: 700;
              }
            }
          }
          /* priority to .selected class */
          &.today {
            border: 0.5px solid #B10000;
            .mdc-text {
              color: #B10000;
            }
            &.highlight {
              border-color: #6184AD;
              box-shadow: 0 0 5px #6184AD;
              .mdc-text {
                color: #6184AD;
                font-weight: 600;
              }
            }
          }
          @media all and (max-width: 1345px) {
            padding: 3px 0;
          }
          @media all and (max-width: 1280px) {
            min-width: 60px;
          }
          @media all and (max-width: 1150px) {
            min-width: 45px;
          }
          .icon-wrapper {
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .planner-details-section {
    z-index: 2;
    .day-date-task-label {
      margin: 0 20px;
      display: flex;
      align-items: baseline;
      padding-top: 15px;
      justify-content: space-between;
      .day-date {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .day-prefix {
          display: flex;
          align-items: baseline;
          max-height: 24px;
          .prefix-text {
            font-size: 18px;
            text-transform: uppercase;
            color: #404040;
            font-weight: 600;
            margin-right: 10px;
            &.today {
              color:#B10000
            }
            &.highlighted {
              color:#B10000
            }
          }
          .dates-of-prefix {
            font-size: 10px;
            text-transform: uppercase;
            color: #404040;
            font-weight: 600;
            margin-right: 10px;
          }
        }
        .past-date {
          display: flex;
          align-items: baseline;
          color: #828282;
          max-height: 24px;
          .day {
            text-transform: uppercase;
            margin-right: 10px;
            font-weight: 600;
          }
          .date {
            text-transform: uppercase;
            font-size: 10px;
            margin-right: 10px;
            font-weight: 600;
          }
        }
        .future-date {
          display: flex;
          align-items: center;
          color: #404040;
          max-height: 24px;
          .date {
            margin-right: 5px;
            font-weight: 600;
            font-size: 18px;
          }
          .day {
            text-transform: uppercase;
            margin-right: 10px;
            font-weight: 600;
          }
        }
      }
      .taskgroup-footer {
        display:flex;
        align-items: center;
        font-size: 14px;
        color: #333333;
        justify-content: flex-end;
        .view-all-button {
          color: #6184AD;
          text-transform: initial;
          padding-right: 0;
          height: 22px;
          .mdc-text {
            font-weight: 700;
            color: #6184AD;
            margin-right: 5px;
            line-height: 21px;
          }
          &.clicked {
            box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
          }
        }
      }
    }
    .recommendation-change-content {
      margin: 3px 20px 0 20px;
      display: flex;
      align-items: center;
      background: #fffcdd;
      border-radius: 10px;
      padding: 3px 0;
      .change-status {
        // background: #FFE973;
        font-weight: 900;
        font-size: 9px;
        color: #000;
        padding: 4px;
        border-radius: 10px;
        text-align: center;
        line-height: 14px;
        min-width: 30px;
        margin-right: 5px;
        margin-left: 4px;
      }
      .change-text {
        color: #000;
      }
      .clear-all {
        cursor: pointer;
        margin-right: 10px;
        margin-left: auto;
      }
      .icon-wrapper {
        margin-left: 20px;
        margin-right: 10px;
        img {}
      }
    }
  }
  .planner-meetings-section {
    margin-top: 15px;
    position: relative;
    z-index:1;
    &.meetings {
      &:before {
        position: absolute;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.003) 50%, rgba(0, 0, 0, 0.05) 100%);
        content: '';
        height: 40px;
        width: 100%;
      }
      &:after {
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 43.23%, rgba(0, 0, 0, 0.19) 100%);
        content: '';
        height: 30px;
        width: 100%;
      }
    }
  }
  .all-day-meetings {
    margin: 3px 0 0 0;
  }
}
</style>
