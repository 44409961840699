<template>
  <div class="team-wrapper">
      <app-layout key="teamLayout">
        <div class="one-col-layout" slot="layout-content">
          <app-section :editable="false">
            <div class="team-content-wrapper">
              <div class="header-wrapper">
                <header class="team-title">
                  <base-svg-icon name="analytics" />
                  <mdc-text tag="span">ANALYTICS <span class="beta">BETA</span></mdc-text>
                </header>
              </div>
              <section class="team-content">
                <div class="all-stats">
                  <div class="filters stat">
                    <mdc-text tag="span" class="show-data-text">Showing data for</mdc-text>
                    <div v-show="hasAdminTeams && teamSelected" class="switch-details-wrapper">
                      <mdc-button aria-label="filter-val-team" class="team-btn" @click.native="toggleTeamOptions()">
                        <mdc-text class="filter-val-team" typo="caption" tag="span">{{ teamSelected.name }}</mdc-text>
                        <base-svg-icon clickable class="down-arrow" name="arrow-down-4175b1" />
                      </mdc-button>
                      <teams-details class="all-teams-details" v-if="teamOptionsOpen" :teams="userTeamsDetail" @setSelected="setTeamSelected"/>
                    </div>
                    <div class="switch-details-wrapper">
                      <mdc-button aria-label="filter-val-time" class="time-btn" @click.native="toggleTimeOptions()">
                        <mdc-text class="filter-val-time" typo="caption" tag="span">{{ timeSelected }}</mdc-text>
                        <base-svg-icon clickable class="down-arrow" name="arrow-down-4175b1" />
                      </mdc-button>
                      <div class="filter-val-options" role="menu" name="team-options-menu" v-if="timeOptionsOpen">
                        <mdc-text @click.native="setTimeSelected(time)" tag="span" typo="body2" class="option-type clickable" v-for="(time, index) in timeOptions" :key="index" >
                          {{ time }}
                        </mdc-text>
                      </div>
                    </div>
                  </div>
                  <div class="team-stat stat">
                    <mdc-text typo="body2" class="stat-about">
                      Not in the office in over a month
                    </mdc-text>
                    <div class="stat-value">{{notInOfficeMonth}}</div>
                  </div>
                  <div class="team-stat stat">
                    <mdc-text typo="body2" class="stat-about">
                      Most quiet day in the office for team
                    </mdc-text>
                    <div class="stat-value">
                      {{quietDay}}
                    </div>
                  </div>
                  <div class="team-stat stat">
                    <mdc-text typo="body2" class="stat-about">
                      Most frequent day in the office for team
                    </mdc-text>
                    <div class="stat-value">
                      {{busyDay}}
                    </div>
                  </div>
                </div>
                <div class="all-types-charts">
                  <div class="chart-wrapper">
                    <mdc-text tag="span" class="chart-title">Number of days in the office (%)</mdc-text>
                    <LineChart id="daysInOffice" v-if="daysInOfficeChartData && daysInOfficeChartOptions" :chartData="daysInOfficeChartData" :chartOptions="daysInOfficeChartOptions" />
                  </div>
                  <div class="chart-wrapper">
                    <mdc-text tag="span" class="chart-title">Average distribution of team in office by weekday (%)</mdc-text>
                    <BarChart id="teamDist" v-if="teamDistChartData && teamDistChartOptions" :chartData="teamDistChartData" :chartOptions="teamDistChartOptions" enabledDataLabels/>
                  </div>
                  <div class="chart-wrapper">
                    <mdc-text tag="span" class="chart-title">Location utilisation</mdc-text>
                    <div class="chart-filters third">
                      <div class="switch-location">
                        <div class="switch-details-wrapper">
                          <mdc-button aria-label="filter-val-btn" class="name-btn" @click.native="toggleLocationReportingLocOptions()">
                            <mdc-text typo="caption" tag="span" v-html="locationReportingLocSelected.text ? locationReportingLocSelected.text : 'Office Locations'"></mdc-text>
                            <base-svg-icon clickable class="down-arrow" name="arrow-down-4175b1" />
                          </mdc-button>
                          <locations-details lookup @saveOfficeLocation="setLocationReportingLoc" class="all-locations-details" v-if="locationReportingLocOptionsOpen" :date="appDate" hideActivityDetails :locationType="'office'" :showFor="'office'" />
                        </div>
                      </div>
                    </div>
                    <BarChart id="teamDist" v-if="locationReportingChartData && locationReportingChartOptions" :chartData="locationReportingChartData" :chartOptions="locationReportingChartOptions"/>
                  </div>
                </div>
              </section>
            </div>
          </app-section>
        </div>
      </app-layout>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import AppLayout from '../../components/flexibly/AppLayout'
import AppSection from '../../components/flexibly/AppSection'
import BaseSvgIcon from '../../components/flexibly/BaseSvgIcon.vue'
import BarChart from '../../components/flexibly/BarChart'
import LineChart from '../../components/flexibly/LineChart'
import LocationsDetails from '../../components/flexibly/LocationsDetails.vue'
import { getBusinessDays, getBusinessDayMap, getDateRange } from '../../utils/time'
import TeamsDetails from '../../components/flexibly/TeamsDetails.vue'
export default {
  name: 'Team',
  computed: {
    ...mapState([
      'appDate'
    ]),
    ...mapState({
      profile: state => state.profile,
      profiles: state => state.profiles,
      teams: state => state.teams.teams,
      locations: state => state.teams.locations,
      userLocations: state => state.teams.userLocations,
      userTeamsAdmin: state => state.userTeamsAdmin
    }),
    hasAdminTeams () {
      if (this.$workspaceAdmin()) {
        return true
      }
      if (this.userTeamsAdmin.length > 0) {
        return true
      }
      return false
    },
    ready () {
      if (this.teams && this.teamSelected && this.teamProfiles && this.userLocations) {
        return true
      }
      return false
    },
    adminTeams () {
      if (this.teams) {
        if (this.$workspaceAdmin()) {
          return this.teams.map(a => a.id)
        }
        if (this.userTeamsAdmin.length > 0) {
          return this.userTeamsAdmin
        }
      }

      return []
    },
    userTeamsDetail () {
      const teamDetails = [{ id: 'all', name: 'All Teams' }]
      if (this.adminTeams) {
        for (const ut of this.adminTeams) {
          const team = this.teams.filter(t => t.id === ut)
          if (team.length) {
            teamDetails.push({ id: team[0].id, name: team[0].name, members: team[0].members })
          }
        }
      }
      return teamDetails
    },
    teamProfiles () {
      // For Stats
      if (this.teamSelected) {
        if (this.teamSelected.id === 'all') {
          return [...new Set(this.profiles.map(pr => pr.id))]
        }
        return this.teamSelected.members
      }
      return false
    },
    locationReportingLocOptions () {
      return [{ name: 'Office locations' }, ...this.locations].filter(loc => !!loc.name && (loc.type === 'building' || loc.type === 'floor'))
    }
  },
  components: {
    AppLayout,
    AppSection,
    BaseSvgIcon,
    BarChart,
    LineChart,
    LocationsDetails,
    TeamsDetails
  },
  data () {
    return {
      showAddNewteamModal: false,
      notInOfficeMonth: '',
      busyDay: '',
      quietDay: '',

      daysInOfficeChartData: false,
      daysInOfficeChartOptions: false,

      teamDistChartData: false,
      teamDistChartOptions: false,

      locationReportingChartData: false,
      locationReportingChartOptions: false,

      timeOptions: [
        'Last week',
        'Last 30 days',
        'Last month',
        'Last 12 months'
      ],
      timeSelected: 'Last 12 months',
      timeOptionsOpen: false,

      locationReportingLocOptionsOpen: false,
      locationReportingLocSelected: { id: 'all', name: 'Office locations' },

      teamOptionsOpen: false,
      teamSelected: false
    }
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  created () {
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
    document.removeEventListener('keyup', this.handleKeyDown)
  },
  activated () {
    if (!this.userLocations) {
      this.getUserLocations()
    }
    if (this.ready) {
      this.initialize()
    } else {
      this.setupCharts()
    }
  },
  mounted () {
    if (!this.userLocations) {
      this.getUserLocations()
    }
    if (this.ready) {
      this.initialize()
    } else {
      this.setupCharts()
    }
    this.$emit('setNavActive', 'Analytics')
    this.$root.$emit('enableSearch', false)
    document.addEventListener('click', this.handleClickOutside)
    document.addEventListener('keyup', this.handleKeyDown)
  },
  methods: {
    ...mapActions({
      getUserLocations: 'teams/getUserLocations'
    }),
    getTeamProfiles (teamId) {
      if (teamId === 'all') {
        const allProfiles = [...this.profiles, this.profile]
        return [...new Set(allProfiles.map(pr => pr.id))]
      }
      return [...new Set(this.teams.filter(t => t.id === teamId)[0].members)]
    },
    toggleTeamOptions () {
      this.teamOptionsOpen = !this.teamOptionsOpen
      this.locationReportingLocOptionsOpen = false
      this.timeOptionsOpen = false
    },
    toggleTimeOptions () {
      this.timeOptionsOpen = !this.timeOptionsOpen
      this.teamOptionsOpen = false
      this.locationReportingLocOptionsOpen = false
    },
    toggleLocationReportingLocOptions () {
      this.locationReportingLocOptionsOpen = !this.locationReportingLocOptionsOpen
      this.timeOptionsOpen = false
      this.teamOptionsOpen = false
    },
    getTeamName (team) {
      if (team) {
        if (team.name) {
          return team.name
        }
        const teamId = team.id
        if (teamId === 'All') {
          return 'All Teams'
        }
        if (this.teams) {
          const ft = this.teams.filter((team) => team.id === teamId)[0] || {}
          return `${ft.name || ''}`
        }
      }
      return ''
    },
    setTeamSelected (val) {
      this.teamSelected = val
      this.teamOptionsOpen = false
      this.generateStatData()
      this.generateChartData()
    },
    setTimeSelected (time) {
      this.timeSelected = time
      this.timeOptionsOpen = false
      this.generateChartData()
    },
    setLocationReportingLoc (val, showFor, text, type, location) {
      this.locationReportingLocSelected = location
      this.locationReportingLocOptionsOpen = false
      this.generateLocationReportingChart()
    },
    handleClickOutside (event) {
      if (!event.target.closest('.switch-details-wrapper')) {
        this.teamOptionsOpen = false
        this.timeOptionsOpen = false
        this.locationReportingLocOptionsOpen = false
      }
    },
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        this.teamOptionsOpen = false
        this.timeOptionsOpen = false
        this.locationReportingLocOptionsOpen = false
      }
    },
    initialize () {
      if (this.teamProfiles) {
        this.generateStatData()
        this.generateChartData()
      }
    },
    generateStatData () {
      if (this.userLocations && this.teamProfiles) {
        const userLocations = this.userLocations || []
        const officeDays = userLocations.filter(ul => ul.location === 'office')
        const dayMap = {
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0
        }
        const monthAgo = moment().add(-1, 'month')
        const userIds = []
        const profileMap = {
        }
        for (const profileId of this.teamProfiles) {
          userIds.push(profileId)
          profileMap[profileId] = false
        }
        for (const od of officeDays) {
          const odDate = moment(od.dateStr)
          const odDay = odDate.format('ddd').toLowerCase()
          if (od.users) {
            for (const user of Object.keys(od.users)) {
              if (userIds.includes(user)) {
                dayMap[odDay] = dayMap[odDay] + 1
                if (odDate.isAfter(monthAgo)) {
                  if (!profileMap[user]) {
                    profileMap[user] = odDate
                  }
                }
              }
            }
          }
        }

        // Busy and Quiet days
        let maxDay = false
        let minDay = false
        for (const day of Object.keys(dayMap)) {
          if (!maxDay) {
            maxDay = { day, value: dayMap[day] }
          } else {
            if (maxDay.value < dayMap[day]) {
              maxDay = { day, value: dayMap[day] }
            }
          }
          if (!minDay) {
            minDay = { day, value: dayMap[day] }
          } else {
            if (minDay.value > dayMap[day]) {
              minDay = { day, value: dayMap[day] }
            }
          }
        }
        this.busyDay = '-'
        this.quietDay = '-'

        if (maxDay.value > 0) {
          this.busyDay = maxDay.day.toUpperCase()
          this.quietDay = minDay.day.toUpperCase()
        }

        // Profiles not in for a month
        let notIn = 0
        if (profileMap) {
          for (const pr of Object.keys(profileMap)) {
            if (!profileMap[pr]) {
              notIn++
            }
          }
        }
        this.notInOfficeMonth = notIn
      }
    },
    generateChartData () {
      this.generateTeamDistributionChart()
      this.generateDaysInOfficeChart()
      this.generateLocationReportingChart()
    },
    setupCharts () {
      this.setupTeamDistributionChart()
      this.setupDaysInOfficeChart()
      this.setupLocationReportingChart()
    },
    setupTeamDistributionChart () {
      this.teamDistChartData = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        datasets: [
          {
            label: 'Team',
            backgroundColor: '#82C5FF',
            data: [0, 0, 0, 0, 0]
          },
          {
            label: 'Company wide',
            backgroundColor: '#00DCC8',
            data: [0, 0, 0, 0, 0]
          }
        ]
      }
      this.teamDistChartOptions = {
        layout: {
          padding: {
            right: 30
          }
        },
        responsive: true,
        aspectRatio: '1|2',
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
              display: false
            },
            ticks: {
              color: '#828282',
              font: {
                weight: 600,
                size: 10
              }
            }
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              font: {
                weight: 600,
                size: 10
              },
              color: '#828282',
              beginAtZero: true,
              stepSize: 12.5,
              callback: function (val, index) {
                return index % 4 === 0 ? val : ''
              }
            },
            title: {
              padding: 10,
              color: '#828282',
              font: {
                size: 10,
                weight: 400
              },
              display: true,
              text: 'Percentage'
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                size: 12,
                weight: 400
              },
              color: '#404040',
              padding: 20,
              boxWidth: 20,
              boxHeight: 10
            }
          }
        }
      }
    },
    setupDaysInOfficeChart () {
      this.daysInOfficeChartData = {
        labels: ['', '', '', '', '', ''],
        datasets: [
          {
            label: '0 days',
            backgroundColor: '#5485B4',
            borderColor: '#5485B4',
            fill: true,
            data: [20, 20, 20, 20, 20, 20],
            tension: 0.1
          },
          {
            label: '1 day',
            backgroundColor: '#87C4FF',
            borderColor: '#87C4FF',
            fill: true,
            data: [20, 20, 20, 20, 20, 20],
            tension: 0.1
          },
          {
            label: '2 days',
            backgroundColor: '#B7D7EC',
            borderColor: '#B7D7EC',
            fill: true,
            data: [20, 20, 20, 20, 20, 20],
            tension: 0.1
          },
          {
            label: '3 days',
            backgroundColor: '#C4E7FD',
            borderColor: '#C4E7FD',
            fill: true,
            data: [20, 20, 20, 20, 20, 20],
            tension: 0.1
          },
          {
            label: '4 days',
            backgroundColor: '#DDF2FF',
            borderColor: '#DDF2FF',
            fill: true,
            data: [20, 20, 20, 20, 20, 20],
            tension: 0.1
          },
          {
            label: '5 days',
            backgroundColor: '#ECF5FE',
            borderColor: '#ECF5FE',
            fill: true,
            data: [20, 20, 20, 20, 20, 20],
            tension: 0.1
          }
        ]
      }
      this.daysInOfficeChartOptions = {
        layout: {
          padding: {
            right: 30
          }
        },
        responsive: true,
        aspectRatio: '1|2',
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
              display: false
            },
            ticks: {
              color: '#828282',
              font: {
                weight: 600,
                size: 10
              }
            }
          },
          y: {
            min: 0,
            max: 100,
            stacked: true,
            ticks: {
              font: {
                weight: 600,
                size: 10
              },
              color: '#828282',
              stepSize: 12.5,
              callback: function (val, index) {
                return index % 4 === 0 ? val : ''
              }
            },
            title: {
              padding: 10,
              color: '#828282',
              font: {
                size: 10,
                weight: 400
              },
              display: true,
              text: 'Percentage'
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                size: 12,
                weight: 400
              },
              color: '#404040',
              padding: 20,
              boxWidth: 20,
              boxHeight: 10
            }
          }
        }
      }
    },
    setupLocationReportingChart () {
      this.locationReportingChartData = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        datasets: [
          {
            label: 'Location',
            backgroundColor: '#82C5FF',
            data: [0, 0, 0, 0, 0]
          },
          {
            label: 'Average',
            backgroundColor: '#00DCC8',
            data: [0, 0, 0, 0, 0]
          }
        ]
      }
      this.locationReportingChartOptions = {
        layout: {
          padding: {
            right: 30
          }
        },
        responsive: true,
        aspectRatio: '1|2',
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
              display: false
            },
            ticks: {
              color: '#828282',
              font: {
                weight: 600,
                size: 10
              }
            }
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              font: {
                weight: 600,
                size: 10
              },
              color: '#828282',
              beginAtZero: true,
              stepSize: 12.5,
              callback: function (val, index) {
                return index % 4 === 0 ? val : ''
              }
            },
            title: {
              padding: 10,
              color: '#828282',
              font: {
                size: 10,
                weight: 400
              },
              display: true,
              text: 'Percentage'
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                size: 12,
                weight: 400
              },
              color: '#404040',
              padding: 20,
              boxWidth: 20,
              boxHeight: 10
            }
          }
        }
      }
    },
    generateLocationReportingChart () {
      // console.log('Team.generateLocationReportingChart', this.teamSelected.name, '|', this.timeSelected, '|', this.locationReportingLocSelected.text)
      const teamProfiles = this.getTeamProfiles(this.teamSelected.id)
      const { from, to } = getDateRange(this.timeSelected)
      const daysInTime = getBusinessDayMap(from, to)
      const locationCount = this.locationReportingLocOptions.length - 1
      let locationSelCount = 1
      if (this.locationReportingLocSelected.id === 'all') {
        locationSelCount = locationCount
      }

      const dayMapTeam = {
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0
      }
      const dayMapAvg = {
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0
      }

      const userLocations = this.userLocations || []
      const officeDays = userLocations.filter(ul => ul.location === 'office')
      for (const od of officeDays) {
        const odDate = moment(od.dateStr)
        if (odDate.isBetween(moment(from), moment(to))) {
          const odDay = odDate.format('ddd').toLowerCase()
          if (od.users) {
            for (const odu of Object.keys(od.users)) {
              if (teamProfiles.includes(odu)) {
                const oduLoc = od.users[odu]

                if (this.locationReportingLocSelected.id === 'all') {
                  dayMapTeam[odDay] = dayMapTeam[odDay] + 1
                } else {
                  if (this.locationReportingLocSelected.id === oduLoc) {
                    dayMapTeam[odDay] = dayMapTeam[odDay] + 1
                  }
                }
                dayMapAvg[odDay] = dayMapAvg[odDay] + 1
              }
            }
          }
        }
      }

      const getPercentageUtilization = (count, locations, dayCount) => {
        const percentUtil = (count / (dayCount * locations)) * 100
        return percentUtil.toFixed(1)
      }

      const getAverageUtilization = (count, dayCount) => {
        const avgUtil = count / dayCount
        return avgUtil.toFixed(1)
      }

      const teamData = [
        getPercentageUtilization(dayMapTeam.mon, locationSelCount, daysInTime.Mon),
        getPercentageUtilization(dayMapTeam.tue, locationSelCount, daysInTime.Mon),
        getPercentageUtilization(dayMapTeam.wed, locationSelCount, daysInTime.Mon),
        getPercentageUtilization(dayMapTeam.thu, locationSelCount, daysInTime.Mon),
        getPercentageUtilization(dayMapTeam.fri, locationSelCount, daysInTime.Mon)
      ]
      const avgData = [
        getPercentageUtilization(dayMapAvg.mon, locationCount, daysInTime.Mon),
        getPercentageUtilization(dayMapAvg.tue, locationCount, daysInTime.Tue),
        getPercentageUtilization(dayMapAvg.wed, locationCount, daysInTime.Wed),
        getPercentageUtilization(dayMapAvg.thu, locationCount, daysInTime.Thu),
        getPercentageUtilization(dayMapAvg.fri, locationCount, daysInTime.Fri)
      ]

      const perCount = [
        [
          getAverageUtilization(dayMapTeam.mon, daysInTime.Mon),
          getAverageUtilization(dayMapTeam.tue, daysInTime.Tue),
          getAverageUtilization(dayMapTeam.wed, daysInTime.Wed),
          getAverageUtilization(dayMapTeam.thu, daysInTime.Thu),
          getAverageUtilization(dayMapTeam.fri, daysInTime.Fri)
        ],
        [
          getAverageUtilization(dayMapAvg.mon, daysInTime.Mon),
          getAverageUtilization(dayMapAvg.tue, daysInTime.Tue),
          getAverageUtilization(dayMapAvg.wed, daysInTime.Wed),
          getAverageUtilization(dayMapAvg.thu, daysInTime.Thu),
          getAverageUtilization(dayMapAvg.fri, daysInTime.Fri)
        ]
      ]

      this.locationReportingChartData = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        datasets: [
          {
            label: 'Location',
            backgroundColor: '#82C5FF',
            data: teamData
          },
          {
            label: 'Average',
            backgroundColor: '#00DCC8',
            data: avgData
          }
        ]
      }
      this.locationReportingChartOptions = {
        layout: {
          padding: {
            right: 30
          }
        },
        responsive: true,
        aspectRatio: '1|2',
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
              display: false
            },
            ticks: {
              color: '#828282',
              font: {
                weight: 600,
                size: 10
              }
            }
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              font: {
                weight: 600,
                size: 10
              },
              color: '#828282',
              beginAtZero: true,
              stepSize: 12.5,
              callback: function (val, index) {
                return index % 4 === 0 ? val : ''
              }
            },
            title: {
              padding: 10,
              color: '#828282',
              font: {
                size: 10,
                weight: 400
              },
              display: true,
              text: 'Percentage'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label (context) {
                const countValue = perCount[context.datasetIndex][context.dataIndex]
                const perValue = context.parsed.y
                const label = context.dataset.label || ''
                return ` ${label}: ${countValue} (${perValue}%)`
              }
            }
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                size: 12,
                weight: 400
              },
              color: '#404040',
              padding: 20,
              boxWidth: 20,
              boxHeight: 10
            }
          }
        }
      }
    },
    generateTeamDistributionChart () {
      // console.log('Team.generateTeamDistributionChart', this.teamSelected.name, '|', this.timeSelected)

      const companyProfiles = this.getTeamProfiles('all')
      const companyMax = companyProfiles.length || 1
      const teamProfiles = this.getTeamProfiles(this.teamSelected.id)
      const teamMax = teamProfiles.length || 1

      const { from, to } = getDateRange(this.timeSelected)
      const daysInTime = getBusinessDayMap(from, to)
      const userLocations = this.userLocations || []
      const officeDays = userLocations.filter(ul => ul.location === 'office')

      const dayMapTeam = {
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0
      }
      const dayMapComp = {
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0
      }

      for (const od of officeDays) {
        const odDate = moment(od.dateStr)
        if (odDate.isBetween(moment(from), moment(to))) {
          const odDay = odDate.format('ddd').toLowerCase()
          if (od.users) {
            for (const odu of Object.keys(od.users)) {
              if (teamProfiles.includes(odu)) {
                dayMapTeam[odDay] = dayMapTeam[odDay] + 1
              }
              dayMapComp[odDay] = dayMapComp[odDay] + 1
            }
          }
        }
      }

      const getPercentageUtilization = (count, members, dayCount) => {
        const percentUtil = (count / (dayCount * members)) * 100
        return percentUtil.toFixed(1)
      }

      const getAverageUtilization = (count, dayCount) => {
        const avgUtil = count / dayCount
        return avgUtil.toFixed(1)
      }

      const teamData = [
        getPercentageUtilization(dayMapTeam.mon, teamMax, daysInTime.Mon),
        getPercentageUtilization(dayMapTeam.tue, teamMax, daysInTime.Tue),
        getPercentageUtilization(dayMapTeam.wed, teamMax, daysInTime.Wed),
        getPercentageUtilization(dayMapTeam.thu, teamMax, daysInTime.Thu),
        getPercentageUtilization(dayMapTeam.fri, teamMax, daysInTime.Fri)
      ]
      const compData = [
        getPercentageUtilization(dayMapComp.mon, companyMax, daysInTime.Mon),
        getPercentageUtilization(dayMapComp.tue, companyMax, daysInTime.Tue),
        getPercentageUtilization(dayMapComp.wed, companyMax, daysInTime.Wed),
        getPercentageUtilization(dayMapComp.thu, companyMax, daysInTime.Thu),
        getPercentageUtilization(dayMapComp.fri, companyMax, daysInTime.Fri)
      ]

      const perCount = [
        [
          getAverageUtilization(dayMapTeam.mon, daysInTime.Mon),
          getAverageUtilization(dayMapTeam.tue, daysInTime.Tue),
          getAverageUtilization(dayMapTeam.wed, daysInTime.Wed),
          getAverageUtilization(dayMapTeam.thu, daysInTime.Thu),
          getAverageUtilization(dayMapTeam.fri, daysInTime.Fri)
        ],
        [
          getAverageUtilization(dayMapComp.mon, daysInTime.Mon),
          getAverageUtilization(dayMapComp.tue, daysInTime.Tue),
          getAverageUtilization(dayMapComp.wed, daysInTime.Wed),
          getAverageUtilization(dayMapComp.thu, daysInTime.Thu),
          getAverageUtilization(dayMapComp.fri, daysInTime.Fri)
        ]
      ]

      this.teamDistChartData = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        datasets: [
          {
            label: 'Team',
            backgroundColor: '#82C5FF',
            data: teamData
          },
          {
            label: 'Company wide',
            backgroundColor: '#00DCC8',
            data: compData
          }
        ]
      }
      this.teamDistChartOptions = {
        layout: {
          padding: {
            right: 30
          }
        },
        responsive: true,
        aspectRatio: '1|2',
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
              display: false
            },
            ticks: {
              color: '#828282',
              font: {
                weight: 600,
                size: 10
              }
            }
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              font: {
                weight: 600,
                size: 10
              },
              color: '#828282',
              beginAtZero: true,
              stepSize: 12.5,
              callback: function (val, index) {
                return index % 4 === 0 ? val : ''
              }
            },
            title: {
              padding: 10,
              color: '#828282',
              font: {
                size: 10,
                weight: 400
              },
              display: true,
              text: 'Percentage'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label (context) {
                const countValue = perCount[context.datasetIndex][context.dataIndex]
                const perValue = context.parsed.y
                const label = context.dataset.label || ''
                return ` ${label}: ${countValue} (${perValue}%)`
              }
            }
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                size: 12,
                weight: 400
              },
              color: '#404040',
              padding: 20,
              boxWidth: 20,
              boxHeight: 10
            }
          }
        }
      }
    },
    generateDaysInOfficeChart () {
      const teamProfiles = this.getTeamProfiles(this.teamSelected.id)
      const teamMax = teamProfiles.length
      // console.log('Team.generateDaysInOfficeChart', this.teamSelected.name, '|', this.timeSelected)

      const { from, to } = getDateRange(this.timeSelected)
      let timePoint = 'day'
      const labelInterval = 'all'

      if (this.timeSelected === 'Last 12 months') {
        timePoint = 'week'
      } else if (this.timeSelected === 'Last month' || this.timeSelected === 'Last 30 days') {
        timePoint = 'week'
      }

      const labels = []
      const intervals = []
      const workingDaysOfInterval = {}
      const intervalData = {
      }

      let interim = moment(from)
      let index = 0
      while (to > interim) {
        let label = ''

        if (timePoint === 'week') {
          label = interim.format('[WK]WW YY')
          labels.push(label)
          intervals.push(label)
        } else {
          if (labelInterval === 'all') {
            label = interim.format('DD MMM')
            labels.push(label)
            intervals.push(label)
          } else if (labelInterval === 'even') {
            label = interim.format('DD MMM')
            intervals.push(label)
            if (index % 2 === 0) {
              labels.push(label)
            } else {
              labels.push('')
            }
          }
        }

        workingDaysOfInterval[label] = getBusinessDays(moment(interim).startOf(timePoint), moment(interim).endOf(timePoint))
        intervalData[label] = {}
        interim = interim.add(1, timePoint)
        index++
      }

      const userLocations = this.userLocations || []
      const officeDays = userLocations.filter(ul => ul.location === 'office')
      for (const od of officeDays) {
        const odDate = moment(od.dateStr)
        if (odDate.isBetween(moment(from), moment(to))) {
          let dateF = odDate.format('DD MMM')
          if (timePoint === 'week') {
            dateF = odDate.format('[WK]WW YY')
          }

          if (od.users) {
            for (const odu of Object.keys(od.users)) {
              if (teamProfiles.includes(odu)) {
                if (intervalData[dateF] && intervalData[dateF][odu]) {
                  intervalData[dateF][odu] = intervalData[dateF][odu] + 1
                } else if (intervalData[dateF]) {
                  intervalData[dateF][odu] = 1
                }
              }
            }
          }
        }
      }

      const dayData = {
        day0: [],
        day1: [],
        day2: [],
        day3: [],
        day4: [],
        day5: []
      }

      const dayCounts = {
        day0: [],
        day1: [],
        day2: [],
        day3: [],
        day4: [],
        day5: []
      }

      for (const interval of intervals) {
        const userTotals = intervalData[interval]
        const workDays = workingDaysOfInterval[interval]
        let d0 = 0
        let d1 = 0
        let d2 = 0
        let d3 = 0
        let d4 = 0
        let d5 = 0

        for (const ut of Object.values(userTotals)) {
          let bracket = ut
          if (timePoint === 'week') {
            bracket = Math.round((ut * 5) / workDays)
          }
          if (bracket >= 5) {
            d5++
          } else if (bracket >= 4) {
            d4++
          } else if (bracket >= 3) {
            d3++
          } else if (bracket >= 2) {
            d2++
          } else if (bracket >= 1) {
            d1++
          }
        }
        d0 = teamMax - d5 - d4 - d3 - d2 - d1
        dayData.day0.push(((d0 / teamMax) * 100).toFixed(1))
        dayData.day1.push(((d1 / teamMax) * 100).toFixed(1))
        dayData.day2.push(((d2 / teamMax) * 100).toFixed(1))
        dayData.day3.push(((d3 / teamMax) * 100).toFixed(1))
        dayData.day4.push(((d4 / teamMax) * 100).toFixed(1))
        dayData.day5.push(((d5 / teamMax) * 100).toFixed(1))
        dayCounts.day0.push(d0)
        dayCounts.day1.push(d1)
        dayCounts.day2.push(d2)
        dayCounts.day3.push(d3)
        dayCounts.day4.push(d4)
        dayCounts.day5.push(d5)
      }

      let datasets
      let datacounts
      if (timePoint === 'day') {
        datasets = [
          {
            label: 'Out of Office',
            backgroundColor: '#5485B4',
            borderColor: '#5485B4',
            fill: true,
            data: dayData.day0,
            tension: 0.1
          },
          {
            label: 'In Office',
            backgroundColor: '#ECF5FE',
            borderColor: '#ECF5FE',
            fill: true,
            data: dayData.day1,
            tension: 0.1
          }
        ]
        datacounts = [
          dayCounts.day0,
          dayCounts.day1
        ]
      } else {
        datasets = [
          {
            label: '0 days',
            backgroundColor: '#5485B4',
            borderColor: '#5485B4',
            fill: true,
            data: dayData.day0,
            tension: 0.1
          },
          {
            label: '1 days',
            backgroundColor: '#87C4FF',
            borderColor: '#87C4FF',
            fill: true,
            data: dayData.day1,
            tension: 0.1
          },
          {
            label: '2 days',
            backgroundColor: '#B7D7EC',
            borderColor: '#B7D7EC',
            fill: true,
            data: dayData.day2,
            tension: 0.1
          },
          {
            label: '3 days',
            backgroundColor: '#C4E7FD',
            borderColor: '#C4E7FD',
            fill: true,
            data: dayData.day3,
            tension: 0.1
          },
          {
            label: '4 days',
            backgroundColor: '#DDF2FF',
            borderColor: '#DDF2FF',
            fill: true,
            data: dayData.day4,
            tension: 0.1
          },
          {
            label: '5 days',
            backgroundColor: '#ECF5FE',
            borderColor: '#ECF5FE',
            fill: true,
            data: dayData.day5,
            tension: 0.1
          }
        ]
        datacounts = [
          dayCounts.day0,
          dayCounts.day1,
          dayCounts.day2,
          dayCounts.day3,
          dayCounts.day4,
          dayCounts.day5
        ]
      }

      this.daysInOfficeChartData = {
        labels,
        datasets
      }
      this.daysInOfficeChartOptions = {
        layout: {
          padding: {
            right: 30
          }
        },
        responsive: true,
        aspectRatio: '1|2',
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
              display: false
            },
            ticks: {
              color: '#828282',
              font: {
                weight: 600,
                size: 10
              }
            }
          },
          y: {
            min: 0,
            max: 100,
            stacked: true,
            ticks: {
              font: {
                weight: 600,
                size: 10
              },
              color: '#828282',
              beginAtZero: true,
              stepSize: 12.5,
              callback: function (val, index) {
                return index % 4 === 0 ? val : ''
              }
            },
            title: {
              color: '#828282',
              font: {
                size: 10,
                weight: 400
              },
              display: true,
              text: 'Percentage'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label (context) {
                const countValue = datacounts[context.datasetIndex][context.dataIndex]
                const perValue = context.parsed.y
                const label = context.dataset.label || ''
                return ` ${label}: ${countValue} (${perValue}%)`
              }
            }
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                size: 12,
                weight: 400
              },
              color: '#404040',
              padding: 20,
              boxWidth: 20,
              boxHeight: 10
            }
          }
        }
      }
    }
  },
  watch: {
    teams: {
      handler () {
        if (this.teams) {
          if (!this.teamSelected) {
            this.teamSelected = this.teams.filter(t => t.id === this.profile.team)[0]
          }
        }
      },
      immediate: true
    },
    userLocations () {
      if (this.userLocations) {
        this.initialize()
      }
    },
    ready: {
      handler () {
        if (this.ready) {
          this.initialize()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.team-wrapper {
  height: 100%;
  margin-bottom: 30px;
  .one-col-layout {
    .mdc-card {
      border-radius: 0;
    }
  }
  .team-content-wrapper {
    .header-wrapper {
      display: flex;
      align-items: center;
      margin: 30px 150px 0 50px;
      .team-title {
        display: flex;
        align-items: center;
        .icon-wrapper {
          height: 15px;
          width: 15px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .mdc-text {
          color: #0F3860;
          font-weight: 600;
          font-size: 16px;
          .beta {
            font-size: 12px;
            color: #959EA7;
          }
        }
      }
    }
  }
  .team-content {
    .chart-filters {
      max-width: 840px;
      display:flex;
      justify-content: flex-end;
      z-index: 2;
      position: relative;
      &.third {
        z-index:2;
        margin-right: 30px;
        position: absolute;
        background: white;
        right: 0;
        top: 0;
        margin-top: 20px;
      }
      .switch-details-wrapper {
        position: relative;
        justify-self: flex-end;
        display: flex;
        .mdc-button {
          width:100%;
          padding: 0 20px 0 0;
          .mdc-text {
            text-align:left;
            margin-right:5px;
            flex:1;
            color: #4175B1;
            font-weight: 700;
            text-transform: none;
          }
        }
        .all-locations-details {
          position: absolute;
          background: #FFFFFF;
          border: 0.5px solid #C4C4C4;
          box-sizing: border-box;
          border-radius: 4px;
          top: 30px;
          z-index: 100;
          width: max-content;
          max-width: 380px;
        }
      }
    }
    h1 {
      font-size: 30px;
      color: #4f4f4f;
      margin-bottom: 35px;
      text-align: center;
    }
    .all-stats {
      display: flex;
      margin: 30px 55px 40px 55px;
      flex-direction: row;
      flex-wrap: wrap;
      grid-gap: 25px;
      @media all and (max-width: 1440px) {
        margin: 30px;
      }
      @media all and (max-width: 1200px) {
        max-width: 591px;
      }
      @media all and (min-width: 1200px) and (max-width: 1246px) {
        max-width: 540px;
        .stat {
          flex-basis: 33%
        }
      }
      .stat {
        width: 283px;
        @media all and (min-width: 1200px) and (max-width: 1350px) {
          min-width: 257px;
          width: 257px;
        }
      }

      .filters {
        display: flex;
        flex-direction: column;
        .show-data-text {
          font-size: 20px;
          font-weight: 700;
          color: #404040;
          margin-bottom: 15px;
          display: inline-block;
        }
        .switch-details-wrapper {
          background: #FAFAFA;
          width: 260px;
          margin-bottom: 10px;
          position: relative;
          justify-self: flex-start;
          display: flex;
          .mdc-button {
            width:100%;
            padding: 0 20px;
            .mdc-text {
              text-align:left;
              margin-right:5px;
              flex:1;
              color: #4175B1;
              font-weight: 700;
              text-transform: none;
            }
          }
          .all-teams-details {
            position: absolute;
            background: #FFFFFF;
            border: 0.5px solid #C4C4C4;
            box-sizing: border-box;
            border-radius: 4px;
            top: 40px;
            z-index: 100;
            width: max-content;
            max-width: 310px;
            min-width: 260px;
          }
          .filter-val-options {
            position: absolute;
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            border: 0.5px solid #C4C4C4;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 6px;
            width: max-content;
            min-width: 260px;
            max-width: 310px;
            top: 40px;
            right: 0;
            z-index: 100;
            .mdc-text {
              padding: 5px 12px;
              color: #4f4f4f;
              &:hover {
                background: rgba(235, 243, 252, 0.58);
              }
            }
          }
        }
      }
      .team-stat {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.29);
        border-radius: 2px;
        height: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .stat-value {
          margin:auto;
          font-size: 26px;
          color: #2B8AFB;
          font-weight: 800;
          text-transform: capitalize;
        }
        .stat-about {
          font-weight: 600;
          color: #404040;
        }
      }
    }
    .all-types-charts {
      display: flex;
      flex-wrap: wrap;
      margin: 0 55px 40px 55px;
      grid-gap: 25px;
      @media all and (max-width: 1440px) {
        margin: 0 30px 40px 30px;
      }
      .chart-wrapper {
        position: relative;
        max-width: 571px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.29);
        padding: 10px;
        border-radius: 2px;
        width: 571px;
        height: 400px;
        .chart-title {
          margin: 10px;
          display: block;
          font-size: 16px;
          font-weight: 700;
          color: #404040;
        }
        canvas {
          height: 310px !important;
        }
      }
      @media all and (min-width: 1200px) and (max-width: 1350px) {
        .chart-wrapper {
          max-width: 520px;
          width: unset;
          min-width: 520px;
        }
      }
    }
  }
}
</style>
