import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { datetimeFormat, dateFormat, dateDisplayFormat, dateShortDisplayFormat, timeFormat } from './utils/time'
import { rolePlugin, teamLeaderPlugin, workspaceAdminPlugin, featurePreviewPlugin } from './utils/role'
import ShareNetwork from 'vue-social-sharing'
import VTooltip from 'v-tooltip'
import Fitty from 'vue-fitty'

import browserDetect from 'vue-browser-detect-plugin'

import VueCroppie from 'vue-croppie'
import 'croppie/croppie.css'

import VueCarousel from 'vue-carousel'

import PortalVue from 'portal-vue'

import VueTextareaAutosize from 'vue-textarea-autosize'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import InstantSearch from 'vue-instantsearch'

import components from './global.components'
Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

Vue.use(VueTextareaAutosize)
Vue.use(PortalVue)
Vue.use(VueCarousel)
Vue.use(VueCroppie)
Vue.use(browserDetect)
Vue.use(VTooltip)
Vue.use(InstantSearch)
Vue.use(Fitty)
Vue.use(ShareNetwork)
Vue.use(rolePlugin, { router })
Vue.use(teamLeaderPlugin, { router })
Vue.use(workspaceAdminPlugin, { router })
Vue.use(featurePreviewPlugin, { router })
Vue.component('v-select', vSelect)

// Filters
Vue.filter('datetimeFormat', datetimeFormat)
Vue.filter('dateFormat', dateFormat)
Vue.filter('dateDisplayFormat', dateDisplayFormat)
Vue.filter('dateShortDisplayFormat', dateShortDisplayFormat)
Vue.filter('timeFormat', timeFormat)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
