<template>
  <div ref="settingsCogMenu" class="settings-cog-menu" v-bind:class="forSection" v-if="showSettingsCogMenu && forSection !== ''">
    <div class="menu-item-wrapper" v-if="forSection === 'scheduler'" role="menu" aria-label="scheduler">
      <mdc-text @click.native="enableSetupCalendar()" typo="body2" tag="span">Configure your calendar</mdc-text>
    </div>
    <div class="menu-item-wrapper" v-if="forSection === 'people-schedules' && ($teamLeader() || $workspaceAdmin())" aria-label="people-schedules" role="menu">
      <mdc-text @click.native="enableSetupSchedules()" typo="body2" tag="span">{{ isSchedulesPreferencesSubmitted ? 'Edit schedule preferences' : 'Setup schedule preferences' }}</mdc-text>
      <mdc-text @click.native="enableAddUser()" typo="body2" tag="span">Add team member</mdc-text>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SettingsCogMenu',
  props: {
    showSettingsCogMenu: {
      type: Boolean,
      default: false
    },
    forSection: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState([
      'setupCalendarLater',
      'profile'
    ]),
    ...mapState({
      tempos: state => state.teams.tempos
    }),
    isSchedulesPreferencesSubmitted () {
      if (this.tempos.length > 0 && this.profile) {
        const preferences = this.tempos.filter(tempo => tempo.type === 'schedules' && tempo.teamId === this.profile.team)
        if (preferences.length > 0) {
          return preferences[0].preferencesSubmitted
        }
      }
      return false
    }
  },
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions({
      setSetupCalendarLater: 'setSetupCalendarLater'
    }),
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target))) {
        this.$emit('closeSettingsCogMenu', false)
      } else {
        // to be done
      }
    },
    enableSetupSchedules () {
      this.$emit('enableSetupSchedules')
      this.$emit('closeSettingsCogMenu', false)
    },
    enableAddUser () {
      this.$emit('enableAddUser')
      this.$emit('closeSettingsCogMenu', false)
    },
    enableSetupCalendar () {
      this.$emit('enableSetupCalendar')
      this.$emit('closeSettingsCogMenu', false)
      this.setSetupCalendarLater(false)
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  watch: {
    showSettingsCogMenu: {
      handler () {
        if (this.showSettingsCogMenu) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
          }, 250)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
          }, 250)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped >
.settings-cog-menu:not(:empty) {
  background: #FEFEFE;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  position: absolute;
  z-index: 30;
  right: 0;
  display: flex;
  top: 36px;
  flex-direction: column;
  min-width: 150px;
  left: unset;
  max-width: max-content;
  .menu-item-wrapper:not(:empty) {
    display: flex;
    min-height: 40px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    span.mdc-text {
      padding: 7px 10px;
      color: #404040;
      font-weight: 600;
      white-space: nowrap;
      &:hover {
        background: #EBF3FC;
        &:first-of-type {
          border-radius: 4px 4px 0 0;
        }
        &:last-of-type {
          border-radius: 0 0 4px 4px;
        }
      }
    }
  }
  &.people-schedules {
    margin-left: 195px;
    top: 55px;
    left: 0;
  }
}
</style>
