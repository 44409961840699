<template>
  <mdc-text tag="span" typo="caption" class="timeago" v-if="timeago">{{timeago}}</mdc-text>
</template>

<script>
import moment from 'moment'
export default {
  name: 'TimeAgo',
  props: {
    datetime: {
      type: Date
    },
    refresh: {
      type: Boolean,
      default: false
    },
    short: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timeago: this.getTimeAgo(this.datetime),
      interval: 1000,
      updates: this.refresh
    }
  },
  mounted () {
    this.startUpdater()
  },
  beforeDestroy () {
    this.stopUpdater()
  },
  methods: {
    getTimeAgo () {
      let ago = moment(this.datetime).fromNow()
      if (this.short) {
        ago = ago.replace(' minutes', 'm')
        ago = ago.replace(' hours', 'h')
        ago = ago.replace(' days', 'd')
        ago = ago.replace('a month', '1m')
        ago = ago.replace(' months', 'm')
        ago = ago.replace(' ago', '')
      }
      return ago
    },
    startUpdater () {
      const ta = `${this.timeago}`
      if (ta.includes('year')) {
        this.updates = false // off
      } else if (ta.includes('month')) {
        this.updates = false // off
      } else if (ta.includes('day')) {
        this.updates = 3600000 // 1 hour
      } else if (ta.includes('hour')) {
        this.updates = 60000 // 1 min
      }

      if (this.updates) {
        this.updater = setInterval(() => {
          this.timeago = this.getTimeAgo()
        }, this.interval)
      }
    },
    stopUpdater () {
      if (this.updater) {
        clearInterval(this.updater)
        this.updater = null
      }
    }
  }
}
</script>
<style scoped>
.timeago {
  padding-left:4px;
  padding-right:4px;
  white-space: nowrap;
}
</style>
