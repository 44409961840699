<template>
  <div class="team-invites-dialog" v-if="showDialog" tabindex="0">
    <header>
      <template v-if="filteredInvites.length > 0">
        <base-svg-icon name="people-red" />
        <mdc-text v-if="myTeam" tag="span" typo="headline6">Join new team requests for {{profile.displayName}}</mdc-text>
      </template>
      <template v-else>
        <mdc-text tag="span" typo="headline6">No more requests to join other team</mdc-text>
      </template>
      <mdc-icon-button aria-label="close" class="close-modal" @click.native="closeDialog()">clear</mdc-icon-button>
    </header>
    <section class="team-invitess-content" v-if="filteredInvites.length > 0">
      <header>
        <mdc-text tag="span" typo="body2">REQUESTS</mdc-text>
      </header>
      <div class="requests-list-wrapper" role="list">
        <div class="request-details-actions" v-for="invite in filteredInvites" :key="invite.id">
          <div class="details">
            <base-svg-icon name="people-grey" class="people-icon" />
            <mdc-text tag="span" typo="body2" class="member-name">{{invite.teamName}} - invited by {{getInvitedBy(invite)}}</mdc-text>
          </div>
          <div class="actions">
            <mdc-button raised aria-label="accept" class="accept" @click.prevent.stop="acceptInvite(invite)" :class="sendingInvite ? 'disabled' : ''">Accept</mdc-button>
            <mdc-button raised aria-label="decline" class="decline" @click.prevent.stop="declineInvite(invite)" :class="sendingInvite ? 'disabled' : ''">Decline</mdc-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import { mapActions, mapState } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import { onboarding } from '../../utils/backend'
import { getNameInitials } from '../../utils/profile'

export default {
  name: 'TeamInviteDialog',
  components: {
    BaseSvgIcon
  },
  data () {
    return {
      sendingInvite: false
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
    document.removeEventListener('keyup', this.handleKeyDown)
  },
  created () {},
  mounted () {
  },
  computed: {
    ...mapState([
      'user',
      'teamInvitesDisplay',
      'profile',
      'profiles',
      'workspace'
    ]),
    ...mapState({
      myTeam: state => state.teams.team,
      myInvites: state => state.myInvites,
      notifications: state => state.teams.notifications
    }),
    filteredInvites () {
      if (this.myInvites) {
        return this.myInvites.filter((inv) => inv.status !== 'accepted' && inv.status !== 'declined' && inv.workspace === this.workspace.id)
      }
      return []
    }
  },
  methods: {
    ...mapActions([
      'setTeamInvitesDisplay'
    ]),
    ...mapActions({
      loadTeam: 'teams/loadTeam',
      updateNotification: 'teams/updateNotification'
    }),
    getInvitedBy (invite) {
      if (invite) {
        if (invite.createdBy) {
          const invProfile = this.profiles.filter(pr => pr.id === invite.createdBy)
          if (invProfile.length) {
            return invProfile[0].displayName
          }
        }
        if (invite.ownerName) {
          return invite.ownerName
        }
      }
      return ''
    },
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        this.closeDialog()
      }
    },
    getMemberInitials (name) {
      return getNameInitials(name)
    },
    closeDialog () {
      this.setTeamInvitesDisplay(false)
    },
    checkClose () {
      const self = this
      setTimeout(() => {
        // console.log('checkClose', self.filteredRequests.length)
        if (self.filteredRequests.length === 0) {
          self.closeDialog()
        }
      }, 1000)
    },
    async acceptInvite (invite) {
      this.sendingInvite = true
      const params = {
        workspaceId: invite.workspace,
        teamId: invite.team,
        userId: this.profile.id,
        userName: this.profile.displayName,
        inviteId: invite.id,
        replyId: invite.createdBy
      }
      const response = await onboarding('accept-invite', params)
      console.log('TeamInviteDialog.accpetInvite', response, params)
      await firebase.auth().currentUser.getIdToken(true)
      this.sendingInvite = false
    },
    async declineInvite (invite) {
      this.sendingInvite = true
      const params = {
        workspaceId: invite.workspace,
        teamId: invite.team,
        userId: this.profile.id,
        userName: this.profile.displayName,
        inviteId: invite.id,
        replyId: invite.createdBy
      }
      const response = await onboarding('decline-invite', params)
      console.log('TeamInviteDialog.declineInvite', response, params)
      this.sendingInvite = false
    },
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target) || event.target.classList.contains('accept') || event.target.parentNode.classList.contains('decline'))) {
        this.closeDialog()
      }
    }
  },
  watch: {
    showDialog: {
      handler () {
        if (this.showDialog) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
            document.addEventListener('keyup', self.handleKeyDown)
          }, 300)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
            document.removeEventListener('keyup', self.handleKeyDown)
          }, 300)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.team-invites-dialog {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  background: #FFFFFF;
  border: 0.5px solid #BDBDBD;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);
  border-radius: 10px;
  height: max-content;
  z-index: 35;
  > header {
    margin: 20px 35px;
    display: flex;
    align-items: center;
    .mdc-text {
      margin-left: 5px;
      flex: 1;
    }
    &.no-requests {
      display: flex;
      justify-content: center;
    }
    .close-modal {
      &::before,
        &::after {
          --mdc-ripple-fg-size: 0;
          width: auto;
          height: auto;
        }
    }
  }
  .team-invitess-content {
    display: flex;
    flex-direction: column;
    margin: 0 35px 20px 35px;
    > header {
      margin-bottom: 10px;
      .mdc-text {
        color: #404040;
      }
    }
    .requests-list-wrapper {
      .request-details-actions {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .details {
          flex: 1;
          display: flex;
          align-items: center;
          *:not(:empty) {
            margin-right: 5px;
          }
          .mdc-text:not(.profile-initials) {
            color: #404040;
            font-weight: 600;
          }
        }
        .actions {
          display: flex;
          align-items: center;
          .mdc-button {
            margin-right: 5px;
            height: 25px;
            border-radius: 8px;
            font-size: 12px;
            text-transform: none;
            width: max-content;
            &.accept {
              background: #00498D;
            }
            &.decline {
              background: #FFFFFF;
              border: 0.5px solid #404040;
              box-sizing: border-box;
              color: #404040;
            }
            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
</style>
