<template>
  <header v-if="!noHeader"
          :style="dynamicStyle.topbar"
          style="position: relative;"
          :class="topBarBg"
          class="mdc-top-app-bar mdc-top-app-bar--fixed mdc-top-app-bar--dense" id="app-bar">
    <div v-if="noMenu" class="mdc-top-app-bar__row">
      <img v-if="appLogo" :src="appLogo" class="logo">
    </div>
    <div v-else class="mdc-top-app-bar__row all-menu">
      <section v-if="profileReady" class="header-section" style="padding: 0;">
        <router-link :to="{ name: 'Dashboard'}" class="logo-wrapper">
          <base-svg-icon name="duome-logo-whitelogo" logo class="logo desktoponly"/>
          <base-svg-icon name="logo-notext" logo class="logo mobileonly"/>
        </router-link>
        <app-nav :remote="remote" :navActive="navActive" :linktext="dynamicStyle.linktext"></app-nav>
        <div style="display: flex;height: 25px;align-items: flex-end;margin-left: auto;">
          <span class="header-search" @click="searchIconClick()">
            <base-svg-icon name="search-lens"  clickable/>
          </span>
          <span class="plus-redirect" @click="plusMenuClick()">
            <base-svg-icon name="plus-topbar" clickable/>
          </span>
          <span class="notification-mode" @click="toggleNotificationMode()">
            <base-svg-icon :name="notificationMode === 'on' ? 'notification-panel-on' : 'notification-panel-off'" class="panel-icon" clickable />
            <div v-if="notificationMode === 'on'" class="unread-count-wrapper">
              <mdc-text tag="span"  class="unread-count" >{{ allUnreadNotifications.length }}</mdc-text>
            </div>
          </span>
          <span class="menu-section">
            <!-- <notification-area :dynamicStyle="dynamicStyle" @openProfileTasks="$emit('openProfileTasks')" v-model="unreadOpen" :profile="profile" /> -->
            <div class="profile-menu" @click="profileMenuClick">
              <!-- <mdc-text tag="span" typo="body2">Hello, {{ getFirstNameAndInitial }}</mdc-text> -->
              <profile-initials @click.native="profileMenuClick" :profileId="profile.id" :date="appDate" noProfileDetailClick />
            </div>
          </span>
        </div>
      </section>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import NotificationArea from './profile-v2/NotificationArea.vue'
import AppNav from './AppNav.vue'
import BaseSvgIcon from './flexibly/BaseSvgIcon.vue'
import ProfileInitials from './flexibly/ProfileInitials.vue'
export default {
  components: {
    // NotificationArea,
    AppNav,
    BaseSvgIcon,
    ProfileInitials
  },
  data () {
    return {
      openPlusMenus: false,
      openUserSettings: false,
      menuOpen: false,
      unreadOpen: false,
      noHeader: false,
      noMenu: false
    }
  },
  props: {
    navActive: {
      default: null
    },
    profileReady: {
      default: false
    },
    dynamicStyle: {
    }
  },
  computed: {
    ...mapState([
      'user',
      'connections',
      'workspace',
      'profiles',
      'profile',
      'isBranded',
      'workspace',
      'remote',
      'teamMode',
      'notificationMode',
      'appDate'
    ]),
    ...mapState({
      notifications: state => state.teams.notifications
    }),
    allUnreadNotifications () {
      return this.notifications.filter((notif) => !notif.read)
    },
    getFirstNameAndInitial () {
      let firstName = ''
      let initialLast = ''
      let displayNameParts = ''
      const profile = this.profile
      if (profile) {
        displayNameParts = `${profile.displayName}`.split(' ')
        if (profile.firstName) {
          firstName = profile.firstName
        } else {
          firstName = displayNameParts[0]
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${firstName} ${initialLast.toUpperCase()}`
      }
      return ''
    },
    userClaimAdmin () {
      if (this.user) {
        if (this.user.claims) {
          return this.user.claims.admin
        }
      }
      return false
    },
    connsWithUnread () {
      return this.connections.filter(con => con.unread[this.user.uid] > 0)
    },
    mobileLogo () {
      if (this.isBranded) {
        return this.workspace.logo
      }
      return '/static/img/icons-60.png'
    },
    appLogo () {
      if (this.isBranded) {
        if (!this.workspace.logo) {
          return '/static/img/logo-black.svg'
        }
        return this.workspace.logo
      }
      if (this.teamMode) {
        return '/static/img/flexibly_logo_white.svg'
      }
      return '/static/img/Logo-white.svg'
    },
    topBarBg () {
      if (this.profileReady || !this.isBranded) {
        return 'app-bar-bg'
      }
      return 'app-bar-base'
    },
    unread () {
      let unread = 0
      const uid = this.user.uid
      if (this.connections) {
        Object.values(this.connections).forEach(function (con) {
          if (con.unread) {
            unread += con.unread[uid]
          }
        })
      }
      return unread
    }
  },
  mounted () {
    // this.topAppBar = MDCTopAppBar.attachTo(this.$el)
    if (this.$route) {
      this.noHeader = this.$route.meta.noheader
      this.noMenu = this.$route.meta.nomenu
    }
  },
  methods: {
    ...mapActions([
      'clearUnread',
      'setNotificationMode'
    ]),
    toggleNotificationMode () {
      if (this.notificationMode === 'on') {
        this.setNotificationMode('off')
        return
      }
      this.setNotificationMode('on')
    },
    profileMenuClick () {
      this.$emit('settingsMode', true)
    },
    plusMenuClick () {
      this.$emit('plusMenu', true)
    },
    searchIconClick () {
      this.$root.$emit('searchClick', true)
    },
    clearAllUnread () {
      const uid = this.user.uid
      const self = this
      Object.values(this.connections).forEach(function (con) {
        if (con.unread) {
          if (con.unread[uid] > 0) {
            self.clearUnread({ connectionId: con.id })
          }
        }
      })
    },
    onSelected (event) {
      if (this.$router) {
        switch (event.detail.index) {
          case 0:
            this.$router.push('/my-profile')
            break
          case 1:
            this.$router.push('/settings')
            break
          case 2:
            this.$router.push('/legal')
            break
          case 3:
            window.open('https://duome.zendesk.com/hc/en-gb', '_blank')
            break
          case 4:
            this.$router.push('/extra')
            break
          case 5:
            this.$router.push('/search')
            break
          case 6:
            if (this.user.claims.admin) {
              this.$router.push('/admin')
            } else {
              this.signOut()
            }
            break
          case 7:
            this.signOut()
            break
        }
      }
    },
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.profile.id]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return {}
    },
    onMessageMenu (event) {
      if (event.detail.index === 0) {
        this.clearAllUnread()
      } else {
        const conSelected = this.connsWithUnread[event.detail.index - 1]
        if (conSelected) {
          this.clearUnread({ connectionId: conSelected.id })
        }
      }
    },
    signOut () {
    }
  }
}
</script>

<style>
.mdc-menu__scrim {
  height:100vh;
  width:100%;
}
.app-bar-base {
  background: #FFFFFF;
}
.app-bar-bg {
  background: linear-gradient(0.23deg, #C10457 0%, #730027 100%);
}
.message-menu {
  margin-top: 30px;
  top:48px;
  right:24px;
  height:260px;
  width:300px;
  border-radius:5px;
}
.message-menu .mark-all {
  justify-content: flex-end;
  font-size:12px;
  cursor:pointer;
}
.message-menu .mdc-menu-item span {
  height: 48px;
  line-height: 48px;
}
.message-menu .mdc-menu-item > span:first-child {
  width:100%
}
.message-menu .mdc-icon {
  margin-left:auto;
  padding-left:16px;
}
.message-menu .mdc-list-item .mdc-list-item__text {
  display: flex;
  align-content: center;
  height: 100%;
  width: 100%;
}
.message-menu .mdc-list-item .mdc-list-item__text > span {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
.mdc-top-app-bar__row {
  &.all-menu {
    margin: 15px 40px 12px 35px;
    height: 30px;
    width: auto;
  }
}
@media (max-width: 720px){
.mdc-top-app-bar__row {
  &.all-menu {
    margin: 15px 20px 12px 15px;
  }
}
}
.header-section {
  display: flex;
  align-items: center;
  width: 100%;
  .logo-wrapper {
    min-width: 30px;
    height: 30px;
    justify-content: flex-start;
    margin-right: 50px;
    .icon-wrapper {
      width: 110px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .logo {
      &.mobileonly {
        max-width: 30px;
      }
    }
  }
  .header-search {
    display: flex;
    align-items: center;
    margin-right: 22px;
    .icon-wrapper {
      padding: 0;
      height: 18px;
      width: 18px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .plus-redirect {
    display: flex;
    align-items: center;
    margin-right: 22px;
    .icon-wrapper {
      padding: 0;
      height: 18px;
      width: 18px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .notification-mode {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 22px;
    align-self: flex-end;
    position: relative;
    .icon-wrapper {
      height: 18px;
      width: 18px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .mdc-text:not(.unread-count) {
      margin-left: 5px;
      font-weight: 800;
      font-size: 10px;
      text-transform: uppercase;
      min-width: 88px;
    }
    .unread-count-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      border-radius: 50%;
      background: white;
      height: 12px;
      width: 12px;
      top: -4px;
      right: -5px;
      text-align: center;
      justify-content: center;
      border: 0.5px solid var(--app-background);
    }
    .unread-count {
      color: red;
      font-size: 8px;
      font-weight: 900;
      line-height: 11px;
    }
  }
  .menu-section {
    display: flex;
    align-items: center;
    .profile-menu {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: max-content;
      .profile-redirect {
        margin-right: 0;
      }
    }
  }
}

@media all and (max-width: 700px) {
  .top-navbar .desktoponly {
    display: none !important;
  }
}
</style>
