<template>
  <div class="mentions-side-bar">
    <!-- <section>
      <div @click="setClickedMentionType('comments')" class="sidebar-section-header">
        Comments <span class="count">{{commentCount}}</span>
        <mdc-icon-button v-show="mentionType==='comments' && Object.keys(mentionsComments).length > 0" class="fold-icon">unfold_less</mdc-icon-button>
      </div>
      <template  v-if="mentionType==='comments'">
        <div class="mention-section" v-for="(mention, index) in mentionsComments" :key="'mentionscomments' + index">
          <div class="mention-link"></div>
          <div class="mention-preview"></div>
        </div>
      </template>
    </section> -->
    <section>
      <div @click="setClickedMentionType('linkedmentions')" class="sidebar-section-header">
        Linked Mentions <span class="count">{{linkedMentionsCount}}</span>
        <mdc-icon-button v-show="mentionType==='linkedmentions' && Object.keys(groupedLinkedMentions).length > 0" class="fold-icon">unfold_less</mdc-icon-button>
      </div>
      <template  v-if="mentionType==='linkedmentions'">
        <div class="mention-section"
          v-for="(mentions, mentionsName, index) in groupedLinkedMentions"
          :key="'link' + mentionsName + index">
          <mdc-text :key="'li-mention-link-' + index" class="mention-link" typo="subtitle2"
          @click.native="composerRoute(mentions[0].type, mentions[0].pageId, mentions[0].pageLabel, mentions[0].link, mentions[0])"> {{mentionsName}} ({{mentions.length}})</mdc-text>
          <template v-for="(mention, index) in mentions">
            <mdc-text :key="'li-mention-preview-' + mention.pageId + '-' + index"
              class="mention-preview"
              v-bind:class="{'full-block' : expandMentionBlock.findIndex(block => block.pageId === mention.pageId && block.index === index) !== -1}"
              v-html="'...' + mention.parentText +' <mark>' + mention.pageName + '</mark>...'"
              typo="subtitle2"
              @click.native="composerRoute(mention.type, mention.pageId, mention.pageLabel, mention.pageLink, mention)"
            >
            </mdc-text>
          </template>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'MentionsSideBar',
  props: {
    /* mentionsComments: {
      default () {
        return {}
      }
    }, */
    linkedMentions: {
      default () {
        return {}
      }
    },
    mentionsTypeSelected: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      groupedLinkedMentions: {},
      comments: {},
      mentionType: this.mentionsTypeSelected,
      expandMentionBlock: [],
      editingBlock: false,
      doc: false,
      page: false,
      pageType: ''
    }
  },
  mounted () {
    this.groupLinkedMentionsBlockByPageLabel()
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'tasks'
    ]),
    ...mapState({
      initiatives: state => state.teams.initiatives,
      teamProfiles: state => state.teams.teamProfiles
    }),
    teamMentions () {
      if (this.teamProfiles) {
        return this.teamProfiles.map((prof) => {
          return { ...prof, name: prof.displayName, initials: this.initials(prof) }
        })
      }
      return []
    },
    /* commentCount () {
      if (this.comments) {
        return Object.keys(this.comments).length
      }
      return 0
    }, */
    linkedMentionsCount () {
      if (this.linkedMentions) {
        return Object.keys(this.linkedMentions).length
      }
      return 0
    }
  },
  methods: {
    ...mapActions({
      getNotePageById: 'teams/getNotePageById',
      updateNotePage: 'teams/updateNotePage',
      getMeetingPageById: 'teams/getMeetingPageById',
      updateMeetingPage: 'teams/updateMeetingPage',
      getMeetingByPageId: 'teams/getMeetingByPageId'
    }),
    formatMentionBlock (block) {
      const splitBlockBySpace = block.split(' ')
      const markIndex = splitBlockBySpace.findIndex(element => element.includes('<mark>'))
      const splitBlockWords = splitBlockBySpace.slice(markIndex - 5 > 0 ? markIndex - 5 : 0, markIndex + 10 > splitBlockBySpace.length ? splitBlockBySpace.length : markIndex + 10)
      if (markIndex === 0) {
        return splitBlockWords.join(' ').toString()
      }
      if (markIndex > 5) {
        splitBlockWords.splice(0, 0, splitBlockBySpace[0])
      }
      if (splitBlockBySpace.length - markIndex >= 10) {
        splitBlockWords.push(splitBlockBySpace[splitBlockBySpace.length - 1])
      }
      return splitBlockWords.join(' ').toString()
    },
    initials (profile) {
      if (profile) {
        let initialFirst = ''
        let initialLast = ''
        let displayNameParts = ['', '']
        displayNameParts = profile.displayName.split(' ')
        if (profile.firstName) {
          initialFirst = profile.firstName.trim().substring(0, 1)
        } else {
          initialFirst = displayNameParts[0].substring(0, 1)
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${initialFirst}${initialLast}`.toUpperCase()
      }
      return 'U'
    },
    mentionBlockClick (mention, index) {
      this.doc = false
      this.page = false
      const pageId = mention.pageId
      const mentionType = mention.type
      this.pageType = mention.type
      this.expandMentionBlock.push({
        pageId,
        index
      })
      this.editingBlock = pageId
      const self = this
      this.$nextTick(() => {
        if (mentionType === 'notePage') {
          this.getNotePageById({
            teamId: self.profile.team,
            pageId: mention.pageId
          }).then((notePage) => {
            self.page = notePage
            self.doc = `notepage/${notePage.pageId}`
          })
        }
        if (mentionType === 'meetingPage') {
          this.getMeetingPageById({
            teamId: self.profile.team,
            pageId: mention.pageId
          }).then((meetingPage) => {
            self.page = meetingPage
            self.doc = `meetingpage/${meetingPage.pageId}`
          })
        }
      })
    },
    composerRoute (mentionType, mentionId, mentionLabel, mentionLink, mention) {
      // console.log('MentionsSideBar.composerRoute', mentionType, mentionId, mentionLabel, mention)
      if (mentionType === 'notePage') {
        this.$root.$emit('changeComposerView', 'notesPage', mentionLabel, { link: mentionLink, label: mentionLabel, notesSearch: true })
      } else if (mentionType === 'dailyPage') {
        this.$root.$emit('changeComposerView', 'dailyPage', 'COMPOSER', { date: mention.pageDate })
      } else if (mentionType === 'meetingPage') {
        this.getMeetingByPageId({
          userId: this.profile.id,
          pageId: mention.pageId
        }).then((meeting) => {
          // console.log('MentionsSideBar.composerRoute.meetingPage', meeting)
          if (meeting) {
            const uid = meeting.iCalUId
            const meetingLabel = `${meeting.subject}`
            const meetingLink = 'meetingpage/' + uid
            this.$root.$emit('changeComposerView', 'meetingPage', meetingLabel, { meetingId: meeting.id, link: meetingLink, label: meetingLabel, subject: meeting.subject, when: meeting.date, meetingDetails: [meeting] })
          }
        })
      }
    },
    groupLinkedMentionsBlockByPageLabel () {
      this.groupedLinkedMentions = {}
      if (this.linkedMentions.length > 0) {
        const labelsByDate = {}
        const groupByPageLabel = this.linkedMentions.reduce((r, a) => {
          if (!labelsByDate[a.pageLabel]) {
            labelsByDate[a.pageLabel] = a.pageDate
          }
          r[a.pageLabel] = [...r[a.pageLabel] || [], a]
          return r
        }, {})
        const sortedByDateLabel = Object.entries(labelsByDate)
          .sort(([, a], [, b]) => b - a)
          .reduce((r, [k, v]) => ({ ...r, [k]: groupByPageLabel[k] }), {})
        this.groupedLinkedMentions = sortedByDateLabel || {}
      }
    },
    setClickedMentionType (type) {
      this.expandMentionBlock = []
      if (type === this.mentionType) {
        this.mentionType = ''
      } else {
        this.mentionType = type
      }
    }
  },
  watch: {
    linkedMentions () {
      this.groupLinkedMentionsBlockByPageLabel()
    }
  }
}
</script>

<style>
.mention-preview mark {
  background: #FFCF23 !important;
  color: white;
  padding-left:4px;
  padding-right:4px;
  border-radius:2px;
}
.mentions-side-bar .userlist {
  display:none;
}
</style>
<style scoped lang="scss">
.mentions-side-bar {
  padding: 0 0 30px 0;
  section {
    border-bottom: 0.5px solid #4175B1;
    min-width: 260px;
    .sidebar-section-header {
      font-size: 14px;
      font-weight: 500;
      color: #4175B1;
      text-transform: capitalize;
      padding: 10px;
      cursor: pointer;
      margin-top: 32px;
      .count {
        padding: 0;
        display:inline-block;
        text-align: center;
        width: 15px;
        height: 15px;
        border-radius: 16px;
        background: #D9E8FA;
        border: 0.2px solid #4175B1;
        box-sizing: border-box;
        font-size: 10px;
        line-height: 15px;
        margin: 0 5px 0 10px;
        font-weight: 500;
      }
      .fold-icon {
        padding: 0;
        height: 15px;
        width: 15px;
        float: right;
        font-size: 16px;
        &.mdc-icon-button::before,
        &.mdc-icon-button::after {
          --mdc-ripple-fg-size: 0;
          width: auto;
          height: auto;
        }
      }
    }
    .mention-section {
      margin: 10px;
    }
    .mention-link {
      white-space: nowrap;
      color: #333333;
      text-transform: capitalize;
      cursor:pointer;
      font-weight: 600;
    }
    .mention-preview {
      font-weight: 400;
      padding: 0 10px;
      color: #000000;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
      &.full-block {
        -webkit-line-clamp: unset;
        overflow: visible;
      }
    }
  }
}
</style>
