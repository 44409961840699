<template>
  <div class="navbar-holder" :style="reducedMenu">
    <span class="navbar-item-wrapper" v-for="(item, index) in links" :key="index">
      <template v-if="item.teamAdmin && teamLeader || !item.teamAdmin">
      <router-link @click.native="setDefaultView(item.type)" active-class="router-link-active" v-bind:class="{'router-link-active-link': navActive === item.label}" :to="item.to">
        <div class="navbar-item">
          <span v-if="item.imageIcon" :class="[item.type.toLowerCase(), 'item-type']">
            <base-svg-icon :id="item.type + 'icon'" :class="[navActive === item.type ? 'on-icon': 'off-icon', 'item-icon']" :name="navActive === item.type ? item.selectIcon : item.imageIcon" />
          </span>
          <mdc-body typo='caption' tag="span" class="desktoponly nav-text" >{{item.label}}</mdc-body >
        </div>
      </router-link>
      </template>
    </span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isTeamLeader, isWorkspaceAdmin } from '../utils/role'
import BaseSvgIcon from './flexibly/BaseSvgIcon'
export default {
  name: 'AppNav',
  components: {
    BaseSvgIcon
  },
  props: {
    navActive: {
      type: String,
      required: false,
      default: undefined
    },
    linktext: {
      type: Object,
      required: false,
      default: undefined
    },
    remote: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState([
      'role',
      'workspace'
    ]),
    ...mapState({
      editingSchedule: state => state.teams.editingSchedule
    }),
    teamLeader () {
      return isTeamLeader() || isWorkspaceAdmin()
    },
    reducedMenu () {
      return ''
    }
  },
  methods: {
    ...mapActions([
      'setComposerView',
      'setScheduleSearchWeekDetail'
    ]),
    showThisLink (item) {
      return true
    },
    async setDefaultView (item) {
      // console.log('AppNav.setDefaultView', item)
      if (item === 'Planner') {
        await this.setComposerView('default')
      }
      if (item === 'People') {
        if (!this.editingSchedule) {
          await this.setScheduleSearchWeekDetail(false)
        }
      }
    }
  },
  data () {
    return {
      links: [
        { type: 'People', to: '/people', imageIcon: 'whoswherewhen-off', label: 'WHO\'S WHERE WHEN', selectIcon: 'whoswherewhen-on' },
        { type: 'Planner', to: '/dashboard', imageIcon: 'mywork-off', label: 'MY WORK', selectIcon: 'mywork-on' },
        { type: 'Initiatives', to: '/initiatives', imageIcon: 'initiatives-off', label: 'INITIATIVES', selectIcon: 'initiatives-on' },
        { type: 'Analytics', to: '/analytics', imageIcon: 'analytics-white', label: 'ANALYTICS', selectIcon: 'analytics-on', teamAdmin: true }
      ]
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.navbar-holder {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-end;
  height: 30px;
  .navbar-item-wrapper {
    flex-grow: 1;
    text-align: center;
    margin-right: 22px;
    max-height: 50px;
    a {
      text-decoration: none;
      box-sizing: border-box;
      display: block;
      .navbar-item {
        display:flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .item-type {
          .item-icon {
            height: 21px;
            width: 21px;
            &.on-icon {
              margin-right: 7px;
            }
            &.off-icon {
              margin-right: 7px;
            }
          }
        }
        * {
          color: white;
        }
        .nav-text {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
