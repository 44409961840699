<template>
  <div v-if="profile && profile.id" class="people-wrapper">
    <app-layout>
      <div class="flex-col-layout" slot="layout-content"
          v-bind:class="{
          'full-view': rightSideFullView || leftSideFullView,
          'rightside-full-view': rightSideFullView,
          'leftside-full-view': leftSideFullView,
          'notification-mode': notificationMode === 'on'
        }">
        <app-section
          cogMenu
          class="whoswherewhen-section-layout left-side"
          :expandedView="leftSideFullView"
          noSettings
          @enableSetupUpdates="false"
          @enableSetupSchedules="enableSetupSchedules"
          @closeSettingsCogMenu="closeSettingsCogMenu"
          @fullscreen="setLeftSideFullView"
          @settingsCogClick="leftSettingsCogClick"
          :fullscreenEnabled="!rightSideFullView"
          @enableAddUser="addUserIconClick"
          :showSettingsCogMenu="showSettingsCogMenu && showSettingsMenuFor === 'people-schedules'"
          :showSettingsMenuFor="showSettingsMenuFor"
          :editable="false">
            <schedule-search
            setWeekDetail
            :showDate="showDate"
            @setQuerySearch="setScheduleSearchQuery"
            @enableSetupUpdates="false"
            @enableSetupSchedules="enableSetupSchedules"
            @closeSetup="closeSetupSchedules"
            :displaySchedulesSetup="showSetupSchedules"
            @emitLeftSideCog="leftSettingsCogClick"
            @enableAddUser="addUserIconClick"
            :expandedView="leftSideFullView"
            :ref="'searchSchedule'"
            @tooltipLocation="closeOtherTooltip('location')"
            v-if="teamProfiles !== null"
            v-show="!rightSideFullView"
            :isMobile="isMobile"
            :isTablet="isTablet" />
            <pinned-schedules :scheduleSearchQuery="scheduleSearchQuery" v-show="!rightSideFullView" v-if="scheduleSearchQuery === '' && teamsLoaded && scheduleSearchWeekDetail && teamProfiles && profile && !showSetupSchedules && initiatives" />
            <span style="position: absolute;left: 0;top: 50px;" >
                <modal-dialog
                  @close="resetErrorState"
                  :imageName="'people-red'"
                  v-model="showEditMembers"
                  :pauseFocus="isMobile"
                  class="add-member-modal fullsize-mobile with-messagebar"
                  style="z-index: 22;"
                  accept=""
                  :title="'Add team members to ' + myTeam.name"
                  >
                    <mdc-text typo="body2" class="member-invite-heading">SEARCH NAME OR ENTER EMAIL TO INVITE</mdc-text>
                    <div class="member-invite-section mobile-column">
                      <auto-complete-outline
                        @keyup.escape="onClear"
                        @keyup.backspace="onDelete"
                        @keyup.delete="onDelete"
                        @set="onSearch"
                        autocompleteId="'teaminvite'"
                        style="width:100%;"
                        class="member-invite-email"
                        v-model="teamInviteEmail"
                        field="displayName"
                        prompt="Invite member to join your team"
                        promptMobileOverlay="Invite member to join your team"
                        placeholder="Name or Email address*"
                        :items="profilesFiltered"
                        autocomplete="new-password"
                        autofill="off"
                        :noNotched="false"
                        ariaLabelledBy="teamInviteEmail"
                        ref="teamName"
                        :onEnterSelectTop="false"
                        :valid="teamInviteValid || !saveFailed"
                        :min="1">
                      </auto-complete-outline>
                      <mdc-button raised @click="addInvite" :class="sendingInvite ? 'disabled' : ''">
                        <span>Send Invite</span>
                      </mdc-button>
                    </div>
                    <mdc-text v-if="sendingInvite" tag="span" typo="body2" class="sending-status">Sending invite</mdc-text>
                    <mdc-text v-if="inviteSent" tag="span" typo="body2">Invite sent</mdc-text>
                    <mdc-text v-if="helpText" typo="body2" class="helptext" v-html="helpText"></mdc-text>
                </modal-dialog>
              </span>
        </app-section>
        <app-section
          :updatesPreferencesSubmitted="updatesPreferencesSubmitted"
          @fullscreen="setRightSideFullView"
          cogMenu
          class="updates-section-layout right-side"
          :expandedView="rightSideFullView"
          :fullscreenEnabled="!leftSideFullView"
          :editable="false">
          <template v-if="!leftSideFullView">
            <template v-if="!locationIndicatorDialogDate">
              <section class="sub-section-types" :class="rightSideFullView ? 'fullscreen-mode' : ''">
                <mdc-button :class="rightSectionType === 'bestOfficeDays' ? 'clicked' : ''" class="best-office-header header-link" @click.native="setRightSectionType('bestOfficeDays')">
                  <base-svg-icon :name="rightSectionType === 'bestOfficeDays' ? 'office-black' : 'office-6184ad'" />
                  <mdc-text tag="span" typo="caption">BEST OFFICE DAYS</mdc-text>
                </mdc-button>
                <mdc-button :class="[rightSectionType === 'weeklyPreferences' ? 'clicked' : '', rightSectionType === 'bestOfficeDays' && !mySchedule && !myWeekPreferences && !bestDaysCalculating ? 'set-preferences': '']" class="location-pref-header header-link" @click.native="setRightSectionType({ section: 'weeklyPreferences', weeklyPreferences: true, openLocation: false })">
                  <svg xmlns="http://www.w3.org/2000/svg" class="svg-dashed-box anim">
                    <rect x="5" y="5" height="100%" width="100%" rx="20" ry="20" stroke-width="1"></rect>
                  </svg>
                  <base-svg-icon :name="rightSectionType === 'weeklyPreferences' ? 'settings-cog-404040' : 'settings-cog-6184ad'" />
                  <mdc-text tag="span" typo="caption">WORKPLAN</mdc-text>
                </mdc-button>
              </section>
              <template v-if="rightSectionType === 'weeklyPreferences'">
                <workplan :ref="'workplan'" @tooltipDesk="closeOtherTooltip('desk')" :showDate="showDate" :dateInvalid="dateInvalid" :useDefault="useDefault" :useLast="useLast" :openDate="rightSectionDate" @preferencesOpened="preferencesOpened = $event" @enableAddUser="addUserIconClick" @close="closeWeeklyPreferences" :expandedView="rightSideFullView" v-show="rightSectionType !== 'teamUpdates'" v-if="scheduleSearchWeekDetail" :openLocation="openLocation" @goToBestOfficeDays="setRightSectionType" />
              </template>
              <template v-else-if="rightSectionType === 'bestOfficeDays'">
                <recommendations :expandedView="rightSideFullView" @emitWeeklyPreferences="setRightSectionType"/>
              </template>
            </template>
            <office-activity :ref="'officeActivity'" @tooltipDesk="closeOtherTooltip('desk')" v-else></office-activity>
          </template>
        </app-section>
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AppLayout from '../../components/flexibly/AppLayout'
import AppSection from '../../components/flexibly/AppSection'
import ScheduleSearch from '../../components/flexibly/ScheduleSearch'
import ModalDialog from '../../components/flexibly/ModalDialog.vue'
import BaseSvgIcon from '../../components/flexibly/BaseSvgIcon.vue'
import { onboarding } from '../../utils/backend'
import PinnedSchedules from '../../components/flexibly/PinnedSchedules.vue'
import Recommendations from '../../components/flexibly/Recommendations.vue'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'
import Workplan from '../../components/flexibly/Workplan.vue'
import OfficeActivity from '../../components/flexibly/OfficeActivity.vue'
import moment from 'moment'

export default {
  name: 'People',
  computed: {
    ...mapState([
      'workspace',
      'profile',
      'profiles',
      'user',
      'notificationMode',
      'scheduleSearchWeekDetail',
      'locationIndicatorDialogDate'
    ]),
    ...mapState({
      lookingForwardPages: state => state.teams.lookingForwardPages,
      teamProfiles: state => state.teams.teamProfiles,
      requests: state => state.teams.requests,
      tempos: state => state.teams.tempos,
      historySchedules: state => state.historySchedules,
      myTeam: state => state.teams.team,
      teams: state => state.teams.teams,
      teamsLoaded: state => state.teams.teamsLoaded,
      initiatives: state => state.teams.initiatives,
      preferences: state => state.teams.preferences,
      schedules: state => state.teams.schedules,
      bestDaysCalculating: state => state.teams.bestDaysCalculating
    }),
    profilesFiltered () {
      if (this.profiles && this.myTeam) {
        if (this.myTeam.members) {
          return this.profiles.filter(profile => !this.myTeam.members.includes(profile.id))
        }
      }
      return []
    },
    mySchedule () {
      if (this.schedules) {
        const mySchedules = this.schedules.filter(sc => sc.fromId === this.profile.id)
        return mySchedules[0]
      }
      return false
    },
    myWeekPreferences () {
      if (this.preferences && this.preferences.length > 0) {
        return { ...this.preferences[0] }
      }
      return false
    }
  },
  mounted () {
    console.log('People.mounted', this.$route.params)
    this.$root.$emit('enableSearch', false)
    this.$emit('setNavActive', 'People')
    this.getUpdatesDetailsFromTempo()
    const action = (this.$route) ? this.$route.params.action : null
    const week = this.parseWeek((this.$route) ? this.$route.params.week : null)
    if (action) {
      if (action === 'workplan') {
        this.setRightSectionType({ section: 'weeklyPreferences', weeklyPreferences: true })
        if (week) {
          this.setRightSectionDate(week)
          this.showDate = week
        }
      } else if (action === 'bestdays') {
        this.setRightSectionType({ section: 'bestOfficeDays', weeklyPreferences: true })
        if (week) {
          this.setRightSectionDate(week)
          this.showDate = week
        }
      } else if (action === 'apply-last') {
        if (week) {
          this.showDate = week
          this.setRightSectionDate(week)
          this.useLast = true
          if (!this.isWeekEditable(week)) {
            this.dateInvalid = true
          }
        }
        this.setRightSectionType({ section: 'weeklyPreferences', weeklyPreferences: true })
      } else if (action === 'apply-default') {
        if (week) {
          this.showDate = week
          this.setRightSectionDate(week)
          this.useDefault = true
          if (!this.isWeekEditable(week)) {
            this.dateInvalid = true
          }
        }
        this.setRightSectionType({ section: 'weeklyPreferences', weeklyPreferences: true })
      }
      window.history.pushState({ urlPath: '/people' }, '', '/people')
    } else if (this.$route) {
      if (this.$route.params) {
        if (this.$route.params.showDate) {
          this.setRightSectionDate(this.$route.params.showDate)
        }
        if (this.$route.params.weeklyPreferences) {
          this.setRightSectionType({ section: this.$route.params.changeView, weeklyPreferences: true })
        } else if (this.$route.params.changeView) {
          this.setRightSectionType(this.$route.params.changeView)
        }
        if (this.$route.params.openModal === 'addNewUserModal') {
          this.addUserIconClick()
        }
      }
    }
  },
  created () {
    this.$root.$on('setRightSectionType', (viewType) => {
      this.setRightSectionType(viewType)
    })
    this.$root.$on('setRightSectionDate', (date) => {
      this.setRightSectionDate(date)
    })
  },

  beforeDestroy () {
    this.$root.$off('setRightSectionType', (viewType) => {
      this.setRightSectionType(viewType)
    })
    this.$root.$off('setRightSectionDate', (date) => {
      this.setRightSectionDate(date)
    })
  },
  components: {
    AppLayout,
    AppSection,
    ScheduleSearch,
    ModalDialog,
    BaseSvgIcon,
    Recommendations,
    PinnedSchedules,
    AutoCompleteOutline,
    Workplan,
    OfficeActivity
  },
  data () {
    return {
      scheduleSearchQuery: '',
      rightSideFullView: null,
      leftSideFullView: null,
      showSetupUpdates: false,
      showSettingsCogMenu: false,
      showSetupSchedules: false,
      showSettingsMenuFor: '',
      updatesPreferencesSubmitted: false,
      updatesPreferences: {},
      sendingInvite: false,
      inviteSent: false,
      teamInviteValid: true,
      inviteMessage: '',
      teamInviteEmail: '',
      teamInviteValue: false,
      showEditMembers: false,
      bottomProfileFirst: { displayName: '?', cardDesign: 'profile-colour-cyan', cardDesignBackground: 'profile-bg-bottom' },
      bottomProfileSecond: { displayName: '?', cardDesign: 'profile-colour-cyan', cardDesignBackground: 'profile-bg-bottom' },
      rightSectionType: 'bestOfficeDays',
      helpText: '',
      rightSectionDate: false,
      openLocation: false,
      preferencesOpened: false,
      showDate: false,
      useDefault: false,
      useLast: false,
      dateInvalid: false
    }
  },
  props: {
    desktop: {
      type: Boolean
    },
    isMobile: {
      type: Boolean
    },
    isTablet: {
      type: Boolean
    },
    isDesktopLarge: {
      type: Boolean
    },
    isTabletLarge: {
      type: Boolean
    },
    dynamicStyle: {}
  },
  methods: {
    ...mapActions([
      'setLocationIndicatorDialogDate'
    ]),
    ...mapActions({
      updateRequest: 'teams/updateRequest'
    }),
    parseWeek (week) {
      try {
        const parsed = moment(week, 'YYYY-MM-DD')
        if (parsed.isValid()) {
          return parsed
        }
      } catch (ex) {
        console.error(`Date format not recognized ${week}`, ex)
      }
      return false
    },
    closeOtherTooltip (type) {
      // console.log('closeOtherTooltip', type, this.$refs)
      if (type === 'desk') {
        if (this.$refs.searchSchedule) {
          this.$refs.searchSchedule.resetLocationPinClicked()
        }
      }
      if (type === 'location') {
        if (this.$refs.officeActivity) {
          this.$refs.officeActivity.resetTooltipType()
        }
        if (this.$refs.workplan) {
          this.$refs.workplan.resetTooltipType()
        }
      }
    },
    isWeekEditable (week) {
      return moment().isBefore(week)
    },
    closeWeeklyPreferences () {
      this.rightSectionType = 'bestOfficeDays'
    },
    setScheduleSearchQuery (query) {
      this.scheduleSearchQuery = query || ''
    },
    resetErrorState () {
      this.helpText = ''
      this.teamInviteEmail = ''
    },
    closeTempo () {
      this.$root.$emit('changePeopleView', 'teamUpdates')
    },
    setRightSectionDate (date) {
      this.rightSectionDate = date
    },
    setRightSectionType (type) {
      this.openLocation = false
      if (type === 'addNewUserModal') {
        this.addUserIconClick()
        return
      }
      if (type.section) {
        this.rightSectionType = type.section
      } else {
        this.rightSectionType = type
      }
      if (type.weeklyPreferences) {
        setTimeout(() => {
          if (type.openLocation === false) {
            this.openLocation = false
          } else {
            this.openLocation = true
            this.setLocationIndicatorDialogDate(false)
            if (type.openDate) {
              this.rightSectionDate = this.scheduleSearchWeekDetail.selectedDay
            } else {
              this.rightSectionDate = false
            }
          }
        }, 30)
      }
    },
    inDomains (email) {
      const emailDomain = email.split('@')[1].toLowerCase()
      if (this.workspace) {
        if (this.workspace.domains) {
          for (const domain of this.workspace.domainUrls) {
            if (emailDomain === domain) {
              return true
            }
          }
        } else {
          return this.workspace.domainUrl === emailDomain
        }
      }
      return false
    },
    async addInvite () {
      this.helpText = ''
      const guestsAllowed = this.workspace.guestAccessWorkspace
      const self = this
      setTimeout(() => {
        self.sendingInvite = false
      }, 2000)
      if (this.teamInviteEmail) {
        const teamAddArr = []
        const newInviteArr = []
        this.teamInviteEmail = this.teamInviteEmail.toLowerCase()
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const emailArr = this.teamInviteEmail.replaceAll(' ', '').split(',')

        for (const emailPart of emailArr) {
          if (!emailPart.match(emailRegex)) {
            this.helpText = `Please enter a valid email: ${emailPart}`
            return
          }

          if (!guestsAllowed) {
            if (!this.inDomains(emailPart)) {
              this.helpText = `Your workspace does not allow adding of guests. Please enabled this option or enter a valid email: ${emailPart}`
              return
            }
          }

          // console.log('People.addInvite.workspace', this.workspace)
          let inWorkspace = false
          if (this.workspace && this.workspace.members) {
            inWorkspace = this.workspace.members.includes(this.teamInviteEmail)
          }
          if (inWorkspace) {
            teamAddArr.push(emailPart)
            const inviteProfiles = this.profiles.filter(pr => pr.email.toLowerCase() === emailPart)
            if (inviteProfiles.length) {
              const inviteProfileId = inviteProfiles[0]
              if (this.myTeam.members.includes(inviteProfileId)) {
                this.helpText = 'This user already belongs to this team'
                return
              }
            }
          } else {
            newInviteArr.push(emailPart)
          }
        }
        this.sendingInvite = true
        // console.log('addInvite.send-invites', teamAddArr, teamAddArr.length, newInviteArr, newInviteArr.length)
        try {
          if (teamAddArr.length) {
            // Already members of this workspace add invite to team
            const params = {
              userId: this.user.uid,
              emails: teamAddArr
            }
            // eslint-disable-next-line no-unused-vars
            const response = await onboarding('invite-to-team', params)
            console.log('People.addInvite.invite-to-team', response)
          }
          if (newInviteArr.length) {
            // Not members of this workspace - add invite to workspace / team
            // eslint-disable-next-line no-unused-vars
            const params = {
              userId: this.user.uid,
              invites: newInviteArr
            }
            // eslint-disable-next-line no-unused-vars
            const response = await onboarding('send-invites', params)
            console.log('People.addInvite.send-invites', response)
          }

          for (const email in emailArr) {
            const matchingRequests = this.requests.filter((req) => req.email === email)
            if (matchingRequests) {
              for (const mRequest in matchingRequests) {
                if (!mRequest.completed) {
                  this.updateRequest({ requestId: mRequest.id, request: { completed: true } })
                }
              }
            }
          }
        } catch (error) {
          console.error('addInvite.send-invites', error)
        }
        this.$nextTick(() => {
          const self = this
          this.teamInviteEmail = ''
          this.sendingInvite = false
          this.inviteSent = true
          setTimeout(() => {
            self.inviteSent = false
          }, 3000)
        })
      } else {
        this.helpText = 'Please enter an email address to send the invite.'
      }
    },
    setTeamInviteEmail (val) {
      this.teamInviteEmail = val
    },
    getUpdatesDetailsFromTempo () {
      if (this.tempos !== null && this.tempos.length > 0 && this.profile) {
        const updates = this.tempos.filter(tempo => tempo.name === 'Looking Forward' && tempo.teamId === this.profile.team)
        if (updates.length > 0) {
          this.updatesPreferences = updates[0]
          this.$nextTick(() => {
            if (this.updatesPreferences.preferencesSubmitted) {
              this.updatesPreferencesSubmitted = true
              this.showSetupUpdates = false
            } else {
              this.updatesPreferencesSubmitted = false
              this.showSetupUpdates = false
            }
          })
        }
      }
    },
    addUserIconClick () {
      this.showEditMembers = true
    },
    leftSettingsCogClick () {
      this.showSettingsCogMenu = true
      this.showSettingsMenuFor = 'people-schedules'
    },
    setLeftSideFullView (value) {
      this.leftSideFullView = value
    },
    setRightSideFullView (value) {
      this.rightSideFullView = value
    },
    closeSetupUpdates () {
      this.showSetupUpdates = false
    },
    closeSetupSchedules () {
      this.showSetupSchedules = false
    },
    enableSetupUpdates () { // from menu item click emit -- settings cog
      this.showSetupUpdates = false
      this.showSettingsCogMenu = false
      this.showSettingsMenuFor = ''
    },
    enableSetupSchedules () {
      this.showSetupSchedules = true
      this.showSettingsCogMenu = false
      this.showSettingsMenuFor = ''
    },
    closeSettingsCogMenu () { // menu close -- settings cog
      this.showSettingsCogMenu = false
    },
    onSearch (str, val) {
      this.teamInviteValue = val.email
      this.teamInviteEmail = val.email
    },
    onDelete () {
    },
    onClear () {
    }
  },
  watch: {
    profile: {
      handler (newP, oldP) {
        if (!oldP?.id && this.profile && this.profile.id) {
          console.log('Onboarding.watch profile', this.profile)
          if (this.profile.displayOnboarding && this.$router) {
            this.$router.push('/onboarding')
          }
        }
      },
      immediate: true
    },
    teamProfiles: {
      handler () {
        // console.log('people.watch.teamProfiles', this.teamProfiles)
      }
    },
    historySchedules: {
      handler () {
        // console.log('people.watch.historySchedules', this.historySchedules)
      }
    },
    tempos: {
      handler (newTempos, oldTempos) {
        this.getUpdatesDetailsFromTempo()
      }
    },
    teamInviteEmail: {
      handler () {
        if (this.teamInviteEmail) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          if (this.teamInviteEmail.match(emailRegex)) {
            this.helpText = ''
          }
        }
      }
    },
    scheduleSearchWeekDetail: {
      handler () {
        this.openLocation = false
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
.people-wrapper .helptext {
  color: #b00020;
}
.people-wrapper .team-tree-wrapper {
  display:flex;
}
.people-wrapper .team-tree {
  flex-flow: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  margin-left: auto;
  margin-right: auto;
}
.people-wrapper .tree-row {
  height: 1px;
  background-color: #666;
  width: 50%;
  margin: 16px;
  position: relative;
}
.people-wrapper .tree-row:before {
  content: '|';
  color:#666;
  position:absolute;
  top:-18px;
  left:50%;
}
.people-wrapper .tree-row:after {
  content: '';
  border-left:1px solid #666;
  border-right:1px solid #666;
  height:18px;
  display:block;
}
.people-wrapper .bottom-row {
  display:flex;
  min-width:290px;
}
</style>

<style lang="scss">
@keyframes rotate {
  to {
    stroke-dashoffset: 150;
  }
}
.people-wrapper {
  height: 100%;
  margin-bottom: 30px;
  .app-layout {
    .flex-col-layout {
      .app-section {
        .section-title:not(:empty) {
          z-index: 20;
        }
      }
      .whoswherewhen-section-layout {
        .section-title:not(:empty) {
          flex-direction: row-reverse;
          width: max-content;
          .title-content {
            flex-direction: row-reverse;
            .section-controls {
              .settings-icon {
                margin-right: auto;
              }
            }
          }
        }
      }
      .updates-section-layout {
        .section-title:not(:empty) {
          flex-direction: row-reverse;
        }
        .sub-section-types {
          display: flex;
          margin: 0 30px 0 22px;
          border-bottom: 0.5px solid #f1f1f1;
          align-items: center;
          &.fullscreen-mode {
           margin: 0 0 0 10px;
          }
          .header-link {
            height: 30px;
            margin-right: 40px;
            @media only screen and (max-width: 1200px) {
              margin-right: 10px;
            }
            &.location-pref-header {
              position: relative;
              .svg-dashed-box {
                display: none;
              }
              &.set-preferences {
                box-shadow: 0px 0px 1px rgb(0 0 0 / 25%);
                border-radius: 20px;
                .svg-dashed-box {
                  display: block;
                  width: 105px;
                  height: 31px;
                  overflow: visible;
                  position: absolute;
                  top: -6px;
                  bottom: 0;
                  left: -5px;
                  right: 0;
                  rect {
                    stroke: #ffde32;
                    fill: transparent;
                    stroke-dasharray: 3, 3;
                    animation: rotate 7s linear infinite;
                  }
                }
              }
            }
            &:last-of-type {
              margin-left: 10px;
            }
            .icon-wrapper {
              min-width: 12px;
              min-height: 12px;
              margin-right: 5px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .mdc-text {
              color: #6184AD;
              font-weight: 600;
              white-space: nowrap;
              @media only screen and (max-width: 1100px) {
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            &.clicked {
              background: #F1F1F1;
              border-radius: 4px 4px 0px 0px;
              .mdc-text {
                color: #404040;
              }
            }
          }
        }
      }
      &.leftside-full-view {
        grid-template-columns: 1fr 40px;
        .whoswherewhen-section-layout {
          #autocomplete-people {
            max-width: 720px;
          }
          .section-content {
            max-width: 720px;
            margin: auto;
          }
        }
      }
      &.rightside-full-view {
        grid-template-columns: 40px 1fr;
        .updates-section-layout {
          .section-content {
            max-width: 650px;
            margin: auto;
          }
          .settings-cog-menu.people-updates {
            left: 650px;
            right: unset;
            margin-left: 130px;
          }
        }
      }
      .title-content {
        .info-icon {
          display: none;
        }
      }
    }
  }
}
.add-member-modal {
  .mdc-dialog__surface {
    max-width: 680px;
    min-width: 650px;
    min-height: 225px;
    overflow: visible;
    .mdc-dialog__content {
      .member-invite-heading {
        color: #404040;
      }
      .member-invite-section {
        display: flex;
        .autocomplete.outlined {
          margin-top:-6px !important;
          min-height: 54px;
          .autocomplete-results {
            position: absolute;
            max-width: 449px;
            margin-left: 0px;
          }
          .autocomplete-input {
            max-width: 440px;
            padding-top: 0;
            height: 50px;
          }
          .autocomplete-input::placeholder {
            color: #979797 !important;
            opacity:1 !important;
          }
        }
        .mdc-text-field-wrapper {
          display: flex;
          max-width: 440px;
          padding-top: 0;
          .mdc-text-field {
            &--outlined {
              height: 50px;
            }
            &__input {
              &::placeholder {
                opacity: 1 !important;
              }
            }
          }
        }
        .mdc-button {
          height: 50px;
          min-width: 150px;
          background: #00498D;
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
      .sending-status {
        &:after {
          content: ' .';
          animation: dots 1s steps(5, end) infinite;
          font-weight: 600;
          font-size: 18px;
          color: #959EA7;
        }
      }
    }
  }
}
</style>
