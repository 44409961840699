<template>
  <div class="profile-settings-wrapper">
    <section v-if="settingHeadingType.subType === 'info'" class="profile-info sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Profile information</mdc-text>
      </header>
      <div class="profile-info-content type-content">
        <div class="name-wrapper field-wrapper">
          <span class="name-label label">
            <mdc-text tag="span" typo="caption">FULL NAME</mdc-text>
            <base-svg-icon name="pen-edit" clickable class="edit-icon" @click.native="editName()" />
          </span>
          <template  v-if="editNameMode">
            <div class="name-edit-wrapper">
              <input
                type="text"
                class="firstname-field"
                v-model="profileFirstName"
                placeholder="First name"
              />
              <input
                type="text"
                class="lastname-field"
                v-model="profileLastName"
                placeholder="Last name"
              />
            </div>
            <div class="edit-actions">
              <mdc-button @click.native="saveProfileName()" outlined>Save</mdc-button>
              <mdc-button @click.native="editName()" outlined>Cancel</mdc-button>
            </div>
          </template>
          <span class="profile-name" v-else>
            <mdc-text tag="span" typo="body2" class="name">{{ profile.displayName }}</mdc-text>
          </span>
        </div>
        <div class="email-wrapper field-wrapper">
          <span class="email-label label">
            <mdc-text tag="span" typo="caption">EMAIL</mdc-text>
          </span>
          <span class="email-id">
            <mdc-text tag="span" typo="body2" class="email">{{ profile.email }}</mdc-text>
          </span>
          <span class="email-change-info">
            <mdc-text tag="span" class="change-text">If you would like to change your email, please email</mdc-text>
            <a class="support-link" target="_blank" href="mailto:support@duome.co">support@duome.co</a>
            <mdc-text tag="span" class="change-text">for assistance</mdc-text>
          </span>
        </div>
        <div class="timezone-wrapper field-wrapper">
          <span class="timezone-label label">
            <mdc-text tag="span" typo="caption">TIME ZONE</mdc-text>
          </span>
          <div class="timezone-options">
            <mdc-text tag="span" typo="body2" class="timezone">{{timezone}}</mdc-text>
          </div>
          <span class="timezone-help-info">Timezone is automatically set based on your browser or system time</span>
        </div>
      </div>
    </section>
    <section v-if="settingHeadingType.subType === 'notifications'" class="notifications sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Notifications</mdc-text>
      </header>
      <div class="notifications-content type-content">
        <notification-settings displayFor="settings"/>
      </div>
    </section>
    <section v-if="settingHeadingType.subType === 'calendarconnect'" class="calendar-connect sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Calendar connect</mdc-text>
      </header>
      <calendar-connect />
      <!-- <setup-calendar displayFor="settings" /> -->
    </section>
    <section v-if="settingHeadingType.subType === 'consent'" class="consent sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Consent</mdc-text>
      </header>
      <div class="consent-content type-content">
        <mdc-text tag="span" typo="body2" class="consent-date">{{ 'User Consent was provided by accepting terms upon signup on ' + getConsentTimeFormat(profile.consentTimeStamp) }}.</mdc-text>
        <div class="consent-support-text">
          <mdc-text tag="span" typo="body2" class="text">You can remove consent at anytime by emailing</mdc-text>
          <a class="support-link" target="_blank" href="mailto:support@duome.co">support@duome.co</a>
          <mdc-text tag="span" typo="body2" class="text">and requesting your account to be deleted.</mdc-text>
        </div>
      </div>
    </section>
    <section v-if="settingHeadingType.subType === 'cookies'" class="cookies sub-type">
      <cookie-settings />
    </section>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from '../flexibly/BaseSvgIcon.vue'
import moment from 'moment'
import NotificationSettings from '../flexibly/NotificationSettings.vue'
import CalendarConnect from '../flexibly/CalendarConnect.vue'
import CookieSettings from '../flexibly/CookieSettings.vue'
export default {
  name: 'ProfileSettings',
  data () {
    return {
      editNameMode: false,
      profileFirstName: '',
      profileLastName: ''
    }
  },
  props: {
    settingHeadingType: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    BaseSvgIcon,
    NotificationSettings,
    CalendarConnect,
    CookieSettings
  },
  computed: {
    ...mapState([
      'profile',
      'timezone'
    ])
  },
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    saveProfileName () {
      this.updateProfile({ profile: { firstName: this.profileFirstName, lastName: this.profileLastName, displayName: this.profileFirstName + ' ' + this.profileLastName } }).then(() => {
        this.editNameMode = false
      })
    },
    editName () {
      this.initialize()
      this.editNameMode = !this.editNameMode
    },
    initialize () {
      if (this.settingHeadingType && this.settingHeadingType.subType === 'info') {
        this.profileFirstName = this.profile.firstName
        this.profileLastName = this.profile.lastName
      }
    },
    getConsentTimeFormat (timestamp) {
      return moment.unix(timestamp.seconds).local().format('Do MMMM YYYY [at] HH:mm')
    }
  },
  beforeDestroy () {
  },
  mounted () {
    this.initialize()
  },
  watch: {
    'settingHeadingType.subType': {
      handler () {
        this.initialize()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.profile-settings-wrapper {
  .sub-type {
    max-width: 600px;
    .type-heading {
      margin-bottom: 20px;
      .mdc-text {
        color: #959EA7;
        font-weight: 700;
      }
    }
    .type-content {
      .support-link {
        color: #6184AD;
        font-weight: 600;
        text-decoration: none;
      }
      .field-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        .label {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          text-transform: uppercase;
          .mdc-text {
            margin-right: 5px;
            color: #828282;
          }
          .icon-wrapper {
            &.edit-icon {
              height: 12px;
              width: 12px;
              img {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
        &.timezone-wrapper {
          .timezone-options {
            .timezone {
              color: #404040;
              font-weight: 600;
            }
          }
          .timezone-help-info {
            margin-top: 5px;
            font-size: 10px;
            color: #404040;
            font-weight: 400;
            margin-right: 3px;
            margin-left: 0 !important;
          }
        }
        &.name-wrapper {
          .name-edit-wrapper {
            display: flex;
            flex-direction: column;
            input {
              max-width: 180px;
              border-radius: 4px;
              border: 0.5px solid #959EA7;
              margin-bottom: 10px;
              padding: 5px;
              font-size: 14px;
              color: #404040;
              height: 20px;
            }
          }
          .edit-actions {
            display: flex;
            align-items: center;
            .mdc-button {
              max-width: 30px;
              color: #6184Ad;
              margin-right: 30px;
              font-size: 12px;
              height: 25px;
            }
          }
        }
      }
      &.profile-info-content {
        .profile-name {
          .name {
            color: #404040;
            font-weight: 600;
          }
        }
        .email-id {
          .email {
            color: #828282;
            font-weight: 600;
          }
        }
        .email-change-info {
          margin-top: 5px;
          .change-text {
            font-size: 10px;
            color: #404040;
            font-weight: 400;
            margin-right: 3px;
          }
          .support-link {
            font-size: 10px;
            margin-right: 3px;
          }
        }
      }
      &.consent-content {
        .consent-date {
          color: #404040;
          font-weight: 600;
          line-height: 23px;
        }
        .consent-support-text {
          margin-top: 20px;
          * {
            margin-right: 3px;
          }
          .text {
            color: #404040;
            font-weight: 400;
            line-height: 23px;
          }
          .support-link {
            font-size: 14px;
          }
        }
      }
      &.calendar-connect-content {
        .setup-badges {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin:  0 0 40px 0;
          width: 100%;
          flex-wrap: wrap;
          .icon-wrapper {
            margin-right: 10px;
          }
        }
        .about-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .mdc-text {
            color: #404040;
            margin-bottom: 10px;
          }
        }
        .calendar-types {
          display: flex;
          flex-direction: column;
          align-items: center;
          .mdc-button {
            margin-bottom: 10px;
            width: 220px;
            max-width: 280px;
            justify-content: flex-start;
            .icon-wrapper {
              margin-right: 20px;
            }
            .mdc-text {
              color: #404040;
              text-transform: none;
              font-weight: 600;
            }
          }
        }
      }
    }
    &.profile-info {
      .type-heading {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
