<template>
  <div ref="sharingMenu"  class="note-block-menu" v-if="showNoteBlockMenu">
    <div class="visibility-slider">
      <!-- <mdc-switch /> bg-image switch-->
      <label class="switch" for="block-switch">
        <input v-model="contentVisible" type="checkbox" id="block-switch" @change="toggleVisibilitySwitch" />
        <div class="slider round"></div>
      </label>
      <mdc-text tag="span" class="switch-text" typo="caption">{{ !contentVisible ? 'Slide to share this content' : 'Slide to make this content private.'}}</mdc-text>
    </div>
    <div v-bind:class="{'disabled': !contentVisible}">
      <div class="share-input" v-bind:class="{'disabled': !contentVisible}">
          <auto-complete-outline
            autocompleteId="'note-search'"
            class="share-autocomplete"
            field="name"
            :value="query"
            :placeholder="'Type @ or # to share'"
            :items="items"
            @set="setShare"
            :min="1"
            ref="itemAdd"
            >
            </auto-complete-outline>
      </div>
      <div class="recent-shares share-section" v-bind:class="{'disabled': !contentVisible}">
        <mdc-text tag="span" class="section-text" style="margin-bottom:10px;display:block" typo="body2">Shared Users</mdc-text>
        <div style="display:flex; align-items:center;" v-for="share in item.shareUsers" :key="share.id">
           <span>{{getUser(share).displayName}}</span>
          <round-button background="transparent" style="margin:0;margin-left:auto;" @click.stop.prevent="removeShareUser(share)" class="close" title="Remove" icon="close" label="Remove"/>
        </div>
      </div>
      <div class="recent-tags share-section" v-bind:class="{'disabled': !contentVisible}">
        <mdc-text tag="span" class="section-text" style="margin-bottom:10px;display:block" typo="body2">Shared Tags</mdc-text>
        <div style="display:flex; align-items:center;" v-for="share in item.shareInitiatives" :key="share.id">
          <span>{{getInitiative(share).name}}</span>
          <round-button background="transparent" style="margin:0;margin-left:auto;" @click.stop.prevent="removeShareInitiative(share)" class="close" title="Remove" icon="close" label="Remove"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'

export default {
  name: 'ShareMenu',
  props: {
    showNoteBlockMenu: {
      type: Boolean,
      default: false
    },
    menuForNoteId: {
      type: String,
      default: ''
    },
    menuForPageId: {
      type: String,
      default: ''
    },
    item: {
    }
  },
  components: {
    AutoCompleteOutline
  },
  data () {
    return {
      toggleVisibility: false,
      contentVisible: false,
      windowWidth: window.innerWidth,
      query: ''
    }
  },
  computed: {
    ...mapState([
      'user',
      'workspace',
      'profile'
    ]),
    ...mapState({
      initiatives: state => state.teams.initiatives,
      teamProfiles: state => state.teams.teamProfiles
    }),
    items () {
      let items = []
      items = [...this.teamProfiles.map((p) => { return { id: p.id, name: `@${p.displayName}`, type: 'user' } })]
      items = [...items, ...this.initiatives.map((p) => { return { id: p.id, name: `#${p.name}`, type: 'tag' } })]
      return items
    }
  },
  methods: {
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target))) {
        this.$emit('closeNoteBlockMenu', false)
        this.resetData()
      } else {
        // to be done
      }
    },
    toggleVisibilitySwitch () {
      this.$emit('setShareEnabled', !this.item.shareEnabled)
    },
    getInitiative (initiativeId) {
      return this.initiatives.filter((i) => i.id === initiativeId)[0]
    },
    getUser (userId) {
      return this.teamProfiles.filter((p) => p.id === userId)[0]
    },
    setShare (selected, item) {
      this.query = selected.name
      this.contentVisible = true
      this.$emit('setShare', selected, item)
      this.query = ''
      this.$refs.itemAdd.clearSearch()
    },
    removeShareUser (item) {
      this.$emit('removeShare', 'user', item)
    },
    removeShareInitiative (item) {
      this.$emit('removeShare', 'tag', item)
    },
    resetData () {
      this.toggleVisibility = false
      this.query = ''
    },
    positionSharingMenu () {
      const sharingMenuElement = this.$refs.sharingMenu
      if (sharingMenuElement) {
        if (this.menuForNoteId) { // note share menu
          if (!this.isInViewport(sharingMenuElement)) {
            const noteBlockElementHeight = document.querySelectorAll('.block-wrapper.menu-opened')[0]?.offsetHeight
            sharingMenuElement.style.marginTop = `${noteBlockElementHeight + 3}px`
            sharingMenuElement.style.left = 0
          } else {
            sharingMenuElement.style.marginTop = 10 + 'px'
            sharingMenuElement.style.left = 'unset'
          }
        } else { // page share menu
          if (!this.isInViewport(sharingMenuElement)) {
            sharingMenuElement.style.top = 85 + 'px'
            sharingMenuElement.style.left = 0
            sharingMenuElement.style.marginTop = 0
          } else {
            sharingMenuElement.style.marginTop = -48 + 'px'
            sharingMenuElement.style.top = 105 + 'px'
            sharingMenuElement.style.left = 'unset'
          }
        }
      }
    },
    isInViewport (el) {
      const rect = el.getBoundingClientRect()
      const vWidth = window.innerWidth || document.documentElement.clientWidth
      const vHeight = window.innerHeight || document.documentElement.clientHeight
      const efp = function (x, y) {
        return document.elementFromPoint(x, y)
      }

      // Return false if it's not in the viewport
      if (rect.left < 0 || rect.bottom < 0 || rect.right > vWidth || rect.top > vHeight) { return false }

      // Return true if any of its four corners are visible
      return (
        el.contains(efp(rect.left, rect.top)) ||
          el.contains(efp(rect.right, rect.top)) ||
          el.contains(efp(rect.right, rect.bottom)) ||
          el.contains(efp(rect.left, rect.bottom))
      )
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
    window.removeEventListener('resize', this.positionSharingMenu)
  },
  mounted () {
    if (this.item) {
      this.contentVisible = this.item.shareEnabled
    }
  },
  watch: {
    item: {
      handler () {
        if (this.item) {
          this.contentVisible = this.item.shareEnabled
        }
      },
      immediate: true
    },
    showNoteBlockMenu: {
      handler () {
        if (this.showNoteBlockMenu) {
          const self = this
          this.$nextTick(() => {
            this.positionSharingMenu()
          })
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
            window.addEventListener('resize', self.positionSharingMenu)
          }, 250)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
            window.removeEventListener('resize', self.positionSharingMenu)
          }, 250)
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
.share-autocomplete .mdc-notched-outline__leading {
    border-left:none !important;
    border-radius: 0 !important;
}
.share-autocomplete .mdc-notched-outline__trailing {
    border-right:none !important;
    border-radius: 0 !important;
}
.share-autocomplete .autocomplete-input::placeholder {
    display:block !important;
    visibility: visible !important;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.33) !important;
    opacity: 1 !important;
}
.share-autocomplete .clear-search {
  height: 54px;
}
</style>

<style lang="scss" scoped >
.note-block-menu {
  background: #FEFEFE;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  position: absolute;
  top: 2px;
  width: 285px;
  z-index: 10;
  right: 100%;
  margin-right: 35px;
  margin-top: 10px;
  .visibility-slider {
    display: flex;
    align-items: center;
    padding: 14px;
    .switch {
      display: inline-block;
      height: 10px;
      position: relative;
      width: 40px;
      input {
        display:none;
        &:checked {
          + .slider {
            background-color: #4F4F4F;
            &:before {
              transform: translateX(26px);
              background-image: url('/static/img/icons/flexibly/opened-eye.svg');
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }
      .slider {
        background-color: #ccc;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .2s;
        &:before {
          background-color: #fff;
          bottom: -3px;
          content: "";
          height: 18px;
          position: absolute;
          transition: .2s;
          width: 18px;
        }
        &.round {
          border-radius: 18px;
          &:before {
            border-radius: 50%;
            background-image: url('/static/img/icons/flexibly/closed-eye.svg'), url('/static/img/icons/flexibly/closed-eye-part2.svg');
            background-repeat: no-repeat;
            background-position: center, 6.5px 6px;
            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
    .switch-text {
      color: #404040;
      margin-left: 20px;
    }
  }
  .share-input {
    margin-top: 0;
    text-align: center;
    input {
      color: #4f4f4f;
      font-size: 14px;
      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 23px;
      border: 1px solid rgba(255, 255, 255, 1);
      padding: 7px 10px;
      width: 240px;
      &::placeholder {
        display:block;
        visibility: visible;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.33);
      }
    }
  }
  .share-section {
    padding: 14px;
    border-bottom: 1px solid #E0E0E0;
    .section-text {
      color: #4f4f4f;
    }
  }
  .comments {
    color: #1F5CB8;
    display: flex;
    align-items: center;
    .mdc-icon {
      color: #1F5CB8;
      font-size: 14px;
      margin-right: 5px;
    }
  }
  &.page-share-menu {
    top: 105px;
    margin-top: -48px;
    margin-right: 0;
  }
}
</style>
