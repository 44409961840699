<template>
<!-- change opacity in calling component -->
  <div class="calendar" v-if="displayCalendar" v-bind:class="{ 'default': !showPresets }">
    <div class="location-presets" v-if="showLocationPresets">
      <h4>PICK A LOCATION</h4>
      <div>
        <mdc-button @click="setPreset('office')">
          <base-svg-icon name="office" />
          <mdc-text typo="caption" tag="span">Office</mdc-text>
        </mdc-button>
        <mdc-button @click="setPreset('remote')">
          <base-svg-icon name="home" />
          <mdc-text typo="caption" tag="span"> REMOTE</mdc-text>
        </mdc-button>
      </div>
    </div>
    <div class="day-presets" v-if="showPresets">
      <h4>PICK A DATE</h4>
      <div v-if="!weekPresets">
        <mdc-button @click="setPreset('today')">
          <mdc-text typo="caption" tag="span">Today</mdc-text>
        </mdc-button>
        <mdc-button @click="setPreset('tomorrow')">
          <mdc-text typo="caption" tag="span">Tomorrow</mdc-text>
        </mdc-button>
        <mdc-button @click="setPreset('nextweek')">
          <mdc-text typo="caption" tag="span">Next Week</mdc-text>
        </mdc-button>
        <mdc-button @click="setPreset('someday')">
          <mdc-text typo="caption" tag="span">Some Day</mdc-text>
        </mdc-button>
      </div>
      <div v-else>
        <mdc-button @click="setPreset('nextweek')">
          <mdc-text typo="caption" tag="span">Next week</mdc-text>
        </mdc-button>
        <mdc-button @click="setPreset('twoweeks')">
          <mdc-text typo="caption" tag="span">Two weeks</mdc-text>
        </mdc-button>
        <mdc-button @click="setPreset('fourweeks')">
          <mdc-text typo="caption" tag="span">Four weeks</mdc-text>
        </mdc-button>
      </div>
    </div>
    <div class="calendar-controls">
      <base-svg-icon name="redarrowleft" class="month-nav-icon" @click.native="previousMonth" clickable />
      <mdc-text typo="body2" tag="span" class="calendar-current-month">
          {{displayMonth}} {{displayYear}}
      </mdc-text>
      <base-svg-icon name="redarrowright" class="month-nav-icon" @click.native="nextMonth" clickable />
    </div>
    <div class="calendar-days">
      <div class="calendar-days-header">
        <span v-for="(dayHeader, index) in dayHeaders" :key="'day-header-'+index" class="calendar-day-header">
            {{dayHeader.display}}
        </span>
      </div>
      <div class="calendar-days-content">
        <div v-for="(dayrow, rindex) in dayrows" :key="'row-'+rindex" class="calendar-day-row">
          <span
            v-for="(day, index) in dayrow"
            :key="'day-'+rindex+'-'+index"
            class="calendar-day"
            v-bind:class="[isSelected(day) ? 'selected' : '' , checkIsBeforeToday(day) && disablePastDates ? 'disabled': '' ]">
            <template v-if="day">
              <span @click="selectDate(day)" class="date-icon">
                <span class="day" v-bind:class="[checkIsToday(day) ? 'today' : '', checkIsBeforeToday(day) && disablePastDates ? 'disabled': '']">{{day.date()}}</span>
              </span>
            </template>
            <template v-else>
            </template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import BaseSvgIcon from './BaseSvgIcon'

export default {
  name: 'Calendar',
  components: {
    BaseSvgIcon
  },
  props: {
    disablePastDates: {
      type: Boolean,
      default: false
    },
    displayCalendar: {
      type: Boolean,
      default: false
    },
    resetDates: {
      type: Boolean,
      default: false
    },
    showPresets: {
      type: Boolean,
      default: false
    },
    showLocationPresets: {
      type: Boolean,
      default: false
    },
    weekPresets: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  data () {
    return {
      displayMonth: null,
      displayYear: null,
      selectedDate: null,
      selectedMonth: null,
      selectedYear: null,
      selectedMonthYear: null,
      dayHeaders: [
        { index: 0, display: 'M' },
        { index: 1, display: 'T' },
        { index: 2, display: 'W' },
        { index: 3, display: 'T' },
        { index: 4, display: 'F' },
        { index: 5, display: 'S' },
        { index: 6, display: 'S' }
      ],
      days: [],
      dayrows: []
    }
  },
  methods: {
    ...mapActions({
    }),
    getDays (month, year) {
      this.displayMonth = this.selectedMonthYear.format('MMMM').toUpperCase()
      this.displayYear = this.selectedMonthYear.format('YYYY')
      const daysInMonth = moment({ year, month, day: 1 }).daysInMonth()
      this.days = Array.from({ length: daysInMonth }, (v, k) => moment({ year, month, day: k + 1 }))
      let currentRow = []
      this.dayrows = []
      let padmonth = this.days[0].day() - 1
      if (padmonth === -1) {
        padmonth = 6
      }
      if (padmonth > -1) {
        currentRow = new Array(padmonth)
      }
      for (const day of this.days) {
        currentRow.push(day)
        if (currentRow.length === 7) {
          this.dayrows.push([...currentRow])
          currentRow = []
        }
      }
      if (currentRow.length > 0) {
        this.dayrows.push([...currentRow])
      }
    },
    nextMonth () {
      const currentMonth = moment({ year: this.selectedMonthYear.year(), month: this.selectedMonthYear.month(), day: 1 })
      const nextMonth = currentMonth.add(1, 'months')
      this.selectedMonthYear = nextMonth
    },
    previousMonth () {
      const currentMonth = moment({ year: this.selectedMonthYear.year(), month: this.selectedMonthYear.month(), day: 1 })
      const prevMonth = currentMonth.subtract(1, 'months')
      this.selectedMonthYear = prevMonth
    },
    selectDate (date) {
      this.selectedDate = date
      this.$emit('selected', date, '')
    },
    isSelected (date) {
      if (!date) return false
      return date.isSame(this.selectedDate, 'day')
    },
    setPreset (type) {
      switch (type) {
        case 'today':
          this.selectedDate = moment()
          this.selectedType = type
          this.$emit('selected', this.selectedDate, type)
          break
        case 'tomorrow':
          this.selectedDate = moment().add(1, 'days')
          this.selectedType = type
          this.$emit('selected', this.selectedDate, type)
          break
        case 'nextweek':
          this.selectedDate = moment().startOf('isoWeek').add(1, 'week')
          this.selectedType = type
          this.$emit('selected', this.selectedDate, '')
          break
        case 'twoweeks':
          this.selectedDate = moment().startOf('isoWeek').add(2, 'week')
          this.selectedType = type
          this.$emit('selected', this.selectedDate, '')
          break
        case 'fourweeks':
          this.selectedDate = moment().startOf('isoWeek').add(4, 'week')
          this.selectedType = type
          this.$emit('selected', this.selectedDate, '')
          break
        case 'remote':
        case 'office':
        case 'someday':
        default:
          this.selectedDate = null
          this.selectedType = type
          this.$emit('selected', this.selectedDate, type)
          break
      }
    },
    checkIsToday (day) {
      if (day) {
        if (moment(day).isSame(moment(), 'day')) {
          return true
        }
        return false
      }
    },
    checkIsBeforeToday (day) {
      if (day) {
        if (moment(day).isBefore(moment(), 'day')) {
          return true
        }
        return false
      }
    }
  },
  mounted () {
    if (!this.selectedYear) {
      this.selectedYear = moment().year()
    }
    if (!this.selectedMonth) {
      this.selectedMonth = moment().month()
    }
    this.selectedMonthYear = moment({ year: this.selectedYear, month: this.selectedMonth })
  },
  watch: {
    selectedMonthYear: {
      handler () {
        // console.log('watch.selectedMonthYear', this.selectedMonthYear)
        if (this.selectedMonthYear) {
          this.getDays(this.selectedMonthYear.month(), this.selectedMonthYear.year())
        }
      },
      immediate: true
    },
    resetDates: {
      handler () {
        this.selectedDate = null
      }
    }
  }
}
</script>

<style lang="scss">
  .calendar {
    opacity: 0;
    white-space: nowrap;
    width: fit-content;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 2px 1px rgba(0,0,0,0.28);
    position: relative;
    overflow: hidden;
    z-index: 40;
    .location-presets {
      display: flex;
      flex-direction: column;
      padding: 10px 6px 0 6px;
      h4 {
        margin: 5px 0;
        color: #494949;
        font-weight: 600;
        font-size: 12px;
        opacity: 0.4;
      }
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        .mdc-button {
          padding: 10px 5px;
          display: flex;
          align-items: baseline;
          justify-content: center;
          .mdc-icon {
            font-size: 15px;
            margin: 0;
            line-height: normal;
          }
          .mdc-text {
            color: #333333;
            font-weight: 600;
            margin-left: 2px;
          }
        }
      }
    }
    .day-presets {
      display: flex;
      flex-direction: column;
      background: rgba(243,243,243, 0.44);
      padding: 10px 6px 0 6px;
      h4 {
        margin: 5px 0;
        color: #494949;
        font-weight: 600;
        font-size: 12px;
        opacity: 0.4;
      }
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .mdc-button {
          padding: 5px;
          .mdc-text {
            color: #333333;
            font-weight: 600;
          }
        }
      }
    }
    &.default {
      .calendar-controls {
        background: #fff;
      }
      .calendar-days {
        padding-top: 10px;
      }
    }
  }

  .calendar-controls {
    display:flex;
    align-items: center;
    justify-content: center;
    background: rgba(243,243,243, 0.44);
    padding-bottom: 5px;
    .calendar-current-month {
      min-width: 110px;
      text-align: center;
      color: #000000;
      font-weight: 600;
    }
    .month-nav-icon {
      height: 34px;
      width: 34px;
    }
  }
  .calendar-days {
    display: flex;
    background: rgba(243,243,243, 0.4);
    flex-direction: column;
    align-items: center;
  }

  .calendar-days-content {
    display: flex;
    flex-direction: column;
    .calendar-day-row {
      text-align: center;
      display: inline-flex;
      align-items: center;
      .calendar-day {
        width: 40px;
        line-height: 28px;
        font-size: 14px;
        text-align: center;
        .working-at {
          position: absolute;
        }
        .date-icon {
          cursor: pointer;
        }
      }
    }
  }
  .calendar-days-header {
    display: flex;
    span.calendar-day-header {
      justify-content: space-between;
      width: 40px;
      text-align: center;
      font-size: 14px;
    }
  }
  .calendar-day {
    position: relative;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
      pointer-events: none;
    }
    .day {
      font-weight: 600;
      color: #333333;
      &.today {
        color: #cc0000;
        font-weight: 700;
      }
      &.disabled & {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
    .working-at {
      all: unset;
      margin-top: -7px;
      margin-left: 2px;
      width: 7px;
      height: 7px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
</style>
