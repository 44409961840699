<template>
  <span class="profile-redirect" :class="{ 'clickable' : showProfileDetails, 'grayscale' : applyGrayscale, 'dotted-profile': dottedEmptyProfile }" @click="displayProfileDetails($event)" v-if="!forUser">
    <span class="photo-wrapper" v-if="getPhotoUrl(getProfileFromId()) && getPhotoUrl(getProfileFromId()) !== '' && !showInitials"
      :class="[profileSchedule ? profileSchedule : '',
      profileSchedule ? 'profile-schedule': '']" >
      <img class="profile-initials" @error="showInitials = true" :src="getPhotoUrl(getProfileFromId())">
    </span>
    <span v-else class="profile-initials"
      :class="[profileSchedule ? profileSchedule : '',
      profileSchedule ? 'profile-schedule': '',
      noProfile && !dottedEmptyProfile ? 'noProfile': '']">{{ getInitials(getProfileFromId()) }}</span>
    <template v-if="!onlyMe">
      <mdc-text v-if="showNameAndInitial" class="profile-firstname-initial" typo="body2" tag="span" :class="[profileSchedule ? profileSchedule : '']">{{ getFirstNameAndInitial(getProfileFromId()) }}</mdc-text>
      <mdc-text v-if="showDisplayName" class="profile-display-name" typo="body2" tag="span" :class="[profileSchedule ? profileSchedule : '']">{{ getDisplayName(getProfileFromId()) || displayName }}</mdc-text>
      <mdc-text v-if="showYou && profile.id === profileId" class="you-text" typo="body2" tag="span">(me)</mdc-text>
    </template>
    <div v-else class="only-me-text">
      <mdc-text v-if="showYou && profile.id === profileId" class="you-text" typo="body2" tag="span">Me</mdc-text>
      <base-svg-icon v-if="!noEditProfile" name="pen-edit" @click.native="emitEditIconClick()" />
    </div>
  </span>
  <span v-else class="user-initials">
    <mdc-text v-if="userInitials" class="profile-initials profile-schedule" tag="span">{{ userInitials }}</mdc-text>
    <img v-else-if="photoURL" class="profile-initials" :src="photoURL">
    <mdc-text v-else tag="span">User</mdc-text>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import moment from 'moment'
export default {
  components: {
    BaseSvgIcon
  },
  name: 'ProfileInitials',
  props: {
    noEditProfile: {
      type: Boolean,
      default: false
    },
    applyGrayscale: {
      type: Boolean,
      default: false
    },
    photoURL: {
      type: String,
      default: ''
    },
    forUser: {
      type: Boolean,
      default: false
    },
    userInitials: {
      type: String,
      default: ''
    },
    onlyMe: {
      type: Boolean,
      default: false
    },
    noProfileDetailClick: {
      type: Boolean,
      default: false
    },
    showDisplayName: {
      type: Boolean,
      default: false
    },
    displayName: {
    },
    showYou: {
      type: Boolean,
      default: false
    },
    workingAt: {
      type: String,
      default: ''
    },
    spin: {
      type: Boolean,
      default: false
    },
    showNameAndInitial: {
      type: Boolean,
      default: false
    },
    profileId: {
      type: String,
      default: ''
    },
    date: {
      type: Object,
      default: null
    },
    showProfileDetails: {
      type: Boolean,
      default: true
    },
    dottedEmptyProfile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      profiles: state => state.profiles,
      schedules: state => state.teams.schedules,
      currentSchedules: state => state.teams.currentSchedules,
      appDate: state => state.appDate
    }),
    noProfile () {
      if (this.profileId) {
        if (this.getProfileFromId()) {
          return false
        }
      }
      return true
    }
  },
  async created () {
    if (this.profileId && this.date) {
      this.profileSchedule = this.filterSchedule()
    } else if (this.workingAt) {
      this.profileSchedule = this.workingAt
    } else {
      this.profileSchedule = 'none'
    }
  },
  data () {
    return {
      profileSchedule: 'none',
      showInitials: false
    }
  },
  methods: {
    ...mapActions({
      getNextMeetingWithUser: 'teams/getNextMeetingWithUser',
      getLastSameLocation: 'teams/getLastSameLocation',
      setDisplayProfile: 'setDisplayProfile'
    }),
    emitEditIconClick () {
      this.$emit('emitEditProfile')
    },
    filterSchedule () {
      if (this.currentSchedules) {
        const weekdayAbv = moment(this.date).format('ddd').toUpperCase()
        const isAM = moment(this.date).format('a') === 'am'
        const profileSchedule = this.currentSchedules.filter(todayProfile => todayProfile.fromId === this.profileId)
        const profileResponseObj = profileSchedule[0] ? profileSchedule[0].schedules[weekdayAbv] ? profileSchedule[0].schedules[weekdayAbv] : {} : {}
        if (profileResponseObj.split) {
          if (isAM) {
            return profileResponseObj.amResponse || 'none'
          } else {
            return profileResponseObj.pmResponse || 'none'
          }
        } else {
          return profileResponseObj.response || 'none'
        }
      }
    },
    async displayProfileDetails (event) {
      // console.log('displayProfileDetails', event, this.showProfileDetails, !this.noProfileDetailClick)
      if (this.showProfileDetails && !this.noProfileDetailClick) {
        // console.log('displayProfileDetails', this.profile, this.$root)
        const lastInLoc = await this.getLastSameLocation({ users: [this.profile.id, this.profileId] })
        let lastInLocation = ''
        if (lastInLoc) {
          lastInLocation = lastInLoc.date
        }
        // console.log('displayProfileDetails.getLastSameLocation', lastInLocation)
        const userProfile = this.getProfileFromId(this.profileId)
        if (userProfile) {
          const msUserEmail = userProfile.msEmail || userProfile.email
          const googleUserEmail = userProfile.googleEmail
          const nextMeeting = await this.getNextMeetingWithUser({ msUserEmail, googleUserEmail })
          const profileDetails = {
            profileId: this.profileId,
            workingAt: this.filterSchedule(),
            name: this.getDisplayName(userProfile),
            lastInLocation,
            nextMeetingDate: nextMeeting ? nextMeeting.startDateTime : null
          }
          this.setDisplayProfile(profileDetails)
        }
        event.preventDefault()
        event.stopPropagation()
        return false
      } else {
        return true
      }
    },
    getInitials (profile) {
      let initialFirst = ''
      let initialLast = ''
      let displayNameParts = ''
      if (profile) {
        // console.log('ProfileInitials.getInitials', profile)
        displayNameParts = `${profile.displayName}`.split(' ')
        if (profile.firstName) {
          initialFirst = profile.firstName.substring(0, 1)
        } else {
          initialFirst = displayNameParts[0].substring(0, 1)
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${initialFirst}${initialLast}`.toUpperCase()
      }
      return ''
    },
    getProfileFromId () {
      if (this.profileId === '1') {
        return {
          displayName: 'test'
        }
      }
      let allProfiles = []
      if (this.profiles) {
        allProfiles = [...this.profiles]
      }
      allProfiles = [...allProfiles, this.profile]
      if (allProfiles) {
        return allProfiles.filter(prof => prof.id === this.profileId)[0]
      }
      return []
    },
    getPhotoUrl (profile) {
      if (profile) {
        return profile.photoURL
      }
      return ''
    },
    getDisplayName (profile) {
      if (profile) {
        return profile.displayName ? profile.displayName : ''
      }
      return ''
    },
    getFirstNameAndInitial (profile) {
      let firstName = ''
      let initialLast = ''
      let displayNameParts = ''
      // console.log('ProfileInitials.getInitials', profile)
      if (profile) {
        displayNameParts = `${profile.displayName}`.split(' ')
        if (profile.firstName) {
          firstName = profile.firstName
        } else {
          firstName = displayNameParts[0]
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${firstName} ${initialLast.toUpperCase()}`
      }
      return ''
    }
  },
  mounted () {
  },
  watch: {
    currentSchedules: {
      handler () {
        if (this.profileId && this.date) {
          this.profileSchedule = this.filterSchedule()
        } else if (this.workingAt) {
          this.profileSchedule = this.workingAt
        } else {
          this.profileSchedule = 'none'
        }
      }
    },
    profileId: {
      handler () {
        if (this.profileId && this.date) {
          this.profileSchedule = this.filterSchedule()
        } else if (this.workingAt) {
          this.profileSchedule = this.workingAt
        } else {
          this.profileSchedule = 'none'
        }
      }
    },
    workingAt: {
      handler () {
        if (this.workingAt) {
          this.profileSchedule = this.workingAt
        } else {
          this.profileSchedule = 'none'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes rotate {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
.profile-redirect {
  text-decoration: none;
  border: none;
  color: inherit;
  max-height: 24px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  width: max-content;
  &.grayscale {
    .profile-initials {
      background: #a8a8a8;
      background-blend-mode: luminosity;
    }
    .photo-wrapper {
      img {
        filter: grayscale(1);
      }
    }
    .mdc-text {
      color: #989898;
    }
  }
  &.dotted-profile {
    .profile-schedule {
      background-color: transparent;
      background-image: url('/static/img/icons/flexibly/avatar-person-only-cfe5ff.svg');
      &:before {
        border: 2px dashed #2B8AFB;
        padding: 0;
      }
    }
  }
}
.profile-firstname-initial {
  margin-left: 8px;
  border: none !important;
  color: #1F5CB8;
  line-height: 16px;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.profile-display-name {
  margin-left: 8px;
  border: none !important;
  color: #1F5CB8 ;
  line-height: 16px;
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.you-text {
  color: #1F5CB8;
  margin-left: 3px;
}
.only-me-text {
  display: flex;
  align-items: center;
  margin-left: 8px;
  .you-text {
    margin-left: 0px;
  }
  .icon-wrapper {
    margin-left: 10px;
    margin-top: 2px;
  }
}
.user-initials {
  .profile-schedule {
    text-transform: uppercase;
  }
}

/* initial text -- current profile schedule */
.profile-initials {
  border-radius: 50%;
  font-size: 9px;
  letter-spacing: 0.5px;
  border: 1px solid;
  text-align: center;
  box-sizing: content-box;
  height: 16px;
  width: 16px;
  min-width: 16px;
  line-height: 18px;
  font-weight: 600;
   &.profile-schedule {
     /** generic avatar with gray background/shadow image */
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    box-sizing: border-box;
    background-color: rgba(65, 117, 177, 0.6);
    color: #fff;
    border: none;
    background-image: url('/static/img/icons/flexibly/avatar-person-only.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: auto;
    &.noProfile {
      background-image: url('/static/img/icons/flexibly/avatar-no-profile.svg');
      background-position: center;
    }
    &:before {
      content: '';
      border-radius: 50%;
      padding: 2px;
      position: absolute;
      width: 16px;
      height: 16px;
      border: 2px solid transparent;
    }
    &.thirdspace {
      &:before {
        border-color: var(--thirdspace-icon);
      }
    }
    &.home {
      &:before {
        border-color: var(--home-icon);
      }
    }
    &.office {
      &:before {
        border-color: var(--office-icon);
      }
    }
    &.nonworking {
      &:before {
        border-color: var(--nonworking-icon);
      }
    }
  }
}

/* photo url -- current profile schedule */

.photo-wrapper {
  display: flex;
  .profile-initials { /* image tag */
    border: none;
    width: 16px;
    height: 16px;
  }
  &.profile-schedule {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    box-sizing: border-box;
    &:before {
      content: '';
      border-radius: 50%;
      padding: 2px;
      position: absolute;
      width: 16px;
      height: 16px;
      border: 2px solid transparent;
    }
    &.thirdspace {
      &:before {
        border-color: var(--thirdspace-icon);
      }
    }
    &.home {
      &:before {
        border-color: var(--home-icon);
      }
    }
    &.office {
      &:before {
        border-color: var(--office-icon);
      }
    }
    &.nonworking {
      &:before {
        border-color: var(--nonworking-icon);
      }
    }
  }
}
</style>
