<template>
  <div class="navbar-holder">
    <span v-for="(item, index) in links" :key="index">
          <router-link class="nav-link" :to="item.to" @click.native="setDefaultView(item.type)">
            <div class="navbar-item"
                :class="{ iconOnly: !item.type, lessOne: !teamLeader }"
                :style="dynamicStyle.bottombarItem" >
              <base-svg-icon :name="item.imageIcon" :class="item.label.toLowerCase()" />
            </div>
          </router-link>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { inRole, isTeamLeader, isWorkspaceAdmin } from '../utils/role'
import BaseSvgIcon from '../components/flexibly/BaseSvgIcon'
export default {
  name: 'AppNavBottom',
  components: {
    BaseSvgIcon
  },
  props: {
    dynamicStyle: Object
  },
  computed: {
    ...mapState([
      'role',
      'workspace'
    ]),
    teamLeader () {
      return isTeamLeader() || isWorkspaceAdmin()
    },
    links () {
      return this.allLinks.filter((link) => {
        if (link.teamAdmin) {
          return this.teamLeader
        }
        if (link.restricted) {
          return inRole(link.restricted)
        }
        return true
      })
    }
  },
  methods: {
  },
  data () {
    return {
      allLinks: [
        { type: 'People', to: '/people', imageIcon: 'People', label: 'WHO\'S WHERE WHEN' },
        { type: 'Planner', to: '/dashboard', imageIcon: 'planner', label: 'MY WORK' },
        { type: 'Initiatives', to: '/initiatives', imageIcon: 'initiatives-white', label: 'INITIATIVES' },
        { type: 'Team', to: '/team', imageIcon: 'initiatives-white', label: 'MY TEAM', teamAdmin: true }

      ]
    }
  }
}
</script>

<style scoped>
.navbar-holder {
  padding: 0;
  margin: 0;
  display: flex;
}

.navbar-holder > span {
  flex:1;
}

.nav-link {
  text-decoration: none;
}

.navbar-item {
  font-family: 'Roboto Condensed', Roboto, sans-serif;
  font-Size: 14px;
  line-height: 16px;
  color: #F7FFFF;
  text-align: center;
  padding: 6px 0px 6px 0px;
}
.navbar-item.lessOne {
  width:33%;
  margin-left: auto;
  margin-right: auto;
}
.navbar-item.iconOnly {
  width:20%;
  font-size:1.5em;
}
.router-link-active .navbar-item {
  border-bottom: 2px solid #F8B517;
}
</style>
