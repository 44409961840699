var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.workspace)?_c('div',{staticClass:"search-wrapper",attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeSearch($event)}}},[_c('div',{staticClass:"search-scrim"}),(_vm.searchClient)?_c('div',{staticClass:"container all-search"},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.workspace.id+'-blocks'}},[_c('stat',{on:{"nbHitsChange":function($event){return _vm.hitsChange($event, 'notes')}}}),(!_vm.typeFilter)?_c('ais-configure',{attrs:{"hitsPerPage":5}}):_c('ais-configure',{attrs:{"hitsPerPage":10}}),_c('ais-search-box',{ref:"searchInput",staticClass:"search-box-input",model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('div',{staticClass:"search-result-box"},[_c('div',{staticClass:"search-results"},[_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var query = ref.state.query;
return [(query)?_c('div',{staticClass:"result-section"},[(query)?_c('div',{staticClass:"result-facets"},[_vm._v("Showing results for \""+_vm._s(query)+"\" 1 to "+_vm._s(_vm.getLeast(_vm.totalHits, 15))+" of "+_vm._s(_vm.totalHits))]):_vm._e(),_c('div',{staticClass:"result-facets"},[_c('span',{staticClass:"result-facet-item",class:{ active: !_vm.typeFilter },on:{"click":function($event){_vm.typeFilter = null}}},[_vm._v("All ("+_vm._s(_vm.totalHits)+")")]),_vm._l((_vm.hitTotalsFiltered),function(value,name){return _c('span',{key:'facet-item-' + name,staticClass:"result-facet-item",class:{ active: _vm.typeFilter === name },on:{"click":function($event){_vm.typeFilter = name}}},[(name === 'notes')?_c('tempate',[_vm._v(" Updates ")]):[_vm._v(_vm._s(name))],_vm._v(" ("+_vm._s(value)+") ")],2)})],2),(!_vm.typeFilter || _vm.typeFilter === 'notes')?_c('section',[(_vm.hitTotalsFiltered.notes)?_c('div',{staticClass:"result-section"},[_c('div',{staticClass:"results-heading"},[_vm._v("UPDATES")]),(query)?_c('ais-hits',{scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return _c('div',{on:{"click":function($event){return _vm.routeNote(item)},"mouseover":function($event){return _vm.previewNote(item)}}},[_c('img',{staticClass:"resut-icon",attrs:{"src":_vm.getIcon('note', item)}}),_c('div',[_c('h4',[_vm._v(_vm._s(item.pageName)+" "+_vm._s(item.content))]),_c('p',[_vm._v("Updates > "+_vm._s(_vm.getNoteType(item.type))+" > "+_vm._s(item.pageName))])])])}}],null,true)}):_vm._e()],1):_vm._e()]):_vm._e()]):_c('div'),(query)?_c('ais-index',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.workspace.id+'-tasks'}},[_c('stat',{on:{"nbHitsChange":function($event){return _vm.hitsChange($event, 'tasks')}}}),(!_vm.typeFilter || _vm.typeFilter === 'tasks')?_c('section',[(_vm.hitTotalsFiltered.tasks)?_c('div',{staticClass:"result-section"},[_c('div',{staticClass:"results-heading"},[_vm._v("TASKS")]),_c('ais-hits',{key:'task-hits',scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return _c('div',{on:{"click":function($event){return _vm.routeTask(item)},"mouseover":function($event){return _vm.previewTask(item)}}},[_c('img',{staticClass:"resut-icon",attrs:{"src":_vm.getIcon('task', item)}}),_c('div',[_c('h4',{domProps:{"innerHTML":_vm._s(item.text)}}),_c('p',[_vm._v("Tasks")])])])}}],null,true)})],1):_vm._e()]):_vm._e()],1):_vm._e(),(query)?_c('ais-index',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.workspace.id+'-profiles'}},[_c('stat',{on:{"nbHitsChange":function($event){return _vm.hitsChange($event, 'users')}}}),(!_vm.typeFilter || _vm.typeFilter === 'users')?_c('section',[_c('div',{staticClass:"result-section"},[(_vm.hitTotalsFiltered.users)?_c('div',{staticClass:"results-heading"},[_vm._v("USERS")]):_vm._e(),_c('ais-hits',{key:'user-hits',scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return _c('div',{on:{"click":function($event){return _vm.routeUser(item)},"mouseover":function($event){return _vm.previewUser(item)}}},[_c('img',{staticClass:"resut-icon",attrs:{"src":_vm.getIcon('user', item)}}),_c('div',[_c('h4',[_vm._v(_vm._s(item.displayName))]),_c('p',[_vm._v("Users > "+_vm._s(item.displayName))])])])}}],null,true)})],1)]):_vm._e()],1):_vm._e()]}}],null,false,2073780571)})],1),_c('div',{staticClass:"search-preview"},[(_vm.preview)?_c('div',{staticClass:"preview-box"},[(_vm.lookingForward && _vm.preview === 'lookingForward')?_c('div',{staticClass:"looking-forward-note"},[_c('h4',[_vm._v(_vm._s(_vm.lookingForward.pageName))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.lookingForward.html)}})]):_vm._e(),(_vm.meetingNote && _vm.preview === 'meetingnote')?_c('div',{staticClass:"meeting-note"},[_c('h4',[_vm._v(_vm._s(_vm.meetingNote.pageName))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.meetingNote.html)}})]):_vm._e(),(_vm.task && _vm.preview === 'task')?_c('div',{staticClass:"task"},[_c('h4',{domProps:{"innerHTML":_vm._s(_vm.task.text)}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.task.description)}})]):_vm._e(),(_vm.user && _vm.detailsForProfile && _vm.preview === 'user')?_c('div',{staticClass:"user-preview"},[_c('h4',[_vm._v(_vm._s(_vm.user.displayName))]),_c('profile-details-dialog',{attrs:{"dialogStyle":false,"id":'detailsforprofile'+ _vm.detailsForProfile.profileId,"profileDetails":_vm.detailsForProfile,"showDialog":true}})],1):_vm._e()]):_vm._e()])])],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }