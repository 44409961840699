<template>
  <div class="update-comments-dialog" v-if="showDialog">
    <div class="dialog-wrapper">
      <div class="noteblock-wrapper">
        <mdc-text typo="body2" tag="span" class="text note-block"  v-if="selectedNoteBlockText" v-html="selectedNoteBlockText" />
        <base-svg-icon name="cross-thin" class="close-icon" @click.native="$emit('closeDialog')" clickable />
      </div>
      <update-comments
          :pageDetails="pageDetails"
          :commentMode="true"
          :selectedNoteBlock="selectedNoteBlock"
          @closeDialog="$emit('closeDialog')"
        />
    </div>
  </div>
</template>

<script>
import UpdateComments from '../../components/flexibly/UpdateComments.vue'
import { mapActions } from 'vuex'
import BaseSvgIcon from '../../components/flexibly/BaseSvgIcon.vue'
export default {
  name: 'UpdateCommentsDialog',
  components: {
    UpdateComments,
    BaseSvgIcon
  },
  computed: {
  },
  data () {
    return {
      selectedNoteBlockText: null
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      default: true
    },
    pageDetails: {},
    selectedNoteBlock: {}
  },
  beforeDestroy () {
    // document.removeEventListener('click', this.handleClickOutside)
  },
  created () {},
  mounted () {
  },
  methods: {
    ...mapActions({
      getLookingForwardPageById: 'teams/getLookingForwardPageById'
    })
  },
  watch: {
    pageDetails: {
      handler () {
        if (this.pageDetails) {
          if (this.pageDetails.id) {
            this.getLookingForwardPageById({
              pageId: this.pageDetails.id
            }).then((lookingForward) => {
              console.log('updateCommentsDialog.getLookingForwardPageById', this.pageDetails.id, lookingForward)
              if (lookingForward) {
                if (lookingForward.noteBlocks) {
                  const noteblock = lookingForward.noteBlocks.filter(nb => nb.noteBlockId === this.selectedNoteBlock)
                  console.log(noteblock)
                  if (noteblock) {
                    this.selectedNoteBlockText = noteblock[0].html
                  }
                }
              }
            })
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.update-comments-dialog {
  width: 600px;
  margin: auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, .40);
  border-radius: 8px;
  min-height: 200px;
  z-index: 40;
  position: relative;

  .block-comment-box {
    margin-top:0px;
  }

  &.no-style {
    box-shadow: none;
  }

  .task-line.active:not(.taskmodal) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  .dialog-wrapper {
    .noteblock-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 15px 0 15px;
      .text {
        &.note-block {
          p {
            margin: 0;
            font-weight: 600;
          }
        }
      }
      .close-icon {}
    }
    > div {
      padding-bottom: 10px;
      border-bottom: 1px dashed grey;
      &:last-of-type {
        border-bottom: transparent;
      }
      &:not(:first-of-type) {
        padding-top: 10px;
      }
    }
  }
}
</style>
