<template>
  <div ref="user-settings" v-if="showPlusMenus" class="plus-menus-wrapper">
    <div class="menu-item-wrapper" >
      <mdc-text @click.native="redirectTaskModal()" typo="body2" tag="span">New task</mdc-text>
      <mdc-text @click.native="redirectInitiative()" typo="body2" tag="span">New initiative</mdc-text>
      <mdc-text @click.native="redirectWeeklyPreferences()" typo="body2" tag="span">Add location preferences</mdc-text>
      <mdc-text v-if="$teamLeader()" @click.native="redirectAddMember()" typo="body2" tag="span">Add team member</mdc-text>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PlusMenus',
  components: {},
  props: {
    showPlusMenus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  beforeDestroy () {},
  created () {},
  mounted () {},
  methods: {
    ...mapActions([
      'setDisplayTask'
    ]),
    ...mapActions({
    }),
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target))) {
        this.$emit('triggerClose', false)
      }
    },
    redirectTaskModal () {
      const newTask = {
        id: '',
        complete: false,
        completed: false,
        text: '<p></p>',
        due: null,
        shareUsers: [],
        mentions: [],
        order: this.tasks.length,
        shareInitiatives: [],
        userId: this.profile.id,
        createdOn: new Date(),
        isPlanPresent: {},
        selfAssignedTo: this.profile.id
      }
      this.setDisplayTask(newTask)
      this.$emit('triggerClose', false)
    },
    redirectInitiative () {
      if (this.$route.name === 'Initiatives') {
        this.$root.$emit('setNewInitiativeModal', true)
      } else {
        this.$router.push({ name: 'Initiatives', params: { openModal: 'showAddNewInitiativeModal' } })
      }
      this.$emit('triggerClose', false)
    },
    redirectWeeklyPreferences () {
      if (this.$route.name === 'People') {
        this.$root.$emit('setRightSectionType', { section: 'weeklyPreferences', weeklyPreferences: true })
      } else {
        this.$router.push({ name: 'People', params: { changeView: 'weeklyPreferences', weeklyPreferences: true } })
      }
      this.$emit('triggerClose', false)
    },
    redirectAddMember () {
      if (this.$route.name === 'People') {
        this.$root.$emit('setRightSectionType', 'addNewUserModal')
      } else {
        this.$router.push({ name: 'People', params: { changeView: 'bestOfficeDays', openModal: 'addNewUserModal' } })
      }
      this.$emit('triggerClose', false)
    }
  },
  watch: {
    showPlusMenus: {
      handler () {
        if (this.showPlusMenus) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
          }, 300)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
          }, 300)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState([
    ]),
    ...mapState({
      tasks: state => state.teams.tasks,
      profile: state => state.profile
    })
  }
}
</script>

<style lang="scss">
$color-black: #000000;
$color-white: #ffffff;
.plus-menus-wrapper {
  position: absolute;
  display: flex;
  z-index: 93;
  background: #FBFBFB;
  box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  top: 55px;
  color: $color-black;
  right: 115px;
  width: 200px;
  margin: 0;
  flex-direction: column;
  &::before {
    position: absolute;
    left: 90%;
    top: -10px;
    width: 0;
    height: 0;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid white;
  }
  .menu-item-wrapper:not(:empty) {
    display: flex;
    min-height: 40px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    span.mdc-text {
      padding: 7px 10px;
      color: #404040;
      font-weight: 600;
      white-space: nowrap;
      &:hover {
        background: #EBF3FC;
        &:first-of-type {
          border-radius: 4px 4px 0 0;
        }
        &:last-of-type {
          border-radius: 0 0 4px 4px;
        }
      }
    }
  }
}
</style>
