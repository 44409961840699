<template>
  <div class="locations-details-wrapper" :class="{ 'thirdspace-location': locationType === 'thirdspace', 'office-location': locationType === 'office', outlined }">
    <section v-if="locationType === 'thirdspace'" class="thirdspace-type section">
      <div class="location-input thirdspace-input"
        @mouseover="showClear = true"
        @mouseleave="showClear = false">
        <vue-google-autocomplete
          :value="locationInputVal"
          :position="myLocation"
          enable-geolocation
          ref="thirdspaceaddress"
          id="location-ac"
          autocomplete="new-password"
          autofill="off"
          @focus="locationFocus"
          @blur="locationBlur"
          @inputChange="updateLocation"
          classname="mdc-text-field__input"
          :types="locationTypes"
          :placeholder="'Start typing and select to add a new location'"
          :country="['uk']"
          @placechanged="getAddressData"
        >
        </vue-google-autocomplete>
        <base-svg-icon v-if="showClear && locationInputVal" @click.native.stop.prevent="clearThirdspaceInput" name="cross-thin" class="clear-input" clickable/>
      </div>
    </section>
    <section v-if="locationType === 'office'" class="office-type section">
      <div class="location-input office-input"
        :class="{ 'mdc-text-field': outlined, 'mdc-text-field--outlined': outlined }"
        @mouseover="showClear = true"
        @mouseleave="showClear = false">
        <span v-if="outlined" :class="{ 'mdc-text-field--focused': focused}" class="mdc-notched-outline mdc-notched-outline--no-label">
          <span class="mdc-notched-outline__leading"></span>
          <span class="mdc-notched-outline__notch mdc-notched-outline__notch--notched"></span>
          <span class="mdc-notched-outline__trailing"></span>
        </span>
        <input
          ref="deskInput"
          type="text"
          class="desk-share-field"
          v-model="deskInput"
          :placeholder="profile && !profile.favOfficeLocationId && !lookup ? 'Tip: Star the location you work at most often' : 'Start typing a location'"
          autofocus
          @focus="focused=true"
          @blur="focused=false"
        />
      </div>
      <div class="office-locations-list" :class="{ 'no-details' : hideActivityDetails }">
        <template v-if="officeLocationsSearchResults.length === 0 && orderedOfficeLocations.length === 0">
          <div class="office-places locations-menu workspace-locations" role="list" aria-label="workspace-locations">
            <template v-for="(location, index) in limitedLocationsSearchKeys">
              <div v-if="location.text" class="locations-options" :key="location.id + index" role="listitem">
                <mdc-text typo="body2" v-html="location.text" tag="span" @click.native.stop="setDeskInput(location.id, location.text, location.type, location)" class="menu-item"></mdc-text>
                <mdc-text typo="body2" v-if="!hideActivityDetails" class="location-activity" :class="getCapacityLevel(location)">{{getLocationActivityLevelText(location)}} <span class="occupied">{{occupiedWithDisabled(location)}}</span> / <span class="capacity">{{location.capacity}}</span></mdc-text>
              </div>
            </template>
          </div>
        </template>
        <template v-if="officeLocationsSearchResults.length > 0">
          <div class="office-menu-heading">
            <mdc-text tag="span" typo="body2">Search results</mdc-text>
          </div>
          <div class="office-places locations-menu search-results" role="list" aria-label="search-results">
            <template v-for="(location, index) in officeLocationsSearchResults.slice(0, 5)">
              <div v-if="location.text" class="locations-options" :key="location.id + index">
                <mdc-text typo="body2" v-html="location.text" tag="span" @click.native.stop="setDeskInput(location.id, location.text, location.type, location)" class="menu-item"></mdc-text>
                <mdc-text typo="body2" v-if="!hideActivityDetails" class="location-activity" :class="getCapacityLevel(location)">{{getLocationActivityLevelText(location)}} <span class="occupied">{{occupiedWithDisabled(location)}}</span> / <span class="capacity">{{location.capacity}}</span></mdc-text>
              </div>
            </template>
          </div>
        </template>
        <template v-if="orderedOfficeLocations.length > 0">
          <div class="office-menu-heading">
            <mdc-text tag="span" typo="body2">My office locations</mdc-text>
          </div>
          <div class="office-places locations-menu prev-locations" role="list" aria-label="previous-locations">
            <template v-for="(location, index) in orderedOfficeLocations">
              <div class="locations-options" :key="location.id + index" v-if="location.text">
                <base-svg-icon v-if="!lookup" :name="favLocation === location.id ? 'favourite-star-on' : 'favourite-star-off'" class="fav-icon" clickable @click.native.stop="markFavourite(location)" />
                <mdc-text typo="body2" v-html="location.text" tag="span" @click.native.stop="setDeskInput(location.id, location.text, location.type, location)" class="menu-item" :class="favLocation === location.id && !lookup ? 'fav-location' : ''"></mdc-text>
                <mdc-text typo="body2" v-if="!hideActivityDetails" class="location-activity" :class="getCapacityLevel(location)">{{getLocationActivityLevelText(location)}} <span class="occupied">{{occupiedWithDisabled(location)}}</span> / <span class="capacity">{{location.capacity}}</span></mdc-text>
              </div>
            </template>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import BaseSvgIcon from './BaseSvgIcon.vue'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
const _ = { orderBy, uniqBy, sortBy }
export default {
  name: 'LocationsDetails',
  components: {
    VueGoogleAutocomplete,
    BaseSvgIcon
  },
  props: {
    hideActivityDetails: {
      type: Boolean,
      default: false
    },
    lookup: {
      type: Boolean,
      default: false
    },
    locationType: {
      type: String,
      default: ''
    },
    showFor: {
      type: String,
      default: ''
    },
    date: {
    },
    onboarding: {
      type: Boolean,
      default: false
    },
    locationList: {
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      deskInput: '',
      locationInputVal: '',
      myLocation: '',
      locationTypes: '',
      showClear: false,
      locationFocused: false,
      addressDataRetrieved: false,
      officeLocationsSearchResults: [],
      presentLocationsOrder: [],
      officeLocationsOpen: false,
      focused: false
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  created () {
    if (this.locationType === 'office') {
      this.presentLocationsOrder = this.orderedOfficeLocations
    }
  },
  mounted () {
    this.$refs?.deskInput?.focus()
    document.addEventListener('click', this.handleClickOutside)
  },
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    ...mapActions({
    }),
    clearThirdspaceInput (event) {
      this.$refs.thirdspaceaddress.clear()
    },
    markFavourite (location) {
      this.officeLocationsOpen = true
      const updateFavLocation = location.id === this.favLocation ? '' : location.id
      this.updateProfile({ profile: { favOfficeLocationId: updateFavLocation }, noSnack: true })
    },
    buildingFloors (buildingId) {
      if (buildingId) {
        const floors = [...this.allFloors]
        return floors.filter(floor => floor.buildingId === buildingId)
      }
      return []
    },
    floorAreas (floorId) {
      if (floorId) {
        const areas = [...this.allAreas]
        return areas.filter(area => area.floorId === floorId)
      }
      return []
    },
    handleClickOutside (event) {
      this.officeLocationsOpen = false
      if (!(this.$el === event.target || this.$el.contains(event.target) || event.target.classList.contains('set-location-button') || event.target.classList.contains('location-text'))) {
        this.$emit('emitClose')
      }
    },
    setDeskInput (val, text, type, location) {
      this.$emit('saveOfficeLocation', val, this.showFor, text, type, location)
    },
    async updateLocation (val) {
      this.locationInputVal = val.newVal
      if (this.addressDataRetrieved) {
        this.$emit('saveThirdspaceLocation', this.locationInputVal, this.showFor)
      }
    },
    locationFocus () {
      this.locationFocused = true
      this.movePac()
    },
    locationBlur () {
      this.locationFocused = false
    },
    movePac () {
      const pac = document.getElementsByClassName('pac-container')[0]
      const autocomp = document.getElementsByClassName('thirdspace-input')[0]
      if (pac) {
        pac.parentNode.removeChild(pac)
        if (autocomp) {
          autocomp.appendChild(pac)
        }
      }
    },
    getAddressData (addressData, placeResultData, id) {
      this.locationInputVal = placeResultData.name
      this.addressDataRetrieved = true
    },
    getDailyAverage (location) {
      if (location.dailyAverage && this.dayAbv) {
        return location.dailyAverage[this.dayAbv]
      }
      return -1
    },
    occupiedWithDisabled (location) {
      if (location.assignedDesks && location.desks) {
        return location.desks.filter(desk => desk.enabled === false).length + (location.occupied || 0)
      }
      return location.occupied || 0
    },
    getCapacityPer (location) {
      const dailyAverage = this.getDailyAverage(location)
      if (location && location.capacity && dailyAverage > 0) {
        return (dailyAverage / location.capacity) * 100
      }
      return 0
    },
    getCapacityLevel (location) {
      let over = ''
      if (location.capacity < this.occupiedWithDisabled(location)) {
        over = ' over'
      }
      const capPer = this.getCapacityPer(location)
      if (capPer < 0) {
        return 'low' + over
      } else if (capPer === 0) {
        return 'low' + over
      } else if (capPer <= 25) {
        return 'low' + over
      } else if (capPer >= 75) {
        return 'high' + over
      } else {
        return 'mid' + over
      }
    },
    getLocationActivityLevelText (location) {
      const capacityLevel = this.getCapacityLevel(location)
      if (capacityLevel.indexOf('high') >= 0) {
        return 'Usually busy'
      } else if (capacityLevel.indexOf('mid') >= 0) {
        return 'Usually active'
      } else if (capacityLevel.indexOf('low') >= 0) {
        return 'Usually quiet'
      } else {
        return 'No Activity'
      }
    }
  },
  watch: {
    locationType: {
      handler () {
        if (this.locationType === 'thirdspace') {
          // console.log('watch.myTodaySchedule: attempting to get location from browser')
          if (window.navigator) {
            window.navigator.geolocation.getCurrentPosition(
              position => {
                // console.log('watch.myTodaySchedule location:', position.coords.latitude, position.coords.longitude)
                this.myLocation = { lat: position.coords.latitude, lon: position.coords.longitude }
              },
              error => {
                console.log('watch.myTodaySchedule error:', error.message)
              })
          }
        }
      },
      immediate: true
    },
    deskInput: {
      handler () {
        if (this.deskInput) {
          this.officeLocationsSearchResults = this.locationsSearchKeys.filter((item) => {
            const a = item.text.toLowerCase() || null
            const b = this.deskInput.toLowerCase() || ''
            if (a && a !== '') {
              if (b) {
                return a.includes(b)
              }
            }
            return true
          })
        } else {
          this.officeLocationsSearchResults = []
        }
      }
    }
  },
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
      locations: state => state.teams.locations,
      schedules: state => state.teams.schedules
    }),
    favLocation () {
      if (this.profile) {
        if (this.profile.favOfficeLocationId) {
          return this.profile.favOfficeLocationId
        }
      }
      if (this.locations && this.locations.length === 1) {
        return this.locations[0].id
      }
      return null
    },
    locationMap () {
      if (this.locationList && this.locationList.length) {
        const locMap = Object.assign({}, ...(this.locationList.map(item => ({ [item.id]: item }))))
        return locMap
      }
      if (this.locations && this.locations.length) {
        const locMap = Object.assign({}, ...(this.locations.map(item => ({ [item.id]: item }))))
        return locMap
      }
      return {}
    },
    limitedLocationsSearchKeys () {
      if (this.locationsSearchKeys) {
        if (this.locationsSearchKeys.length <= 3) {
          return this.locationsSearchKeys
        } else {
          return this.locationsSearchKeys.slice(0, 3)
        }
      }
      return []
    },
    dayAbv () {
      if (this.date) {
        return moment(this.date).format('ddd').toUpperCase()
      }
      return ''
    },
    schedulesByLocation () {
      const schedulesByLoc = {}
      if (this.schedules) {
        for (const schedule of this.schedules) {
          if (schedule.schedules) {
            if (schedule.schedules[this.dayAbv]) {
              const locId = schedule.schedules[this.dayAbv].locationId
              const isOnlyDefault = schedule.default === true && schedule.confirmed !== true && schedule.addedByConfirmation !== true
              const inOffice = (schedule.schedules[this.dayAbv].response === 'office') ||
                (schedule.schedules[this.dayAbv].split &&
                (schedule.schedules[this.dayAbv].amResponse === 'office' ||
                schedule.schedules[this.dayAbv].pmResponse === 'office'))
              const issue = schedule.schedules[this.dayAbv].issue
              if (locId && inOffice && !isOnlyDefault && !issue) {
                if (schedulesByLoc[locId]) {
                  schedulesByLoc[locId] = [...schedulesByLoc[locId], schedule]
                } else {
                  schedulesByLoc[locId] = [schedule]
                }
              }
            }
          }
        }
      }
      return schedulesByLoc
    },
    orderedOfficeLocations () {
      let locs = []
      if (this.profile) {
        if (this.profile.officeLocations && this.profile.officeLocations.length > 0) {
          locs = this.profile.officeLocations
        } else if (this.locationsSearchKeys && this.locationsSearchKeys.length === 1) {
          locs = [...this.locationsSearchKeys]
        }
      }
      if (locs.length > 0) {
        const officeLocations = locs.map(item => {
          const occupied = this.schedulesByLocation[item.id] ? this.schedulesByLocation[item.id].length : 0
          const active = this.locationMap[item.id] ? !this.locationMap[item.id].deleted : false
          const capacity = this.locationMap[item.id] ? this.locationMap[item.id].capacity || '?' : '?'
          const dailyAverage = this.locationMap[item.id] ? this.locationMap[item.id].dailyAverage : 0
          const buildingId = this.locationMap[item.id] ? this.locationMap[item.id].buildingId : ''
          const floorId = this.locationMap[item.id] ? this.locationMap[item.id].floorId : ''
          const assignedDesks = this.locationMap[item.id] ? this.locationMap[item.id].assignedDesks : false
          const desks = this.locationMap[item.id] && assignedDesks ? this.locationMap[item.id].desks : []
          return { ...item, capacity, occupied, dailyAverage, active, buildingId, floorId, assignedDesks, desks }
        })
        const activeOfficeLocations = officeLocations.filter((ol) => { return ol.active })
        const orderByDate = _.orderBy(activeOfficeLocations, 'date', 'desc')
        if (this.officeLocationsOpen) {
          if (this.presentLocationsOrder.length > 0) {
            return _.uniqBy(this.presentLocationsOrder, 'text')
          }
        } else {
          const defaultFirst = _.sortBy(orderByDate, ({ id }) => id === this.profile.favOfficeLocationId ? 0 : 1)
          return _.uniqBy(defaultFirst, 'text')
        }
      }
      return []
    },
    allLocations () {
      if (this.locationList && this.locationList.length) {
        return this.locationList
      }
      if (this.locations) {
        return this.locations
      }
      return []
    },
    allBuildings () {
      if (this.locationList && this.locationList.length) {
        return this.locationList.filter(location => location.type === 'building').sort((a, b) => a.addedOn - b.addedOn)
      }
      if (this.locations) {
        return this.locations.filter(location => location.type === 'building').sort((a, b) => a.addedOn - b.addedOn)
      }
      return []
    },
    allFloors () {
      if (this.locationList && this.locationList.length) {
        return this.locationList.filter(location => location.type === 'floor').sort((a, b) => a.addedOn - b.addedOn)
      }
      if (this.locations) {
        return this.locations.filter(location => location.type === 'floor').sort((a, b) => a.addedOn - b.addedOn)
      }
      return []
    },
    allAreas () {
      if (this.locationList && this.locationList.length) {
        return this.locationList.filter(location => location.type === 'area').sort((a, b) => a.addedOn - b.addedOn)
      }
      if (this.locations) {
        return this.locations.filter(location => location.type === 'area').sort((a, b) => a.addedOn - b.addedOn)
      }
      return []
    },
    locationsSearchKeys () {
      let detail = {}
      const allText = []
      this.allBuildings.forEach((building) => {
        const floors = this.buildingFloors(building.id)
        if (floors.length > 0) {
          floors.forEach((floor) => {
            const areas = this.floorAreas(floor.id)
            if (areas.length > 0) {
              areas.forEach((area) => {
                detail = {
                  text: building.name + ' ' + '<i>' + floor.name + ',' + '</i>' + ' ' + '<i>' + area.name + '</i>',
                  id: area.id,
                  type: 'area',
                  capacity: area.capacity || '?',
                  occupied: this.schedulesByLocation[area.id] ? this.schedulesByLocation[area.id].length : 0,
                  dailyAverage: area.dailyAverage,
                  buildingId: area.buildingId,
                  floorId: area.floorId,
                  assignedDesks: area.assignedDesks || false,
                  desks: area.desks || []
                }
                allText.push(detail)
              })
            } else {
              detail = {
                text: building.name + ' ' + '<i>' + floor.name + '</i>',
                id: floor.id,
                type: 'floor',
                capacity: floor.capacity || '?',
                occupied: this.schedulesByLocation[floor.id] ? this.schedulesByLocation[floor.id].length : 0,
                dailyAverage: floor.dailyAverage,
                buildingId: floor.buildingId,
                floorId: '',
                assignedDesks: floor.assignedDesks || false,
                desks: floor.desks || []
              }
              allText.push(detail)
            }
          })
        } else {
          detail = {
            text: building.name.trim(),
            id: building.id,
            type: 'building',
            capacity: building.capacity || '?',
            occupied: this.schedulesByLocation[building.id] ? this.schedulesByLocation[building.id].length : 0,
            dailyAverage: building.dailyAverage,
            buildingId: '',
            floorId: '',
            assignedDesks: building.assignedDesks || false,
            desks: building.desks || []
          }
          allText.push(detail)
        }
      })
      return allText
    }
  }
}
</script>

<style lang="scss">
.locations-details-wrapper {

  .section {
    &.office-type {
      display: flex;
      flex-direction: column;
      .office-menu-heading {
        display: flex;
        margin: 0px 10px;
        padding: 5px 0;
        border-top: 0.5px solid #E0E0E0;
        .icon-wrapper {
          margin-right: 5px;
          width: 9px;
          height: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .mdc-text {
          font-weight: 700;
        }
      }
    }
    .location-input {
      position: relative;
      .clear-input {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0 10px;
        height: 100%;
      }
      input {
        background: #fbfbfb;
      }
      &.thirdspace-input {
        padding: 5px 10px;
        input {
          font-size: 14px;
          height: 24px;
          font-weight: 600;
          &::placeholder {
            opacity: 1 !important;
            color: #BDBDBD;
            font-size: 14px;
          }
        }
        .pac-container {
          top: unset !important;
          left: 0 !important;
          border-radius: 0 0 4px 4px;
          z-index: 9999;
          .pac-item {
            padding: 0 5px;
            .pac-icon {
              background-image: url('/static/img/icons/flexibly/location_fill.svg');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }
      }
      &.office-input {
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin-top: 5px;
        input {
          padding: 2px 20px 2px 5px;
          border: none;
          font-size: 14px;
          color: #404040;
          font-weight: 600;
          &::placeholder {
            opacity: 1 !important;
            color: #BDBDBD;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
    .office-locations-list {
      .locations-menu {
        &.office-places {
          display: flex;
          flex-direction: column;
          margin-bottom: 5px;
          .locations-options {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 5px 10px;
            cursor: pointer;
            .fav-icon {
              margin-right: 10px;
              height: 14px;
              width: 14px;
            }
            .menu-item {
              color: #4f4f4f;
              text-transform: capitalize;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              flex: 1;
              margin-right: 10px;
              max-width: calc(100% - 110px);
              overflow-wrap: break-word;
              word-break: break-word;
              white-space: normal;
              i {
                text-transform: capitalize;
                margin-left: 3px;
                max-width: 100%;
                overflow-wrap: break-word;
              }
              &.fav-location {
                font-weight: 700;
                i {
                  font-weight: 600;
                }
              }
            }
            .location-activity {
              margin:0;
              margin-left:auto;
              font-size:10px;
              .occupied {
                font-size:12px;
                margin-left:4px;
              }
              &.high {
                color: #FF8900;
              }
              &.over {
                color:red;
              }
            }
            &:hover {
              background: #EBF3FC;
            }
          }
        }
      }
      &.no-details {
        .locations-menu {
          &.office-places {
            .locations-options {
              .menu-item {
                max-width: calc(100% - 10px);
              }
            }
          }
        }
      }
    }
  }
  &.outlined {
    .locations-menu {
      &.office-places {
        margin-left:auto;
      }
    }
    .location-input {
      height: auto;
      input {
        background: transparent;
        width: 100%;
         padding: 6px;
      }
    }
  }
}
</style>
