<template>
    <section class="meeting-wrapper" :style="{ 'height': meetingsHeight }">
      <div v-if="!(profile.msEmail || profile.googleEmail)" class="no-calendar-text">
        <mdc-text tag="span" typo="body2">Connect your calendar <a class="link" @click="openConnectCalendar()">here</a></mdc-text>
      </div>
      <template v-else-if="todaysMeetings.length > 0">
        <base-svg-icon :style="{ top: timelineTop }" name="meeting-timeline" class="timeline" v-show="showTimeline"/>
        <template v-for="(meeting, index) in todaysMeetings">
          <meeting-event :first="index === 0" :windowWidth="windowWidth" :ref="meeting.id" v-if="meeting.calendarId && meeting.status !== 'cancelled'" :meeting="meeting" :key="'meet-' + index + '-' + meeting.id" :ongoingMeetings="ongoingMeetings" :class="{'ongoing-or-next': ongoingOrNextMeeting.meeting ? meeting.id === ongoingOrNextMeeting.meeting.id : false}" :scheduleDate="scheduleDate"/>
        </template>
      </template>
      <div v-else class="no-meetings-text">
        <mdc-text tag="span" v-if="meetingsLoaded" typo="body2">No meetings scheduled</mdc-text>
      </div>
    </section>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import MeetingEvent from './MeetingEvent.vue'
import { getMeetingForDate } from '../../utils/meetings'
import { sameDay } from '../../utils/time'
import uniqBy from 'lodash/uniqBy'
import BaseSvgIcon from './BaseSvgIcon.vue'
const _ = { uniqBy }
export default {
  name: 'Meeting',
  components: {
    MeetingEvent,
    BaseSvgIcon
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      timelineTop: 0,
      currentOngoing: false
    }
  },
  props: {
    meetings: {
      type: Array,
      default () {
        return []
      }
    },
    meetingsRec: {},
    scheduleDate: {
      type: Object,
      default: () => {
        return {}
      }
    },
    composerView: {
      type: String,
      default: ''
    }
  },
  created () {
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
    document.removeEventListener('visibilitychange', this.triggerTimeline)
  },
  mounted () {
    window.addEventListener('resize', this.getWindowWidth)
    document.addEventListener('visibilitychange', this.triggerTimeline)
  },
  computed: {
    ...mapState([
      'appDate',
      'profile'
    ]),
    ...mapState({
      meetingsLoaded: state => state.teams.meetingsLoaded
    }),
    showTimeline () {
      const time = this.appDate || moment()
      return sameDay(time, this.scheduleDate)
    },
    todaysMeetings () {
      let todaysMeetings = []
      if (this.meetingsLoaded && this.meetings && this.meetingsRec) {
        const scheduleDate = this.scheduleDate ? this.scheduleDate.toDate ? this.scheduleDate.toDate() : this.scheduleDate : null
        if (scheduleDate) {
          const meetings = getMeetingForDate(scheduleDate, this.meetings, this.meetingsRec)
          const meetingWithoutAllDay = meetings.filter(meeting => !meeting.isAllDay)
          todaysMeetings = _.uniqBy(meetingWithoutAllDay, 'id')
        }
      }
      return todaysMeetings
    },
    meetingsHeight () {
      let pastMeetingCount = 0
      let ongoingFutureCount = 0
      const elemHeight = 55
      const offset = 25

      if (this.todaysMeetings) {
        for (const meeting of this.todaysMeetings) {
          const end = meeting.endDateTime ? moment(meeting.endDateTime.toDate()) : null
          // console.log(`Meeting ${meeting.id} ${meeting.summary}`, end, moment().toDate(), moment().isAfter(end))
          if (end && this.appDate.isAfter(end) && !meeting.isAllDay) {
            pastMeetingCount++
          } else if (!meeting.isAllDay) {
            ongoingFutureCount++
          }
        }
      }

      const totalCount = pastMeetingCount + ongoingFutureCount

      if (totalCount) {
        if (pastMeetingCount && ongoingFutureCount) {
          // set height to show ongoing and future meetings + half of last past meeting
          let elemCount = ongoingFutureCount
          if (elemCount < 6) {
            elemCount += pastMeetingCount < (6 - elemCount) ? pastMeetingCount : (6 - elemCount)
          }
          const height = (elemCount * elemHeight) + offset
          // console.log('Meetings.meetingsHeight.offset', ongoingFutureCount, height)
          return `${height}px`
        } else {
          // either all meetings past or none past - show all
          const height = totalCount * elemHeight
          // console.log('Meetings.meetingsHeight.noOffset', totalCount, height)
          return `${height}px`
        }
      }
      return ''
    },
    ongoingOrNextMeeting () {
      let hasPast = false
      let isOngoing = false
      if (sameDay(this.appDate, this.scheduleDate)) {
        if (this.todaysMeetings) {
          const ongoingOrNext = this.todaysMeetings.filter(meeting => {
            const start = meeting.startDateTime ? moment(meeting.startDateTime.toDate()) : null
            const end = meeting.endDateTime ? moment(meeting.endDateTime.toDate()) : null
            if (start && end && this.appDate.isBetween(start, end) && !meeting.isAllDay) {
              isOngoing = true
              return true
            }
            if (end && this.appDate.isBefore(end) && !meeting.isAllDay) {
              return true
            } else {
              if (end && !meeting.isAllDay) {
                hasPast = true
              }
              return false
            }
          })
          if (ongoingOrNext.length > 0) {
            return { meeting: ongoingOrNext[0], hasPast, isOngoing }
          }
        }
      }
      return { meeting: false, hasPast, isOngoing }
    },
    ongoingMeetings () {
      if (sameDay(this.appDate, this.scheduleDate)) {
        if (this.todaysMeetings) {
          return this.todaysMeetings.filter(meeting => {
            const start = meeting.startDateTime ? moment(meeting.startDateTime.toDate()) : null
            const end = meeting.endDateTime ? moment(meeting.endDateTime.toDate()) : null
            return start && end && this.appDate.isBetween(start, end) && !meeting.isAllDay
          })
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions([
      'setSetupCalendarLater',
      'updateAppDate'
    ]),
    triggerTimeline () {
      if (!document.hidden) {
        this.updateAppDate()
        this.positionTimeLine(moment())
      }
    },
    positionTimeLine (calcTime) {
      const elemHeight = 55
      const time = calcTime || this.appDate
      // console.log('Meeting.positionTimeLine', this.ongoingOrNextMeeting)

      if (this.ongoingOrNextMeeting.meeting) {
        if (this.$refs[this.ongoingOrNextMeeting.meeting.id]) {
          const ongoingMeeting = this.$refs[this.ongoingOrNextMeeting.meeting.id][0]
          if (ongoingMeeting) {
            const ongoingMeetingEl = ongoingMeeting.$el
            // console.log('Meeting.positionTimeLine', this.showTimeline, this.ongoingOrNextMeeting)

            if (this.showTimeline) {
              if (this.ongoingOrNextMeeting.isOngoing) {
              // work out a percentage progress through the ongoing meeting
                let meetingProgressOffset = 0
                const ogMeeting = this.ongoingOrNextMeeting.meeting
                const meetingStart = moment(ogMeeting.startDateTime ? ogMeeting.startDateTime.toDate ? ogMeeting.startDateTime.toDate() : ogMeeting.startDateTime : false)
                const meetingEnd = moment(ogMeeting.endDateTime ? ogMeeting.endDateTime.toDate ? ogMeeting.endDateTime.toDate() : ogMeeting.endDateTime : false)
                if (meetingStart && meetingEnd) {
                  const duration = moment.duration(meetingEnd.diff(meetingStart))
                  const durationMins = duration.asMinutes()
                  const progressDuration = moment.duration(time.diff(meetingStart))
                  const progressMins = progressDuration.asMinutes()
                  const meetingProgressPercent = progressMins / durationMins
                  meetingProgressOffset = Math.ceil(elemHeight * meetingProgressPercent)
                // console.log('Meeting.positionTimeLine.ongoing', ongoingMeeting.summary, meetingStart.toDate(), meetingEnd.toDate(), durationMins, progressMins, meetingProgressPercent, meetingProgressOffset)
                }
                this.timelineTop = `${ongoingMeetingEl.offsetTop + meetingProgressOffset}px`
                return
              } else {
              // console.log('Meeting.positionTimeLine.notOngoing', ongoingMeeting.offsetTop - 6)
                this.timelineTop = `${ongoingMeetingEl.offsetTop - 6}px`
                return
              }
            }
          }
        }
      } else if (this.todaysMeetings && this.todaysMeetings.length) {
        const height = (this.todaysMeetings?.length || 0) * elemHeight
        this.timelineTop = `${height + 2}px`
        return
      }
      this.timelineTop = 0
    },
    positionOnGoingMeeting () {
      const hasOngoingOrNext = this.ongoingOrNextMeeting.meeting
      const meetingsScrollWrapper = document.getElementsByClassName('meeting-wrapper')[0]
      // console.log('Meeting.positionOnGoingMeeting', meetingsScrollWrapper, this.ongoingOrNextMeeting)
      if (!hasOngoingOrNext && this.currentOngoing) {
        this.currentOngoing = false
      }

      if (meetingsScrollWrapper && hasOngoingOrNext) {
        setTimeout(() => {
          if (this.currentOngoing !== this.ongoingOrNextMeeting.meeting.id) {
            this.currentOngoing = this.ongoingOrNextMeeting.meeting.id
            meetingsScrollWrapper.scrollTop = meetingsScrollWrapper.scrollHeight
          }
        }, 200)
      }
    },
    getWindowWidth () {
      this.windowWidth = window.innerWidth
    },
    openConnectCalendar () {
      this.setSetupCalendarLater(false)
      this.$emit('openConnectCalendar', true)
    }
  },
  watch: {
    ongoingOrNextMeeting: {
      handler () {
        if (this.ongoingOrNextMeeting) {
          setTimeout(() => {
            this.positionOnGoingMeeting()
            this.positionTimeLine()
          }, 300)
        }
      },
      immediate: true
    },
    appDate: {
      handler () {
        this.positionTimeLine()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">

.meeting-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  padding-top: 3px;
  scroll-behavior: smooth;
  padding-top:10px;

  .timeline {
    position: absolute;
    width: 100%;
    top: 23px;
    z-index: 20;
    img {
      margin-left:10px;
      margin-right:10px;
      width: calc(100% - 20px);
    }
  }

  .meetings:not(:empty) {
    color: #d5dbe2;
    display: flex;
    flex-direction: column;
    margin: 0 25px;
    padding-top: 10px;
    &:empty {
      padding-bottom: 25px;
    }
  }
  .no-meetings-text {
    margin: 20px auto;
    .mdc-text {
      color: #959EA7;
    }
  }
  .no-calendar-text {
    margin: 20px auto;
    .mdc-text {
      color: #959EA7;
    }
    .link {
      color: #6184AD;
    }
  }
}
</style>
