<template>
  <div class="workspace-admin-settings-wrapper">
    <section v-if="settingHeadingType.subType === 'wsInfo'" class="workspace-name sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Workspace Information</mdc-text>
      </header>
      <div class="workspace-info-content type-content">
        <div class="name-wrapper field-wrapper">
          <span class="name-label label">
            <mdc-text tag="span" typo="caption">NAME</mdc-text>
          </span>
          <span class="workspace-name">
            <mdc-text tag="span" typo="body2" class="name">{{ myWorkspace.name }}</mdc-text>
          </span>
          <mdc-text typo="caption" tag="span" class="change-info">To change you must upgrade to enterprise -
            <a class="support-link" target="_blank" href="mailto:support@duome.co">Contact us</a>
          </mdc-text>
        </div>
        <!-- <div class="members-wrapper field-wrapper">
          <span class="members-label label">
            <mdc-text tag="span" typo="caption">MEMBERS</mdc-text>
          </span>
          <span class="members-list">
            <profile-initials v-for="member in myWorkspaceMembers" :key="member.id" :profileId="member.id" :date="today" showDisplayName showYou/>
          </span>
        </div> -->
        <div class="display-admin-wrapper field-wrapper">
          <span class="admins-label label">
            <mdc-text tag="span" typo="caption">ADMINS</mdc-text>
          </span>
          <span class="admins-list">
            <div v-for="admin in myWorkspace.admins" :key="admin" class="admin-name-wrapper" role="presentation">
              <profile-initials :profileId="admin" :date="appDate" showDisplayName showYou />
              <mdc-button role="button" aria-label="remove-btn" v-if="admin !== profile.id" :class="myWorkspace.admins.length === 1 ? 'disabled' : ''" :key="admin + 'button'" class="remove-button" outlined @click.native="removeAdmin(admin)">Remove admin</mdc-button>
            </div>
          </span>
        </div>
        <div class="add-admin-wrapper field-wrapper">
          <span class="add-admins-label label">
            <mdc-text tag="span" typo="caption">ADD ADMIN</mdc-text>
          </span>
          <div class="search-wrapper">
            <div class="admin-name-search" v-if="myWorkspace.members.length > 0">
              <v-select :dropdown-should-open="dropdownShouldOpen" v-model="nameInput" :options="myWorkspaceMembers.filter(member => member.displayName !== profile.displayName)" :reduce="member => member.id" label="displayName" @input="setNewWorkspaceAdmin" placeholder="Start typing a name">
                <div slot="open-indicator">
                </div>
                <template #option="{ displayName }">
                  <base-svg-icon name="taskassignee-on"/>
                  <mdc-text typo="body2" tag="span">{{ displayName }}</mdc-text>
                </template>
              </v-select>
            </div>
            <mdc-button :class="!workspaceAdmin ? 'disabled' : ''" class="add-admin-button" @click.native="addWorkspaceAdmin">Add admin</mdc-button>
          </div>
          <mdc-text role="alert" aria-label="already-admin" class="help-text" tag="span" typo="caption">{{ helpText }}</mdc-text>
        </div>
        <div class="remove-user-wrapper field-wrapper">
          <span class="remove-user-label label">
            <mdc-text tag="span" typo="caption">REMOVE USER FROM WORKSPACE</mdc-text>
          </span>
          <div v-if="!confirmRemove" class="search-wrapper">
            <div class="remove-name-search" v-if="myWorkspace.members.length > 0">
              <v-select :dropdown-should-open="dropdownShouldOpen" v-model="nameRemoveInput" :options="myWorkspaceMembers.filter(member => member.displayName !== profile.displayName)" :reduce="member => member.id" label="displayName" @input="confirmRemoveWorkspaceUser" placeholder="Start typing a name">
                <div slot="open-indicator">
                </div>
                <template #option="{ displayName }">
                  <base-svg-icon name="taskassignee-on"/>
                  <mdc-text typo="body2" tag="span">{{ displayName }}</mdc-text>
                </template>
              </v-select>
            </div>
            <mdc-button :class="!workspaceAdmin || !nameRemoveInput ? 'disabled' : ''" class="remove-user-button" @click.native="confirmRemoveWorkspaceUser">Remove User</mdc-button>
          </div>
          <div class="remove-user-prompt" v-else-if="removeLoading">
            <mdc-text tag="span" typo="caption">Removing please wait</mdc-text>
          </div>
          <div class="remove-user-prompt" v-else>
            <mdc-text tag="span" typo="caption">Please confirm you wish to remove {{ nameRemoveInputName }}, this action is irreversable.</mdc-text>
            <div class="remove-prompt">
              <mdc-button outlined :class="!workspaceAdmin ? 'disabled' : ''" class="remove-user-button" @click.native="removeWorkspaceUser">Confirm</mdc-button>
              <mdc-button outlined :class="!workspaceAdmin ? 'disabled' : ''" class="remove-user-button" @click.native="confirmRemove = false">Cancel</mdc-button>
            </div>
          </div>
          <mdc-text role="alert" aria-label="already-admin" class="help-text" tag="span" typo="caption">{{ helpTextRemove }}</mdc-text>
        </div>
      </div>
    </section>
    <section v-if="settingHeadingType.subType === 'manageTeams'" class="manage-teams sub-type">
      <header class="type-heading">
        <mdc-text v-if="editTeam || showNewTeam" @click.native="clearEditAdd" class="link" tag="span" typo="body1">Manage Teams</mdc-text>
        <mdc-text v-else tag="span" typo="body1">Manage Teams</mdc-text>
        <mdc-text v-if="showNewTeam" tag="span" typo="body1"> > Add Team</mdc-text>
        <mdc-text v-else-if="editTeam" tag="span" typo="body1"> > Edit Team</mdc-text>
      </header>
      <div class="manage-teams-content type-content">
        <div class="manage-teams-wrapper field-wrapper">
          <div class="team-list-wrapper" v-if="!editTeam && !showNewTeam">
            <mdc-button class="add-team-main" @click.native="showAddTeam">
              <base-svg-icon name="plus_blue" />
              <mdc-text typo="body1" tag="span">ADD TEAM</mdc-text>
            </mdc-button>
            <div class="all-teams-list">
              <div class="team-detail" v-for="team in sortedTeams" :key="team.id">
                <div class="name-members-wrapper">
                  <div class="name-edit">
                    <mdc-text class="team-name" tag="span" typo="body2">{{team.name}}</mdc-text>
                    <base-svg-icon name="pen-edit" clickable @click.native="showEditTeam(team)" />
                  </div>
                  <div class="members-list" v-if="team.members.length > 0">
                    <profile-initials v-for="(member, index) in team.members" :key="team.id + '-ma-' + member + '-' + index" :profileId="member" :date="appDate"/>
                  </div>
                  <mdc-text tag="span" typo="body2" v-else>No team members</mdc-text>
                </div>
              </div>
            </div>

            <!-- <table class="team-details-table">
              <tr>
                <td>
                  <mdc-text typo="body2">TEAM NAME</mdc-text>
                </td>
                <td>
                  <mdc-text typo="body2">MEMBERS</mdc-text>
                </td>
                <td></td>
              </tr>
              <tr v-for="team in sortedTeams" :key="team.id">
                <td class="team-name">
                  <mdc-text class="team-name-text" tag="span" typo="body2">{{team.name}}</mdc-text>
                </td>
                <td>
                  <div class="members-list field-lists" >
                    <profile-initials v-for="(member, index) in team.members" :key="team.id + '-ma-' + member + '-' + index" :profileId="member" :date="appDate"/>
                  </div>
                </td>
                <td>
                  <mdc-button class="edit-team-btn" @click.native="showEditTeam(team)" outlined>Edit Team</mdc-button>
                </td>
              </tr>
            </table> -->
          </div>

          <div class="new-team" v-if="showNewTeam">

            <div class="name-wrapper field-wrapper">
              <span class="admins-label label">
                <mdc-text tag="span" typo="caption">TEAM NAME*</mdc-text>
              </span>
              <mdc-textfield
                @keypress.enter="createTeam"
                placeholder="Enter a team name"
                required
                outlined
                iconOutlined
                helptext-persistent
                v-model="newTeamName">
              </mdc-textfield>
              <mdc-body v-if="helpText" typo="body2" class="helptext" v-html="helpText"></mdc-body>
            </div>

            <div class="members-wrapper field-wrapper">
              <span class="members-label label">
                <mdc-text tag="span" typo="caption">MEMBERS</mdc-text>
              </span>
              <span class="members-list field-lists">
                <profile-initials v-for="(member, index) in [profile.id]" :key="'m-' + member + '-' + index" :profileId="member" :date="appDate" showDisplayName showYou/>
              </span>
            </div>

            <div class="display-admin-wrapper field-wrapper">
              <span class="admins-label label">
                <mdc-text tag="span" typo="caption">ADMINS</mdc-text>
              </span>
              <span class="admins-list">
                <div v-for="admin in [profile.id]" :key="'a-' + admin" class="admin-name-wrapper">
                  <profile-initials :profileId="admin" :date="today" showDisplayName showYou/>
                </div>
              </span>
            </div>
            <div class="add-team-actions">
              <mdc-button class="cancel-button" outlined @click.native="resetData">Cancel</mdc-button>
              <mdc-button class="add-team-button" @click.native="createTeam">Add Team</mdc-button>
            </div>
          </div>
          <div v-else-if="editTeam">
            <team-admin-settings :team="editTeam" :settingHeadingType="{ subType: 'teamInfo' }" />
            <mdc-button class="cancel-button" outlined @click.native="resetData">Cancel</mdc-button>
          </div>

        </div>
      </div>
    </section>
    <section v-if="settingHeadingType.subType === 'asscEmailDomains'" class="assoc-email-domains sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Approved Email Domains</mdc-text>
      </header>
      <div class="email-domain-content type-content">
        <div class="email-domain-wrapper field-wrapper">
          <span class="domain-label label">
            <mdc-text tag="span" typo="caption">DOMAINS</mdc-text>
          </span>
          <mdc-text typo="caption" tag="span" class="domain-info">Users that have access to an email for the domains below will be able to access your workspace</mdc-text>
          <span class="domain-name">
            <mdc-text v-if="myWorkspace.domainUrls" tag="span" typo="body2" class="name">{{ joinDomains(myWorkspace.domainUrls) }}</mdc-text>
            <mdc-text v-else tag="span" typo="body2" class="name">{{ myWorkspace.domainUrl }}</mdc-text>
          </span>
          <mdc-text typo="caption" tag="span" class="change-info">To add multiple domains you must upgrade to enterprise -
            <a class="support-link" target="_blank" href="mailto:support@duome.co">Contact us</a>
          </mdc-text>

          <div class="guest-access manage" >
            <div class="guest-access-row">
              <mdc-text typo="caption" class="workspace-set-text">
                GUEST ACCESS
              </mdc-text>

              <div class="guest-access-set-switch">
                <mdc-text typo="body2" class="workspace-set-label">Off</mdc-text>
                <mdc-switch @change="setGuestAccess" v-model="guestAccess" class="switch-row">
                </mdc-switch>
                <mdc-text typo="body2" class="workspace-set-label">ON</mdc-text>
              </div>
            </div>

            <mdc-text typo="caption" tag="div" class="guest-access-info">
              Enable any domain to join your workspace by setting guest access to on.
              This is useful if you want to allow users with public email addressses (like gmail) to join.
            </mdc-text>
            <mdc-text typo="caption" tag="span" class="change-info">
              You have {{guestCount || 0}} guests registered in the workspace.
              <br><br>
              Note: if you enable this you will be able to invite anyone to join your workspace, not just those from an approved domain.
            </mdc-text>

          </div>

        </div>
      </div>
    </section>
    <section v-if="settingHeadingType.subType === 'teamPrivacySettings'" class="team-privacy-settings sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Team Privacy</mdc-text>
      </header>
      <div class="team-privacy-content type-content">
      </div>
    </section>
    <section v-if="settingHeadingType.subType === 'officeLocations'" class="office-locations-settings sub-type">
      <workspace-locations />
    </section>
    <section v-if="settingHeadingType.subType === 'workspaceLocationSchedules'" class="location-schedules sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Location schedules by workspace</mdc-text>
      </header>
      <div class="location-schedules-content type-content">
        <setup-schedules :from="'workspaceAdmin'" workspaceAdmin />
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import ProfileInitials from '../flexibly/ProfileInitials.vue'
import WorkspaceLocations from '../flexibly/WorkspaceLocations.vue'
import TeamAdminSettings from '../flexibly/TeamAdminSettings.vue'
import SetupSchedules from '../flexibly/SetupSchedules.vue'
import BaseSvgIcon from '../flexibly/BaseSvgIcon.vue'
import { onboarding, cloudFunction } from '../../utils/backend'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'WorkspaceAdminSettings',
  props: {
    settingHeadingType: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    ProfileInitials,
    WorkspaceLocations,
    SetupSchedules,
    TeamAdminSettings,
    BaseSvgIcon
  },
  computed: {
    ...mapState([
      'profile',
      'profiles',
      'appDate',
      'user'
    ]),
    ...mapState({
      myWorkspace: state => state.workspace,
      workspaceRoles: state => state.teams.workspaceRoles,
      teams: state => state.teams.teams
    }),
    nameRemoveInputName () {
      if (this.nameRemoveInput) {
        if (this.profiles) {
          return this.profiles.filter(prof => prof.id === this.nameRemoveInput)[0].displayName
        }
      }
      return ''
    },
    guestCount () {
      let count = 0
      for (const pr of this.profiles) {
        if (this.workspaceRoles) {
          const wsRole = this.workspaceRoles.filter(wsr => wsr.teamId === pr.team && wsr.userId === pr.id)[0]
          if (wsRole && wsRole.role === 'guest') {
            count++
          }
        }
      }
      return count
    },
    sortedTeams () {
      if (this.teams) {
        const sorted = [...this.teams]
        sorted.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase()
        })
        return sorted
      }
      return []
    },
    myWorkspaceMembers () {
      const wsMem = this.myWorkspace.members.map((email) => {
        const allProfiles = [...this.profiles, this.profile]
        const profileDetail = allProfiles.filter(profile => profile.email === email)
        if (profileDetail[0] && !profileDetail[0].disabled) {
          return profileDetail[0]
        }
        return false
      })
      return wsMem.filter(mem => mem)
    }
  },
  data () {
    return {
      today: moment(),
      helpText: '',
      nameInput: '',
      workspaceAdmin: '',
      showNewTeam: false,
      newTeamName: '',
      newTeamDescription: '',
      newTeamSelectedDescription: [],
      newTeamInvites: [],
      editTeam: false,
      nameRemoveInput: '',
      confirmRemove: false,
      helpTextRemove: '',
      removeLoading: false,
      guestAccess: false
    }
  },
  methods: {
    ...mapActions([
      'updateWorkspace',
      'setPageLoad',
      'saveGuestAccessWorkspace'
    ]),
    ...mapActions({
    }),
    setGuestAccess (val) {
      console.log('setGuestAccess', val)
      this.saveGuestAccessWorkspace({ guestAccessWorkspace: val })
    },
    showAddTeam () {
      this.showNewTeam = true
    },
    hideAddTeam () {
      this.showNewTeam = false
    },
    showEditTeam (team) {
      this.editTeam = team
    },
    confirmRemoveWorkspaceUser () {
      this.helpTextRemove = ''
      if (this.nameRemoveInput) {
        this.confirmRemove = true
      }
    },
    async removeWorkspaceUser () {
      this.removeLoading = true
      this.helpTextRemove = ''
      const params = {
        id: this.nameRemoveInput,
        workspace: this.myWorkspace.id
      }
      const response = await cloudFunction('deleteUserData', params)
      console.log('workspaceAdminSettings.removeWorkspaceUser', response)
      if (response.error) {
        this.helpTextRemove = 'Unable to remove user'
      } else {
        this.helpTextRemove = 'User removed successfully'
      }
      this.removeLoading = false
      this.nameRemoveInput = ''
      this.confirmRemove = false
    },
    async addWorkspaceAdmin () {
      if (!this.myWorkspace.admins.includes(this.workspaceAdmin)) {
        const admins = [...this.myWorkspace.admins, this.workspaceAdmin]
        await this.updateWorkspace({ workspaceId: this.myWorkspace.id, updates: { admins } })
      } else {
        this.helpText = 'Person you are trying to add is already an admin'
      }
    },
    async removeAdmin (member) {
      if (this.myWorkspace.admins.includes(member)) {
        const memberIndex = this.myWorkspace.admins.findIndex(admin => admin === member)
        if (memberIndex !== -1) {
          const admins = [...this.myWorkspace.admins]
          admins.splice(memberIndex, 1)
          await this.updateWorkspace({ workspaceId: this.myWorkspace.id, updates: { admins } })
        }
      }
    },
    dropdownShouldOpen (VueSelect) {
      if (this.nameInput !== '') {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    setNewWorkspaceAdmin (val) {
      this.workspaceAdmin = val
    },
    resetData () {
      this.workspaceAdmin = ''
      this.nameInput = ''
      this.helpText = ''
      this.newTeamName = ''
      this.showNewTeam = false
      this.editTeam = false
      this.guestAccess = this.myWorkspace.guestAccessWorkspace
    },
    splitDomains (domains) {
      return domains.split(',').join(' ')
    },
    joinDomains (domains) {
      return domains.join(' ')
    },
    clearEditAdd () {
      this.showNewTeam = false
      this.editTeam = false
    },
    isTeamValid (team) {
      if (!team.name || team.name === '') {
        this.helpText = 'Please enter a valid team name'
        return false
      }
      const sameName = this.teams.filter(t => t.name.toLowerCase() === team.name.toLowerCase())
      if (sameName.length > 0) {
        this.helpText = 'A team with this name already exists'
        return false
      }
      return true
    },
    async createTeam () {
      const params = {
        userId: this.user.uid,
        email: this.user.email,
        displayName: this.user.displayName,
        teamName: this.newTeamName,
        teamDescription: this.newTeamDescription,
        teamDescriptionSelected: this.newTeamSelectedDescription,
        invites: this.newTeamInvites,
        noSwitch: true
      }
      if (this.isTeamValid({ name: this.newTeamName })) {
        let response
        try {
          this.setPageLoad(true)
          response = await onboarding('create-team', params)
          console.log('WSAS.createTeam', response)
          if (response.teamId) {
            await firebase.auth().currentUser.getIdToken(true)
            setTimeout(() => {
              this.showNewTeam = false
              this.setPageLoad(false)
              this.editTeam = this.teams.filter(t => t.id === response.teamId)[0]
            }, 500)
          } else {
            this.setPageLoad(false)
          }
        } catch (error) {
          this.setPageLoad(false)
          console.error('WSAS.createTeam', error, response)
        }
      }
    }
  },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {},
  watch: {
    newTeamName () {
      this.helpText = ''
    },
    teams () {
      if (this.editTeam) {
        const editUpdated = this.teams.filter(t => t.id === this.editTeam.id)[0]
        this.editTeam = editUpdated
      }
    },
    nameInput: {
      handler () {
        if (!this.nameInput) {
          this.helpText = ''
        }
      }
    },
    workspaceRoles () {
      if (this.workspaceRoles) {
        console.log(this.workspaceRoles)
      }
    },
    myWorkspace: {
      handler () {
        if (this.myWorkspace) {
          this.resetData()
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.workspace-admin-settings-wrapper {
  .team-list-wrapper {
    display:flex;
    flex-direction: column;
    .add-team-main {
      max-width: 200px;
      border: 0.5px solid #6184AD;
      border-radius: 4px;
      margin-bottom: 40px;
      .mdc-text {
        margin-left: 5px;
        color: #6184AD;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .all-teams-list {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      .team-detail {
        display: flex;
        border-bottom: 0.5px solid #E0E0E0;
        padding: 10px 0 5px 0;
        &:first-of-type {
          border-top: 0.5px solid #E0E0E0;
        }
        .name-members-wrapper {
          display: flex;
          flex-direction: column;
          .name-edit {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .team-name {
              text-transform: capitalize;
              font-weight: 600;
              color: #4175B1;
              flex: 0 0 275px;
            }
            .icon-wrapper {
            }
          }
          .members-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .profile-redirect {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .sub-type {
    max-width: 600px;
    .type-heading {
      margin-bottom: 20px;
      .mdc-text {
        color: #959EA7;
        font-weight: 700;
      }
    }
    .type-content {
      .support-link {
        color: #6184AD;
        font-weight: 600;
        text-decoration: none;
      }
      .change-info {
        margin-top: 5px;
      }
      &.type-content {
        .field-wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          .label {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            text-transform: uppercase;
            .mdc-text {
              margin-right: 5px;
              color: #828282;
            }
            .icon-wrapper {
              &.edit-icon {
                height: 12px;
                width: 12px;
                img {
                  height: 100%;
                  width: 100%;
                }
              }
            }
          }
          &.members-wrapper {
            .members-list {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              .profile-redirect {
                margin-bottom: 5px;
              }
            }
          }
          &.name-wrapper {
            .name-edit-wrapper {
              display: flex;
              flex-direction: column;
              input {
                max-width: 180px;
                border-radius: 4px;
                border: 0.5px solid #959EA7;
                margin-bottom: 10px;
                padding: 5px;
                font-size: 14px;
                color: #404040;
                height: 20px;
              }
            }
            .edit-actions {
              display: flex;
              align-items: center;
              .mdc-button {
                max-width: 30px;
                color: #6184Ad;
                margin-right: 30px;
                font-size: 12px;
                height: 25px;
              }
            }
          }
          &.display-admin-wrapper {
            .admins-list {
              .admin-name-wrapper:not(:last-of-type) {
                margin-bottom: 15px
              }
              .admin-name-wrapper {
                display: flex;
              }
            }
            .remove-button {
              text-transform: none;
              color: #6184Ad;
              margin-right: 30px;
              font-size: 12px;
              height: 25px;
              &.disabled {
                opacity: 0.5;
                pointer-events: none;
              }
            }
          }
          &.add-admin-wrapper {
            display: flex;
            flex-direction: column;
            .search-wrapper {
              display: flex;
              align-items: center;
              .admin-name-search {
                margin: 0;
                background: #f2f2f2;
                width: 200px;
                border-radius: 4px;
                margin-right: 20px;
                .v-select {
                  .vs__selected {
                    color: #404040;
                    font-size: 14px;
                  }
                  .vs__dropdown-toggle .vs__selected-options {
                    padding: 0;
                    input {
                      font-size: 14px;
                      color: #404040;
                      height: 32px;
                      &::-webkit-input-placeholder {
                        color: #959EA7;
                        font-size: 14px;
                        padding-left: 5px;
                      }
                      &::-webkit-textfield-decoration-container {
                        padding-left: 5px;
                      }
                    }
                  }
                  .vs__dropdown-menu {
                    margin: 0;
                    list-style: none;
                    z-index: 11;
                    background: #fff;
                    border-radius: 6px;
                    color: #6184ad;
                    position: absolute;
                    border: none;
                    padding: 0;
                    .vs__dropdown-option {
                      padding: 8px 12px;
                      display: inline-flex;
                      align-items: baseline;
                      width: 100%;
                      font-size: 14px;
                      cursor: pointer;
                      color: #6184ad;
                      white-space: normal;
                      .icon-wrapper {
                        margin-right: 8px;
                        height: 10px;
                        width: 10px;
                        img {
                          height: 100%;
                          width: 100%;
                        }
                      }
                    }
                    .vs__dropdown-option:hover {
                      background: #EBF3FC;
                    }
                    .vs__dropdown-option--highlight {
                      background: #EBF3FC;
                    }
                  }
                }
              }
              .add-admin-button {
                text-transform: none;
                min-width: 100px;
                background: #00498D;
                color: white;
                height: 40px;
                &.disabled {
                  opacity: 0.5;
                  pointer-events: none;
                }
              }
            }
          }
          &.remove-user-wrapper {
            display: flex;
            flex-direction: column;
            .search-wrapper {
              display: flex;
              align-items: center;
              .remove-name-search {
                margin: 0;
                background: #f2f2f2;
                width: 200px;
                border-radius: 4px;
                margin-right: 20px;
                .v-select {
                  .vs__selected {
                    color: #404040;
                    font-size: 14px;
                  }
                  .vs__dropdown-toggle .vs__selected-options {
                    padding: 0;
                    input {
                      font-size: 14px;
                      color: #404040;
                      height: 32px;
                      &::-webkit-input-placeholder {
                        color: #959EA7;
                        font-size: 14px;
                        padding-left: 5px;
                      }
                      &::-webkit-textfield-decoration-container {
                        padding-left: 5px;
                      }
                    }
                  }
                  .vs__dropdown-menu {
                    margin: 0;
                    list-style: none;
                    z-index: 11;
                    background: #fff;
                    border-radius: 6px;
                    color: #6184ad;
                    position: absolute;
                    border: none;
                    padding: 0;
                    .vs__dropdown-option {
                      padding: 8px 12px;
                      display: inline-flex;
                      align-items: baseline;
                      width: 100%;
                      font-size: 14px;
                      cursor: pointer;
                      color: #6184ad;
                      white-space: normal;
                      .icon-wrapper {
                        margin-right: 8px;
                        height: 10px;
                        width: 10px;
                        img {
                          height: 100%;
                          width: 100%;
                        }
                      }
                    }
                    .vs__dropdown-option:hover {
                      background: #EBF3FC;
                    }
                    .vs__dropdown-option--highlight {
                      background: #EBF3FC;
                    }
                  }
                }
              }
              .remove-user-button {
                text-transform: none;
                min-width: 100px;
                background: #00498D;
                color: white;
                height: 40px;
                &.disabled {
                  opacity: 0.5;
                  pointer-events: none;
                }
              }
            }
            .remove-user-prompt {
              display: flex;
              align-items: center;
              .remove-prompt {
                .mdc-text {
                  margin:10px;
                  color: #4f4f4f;
                }
                .mdc-button {
                  text-transform: none;
                  margin-left:5px;
                  color: #6184Ad;
                  font-size: 12px;
                  height: 32px;
                  line-height: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
  .new-team {
    display: flex;
    flex-direction: column;
    .mdc-text-field {
      height: 40px;
      width: 400px;
      input {
        font-size: 14px;
        font-weight: 500;
        &::placeholder {
          opacity: 1;
          font-size: 14px;
        }
      }
    }
    .add-team-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 400px;
      .mdc-button {}
    }
    .add-team-button {
      min-width: 100px;
      background: #00498D;
      color: white;
      text-transform: none;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .cancel-button {
    min-width: 100px;
    text-transform: none;
    color: #00498D;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .domain-label {
    margin-bottom: 5px;
  }
  .domain-info {
    color: #828282;
    margin-bottom:20px;
  }
  .guest-access-info {
    color: #828282;
    margin-bottom:20px;
  }
  .change-info {
    margin-top:20px !important;
    margin-bottom:20px;
  }
  .guest-access-row {
    display:flex;
    text-transform: uppercase;
    .mdc-text {
      margin-right: auto;
      color: #828282;
    }
    .guest-access-set-switch {
      display: flex;
      justify-content: center;
      align-items: center;

      .switch-row {
        margin-right:10px;
        margin-left:10px;
      }
    }
  }
}
</style>
