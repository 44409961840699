<template>
  <div role="info" aria-label="desk-details" class="desk-details-wrapper"
    :class="[deskStatus ? deskState : '', flipDetails ? 'invert' : '', tooltipOpen ? 'tooltip-open' : 'tooltip-close' ]"
    @mouseover.prevent.stop="setBookDeskLink($event)"
    @mouseleave.prevent.stop="resetBookDeskLink($event)"
    @click="bookTheDesk()">
    <div class="profile-wrapper" v-if="bookDesk || availStatus === 'booked' || availStatus === 'unavailable'"> <!-- book/booked desk tick -->
      <base-svg-icon class="book-circle"
        :name="availStatus === 'booked' ? 'tick-rounded-background' : availStatus === 'unavailable' ? 'profile-rounded-d9d9d9' : 'tick-rounded-outline'">
      </base-svg-icon>
    </div>
    <div class="profile-wrapper" v-else-if="emptyProfile"> <!-- dotted profile wrapper -->
      <profile-initials class="desk-profile" dottedEmptyProfile />
    </div>
    <profile-initials  v-else class="desk-profile clickable" :profileId="profileId" :date="appDate" :showProfileDetails="false" @click.native.stop.prevent="openDetails('member')"/>
    <div class="count-icons-wrapper">
      <span class="book-status-text book clickable" v-if="deskState === 'available'">Book Desk</span>
      <span class="count-text" v-else-if="deskState !== 'crowded'">{{ countText }}</span>
      <div class="init-sch-icons" v-if="!emptyProfile" >
        <slot name="tooltipDetails"></slot>
        <base-svg-icon v-if="initiativesWithUser.length > 0" class="rocket-icon" :name="getIconName('initiative')" clickable @click.native.stop.prevent="openDetails('initiative')"/>
        <base-svg-icon v-if="meetingsWithUser.length > 0" class="calendar-icon"  :name="getIconName('meeting')" clickable @click.native.stop.prevent="openDetails('meeting')"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProfileInitials from './ProfileInitials.vue'
import BaseSvgIcon from './BaseSvgIcon.vue'
import { getMeetingForDate } from '../../utils/meetings'
import uniqBy from 'lodash/uniqBy'
const _ = { uniqBy }
export default {
  name: 'Templates',
  components: {
    ProfileInitials,
    BaseSvgIcon
  },
  props: {
    availStatus: {
      type: String, // available, booked, unavailable
      default: ''
    },
    meetingsWithMember: {
      type: Array,
      default: () => {
        return []
      }
    },
    tooltipDetails: {
      type: Object,
      default: () => {
        return {}
      }
    },
    countText: {
      type: String,
      default: ' '
    },
    profileId: {
      default: ''
    },
    deskStatus: {
      type: String, // available, unavailable, booked, empty, submitted, default and overcrowded
      default: ''
    },
    emptyProfile: {
      type: Boolean,
      default: false
    },
    flipDetails: {
      type: Boolean,
      default: false
    },
    selectedDate: {
    }
  },
  data () {
    return {
      allMeetingsWithUser: [],
      allInitiativesWithUser: [],
      bookDesk: false,
      isDeskBooked: false
    }
  },
  beforeDestroy () {},
  created () {},
  computed: {
    ...mapState([
      'appDate',
      'locationIndicatorDialogDate',
      'profiles',
      'profile'
    ]),
    ...mapState({
      meetings: state => state.teams.meetings,
      meetingsRec: state => state.teams.meetingsRec,
      initiatives: state => state.teams.initiatives
    }),
    deskState () {
      if (this.bookDesk) {
        return this.availStatus
      }
      return this.deskStatus
    },
    tooltipOpen () {
      if (this.profileId) {
        return this.tooltipDetails.memberId === this.profileId
      }
      return false
    },
    initiativesWithUser () {
      if (this.profile.id === this.profileId) {
        return []
      }
      const allProfiles = [...this.profiles, this.profile]
      const memberDetail = allProfiles.filter(prof => prof.id === this.profileId)[0]
      let teamInits = []
      let initsWithSelfAndMember = []
      if (memberDetail) {
        if (memberDetail.team === this.profile.team) {
          teamInits = this.initiatives.filter((initiative) => initiative.teamId === this.profile.team)
        }
      }
      initsWithSelfAndMember = this.initiatives.filter(init => init.members.includes(this.profile.id) && init.members.includes(this.profileId))
      const allInits = [...teamInits, ...initsWithSelfAndMember]
      return _.uniqBy(allInits, 'id')
    },
    meetingsWithUser () {
      if (this.profile.id === this.profileId) {
        return []
      }
      const allProfiles = [...this.profiles, this.profile]
      const profile = allProfiles.filter(prof => prof.id === this.profileId)[0]
      if (profile) {
        let selectedDate = this.selectedDate
        if (selectedDate) {
          if (selectedDate.toDate) {
            selectedDate = selectedDate.toDate()
          }
          const datesMeetings = getMeetingForDate(selectedDate, this.meetings, this.meetingsRec)
          const userMeetings = datesMeetings.filter(meet => {
            if (meet.status !== 'cancelled') {
              let inMeeting = false
              if (profile.email) {
                inMeeting = meet.attendeeEmails.includes(profile.email)
              }
              if (profile.msEmail) {
                inMeeting = inMeeting || meet.attendeeEmails.includes(profile.msEmail)
              }
              if (profile.googleEmail) {
                inMeeting = inMeeting || meet.attendeeEmails.includes(profile.googleEmail)
              }
              return inMeeting
            }
            return false
          })
          return userMeetings
        }
      }
      return []
    }
  },
  mounted () {},
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
      getProfileById: 'teams/getProfileById'
    }),
    bookTheDesk () {
      if (this.deskState === 'available') {
        this.$emit('bookDesk')
      }
    },
    cancelTheDesk () {
      this.$emit('cancelDesk')
    },
    setBookDeskLink ($event) {
      $event.cancelBubble = true
      if (this.availStatus) {
        if (this.availStatus === 'available') {
          this.bookDesk = true
        }
      }
    },
    resetBookDeskLink ($event) {
      this.bookDesk = false
    },
    openDetails (type) {
      this.$emit('openTooltip', type, this.profileId)
    },
    getIconName (type) {
      if (type === 'initiative') {
        if (this.deskState === 'crowded') {
          return this.tooltipOpen && this.tooltipDetails.type === type ? 'rocket-white-hover' : 'rocket-084388'
        }
        return this.tooltipOpen && this.tooltipDetails.type === type ? 'rocket-084388-hover' : 'rocket-white'
      }

      if (type === 'meeting') {
        if (this.deskState === 'crowded') {
          return this.tooltipOpen && this.tooltipDetails.type === type ? 'calendar-white-hover' : 'calendar-084388'
        }
        return this.tooltipOpen && this.tooltipDetails.type === type ? 'calendar-084388-hover' : 'calendar-white'
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.desk-details-wrapper {
  position: relative;
  height: 53px;
  width: 90px;
  border-radius: 5px;
  border: 2px solid #FFFFFF;
  background: #fff;
  display: flex;
  &.tooltip-open {
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.49));
    transform: perspective(1px) translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    z-index: 2;
    padding-left:2px;
    padding-right:2px;
    padding-bottom:2px;
    margin-bottom:-4px;
    margin-right:-4px;
  }
  &.invert {
    margin-top: 55px;
    .desk-profile,
    .profile-wrapper {
      top: -15px;
    }
    .count-icons-wrapper {
      flex-direction: column-reverse;
      .init-sch-icons {
        margin: 9px 7px;
      }
    }
    &.crowded {
      .count-icons-wrapper {
        flex-direction: column;
      }
    }
  }
  &.empty {
    border: 2px dashed #2B8AFB;
    background: #fff;
    .count-icons-wrapper {
      .count-text {
        color: #404040;
      }
    }
  }
  &.booked {
    border: 2px solid #01CB52;
    background: #01CB52;
  }
  &.unavailable {
    border: 2px solid #FFFFFF;
    background: rgba(64, 64, 64, 0.2);
    .count-icons-wrapper {
      .count-text {
        color: #404040;
      }
    }
  }
  &.available {
    border: 2px solid #01CB52;
    background: #fff;
    cursor: pointer;
  }
  &.submitted {
    background: #1B5EAD;
    border: 2px solid #FFFFFF;
  }
  &.default {
    background: #6097D9;
    border: 2px solid #FFFFFF;
  }
  &.crowded {
    background: repeating-linear-gradient(
      45deg,
      #FFDBDB,
      #FFDBDB 2px,
      #ffffff 2px,
      #ffffff 5px
    );
    border: 2px solid #FFFFFF;
  }
  .desk-profile {
    max-height: max-content;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 28px;
    top: 45px;
    .profile-schedule {
      background-size: 21px;
      height: 30px;
      width: 30px;
      &:before {
        width: 30px;
        height: 30px;
      }
      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        height: calc(100% + 8px);
        width: calc(100% + 8px);
        border: 2px solid white;
      }
      &.none {
        &:after {
          all: unset;
        }
      }
      img.profile-initials {
        width: 30px;
        height: 30px;
      }
    }
  }
  .profile-wrapper {
    position: absolute;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 40px;
    background: white;
    left: 28px;
    border-radius: 50%;
    .desk-profile {
      all: unset;
      max-height: max-content;
      &.dotted-profile {
        .profile-schedule {
          background-size: 30px 30px;
          &:before {
            width: 34px;
            height: 34px;
          }
          &:after {
            all: unset;
          }
        }
      }
    }
  }
  .count-icons-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    .count-text {
      color: #FFFFFF;
      font-weight: 400;
      font-size: 11px;
      margin: 7px 4px;
      justify-content: center;
      text-align: center;
      letter-spacing: 2.5px;
      min-height: 12px;
    }
    .book-status-text {
      &.book {
        color: #01CB52;
      }
      &.cancel {
        color: #fff;
      }
      font-weight: 700;
      font-size: 10px;
      margin: 10px auto;
      justify-content: center;
      text-align: center;
    }
    .init-sch-icons {
      display: flex;
      margin: auto 7px 5px 7px;
      position: relative;
      justify-content: space-between;
      .icon-wrapper {
        height: 9px;
        width: 9px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .rocket-icon {}
      .calendar-icon {}
    }
  }
  .desktooltip-wrapper {
    &.initiative {}
    &.member {}
    &.meeting {}
  }
}
</style>
