<template>
  <span v-if="tooltipText" :class="pointerPosition" class="tool-tip tooltip-text">
    <base-svg-icon v-if="tooltipIcon" :name="tooltipIcon" class="tooltip-icon" />
    <mdc-text tag="span" typo="caption" class="tooltip-content" v-html="tooltipText"></mdc-text>
  </span>
</template>

<script>
import BaseSvgIcon from './BaseSvgIcon.vue'

export default {
  name: 'Tooltip',
  components: {
    BaseSvgIcon
  },
  props: {
    tooltipIcon: {
      default: '',
      type: String
    },
    tooltipText: {
      default: '',
      type: String
    },
    pointerPosition: {
      default: 'center',
      type: String
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.tool-tip { // hyphenated name to avoid common css override
  position: absolute;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  padding: 5px;
  line-height: 14px;
  display:flex;
  flex-flow:row;
  .tooltip-content {
    text-align: center;
  }
  .tooltip-icon {
    margin:5px;
  }
  &.center:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
  &.left:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 20px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
  &.right:after {
    content: "";
    position: absolute;
    top: 100%;
    right: 20px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
}
</style>
