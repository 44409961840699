<template>
  <div class="ui-snackbar-container">
    <div class="ui-snackbar" v-show="show" transition="ui-snackbar-toggle">
      <div class="ui-snackbar-text" v-html="message">
      </div>
      <div class="ui-snackbar-action">
        <mdc-button @click="closeAction">{{actionText}}</mdc-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
      default: ''
    },
    actionText: {
      required: false,
      default: 'OK'
    },
    actionHandler: {
      required: false
    },
    timeout: {
      required: false,
      default: 5000
    }
  },
  data () {
    return {
      show: false
    }
  },
  watch: {
    message: {
      handler (newVal, oldVal) {
        if (newVal !== '') {
          this.show = true
          if (this.timeout > 0) {
            setTimeout(() => {
              this.closeAction()
            }, this.timeout)
          }
        }
      },
      immediate: true
    }
  },
  transitions: {
    'ui-snackbar-toggle': {
      afterEnter () {
        // console.log('snackbar -entered')
      },
      afterLeave () {
        // console.log('snackbar -left')
      }
    }
  },
  methods: {
    closeAction () {
      this.show = false
      if (this.actionHandler) {
        this.actionHandler()
      } else {
        this.$emit('update:message', '')
      }
    }
  }
}
</script>

<style>
.ui-snackbar-text * {
  color:white !important;
}
</style>
<style scoped>
.ui-snackbar-container {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  left: 8px;
  z-index:9999;
}
.ui-snackbar {
  display: inline-flex;
  align-items: center;
  min-width: 288px;
  max-width: 568px;
  min-height: 48px;
  padding: 14px 24px;
  margin: 4px 4px 8px 4px;
  border-radius: 2px;
  background-color: #323232;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.ui-snackbar-text {
  font-size: 14px;
  color: #fff;
}
.ui-snackbar-action {
  margin-left: auto;
  padding-left: 48px;
}
.ui-snackbar-action button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
  color: #2D9CDB;
}
.ui-snackbar-toggle-transition {
  transition: transform 0.3s ease;
}
.ui-snackbar-toggle-transition .ui-snackbar-text,
.ui-snackbar-toggle-transition .ui-snackbar-action {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.ui-snackbar-toggle-enter,
.ui-snackbar-toggle-leave {
  transform: translateY(60px);
}
.ui-snackbar-toggle-enter .ui-snackbar-text,
.ui-snackbar-toggle-leave .ui-snackbar-text,
.ui-snackbar-toggle-enter .ui-snackbar-action,
.ui-snackbar-toggle-leave .ui-snackbar-action {
  opacity: 0;
}
</style>
