<template>
  <div class="office-activity-wrapper">
    <base-svg-icon class="close-icon" name="cross-thin" clickable @click.native="closeOfficeActivity()"/>
    <div class="activity-details">
      <mdc-text class="activity-date" tag="span" typo="caption">{{ getDate() }}</mdc-text>
      <section class="off-loc-name">
        <location-name v-if="officeLocation" :locationDetails="officeLocation"/>
        <mdc-text v-else role="document" aria-label="location-name" class="loc-name-det" tag="span" typo="caption">
          No Location selected
        </mdc-text>
        <base-svg-icon :name="officeLocation && officeLocation.id === profile.favOfficeLocationId ? 'favourite-star-on' : 'favourite-star-off'" class="fav-icon" />
        <div class="change-loc clickable" @click.stop.prevent="openLocationSettingInput()">
          <base-svg-icon name="location-pin-4175b1" />
          <mdc-text tag="span" typo="caption" class="change-loc-text">
            <span v-if="location">Change </span><span v-else>Set </span>location
          </mdc-text>
          <locations-details lookup class="all-locations-list" v-if="openLocationDetails" :date="scheduleSearchWeekDetail.selectedDay" @emitClose="closeLocationDetails" @saveOfficeLocation="setOfficeLocation" :locationType="'office'" :showFor="'office'" />
        </div>
      </section>
      <section class="cap-avble-details">
        <span class="cap-count detail-box">
          <mdc-text tag="span" typo="caption" class="detail-title">Capacity</mdc-text>
          <mdc-text tag="span" typo="caption" class="detail-text">{{ officeLocation && officeLocation.capacity ? officeLocation.capacity : 'NA' }}</mdc-text>
        </span>
        <span class="remain-cap-count detail-box">
          <mdc-text tag="span" typo="caption" class="detail-title">Available desks</mdc-text>
          <mdc-text tag="span" typo="caption" class="detail-text" :class="remainingCapacity === 0 ? 'no-place' : ''">{{ remainingCapacity !== '' && remainingCapacity >= 0 ? remainingCapacity : 'NA' }}</mdc-text>
        </span>
        <span class="acti-text detail-box">
          <mdc-text tag="span" typo="caption" class="detail-title">Activity</mdc-text>
          <mdc-text :style="{ color: capacityColor }" tag="span" typo="caption" class="detail-text">{{ capacitytext }}</mdc-text>
        </span>
      </section>
      <div v-if="officeLocation" class="booked-in-details">
        <mdc-text class="book-in-title" tag="span" typo="body1">Who's booked in?</mdc-text>
      </div>
      <div v-else class="booked-in-details">
        <mdc-text class="no-location-hint" tag="span" typo="body2">Hint: Click Set location above and star the location you use most often to see info by default.</mdc-text>
      </div>
      <!-- <section class="org-details-options">
        <div class="option-type filter-by">
          <mdc-text tag="span" typo="caption" class="filter-by-title title-text">Filter by</mdc-text>
          <div class="teams-filter-list">
            <span class="team-name">
              <base-svg-icon name="" />
              <mdc-text tag="span" typo="caption"></mdc-text>
            </span>
          </div>
        </div>
        <div class="option-type sort-by">
          <mdc-text tag="span" typo="caption" class="sort-by-title title-text">Sort by</mdc-text>
          <div class="sort-list">
            <mdc-text class="sort-by-text" tag="span" typo="caption">Time of booking</mdc-text>
          </div>
        </div>
      </section> -->
      <section class="booked-in-members" :class="'cards'+noOfCards" v-if="officeLocation && allMembers.length > 0">
        <desk-details
          v-for="(member, index) in allMembers"
          :key="index + 'desk-detail'"
          :flipDetails="member.flipDetails"
          :selectedDate="selectedDateMoment"
          :deskStatus="member.type"
          :emptyProfile="member.type === 'empty'"
          :profileId="member.fromId"
          :countText="member.countText"
          :title="member.bookedDate"
          @openTooltip="setTooltipType"
          @closeTooltip="resetTooltipType"
          :tooltipDetails="deskTooltip"
          :availStatus="member.availStatus"
          @bookDesk="selfBookDesk(member)"
          @cancelDesk="cancelBookedDesk()">
          <template v-slot:tooltipDetails>
            <desk-tooltip v-if="deskTooltip.memberId === member.fromId" :type="deskTooltip.type" :memberId="deskTooltip.memberId" @closeTooltip="resetTooltipType" :allMeetingsWithUser="meetingMember === member.fromId ? meetingsList : []" :allInitiativesWithUser="initiativeMember === member.fromId ? initiativesList : []" :detailFlipped="member.flipDetails"></desk-tooltip>
          </template>
        </desk-details>
      </section>

      <mdc-text tag="span" typo="caption" class="default-title" v-if="officeLocation && defaultOccupiedMembers.length > 0">From default schedules</mdc-text>
      <mdc-text tag="span" typo="caption" class="default-info" v-if="officeLocation && defaultOccupiedMembers.length > 0 && !inPast">These will be assigned available spaces {{ confirmationDate }}</mdc-text>

      <section class="default-members" :class="'cards'+noOfCards" v-if="officeLocation && defaultOccupiedMembers.length > 0">
        <desk-details
          v-for="(member, index) in defaultOccupiedMembers"
          :key="index + 'desk-detail'"
          :flipDetails="member.flipDetails"
          :selectedDate="selectedDateMoment"
          :deskStatus="member.type"
          :emptyProfile="member.type === 'empty'"
          :profileId="member.fromId"
          :countText="member.countText"
          @openTooltip="setTooltipType"
          @closeTooltip="resetTooltipType"
          :tooltipDetails="deskTooltip"
          :availStatus="member.availStatus"
          @bookDesk="selfBookDesk(member)"
          @cancelDesk="cancelBookedDesk()">
          <template v-slot:tooltipDetails>
            <desk-tooltip v-if="deskTooltip.memberId === member.fromId" :type="deskTooltip.type" :memberId="deskTooltip.memberId" @closeTooltip="resetTooltipType" :allMeetingsWithUser="meetingMember === member.fromId ? meetingsList : []" :allInitiativesWithUser="initiativeMember === member.fromId ? initiativesList : []" :detailFlipped="member.flipDetails"></desk-tooltip>
          </template>
        </desk-details>
      </section>

      <section class="no-members" v-if="officeLocation && allMembers.length === 0 && defaultOccupiedMembers.length === 0">
        <mdc-text tag="span" typo="caption">No one is currently booked in</mdc-text>
      </section>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import { dayDateFormat } from '../../utils/time'
import moment from 'moment'
import LocationName from './LocationName.vue'
import LocationsDetails from './LocationsDetails.vue'
import DeskDetails from './DeskDetails.vue'
import DeskTooltip from './DeskTooltip.vue'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
const _ = { sortBy, orderBy }
export default {
  name: 'OfficeActivity',
  components: {
    BaseSvgIcon,
    LocationName,
    LocationsDetails,
    DeskDetails,
    DeskTooltip
  },
  props: {
    workplanDeskSelect: {},
    location: {},
    scheduleDay: {},
    selectedDesk: {}
  },
  data () {
    return {
      noOfCards: 6,
      windowWidth: window.innerWidth,
      openLocationDetails: false,
      lookupLocationDetails: null,
      deskTooltip: {
        type: '',
        memberId: ''
      },
      meetingsList: [],
      meetingMember: '',
      initiativesList: [],
      initiativeMember: ''
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
    document.removeEventListener('keyup', this.handleKeyDown)
  },
  created () {},
  computed: {
    ...mapState([
      'appDate',
      'scheduleSearchWeekDetail',
      'profile',
      'locationIndicatorDialogDate',
      'workspace'
    ]),
    ...mapState({
      schedules: state => state.teams.schedules,
      defaultSchedules: state => state.teams.defaultSchedules,
      locations: state => state.teams.locations,
      deskActivity: state => state.teams.deskActivity,
      tempos: state => state.teams.tempos
    }),
    confirmationDate () {
      // console.log('confirmationDate', this.workspace, this.tempos)
      if (this.tempos && this.workspace) {
        const byWorkspace = this.workspace.schedulesSetByWorkspace
        let tempo
        if (byWorkspace) {
          tempo = this.tempos.filter(tmp => tmp.id === 'workspace-schedules')[0]
        } else {
          tempo = this.tempos.filter(tmp => tmp.teamId === this.profile.team && tmp.type === 'schedules')[0]
        }
        if (tempo) {
          const nextConfirm = moment(this.scheduleSearchWeekDetail.selectedDay)
          nextConfirm.isoWeekday(tempo.confirmWhenTimings.day)
          nextConfirm.hours(tempo.confirmWhenTimings.time.split(':')[0])
          nextConfirm.minutes(tempo.confirmWhenTimings.time.split(':')[1])
          if (tempo.frequency === 'weekBefore') {
            nextConfirm.add(-1, 'weeks')
          } else {
            nextConfirm.add(-2, 'weeks')
          }
          return nextConfirm.format('[on] dddd, MMMM Do [at] HH:mm')
        }
      }

      return 'when confirmed'
    },
    capacityLevel () {
      if (this.officeLocation && this.dailyAverage) {
        const capPer = this.capacityPer
        if (capPer < 0) {
          return 'low'
        } else if (capPer === 0) {
          return 'low'
        } else if (capPer <= 25) {
          return 'low'
        } else if (capPer >= 75) {
          return 'high'
        } else {
          return 'mid'
        }
      }
      return 'low'
    },
    capacitytext () {
      if (this.capacityLevel === 'high') {
        return 'Usually busy'
      } else if (this.capacityLevel === 'mid') {
        return 'Usually active'
      } if (this.capacityLevel === 'low') {
        return 'Usually quiet'
      } else {
        return 'No Activity'
      }
    },
    capacityColor () {
      switch (this.capacityLevel) {
        case 'high':
          return '#FC5555'
        case 'mid':
          return '#FF9901'
        case 'low':
        default:
          return '#0F395F'
      }
    },
    remainingCapacity () {
      if (this.officeLocation && this.officeLocation.assignedDesks) {
        const cap = this.officeLocation.desks.filter(desk => desk.enabled).length
        if (this.occupied >= 0) {
          if (cap > this.occupied) {
            return cap - this.occupied
          }
          return 0
        } else {
          return this.officeLocation.capacity
        }
      }
      if (this.officeLocation && this.officeLocation.capacity) {
        if (this.occupied >= 0) {
          if (this.officeLocation.capacity > this.occupied) {
            return this.officeLocation.capacity - this.occupied
          }
          return 0
        } else {
          return this.officeLocation.capacity
        }
      }
      return ''
    },
    dayAbv () {
      if (this.workplanDeskSelect) {
        return moment(this.scheduleSearchWeekDetail.selectedDay).format('ddd').toUpperCase()
      }
      if (this.locationIndicatorDialogDate) {
        return moment(this.scheduleSearchWeekDetail.selectedDay).format('ddd').toUpperCase()
      }
      return ''
    },
    selectedDateMoment () {
      if (this.workplanDeskSelect) {
        return moment(this.scheduleSearchWeekDetail.selectedDay)
      }
      if (this.locationIndicatorDialogDate) {
        return moment(this.scheduleSearchWeekDetail.selectedDay)
      }
      return false
    },
    selectedDate () {
      if (this.workplanDeskSelect) {
        return moment(this.scheduleSearchWeekDetail.selectedDay).format('YYYY-MM-DD')
      }
      if (this.locationIndicatorDialogDate) {
        return moment(this.scheduleSearchWeekDetail.selectedDay).format('YYYY-MM-DD')
      }
      return ''
    },
    mySchedule () {
      if (this.schedules) {
        const mySchedules = this.schedules.filter((schedule) => {
          return schedule.fromId === this.profile.id
        })
        return mySchedules[0]
      }
      return false
    },
    myDefaultSchedule () {
      if (this.defaultSchedules) {
        const mySchedules = this.defaultSchedules.filter((schedule) => {
          return schedule.fromId === this.profile.id
        })
        return mySchedules[0]
      }
      return false
    },
    officeLocation () {
      if (this.lookupLocationDetails) {
        // console.log('look up', this.lookupLocationDetails)
        return this.locations.filter(loc => loc.id === this.lookupLocationDetails.id)[0]
      }
      if (this.location) {
        return this.locations.filter(loc => loc.id === this.location)[0]
      }
      if (this.mySchedule && this.dayAbv) {
        // console.log('mySchedule', this.mySchedule, this.dayAbv)
        const location = this.mySchedule.schedules[this.dayAbv]
        if (this.locations && location && location.locationId) {
          return this.locations.filter(loc => loc.id === location.locationId)[0]
        }
      }
      if (this.myDefaultSchedule && this.dayAbv) {
        // console.log('myDefaultSchedule', this.myDefaultSchedule, this.dayAbv)
        const location = this.myDefaultSchedule.schedules[this.dayAbv]
        const locationId = location ? location.locationId : false
        if (this.locations && locationId) {
          return this.locations.filter(loc => loc.id === locationId)[0]
        }
      }
      if (this.profile.favOfficeLocationId) {
        // console.log('favOfficeLocationId', this.profile.favOfficeLocationId)
        if (this.locations) {
          return this.locations.filter(loc => loc.id === this.profile.favOfficeLocationId)[0]
        }
      }
      return false
    },
    occupied () {
      if (this.schedules && this.officeLocation && this.dayAbv) {
        if (this.officeLocation.assignedDesks) {
          const activeDesks = this.deskActivity.filter(da => {
            const activityDate = moment(da.date.toDate()).format('YYYY-MM-DD')
            // console.log('deskOccupiedMembers', this.officeLocation.name, da.location, this.officeLocation.id, activityDate, this.selectedDate)
            return (da.location === this.officeLocation.id && activityDate === this.selectedDate)
          })
          const occupiedDesks = {}
          for (const activeDesk of activeDesks) {
            occupiedDesks[activeDesk.desk] = activeDesk
          }
          return Object.keys(occupiedDesks).length
        }

        const atLocationOnDateSchedules = this.schedules.filter((schedule) => {
          if (schedule.schedules) {
            if (schedule.schedules[this.dayAbv]) {
              const inOffice = (schedule.schedules[this.dayAbv].type === 'full' && schedule.schedules[this.dayAbv].response === 'office') ||
                (schedule.schedules[this.dayAbv].split &&
                (schedule.schedules[this.dayAbv].amResponse === 'office' ||
                schedule.schedules[this.dayAbv].pmResponse === 'office'))
              if (inOffice && schedule.schedules[this.dayAbv].locationId === this.officeLocation.id) {
                return true
              }
            }
          }
          return false
        })
        return atLocationOnDateSchedules.length
      }
      return 0
    },
    deskOccupiedMembers () {
      // console.log('deskOccupiedMembers', this.deskActivity.length, this.officeLocation, this.selectedDate)
      if (this.officeLocation && this.selectedDate) {
        // Own unsaved desk activity from workplan
        let unsavedDeskActivity = false
        const deskOccupied = []
        if (this.workplanDeskSelect && this.selectedDesk) {
          const weekCommencingDate = moment(this.selectedDate).startOf('isoWeek').format('YYYY-MM-DD')
          unsavedDeskActivity = {
            desk: this.selectedDesk,
            countText: this.selectedDesk,
            flipDetails: false,
            fromId: this.profile.id,
            type: 'booked',
            availStatus: 'booked',
            weekCommencingDate,
            weekCommencingDateStr: moment(weekCommencingDate).toDate(),
            lastUpdated: new Date()
          }
          deskOccupied.push(unsavedDeskActivity)
          // console.log('deskOccupiedMembers.unsavedDeskActivity', unsavedDeskActivity)
        }

        if (this.deskActivity && this.deskActivity.length) {
          const activeDesks = this.deskActivity.filter(da => {
            const activityDate = moment(da.date.toDate()).format('YYYY-MM-DD')
            // console.log('deskOccupiedMembers', this.officeLocation.name, da.location, this.officeLocation.id, activityDate, this.selectedDate)
            return (da.location === this.officeLocation.id && activityDate === this.selectedDate)
          })

          for (const desk of activeDesks) {
            if (!unsavedDeskActivity || desk.userId !== this.profile.id) {
              deskOccupied.push({
                desk: desk.desk,
                countText: desk.desk,
                flipDetails: false,
                fromId: desk.userId,
                type: desk.userId === this.profile.id ? 'booked' : 'submitted',
                availStatus: desk.userId === this.profile.id ? 'booked' : 'submitted',
                weekCommencingDate: desk.weekCommencingDate,
                weekCommencingDateStr: moment(desk.weekCommencingDate).toDate(),
                lastUpdated: desk.lastUpdated,
                bookedDate: moment(desk.lastUpdated ? desk.lastUpdated.toDate ? desk.lastUpdated.toDate() : desk.lastUpdated : new Date()).format('[Booked on] ddd D MMM')
              })
            }
          }
          return deskOccupied
        }
      }
      return []
    },
    defaultOccupiedMembers () {
      // pick fromId whose schedules are confirmed and default schedules
      if (this.defaultSchedules && this.officeLocation && this.dayAbv) {
        const scheduledMembers = {}
        for (const schedule of this.schedules) {
          if (schedule.schedules) {
            if (schedule.schedules[this.dayAbv]) {
              scheduledMembers[schedule.fromId] = true
            }
          }
        }

        const atLocationOnDateSchedules = this.defaultSchedules.filter((ds) => {
          const daySchedule = ds.schedules[this.dayAbv]
          // console.log('defaultOccupiedMembers.daySchedule', daySchedule)
          if (daySchedule) {
            if (daySchedule.split) {
              return !scheduledMembers[ds.fromId] && daySchedule.locationId === this.officeLocation.id && (daySchedule.amResponse === 'office' || daySchedule.pmResponse === 'office')
            } else {
              return !scheduledMembers[ds.fromId] && daySchedule.locationId === this.officeLocation.id && daySchedule.response === 'office'
            }
          }
          return false
        })

        // console.log('defaultOccupiedMembers', scheduledMembers, this.defaultSchedules.length, atLocationOnDateSchedules)

        const defaultMembers = atLocationOnDateSchedules.map(sch => (
          { ...sch, type: 'default', countText: '' }
        ))
        for (let i = 0; i < defaultMembers.length; i++) {
          const flipDetails = (Math.floor(i / this.noOfCards) % 2) === 0
          defaultMembers[i].flipDetails = flipDetails
        }
        return defaultMembers
      }
      return []
    },
    occupiedMembers () {
      // pick fromID whos schedules are not default (not true)
      const addedMembers = {}
      if (this.schedules && this.officeLocation && this.dayAbv) {
        const atLocationOnDateSchedules = this.schedules.filter((schedule) => {
          if (schedule.schedules) {
            if (schedule.schedules[this.dayAbv]) {
              const isOnlyDefault = schedule.default === true && schedule.confirmed !== true && schedule.addedByConfirmation !== true
              const inOffice = (schedule.schedules[this.dayAbv].response === 'office') ||
                (schedule.schedules[this.dayAbv].split &&
                (schedule.schedules[this.dayAbv].amResponse === 'office' ||
                schedule.schedules[this.dayAbv].pmResponse === 'office'))
              const issue = schedule.schedules[this.dayAbv].issue
              // console.log('occupiedMembers.schedule', schedule.fromId, schedule.schedules[this.dayAbv], schedule, schedule.schedules[this.dayAbv].locationId === this.officeLocation.id, !isOnlyDefault, inOffice)
              if (!isOnlyDefault && !issue && inOffice && schedule.schedules[this.dayAbv].locationId === this.officeLocation.id) {
                if (!addedMembers[schedule.fromId]) {
                  addedMembers[schedule.fromId] = true
                  return true
                }
              }
            }
          }
          return false
        })
        const occupiedMembers = []
        for (let index = 0; index < atLocationOnDateSchedules.length; index++) {
          const sch = atLocationOnDateSchedules[index]
          const weekCommencingDate = sch.weekCommencingDate ? sch.weekCommencingDate.toDate ? sch.weekCommencingDate.toDate() : sch.weekCommencingDate : null
          occupiedMembers.push({
            ...sch,
            desk: '',
            countText: ' ',
            flipDetails: false,
            type: sch.fromId === this.profile.id ? 'booked' : 'submitted',
            availStatus: sch.fromId === this.profile.id ? 'booked' : 'submitted',
            weekCommencingDate,
            weekCommencingDateStr: moment(weekCommencingDate).toDate(),
            lastUpdated: sch.lastUpdated,
            bookedDate: moment(sch.lastUpdated ? sch.lastUpdated.toDate ? sch.lastUpdated.toDate() : sch.lastUpdated : new Date()).format('[Booked on] ddd D MMM')
          })
        }
        return occupiedMembers
        // let newArr = [...arr.map(obj => ({...obj, id: Math.random()}))];
      }
      return []
    },
    dailyAverage () {
      if (this.officeLocation && this.officeLocation.dailyAverage && this.dayAbv) {
        return this.officeLocation.dailyAverage[this.dayAbv]
      }
      return -1
    },
    inPast () {
      if (this.appDate.isAfter(this.scheduleSearchWeekDetail.selectedDay, 'day')) {
        return true
      }
      return false
    },
    showBookDesk () { // if location is not booked yet by self
      // return this.allMembers.findIndex(profile => profile.fromId === this.profile.id) === -1
      if (this.inPast) {
        return false
      }
      return true
    },
    allMembers () {
      // console.log('allMembers', this.inPast, this.officeLocation.capacityManage, this.officeLocation.assignedDesks, this.officeLocation.desks, this.officeLocation.capacity)
      let totalMembers = []
      let selfBooked = false
      // ...this.occupiedMembers, ...this.defaultOccupiedMembers
      const occupiedDesks = {}
      for (const member of this.deskOccupiedMembers) {
        occupiedDesks[member.desk] = member
      }
      for (let i = 0; i < this.occupiedMembers.length; i++) {
        const member = this.occupiedMembers[i]
        occupiedDesks[i] = member
        if (member.fromId === this.profile.id) {
          selfBooked = true
        }
      }
      const occupiedDesksLength = Object.keys(occupiedDesks).length
      // console.log('allMembers.occupiedDesksLength', occupiedDesksLength)

      if (this.officeLocation.assignedDesks && this.officeLocation.desks) {
        for (let i = 0; i < this.officeLocation.desks.length; i++) {
          const desk = this.officeLocation.desks[i]
          if (!occupiedDesks[desk.name]) {
            let availStatus = 'available'
            let type = 'empty'
            if (this.inPast) {
              availStatus = 'unavailable'
              type = 'unavailable'
            } else if (!desk.enabled) {
              availStatus = 'unavailable'
              type = 'unavailable'
            }

            totalMembers.push({
              fromId: false,
              type,
              availStatus,
              location: this.officeLocation,
              desk,
              countText: desk.name,
              bookedDate: ''
            })
          } else {
            totalMembers.push(occupiedDesks[desk.name])
          }
        }
      } else if (occupiedDesksLength === 0) {
        if (this.officeLocation.capacity > 0) {
          for (let i = 0; i < this.officeLocation.capacity; i++) {
            let availStatus = 'available'
            let type = 'empty'
            if (this.inPast) {
              availStatus = 'unavailable'
              type = 'unavailable'
            }
            totalMembers.push({ ...totalMembers[i], type, availStatus, location: this.officeLocation, countText: ' ', bookedDate: '' })
          }
        }
      } else if (occupiedDesksLength > 0 && occupiedDesksLength <= this.officeLocation.capacity) {
        for (let i = 0; i < this.officeLocation.capacity; i++) {
          if (occupiedDesks[i]) {
            totalMembers.push(occupiedDesks[i])
          } else {
            let availStatus = 'available'
            let type = 'empty'
            if (this.inPast) {
              availStatus = 'unavailable'
              type = 'unavailable'
            } else if (selfBooked) {
              availStatus = ''
            }
            totalMembers.push({ ...totalMembers[i], type, availStatus, location: this.officeLocation, countText: ' ', bookedDate: '' })
          }
        }
        // console.log('totalMembers', totalMembers)
      } else if (occupiedDesksLength > 0 && occupiedDesksLength > this.officeLocation.capacity) {
        for (const occupiedDeskKey of Object.keys(occupiedDesks)) {
          const occupiedDesk = occupiedDesks[occupiedDeskKey]
          totalMembers.push(occupiedDesk)
        }
        const lastUpdatedList = _.sortBy(totalMembers, 'lastUpdated')
        const toCrowded = totalMembers.length - this.officeLocation.capacity
        const lastIndexes = totalMembers.length - toCrowded
        for (let i = lastIndexes; i < totalMembers.length; i++) {
          if (lastUpdatedList[i]) {
            lastUpdatedList[i].type = 'crowded'
          }
        }
        totalMembers = _.orderBy(lastUpdatedList, 'type', 'desc')
      }

      // FLIP
      for (let i = 0; i < totalMembers.length; i++) {
        const flipDetails = (Math.floor(i / this.noOfCards) % 2) === 0
        totalMembers[i].flipDetails = flipDetails
      }
      return totalMembers
    }
  },
  mounted () {
    window.addEventListener('resize', this.getWindowWidth)
    document.addEventListener('keyup', this.handleKeyDown)
    this.getWindowWidth()
  },
  methods: {
    ...mapActions([
      'setLocationIndicatorDialogDate',
      'updateProfile'
    ]),
    ...mapActions({
      setScheduleLocationDesk: 'teams/setScheduleLocationDesk'
    }),
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        this.closeOfficeActivity()
      }
    },
    selfBookDesk (desk) {
      // update the location desk number(officeLocation) for the date if that day's schedule is available and is office
      this.$emit('setDesk', desk)
      if (!this.workplanDeskSelect) {
        const locationId = desk.location.id
        const buildingId = desk.location.buildingId || ''
        const floorId = desk.location.floorId || ''
        const officeLocation = this.getLocationName(locationId)
        const date = this.scheduleSearchWeekDetail.selectedDay.toDate()
        const deskName = desk.desk ? desk.desk.name : '' || ''
        this.setScheduleLocationDesk({ locationId, buildingId, floorId, officeLocation, date, desk: deskName })
      }
    },
    getLocationName (locationId) {
      const loc = this.locations.filter(location => location.id === locationId)
      if (loc.length) {
        let locName = loc[0].name
        if (loc[0].type === 'area') {
          const floor = this.locations.filter(location => {
            if (location.id === loc[0].floorId) {
              return true
            }
            return false
          })
          if (floor.length) {
            locName = `${floor[0].name}, ${locName}`
          }
          const building = this.locations.filter(location => {
            if (location.id === loc[0].buildingId) {
              return true
            }
            return false
          })
          if (building.length) {
            locName = `${building[0].name}, <i>${locName}</i>`
          }
        } else if (loc[0].type === 'floor') {
          const building = this.locations.filter(location => {
            if (location.id === loc[0].buildingId) {
              return true
            }
            return false
          })
          if (building.length) {
            locName = `${building[0].name}, <i>${locName}</i>`
          }
        }
        return locName
      }
      return ''
    },
    cancelBookedDesk () {
      // console.log('cancel desk')
    },
    getWindowWidth () {
      this.windowWidth = window.innerWidth
      this.getNoOfCards()
    },
    getNoOfCards () {
      this.noOfCards = this.windowWidth >= 1442 ? 6 : this.windowWidth >= 1248 ? 5 : this.windowWidth >= 1054 ? 4 : 3
    },
    setTooltipType (type, profileId) {
      this.deskTooltip.type = type
      this.deskTooltip.memberId = profileId
      this.$emit('tooltipDesk')
    },
    resetTooltipType () {
      this.deskTooltip = {
        type: '',
        memberId: ''
      }
    },
    closeOfficeActivity () {
      if (this.workplanDeskSelect) {
        this.$emit('close')
      } else {
        this.setLocationIndicatorDialogDate(false)
      }
    },
    getDate () {
      return dayDateFormat(this.scheduleSearchWeekDetail.selectedDay, 'ddd D MMM')
    },
    closeLocationDetails () {
      this.openLocationDetails = false
    },
    openLocationSettingInput () {
      this.openLocationDetails = true
    },
    setOfficeLocation (val, shownFor, text, type, location) {
      this.lookupLocationDetails = location
      this.updateProfileSearchLocations(location)
      this.closeLocationDetails()
    },
    updateProfileSearchLocations (location) {
      // console.log('updateProfileSearchLocations', location)
      const locationVal = {
        id: location.id,
        text: location.text,
        buildingId: location.buildingId || null,
        floorId: location.floorId || null,
        type: location.type
      }
      let searchLocations = []
      let update = false
      if (!this.profile.searchLocations) {
        if (this.profile.officeLocations) {
          searchLocations = this.profile.officeLocations
          update = true
        }
      } else {
        searchLocations = this.profile.searchLocations
      }
      const locationIndex = searchLocations.findIndex(loc => loc.id === location.id) || -1
      if (locationIndex === -1) {
        if (searchLocations && searchLocations.length) {
          const favLast = _.sortBy(searchLocations, ({ id }) => id === this.profile.favOfficeLocationId ? 1 : 0)
          searchLocations = [...favLast.slice(-3), locationVal]
        } else {
          searchLocations = [locationVal]
        }
        update = true
      }
      if (update) {
        this.profile.searchLocations = searchLocations
        this.updateProfile({ profile: { searchLocations }, noSnack: true })
      }
    }
  },
  watch: {
    scheduleDay () {
      if (this.scheduleDay) {
        this.lookupLocationDetails = false
      }
    },
    location () {
      if (this.location) {
        this.lookupLocationDetails = false
      }
    }
  }
}
</script>

<style lang="scss">
.office-activity-wrapper {
  .close-icon {
    width: 15px;
    height: 15px;
    margin: 10px 30px 15px auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .activity-details {
    display: flex;
    flex-direction: column;
    .activity-date {
      margin-left: 20px;
      margin-bottom: 10px;
      background: #F4F4F4;
      border-radius: 4px;
      font-weight: 600;
      font-size: 10px;
      color: #404040;
      padding: 3px;
      min-width: 55px;
      max-width: max-content;
      text-align: center;
    }
    .off-loc-name {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 0px;
      .loc-name-det {
        margin-left: 20px;
        margin-bottom:10px;
        color: #404040;
        display: flex;
        .building-name {
          font-weight: 700;
          font-size: 24px;
          white-space: nowrap;
          &:not(:only-child) {
            &::after {
              content: "";
              border-radius: 50%;
              width: 4px;
              height: 4px;
              display: inline-block;
              margin: 0 10px;
              background: #404040;
              position: relative;
              bottom: 5px;
            }
          }
        }
        i {
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          white-space: nowrap;
        }
      }
      .fav-icon {
        margin: 0 10px;
      }
    }
    .change-loc {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 50px;
      position: relative;
      .icon-wrapper {}
      .change-loc-text {
        color: #4175b1;
        font-weight: 400;
        margin-left: 3px;
        white-space: nowrap;
      }
      .all-locations-list {
        border: 0.5px solid #828282;
        border-radius: 4px;
        width: max-content;
        position: absolute;
        top: 25px;
        z-index: 100;
        background: #fff;
        right: 0;
      }
    }
    .cap-avble-details {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      margin-right: 20px;
      .detail-box {
        flex: 1;
        border: 1px dashed #CECECE;
        border-radius: 4px;
        height: 38px;
        max-width: calc(50% - 20px);
        margin-right: 10px;
        margin-top:10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .detail-title {
          color: #404040;
          font-weight: 400;
          font-size: 16px;
          margin-left: 5px;
          margin-right: 10px;
          white-space: nowrap;
        }
        .detail-text {
          color: #404040;
          font-weight: 700;
          font-size: 16px;
          margin-right: 5px;
          white-space: nowrap;
          &.no-place {
            color: #FF0f00;
          }
        }
      }
    }
    .booked-in-details {
      padding-bottom: 4px;
      border-bottom: 0.2px solid #bdbdbd;
      margin: 15px 20px 0 20px;
      .book-in-title {
        font-weight: 800;
        color: #404040;
      }
      .no-location-hint {
        display:inline-block;
        color: #FF0f00;
        padding-top:30px;
        padding-left:10px;
        padding-bottom:30px;
      }
    }
    .org-details-options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 20px 0 20px;
      .option-type {
        display: flex;
        align-items: center;
        .title-text {
          font-weight: 400;
          color: #959EA7;
          margin-right: 10px;
        }
        &.filter-by {}
        &.sort-by {}
      }
    }
    .booked-in-members {
      display: grid;
      align-items: center;
      grid-gap: 5px 3px;
      margin: -10px 20px 10px 20px;
      z-index: 11;

      &.cards6 {
        grid-column-start: 1;
        grid-column-end: 6;
        grid-template-columns: auto auto auto auto auto 1fr;
      }
      &.cards5 {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-template-columns: auto auto auto auto 1fr;;
      }
      &.cards4 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-template-columns: auto auto auto 1fr;;
      }
      &.cards3 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-template-columns: auto auto 1fr;;
      }
    }
    .default-title {
      margin-top: 40px;
      margin-left: 20px;
      color: #404040;
      font-weight:800;
      font-size:14px;
    }
    .default-info {
      margin-top: 2px;
      margin-left: 20px;
      color: #404040;
    }
    .default-members {
      display: grid;
      align-items: center;
      grid-gap: 5px 3px;
      margin: -20px 20px 10px 20px;
      z-index: 10;

      &.cards6 {
        grid-column-start: 1;
        grid-column-end: 6;
        grid-template-columns: auto auto auto auto auto 1fr;
      }
      &.cards5 {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-template-columns: auto auto auto auto 1fr;;
      }
      &.cards4 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-template-columns: auto auto auto 1fr;;
      }
      &.cards3 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-template-columns: auto auto 1fr;;
      }
    }
    .no-members {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      grid-gap: 5px 3px;
      margin: 20px 20px 10px 20px;
      color: #404040;
    }
  }
}
</style>
