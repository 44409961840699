<template>
  <div class="desktooltip-wrapper" :class="[type ? type + 'tooltip' : '', detailFlipped ? 'flipped' : '']">
    <div class="initiative-details tooltip-details" v-if="type === 'initiative'">
      <mdc-text tag="span" typo="caption" class="initiative-heading heading">Your initiatives</mdc-text>
      <div class="inits-list">
        <div class="init-details" v-for="initiative in allInitiativesWithUser" :key="initiative.id + 'tooltip'">
          <base-svg-icon name="rocket-4175b1" />
          <initiative-name applyTextOverflow class="desk-init-name" :fontType="'caption'" :initiativeId="initiative.id" showTeam @initiativeClick="redirectToInitiative(initiative.id)" clickable/>
        </div>
      </div>
    </div>
    <div class="meeting-details tooltip-details" v-if="type === 'meeting'">
      <mdc-text tag="span" typo="caption" class="meeting-heading heading">Your meetings</mdc-text>
      <div class="meeting-time-list">
        <div class="timing-details clickable" v-for="meeting in allMeetingsWithUser" :key="meeting.id + 'tooltip'" @click="showMeetingDialog(meeting)">
          <base-svg-icon name="calendar-4175b1" />
          <mdc-text tag="span" typo="caption" class="timing">{{ timeZoneTime(meeting.startDateTime) }}</mdc-text>
          <!-- <template v-if="deskNumber">
            <mdc-text tag="span" typo="caption" class="desk-number">Room 4D</mdc-text>
          </template> -->
        </div>
      </div>
    </div>
    <div class="member-details tooltip-details" v-if="type === 'member'">
      <profile-schedule-tooltip :profileId="memberId" :memberDaySchedule="memberDaySchedule" :tooltipDate="scheduleSearchWeekDetail.selectedDay"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getMeetingForDate } from '../../utils/meetings'
import InitiativeName from './InitiativeName.vue'
import moment from 'moment'
import BaseSvgIcon from './BaseSvgIcon.vue'
import uniqBy from 'lodash/uniqBy'
import ProfileScheduleTooltip from './ProfileScheduleTooltip.vue'
const _ = { uniqBy }
export default {
  name: 'DeskTooltip',
  components: {
    BaseSvgIcon,
    InitiativeName,
    ProfileScheduleTooltip
  },
  props: {
    type: {
      type: String,
      default: '' // initiative, meeting and member
    },
    memberId: {
      type: String,
      default: ''
    },
    detailFlipped: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allMeetingsWithUser: [],
      allInitiativesWithUser: [],
      memberDaySchedule: null
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  created () {
    this.getMeetingsWithUser()
    this.getInitiativesWithUser()
    this.getMemberSchedule()
  },
  computed: {
    ...mapState([
      'scheduleSearchWeekDetail',
      'profiles',
      'profile',
      'displayMeeting'
    ]),
    ...mapState({
      meetings: state => state.teams.meetings,
      meetingsRec: state => state.teams.meetingsRec,
      initiatives: state => state.teams.initiatives
    })
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  methods: {
    ...mapActions([
      'setDisplayMeeting'
    ]),
    ...mapActions({
      getProfileById: 'teams/getProfileById',
      getMembersWeekScheduleByDate: 'teams/getMembersWeekScheduleByDate'
    }),
    showMeetingDialog (meetingDetails) {
      if (this.displayMeeting) {
        this.setDisplayMeeting(false)
      }
      const self = this
      setTimeout(() => {
        self.setDisplayMeeting({ ...meetingDetails, showFor: 'officeActivity' })
      }, 10)
      this.$emit('closeTooltip')
    },
    redirectToInitiative (id) {
      this.$router.push({ name: 'InitiativeDetails', params: { id } })
    },
    timeZoneTime (startDateTime) {
      let sdt = startDateTime
      if (sdt?.toDate) {
        sdt = sdt.toDate()
      }
      return moment.tz(sdt, this.profile.timezone).format('HH:mm')
    },
    memberDetails () {
      const profile = this.profiles.filter(prof => prof.id === this.memberId)[0]
      if (profile) {
        return {
          profileId: this.memberId,
          name: profile.displayName
        }
      }
    },
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target))) {
        this.$emit('closeTooltip')
      }
    },
    async getMeetingsWithUser () {
      const allProfiles = [...this.profiles, this.profile]
      const profile = allProfiles.filter(prof => prof.id === this.memberId)[0]
      if (profile) {
        const datesMeetings = getMeetingForDate(this.scheduleSearchWeekDetail.selectedDay.toDate(), this.meetings, this.meetingsRec)
        const userMeetings = datesMeetings.filter(meet => {
          if (meet.status !== 'cancelled') {
            let inMeeting = false
            if (profile.email) {
              inMeeting = meet.attendeeEmails.includes(profile.email)
            }
            if (profile.msEmail) {
              inMeeting = inMeeting || meet.attendeeEmails.includes(profile.msEmail)
            }
            if (profile.googleEmail) {
              inMeeting = inMeeting || meet.attendeeEmails.includes(profile.googleEmail)
            }
            return inMeeting
          }
          return false
        })
        this.allMeetingsWithUser = userMeetings
      }
      return []
    },
    getInitiativesWithUser () {
      const allProfiles = [...this.profiles, this.profile]
      const memberDetail = allProfiles.filter(prof => prof.id === this.memberId)[0]
      let teamInits = []
      let initsWithSelfAndMember = []
      if (memberDetail?.team === this.profile.team) {
        teamInits = this.initiatives.filter((initiative) => initiative.teamId === this.profile.team)
      }
      initsWithSelfAndMember = this.initiatives.filter(init => init.members.includes(this.profile.id) && init.members.includes(this.memberId))
      const allInits = [...teamInits, ...initsWithSelfAndMember]
      const uniqueInits = _.uniqBy(allInits, 'id')
      this.allInitiativesWithUser = uniqueInits.slice(0, 10)
    },
    async getMemberSchedule () {
      const weeksSchedule = await this.getMembersWeekScheduleByDate({ fromId: this.memberId, scheduleDate: this.scheduleSearchWeekDetail.selectedDay })
      if (weeksSchedule[0]) {
        const daysSchedules = weeksSchedule[0].schedules
        const dayAbv = moment(this.scheduleSearchWeekDetail.selectedDay).format('ddd').toUpperCase()
        const dayResponse = daysSchedules[dayAbv]
        this.memberDaySchedule = dayResponse
        return
      }
      this.memberDaySchedule = false
    }
  },
  watch: {
    meetings: {
      handler () {
        this.getMeetingsWithUser()
      }
    },
    meetingsRec: {
      handler () {
        this.getMeetingsWithUser()
      }
    },
    initiatives: {
      handler () {
        this.getInitiativesWithUser()
      }
    },
    schedules: {
      handler () {
        this.getMemberSchedule()
      }
    }
  }
}
</script>

<style lang="scss">
.desktooltip-wrapper {
  position: absolute;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 29%);
  z-index: 25;
  bottom: 0;
  &::before {
    width: 0;
    height: 0;
    content: "";
    display: flex;
    position: absolute;
  }

  &.meetingtooltip {
    margin-bottom: 25px;
    margin-left: 5px;
    transform: translate(75%, 100%);
    bottom: -2px;
    &::before {
      border-top: 14px solid #FFFFFF;
      border-left: 18px solid transparent;
      top: 20%;
      right: 99%;
    }
  }
  &.initiativetooltip {
    margin-bottom: -8px;
    margin-left: -20px;
    transform: translate(-100%, 80%);
    &:before {
      border-top: 14px solid #FFFFFF;
      border-right: 18px solid transparent;
      top: 15%;
      left: 99%;
    }
  }
  &.membertooltip {
    transform: translate(-80px, 100%);
    margin-bottom: -50px;
    &.flipped {
      transform: translate(-45px, -55px);
      margin-bottom: 0;
      &:before {
        border-top: 14px solid #fff;
        border-left: 18px solid transparent;
        border-bottom: none;
        top: 99%;
        left: 30%;
      }
    }
    &:before {
      border-bottom: 14px solid #fff;
      border-left: 18px solid transparent;
      bottom: 99%;
      left: 50%;
    }
  }

  .tooltip-details {
    display: flex;
    flex-direction: column;
    min-width: 120px;
    .heading {
      font-size: 10px;
      color: #404040;
      font-weight: 700;
      padding: 6px 12px 5px 10px;
      border-bottom: 0.2px solid #BDBDBD;
      white-space: nowrap;
    }
    &.meeting-details {
      .meeting-time-list {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        .timing-details {
          display: flex;
          align-items: center;
          margin: 0px 5px 2px 10px;
          justify-content: center;
          width: max-content;
          .icon-wrapper {
            height: 8px;
            width: 8px;
            margin-right: 3px;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .mdc-text {
            color: #4175B1;
            font-weight: 600;
            font-size: 10px;
            white-space: nowrap;
            &.desk-number {
              font-weight: 400;
              &::before {
                content: "";
                border-radius: 50%;
                width: 2px;
                height: 2px;
                display: inline-block;
                margin: 0 2px;
                background: #4175B1;
                position: relative;
                bottom: 3px;
              }
            }
          }
        }
      }
    }
    &.initiative-details {
      max-width: 170px;
      .inits-list {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        .init-details {
          display: flex;
          align-items: center;
          margin: 0px 5px 2px 10px;
          max-width: 160px;
          .icon-wrapper {
            height: 8px;
            width: 8px;
            margin-right: 5px;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .desk-init-name {
            color: #4175B1;
            white-space: nowrap;
            display: flex;
            .initiative-name {
              max-width: 150px;
              .detail {
                font-size: 10px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    &.member-details {
    }
  }
}
</style>
