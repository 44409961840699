<template>
  <app-dialog
    class="tasks-modal fullsize-mobile"
    style="z-index:999; height:100vh;"
    accept=""
    cancel=""
    v-model="open"
    title="Profile Progress"
    subtitle="Complete these simple tasks to create a great profile">

      <mdc-body typo="headline5" style="margin-top:auto; margin-right: 14px;" slot="post-title" class="modal-progression">
        {{completed}} / 4
      </mdc-body>

      <div class="progress-container">
        <div v-if="open" class="progress2 progress-moved">
          <div :style="getStyle()" class="progress-bar2" >
          </div>
        </div>
      </div>

      <div style="display:flex;flex-flow:column">

        <div class="task" :class="{done: signup}"  :style="{order: signup ? 1 : 4}">
          <img src="/static/img/modal-icons/outline-input-24px.svg">
          <div class="info">
            <mdc-title v-if="signup">Sign-Up Complete</mdc-title>
            <mdc-title v-else>Sign-Up to Access</mdc-title>
            <mdc-body typo="body2" v-if="signup">You’re on the way, you can now use the site!</mdc-body>
            <mdc-body typo="body2" v-else>Create an account to get started!</mdc-body>
          </div>
          <div>
            <i v-if="signup" class="pink tick done material-icons">done</i>
            <i v-else class="pink tick">?</i>
          </div>
        </div>

        <div class="task" :class="{done: basic}"  :style="{order: basic ? 2 : 5}" @click="routeToProfile">
          <img src="/static/img/modal-icons/outline-person-24px.svg">
          <div class="info">
            <mdc-title v-if="basic">Basic Information Updated</mdc-title>
            <mdc-title v-else>Update Your Basic Information*</mdc-title>
            <mdc-body typo="body2" v-if="basic">View and manage your profile by clicking your card</mdc-body>
            <mdc-body typo="body2" v-else>Provide your preferences to get the best support</mdc-body>
          </div>
          <div>
            <i v-if="basic" class="orange tick done material-icons">done</i>
            <i v-else class="orange tick">?</i>
          </div>
        </div>

        <div class="task" :class="{done: experience}"  :style="{order: experience ? 3: 6}" @click="routeToProfile">
          <img src="/static/img/modal-icons/outline-assignment-24px.svg">
          <div class="info">
            <mdc-title v-if="experience">Experience Data Updated</mdc-title>
            <mdc-title v-else>Enhance your Profile with Experience Data*</mdc-title>
            <mdc-body typo="body2" v-if="experience">You can now connect to others and send messages</mdc-body>
            <mdc-body typo="body2" v-else>Make what's presented to you relevant and useful</mdc-body>
          </div>
          <div>
            <i v-if="experience" class="green tick done material-icons">done</i>
            <i v-else class="green tick">?</i>
          </div>
        </div>

        <div class="task" :class="{done: personalise}"  :style="{order: personalise ? 4: 7}" @click="routeToProfilePersonalise">
          <img src="/static/img/modal-icons/outline-face-24px.svg">
          <div class="info">
            <mdc-title v-if="personalise">Your Profile Card Looks Great</mdc-title>
            <mdc-title v-else>Finishing Touches</mdc-title>
            <mdc-body typo="body2" v-if="personalise">Share your business card so people view your profile</mdc-body>
            <mdc-body typo="body2" v-else>Add a brief introduction to about me and a photo</mdc-body>
          </div>
          <div>
            <i v-if="personalise" class="blue tick done material-icons">done</i>
            <i v-else class="blue tick">?</i>
          </div>
        </div>

      </div>

   </app-dialog>
</template>

<script>
import AppDialog from '../components/AppDialog'
export default {
  name: 'ProfileTasks',
  props: {
    tasksModalOpen: {
      type: Boolean
    },
    profile: {}
  },
  components: {
    AppDialog
  },
  data () {
    return {
      open: false,
      completed: 1,
      signup: true,
      basic: false,
      experience: false,
      personalise: false
    }
  },
  methods: {
    getStyle () {
      if (this.completed === 0) {
        return { width: '0%', 'background-color': '#C10457' }
      } else if (this.completed === 1) {
        return { width: '25%', 'background-color': '#C10457', animation: 'progressAnimation25 1s' }
      } else if (this.completed === 2) {
        return { width: '50%', 'background-color': '#f4854d', animation: 'progressAnimation50 2s' }
      } else if (this.completed === 3) {
        return { width: '75%', 'background-color': '#2cbcb6', animation: 'progressAnimation75 3s' }
      } else {
        return { width: '100%', 'background-color': '#3560af', animation: 'progressAnimation100 4s' }
      }
    },
    routeToProfile () {
      const self = this
      this.$router.push('/my-profile')
        .catch(err => {
          console.warn(err)
          self.$root.$refs.$children[0].view.initialize()
        })
      this.open = false
      this.$emit('checkProfile')
    },
    routeToProfilePersonalise () {
      const self = this
      if (!this.profile.aboutMe || this.profile.aboutMe === '') {
        this.$router.push({ name: 'MyProfile', params: { focus: 'aboutme' } })
          .catch(err => {
            console.warn(err)
            self.$root.$children[0].$refs.view.initialize('aboutme')
          })
      } else if (!this.profile.photoURL) {
        this.$router.push({ name: 'MyProfile', params: { focus: 'photo' } })
          .catch(err => {
            console.warn(err)
            self.$root.$children[0].$refs.view.initialize('photo')
          })
      }
      this.open = false
      this.$emit('checkProfile')
    }
  },
  mounted () {
    if (this.profile) {
      this.completed = 1
      if (this.profile.basicCompleted) {
        this.basic = true
        this.completed++
      }
      if (this.profile.experienceCompleted) {
        this.experience = true
        this.completed++
      }
      if (this.profile.photoURL && this.profile.aboutMe !== '') {
        this.personalise = true
        this.completed++
      }
    }
  },
  watch: {
    tasksModalOpen: {
      handler (newo, oldo) {
        if (this.tasksModalOpen) {
          this.open = false
          this.$nextTick(() => {
            this.open = true
          })
        } else {
          this.open = false
        }
      },
      immediate: true
    },
    profile (newp, oldp) {
      if (this.profile) {
        this.completed = 1
        this.basic = false
        this.experience = false
        this.personalise = false

        if (this.profile.basicCompleted) {
          this.basic = true
          this.completed++
        }
        if (this.profile.experienceCompleted) {
          this.experience = true
          this.completed++
        }
        if (this.profile.photoURL && this.profile.aboutMe !== '') {
          this.personalise = true
          this.completed++
        }
      }
    },
    open (newo, oldo) {
      if (!newo) {
        this.$emit('modalClosed')
      }
    }
  }
}
</script>

<style>
.tasks-modal.mdc-dialog .mdc-dialog__container {
  flex:1;
}
.tasks-modal .modal-title h2 {
  line-height: 1.2em;
}
.tasks-modal .task {
  align-items:center;
  display: flex;
  margin-top:auto;
  flex-flow:row;
  margin-top:12px;
  min-height:60px;
  padding:12px;
  cursor:pointer;
  border-radius: 2px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.tasks-modal .task.done {
  background-color:#e4e4e4;
}
.tasks-modal .tasks-modal p.mdc-typography--body2{
  margin-bottom:0;
}
.tasks-modal .task .info {
  flex: 1;
  padding-left:24px;
  padding-right:24px;
}
.tasks-modal .task .info .mdc-title {
  font-size:18px;
}
.tasks-modal .tick {
  border-radius:50%;
  padding:10px;
  background-color:transparent;
  border-color:transparent;
  border-width: 2px;
  border-style: solid;
  width:10px;
  height:10px;
  text-align: center;
  font-style: normal;
  display: block;
  font-weight:500;
  font-size:22px;
  line-height: 11px;
}
.tasks-modal .tick.material-icons {
  text-indent: -6px;
}

.tasks-modal .tick.blue {
  border-color: #3560af;
}
.tasks-modal .tick.pink {
  border-color: #C10457;
}
.tasks-modal .tick.orange {
  border-color: #f4854d;
}
.tasks-modal .tick.green {
  border-color: #2cbcb6;
}
.tasks-modal .tick.done.blue {
  background-color: #3560af;
  color:white;
}
.tasks-modal .tick.done.pink {
  background-color: #C10457;
  color:white;
}
.tasks-modal .tick.done.orange {
  background-color: #f4854d;
  color:white;
}
.tasks-modal .tick.done.green {
  background-color: #2cbcb6;
  color:white;
}
.tasks-modal .task p,
.tasks-modal .task h1,
.tasks-modal .task h2,
.tasks-modal .task h3 {
  color: #24292e;
  margin-bottom:6px;
  margin-top:6px;
}

.tasks-modal .modal-progression {
  flex:0;
}

@media all and (max-width: 670px), all and (max-height: 670px){
  .tasks-modal .task {
    min-height:65px;
    padding:8px;
  }
  .tasks-modal p.mdc-typography--body2 {
    font-size: 0.9em;
    line-height: 1.2em;
  }
  .tasks-modal .progress-container {
    padding-top: 12px;
    padding-bottom: 6px;
  }
  .tasks-modal .task img {
    height:55px;
    width:55px;
  }
  .tasks-modal .task .info {
    padding-left:8px;
    padding-right:6px;
  }
  .tasks-modal .task .info .mdc-title {
    font-size:0.9em;
    line-height:1.4em;
  }
}
@media all and (max-width: 570px), all and (max-height: 570px){
  .tasks-modal .task {
    min-height:50px;
    padding: 6px;
  }
  .tasks-modal p.mdc-typography--body2 {
    font-size: 0.8em;
    line-height: 1.2em;
  }
  .tasks-modal .progress-container {
    padding-top: 12px;
    padding-bottom: 6px;
  }
  .tasks-modal .task img {
    height:45px;
    width:45px;
  }
  .tasks-modal .task .info {
    padding-left:8px;
    padding-right:6px;
  }
  .tasks-modal .task .info .mdc-title {
    font-size:0.9em;
    line-height:1.4em;
  }
  .tasks-modal .tick {
    width:6px;
    height:6px;
    font-size:20px;
    line-height: 6px;
    text-indent: -2px;
  }
  .tasks-modal .tick.material-icons {
    text-indent: -7px;
  }
}
</style>

<style lang="scss">

.tasks-modal .mdc-dialog__body {
  position:relative;
  padding:16px;
  padding-top:0px;
  overflow:auto;
  max-height:calc(100vh - 80px);
}
@media all and (max-width: 570px), all and (max-height: 700px){
  .tasks-modal .mdc-dialog__body {
    max-height:calc(100vh - 65px);
  }
}
.tasks-modal .progress-container {
  padding-top:4px;
  padding-bottom:12px;
}
.tasks-modal .progress2 {
  padding: 3px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}
.tasks-modal .progress-bar2 {
  height: 10px;
  border-radius: 30px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.2s linear;
  transition-property: width, background-color;
}
@keyframes progressAnimation25{
  0% {
    width: 0%;
    background-color: #C10457;
  }
  100% {
    width: 25%;
    background-color: #C10457;
  }
}
@keyframes progressAnimation50{
  0% {
    width: 0%;
    background-color: #C10457;
  }
  50% {
    width: 25%;
    background-color: #C10457;
  }
  100% {
    width: 50%;
    background-color: #f4854d;
  }
}
@keyframes progressAnimation75{
  0% {
    width: 0%;
    background-color: #C10457;
  }
  33% {
    width: 25%;
    background-color: #C10457;
  }
  66% {
    width: 50%;
    background-color: #f4854d;
  }
  100% {
    width: 75%;
    background-color: #2cbcb6;
  }
}
@keyframes progressAnimation100 {
  0% {
    width: 0%;
    background-color: #C10457;
  }
  25% {
    width: 25%;
    background-color: #C10457;
  }
  50% {
    width: 50%;
    background-color: #f4854d;
  }
  75% {
    width: 75%;
    background-color: #2cbcb6;
  }
  100% {
    width: 100%;
    background-color: #3560af;
  }
}
</style>
