<template>
  <section class="teams-details-wrapper">
    <div class="team-name-input-wrapper">
      <input
        type="text"
        class="team-name-field"
        v-model="teamNameInput"
        placeholder="Start typing a team name"
        autofocus
      />
    </div>
    <div class="team-names-list">
      <template v-if="teamNamesSearchResults.length === 0 && recentlySearchedTeams.length === 0">
        <div class="names-menu-heading">
          <mdc-text tag="span" typo="body2">Teams</mdc-text>
        </div>
        <div class="team-names team-names-menu team-names-results" role="list" aria-label="team-names-results">
          <template v-for="(team, index) in teams.slice(0, 5)">
            <div v-if="team.name" class="team-names-options" :key="team.id + index">
              <mdc-text typo="body2" v-html="team.name" tag="span" @click.native.stop="setTeam(team)" class="menu-item"></mdc-text>
            </div>
          </template>
        </div>
      </template>
      <template v-if="teamNamesSearchResults.length > 0">
        <div class="names-menu-heading">
          <mdc-text tag="span" typo="body2">Search results</mdc-text>
        </div>
        <div class="team-names team-names-menu search-results" role="list" aria-label="search-results">
          <template v-for="(team, index) in teamNamesSearchResults.slice(0, 5)">
            <div v-if="team.name" class="team-names-options" :key="team.id + index">
              <mdc-text typo="body2" v-html="team.name" tag="span" @click.native.stop="setTeam(team)" class="menu-item"></mdc-text>
            </div>
          </template>
        </div>
      </template>
      <template v-if="recentlySearchedTeams.length > 0">
        <div class="names-menu-heading">
          <mdc-text tag="span" typo="body2">Recent Searches</mdc-text>
        </div>
        <div class="team-names team-names-menu prev-searched" role="list" aria-label="prev-searched">
          <template v-for="(team, index) in recentlySearchedTeams">
            <div v-if="team.name" class="team-names-options" :key="team.id + index">
              <mdc-text typo="body2" v-html="team.name" tag="span" @click.native.stop="setTeam(team)" class="menu-item"></mdc-text>
            </div>
          </template>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import sortBy from 'lodash/sortBy'
const _ = { sortBy }
export default {
  name: 'TeamsDetails',
  components: {},
  props: {
    teams: {}
  },
  data () {
    return {
      teamNameInput: '',
      teamNamesSearchResults: []
    }
  },
  beforeDestroy () {},
  created () {},
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
    }),
    recentlySearchedTeams () {
      if (this.profile?.searchedTeams?.length > 0) {
        return this.profile?.searchedTeams
      }
      return []
    }
  },
  mounted () {},
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    ...mapActions({
    }),
    setTeam (team) {
      this.$emit('setSelected', team)
      if (team) {
        const searchedTeamIndex = this.profile.searchedTeams ? this.profile.searchedTeams.findIndex(sTeam => sTeam.id === team.id) : -1
        if (searchedTeamIndex === -1) {
          const searchedTeamDetails = { date: new Date(), ...team }
          let searchedTeamToSave
          if (this.profile.searchedTeams && this.profile.searchedTeams.length) {
            const sortedTeams = _.sortBy(this.profile.searchedTeams, 'date')
            searchedTeamToSave = [...sortedTeams.slice(-3), searchedTeamDetails]
          } else {
            searchedTeamToSave = [searchedTeamDetails]
          }
          this.updateProfile({ profile: { searchedTeams: searchedTeamToSave }, noSnack: true })
        }
      }
    }
  },
  watch: {
    teamNameInput: {
      handler () {
        if (this.teamNameInput) {
          this.teamNamesSearchResults = this.teams.filter((item) => {
            const a = item.name.toLowerCase() || null
            const b = this.teamNameInput.toLowerCase() || ''
            if (a && a !== '') {
              if (b) {
                return a.includes(b)
              }
            }
            return true
          })
        } else {
          this.teamNamesSearchResults = []
        }
      }
    }
  }
}
</script>

<style lang="scss">
.teams-details-wrapper {
  .team-name-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-top: 5px;
    input {
      padding: 2px 20px 2px 5px;
      border: none;
      font-size: 14px;
      color: #404040;
      font-weight: 600;
      &::placeholder {
        opacity: 1 !important;
        color: #BDBDBD;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .team-names-list {
    .names-menu-heading {
      display: flex;
      margin: 0 10px;
      padding: 5px 0;
      border-top: 0.5px solid #e0e0e0;
      .mdc-text {
        font-weight: 700;
      }
    }
    .team-names-menu {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      .team-names-options {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        cursor: pointer;
        .menu-item {
          color: #4f4f4f;
          text-transform: capitalize;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          flex: 1;
          margin-right: 10px;
        }
        &:hover {
          background: #EBF3FC;
        }
      }
    }
  }
}
</style>
