import moment from 'moment'
import range from 'lodash/range'

const _ = { range }

require('moment/locale/en-gb')
moment.locale('en-gb')

const DAY_ABV_ISO = {
  0: 'MON',
  1: 'TUE',
  2: 'WED',
  3: 'THU',
  4: 'FRI',
  5: 'SAT',
  6: 'SUN'
}

function roundedToFixed (_float, _digits) {
  const rounder = Math.pow(10, _digits)
  return Math.round(_float * rounder) / rounder
}

export function getMonthArray () {
  return moment.monthsShort()
}

export function getYearArray () {
  const startYear = new Date().getFullYear()
  return _.range(startYear, 1900, -1)
}

export function diffNowDays (ts) {
  return moment().diff(ts, 'days')
}

export function differenceYears (startYear, endYear, startMonth, endMonth) {
  const months = getMonthArray()
  startYear = parseInt(startYear)
  if (!startMonth) {
    startMonth = 1
  } else {
    if (isNaN(startMonth)) {
      startMonth = months.indexOf(startMonth)
    }
  }
  if (!endYear) {
    endYear = moment().year()
    endMonth = moment().month()
  } else {
    endYear = parseInt(endYear)
  }

  if (!endMonth) {
    endMonth = 1
  } else {
    if (isNaN(endMonth)) {
      endMonth = months.indexOf(endMonth)
    }
  }
  const a = moment([startYear, startMonth, 1])
  const b = moment([endYear, endMonth, 1])
  const years = b.diff(a, 'year')
  return years
}

export function differenceYearsMonths (startYear, endYear, startMonth, endMonth) {
  const months = getMonthArray()
  startYear = parseInt(startYear)
  if (!startMonth) {
    startMonth = 1
  } else {
    if (isNaN(startMonth)) {
      startMonth = months.indexOf(startMonth)
    }
  }
  if (!endYear) {
    endYear = moment().year()
    endMonth = moment().month()
  } else {
    endYear = parseInt(endYear)
  }

  if (!endMonth) {
    endMonth = 1
  } else {
    if (isNaN(endMonth)) {
      endMonth = months.indexOf(endMonth)
    }
  }

  const a = moment([startYear, startMonth, 1])
  const b = moment([endYear, endMonth, 1])
  const years = b.diff(a, 'year')
  const monthDiff = b.diff(a, 'month')
  return '' + years + ' yrs ' + monthDiff + ' mos'
}

export function getBusinessDays (startDate, endDate) {
  const day = moment(startDate)
  let businessDays = 0

  while (day.isSameOrBefore(endDate, 'day')) {
    if (day.day() !== 0 && day.day() !== 6) {
      businessDays++
    }
    day.add(1, 'd')
  }
  return businessDays
}

export function getBusinessDayMap (startDate, endDate) {
  const day = moment(startDate)
  const businessDayMap = {
    Mon: 0,
    Tue: 0,
    Wed: 0,
    Thu: 0,
    Fri: 0
  }

  while (day.isSameOrBefore(endDate, 'day')) {
    if (day.day() !== 0 && day.day() !== 6) {
      const df = day.format('ddd')
      businessDayMap[df] = businessDayMap[df] + 1
    }
    day.add(1, 'd')
  }
  return businessDayMap
}

export function differenceHours (startTS, endTS) {
  const end = moment(endTS)
  const start = moment(startTS)
  const duration = moment.duration(start.diff(end))
  const hours = duration.asHours()
  return roundedToFixed(hours, 1)
}
export function parseDateFormat (dateStr) {
  if (dateStr) {
    const date = moment(dateStr, 'DD/MM/YY')
    return date.toDate()
  } else {
    return null
  }
}
export function parseDate (dateStr) {
  if (dateStr) {
    const date = moment(dateStr, 'ddd D MMM')
    return date.toDate()
  }
  return null
}
export function parseDisplayDate (dateStr) {
  if (dateStr) {
    const date = moment(dateStr, 'Do MMMM YYYY')
    return date.toDate()
  }
  return null
}
export function parseDateTime (dateStr) {
  if (dateStr) {
    const date = moment(dateStr, 'ddd D MMM HH:mm')
    return date.toDate()
  }
  return null
}
export function parseDatetoTS (dateStr) {
  if (dateStr) {
    const date = moment(dateStr, 'ddd D MMM HH:mm')
    return date.toDate().getTime()
  }
  return 0
}

export function addDaysDate (dateObj, days) {
  if (dateObj) {
    let date = moment(dateObj)
    date = date.add(days, 'days')
    return date.toDate()
  }
  return null
}

export function addDays (dateStr, days) {
  if (dateStr) {
    let date = moment(dateStr, 'ddd D MMM HH:mm')
    date = date.add(days, 'days')
    return date.toDate()
  }
  return null
}

export function getDayAbr (date) {
  return moment(date).format('ddd')
}

export function timeSlotFormat (date) {
  return moment(date).format('a')
}

export function getDayAbrFromIndex (index) {
  return DAY_ABV_ISO[`${index}`]
}

export function getNextMonday () {
  if (moment().day() === 0) {
    return moment().day(1).format('Do MMM [at 9:00AM]')
  }
  return moment().day(8).format('Do MMM [at 9:00AM]')
}

export function getNextFriday () {
  if (moment() === 6) {
    return moment().day(12).format('Do MMM [at 9:00AM]')
  }
  return moment().day(5).format('Do MMM [at 9:00AM]')
}

export function isSameWeek (dayA, dayB) {
  if (moment(dayA).week() === moment(dayB).week()) {
    return true
  }
  return false
}

export function isCurrentWeek (day) {
  if (moment(day).week() === moment().week()) {
    return true
  }
  return false
}

export function getFirstDayOfMonth (day, since, scheduleTime) {
  const dayNums = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }
  const now = moment()
  let result = moment().set('year', now.year()).set('month', now.month() + 1).set('date', 1).add(dayNums[day] + 1, 'days')

  if (result.isBefore(now)) {
    result = result.add(1, 'month')
  }

  const format = 'hh:mm'
  // var time = moment() gives you current time. no format required.
  const timeMoment = moment(scheduleTime, format)
  result = result.hour(timeMoment.get('hour'))
  result = result.minute(timeMoment.get('minute'))
  result = result.second('00')
  return result
}

export function getNextDay (listOfDays, since, scheduleTime, additionalDays) {
  const dayNums = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }
  const dayNumsSelected = listOfDays.map(d => dayNums[d])
  const daysNeeded = dayNumsSelected.sort()

  function isThisInFuture (targetDayNum) {
    // param: positive integer for weekday
    // returns: matching moment or false
    let todayNum = moment().isoWeekday()
    const date = moment()

    if (scheduleTime) {
      const format = 'hh:mm'
      // var time = moment() gives you current time. no format required.
      const time = moment(scheduleTime, format)
      if (time.isBefore()) {
        date.add(1, 'day')
        todayNum = date.isoWeekday()
      }
    }

    if (since) {
      if (moment(since).isSame(new Date(), 'day')) {
        date.add(additionalDays || 1, 'day')
        todayNum = date.isoWeekday()
      }
    }

    if (todayNum <= targetDayNum) {
      return date.isoWeekday(targetDayNum)
    }
    return false
  }

  function findNextInstanceInDaysArray (daysArray) {
    const tests = daysNeeded.map(isThisInFuture)

    const thisWeek = tests.find((sample) => { return sample instanceof moment })
    const nextWeek = moment().add(1, 'weeks').isoWeekday(daysNeeded[0])
    // console.log('time.findNextInstanceInDaysArray', thisWeek, nextWeek)
    let result = thisWeek || nextWeek
    const format = 'hh:mm'

    const timeMoment = moment(scheduleTime, format)
    if (result.hour) {
      result = result.hour(timeMoment.get('hour'))
      result = result.minute(timeMoment.get('minute'))
      result = result.second('00')
    }
    return result
  }
  return findNextInstanceInDaysArray(daysNeeded)
}

export function sameDay (date1, date2) {
  // console.log('checking sameday', date1, date2, moment(date1).isSame(moment(date2), 'day'))
  return moment(date1).isSame(moment(date2), 'day')
}

export function isToday (date) {
  return moment(date).isSame(moment(new Date()), 'day')
}

export function isTomorrow (date) {
  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  return moment(date).isSame(moment(tomorrow), 'day')
}

export function inPast (date) {
  return moment(date).isBefore(moment(new Date()), 'day')
}
export function inPastTime (date) {
  return moment(date).isBefore(moment(new Date()), 'seconds')
}

export function inFuture (date) {
  return moment(date).isAfter(moment(new Date()), 'day')
}

export function getTimeslotsBetween (start, end, endGap, endGapTimeFormat) {
  let startTime = moment(start, 'HH:mm')
  let endTime = moment(end, 'HH:mm')
  if (endGap && endGapTimeFormat) {
    endTime = endTime.add(-endGap, endGapTimeFormat)
  } else {
    endTime = endTime.add(-1, 'h')
  }
  // console.log(endTime)
  const timeslots = []
  while (startTime <= endTime) {
    timeslots.push({ startTime: timeFormat(startTime.toDate()) })
    startTime = startTime.add(30, 'm')
  }
  return timeslots
}

export function datesBetween (start, end) {
  const dateArray = []
  let currentDate = moment(start)
  const stopDate = moment(end)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).toDate())
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

export function weeksBetween (start, end) {
  const dateArray = []
  let currentDate = moment(start)
  const stopDate = moment(end)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).endOf('day').toDate())
    currentDate = moment(currentDate).add(1, 'weeks').endOf('day')
  }
  return dateArray
}

export function monthsBetween (start, end) {
  const dateArray = []
  let currentDate = moment(start)
  const stopDate = moment(end)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).toDate())
    currentDate = moment(currentDate).add(1, 'months')
  }
  return dateArray
}

export function getLastWeek () {
  return moment().subtract(7, 'days').startOf('day').toDate()
}

export function getLastMonth () {
  return moment().subtract(30, 'days').startOf('day').toDate()
}

export function getLastQuarter () {
  return moment().subtract(90, 'days').startOf('day').toDate()
}

export function getLast12Weeks () {
  return moment().subtract(11, 'weeks').startOf('day').toDate()
}

export function getLastYear () {
  return moment().subtract(1, 'years').startOf('day').toDate()
}

export function getDateRange (range) {
  if (range === 'Today') {
    const from = moment().startOf('day').toDate()
    const to = moment().endOf('day').toDate()
    return { from, to }
  } else if (range === 'Yesterday') {
    const from = moment().subtract(1, 'days').startOf('day').toDate()
    const to = moment().subtract(1, 'days').endOf('day').toDate()
    return { from, to }
  } else if (range === 'Last week') {
    const from = moment().subtract(1, 'weeks').startOf('isoWeek').toDate()
    const to = moment().subtract(1, 'weeks').endOf('isoWeek').toDate()
    return { from, to }
  } else if (range === 'Last month') {
    const from = moment().subtract(1, 'months').startOf('month').toDate()
    const to = moment().subtract(1, 'months').endOf('month').toDate()
    return { from, to }
  } else if (range === 'Last 7 days') {
    const from = getLastWeek()
    const to = moment().endOf('day').toDate()
    return { from, to }
  } else if (range === 'Last 30 days') {
    const from = getLastMonth()
    const to = moment().endOf('day').toDate()
    return { from, to }
  } else if (range === 'Last 6 months') {
    const from = moment().subtract(6, 'months').startOf('month').toDate()
    const to = moment().subtract(1, 'months').endOf('month').toDate()
    return { from, to }
  } else if (range === 'Last 12 months') {
    const from = moment().subtract(12, 'months').startOf('month').toDate()
    const to = moment().subtract(1, 'months').endOf('month').toDate()
    return { from, to }
  }
  return null
}

export function getWeeksDates (date) {
  const wc = getWC(date)
  const we = getWE(date)
  return datesBetween(wc, we)
}

export function getWC (date) {
  const wc = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  if (wc.getDay() !== 1) {
    if (wc.getDay() === 0) {
      wc.setDate(wc.getDate() - 6)
    } else {
      wc.setDate(wc.getDate() - wc.getDay() + 1)
    }
  }
  return wc
}

export function setTime (timestamp, hour, minute, second, millisecond) {
  if (!hour) {
    hour = 0
  }
  if (!minute) {
    minute = 0
  }
  if (!second) {
    second = 0
  }
  if (!millisecond) {
    millisecond = 0
  }
  return moment(timestamp).set({ hour, minute, second, millisecond })
}

export function getWE (date) {
  const we = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
  if (we.getDay() !== 0) {
    we.setDate(we.getDate() - we.getDay() + 7)
  }
  return we
}

export function now () {
  return moment().local().format('DD/MM/YY')
}

export function dayFormat (timestamp) {
  if (timestamp) {
    return moment(timestamp).local().format('ddd')
  } else {
    return ''
  }
}
export function dayDateFormat (timestamp, format = 'dddd D') {
  if (timestamp) {
    return moment(timestamp).local().format(format)
  } else {
    return ''
  }
}
export function datetimeFormat (timestamp) {
  if (timestamp) {
    return moment(timestamp).local().format('MMM DD [at] HH:mm')
  } else {
    return ''
  }
}
export function datetimeFormatFull (timestamp) {
  if (timestamp) {
    return moment(timestamp).local().format('Do MMMM YYYY [at] HH:mm')
  } else {
    return ''
  }
}
export function dateTimeArrayFormat (dateStr) {
  if (dateStr) {
    const datetime = moment(dateStr, 'ddd D MMM HH:mm')
    const formatdt = datetime.format('YYYY, M, D, H, m')
    return `[${formatdt}]`
  } else {
    return ''
  }
}
export function dateFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('DD/MM/YY')
  } else {
    return ''
  }
}
export function dateCustomFormat (timestamp, format) {
  if (timestamp) {
    return moment.utc(timestamp).local().format(format)
  } else {
    return ''
  }
}
export function dateDisplayFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('Do MMMM YYYY')
  } else {
    return ''
  }
}

export function dateShortDisplayFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('DD MMM')
  } else {
    return ''
  }
}

export function dateTimeDisplayFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('Do MMMM YYYY [at] HH:mm')
  } else {
    return ''
  }
}

export function dateExtFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('ddd D MMM')
  } else {
    return ''
  }
}
export function dateYearFormat (timestamp) {
  if (timestamp) {
    return moment(timestamp).format('DD MMM YYYY')
  } else {
    return ''
  }
}
export function timeFormat (timestamp) {
  if (timestamp) {
    return moment.utc(timestamp).local().format('HH:mm')
  } else {
    return ''
  }
}
