import { render, staticRenderFns } from "./Task.vue?vue&type=template&id=4ca53aaa&scoped=true&"
import script from "./Task.vue?vue&type=script&lang=js&"
export * from "./Task.vue?vue&type=script&lang=js&"
import style0 from "./Task.vue?vue&type=style&index=0&id=4ca53aaa&scoped=true&lang=css&"
import style1 from "./Task.vue?vue&type=style&index=1&lang=css&"
import style2 from "./Task.vue?vue&type=style&index=2&lang=css&"
import style3 from "./Task.vue?vue&type=style&index=3&lang=scss&"
import style4 from "./Task.vue?vue&type=style&index=4&id=4ca53aaa&lang=scss&scoped=true&"
import style5 from "./Task.vue?vue&type=style&index=5&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ca53aaa",
  null
  
)

export default component.exports