import fb from './firebase'

export const uploadCVFile = (userId, file, addCV) => {
  const filename = file.name
  const filepath = `/user/${userId}/cv/${filename}`
  return fb.storage.ref().child(filepath).put(file).then(snapshot => {
    return snapshot.ref.getDownloadURL().then(function (downloadURL) {
      addCV(filename, downloadURL)
      return 'File uploaded'
    })
  }).catch(err => {
    console.error(err)
    return 'Error uploading file'
  })
}

export const uploadAvatarFile = (userId, file, filename, addAvatar) => {
  const filepath = `/user/${userId}/avatar/${filename}`
  return fb.storage.ref().child(filepath).put(file).then(snapshot => {
    return snapshot.ref.getDownloadURL().then(function (downloadURL) {
      addAvatar(downloadURL)
      return 'File uploaded'
    })
  }).catch(err => {
    console.error(err)
    return 'Error uploading file'
  })
}
