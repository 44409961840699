<template>
  <div role="presentation" aria-label="activity-box" class="activity-box" :style="{'background-color': bgColor, 'border-color': borderColor, 'border-style': borderStyle, 'height': height, 'width': width }" v-if="activityText !== undefined">
    <mdc-text tag="span" typo="caption" class="number" :style="{'font-size': fontSize, 'color': fontColor, 'font-weight': fontWeight }" v-html="activityText"></mdc-text>
    <mdc-text v-if="activityType" typo="caption" tag="span" :style="{'font-size': typeFontSize, 'color': typeFontColor, 'font-weight': typeFontWeight }" class="activity-type">{{activityType}}</mdc-text>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ActivityBox',
  components: {},
  props: {
    height: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    activityText: {
    },
    activityType: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: ''
    },
    fontColor: {
      type: String,
      default: ''
    },
    fontWeight: {
      type: String,
      default: ''
    },
    typeFontSize: {
      type: String,
      default: ''
    },
    typeFontColor: {
      type: String,
      default: ''
    },
    typeFontWeight: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: ''
    },
    borderStyle: {
      type: String,
      default: ''
    },
    borderColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  beforeDestroy () {},
  created () {},
  mounted () {},
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
    })
  },
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    })
  },
  watch: {}
}
</script>

<style lang="scss">
.activity-box {
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border: 1px;
  .mdc-text {
    line-height: unset;
  }
  .activity-text {}
  .activity-type {
    text-align: center;
  }
}
</style>
