<template>
  <div class="rec-card-wrapper" :ref="'rec-card'+recommendation.id" :class="[recType, recType === 'initiative' ? 'initiative-rec' : '', recType === 'people' ? 'people-rec' : '', fullView ? 'full-view' : '', noHover ? 'no-hover' : 'hover-present', recommendation.pauseNotification ? 'paused' : '', recommendationClicked === recommendation.id && meetingClicked === displayMeeting.id ? 'meeting-clicked' : '']" role="card">
    <div class="pause-notify" v-if="freeTimingsPageNum === 0 && !bookingTime && !bookingSuccess">
      <base-svg-icon :name="!recommendation.pauseNotification ? 'toggle_on-6184ad' : 'toggle_off-6184ad'" clickable class="tick" @click.native.stop.prevent="pauseNotification()" />
      <tooltip v-if="showTooltip" pointerPosition="center" :tooltipText="recommendation.pauseNotification ? 'Toggle to resume notification' : 'Toggle to pause notification'" :tooltipIcon="recommendation.pauseNotification ? 'toggle_on-6184ad' : 'toggle_off-6184ad'" class="rec-tooltip"/>
    </div>
    <template v-if="recType === 'initiative' && recommendation">
      <svg-percent-ring class="perc-ring" :tFontWeight="900" :tFontSize="16" :tFill="'#2B8AFB'" :tPosX="'50%'" :tPosY="'60%'" :svgBgColor="'#E1EFFF'" :strokeColor="'#2B8AFB'" showBackground :height="58" :width="58" :viewBox="'0 0 58 58'" :cRadius="26" :centerX="29" :centerY="29" :percentage="recommendation.percentInOffice" :id="recommendation.id"/>
      <initiative-name class="init-name" :initiativeId="recommendation.initiativeId" showTeam showHash @initiativeClick="redirectToInitiative(recommendation.initiativeId)" clickable />
      <div class="members-list">
        <carousel :per-page="5"
          centerMode
          :minSwipeDistance="0"
          ref="carousel"
          paginationColor="rgba(97, 132, 173, 0.3)"
          paginationActiveColor="#4f4f4f"
          navigationEnabled
          :paginationPadding="5"
          :paginationSize="4"
          navigationPrevLabel="<img src='/static/img/icons/flexibly/black-arrow-nav-left.svg' />"
          navigationNextLabel="<img src='/static/img/icons/flexibly/black-arrow-nav-right.svg' />">
            <slide :data-index="index" v-for="(profileId, index) in recommendation.inOffice" :key="profileId">
              <profile-initials :profileId="profileId" class="member-initials" :date="appDate" />
            </slide>
          </carousel>
      </div>
      <div class="location-name rec-card" :class="recommendation.id" v-if="recommendation.locationDetails">
        <base-svg-icon name="location-pin-fff" />
        <location-name class="loc-name" :locationDetails="recommendation.locationDetails" />
      </div>
    </template>
    <template v-if="recType === 'people' && recommendation">
      <div class="more-free-timings" v-if="freeTimingsShown && freeTimingsPageNum !== 0">
        <base-svg-icon name="cross-thin" clickable class="close-icon" @click.native.stop.prevent="closeFreeTimings()" />
        <div class="list-wrapper">
          <mdc-text class="more-slots-text" tag="span" typo="caption">More available slots</mdc-text>
          <div class="free-timings-wrapper">
            <base-svg-icon :class="{ 'hide': totalFreeTimingsPages === 0 || freeTimingsPageNum === 0 }" class="arrow-left nav-arrow" name="thin-left-arrow-grey" clickable @click.native.stop="prevPageFreeTimings" />
            <div class="timings-wrapper">
              <mdc-text class="free-time clickable" v-for="timing in finalFreeTimings" :key="timing + recommendation.id" @click.native="setBookingTime(timing)" tag="span" typo="caption" :class="{disabled: bookingTime}">
                {{timing}}
              </mdc-text>
            </div>
            <base-svg-icon :class="{ 'hide': totalFreeTimingsPages === 0 || freeTimingsPageNum + 1 === totalFreeTimingsPages }" class="arrow-right nav-arrow" name="thin-right-arrow-grey" clickable @click.native.stop="nextPageFreeTimings" />
          </div>
        </div>
      </div>
      <div class="selected-timing" v-else-if="bookingTime">
        <base-svg-icon name="cross-thin" clickable class="close-icon" @click.native.stop.prevent="closeBookMeeting()" />
        <div class="meeting-time-selected slot-details">
          <mdc-text class="selected-slot-text slot-type" tag="span" typo="caption">Selected slot</mdc-text>
          <mdc-text class="selected-time-slot" tag="span" typo="caption">{{bookingTime}}</mdc-text>
        </div>
        <div class="meeting-name-details slot-details">
          <mdc-text class="meeting-name-text slot-type" tag="span" typo="caption">Meeting name</mdc-text>
          <textarea v-model="meetingNameText" class="meeting-name-input" placeholder="Meeting subject" :class="errorMsg ? 'error' : ''" />
        </div>
        <mdc-button class="send-invite-btn" @click.native.stop="bookMeeting">
          {{ bookingInprogress ? 'Sending' : 'Send invite' }}
          <span v-show="bookingInprogress" class="loader invite"></span>
        </mdc-button>
      </div>
      <div class="sucess-booking" v-else-if="bookingSuccess">
        <mdc-text tag="span" typo="body2">Booking successful</mdc-text>
        <base-svg-icon name="round_checked_green_tran" class="green_tick" />
      </div>
      <template v-else>
        <profile-initials :key="'pi-' + recommendation.userId" :profileId="recommendation.userId" class="member-initials" :date="appDate" showDisplayName />
        <mdc-text class="meet-time-text" tag="span" typo="caption" v-if="meetingsLoaded && recommendation.allMeetings && recommendation.allMeetings.length > 0">You're due to meet at</mdc-text>
        <mdc-text class="free-time-text" tag="span" typo="caption" v-else-if="recommendation.freeTimings && recommendation.freeTimings.length > 0">You're both free at</mdc-text>
        <mdc-text class="time-text" tag="span" typo="caption" v-else></mdc-text>
        <div class="meeting-timings-wrapper"  v-if="recommendation.allMeetings && recommendation.allMeetings.length > 0">
          <base-svg-icon :class="{ 'hide': totalMeetingTimingsPages === 0 || meetingTimingsPageNum === 0 }" class="arrow-left nav-arrow" name="thin-left-arrow-grey" clickable @click.native.stop="prevPageMeetingTimings" />
          <div class="timings-wrapper">
            <mdc-text @click.native.stop="showMeetingDialog(meetingDetails)" v-for="meetingDetails in finalMeetingTimings" :key="meetingDetails && meetingDetails.id + recommendation.userId" class="meeting-time clickable" :class="{ past: isMeetingPast(meetingDetails) }" tag="span" typo="body2">
              <template v-if="meetingDetails.isAllDay">ALL DAY</template>
              <template v-else>{{timeZoneTime(meetingDetails.startDateTime)}}</template>
            </mdc-text>
          </div>
          <base-svg-icon :class="{ 'hide': totalMeetingTimingsPages === 0 || meetingTimingsPageNum + 1 === totalMeetingTimingsPages }" class="arrow-right nav-arrow" name="thin-right-arrow-grey" clickable @click.native.stop="nextPageMeetingTimings" />
        </div>
        <div class="free-timings-wrapper" v-else-if="loadingMeetings">
            <mdc-text class="no-slots" tag="span" typo="body2">Loading meetings</mdc-text>
        </div>
        <div class="free-timings-wrapper"  v-else-if="recommendation.freeTimings">
          <template v-if="recommendation.freeTimings.length === 0">
            <mdc-text class="no-slots" tag="span" typo="body2">No free time</mdc-text>
          </template>
          <template v-else>
            <base-svg-icon :class="{ 'hide': totalFreeTimingsPages === 0 || freeTimingsPageNum === 0 }" class="arrow-left nav-arrow" name="thin-left-arrow-grey" clickable @click.native.stop="prevPageFreeTimings" />
            <div class="timings-wrapper">
              <mdc-text class="free-time clickable" v-for="timing in finalFreeTimings" :key="timing + recommendation.id" @click.native="setBookingTime(timing)" tag="span" typo="body2" :class="{disabled: bookingTime}">
                {{timing}}
              </mdc-text>
            </div>
            <base-svg-icon :class="{ 'hide': totalFreeTimingsPages === 0 || freeTimingsPageNum + 1 === totalFreeTimingsPages }" class="arrow-right nav-arrow" name="thin-right-arrow-grey" clickable @click.native.stop="nextPageFreeTimings" />
          </template>
        </div>
        <div class="time-wrapper" v-else-if="(!profile.msEmail && !profile.googleEmail)">
          <mdc-text class="meeting-time no-calendar" tag="span" typo="body2">--:--</mdc-text>
        </div>
        <div class="time-wrapper connect" v-else-if="(msNeedsReconnect && googleNeedsReconnect) || ((profile.msEmail || profile.googleEmail) && (subscriptions && subscriptions.length === 0))" @click="navigateSettingsConnectCal()"> <!-- both needs reconnect or cal without subscription -->
          <base-svg-icon name="calendar-rec-card" />
          <mdc-text class="meeting-time connect-text" tag="span" typo="body2">Connect</mdc-text>
        </div>
        <mdc-button v-else-if="profile.defaultCalendar && recommendation.date && !isPast && meetingsLoaded && recommendation.allMeetings !== null" class="book-meeting-button check-avail" @click.native="checkAvailbility()">
          <span v-show="!loadingAvailability">Check availability</span>
          <span v-show="loadingAvailability">Checking</span>
          <span v-show="loadingAvailability" class="loader"></span>
        </mdc-button>
        <mdc-button v-else-if="profile.defaultCalendar && meetingsLoaded && recommendation.allMeetings !== null" class="book-meeting-button" @click.native="redirectCalendar()">Book a meeting</mdc-button>
        <div class="time-wrapper" v-else-if="!meetingsLoaded || recommendation.allMeetings === null">
          <mdc-text class="meeting-time no-time" tag="span" typo="body2">-</mdc-text>
        </div>
        <div class="location-name rec-card" :class="recommendation.id" v-if="recommendation.locationDetails">
          <base-svg-icon name="location-pin-fff" />
          <location-name class="loc-name" :locationDetails="recommendation.locationDetails" />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import SvgPercentRing from './SvgPercentRing.vue'
import InitiativeName from './InitiativeName.vue'
import ProfileInitials from './ProfileInitials.vue'
import Tooltip from './Tooltip.vue'
import LocationName from './LocationName.vue'
import { calendar } from '../../utils/backend'
import { setTime, inPast, sameDay } from '../../utils/time'
import { getFirstNameAndInitial } from '../../utils/profile'
import moment from 'moment'

export default {
  name: 'RecommendationCard',
  components: {
    BaseSvgIcon,
    SvgPercentRing,
    InitiativeName,
    ProfileInitials,
    Tooltip,
    LocationName
  },
  props: {
    noHover: {
      type: Boolean,
      default: false
    },
    recommendation: {},
    recType: {
      type: String,
      default: ''
    },
    preferenceId: {
    },
    fullView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showTooltip: true,
      freeTimingsShown: false,
      finalFreeTimings: [],
      freeTimingsPageNum: 0,
      totalFreeTimingsPages: 0,
      meetingTimingsShown: false,
      finalMeetingTimings: [],
      meetingTimingsPageNum: null,
      firstNonPastMeetingIndex: 0,
      firstNonPastMeetingPage: 0,
      totalMeetingTimingsPages: 0,
      loadingAvailability: false,
      bookingTime: false,
      noAvailability: false,
      meetingNameText: '',
      bookingSuccess: false,
      bookingInprogress: false,
      errorMsg: '',
      meetingClicked: '',
      loadingMeetings: false
    }
  },
  beforeDestroy () {},
  created () {},
  mounted () {
    if (this.recommendation.freeTimings && this.recommendation.freeTimings.length > 0) {
      this.frameFreeTimings()
    }
    if (this.recommendation.allMeetings && this.recommendation.allMeetings.length > 0) {
      this.frameMeetingTimings()
    }
    if (this.recommendation.locationDetails) {
      this.setLocationNameEffect()
    }
  },
  computed: {
    ...mapState([
      'appDate',
      'profile',
      'profiles',
      'displayMeeting',
      'recommendationClicked'
    ]),
    ...mapState({
      subscriptions: state => state.teams.subscriptions,
      meetingsLoaded: state => state.teams.meetingsLoaded
    }),
    ...mapGetters([
      'getProfile'
    ]),
    msConnected () {
      if (this.profile) {
        return this.profile.msEmail && this.profile.calendarsSelected && this.profile.calendarsSelected.length > 0
      }
      return false
    },
    googleConnected () {
      if (this.profile) {
        return this.profile.googleEmail && this.profile.googleCalendarsSelected && this.profile.googleCalendarsSelected.length > 0
      }
      return false
    },
    recommendationUserProfile () {
      if (this.recommendation) {
        if (this.recommendation.userId) {
          const recP = this.profiles.filter(pr => pr.id === this.recommendation.userId)[0]
          return recP || false
        }
      }
      return false
    },
    isPast () {
      if (this.recommendation) {
        if (this.recommendation.date) {
          const recDate = this.recommendation.date.toDate ? this.recommendation.date.toDate() : this.recommendation.date
          if (inPast(recDate)) {
            return true
          }
        }
      }
      return false
    },
    msNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type !== 'Google') {
              // type MS or blank
              if (sub.msNeedsReconnect) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    googleNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type === 'Google') {
              // type Google
              if (sub.googleNeedsReconnect) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    defaultCalendarLink () {
      if (this.profile && this.profile.defaultCalendar) {
        const msCal = this.profile.calendarsSelected || []
        const gCal = this.profile.googleCalendarsSelected || []
        for (const cal of msCal) {
          if (this.profile.defaultCalendar === cal.calendarId) {
            return `https://outlook.office.com/calendar/${this.profile.defaultCalendar}/view/month`
          }
        }
        for (const cal of gCal) {
          if (this.profile.defaultCalendar === cal.calendarId) {
            return `https://calendar.google.com/calendar/u/${this.profile.googleEmail}?src=${this.profile.defaultCalendar}`
          }
        }
      }
      return false
    },
    defaultCalendar () {
      if (this.profile && this.profile.defaultCalendar) {
        const msCal = this.profile.calendarsSelected || []
        const gCal = this.profile.googleCalendarsSelected || []
        for (const cal of msCal) {
          if (this.profile.defaultCalendar === cal.calendarId) {
            return { ...cal, type: 'MS' }
          }
        }
        for (const cal of gCal) {
          if (this.profile.defaultCalendar === cal.calendarId) {
            return { ...cal, type: 'Google' }
          }
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      'setDisplayMeeting',
      'setRecommendationClicked'
    ]),
    ...mapActions({
      updateCurrentUserRecommendation: 'teams/updateCurrentUserRecommendation'
    }),
    getFirstNonPastMeetingIndex () {
      if (this.recommendation) {
        if (this.recommendation.allMeetings && this.recommendation.allMeetings.length > 0) {
          for (let idx = 0; idx < this.recommendation.allMeetings.length; idx++) {
            if (this.recommendation.allMeetings[idx]) {
              // Ignore all day meetings
              if (!this.recommendation.allMeetings[idx].isAllDay) {
                if (!this.isMeetingPast(this.recommendation.allMeetings[idx])) {
                  this.firstNonPastMeetingIndex = idx
                  this.firstNonPastMeetingPage = Math.ceil(this.firstNonPastMeetingIndex / 3)
                  return
                }
              }
            }
          }
          // All Meetings past
          this.firstNonPastMeetingIndex = this.recommendation.allMeetings.length - 1
          this.firstNonPastMeetingPage = Math.ceil(this.firstNonPastMeetingIndex / 3)
          return
        }
      }
      this.firstNonPastMeetingIndex = 0
      this.firstNonPastMeetingPage = 0
    },
    isMeetingPast (meetingDetails) {
      let edt = meetingDetails.endDateTime
      if (edt?.toDate) {
        edt = edt.toDate()
      }
      if (!edt) {
        // All day
        return false
      }
      if (this.appDate.isAfter(edt)) {
        return true
      }
      return false
    },
    timeZoneTime (startDateTime) {
      let sdt = startDateTime
      if (sdt?.toDate) {
        sdt = sdt.toDate()
      }
      return moment.tz(sdt, this.profile.timezone).format('HH:mm')
    },
    showMeetingDialog (meetingDetails) {
      this.meetingClicked = ''
      this.$nextTick(() => {
        this.meetingClicked = meetingDetails.id
        this.setRecommendationClicked(this.recommendation.id)
      })
      const details = meetingDetails
      if (details) {
        this.setDisplayMeeting({ ...details, showFor: 'recommendation' })
      }
    },
    navigateSettingsConnectCal () {
      this.$router.push({ name: 'Settings', params: { section: 'calendarconnect' } })
    },
    isEllipsisActive (el, onlyBuilding) {
      if (el) {
        const temp = el.cloneNode(true)
        temp.style.position = 'fixed'
        temp.style.overflow = 'visible'
        if (onlyBuilding) {
          temp.style.whiteSpace = 'pre-wrap'
          temp.style.webkitLineClamp = 'unset'
        } else {
          temp.style.whiteSpace = 'nowrap'
          temp.style.maxWidth = 'unset'
        }
        temp.style.visibility = 'hidden'
        el.parentElement.appendChild(temp)
        try {
          if (onlyBuilding) {
            const fullHeight = temp.getBoundingClientRect().height
            const displayHeight = el.getBoundingClientRect().height
            return fullHeight > displayHeight
          } else {
            const fullWidth = temp.getBoundingClientRect().width
            const displayWidth = el.getBoundingClientRect().width
            return fullWidth > displayWidth
          }
        } finally {
          temp.remove()
        }
      }
      return false
    },
    setLocationNameEffect () {
      const locationClass = 'location-name ' + this.recommendation.id
      const locationElement = locationClass ? document?.getElementsByClassName(locationClass) : null
      const locationName = locationElement ? locationElement[0] : null
      if (locationName) {
        const isBuildingOnlychild = locationName.querySelector('.loc-name .only-building')
        const isBuildingNameTruncated = this.isEllipsisActive(locationName.querySelector('.loc-name .building-name'), isBuildingOnlychild)
        const isFloorAreaNameTruncated = this.isEllipsisActive(locationName.querySelector('.loc-name i'))
        if (isBuildingNameTruncated || isFloorAreaNameTruncated) {
          locationName.classList.add('hoverEffect')
        }
      }
    },
    calcAvailableSlots (busyTimes) {
      // console.log('RecommendationCard.calcAvailableSlots', busyTimes)
      const startHour = 9
      const endHour = 18
      const intervalMins = 30
      let endReached = false
      let currentHour = startHour
      let currentMin = 0
      const freeTimes = []
      const recDate = this.recommendation.date.toDate ? this.recommendation.date.toDate() : this.recommendation.date
      const now = new Date()

      if (sameDay(recDate, now)) {
        currentHour = now.getHours()
        currentMin = now.getMinutes()
        if (currentMin > 30) {
          currentMin = 0
          currentHour = currentHour + 1
        } else if (currentMin > 0) {
          currentMin = 30
        }
      }

      if (!busyTimes) {
        busyTimes = []
      }

      while (!endReached) {
        const current = setTime(recDate, currentHour, currentMin)

        // If not in busyTimes for any cal
        const ftf = current.format('HH:mm')
        let busy = false
        if (busyTimes) {
          for (const bt of busyTimes) {
            if (current.isBetween(moment.utc(bt.start), moment.utc(bt.end), 'minute', '[)')) {
              // console.log(`RecommendationCard.calcAvailableSlots slot ${ftf} is in busy time: ${moment.utc(bt.start).local().format('HH:mm')} - ${moment.utc(bt.end).local().format('HH:mm')}`)
              busy = true
            }
          }
        }

        if (!busy) {
          freeTimes.push(ftf)
        }
        currentMin += intervalMins
        if (currentMin >= 60) {
          currentHour += 1
          currentMin = 0
        }
        if (currentHour >= endHour) {
          endReached = true
        }
      }
      this.recommendation.freeTimings = freeTimes
      this.frameFreeTimings()
    },
    async checkAvailbility () {
      this.loadingAvailability = true
      this.noAvailability = false
      this.availabilityMessage = ''
      this.availabilityError = false
      setTimeout(() => {
        // console.log('RecommendationCard.checkAvailbility - Availability timeout')
        this.recommendation.freeTimings = false
        this.freeTimingsShown = false
      }, 600000) // 10 miinute timeout of availability
      let busyTimes = []
      const recDate = this.recommendation.date.toDate ? this.recommendation.date.toDate() : this.recommendation.date
      const start = setTime(recDate, 9, 0).format('YYYY-MM-DD[T]HH:mm:ssZ')
      const end = setTime(recDate, 18, 0).format('YYYY-MM-DD[T]HH:mm:ssZ')
      const gstart = setTime(recDate, 9, 0).format('YYYY-MM-DD[T]HH:mm:ss.000[Z]')
      const gend = setTime(recDate, 18, 0).format('YYYY-MM-DD[T]HH:mm:ss.000[Z]')
      // console.log('recommendationCard.checkAvailbility', recDate, start, end)
      try {
        if (this.recommendationUserProfile) {
          if (this.msConnected) {
            const params = {
              userId: this.profile.id,
              schedules: [
                this.profile.msEmail,
                this.recommendationUserProfile.msEmail || this.recommendationUserProfile.email
              ],
              start,
              end
            }
            const msResponse = await calendar('availability', params)
            // console.log('RecommendationCard.checkAvailbility.ms', msResponse, params)
            if (msResponse.availability) {
              if (msResponse.availability.value) {
                for (const cal of msResponse.availability.value) {
                  if (cal.scheduleItems) {
                    for (const item of cal.scheduleItems) {
                      if (item.status === 'busy' || item.status === 'tentative') {
                        const busyTime = { start: item.start.dateTime, end: item.end.dateTime }
                        busyTimes.push(busyTime)
                      }
                    }
                  }
                }
              }
            }
          }
          if (this.googleConnected) {
            const gParams = {
              userId: this.profile.id,
              calendars: [
                { id: this.profile.googleEmail },
                { id: this.recommendationUserProfile.googleEmail || this.recommendationUserProfile.email }
              ],
              start: gstart,
              end: gend
            }
            const gResponse = await calendar('availability-google', gParams)
            // console.log('RecommendationCard.checkAvailbility.google', gResponse, gParams)
            if (gResponse.availability) {
              if (gResponse.availability.calendars) {
                for (const cal of Object.values(gResponse.availability.calendars)) {
                  if (cal.busy) {
                    busyTimes = [...busyTimes, ...cal.busy]
                  }
                }
              }
            }
          }
        }
        this.calcAvailableSlots(busyTimes)
        this.freeTimingsShown = true
      } catch (ex) {
        this.availabilityError = true
        this.availabilityMessage = 'Unable to retrieve availability'
        console.error('RecommendationCard.checkAvailability.error', ex)
      }
      this.loadingAvailability = false
    },
    frameMeetingTimings () {
      this.getFirstNonPastMeetingIndex()
      if (this.recommendation.allMeetings && this.recommendation.allMeetings.length > 0) {
        const mLength = this.recommendation.allMeetings.length
        const mBefore = this.firstNonPastMeetingIndex
        const mAfter = mLength - mBefore - 1

        const mToShow = 3
        const mToShowFloat = 3.0

        let offset = 0
        if (mBefore > 0) {
          if (mAfter === 0 && mBefore > 1) {
            offset = 2
          } else {
            offset = 1
          }
        }

        // console.log(`RecommendationCard.frameMeetingTimings Meetings Length:${mLength} MBefore:${mBefore} MAfter:${mAfter}`)

        const pBefore = Math.ceil((mBefore - offset) / mToShowFloat)
        const pAfter = Math.ceil((mAfter - (mToShow - (offset + 1))) / mToShowFloat)
        const totalP = pBefore + 1 + pAfter
        this.totalMeetingTimingsPages = totalP

        // console.log(`RecommendationCard.frameMeetingTimings Page ${pBefore} ${pAfter} ${totalP}`)

        if (this.meetingTimingsPageNum === null) {
          this.meetingTimingsPageNum = pBefore
        }

        const pageC = (this.meetingTimingsPageNum - pBefore) * mToShow

        // console.log(`RecommendationCard.frameMeetingTimings Page ${this.meetingTimingsPageNum + 1}/${totalP} PageOffset: ${pageC}`)

        const start = mBefore - offset + pageC
        const end = mBefore - offset + mToShow + pageC

        // console.log(`RecommendationCard.frameMeetingTimings IDX ${start} - ${end}`)

        this.finalMeetingTimings = []
        for (let i = start; i < end; i++) {
          const meetingTiming = this.recommendation.allMeetings[i]
          if (meetingTiming) {
            this.finalMeetingTimings.push(meetingTiming)
          }
        }
      } else {
        this.finalMeetingTimings = []
      }
      this.loadingMeetings = false
    },
    prevPageMeetingTimings () {
      if (this.meetingTimingsPageNum === 0) {
        this.meetingTimingsPageNum = this.totalMeetingTimingsPages - 1
      } else {
        this.meetingTimingsPageNum = this.meetingTimingsPageNum - 1
      }
      this.frameMeetingTimings()
    },
    nextPageMeetingTimings () {
      if (!this.meetingTimingsShown) {
        this.meetingTimingsShown = true
      }
      if (this.meetingTimingsPageNum === this.totalMeetingTimingsPages - 1) {
        this.meetingTimingsPageNum = 0
      } else {
        this.meetingTimingsPageNum = this.meetingTimingsPageNum + 1
      }
      this.frameMeetingTimings()
    },
    closeMeetingTimings () {
      this.frameMeetingTimings()
    },
    frameFreeTimings () {
      const remainingItems = this.recommendation.freeTimings.length - 3
      this.totalFreeTimingsPages = Math.ceil((0.0 + remainingItems || 0) / 5) + 1
      this.finalFreeTimings = []
      const endIndex = 2 + 5 * this.freeTimingsPageNum
      const startIndex = this.freeTimingsPageNum === 0 ? 0 : endIndex - 4
      for (let i = startIndex; i <= endIndex; i++) {
        const freeTiming = this.recommendation.freeTimings[i]
        if (freeTiming) {
          this.finalFreeTimings.push(freeTiming)
        }
      }
    },
    prevPageFreeTimings () {
      if (this.freeTimingsPageNum === 0) {
        this.freeTimingsPageNum = this.totalFreeTimingsPages - 1
      } else {
        this.freeTimingsPageNum = this.freeTimingsPageNum - 1
      }
      this.frameFreeTimings()
    },
    nextPageFreeTimings () {
      if (!this.freeTimingsShown) {
        this.freeTimingsShown = true
      }
      if (this.freeTimingsPageNum === this.totalFreeTimingsPages - 1) {
        this.freeTimingsPageNum = 0
      } else {
        this.freeTimingsPageNum = this.freeTimingsPageNum + 1
      }
      this.frameFreeTimings()
    },
    closeFreeTimings () {
      this.freeTimingsPageNum = 0
      this.frameFreeTimings()
    },
    closeBookMeeting () {
      this.bookingTime = false
    },
    setBookingTime (time) {
      if (!this.bookingTime) {
        this.bookingTime = time
        this.closeFreeTimings()
      }
      this.meetingNameText = 'Catch up: ' + getFirstNameAndInitial(this.getProfile(this.recommendation.userId)) + ' & ' + getFirstNameAndInitial(this.profile)
    },
    async bookMeeting () {
      // console.log('recommendationCard.bookMeeting', this.bookingTime, this.meetingNameText)
      if (!this.meetingNameText) {
        this.errorMsg = 'Enter meeting subject'
        return
      }
      if (this.bookingTime && this.meetingNameText) {
        this.errorMsg = ''
        this.bookingInprogress = true
        const time = this.bookingTime
        const bookingHour = parseInt(time.split(':')[0])
        const bookingMin = time.split(':')[1]

        const userId = this.profile.id
        const calId = this.defaultCalendar?.calendarId || false
        const recDate = this.recommendation.date.toDate ? this.recommendation.date.toDate() : this.recommendation.date
        const start = setTime(recDate, bookingHour, bookingMin).format('YYYY-MM-DD[T]HH:mm:ssZ')
        const end = setTime(recDate, bookingHour, bookingMin).add(30, 'minutes').format('YYYY-MM-DD[T]HH:mm:ssZ')
        const timeZone = this.profile.timezone
        if (this.defaultCalendar) {
          try {
            if (this.defaultCalendar.type === 'MS') {
              const event = {
                subject: this.meetingNameText || 'Duome connect',
                start: {
                  dateTime: start,
                  timeZone
                },
                end: {
                  dateTime: end,
                  timeZone
                },
                attendees: [
                  {
                    emailAddress: {
                      address: this.profile.msEmail || this.profile.email,
                      name: this.profile.displayName
                    },
                    status: {
                      response: 'accepted'
                    }
                  },
                  {
                    emailAddress: {
                      address: this.recommendationUserProfile.msEmail || this.recommendationUserProfile.googleEmail || this.recommendationUserProfile.email,
                      name: this.recommendationUserProfile.displayName
                    }
                  }
                ]
              }
              const params = {
                userId,
                calId,
                event
              }
              const msResponse = await calendar('create-event', params)
              console.log('RecommendationCard.bookMeeting.msResponse', msResponse)
              this.loadingMeetings = true
            } else if (this.defaultCalendar.type === 'Google') {
              const event = {
                summary: this.meetingNameText || 'Duome connect',
                start: {
                  dateTime: start,
                  timeZone
                },
                end: {
                  dateTime: end,
                  timeZone
                },
                attendees: [
                  {
                    email: this.profile.googleEmail || this.profile.email,
                    name: this.profile.displayName,
                    responseStatus: 'accepted'
                  },
                  {
                    email: this.recommendationUserProfile.googleEmail || this.recommendationUserProfile.msEmail || this.recommendationUserProfile.email,
                    name: this.recommendationUserProfile.displayName
                  }
                ]
              }
              const params = {
                userId,
                calId,
                event
              }
              const gResponse = await calendar('create-event-google', params)
              console.log('RecommendationCard.bookMeeting.googleResponse', gResponse)
              this.loadingMeetings = true
            }
            this.bookingInprogress = false
            this.bookingSuccess = true
            this.bookingTime = false
            this.recommendation.meetingTime = time
            const recommendationId = this.recommendation.id
            this.updateCurrentUserRecommendation({ preferenceId: this.preferenceId, recommendationId, recommendation: { meetingTime: time } })
          } catch (err) {
            console.error('RecommendationCard.bookMeeting.error', err)
          }
        }
        this.recommendation.freeTimings = []
        this.freeTimingsPageNum = 0
        this.finalFreeTimings = []
        this.totalFreeTimingsPages = 0
        this.meetingNameText = ''
        setTimeout(() => {
          this.bookingSuccess = false
        }, 3000)
      }
    },
    pauseNotification () {
      this.$emit('pauseNotification', !this.recommendation.pauseNotification)
    },
    redirectToInitiative (id) {
      this.$router.push({ name: 'InitiativeDetails', params: { id } })
    },
    redirectCalendar () {
      window.open(this.defaultCalendarLink)
    }
  },
  watch: {
    'recommendation.allMeetings': {
      handler () {
        if (this.recommendation.allMeetings) {
          this.frameMeetingTimings()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
$circle-width: 0.09em;
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.rec-card-wrapper {
  background: #FFFFFF;
  border: 0.3px solid #ADBCCD;
  border-radius: 4px;
  max-width: 143px;
  max-height: 193px;
  min-width: 143px;
  min-height: 193px;
  .sucess-booking {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    .mdc-text {
      font-weight: 600;
      margin: 0 17px 20px 17px;
      text-align: center;
    }
    .icon-wrapper {
      height: 44px;
      width: 44px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .selected-timing {
    display: flex;
    flex-direction: column;
    .close-icon {
      margin-left: auto;
      margin-right: 10px;
      margin-top: 10px;
      height: 13px;
      width: 13px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .slot-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .slot-type {
        font-weight: 400;
        font-size: 8px;
        color: #404040;
      }
      &.meeting-time-selected {
        margin-bottom: 20px;
        .slot-text {}
        .selected-time-slot {
          color: #6184AD;
          font-weight: 600;
        }
      }
      &.meeting-name-details {
        .meeting-name-text {}
        .meeting-name-input {
          width: 80px;
          font-size: 10px;
          color: #404040;
          font-weight: 400;
          border: 0.5px solid #9CA4AE;
          border-radius: 2px;
          text-align: left;
          margin: 0 20px;
          max-width: 100px;
          resize: none;
          padding: 5px 10px;
          max-height: 35px;
          &::placeholder {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
          }
          &.error {
            border: 0.5px solid red;
          }
        }
      }
    }
    .send-invite-btn {
      background: #6184AD;
      border-radius: 20px;
      width: 107px;
      height: 30px;
      margin: auto;
      margin-top: 17px;
      text-transform: none;
      color: #fff;
      font-size: 12px;
    }
  }
  .more-free-timings {
    display: flex;
    flex-direction: column;
    .close-icon {
      margin-left: auto;
      margin-right: 10px;
      margin-top: 10px;
      height: 13px;
      width: 13px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .list-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .more-slots-text {
        font-size: 8px;
        margin-bottom: 7px;
      }
      .free-timings-wrapper {
        height: unset;
        max-height: unset;
      }
    }
  }
  &.paused {
    > *:not(.pause-notify) {
      opacity: 0.5;
    }
  }

  .loader {
    animation: spin 1s linear infinite;
    border-top: 2px solid transparent;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-left:10px;
    border: 2px solid #4175B1;
    border-top: 2px solid transparent;
    &.invite {
      border: 2px solid #fff;
      border-top: 2px solid transparent;
    }
  }

  .pause-notify {
    z-index: 200;
    position: absolute;
    right: 0;
    padding: 5px;
    display: none;
    .tool-tip {
      display: none;
    }
    .tick {
    }
    &:hover {
      > .tool-tip {
        display: flex;
        bottom: 0;
        transform: translate(-45px, -20px);
        flex-direction: column;
        width: 100px;
      }
    }
  }
  &.meeting-clicked {
    border-radius: 4px;
    background: white;
    transform: scale(1.04, 1.065);
    transition: all 0.3s ease-out;
  }
  &.hover-present {
    &:hover {
      border-radius: 4px;
      background: white;
      transform: scale(1.04, 1.065);
      transition: all 0.3s ease-out;
      .pause-notify {
        display: block;
      }
      .location-name {
        pointer-events: unset;
        &.hoverEffect {
          &:hover {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100px;
            max-height: 100px;
            .loc-name {
              .building-name, i {
                max-width: unset;
                white-space: pre-wrap;
                -webkit-line-clamp: unset;
              }
            }
          }
        }
      }
    }
  }
  .location-name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    justify-content: flex-start;
    background: #ADBCCD;
    border-radius: 0 0 3px 3px;
    min-height: 36px;
    pointer-events: none;
    transition: height .2s ease-in;
    height: 0;
    .icon-wrapper {
      margin-left: 10px;
    }
    .loc-name {
      display: flex;
      flex-direction: column;
    }
    .mdc-text {
      color: #fff;
      font-weight: 600;
      line-height: 12px;
      margin: 5px 8px;
      font-size: 10px;
      .building-name {
        max-width: 115px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &.only-building {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: pre-wrap;
        }
      }
      i {
        font-weight: 400;
        white-space: nowrap;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &.initiative-rec {
    position: relative;
    display: flex;
    flex-direction: column;
    .init-rocket {
      position: absolute;
      margin: 5px;
    }
    .init-name {
      text-align: center;
      min-height: 36px;
      justify-content: center;
      display: flex;
      max-height: 36px;
      .initiative-name {
        color: #2B8AFB;
        font-size: 14px;
        font-weight: 600;
        line-height: unset;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        margin: 5px 5px 0 5px;
        &:before {
          color: #2B8AFB;
          font-size: 14px;
          font-weight: 200;
        }
        .team-name {
          font-size: 14px;
          font-weight: 200;
          max-width: 125px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .name-text {
          max-width: 125px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .svg-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 9px;
    }
    .members-list {
      margin: 7px 0 5px 0;
      min-height: 18px;
      .VueCarousel {
        margin: 0 10px;
        &.one-page {
          .VueCarousel-inner {
            justify-content: center;
          }
        }
        .VueCarousel-inner {
          .profile-redirect {
            margin: 2px 0px 2px 2px;
            .photo-wrapper {
              margin: 2px;
            }
            .profile-schedule {
              &.profile-initials {
                margin: 2px;
              }
            }
          }
        }
        .VueCarousel-navigation {
          display: none;
          .VueCarousel-navigation--disabled {
            display: none !important;
          }
          .VueCarousel-navigation-button {
            padding: 0 !important;
            margin: 0 !important;
            top: 27%;
            &.VueCarousel-navigation-next {
              right: 3px;
              &::before {
                content: '';
                position: absolute;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 55.73%, rgba(255, 255, 255, 0.7) 92%);
                height: 23px;
                width: 24px;
                right: 11px;
                top: -2px;
                pointer-events: none;
              }
            }
            &.VueCarousel-navigation-prev {
              left: 1px;
              &::after {
                content: '';
                position: absolute;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.5) 20%, rgba(255, 255, 255, 0.3) 55.73%, rgba(255, 255, 255, 0) 100%);
                height: 23px;
                width: 21px;
                left: 9px;
                top: -2px;
                pointer-events: none;
                border-radius: 50%;
              }
            }
          }
        }
        .VueCarousel-pagination {
          .VueCarousel-dot-container {
            margin-top: -5px !important;
            .VueCarousel-dot {
              &--active {
                background-color: #6184AD !important;
              }
            }
          }
        }
        &:hover {
          .VueCarousel-navigation {
            display: block;
          }
          .VueCarousel-slide {
            &.every-four {
              &::after {
                content: '';
                position: absolute;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 55.73%);
                height: 22px;
                width: 22px;
                right: 4px;
              }
            }
          }
        }
      }
    }
  }
  &.people-rec {
    position: relative;
    display: flex;
    flex-direction: column;
    .free-time-text,
    .meet-time-text,
    .time-text {
      font-size: 8px;
      color: #404040;
      font-weight: 400;
      text-align: center;
      line-height: 15px;
      min-height: 15px;
      &.loading {
        margin-top:30px;
        margin-bottom:33px;
        font-weight:200;
        font-size:30px;
        opacity:0.5;
        color: #959EA7;
      }
    }
    .meeting-timings-wrapper {
      display: flex;
      justify-content: center;
      margin: 4px 0;
      height: 100%;
      max-height: 68px;
      .nav-arrow {
        align-self: flex-end;
        margin: 5px 13px;
        &.hide {
          visibility: hidden;
        }
      }
      .timings-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .meeting-time {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #4175B1;
          margin-bottom: 5px;
          text-align: center;
          &:last-of-type {
            margin-bottom: 0;
          }
          &.past {
            color: #959EA7;
          }
        }
      }
    }
    .free-timings-wrapper {
      display: flex;
      justify-content: center;
      margin: 4px 0;
      height: 100%;
      max-height: 68px;
      .no-slots {
        font-size: 12px;
        margin: auto;
        position: relative;
        top: -5px;
        font-weight: 700;
        color: #4175B1;
      }
      .nav-arrow {
        align-self: flex-end;
        margin: 5px 13px;
        &.hide {
          visibility: hidden;
        }
      }
      .timings-wrapper {
        display: flex;
        flex-direction: column;
        .free-time {
          border: 0.5px solid #6184AD;
          border-radius: 20px;
          min-width: 56px;
          min-height: 18px;
          max-width: 56px;
          max-height: 18px;
          text-align: center;
          font-size: 8px;
          margin: 0;
          margin-bottom: 3px;
          line-height: 18px;
          color: #6184AD;
          font-weight: 700;
          &.disabled {
            color: #9ea4ac;
            cursor: progress;
          }
          &:hover {
            color:#fff;
            background: #6184AD;
          }
        }
      }
    }
    .time-wrapper {
      display: flex;
      align-items: center;
      margin-top: 25px;
      justify-content: center;
      &.connect {
        position: relative;
        cursor: pointer;
        .icon-wrapper {
          position: absolute;
        }
      }
      .meeting-time {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #4175B1;
        &.connect-text {
          font-size: 12px;
          color: #6184AD;
          font-weight: 300;
        }
        &.no-time {
          color: #959EA7;
          font-size: 30px;
          font-weight: 400;
        }
      }
    }
    .member-initials {
      display: flex;
      flex-direction: column;
      max-height: unset;
      align-items: center;
      justify-content: center;
      width: unset;
      margin: 10px 10px 0px 10px;
      .profile-schedule {
        &:before {
          width: 32px;
          height: 32px;
          padding: 0;
        }
        &.profile-initials {
          width: 30px;
          height: 30px;
          background-size: 25px;
          font-size: 12px;
        }
        &.photo-wrapper {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
      .profile-display-name {
        color: #404040;
        font-weight: 700;
        font-size: 12px;
        margin: unset;
        margin-top: 10px;
        max-width: 125px;
      }
    }
    .book-meeting-button {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #6184AD;
      text-transform: initial;
      width: 110px;
      height: 30px;
      margin: auto;
      margin-top: 20px;
      background: #FFFFFF;
      border: 0.5px solid #6184AD;
      border-radius: 20px;
      &:hover {
        background: #4175B1;
        color: #FFFFFF;
      }
      &.check-avail {
        width: 114px;
      }
    }
  }
}
</style>
