import { colorNameToHex } from './colors'

const randomColour = () => {
  const cardColours = ['cyan', 'magenta', 'orange', 'yellow']
  const rnd = Math.floor(Math.random() * (4))
  return cardColours[rnd]
}

export const initializeProfile = (profile, user) => {
  let updated = false
  if (profile.showDragHelpText === undefined) {
    profile.showDragHelpText = true
    updated = true
  }
  if (profile.teamExpanded === undefined) {
    profile.teamExpanded = true
    updated = true
  }
  if (!profile.emailVerified && user.emailVerified) {
    profile.emailVerified = true
    updated = true
  }
  if (!profile.id) {
    profile.id = user.uid
    updated = true
  }
  if (profile.cookiesPerf === undefined) {
    profile.cookiesPerf = true
    updated = true
  }
  if (profile.cookiesFunc === undefined) {
    profile.cookiesFunc = true
    updated = true
  }
  if (!profile.email) {
    profile.email = user.email
    updated = true
  }
  if (!profile.industry) {
    profile.industry = ''
    updated = true
  }
  if (!profile.displayName) {
    profile.displayName = user.displayName
    updated = true
  }
  if (!profile.firstName) {
    const names = profile.displayName.split(' ')
    profile.firstName = names[0]
    if (names.length > 1) profile.lastName = names[1]
    updated = true
  }
  if (!profile.photoURL) {
    if (user.photoURL) {
      profile.photoURL = user.photoURL
    }
    updated = true
  }
  if (!profile.cardDesign) {
    profile.cardDesign = 'profile-colour-' + randomColour()
    updated = true
  }
  if (!profile.cardDesignBackground) {
    profile.cardDesignBackground = 'profile-bg-bottom'
    updated = true
  }
  if (!profile.view) {
    profile.view = 'public'
    updated = true
  }
  if (!profile.title) {
    profile.title = { role: '' }
    updated = true
  }
  if (!profile.skills) {
    profile.skills = []
    updated = true
  }
  if (!profile.roles) {
    profile.roles = []
    updated = true
  }
  if (!profile.notes) {
    profile.notes = []
    updated = true
  }
  if (!profile.bookmarks) {
    profile.bookmarks = {}
    updated = true
  }
  if (!profile.coverImage) {
    profile.coverImage = '/static/img/cover-image/pattern4.jpg'
    updated = true
  }
  if (typeof profile.profileChemistry === 'undefined') {
    profile.profileChemistry = true
    updated = true
  }
  if (typeof profile.experienceCompleted === 'undefined') {
    profile.experienceCompleted = false
    updated = true
  }
  if (typeof profile.basicCompleted === 'undefined') {
    profile.basicCompleted = false
    updated = true
  }
  if (typeof profile.hotspotClicked === 'undefined') {
    profile.hotspotClicked = {}
    updated = true
  }
  if (typeof profile.hotspotSeen === 'undefined') {
    profile.hotspotSeen = {}
    updated = true
  }
  if (!profile.lastActive) {
    profile.lastActive = new Date()
    updated = true
  }
  if (typeof profile.interest === 'undefined') {
    profile.interest = []
    updated = true
  }
  if (typeof profile.profileAlerts === 'undefined') {
    profile.profileAlerts = true
    updated = true
  }
  if (typeof profile.managerEmail === 'undefined') {
    profile.managerEmail = ''
    updated = true
  }
  if (typeof profile.socialGroups === 'undefined') {
    profile.socialGroups = []
    updated = true
  }
  if (!profile.currentStatus) {
    profile.currentStatus = 'Not Set'
    updated = true
  }
  if (typeof profile.initiatives === 'undefined') {
    profile.initiatives = []
    updated = true
  }
  if (typeof profile.teams === 'undefined') {
    profile.teams = []
    updated = true
  }
  if (typeof profile.calendarColors === 'undefined') {
    profile.calendarColors = {}
    if (profile.calendarsSelected) {
      for (const cal of profile.calendarsSelected) {
        if (!profile.calendarColors[cal.calendarId]) {
          if (cal.hexColor) {
            profile.calendarColors[cal.calendarId] = cal.hexColor
          } else if (cal.color) {
            const hexValue = colorNameToHex(cal.color)
            if (hexValue) {
              profile.calendarColors[cal.calendarId] = hexValue
            }
          }
        }
      }
    }
    if (profile.googleCalendarsSelected) {
      for (const cal of profile.googleCalendarsSelected) {
        if (!profile.calendarColors[cal.calendarId]) {
          if (cal.hexColor) {
            profile.calendarColors[cal.calendarId] = cal.hexColor
          } else if (cal.color) {
            const hexValue = colorNameToHex(cal.color)
            if (hexValue) {
              profile.calendarColors[cal.calendarId] = hexValue
            }
          }
        }
      }
    }
    updated = true
  }
  if (typeof profile.notificationsSettings === 'undefined') {
    profile.notificationsSettings = {
      notifyAbout: 'all',
      notifyAboutFields: {
        locationupdates: ['app', 'email'],
        mentions: ['app', 'email'],
        notes: ['app', 'email'],
        tasks: ['app', 'email'],
        teamupdates: ['app', 'email']
      },
      notifyWhen: 'anytime',
      notifyWhenFields: {
        notifyWhenDays: {
          MON: true,
          TUE: true,
          WED: true,
          THU: true,
          FRI: true,
          SAT: true,
          SUN: true
        },
        notifyWhenTime: {
          from: '09:00',
          to: '17:00'
        },
        notifyWhenTimeUTC: {
          from: '08:00',
          to: '16:00'
        }
      },
      updatedOn: new Date()
    }
    updated = true
  }
  return updated
}

export const getFirstNameAndInitial = (profile) => {
  let firstName = ''
  let initialLast = ''
  let displayNameParts = ''
  // console.log('ProfileInitials.getInitials', profile)
  if (profile) {
    displayNameParts = `${profile.displayName}`.split(' ')
    if (profile.firstName) {
      firstName = profile.firstName
    } else {
      firstName = displayNameParts[0]
    }
    if (profile.lastName) {
      initialLast = profile.lastName.substring(0, 1)
    } else {
      if (displayNameParts.length > 1) {
        initialLast = displayNameParts[1].substring(0, 1)
      }
    }
    return `${firstName} ${initialLast.toUpperCase()}`
  }
  return ''
}

export const getResponseIconName = (response) => {
  if (response && response !== null && response !== '' && response !== undefined) {
    switch (response.toLowerCase()) {
      case 'home':
        return 'home'
      case 'office':
        return 'office'
      case 'nonworking':
        return 'nonworking'
      case 'notset':
        return ''
      case 'thirdspace':
        return 'thirdspace'
      default:
        return ''
    }
  }
}

export const getNameInitials = (name) => {
  if (name !== '' && name) {
    const nameSplit = name.split(' ')
    if (nameSplit.length === 1) {
      return nameSplit[0].substring(0, 1)
    } else if (nameSplit.length > 1) {
      return nameSplit[0].substring(0, 1) + nameSplit[1].substring(0, 1)
    } else {
      return name.substring(0, 1)
    }
  }
}
