<template>
  <div class="planned-unplanned-tasks-wrapper">
    <section class="task-type-headers">
      <mdc-button :class="taskType === 'unplanned' ? 'clicked' : ''" class="unplanned-header header-link" @click.native="setTaskType('unplanned')">
        <mdc-text tag="span" typo="body2">UNPLANNED</mdc-text>
      </mdc-button>
      <mdc-button :class="taskType === 'planned' ? 'clicked' : ''" class="planned-header header-link" @click.native="setTaskType('planned')">
        <mdc-text tag="span" typo="body2">PLANNED</mdc-text>
      </mdc-button>
    </section>
    <section class="tasks-categories-tabs" v-if="taskType === 'unplanned'">
      <div class="mobile-vertical categories-types">
        <div class="category-type-counts all-type">
          <mdc-button @click.native="categorySelection('all')" :class="{ clicked: selectedCategory === 'all'}" class="category-button all-type">
            <mdc-text tag="span" typo="body2">All</mdc-text>
          </mdc-button>
          <mdc-text tag="span" typo="caption" class="count">{{allTasks}}</mdc-text>
        </div>
        <div class="category-type-counts assigned">
          <mdc-button @click.native="categorySelection('assigned')" :class="{ clicked: selectedCategory === 'assigned'}" class="category-button assigned-type">
            <mdc-text tag="span" typo="body2">Assigned to me</mdc-text>
          </mdc-button>
          <mdc-text tag="span" typo="caption" class="count">{{assignedTasks}}</mdc-text>
        </div>
        <div class="category-type-counts created">
          <mdc-button @click.native="categorySelection('created')" :class="{ clicked: selectedCategory === 'created'}" class="category-button created-type">
            <mdc-text tag="span" typo="body2">Created by me</mdc-text>
          </mdc-button>
          <mdc-text tag="span" typo="caption" class="count">{{createdTasks}}</mdc-text>
        </div>
      </div>
    </section>
    <section v-if="taskType === 'unplanned'" class="all-tasks-section">
      <task-group
        :dragClone="true"
        dropFunction="updateWhen"
        selfAssign
        showTaskTabs
        :tasks="unplannedTasks"
      ></task-group>
    </section>
    <section v-if="taskType === 'planned'" class="planned-all-tasks-section">
      <template v-if="plannedTasks.length > 0 && !tasksLoading">
        <template v-for="(taskGroup, dateKey) in plannedTasksGrouped" >
          <div class="group-row" :key="'group-' + dateKey">
            <div class="group-header" >
              <mdc-text class="planned-date" tag="span" typo="caption">{{dateKey}}</mdc-text>
            </div>
            <task-group
              :dragClone="true"
              :dropFunction="taskGroup.assignDate ? 'updateWhen' : false"
              :assignDate="taskGroup.assignDate"
              disableAdd
              selfAssign
              showTaskTabs
              class="planned-task-group"
              :tasks="taskGroup.tasks"
              v-if="plannedTasks.length > 0"
            ></task-group>
          </div>
        </template>
      </template>
      <template v-else-if="tasksLoading">
        <mdc-text tag="span" typo="body2" class="tasks-loading">Loading...</mdc-text>
      </template>
      <template v-else>
        <mdc-text tag="span" typo="body1" class="no-planned-tasks">No planned tasks</mdc-text>
      </template>
    </section>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import TaskGroup from './TaskGroup'
import { inPast } from '../../utils/time'

export default {
  name: 'PlannedUnplannedTasks',
  props: {
    date: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    TaskGroup
  },
  mounted () {
    this.getFilteredCategoryOfTasks('all')
  },
  beforeDestroy () {
  },
  watch: {
    tasks: {
      handler () {
        this.getFilteredTasks(this.taskType)
        this.getFilteredCategoryOfTasks(this.selectedCategory)
      }
    },
    taskPlans () {
      // console.log('Scheduler.watch.taskPlans')
      if (this.taskPlans.length) {
        this.getFilteredTasks(this.taskType)
        this.getFilteredCategoryOfTasks(this.selectedCategory)
      }
    },
    scheduleDate: {
      handler () {
        this.getFilteredTasks('unplanned')
      },
      immediate: true
    },
    selectedCategory: {
      handler () {
        this.getFilteredCategoryOfTasks(this.selectedCategory)
      }
    },
    taskType: {
      handler () {
        this.getFilteredTasks(this.taskType)
      }
    },
    typeFilteredTasks: {
      handler () {
        this.getFilteredCategoryOfTasks(this.selectedCategory)
      }
    }
  },
  methods: {
    ...mapActions({
      getTaskPlan: 'teams/getTaskPlan'
    }),
    isCreated (task) {
      // console.log('isCreated', task.text, task.userId, task.shareUsers, task.shareInitiatives)
      if (task.userId === this.profile.id) {
        return !this.isAssigned(task) && !this.isPrivate(task)
      }
      return false
    },
    isPrivate (task) {
      // console.log('isPrivate', task.text, task.userId, task.selfAssignedTo, task.shareUsers, task.shareInitiatives)
      if (task.userId === this.profile.id) {
        if (task.selfAssignedTo === this.profile.id) {
          // Task created by user and set as selfAssigned
          if (!task.shareUsers || (task.shareUsers && task.shareUsers.length === 0)) {
            if (!task.shareInitiatives || (task.shareInitiatives && task.shareInitiatives.length === 0)) {
              // Task not shared with an any other users or initiatives
              return true
            }
          }
        }
      }
      return false
    },
    isAssigned (task) {
      if (task.shareUsers && task.shareUsers.includes(this.profile.id)) {
        if (!this.isPrivate(task)) {
          return true
        }
      }
      return false
    },
    getFilteredCategoryOfTasks (selectedCategory) {
      switch (selectedCategory) {
        case 'all':
          this.unplannedTasks = this.typeFilteredTasks
          break
        case 'created': {
          // if tag or assigned ids present and its created by me(userId)
          const created = this.typeFilteredTasks.filter((task) => {
            return this.isCreated(task)
          })
          // if tag or assigned ids are not there and selfassigned(while creating)
          const privateTasks = this.typeFilteredTasks.filter(task => {
            return this.isPrivate(task)
          })
          this.unplannedTasks = [...created, ...privateTasks]
          break
        }
        case 'assigned':
        default:
          // if assigned by anyone else and not self assigned
          this.unplannedTasks = this.typeFilteredTasks.filter((task) => {
            return this.isAssigned(task)
          })
          break
      }
    },
    async getFilteredTasks (taskType) {
      this.tasksLoading = true
      // console.log('PlannedUnplannedTasks.getFilteredTasks', taskType)
      const plannedTasks = this.tasks.filter((task) => {
        return (task.isPlanPresent && task.isPlanPresent[this.profile.id]) && task.text !== '<p></p>' && this.isMyTask(task) && !task.completed
      })
      plannedTasks.sort((x, y) => {
        if (x.completed === y.completed) {
          return x.createdOn < y.createdOn ? 1 : -1
        } else {
          return (x.completed === y.completed) ? 0 : x.completed ? 1 : -1
        }
      })
      const plannedMap = {}
      for (const plannedTask of plannedTasks) {
        const taskPlan = await this.getTaskPlan({ teamId: this.profile.team, userId: this.profile.id, taskId: plannedTask.id })
        if (taskPlan) {
          plannedTask.plan = taskPlan[0]
          let plannedDate
          let assignDate
          if (taskPlan[0].type === 'someday') {
            plannedTask.planDate = 'some day'
            assignDate = false
            plannedDate = 'some day'
          } else if (taskPlan[0].type === 'remote') {
            plannedTask.planDate = this.getNextRemote()
            plannedTask.scheduleDate = plannedTask.planDate
            if (plannedTask.planDate !== 'someday' && plannedTask.planDate !== 'next at home') {
              plannedDate = this.getDateFormat(plannedTask.planDate)
              assignDate = moment(plannedTask.planDate)
            } else {
              if (plannedTask.planDate === 'someday') {
                plannedDate = 'some day'
                assignDate = false
              } else {
                plannedDate = plannedTask.planDate
                assignDate = plannedTask.planDate
              }
            }
          } else if (taskPlan[0].type === 'office') {
            plannedTask.planDate = this.getNextOffice()
            plannedTask.scheduleDate = plannedTask.planDate
            if (plannedTask.planDate !== 'someday' && plannedTask.planDate !== 'next at office') {
              plannedDate = this.getDateFormat(plannedTask.planDate)
              assignDate = moment(plannedTask.planDate)
            } else {
              if (plannedTask.planDate === 'someday') {
                plannedDate = 'some day'
                assignDate = false
              } else {
                plannedDate = plannedTask.planDate
                assignDate = plannedTask.planDate
              }
            }
          } else {
            if (taskPlan[0].planDate) {
              if (inPast(taskPlan[0].planDate.toDate())) {
                plannedTask.planDate = new Date()
                plannedTask.scheduleDate = plannedTask.planDate
                plannedDate = this.getDateFormat(plannedTask.planDate)
                assignDate = moment(plannedTask.planDate)
              } else {
                plannedTask.planDate = taskPlan[0].planDate.toDate()
                plannedTask.scheduleDate = plannedTask.planDate
                plannedDate = this.getDateFormat(plannedTask.planDate)
                assignDate = moment(plannedTask.planDate)
              }
            }
          }
          if (plannedMap[plannedDate]) {
            plannedMap[plannedDate].tasks.push(plannedTask)
          } else {
            plannedMap[plannedDate] = { tasks: [plannedTask], assignDate }
          }
        }
      }
      this.plannedTasks = plannedTasks
      this.plannedTasksGrouped = plannedMap
      // console.log(this.plannedTasksGrouped)
      this.$emit('passPlannedTasks', this.plannedTasks)
      this.unplannedTasks = this.tasks.filter((task) => {
        return (task.isPlanPresent && !task.isPlanPresent[this.profile.id]) &&
        task.text !== '<p></p>' && this.isMyTask(task) && !task.completed
      })
      this.unplannedTasks.sort((x, y) => {
        if (x.completed === y.completed) {
          return x.createdOn < y.createdOn ? 1 : -1
        } else {
          return (x.completed === y.completed) ? 0 : x.completed ? 1 : -1
        }
      })
      this.typeFilteredTasks = [...this.unplannedTasks]
      // AssignedTasks - includes tasks where user is assigned or self assigned
      const assignedTasks = this.typeFilteredTasks.filter((task) => {
        return this.isAssigned(task)
      })
      this.assignedTasks = assignedTasks.length

      // CreatedTasks - excludes created by self assigned tasks
      const createdTasks = this.typeFilteredTasks.filter((task) => {
        return this.isCreated(task)
      })
      this.createdTasks = createdTasks.length
      // PrivateTasks - has no share users  or initiative shares and self assigned
      const privateTasks = this.typeFilteredTasks.filter(task => {
        return this.isPrivate(task)
      })
      this.privateTasks = privateTasks.length
      this.allTasks = this.typeFilteredTasks?.length
      this.createdTasks = this.createdTasks + this.privateTasks
      this.tasksLoading = false
      this.$emit('passUnplannedTasks', this.unplannedTasks)
    },
    getNextRemote () {
      let nextDate = 'next at home'
      for (const schedule of this.mySchedules) {
        if (schedule.response === 'home' || schedule.response === 'thirdspace') {
          const scheduleDate = schedule.date.toDate()
          if (!inPast(scheduleDate)) {
            nextDate = scheduleDate
            break
          }
        }
      }
      return nextDate
    },
    getNextOffice () {
      let nextDate = 'next at office'
      for (const schedule of this.mySchedules) {
        if (schedule.response === 'office') {
          const scheduleDate = schedule.date.toDate()
          if (!inPast(scheduleDate)) {
            nextDate = scheduleDate
            break
          }
        }
      }
      return nextDate
    },
    getDateFormat (date) {
      return moment(date).format('DD MMM')
    },
    isMyTask (task) {
      return task.selfAssignedTo === this.profile.id || task.userId === this.profile.id || task.shareUsers.includes(this.profile.id)
    },
    closeTempo () {
      this.$root.$emit('changeComposerView', 'default', 'COMPOSER')
    },
    categorySelection (category) {
      this.selectedCategory = category
    },
    setTaskType (type) {
      this.taskType = type
    }
  },
  computed: {
    ...mapState({
      tasks: state => state.teams.tasks,
      taskPlans: state => state.teams.taskPlans,
      initiatives: state => state.teams.initiatives,
      schedules: state => state.teams.schedules
    }),
    ...mapState([
      'profile'
    ]),
    mySchedules () {
      return this.schedules.filter((schedule) => schedule.fromId === this.profile.id)
    }
  },
  data () {
    return {
      tasksLoading: false,
      selectedCategory: 'all',
      typeFilteredTasks: [],
      taskType: 'unplanned',
      plannedTasks: [],
      unplannedTasks: [],
      plannedTasksGrouped: {},
      createdTasks: 0,
      privateTasks: 0,
      assignedTasks: 0,
      allTasks: 0
    }
  }
}
</script>
<style lang="scss">
.planned-unplanned-tasks-wrapper {
  margin-top: 30px;
  &.fullscreen-mode {
    max-width: 720px;
  }
  padding: 0 25px;
  .task-type-headers {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 0.5px solid #CAD1D8;
    padding-bottom: 4px;
    .header-type {
      display: flex;
      align-items: center;
      margin-right: 10px;
      .icon-wrapper {
        margin: 0 5px 0 10px;
        img {
          height: 16px;
          width: 16px;
        }
      }
      .mdc-text {
        font-weight: 700;
        color: #404040;
      }
    }
    .header-link {
      text-transform: initial;
      height: 24px;
      .mdc-text {
        color: #4175B1;
        font-weight: 600;
      }
      &.clicked{
        .mdc-text {
          color: #000;
          font-weight: 700;
        }
      }
    }
  }
  .tasks-categories-tabs {
    position: relative;
    height: 25px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: space-between;
    .categories-types {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      .category-type-counts {
        display: flex;
        align-items: center;
        margin-right: 30px;
        .mdc-button {
          height: 27px;
          white-space: nowrap;
          min-width: max-content;
          .mdc-text {
            color: #4175B1;
            text-transform: none;
            font-weight: 400;
          }
          &.clicked {
            .mdc-text {
              color: #404040;
              font-weight: 600;
            }
          }
        }
        .count {
          border-radius: 50%;
          height: 22px;
          width: 22px;
          text-align: center;
          background: #F9F9F9;
          color: #2B8AFB;
          font-weight: 700;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .closed-task-filter {
      display: flex;
      align-items: center;
      .icon-wrapper {
        margin: 0 5px 0 10px;
        img {
          height: 16px;
          width: 16px;
        }
      }
      .mdc-text {
        color: #404040;
      }
    }
  }
  .all-tasks-section {
    .taskgroup-tasks {
      margin-top: 3px;
      .task-line {
        margin: 0 0 3px 0;
      }
    }
  }
  .planned-all-tasks-section {
    .group-row {
      display:flex;
      width:100%;
      .group-header {
        width:100px;
        text-transform: capitalize;
        color: silver;
        padding:5px;
        .planned-date {
          text-transform: uppercase;
        }
      }
      .planned-task-group {
        flex:1;
        overflow-y: auto;
        margin-bottom: 5px;
        padding: 5px;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #828282;
        }
        .tasks-wrapper {
          .task-line {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
    .tasks-loading {
      margin: auto;
      color: #959EA7;
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
    .no-planned-tasks {
      margin: auto;
      color: #6184AD;
      font-weight: 700;
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
