<template>
  <section class="workspace-locations-wrapper">
    <header v-if="!onboarding" class="type-heading">
      <mdc-text tag="span" typo="body1">Office Locations</mdc-text>
    </header>
    <div class="locations-content type-content">
      <div class="building-fields-wrapper">
        <mdc-button class="add-location" @click.native="openBuildingDetailsInputs" v-if="!buildingDetailsInputsOpened && !onboarding">
          <base-svg-icon name="plus_blue" />
          <mdc-text typo="body1" tag="span">ADD OFFICE LOCATION</mdc-text>
        </mdc-button>
        <div class="add-building-fields" v-else>
          <div class="field-wrapper office-name">
            <span class="office-name-label label">
              <mdc-text tag="span" typo="caption">Office name</mdc-text>
            </span>
            <input
              type="text"
              class="office-name-field field"
              v-model="newBuildingDetails.name"
              placeholder="E.g building name"
            />
          </div>
          <div class="field-wrapper capacity">
            <span class="capacity-label label">
              <mdc-text tag="span" typo="caption">Max. Capacity</mdc-text>
            </span>
            <input
              type="number"
              class="capacity-field field"
              v-model="newBuildingDetails.capacity"
              placeholder="Capacity"
            />
          </div>
          <div class="building-details-actions">
            <mdc-button class="cancel-name-button" v-if="!onboarding"  @click.native="closeBuildingDetailsInputs">
              <mdc-text typo="body1" tag="span">Cancel</mdc-text>
            </mdc-button>
            <mdc-button class="save-name-button" @click.native="saveNewBuildingDetails" :class="buildingInputErrorMsg || buildingInputError || !newBuildingDetails.name ? 'disabled' : ''">
              <mdc-text typo="body1" tag="span">Save</mdc-text>
            </mdc-button>
          </div>
        </div>
        <div class="location-input-error" v-if="buildingInputErrorMsg && buildingDetailsInputsOpened">
          <mdc-text tag="span" typo="caption">{{buildingInputErrorMsg}}</mdc-text>
        </div>
      </div>
      <div class="all-locations-list" v-if="allBuildings && allBuildings.length > 0">
        <div class="location-details-wrapper" v-for="(buildingDetail, index) in allBuildings" :key="buildingDetail.name + index" :class="editingBuildingIndex !== '' && editingBuildingIndex === buildingDetail.id ? 'edit-mode' : 'non-edit-mode'">
          <template v-if="editingBuildingIndex === buildingDetail.id">
            <base-svg-icon name="cross-thin" class="close-edit" clickable @click.native="cancelAllDetailsEdit('close')"/>
            <div class="edit-building-details-wrapper" :class="'edit' + editingBuildingIndex">
              <div class="edit-building-fields" v-if="newBuildingDetails.id && editingBuidingName">
                <div class="field-wrapper office-name">
                  <span class="office-name-label label">
                    <mdc-text tag="span" typo="caption">Office name</mdc-text>
                  </span>
                  <input
                    type="text"
                    class="office-name-field field"
                    v-model="newBuildingDetails.name"
                    ref="officename"
                    placeholder="E.g building name"
                  />
                </div>
                <div class="field-wrapper capacity">
                  <span class="capacity-label label">
                    <mdc-text tag="span" typo="caption">Max. Capacity</mdc-text>
                  </span>
                  <input v-if="buildingFloors(newBuildingDetails.id).length > 0"
                    :readonly="true"
                    class="capacity-field field readonly"
                    :value="getCapacity(newBuildingDetails)"
                  >
                  <input
                    v-else
                    type="number"
                    class="capacity-field field"
                    v-model="newBuildingDetails.capacity"
                    placeholder="Capacity"
                  />
                </div>
              </div>
              <div class="name-capacity building" v-else>
                <mdc-text tag="span" typo="body1" class="office-name">{{ buildingDetail.name }}</mdc-text>
                <base-svg-icon class="edit-building" name="pen-edit" clickable @click.native="openAllDetailsBuildingEdit(buildingDetail)" />
                <mdc-text tag="span" typo="body1" class="office-capacity">
                  <mdc-text tag="span" typo="caption" class="capacity-label">{{detailsText(newBuildingDetails)}}</mdc-text>
                </mdc-text>
              </div>

              <div class="manage-capacity-section" v-if="newBuildingDetails.id">
                <div class="manage-capacity-info">
                <mdc-text tag="div" typo="body1" class="section-header">Manage capacity</mdc-text>
                <mdc-text tag="div" typo="caption">This will control the number of users who can book to work from an office location to its capacity</mdc-text>
                </div>
                <div>
                  <div class="manage-capacity-switch">
                    <mdc-switch @change="setCapacityManage" v-model="newBuildingDetails.capacityManage" class="switch-row">
                    </mdc-switch>
                  </div>
                </div>
              </div>

              <div class="location-input-error edit-building" v-if="buildingInputErrorMsg">
                <mdc-text tag="span" typo="caption">{{buildingInputErrorMsg}}</mdc-text>
              </div>
              <div class="floor-details-wrapper" v-for="(floor, floorIndex) in editingBuildingFloors" :key="floor.name + floorIndex">
                <template v-if="floor.name !== floorEditing.name">
                  <div class="floor-name-capacity" v-if="floor.name || floor.capacity">
                    <mdc-text tag="span" typo="body2" class="floor-details">{{ floor.name }}</mdc-text>
                    <mdc-text tag="span" typo="body2" class="floor-details">{{ floor.capacity ? '' + floor.capacity  : '' }}{{ newBuildingDetails.capacityManage ? ' Desks': '' }}</mdc-text>
                    <div class="areas-names" v-if="floorAreas(floor.id).length > 0">
                      <mdc-text v-for="(area, index) in floorAreas(floor.id)" :key="area.name + index + area.capacity" tag="span" typo="body2" class="area-name"> {{area.name}} {{area.capacity ? '(' + area.capacity + ')' : ''}} </mdc-text>
                    </div>
                  </div>
                  <div class="floor-action-buttons">
                    <div class="remove-menu-wrapper">
                    <base-svg-icon class="edit-floor" name="pen-edit" clickable @click.native="floorEditMode(floor, buildingDetail.id)" />
                    <base-svg-icon name="task-ellipse" @click.native="openFloorMenu(floor, index)" class="floor-menu" clickable/>
                    <app-popup-menu v-if="floorMenuOpenFor === floor.id" :showAppPopupMenu="floorMenuOpenFor === floor.id" @closeAppPopupMenu="floorMenuOpenFor = false">
                      <mdc-text typo="body2" tag="span" class="remove-floor" @click.native="removeFloor(floor, buildingDetail.id)">Delete Floor</mdc-text>
                    </app-popup-menu>
                    </div>
                  </div>
                </template>
              </div>
              <div class="floor-button-fields">
                <div class="add-floor-fields" v-if="addFloorClicked && !editingArea">
                  <div class="field-wrapper floor-name">
                    <span class="floor-name-label label">
                      <mdc-text tag="span" typo="caption">Floor name</mdc-text>
                    </span>
                    <input
                      type="text"
                      class="floor-name-field field"
                      v-model="newFloorDetails.name"
                      placeholder="E.g Floor name"
                    />
                  </div>
                  <div class="field-wrapper floor-capacity">
                    <span class="floor-capacity-label label">
                      <mdc-text tag="span" typo="caption">Capacity</mdc-text>
                    </span>
                    <input
                      :class="floorAreas(newFloorDetails.id).length > 0 ? 'readonly' : ''"
                      :readonly="floorAreas(newFloorDetails.id).length > 0"
                      type="number"
                      class="capacity-field field"
                      v-model="newFloorDetails.capacity"
                      placeholder="Capacity"
                    />
                  </div>
                  <div class="add-area-actions action-buttons">
                    <mdc-button class="add-area" @click.native="addArea()">
                      <base-svg-icon name="plus_blue" />
                      <mdc-text typo="caption" tag="span">Add area</mdc-text>
                    </mdc-button>
                  </div>
                  <!-- <div class="remove-floor-actions" v-if="buildingFloors(buildingDetail.id).length > 0">
                    <mdc-button class="remove-floor" @click.native="closeEditFloor()">
                      <mdc-text typo="caption" tag="span">Cancel</mdc-text>
                    </mdc-button>
                  </div> -->
                </div>
                <div v-else-if="addFloorClicked">
                  <div class="floor-details-wrapper" style="padding-left:0px;">
                  <div class="floor-name-capacity" v-if="newFloorDetails.name || newFloorDetails.capacity">
                    <mdc-text tag="span" typo="body2" class="floor-details"><b>{{ newFloorDetails.name }}</b></mdc-text>
                    <mdc-text tag="span" typo="body2" class="floor-details">{{ newFloorDetails.capacity ? '' + newFloorDetails.capacity : '' }}{{ newBuildingDetails.capacityManage ? ' Desks': '' }}</mdc-text>
                  </div>
                  </div>
                </div>

                <div class="floor-input-error" v-if="floorInputErrorMsg" role="alert" aria-label="floor-error">
                  <mdc-text tag="span" typo="caption">{{floorInputErrorMsg}}</mdc-text>
                </div>
              </div>

              <div class="assigned-desks-section" v-if="floorEditing && !editingArea && floorAreas(newFloorDetails.id).length === 0">
                <div class="assigned-desks-toggle">
                  <div class="assigned-desks-info">
                  <mdc-text tag="div" typo="body1" class="section-header">Assigned desk numbers</mdc-text>
                  <mdc-text tag="div" typo="caption">This enables desk numbers or names to be defined.</mdc-text>
                  </div>
                  <div>
                    <div class="assigned-desks-switch">
                      <mdc-switch @change="setAssignedDesks" v-model="newFloorDetails.assignedDesks" class="switch-row">
                      </mdc-switch>
                    </div>
                  </div>
                </div>
                <div v-if="newFloorDetails.assignedDesks" class="desk-list-header">
                  <div class="desk-row">
                    <mdc-text typo="body2" tag="span">Desk Name
                      <mdc-text v-if="!editingDesks" tag="span" class="edit-desk-link" @click.native="editingDesks = !editingDesks" typo="caption">Edit</mdc-text>
                      <mdc-text v-else tag="span" class="edit-desk-link" @click.native="editingDesks = !editingDesks" typo="caption">Save</mdc-text>
                    </mdc-text>
                    <mdc-text typo="body2" tag="span">Enabled</mdc-text>
                  </div>
                </div>
                <div v-if="newFloorDetails.assignedDesks" class="desk-list">
                  <div class="desk-row" v-for="(desk, index) in newFloorDetails.desks" :key="'desk-' + index">
                    <input
                      v-if="editingDesks"
                      type="text"
                      class="office-name-field field"
                      v-model="desk.name"
                      placeholder="E.g desk name"
                    />
                    <mdc-text v-else typo="body2" tag="span">{{desk.name}}</mdc-text>
                    <div class="desk-enabled-switch">
                      <mdc-switch @change="setDeskEnabled" v-model="desk.enabled" class="switch-row">
                      </mdc-switch>
                    </div>
                  </div>
                </div>
              </div>

              <div class="area-button-fields">
                <div v-if="!editingArea" class="editing-areas">
                  <div class="area-details-wrapper" v-for="(areaDetail, index) in newFloorAreas" :key="areaDetail.name">
                    <div class="area-name-capacity">
                      <mdc-text tag="span" typo="body2" class="area-name"> {{areaDetail.name}}</mdc-text>
                      <mdc-text tag="span" typo="body2" class="area-capacity">{{ areaDetail.capacity ? '' + areaDetail.capacity : '' }}{{ newBuildingDetails.capacityManage ? ' Desks': '' }}</mdc-text>
                    </div>
                    <div class="remove-area-actions">
                      <div class="remove-menu-wrapper">
                      <base-svg-icon class="edit-area" name="pen-edit" clickable @click.native="areaEditMode(areaDetail, buildingDetail.id)" />
                      <base-svg-icon name="task-ellipse" @click.native="openAreaMenu(areaDetail, index)" class="area-menu" clickable/>
                      <app-popup-menu v-if="areaMenuOpenFor === areaDetail.id" :showAppPopupMenu="areaMenuOpenFor === areaDetail.id" @closeAppPopupMenu="areaMenuOpenFor = false">
                        <mdc-text typo="body2" tag="span" class="remove-area" @click.native="removeArea(areaDetail, 'floorArea')">Delete Area</mdc-text>
                      </app-popup-menu>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="add-area-fields" v-for="(areaDetail, index) in newFloorAreas" :key="areaDetail.name">
                    <template v-if="editingArea.id === areaDetail.id">
                    <mdc-text tag="span" typo="caption" class="area-label">{{index + 1 }}.</mdc-text>
                    <div class="field-wrapper area-name">
                      <input
                        type="text"
                        class="area-name-field field"
                        v-model="areaDetail.name"
                        placeholder="E.g Area name"
                      />
                    </div>
                    <div class="field-wrapper area-capacity">
                      <input
                        type="number"
                        class="capacity-field field"
                        v-model="areaDetail.capacity"
                        placeholder="Capacity"
                      />
                    </div>
                    <div class="remove-area-actions">
                      <div class="remove-menu-wrapper">
                      <base-svg-icon name="task-ellipse" @click.native="openAreaMenu(areaDetail, index)" class="area-menu" clickable/>
                      <app-popup-menu v-if="areaMenuOpenFor === areaDetail.id" :showAppPopupMenu="areaMenuOpenFor === areaDetail.id" @closeAppPopupMenu="areaMenuOpenFor = false">
                        <mdc-text typo="body2" tag="span" class="remove-area" @click.native="removeArea(areaDetail, 'floorArea')">Delete Area</mdc-text>
                      </app-popup-menu>
                      </div>
                    </div>
                    </template>
                  </div>
                </div>

                <div class="assigned-desks-section" v-if="editingArea && !addAreaClicked">
                <div class="assigned-desks-toggle">
                  <div class="assigned-desks-info">
                  <mdc-text tag="div" typo="body1" class="section-header">Assigned desk numbers</mdc-text>
                  <mdc-text tag="div" typo="caption">This enables desk numbers or names to be defined.</mdc-text>
                  </div>
                  <div>
                    <div class="assigned-desks-switch">
                      <mdc-switch @change="setAssignedDesksArea" v-model="editingArea.assignedDesks" class="switch-row">
                      </mdc-switch>
                    </div>
                  </div>
                </div>
                <div v-if="editingArea.assignedDesks" class="desk-list-header">
                  <div class="desk-row">
                    <mdc-text typo="body2" tag="span">Desk Name
                      <mdc-text v-if="!editingDesks" tag="span" class="edit-desk-link" @click.native="editingDesks = !editingDesks" typo="caption">Edit</mdc-text>
                      <mdc-text v-else tag="span" class="edit-desk-link" @click.native="editingDesks = !editingDesks" typo="caption">Save</mdc-text>
                    </mdc-text>
                    <mdc-text typo="body2" tag="span">Enabled</mdc-text>
                  </div>
                </div>
                <div v-if="editingArea.assignedDesks" class="desk-list">
                  <div class="desk-row" v-for="(desk, index) in editingArea.desks" :key="'desk-' + index">
                    <input
                      v-if="editingDesks"
                      type="text"
                      class="office-name-field field"
                      v-model="desk.name"
                      placeholder="E.g desk name"
                    />
                    <mdc-text v-else typo="body2" tag="span">{{desk.name}}</mdc-text>
                    <div class="desk-enabled-switch">
                      <mdc-switch @change="setDeskEnabled" v-model="desk.enabled" class="switch-row">
                      </mdc-switch>
                    </div>
                  </div>
                </div>
              </div>

                <div class="add-area-fields" v-if="addAreaClicked">
                  <mdc-text tag="span" typo="caption" class="area-label">Area {{ newFloorAreas.length + 1 }}</mdc-text>
                  <div class="field-wrapper area-name">
                    <input
                      type="text"
                      class="area-name-field field"
                      v-model="newAreaDetails.name"
                      placeholder="E.g Area name"
                    />
                  </div>
                  <div class="field-wrapper area-capacity">
                    <input
                      type="number"
                      class="capacity-field field"
                      v-model="newAreaDetails.capacity"
                      placeholder="Capacity"
                    />
                  </div>
                  <div class="remove-area-actions">
                    <mdc-button class="remove-area" @click.native="removeArea(newAreaDetails, 'new')">
                      <mdc-text typo="caption" tag="span">Remove</mdc-text>
                    </mdc-button>
                  </div>
                </div>
                <div class="area-input-error" v-if="areaInputErrorMsg || areaInputError" role="alert" aria-label="area-error">
                  <mdc-text tag="span" typo="caption">{{areaInputErrorMsg}}</mdc-text>
                </div>
              </div>
              <div class="floor-add">
                <mdc-button v-if="buildingFloors(buildingDetail.id).length > 0 && !addFloorClicked" class="add-floor-button" @click.native="addNewFloor(buildingDetail, index)">
                  <base-svg-icon name="plus_blue" />
                  <mdc-text typo="caption" tag="span">Add floor</mdc-text>
                </mdc-button>
              </div>
              <div class="save-location-actions" v-if="newBuildingDetails.id || addFloorClicked || buildingFloors(buildingDetail.id).length === 0 || deleteFloors.length > 0">
                <mdc-button class="cancel-button" @click.native="cancelAllDetailsEdit()">
                  <mdc-text typo="body2" tag="span">Cancel</mdc-text>
                </mdc-button>
                <mdc-button class="save-button" @click.native="saveAllDetails(buildingDetail, index)">
                  <mdc-text typo="body2" tag="span">Save
                    <template v-if="editingFloor || addFloorClicked"> Floor</template>
                    <template v-else> Location</template>
                  </mdc-text>
                </mdc-button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="name-capacity all-names">
              <mdc-text tag="span" typo="body2" class="office-name">{{ buildingDetail.name }} {{ getCapacity(buildingDetail) ? '(' + getCapacity(buildingDetail) + ')' : '' }}</mdc-text>
              <div v-for="floor in buildingFloors(buildingDetail.id)" :key="floor.name" class="floor-area">
                <mdc-text tag="span" typo="body2" class="floor-name"> {{floor.name}}{{ floorAreas(floor.id).length > 0 ? ',' : '' }}</mdc-text>
                <div class="areas-names" v-if="floorAreas(floor.id).length > 0">
                  <mdc-text v-for="(area) in floorAreas(floor.id)" :key="area.name" tag="span" typo="body2" class="area-name"> {{area.name}} </mdc-text>
                </div>
              </div>
            </div>
            <div class="add-floor-action">
              <mdc-button v-if="buildingFloors(buildingDetail.id).length === 0" class="add-floor-button" @click.native="openAllDetailsEdit(buildingDetail, 'noFloors')">
                <base-svg-icon name="plus_blue" />
                <mdc-text typo="caption" tag="span">Add floor</mdc-text>
              </mdc-button>
              <base-svg-icon name="pen-edit" clickable @click.native="openAllDetailsEdit(buildingDetail)"/>
            </div>
            <div class="remove-menu-wrapper">
              <base-svg-icon name="task-ellipse" @click.native="openBuildingMenu(buildingDetail, index)" class="building-menu" clickable/>
              <app-popup-menu style="width: 200px;" v-if="buildingMenuOpenFor === buildingDetail.id" :showAppPopupMenu="buildingMenuOpenFor === buildingDetail.id" @closeAppPopupMenu="buildingMenuOpenFor = false">
                <mdc-text v-show="!confirmDeleteBuilding" typo="body2" tag="span" class="remove-location" @click.native="confirmDeleteBuilding = true">Delete Building</mdc-text>
                <div style="padding:10px;" v-if="confirmDeleteBuilding">
                  <mdc-text typo="caption" tag="div" class="remove-location-prompt">Delete this entire location?</mdc-text>
                  <mdc-text typo="body2" tag="span" class="cancel-remove-location" @click.native="buildingMenuOpenFor = false">Cancel</mdc-text>
                  <mdc-text typo="body2" tag="span" class="confirm-remove-location" @click.native="deleteBuilding(buildingDetail, index)">Delete</mdc-text>
                </div>
              </app-popup-menu>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from '../flexibly/BaseSvgIcon.vue'
import AppPopupMenu from '../flexibly/AppPopupMenu.vue'
import { uuidv4 } from '../../utils/uid'

export default {
  name: 'WorkspaceLocations',
  components: {
    BaseSvgIcon,
    AppPopupMenu
  },
  props: {
    onboarding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newBuildingDetails: {
        name: '',
        capacity: '',
        capacityManage: false
      },
      buildingInputError: false,
      buildingInputErrorMsg: '',
      editingBuildingIndex: '',
      editingBuildingDetails: {},
      addFloorClicked: false,
      newFloorDetails: {
        name: '',
        capacity: '',
        assignedDesks: false,
        desks: []
      },
      newAreaDetails: {
        name: '',
        capacity: '',
        assignedDesks: false,
        desks: []
      },
      buildingDetailsInputsOpened: false,
      addAreaClicked: false,
      floorInputError: false,
      floorInputErrorMsg: '',
      floorEditing: false,
      newFloorAreas: [],
      areaInputError: false,
      areaInputErrorMsg: '',
      allLocations: [],
      allBuildings: [],
      allFloors: [],
      allAreas: [],
      deleteAreas: [],
      deleteFloors: [],
      areaMenuOpenFor: false,
      floorMenuOpenFor: false,
      buildingMenuOpenFor: false,
      confirmDeleteBuilding: false,
      newLocations: [],
      editingDesks: false,
      editingBuidingName: false,
      editingArea: false
    }
  },
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
      locations: state => state.teams.locations
    }),
    editingFloor () {
      const isEmpty = (obj) => {
        // eslint-disable-next-line no-unreachable-loop
        for (const i in obj) {
          return false
        }
        return true
      }
      if (this.floorEditing) {
        return !isEmpty(this.floorEditing)
      }
      return false
    },
    editingBuildingFloors () {
      if (this.editingBuildingDetails) {
        const buildingId = this.editingBuildingDetails.id
        if (buildingId) {
          const deletedFloorIds = this.deleteFloors.map(floor => floor.id)
          const floors = [...this.allFloors]
          return floors.filter(floor => {
            return floor.buildingId === buildingId &&
              !(deletedFloorIds.includes(floor.id))
          }).sort((a, b) => {
            const aName = a.name.toLowerCase()
            const bName = b.name.toLowerCase()
            return aName > bName ? 1 : -1
          })
        }
      }
      return []
    }
  },
  beforeDestroy () {},
  created () {
  },
  mounted () {
    this.getAllLocationsDetails()
  },
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
      saveLocations: 'teams/saveLocations',
      loadLocations: 'teams/loadLocations',
      deleteLocations: 'teams/deleteLocations'
    }),
    setCapacityManage (val) {
      console.log('setCapacityManage', val)
    },
    setDeskEnabled (val) {
      console.log('setDeskEnabled', val)
    },
    detailsText (buildingDetail) {
      let text = ''
      if (buildingDetail) {
        const buildingId = buildingDetail.id
        if (buildingDetail.capacityManage) {
          const buildCap = this.getCapacity(buildingDetail)
          if (buildCap) {
            text = `${buildCap} Desks`
          }
        }
        const floors = this.buildingFloors(buildingId)
        if (floors.length > 0) {
          let areaCount = 0
          for (const floor of floors) {
            const areas = this.floorAreas(floor.id)
            if (areas) {
              areaCount += areas.length
            }
          }
          if (areaCount > 0) {
            if (text !== '') {
              text = ' | ' + text
            }
            text = `${areaCount} Areas` + text
          }
          if (text !== '') {
            text = ' | ' + text
          }
          text = `${floors.length} Floors` + text
        }
      }
      return text
    },
    setAssignedDesksArea (val) {
      // console.log('setAssignedDesks', val, !this.editingArea.desks)
      this.editingArea.assignedDesks = val
      const capInt = parseInt(this.editingArea.capacity)

      if (val) {
        if (!this.editingArea.desks) {
          this.editingArea.desks = []
          if (this.editingArea.capacity) {
            for (const desknum of [...Array(capInt).keys()]) {
              this.editingArea.desks.push(
                {
                  name: `Desk ${desknum + 1}`,
                  enabled: true
                }
              )
            }
          }
        } else {
          if (this.editingArea.desks.length < capInt) {
            let currentDesksLength = this.editingArea.desks.length
            while (currentDesksLength < capInt) {
              currentDesksLength++
              this.editingArea.desks.push(
                {
                  name: `Desk ${currentDesksLength}`,
                  enabled: true
                }
              )
            }
          } else if (this.editingArea.desks.length > capInt) {
            this.editingArea.desks = this.editingArea.desks.slice(0, capInt)
          }
        }
      }
    },
    setAssignedDesks (val) {
      // console.log('setAssignedDesks', val, this.newFloorDetails, this.floorEditing)
      this.floorEditing.assignedDesks = val
      const capInt = parseInt(this.newFloorDetails.capacity)

      if (val) {
        if (!this.newFloorDetails.desks) {
          this.newFloorDetails.desks = []
          if (this.newFloorDetails.capacity) {
            for (const desknum of [...Array(capInt).keys()]) {
              this.newFloorDetails.desks.push(
                {
                  name: `Desk ${desknum + 1}`,
                  enabled: true
                }
              )
            }
          }
        } else {
          if (this.newFloorDetails.desks.length < capInt) {
            let currentDesksLength = this.newFloorDetails.desks.length
            while (currentDesksLength < capInt) {
              currentDesksLength++
              this.newFloorDetails.desks.push(
                {
                  name: `Desk ${currentDesksLength}`,
                  enabled: true
                }
              )
            }
          } else if (this.newFloorDetails.desks.length > capInt) {
            this.newFloorDetails.desks = this.newFloorDetails.desks.slice(0, capInt)
          }
        }
      }
    },
    closeEditBuilding () {
      this.newBuildingDetails = {
        name: '',
        capacity: '',
        capacityManage: false
      }
      this.buildingInputError = false
      this.buildingInputErrorMsg = ''
    },
    closeEditFloor () {
      this.addFloorClicked = false
      this.newFloorDetails = {
        name: '',
        capacity: '',
        assignedDesks: false,
        desks: []
      }
      this.floorInputError = false
      this.floorInputErrorMsg = ''
      this.editingDesks = false
    },
    getAllLocationsDetails () {
      if (this.locations) {
        this.allLocations = [...this.locations]
        this.allBuildings = [...this.allLocations].filter(location => location.type === 'building').sort((a, b) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          return aName > bName ? 1 : -1
        })
        this.allFloors = [...this.allLocations].filter(location => location.type === 'floor').sort((a, b) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          return aName > bName ? 1 : -1
        })
        this.allAreas = [...this.allLocations].filter(location => location.type === 'area').sort((a, b) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          return aName > bName ? 1 : -1
        })
      } else if (this.onboarding && this.newLocations && this.newLocations.length > 0) {
        console.log('getAllLocationsDetails.onboarding', this.newLocations)
        this.allLocations = [...this.newLocations]
        this.allBuildings = [...this.allLocations].filter(location => location.type === 'building').sort((a, b) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          return aName > bName ? 1 : -1
        })
        this.allFloors = [...this.allLocations].filter(location => location.type === 'floor').sort((a, b) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          return aName > bName ? 1 : -1
        })
        this.allAreas = [...this.allLocations].filter(location => location.type === 'area').sort((a, b) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          return aName > bName ? 1 : -1
        })
      }
    },
    openBuildingDetailsInputs () {
      this.cancelAllDetailsEdit('close')
      this.buildingDetailsInputsOpened = true
    },
    closeBuildingDetailsInputs () {
      this.buildingInputError = false
      this.buildingInputErrorMsg = ''
      this.buildingDetailsInputsOpened = false
      this.newBuildingDetails = {
        name: '',
        capacity: ''
      }
    },
    resetBuildingDetailsInputs () {
      this.buildingInputError = false
      this.buildingInputErrorMsg = ''
      this.newBuildingDetails = {
        name: '',
        capacity: ''
      }
    },
    openAreaMenu (areaDetail, index) {
      this.areaMenuOpenFor = areaDetail.id || index
    },
    openFloorMenu (floorDetail, index) {
      this.floorMenuOpenFor = floorDetail.id || index
    },
    openBuildingMenu (buildingDetail, index) {
      this.buildingMenuOpenFor = buildingDetail.id || index
    },
    deleteBuilding (buildingDetail) {
      if (buildingDetail.id) {
        this.deleteLocations({ locationId: buildingDetail.id }).then(() => {
          const floorsToDelete = this.buildingFloors(buildingDetail.id)
          floorsToDelete.forEach((floor) => {
            this.deleteFloor(floor)
          })
        })
      }
    },
    deleteFloor (floor) {
      if (floor.id) {
        this.deleteLocations({ locationId: floor.id }).then(() => {
          const areasToDelete = this.floorAreas(floor.id)
          areasToDelete.forEach((area) => {
            this.deleteArea(area)
          })
        })
      }
    },
    removeFloor (floor) {
      if (floor.id) {
        this.deleteFloors.push(floor)
      }
    },
    deleteArea (area) {
      if (area.id) {
        this.deleteLocations({ locationId: area.id })
      }
    },
    isNewBuildingDetailsValid () {
      if (this.newBuildingDetails.name) {
        if (!this.buildingInputError) {
          return true
        }
        return false
      } else {
        this.buildingInputErrorMsg = 'Please enter office name'
        return false
      }
    },
    saveNewBuildingDetails () {
      if (this.isNewBuildingDetailsValid()) {
        const buildingDetails = { ...this.newBuildingDetails, addedOn: new Date(), type: 'building' }
        if (this.onboarding) {
          buildingDetails.id = uuidv4()
          this.newLocations.push(buildingDetails)
          this.resetBuildingDetailsInputs()
        } else {
          buildingDetails.addedBy = this.profile.id
          buildingDetails.teamId = this.profile.team
          this.saveLocations({ location: buildingDetails })
          this.closeBuildingDetailsInputs()
        }
      }
    },
    cancelAllDetailsEdit (from) {
      if (this.editingArea) {
        this.editingArea = false
      } else {
        if (this.buildingFloors(this.editingBuildingIndex).length === 0 || from === 'close' || !this.editingFloor) {
          this.resetAllData()
        } else {
          this.resetAllData('update')
        }
      }
      this.loadLocations() // reset to original values if edited floor values
    },
    getCapacity (building) {
      const buildingId = building.id
      const floors = this.buildingFloors(buildingId)
      if (floors.length > 0) {
        let capFromFloors = 0
        for (const floor of floors) {
          const areas = this.floorAreas(floor.id)
          if (areas.length > 0) {
            for (const area of areas) {
              capFromFloors += Number(area.capacity)
            }
          } else {
            capFromFloors += Number(floor.capacity)
          }
        }
        return capFromFloors
      } else {
        return building.capacity
      }
    },
    buildingFloors (buildingId) {
      if (buildingId) {
        const deletedFloorIds = this.deleteFloors.map(floor => floor.id)
        const floors = [...this.allFloors]
        return floors.filter(floor => {
          return floor.buildingId === buildingId &&
            !(floor.id in deletedFloorIds)
        }).sort((a, b) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          return aName > bName ? 1 : -1
        })
      }
      return []
    },
    floorAreas (floorId) {
      if (floorId) {
        const areas = [...this.allAreas]
        return areas.filter(area => area.floorId === floorId).sort((a, b) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          return aName > bName ? 1 : -1
        })
      }
      return []
    },
    openAllDetailsBuildingEdit (buildingDetail) {
      this.editingBuidingName = true
      this.newBuildingDetails = { ...buildingDetail }
      this.$nextTick(() => {
        console.log('openAllDetailsBuildingEdit', this.$refs, this.$refs.officename[0])
        this.$refs.officename[0].focus()
      })
    },
    openAllDetailsEdit (buildingDetail, when) {
      this.resetAllData()
      this.editingBuildingIndex = buildingDetail.id
      this.editingBuildingDetails = buildingDetail
      this.newBuildingDetails = { ...buildingDetail }
      this.deleteAreas = []
      this.deleteFloors = []
      if (when === 'noFloors') {
        this.addNewFloor()
      }
    },
    isFloorAndAreaDetailsValid (buildingDetails) {
    },
    async saveAllDetails (buildingDetails) {
      if (this.onboarding) {
        if (this.deleteFloors) {
          for (const floor of this.deleteFloors) {
            this.newLocations = this.newLocations.filter(nl => nl.id !== floor.id && nl.floorId !== floor.id)
          }
          this.deleteFloors = []
        }
        if (this.deleteAreas) {
          for (const area of this.deleteAreas) {
            this.newLocations = this.newLocations.filter(nl => nl.id !== area.id)
          }
          this.deleteAreas = []
        }
        if (this.newBuildingDetails && this.newBuildingDetails.id && this.isNewBuildingDetailsValid()) {
          this.closeEditBuilding()
        }
        if (this.addArea('save')) {
          let floorId = this.newFloorDetails.id
          if (!this.newFloorDetails.id) {
            const floorDetails = { ...this.newFloorDetails, addedOn: this.newFloorDetails.addedOn || new Date(), type: 'floor', buildingId: buildingDetails.id }
            floorDetails.id = uuidv4()
            floorId = floorDetails.id
            this.newLocations.push(floorDetails)
          }
          if (this.newFloorAreas.length > 0) {
            this.newFloorAreas.forEach((area) => {
              if (!area.id) {
                const areaDetails = { ...area, addedOn: area.addedOn || new Date(), type: 'area', floorId, buildingId: buildingDetails.id }
                areaDetails.id = uuidv4()
                this.newLocations.push(areaDetails)
              }
            })
          }
          this.resetAllData('update')
        }
      } else {
        if (this.deleteFloors) {
          for (const floor of this.deleteFloors) {
            this.deleteFloor(floor)
          }
          this.deleteFloors = []
        }
        if (this.deleteAreas) {
          for (const area of this.deleteAreas) {
            this.deleteArea(area)
          }
          this.deleteAreas = []
        }
        if (this.newBuildingDetails && this.newBuildingDetails.id && this.isNewBuildingDetailsValid()) { // building edit
          await this.saveLocations({ locationId: this.newBuildingDetails.id, location: { ...this.newBuildingDetails } }).then(() => {
            this.closeEditBuilding()
          })
        }
        if (this.addArea('save')) { // floor save, area save and capacity updates
          const floorDetails = { ...this.newFloorDetails, addedBy: this.profile.id, addedOn: this.newFloorDetails.addedOn || new Date(), teamId: this.profile.team, type: 'floor', buildingId: buildingDetails.id }
          this.saveLocations({ locationId: this.newFloorDetails.id || false, location: floorDetails }).then(async (doc) => {
            if (this.newFloorAreas.length > 0) {
            // save all areas first, update floor with area capacity and update building with floor capacity
              const floorId = this.newFloorDetails.id || doc.id
              this.saveallAreas(floorId, buildingDetails.id)
              if (this.newFloorAreas.every(area => area.capacity !== '')) {
              // save floor capacity when all areas has capacity
                const floorCapacity = this.newFloorAreas.reduce(function (previousValue, currentValue) {
                  const previous = isNaN(parseInt(previousValue)) ? 0 : parseInt(previousValue)
                  const current = isNaN(parseInt(currentValue.capacity)) ? 0 : parseInt(currentValue.capacity)
                  return previous + current
                }, 0)
                const floorDetailsWithCapacity = { ...floorDetails, capacity: floorCapacity }
                this.saveLocations({ locationId: floorId, location: floorDetailsWithCapacity }).then(() => {
                  this.updateBuildingWithCapacity(buildingDetails)
                })
              } else {
                this.resetAllData('update')
              }
            } else {
              this.updateBuildingWithCapacity(buildingDetails)
            }
          })
        }
      }
    },
    updateBuildingWithCapacity (buildingDetails) {
      const floors = this.buildingFloors(buildingDetails.id)
      const buildingCapacity = floors.reduce(function (previousValue, currentValue) {
        const previous = isNaN(parseInt(previousValue)) ? 0 : parseInt(previousValue)
        const current = isNaN(parseInt(currentValue.capacity)) ? 0 : parseInt(currentValue.capacity)
        return previous + current
      }, 0)
      const buildingDetailsWithCapacity = { ...this.newBuildingDetails, capacity: buildingCapacity }
      // console.log(floors, buildingCapacity, buildingDetailsWithCapacity)
      this.saveLocations({ locationId: this.newBuildingDetails.id, location: buildingDetailsWithCapacity }).then(() => {
        this.resetAllData('update')
      })
    },
    resetAllData (from) {
      if (from !== 'update') {
        this.editingBuildingIndex = ''
        this.editingBuildingDetails = {}
        this.newBuildingDetails = {
          name: '',
          capacity: '',
          captacityManage: false
        }
      }
      this.editingBuidingName = false
      this.editingDesks = false
      this.editingArea = false
      this.buildingDetailsInputsOpened = false
      this.floorInputErrorMsg = ''
      this.floorInputError = false
      this.areaInputError = false
      this.areaInputErrorMsg = ''
      this.addFloorClicked = false
      this.addAreaClicked = false
      this.newFloorAreas = []
      this.floorEditing = false
      this.deleteAreas = []
      this.deleteFloors = []
      this.newFloorDetails = {
        name: '',
        capacity: '',
        assignedDesks: false,
        desks: []
      }
      this.newAreaDetails = {
        name: '',
        capacity: '',
        assignedDesks: false,
        desks: []
      }
    },
    addNewFloor () {
      this.addFloorClicked = true
      this.closeBuildingDetailsInputs()
    },
    addArea (from) {
      if (this.addFloorClicked) {
        if (this.newFloorDetails.name && !this.floorInputError) {
          let areaValid = true
          if (this.addAreaClicked || this.newFloorAreas.length > 0) {
            if (this.addAreaClicked && this.newAreaDetails.name && !this.areaInputError) {
              this.newFloorAreas.push(this.newAreaDetails)
              this.newAreaDetails = {
                name: '',
                capacity: ''
              }
              areaValid = true
            } else if (this.addAreaClicked && !this.newAreaDetails.name) {
              this.areaInputErrorMsg = 'Please enter area name'
              areaValid = false
            } else if (this.addAreaClicked && this.newFloorAreas.length > 0 && this.newFloorAreas.some(area => area.capacity !== '') && !this.newAreaDetails.capacity) {
              this.areaInputErrorMsg = 'Please enter area capacity'
              areaValid = false
            } else if (this.newFloorAreas.length > 0 && !this.newFloorAreas.every(area => area.name !== '')) {
              this.areaInputErrorMsg = 'Please enter area name'
              areaValid = false
            } else if (this.newFloorAreas.length > 0 && this.newFloorAreas.some(area => area.capacity !== '') && !this.newFloorAreas.every(area => area.capacity !== '')) {
              this.areaInputErrorMsg = 'Please enter area capacity'
              areaValid = false
            }
          }
          if (areaValid) { // to do validation
            this.floorInputErrorMsg = ''
            if (from !== 'save') {
              this.addAreaClicked = true // no new area input during save
            }
            return true
          }
        } else {
          this.floorInputErrorMsg = 'Please enter floor name'
          return false
        }
      }
    },
    saveallAreas (floorId, buildingId) {
      this.newFloorAreas.forEach((area) => {
        const areaDetails = { ...area, addedOn: area.addedOn || new Date(), addedBy: this.profile.id, teamId: this.profile.team, type: 'area', floorId, buildingId }
        this.saveLocations({ locationId: area.id || false, location: areaDetails })
      })
      this.editingArea = false
    },
    areaEditMode (areaDetails, buildingId) {
      this.editingArea = areaDetails
    },
    floorEditMode (floorDetails) {
      this.addFloorClicked = true
      this.editingArea = false
      this.floorEditing = { ...floorDetails }
      this.newFloorDetails = { ...floorDetails }
      this.newFloorAreas = this.floorAreas(floorDetails.id)
      this.deleteAreas = []
    },
    removeArea (areaDetail, from) {
      if (from === 'new') {
        this.addAreaClicked = false
        this.newAreaDetails = {
          name: '',
          capacity: ''
        }
      } else if (from === 'floorArea') {
        const areaIndex = this.newFloorAreas.findIndex(area => areaDetail.name === area.name)
        if (areaIndex !== -1) {
          this.newFloorAreas.splice(areaIndex, 1)
        }
        this.deleteAreas.push(areaDetail)
      }
    }
  },
  watch: {
    buildingMenuOpenFor: {
      handler () {
        this.confirmDeleteBuilding = false
      }
    },
    newLocations: {
      handler () {
        this.getAllLocationsDetails()
        this.$emit('locationsUpdate', this.newLocations)
      }
    },
    locations: {
      handler () {
        this.getAllLocationsDetails()
      }
    },
    'newBuildingDetails.name': {
      handler () {
        if (this.allBuildings.some((building) => building.id !== this.editingBuildingIndex && building.name.trim().toLowerCase() === this.newBuildingDetails.name.trim().toLowerCase())) {
          this.buildingInputError = true
          this.buildingInputErrorMsg = 'Building details already exist, add different.'
        } else {
          this.buildingInputError = false
          this.buildingInputErrorMsg = ''
        }
      }
    },
    'newFloorDetails.name': {
      handler () {
        if (this.allFloors.some((floor) => floor.id !== this.floorEditing.id && floor.buildingId === this.editingBuildingIndex && floor.name.trim().toLowerCase() === this.newFloorDetails.name.trim().toLowerCase())) {
          this.floorInputError = true
          this.floorInputErrorMsg = 'Floor details already exist, add different.'
        } else {
          this.floorInputError = false
          this.floorInputErrorMsg = ''
        }
      }
    },
    'newFloorDetails.capacity': {
      handler () {
        // console.log('WorkspaceLocations.watch newFloorDetails.capacity', this.newFloorDetails.capacity, this.newFloorDetails.assignedDesks)
        if (this.newFloorDetails.assignedDesks) {
          this.setAssignedDesks(true)
        }
      }
    },
    'editingArea.capacity': {
      handler () {
        // console.log('WorkspaceLocations.watch editingArea.capacity', this.editingArea.capacity, this.editingArea.assignedDesks)
        if (this.editingArea.assignedDesks) {
          this.setAssignedDesksArea(true)
        }
      }
    },
    'newAreaDetails.name': {
      handler () {
        // console.log('WorkspaceLocations.watch newAreaDetails.name', this.newAreaDetails.name, this.allAreas.some((area) => area.floorId === this.floorEditing.id && area.buildingId === this.editingBuildingIndex && area.name.trim().toLowerCase() === this.newAreaDetails.name.trim().toLowerCase()))
        if (this.newFloorAreas.some((area) => area.name.trim().toLowerCase() === this.newAreaDetails.name.trim().toLowerCase())) {
          this.areaInputError = true
          this.areaInputErrorMsg = 'Area details already exist, add different.'
        } else {
          this.areaInputError = false
          this.areaInputErrorMsg = ''
        }
      }
    }
  }
}
</script>

<style lang="scss">
.workspace-locations-wrapper {
  .type-content {
    &.locations-content {
      .building-fields-wrapper {
        margin-bottom: 40px;
        min-height: 57px;
        .mdc-button {
          &.add-location {
            border: 0.5px solid #6184AD;
            border-radius: 4px;
            .mdc-text {
              margin-left: 5px;
              color: #6184AD;
            }
          }
        }
        .add-building-fields {
          display: flex;
          align-items: center;
          .field-wrapper {
            margin-right: 20px;
            margin-bottom: 0;
            .label {
              text-transform: initial;
              margin-bottom: 5px;
            }
            input {
              max-width: 200px;
              border-radius: 2px;
              border: 1px solid #e0e0e0;
              padding: 5px;
              font-size: 14px;
              color: #4f4f4f;
              font-weight: 600;
              height: 20px;
              &.office-name-field {
                width: 200px;
              }
              &.capacity-field {
                max-width: 55px;
                text-align: center;
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
              &::placeholder {
                color: #BDBDBD;
                font-weight: 600;
              }
            }
          }
          .building-details-actions {
            align-self: flex-end;
            .mdc-button {
              color: #6184AD;
              font-weight: 400;
              &.cancel-name-button {
                text-transform: none;
              }
              &.save-name-button {
                .mdc-text {
                  font-weight: 600;
                }
                &.disabled {
                  pointer-events: none;
                  color: #e0e0e0;
                }
              }
            }
          }
        }
      }
      .manage-capacity-section {
        background-color: rgba(249, 252, 255, 1);
        padding:20px;
        margin-bottom:20px;
        display:flex;
        .manage-capacity-info {
          margin-right:auto;
        }
        .section-header {
          margin-bottom:10px;
        }
        .mdc-switch {

          margin:20px;

          .mdc-switch__thumb-underlay {
            &::before {
              background-color: #018754;
            }
          }

          .mdc-switch__track {
            background-color: rgba(53, 154, 63, 0.5)
          }

          &.mdc-switch--checked {
            .mdc-switch__track {
              background-color: rgba(53, 154, 63, 1);
            }
            .mdc-switch__thumb {
              background-color: #FFFFFF;
              border-color: #FFFFFF;
            }
          }
        }
      }

      .assigned-desks-section {
        background-color: rgba(249, 252, 255, 1);
        padding:20px;
        margin-bottom:20px;
        display:flex;
        flex-flow:column;
        .assigned-desks-toggle {
          display:flex;
          .assigned-desks-info {
            margin-right:auto;
          }
          .section-header {
            margin-bottom:10px;
          }
          .mdc-switch {

            margin:20px;

            .mdc-switch__thumb-underlay {
              &::before {
                background-color: #018754;
              }
            }

            .mdc-switch__track {
              background-color: rgba(53, 154, 63, 0.5)
            }

            &.mdc-switch--checked {
              .mdc-switch__track {
                background-color: rgba(53, 154, 63, 1);
              }
              .mdc-switch__thumb {
                background-color: #FFFFFF;
                border-color: #FFFFFF;
              }
            }
          }
        }
        .desk-list-header {
          margin-top:20px;
          .desk-row {
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
            margin-bottom: 0.5px;
            margin-right: 10px;
            height: 29px;
            display:flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 10px);
            .edit-desk-link {
              margin-left:10px;
              cursor:pointer;
              color: #6184AD;
            }
          }
        }
        .desk-list {
          max-height: 210px;
          overflow: auto;
          scrollbar-width: thin;
          &::-webkit-scrollbar {
            width: 5px;
            height: 8px;
            background-color: #fff; /* or add it to the track */
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(173,188,205)
          }
          .desk-row {
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
            margin-bottom: 0.5px;
            margin-right: 10px;
            height: 29px;
            display:flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 10px);

            .mdc-switch {
              margin:20px;

              .mdc-switch__thumb-underlay {
                &::before {
                  background-color: #018754;
                }
              }

              .mdc-switch__track {
                background-color: rgba(53, 154, 63, 0.5)
              }

              &.mdc-switch--checked {
                .mdc-switch__track {
                  background-color: rgba(53, 154, 63, 1);
                }
                .mdc-switch__thumb {
                  background-color: #FFFFFF;
                  border-color: #FFFFFF;
                }
              }
            }
          }
        }
      }

      .location-input-error {
        margin-top: 5px;
        .mdc-text {
          font-weight: 600;
          color: #b10000;
        }
      }
      .all-locations-list {
        .location-details-wrapper {
          &.non-edit-mode {
            display: grid;
            grid-template-columns: 300px 125px 30px;
            align-items: center;
            border-bottom: 0.5px solid #E0E0E0;
            max-width: 450px;
            &:first-of-type {
              border-top: 0.5px solid #e0e0e0;
            }
            .add-floor-action {
              display:flex;
              justify-self: flex-end;
              margin-right: 15px;
              align-self: unset;
              .add-floor-button {
                margin-right: 10px;
              }
            }
            .remove-location {
              align-self: unset;
            }
            .remove-location-prompt {
              margin-bottom:5px;
            }
          }
          &.edit-mode {
            border: 0.5px solid #E0E0E0;
            box-shadow: 0px 0px 7px rgb(0 0 0 / 31%);
            border-radius: 4px;
            padding: 15px 25px;
            .close-edit {
              img {
                margin-right: 0;
              }
            }
            .edit-building-details-wrapper {
              display: flex;
              flex-direction: column;
              .edit-building-fields {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .field-wrapper {
                  margin-right: 20px;
                  margin-bottom: 0;
                  .label {
                    text-transform: initial;
                    margin-bottom: 5px;
                  }
                  input {
                    max-width: 200px;
                    border-radius: 2px;
                    border: 1px solid #e0e0e0;
                    padding: 5px;
                    font-size: 14px;
                    color: #4f4f4f;
                    font-weight: 600;
                    height: 20px;
                    &.readonly {
                      opacity: 0.5;
                    }
                    &.office-name-field {
                      width: 200px;
                    }
                    &.capacity-field {
                      max-width: 55px;
                      text-align: center;
                      -moz-appearance: textfield;
                      &::-webkit-outer-spin-button,
                      &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                    }
                    &::placeholder {
                      color: #BDBDBD;
                      font-weight: 600;
                    }
                  }
                }
                .building-details-actions {
                  align-self: flex-end;
                  .mdc-button {
                    color: #6184AD;
                    font-weight: 400;
                    &.cancel-name-button {
                      text-transform: none;
                    }
                  }
                }
                + .location-input-error {
                  margin-top: -15px;
                  margin-bottom: 20px;
                }
              }
              .name-capacity {
                margin-bottom: 20px;
                .mdc-text {
                  color: #404040;
                  font-weight: 600;
                  &.office-name {
                    margin-right:10px;
                    min-width:50px;
                  }
                  &.capacity-label {
                    color: #828282;
                    font-weight: 400;
                  }
                }
                .edit-building {
                  margin-left: 5px;
                }
                .office-capacity {
                  margin-left: auto;
                }
              }
              .floor-add:not(:empty) {
                margin-top: 15px;
              }
              .floor-details-wrapper:not(:empty) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 4px;
                padding: 5px 15px;
                margin-bottom: 5px;
                .floor-name-capacity {
                  color: #6184AD;
                  text-transform: capitalize;
                  display: inline-flex;
                  flex-wrap: wrap;
                  .floor-details {
                    margin-right: 10px;
                  }
                  .areas-names {
                    display: inline-flex;
                    flex-wrap: wrap;
                    span:not(:last-of-type) {
                      &:after {
                        content: ','
                      }
                    }
                    .area-name {
                      margin-left: 3px;
                      color: #828282;
                      font-style: italic;
                    }
                  }
                }
                .floor-action-buttons {
                  display: flex;
                  align-items: center;
                  .icon-wrapper:first-of-type {
                    margin-right: 20px;
                  }
                }
              }
              .floor-button-fields {
                margin-left: 15px;
                &:not(:empty) {
                  border-bottom: 0.5px dashed #e0e0e0;
                }
                .add-floor-fields {
                  display: flex;
                  align-items: center;
                  padding-bottom: 8px;
                  .field-wrapper {
                    margin-right: 20px;
                    margin-bottom: 0;
                    .label {
                      text-transform: initial;
                      margin-bottom: 5px;
                    }
                    input {
                      max-width: 200px;
                      border-radius: 2px;
                      border: 1px solid #e0e0e0;
                      padding: 5px;
                      font-size: 14px;
                      color: #4f4f4f;
                      font-weight: 600;
                      height: 20px;
                      &.floor-name-field {
                        width: 200px;
                      }
                      &.capacity-field {
                        max-width: 55px;
                        text-align: center;
                        -moz-appearance: textfield;
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                          -webkit-appearance: none;
                          margin: 0;
                        }
                      }
                      &::placeholder {
                        color: #BDBDBD;
                        font-weight: 600;
                      }
                      &.readonly {
                        opacity: 0.5;
                      }
                    }
                  }
                  .action-buttons {
                    align-self: flex-end;
                    margin-bottom: 5px;
                    margin-left: 20px;
                    .mdc-button {
                      color: #6184AD;
                      font-weight: 400;
                      &.add-area {
                        border: 0.5px solid #6184AD;
                        border-radius: 4px;
                        text-transform: none;
                        width: 90px;
                        height: 24px;
                        .icon-wrapper {
                          margin-right: 5px;
                        }
                        .mdc-text {
                          color: #6184AD;
                        }
                      }
                    }
                  }
                  .remove-menu-wrapper {
                    display: flex;
                    position: relative;
                  }
                  .remove-floor-actions {
                    align-self: flex-end;
                    margin-left: auto;
                    .mdc-button {
                      color: #6184AD;
                      text-transform: none;
                    }
                  }
                }
                .floor-input-error {
                  .mdc-text {
                    font-weight: 600;
                    color: #b10000;
                  }
                }
              }
              .area-button-fields {
                margin-left:5px;
                margin-top:10px;
              }
              .area-details-wrapper:not(:empty) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width:100%;
                border-radius: 4px;
                padding: 5px 15px;
                margin-bottom: 5px;
                .area-name-capacity {
                  text-transform: capitalize;
                  display: inline-flex;
                  flex-wrap: wrap;
                  span {
                    margin-right:10px;
                  }
                }
                .remove-menu-wrapper {
                    display: flex;
                    position: relative;
                    .edit-area {
                      margin-right:20px;
                    }
                  }
                .remove-area-actions {
                  align-self: flex-end;
                  min-height:15px;
                  margin-right:10px;
                  margin-left: auto;
                  .mdc-button {
                    color: #6184AD;
                    text-transform: none;
                  }
                }
              }
              .add-area-fields {
                display: flex;
                align-items: center;
                border-bottom: 0.5px solid #E0E0E0;
                padding: 5px 0;
                .area-label {
                  color: #828282;
                  margin-right: 10px;
                }
                .remove-menu-wrapper {
                    display: flex;
                    position: relative;
                    .edit-area {
                      margin-right:20px;
                    }
                  }
                .remove-area-actions {
                  align-self: flex-end;
                  min-height:15px;
                  margin-right:10px;
                  margin-left: auto;
                  .mdc-button {
                    color: #6184AD;
                    text-transform: none;
                  }
                }
                .field-wrapper {
                  margin-right: 20px;
                  margin-bottom: 0;
                  input {
                    max-width: 200px;
                    border-radius: 2px;
                    border: 1px solid #e0e0e0;
                    padding: 5px;
                    font-size: 14px;
                    color: #4f4f4f;
                    font-weight: 600;
                    height: 20px;
                    &.area-name-field {
                      width: 155px;
                    }
                    &.capacity-field {
                      max-width: 55px;
                      text-align: center;
                      -moz-appearance: textfield;
                      &::-webkit-outer-spin-button,
                      &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                    }
                    &::placeholder {
                      color: #BDBDBD;
                      font-weight: 600;
                    }
                  }
                }
              }
              .area-input-error {
                .mdc-text {
                  font-weight: 600;
                  color: #b10000;
                }
              }
              .save-location-actions {
                align-self: flex-end;
                margin-top: 35px;
                .mdc-button {
                  border: 0.5px solid #6184AD;
                  border-radius: 4px;
                  height: 30px;
                  .mdc-text {
                    font-weight: 700;
                  }
                  &.cancel-button {
                    margin-right: 15px;
                    .mdc-text {
                      color: #6184AD;
                    }
                  }
                  &.save-button {
                    background: #6184AD;
                    .mdc-text {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          padding: 15px 0;
          .name-capacity {
            display: flex;
            align-items: center;
            &.all-names {
              flex-direction: column;
              align-items: baseline;
              .floor-area {
                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
                color: #404040;
                .floor-name {
                  color: #404040;
                  font-weight: 400;
                }
                .areas-names {
                  display: inline-flex;
                  flex-wrap: wrap;
                  span:not(:last-of-type) {
                    &:after {
                      content: ','
                    }
                  }
                  .area-name {
                    color: #404040;
                    font-style: italic;
                    font-weight: 300;
                    margin-left: 3px;
                  }
                }
              }
            }
            .mdc-text {
              color: #4175B1;
              &.office-name {
                font-weight: 600;
                margin-right: 30px;
                min-width: 100px;
                max-width: 200px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
          .add-floor-action {
            justify-self: flex-end;
            margin-right: 15px;
            align-self: flex-start;
          }
          .remove-menu-wrapper {
            display: flex;
            position: relative;
          }
          .remove-location {
            align-self: flex-start;
          }
          .mdc-button {
            &.add-floor-button {
              border: 0.5px solid #6184AD;
              border-radius: 4px;
              height: 24px;
              text-transform: initial;
              .mdc-text {
                margin-left: 5px;
                color: #6184AD;
              }
            }
          }
        }
      }
    }
  }
}
</style>
