import { Mark, markInputRule, mergeAttributes } from '@tiptap/core'

export const underScoreRegEx = /(?:^|\s)((?:_)((?:[^*]+))(?:_))$/gm
const UnderlineMark = Mark.create({
  name: 'underlinemark',
  addOptions () {
    return {
      HTMLAttributes: {}
    }
  },
  parseHTML () {
    return [
      {
        tag: 'u'
      },
      {
        style: 'text-decoration=underline'
      }
    ]
  },

  renderHTML ({ HTMLAttributes }) {
    return ['u', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addInputRules () {
    return [
      markInputRule({
        find: underScoreRegEx,
        type: this.type
      })
    ]
  }
})
export default UnderlineMark
