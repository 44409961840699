<template>
  <div class="mentions">
    <header v-bind:class="{'sidebar-adjusts': showSideBar}">
      <div class="mentions-tab-bar" v-show="!showSideBar">
        <mdc-button @click.native="setClickedMentionType('linkedmentions')">
          <mdc-text typo="body2" tag="span">Linked mentions</mdc-text>
          <mdc-text typo="caption" tag="span" class="count">{{linkedMentionsCount}}</mdc-text>
        </mdc-button>
        <!-- <mdc-button @click.native="setClickedMentionType('comments')">
          <mdc-text typo="body2" tag="span">Comments</mdc-text>
          <mdc-text typo="caption" tag="span" class="count">{{commentCount}}</mdc-text>
        </mdc-button> -->
      </div>
      <mdc-button @click.native="toggleSideBarClick">{{ showSideBar ? 'HIDE SIDE BAR' : 'SHOW SIDE BAR' }}</mdc-button>
    </header>
  </div>
</template>

<script>

export default {
  name: 'Mentions',
  components: {
  },
  props: {
    comments: {
      default () {
        return {}
      }
    },
    linkedMentions: {
      default () {
        return {}
      }
    },
    showMentionsSideBar: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
  },
  computed: {
    // commentCount () {
    //   if (this.comments) {
    //     return Object.keys(this.comments).length
    //   }
    //   return 0
    // },
    linkedMentionsCount () {
      if (this.linkedMentions) {
        return Object.keys(this.linkedMentions).length
      }
      return 0
    }
  },
  data () {
    return {
      showSideBar: this.showMentionsSideBar,
      mentionType: 'linkedmentions'
    }
  },
  methods: {
    toggleSideBarClick () {
      this.showSideBar = !this.showSideBar
      this.$emit('toggleMentionsSideBar', this.showSideBar)
    },
    setClickedMentionType (type) {
      this.mentionType = type
      this.showSideBar = true
      this.$emit('toggleMentionsSideBar', true, this.mentionType)
    }
  }
}
</script>

<style scoped lang="scss">
.mentions {
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    .mentions-tab-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      .mdc-button {
        min-width: 100px;
        min-height: 20px;
        background: #FFFFFF;
        text-transform: none;
        display: flex;
        align-items: center;
        justify-content: space-around;
        white-space: nowrap;
        margin: 5px;
      }
      .mdc-text {
        font-size: 14px;
        color: #4175B1;
        font-weight: 500;
      }
      * {
        padding: 5px;
      }
      .count {
        padding: 0;
        width: 15px;
        height: 15px;
        border-radius: 16px;
        background: #D9E8FA;
        border: 0.2px solid #4175B1;
        box-sizing: border-box;
        font-size: 10px;
        line-height: 15px;
        margin: 0 5px 0 10px;
        font-weight: 500;
      }
    }
    &.sidebar-adjusts {
      justify-content: flex-end;
    }
  }
  .mdc-button {
    font-size: 12px;
    color: #4175B1;
    font-weight: 400;
  }
}
</style>
