<template>
  <div v-if="workspace" class="initiative-updates">
    <div v-if="searchClient && initiative && teams" class="container">
      <ais-instant-search :search-client="searchClient" :index-name="workspace.id+'-blocks-date-desc'">
        <ais-configure
          :tagFilters="[getTag(initiative)]"
          :filters="'type:lookingForwardPages'"
          :hitsPerPage="20"
        />
        <ais-search-box placeholder="Type here to find updates" v-model="query" class="updates-search" />
        <ais-state-results style="min-height: 400px;">
          <template>
            <ais-hits>
              <ol class="ais-Hits-list" slot-scope="{ items }" v-if="items.length > 0">
                <li class="ais-Hits-item" v-for="(item, index) in items" :key="item.objectID">
                  <div class="update-response-column" style="display:flex">
                    <mdc-text tag="span" typo="caption" v-if="index == 0" class="date">{{getDateFromUnix(item.date)}}</mdc-text>
                    <mdc-text tag="span" typo="caption" v-else-if="getDateFromUnix(item.date) !== getDateFromUnix(items[index-1].date)" class="date">{{getDateFromUnix(item.date)}}</mdc-text>
                    <mdc-text tag="span" typo="caption" v-else class="date"></mdc-text>
                    <div class="blocks-details">
                      <profile-initials v-if="index == 0" :key="item.objectId + item.userId + 'profiles'" :profileId="item.userId" :date="appDate" showNameAndInitial showYou />
                      <template v-if="index && getDateFromUnix(item.date) !== getDateFromUnix(items[index-1].date)">
                        <profile-initials :key="item.objectId + item.userId + 'profiles'" :profileId="item.userId" :date="appDate" showNameAndInitial showYou />
                      </template>
                      <template v-else>
                        <profile-initials v-if="index && item.userId !== items[index -1].userId" :key="item.objectId + item.userId + 'profiles'" :profileId="item.userId" :date="appDate" showNameAndInitial showYou />
                      </template>
                      <div :id="item.objectID" class="updates-blocks" @click.stop.prevent="updateTextClick($event, item.pageId, item.objectID)">
                        <span v-html="item.html" />
                        <section class="block-comment-section" v-if="getNoteBlockCommentCount(item.objectID, item.pageId) !== 0">
                          <span :id="'comment-count-'+item.objectID" class="block-comment-count">
                            <base-svg-icon name="comment-grey" />
                            <span class="count">{{getNoteBlockCommentCount(item.objectID, item.pageId)}}</span>
                          </span>
                        </section>
                      </div>
                    </div>
                  </div>
                </li>
              </ol>
              <mdc-text v-else class="no-updates-text" tag="span" typo="body2">Updates will appear here as people tag initiatives in their team updates</mdc-text>
            </ais-hits>
          </template>
        </ais-state-results>
        <ais-pagination v-if="query" class="initiative-updates-pagination" :show-first="false" :show-last="false" />
      </ais-instant-search>
    </div>
    <section id="commentsection" @click.prevent.stop >
      <div v-if="commentMode" class="block-comment-box">
        <div class="block-comments">
          <template v-for="(comment, index) in lookingForwardBlockComments" >
            <div :ref="'looking-forward' + comment.id" class="comment-wrapper" :key="comment.noteBlockId + index" v-if="comment.noteBlockId === selectedNoteBlock">
              <div class="comment-from-details">
                <profile-initials :profileId="comment.fromId" :date="appDate" />
                <mdc-text typo="body2" tag="span">{{ getProfileFromId(comment.fromId) }}</mdc-text>
                <span v-if="comment.date" class="comment-time">
                  <time-ago refresh long :datetime="comment.date.toDate()"></time-ago>
                </span>
              </div>
              <div class="comment-message">
                {{comment.message}}
              </div>
            </div>
          </template>
        </div>
        <message-area class="block-message-area" allowMentions :mentions="teamMentions" @send="sendBlockComments" :placeholderText="'Type a comment'" :profileId="profile.id" :forDate="appDate" :sendTextLabel="'POST'" :disableSend="true" />
      </div>
    </section>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite'
import TimeAgo from '../TimeAgo'
import 'instantsearch.css/themes/satellite-min.css'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import ProfileInitials from './ProfileInitials.vue'
import fb from '../../store/firebase'
import MessageArea from './MessageArea'
import BaseSvgIcon from './BaseSvgIcon.vue'
import { appSettings } from '../../settings'

export default {
  name: 'InitiativeUpdates',
  props: {
    expandedView: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isTablet: {
      type: Boolean,
      default: false
    },
    initiative: {
    }
  },
  computed: {
    ...mapState([
      'appDate'
    ]),
    ...mapState({
      profile: state => state.profile,
      profiles: state => state.profiles,
      teamProfiles: state => state.teams.teamProfiles,
      teams: state => state.teams.teams,
      workspace: state => state.workspace,
      lookingForwardBlockComments: state => state.teams.lookingForwardBlockComments
    }),
    teamMentions () {
      if (this.teamProfiles) {
        return this.teamProfiles.map((prof) => {
          return { ...prof, name: prof.displayName, initials: this.initials(prof) }
        })
      }
      return []
    }
  },
  components: {
    ProfileInitials,
    MessageArea,
    TimeAgo,
    BaseSvgIcon
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      query: '',
      searchClient: null,
      selectedNode: null,
      selectedNoteBlock: false,
      commentModeForBlock: null,
      commentMode: false,
      pageDetails: null,
      commentCounts: {}
    }
  },
  methods: {
    ...mapActions({
      loadLookingForwardBlockComments: 'teams/loadLookingForwardBlockComments',
      saveLookingForwardBlockComments: 'teams/saveLookingForwardBlockComments',
      updateLookingForwardPage: 'teams/updateLookingForwardPage',
      getLookingForwardPageById: 'teams/getLookingForwardPageById'
    }),
    getTag (initiative) {
      const teamId = initiative.teamId
      const initName = initiative.name
      if (this.teams) {
        const ft = this.teams.filter((t) => {
          return t.id === teamId
        })[0] || {}
        const tag = `${ft.name || ''}/${initName}`
        // console.log('initiativeUpdates.getTag', tag)
        return tag
      }
      return ''
    },
    initials (profile) {
      if (profile) {
        let initialFirst = ''
        let initialLast = ''
        let displayNameParts = ['', '']
        displayNameParts = profile.displayName.split(' ')
        if (profile.firstName) {
          initialFirst = profile.firstName.trim().substring(0, 1)
        } else {
          initialFirst = displayNameParts[0].substring(0, 1)
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${initialFirst}${initialLast}`.toUpperCase()
      }
      return 'U'
    },
    getNoteBlockCommentCount (noteBlockId, pageId) {
      if (this.commentCounts[noteBlockId]) {
        return this.commentCounts[noteBlockId]
      } else {
        this.loadNoteBlockCommentCount(noteBlockId, pageId)
      }
      return 0
    },
    async loadNoteBlockCommentCount (noteBlockId, pageId) {
      const lookingForward = await this.getLookingForwardPageById({
        teamId: this.profile.team,
        pageId
      })
      if (lookingForward) {
        if (lookingForward.noteBlocksCommentCount) {
          if (lookingForward.noteBlocksCommentCount[noteBlockId]) {
            this.commentCounts[noteBlockId] = lookingForward.noteBlocksCommentCount[noteBlockId].commentCount
            this.commentCounts = { ...this.commentCounts }
          }
        }
      }
    },
    getProfileFromId (id) {
      const allProfiles = [this.profile, ...this.profiles]
      if (allProfiles) {
        const profile = allProfiles.filter(prof => prof.id === id)
        if (profile.length > 0) {
          return profile[0].displayName
        }
      }
      return ''
    },
    async sendBlockComments (message) {
      const comment = {
        fromId: message.fromId,
        message: message.text,
        created: new Date(),
        date: new Date(),
        mentions: message.mentions || [],
        pageId: this.pageDetails.id,
        type: 'Looking Forward'
      }
      await this.saveLookingForwardBlockComments({
        teamId: this.profile.team,
        noteBlockId: this.selectedNoteBlock,
        commentId: comment.id,
        comment
      })
      const blockCommentCount = this.pageDetails.noteBlocksCommentCount && this.pageDetails.noteBlocksCommentCount[this.selectedNoteBlock] && this.pageDetails.noteBlocksCommentCount[this.selectedNoteBlock].commentCount ? this.pageDetails.noteBlocksCommentCount[this.selectedNoteBlock].commentCount + 1 : 1
      const noteBlock = this.pageDetails.noteBlocksCommentCount || {}
      noteBlock[this.selectedNoteBlock] = { commentCount: blockCommentCount }
      const lookingForwardPage = { ...this.pageDetails, noteBlocksCommentCount: noteBlock }
      this.updateLookingForwardPage({ teamId: this.profile.team, lookingForwardPageId: this.pageDetails.id, lookingForwardPage, saveComment: true })
      if (this.selectedNode.id) {
        this.commentCounts[this.selectedNode.id] = blockCommentCount
        this.commentCounts = { ...this.commentCounts }
      }
    },
    handleClickOutside (event) {
      event.stopPropagation()
      if (!event.target.classList.contains('comment-mode')) {
        this.commentMode = false
        this.pageDetails = null
        if (this.selectedNode) {
          this.selectedNode.classList.remove('comment-mode')
        }
      }
    },
    async updateTextClick (event, pageId, noteBlockId) {
      this.commentMode = false
      await this.getLookingForwardPageById({
        teamId: this.profile.team,
        pageId
      }).then((lookingForward) => {
        this.pageDetails = lookingForward
      })
      event.stopPropagation()
      let checkNode
      if (event.target.classList.contains('updates-blocks')) {
        checkNode = event.target
      } else if (event.target.parentNode.classList.contains('updates-blocks')) {
        checkNode = event.target.parentNode
      } else {
        checkNode = event.target.closest('.updates-blocks')
      }
      let noteBlock

      if (this.selectedNode) {
        this.selectedNode.classList.remove('comment-mode')
      }

      // find the noteblock above the click
      while (checkNode && !noteBlock) {
        if (checkNode.id) {
          noteBlock = checkNode
          this.selectedNoteBlock = checkNode.id
        } else {
          checkNode = checkNode.parentNode
        }
      }
      const noteBlockTextNode = noteBlock.querySelectorAll('p')
      let noteBlockText = ''
      if (noteBlockTextNode[0] && noteBlockTextNode[0].innerText !== '') {
        noteBlockText = noteBlockTextNode[0].innerText
      }
      if (noteBlock && noteBlockText !== '') {
        this.commentModeForBlock = noteBlock
        this.loadLookingForwardBlockComments({ noteBlockId })
        this.selectedNode = noteBlock
        noteBlock.appendChild(document.getElementById('commentsection'))
        this.$nextTick(() => {
          this.commentMode = true
          this.selectedNode.classList.add('comment-mode')
        })
      }
    },
    getWindowWidth () {
      this.windowWidth = window.innerWidth
    },
    getDateFromName (pageName) {
      return pageName.split(' ')[0]
    },
    getDateFromUnix (unix) {
      return moment.unix(unix).format('ddd D MMM').toUpperCase()
    },
    async getSearchClient () {
      const algoliaId = appSettings.algoliaId
      const algoliaKey = appSettings.algoliaKey
      if (!this.profile.searchKey) {
        const generateSearchKey = fb.functions.httpsCallable('generateSearchKey')
        const params = { userId: this.profile.id, teamId: this.profile.team, workspaceId: this.workspace.id }
        // console.log('Search.generateSearchKey params', params)
        await generateSearchKey(params).then((res) => {
          // console.log('Search.generateSearchKey', res, res.data)
          if (res.data.error) {
            // Fallback to all
            console.error('getSearchClient', res.data.error)
            this.searchClient = algoliasearch(
              algoliaId,
              algoliaKey
            )
          }
          if (res.data) {
            if (res.data.searchKey) {
              this.profile.searchKey = res.data.searchKey
              this.searchClient = algoliasearch(
                algoliaId,
                this.profile.searchKey
              )
            }
          }
        })
      } else {
        this.searchClient = algoliasearch(
          algoliaId,
          this.profile.searchKey
        )
      }
    }
  },
  created () {
  },
  mounted () {
    this.getSearchClient()
    window.addEventListener('resize', this.getWindowWidth)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  watch: {
    commentMode: {
      handler () {
        if (this.commentMode) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
          }, 300)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
          }, 300)
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
.initiative-updates .ais-Hits-item {
  padding:0px !important;
}
.initiative-updates .ais-Hits-item h4, .initiative-update.ais-Hits-item p {
  margin: 3px;
}
.initiative-updates .ais-Hits-item, .ais-InfiniteHits-item {
  box-shadow: none;
  margin-bottom: 5px;
}
.initiative-updates .date {
  margin-right:20px;
  margin-top: 10px;
  min-width: 150px;
  color: #828282;
}
</style>

<style lang="scss">
  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;

      > input {
        flex: 0 0 auto;
        margin-right: 0.5rem;
      }

      &[data-checked="true"] {
        * {
          text-decoration: line-through;
        }
      }

      p {
        margin-top: 3px;
        margin-bottom:3px;
      }
    }
  }

  .initiative-updates {
    .no-updates-text {
      margin: 20px auto;
      color: #959EA7;
      display: block;
      text-align: center;
    }
    .update-response-column {
      .blocks-details {
        > .profile-redirect {
          margin-right: 0px;
          margin-bottom: 10px;
          margin-top: 12px;
          .profile-firstname-initial {
            color: #333333 !important;
            font-size: 14px;
            font-weight: 700;
          }
          .you-text {
            color: #333333 !important;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
    .updates-blocks {
      max-width: 600px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      > span {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &::before {
          content: "";
          margin-right: 10px;
          min-width: 4px;
          min-height: 4px;
          max-width: 4px;
          max-height: 4px;
          background-color: #cc0000;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          margin-left: 10px;
        }
      }
      p {
        color: #404040;
        font-weight: 600;
        max-width: calc(100% - 30px);
        span {
          &[data-hashtag] {
            margin-bottom: 0;
            height: 15px;
            font-size: 14px;
            flex: unset;
            display: inline-flex;
            padding: 1px 2px 1px 2px;
            color: #7A95B5;
            position: relative;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            line-height: 17px;
            font-weight: normal;
            font-weight: normal;
            &::before {
              content: '#';
              color: #bdbdbd;
              font-weight: 400;
              position: absolute;
            }
          }
        }
      }
      [data-type="note-block"] {
        width: calc(100% - 20px);
        margin-left: 20px;
        display: flex;
        align-items: baseline;
        &::before {
          content: "";
          margin-right: 10px;
          min-width: 4px;
          min-height: 4px;
          max-width: 4px;
          max-height: 4px;
          background-color: #cc0000;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          margin-left: 10px;
        }
      }
      &.comment-mode {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px 1px rgba(0,0,0, 0.36);
        border-radius: 4px;
        min-height: 100px;
        align-items: baseline;
        display: block;
        z-index: 24;
        max-width: 500px;
        width: 500px;
        .block-comment-box {
          margin-top: 15px;
          padding: 16px 10px;
          background: rgba(234,234,234,0.26);
          .block-comments {
            overflow: auto;
            height: auto;
            max-height: 140px;
            cursor: default;
            margin-bottom: 10px;
            & > div.comment-wrapper {
              display: flex;
              flex-direction: column;
              margin-bottom: 15px;
              .comment-from-details {
                display: flex;
                align-items: center;
              }
            }
            .mdc-text.mdc-typography--body2 {
              color: #333333;
              font-weight: 700;
            }
            .comment-time {
              color: rgba(51,51,51,0.4);
              font-size: 12px;
            }
            .profile-initials {
              margin: 0;
              margin-left: 2px;
              margin-top: 2px !important;
            }
            .photo-wrapper {
              margin-left: 2px;
              margin-top: 2px;
              .profile-initials {
                margin: 0 !important;
              }
            }
            .comment-message {
              color: #404040;
              font-size: 14px;
              margin-left: 28px;
              line-height: 22px;
              margin-top: 3px;
            }
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-track {
              background: transparent;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: #828282;
            }
            > :last-child {
              margin-bottom: 5px;
            }
          }
          .block-message-area {
            display: flex;
            align-items: center;
            border: 0.5px solid #828282;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 7px 10px;
            background: #ffffff;
            .profile-redirect {
              margin: auto;
            }
          }
        }
        .block-comment-section {
          display: none;
        }
      }
      .block-comment-section {
        display: inline-flex;
        vertical-align: bottom;
        align-items: center;
        margin-left: 15px;
        margin-top: 4px;
        .block-comment-count {
          display: inline-flex;
          align-items: center;
          line-height: 21px;
          span {
            display: flex;
            align-items: center;
            color: #7A95B5;
            &.count {
              font-size: 10px;
              font-weight: 600;
              text-indent: 2px;
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
.ais-Pagination {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  .ais-Pagination-list {
    .ais-Pagination-item {
      .ais-Pagination-link {
        font-weight: 500;
        box-shadow: none;
        color: #00498D;
        border: none;
        padding: 0;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        display: flex;
        align-items: center;
        &:hover {
          border-color: #000000;
          box-shadow: none;
          background-image: none;
        }
      }
      &--selected {
        .ais-Pagination-link {
          cursor: default;
        }
      }
      &--disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: default;
      }
      &--previousPage {
        padding-right: 30px;
        .ais-Pagination-link {
          background-image: url('/static/img/icons/flexibly/blackarrow-left.svg') !important;
          background-repeat: no-repeat;
          background-position: center;
          color: transparent;
          border: 0;
          border-radius: 0px;
          padding: 0;
          background-size: contain;
          height: 10px;
          width: 10px;
        }
      }
      &--nextPage {
        padding-left: 30px;
        .ais-Pagination-link {
          background-image: url('/static/img/icons/flexibly/blackarrow-right.svg') !important;
          background-repeat: no-repeat;
          background-position: center;
          color: transparent;
          border: 0;
          border-radius: 0px;
          padding: 0;
          background-size: contain;
          height: 10px;
          width: 10px;
        }
      }
      &--selected {
        a.ais-Pagination-link {
          font-weight: 700 !important;
          color: #000000 !important;
        }
      }
      &:not(&--previousPage):not(&--nextPage) {
        border-right: 1.5px solid #000000;
        padding-right: 15px;
        padding-left: 15px;
      }
      &:nth-last-of-type(2) {
        border-right: 0 !important;
        padding-right: 0 !important;
      }
      &:nth-of-type(2) {
        padding-left: 0 !important;
      }
    }
  }
}
</style>
<style lang="scss">
.initiative-updates {
  .ais-SearchBox {
    max-width: 200px;
    top: 0;
    position: absolute;
    right: 0;
    .ais-SearchBox-form {
      box-shadow: none;
      height: 40px;
      border-radius: 3px;
      &::before {
        content: none;
      }
      &:hover {
        border-color: rgba(0, 0, 0, 0.87);
      }
      &:focus-within {
        border: 2px solid #2d9cdb;
      }
      .ais-SearchBox-input {
        font-size: 14px;
        padding: 0 16px;
        border: 0;
        box-shadow: none;
        background: #F8F7F7;
        &::placeholder {
          font-size: 14px;
          color: #BDBDBD;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
