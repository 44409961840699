<template>
  <div class="workplan-wrapper" :class="{ 'connect-cal' : openCalendarConnect,'onboarding-workplan-wrapper' : onboarding }">
    <div v-if="schedulesByDay" v-show="selectingDesk" class="desk-selection">
      <div class="tooltip-anchor" style="margin-left:auto;position:relative;"><slot name="tooltipDesk"></slot></div>
      <office-activity :ref="'officeActivity'" @tooltipDesk="$emit('tooltipDesk')" :location="schedulesByDay[advancedSettingsClickedIndex] ? schedulesByDay[advancedSettingsClickedIndex][0].locationId : false" :workplanDeskSelect="true" :selectedDesk="schedulesByDay && advancedSettingsClickedIndex ? schedulesByDay[advancedSettingsClickedIndex][0].desk : false" @setLocation="setDeskLocation" @close="selectingDesk = false" @setDesk="setDesk"></office-activity>
    </div>
    <div v-show="!selectingDesk">
    <header v-if="!onboarding" class="datedetails-header">
      <div class="plan-dates">
        <mdc-text tag="span" typo="body1">Workplan for</mdc-text>
        <mdc-text tag="span" typo="body1" class="week-dates">{{ getDate(scheduleSearchWeekDetail.startOfWeek, 'D') }} - {{ getDate(scheduleSearchWeekDetail.endOfWeek, 'D MMMM') }}</mdc-text>
        <mdc-text tag="span" typo="body1" class="submitted-text" v-if="allWorkPlanSubmitted">successfully submitted!</mdc-text>
      </div>
      <div class="applied-dates" v-if="currentWeek && useCurrentWeekClicked">
        <mdc-text tag="span" typo="caption" class="week-dates">Workplan {{ getDate(currentWeek.startOfWeek, 'D') }} - {{ getDate(currentWeek.endOfWeek, 'D MMM') }} applied</mdc-text>
      </div>
      <template>
        <div v-if="someWorkPlanSubmitted && isWeekNotPast()" class="edit-again-wrapper">
          <mdc-button :class="{ 'disabled' : preferencesSubmitting }" class="edit-again-btn" @click.native="editAgain()">{{ editAgainClick ? 'Reset' : 'Edit again' }}</mdc-button>
        </div>
        <div v-else-if="isWeekInFuture() && currentWeekWorkplanSubmitted && !allWorkPlanSubmitted && !isPastFutureBookingLimit" class="apply-reset-link clickable" @click="applyResetCurrentWeek()">
          <mdc-text tag="span" typo="caption" class="linktype-text">{{ useCurrentWeekClicked ? 'Reset' : 'Apply current week' }}</mdc-text>
        </div>
      </template>
    </header>
    <section class="all-preferences-wrapper" :class="((allWorkPlanSubmitted || !isWeekNotPast()) && (preferencesOpened && preferencesOpened.length === 0)) && !onboarding ? 'workplan-submitted' : 'not-submitted'">
      <div class="pref-type-wrapper when-where" :class="preferencesOpened.includes('locations') ? 'edit-mode' : 'collapsed'">
        <header class="type-header">
          <base-svg-icon class="type-icon" name="location-pin-404040" />
          <mdc-text tag="span" typo="caption">Where When</mdc-text>
          <base-svg-icon class="downward-line" name="downward-line" />
        </header>
        <div class="pref-details when-where">
          <div v-if="preferencesOpened.includes('locations')" class="location-pref-input-wrapper pref-input-wrapper">
            <div v-if="maxRequired || minRequired || showDateError || scheduleIssue" class="pattern-schedule">
              <span class="bullet" v-if="minOfficeRequirementNotMet || maxOfficeRequirementExceeded" :class="minOfficeRequirementNotMet || maxOfficeRequirementExceeded ? 'red' : 'grey'"></span>
              <mdc-text typo="caption" v-if="minOfficeRequirementNotMet" tag="span" class="red">Add more office days to meet your team's requested works pattern.</mdc-text>
              <mdc-text typo="caption" v-else-if="maxOfficeRequirementExceeded" tag="span" class="red">Reduce your office days to meet your team's requested works pattern.</mdc-text>
              <mdc-text typo="caption" v-else-if="showDateError" tag="span" class="red">This Workplan is in the past, <span class="red link" @click="goToCurrentWeek()">click here</span> for current week</mdc-text>
              <mdc-text typo="caption" v-else-if="scheduleIssue" tag="span" class="red">{{ scheduleIssue }}</mdc-text>
            </div>

            <mdc-text tag="span" typo="caption" v-else-if="Object.keys(myDefaultSchedule).length > 0" class="usedefault-link clickable" @click.native="useDefaultSchedules()" >{{ useDefaultSchedulesClicked ? 'Reset default schedule' : 'Use default schedule' }}</mdc-text>
            <mdc-text tag="span" typo="caption" v-else class="usedefault-link"></mdc-text>

            <div class="day-locations-wrapper">
              <div class="day-grid input-grid" v-if="scheduleSearchWeekDetail.datesOfWeek">
                <span class="days-text-wrapper" :class="[date === advancedSettingsClickedFor ? 'day-schedule' : '', isDatePast(date) ? 'past-date' : '']" v-for="(date, index) in scheduleSearchWeekDetail.datesOfWeek" :key="date + index">
                  <mdc-text typo="body2" tag="span">{{ formatDate(date) }}</mdc-text>
                  <base-svg-icon class="day-issue" v-if="schedulesByDay[index] && schedulesByDay[index][0].issue" name="issue" />
                </span>
              </div>
              <div class="locations-grid input-grid" role="grid">
                <div class="location-type" :class="[index === advancedSettingsClickedIndex ? 'dated-schedule' : '', isDatePast(date) ? 'past-date' : '', schedulesByDay[index] && schedulesByDay[index][0].issue ? 'issue' : '']" @click="openAdvancedSettings(date, index)" v-for="(date, index) in scheduleSearchWeekDetail.datesOfWeek" :key="date + index">
                  <div class="full-day" v-if="schedulesByDay[index] && schedulesByDay[index][0].type && schedulesByDay[index][0].type === 'full' && schedulesByDay[index][0].response" :class="[!schedulesByDay[index][0].defaultDay ? getBoxFillClass(schedulesByDay[index][0].response) : 'notset-fill']">
                    <base-svg-icon :name="getWorkingAtIcon(schedulesByDay[index][0].response)" />
                  </div>
                  <div class="split-day" v-else-if="schedulesByDay[index] && schedulesByDay[index][0].type && schedulesByDay[index][0].type === 'split' && schedulesByDay[index][0].amResponse && schedulesByDay[index][0].pmResponse">
                    <div class="am-wrapper slot-type" :class="[!schedulesByDay[index][0].defaultDay ? getBoxFillClass(schedulesByDay[index][0].amResponse) : 'notset-fill']">
                      <base-svg-icon :name="getWorkingAtIcon(schedulesByDay[index][0].amResponse)" />
                    </div>
                    <div class="pm-wrapper slot-type" :class="[!schedulesByDay[index][0].defaultDay ? getBoxFillClass(schedulesByDay[index][0].pmResponse) : 'notset-fill']">
                      <base-svg-icon :name="getWorkingAtIcon(schedulesByDay[index][0].pmResponse)" />
                    </div>
                  </div>
                  <div class="notset-day notset-fill" v-else>
                    <base-svg-icon name="plus-rounded-bdbdbd" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="advancedSettingsClickedFor" class="advanced-settings-wrapper" :class="isDatePast(advancedSettingsClickedFor) ? 'past-date' : ''">
              <div class="split-full-types">
                <div class="split-or-full-radios">
                  <mdc-radio :checked="schedulesByDay[advancedSettingsClickedIndex][0].type === 'full'" @click.native.stop="toggleSettingsDayType('full', true)" :value="schedulesByDay[advancedSettingsClickedIndex][0].type" name="radio-day-type" :label="'Full day'" />
                  <mdc-radio :checked="schedulesByDay[advancedSettingsClickedIndex][0].type === 'split'" @click.native.stop="toggleSettingsDayType('split', true)" :value="schedulesByDay[advancedSettingsClickedIndex][0].type" name="radio-day-type" :label="'Split day'" />
                </div>
              </div>
              <div class="selection-arrows-settings">
                <div class="day-type-wrapper">
                <template v-if="schedulesByDay[advancedSettingsClickedIndex][0].type === 'full'">
                  <div class="location-selection-wrapper">
                    <div class="type-selection-location">
                      <div class="location-selection-grid">
                        <button-with-image :buttonType="'image'" :tickSelect="schedule === schedulesByDay[advancedSettingsClickedIndex][0].response" :selected="schedule === schedulesByDay[advancedSettingsClickedIndex][0].response" :buttonText="schedule === 'thirdspace' ? '3rd space' : schedule === 'nonworking' ? 'Not working' : schedule" :imageName="schedulesByDay[advancedSettingsClickedIndex][0].response === schedule ? schedule : schedule+'-bdbdbd'" v-for="schedule in scheduleTypes" :key="schedule + 'loc-icon'"  @click.native="setDayTypeSchedule(schedule, 'fullDay')" />
                      </div>
                      <div>
                        <span class="select-location-field" v-if="schedulesByDay[advancedSettingsClickedIndex][0].response === 'office' || schedulesByDay[advancedSettingsClickedIndex][0].response === 'thirdspace'">
                          <div v-if="(schedulesByDay[advancedSettingsClickedIndex][0].response === 'thirdspace' && schedulesByDay[advancedSettingsClickedIndex][0].thirdspaceLocation) || (schedulesByDay[advancedSettingsClickedIndex][0].response === 'office' && schedulesByDay[advancedSettingsClickedIndex][0].officeLocation)">
                            <mdc-text tag="span" typo="caption">{{ schedulesByDay[advancedSettingsClickedIndex][0].response === 'thirdspace' ? 'Remote location:' : 'Office location:' }}</mdc-text>
                            <span class="location-place-value">
                              <base-svg-icon name="location-pin-bluegray" />
                              <template v-if="schedulesByDay[advancedSettingsClickedIndex][0].response === 'thirdspace' && schedulesByDay[advancedSettingsClickedIndex][0].thirdspaceLocation">
                                <mdc-text class="location-text" tag="span" typo="caption" @click.native="openLocationSettingInput(schedulesByDay[advancedSettingsClickedIndex][0].response, 'full')">{{ schedulesByDay[advancedSettingsClickedIndex][0].thirdspaceLocation }}</mdc-text>
                                <base-svg-icon class="clear-text" name="cross-thin-blue" clickable @click.native="setThirdspaceLocation('', 'full')" />
                              </template>
                              <template v-if="schedulesByDay[advancedSettingsClickedIndex][0].response === 'office' && schedulesByDay[advancedSettingsClickedIndex][0].officeLocation">
                                <mdc-text class="location-text" tag="span" typo="caption" @click.native.stop.capture="openLocationSettingInput(schedulesByDay[advancedSettingsClickedIndex][0].response, 'full')" v-html="schedulesByDay[advancedSettingsClickedIndex][0].officeLocation" />
                                <base-svg-icon class="clear-text" name="cross-thin-blue" clickable @click.native="setOfficeLocation('', 'full', '')" />
                              </template>
                            </span>
                          </div>
                          <div v-else  class="select-location-actions">
                            <base-svg-icon name="location-pin-4175b1" />
                            <mdc-button class="set-location-button" @click.native="openLocationSettingInput(schedulesByDay[advancedSettingsClickedIndex][0].response, 'full')">Select location</mdc-button>
                          </div>
                        </span>
                        <template v-if="schedulesByDay[advancedSettingsClickedIndex][0].response === 'office' && schedulesByDay[advancedSettingsClickedIndex][0].officeLocation">
                          <div class="desk-select" v-if="locationHasDesks(schedulesByDay[advancedSettingsClickedIndex][0].locationId)">
                            <div v-if="schedulesByDay[advancedSettingsClickedIndex][0].desk">
                              <mdc-text class="desk-text" tag="span" typo="caption">{{ schedulesByDay[advancedSettingsClickedIndex][0].desk }}</mdc-text>
                              <mdc-button elevated aria-label="select-desk" role="button" @click="selectingDesk = true" class="select-desk-btn">Edit Desk</mdc-button>
                            </div>
                            <mdc-button v-else elevated aria-label="select-desk" role="button" @click="selectingDesk = true" class="select-desk-btn">Select a Desk</mdc-button>
                          </div>
                        </template>
                      </div>
                      <div class="location-details" v-if="openLocationDetails && openLocationInputFor === 'full'">
                        <locations-details :date="advancedSettingsClickedFor" @emitClose="closeLocationDetails" @saveThirdspaceLocation="setThirdspaceLocation" @saveOfficeLocation="setOfficeLocation" :locationType="schedulesByDay[advancedSettingsClickedIndex][0].response" showFor="full" />
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="schedulesByDay[advancedSettingsClickedIndex][0].type === 'split'">
                  <div class="am-selection-wrapper location-selection-wrapper">
                    <mdc-text tag="span" typo="caption" class="selection-text">AM</mdc-text>
                    <div class="type-selection-location">
                      <div class="location-selection-grid">
                        <button-with-image :buttonType="'image'" :tickSelect="schedule === schedulesByDay[advancedSettingsClickedIndex][0].amResponse" :selected="schedule === schedulesByDay[advancedSettingsClickedIndex][0].amResponse" :buttonText="schedule === 'thirdspace' ? '3rd space' : schedule === 'nonworking' ? 'Not working' : schedule" :imageName="schedulesByDay[advancedSettingsClickedIndex][0].amResponse === schedule ? schedule : schedule+'-bdbdbd'" v-for="schedule in scheduleTypes" :key="schedule + 'loc-icon'"  @click.native="setDayTypeSchedule(schedule, 'AM')" />
                      </div>
                      <div>
                        <div class="select-location-field" v-if="schedulesByDay[advancedSettingsClickedIndex][0].amResponse === 'office' || schedulesByDay[advancedSettingsClickedIndex][0].amResponse === 'thirdspace'">
                          <span v-if="(schedulesByDay[advancedSettingsClickedIndex][0].amResponse === 'thirdspace' && schedulesByDay[advancedSettingsClickedIndex][0].splitThirdspaceLocation) || (schedulesByDay[advancedSettingsClickedIndex][0].amResponse === 'office' && schedulesByDay[advancedSettingsClickedIndex][0].splitOfficeLocation)">
                            <mdc-text tag="span" typo="caption">{{ schedulesByDay[advancedSettingsClickedIndex][0].amResponse === 'thirdspace' ? 'Remote location:' : 'Office location:' }}</mdc-text>
                            <span class="location-place-value">
                              <base-svg-icon name="location-pin-bluegray" />
                              <template  v-if="schedulesByDay[advancedSettingsClickedIndex][0].amResponse === 'thirdspace' && schedulesByDay[advancedSettingsClickedIndex][0].splitThirdspaceLocation">
                                <mdc-text class="location-text" tag="span" typo="caption" @click.native="openLocationSettingInput(schedulesByDay[advancedSettingsClickedIndex][0].amResponse, 'splitAM')">{{ schedulesByDay[advancedSettingsClickedIndex][0].amResponse === 'thirdspace' ? schedulesByDay[advancedSettingsClickedIndex][0].splitThirdspaceLocation : '' }}</mdc-text>
                                <base-svg-icon class="clear-text" name="cross-thin-blue" clickable @click.native="setThirdspaceLocation('', 'split')" />
                              </template>
                              <template v-if="schedulesByDay[advancedSettingsClickedIndex][0].amResponse === 'office' && schedulesByDay[advancedSettingsClickedIndex][0].splitOfficeLocation">
                                <mdc-text class="location-text" tag="span" typo="caption" @click.native.stop.capture="openLocationSettingInput(schedulesByDay[advancedSettingsClickedIndex][0].amResponse, 'splitAM')" v-html="schedulesByDay[advancedSettingsClickedIndex][0].splitOfficeLocation" />
                                <base-svg-icon class="clear-text" name="cross-thin-blue" clickable @click.native="setOfficeLocation('', 'split', '')" />
                              </template>
                            </span>
                          </span>
                          <div v-else  class="select-location-actions">
                            <base-svg-icon name="location-pin-4175b1" />
                            <mdc-button class="set-location-button" @click.native="openLocationSettingInput(schedulesByDay[advancedSettingsClickedIndex][0].amResponse, 'splitAM')">Select location</mdc-button>
                          </div>
                        </div>
                        <template v-if="schedulesByDay[advancedSettingsClickedIndex][0].amResponse === 'office' && schedulesByDay[advancedSettingsClickedIndex][0].splitOfficeLocation">
                          <div class="desk-select" v-if="locationHasDesks(schedulesByDay[advancedSettingsClickedIndex][0].locationId)">
                            <div v-if="schedulesByDay[advancedSettingsClickedIndex][0].desk">
                              <mdc-text class="desk-text" tag="span" typo="caption">{{ schedulesByDay[advancedSettingsClickedIndex][0].desk }}</mdc-text>
                              <mdc-button elevated aria-label="select-desk" role="button" @click="selectingDesk = true" class="select-desk-btn">Edit Desk</mdc-button>
                            </div>
                            <mdc-button v-else elevated aria-label="select-desk" role="button" @click="selectingDesk = true" class="select-desk-btn">Select a Desk</mdc-button>
                          </div>
                        </template>
                      </div>

                      <div class="location-details" v-if="openLocationDetails && openLocationInputFor === 'splitAM'">
                        <locations-details :date="advancedSettingsClickedFor" @emitClose="closeLocationDetails" @saveThirdspaceLocation="setThirdspaceLocation" @saveOfficeLocation="setOfficeLocation" :locationType="schedulesByDay[advancedSettingsClickedIndex][0].amResponse" showFor="split" />
                      </div>
                    </div>
                  </div>
                  <div class="pm-selection-wrapper location-selection-wrapper">
                    <mdc-text tag="span" typo="caption" class="selection-text">PM</mdc-text>
                    <div class="type-selection-location">
                      <div class="location-selection-grid">
                        <button-with-image :buttonType="'image'" :tickSelect="schedule === schedulesByDay[advancedSettingsClickedIndex][0].pmResponse" :selected="schedule === schedulesByDay[advancedSettingsClickedIndex][0].pmResponse" :buttonText="schedule === 'thirdspace' ? '3rd space' : schedule === 'nonworking' ? 'Not working' : schedule" :imageName="schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === schedule ? schedule : schedule+'-bdbdbd'" v-for="schedule in scheduleTypes" :key="schedule + 'loc-icon'"  @click.native="setDayTypeSchedule(schedule, 'PM')" />
                      </div>
                      <div>
                        <div class="select-location-field" v-if="schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === 'office' || schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === 'thirdspace'">
                          <span v-if="(schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === 'thirdspace' && schedulesByDay[advancedSettingsClickedIndex][0].splitThirdspaceLocation) || (schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === 'office' && schedulesByDay[advancedSettingsClickedIndex][0].splitOfficeLocation)">
                            <mdc-text tag="span" typo="caption">{{ schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === 'thirdspace' ? 'Remote location:' : 'Office location:' }}</mdc-text>
                            <span class="location-place-value">
                              <base-svg-icon name="location-pin-bluegray" />
                              <template  v-if="schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === 'thirdspace' && schedulesByDay[advancedSettingsClickedIndex][0].splitThirdspaceLocation">
                                <mdc-text class="location-text" tag="span" typo="caption" @click.native="openLocationSettingInput(schedulesByDay[advancedSettingsClickedIndex][0].pmResponse, 'splitPM')">{{ schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === 'thirdspace' ? schedulesByDay[advancedSettingsClickedIndex][0].splitThirdspaceLocation : '' }}</mdc-text>
                                <base-svg-icon class="clear-text" name="cross-thin-blue" clickable @click.native="setThirdspaceLocation('', 'split')" />
                              </template>
                              <template v-if="schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === 'office' && schedulesByDay[advancedSettingsClickedIndex][0].splitOfficeLocation">
                                <mdc-text class="location-text" tag="span" typo="caption" @click.native.stop.capture="openLocationSettingInput(schedulesByDay[advancedSettingsClickedIndex][0].pmResponse, 'splitPM')" v-html="schedulesByDay[advancedSettingsClickedIndex][0].splitOfficeLocation" />
                                <base-svg-icon class="clear-text" name="cross-thin-blue" clickable @click.native="setOfficeLocation('', 'split', '')" />
                              </template>
                            </span>
                          </span>
                          <div v-else  class="select-location-actions">
                            <base-svg-icon name="location-pin-4175b1" />
                            <mdc-button class="set-location-button" @click.native="openLocationSettingInput(schedulesByDay[advancedSettingsClickedIndex][0].pmResponse, 'splitPM')">Select location</mdc-button>
                          </div>
                        </div>
                        <template v-if="schedulesByDay[advancedSettingsClickedIndex][0].pmResponse === 'office' && schedulesByDay[advancedSettingsClickedIndex][0].splitOfficeLocation">
                          <div class="desk-select" v-if="locationHasDesks(schedulesByDay[advancedSettingsClickedIndex][0].locationId)">
                            <div v-if="schedulesByDay[advancedSettingsClickedIndex][0].desk">
                              <mdc-text class="desk-text" tag="span" typo="caption">{{ schedulesByDay[advancedSettingsClickedIndex][0].desk }}</mdc-text>
                              <mdc-button elevated aria-label="select-desk" role="button" @click="selectingDesk = true" class="select-desk-btn">Edit Desk</mdc-button>
                            </div>
                            <mdc-button v-else elevated aria-label="select-desk" role="button" @click="selectingDesk = true" class="select-desk-btn">Select a Desk</mdc-button>
                          </div>
                        </template>
                      </div>
                      <div class="location-details" v-if="openLocationDetails && openLocationInputFor === 'splitPM'">
                        <locations-details :date="advancedSettingsClickedFor" @emitClose="closeLocationDetails" @saveThirdspaceLocation="setThirdspaceLocation" @saveOfficeLocation="setOfficeLocation" :locationType="schedulesByDay[advancedSettingsClickedIndex][0].pmResponse" showFor="split" />
                      </div>
                    </div>
                  </div>
                </template>
                </div>
              </div>
            </div>
            <div class="help-text" v-if="helpText">
              <mdc-text tag="span" typo="caption">{{helpText}}</mdc-text>
            </div>
            <div class="default-next-wrapper">
              <div class="tooltip-anchor" style="margin-left: auto;position:relative;"><slot name="tooltipDefault"></slot></div>
              <div class="default-schedule-check">
                <base-svg-icon
                  name="checkbox_unchecked"
                  class="default-check-box"
                  v-if="!isDefaultTempoSelected"
                  clickable
                  @click.native="toggleDefaultTempoSelect(false)" />
                <base-svg-icon
                  name="checkbox_checked"
                  class="default-check-box"
                  v-if="isDefaultTempoSelected"
                  clickable
                  @click.native="toggleDefaultTempoSelect(true)"/>
                <mdc-text tag="span" typo="caption" class="make-default-text">Make default</mdc-text>
              </div>
              <div class="navigation-buttons">
                <div class="tooltip-anchor" style="margin-left:auto;position:relative;"><slot name="tooltipNext1"></slot></div>
                <mdc-button aria-label="schedule-next" v-if="scheduleComplete && locationsWithinCapacity" role="button" class="next-btn" @click.native="openPreferencesInput('people')">NEXT</mdc-button>
                <mdc-button aria-label="schedule-next" v-else disabled role="button" class="next-btn disabled">NEXT</mdc-button>
              </div>
            </div>
          </div>
          <div v-else class="locations preference">
            <template v-if="weeklySchedulesByDate.length > 0 && !weeklySchedulesByDate.every(schedule => !schedule.type || schedule.type === '')">
              <template v-for="(schedule, index) in weeklySchedulesByDate">
                <span class="answer" :class="{ 'full-day' : schedule.type && schedule.type === 'full', 'split-day' : schedule.type && schedule.type === 'split', 'notset-day' : !schedule.type, 'issue': schedule.issue }" :key="schedule.index + schedule.type + schedule.day">
                  <mdc-text typo="body1" tag="span" class="day-letter" :key="schedule.day + index">
                    {{ schedule.day.substring(0,1) }}
                  </mdc-text>
                  <!-- small grid based on type and response-->
                  <base-svg-icon v-if="schedule.type === 'full'" :name="getWorkingAtIcon(schedule.response)" class="response-icon" :class="[getWorkingAtClass(schedule.response), !schedule.defaultDay ? getBoxFillClass(schedule.response) : '', schedule.issue ? 'issue' : '']" />
                  <div class="split-day-grid" v-else-if="schedule.type === 'split'">
                    <div class="am-wrapper slot-type" :class="[!schedule.defaultDay ? getBoxFillClass(schedule.amResponse) : '',  schedule.issue ? 'issue' : '']">
                      <template v-if="schedule.amResponse">
                        <base-svg-icon class="response-icon" :name="schedule.amResponse" />
                      </template>
                    </div>
                    <div class="pm-wrapper slot-type" :class="[!schedule.defaultDay ? getBoxFillClass(schedule.pmResponse) : '',  schedule.issue ? 'issue' : '']">
                      <template v-if="schedule.pmResponse">
                        <base-svg-icon class="response-icon" :name="schedule.pmResponse" />
                      </template>
                    </div>
                  </div>
                  <div class="notset-day notset-fill" v-else>
                    <base-svg-icon name="plus-rounded-bdbdbd" />
                  </div>
                  <base-svg-icon class="day-issue" v-if="schedule && schedule.issue" name="issue" />
                </span>
              </template>
              <mdc-text @click.native="useCurrentWeekSchedules()" v-if="displayUseCurrent()" tag="span" typo="caption" class="locations-use-link clickable">{{ useCurrentSchedulesClicked ? 'Reset' : 'Use current' }}</mdc-text>
            </template>
            <template v-else>
              <mdc-text tag="span" typo="caption" class="add-type-text">{{ isWeekNotPast() ? 'Add locations you want to work from' : 'No locations added' }}</mdc-text>
            </template>
          </div>
          <base-svg-icon v-if="(!allWorkPlanSubmitted || editAgainClick) && isWeekNotPast() && !onboarding" @click.native="openPreferencesInput('locations')" class="arrow-icon" :name="preferencesOpened.includes('locations') ? 'arrow-up-a5b4c4' : 'arrow-down-a5b4c4'" clickable />
        </div>
      </div>
      <div class="pref-type-wrapper who-with" :class="preferencesOpened.includes('people') ? 'edit-mode' : 'collapsed'">
        <header class="type-header">
          <base-svg-icon class="type-icon" name="people-404040" />
          <mdc-text tag="span" typo="caption">Who With</mdc-text>
          <base-svg-icon class="downward-line" name="downward-line" />
        </header>
        <div class="pref-details who-with">
          <div v-if="preferencesOpened.includes('people')" class="people-pref-input-wrapper pref-input-wrapper">
            <template v-if="inviteEmailClicked">
              <div class="send-email-wrapper people-info-wrapper" role="group" aria-label="working-group">
                <div class="heading-invite-wrapper">
                  <mdc-text tag="span" typo="body2 heading">Send email invites</mdc-text>
                </div>
                <textarea v-model="emailList" placeholder="Type email addresses separated by commas" class="invites-txtarea"></textarea>
                <mdc-text class="help-text" tag="span" typo="caption" v-if="helpText">{{ helpText }}</mdc-text>
                <div class="send-invites-actions">
                  <mdc-button class="btn-text inv-back-btn" @click.native="closeSendEmail">Back</mdc-button>
                  <mdc-button class="btn-text inv-send-btn" @click.native="sendEmailInvites" :class="!emailList || sendingInvite ? 'disabled' : ''">Send</mdc-button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="team-people-wrapper people-info-wrapper" role="group" aria-label="working-group">
                <div class="heading-invite-wrapper">
                  <mdc-text tag="span" typo="body2 heading">People you work with</mdc-text>
                  <mdc-text tag="span" typo="body2" class="invite-link clickable" @click.native="openSendEmail">{{ $teamLeader() ? 'Invite by email' : '' }}</mdc-text>
                </div>
                <v-select @search="handlePeopleSearch" :dropdown-should-open="peopleDropdownShouldOpen" v-model="peopleInput" ref="peopleSelect" placeholder="Type to find and add people" class="people-select" :options="sortedAvailableProfiles" :reduce="peopleProfile => peopleProfile.id" label="displayName" @input="setSearchedPeople">
                  <div slot="open-indicator">
                  </div>
                  <template #selected-option="{ displayName }">
                    <strong>{{ displayName }}</strong>
                  </template>
                  <template #no-options="{ search }">
                    <div class="no-options-detail">
                      <mdc-text tag="span" typo="body1" class="searched-txt">{{ search }}</mdc-text>
                      <mdc-text tag="span" :class="sendingInvite ? 'disabled' : ''" v-if="isPeopleInputEmail" typo="body2" class="no-option-link clickable" @click.native="sendEmailInvites(search)">{{ $teamLeader() ? 'Invite by email' : '' }}</mdc-text>
                      <mdc-text tag="span" :class="sendingInvite ? 'disabled' : ''" v-else typo="body2" class="no-option-link clickable" @click.native="openSendEmail">{{ $teamLeader() ? 'Not found. Send email invite >' : 'Not found' }}</mdc-text>
                    </div>
                  </template>
                </v-select>
                  <!-- <mdc-text tag="span" typo="body2 link" @click.native="selectAllWorkingWithProfiles()">{{ allWorkingWithProfilesSelected() ? 'Deselect' : 'Select' }} all</mdc-text> -->
                <div class="people-buttons" v-if="peopleAnswers.length > 0 || profilesWorkWith().length > 0">
                  <template v-for="peopleId in peopleAnswers">
                    <template v-if="allTypesProfiles().findIndex(profile => peopleId === profile.id) === -1 && !meetingUser(peopleId)">
                      <button-with-image tickSelect :profileId="peopleId" :selected="peopleAnswers.includes(peopleId)" :buttonType="'profile'" @click.native="setSelectedPeople(peopleId)" :key="'paa-' + peopleId"/>
                    </template>
                  </template>
                  <template v-if="showMorePeopleClicked">
                    <button-with-image tickSelect :profileId="profile.id" v-for="profile in profilesWorkWith()" :selected="peopleAnswers.findIndex(peopleId => peopleId === profile.id) !== -1" :buttonType="'profile'" @click.native="setSelectedPeople(profile.id)" :key="'pw-' + profile.id"/>
                  </template>
                  <template v-else>
                    <button-with-image tickSelect :profileId="profile.id" v-for="profile in profilesWorkWith().slice(0, workWithPeopleCount)" :selected="peopleAnswers.findIndex(peopleId => peopleId === profile.id) !== -1" :buttonType="'profile'" @click.native="setSelectedPeople(profile.id)" :key="'pw-' + profile.id"/>
                  </template>
                </div>
                <div class="no-members-text" v-else>
                  <button-with-image v-for="i in 2" :key="'no-avatar' + i" profileSkeleton />
                </div>
                <div class="invitions-sent-wrapper" v-if="emailInvites.length > 0">
                  <mdc-text tag="span" typo="caption" class="invite-heading">Invitations sent to:</mdc-text>
                  <div class="invites-list">
                    <mdc-text tag="span" typo="caption" class="invite-email">
                      {{ emailInvites.map(email => email).join(', ') }}
                    </mdc-text>
                  </div>
                </div>
              </div>
              <div class="show-more-wrapper" v-if="profilesWorkWith().length > workWithPeopleCount">
                <mdc-text tag="span" v-if="profilesWorkWith().length > workWithPeopleCount && !showMorePeopleClicked" typo="body2 show-more-link" @click.native="showPeopleWorkingWith()">Show more people</mdc-text>
                <mdc-text tag="span" v-else-if="showMorePeopleClicked" typo="body2 show-more-link" @click.native="showPeopleWorkingWith()">Show less people</mdc-text>
              </div>
              <div class="meeting-people-wrapper people-info-wrapper" role="group" aria-label="meeting-group">
                <mdc-text tag="span" typo="body2 heading">People you're meeting with</mdc-text>
                <template v-if="!meetingPeopleLoading && meetingWithUsers && meetingWithUsers.length > 0">
                  <mdc-text tag="span" typo="body2 link" @click.native="selectAllMeetingWithProfiles()">{{ allMeetingWithProfilesSelected() ? 'Deselect' : 'Select' }} all</mdc-text>
                  <div class="people-buttons">
                    <button-with-image tickSelect :profileId="profile.id" :selected="peopleAnswers.findIndex(personId => personId === profile.id) !== -1 " :buttonType="'profile'" @click.native="setSelectedPeople(profile.id)" v-for="(profile, index) in meetingWithUsers" :key="'mw-' + profile.id + index"/>
                  </div>
                </template>
                <div class="loading-text" v-else-if="meetingPeopleLoading">
                  <mdc-text tag="span" typo="caption">Loading...</mdc-text>
                </div>
                <div class="no-members-text meeting-members" v-else>
                  <div v-if="connectCalendar || onboarding" class="connect-cal-options"> <!-- both needs reconnect or cal without subscription -->
                    <button-with-image v-for="i in 2" :key="'no-avatar' + i" profileSkeleton />
                    <div class="tooltip-anchor" style="position:relative;">
                      <slot name="tooltipCalendar"></slot>
                    </div>
                    <base-svg-icon v-if="connectCalendar || (profile.googleCalendarsSelected && profile.googleCalendarsSelected.length === 0)" name="google-logo" class="cal-logo clickable" @click.native.stop="navigateSettingsConnectCal('google')" />
                    <base-svg-icon v-if="connectCalendar || (profile.calendarsSelected && profile.calendarsSelected.length === 0)" name="microsoft-logo" class="cal-logo clickable" @click.native.stop="navigateSettingsConnectCal('MS')" />
                  </div>
                  <mdc-text v-else typo="body2" tag="span" class="meeting-no-one-text">Not meeting anyone</mdc-text>
                </div>
                <calendar-connect v-show="openCalendarConnect" @nextWeekImportCompleted="profilesHavingMeetingWith" :modalDisplay="openCalendarConnect !== ''" :connectCal="openCalendarConnect" @closeModal="emitFromCalConn"/>
              </div>
              <div class="navigation-buttons">
                <mdc-button aria-label="people-back" role="button" class="back-btn" @click.native="openPreferencesInput('locations')">BACK</mdc-button>
                <div class="tooltip-anchor" style="margin-left:auto;position:relative;"><slot name="tooltipNext2"></slot></div>
                <mdc-button aria-label="people-next" role="button" class="next-btn" @click.native="openPreferencesInput('topics')">NEXT</mdc-button>
              </div>
            </template>
          </div>
          <div class="people preference" v-else>
            <template v-if="peopleAnswers.length > 0">
              <template v-if="peopleAnswers.length > peopleAnswersCount">
                <profile-initials v-for="peopleId in peopleAnswers.slice(0, peopleAnswersCount)" :key="'pa-' + peopleId" :profileId="peopleId" :date="appDate"/>
                <mdc-text tag="span" typo="body1" class="load-members-count">+{{peopleAnswers.length - peopleAnswersCount}}</mdc-text>
              </template>
              <template v-else>
                <profile-initials v-for="peopleId in peopleAnswers" :key="'pa-' + peopleId" :profileId="peopleId" :date="appDate"/>
              </template>
            </template>
            <mdc-text v-else tag="span" typo="caption" class="add-type-text">{{ isWeekNotPast() ? 'Add people you want to work with' : 'No people added' }}</mdc-text>
          </div>
          <base-svg-icon v-if="(!allWorkPlanSubmitted || editAgainClick) && isWeekNotPast() && !onboarding" @click.native="openPreferencesInput('people')" class="arrow-icon" :name="preferencesOpened.includes('people') ? 'arrow-up-a5b4c4' : 'arrow-down-a5b4c4'" clickable />
        </div>
      </div>
      <div class="pref-type-wrapper what-work" :class="preferencesOpened.includes('topics') ? 'edit-mode' : 'collapsed'">
        <header class="type-header">
          <base-svg-icon class="type-icon" name="rocket-black" />
          <mdc-text tag="span" typo="caption">What Work</mdc-text>
          <base-svg-icon class="downward-line" name="downward-line" />
        </header>
        <div class="pref-details what-work">
          <div v-if="preferencesOpened.includes('topics')" class="topics-pref-input-wrapper pref-input-wrapper">
            <template v-if="createNewInitClicked || displayInitForm()">
              <create-initiative @closeInit="closeNewInitiativeForm" showBack>
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                  <slot :name="name" v-bind="slotData"></slot>
                </template>
              </create-initiative>
            </template>
            <template v-else>
              <div class="input-and-link-wrapper">
                <v-select :dropdown-should-open="topicsDropdownShouldOpen" v-model="topicInput"  ref="topicSelect" placeholder="Type to find and add initiatives" class="topics-select" :options="workspaceInitiatives.filter(initiative => !topicsAnswers.includes(initiative.id))" :reduce="initiative => initiative.id" label="name" @input="setSearchedTopic">
                  <div slot="open-indicator">
                  </div>
                  <template #option="{ name, teamId }">
                    <mdc-text typo="caption" tag="span"># {{ teamId !== profile.team ? getTeamName(teamId): ''}}{{name}}</mdc-text>
                  </template>
                </v-select>
                <mdc-text tag="span" class="clickable create-link" typo="body2" @click.native="openNewInitiativeForm()">Create new</mdc-text>
              </div>

              <div class="no-initiatives" v-if="(!personalInitiatives || personalInitiatives.length === 0) && (topicsAnswers.length === 0)">
                  <button-with-image v-for="i in 2" :key="'no-avatar' + i" initiativeSkeleton />
              </div>

              <div class="tooltip-anchor" style="position:absolute;"><slot name="tooltipInitiativeAdd"></slot></div>

              <div v-if="initiatives && (teamInitiatives.length > 0 || personalInitiatives.length > 0 || topicsAnswers.length > 0)" class="topics-info-wrapper" role="group" aria-label="topics-group">
                <mdc-text tag="span" typo="body2 heading">Your initiatives</mdc-text>
                <div class="topics-buttons">
                  <template v-for="initiative in initiativeButtons">
                    <button-with-image tickSelect :buttonText="initiative.name" :title="getFullTopicName(initiative.id)" :selected="hasTopic(initiative.id)" @click.native="setSelectedTopics(initiative.id)" :key="'init-btn-' + initiative.id" />
                  </template>
                </div>
              </div>

              <div class="navigation-buttons">
                <mdc-button aria-label="topics-back" role="button" class="back-btn" @click.native="openPreferencesInput('people')">BACK</mdc-button>
              </div>
            </template>
          </div>
          <div class="topics preference" v-else>
            <template v-if="topicsAnswers.length > 0">
              <div class="all-topics-count-wrapper" v-if="topicsAnswers.length > topicsAnswersCount">
                <div class="all-topics-list">
                  <mdc-text v-for="(topicId, index) in topicsAnswers.slice(0, topicsAnswersCount)" class="topic-name" :key="'topic-' + topicId" :title="getFullTopicName(topicId)" tag="span" typo="caption">
                    {{ getTopicName(topicId) }}{{ index === topicsAnswersCount - 1 ? '': ','}}
                  </mdc-text>
                </div>
                <mdc-text tag="span" typo="body1" class="load-topics-count">+{{topicsAnswers.length - topicsAnswersCount}}</mdc-text>
              </div>
              <template v-else>
                <mdc-text v-for="(topicId, index) in topicsAnswers" class="topic-name" :key="'topic-' + topicId" :title="getFullTopicName(topicId)" tag="span" typo="caption">
                  {{ getTopicName(topicId) }}{{ index === topicsAnswers.length - 1 ? '': ','}}
                </mdc-text>
              </template>
            </template>
            <mdc-text v-else tag="span" typo="caption" class="add-type-text">{{ isWeekNotPast() ? 'Add initiatives you want to work upon' : 'No initiatives added' }}</mdc-text>
          </div>
          <base-svg-icon v-if="(!allWorkPlanSubmitted || editAgainClick) && isWeekNotPast() && !onboarding" @click.native="openPreferencesInput('topics')" class="arrow-icon" :name="preferencesOpened.includes('topics') ? 'arrow-up-a5b4c4' : 'arrow-down-a5b4c4'" clickable />
        </div>
      </div>
    </section>
    <div class="sticky-footer" v-show="showStickyFooter && preferencesChanged && isWeekNotPast() && !isPastFutureBookingLimit">
      <mdc-button class="sticky-submit-btn" :class="{ 'disabled' : preferencesSubmitting || !preferencesChanged || !locationsWithinCapacity }" @click.native="submitAllPreferences()">Submit</mdc-button>
    </div>
    <div class="button-wrapper" v-if="isWeekNotPast() && !isPastFutureBookingLimit">
      <div class="tooltip-anchor tooltip-anchor-submit" style="margin-left:auto;position:relative;"><slot name="tooltipSubmit"></slot></div>
      <mdc-button class="submit-btn" :class="{ 'disabled' : preferencesSubmitting || !preferencesChanged || !locationsWithinCapacity }" @click.native="submitAllPreferences()">Submit</mdc-button>
    </div>
    <template v-if="!onboarding">
      <!--show past week error text or best office days link -->
      <div class="pastweek-error-text" v-if="isPastFutureBookingLimit">
        <mdc-text typo="caption" tag="span" class="red">You can only submit up to {{workspace.futureBookingLimit}} weeks in the future</mdc-text>
      </div>
      <div class="pastweek-error-text" v-if="showDateError">
        <mdc-text typo="caption" tag="span" class="red">This Workplan is in the past, <span class="red link" @click="goToCurrentWeek()">click here</span> for current week</mdc-text>
      </div>
      <div class="submitted-text-bodlink" v-else-if="allWorkPlanSubmitted">
        <mdc-text tag="span" typo="body1" class="recomm-text">You have recommendations waiting! Go to </mdc-text>
        <mdc-text tag="span" typo="body1" class="bod-link-text clickable" @click.native="goToBOD()">Best Office Days</mdc-text>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import LocationsDetails from './LocationsDetails.vue'
import ProfileInitials from './ProfileInitials.vue'
import ButtonWithImage from './ButtonWithImage.vue'
import CreateInitiative from './CreateInitiative.vue'
import OfficeActivity from './OfficeActivity.vue'
import { dayDateFormat, inFuture, inPast } from '../../utils/time'
import moment from 'moment'
import fb from '../../store/firebase'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import isEqual from 'lodash/isEqual'
import { getResponseIconName } from '../../utils/profile'
import { onboarding, mail } from '../../utils/backend'
import CalendarConnect from './CalendarConnect.vue'
const _ = { orderBy, groupBy, sortBy, isEqual }

export default {
  name: 'Workplan',
  components: {
    BaseSvgIcon,
    LocationsDetails,
    ProfileInitials,
    ButtonWithImage,
    CreateInitiative,
    CalendarConnect,
    OfficeActivity
  },
  props: {
    showDate: {},
    openLocation: {
      type: Boolean,
      default: false
    },
    openDate: {
    },
    useDefault: {
      type: Boolean,
      default: false
    },
    useLast: {
      type: Boolean,
      default: false
    },
    dateInvalid: {
      type: Boolean,
      default: false
    },
    onboarding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      createNewInitClicked: false,
      sendingInvite: false,
      inviteSent: false,
      topicsAnswersCount: 4,
      peopleAnswersCount: 10,
      preferencesSubmitting: false,
      useDefaultSchedulesClicked: false,
      currentWeek: null,
      showStickyFooter: false,
      helpText: '',
      windowWidth: window.innerWidth,
      days: [
        'MON', 'TUE', 'WED', 'THU', 'FRI'
      ],
      preferencesOpened: [],
      officeLocations: [],
      openLocationInputFor: '',
      openLocationDetails: false,
      useCurrentWeekClicked: false,
      myWeeklySchedules: [],
      myDefaultSchedules: [],
      weeklySchedulesByDate: [],
      officeDays: [],
      officeHalfDays: [],
      homeDays: [],
      nonWorkingDays: [],
      thirdSpaceDays: [],
      defaultDaysLoaded: [],
      schedulesByDay: {},
      advancedSettingsClickedFor: null,
      advancedSettingsClickedIndex: null,
      isDefaultTempoSelected: false,
      scheduleTypes: [
        'office',
        'home',
        'thirdspace',
        'nonworking'
      ],
      showMorePeopleClicked: false,
      workWithPeopleCount: null,
      meetingPeopleLoading: false,
      topicInput: '',
      peopleInput: '',
      meetingWithUsers: [],
      topicsAnswers: [],
      peopleAnswers: [],
      selectedWeeklyPreferences: {},
      preferencesChanged: false,
      editAgainClick: false,
      useCurrentSchedulesClicked: false,
      showDateError: false,
      inviteEmailClicked: false,
      emailList: '',
      emailInvites: [],
      openCalendarConnect: false,
      selectingDesk: false,
      scheduleIssue: false
    }
  },
  beforeDestroy () {
    this.saveEditingSchedule()
    if (window) {
      window.removeEventListener('resize', this.resizeListener)
    }
    const appLayout = document.querySelector('.mdc-layout-app')
    if (appLayout) {
      appLayout.removeEventListener('scroll', () => {
        this.checkSubmitButton()
      })
    }
  },
  created () {
    this.profilesHavingMeetingWith()
  },
  computed: {
    ...mapState([
      'workspace',
      'profile',
      'appDate',
      'scheduleSearchWeekDetail',
      'user'
    ]),
    ...mapState({
      locations: state => state.teams.locations,
      defaultSchedules: state => state.teams.defaultSchedules,
      currentSchedules: state => state.teams.currentSchedules,
      schedules: state => state.teams.schedules,
      initiatives: state => state.teams.initiatives,
      personalInitiatives: state => state.teams.personalInitiatives,
      workspaceInitiatives: state => state.teams.workspaceInitiatives,
      profiles: state => state.profiles,
      currentTopicsAndPeople: state => state.teams.currentTopicsAndPeople,
      weekPreferences: state => state.teams.preferences,
      meetings: state => state.teams.meetings,
      usersMeeting: state => state.teams.usersMeeting,
      teamProfiles: state => state.teams.teamProfiles,
      tempos: state => state.teams.tempos,
      teams: state => state.teams.teams,
      editingSchedule: state => state.teams.editingSchedule,
      subscriptions: state => state.teams.subscriptions,
      myTeam: state => state.teams.team,
      requests: state => state.teams.requests
    }),
    teamInitiatives () {
      return this.initiatives.filter((initiative) => {
        return initiative.teamId === this.profile.team
      })
    },
    connectCalendar () {
      if (!this.profile.msEmail && !this.profile.googleEmail) {
        // No ms or google emails on profile - not connected
        return true
      }
      if (this.subscriptions && this.subscriptions.length === 0) {
        // No active calendar subscriptions
        return true
      }
      if (this.msNeedsReconnect || this.googleNeedsReconnect) {
        // one or more calendar subscriptions needs to be reconnected
        return true
      }
      return false
    },
    msNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type !== 'Google') {
              // type MS or blank
              if (sub.msNeedsReconnect) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    googleNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type === 'Google') {
              // type Google
              if (sub.googleNeedsReconnect) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    initiativeButtons () {
      const personalInitiatives = this.personalInitiatives.length > 0 ? this.personalInitiatives : []
      let buttons
      if ((personalInitiatives.length === 0) && (this.topicsAnswers.length === 0)) {
        buttons = [...this.teamInitiatives]
      } else {
        buttons = [
          ...this.topicsAnswers.map(topicId => { return { id: topicId, name: this.getTopicName(topicId) } }),
          ...personalInitiatives.filter(init => this.topicsAnswers.indexOf(init.id) === -1)
        ]
      }
      return buttons.sort((a, b) => {
        if (a.name === b.name) {
          return (a.id > b.id) ? 1 : -1
        }
        return (a.name > b.name) ? 1 : -1
      })
    },
    sortedAvailableProfiles () {
      let sortedProfiles = [...this.profiles].filter(peopleProfile => !this.peopleAnswers.includes(peopleProfile.id))
      sortedProfiles.forEach(profile => {
        profile.nameEmail = `${profile.displayName} - ${profile.email}`
      })
      sortedProfiles = sortedProfiles.sort((a, b) => {
        if (!a.displayName) {
          a.displayName = ''
        }
        if (!b.displayName) {
          b.displayName = ''
        }
        if (a.displayName < b.displayName) return -1
        return a.displayName > b.displayName ? 1 : 0
      })
      return sortedProfiles
    },
    isPastFutureBookingLimit () {
      if (this.workspace && this.workspace.futureBookingLimit) {
        if (this.scheduleSearchWeekDetail && this.scheduleSearchWeekDetail.startOfWeek && this.scheduleSearchWeekDetail.weekNumber) {
          let nextYearModifier = 0
          const startWeek = this.scheduleSearchWeekDetail.startOfWeek
          if (startWeek.year() !== moment().year()) {
            nextYearModifier += 52
          }
          const weekLimit = moment().week() + this.workspace.futureBookingLimit
          console.log('isPastFutureBookingLimit', this.scheduleSearchWeekDetail.weekNumber, nextYearModifier, weekLimit)
          if ((this.scheduleSearchWeekDetail.weekNumber + nextYearModifier) > weekLimit) {
            return true
          }
        }
      }
      return false
    },
    officeCount () {
      let count = 0
      count = this.officeDays.length
      return count
    },
    officeHDCount () {
      let count = 0
      count = this.officeHalfDays.length / 2
      return count
    },
    scheduleTempo () {
      if (this.tempos && this.profile) {
        let preferences = false
        // console.log('WeeklyPreferences.scheduleTempo', this.workspace)
        if (this.workspace && this.workspace.schedulesSetByWorkspace) {
          preferences = this.tempos.filter(tempo => tempo.id === 'workspace-schedules')
        } else {
          preferences = this.tempos.filter(tempo => tempo.type === 'schedules' && tempo.teamId === this.profile.team)
        }
        if (preferences && preferences[0]) {
          return preferences[0]
        }
      }
      return null
    },
    minRequired () {
      if (this.scheduleTempo) {
        if (this.scheduleTempo.pattern) {
          if (this.scheduleTempo.pattern.isOpted) {
            if (this.scheduleTempo.pattern.min) {
              if (this.scheduleTempo.pattern.min !== 'None') {
                return this.scheduleTempo.pattern.min
              }
            }
          }
        }
      }
      return false
    },
    maxRequired () {
      if (this.scheduleTempo) {
        if (this.scheduleTempo.pattern) {
          // console.log('weeklyPreferences.maxRequired', this.scheduleTempo.id, this.scheduleTempo.pattern.isOpted, this.scheduleTempo.pattern.max)
          if (this.scheduleTempo.pattern.isOpted) {
            if (this.scheduleTempo.pattern.max) {
              if (this.scheduleTempo.pattern.max !== 'None') {
                return parseInt(this.scheduleTempo.pattern.max) + 1
              }
            }
          }
        }
      }
      return false
    },
    minOfficeRequirementNotMet () {
      if (this.scheduleTempo) {
        if (this.scheduleTempo.pattern) {
          if (this.scheduleTempo.pattern.isOpted) {
            if (this.scheduleTempo.pattern.min) {
              if (this.scheduleTempo.pattern.min !== 'None') {
                // console.log('minOfficeRequirementNotMet', this.scheduleTempo.pattern.min, this.officeCount, this.officeHDCount)
                return this.officeHDCount < this.scheduleTempo.pattern.min
              }
            }
          }
        }
      }
      return false
    },
    maxOfficeRequirementExceeded () {
      if (this.scheduleTempo) {
        if (this.scheduleTempo.pattern) {
          if (this.scheduleTempo.pattern.isOpted) {
            if (this.scheduleTempo.pattern.max) {
              if (this.scheduleTempo.pattern.max !== 'None') {
                // console.log('maxOfficeRequirementExceeded', this.scheduleTempo.pattern.min, this.officeCount, this.officeHDCount)
                return this.officeHDCount > this.scheduleTempo.pattern.max
              }
            }
          }
        }
      }
      return false
    },
    allProfiles () {
      if (this.profile && this.profiles) {
        return [this.profile, ...this.profiles]
      }
      return []
    },
    filteredTeamProfiles () {
      return this.teamProfiles.filter(profile => profile.id !== this.profile.id)
    },
    myDefaultSchedule () {
      if (this.defaultSchedules) {
        const myDefaultSchedules = [...this.defaultSchedules].filter(schedule => { return schedule.fromId === this.profile.id })
        if (myDefaultSchedules.length > 0) {
          return myDefaultSchedules[0]
        } else {
          return {}
        }
      }
      return {}
    },
    myCurrentWeekSchedule () {
      if (this.currentSchedules) {
        const mySchedules = [...this.currentSchedules].filter(schedule => { return schedule.fromId === this.profile.id })
        if (mySchedules.length > 0) {
          return mySchedules[0]
        } else {
          return {}
        }
      }
      return {}
    },
    mySchedule () {
      if (this.schedules) {
        const mySchedules = [...this.schedules].filter(schedule => { return schedule.fromId === this.profile.id })
        if (mySchedules.length > 0) {
          return mySchedules[0]
        } else {
          return {}
        }
      }
      return {}
    },
    scheduleComplete () {
      // only for onboarding
      if (this.onboarding) {
        let completed = 0
        if (this.schedulesByDay) {
          for (const scheduleDayArr of Object.values(this.schedulesByDay)) {
            const scheduleDay = scheduleDayArr[0]
            // no location selected, or if location has desks then desk selected.
            let deskSelectionValid = true
            const inOffice = (scheduleDay.type === 'full' && scheduleDay.resposne === 'office') ||
            (scheduleDay.type === 'split' && (scheduleDay.amResponse === 'office' || scheduleDay.pmResponse === 'office'))
            if (scheduleDay.locationId && inOffice) {
              if (this.locationHasDesks(scheduleDay.locationId)) {
                if (!scheduleDay.desk) {
                  deskSelectionValid = false
                }
              }
            }
            if (deskSelectionValid) {
              if (scheduleDay.type === 'full' && scheduleDay.response && scheduleDay.response !== '') {
                completed++
              } else if (scheduleDay.type === 'split' && scheduleDay.pmResponse && scheduleDay.pmResponse !== '' && scheduleDay.amResponse && scheduleDay.amResponse !== '') {
                completed++
              }
            }
          }
        }
        if (completed >= 5) {
          return true
        }
        return false
      }
      return true
    },
    myWeekPreferences () {
      if (this.weekPreferences && this.weekPreferences.length > 0) {
        return { ...this.weekPreferences[0] }
      }
      return {}
    },
    myCurrentWeekTopicsAndPeople () {
      if (this.currentTopicsAndPeople && this.currentTopicsAndPeople.length > 0) {
        return { ...this.currentTopicsAndPeople[0] }
      }
      return {}
    },
    someWorkPlanSubmitted () {
      return this.mySchedule.submittedOn || this.myWeekPreferences.lastSuggestionDate
    },
    allWorkPlanSubmitted () {
      return this.mySchedule.submittedOn && this.myWeekPreferences.lastSuggestionDate
    },
    currentWeekWorkplanSubmitted () {
      return this.myCurrentWeekSchedule.submittedOn || this.myCurrentWeekTopicsAndPeople.lastSuggestionDate
    },
    locationsWithinCapacity () {
      return this.allLocationsValid()
    },
    confirmationDate () {
      if (this.tempos && this.workspace) {
        const byWorkspace = this.workspace.schedulesSetByWorkspace
        let tempo
        if (byWorkspace) {
          tempo = this.tempos.filter(tmp => tmp.id === 'workspace-schedules')[0]
        } else {
          tempo = this.tempos.filter(tmp => tmp.teamId === this.profile.team && tmp.type === 'schedules')[0]
        }
        if (tempo) {
          const nextConfirm = moment(this.scheduleSearchWeekDetail.selectedDay)
          nextConfirm.isoWeekday(tempo.confirmWhenTimings.day)
          nextConfirm.hours(tempo.confirmWhenTimings.time.split(':')[0])
          nextConfirm.minutes(tempo.confirmWhenTimings.time.split(':')[1])
          if (tempo.frequency === 'weekBefore') {
            nextConfirm.add(-1, 'weeks')
          } else {
            nextConfirm.add(-2, 'weeks')
          }
          return nextConfirm
        }
      }
      return false
    }
  },
  mounted () {
    if (window) {
      window.addEventListener('resize', this.resizeListener)
    }
    const appLayout = document.querySelector('.mdc-layout-app')
    if (appLayout) {
      appLayout.addEventListener('scroll', () => {
        this.checkSubmitButton()
      })
    }
    this.calculateWorkWithPeopleCount()
    this.calculatePeopleAnswersCount()
    this.calculateTopicsAnswersCount()
  },
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    ...mapActions({
      getNextMeetingWithUser: 'teams/getNextMeetingWithUser',
      saveSchedule: 'teams/saveSchedule',
      saveDefaultSchedule: 'teams/saveDefaultSchedule',
      setBestDaysCalculating: 'teams/setBestDaysCalculating',
      saveTopicsAndPeople: 'teams/saveTopicsAndPeople',
      setEditingSchedule: 'teams/setEditingSchedule',
      setScheduleSearchWeekDetail: 'setScheduleSearchWeekDetail',
      updateRequest: 'teams/updateRequest'
    }),
    resetTooltipType () {
      if (this.$refs.officeActivity) {
        this.$refs.officeActivity.resetTooltipType()
      }
    },
    emitFromCalConn (from) {
      if (from !== 'close') {
        this.meetingPeopleLoading = true
      }
      this.closeCalendarConnect()
    },
    setDeskLocation (val) {
      // console.log('setDeskLocation', val)
      const schDay = this.schedulesByDay[this.advancedSettingsClickedIndex][0]
      if (val.location) {
        if (val.location.id !== schDay.locationId) {
          const text = this.getLocationName(val.location.id)
          const type = val.location.type
          const shownFor = schDay.type
          // console.log('setDeskLocation: Change location', shownFor, val.location, text, type)
          // change location if changed by office activity
          this.setOfficeLocation(val.location.id, shownFor, text, type, val.location)
        }
        this.preferencesChanged = true
        schDay.desk = val.desk.name
      }
      this.selectingDesk = false
      this.allLocationsValid()
    },
    setDesk (val) {
      // console.log('setDesk', val)
      const schDay = this.schedulesByDay[this.advancedSettingsClickedIndex][0]
      if (val.location) {
        if (val.location.id !== schDay.locationId) {
          const text = this.getLocationName(val.location.id)
          const type = val.location.type
          const shownFor = schDay.type
          // console.log('setDesk: Change location', shownFor, val.location, text, type)
          // change location if changed by office activity
          this.setOfficeLocation(val.location.id, shownFor, text, type, val.location)
        }
        this.preferencesChanged = true
        schDay.desk = val.desk ? val.desk.name : ''
      }
      this.selectingDesk = false
      this.allLocationsValid()
    },
    displayInitForm () {
      // const show = (this.personalInitiatives.length === 0 && this.topicsAnswers.length === 0) && this.initiatives
      // if (show) {
      //   this.$emit('openNewInitiative')
      // }
      // return show
      return false
    },
    inDomains (email) {
      const emailDomain = email.split('@')[1].toLowerCase()
      if (this.workspace) {
        if (this.workspace.domains) {
          for (const domain of this.workspace.domainUrls) {
            if (emailDomain === domain) {
              return true
            }
          }
        } else {
          return this.workspace.domainUrl === emailDomain
        }
      }
      return false
    },
    locationHasDesks (locationId) {
      const location = this.locations.filter(loc => loc.id === locationId)[0]
      if (location) {
        // console.log('locationHasDesks', location, location.assignedDesks, location.desks, location.capacity)
        if (location.assignedDesks && location.capacity > 0) {
          return true
        }
      }
      return false
    },
    async sendEmailInvites (value) {
      if (this.emailList || value) {
        const emailsDetails = this.emailList || value
        const emailArr = emailsDetails.replaceAll(' ', '').split(',').filter((email) => email !== '')
        const uniqueEmails = emailArr.filter((item, index) => emailArr.indexOf(item.toLowerCase()) === index)
        const validEmails = uniqueEmails.filter(email => email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))
        this.helpText = ''
        const guestsAllowed = this.workspace.guestAccessWorkspace

        const self = this
        setTimeout(() => {
          self.sendingInvite = false
        }, 2000)
        const teamAddArr = []
        const newInviteArr = []
        if (validEmails.length > 0) {
          for (const emailPart of validEmails) {
            // console.log('People.addInvite.workspace', this.workspace, emailPart,)

            if (!guestsAllowed) {
              if (!this.inDomains(emailPart)) {
                this.helpText = `Your workspace does not allow adding of guests. Please enabled this option or enter a valid email: ${emailPart}`
                return
              }
            }

            let inWorkspace = false
            if (this.workspace && this.workspace.members) {
              inWorkspace = this.workspace.members.includes(emailPart)
            }
            if (inWorkspace) {
              teamAddArr.push(emailPart)
              const inviteProfiles = this.profiles.filter(pr => pr.email.toLowerCase() === emailPart)
              if (inviteProfiles.length) {
                const inviteProfileId = inviteProfiles[0]
                if (this.myTeam.members.includes(inviteProfileId)) {
                  this.helpText = 'This user already belongs to this team'
                  return
                }
              }
            } else {
              newInviteArr.push(emailPart)
            }
          }
          this.sendingInvite = true
          // console.log('addInvite.send-invites', teamAddArr, teamAddArr.length, newInviteArr, newInviteArr.length)
          try {
            if (teamAddArr.length) {
              // Already members of this workspace add invite to team
              const params = {
                userId: this.user.uid,
                emails: teamAddArr
              }
              // eslint-disable-next-line no-unused-vars
              const response = await onboarding('invite-to-team', params)
              // console.log('People.addInvite.invite-to-team', response)
            }
            if (newInviteArr.length) {
              // Not members of this workspace - add invite to workspace / team
              // eslint-disable-next-line no-unused-vars
              const params = {
                userId: this.user.uid,
                invites: newInviteArr
              }
              // eslint-disable-next-line no-unused-vars
              const response = await onboarding('send-invites', params)
              // console.log('People.addInvite.send-invites', response, params)
            }

            for (const email in emailArr) {
              const matchingRequests = this.requests.filter((req) => req.email === email)
              if (matchingRequests) {
                for (const mRequest in matchingRequests) {
                  if (!mRequest.completed) {
                    this.updateRequest({ requestId: mRequest.id, request: { completed: true } })
                  }
                }
              }
            }
            this.closeSendEmail()
            this.emailInvites = validEmails
          } catch (error) {
            console.error('addInvite.send-invites', error)
            this.helpText = 'Error sending invite'
          }
          this.$nextTick(() => {
            const self = this
            this.sendingInvite = false
            this.inviteSent = true
            setTimeout(() => {
              self.inviteSent = false
            }, 3000)
          })
        } else {
          this.helpText = 'Please enter a valid email'
        }
      }
    },
    closeSendEmail () {
      this.emailList = ''
      this.clearHelpText()
      this.inviteEmailClicked = false
      this.isPeopleInputEmail = false
    },
    openSendEmail () {
      this.inviteEmailClicked = true
    },
    openNewInitiativeForm () {
      this.createNewInitClicked = true
      this.$emit('stepAction', 'openNewInitiative')
    },
    closeNewInitiativeForm () {
      if (this.displayInitForm()) {
        this.openPreferencesInput('people')
      }
      this.createNewInitClicked = false
      this.$emit('stepAction', 'closeNewInitiative')
    },
    calculateTableWeekDetails (weekDetail) {
      // console.log('calculateTableWeekDetails', this.weekDetail.startOfWeek)
      const tableWeekDetails = []
      for (let i = 0; i <= 4; i++) {
        tableWeekDetails.push(moment(weekDetail.startOfWeek).startOf('day').add(i, 'days').toDate())
      }
      return tableWeekDetails
    },
    goToCurrentWeek () {
      // console.log('workplan.goToCurrentWeek')
      this.showDateError = false
      const setWeekDetail = {
        startOfWeek: moment(this.appDate).startOf('isoWeek'),
        endOfWeek: moment(this.appDate).endOf('isoWeek'),
        weekNumber: moment(this.appDate).week(),
        selectedDay: moment(this.appDate)
      }
      setWeekDetail.datesOfWeek = this.calculateTableWeekDetails(setWeekDetail)
      this.setScheduleSearchWeekDetail(setWeekDetail)
    },
    navigateSettingsConnectCal (calName) {
      if (this.onboarding) {
        this.openCalendarConnect = calName
        return
      }
      this.$router.push({ name: 'Settings', params: { section: 'calendarconnect', calName } })
    },
    closeCalendarConnect () {
      this.openCalendarConnect = false
    },
    clearEditScheduleState () {
      this.setEditingSchedule(null)
    },
    displayUseCurrent () {
      let isSchedulesEqual = null
      const defaultSchedules = this.myDefaultSchedule?.schedules
      const weekSchedules = this.myWeeklySchedules?.schedules
      const currentWeekSchedules = this.myCurrentWeekSchedule?.schedules
      if ((weekSchedules === undefined) && !_.isEqual(defaultSchedules, currentWeekSchedules)) {
        isSchedulesEqual = false
      } else if (!_.isEqual(weekSchedules, currentWeekSchedules)) {
        isSchedulesEqual = false
      } else {
        isSchedulesEqual = true
      }
      const useCurrent = this.isWeekInFuture() && !this.useCurrentWeekClicked && this.myCurrentWeekSchedule.submittedOn && !this.isPastFutureBookingLimit
      return this.useCurrentSchedulesClicked || (useCurrent && !isSchedulesEqual)
    },
    getLocationName (locationId) {
      const loc = this.locations.filter(location => location.id === locationId)
      if (loc.length) {
        let locName = loc[0].name
        if (loc[0].type === 'area') {
          const floor = this.locations.filter(location => {
            if (location.id === loc[0].floorId) {
              return true
            }
            return false
          })
          if (floor.length) {
            locName = `${floor[0].name}, ${locName}`
          }
          const building = this.locations.filter(location => {
            if (location.id === loc[0].buildingId) {
              return true
            }
            return false
          })
          if (building.length) {
            locName = `${building[0].name}, <i>${locName}</i>`
          }
        } else if (loc[0].type === 'floor') {
          const building = this.locations.filter(location => {
            if (location.id === loc[0].buildingId) {
              return true
            }
            return false
          })
          if (building.length) {
            locName = `${building[0].name}, <i>${locName}</i>`
          }
        }
        return locName
      }
      return ''
    },
    setTempoLocationFromId (tempoResponse) {
      if (this.locations) {
        const loc = this.locations.filter(location => {
          if (location.id === tempoResponse.locationId) {
            return true
          }
          return false
        })
        // console.log('setTempoLocationFromId', loc)
        if (loc.length) {
          let locName = loc[0].name
          if (loc[0].type === 'area') {
            tempoResponse.floorId = loc[0].floorId
            const floor = this.locations.filter(location => {
              if (location.id === loc[0].floorId) {
                return true
              }
              return false
            })
            if (floor.length) {
              locName = `${floor[0].name}, ${locName}`
            }
            tempoResponse.buildingId = loc[0].buildingId
            const building = this.locations.filter(location => {
              if (location.id === loc[0].buildingId) {
                return true
              }
              return false
            })
            if (building.length) {
              locName = `${building[0].name}, <i>${locName}</i>`
            }
          } else if (loc[0].type === 'floor') {
            tempoResponse.buildingId = loc[0].buildingId
            const building = this.locations.filter(location => {
              if (location.id === loc[0].buildingId) {
                return true
              }
              return false
            })
            if (building.length) {
              locName = `${building[0].name}, <i>${locName}</i>`
            }
          }
          // tempoResponse.locationFromFav = true
          tempoResponse.officeLocation = locName
        }
      }
    },
    checkSubmitButton () {
      setTimeout(() => {
        const submitButton = document.querySelector('.submit-btn')
        if (submitButton) {
          const observer = new IntersectionObserver((entry) => {
            if (entry[0].isIntersecting) {
              this.showStickyFooter = false
              observer.unobserve(submitButton)
              return
            }
            this.showStickyFooter = true
            this.setStickyFooter()
          }, { threshold: 1.0 })
          observer.observe(submitButton)
        }
      }, 50)
    },
    setStickyFooter () {
      const wrapperElement = document.querySelectorAll('.right-side .mdc-card')
      if (wrapperElement[0]) {
        const wrapperScrollHeight = wrapperElement[0].scrollHeight
        const wrapperLeft = wrapperElement[0].getBoundingClientRect().left
        if (wrapperScrollHeight > window.innerHeight) {
          if (this.showStickyFooter) {
            this.$nextTick(() => {
              setTimeout(() => {
                const stickyFooter = document.querySelector('.sticky-footer')
                if (stickyFooter) {
                  let wrapperWidth
                  if (wrapperElement) {
                    wrapperWidth = wrapperElement[0].offsetWidth
                  }
                  stickyFooter.style.width = wrapperWidth + 'px'
                  stickyFooter.style.left = wrapperLeft + 'px'
                }
              }, 50)
            })
          }
        }
      }
    },
    getCurrentWeekDetails () {
      this.currentWeek = {
        startOfWeek: moment().startOf('isoWeek'),
        endOfWeek: moment().endOf('isoWeek').subtract('2', 'days'),
        weekNumber: moment.week()
      }
    },
    goToBOD () {
      this.$emit('goToBestOfficeDays', 'bestOfficeDays')
    },
    editAgain () {
      this.editAgainClick = !this.editAgainClick
      this.openPreferencesInput('locations')
      if (!this.editAgainClick) {
        this.getAllProfilePreferences()
      }
    },
    toggleDefaultTempoSelect (value) {
      this.isDefaultTempoSelected = !value
      this.preferencesChanged = true
    },
    redirectToAddUser () {
      this.$emit('enableAddUser')
    },
    async trackSubmit (pf, ws) {
      // eslint-disable-next-line no-undef
      if (typeof analytics !== 'undefined') {
        let integrations = { All: true }
        if (pf && !pf.cookiesPerf) {
          integrations = {
            All: false,
            FullStory: true,
            'Google Analytics': false,
            'Google Tag Manager': false
          }
        }
        if (pf && !pf.cookiesFunc) {
          integrations['Customer.io'] = false
        }
        // eslint-disable-next-line no-undef
        await analytics.page('/submit-preferences', {
          ws: ws.domainUrl
        }, {
          integrations
        })
      }
    },
    async submitAllPreferences () {
      this.preferencesSubmitting = true
      if (this.validateLocationPreferences() && this.locationsWithinCapacity) {
        if (this.profile) {
          if (this.workspace && this.workspace.isTracking) {
            this.trackSubmit(this.profile, this.workspace)
          }
        }
        await this.saveLocationPreferences()
        const weekDate = this.selectedWeeklyPreferences.fromDate ? this.selectedWeeklyPreferences.fromDate.toDate ? this.selectedWeeklyPreferences.fromDate.toDate() : this.selectedWeeklyPreferences.fromDate : this.scheduleSearchWeekDetail.datesOfWeek[0]
        const wsTS = moment(weekDate).add('12', 'hours')
        await this.saveTopicsAndPeople({ preferences: this.selectedWeeklyPreferences })
        this.preferencesOpened = []
        if (this.onboarding) {
          this.$emit('stageChange', 'submit')
        }
        this.setBestDaysCalculating(true)
        // this.$emit('goToBestOfficeDays', 'bestOfficeDays')
        const calcWorkspaceSchedules = fb.functions.httpsCallable('calcSchedules')
        const params = { date: wsTS.toDate(), workspaceId: this.profile.workspaceId }
        await calcWorkspaceSchedules(params)
        this.preferencesChanged = false
        this.editAgainClick = false
        this.closeAdvancedSettings()
        this.preferencesSubmitting = false
        this.useCurrentWeekClicked = false
        this.useCurrentSchedulesClicked = false
      }
    },
    getTeamName (teamId) {
      if (teamId !== this.profile.team) {
        if (this.teams) {
          const ft = this.teams.filter((team) => team.id === teamId)[0] || {}
          return `${ft.name || ''}/`
        }
      }
      return ''
    },
    hasTopic (topicId) {
      if (this.topicsAnswers) {
        return this.topicsAnswers.indexOf(topicId) >= 0
      }
      return false
    },
    getTopicName (topicId) {
      if (topicId) {
        const initiative = this.initiatives.filter(initiative => initiative.id === topicId)
        if (initiative.length > 0) {
          return initiative[0].name
        }
        return ''
      }
      return ''
    },
    getFullTopicName (topicId) {
      if (topicId) {
        const initiative = this.initiatives.filter(initiative => initiative.id === topicId)
        if (initiative.length > 0) {
          if (initiative[0].teamId !== this.profile.team) {
            return `${this.getTeamName(initiative[0].teamId)}${initiative[0].name}`
          }
          return initiative[0].name
        }
        return ''
      }
      return ''
    },
    meetingUser (userId) {
      if (this.meetingWithUsers) {
        return [...this.meetingWithUsers].filter(prof => prof.id === userId).length > 0
      }
    },
    allTypesProfiles () {
      return [...this.initiativesProfiles(), ...this.profilesWorkWith()]
    },
    getDate (date, format) {
      return dayDateFormat(date, format)
    },
    formatDate (date) {
      if (this.windowWidth <= 1200) {
        return moment(date).format('dd D')
      }
      return moment(date).format('ddd D')
    },
    useCurrentWeekSchedules () {
      this.useCurrentSchedulesClicked = !this.useCurrentSchedulesClicked
      if (this.useCurrentSchedulesClicked) {
        this.populateCurrentWeekSchedule()
      } else {
        this.getMyWeeklySchedules()
      }
    },
    useLastWeekSchedules () {
      this.populateLastWeekSchedule()
      this.populateLastWeekTopicsAndPeople()
    },
    useDefaultSchedules () {
      this.useDefaultSchedulesClicked = !this.useDefaultSchedulesClicked
      if (this.useDefaultSchedulesClicked) {
        this.populateDefaultSchedules()
      } else {
        if (!this.useCurrentWeekClicked) {
          this.preferencesChanged = false
        }
        this.getMyWeeklySchedules()
        const dateIndex = moment().day() - 1
        this.openAdvancedSettings(this.scheduleSearchWeekDetail.datesOfWeek[dateIndex], dateIndex) // always open today
      }
    },
    applyResetCurrentWeek () {
      this.useCurrentWeekClicked = !this.useCurrentWeekClicked
      if (this.useCurrentWeekClicked) {
        this.populateCurrentWeekSchedule()
        this.populateCurrentWeekTopicsAndPeople()
        this.getCurrentWeekDetails()
      } else {
        this.preferencesChanged = false
        this.getMyWeeklySchedules()
        this.getTopicsAndPeople()
      }
      if (this.preferencesOpened.includes('locations')) {
        // always open monday since apply currentweek will be enabled only for future weeks.
        this.openAdvancedSettings(this.scheduleSearchWeekDetail.datesOfWeek[0], 0)
      }
    },
    populateCurrentWeekTopicsAndPeople () {
      this.selectedWeeklyPreferences = {}
      this.topicsAnswers = []
      this.peopleAnswers = []
      this.selectedWeeklyPreferences = this.myCurrentWeekTopicsAndPeople
      if (this.selectedWeeklyPreferences && Object.keys(this.selectedWeeklyPreferences).length > 0) {
        if (this.selectedWeeklyPreferences.topicsId) {
          this.topicsAnswers = [...this.selectedWeeklyPreferences.topicsId]
        }
        if (this.selectedWeeklyPreferences.teamMatesId) {
          this.peopleAnswers = [...this.selectedWeeklyPreferences.teamMatesId]
        }
      }
      this.selectedWeeklyPreferences = {
        fromDate: this.scheduleSearchWeekDetail.startOfWeek.toDate(),
        toDate: this.scheduleSearchWeekDetail.endOfWeek.toDate(),
        teamId: this.profile.team,
        userId: this.profile.id,
        teamMatesId: this.peopleAnswers,
        topicsId: this.topicsAnswers,
        id: false,
        userDailyLocation: {}
      }
    },
    populateDefaultSchedules () {
      this.myWeeklySchedules = []
      this.myDefaultSchedules = []
      this.weeklySchedulesByDate = []
      this.myWeeklySchedules = this.myDefaultSchedule
      this.scheduleSearchWeekDetail.datesOfWeek.forEach((date, index) => {
        const day = moment(date).format('ddd').toUpperCase()
        if (this.myWeeklySchedules) {
          let scheduleByDate = {}
          if (Object.values(this.myDefaultSchedule).length) {
            scheduleByDate = this.myDefaultSchedule.schedules[day]
          }
          scheduleByDate = { ...scheduleByDate, day, defaultDay: false, date, index, submittedOn: new Date() }
          this.weeklySchedulesByDate.push(scheduleByDate)
        }
      })
      // console.log('populate default schedules', this.weeklySchedulesByDate)
      this.frameAllTypeSchedules(this.weeklySchedulesByDate, true)
    },
    populateCurrentWeekSchedule () {
      this.myWeeklySchedules = []
      this.myDefaultSchedules = []
      this.weeklySchedulesByDate = []
      this.myWeeklySchedules = this.myCurrentWeekSchedule
      this.scheduleSearchWeekDetail.datesOfWeek.forEach((date, index) => {
        const day = moment(date).format('ddd').toUpperCase()
        if (this.myWeeklySchedules) {
          let scheduleByDate = {}
          if (Object.values(this.myCurrentWeekSchedule).length) {
            scheduleByDate = this.myCurrentWeekSchedule.schedules[day]
          }
          scheduleByDate = { ...scheduleByDate, day, defaultDay: false, date, index, submittedOn: new Date() }
          this.weeklySchedulesByDate.push(scheduleByDate)
        }
      })
      this.frameAllTypeSchedules(this.weeklySchedulesByDate, true)
    },
    populateLastWeekTopicsAndPeople () {
      this.selectedWeeklyPreferences = {}
      this.topicsAnswers = []
      this.peopleAnswers = []
      this.selectedWeeklyPreferences = this.myCurrentWeekTopicsAndPeople
      if (this.selectedWeeklyPreferences && Object.keys(this.selectedWeeklyPreferences).length > 0) {
        if (this.selectedWeeklyPreferences.topicsId) {
          this.topicsAnswers = [...this.selectedWeeklyPreferences.topicsId]
        }
        if (this.selectedWeeklyPreferences.teamMatesId) {
          this.peopleAnswers = [...this.selectedWeeklyPreferences.teamMatesId]
        }
      }
      this.selectedWeeklyPreferences = {
        fromDate: this.scheduleSearchWeekDetail.startOfWeek.toDate(),
        toDate: this.scheduleSearchWeekDetail.endOfWeek.toDate(),
        teamId: this.profile.team,
        userId: this.profile.id,
        teamMatesId: this.peopleAnswers,
        topicsId: this.topicsAnswers,
        id: false,
        userDailyLocation: {}
      }
    },
    populateLastWeekSchedule () {
      this.myWeeklySchedules = []
      this.myDefaultSchedules = []
      this.weeklySchedulesByDate = []
      // getScheduleByDate
      this.myWeeklySchedules = this.myCurrentWeekSchedule
      this.scheduleSearchWeekDetail.datesOfWeek.forEach((date, index) => {
        const day = moment(date).format('ddd').toUpperCase()
        if (this.myWeeklySchedules) {
          let scheduleByDate = {}
          if (Object.values(this.myCurrentWeekSchedule).length) {
            scheduleByDate = this.myCurrentWeekSchedule.schedules[day]
          }
          scheduleByDate = { ...scheduleByDate, day, defaultDay: false, date, index, submittedOn: new Date() }
          this.weeklySchedulesByDate.push(scheduleByDate)
        }
      })
      this.frameAllTypeSchedules(this.weeklySchedulesByDate, true)
    },
    openPreferencesInput (type, fromOpenLocation) {
      // console.log('Workplan.openPreferencesInput', type, fromOpenLocation, this.isWeekInFuture(), this.openDate, this.openLocation)
      if (type) {
        if (this.preferencesOpened.includes(type) && !fromOpenLocation) {
          if (type === 'locations') {
            this.closeAdvancedSettings()
          }
          const typeIndex = this.preferencesOpened.findIndex(preference => preference === type)
          if (typeIndex !== -1) {
            this.preferencesOpened.splice(typeIndex, 1)
          }
        } else {
          if (this.onboarding) {
            this.$emit('stageChange', type)
          }
          this.preferencesOpened = [type]
          if (type === 'locations') {
            if (this.scheduleSearchWeekDetail.datesOfWeek) {
              if (this.openDate && this.openLocation) {
                const dateIndex = moment(this.scheduleSearchWeekDetail.selectedDay).day() - 1
                this.openAdvancedSettings(this.scheduleSearchWeekDetail.datesOfWeek[dateIndex], dateIndex)
              } else if (this.isWeekInFuture()) {
                this.openAdvancedSettings(this.scheduleSearchWeekDetail.datesOfWeek[0], 0) // always open monday
              } else {
                const dateIndex = moment().day() - 1
                this.openAdvancedSettings(this.scheduleSearchWeekDetail.datesOfWeek[dateIndex], dateIndex) // always open today
              }
            }
          }
        }
      }
    },
    resizeListener () {
      this.getWindowWidth()
      this.checkSubmitButton()
    },
    getWindowWidth () {
      this.windowWidth = window.innerWidth
      this.calculateWorkWithPeopleCount()
      this.calculatePeopleAnswersCount()
      this.calculateTopicsAnswersCount()
    },
    openAdvancedSettings (date, index) {
      const previousclickedSettingsIndex = this.advancedSettingsClickedIndex
      const previousclickedSettingsDate = this.advancedSettingsClickedFor
      const isPreviousValid = this.validateLocationPreferences()
      // console.log('workplan.openAdvancedsettings', date, index, isPreviousValid, previousclickedSettingsIndex, this.scheduleSearchWeekDetail)
      this.setScheduleSearchWeekDetail({
        ...this.scheduleSearchWeekDetail,
        selectedDay: moment(date)
      })

      if (previousclickedSettingsIndex !== null) {
        if (!isPreviousValid) {
          const weekdayAbv = moment(previousclickedSettingsDate).format('ddd').toUpperCase()
          const index = moment(previousclickedSettingsDate).isoWeekday() - 1
          if (this.myWeeklySchedules.schedules && this.myWeeklySchedules.schedules[weekdayAbv]) {
            if (this.schedulesByDay[previousclickedSettingsIndex]) {
              this.schedulesByDay[previousclickedSettingsIndex][0] = { ...this.myWeeklySchedules.schedules[weekdayAbv] }
            }
          } else {
            this.revertWeeklySchedulesByDate(weekdayAbv, this.advancedSettingsClickedFor, index)
          }
        }
      }
      this.deskValidLocation()
      // console.log('workplan.openAdvancedsettings.deskValid', deskValid)
      this.advancedSettingsClickedFor = date
      this.advancedSettingsClickedIndex = index
      if (this.schedulesByDay[index] && this.schedulesByDay[index][0]) {
        if (this.schedulesByDay[index][0].type === '' || !this.schedulesByDay[index][0].type) {
          this.toggleSettingsDayType('full', false) // default to full day when no response set
        }
      }
      this.clearHelpText()
    },
    toggleSettingsDayType (type, from) {
      this.clearHelpText()
      this.closeLocationDetails()
      const changeResponseIn = this.weeklySchedulesByDate.find(response => response.index === this.advancedSettingsClickedIndex)
      const changeResponseAtIndex = this.weeklySchedulesByDate.findIndex(response => response.index === this.advancedSettingsClickedIndex)
      const modifiedResponseObject = { ...changeResponseIn, type, split: type === 'split' }
      this.weeklySchedulesByDate.splice(changeResponseAtIndex, 1, modifiedResponseObject)
      this.frameAllTypeSchedules(this.weeklySchedulesByDate, from)
    },
    frameAllTypeSchedules (data, changed) {
      this.officeDays = [...data.filter(tempoResponse => tempoResponse.response === 'office' || tempoResponse.amResponse === 'office' || tempoResponse.pmResponse === 'office')]
      this.officeHalfDays = []
      for (const sd of data) {
        if (sd.type === 'split') {
          if (sd.amResponse === 'office') {
            this.officeHalfDays.push({ ...sd, slot: 'am' })
          }
          if (sd.pmResponse === 'office') {
            this.officeHalfDays.push({ ...sd, slot: 'pm' })
          }
        } else {
          if (sd.response === 'office') {
            this.officeHalfDays.push({ ...sd, slot: 'pm' })
            this.officeHalfDays.push({ ...sd, slot: 'pm' })
          }
        }
      }
      this.homeDays = [...data.filter(tempoResponse => tempoResponse.response === 'home')]
      this.nonWorkingDays = [...data.filter(tempoResponse => tempoResponse.response === 'nonworking')]
      this.thirdSpaceDays = [...data.filter(tempoResponse => tempoResponse.response === 'thirdspace')]
      this.defaultDaysLoaded = data.filter(tempoResponse => tempoResponse.defaultDay === true).map(response => response.day)
      this.schedulesByDay = _.groupBy(data, 'index')
      this.allLocationsValid()
      if (changed) {
        this.preferencesChanged = true
        this.checkSubmitButton()
      }
    },
    toggleFullDayResponseType (schedule) {
      this.clearHelpText()
      const changeResponseIn = this.weeklySchedulesByDate.find(response => response.index === this.advancedSettingsClickedIndex)
      const changeResponseAtIndex = this.weeklySchedulesByDate.findIndex(response => response.index === this.advancedSettingsClickedIndex)
      const modifiedResponseObject = { ...changeResponseIn, type: 'full', response: schedule, amResponse: '', pmResponse: '', defaultDay: false, split: false, submittedOn: changeResponseIn.submittedOn || new Date(), editedOn: changeResponseIn.submittedOn ? new Date() : '' }
      this.weeklySchedulesByDate.splice(changeResponseAtIndex, 1, modifiedResponseObject)
      this.frameAllTypeSchedules(this.weeklySchedulesByDate, true)
    },
    toggleSplitDayResponseType (schedule, slot) {
      this.clearHelpText()
      const changeResponseIn = this.weeklySchedulesByDate.find(response => response.index === this.advancedSettingsClickedIndex)
      const changeResponseAtIndex = this.weeklySchedulesByDate.findIndex(response => response.index === this.advancedSettingsClickedIndex)
      let pmSettings = changeResponseIn.pmResponse
      let amSettings = changeResponseIn.amResponse
      if (slot === 'AM' && changeResponseIn.pmResponse === schedule) {
        pmSettings = ''
        amSettings = schedule
      } else if (slot === 'PM' && changeResponseIn.amResponse === schedule) {
        amSettings = ''
        pmSettings = schedule
      } else {
        if (slot === 'AM') {
          amSettings = schedule
        } else if (slot === 'PM') {
          pmSettings = schedule
        }
      }
      const modifiedResponseObject = { ...changeResponseIn, type: 'split', amResponse: amSettings, pmResponse: pmSettings, response: '', defaultDay: false, split: true, submittedOn: changeResponseIn.submittedOn || new Date(), editedOn: changeResponseIn.submittedOn ? new Date() : '' }
      this.weeklySchedulesByDate.splice(changeResponseAtIndex, 1, modifiedResponseObject)
      this.frameAllTypeSchedules(this.weeklySchedulesByDate, true)
    },
    clearHelpText () {
      if (this.helpText) {
        this.helpText = ''
      }
    },
    closeLocationDetails () {
      this.openLocationDetails = false
      this.openLocationInputFor = ''
    },
    getWorkingAtIcon (response) {
      return getResponseIconName(response)
    },
    getWorkingAtClass (response) {
      if (response && response !== null && response !== '' && response !== undefined) {
        switch (response.toLowerCase()) {
          case 'home':
            return 'home'
          case 'office':
            return 'office'
          case 'nonworking':
            return 'nonworking'
          case 'thirdspace':
            return 'thirdspace'
          default:
            return ''
        }
      }
    },
    getBoxFillClass (response) {
      if (response && response !== null && response !== '' && response !== undefined) {
        switch (response.toLowerCase()) {
          case 'home':
            return 'home-fill'
          case 'thirdspace':
            return 'thirdspace-fill'
          case 'office':
            return 'office-fill'
          case 'nonworking':
            return 'nonworking-fill'
          case 'notset':
            return 'notset-fill'
          default:
            return ''
        }
      }
    },
    calculateWorkWithPeopleCount () {
      if (this.windowWidth <= 1100) {
        this.workWithPeopleCount = 4
      } else if (this.windowWidth > 1100 && this.windowWidth < 1304) {
        this.workWithPeopleCount = 5
      } else {
        this.workWithPeopleCount = 6
      }
    },
    calculatePeopleAnswersCount () {
      if (this.windowWidth >= 1400) {
        this.peopleAnswersCount = 10
      } else if (this.windowWidth >= 1350) {
        this.peopleAnswersCount = 8
      } else if (this.windowWidth >= 1250) {
        this.peopleAnswersCount = 7
      } else if (this.windowWidth >= 1150) {
        this.peopleAnswersCount = 6
      } else if (this.windowWidth >= 1050) {
        this.peopleAnswersCount = 5
      } else {
        this.peopleAnswersCount = 4
      }
    },
    calculateTopicsAnswersCount () {
      if (this.windowWidth >= 1400) {
        this.topicsAnswersCount = 6
      } else if (this.windowWidth >= 1350) {
        this.topicsAnswersCount = 5
      } else if (this.windowWidth >= 1250) {
        this.topicsAnswersCount = 4
      } else if (this.windowWidth >= 1150) {
        this.topicsAnswersCount = 3
      } else if (this.windowWidth >= 1050) {
        this.topicsAnswersCount = 3
      } else {
        this.topicsAnswersCount = 2
      }
    },
    setDayTypeSchedule (schedule, slot) {
      this.closeLocationDetails()
      if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].type === 'split') {
        this.toggleSplitDayResponseType(schedule, slot)
      } else if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].type === 'full') {
        this.toggleFullDayResponseType(schedule)
      }
    },
    setThirdspaceLocation (val, shownFor) {
      if (shownFor === 'full') {
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].thirdspaceLocation = val
      } else if (shownFor === 'split') {
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].splitThirdspaceLocation = val
      }
      this.schedulesByDay[this.advancedSettingsClickedIndex][0].locationId = ''
      this.schedulesByDay[this.advancedSettingsClickedIndex][0].locationType = ''
      if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].floorId) {
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].floorId = ''
      }
      if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].buildingId) {
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].buildingId = ''
      }
      this.preferencesChanged = true
      this.closeLocationDetails()
    },
    setOfficeLocation (val, shownFor, text, type, location) {
      // console.log('Workplan.setOfficeLocation val:', val, ' showFor:', shownFor, ' text:', text, ' type:', type, ' location:', location, 'advClicked:', this.advancedSettingsClickedIndex)
      if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].desk) {
        // reset desk if changing location
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].desk = false
      }

      if (shownFor === 'full') {
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].officeLocation = text
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].splitOfficeLocation = ''
      } else if (shownFor === 'split') {
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].officeLocation = ''
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].splitOfficeLocation = text
      }

      this.schedulesByDay[this.advancedSettingsClickedIndex][0].locationId = val
      this.schedulesByDay[this.advancedSettingsClickedIndex][0].locationType = type
      this.schedulesByDay[this.advancedSettingsClickedIndex][0].locationFromFav = false
      this.schedulesByDay[this.advancedSettingsClickedIndex][0].floorId = ''
      this.schedulesByDay[this.advancedSettingsClickedIndex][0].buildingId = ''

      if (location && location.floorId) {
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].floorId = location.floorId
      }
      if (location && location.buildingId) {
        this.schedulesByDay[this.advancedSettingsClickedIndex][0].buildingId = location.buildingId
      }

      if (val) {
        const locationIndex = this.profile.officeLocations ? this.profile.officeLocations.findIndex(location => location.id === val) : -1
        if (locationIndex === -1) {
          this.officeLocations = [...this.officeLocations, { date: new Date(), id: val, type, text }]
          let officeLocationsToSave
          if (this.profile.officeLocations && this.profile.officeLocations.length) {
            const favLast = _.sortBy(this.profile.officeLocations, ({ id }) => id === this.profile.favOfficeLocationId ? 1 : 0)
            officeLocationsToSave = [...favLast.slice(-3), ...this.officeLocations]
          } else {
            officeLocationsToSave = [...this.officeLocations]
          }
          this.updateProfile({ profile: { officeLocations: officeLocationsToSave }, noSnack: true }).then(() => {
            this.officeLocations = []
          })
        }
      }
      this.preferencesChanged = true
      this.allLocationsValid()
      this.closeLocationDetails()
    },
    openLocationSettingInput (location, openFor) {
      if (location === 'thirdspace' || location === 'office') {
        this.openLocationDetails = true
        this.openLocationInputFor = openFor
      }
    },
    async saveLocationPreferences () {
      const fromDate = this.scheduleSearchWeekDetail.startOfWeek.toDate()
      const isAfterConfirm = this.confirmationDate ? moment().isAfter(this.confirmationDate) : false
      const week = moment(fromDate).format('YYYY-MM-DD')
      let previousIssue = false
      let allValid = true
      if (this.mySchedule && this.mySchedule.schedules) {
        for (const key of Object.keys(this.mySchedule.schedules)) {
          if (this.mySchedule.schedules[key]) {
            const scheduleDay = this.mySchedule.schedules[key]
            if (scheduleDay && scheduleDay.issue) {
              previousIssue = true
            }
          }
        }
      }
      if (this.schedulesByDay) {
        for (const key of Object.keys(this.schedulesByDay)) {
          if (this.schedulesByDay[key]) {
            const scheduleDay = this.schedulesByDay[key][0]
            if (scheduleDay && scheduleDay.issue) {
              allValid = false
            }
          }
        }
      }
      const issuesResolved = isAfterConfirm && previousIssue && allValid
      this.saveSchedule({ date: fromDate, schedule: this.mySchedule, responses: this.schedulesByDay, issuesResolved })
      if (this.isDefaultTempoSelected) {
        this.saveDefaultSchedule({ schedule: this.myDefaultSchedule, responses: this.schedulesByDay })
      }
      console.log('Workplan.saveLocationPreferences scheduleId issuesResolved (isAfterConfirm, PreviousIssue, allValid)', this.mySchedule.id, issuesResolved, isAfterConfirm, previousIssue, allValid)
      if (issuesResolved) {
        const params = {}
        const email = this.profile.email
        const url = `send-confirmation/${this.workspace.id}/${this.profile.id}/${week}/${email}`
        const response = await mail(url, params)
        console.log('Workplan.saveLocationPreferences issuesResolvedMail', url, response)
      }
    },
    isWeekInFuture () {
      return inFuture(this.scheduleSearchWeekDetail.startOfWeek)
    },
    isDatePast (date) {
      return inPast(date)
    },
    isWeekNotPast () {
      if (moment(this.scheduleSearchWeekDetail.startOfWeek).isSameOrAfter(new Date(), 'week')) {
        return true
      }
      return false
    },
    allLocationsValid () {
      let allValid = true
      this.scheduleIssue = false
      this.scheduleHadIssue = false
      // console.log('allLocationsValid 1', this.schedulesByDay)
      if (this.schedulesByDay) {
        for (const key of Object.keys(this.schedulesByDay)) {
          if (this.schedulesByDay[key]) {
            const scheduleDay = this.schedulesByDay[key][0]
            if (scheduleDay.date) {
              const scheduleDate = scheduleDay.date.toDate ? scheduleDay.date.toDate() : scheduleDay.date
              if (moment().isBefore(moment(scheduleDate), 'day')) {
                // ignore past dates
                const previousIssue = !!scheduleDay.issue
                this.scheduleHadIssue = this.scheduleHadIssue || previousIssue
                scheduleDay.issue = false
                const inOffice = (scheduleDay.type === 'full' && scheduleDay.response === 'office') || (scheduleDay.split && (scheduleDay.amResponse === 'office' || scheduleDay.pmResponse === 'office'))
                if (inOffice && scheduleDay.locationId) {
                  const locationId = scheduleDay.locationId
                  const location = this.locations.filter(loc => loc.id === locationId)[0]
                  if (location) {
                    if (location.buildingId) {
                      const building = this.locations.filter(loc => loc.id === location.buildingId)[0]
                      location.capacityManage = building.capacityManage
                    }
                    // console.log(`allLocationsValid.${key}`, scheduleDay, location, location.capacityManage)
                    if (location.capacityManage) {
                      const locationCapacity = this.locationCapacityExSelf(locationId)
                      // console.log(`allLocationsValid.${key}.locationCapacity`, locationCapacity, location)
                      if (locationCapacity >= location.capacity) {
                        allValid = false
                        scheduleDay.issue = 'Overbooked'
                        this.scheduleIssue = 'There are workspace issues on some days. Adjust office locations.'
                      }
                    }
                    if (location.assignedDesks) {
                      if (!scheduleDay.desk) {
                        allValid = false
                        scheduleDay.issue = 'Desk'
                        this.scheduleIssue = 'There are workspace issues on some days. Please select desk locations.'
                      }
                    }
                  }
                }
                if (previousIssue && !this.scheduleIssue) {
                  scheduleDay.issueResolved = true
                }
              }
            }
          }
        }
      }
      return allValid
    },
    locationCapacityExSelf (locationId, dayAbv) {
      let totalCap = 0
      for (const schedule of this.schedules.filter(sc => sc.fromId !== this.profile.id)) {
        if (schedule.schedules) {
          const sday = schedule.schedules[dayAbv]
          if (sday) {
            if (sday.locationId === locationId) {
              totalCap++
            }
          }
        }
      }
      return totalCap
    },
    deskValidLocation () {
      if (this.advancedSettingsClickedIndex !== null) {
        if (this.schedulesByDay[this.advancedSettingsClickedIndex]) {
          if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].locationId) {
            if (this.locationHasDesks(this.schedulesByDay[this.advancedSettingsClickedIndex][0].locationId)) {
              if (!this.schedulesByDay[this.advancedSettingsClickedIndex][0].desk) {
                this.helpText = 'Please select a desk'
                return false
              }
            }
          }
        }
      }
      return true
    },
    validateLocationPreferences () {
      if (this.advancedSettingsClickedIndex !== null) {
        if (this.schedulesByDay[this.advancedSettingsClickedIndex]) {
          if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].type === 'full') {
            if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].response && this.schedulesByDay[this.advancedSettingsClickedIndex][0].response !== '') {
              return true
            } else {
              this.helpText = 'Choose work location'
              return false
            }
          }
          if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].type === 'split') {
            if (this.schedulesByDay[this.advancedSettingsClickedIndex][0].amResponse && this.schedulesByDay[this.advancedSettingsClickedIndex][0].amResponse !== '' && this.schedulesByDay[this.advancedSettingsClickedIndex][0].pmResponse && this.schedulesByDay[this.advancedSettingsClickedIndex][0].pmResponse !== '') {
              return true
            } else {
              this.helpText = 'Choose am and pm locations'
              return false
            }
          }
        }
      }
      return true
    },
    getMyWeeklySchedules () {
      this.myWeeklySchedules = []
      this.myDefaultSchedules = []
      this.weeklySchedulesByDate = []
      this.myWeeklySchedules = this.mySchedule
      this.myDefaultSchedules = this.myDefaultSchedule
      this.scheduleIssue = ''

      this.scheduleSearchWeekDetail.datesOfWeek.forEach((date, index) => {
        const day = moment(date).format('ddd').toUpperCase()
        if (this.myWeeklySchedules) {
          let scheduleByDate = {}
          if (Object.values(this.mySchedule).length) {
            scheduleByDate = this.mySchedule.schedules[day]
          }
          scheduleByDate = { ...scheduleByDate, day, defaultDay: false, date, index }
          if (scheduleByDate.issue === 'Overbooked') {
            console.log('getMyWeeklySchedules', scheduleByDate.issue)
            this.scheduleIssue = 'There are workspace issues on some days. Adjust office locations.'
          }
          if (scheduleByDate.issue === 'Desk') {
            console.log('getMyWeeklySchedules', scheduleByDate.issue)
            this.scheduleIssue = 'There are workspace issues on some days. Please select desk locations.'
          }
          this.weeklySchedulesByDate.push(scheduleByDate)
        }
      })
      this.weeklySchedulesByDate.forEach((tempoResponse, index) => {
        if (!tempoResponse.locationId) {
          if (this.profile.favOfficeLocationId) {
            tempoResponse.locationId = this.profile.favOfficeLocationId || ''
            this.setTempoLocationFromId(tempoResponse)
          } else if (this.locations && this.locations.length === 1) {
            tempoResponse.locationId = this.locations[0].id || ''
            this.setTempoLocationFromId(tempoResponse)
          }
        }
        if (this.myDefaultSchedules) {
          if (this.myDefaultSchedules.schedules && Object.values(this.myDefaultSchedules.schedules).length) {
            Object.values(this.myDefaultSchedules.schedules).forEach(defaultResponse => {
              if (tempoResponse.day === defaultResponse.day && (!tempoResponse.type || tempoResponse.type === '')) {
                tempoResponse.response = defaultResponse.response
                tempoResponse.defaultDay = true
                tempoResponse.index = index
                tempoResponse.id = tempoResponse.id || ''
                tempoResponse.teamId = this.profile.team
                tempoResponse.fromId = this.profile.id
                tempoResponse.fromName = this.profile.displayName
                tempoResponse.type = defaultResponse.type || ''
                tempoResponse.split = defaultResponse.split || false
                tempoResponse.amResponse = defaultResponse.amResponse || ''
                tempoResponse.pmResponse = defaultResponse.pmResponse || ''
                tempoResponse.locationId = defaultResponse.locationId || tempoResponse.locationId
                tempoResponse.desk = defaultResponse.desk || tempoResponse.desk
                if (defaultResponse.locationId) {
                  tempoResponse.locationFromFav = false
                }
                tempoResponse.locationType = defaultResponse.locationType || ''
                tempoResponse.floorId = defaultResponse.floorId || ''
                tempoResponse.buildingId = defaultResponse.buildingId || ''
                tempoResponse.officeLocation = defaultResponse.officeLocation || ''
                tempoResponse.splitOfficeLocation = defaultResponse.splitOfficeLocation || ''
                tempoResponse.thirdspaceLocation = defaultResponse.thirdspaceLocation || ''
                tempoResponse.splitThirdspaceLocation = defaultResponse.splitThirdspaceLocation || ''
                tempoResponse.date = moment(this.scheduleSearchWeekDetail.startOfWeek).day(index + 1).toDate()
                tempoResponse = tempoResponse || {}
                tempoResponse.submittedOn = tempoResponse.submittedOn || new Date()
              }
            })
          }
        }
      })
      this.frameAllTypeSchedules(this.weeklySchedulesByDate)
    },
    getAllProfilePreferences (from) {
      this.resetData()
      this.getMyWeeklySchedules()
      this.getTopicsAndPeople()
      this.profilesHavingMeetingWith()
      if (this.onboarding) {
        if (!this.preferencesOpened.length) {
          setTimeout(() => {
            this.openPreferencesInput('locations')
          }, 1000)
        }
      }
    },
    closeAdvancedSettings () {
      if (this.advancedSettingsClickedIndex !== null) {
        if (!this.validateLocationPreferences()) {
          const weekdayAbv = moment(this.advancedSettingsClickedFor).format('ddd').toUpperCase()
          const index = moment(this.advancedSettingsClickedFor).isoWeekday() - 1
          if (this.myWeeklySchedules.schedules && this.myWeeklySchedules.schedules[weekdayAbv]) {
            this.schedulesByDay[this.advancedSettingsClickedIndex][0] = { ...this.myWeeklySchedules.schedules[weekdayAbv] }
          } else {
            this.revertWeeklySchedulesByDate(weekdayAbv, this.advancedSettingsClickedFor, index)
          }
        }
        this.advancedSettingsClickedFor = null
        this.advancedSettingsClickedIndex = null
      }
      this.clearHelpText()
    },
    getTopicsAndPeople () {
      this.selectedWeeklyPreferences = {}
      this.topicsAnswers = []
      this.peopleAnswers = []
      this.selectedWeeklyPreferences = this.myWeekPreferences
      // console.log('getTopicsAndPeople', this.selectedWeeklyPreferences)
      if (this.selectedWeeklyPreferences && Object.keys(this.selectedWeeklyPreferences).length > 0) {
        if (this.selectedWeeklyPreferences.topicsId) {
          this.topicsAnswers = [...this.selectedWeeklyPreferences.topicsId]
        }
        if (this.selectedWeeklyPreferences.teamMatesId) {
          this.peopleAnswers = [...this.selectedWeeklyPreferences.teamMatesId]
        }
      } else {
        this.selectedWeeklyPreferences = {
          fromDate: this.scheduleSearchWeekDetail.startOfWeek.toDate(),
          toDate: this.scheduleSearchWeekDetail.endOfWeek.toDate(),
          teamId: this.profile.team,
          userId: this.profile.id,
          teamMatesId: [],
          topicsId: [],
          id: false,
          userDailyLocation: {}
        }
      }
    },
    async profilesHavingMeetingWith () {
      // console.log('wp.profilesHavingMeetingWith', this.meetings, this.usersMeeting)
      this.meetingWithUsers = []
      // const otherProfiles = this.profiles.filter(profile => profile.team !== this.profile.team)
      if (this.profiles && this.meetings && this.usersMeeting) {
        for (const profile of this.profiles) {
          if (profile) {
            const msUserEmail = profile.msEmail || profile.email
            const googleUserEmail = profile.googleEmail
            if (this.usersMeeting.includes(msUserEmail) || this.usersMeeting.includes(googleUserEmail)) {
              this.meetingWithUsers.push(profile)
              this.meetingPeopleLoading = false
            }
          }
        }
      }
      this.$emit('hasMeeting', (this.meetingWithUsers.length > 0))
      this.meetingPeopleLoading = false
    },
    profilesWorkWith () {
      const teamAndInitiativeProfiles = [...this.initiativesProfiles(), ...this.filteredTeamProfiles]
      const profilesWithoutMeetingPeople = teamAndInitiativeProfiles.filter((profileDetail) => {
        const isProfileInMeetingWith = this.meetingWithUsers.findIndex(profile => profile.id === profileDetail.id)
        if (isProfileInMeetingWith === -1) {
          return profileDetail
        }
        return false
      })
      return profilesWithoutMeetingPeople
    },
    initiativesProfiles () { // initiative profiles from different team
      if (this.initiatives) {
        const myInitiatives = this.initiatives.filter((initiative) => initiative.members.includes(this.profile.id))
        let allMembers = []
        myInitiatives.forEach(initiative => {
          allMembers = [...allMembers, ...initiative.members]
        })
        allMembers = [...new Set(allMembers)]
        return allMembers.filter(member => member !== this.profile.id && this.teamProfiles.findIndex(profile => profile.id === member) === -1).map((member) => {
          return this.profiles.find(profile => profile.id === member)
        })
      }
    },
    setSelectedPeople (profileParamId, fromSelectAll) {
      const peopleAnswerAtIndex = this.peopleAnswers.findIndex(peopleId => peopleId === profileParamId)
      if (peopleAnswerAtIndex !== -1) {
        if (!fromSelectAll) {
          this.peopleAnswers.splice(peopleAnswerAtIndex, 1)
        }
      } else {
        this.peopleAnswers.push(profileParamId)
      }
      this.selectedWeeklyPreferences.teamMatesId = this.peopleAnswers
      this.preferencesChanged = true
    },
    setSearchedPeople (value) {
      if (value) {
        this.setSelectedPeople(value)
        this.$refs.peopleSelect.clearSelection()
      }
    },
    setSelectedTopics (topicParamId) {
      const topicAnswerAtIndex = this.topicsAnswers.findIndex(topicId => topicId === topicParamId)
      if (topicAnswerAtIndex !== -1) {
        this.topicsAnswers.splice(topicAnswerAtIndex, 1)
      } else {
        this.topicsAnswers.push(topicParamId)
      }
      this.selectedWeeklyPreferences.topicsId = this.topicsAnswers
      this.preferencesChanged = true
    },
    setSearchedTopic (value) {
      if (value) {
        this.setSelectedTopics(value)
        this.$refs.topicSelect.clearSelection()
      }
    },
    topicsDropdownShouldOpen (VueSelect) {
      if (this.topicInput !== '') {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    peopleDropdownShouldOpen (VueSelect) {
      if (this.peopleInput !== '') {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    handlePeopleSearch (search) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.isPeopleInputEmail = emailRegex.test(search)
    },
    getTeamMateName (profileId) {
      if (profileId) {
        return this.profiles.filter(profile => profile.id === profileId).map(profile => profile.displayName)
      }
    },
    selectAllWorkingWithProfiles () {
      if (this.profilesWorkWith().length > 0) {
        const allIds = this.profilesWorkWith().map(profile => profile.id)
        if (this.allWorkingWithProfilesSelected()) {
          allIds.forEach((id) => {
            this.setSelectedPeople(id) // deselect
          })
        } else {
          allIds.forEach((id) => {
            this.setSelectedPeople(id, true) // select all true param
          })
        }
      }
    },
    allWorkingWithProfilesSelected () {
      const allIds = this.profilesWorkWith().map(profile => profile.id)
      if (allIds.every(id => this.peopleAnswers.includes(id))) {
        return true
      }
      return false
    },
    selectAllMeetingWithProfiles () {
      if (this.meetingWithUsers.length > 0) {
        const allIds = this.meetingWithUsers.map(profile => profile.id)
        if (this.allMeetingWithProfilesSelected()) {
          allIds.forEach((id) => {
            this.setSelectedPeople(id) // deselect
          })
        } else {
          allIds.forEach((id) => {
            this.setSelectedPeople(id, true) // select all true param
          })
        }
      }
    },
    allMeetingWithProfilesSelected () {
      const allIds = this.meetingWithUsers.map(profile => profile.id)
      if (allIds.every(id => this.peopleAnswers.includes(id))) {
        return true
      }
      return false
    },
    showPeopleWorkingWith () {
      this.showMorePeopleClicked = !this.showMorePeopleClicked
    },
    saveEditingSchedule () {
      if (this.preferencesChanged) {
        this.setEditingSchedule({
          startOfWeek: this.scheduleSearchWeekDetail.startOfWeek,
          schedulesByDay: { ...this.schedulesByDay },
          weeklySchedulesByDate: [...this.weeklySchedulesByDate.map(ws => { return { ...ws } })],
          selectedWeeklyPreferences: { ...this.selectedWeeklyPreferences },
          peopleAnswers: [...this.peopleAnswers],
          topicsAnswers: [...this.topicsAnswers],
          preferencesOpened: [...this.preferencesOpened],
          officeDays: [...this.officeDays],
          officeHalfDays: [...this.officeHalfDays],
          homeDays: [...this.homeDays],
          nonWorkingDays: [...this.nonWorkingDays],
          thirdSpaceDays: [...this.thirdSpaceDays],
          defaultDaysLoaded: [...this.defaultDaysLoaded],
          isDefaultTempoSelected: this.isDefaultTempoSelected
        })
      }
    },
    loadFromEditingSchedule () {
      if (this.editingSchedule) {
        // console.log('loadFromEditingSchedule')
        let changed = false
        if (this.editingSchedule.selectedWeeklyPreferences) {
          this.selectedWeeklyPreferences = this.editingSchedule.selectedWeeklyPreferences
          // console.log('loadFromEditingSchedule.selectedWeeklyPreferences')
          changed = true
        }
        if (this.editingSchedule.schedulesByDay) {
          this.schedulesByDay = this.editingSchedule.schedulesByDay
          // console.log('loadFromEditingSchedule.schedulesByDay')
          changed = true
        }
        if (this.editingSchedule.myWeeklySchedules) {
          this.myWeeklySchedules = this.editingSchedule.myWeeklySchedules
          // console.log('loadFromEditingSchedule.myWeeklySchedules')
        }
        if (this.editingSchedule.weeklySchedulesByDate) {
          this.weeklySchedulesByDate = this.editingSchedule.weeklySchedulesByDate
          // console.log('loadFromEditingSchedule.weeklySchedulesByDate')
          changed = true
        }
        if (this.editingSchedule.peopleAnswers) {
          this.peopleAnswers = this.editingSchedule.peopleAnswers
          // console.log('loadFromEditingSchedule.peopleAnswers')
          changed = true
        }
        if (this.editingSchedule.topicsAnswers) {
          this.topicsAnswers = this.editingSchedule.topicsAnswers
          // console.log('loadFromEditingSchedule.topicsAnswers')
          changed = true
        }
        if (this.editingSchedule.preferencesOpened) {
          this.preferencesOpened = this.editingSchedule.preferencesOpened
          this.advancedSettingsClickedFor = null
          this.advancedSettingsClickedIndex = null
          if (this.editingSchedule.preferencesOpened.includes('locations')) {
            if (this.isWeekInFuture()) {
              this.openAdvancedSettings(this.scheduleSearchWeekDetail.datesOfWeek[0], 0) // always open monday
            } else {
              const dateIndex = moment().day() - 1
              this.openAdvancedSettings(this.scheduleSearchWeekDetail.datesOfWeek[dateIndex], dateIndex) // always open today
            }
          }
        }
        if (this.editingSchedule.officeDays) {
          // console.log('loadFromEditingSchedule.officeDays')
          this.officeDays = this.editingSchedule.officeDays
        }
        if (this.editingSchedule.officeHalfDays) {
          // console.log('loadFromEditingSchedule.officeHalfDays')
          this.officeHalfDays = this.editingSchedule.officeHalfDays
        }
        if (this.editingSchedule.homeDays) {
          // console.log('loadFromEditingSchedule.homeDays')
          this.homeDays = this.editingSchedule.homeDays
        }
        if (this.editingSchedule.nonWorkingDays) {
          // console.log('loadFromEditingSchedule.nonWorkingDays')
          this.nonWorkingDays = this.editingSchedule.nonWorkingDays
        }
        if (this.editingSchedule.thirdSpaceDays) {
          // console.log('loadFromEditingSchedule.thirdSpaceDays')
          this.thirdSpaceDays = this.editingSchedule.thirdSpaceDays
        }
        if (this.editingSchedule.defaultDaysLoaded) {
          // console.log('loadFromEditingSchedule.defaultDaysLoaded')
          this.defaultDaysLoaded = this.editingSchedule.defaultDaysLoaded
        }
        if (this.editingSchedule.isDefaultTempoSelected) {
          // console.log('loadFromEditingSchedule.isDefaultTempoSelected')
          this.isDefaultTempoSelected = this.editingSchedule.isDefaultTempoSelected
        }
        this.preferencesChanged = changed
      }
    },
    revertWeeklySchedulesByDate (day, date, index) {
      const changeResponseAtIndex = this.weeklySchedulesByDate.findIndex(response => response.index === index)
      const modifiedResponseObject = { day, defaultDay: false, date, index }
      this.weeklySchedulesByDate.splice(changeResponseAtIndex, 1, modifiedResponseObject)
      this.frameAllTypeSchedules(this.weeklySchedulesByDate)
    },
    checkDefaultDaysLoaded () {
      if (this.defaultDaysLoaded.length > 0) {
        this.preferencesChanged = true
      }
    },
    resetData () {
      this.createNewInitClicked = false
      this.sendingInvite = false
      this.inviteSent = false
      this.showStickyFooter = false
      this.helpText = ''
      this.preferencesChanged = false
      this.officeLocations = []
      this.openLocationInputFor = ''
      this.openLocationDetails = false
      this.useCurrentWeekClicked = false
      this.myWeeklySchedules = []
      this.myDefaultSchedules = []
      this.weeklySchedulesByDate = []
      this.officeDays = []
      this.officeHalfDays = []
      this.homeDays = []
      this.nonWorkingDays = []
      this.thirdSpaceDays = []
      this.defaultDaysLoaded = []
      this.schedulesByDay = {}
      this.advancedSettingsClickedFor = null
      this.advancedSettingsClickedIndex = null
      this.isDefaultTempoSelected = false
      this.showMorePeopleClicked = false
      this.meetingPeopleLoading = false
      this.topicInput = ''
      this.peopleInput = ''
      this.meetingWithUsers = []
      this.topicsAnswers = []
      this.peopleAnswers = []
      this.selectedWeeklyPreferences = {}
      this.editAgainClick = false
      this.useDefaultSchedulesClicked = false
      this.preferencesSubmitting = false
      this.inviteEmailClicked = false
      this.emailList = ''
      this.isPeopleInputEmail = false
      this.emailInvites = []
      this.preferencesOpened = []
    }
  },
  watch: {
    selectingDesk: {
      handler () {
        this.$emit('deskShowing', this.selectingDesk)
      }
    },
    dateInvalid: {
      handler () {
        if (this.dateInvalid) {
          this.showDateError = true
        }
      },
      immediate: true
    },
    useDefault: {
      handler () {
        if (this.useDefault) {
          // setting default
          setTimeout(() => {
            if (!this.dateInvalid) {
              this.populateDefaultSchedules()
              this.preferencesChanged = true
              this.preferencesOpened = ['locations']
              const dateIndex = moment(this.showDate).day() - 1
              this.openAdvancedSettings(this.scheduleSearchWeekDetail.datesOfWeek[dateIndex], dateIndex) // always open today
              this.useDefaultSchedulesClicked = true
            } else {
              this.preferencesOpened = []
            }
          }, 500)
        }
      },
      immediate: true
    },
    useLast: {
      handler () {
        if (this.useLast) {
          // setting last week
          setTimeout(() => {
            if (!this.dateInvalid) {
              this.populateLastWeekSchedule()
              this.preferencesChanged = true
              this.preferencesOpened = ['locations', 'topics', 'people']
              const dateIndex = moment(this.showDate).day() - 1
              this.openAdvancedSettings(this.scheduleSearchWeekDetail.datesOfWeek[dateIndex], dateIndex) // always open today
              this.useCurrentWeekClicked = true
            } else {
              this.preferencesOpened = []
            }
          }, 500)
        }
      },
      immediate: true
    },
    defaultDaysLoaded: {
      handler () {
        this.checkDefaultDaysLoaded()
      }
    },
    preferencesChanged: {
      handler () {
        this.checkSubmitButton()
      }
    },
    openLocation: {
      handler () {
        if (this.openLocation) {
          this.openPreferencesInput('locations', true)
          this.editAgainClick = true
        }
      },
      immediate: true
    },
    preferencesOpened () {
      if (this.preferencesOpened.length > 0) {
        this.$emit('preferencesOpened', true)
        this.editAgainClick = true
      } else {
        this.$emit('preferencesOpened', false)
        this.editAgainClick = false
      }
    },
    'scheduleSearchWeekDetail.startOfWeek': {
      handler (newSow, oldSow) {
        // don't rerun if dates are the same
        const hasChanged = newSow && oldSow ? !moment(newSow).isSame(moment(oldSow), 'day') : true
        // console.log('watch:scheduleSearchWeekDetail.startOfWeek', newSow, oldSow, hasChanged, this.scheduleSearchWeekDetail)

        if (newSow) {
          if (hasChanged) {
            this.closeAdvancedSettings()
          }
          let loadingFromEdit = false
          if (!oldSow) {
            if (this.editingSchedule) {
              if (moment(this.editingSchedule.startOfWeek, 'day').isSame(this.scheduleSearchWeekDetail.startOfWeek, 'day')) {
                loadingFromEdit = true
                setTimeout(() => {
                  this.loadFromEditingSchedule()
                }, 1000)
              }
            }
          } else {
            // Clear Edit state on change of weeks?
            // console.log('wp.watch.startOfWeek Change Week?', newSow, oldSow)
          }
          if (!loadingFromEdit) {
            if (hasChanged) {
              this.getAllProfilePreferences('watch')
            }
          }
          this.checkDefaultDaysLoaded()
        }
      },
      immediate: true
    },
    profile (newP, oldP) {
      if (newP && oldP) {
        if (newP.favOfficeLocationId !== oldP.favOfficeLocationId) {
          if (this.schedulesByDay) {
            for (const sd of Object.values(this.schedulesByDay)) {
              const tempoResponse = sd[0]
              if (tempoResponse.locationFromFav) {
                tempoResponse.locationId = newP.favOfficeLocationId
                this.setTempoLocationFromId(tempoResponse)
              }
            }
          }
        }
      }
    },
    mySchedule: {
      handler (newV, oldV) {
        if (!_.isEqual(newV, oldV)) {
          this.getMyWeeklySchedules()
        }
      }
    },
    myWeekPreferences: {
      handler () {
        // console.log('wp.watch.myWeekPreferences', this.myWeekPreferences)
        if (!this.editingSchedule) {
          this.getTopicsAndPeople()
        }
      }
    },
    meetings: {
      handler () {
        if (this.usersMeeting) {
          this.profilesHavingMeetingWith()
        }
      }
    },
    usersMeeting: {
      handler () {
        if (this.usersMeeting) {
          this.profilesHavingMeetingWith()
        }
      }
    },
    openCalendarConnect () {
      if (this.openCalendarConnect) {
        this.$emit('calOpen')
      } else {
        this.$emit('calClose')
      }
    }
  }
}
</script>

<style lang="scss">
.tooltip-anchor-submit {
  .onboarding-tooltip-wrapper {
    &::before {
      top: 110px !important;
    }
  }
}
.workplan-wrapper {
  margin-top: 50px;

  .desk-selection {
    background: white;
    margin: 20px;
    padding: 10px;
    margin-top: -30px;
    border-radius: 6px;
    padding-bottom: 60px;
  }

  &.connect-cal {
    z-index: 28 !important;
  }
  .datedetails-header {
    margin-left: 45px;
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 26px;
    div {
      display: flex;
      align-items: center;
    }
    .plan-dates {
      .mdc-text {
        font-weight: 700;
        white-space: nowrap;
      }
      .week-dates {
        color: #b10000;
        margin-left: 3px;
      }
      .submitted-text {
        font-weight: 700;
        margin-left: 3px;
      }
    }
    .applied-dates {
      .week-dates {
        font-weight: 300;
        color: #404040;
      }
    }
    .apply-reset-link {
      .linktype-text {
        font-weight: 600;
        color: #4175B1;
      }
    }
    .edit-again-wrapper {
      .edit-again-btn {
        border: 0.5px solid #404040;
        border-radius: 4px;
        width: 90px;
        height: 26px;
        text-transform: initial;
        font-size: 14px;
        color: #404040;
        &.disabled {
          pointer-events: none;
        }
      }
    }
  }
  .all-preferences-wrapper {
    margin: 45px 30px 30px 30px;
    &.workplan-submitted {
      border-radius: 25px;
      border: 1px solid #EEEEEE;
      .pref-type-wrapper {
        box-shadow: none;
        border: none;
        margin-bottom: 0;
        .pref-details {
          border-radius: 0;
          &.when-where {
            border-radius: 0 25px 0 0;
          }
          &.whom-with {}
          &.what-work {
            border-radius: 0 0 25px 0;
          }
        }
      }
    }
    .pref-type-wrapper {
      display: flex;
      border: 0.2px solid #C9C9C9;
      background-color:#ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.11);
      border-radius: 24px;
      margin-bottom: 5px;
      min-height: 50px;
      &.edit-mode { // open mode
        min-height: 365px;
        &.what-work {
          min-height: 250px;
          .type-header {
            .downward-line {
              min-height: 200px;
            }
          }
        }
        .type-header {
          position: relative;
          align-self: flex-start;
          margin-top: 15px;
          .downward-line {
            position: absolute;
            display: flex;
            top: 21px;
            min-height: 250px;
          }
        }
        .pref-details {
          align-items: flex-start;
          .pref-input-wrapper {
            width: 100%;
            margin-top: 40px;
          }
          .location-pref-input-wrapper {
            margin-top: unset; // due to use default link
          }
        }
        .arrow-icon {
          margin-top: 20px;
        }
      }
      .type-header {
        display: flex;
        align-items: center;
        margin-left: 15px;
        min-width: 127px;
        @media (max-width: 1070px) {
          min-width: unset;
          width: 90px;
        }
        .downward-line {
          display: none;
        }
        .mdc-text {
          font-weight: 700;
        }
        .icon-wrapper {
          height: 15px;
          width: 15px;
          margin-right: 8px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .pref-details {
        background: #FBFBFB;
        flex: 1;
        border-radius: 0 24px 24px 0;
        display: flex;
        align-items: center;
        position: relative;
        .arrow-icon {
          margin-left: auto;
          margin-right: 30px;
          position: absolute;
          right: 0;
        }
        .add-type-text {
          color: #4175B1;
          font-weight: 700;
        }
        &.when-where {
          .location-pref-input-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            .usedefault-link {
              height: 20px;
              line-height: 20px;
              margin-left: 10px;
              margin-top: 15px;
              color: #4175B1;
            }
            .day-locations-wrapper {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-top: 20px;
              margin-left: 15px;
              margin-right: 10px;
              .input-grid {
                display: grid;
                grid-template-columns: repeat(5, minmax(0, 1fr));
                height: 36px;
                grid-gap: 5px;
                grid-auto-flow: column;
                width: 100%;

                &.day-grid {
                  height: 20px;
                  margin-bottom: 5px;
                  .days-text-wrapper {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    color: #4175B1;
                    white-space: nowrap;
                    .day-issue {
                      position:absolute;
                      z-index:2;
                      right:-4px;
                      bottom: -10px;
                    }
                    .mdc-text {
                      font-weight: 600;
                    }
                    &.day-schedule {
                      .mdc-text {
                        font-weight: 800;
                        color: #404040;
                      }
                    }
                    &.past-date {
                      pointer-events: none;
                    }
                  }
                }
                &.locations-grid {
                  .location-type {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    cursor: pointer;
                    // opacity: 0.5;
                    &.dated-schedule {
                      position: relative;
                      opacity: 1;
                      > div {
                        border: 1px solid #404040;
                        border-radius: 4px;
                      }
                    }
                    &.past-date {
                      pointer-events: none;
                    }
                    &.issue {
                      > div {
                        border: 1px solid rgba(252, 85, 85, 1);
                        border-radius: 4px;
                      }
                    }
                    .notset-day {
                      display: flex;
                      align-items: center;
                      height: 100%;
                      width: 100%;
                      justify-content: center;
                      border-radius: 4px;
                      border: 1px dashed #bdbdbd;
                      .icon-wrapper {
                        width: 15px;
                        height: 15px;
                        img {
                          width: 100%;
                          height: 100%;
                        }
                      }
                      &.notset-fill {
                        background-color: var(--white-fill); // white fill with plus icon
                      }
                    }
                    .full-day {
                      display: flex;
                      align-items: center;
                      height: 100%;
                      width: 100%;
                      justify-content: center;
                      border-radius: 4px;
                      .icon-wrapper {
                        width: 15px;
                        height: 15px;
                        img {
                          width: 100%;
                          height: 100%;
                        }
                      }
                      &.thirdspace-fill {
                        background-color: var(--thirdspace-fill);
                      }
                      &.home-fill {
                        background-color: var(--home-fill);
                      }
                      &.office-fill {
                        background-color: var(--office-fill);
                      }
                      &.nonworking-fill {
                        background: var(--nonworking-fill);
                      }
                      &.notset-fill {
                        background-color: var(--notset-fill);
                      }
                    }
                    .split-day {
                      display: flex;
                      align-items: center;
                      height: 100%;
                      width: 100%;
                      justify-content: center;
                      .slot-type {
                        flex: 1;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        display: flex;
                        .icon-wrapper {
                          width: 15px;
                          height: 15px;
                          img {
                            width: 100%;
                            height: 100%;
                          }
                        }
                        &.thirdspace-fill {
                          background-color: var(--thirdspace-fill);
                        }
                        &.home-fill {
                          background-color: var(--home-fill);
                        }
                        &.office-fill {
                          background-color: var(--office-fill);
                        }
                        &.nonworking-fill {
                          background: var(--nonworking-fill);
                        }
                        &.notset-fill {
                          background-color: var(--notset-fill);
                        }
                        &.am-wrapper {
                          border-radius: 4px 0 0 4px;
                        }
                        &.pm-wrapper {
                          border-radius: 0 4px 4px 0;
                        }
                      }
                    }
                  }
                }
              }
            }
            .advanced-settings-wrapper {
              margin-top: 14px;
              &.past-date {
                pointer-events: none;
              }
              .split-full-types {
                margin: 20px 10px 10px 20px;
                display: flex;
                align-items: center;
                .split-or-full-radios {
                  display: flex;
                  align-items: center;
                  flex: 1;
                  margin-left: 10px;
                  .mdc-radio {
                    height: 14px;
                    width: 14px;
                    padding: 0;
                    &__background {
                      height: 12px;
                      width: 12px;
                    }
                    &__outer-circle {
                      border-color: rgba(149, 158, 167, 0.9) !important;
                      border: 1px solid rgba(149, 158, 167, 0.9);
                    }
                    &__inner-circle{
                      border-width: 6px;
                      border-color: #404040;
                    }
                    &___native-control {
                      height: unset;
                      width: unset;
                    }
                  }
                  .mdc-radio-wrapper {
                    margin-right: 20px;
                    label {
                      color: #404040;
                      font-size: 12px;
                      font-weight: 700;
                    }
                  }
                }
              }
              .selection-arrows-settings {
                display: flex;
                align-items: center;
                margin: 0px 15px 10px 30px;
                .day-nav-arrow {
                  align-self: flex-start;
                  margin-top: 20px;
                  &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                  }
                }
                .day-type-wrapper {
                  display: flex;
                  flex-direction: column;
                  .location-selection-wrapper {
                    margin: 0px 0px 10px 0px;
                    display: flex;
                    align-items: center;
                    .selection-text {
                      align-self: flex-start;
                      margin-top: 5px;
                      margin-right: 10px;
                    }
                    .type-selection-location {
                      display: flex;
                      flex-direction: column;
                      max-height: 100px;
                      min-height: 61px;
                      position: relative;
                      .location-details {
                        background: #fbfbfb;
                        position: absolute;
                        border: 0.5px solid #828282;
                        box-sizing: border-box;
                        border-radius: 4px;
                        top: 70px;
                        z-index: 100;
                        max-width: calc(100% + 5px);
                        width:100%;
                      }
                      .location-selection-grid {
                        display: grid;
                        grid-template-columns: repeat(4, minmax(auto, 100px));
                        height: 36px;
                        grid-gap: 10px;
                        .button-detail {
                          width: unset;
                          @media (max-width: 1055px) {
                            justify-content: center;
                          }
                          .button-image {}
                          .button-text {
                            font-size: 11px;
                            line-height: 16px;
                            height: 16px;
                            @media (max-width: 1055px) {
                              display: none;
                            }
                          }
                        }
                      }
                      .desk-select {
                        margin-left: 100px;
                        .desk-text {
                          background-color: rgba(27, 94, 173, 1);
                          color: white;
                          padding: 2px 10px;
                          margin-right:10px;
                          display:inline-block;
                        }
                        .select-desk-btn {
                          height: 20px;
                          text-transform: none;
                          color: #4175B1;
                          border: 0.5px solid #6184AD;
                          border-radius: 4px;
                          font-size: 12px;
                          font-weight: 400;
                          line-height: 20px;
                          margin-top:5px;
                        }
                      }
                      .select-location-field {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 5px;
                        width:100%;

                        > div {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 5px;
                        width:100%;
                          .location-place-value {
                            margin-left:10px;
                            display: flex;
                            align-items: center;
                            > span {
                              margin-left:5px;
                              margin-right: 10px;
                            }
                          }
                        }
                        > span {
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                          .location-place-value {
                            margin: 0 5px;
                            display: flex;
                            align-items: center;
                            .icon-wrapper {
                              margin-right: 3px;
                            }
                            .mdc-text {
                              cursor: pointer;
                              font-weight: 600;
                              text-transform: capitalize;
                              color:#4175B1;
                              &.location-text {
                                i {
                                  font-weight: 400;
                                }
                              }
                            }
                            .clear-text {
                              margin: 0 8px;
                              height: 8px;
                              width: 8px;
                              img {
                                height: 100%;
                                width: 100%;
                              }
                            }
                          }
                        }
                        .select-location-actions {
                          display: flex;
                          align-items: center;
                          .icon-wrapper {
                            margin-right: 5px;
                            img {}
                          }
                          .mdc-button {
                            height: 20px;
                            text-transform: none;
                            color: #4175B1;
                            font-size: 12px;
                            font-weight: 400;
                            padding: 0;
                            line-height: 20px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .default-next-wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-top: auto;
              margin-bottom: 20px;
              margin-right: 15px;
              .default-schedule-check {
                display: flex;
                align-items: center;
                margin: 0px 25px 0px 10px;
                .default-check-box {
                  cursor: pointer;
                  height: 15px;
                  width: 15px;
                  margin-right: 5px;
                  color: #bdbdbd;
                  img {
                    height: 100%;
                  }
                }
                .make-default-text {
                  color: #404040;
                  font-weight: 700;
                  white-space: nowrap;
                }
              }
              .navigation-buttons {
                width: unset;
                display: flex;
                .next-btn {
                  background: #FFFFFF;
                  border: 0.5px solid #404040;
                  border-radius: 4px;
                  width: 68px;
                  height: 34px;
                  color: #404040;
                  font-size: 14px;
                  margin-right: 15px;
                  &.disabled {
                    pointer-events: none;
                    color: #9CA4AE;
                    border: 1px solid #9CA4AE;
                  }
                }
              }
            }
            .help-text {
              margin: 15px 45px 3px 40px;
              .mdc-text {
                color: #b10000;
              }
            }
            .pattern-schedule:not(:empty) {
              margin: 15px 45px 3px 10px;
              .bullet {
                border-radius: 50%;
                height: 6px;
                width: 6px;
                display: inline-block;
                margin-right: 6px;
                vertical-align: middle;
                &.red {
                  color: #b10000;
                  background: #b10000;
                }
                &.grey {
                  color: #394A5D;
                  background: #394A5D;
                }
              }
              span {
                &.red {
                  color: #b10000 !important;
                }
              }
              .mdc-text {
                &.red {
                  color: #b10000;
                }
                &.grey {
                  color: #394A5D;
                }
              }
            }
          }
          .preference {
            &.locations {
              display: flex;
              align-items: center;
              margin-left: 20px;
              flex-wrap: wrap;
              > span:not(:empty) {
                padding-left: 5px;
                margin-right: 5px;
              }
              span {
                &.answer {
                  min-width: 30px;
                  max-width: 75px;
                  .day-letter {
                    width: 15px;
                    text-align: right;
                  }
                  .day-issue {
                    position:absolute;
                    margin-top:-8px;
                    margin-left:40px !important;
                    background: transparent !important;
                    img {
                      width: 10px;
                      height: 10px;
                    }
                  }
                  > .mdc-text {
                    font-weight: 700;
                    color: #4f4f4f;
                  }
                  &.full-day {
                    display: flex;
                    align-items: baseline;
                    .icon-wrapper {
                      margin-left: 5px;
                      width: 35px;
                      height: 20px;
                      border-radius: 4px;
                      background-color: var(--notset-fill);
                      img {
                        width: 10px;
                        height: 10px;
                      }
                      &.thirdspace-fill {
                        background-color: var(--thirdspace-fill);
                      }
                      &.home-fill {
                        background-color: var(--home-fill);
                      }
                      &.office-fill {
                        background-color: var(--office-fill);
                      }
                      &.nonworking-fill {
                        background: var(--nonworking-fill);
                      }
                      &.notset-fill {
                        background-color: var(--notset-fill);
                      }
                      &.issue {
                        border: 1px solid rgb(252, 85, 85);
                        border-radius: 4px;
                      }
                    }
                  }
                  &.split-day {
                    display: flex;
                    align-items: baseline;
                    flex-wrap: wrap;
                    .split-day-grid {
                      display: grid;
                      grid-template-columns: 20px 20px;
                      height: 20px;
                      align-items: center;
                      grid-gap: 0.5px;
                      margin-left: 5px;
                      .am-wrapper {
                        border-radius: 4px 0 0 4px;
                      }
                      .pm-wrapper {
                        border-radius: 0 4px 4px 0;
                      }
                      .slot-text {
                        font-size: 8px;
                      }
                      .slot-type {
                        background-color: var(--notset-fill);
                        .icon-wrapper {
                          width: 20px;
                          height: 20px;
                          img {
                            width: 10px;
                            height: 10px;
                          }
                        }
                        &.thirdspace-fill {
                          background-color: var(--thirdspace-fill);
                        }
                        &.home-fill {
                          background-color: var(--home-fill);
                        }
                        &.office-fill {
                          background-color: var(--office-fill);
                        }
                        &.nonworking-fill {
                          background: var(--nonworking-fill);
                        }
                        &.notset-fill {
                          background-color: var(--notset-fill);
                        }
                        &.default {
                          background-color: var(--notset-fill);
                        }
                      }
                    }
                    .day-issue {
                      position:absolute;
                      margin-top:-3px;
                      margin-left:55px !important;
                      background: transparent !important;
                      img {
                        width: 10px;
                        height: 10px;
                      }
                    }
                    &.issue {
                      .split-day-grid {
                        border: 1px solid rgb(252, 85, 85);
                        border-radius: 4px;
                      }
                    }
                  }
                  &.notset-day {
                    display: flex;
                    align-items: baseline;
                    .icon-wrapper {
                      margin-left: 5px;
                      width: 35px;
                      height: 20px;
                      border-radius: 4px;
                      background-color: var(--notset-fill);
                      img {
                        width: 10px;
                        height: 10px;
                        visibility: hidden;
                      }
                    }
                    &.notset-fill {
                      background-color: var(--notset-fill);
                    }
                  }
                }
                &.locations-use-link {
                  font-weight: 400;
                  color: #4175B1;
                  margin-left: 5px;
                }
              }
            }
          }
        }
        &.who-with {
          .people-info-wrapper {
            display: flex;
            flex-direction: column;
            margin: 0 35px 0 20px;
            .invitions-sent-wrapper {
              margin-top: 10px;
              display:flex;
              flex-direction: column;
              .invite-heading {
                color: #404040;
                font-weight: 400;
              }
              .invites-list {
                display: flex;
                align-items: center;
                .invite-email {
                  color: #4F4F4F;
                  font-weight: 700;
                }
              }
            }
            &.send-email-wrapper {
              .help-text {
                margin-top: 5px;
                color: #b10000;
              }
              .invites-txtarea {
                color: #404040;
                font-size: 16px;
                border: 0.5px solid #E0E0E0;
                border-radius: 2px;
                background: #fff;
                resize: none;
                height: 100px;
                max-height: 150px;
                padding: 10px;
                line-height: 19px;
                margin-top: 7px;
                &::placeholder {
                  color: #828282;
                  font-size: 14px;
                }
              }
              .send-invites-actions {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 25px;
                .btn-text {
                  font-weight: 700;
                  font-size: 14px;
                  padding: 0;
                  min-width: unset;
                  color: #828F9C;
                  &.inv-send-btn {
                    color: #4175B1;
                  }
                  &.inv-back-btn {}
                  &.disabled {
                    pointer-events: none;
                    color: #9CA4AE;
                    opacity: 0.3;
                  }
                }
              }
            }
            &.meeting-people-wrapper {
              margin-bottom: 10px;
              margin-top: 25px;
            }
            .heading {
              color: #404040;
              font-weight: 600;
            }
            .heading-invite-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-right: 20px;
              flex-wrap: wrap;
              .invite-link {
                color: #4175B1;
                font-weight: 600;
              }
            }
            .loading-text {
              margin-left: 0;
              .mdc-text {
                color: #959EA7;
              }
            }
            .no-members-text {
              margin-top: 10px;
              .meeting-no-one-text {
                color: #959EA7;
              }
              .button-detail {
                margin-right: 10px;
              }
              .connect-cal-options {
                display: flex;
                align-items: center;
              }
              .cal-logo {
                margin: 0 23px 0 5px;
                &:last-of-type {
                  margin-left: 0;
                }
              }
            }
            .link {
              color: #4175B1;
              font-weight: 400;
              text-decoration: none;
              margin: 10px 0 7px 0;
            }
            .people-buttons {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex-wrap: wrap;
              margin: 10px 0;
              grid-gap: 10px;
            }
          }
          .show-more-wrapper {
            margin-left: 20px;
            margin-top: 5px;
            .show-more-link {
              color: #4175B1;
              font-weight: 400;
              text-decoration: none;
              cursor: pointer;
            }
          }
          .navigation-buttons {
            margin: 20px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .mdc-button {
              background: #FFFFFF;
              border: 0.5px solid #404040;
              border-radius: 4px;
              width: 68px;
              height: 34px;
              color: #404040;
              font-size: 14px;
              margin-right: 30px;
                &.back-btn {
                  margin-left: 20px;
                }
            }
          }
          .preference {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &.people {
              margin-left: 20px;
              align-items: center;
              margin-right: 10px;
              .profile-redirect {
                margin-right: 15px;
                .photo-wrapper {
                  .profile-initials {
                    width: 24px;
                    height: 24px;
                  }
                  &:before {
                    width: 24px;
                    height: 24px;
                  }
                }
                .profile-initials {
                  width: 24px;
                  height: 24px;
                  font-size: 12px;
                  font-weight: 700;
                  &:before {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
        &.what-work {

          display:flex;
          flex-flow:column;
          justify-content: center;
          align-items: flex-start;

          .topics-pref-input-wrapper {
            flex: 1;
            display:flex;
            flex-flow:column;
          }

          .input-and-link-wrapper {
            display: flex;
            align-items: center;
            .v-select {
              margin-top: 0;
              margin-left: 20px;
              flex: 1;
            }
            .create-link {
              color: #4175B1;
              font-size: 600;
              margin-right: 20px;
            }
          }

          .no-initiatives {
            margin-top: 10px;
            margin-left: 20px;
            .button-detail {
              margin-right: 10px;
            }
          }

          .topics-info-wrapper {
            display: flex;
            flex-direction: column;
            .heading {
              margin: 20px 25px 15px 20px;
              color: #404040;
              font-weight: 600;
            }
            .link {
              color: #4175B1;
              font-weight: 300;
              text-decoration: none;
              margin: 0 25px 7px 20px;
            }
            .topics-buttons {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 0 10px 10px 20px;
              grid-gap: 10px;
              flex-wrap: wrap;
              &:not(:last-of-type) {
                border-bottom: 0.5px dashed #989898;
              }
              .button-detail {
                .button-text {
                  font-weight: 700;
                }
              }
            }

            .no-topics-text {
              margin-left: 20px;
              .mdc-text {
                color: #959EA7;
              }
              .page-link {
                text-decoration: none;
                .add-link {
                  cursor: pointer;
                  color: #6184ad;
                }
              }
            }
          }
          .navigation-buttons {
            margin-bottom: 30px;
            margin-top:auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .mdc-button {
              background: #FFFFFF;
              border: 0.5px solid #404040;
              border-radius: 4px;
              width: 68px;
              height: 34px;
              color: #404040;
              font-size: 14px;
              &.back-btn {
                margin-left: 20px;
              }
            }
          }
          .preference {
            &.topics {
              margin-left: 20px;
              .all-topics-count-wrapper {
                display: grid;
                grid-template-columns: minmax(100px, calc(100% - 85px)) 40px;
                align-items: center;
                .all-topics-list {
                  display: inline-block;
                  align-items: center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  flex-direction: row;
                }
                .load-topics-count {
                  margin-left: auto;
                }
              }
              .topic-name {
                text-transform: uppercase;
                font-weight: 700;
                color: #4f4f4f;
              }
            }
          }
        }
        .v-select:not(.members-select) {
          background: #FFFFFF;
          border: 1px solid #E0E0E0;
          box-sizing: border-box;
          margin-top: 10px;
          margin-right: 10px;
          max-width: 330px;
          min-width: 150px;
          .vs__dropdown-toggle {
            border: none;
          }
          .vs__selected {
            color: #7e7070;
            font-size: 14px;
          }
          .vs__dropdown-toggle .vs__selected-options {
            padding: 0;
            input {
              font-size: 14px;
              color: #404040;
              height: 24px;
              &::-webkit-input-placeholder {
                color: #828282;
                font-size: 14px;
                padding-left: 5px;
              }
              &::-webkit-textfield-decoration-container {
                padding-left: 5px;
              }
            }
          }
          .vs__dropdown-menu {
            margin: 0;
            list-style: none;
            z-index: 11;
            background: #fff;
            border-radius: 6px;
            color: #6184ad;
            position: absolute;
            border: none;
            padding: 0;
            .no-options-detail {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px 12px;
              .searched-txt {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #404040;
                max-width: 75px;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 10px;
              }
              .no-option-link {
                color: #4175B1;
                font-size: 14px;
                font-weight: 400;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: -webkit-fill-available;
                overflow: hidden;
                &.disabled {
                  pointer-events: none;
                }
              }
            }
            .vs__dropdown-option {
              padding: 5px 12px;
              display: inline-flex;
              align-items: baseline;
              width: 100%;
              cursor: pointer;
              color: #6184ad;
              white-space: normal;
            }
            .vs__dropdown-option:hover {
              background: #EBF3FC;
            }
            .vs__dropdown-option--highlight {
              background: #EBF3FC;
            }
          }
        }
      }
    }
  }
  .sticky-footer {
    box-shadow: 0 5px 30px rgba(0,0,0,.1);
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    background: #FFFFFF;
    border: 0.3px solid #F4F4F4;
    border-left: 0px;
    border-right: 0px;
    z-index: 30;
    width: 100%;
    right: 0;
    .sticky-submit-btn {
      margin-left: auto;
      margin-right: 30px;
      background: #4175B1;
      border-radius: 4px;
      width: 88px;
      height: 34px;
      min-width: unset;
      color: #fff;
      &.disabled {
        opacity: 0.7;
        pointer-events: none;
        background: #bdbdbd;
      }
    }
  }
  .button-wrapper {
    display: flex;
    margin-bottom: 20px;
    .submit-btn {
      margin-right: 30px;
      background: #4175B1;
      border-radius: 4px;
      width: 88px;
      height: 34px;
      min-width: unset;
      color: #fff;
      &.disabled {
        opacity: 0.7;
        pointer-events: none;
        background: #bdbdbd;
      }
    }
  }
  .submitted-text-bodlink {
    display: flex;
    align-items: center;
    margin-left: 40px;
    flex-wrap: wrap;
    .recomm-text {
      color: #404040;
      font-weight: 600;
    }
    .bod-link-text {
      color: #4175B1;
      margin-left: 3px;
      font-weight: 600;
    }
  }
  .pastweek-error-text {
    display: flex;
    align-items: center;
    margin-left: 40px;
    .mdc-text {
      color: #b10000;
      .link {
        color: #b10000;
      }
    }
  }
}
.onboarding-workplan-wrapper {
  margin-top:50px;
  .desk-selection {
    margin-top: 0px;
  }
}
</style>
