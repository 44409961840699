import { Mark, markInputRule, mergeAttributes } from '@tiptap/core'

export const starInputRegex = /(?:^|\s)((?:\*)((?:[^*]+))(?:\*))$/gm
const BoldMark = Mark.create({
  name: 'boldmark',
  addOptions () {
    return {
      HTMLAttributes: {}
    }
  },
  parseHTML () {
    return [
      {
        tag: 'strong'
      },
      {
        tag: 'b',
        getAttrs: (node) => { return node.style.fontWeight !== 'normal' && null }
      },
      {
        style: 'font-weight',
        getAttrs: (value) => { return /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null }
      }
    ]
  },
  renderHTML ({ HTMLAttributes }) {
    return ['strong', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addInputRules () {
    return [
      markInputRule({
        find: starInputRegex,
        type: this.type
      })
    ]
  }
})
export default BoldMark
