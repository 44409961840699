const firebaseAppName = process.env.VUE_APP_NAME || 'flexibly-testing'
export const appName = firebaseAppName
export const gaeURL = (firebaseAppName === 'flexibly-live') ? 'https://mslogin.duome.co' : `https://${firebaseAppName}.appspot.com`
export const publicProfilesUrl = (firebaseAppName === 'flexibly-live') ? 'https://public.flexibly.co' : `https://${firebaseAppName}.appspot.com`
export const region = (firebaseAppName === 'flexibly-live') ? 'europe-west2' : 'europe-west1'
export const functionsUrl = `https://${region}-${firebaseAppName}.cloudfunctions.net`

// export const functionsUrl = `http://localhost:5000/${firebaseAppName}/${region}`

const appKeys = {
  'flexibly-testing': {
    segment: 'CY6j92rEdsmw42TYs2gEv4LgjKW7nV9v',
    apiKey: 'AIzaSyDsatJM4bs2KWqKPFzOWUU0FlBRS7KjtEw',
    messagingSenderId: '106368073306',
    appId: '1:106368073306:web:8ebaa3efbaa2a8f2',
    algoliaId: 'J4XWS6I9XF',
    algoliaKey: 'dac0c22784d6ac98378d46caf8a6deaa',
    providerURL: 'hocus-dot-flexibly-testing.appspot.com'
  },
  'flexibly-demo': {
    segment: 'CY6j92rEdsmw42TYs2gEv4LgjKW7nV9v',
    apiKey: 'AIzaSyAbsqqw4iYKK-tA3mT-jSbC_bd_Jqk0Zf0',
    messagingSenderId: '106368073306',
    appId: '1:269659973326:web:24d0b6def3c4cdafa1529f',
    algoliaId: 'GFOOHJJ4DM',
    algoliaKey: '2eee9dbf4580108a039662db36bab10c',
    providerURL: 'hocus-dot-flexibly-demo.appspot.com'
  },
  'flexibly-live': {
    segment: '3Kz8YpLXxW09hhiZcOWZKx13Xc5Ndfec',
    apiKey: 'AIzaSyDz9TBunGjsRf-31EFH3MI_8ZIpwS8ICxw',
    messagingSenderId: '568517475817',
    appId: '1:568517475817:web:8d57c4763abfbbbc60df78',
    algoliaId: 'PX7SAD2RRR',
    algoliaKey: 'c7eab993f5e74506ed78a3a1ccbf85ff',
    providerURL: 'hocus-dot-flexibly-live.appspot.com'
  }
}

// console.log('Firebase App ', firebaseAppName)

const { segment, apiKey, messagingSenderId, appId, algoliaId, algoliaKey, providerURL } = appKeys[firebaseAppName]

const authDomain = (firebaseAppName === 'flexibly-live') ? 'app.duome.co' : `${firebaseAppName}.firebaseapp.com`

export const firebaseSettings = {
  apiKey,
  authDomain,
  databaseURL: `https://${firebaseAppName}.firebaseio.com`,
  projectId: firebaseAppName,
  storageBucket: `${firebaseAppName}.appspot.com`,
  messagingSenderId,
  appId
}

export const appSettings = {
  segment,
  appId,
  algoliaId,
  algoliaKey,
  providerURL
}

export const allowedEmails = [
  'jelliott+',
  'rchan+',
  'eric+',
  'graham+',
  'stephen+'
]
