import moment from 'moment'
import { RRule, rrulestr } from 'rrule'
import { sameDay } from './time'

function setDatePart (datetime, date) {
  if (!!datetime && !!date) {
    if (date.toDate) {
      date = date.toDate()
    }
    if (datetime.toDate) {
      datetime = datetime.toDate()
    }
    const dateMoment = moment(date)
    const newD = moment(datetime)
    return newD.set({ year: dateMoment.year(), month: dateMoment.month(), date: dateMoment.date() })
  }
  return datetime
}

function getRRuleFreq (freq) {
  if (freq === 'daily') {
    return RRule.DAILY
  }
  if (freq === 'weekly') {
    return RRule.WEEKLY
  }
  if (freq === 'absoluteMonthly') {
    return RRule.MONTHLY
  }
  if (freq === 'relativeMonthly') {
    return RRule.MONTHLY
  }
  if (freq === 'absoluteYearly') {
    return RRule.YEARLY
  }
  return false
}

function getRRuleDays (dayList) {
  const rruleDays = []
  for (const day in dayList) {
    if (day === 'monday') {
      rruleDays.push(RRule.MO)
    } if (day === 'tuesday') {
      rruleDays.push(RRule.TU)
    } if (day === 'wednesday') {
      rruleDays.push(RRule.WE)
    } if (day === 'thursday') {
      rruleDays.push(RRule.TH)
    } if (day === 'friday') {
      rruleDays.push(RRule.FR)
    } if (day === 'saturday') {
      rruleDays.push(RRule.SA)
    } if (day === 'sunday') {
      rruleDays.push(RRule.SU)
    }
  }
  return rruleDays
}

export function isEnded (meetingRec) {
  let ended = false
  // Rec meeting cancelled
  if (meetingRec.status === 'cancelled') {
    return true
  } else if (meetingRec.status !== 'active' && meetingRec.meetingSource !== 'Google') {
    // MS
    return true
  }

  if (meetingRec.ended) {
    return true
  }

  if (!ended) {
    if (meetingRec.meetingSource === 'Google') {
      // Google
      if (meetingRec.reccurrencePattern) {
        for (const patternRule of meetingRec.reccurrencePattern) {
          if (patternRule.indexOf('RRULE') === 0) {
            const rrule = rrulestr(patternRule)
            // console.log('meetings.isEnded.google', rrule.options)
            if (rrule.options.until) {
              ended = moment(rrule.options.until).isBefore(moment())
            }
          }
        }
      }
    } else {
      if (meetingRec.reccurrencePattern) {
        if (meetingRec.reccurrencePattern.range && meetingRec.reccurrencePattern.range.endDate) {
          const untilEnd = meetingRec.reccurrencePattern.range.endDate
          // console.log('meetings.isEnded.ms', untilEnd)
          ended = moment(untilEnd).isBefore(moment())
        }
      }
    }
  }

  return ended
}

export function getNextRecMeeting (scheduleDate, meetingsRecList) {
  let nextMeeting = false
  let nextMeetingDate = false
  for (const meetingRec of meetingsRecList) {
    let ended = false

    // Rec meeting cancelled
    if (meetingRec.status === 'cancelled') {
      ended = true
    } else if (meetingRec.status !== 'active' && meetingRec.meetingSource !== 'Google') {
      // MS
      ended = true
    }

    // Rec meeting future meeting deleted
    const endDate = meetingRec.ended ? meetingRec.ended.toDate ? meetingRec.ended.toDate() : meetingRec.ended : false
    if (!ended && endDate) {
      if (moment(scheduleDate).isAfter(endDate)) {
        ended = true
      }
    }

    if (!ended) {
      if (meetingRec.meetingSource === 'Google') {
        // Google
        if (meetingRec.reccurrencePattern) {
          for (const patternRule of meetingRec.reccurrencePattern) {
            if (patternRule.indexOf('RRULE') === 0) {
              const options = rrulestr(patternRule)
              const rule = new RRule({
                ...options.options,
                dtstart: scheduleDate,
                count: 1
              })
              const nextDates = rule.all()
              // console.log('getNextRecMeeting.google', meetingRec.subject, scheduleDate, nextDates)
              if (nextDates.length) {
                const nextDate = nextDates[0]
                if (!nextMeeting) {
                  meetingRec.startDateTime = setDatePart(meetingRec.startDateTime, nextDate)
                  nextMeeting = meetingRec
                  nextMeetingDate = nextDate
                } else {
                  if (moment(nextDate).isBefore(nextMeetingDate)) {
                    meetingRec.startDateTime = setDatePart(meetingRec.startDateTime, nextDate)
                    nextMeeting = meetingRec
                    nextMeetingDate = nextDate
                  }
                }
              }
            }
          }
        }
      } else {
        // MS
        const rec = {}
        if (meetingRec.reccurrencePattern) {
          if (meetingRec.reccurrencePattern.pattern) {
            rec.freq = meetingRec.reccurrencePattern.pattern.type
            rec.freqDays = meetingRec.reccurrencePattern.pattern.daysOfWeek
            rec.freqInterval = meetingRec.reccurrencePattern.pattern.interval
            rec.freqDom = meetingRec.reccurrencePattern.pattern.dayOfMonth
            rec.freqMonth = meetingRec.reccurrencePattern.pattern.month
          }
          if (meetingRec.reccurrencePattern.range) {
            rec.untilType = meetingRec.reccurrencePattern.range.type
            rec.untilStart = meetingRec.reccurrencePattern.range.startDate
            rec.untilEnd = meetingRec.reccurrencePattern.range.endDate
            rec.untilCount = meetingRec.reccurrencePattern.range.numberOfOccurrences
          }
        }
        if (rec.freq) {
          let stopped = false
          if (rec.untilEnd) {
            if (moment(rec.untilEnd).isBefore(moment())) {
              stopped = true
            }

            if (!stopped) {
              const rruleParams = {
                freq: getRRuleFreq(rec.freq)
              }
              if (rec.untilCount) {
                rruleParams.count = rec.untilCount
              }
              if (rec.freqDays) {
                rruleParams.byweekday = getRRuleDays(rec.freqDays)
              }
              if (rec.freqInterval) {
                rruleParams.interval = rec.freqInterval
              }
              if (rec.freqDom) {
                rruleParams.bymonthday = rec.freqDom
              }
              if (rec.freqMonth) {
                rruleParams.bymonth = rec.freqMonth
              }

              const rule = new RRule({
                ...rruleParams,
                dtstart: scheduleDate,
                count: 1
              })

              const nextDates = rule.all()
              // console.log('getNextRecMeeting.ms', meetingRec.subject, nextDates)
              if (nextDates.length) {
                const nextDate = nextDates[0]
                if (!nextMeeting) {
                  meetingRec.startDateTime = setDatePart(meetingRec.startDateTime, nextDate)
                  nextMeeting = meetingRec
                  nextMeetingDate = nextDate
                } else {
                  if (moment(nextDate).isBefore(moment(nextMeetingDate))) {
                    meetingRec.startDateTime = setDatePart(meetingRec.startDateTime, nextDate)
                    nextMeeting = meetingRec
                    nextMeetingDate = nextDate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return { nextMeeting, nextMeetingDate }
}

export function getRecurringPatternText (recurPattern, meetingSource) {
  if (recurPattern) {
    if (meetingSource === 'Google') {
      const rRuleIndex = recurPattern.findIndex(pat => pat.indexOf('RRULE') >= 0)
      const text = RRule.fromString(recurPattern[rRuleIndex])
      return text.toText()
    } else {
      const pattern = recurPattern.pattern
      const range = recurPattern.range
      const rec = {}
      if (pattern) {
        rec.freq = pattern.type
        rec.freqDays = pattern.daysOfWeek
        rec.freqInterval = pattern.interval
        rec.freqDom = pattern.dayOfMonth
        rec.freqMonth = pattern.month
      }
      if (range) {
        rec.untilType = range.type
        rec.untilStart = range.startDate
        rec.untilEnd = range.endDate
        rec.untilCount = range.numberOfOccurrences
      }
      const rruleParams = {
        freq: getRRuleFreq(rec.freq)
      }
      if (rec.untilCount) {
        rruleParams.count = rec.untilCount
      }
      if (rec.freqDays) {
        rruleParams.byweekday = getRRuleDays(rec.freqDays)
      }
      if (rec.freqInterval) {
        rruleParams.interval = rec.freqInterval
      }
      if (rec.freqDom) {
        rruleParams.bymonthday = rec.freqDom
      }
      if (rec.freqMonth) {
        rruleParams.bymonth = rec.freqMonth
      }
      if (rec.untilStart) {
        rruleParams.dtstart = new Date(rec.untilStart)
      }
      if (rec.untilEnd && rec.untilType !== 'noEnd') {
        rruleParams.until = new Date(rec.untilEnd)
      }
      const rule = new RRule({
        ...rruleParams
      })
      return rule.toText()
    }
  }
}

export function getInstance (meetingRec, meetingStart, meetingEnd, scheduleDate) {
  let attendees = []
  if (meetingRec.attendees) {
    attendees = structuredClone(meetingRec.attendees)
  }
  const meetingInst = { ...meetingRec, attendees }
  meetingInst.startDateTime = setDatePart(meetingStart, scheduleDate)
  meetingInst.endDateTime = setDatePart(meetingEnd, scheduleDate)
  meetingInst.generatedFromMaster = true
  return meetingInst
}

export function getMeetingForDate (scheduleDate, meetingList, meetingsRecList) {
  const dayAbb = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
  const includedRec = []
  if (!meetingList) {
    meetingList = []
  }
  if (!meetingsRecList) {
    meetingsRecList = []
  }
  const scheduleDateMoment = moment(scheduleDate)
  if (!scheduleDate.getDay) {
    scheduleDate = scheduleDateMoment.toDate()
  }
  let meetings = meetingList.filter(meeting => {
    const start = meeting.startDateTime ? meeting.startDateTime.toDate ? meeting.startDateTime.toDate() : meeting.startDateTime : null
    const sameday = start && scheduleDate && sameDay(start, scheduleDate)

    if (sameday && meeting.recurringEventId && meeting.meetingSource === 'Google') {
      // Google meetings
      includedRec.push(meeting.userId + '-' + meeting.recurringEventId)
    } else if (sameday && meeting.masterEvent) {
      // MS Meetings
      includedRec.push(meeting.masterEvent)
    }

    if (meeting.status === 'cancelled') {
      return false
    }
    return sameday
  })

  // console.log('getMeetingsForTheDay.meetings', meetings)
  const meetingsRec = meetingsRecList
  // console.log('getMeetingsForTheDay.meetingsRec', meetingsRec, includedRec)

  for (const meetingRec of meetingsRec) {
    if (!includedRec.includes(meetingRec.id)) {
      let ended = false
      const endDate = meetingRec.ended ? meetingRec.ended.toDate ? meetingRec.ended.toDate() : meetingRec.ended : false
      if (endDate) {
        if (moment(scheduleDate).isAfter(endDate)) {
          ended = true
        }
      }

      if (!ended) {
        if (meetingRec.meetingSource === 'Google') {
        // Google rec meeting
          let meetingStart = meetingRec.startDateTime
          if (meetingStart.toDate) {
            meetingStart = meetingStart.toDate()
          }
          let meetingEnd = meetingRec.endDateTime
          if (meetingEnd.toDate) {
            meetingEnd = meetingEnd.toDate()
          }
          const rec = {}
          let rrule = false
          if (meetingRec.reccurrencePattern) {
            for (const patternRule of meetingRec.reccurrencePattern) {
              if (patternRule.indexOf('RRULE') === 0) {
                rrule = patternRule
              }
            }
          }
          // console.log('getMeetingsForTheDay.google', rrule, meetingRec)

          if (rrule) {
            const recPattern = rrule
            const patterParts = recPattern.split(':')[1].split(';')
            for (const part of patterParts) {
              rec[part.split('=')[0]] = part.split('=')[1]
            }
            if (rec.FREQ) {
              // console.log('getMeetingsForTheDay.rec', rec, meetingRec)
              let ended = false
              let started = true
              const scheduleDateMomentEnd = moment(scheduleDateMoment).endOf('day')
              if (moment(meetingStart).isAfter(scheduleDateMomentEnd)) {
                started = false
              }
              if (rec.UNTIL) {
                const until = moment(rec.UNTIL.replace('Z', ''))
                if (until.isBefore(scheduleDateMoment)) {
                  ended = true
                }
              }
              if (rec.INTERVAL) {
                const interval = parseInt(rec.INTERVAL) - 1
                if (rec.FREQ === 'DAILY') {
                  const untilEnd = moment(meetingStart).add(interval, 'days')
                  // console.log('getMeetingsForTheDay', rec.COUNT, scheduleDate, untilEnd, meetingStart)
                  if (untilEnd.isBefore(scheduleDateMoment)) {
                    ended = true
                  }
                }
                if (rec.FREQ === 'WEEKLY') {
                  const untilEnd = moment(meetingStart).add(interval, 'weeks')
                  if (untilEnd.isBefore(scheduleDateMoment)) {
                    ended = true
                  }
                }
                if (rec.FREQ === 'MONTHLY') {
                  const untilEnd = moment(meetingStart).add(interval, 'months')
                  if (untilEnd.isBefore(scheduleDateMoment)) {
                    ended = true
                  }
                }
                if (rec.FREQ === 'YEARLY') {
                  const untilEnd = moment(meetingStart).add(interval, 'years')
                  if (untilEnd.isBefore(scheduleDateMoment)) {
                    ended = true
                  }
                }
              }
              if (rec.COUNT) {
                const count = parseInt(rec.COUNT) - 1
                if (rec.FREQ === 'DAILY') {
                  const untilEnd = moment(meetingStart).add(count, 'days')
                  // console.log('getMeetingsForTheDay', rec.COUNT, scheduleDate, untilEnd, meetingStart)
                  if (untilEnd.isBefore(scheduleDateMoment)) {
                    ended = true
                  }
                }
                if (rec.FREQ === 'WEEKLY') {
                  const untilEnd = moment(meetingStart).add(count, 'weeks')
                  if (untilEnd.isBefore(scheduleDateMoment)) {
                    ended = true
                  }
                }
                if (rec.FREQ === 'MONTHLY') {
                  const untilEnd = moment(meetingStart).add(count, 'months')
                  if (untilEnd.isBefore(scheduleDateMoment)) {
                    ended = true
                  }
                }
                if (rec.FREQ === 'YEARLY') {
                  const untilEnd = moment(meetingStart).add(count, 'years')
                  if (untilEnd.isBefore(scheduleDateMoment)) {
                    ended = true
                  }
                }
              }
              if (started && !ended) {
                if (rec.FREQ === 'DAILY') {
                  const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                  meetings.push(meetingInst)
                } else if (rec.FREQ === 'WEEKLY') {
                  let recDay = rec.BYDAY
                  if (scheduleDate) {
                    if (!recDay) {
                      if (meetingStart) {
                        recDay = moment(meetingStart).format('dd')
                      }
                    }
                    // console.log('getMeetingsForTheDay.recTest Weekly', dayAbb[scheduleDate.getDay()], recDay, rec, meetingRec)
                    if (recDay) {
                      if (recDay.indexOf(dayAbb[scheduleDate.getDay()]) >= 0) {
                        const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                        meetings.push(meetingInst)
                      }
                    }
                  }
                } else if (rec.FREQ === 'MONTHLY') {
                  if (scheduleDate) {
                    if (rec.BYDAY && rec.BYDAY.length === 3) {
                      // Relative positive
                      const recWOM = parseInt(rec.BYDAY.substring(0, 1))
                      const recDay = rec.BYDAY.substring(1)
                      const date = scheduleDate.getDate()
                      const day = scheduleDate.getDay()
                      const weekOfMonth = Math.ceil((date - 1 - day) / 7)
                      if (recWOM === weekOfMonth && recDay.indexOf(dayAbb[scheduleDate.getDay()]) >= 0) {
                        const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                        meetings.push(meetingInst)
                      }
                    } else if (rec.BYDAY && rec.BYDAY.length === 4) {
                      // Relative negative
                      const recWOM = 5 - parseInt(rec.BYDAY.substring(1, 2))
                      const recDay = rec.BYDAY.substring(2)
                      const date = scheduleDate.getDate()
                      const day = scheduleDate.getDay()
                      const weekOfMonth = Math.ceil((date - 1 - day) / 7)
                      if (recWOM === weekOfMonth && recDay.indexOf(dayAbb[scheduleDate.getDay()]) >= 0) {
                        const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                        meetings.push(meetingInst)
                      }
                    } else if (rec.BYMONTHDAY) {
                      const date = scheduleDate.getDate()
                      if (rec.BYMONTHDAY === `${date}`) {
                        const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                        meetings.push(meetingInst)
                      }
                    }
                  }
                } else if (rec.FREQ === 'YEARLY') {
                  if (scheduleDate) {
                    if (rec.BYMONTHDAY && rec.BYMONTH) {
                      const date = scheduleDate.getDate()
                      const month = scheduleDate.getMonth() + 1
                      if (rec.BYMONTHDAY === `${date}` && rec.BYMONTH === `${month}`) {
                        const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                        meetings.push(meetingInst)
                      }
                    }
                  }
                } else {
                  console.error('getMeetingsForTheDay.meetingsRec unknown Pattern -', rec, meetingRec)
                }
              }
            }
          }
        } else {
        // MS rec meeting
          let meetingStart = meetingRec.startDateTime
          if (meetingStart.toDate) {
            meetingStart = meetingStart.toDate()
          }
          let meetingEnd = meetingRec.endDateTime
          if (meetingEnd.toDate) {
            meetingEnd = meetingEnd.toDate()
          }
          const rec = {}
          if (meetingRec.reccurrencePattern) {
            if (meetingRec.reccurrencePattern.pattern) {
              rec.freq = meetingRec.reccurrencePattern.pattern.type
              rec.freqDays = meetingRec.reccurrencePattern.pattern.daysOfWeek
              rec.freqInterval = meetingRec.reccurrencePattern.pattern.interval
              rec.freqDom = meetingRec.reccurrencePattern.pattern.dayOfMonth
              rec.freqMonth = meetingRec.reccurrencePattern.pattern.month
            }
            if (meetingRec.reccurrencePattern.range) {
              rec.untilType = meetingRec.reccurrencePattern.range.type
              rec.untilStart = meetingRec.reccurrencePattern.range.startDate
              rec.untilEnd = meetingRec.reccurrencePattern.range.endDate
              rec.untilCount = meetingRec.reccurrencePattern.range.numberOfOccurrences
            }
          }
          if (rec.freq) {
            let stopped = false
            if (meetingRec.status !== 'active') {
              stopped = true
            } else if (rec.untilEnd) {
              if (moment(rec.untilEnd).isBefore(scheduleDateMoment)) {
                stopped = true
              }
            }
            if (!stopped) {
              if (rec.freq === 'daily') {
                const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                meetings.push(meetingInst)
              } else if (rec.freq === 'weekly') {
                const recDay = moment(scheduleDate).format('dddd').toLowerCase()
                if (rec.freqDays) {
                  if (rec.freqDays.includes(recDay)) {
                    const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                    meetings.push(meetingInst)
                  }
                }
              } else if (rec.freq === 'absoluteMonthly') {
                if (rec.freqDom) {
                  const recDay = moment(scheduleDate).format('D')
                  if (`${rec.freqDom}` === recDay) {
                    const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                    meetings.push(meetingInst)
                  }
                }
              } else if (rec.freq === 'relativeMonthly') {
                // console.log('getMeetingsForTheDay.MS.relativeMonthly', rec, meetingRec)
              } else if (rec.freq === 'absoluteYearly') {
                if (rec.freqDom && rec.freqMonth) {
                  const recDay = moment(scheduleDate).format('D M')
                  if (`${rec.freqDom} ${rec.freqMonth}` === recDay) {
                    const meetingInst = getInstance(meetingRec, meetingStart, meetingEnd, scheduleDate)
                    meetings.push(meetingInst)
                  }
                }
              } else if (rec.freq === 'relativeYearly') {
                // console.log('getMeetingsForTheDay.MS.relativeMonthly', rec, meetingRec)
              }
            }
          }
        }
      }
    }
  }
  meetings = meetings.sort((a, b) => {
    if (!a.startDateTime) {
      return 1
    } else if (!b.startDateTime) {
      return 0
    } else {
      const aStart = a.startDateTime.toDate ? a.startDateTime.toDate().getTime() : a.startDateTime.getTime()
      const bStart = b.startDateTime.toDate ? b.startDateTime.toDate().getTime() : b.startDateTime.getTime()
      return aStart - bStart
    }
  })
  // console.log('utils.meetings', meetings)
  return meetings
}
