<template>
  <div ref="appPopupMenu" class="app-popup-menu" v-if="showAppPopupMenu">
    <div class="menu-item-wrapper">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPopupMenu',
  props: {
    showAppPopupMenu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  },
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target))) {
        this.$emit('closeAppPopupMenu', false)
      } else {
        // to be done
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  watch: {
    showappPopupMenu: {
      handler () {
        if (this.showAppPopupMenu) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
          }, 250)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
          }, 250)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped >
.app-popup-menu:not(:empty) {
  background: #FEFEFE;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  position: absolute;
  z-index: 30;
  right: 0;
  display: flex;
  top: 16px;
  flex-direction: column;
  min-width: 150px;
  left: unset;
  max-width: max-content;
  .menu-item-wrapper:not(:empty) {
    display: flex;
    min-height: 40px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    span.mdc-text {
      padding: 7px 10px;
      color: #404040;
      font-weight: 600;
      white-space: nowrap;
      &:hover {
        background: #EBF3FC;
        &:first-of-type {
          border-radius: 4px 4px 0 0;
        }
        &:last-of-type {
          border-radius: 0 0 4px 4px;
        }
      }
    }
  }
}
</style>
