import Vue from 'vue'
import Vuex from 'vuex'
import fb from './firebase'
import teams from './teams'
import { uploadCVFile, uploadAvatarFile } from './upload'
import { initializeProfile } from '../utils/profile'
import { appSettings } from '../settings'
import omit from 'lodash/omit'
import moment from 'moment-timezone'

const _ = { omit }

Vue.use(Vuex)

let versionListener
let profilesListener
let connectionsListener
let channelsListener
let workspaceListener
let inviteListener
let requestListener

const workspaceRef = fb.firestore.collection('workspaces')

const getProfileRef = (workspaceId) => {
  // console.log('store.index.getProfileRef', workspaceId, store.state.workspace)
  if (store.state.workspace.id) {
    workspaceId = store.state.workspace.id
  }
  return fb.firestore.collection('workspaces').doc(workspaceId).collection('profiles')
}

const withinPreferenceHours = (notificationsSettings) => {
  const now = new Date()
  if (notificationsSettings.notifyWhen === 'anytime') {
    return true
  } else {
    const weekDayName = moment(now).format('ddd').toUpperCase()
    if (notificationsSettings.notifyWhenFields.notifyWhenDays[weekDayName]) {
      const currentTime = moment(now).format('HH:mm')
      const afterFrom = currentTime > notificationsSettings.notifyWhenFields.notifyWhenTime.from
      const beforeTo = currentTime < notificationsSettings.notifyWhenFields.notifyWhenTime.to
      if (afterFrom && beforeTo) {
        return true
      }
    }
  }
  return false
}

const store = new Vuex.Store({
  state: {
    version: false,
    isBranded: false,
    workspace: {},
    pageLoad: false,
    loading: false,
    afterLogin: '/',
    user: { displayName: 'Not Authenticated' },
    role: { level: 0 },
    industries: null,
    locations: null,
    roles: null,
    profile: null,
    profileUpdates: {},
    profiles: null,
    workspaceProfiles: null,
    connections: null,
    connection: null,
    channels: null,
    messages: null,
    snack: {},
    filters: {},
    action: null,
    skills: null,
    companies: null,
    remote: true,
    teamMode: true,
    composerView: '',
    displayProfile: false,
    displayTask: false,
    displayMeeting: false,
    recommendationClicked: false,
    displayUpdateComments: false,
    setupCalendarLater: false,
    notificationMode: 'on',
    notificationModeSet: false,
    scheduleSearchWeekDetail: null,
    plannerWeekDetail: null,
    appDate: moment(),
    timezone: '',
    memberRequestDisplay: false,
    teamInvitesDisplay: false,
    locationIndicatorDialogDate: false,
    myInvites: [],
    myRequests: [],
    userTeams: [],
    userTeamsAdmin: [],
    onboardingStage: 1,
    onboardingStageStep: 1
  },
  getters: {
    getProfile: (state) => (profileId) => {
      if (state.profile) {
        if (state.profile.id === profileId) {
          return state.profile
        }
      }
      if (state.profiles) {
        const profileMap = new Map(state.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }

      return null
    },
    getCalendarColor: (state) => (calendarId, meetingSource, subject) => {
      // console.log('calendar color', calendarId, meetingSource, state)
      if (state.profile) {
        // try calendarColors first
        if (state.profile.calendarColors) {
          const hexColor = state.profile.calendarColors[calendarId]
          if (hexColor) {
            return hexColor
          }
        }

        // fallback to selected calendars
        const calendar = meetingSource === 'Google' ? state.profile.googleCalendarsSelected : state.profile.calendarsSelected
        if (calendar) {
          const calendarDetails = calendar.find(cal => cal.calendarId === calendarId)
          if (calendarDetails) {
            if (calendarDetails.hexColor) {
              return calendarDetails.hexColor
            }
          }
          return '#062FB1' // default color
        }
      }
    }
  },
  actions: {
    loadVersion ({ commit }) {
      if (versionListener) { versionListener() }
      versionListener = fb.firestore.collection('versions').doc('app')
        .onSnapshot(querySnapshot => {
          const version = { ...querySnapshot.data(), id: querySnapshot.id }
          commit('setVersion', version)
        }, (error) => {
          console.error('store.index.loadVersion listenerError', error)
        })
    },
    setOnboardingStage ({ commit }, stage) {
      commit('setOnboardingStage', stage)
    },
    setOnboardingStageStep ({ commit }, step) {
      commit('setOnboardingStageStep', step)
    },
    setScheduleSearchWeekDetail ({ commit }, date) {
      commit('setScheduleSearchWeekDetail', date)
    },
    setPlannerWeekDetail ({ commit }, weekDetails) {
      commit('setPlannerWeekDetail', weekDetails)
    },
    saveScheduleSetByWorkspace ({ rootState }, { schedulesSetByWorkspace }) {
      if (rootState.workspace.id) {
        const workspaceId = rootState.workspace.id
        return workspaceRef.doc(workspaceId).update({ schedulesSetByWorkspace })
      }
    },
    saveGuestAccessWorkspace ({ rootState }, { guestAccessWorkspace }) {
      if (rootState.workspace.id) {
        const workspaceId = rootState.workspace.id
        return workspaceRef.doc(workspaceId).update({ guestAccessWorkspace })
      }
    },
    updateWorkspace ({ rootState, commit }, { workspaceId, updates }) {
      // console.log(workspaceId, updates)
      return workspaceRef.doc(workspaceId).update(updates)
    },
    updateAppDate ({ commit, dispatch, state }) {
      const storeDate = moment(state.appDate)
      const timezone = moment.tz.guess(true)
      const timezoneOffset = moment().format('Z')
      const timezoneStr = `${timezone} (UTC ${timezoneOffset})`
      const currentDate = moment(new Date())
      if (state.timezone !== timezoneStr) {
        commit('setTimezone', timezoneStr)
        if (state.profile) {
          if (state.profile.timezone !== timezoneStr) {
            dispatch('updateProfile', { profile: { timezone }, noSnack: true })
          }
        }
      }
      if (!storeDate.isSame(currentDate, 'date')) {
        dispatch('teams/loadCurrentSchedules')
        dispatch('updateProfileLastActive')
      }
      commit('setAppDate', moment())
    },
    setNotificationModeFromSettings ({ commit, state }) {
      if (!state.notificationModeSet || state.notificationMode !== 'on') {
        if (state.profile) {
          if (state.profile.notificationsSettings) {
            let mode = 'on'
            const withinNotifiyTimes = withinPreferenceHours(state.profile.notificationsSettings)
            if (!withinNotifiyTimes) {
              mode = 'off'
            }
            if (mode !== state.notificationMode) {
              commit('setNotificationMode', mode)
            }
          }
        }
      }
    },
    setNotificationMode ({ commit }, mode) {
      commit('setNotificationMode', mode)
      commit('setNotificationModeSet', true)
    },
    setSetupCalendarLater ({ commit }, later) {
      commit('setSetupCalendarLater', later)
    },
    setLocationIndicatorDialogDate ({ commit }, mode) {
      commit('setLocationIndicatorDialogDate', mode)
    },
    setDisplayProfile ({ commit }, profile) {
      commit('setDisplayProfile', profile)
    },
    setDisplayTask ({ commit }, task) {
      commit('setDisplayTask', task)
    },
    setDisplayMeeting ({ commit }, display) {
      commit('setDisplayMeeting', display)
    },
    setRecommendationClicked ({ commit }, recommendation) {
      commit('setRecommendationClicked', recommendation)
    },
    setDisplayUpdateComments ({ commit }, updateComments) {
      commit('setDisplayUpdateComments', updateComments)
    },
    setMemberRequestDisplay ({ commit }, display) {
      commit('setMemberRequestDisplay', display)
    },
    setTeamInvitesDisplay ({ commit }, display) {
      commit('setTeamInvitesDisplay', display)
    },
    setUserTeams ({ commit }, teams) {
      commit('setUserTeams', teams)
    },
    async setUserTeamsAdmin ({ state, commit }, { user, teams }) {
      if (state.workspace.id && teams) {
        const workspaceId = state.workspace.id
        const chunkSize = 10
        const adminTeams = []
        for (let i = 0; i < teams.length; i += chunkSize) {
          const teamChunk = teams.slice(i, i + chunkSize)
          const teamRoles = await workspaceRef.doc(workspaceId)
            .collection('teamRoles')
            .where('userId', '==', user.uid)
            .where('teamId', 'in', teamChunk).get()
          for (const roleDoc of teamRoles.docs) {
            const role = { ...roleDoc.data(), id: roleDoc.id }
            if (role.role === 'team-admin') {
              adminTeams.push(role.teamId)
            }
          }
        }
        if (adminTeams.length > 0) {
          commit('setUserTeamsAdmin', [...new Set(adminTeams)])
        }
      }
    },
    setComposerView ({ commit }, view) {
      commit('setComposerView', view)
    },
    setSnackMessage ({ commit }, message) {
      commit('setSnackMessage', message)
    },
    setPageLoad ({ commit }, loading) {
      return commit('setPageLoad', loading)
    },
    setIsBranded ({ commit }, isBranded) {
      commit('setIsBranded', isBranded)
    },
    setWorkspace ({ commit }, workspace) {
      commit('setWorkspace', workspace)
    },
    setTeam ({ commit }, team) {
      commit('setTeam', team)
    },
    setLoading ({ commit }, loading) {
      commit('setLoading', loading)
    },
    setAction ({ commit }, action) {
      commit('setAction', action)
    },
    setFilters ({ commit }, filters) {
      commit('setFilters', filters)
    },
    setProfile ({ commit }, profile) {
      commit('setProfile', profile)
    },
    setProfileUpdates ({ commit }, profileUpdates) {
      commit('setProfileUpdates', profileUpdates)
    },
    clearProfileUpdates ({ commit }) {
      commit('setProfileUpdates', {})
    },
    async loadListeners ({ commit }) {
      // console.log('Store.loadListeners', store.state.user, store.state.team)
      await loadUser(store.state.user, store)
      return loadListeners(store.state.user, store)
    },
    async loadTeamListeners ({ commit }) {
      // console.log('store.loadTeamListeners', store.state.user, store.state.team)
      return loadTeamListeners(store.state.user, store)
    },
    loadProfile ({ commit, dispatch }, { workspaceId, refreshTeam }) {
      // console.log('loadProfile', workspaceId, this.state.user.uid)
      return getProfileRef(workspaceId)
        .doc(this.state.user.uid)
        .get()
        .then(async doc => {
          if (doc.exists) {
            const profile = doc.data()
            const updated = initializeProfile(profile, this.state.user)
            if (updated) {
              doc.ref.set(profile, { merge: true })
            }
            profile.id = doc.id
            if (this.state.workspace.id) {
              profile.workspaceId = this.state.workspace.id
            }
            if (this.state.team) {
              profile.team = this.state.team.id
            }
            commit('setProfile', profile)
            dispatch('updateAppDate')
            dispatch('setNotificationModeFromSettings')

            // console.log('Store.state.workspace', this.state.workspace)
            // console.log('Store.profile.teamId', profile.team)
            if (profile.team) {
              if (!this.state.teams.team || refreshTeam) {
                await dispatch('teams/loadTeam', { teamId: profile.team })
              } else if (this.state.teams.team.id !== profile.team) {
                await dispatch('teams/loadTeam', { teamId: profile.team })
              }
            }
          }
        }).catch(error => {
          console.error('store.index.loadProfile error', error)
          throw error
        })
    },
    loadInvites ({ commit, rootState }) {
      if (inviteListener) { inviteListener() }
      const userEmail = rootState.user.email.toLowerCase()
      if (userEmail) {
        inviteListener = fb.firestore.collection('invites')
          .where('email', '==', userEmail)
          .where('done', '==', false)
          .onSnapshot(querySnapshot => {
            const results = []
            querySnapshot.forEach((doc) => {
              results.push({ id: doc.id, ...doc.data() })
            })
            commit('setMyInvites', results)
          }, (error) => {
            console.error('store.index.loadInvites listenerError', userEmail, error)
          })
      }
    },
    loadRequests ({ commit, rootState }) {
      if (requestListener) { requestListener() }
      const userEmail = rootState.user.email.toLowerCase()
      if (userEmail) {
        requestListener = fb.firestore.collection('requests')
          .where('email', '==', userEmail)
          .where('done', '==', false)
          .onSnapshot(querySnapshot => {
            const results = []
            querySnapshot.forEach((doc) => {
              results.push({ id: doc.id, ...doc.data() })
            })
            commit('setMyRequests', results)
          }, (error) => {
            console.error('store.index.loadRequests listenerError', userEmail, error)
          })
      }
    },
    completeRequest ({ commit }, { requestId }) {
      const requestRef = fb.firestore.collection('requests')
      requestRef.doc(requestId).update({ done: true }).catch((error) => {
        console.error('store.index.completeRequest error', error)
        throw error
      })
    },
    loadProfiles ({ commit }, { workspaceId }) {
      if (profilesListener) { profilesListener() }
      profilesListener = getProfileRef(workspaceId)
        .onSnapshot(querySnapshot => {
          const results = []
          querySnapshot.forEach((doc) => {
            if (doc.id !== this.state.user.uid) {
              results.push({ id: doc.id, ...doc.data() })
            }
          })
          commit('setProfiles', results)
        }, (error) => {
          console.error('store.index.loadProfiles listenerError', error)
        })
    },
    async loadWorkspaceDetails ({ commit }, { workspaceId }) {
      if (workspaceListener) { workspaceListener() }
      workspaceListener = workspaceRef.doc(workspaceId)
        .onSnapshot(querySnapshot => {
          const workspace = { ...querySnapshot.data(), id: querySnapshot.id }
          commit('setWorkspace', workspace)
          return true
        }, (error) => {
          console.error('store.index.loadWorkspaceDetails listenerError', error)
        })
    },
    updateProfileLastActive ({ commit, dispatch }) {
      return getProfileRef()
        .doc(this.state.user.uid)
        .update({ lastActive: new Date(), inactivityEmailSent: null })
    },
    updateProfile ({ commit, state, dispatch }, { profile, noSnack, snackMessage }) {
      // Set Calendar colors when profile updated with a new calendar connection
      if (profile.calendarsSelected || profile.googleCalendarsSelected) {
        console.log('UpdateProfile calendars: ', JSON.stringify(profile.calendarsSelected), JSON.stringify(profile.googleCalendarsSelected))
        let calendarColors = {}
        if (state.profile) {
          if (state.profile.calendarColors) {
            calendarColors = state.profile.calendarColors
          }
        }
        if (profile.calendarsSelected) {
          for (const cal of profile.calendarsSelected) {
            calendarColors[cal.calendarId] = cal.hexColor
          }
        }
        if (profile.googleCalendarsSelected) {
          for (const cal of profile.googleCalendarsSelected) {
            calendarColors[cal.calendarId] = cal.hexColor
          }
        }
        profile.calendarColors = calendarColors
        console.log('UpdateProfile calendarColors: ', calendarColors)
      }

      getProfileRef()
        .doc(this.state.user.uid)
        .update(profile)
        .then(() => {
          dispatch('loadProfile', { workspaceId: this.state.workspace.id })
          if (!noSnack) {
            if (snackMessage && snackMessage !== '') {
              commit('setSnackMessage', snackMessage)
              return
            }
            commit('setSnackMessage', 'Profile Updated')
          }
        })
    },
    applyProfileUpdates ({ commit, dispatch }, { noSnack }) {
      // Remove temp fields in profile updates
      const omitFields = ['newSkill', 'newSkillRating', 'newRoleName', 'newRoleExp']
      const updates = _.omit(this.state.profileUpdates, omitFields)
      // Update profile with profile updates
      getProfileRef()
        .doc(this.state.user.uid)
        .update(updates)
        .then(() => {
          dispatch('clearProfileUpdates')
          dispatch('loadProfile', { workspaceId: this.state.workspace.id })
          if (!noSnack) {
            commit('setSnackMessage', 'Profile Updated')
          }
        })
    },
    uploadCV ({ commit, dispatch }, { file }) {
      const addCV = (filename, downloadUrl) => {
        dispatch('updateProfile', { profile: { resume: downloadUrl, resumeFilename: filename } })
      }
      const userId = this.state.user.uid
      uploadCVFile(userId, file, addCV).then(snapshot => {
        commit('setSnackMessage', 'CV Uploaded')
      }).catch(err => {
        commit('setSnackMessage', 'Error Uploading CV')
        console.error('CV upload Error', err)
      })
    },
    uploadAvatar ({ commit, dispatch }, { file, filename }) {
      const addAvatar = (downloadUrl) => {
        dispatch('updateProfile', { profile: { photoURL: downloadUrl, photoFilename: filename } })
      }
      const userId = this.state.user.uid
      uploadAvatarFile(userId, file, filename, addAvatar).then(snapshot => {
        commit('setSnackMessage', 'Avatar Uploaded')
      }).catch(err => {
        commit('setSnackMessage', 'Error Uploading Avatar')
        console.error('Avatar upload Error', err)
      })
    },
    async updateUser () {
      await fb.auth.currentUser.getIdToken(true)
      await loadUser(fb.auth.currentUser, store)
    },
    clearMessages ({ commit }) {
      commit('setMessages', [])
    }
  },
  mutations: {
    setVersion: (state, version) => {
      state.version = version
    },
    setAppDate: (state, date) => {
      state.appDate = date
    },
    setTimezone: (state, timezone) => {
      state.timezone = timezone
    },
    setComposerView: (state, view) => {
      state.composerView = view
    },
    setIsBranded: (state, isBranded) => {
      state.isBranded = isBranded
    },
    setWorkspace: (state, workspace) => {
      state.workspace = workspace
    },
    setTeam: (state, team) => {
      state.team = team
    },
    setUserTeams: (state, teams) => {
      state.userTeams = teams
    },
    setUserTeamsAdmin: (state, teams) => {
      state.userTeamsAdmin = teams
    },
    setLoading: (state, loading) => {
      state.loading = loading
    },
    setPageLoad: (state, loading) => {
      state.pageLoad = loading
    },
    setAction: (state, action) => {
      state.action = action
    },
    setFilters: (state, filters) => {
      state.filters = filters
    },
    setUser: (state, user) => {
      state.user = user
    },
    setRoles: (state, roles) => {
      state.roles = roles
    },
    setIndustries: (state, industries) => {
      state.industries = industries
    },
    setLocations: (state, locations) => {
      state.locations = locations
    },
    setSkills: (state, skills) => {
      state.skills = skills
    },
    setCompanies: (state, companies) => {
      state.companies = companies
    },
    setRole: (state, role) => {
      state.role = role
    },
    setSnack: (state, snack) => {
      state.snack = snack
    },
    setSnackMessage: (state, message) => {
      const snack = {
        message,
        actionText: 'OK',
        actionHandler () {}
      }
      state.snack = snack
    },
    setProfile: (state, profile) => {
      state.profile = profile
    },
    setProfileUpdates: (state, profileUpdates) => {
      // // console.log('Store.index.mutation.setProfileUpdates', profileUpdates)
      state.profileUpdates = profileUpdates
    },
    setProfiles: (state, profiles) => {
      state.profiles = profiles
    },
    setWorkspaceProfiles: (state, profiles) => {
      state.workspaceProfiles = profiles
    },
    setConnections: (state, connections) => {
      state.connections = connections
    },
    setConnection: (state, connection) => {
      state.connection = connection
    },
    setChannels: (state, channels) => {
      state.channels = channels
    },
    setMessages: (state, messages) => {
      state.messages = messages
    },
    setDisplayProfile: (state, profile) => {
      state.displayProfile = profile
    },
    setDisplayTask: (state, task) => {
      state.displayTask = task
    },
    setDisplayMeeting: (state, meeting) => {
      state.displayMeeting = meeting
    },
    setRecommendationClicked: (state, recommendation) => {
      state.recommendationClicked = recommendation
    },
    setDisplayUpdateComments: (state, updateComments) => {
      state.displayUpdateComments = updateComments
    },
    setMemberRequestDisplay: (state, display) => {
      state.memberRequestDisplay = display
    },
    setTeamInvitesDisplay: (state, display) => {
      console.log('commit-setTeamInvitesDisplay', display)
      state.teamInvitesDisplay = display
    },
    setLocationIndicatorDialogDate: (state, date) => {
      state.locationIndicatorDialogDate = date
    },
    setSetupCalendarLater: (state, later) => {
      state.setupCalendarLater = later
    },
    setNotificationMode: (state, mode) => {
      state.notificationMode = mode
    },
    setNotificationModeSet: (state, value) => {
      state.notificationModeSet = value
    },
    setScheduleSearchWeekDetail: (state, detail) => {
      state.scheduleSearchWeekDetail = detail
    },
    setPlannerWeekDetail: (state, detail) => {
      state.plannerWeekDetail = detail
    },
    setMyInvites: (state, results) => {
      state.myInvites = results
    },
    setMyRequests: (state, results) => {
      state.myRequests = results
    },
    setOnboardingStage: (state, stage) => {
      state.onboardingStage = stage
    },
    setOnboardingStageStep: (state, step) => {
      state.onboardingStageStep = step
    }
  },
  modules: {
    teams
  }
})

const disableListeners = async () => {
  if (profilesListener) { await profilesListener() }
  if (connectionsListener) { await connectionsListener() }
  if (channelsListener) { await channelsListener() }
  if (workspaceListener) { await workspaceListener() }
  if (requestListener) { await requestListener() }
  if (inviteListener) { await inviteListener() }
  await teams.resetTeamNotesListener()
  await teams.resetMeetingListener()
  await teams.resetDailPageListener()
  await teams.resetTaskPlanListener()
  await teams.resetPreferenceListener()
  await teams.resetRequestListener()
  await teams.resetNotificationListener()
  await teams.resetRecommendationListener()
  await teams.resetScheduleListener()
  await teams.resetCurrentScheduleListener()
  await teams.resetMiniScheduleListener()
}

const clearStore = () => {
  store.commit('setUser', null)
  store.commit('setProfile', null)
  store.commit('setRole', { level: 0 })
  store.commit('setProfiles', null)
  store.commit('setWorkspaceProfiles', null)
  store.commit('setConnections', null)
  store.commit('setConnection', null)
  store.commit('setMessages', null)
  store.commit('setChannels', null)
  store.commit('setRoles', null)
  store.commit('setIndustries', null)
  store.commit('setLocations', null)
  store.commit('setSkills', null)
  store.commit('setCompanies', null)
  store.commit('setProfileUpdates', {})
  store.commit('setFilters', {})
  store.commit('setMyInvites', [])
  store.commit('setMyRequests', [])
}

const loadListeners = async (user, store) => {
  store.dispatch('loadVersion')
  store.dispatch('loadInvites')
  store.dispatch('loadRequests')
  if (user.claims.workspace) {
    await store.dispatch('loadWorkspaceDetails', { workspaceId: user.claims.workspace })
    store.dispatch('loadProfiles', { workspaceId: user.claims.workspace })
    await store.dispatch('loadProfile', { workspaceId: user.claims.workspace })
    store.dispatch('updateProfileLastActive')
    store.dispatch('clearProfileUpdates')
  }
}

const loadTeamListeners = async (user, store) => {
  await store.dispatch('loadProfile', { workspaceId: user.claims.workspace, refreshTeam: true })
}

const loadUser = async (user, store) => {
  let claimChange = false
  if (user) {
    const idTokenResult = await user.getIdTokenResult()
    const userClaims = idTokenResult.claims
    // console.log('store.loadUser', user.displayName, userClaims)
    if (store.state.user) {
      // only update store user claims if different
      if (store.state.user.claims) {
        let userTeamsDifferent = false
        if (userClaims.teams && store.state.userTeams) {
          userTeamsDifferent = store.state.userTeams.length !== userClaims.teams.length
        }
        if (store.state.user.claims.workspace !== userClaims.workspace || store.state.user.claims.team !== userClaims.team || userTeamsDifferent) {
          user.claims = userClaims
          setUserClaims(user, store)
          claimChange = true
        }
      } else {
        user.claims = userClaims
        setUserClaims(user, store)
        claimChange = true
      }
    } else {
      user.claims = userClaims
      setUserClaims(user, store)
      claimChange = true
    }
    await store.commit('setUser', user)
  }
  return claimChange
}

const setUserClaims = async (user, store) => {
  // console.log('setUserClaims', user.claims)
  if (user.claims.workspace) {
    store.dispatch('setWorkspace',
      {
        id: user.claims.workspace
      }
    )
  }
  if (user.claims.team) {
    store.dispatch('setTeam',
      {
        id: user.claims.team
      }
    )
  }
  if (user.claims.teams) {
    store.dispatch('setUserTeams', user.claims.teams)
    store.dispatch('setUserTeamsAdmin', { user, teams: user.claims.teams })
  }
}

fb.auth.onIdTokenChanged(async user => {
  // console.log('store.onIdTokenChanged', user, store.state.user, store.state.team)
  if (store.state.user) {
    if (store.state.user.uid) {
      setUser(user)
    }
  }
})

fb.auth.onAuthStateChanged(async user => {
  // console.log('store.onAuthStateChanged', user)
  if (user) {
    if (store.state.user) {
      if (store.state.user.uid) {
        if (store.state.user.uid !== user.uid) {
          // Change in users without signout - clear store
          await disableListeners()
          await clearStore()
        }
      }
    }
  }
  setUser(user)
})

const setUser = async (user) => {
  if (user) {
    const claimChange = await loadUser(user, store)
    if (claimChange) {
      await loadListeners(user, store)
      if (store.state.workspace && store.state.workspace.isTracking) {
        try {
          // eslint-disable-next-line no-undef
          await analytics.load(appSettings.segment)
          // eslint-disable-next-line no-undef
          await analytics.identify({
            customTrackingPreferences: {
              functional: true,
              marketingAndAnalytics: true
            },
            destinationTrackingPreferences: {
              FullStory: true,
              'Google Analytics': true,
              'Google Tag Manager': true
            },
            ws: store.state.workspace.domainUrl
          })
        } catch (err) {
          console.error('store.index.setUser.analyticsError', err)
        }
      }
    }
  } else {
    clearStore()
    disableListeners()
  }
}

export default store
