<template>
  <mdc-text role="document" aria-label="location-name" :title="locationName.replace(/<[^>]+>/g, '')" class="loc-name-det" v-if="locationDetails" tag="span" typo="caption" v-html="locationName"></mdc-text>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LocationName',
  components: {},
  props: {
    locationDetails: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {}
  },
  beforeDestroy () {},
  created () {},
  computed: {
    ...mapState([
    ]),
    ...mapState({
      locations: state => state.teams.locations
    }),
    locationName () {
      if (this.locationDetails && this.locationDetails.type) {
        let building
        let floor
        switch (this.locationDetails.type) {
          case 'building':
            return '<span class="building-name only-building">' + this.locationDetails.name + '</span>'
          case 'floor':
            building = this.locations.filter(loc => loc.id === this.locationDetails.buildingId)
            if (building.length > 0) {
              building = building[0]
              return '<span class="building-name">' + building.name + '</span>' + ' ' + '<i class="floor-name">' + this.locationDetails.name + '</i>'
            }
            break
          case 'area':
            building = this.locations.filter(loc => loc.id === this.locationDetails.buildingId)
            floor = this.locations.filter(loc => loc.id === this.locationDetails.floorId)
            if (building.length > 0 && floor.length > 0) {
              building = building[0]
              floor = floor[0]
              return '<span class="building-name">' + building.name + '</span>' + ' ' + '<i>' + floor.name + ', ' + this.locationDetails.name + '</i>'
            }
            break
          default:
            return ''
        }
      }
      return ''
    }
  },
  mounted () {},
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    })
  },
  watch: {}
}
</script>

<style lang="scss">
</style>
