<template>
  <div id="app" class="mdc-typography" :class="browserClass">
    <div class="mdc-layout-app" :class="headroomClass">

      <portal-target
        name="destination"
        class="mobile-input-overlay"
        v-show="isMobile && inputFocused"
        @change="focusInput"
        ref="portal"
        >
      </portal-target>

      <search @closeSearch="searchActive = false" v-if="searchActive" />
      <user-settings v-if="settingsOpen" :showUserSettings="settingsOpen" @triggerClose="closeUserSettings" @signOut="signOutUser" />
      <plus-menus v-if="plusMenusOpen" :showPlusMenus="plusMenusOpen" @triggerClose="closePlusMenus" />
      <headroom v-if="!$route.meta.noheader && !topNavHidden && !initialPageLoad"
          class="app-header"
          @top="headroomClass = 'app-headroom--pinned'"
          @pin="headroomClass = 'app-headroom--pinned'"
          @unpin="headroomClass = 'app-headroom--unpinned'"
          :disabled="desktop"
          :class="{'headroom--pinned' : desktop }"
          :style="'height:auto'">
        <app-top-bar :navActive="navActive"
          @plusMenu="setPlusMenusMode"
          @settingsMode="setSettingsMode"
          @openProfileTasks="profileNotification = true"
          :profileReady="profileReady"
          :dynamicStyle="workspaceStyle"
          v-if="currentSchedules"
         />
        <div v-else class="app-header-spacer" style='position: relative; background: rgb(12, 56, 96);height:57px;'></div>
      </headroom>

      <template v-if="notificationMode === 'on' && loaded && routeName !== 'Join' && routeName !== 'SigninOnboarding' && routeName !== 'SignupOnboarding' && routeName !== 'SignupOnboardingInvite'">
        <section class="notification-panel">
          <notification-panel />
        </section>
      </template>

      <app-side-drawer v-if="!$route.meta.nodrawer && profileReady && loaded"
                      :style="{'visibility': profileReady ? 'visible' : 'hidden'}"
                      :drawerStyle="workspaceStyle.sidebar"
                      :links="links"
                      :profile="profile"
                      :desktop="desktop"
                      :profileReady="profileReady"
                      :currentRoute="$route"
                      />

      <app-side-drawer v-if="$route.meta.loggedOutDrawer"
                      :style="{'visibility': 'visible'}"
                      :drawerStyle="workspaceStyle.sidebar"
                      :links="links"
                      :profile="{}"
                      :desktop="desktop"
                      :profileReady="true"/>

      <transition name="fade">
        <div v-if="initialPageLoad" style="position:absolute; width:100%;height:100%; display:flex; justify-content: center; align-items: center; flex-flow:column; background-color:rgba(255,255,255,0.8)">
          <div style="position:relative;width: 70px;">
            <div style="position:absolute;left:0;margin-top:16px;" class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </div>
      </transition>

      <div class="mdc-drawer-app-content" v-bind:class="{'no-side-drawer' : $route.meta.nodrawer, 'notification-mode': notificationMode === 'on' }">

        <profile-tasks @checkProfile="initializePage = true" @modalClosed="profileNotification = false" :profile="profile" :tasksModalOpen="profileNotification"></profile-tasks>

        <transition name="fade">
          <div v-if="pageLoad" class="spinner-wrapper">
            <div class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </transition>

        <main class="main-content" id="main-content"
          style="min-width:950px;"
          v-bind:class="[
            desktop ? 'desktop' : '',
            isMobile? 'mobile': '',
            isTablet? 'tablet': '',
            isTabletLarge? 'tabletLarge': '',
            isDesktopLarge? 'desktopLarge': '']">
          <mdc-layout-grid v-show="searchEnabled" class="no-height-grid">
            <mdc-layout-cell span=12>
              <app-search-detailed
                ref="searchbar"
                v-model="searchValue"
                :searchFilter="searchFilter"
                :filterJobs="filterJobs"
                :filterPartners="filterPartners"
                v-bind="searchOptions"
                v-on="searchEvents"
                @action="onAction"
                @hideBottomNav="hideBottomNav"
                @clear="onSearchClear"
                @clearFilter="onFilterClear"
                @search="onSearch" />
            </mdc-layout-cell>
          </mdc-layout-grid>

          <transition
            :duration="100"
            @before-enter="pageLoading"
            @after-enter="finishPageLoad">
            <keep-alive :include="['Discover', 'Dashboard']">
              <router-view
                ref="view"
                v-show="!inSearch"
                :inSearch="inSearch"
                :search="searchValue"
                :filterJobs="filterJobs"
                :filterPartners="filterPartners"
                @loadMore="loadMore"
                :initializePage="initializePage"
                @initialized="initializePage = false"
                @inputFocused="inputFocused = true"
                @inputBlurred="inputFocused = false"
                :desktop="desktop"
                :isMobile="isMobile"
                :isTablet="isTablet"
                :isTabletLarge="isTabletLarge"
                :isDesktopLarge="isDesktopLarge"
                :dynamicStyle="workspaceStyle"
                @setNavActive="setNavActive"
                @hideTopNav="hideTopNav"
                @hideBottomNav="hideBottomNav"
                @openProfileTasks="profileNotification = true"
                @setLinks="setLinks">
              </router-view>
            </keep-alive>
          </transition>
        </main>
        <div v-if="currentVersion" class="version-info">V{{currentVersion}}</div>

        <footer v-if="!$route.meta.noheader && !bottomNavHidden && !$route.meta.nofooter" class="mobileonly">
          <div v-if="user && profileReady" class="bottom-navbar" :class="{'workspace-navbar': this.isBranded}" :style="workspaceStyle.bottombar">
            <app-nav-bottom :dynamicStyle="workspaceStyle"></app-nav-bottom>
          </div>
        </footer>
      </div>
      <snackbar :key="'messageSnack'" :message.sync="snack.message"/>
      <snackbar v-if="reloadMessage" :key="'reloadSnack'" :timeout="-1" :actionHandler="reloadApp" actionText="Reload" :message="reloadMessage"/>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import moment from 'moment'
import { headroom } from 'vue-headroom'
import { MDCDrawer } from '@material/drawer'
import { MDCTopAppBar } from '@material/top-app-bar'
import AppTopBar from './components/AppTopBar'
import AppNavBottom from './components/AppNavBottom.vue'
import AppSideDrawer from './components/AppSideDrawer.vue'
import Snackbar from './components/Snackbar.vue'
import ProfileTasks from './components/ProfileTasks.vue'
import AppSearchDetailed from './components/AppSearchDetailed.vue'
import Search from './components/flexibly/Search.vue'
import NotificationPanel from './components/flexibly/NotificationPanel.vue'
import UserSettings from './components/flexibly/UserSettings.vue'
import PlusMenus from './components/flexibly/PlusMenus.vue'
import './app.css'

export default {
  name: 'app',
  data () {
    return {
      settingsOpen: false,
      plusMenusOpen: false,
      navActive: null,
      unreadopen: false,
      profileNotification: false,
      bottomNavHidden: false,
      topNavHidden: false,
      drawer: undefined,
      topAppBar: undefined,
      desktop: false,
      open: false,
      links: [],
      windowWidth: window.innerWidth,
      browserClass: '',
      headroomClass: 'app-headroom--pinned',
      initializePage: false,
      inputFocused: false,
      isMobile: false,
      isTablet: false,
      isTabletLarge: false,
      isDesktopLarge: false,
      inSearch: false,
      searchActive: false,
      searchEnabled: false,
      searchValue: '',
      searchFilter: '',
      searchOptions: {},
      searchEvents: {},
      filterJobs: false,
      filterPartners: false,
      mobileHistoryLength: 0,
      initialPageLoad: true,
      loaded: false,
      view: '',
      routeName: '',
      currentVersion: false,
      reloadMessage: false
    }
  },
  components: {
    AppTopBar,
    AppNavBottom,
    AppSideDrawer,
    headroom,
    Snackbar,
    ProfileTasks,
    AppSearchDetailed,
    Search,
    NotificationPanel,
    UserSettings,
    PlusMenus
  },
  created () {
    this.windowWidth = window.innerWidth
    this.browserClass = this.browserClasses()
    setTimeout(() => {
      this.routeName = this.$route.name
      this.loaded = true
    }, 3000)
    setInterval(() => {
      this.setNotificationModeFromSettings()
      this.updateAppDate()
    }, 60000)
  },
  mounted () {
    this.topAppBar = MDCTopAppBar.attachTo(this.$el.querySelector('.mdc-top-app-bar'))
    this.topAppBar.setScrollTarget(document.getElementById('main-content'))

    this.$root.$on('toggleSidebar', () => {
      if (!this.desktop) {
        if (this.drawer) {
          this.drawer.open = !this.drawer.open
        }
      }
    })

    this.$root.$on('searchClick', () => {
      this.searchActive = !this.searchActive
    })

    this.$root.$on('setSearchQuery', (val) => {
      this.$set(this, 'searchValue', val)
    })

    this.$root.$on('setSearchFilter', (filter) => {
      this.searchFilter = filter
      if (filter) {
        // Set filter, clear search value
        this.$set(this, 'searchValue', '')
        // eslint-disable-next-line handle-callback-err
        this.$router.push('/dashboard').catch(() => {
          console.debug('App.onSearchFilterClear')
        })
      }
    })

    this.$root.$on('enableSearch', (val, options, events) => {
      this.searchEnabled = val
      if (options) {
        this.searchOptions = options
      } else {
        this.searchOptions = {}
      }
      if (events) {
        this.searchEvents = events
      } else {
        this.searchEvents = {}
      }
    })

    this.$root.$on('onSearch', (query, res) => {
      this.onSearch(query, res)
    })

    document.addEventListener('click', this.handleAppClick)

    window.addEventListener('popstate', (event) => {
      if (this.inputFocused) {
        this.inputFocused = false
      }
    })

    window.addEventListener('resize', () => {
      if (!this.throttled) {
        this.throttled = true
        setTimeout(() => {
          this.throttled = false
          this.windowWidth = window.innerWidth
        }, 200)
      }
    })
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleAppClick)
  },
  watch: {
    version: {
      handler (newV, oldV) {
        if (this.version) {
          if (this.currentVersion) {
            if (this.currentVersion !== this.version.version) {
              // Version change, show version message
              this.reloadMessage = 'A new version has been released please reload to update'
            }
          } else {
            this.currentVersion = this.version.version
          }
        }
      },
      immediate: true
    },
    windowWidth: {
      handler: function (newW, oldW) {
        this.$set(this, 'desktop', this.windowWidth >= 840)
        this.$set(this, 'isMobile', this.windowWidth <= 470)
        this.$set(this, 'isTablet', this.windowWidth >= 470 && this.windowWidth <= 670)
        this.$set(this, 'isTabletLarge', this.windowWidth >= 670 && this.windowWidth <= 1180)
        this.$set(this, 'isDesktopLarge', this.windowWidth >= 1480)
        if (!this.desktop) {
          this.$nextTick(() => {
            this.mountDrawer()
          })
        }
      },
      immediate: true
    },
    pageLoad: {
      handler () {
        if (this.initialPageLoad && !this.pageLoad) {
          this.initialPageLoad = false
        }
      }
    },
    $route: {
      handler (nr, oldr) {
        if (nr.name !== oldr.name) {
          this.searchValue = ''
        }
        // reset zoom level
        // const viewportmeta = document.querySelector('meta[name="viewport"]')
        // viewportmeta.setAttribute('content', 'initial-scale=1.0, maximum-scale=1.0')
        if (this.$route) {
          this.routeName = this.$route.name
        }
      }
    },
    action: function (action, previousAction) {
      if ((previousAction === 'aggregated') && (action === 'jobs' || action === 'partners')) {
        // document.documentElement.scrollTop = document.documentElement.scrollHeight
      } else {
        document.documentElement.scrollTop = 0
      }

      if (this.action === 'jobs') {
        this.filterPartners = false
        this.filterJobs = true
      } else if (this.action === 'partners') {
        this.filterPartners = true
        this.filterJobs = false
      } else if (this.action === 'bookmarked') {
        // no filters, clear query
        this.filterPartners = false
        this.filterJobs = false
        this.searchValue = ''
      } else {
        this.filterPartners = false
        this.filterJobs = false
      }
    },
    inputFocused: {
      handler: function (newif, oldif) {
        if (this.inputFocused) {
          const state = window.history.state
          window.history.pushState(state, document.title + ' - input', window.location.href)
          this.mobileHistoryLength = window.history.length
        } else {
          if (this.mobileHistoryLength === window.history.length) {
            window.history.back()
          }
        }
      }
    },
    searchActive () {
      if (this.searchActive) {
        setTimeout(() => {
          const searchEl = document.getElementsByClassName('ais-SearchBox-input')[0]
          // console.log(searchEl)
          if (searchEl) {
            searchEl.focus()
          }
        }, 300)
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'snack',
      'connections',
      'profiles',
      'profile',
      'loading',
      'pageLoad',
      'workspace',
      'isBranded',
      'action',
      'remote',
      'team',
      'teamMode',
      'notificationMode',
      'version'
    ]),
    ...mapState({
      tasks: state => state.teams.tasks,
      team: state => state.teams.team,
      currentSchedules: state => state.teams.currentSchedules
    }),
    workspaceStyle () {
      if (this.isBranded) {
        return {
          linktext: {
            color: 'black'
          },
          sidebar: {
            background: '#FCFCFC',
            'border-right': 'none',
            color: 'black'
          },
          topbar: {
            background: '#FFFFFF',
            'box-shadow': 'inset 0px -1px 1px #E9E9E9'
          },
          bottombar: {
            background: '#FFFFFF'
          },
          bottombarItem: {
            color: 'rgba(0,0,0,.87)'
          }
        }
      }
      if (this.teamMode) {
        return {
          linktext: {
            color: 'black'
          },
          sidebar: {
            background: '#0c3860',
            'border-right': 'none',
            color: 'black',
            'z-index': 1
          },
          topbar: {
            background: '#0c3860'
          },
          bottombar: {
            background: '#0c3860'
          },
          bottombarItem: {
            color: 'rgba(0,0,0,.87)'
          }
        }
      }
      return { linktext: {}, sidebar: {}, topbar: {}, bottombar: {}, bottombarItem: {} }
    },
    profileReady () {
      if (this.profile) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      'clearUnread',
      'setIsBranded',
      'setBrand',
      'setAction',
      'saveUpdate',
      'setNotificationModeFromSettings',
      'updateAppDate',
      'setDisplayProfile'
    ]),
    ...mapActions({
      saveTask: 'teams/saveTask',
      getNextMeetingWithUser: 'teams/getNextMeetingWithUser',
      getLastSameLocation: 'teams/getLastSameLocation',
      getProfileById: 'teams/getProfileById'
    }),
    reloadApp () {
      window.location.reload()
    },
    signOutUser () {
      firebase.auth().signOut().then(() => {
        window.location = '/signin'
      })
    },
    closeUserSettings () {
      this.settingsOpen = false
    },
    closePlusMenus () {
      this.plusMenusOpen = false
    },
    setSettingsMode (value) {
      this.settingsOpen = value
    },
    setPlusMenusMode (value) {
      this.plusMenusOpen = value
    },
    onSearch (query, res) {
      this.searchValue = query
      this.$router.push({ path: '/dashboard', query: { q: query } }).catch((error) => {
        console.error('App.onSearch.router.push:error', error)
      })
    },
    onSearchClear () {
      this.searchValue = ''
      // eslint-disable-next-line handle-callback-err
      this.$router.push('/dashboard').catch((error) => {
        console.error('App.onSearchClear', error)
      })
    },
    onAction (action) {
      if (action === 'clearFilter') {
        this.onFilterClear()
      }
    },
    onFilterClear (filter) {
      this.searchFilter = ''
      this.$refs.view.clearFilter(filter)
    },
    loadMore (event) {
      this.$refs.searchbar.loadMore(event)
    },
    pageLoading () {
      this.initialPageLoad = false
      // console.log('App.pageLoading')
      this.$store.dispatch('setPageLoad', true)
    },
    finishPageLoad () {
      // console.log('App.finishPageLoad 1')
      setTimeout(() => {
        // fallback if FS loading taking too long
        // console.log('App.finishPageLoad 2')
        this.$store.dispatch('setPageLoad', false)
      }, 600)
    },
    clearAllUnread () {
      const uid = this.user.uid
      const self = this
      Object.values(this.connections).forEach(function (con) {
        if (con.unread) {
          if (con.unread[uid] > 0) {
            self.clearUnread({ connectionId: con.id })
          }
        }
      })
    },
    mountDrawer () {
      if (!this.drawer) {
        if (this.$el.querySelector('.mdc-drawer')) {
          this.drawer = MDCDrawer.attachTo(this.$el.querySelector('.mdc-drawer'))
        }
      }
    },
    goHome () {
      if (this.user) {
        this.$router.push('/people')
        return
      }
      this.$router.push('/')
    },
    setNavActive (nav) {
      this.navActive = nav
    },
    setLinks (links) {
      this.links = links
    },
    hideBottomNav (val) {
      this.bottomNavHidden = val
    },
    hideTopNav (val) {
      this.topNavHidden = val
    },
    getDisplayName (profile) {
      if (profile) {
        return profile.displayName ? profile.displayName : ''
      }
      return ''
    },
    filterSchedule () {
      if (this.currentSchedules) {
        const weekdayAbv = moment(this.date).format('ddd').toUpperCase()
        const isAM = moment(this.date).format('a') === 'am'
        const profileSchedule = this.currentSchedules.filter(todayProfile => todayProfile.fromId === this.profileId)
        const profileResponseObj = profileSchedule[0] ? profileSchedule[0].schedules[weekdayAbv] ? profileSchedule[0].schedules[weekdayAbv] : {} : {}
        if (profileResponseObj.split) {
          if (isAM) {
            return profileResponseObj.amResponse || 'none'
          } else {
            return profileResponseObj.pmResponse || 'none'
          }
        } else {
          return profileResponseObj.response || 'none'
        }
      }
      return 'none'
    },
    async displayProfileDetails (profileId) {
      // console.log('displayProfileDetails', this.profile, this.$root)
      const lastInLoc = await this.getLastSameLocation({ users: [this.profile.id, profileId] })
      let lastInLocation = ''
      if (lastInLoc) {
        lastInLocation = lastInLoc.date
      }
      let nextMeetingDate = ''
      const userProfile = await this.getProfileById({ profileId })
      const msUserEmail = userProfile.msEmail || userProfile.email
      const googleUserEmail = userProfile.googleEmail
      const nextMeeting = await this.getNextMeetingWithUser({ msUserEmail, googleUserEmail })
      // console.log('App.displayProfileDetails.nextMeeting', nextMeeting)
      if (nextMeeting) {
        if (nextMeeting.startDateTime) {
          const mdate = nextMeeting.startDateTime.toDate()
          nextMeetingDate = moment(mdate).format('Do MMMM YYYY h:mm A')
        }
      }
      const profileDetails = {
        profileId,
        workingAt: this.filterSchedule(),
        name: this.getDisplayName(userProfile),
        lastInLocation,
        nextMeetingDate
      }
      this.setDisplayProfile(profileDetails)
    },
    handleAppClick (evt) {
      this.handleClickScrim(evt)
      if (evt.target.className === 'mention') {
        if (evt.target.dataset.id) {
          const profileId = evt.target.dataset.id
          this.displayProfileDetails(profileId)
        }
      }
    },
    handleClickScrim (evt) {
      if (evt.target.className === 'mdc-drawer-scrim') {
        this.drawer.open = false
      }
      if (evt.target.className === 'search-scrim') {
        this.searchActive = false
      }
    },
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.user.uid]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return null
    },
    browserClasses () {
      const browser = `${this.$browserDetect.meta.name}`.toLowerCase()
      return browser
    },
    removeInputFocus () {
      const self = this
      setTimeout(function () {
        self.inputFocused = false
      }, 400)
    },
    focusInput (hasContent) {
      if (!hasContent) {
        self.inputFocused = false
      }
    }
  }
}
</script>

<style>
.version-info {
  display:none;
  position: absolute;
  color: rgba(255,255,255,0.5);
  bottom: 5px;
  font-size: 12px;
  right: 50px;
}
.no-side-drawer {
  width: 100%;
  margin: auto;
  max-width: 1512px;
}
.no-side-drawer main {
  margin-right: 0;
  margin-left: 0;
}
body.mdc-dialog-scroll-lock {
  width:100%;
  min-width: 340px;
  min-height:100%;
  max-height:100%;
  height:100%
}

.app-header {
  width: 100%;
  z-index: 22;
  max-width: 1512px;
  margin: auto;
}
.scroll-lock .app-header {
  z-index: 5;
}

.app-header.headroom--pinned > div {
  transform: translate3d(0px, 0px, 0px) !important;
}
.app-header .headroom {
  z-index: auto !important;
}
.no-height-grid {
  height: 0px;
  padding-top: 0;
  margin: 0;
  padding-bottom: 0;
}
.mobile-input-overlay {
  z-index:99999;
  width:calc(100% - 32px);
  height:calc(100% - 32px);
  min-height:calc(100vh - 32px);
  position:fixed;
  top:0;
  left:0;
  padding:16px;
  background-color:white;
  opacity:0.95;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-input-overlay .autocomplete {
   background:white;
   margin-right:0 !important;
   width:100%;
}
.mobile-input-overlay .autocomplete .pac-container {
  position:relative !important;
  top:12px !important;
  left: 2px !important;
  width: calc(100% - 8px) !important;
}
.prompt-mobile-overlay {
  display: none !important
}
.mobile-input-overlay .prompt {
  display: none !important
}
.mobile-input-overlay .prompt-mobile-overlay {
  display: flex !important
}
.mobile-input-overlay .mdc-text-field-wrapper {
  width:100%;
}
.tooltip.popover {
  z-index:13;
  max-width: 300px;
}
.tooltip.popover .popover-inner {
  background: rgba(0,0,0,0.9);
  padding-top:6px;
  padding-bottom:6px;
}
.notification-panel {
  width: 50%;
  margin: auto;
  z-index: 23;
  max-width: 1512px;
  min-width: 200px;
}
.meeting-dialog {
}
</style>
