<template>
<div class="settings-type-content-wrapper">
  <div v-if="settingHeadingType.type === 'profile'" class="setting-type-wrapper">
    <profile-settings :settingHeadingType="settingHeadingType" />
  </div>
  <div v-if="settingHeadingType.type === 'teamAdmin'" class="setting-type-wrapper">
    <team-admin-settings :settingHeadingType="settingHeadingType" />
  </div>
  <div v-if="settingHeadingType.type === 'workspaceAdmin'" class="setting-type-wrapper">
    <workspace-admin-settings :settingHeadingType="settingHeadingType" />
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProfileSettings from '../flexibly/ProfileSettings.vue'
import TeamAdminSettings from '../flexibly/TeamAdminSettings.vue'
import WorkspaceAdminSettings from '../flexibly/WorkspaceAdminSettings.vue'
export default {
  name: 'SettingsTypeContent',
  data () {
    return {
    }
  },
  props: {
    settingHeadingType: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    ProfileSettings,
    TeamAdminSettings,
    WorkspaceAdminSettings
  },
  computed: {
    ...mapState([
    ])
  },
  methods: {
    ...mapActions([
    ])
  },
  beforeDestroy () {
  },
  mounted () {
  },
  watch: {
  }
}
</script>

<style lang="scss">
.settings-type-content-wrapper {
  margin: 65px 50px 65px 120px;
}
</style>
