import { Mark, markInputRule, mergeAttributes } from '@tiptap/core'

export const slashInputRegEx = /(?:^|\s)((?:\/)((?:[^*]+))(?:\/))$/gm
const ItalicMark = Mark.create({
  name: 'italicmark',
  addOptions () {
    return {
      HTMLAttributes: {}
    }
  },
  parseHTML () {
    return [
      {
        tag: 'em'
      },
      {
        tag: 'i',
        getAttrs: (node) => { return node.style.fontWeight !== 'normal' && null }
      },
      {
        style: 'font-style=italic'
      }
    ]
  },
  renderHTML ({ HTMLAttributes }) {
    return ['em', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
  addInputRules () {
    return [
      markInputRule({
        find: slashInputRegEx,
        type: this.type
      })
    ]
  }
})
export default ItalicMark
