<template>
  <div class="pinned-details-wrapper" v-if="getComputedMembersDatedSchedule.profilesSchedules" :class="[expandMode ? 'expand-mode' : '', defaultTeam ? 'default-pinned' : '']">
    <section class="name-bar-wrapper">
      <div class="name-details">
        <base-svg-icon v-if="!defaultTeam" class="icon-type" :name="teamId ? 'people-black' : initiativeId ? 'rocket-black' : profileId ? 'person-404040' : ''" />
        <base-svg-icon v-else class="icon-type" name="heart-black"/>
        <template v-if="teamDetails && teamDetails.name">
          <initiative-name applyTextOverflow class="pinned-type initname" showTeam v-if="initiativeId" :initiativeId="initiativeId" @initiativeClick="showWeeklyMode()" :fontType="'body1'" clickable />
          <mdc-text role="button" aria-label="expand team" v-else class="pinned-type teamname" @click.native.stop="showWeeklyMode()" tag="span" typo="body1">{{teamDetails.name}}</mdc-text>
        </template>
        <base-svg-icon v-if="(!defaultTeam && expandMode)" class="remove-team-pin" name="remove_minus" clickable @click.native="removePinnedTeam()"/>
      </div>
      <div class="status-bar-date-details" v-if="!expandMode">
        <div class="location-status-bar" v-if="getComputedMembersDatedSchedule.profilesSchedules.some(schedule => schedule.type !== '')" :class="{ 'show-bar': !expandMode }">
          <span class="office status" v-if="getComputedMembersDatedSchedule.percentInOffice !== 0" :style="{ 'width': getComputedMembersDatedSchedule.percentInOffice + '%' }" ></span>
          <span class="remote status" v-if="getComputedMembersDatedSchedule.percentNotInOffice !== 0" :style="{ 'width': getComputedMembersDatedSchedule.percentNotInOffice + '%'}"></span>
          <span class="nonworking status" v-if="getComputedMembersDatedSchedule.percentNotWorking !== 0" :style="{ 'width': getComputedMembersDatedSchedule.percentNotWorking + '%' }"></span>
        </div>
        <div class="for-date">
          <mdc-text :class="{ 'today' : isToday(), 'past' : isSelectedDayPast(), 'future': isSelectedDayFuture() }" class="date-text" tag="span" typo="caption" v-if="!expandMode && forDate">{{forDate.format('ddd D')}}</mdc-text>
        </div>
      </div>
    </section>
    <section class="dated-schedules"  v-if="!expandMode">
      <div class="members-not-set" v-if="getComputedMembersDatedSchedule.profilesSchedules.every(schedule => schedule.type === '')">
        <mdc-text class="not-set-text" tag="span" typo="body2">No members have set a schedule</mdc-text>
      </div>
      <div class="schedules-type-wrapper" v-else>
        <div class="full-day-wrapper type-content" :ref="'fullDaysWrapper'">
          <template v-for="(type, index) in scheduleTypes">
            <div :key="'type-wrapper' + type + index " class="type-wrapper full-day" v-if="getComputedMembersDatedSchedule.schedules[type] && getComputedMembersDatedSchedule.schedules[type].length > 0 && index !== 3">
              <div class="type-icon-profile">
                <base-svg-icon :name="getResponseIcon(type)" :key="'icon' + type" :class="[getBoxFillClass(type)]"/>
                <div class="profiles-list" :class="{ 'hover': getComputedMembersDatedSchedule.schedules[type] && getComputedMembersDatedSchedule.schedules[type].length > minProfilesDisplay && profileListsHovered === type+index+'profiles'}" @mouseover="profileListsHovered = type+index+'profiles'" @mouseout="profileListsHovered = ''">
                  <template v-if="getComputedMembersDatedSchedule.schedules[type] && getComputedMembersDatedSchedule.schedules[type].length > minProfilesDisplay && profileListsHovered !== type+index+'profiles'">
                    <profile-initials v-for="profile in getComputedMembersDatedSchedule.schedules[type].slice(0, minProfilesDisplay)" :key="profile.profileId" :profileId="profile.profileId" :date="appDate" />
                    <mdc-text tag="span" typo="caption" class="member-number">+{{ getComputedMembersDatedSchedule.schedules[type] && getComputedMembersDatedSchedule.schedules[type].length - minProfilesDisplay}}</mdc-text>
                  </template>
                  <template v-else>
                    <profile-initials v-for="profile in getComputedMembersDatedSchedule.schedules[type]" :key="profile.profileId" :profileId="profile.profileId" :date="appDate" />
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="office-day split-day-wrapper type-content" :ref="'splitDaysWrapper'">
          <template v-for="(type, index) in scheduleTypes">
            <div :key="'type-wrapper' + type + index " class="type-wrapper full-day" v-if="getComputedMembersDatedSchedule.schedules[type] && getComputedMembersDatedSchedule.schedules[type].length > 0 && index === 3">
              <div class="type-icon-profile">
                <base-svg-icon :name="getResponseIcon(type)" :key="'icon' + type" :class="[getBoxFillClass(type)]"/>
                <div class="profiles-list" :class="{ 'hover': getComputedMembersDatedSchedule.schedules[type] && getComputedMembersDatedSchedule.schedules[type].length > minProfilesDisplay && profileListsHovered === type+index+'profiles'}" @mouseover="profileListsHovered = type+index+'profiles'" @mouseout="profileListsHovered = ''">
                  <template v-if="getComputedMembersDatedSchedule.schedules[type] && getComputedMembersDatedSchedule.schedules[type].length > minProfilesDisplay && profileListsHovered !== type+index+'profiles'">
                    <profile-initials v-for="profile in getComputedMembersDatedSchedule.schedules[type].slice(0, minProfilesDisplay)" :key="profile.profileId" :profileId="profile.profileId" :date="appDate" />
                    <mdc-text tag="span" typo="caption" class="member-number">+{{ getComputedMembersDatedSchedule.schedules[type] && getComputedMembersDatedSchedule.schedules[type].length - minProfilesDisplay}}</mdc-text>
                  </template>
                  <template v-else>
                    <profile-initials v-for="profile in getComputedMembersDatedSchedule.schedules[type]" :key="profile.profileId" :profileId="profile.profileId" :date="appDate" />
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-for="(type, index) in splitScheduleTypes">
            <div class="type-wrapper split-day" v-if="getComputedMembersDatedSchedule.splitSchedules[type] && getComputedMembersDatedSchedule.splitSchedules[type].length > 0"  :key="'type-wrapper' + type + index">
              <div class="type-icon-profile">
                <div class="split-day-icons">
                  <base-svg-icon class="am-wrapper slot-type" :name="type.split('-')[0]" />
                  <base-svg-icon class="pm-wrapper slot-type" :name="type.split('-')[1]" />
                </div>
                <div class="profiles-list" :class="{ 'hover': getComputedMembersDatedSchedule.splitSchedules[type] && getComputedMembersDatedSchedule.splitSchedules[type].length > minProfilesDisplay && profileListsHovered === type+index+'profiles'}" @mouseover="profileListsHovered = type+index+'profiles'" @mouseout="profileListsHovered = ''">
                  <template v-if="getComputedMembersDatedSchedule.splitSchedules[type] && getComputedMembersDatedSchedule.splitSchedules[type].length > minProfilesDisplay">
                    <profile-initials v-for="profile in getComputedMembersDatedSchedule.splitSchedules[type].slice(0, minProfilesDisplay)" :key="profile.profileId" :profileId="profile.profileId" :date="appDate" />
                    <mdc-text tag="span" typo="caption" class="member-number">+{{ getComputedMembersDatedSchedule.splitSchedules[type] && getComputedMembersDatedSchedule.splitSchedules[type].length - minProfilesDisplay}}</mdc-text>
                  </template>
                  <template v-else>
                    <profile-initials v-for="profile in getComputedMembersDatedSchedule.splitSchedules[type]" :key="profile.profileId" :profileId="profile.profileId" :date="appDate" />
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
    <base-svg-icon v-if="(!defaultTeam && !expandMode)" class="remove-team-pin" name="remove_minus" clickable @click.native="removePinnedTeam()"/>
    <section class="weekly-schedules" v-if="expandMode && !defaultTeam">
      <schedule-search :pinnedTeamId="teamId" :pinnedInitiativeId="initiativeId" :pinnedQuery="teamDetails.name" :name="'search' + teamDetails.name" @allSearchResultsNotSet="setSearchResultsNotSet"/>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import moment from 'moment'
import ProfileInitials from './ProfileInitials.vue'
import InitiativeName from './InitiativeName.vue'
import { inFuture, inPast, isToday } from '../../utils/time'
import { getResponseIconName } from '../../utils/profile'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
const _ = { groupBy, orderBy }
export default {
  name: 'PinnedTeam',
  components: {
    BaseSvgIcon,
    InitiativeName,
    ProfileInitials,
    ScheduleSearch: () => import('./ScheduleSearch.vue')
  },
  props: {
    defaultTeam: {
      type: Boolean,
      default: false
    },
    teamId: {
      type: String,
      default: ''
    },
    initiativeId: {
      type: String,
      default: ''
    },
    forDate: {
      type: Object,
      default: () => {
        return {}
      }
    },
    minProfilesDisplay: {
      type: Number,
      default: 8
    }
  },
  data () {
    return {
      profileListsHovered: '',
      teamDetails: '',
      teamMembersFromProfiles: [],
      expandMode: false,
      allNotSet: false,
      scheduleTypes: [
        'home',
        'thirdspace',
        'nonworking',
        'office'
      ],
      splitScheduleTypes: [
        'home-office',
        'office-home',
        'office-thirdspace',
        'thirdspace-office',
        'office-nonworking',
        'nonworking-office',
        'home-nonworking',
        'home-thirdspace',
        'thirdspace-nonworking',
        'thirdspace-home',
        'nonworking-home',
        'nonworking-thirdspace'
      ],
      splitDaysComponentWidth: '',
      fullDaysComponentWidth: ''
    }
  },
  beforeDestroy () {
  },
  created () {
    this.initialize()
  },
  mounted () {
    if (this.profileId) {
      this.expandMode = true
    }
  },
  methods: {
    ...mapActions([
      'updateProfile'
    ]),
    getBoxFillClass (response) {
      if (response && response !== null && response !== '' && response !== undefined) {
        switch (response.toLowerCase()) {
          case 'home':
            return 'home-fill'
          case 'thirdspace':
            return 'thirdspace-fill'
          case 'office':
            return 'office-fill'
          case 'nonworking':
            return 'nonworking-fill'
          case 'notset':
            return 'notset-fill'
          default:
            return ''
        }
      }
    },
    setSearchResultsNotSet () {
      this.allNotSet = true
    },
    isSelectedDayPast () {
      return inPast(this.forDate)
    },
    isSelectedDayFuture () {
      return inFuture(this.forDate)
    },
    isToday () {
      return isToday(this.forDate)
    },
    getResponseIcon (response) {
      return getResponseIconName(response)
    },
    showWeeklyMode () {
      if (this.defaultTeam) {
        this.updateProfile({ profile: { teamExpanded: true }, noSnack: true })
        this.$emit('expandScheduleSearch', true)
        return
      }
      this.expandMode = !this.expandMode
    },
    removePinnedTeam () {
      if (this.initiativeId) {
        this.updateProfile({ profile: { pinnedInitiatives: this.profile.pinnedInitiatives.filter(initiative => initiative !== this.initiativeId) }, noSnack: true })
      } else {
        this.updateProfile({ profile: { pinnedTeams: this.profile.pinnedTeams.filter(team => team !== this.teamId) }, noSnack: true })
      }
    },
    async initialize () {
      const allProfiles = [...this.profiles, this.profile]
      if (this.initiativeId) {
        const initiative = this.initiatives.filter(initiative => initiative.id === this.initiativeId)
        if (initiative.length > 0) {
          this.teamDetails = initiative[0] || ''
          const teamMembers = initiative[0].members
          for (const member of teamMembers) {
            const memberDetail = allProfiles.filter(profile => profile.id === member)
            if (memberDetail[0]) {
              this.teamMembersFromProfiles.push(memberDetail[0])
            }
          }
        }
      } else if (this.teamId) {
        const team = this.teams?.filter(team => team.id === this.teamId)
        // console.log('initialize', this.teamId, this.teams, team)
        if (team?.length > 0) {
          this.teamDetails = team[0] || ''
          this.teamMembersFromProfiles = allProfiles.filter(profile => profile.team === this.teamId) || []
        }
      } else if (this.profileId) {
        this.teamDetails = allProfiles.filter(prof => prof.id === this.profileId)[0] || false
      }
    },
    ...mapActions({
    })
  },
  watch: {
    initiatives: {
      handler () {
        if (this.initiativeId && !this.teamDetails) {
          this.initialize()
        }
      }
    },
    teams () {
      if (this.teamId) {
        this.initialize()
      }
    }
  },
  computed: {
    ...mapState([
      'profiles',
      'profile',
      'appDate'
    ]),
    ...mapState({
      initiatives: state => state.teams.initiatives,
      teams: state => state.teams.teams,
      schedules: state => state.teams.schedules,
      defaultSchedules: state => state.teams.defaultSchedules
    }),
    getComputedMembersDatedSchedule () {
      if (this.teamMembersFromProfiles && this.schedules && this.defaultSchedules) {
        const allProfilesSchedules = []
        const weekdayAbv = moment(this.forDate).format('ddd').toUpperCase()
        this.teamMembersFromProfiles.forEach((profile, index) => {
          const profileSchedule = this.schedules.filter((schedule) => {
            return schedule.fromId === profile.id
          })
          const datedResponse = profileSchedule[0] ? profileSchedule[0].schedules[weekdayAbv] : {}
          const profileDefaultSchedule = this.defaultSchedules.filter((schedule) => {
            return schedule.fromId === profile.id
          })
          const defaultResponse = profileDefaultSchedule[0] ? profileDefaultSchedule[0].schedules[weekdayAbv] : {}
          allProfilesSchedules.push({
            profileId: profile.id,
            name: profile.displayName,
            response: datedResponse && datedResponse.type === 'full' && datedResponse.response ? datedResponse.response : (!datedResponse || (datedResponse && (!datedResponse.type || datedResponse.type === ''))) && defaultResponse && defaultResponse.type === 'full' && defaultResponse.response ? defaultResponse.response : '',
            type: datedResponse && datedResponse.type ? datedResponse.type : (!datedResponse || (datedResponse && (!datedResponse.type || datedResponse.type === ''))) && defaultResponse && defaultResponse.type ? defaultResponse.type : '',
            amResponse: datedResponse && datedResponse.type === 'split' && datedResponse.amResponse ? datedResponse.amResponse : (!datedResponse || (datedResponse && (!datedResponse.type || datedResponse.type === ''))) && defaultResponse && defaultResponse.type === 'split' && defaultResponse.amResponse ? defaultResponse.amResponse : '',
            pmResponse: datedResponse && datedResponse.type === 'split' && datedResponse.pmResponse ? datedResponse.pmResponse : (!datedResponse || (datedResponse && (!datedResponse.type || datedResponse.type === ''))) && defaultResponse && defaultResponse.type === 'split' && defaultResponse.pmResponse ? defaultResponse.pmResponse : '',
            splitResponse: datedResponse && datedResponse.type === 'split' ? datedResponse.amResponse + '-' + datedResponse.pmResponse : (!datedResponse || (datedResponse && (!datedResponse.type || datedResponse.type === ''))) && defaultResponse && defaultResponse.type === 'split' ? defaultResponse.amResponse + '-' + defaultResponse.pmResponse : ''
          })
        })
        const membersWithSchedule = allProfilesSchedules.filter(attendee => {
          return (['thirdspace', 'office', 'home', 'nonworking'].indexOf(attendee.response) > -1 || ['thirdspace', 'office', 'home', 'nonworking'].indexOf(attendee.amResponse) > -1 || ['thirdspace', 'office', 'home', 'nonworking'].indexOf(attendee.pmResponse) > -1)
        })
        const totalmembers = membersWithSchedule.length
        const totalOfficeAttendees = allProfilesSchedules.filter(attendee => {
          return (attendee.response === 'office' || attendee.amResponse === 'office' || attendee.pmResponse === 'office')
        })
        const totalRemoteAttendees = allProfilesSchedules.filter(attendee => {
          return (['home', 'thirdspace'].indexOf(attendee.response) > -1 || ['home', 'thirdspace'].indexOf(attendee.amResponse) > -1 || ['home', 'thirdspace'].indexOf(attendee.pmResponse) > -1)
        })
        const totalNonWorkingAttendees = allProfilesSchedules.filter(attendee => {
          return (['nonworking'].indexOf(attendee.response) > -1 || ['nonworking'].indexOf(attendee.amResponse) > -1 || ['nonworking'].indexOf(attendee.pmResponse) > -1)
        })
        let inOffice, inRemote, nonworking
        if (totalmembers === 0) {
          inOffice = 0
          inRemote = 0
          nonworking = 0
        } else {
          inOffice = Math.ceil((totalOfficeAttendees.length / totalmembers) * 100)
          inRemote = Math.ceil((totalRemoteAttendees.length / totalmembers) * 100)
          nonworking = Math.ceil((totalNonWorkingAttendees.length / totalmembers) * 100)
        }
        return {
          profilesSchedules: allProfilesSchedules,
          schedules: _.groupBy(allProfilesSchedules, 'response'),
          splitSchedules: _.groupBy(allProfilesSchedules, 'splitResponse'),
          percentInOffice: inOffice,
          percentNotInOffice: inRemote,
          percentNotWorking: nonworking
        }
      }
      return {}
    }
  }
}
</script>

<style lang="scss">
.pinned-details-wrapper {
  display: flex;
  min-height: 71px;
  align-items: flex-start;
  position: relative;
  margin-top: 10px;
  &.expand-mode {
    .remove-team-pin {
      display: flex;
      margin: 0;
      margin-left: auto;
    }
    .name-bar-wrapper {
      width: -webkit-fill-available;
      width: -moz-available;
      margin-right: 25px;
    }
  }
  &:hover {
    background: #F4F9FF;
    .remove-team-pin {
      display: flex;
    }
    &.expand-mode {
      .weekly-schedules {
        .whos-where-when-wrapper {
          .whos-where-when-content {
            background: #F4F9FF;
          }
        }
      }
    }
  }
  &.default-pinned {
    margin-top: 0;
    .name-bar-wrapper {
      .name-details {
        .pinned-type {
        }
      }
    }
  }
  .remove-team-pin {
    display: none;
    margin-top: 17px;
    margin-right: 25px;
  }
  &.expand-mode {
    flex-direction: column;
    .name-bar-wrapper {
      .name-details {
        .pinned-type {
          width: unset;
        }
      }
    }
  }
  .name-bar-wrapper {
    margin-top: 15px;
    margin-left: 25px;
    .name-details {
      display: flex;
      align-items: center;
      .pinned-type {
        &::first-letter {
          text-transform: capitalize;
        }
        text-transform: lowercase;
        color: #404040;
        font-weight: 600;
        margin-left: 5px;
        width: 120px;
        white-space: nowrap;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        * {
          font-weight: 600;
        }
      }
    }
    .status-bar-date-details {
      display: flex;
      margin-top: 5px;
      align-items: center;
      .location-status-bar {
        display: flex;
        visibility: hidden;
        height: 12px;
        min-width: 63px;
        max-width: 63px;
        &.show-bar {
          visibility: visible;
          transition: visibility 0.3s 0.2s;
        }
        .status {
          &.remote {
            background: #00BE13;
          }
          &.office {
            background: #0060D1;
          }
          &.nonworking {
            background:#989898;
          }
          &:first-of-type {
            border-radius: 8px 0 0 8px;
          }
          &:last-of-type {
            border-radius: 0 8px 8px 0;
          }
          &:only-child {
            border-radius: 8px;
          }
        }

      }
      .for-date {
        margin-left: 8px;
        &:only-child {
          margin-left: 15px;
        }
        .date-text {
          font-style: italic;
          &.past {
            color: #828282;
          }
          &.future {
            color: #404040;
          }
          &.today {
            color: #b10000;
          }
        }
      }
    }
  }
  .dated-schedules {
    display: flex;
    margin: 15px 10px 0 20px;
    flex: 1;
    .members-not-set {
      margin-top: 5px;
      text-transform: uppercase;
      .not-set-text {
        color: #959EA7;
        line-height: 14px;
      }
    }
    .schedules-type-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      .type-content {
        display: flex;
        .type-wrapper {
          display: flex;
          margin-right: 25px;
          margin-bottom: 5px;
          position: relative;
          &.full-day {}
          &.split-day {
            .type-icon-profile {
              .split-day-icons {
                display: flex;
                align-items: center;
                .icon-wrapper{
                  &.am-wrapper {
                    z-index: 100;
                  }
                  &.pm-wrapper {
                    z-index: 10;
                    align-self: flex-end;
                    margin-left: -4px;
                    z-index: 10;
                    height: 11px;
                    width: 11px;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }
          }
          .type-icon-profile {
            display: flex;
            align-items: center;
            .profiles-list {
              border: 1px solid #fff;
              margin-left: 7px;
              display: flex;
              border-radius: 16px;
              background: #F1F1F1;
              height: 30px;
              align-items: center;
              width: fit-content;
              transition: width 2s;
              &.hover {
                filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.33));
              }
              .profile-redirect {
                &:first-of-type {
                  margin-left: 6px;
                }
                &:last-of-type {
                  margin-right: 6px;
                }
                margin-right: 10px;
              }
              .member-number {
                color: #4175B1;
                font-weight: 600;
                font-size: 10px;
                margin-left: -4px;
                margin-right: 10px;
              }
            }
            .icon-wrapper {
              height: 15px;
              width: 15px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  .weekly-schedules {
    width: 100%;
    margin-top: 10px;
    .whos-where-when-wrapper {
      .whos-when-where-content {
        .response-table-section {
          .days-membersnames-table-wrapper {
            .table-contents {
              margin: 0;
              border: none;
              > div {
                margin-bottom: 30px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
