<template>
<div v-if="msNeedsReconnect || googleNeedsReconnect" class="calendar-connect-prompt">
  <div class="connect-prompt-wrapper">
    <mdc-text typo="caption" role="button" @click.native="goToConnect">Unable to connect to your
      <span v-if="msNeedsReconnect && googleNeedsReconnect"> calendars</span>
      <span v-else-if="msNeedsReconnect"> Microsoft calendar</span>
      <span v-else-if="googleNeedsReconnect"> Google calendar</span>
      , please reconnect to synchronize meetings</mdc-text>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'CalendarConnectPrompt',
  data () {
    return {
    }
  },
  components: {
  },
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
      subscriptions: state => state.teams.subscriptions
    }),
    msNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type !== 'Google') {
              // type MS or blank
              if (sub.msNeedsReconnect) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    googleNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type === 'Google') {
              // type Google
              if (sub.googleNeedsReconnect) {
                return true
              }
            }
          }
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions([
    ]),
    goToConnect () {
      this.$router.push('/settings/calendarconnect')
    }
  },
  beforeDestroy () {
  },
  mounted () {
  },
  watch: {}
}
</script>

<style lang="scss">
.calendar-connect-prompt {
  .connect-prompt-wrapper {
    margin: 6px 20px 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: rgba(255,233,115,.15);
    border-radius: 12px;
    cursor:pointer;
    .mdc-text {
      margin-left: 10px;
      margin-top: 2px;
      margin-bottom:2px;
    }
  }
}
</style>
