<template>
<div class="cookie-settings-wrapper">
  <section class="intro section">
    <header>
      <mdc-text tag="span" typo="body1">Privacy Preference Center</mdc-text>
    </header>
    <div class="preference-content">
      <mdc-text tag="span" typo="body2" class="content-text">
        When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. We use data collected by cookies and JavaScript libraries to improve your browsing experience, enhance the service, and increase the overall performance of our site.
      </mdc-text>
    </div>
    <mdc-button outlined @click.native="allowAll()">Allow All</mdc-button>
  </section>
  <section class="manage-preferences section">
    <header>
      <mdc-text tag="span" typo="body1">Manage consent preferences</mdc-text>
    </header>
    <div class="preferences-types-wrapper">
      <article class="cookie-type necessary">
        <header>
          <mdc-icon :icon="isTypeContentExpanded ('necessary') ? 'remove' : 'add'" @click.native="showTypeContent('necessary')"/>
          <mdc-text tag="span" typo="body2" class="heading-text">Strictly necessary cookies</mdc-text>
          <mdc-text tag="span" typo="body2">ALWAYS ACTIVE</mdc-text>
        </header>
        <div class="type-content necessary" v-if="isTypeContentExpanded('necessary')">
          <mdc-text tag="span" typo="caption" class="content-text">
            We use browser cookies that are necessary for the site to work as intended.
            For example, we store your website data collection preferences so we can honour them if you return to our site.
            You can disable these cookies in your browser settings but if you do the site may not work as intended.
          </mdc-text>
        </div>
      </article>
      <article class="cookie-type performance">
        <header>
          <mdc-icon :icon="isTypeContentExpanded ('performance') ? 'remove' : 'add'" @click.native="showTypeContent('performance')"/>
          <mdc-text tag="span" typo="body2" class="heading-text">Performance cookies</mdc-text>
          <mdc-switch @change="savePerfomanceCookies" v-model="performanceCookies" class="switch-row">
          </mdc-switch>
        </header>
        <div class="type-content performance" v-if="isTypeContentExpanded('performance')">
          <mdc-text tag="span" typo="caption" class="content-text">
            To understand user behaviour in order to provide you with a more relevant browsing experience, personalize the content, or enhance the capability on our site.
            For example, we collect information about which pages you visit to help us present more relevant information.
          </mdc-text>
        </div>
      </article>
      <article class="cookie-type functional">
        <header>
          <mdc-icon :icon="isTypeContentExpanded ('functional') ? 'remove' : 'add'" @click.native="showTypeContent('functional')"/>
          <mdc-text tag="span" typo="body2" class="heading-text">Functional cookies</mdc-text>
          <mdc-switch @change="saveFunctionalCookies" v-model="functionalCookies" class="switch-row">
          </mdc-switch>
        </header>
        <div class="type-content functional" v-if="isTypeContentExpanded('functional')">
          <mdc-text tag="span" typo="caption" class="content-text">
            To provide enhanced functionality, like chat support or more easily complete forms.
            If you do not enable these cookies it could impact your ability to use certain features.
            For example, we send you a welcome message and depending upon what information you provide to the site, we may tailor it to be more relevant.
          </mdc-text>
        </div>
      </article>
    </div>
   </section>
</div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CookieSettings',
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
    })
  },
  components: {
  },
  data () {
    return {
      performanceCookies: false,
      functionalCookies: false,
      cookieExpanded: []
    }
  },
  mounted () {
    this.initialize()
  },
  props: {
  },
  created () {
  },
  watch: {
    profile: {
      handler () {
        this.initialize()
      }
    }
  },
  methods: {
    ...mapActions({
    }),
    ...mapActions([
      'updateProfile'
    ]),
    showTypeContent (value) {
      if (this.isTypeContentExpanded(value)) {
        const index = this.cookieExpanded.findIndex(type => type === value)
        this.cookieExpanded.splice(index, 1)
        return
      }
      this.cookieExpanded.push(value)
    },
    isTypeContentExpanded (value) {
      if (this.cookieExpanded.includes(value)) {
        return true
      }
      return false
    },
    initialize () {
      this.performanceCookies = this.profile.cookiesPerf
      this.functionalCookies = this.profile.cookiesFunc
    },
    saveFunctionalCookies (value) {
      this.updateProfile({ profile: { cookiesFunc: value }, noSnack: true })
    },
    savePerfomanceCookies (value) {
      this.updateProfile({ profile: { cookiesPerf: value }, noSnack: true })
    },
    allowAll () {
      this.saveFunctionalCookies(true)
      this.savePerfomanceCookies(true)
    }
  }
}
</script>
<style lang="scss">
.cookie-settings-wrapper {
  margin-top: 20px;
  .section {
    margin-bottom: 20px;
    > header {
      margin-bottom: 10px;
      .mdc-text {
        color: #828282;
        font-weight: 600;
      }
    }
    .preference-content {
      .mdc-text {
        line-height: 27px;
      }
    }
    .mdc-button {
      border: 1px solid #6184AD;
      border-radius: 10px;
      margin-top: 20px;
      width: auto;
      max-width: 150px;
      font-size: 14px;
      color: #6184AD;
      font-weight: 700;
      text-transform: none;
    }
    &.manage-preferences {
      .preferences-types-wrapper {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        article {
          display: flex;
          flex-direction: column;
          padding: 15px;
          &:not(:last-of-type) {
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          }
          > header {
            display: flex;
            align-items: center;
            .heading-text {
              flex: 1;
              color: #404040;
            }
            .mdc-icon {
              font-size: 16px;
              margin-right: 5px;
              color: #404040;
              cursor: pointer;
            }
            .mdc-switch {
              margin-top: 3px;
              .mdc-ripple-upgraded::before,
              .mdc-ripple-upgraded::after {
                --mdc-ripple-fg-size: 0;
              }
            }
          }
          .type-content {
            margin-top: 10px;
            margin-left: 20px;
            .content-text {
              text-align: justify;
            }
          }
        }
      }
    }
  }
}
</style>
