<template>
  <div class="app-section-outer">
  <div class="app-section-wrapper" :class="mentionsShown ? 'mentions-shown': ''">
    <template v-if="showSettingsCogMenu && cogMenu">
      <settings-cog-menu
        @closeSettingsCogMenu="closeSettingsCogMenu"
        @enableSetupUpdates="enableSetupUpdates"
        @enableSetupCalendar="enableSetupCalendar"
        @enableAddUser="enableAddUser"
        @enableSetupSchedules="enableSetupSchedules"
        :showSettingsCogMenu="showSettingsCogMenu"
        :forSection="showSettingsMenuFor" />
    </template>
    <div class="mdc-card app-section">
      <div class="section-title">
        <span v-if="enableSchedulerFullScreen && !composerSectionExpanded" class="scheduler-fullscreen-icon"> <!-- scheduler full screen prop -->
          <base-svg-icon v-if="!schedulerFullScreen" @click.native="enterSchedulerFullScreen" name="fullwidthpage" clickable />
          <base-svg-icon v-else name="splitpage" @click.native="exitSchedulerFullScreen" clickable />
        </span>
        <span class="title-content" v-if="sectionControls || composerSectionExpanded || (showFilter && showMentionsSideBar)">
          <span v-if="sectionControls" class="section-controls">
            <base-svg-icon class="info-icon" :class="{ 'clicked': formatControlButtonClicked }" ref="formatsIcon" @click.native="formatControlIconClick($event)" name="info" clickable />
            <base-svg-icon class="settings-icon cog" v-if="cogMenu" ref="settingsIconCog" @click.native="settingsCogClick($event)" name="settings" clickable />
            <base-svg-icon class="settings-icon" v-else-if="!noSettings" :class="{ 'clicked': settingsButtonClicked }" ref="settingsIcon" @click.native="settingsIconClick($event)" name="settings" clickable />
          </span>
          <span v-if="composerSectionExpanded" class="scheduler-button">
            <base-svg-icon name="scheduler-full-screen" @click.native.stop="enterSchedulerFullScreen" />
          </span>
          <span v-if="showFilter && showMentionsSideBar" class="filter-button">
            <round-button background="transparent" title="filter" icon="filter_alt" label="filter">
            </round-button>
          </span>
        </span>
        <span v-if="fullscreenEnabled" style="z-index:5;"> <!-- composer full screen prop -->
          <base-svg-icon class="full-page-icon" v-if="!expandedView" @click.native="enterFullscreen" name="fullwidthpage" clickable />
          <base-svg-icon class="split-page-icon" v-else-if="fullscreen" @click.native="exitFullscreen" name="splitpage" clickable />
          <base-svg-icon class="full-page-icon" v-else @click.native="enterFullscreen" name="fullwidthpage" clickable />
        </span>
      </div>
      <div v-if="notesSearch" class="search-wrapper">
        <div class="app-section-search">
          <auto-complete-outline
              v-if="taskSearch"
              autocompleteId="'note-search'"
              class="note-search-autocomplete"
              field="text"
              :placeholder="placeholderText"
              :value="query"
              :items="formattedTasks"
              ref="pageSearch"
              @set="routeToTask"
              :min="1"
              noNotched
              >
          </auto-complete-outline>
          <auto-complete-outline
              v-else
              autocompleteId="'note-search'"
              class="note-search-autocomplete"
              field="pageName"
              :placeholder="placeholderText"
              :value="query"
              :items="pages"
              @set="getPageOrCreateNotePage"
              ref="pageSearch"
              :min="1"
              noNotched
              >
          </auto-complete-outline>
        </div>
      </div>
      <div :style="{'height': contentHeightValue, 'transition-duration': animationStyle}" class="section-content-wrapper">
        <div ref="content" class="section-content">
          <slot/>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { MDCRipple } from '@material/ripple'
import RoundButton from '../RoundButton'
import BaseSvgIcon from './BaseSvgIcon'
import SettingsCogMenu from '../../components/flexibly/SettingsCogMenu'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'
import { uuidv4 } from '../../utils/uid'
import moment from 'moment'

const ResizeObserver = window.ResizeObserver

export default {
  name: 'AppSection',

  props: {
    mentionsShown: {
      type: Boolean,
      default: false
    },
    updatesPreferencesSubmitted: {
      type: Boolean,
      default: false
    },
    showSettingsMenuFor: {
      type: String,
      default: ''
    },
    showSettingsCogMenu: {
      type: Boolean,
      default: false
    },
    noSettings: {
      type: Boolean,
      default: false
    },
    cogMenu: {
      type: Boolean,
      default: false
    },
    openFormatControls: {
      type: Boolean,
      default: false
    },
    openSettings: {
      type: Boolean,
      default: false
    },
    enableSchedulerFullScreen: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    notesSearch: {
      type: Boolean,
      default: false
    },
    pages: {
    },
    tasks: {
    },
    showMentionsSideBar: {
      type: Boolean,
      default: false
    },
    composerSectionExpanded: {
      type: Boolean,
      default: false
    },
    sectionControls: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    animationTime: {
      type: String,
      default: '100ms'
    },
    accept: {
      type: String,
      default: 'Save'
    },
    closeOnAccept: {
      type: Boolean,
      default: true
    },
    focused: {
      type: Boolean,
      default: false
    },
    fullscreenEnabled: {
      type: Boolean,
      default: false
    },
    expandedView: {
      type: Boolean,
      default: false
    },
    composerView: {
      type: String,
      default: 'default'
    }
  },

  components: {
    RoundButton,
    BaseSvgIcon,
    SettingsCogMenu,
    AutoCompleteOutline
  },

  data () {
    return {
      editing: false,
      startclose: false,
      contentHeight: null,
      animationStyle: null,
      fullscreen: false,
      schedulerFullScreen: false,
      formatControlButtonClicked: false,
      settingsButtonClicked: false,
      placeholderText: 'Type to find tasks',
      query: '',
      taskSearch: true
    }
  },

  computed: {
    ...mapState([
      'profile'
    ]),
    contentHeightValue () {
      if (this.contentHeight) {
        return `${this.contentHeight}px`
      }
      return 'auto'
    },
    formattedTasks () {
      if (this.tasks) {
        const self = this
        return this.tasks.map(task => {
          return { ...task, text: self.removeHTML(task.text) }
        })
      }
      return []
    }
  },

  mounted () {
    this.fabRipple = MDCRipple.attachTo(this.$el)
    this.calcHeight()
    const observer = new ResizeObserver(this.calcHeight)
    observer.observe(this.$refs.content)
    this.setAnimationStyle()
  },

  beforeCreate () {
    // console.log('AppSection beforeCreate')
  },

  methods: {
    ...mapActions({
      getNotePage: 'teams/getNotePage'
    }),
    getPageLink (linkText) {
      return 'notepage/' + encodeURIComponent(linkText.replaceAll('[[', '').replaceAll(']]', '').replaceAll(' ', '-').replaceAll('.', ''))
    },
    removeHTML (str) {
      if (str) {
        return `${str}`.replace(/(<([^>]+)>)/gi, '')
      }
      return ''
    },
    routeToTask (value, task) {
      console.log('Appsection.routeToTask', value, task)
      // TODO: copied from search component - all tasks routing - but where should we be routing to?
      this.$root.$emit('changeComposerView', 'allTasks', 'ALL TASKS')
    },
    getPageOrCreateNotePage (value, page) {
      console.log('Appsection.getOrCreateNotePage', value, page)
      if (page === null) {
        this.query = value.pageName
        if (value.pageName !== '') {
          const pageId = uuidv4()
          const pageLink = this.getPageLink(value.pageName)
          const newPage = { teamId: this.profile.team, id: pageId, notePageName: value.pageName, notePageLink: pageLink }
          this.getNotePage(newPage).then(() => {
            this.$root.$emit('changeComposerView', 'notesPage', value.pageName, { link: pageLink, label: value.pageName, notesSearch: true })
            this.query = ''
            this.$refs.pageSearch.clearSearch()
          })
        }
      } else {
        const pageType = page.pageType
        this.query = page.pageName
        if (pageType === 'dailyPage') {
          this.$root.$emit('changeComposerView', pageType, page.pageName, { date: moment(page.pageName).toDate() })
          this.query = ''
          this.$refs.pageSearch.clearSearch()
        } else if (pageType === 'meetingPage') {
          // console.log('AppSections.getPageOrCreateNotePage meetingPage', page)
          const uid = page.iCalUId
          const meetingLabel = `${page.dateStr} ${page.subject}`
          const meetingLink = 'meetingpage/' + uid
          this.$root.$emit('changeComposerView', 'meetingPage', meetingLabel, { meetingId: page.id, link: meetingLink, label: meetingLabel, subject: page.subject, when: page.date, meetingDetails: [page] })
          this.query = ''
          this.$refs.pageSearch.clearSearch()
        } else {
          // console.log('otherNote', pageType, page)
          this.$root.$emit('changeComposerView', pageType, page.pageName, { link: page.pageLink, label: page.pageName })
          this.query = ''
          this.$refs.pageSearch.clearSearch()
        }
      }
    },
    enableSetupSchedules () {
      this.$emit('enableSetupSchedules')
    },
    enableSetupCalendar () {
      this.$emit('enableSetupCalendar')
    },
    enableAddUser () {
      this.$emit('enableAddUser')
    },
    enableSetupUpdates () {
      this.$emit('enableSetupUpdates')
    },
    closeSettingsCogMenu () {
      this.$emit('closeSettingsCogMenu')
    },
    enterFullscreen () {
      this.fullscreen = true
      this.$emit('fullscreen', true)
      if (this.composerView === 'notesPage') {
        this.$emit('toggleMentionsSideBar', true, 'linkedmentions')
      } else {
        this.$emit('toggleMentionsSideBar', false, '')
      }
    },
    exitFullscreen () {
      this.fullscreen = false
      this.$emit('fullscreen', false)
      this.$emit('toggleMentionsSideBar', false, '')
    },
    enterSchedulerFullScreen () {
      this.schedulerFullScreen = true
      this.fullscreen = false // disabled composer fullscreen
      this.$emit('fullscreen', false)
      this.$emit('schedulerFullScreen', true)
      this.$emit('toggleMentionsSideBar', false, '')
    },
    exitSchedulerFullScreen () {
      this.schedulerFullScreen = false
      this.fullscreen = false // disabled composer fullscreen
      this.$emit('fullscreen', false)
      this.$emit('schedulerFullScreen', false)
      this.$emit('toggleMentionsSideBar', false, '')
    },
    cancel () {
      this.close()
      this.$emit('cancel')
    },
    calcHeight () {
      const self = this
      this.$nextTick(() => {
        if (self.$refs.content) {
          if (self.contentHeight !== self.$refs.content.offsetHeight) {
            self.contentHeight = self.$refs.content.offsetHeight
          }
        }
      })
    },
    edit () {
      this.editing = true
      const self = this
      setTimeout(() => {
        self.$nextTick(() => {
          self.startclose = true
        })
      }, 100)
      this.$emit('change', this.editing)
    },
    close () {
      this.editing = false
      const self = this
      setTimeout(() => {
        self.$nextTick(() => {
          self.startclose = false
        })
      }, 100)
      this.$emit('change', this.editing)
    },
    save () {
      if (this.closeOnAccept) {
        this.close()
      }
      this.$emit('save')
    },
    onClick () {
      this.$emit('click')
    },
    setAnimationStyle () {
      this.animationStyle = `${this.animationTime}`
    },
    titleNavClicked (title) {
      this.$emit('titleNavClick', title)
      this.$emit('toggleMentionsSideBar', false, '')
    },
    formatControlIconClick ($event) {
      if (!this.schedulerFullScreen) {
        this.formatControlButtonClicked = !this.formatControlButtonClicked
        if (this.formatControlButtonClicked) {
          const iconPosition = this.$refs.formatsIcon.$el.getBoundingClientRect()
          this.$emit('openFormatControlsMenu', this.formatControlButtonClicked, iconPosition.top, iconPosition.left)
        } else {
          this.$emit('openFormatControlsMenu', this.formatControlButtonClicked)
        }
      }
    },
    settingsIconClick ($event) {
      if (!this.schedulerFullScreen) {
        this.settingsButtonClicked = !this.settingsButtonClicked
        if (this.settingsButtonClicked) {
          const iconPosition = this.$refs.settingsIcon.$el.getBoundingClientRect()
          this.$emit('openSettingsMenu', this.settingsButtonClicked, iconPosition.top, iconPosition.left)
        } else {
          this.$emit('openSettingsMenu', this.settingsButtonClicked)
        }
      }
    },
    settingsCogClick ($event) {
      this.$emit('settingsCogClick')
    },
    addUserIconClick ($event) {
      this.$emit('addUserIconClick')
    }
  },
  watch: {
    composerView: {
      handler () {
        this.placeholderText = 'Type to find tasks'
        this.taskSearch = true
      }
    },
    focused: {
      handler () {
        if (this.focused) {
          // console.log('Appsection - focused')
          this.edit()
        }
      },
      immediate: true
    },
    openFormatControls: {
      handler () {
        this.formatControlButtonClicked = this.openFormatControls
      }
    },
    openSettings: {
      handler () {
        this.settingsButtonClicked = this.openSettings
      }
    }
  }
}
</script>

<style scoped>

.app-section-wrapper.fullscreen {
  position:fixed;
  z-index: 3;
  left:0;
  width:100%;
  height:calc(100vh - 78px);
  top: 110px;
}

.fullscreen .app-section .section-content {
  padding-bottom: 71px; /*footer height for scroll*/
}
.scheduler-fullscreen-icon {
  z-index: 1;
}
.schedule-app-layout .title-content {
  justify-content: flex-end;
}
.schedule-app-layout .title-content p.mdc-typography--headline6 {
  margin-top: 18px;
  margin-left: -10px;
  margin-right: 15px;
}
.app-section-wrapper.fullscreen .app-section {
  height:100%;
  max-width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.app-section-wrapper.fullscreen .section-content-wrapper {
  overflow: auto;
}

.fullscreen .section-content-wrapper::-webkit-scrollbar-track {
  background: none;
}

.app-section.non-edit .title-content {
  transform: translateX(-16px);
  background-color: transparent;
}
.app-section-wrapper {
  position: relative;
  height: 100%;
}

.app-section .section-title:not(:empty) {
  background-color: transparent;
  align-items: center;
  display:flex;
  border-bottom:0;
  z-index:10;
  padding: 8px;
}
.app-section .title-content {
  flex:1;
  transition: transform 500ms ease-out;
  display: flex;
  align-items: center;
}
.app-section .title-content .mdc-typography--headline6,
.app-section .title-content .title-label {
  margin-top: 2px;
  margin-bottom: 2px;
  color: #e1e1e1;
  font-size: 14px;
  padding: 0;
  font-weight: normal;
}
.app-section .title-content .title-label {
  max-width: 20px;
  padding: 0;
  min-width: 20px;
}

.app-section .section-content-wrapper {
  transition-property: height;
  transition-timing-function: ease-in-out;
  will-change: contents;
  overflow: visible;
}
.schedule-app-layout .app-section .section-content-wrapper {
  margin-top: 0px; /* z-index issue in title if margin applied */
}
.app-section .section-content {
  padding-bottom:16px;
}
.app-section {
  border: 0.5px solid #959EA7;
}
</style>
<style>
.scheduler-full-view .composer-app-layout .app-section .section-title {
  display: none;
}
.scheduler-full-view .schedule-app-layout.app-section-outer {
  overflow: hidden;
}
.scheduler-full-view .schedule-app-layout .section-content,
.expanded-view .composer-app-layout .composer {
  width: 51%;
  margin: auto;
}
.expanded-view.sidebar-view .composer-app-layout .composer {
  width: 75%;
}

.left-side .app-section.mdc-card {
  border-radius: 0px 10px 10px 0px;
  border-right: none;
}
.right-side .app-section.mdc-card {
  border-radius: 10px 0px 0px 10px;
}
.mentions-side-bar-app-layout .app-section.mdc-card {
  border-radius: 0;
  box-shadow: inset 5px 0px 29px rgba(0, 0, 0, 0.07);
}
@media (max-width: 700px){
 .left-side .app-section.mdc-card,
 .right-side .app-section.mdc-card {
    border-radius: 10px;
  }
}
</style>

<style lang="scss">
.app-section {
  .section-title {
    .section-controls {
      display: flex;
      margin: 0;
      .icon-wrapper:not(:last-of-type) {
        margin-right: 12px;
      }
    }
    .scheduler-button {
      position: relative;
      margin-left: -8px;
    }
    .filter-button {
      margin: auto;
      .round-button {
        min-height: 20px;
        min-width: 20px;
        color: #B9BFC2;
        height: 20px;
        width: max-content;
        margin: 0;
        &:hover {
          box-shadow: none;
          border: transparent;
          background: transparent !important;
        }
      }
    }
    .icon-wrapper {
      width: 16px;
      height: 16px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
.app-section-search {
  flex: 1;
  width: 220px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  input::placeholder {
    opacity: 1 !important;
    color: #BDBDBD !important;
    font-size: 14px;
    font-weight: 600;
  }
  .autocomplete-results {
    position:absolute;
    margin-left: 0px;
    width: 100%;
    margin-top: 45px;
    left: 0;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);
    border: none;
    li.autocomplete-result {
      color: #4175B1;
      font-weight: 600;
      font-size: 14px;
      padding: 9px 15px;
      border-left: none !important;
      &:hover {
        background-color: #EBF3FC !important;
        border: none !important;
        border-left: none !important;
      }
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #828282;
    }
  }
  .autocomplete.mdc-textfield-wrapper {
    margin: 0px !important;
    padding-top: 0;
    min-height: 40px !important;
    .mdc-text-field {
      margin: 0 !important;
      min-height: 40px !important;
      .autocomplete-input {
        height: 38px;
        margin: 0;
        caret-color: rgba(0, 0, 0, 0.54);
        background-color: #F8F7F7;
        padding-left: 12px;
        padding-right: 25px;
        max-width: calc(100% - 37px);
        border-radius: 4px;
      }
    }
  }
}
.composer-app-layout {
  .search-wrapper {
    position: absolute;
    right: 40px;
    top: 25px;
  }
}
.expanded-view {
  .composer-app-layout {
    .search-wrapper {
      margin-left: calc(100vw - 51%);
      top: 25px;
      right: unset;
    }
    .app-section-wrapper {
      &.mentions-shown {
        .search-wrapper {
          top: 70px;
        }
      }
    }
  }
  &.sidebar-view {
    .composer-app-layout {
      .search-wrapper {
        margin-left: auto;
        top: 70px;
        right: 40px;
      }
      .composer {
        .sub-section-types {
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
