<template>
<div class="settings-wrapper">
  <app-layout key="initiativeLayout">
        <div class="two-col-layout" slot="layout-content" v-bind:class="{'notification-mode': notificationMode === 'on'}">
          <app-section
            class="settings-side-headers"
            :editable="false">
            <div class="settings-side-headings-wrapper">
              <header class="settings-heading">
                <base-svg-icon name="settings-cog-red" />
                <mdc-text tag="span" typo="headline6">Settings</mdc-text>
              </header>
              <settings-side-headings @sideHeadingClick="setSideHeadingType" :sideHeadingClicked="sideHeadingType" />
            </div>
          </app-section>
          <app-section
            class="settings-contents"
            :editable="false">
            <settings-type-content :settingHeadingType="sideHeadingType" />
          </app-section>
        </div>
  </app-layout>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppLayout from '../../components/flexibly/AppLayout'
import AppSection from '../../components/flexibly/AppSection'
import BaseSvgIcon from '../../components/flexibly/BaseSvgIcon.vue'
import SettingsSideHeadings from '../../components/flexibly/SettingsSideHeadings.vue'
import SettingsTypeContent from '../../components/flexibly/SettingsTypeContent.vue'
export default {
  name: 'Settings',
  data () {
    return {
      sideHeadingType: {}
    }
  },
  components: {
    AppLayout,
    AppSection,
    BaseSvgIcon,
    SettingsSideHeadings,
    SettingsTypeContent
  },
  computed: {
    ...mapState([
      'notificationMode'
    ])
  },
  methods: {
    ...mapActions([
    ]),
    setSideHeadingType (value) {
      this.sideHeadingType = value
    }
  },
  beforeDestroy () {
  },
  mounted () {
    this.$emit('setNavActive', 'Settings')
    const section = (this.$route) ? this.$route.params.section : null
    if (section) {
      // only allow profile types from route params
      this.setSideHeadingType({ type: 'profile', subType: section })
    }
  },
  watch: {}
}
</script>

<style lang="scss">
.settings-wrapper {
  .two-col-layout {
    // width: calc(100% - 80px);
    margin: 0px 40px 30px 40px;
    display: grid;
    // grid-template-columns: 400px auto;
    grid-template-columns: 1fr 2fr;
    background: var(--app-background);
    height: 100%;
    min-height: 678px;
    max-width: 1512px;
    &.notification-mode {
      background: #fff;
    }
    .settings-side-headers {
      .settings-side-headings-wrapper {
        margin: 65px 50px 65px 120px;
        display: flex;
        flex-direction: column;
        .settings-heading {
          display: flex;
          align-items: baseline;
          margin-bottom: 40px;
          .mdc-text {
            color: #959EA7;
            font-size: 24px;
            font-weight: 700;
            margin-left: 3px;
          }
          .icon-wrapper {
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .settings-contents {}
    .section-content {
    }
  }
}

</style>
