import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import fb from '@/store/firebase'

// pages
import Start from '@/pages/Start'
import Consent from '@/pages/Consent'
import Email from '@/pages/Email'
import Cookies from '@/pages/Cookies'
import EmailVerify from '@/pages/EmailVerify'
import Help from '@/pages/Help'
import Legal from '@/pages/Legal'
import Terms from '@/pages/Terms'

// Onboarding Pages
import Signin from '@/pages/SigninOnboarding'
import Join from '@/pages/Join'

// flexibly
import Dashboard from '@/pages/flexibly/Dashboard'
import Initiatives from '@/pages/flexibly/Initiatives'
import InitiativeDetails from '@/pages/flexibly/InitiativeDetails'
import People from '@/pages/flexibly/People'
import Onboarding from '@/pages/flexibly/Onboarding'
import Analytics from '@/pages/flexibly/Analytics'
import Settings from '@/pages/flexibly/Settings'

Router.prototype.open = function (routeObject) {
  const { href } = this.resolve(routeObject)
  window.open(href, '_blank')
}

Vue.use(Router)

const checkWorkspaceTeam = (to, from, next, user) => {
  const claims = user.claims
  if (claims.workspace && claims.team) {
    if (to.name === 'Join') {
      return next('/people')
    }
    return next()
  } else {
    if (to.name === 'Join') {
      return next()
    }
    return next('/join')
  }
}

const guard = async (to, from, next) => {
  if (store.state.profile) {
    return checkWorkspaceTeam(to, from, next, store.state.user)
  }

  fb.auth.onAuthStateChanged(async (user) => {
    if (to.name === 'SigninOnboarding' || to.name === 'SignupOnboarding' || to.name === 'SignupOnboardingInvite') {
      return next()
    }
    if (user) {
      const idTokenResult = await user.getIdTokenResult()
      user.claims = idTokenResult.claims
      checkWorkspaceTeam(to, from, next, user)
    } else {
      const signurl = '/signin'
      return next(signurl)
    }
  })
}

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'Start', component: Start },
    { path: '/terms', name: 'Terms', component: Terms, meta: { nomenu: true, loggedOutDrawer: true } },
    { path: '/consent', name: 'Consent', component: Consent, meta: { nomenu: true } },
    { path: '/email', name: 'Email', component: Email, meta: { nomenu: true } },
    { path: '/cookies', beforeEnter: guard, name: 'Cookies', component: Cookies, meta: { nomenu: true } },
    { path: '/email-verify', name: 'EmailVerify', component: EmailVerify, meta: { nomenu: true, nodrawer: true } },

    /*  flexibly pages  */
    { path: '/dashboard', beforeEnter: guard, name: 'Dashboard', component: Dashboard, meta: { nodrawer: true }, props: (route) => ({ query: route.query.q }) },
    { path: '/initiativedetails/:id', beforeEnter: guard, name: 'InitiativeDetails', component: InitiativeDetails, meta: { nodrawer: true } },
    { path: '/initiatives', beforeEnter: guard, name: 'Initiatives', component: Initiatives, meta: { nodrawer: true }, props: (route) => ({ query: route.query.q }) },

    { path: '/onboarding', beforeEnter: guard, name: 'Onboarding', component: Onboarding, meta: { nodrawer: true }, props: (route) => ({ query: route.query.q }) },

    { path: '/people', beforeEnter: guard, name: 'People', component: People, meta: { nodrawer: true }, props: (route) => ({ query: route.query.q }) },
    { path: '/people/:action/:week', beforeEnter: guard, name: 'ScheduleAction', component: People, meta: { nodrawer: true } },

    { path: '/analytics', beforeEnter: guard, name: 'Analytics', component: Analytics, meta: { nodrawer: true }, props: (route) => ({ query: route.query.q }) },

    { path: '/settings', beforeEnter: guard, name: 'Settings', component: Settings, meta: { nodrawer: true } },
    { path: '/settings/:section', beforeEnter: guard, name: 'Setting', component: Settings, meta: { nodrawer: true } },

    { path: '/help', beforeEnter: guard, name: 'Help', component: Help },
    { path: '/legal', beforeEnter: guard, name: 'Legal', component: Legal },
    { path: '/join', beforeEnter: guard, name: 'Join', component: Join, meta: { nomenu: true, signin: true } },

    { path: '/signin', name: 'SigninOnboarding', component: Signin, meta: { nomenu: true, signin: true } },
    { path: '/signup', name: 'SignupOnboarding', component: Signin, meta: { nomenu: true } },
    { path: '/signup/:invite', name: 'SignupOnboardingInvite', component: Signin, meta: { nomenu: true } },

    { path: '*', name: 'StartAll', component: Start }

  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 120 }
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const trackPage = async (profile, workspace) => {
    // eslint-disable-next-line no-undef
    if (typeof analytics !== 'undefined') {
      let integrations = { All: true }
      if (store.state.profile && !store.state.profile.cookiesPerf) {
        integrations = {
          All: false,
          FullStory: true,
          'Google Analytics': false,
          'Google Tag Manager': false
        }
      }
      if (store.state.profile && !store.state.profile.cookiesFunc) {
        integrations['Customer.io'] = false
      }
      // eslint-disable-next-line no-undef
      await analytics.identify(profile.id, {
        ws: workspace.domainUrl
      }, {
        integrations
      })
      // eslint-disable-next-line no-undef
      await analytics.page(to.path, {}, {
        integrations
      })
    } else {
      setTimeout(trackPage, 1000)
    }
  }

  if (store.state.profile) {
    if (store.state.workspace && store.state.workspace.isTracking) {
      trackPage(store.state.profile, store.state.workspace)
    }
  }
  next()
})

export default router
