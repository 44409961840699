<template>
  <div class="overview-details">
    <div class="calendar-scrim" v-if="showCalendar" @click="calendarClose(false)"></div>
    <div class="menu-scrim" v-if="showStatusMenu" @click="menuClose(false)"></div>
    <section class="overview-cards-grid">
      <div class="card-wrapper">
        <mdc-text tag="span" typo="caption" class="title">TASKS</mdc-text>
        <div class="tasks-count-details">
          <span class="detail">
            <mdc-text typo="headline6" tag="span" class="list-count">{{tasksLists.length}}</mdc-text>
            <mdc-text tag="span" typo="caption">Lists</mdc-text>
          </span>
          <span class="detail">
            <mdc-text typo="headline6" tag="span" class="tasks-count">{{getTasksListsCount}}</mdc-text>
            <mdc-text tag="span" typo="caption">Total Tasks</mdc-text>
          </span>
          <span class="detail">
          </span>
          <span class="detail">
            <mdc-text typo="headline6" tag="span" class="completed-count">{{getTasksCompleted}}</mdc-text>
            <mdc-text tag="span" typo="caption">Completed</mdc-text>
          </span>
          <span class="detail">
            <mdc-text typo="headline6" tag="span" class="incomplete-count">{{getTasksIncomplete}}</mdc-text>
            <mdc-text tag="span" typo="caption">Incomplete</mdc-text>
          </span>
          <span class="detail">
            <mdc-text typo="headline6" tag="span" class="overdue-count">{{getOverDueTasks}}</mdc-text>
            <mdc-text tag="span" typo="caption">Overdue</mdc-text>
          </span>
        </div>
      </div>
      <!-- <div class="card-wrapper">
        <mdc-text tag="span" typo="caption" class="title">UPDATES</mdc-text>
        <div class="updates-details">
        </div>
      </div> -->
      <div class="card-wrapper">
        <calendar
          disablePastDates
          id="initiative-due"
          :displayCalendar="showCalendar"
          showPresets
          weekPresets
          class="overview-calendar"
          @selected="dateSelected"></calendar>
        <div class="due-date-details">
          <div class="title-wrapper">
            <mdc-text tag="span" typo="caption" class="title">DUE DATE</mdc-text>
            <mdc-text v-if="overviewInitiativeDetails.dueUpdatedOn" tag="span" typo="caption" class="updated-on">Updated {{ getFormat(overviewInitiativeDetails.dueUpdatedOn, 'updatedOn') }}</mdc-text>
          </div>
          <div class="date-changetext-wrapper">
            <div class="date-wrapper">
              <mdc-text role="date" v-if="overviewInitiativeDetails.due" typo="headline6" class="due-on">{{ getFormat(overviewInitiativeDetails.due) }}</mdc-text>
              <mdc-text v-else typo="headline6" class="due-on">Not Set</mdc-text>
            </div>
            <div class="calendar-wrapper">
              <mdc-text class="change-due" tag="span" typo="caption" @click.native="changeDueClick">Change</mdc-text>
            </div>
          </div>
        </div>
      </div>
      <div class="card-wrapper">
        <mdc-list class="status-items" v-if="showStatusMenu">
          <mdc-list-item v-bind:class="item.status" v-for="(item, index) in statuses" :key="item.status + index" @click.native="setStatusSelected(item.name, index)">{{item.name}}</mdc-list-item>
        </mdc-list>
        <div class="status-details">
          <div class="title-wrapper">
            <mdc-text tag="span" typo="caption" class="title">STATUS</mdc-text>
            <mdc-text v-if="overviewInitiativeDetails.statusUpdatedOn" tag="span" typo="caption" class="updated-on">Updated {{ getFormat(overviewInitiativeDetails.statusUpdatedOn, 'updatedOn') }}</mdc-text>
          </div>
          <div class="status-updatetext-wrapper">
            <div class="status-wrapper">
              <mdc-text role="status" v-bind:class="overviewInitiativeDetails.status.replace(' ','').toLowerCase()" v-if="overviewInitiativeDetails.status" typo="headline6" class="status-text">{{ overviewInitiativeDetails.status }}</mdc-text>
              <mdc-text v-else typo="headline6" class="status-text">Not Set</mdc-text>
            </div>
            <div class="menu-wrapper">
              <mdc-text role="link" class="update-status" tag="span" typo="caption" @click.native="updateStatusClick">Update</mdc-text>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="overviewInitiativeDetails.members" class="description-wrapper">
      <div class="label-wrapper">
        <label>WHAT’S IT ABOUT?</label>
        <mdc-text v-if="overviewInitiativeDetails.members.includes(profile.id)" tag="span" typo="caption" @click.native="editDescription">{{ descEditMode ? 'Save' : 'Edit' }} description</mdc-text>
      </div>
      <div class="description-text">
        <mdc-text v-if="!descEditMode" tag="span" typo="subtitle2">{{ overviewInitiativeDetails.description }}</mdc-text>
        <textarea v-model="descriptionEdit" v-else placeholder="Type a description for your initiative, so others can understand what it is about." />
      </div>
    </section>
    <section v-if="overviewInitiativeDetails.members" class="initiative-members-wrapper">
      <div class="label-button-wrapper">
        <label>INITIATIVE MEMBERS</label>
        <mdc-button v-if="overviewInitiativeDetails.members.includes(profile.id)" @click.native="addMemberClick" class="add-member-button">Add A MEMBER</mdc-button>
        <mdc-button v-else-if="overviewInitiativeDetails.visibility === 'Team' || overviewInitiativeDetails.visibility === 'Workspace'" @click.native="addSelfInitiativeMember" style="width:100px" class="add-member-button">JOIN</mdc-button>
      </div>
      <div class="members-list" v-if="overviewInitiativeDetails.members && overviewInitiativeDetails.members.length > 0">
        <profile-initials v-for="(member, index) in overviewInitiativeDetails.members" :key="member + index" :profileId="member" :date="appDate" showDisplayName />
      </div>
    </section>
    <modal-dialog
      v-if="initiativeDetailsMembersCopy"
      v-model="addNewMember"
      :pauseFocus="isMobile"
      class="add-member-modal"
      @save="addInitiativeMember"
      :saveText="'DONE'"
      :disableSave="initiativeDetailsMembersCopy && initiativeDetailsMembersCopy.length === 0"
      >
      <div class="fields">
        <div v-if="profiles" class="add-members-wrapper">
          <label>ADD NEW MEMBERS</label>
          <div>
            <v-select :dropdown-should-open="dropdownShouldOpen" v-model="memberInput" ref="memberSelect" placeholder="Start typing a name" class="members-select" :options="profiles.filter(profile => initiativeDetailsMembersCopy != null && !initiativeDetailsMembersCopy.includes(profile.id))" :reduce="profile => profile.id" label="displayName" @input="setSearchedMember">
              <template slot="open-indicator">
                <mdc-icon class="members-search-icon" icon="search" filled/>
              </template>
              <template #selected-option="{ displayName }">
                <strong>{{ displayName }}</strong>
              </template>
            </v-select>
            <mdc-text class="help-text" tag="span" typo="caption">{{ helpText }}</mdc-text>
          </div>
        </div>
        <div class="selected-members-wrapper">
          <span class="members-list" v-for="(memberId) in initiativeDetailsMembersCopy" :key="memberId + 'initiativeDetailsMembersCopy'">
            <profile-initials noProfileDetailClick :profileId="memberId" :date="appDate" showNameAndInitial showYou />
            <mdc-icon icon="clear" :class="initiativeDetailsMembersCopy.length > 1 ? 'enabled' : 'disabled'"  class="clear-member" @click.native="setSelectedMember(memberId, 'fromClear')" />
          </span>
        </div>
        <div class="quick-select-wrapper">
          <div class="quick-select-heading">
            <label>Quick select team members:</label>
            <mdc-text v-if="reducedTeamProfiles().length > membersDisplayCount" typo="body2" tag="span" class="show-all-text" @click.native="showAllTeamMatesClick()">{{ !showAllMembersClicked ? 'Show all' : 'Show less' }}</mdc-text>
          </div>
          <div class="select-team">
            <template v-if="showAllMembersClicked">
              <profile-initials noProfileDetailClick @click.native="setSelectedMember(profile.id)" v-for="profile in reducedTeamProfiles()" :key="profile.id" :profileId="profile.id" :date="appDate" showNameAndInitial />
            </template>
            <template v-else>
              <profile-initials noProfileDetailClick @click.native="setSelectedMember(profile.id)" v-for="profile in reducedTeamProfiles().slice(0, membersDisplayCount)" :key="profile.id" :profileId="profile.id" :date="appDate" showNameAndInitial />
            </template>
          </div>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import ProfileInitials from '../../components/flexibly/ProfileInitials'
import Calendar from './Calendar.vue'
import ModalDialog from '../../components/flexibly/ModalDialog.vue'

export default {
  name: 'InitiativeOverview',
  components: {
    ProfileInitials,
    Calendar,
    ModalDialog
  },
  props: {
    tasksLists: {
      type: Array,
      default: () => {
        return []
      }
    },
    initiativeDetails: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.overviewInitiativeDetails = { ...this.initiativeDetails } // from props to local data
    this.initiativeDetailsMembersCopy = [...this.overviewInitiativeDetails.members] // for modal
  },
  computed: {
    ...mapState([
      'profile',
      'appDate'
    ]),
    ...mapState({
      teamProfiles: state => state.teams.teamProfiles,
      profiles: state => state.profiles
    }),
    getTasksListsCount () {
      let allTasks = 0
      this.tasksLists.forEach(taskList => {
        if (taskList.tasks.length > 0) {
          allTasks = allTasks + taskList.tasks.length
        }
      })
      return allTasks
    },
    getTasksCompleted () {
      let allTasks = 0
      this.tasksLists.forEach(taskList => {
        if (taskList.tasks.length > 0) {
          allTasks = allTasks + taskList.tasks.filter(task => task.completed).length
        }
      })
      return allTasks
    },
    getTasksIncomplete () {
      let allTasks = 0
      this.tasksLists.forEach(taskList => {
        if (taskList.tasks.length > 0) {
          allTasks = allTasks + taskList.tasks.filter(task => !task.completed).length
        }
      })
      return allTasks
    },
    getOverDueTasks () {
      let allTasks = 0
      this.tasksLists.forEach(taskList => {
        if (taskList.tasks.length > 0) {
          allTasks = allTasks + taskList.tasks.filter(task => task.due && moment(task.due.toDate()).diff(moment(), 'days') < 0).length
        }
      })
      return allTasks
    }
  },
  data () {
    return {
      membersDisplayCount: 9,
      showAllMembersClicked: false,
      helpText: '',
      memberInput: null,
      descEditMode: false,
      overviewInitiativeDetails: {},
      showCalendar: false,
      showStatusMenu: false,
      statuses: [{
        name: 'ON TRACK',
        status: 'ontrack'
      },
      {
        name: 'AT RISK',
        status: 'atrisk'
      },
      {
        name: 'OFF TRACK',
        status: 'offtrack'
      },
      {
        name: 'ON HOLD',
        status: 'onhold'
      },
      {
        name: 'COMPLETED',
        status: 'completed'
      }],
      addNewMember: false,
      initiativeDetailsMembersCopy: null,
      descriptionEdit: ''
    }
  },
  watch: {
    descEditMode: {
      handler (newMode, oldMode) {
        if (!newMode) {
          this.overviewInitiativeDetails.description = this.descriptionEdit
          this.saveInitiative({ initiativeId: this.overviewInitiativeDetails.id, initiative: this.overviewInitiativeDetails })
        } else {
          this.descriptionEdit = this.overviewInitiativeDetails.description
        }
      }
    }
  },
  methods: {
    ...mapActions({
      saveInitiative: 'teams/saveInitiative'
    }),
    showAllTeamMatesClick () {
      this.showAllMembersClicked = !this.showAllMembersClicked
    },
    dropdownShouldOpen (VueSelect) {
      if (this.memberInput !== null) {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    addSelfInitiativeMember () {
      if (this.initiativeDetails.visibility === 'Team' || this.initiativeDetails.visibility === 'Workspace') {
        this.initiativeDetailsMembersCopy = [...this.overviewInitiativeDetails.members]
        if (!this.initiativeDetailsMembersCopy.includes(this.profile.id)) {
          this.initiativeDetailsMembersCopy.push(this.profile.id)
          this.addInitiativeMember()
        }
      }
    },
    addInitiativeMember () {
      this.overviewInitiativeDetails = { ...this.overviewInitiativeDetails, members: this.initiativeDetailsMembersCopy }
      this.saveInitiative({ initiativeId: this.overviewInitiativeDetails.id, initiative: this.overviewInitiativeDetails })
    },
    setSelectedMember (profileId, from) {
      this.helpText = ''
      if (from === 'fromClear') {
        if (this.initiativeDetailsMembersCopy.length === 1) {
          this.helpText = 'Initiatives require atleast one member'
          return
        }
      }
      const memberAtIndex = this.initiativeDetailsMembersCopy.findIndex(teamMateId => teamMateId === profileId)
      if (memberAtIndex !== -1) {
        this.initiativeDetailsMembersCopy.splice(memberAtIndex, 1)
      } else {
        this.initiativeDetailsMembersCopy.push(profileId)
      }
    },
    setSearchedMember (value) {
      if (value) {
        this.setSelectedMember(value)
        this.$refs.memberSelect.clearSelection()
      }
    },
    reducedTeamProfiles () {
      if (this.initiativeDetailsMembersCopy) {
        return this.teamProfiles.filter(profile => !this.initiativeDetailsMembersCopy.includes(profile.id))
      }
      return this.teamProfiles
    },
    addMemberClick () {
      this.initiativeDetailsMembersCopy = [...this.overviewInitiativeDetails.members] // for modal
      this.addNewMember = true
    },
    editDescription () {
      this.descEditMode = !this.descEditMode
    },
    getFormat (date, from) {
      let dateToFormat = moment(date)
      if (date && date.seconds) {
        dateToFormat = moment(date.toDate())
      }
      if (from === 'updatedOn') {
        return dateToFormat.format('D MMM')
      }
      return dateToFormat.format('Do MMMM')
    },
    dateSelected (dated) {
      if (dated) {
        this.overviewInitiativeDetails.due = dated.toDate()
        this.overviewInitiativeDetails.dueUpdatedOn = moment().toDate()
        this.calendarClose(false)
        this.saveInitiative({ initiativeId: this.overviewInitiativeDetails.id, initiative: this.overviewInitiativeDetails })
      }
    },
    setStatusSelected (status, index) {
      this.menuClose(false)
      this.overviewInitiativeDetails.status = status
      this.overviewInitiativeDetails.statusUpdatedOn = moment().toDate()
      this.saveInitiative({ initiativeId: this.overviewInitiativeDetails.id, initiative: this.overviewInitiativeDetails })
    },
    changeDueClick () {
      this.menuClose(false)
      this.calendarClose(false)
      this.$nextTick(() => {
        this.showCalendar = true
        const self = this
        setTimeout(function () {
          self.positionPopups('calendar')
          self.animatePopups('calendar')
        }, 100)
      })
    },
    updateStatusClick () {
      this.calendarClose(false)
      this.menuClose(false)
      this.$nextTick(() => {
        this.showStatusMenu = true
        const self = this
        setTimeout(function () {
          self.positionPopups('status-menu')
          self.animatePopups('status-menu')
        }, 100)
      })
    },
    calendarClose (value) {
      const calendarElement = document.querySelector('.overview-calendar')
      if (calendarElement) {
        calendarElement.style.removeProperty('top')
        calendarElement.style.removeProperty('opacity')
      }
      this.showCalendar = value
    },
    menuClose (value) {
      const statusMenu = document.querySelector('.status-items')
      if (statusMenu) {
        statusMenu.style.removeProperty('top')
        statusMenu.style.removeProperty('opacity')
      }
      this.showStatusMenu = value
    },
    positionPopups (type) {
      const fromElement = type === 'calendar' ? document.querySelector('.change-due') : document.querySelector('.update-status')
      const fromElementOffsetTop = fromElement ? fromElement.offsetTop : 0
      const popupElement = type === 'calendar' ? document.querySelector('.overview-calendar') : document.querySelector('.status-items')
      if (popupElement) {
        if (!this.isInViewport(popupElement)) {
          popupElement.style.top = `${fromElementOffsetTop - popupElement.clientHeight}px`
        } else {
          popupElement.style.top = `${fromElementOffsetTop + 20}px`
        }
      }
    },
    animatePopups (type) {
      const popupElement = type === 'calendar' ? document.querySelector('.overview-calendar') : document.querySelector('.status-items')
      if (popupElement) {
        popupElement.style.transition = 'opacity 0.09s ease-in'
        popupElement.style.opacity = 1
      }
    },
    isInViewport (element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }
  }
}
</script>

<style scoped lang="scss">
$color-lists: #00498D;
$color-ontrack: #01CB52; /**ontrack */
$color-incomplete: #FFB11B; /**atrisk */
$color-overdue: #FF0F00; /**offtrack */
$color-onhold: #A0A0A0;
$color-completed: #404040;

.overview-details {
  .overview-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 245px);
    gap: 15px;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
    .card-wrapper {
      color: #333;
      background: #ffffff;
      border: 0.5px solid #BDBDBD;
      border-radius: 10px;
      min-height: 100px;
      max-height: 120px;
      display: flex;
      flex-direction: column;
      padding: 10px 10px 5px 10px;
      position: relative;
      .ontrack {
        color: $color-ontrack;
      }
      .completed {
        color: $color-completed;
      }
      .atrisk {
        color: $color-incomplete;
      }
      .onhold {
        color: $color-onhold;
      }
      .offtrack {
        color: $color-overdue;
      }
      .title {
        color: #828282;
        margin-right: 10px;
      }
      .tasks-count-details {
        display: grid;
        grid-template-columns: auto auto auto;
        .detail {
          display: flex;
          flex-direction: column;
          font-size: 10px;
          .mdc-text.mdc-typography--headline6 {
            line-height: 27px;
            &.list-count,
            &.tasks-count {
              color: $color-lists;
            }
            &.completed-count {
              color: $color-ontrack;
            }
            &.incomplete-count {
              color: $color-incomplete;
            }
            &.overdue-count {
              color: $color-overdue;
            }
          }
          .mdc-text.mdc-typography--caption {
            font-size: 10px;
          }
        }
      }
      .due-date-details {
        display: flex;
        flex-direction: column;
        .date-changetext-wrapper {
          background: #FAFAFA;
          height: 85px;
          margin-top: 2px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .date-wrapper {
            .due-on {
              color: #404040;
              text-align: center;
              margin: 0;
              margin-top: 20px;
            }
          }
          .calendar-wrapper {
            display: inline-flex;
            justify-content: flex-end;
            .mdc-text {
              color: #00498D;
              cursor: pointer;
            }
          }
        }
      }
      .status-details {
        display: flex;
        flex-direction: column;
        .status-updatetext-wrapper {
          background: #FAFAFA;
          height: 85px;
          margin-top: 2px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .status-wrapper {
            color: #404040;
            .status-text {
              text-align: center;
              margin: 0;
              margin-top: 20px;
            }
          }
          .menu-wrapper {
            display: inline-flex;
            justify-content: flex-end;
            .mdc-text {
              color: #00498D;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .description-wrapper {
    color: #404040;
    .label-wrapper {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      label {
        margin-right: 10px;
        font-size: 18px;
        text-transform: uppercase;
      }
      .mdc-text.mdc-text.mdc-typography--caption {
        color: $color-lists;
        cursor: pointer;
      }
    }
    .description-text {
      line-height: 23px;
      min-height: 50px;
      width: 90%;
      word-break: break-word;
      textarea {
        width: 90%;
        resize: none;
        color: #404040;
        border: none;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 20px;
        height: 80px;
        max-height: 80px;
        border: 0.5px solid #BDBDBD;
        border-radius: 5px;
        padding: 10px;
        &::placeholder {
          color: #A0A0A0;
          font-weight: 500;
        }
      }
    }
  }
  .initiative-members-wrapper {
    color: #404040;
    margin-top: 20px;
    .label-button-wrapper {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      label {
        margin-right: 20px;
        font-size: 18px;
        text-transform: uppercase;
      }
      .add-member-button {
        width: 165px;
        height: 30px;
        background: #FFFFFF;
        border: 0.5px solid #A0A0A0;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        color: #4F4F4F;
        &:before {
          content: '+';
          font-size: 25px;
          color: #4F4F4F;
          margin-right: 5px;
          margin-top: -3px;
          opacity: 0.6;
        }
      }
    }
    .members-list {
      display: grid;
      grid-gap: 25px;
      grid-template-columns: repeat(auto-fit, 200px);
      .profile-redirect {
        .profile-display-name {
          font-size: 14px;
          color: #404040;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.overview-details {
  .members-list {
    .profile-redirect {
      .profile-display-name {
        font-size: 14px;
        color: #404040 !important;
      }
    }
  }
  .add-member-modal {
    .help-text {
      color: #FF0F00;
    }
    .mdc-dialog__surface {
      width: 650px;
      max-width: 680px;
      @media only screen and (max-width: 645px) {
        max-width: calc(100vw - 32px);
        width: auto;
      }
    }
    .mdc-dialog__content {
      padding-bottom: 30px;
      padding-top: 0;
      .title-wrapper {
        justify-content: flex-end;
        margin-bottom: 0;
        .close-modal {
          padding-right: 12px;
        }
      }
    }
    .add-members-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 20px;
      padding-left: 5px;
      label {
        font-size: 16px;
        color: #404040;
        font-weight: 600;
      }
      .members-select {
        max-width: 350px;
        width: 200px;
        .vs__dropdown-toggle {
          // border-radius: 20px;
          border: 0.5px solid transparent;
          background: #F8F7F7;
          .vs__search {
            padding-left: 20px;
            font-size: 14px;
            color: #404040;
            font-weight: 600;
            &::placeholder {
              color: #BDBDBD;
              font-size: 14px;
              font-weight: 600;
            }
          }
          .vs__actions {
            display: none;
          }
        }
        .vs__dropdown-menu {
          background: #FEFEFE;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);
          border-radius: 6px;
          width: 180px;
          padding: 0;
          li {
            color: #4175B1;
            font-size: 14px;
            &.vs__dropdown-option--highlight {
              background: #EBF3FC;
            }
          }
        }
      }
    }
    .selected-members-wrapper {
      margin-bottom: 20px;
      display: inline-flex;
      min-height: 25px;
      flex-flow: wrap;
      .members-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 25px;
        padding: 5px 0 5px 5px;
        border: 0.5px solid transparent;
        position: relative;
        margin-right: 10px;
        .profile-redirect {
          margin-right: 0px;
          .profile-firstname-initial {
            color: #4F4F4F !important;
            font-size: 12px;
            font-weight: 600;
          }
          .you-text {
            color: #4F4F4F !important;
            font-size: 12px;
            font-weight: 600;
          }
        }
        .clear-member {
          display: none;
          opacity: 0;
          font-size: 19px;
          color: #cc0000;
          margin-top: -15px;
          cursor: pointer;
          &.disabled {
            color: #404040;
            opacity: 0.6 !important;
          }
        }
        &:hover {
          .clear-member {
            opacity: 1;
            display: block;
          }
          background: #FFFFFF;
          border: 0.5px solid rgba(141, 166, 195, 0.190887);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.50);
          border-radius: 2px;
        }
      }
    }
    .quick-select-wrapper {
      .quick-select-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px dashed #000000;
      }
      label {
        display: flex;
        padding-bottom: 3px;
        color: #4f4f4f;
      }
      .select-team {
        margin-top: 7px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .profile-redirect {
          max-height: 25px;
          height: 25px;
          width: max-content;
          justify-content: center;
          padding: 5px;
          margin-bottom: 5px;
          margin-right: 5px;
          border: 0.5px solid transparent;
          &:hover {
            background: #FFFFFF;
            border: 0.5px solid rgba(141, 166, 195, 0.190887);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.50);
            border-radius: 2px;
          }
          .profile-firstname-initial {
            color: #4F4F4F !important;
            font-size: 14px;
          }
        }
      }
    }
  }
  .overview-calendar {
    margin-left: -45px;
    z-index: 12;
    position: absolute;
    top: 100%;
  }
  .calendar-scrim,
  .menu-scrim {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .status-items {
    opacity: 0;
    position: absolute;
    top: 100%;
    right: 0px;
    background: #FEFEFE;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    li.mdc-list-item {
      height: 30px;
      font-size: 14px;
    }
  }
}
</style>
