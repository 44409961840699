<template>
  <div class="profile-details-dialog" :class="{'no-style': !dialogStyle, 'small-grids': showSmallGrids }" v-if="showDialog">
    <div class="dialog-wrapper" :class="profileDetails && profileDetails.profileId === profile.id ? 'self' : 'other'" role="contentinfo">
      <div class="profile-initials-name">
        <profile-initials :profileId="profileDetails.profileId" :workingAt="profileDetails.workingAt" showDisplayName />
        <mdc-icon-button class="close-icon" v-if="dialogStyle" @click.native="closeDialog()">clear</mdc-icon-button>
      </div>
      <div class="profile-weekly-schedules">
        <div class="week-nav-content">
          <mdc-text typo="caption" tag="span" class="week-nav-label" @click.native.stop="goToThisWeek()" v-bind:class="thisWeekClicked ? 'clicked' : ''">This week</mdc-text>
          <mdc-text typo="caption" tag="span" class="week-nav-label" @click.native.stop="gotoNextWeek()" v-bind:class="nextWeekClicked ? 'clicked' : ''">Next week</mdc-text>
        </div>
        <div class="profile-schedules-wrapper" :class="thisWeekClicked ? 'current-week' : nextWeekClicked ? 'next-week' : ''" role="grid">
          <template v-if="showSmallGrids">
            <div class="week-range">
              <mdc-text class="week-date-text" tag="span" typo="caption">Schedule {{ getDate(tableWeekDetails[0], 'D') }} - {{ getDate(tableWeekDetails[4], 'D MMM') }} </mdc-text>
            </div>
            <div class="smaller-version-wrapper" v-for="(profile,index) in allProfilesSchedulesWeekly" :key="profile.id + 'response' + index">
              <div class="schedule-details" v-for="(response, index) in profile.responses" :key="response.id + response.day + response.response + index" v-bind:class="[getBoxFillClassResponse(response.response, response.default)]">
                <span class="answer" :class="{ 'full-day' : response.type && response.type === 'full', 'split-day' : response.type && response.type === 'split', 'notset-day' : !response || !response.type }" :key="response.index + response.type + response.day">
                  <mdc-text typo="body1" tag="span" :key="response.day + index">
                    {{ response.day.substring(0,1) }}
                  </mdc-text>
                <!-- small grid based on type and response-->
                  <base-svg-icon v-if="response.type === 'full'" :name="getResponseIcon(response.response)" :class="[getWorkingAtClass(response.response), !response.defaultDay ? getBoxFillClassResponse(response.response, response.default) : '']" />
                  <div class="split-day-grid" v-else-if="response.type === 'split'">
                    <div class="am-wrapper slot-type" :class="!response.defaultDay ? getBoxFillClassResponse(response.amResponse, response.default) : ''">
                      <template v-if="response.amResponse">
                        <base-svg-icon :name="response.amResponse" />
                      </template>
                    </div>
                    <div class="pm-wrapper slot-type" :class="!response.defaultDay ? getBoxFillClassResponse(response.pmResponse, response.default) : ''">
                      <template v-if="response.pmResponse">
                        <base-svg-icon :name="response.pmResponse" />
                      </template>
                    </div>
                  </div>
                  <div class="notset-day notset-fill" v-else>
                    <base-svg-icon name="plus-rounded-bdbdbd" />
                  </div>
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="week-day-text-grid">
              <div v-if="scheduleDays">
                <div class="days-text-wrapper" v-for="(dayLabel, index) in tableWeekDetails" :key="dayLabel + index">
                  <mdc-text typo="caption" tag="span">{{ formatDate(dayLabel) }}</mdc-text>
                </div>
              </div>
            </div>
            <div class="responses-days-grid" v-bind:class="{ 'animate': allSchedulesLoaded }">
              <div v-for="(profile,index) in allProfilesSchedulesWeekly" :key="profile.id + 'response' + index">
                <div v-for="(response, index) in profile.responses" :key="response.id + response.day + response.response + index" v-bind:class="[getBoxFillClass(response)]">
                  <template v-if="response.type === 'full'">
                    <mdc-text class="not-set-text" tag="span" typo="caption" v-if="response.response === 'notset'">Not set</mdc-text>
                    <base-svg-icon v-else :name="getResponseIcon(response.response)"/>
                  </template>
                  <template v-else-if="response.type === 'split'">
                    <span class="answer split-day">
                      <div class="split-day-row">
                        <div class="am-wrapper slot-type" :class="getBoxFillClassResponse(response.amResponse, response.default)">
                          <template v-if="response.amResponse">
                            <base-svg-icon :name="response.amResponse" />
                          </template>
                        </div>
                        <div class="pm-wrapper slot-type" :class="getBoxFillClassResponse(response.pmResponse, response.default)">
                          <template v-if="response.pmResponse">
                            <base-svg-icon :name="response.pmResponse" />
                          </template>
                        </div>
                      </div>
                    </span>
                  </template>
                  <template v-else>
                    <mdc-text class="not-set-text" tag="span" typo="caption">Not set</mdc-text>
                  </template>
                </div>
              </div>
            </div>
        </template>
        </div>
      </div>
      <div class="you-and-profile-details" v-if="profileDetails.profileId !== profile.id">
         <mdc-text tag="span" class="you-profile-label" typo="caption">You & {{profileDetails.name.split(' ')[0]}}</mdc-text>
        <div class="same-space detail-row">
          <mdc-text tag="span" typo="caption">Last time in the same space</mdc-text>
          <mdc-text tag="span" typo="caption" class="value">{{ profileDetails.lastInLocation || 'Not recently'}}</mdc-text>
        </div>
        <div class="meeting-event detail-row">
          <mdc-text tag="span" typo="caption">Next meeting in calendar</mdc-text>
          <mdc-text tag="span" typo="caption" class="value">{{ profileDetails.nextMeeting || 'None scheduled'}}</mdc-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileInitials from './ProfileInitials.vue'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon'
import { getResponseIconName } from '../../utils/profile'
import { dayDateFormat } from '../../utils/time.js'
export default {
  name: 'ProfileDetailsDialog',
  components: {
    ProfileInitials,
    BaseSvgIcon
  },
  data () {
    return {
      thisWeekClicked: true,
      nextWeekClicked: false,
      weekDetail: {
        startOfWeek: moment().startOf('isoWeek'),
        endOfWeek: moment().endOf('isoWeek').subtract('2', 'days'),
        weekNumber: moment().add(1, 'weeks')
      },
      scheduleDays: [
        'MON',
        'TUE',
        'WED',
        'THU',
        'FRI'
      ],
      tableWeekDetails: [],
      allProfilesSchedulesWeekly: [],
      allSchedulesLoaded: false
    }
  },
  props: {
    showSmallGrids: {
      type: Boolean,
      default: false
    },
    profileDetails: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    dialogStyle: {
      type: Boolean,
      default: true
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  created () {},
  mounted () {
    this.calculateTableWeekDetails()
    this.loadWeekSchedules()
    // this.getLastInLoc()
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      defaultSchedules: state => state.teams.defaultSchedules,
      miniSchedules: state => state.teams.miniSchedules
    })
  },
  methods: {
    ...mapActions({
      loadMiniSchedules: 'teams/loadMiniSchedules',
      setDisplayProfile: 'setDisplayProfile',
      getLastSameLocation: 'teams/getLastSameLocation'
    }),
    getDate (date, format) {
      return dayDateFormat(date, format)
    },
    async getLastInLoc () {
      if (this.profileDetails) {
        const params = { users: [this.profile.id, this.profileDetails.profileId] }
        const lastInLoc = await this.getLastSameLocation(params)
        let lastInLocation = ''
        if (lastInLoc) {
          lastInLocation = lastInLoc.dateStr
        }
        this.profileDetails.lastInLocation = lastInLocation
      }
    },
    async getNextMeeting () {
      if (this.profileDetails) {
        const userEmail = this.profileDetails.msEmail ? this.profileDetails.msEmail : this.profileDetails.email
        let nextMeeting = null
        let nextMeetingDate = ''
        if (userEmail) {
          nextMeeting = await this.getNextMeetingWithUser({ userEmail })
          if (nextMeeting) {
            nextMeetingDate = moment(nextMeeting.startDateTime.toDate()).format('Do MMMM YYYY hh:mm A')
          }
        }
        console.log(nextMeetingDate)
        this.profileDetails.nextMeetingDate = nextMeetingDate
      }
    },
    dateFormat (date) {
      if (date && date.seconds) {
        return moment.unix(date.seconds).format('dddd, MMMM DD')
      }
    },
    timeFormat (date) {
      if (date && date.seconds) {
        return moment.unix(date.seconds).format('HH:mm')
      }
    },
    formatDate (date) {
      return moment(date).format('ddd D')
    },
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target) || event.target.classList.contains('people-profile-initials') || event.target.parentNode.classList.contains('people-profile-initials'))) {
        this.closeDialog()
      }
    },
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        this.closeDialog()
      }
    },
    getWorkingFromStmt () {
      if (this.profileDetails && (this.profileDetails.workingAt !== null && this.profileDetails.workingAt !== '' && this.profileDetails.workingAt !== undefined && this.profileDetails.workingAt.length !== 0)) {
        switch (this.profileDetails.workingAt && this.profileDetails.workingAt.toLowerCase()) {
          case 'home':
          case 'thirdspace':
            return 'remote'
          case 'office':
            return 'in the office'
          case 'nonworking':
            return 'not working'
          default:
            return ''
        }
      }
    },
    getWorkingAtClass (response) {
      if (response && response !== null && response !== '' && response !== undefined) {
        switch (response.toLowerCase()) {
          case 'home':
            return 'home'
          case 'office':
            return 'office'
          case 'nonworking':
            return 'nonworking'
          case 'thirdspace':
            return 'thirdspace'
          default:
            return ''
        }
      }
    },
    getWorkingAtText () {
      if (this.profileDetails && (this.profileDetails.workingAt !== null && this.profileDetails.workingAt !== '' && this.profileDetails.workingAt !== undefined && this.profileDetails.workingAt.length !== 0)) {
        switch (this.profileDetails.workingAt && this.profileDetails.workingAt.toLowerCase()) {
          case 'home':
          case 'thirdspace':
            return 'remote'
          case 'office':
            return 'office'
          case 'nonworking':
            return 'nonworking'
          default:
            return ''
        }
      }
    },
    getBoxFillClass (resp) {
      if (resp.default) {
        return 'notset-fill'
      }
      if (resp.addedByConfirmation) {
        return 'notset-fill'
      }
      if (resp.type === 'split') {
        return 'split-fill'
      }
      const response = resp.response
      if (response && response !== null && response !== '' && response !== undefined) {
        switch (response.toLowerCase()) {
          case 'home':
            return 'home-fill'
          case 'thirdspace':
            return 'thirdspace-fill'
          case 'office':
            return 'office-fill'
          case 'nonworking':
            return 'nonworking-fill'
          case 'notset':
            return 'notset-fill'
          default:
            return ''
        }
      } else {
        return 'notset-fill'
      }
    },
    getBoxFillClassResponse (response, fromDefault) {
      if (fromDefault) {
        return 'notset-fill'
      }
      switch (response) {
        case 'home':
          return 'home-fill'
        case 'thirdspace':
          return 'thirdspace-fill'
        case 'office':
          return 'office-fill'
        case 'nonworking':
          return 'nonworking-fill'
        case 'notset':
          return 'notset-fill'
        default:
          return ''
      }
    },
    getResponseIcon (response) {
      return getResponseIconName(response)
    },
    goToThisWeek () {
      this.weekDetail.startOfWeek = moment().startOf('isoWeek')
      this.weekDetail.endOfWeek = moment().endOf('isoWeek').subtract('2', 'days')
      this.weekDetail.weekNumber = moment().add(1, 'weeks')
      this.thisWeekClicked = true
      this.nextWeekClicked = false
    },
    gotoNextWeek () {
      this.weekDetail.startOfWeek = moment(this.weekDetail.startOfWeek).add(1, 'weeks')
      this.weekDetail.endOfWeek = moment(this.weekDetail.endOfWeek).add(1, 'weeks')
      this.weekDetail.weekNumber = moment(this.weekDetail.startOfWeek).week()
      this.thisWeekClicked = false
      this.nextWeekClicked = true
    },
    calculateTableWeekDetails () {
      this.tableWeekDetails = []
      for (let i = 1; i <= 5; i++) {
        this.tableWeekDetails.push(moment(this.weekDetail.startOfWeek).startOf('day').add(i - 1, 'days').toDate())
      }
    },
    closeDialog () {
      this.setDisplayProfile(false)
    },
    frameProfileSchedule (profileId) {
      const profileDefaultSchedule = [...this.defaultSchedules].filter((ds) => { return ds.fromId === this.profileDetails.profileId })
      const defaultSchedulesWeekly = profileDefaultSchedule[0] ? profileDefaultSchedule[0].schedules : {}
      const profileSchedulesWeekly = this.miniSchedules || []
      if (profileSchedulesWeekly) {
        const profilesDatedResponse = []
        const myDatedSchedule = profileSchedulesWeekly.filter(schedule => {
          if (schedule.fromId === profileId) {
            return true
          }
          return false
        })
        // console.log('frameProfileSchedule', profileId, myDatedSchedule, defaultSchedulesWeekly)

        this.tableWeekDetails.map(date => {
          const day = moment(date).format('ddd').toUpperCase()
          const datedSchedule = myDatedSchedule[0] || {}
          const scheduleForDay = datedSchedule.schedules ? datedSchedule.schedules[day] : false
          const defaultSchedule = defaultSchedulesWeekly ? defaultSchedulesWeekly[day] || {} : {}
          profilesDatedResponse.push({
            response: scheduleForDay ? scheduleForDay.response : defaultSchedule.response || 'notset',
            amResponse: scheduleForDay ? scheduleForDay.amResponse : defaultSchedule.amResponse || 'notset',
            pmResponse: scheduleForDay ? scheduleForDay.pmResponse : defaultSchedule.pmResponse || 'notset',
            dated: date,
            id: profileId,
            default: scheduleForDay ? false : !!defaultSchedule.type,
            type: scheduleForDay ? scheduleForDay.type : defaultSchedule.type || 'full',
            day: moment(date).format('dddd')
          })
          return profilesDatedResponse
        })
        return profilesDatedResponse
      } else {
        return []
      }
    },
    resetData () {
      this.thisWeekClicked = true
      this.nextWeekClicked = false
      this.weekDetail = {
        startOfWeek: moment().startOf('isoWeek'),
        endOfWeek: moment().endOf('isoWeek').subtract('2', 'days'),
        weekNumber: moment().add(1, 'weeks')
      }
      this.scheduleDays = [
        'MON',
        'TUE',
        'WED',
        'THU',
        'FRI'
      ]
      this.tableWeekDetails = []
      this.allProfilesSchedulesWeekly = []
    },
    async loadWeekSchedules () {
      await this.loadMiniSchedules({ weekCommencingDate: moment(this.weekDetail.startOfWeek).toDate() })
      this.allSchedulesLoaded = true
      this.getProfileweeklySchedule()
    },
    async getProfileweeklySchedule () {
      this.allProfilesSchedulesWeekly = []
      const framedProfileSchedule = await this.frameProfileSchedule(this.profileDetails.profileId)
      this.allProfilesSchedulesWeekly.push({
        id: this.profileDetails.profileId,
        responses: [...framedProfileSchedule]
      })
      const self = this
      setTimeout(function () {
        self.allSchedulesLoaded = true // to paint the schedules after all the responses are loaded - check.
      }, 2000)
    }
  },
  watch: {
    'weekDetail.startOfWeek': {
      handler (newf, oldf) {
        this.calculateTableWeekDetails()
        this.loadWeekSchedules()
      }
    },
    async miniSchedules () {
      this.getProfileweeklySchedule()
    },
    profileDetails: {
      handler (newf, oldf) {
        this.resetData()
        this.$forceUpdate()
      }
    },
    showDialog: {
      handler () {
        if (this.showDialog) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
            document.addEventListener('keyup', self.handleKeyDown)
          }, 300)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
            document.removeEventListener('keyup', self.handleKeyDown)
          }, 300)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.profile-details-dialog {
  width: 500px;
  margin: auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, .40);
  border-radius: 8px;
  z-index: 50;

  &.no-style {
    box-shadow: none;
  }
  &.small-grids {
    width: 350px;
  }
  .dialog-wrapper {
    &.other {
      min-height: 200px;
    }
    > div {
      padding: 12px;
      border-bottom: 0.2px solid #BDBDBD;
    }
    .profile-initials-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .profile-redirect {
        .profile-display-name {
          color: #333333;
          font-weight: 700;
        }
      }
      .mdc-icon-button {
        font-size: 18px;
        height: 20px;
        width: 20px;
        padding: 0;
        &::before,
        &::after {
          --mdc-ripple-fg-size: 0;
          width: auto;
          height: auto;
        }
      }
    }
    .you-and-profile-details {
      display: flex;
      flex-direction: column;
      .you-profile-label {
        font-weight: 800;
        color: #ACACAC;
      }
      .detail-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 3px;
        > * {
          font-weight: 400;
          color: #404040;
        }
        .value {
          font-weight: 700;
        }
      }
    }
    .profile-weekly-schedules {
      display: flex;
      flex-direction: column;
      .week-nav-content {
        .week-nav-label {
          color: #00498D;
          font-weight: 600;
          cursor: pointer;
          &:first-of-type {
            margin-right: 10px;
          }
          &.clicked {
            color: #404040;
            pointer-events: none;
          }
        }
      }
      .profile-schedules-wrapper {
        .week-range {
          margin: 8px 0;
          .week-date-text {
            color: #ACACAC;
            font-size: 12px;
            font-weight: 800;
          }
        }
        .smaller-version-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .schedule-details {
            .answer {
              min-width: 30px;
              max-width: 75px;
              > .mdc-text {
                font-weight: 700;
                color: #404040;
              }
              &.full-day {
                display: flex;
                align-items: baseline;
                .icon-wrapper {
                  margin-left: 5px;
                  width: 35px;
                  height: 20px;
                  border-radius: 4px;
                  background-color: var(--notset-fill);
                  img {
                    width: 10px;
                    height: 10px;
                  }
                  &.thirdspace-fill {
                    background-color: var(--thirdspace-fill);
                  }
                  &.home-fill {
                    background-color: var(--home-fill);
                  }
                  &.office-fill {
                    background-color: var(--office-fill);
                  }
                  &.nonworking-fill {
                    background: var(--nonworking-fill);
                  }
                  &.notset-fill {
                    background-color: var(--notset-fill);
                  }
                }
              }
              &.split-day {
                display: flex;
                align-items: baseline;
                flex-wrap: wrap;
                .split-day-grid {
                  display: grid;
                  grid-template-columns: 20px 20px;
                  height: 20px;
                  align-items: center;
                  grid-gap: 0.5px;
                  margin-left: 5px;
                  .am-wrapper {
                    border-radius: 4px 0 0 4px;
                  }
                  .pm-wrapper {
                    border-radius: 0 4px 4px 0;
                  }
                  .slot-text {
                    font-size: 8px;
                  }
                  .slot-type {
                    background-color: var(--notset-fill);
                    .icon-wrapper {
                      width: 20px;
                      height: 20px;
                      img {
                        width: 10px;
                        height: 10px;
                      }
                    }
                    &.thirdspace-fill {
                      background-color: var(--thirdspace-fill);
                    }
                    &.home-fill {
                      background-color: var(--home-fill);
                    }
                    &.office-fill {
                      background-color: var(--office-fill);
                    }
                    &.nonworking-fill {
                      background: var(--nonworking-fill);
                    }
                    &.notset-fill {
                      background-color: var(--notset-fill);
                    }
                    &.default {
                      background-color: var(--notset-fill);
                    }
                  }
                }
              }
              &.notset-day {
                display: flex;
                align-items: baseline;
                .icon-wrapper {
                  margin-left: 5px;
                  width: 35px;
                  height: 20px;
                  border-radius: 4px;
                  background-color: var(--notset-fill);
                  img {
                    width: 10px;
                    height: 10px;
                    visibility: hidden;
                  }
                }
                &.notset-fill {
                  background-color: var(--notset-fill);
                }
              }
            }
          }
        }
        .week-day-text-grid {
          display: grid;
          height: 26px;
          grid-gap: 5px;
          align-items: center;
          position: relative;
          background: #fcfcfc;
          width: 100%;
          > div {
            display: grid;
            grid-template-columns: repeat(5, minmax(auto, 1fr));
            align-items: center;
          }
          div.days-text-wrapper {
            text-align: center;
            max-width: 90px;
            &.hide-day {
              visibility: hidden;
            }
            .mdc-text {
              text-align: center;
              text-transform: capitalize;
              color: #404040;
            }
          }
        }
        .responses-days-grid {
          display: grid;
          grid-gap: 5px;
          position: relative;
          background: #fcfcfc;
          min-height: 36px;
          opacity: 0.5;
          width: 100%;
          > div {
            display: grid;
            grid-template-columns: repeat(5, minmax(auto, 1fr));
            height: 36px;
            grid-gap: 5px;
            background: #fcfcfc;
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              box-sizing: border-box;
              margin-left:1px;
              max-width: 90px;
              .icon-wrapper {
                height: 13px;
                width: 13px;
              }
              &.thirdspace-fill {
                background-color: var(--thirdspace-fill);
              }
              &.home-fill {
                background-color: var(--home-fill);
              }
              &.office-fill {
                background-color: var(--office-fill);
              }
              &.nonworking-fill {
                background: var(--nonworking-fill);
              }
              &.split-fill {
                width: 100%;
                height: 100%;
                background: white;
                .response-icons-wrapper {
                  width: 100%;
                  height: 100%;
                }
              }
              &.notset-fill {
                background-color: var(--notset-fill);
              }
              .not-set-text {
                color: #bdbdbd;
                text-align: center;
                font-style: italic;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 600;
              }
              .split-day {
                display: flex;
                flex-flow: row;
                align-items: baseline;
                width:100%;
                height:100%;
                .split-day-row {
                  display: flex;
                  height: 100%;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  .am-wrapper {
                    flex:1;
                    border-radius: 4px 0 0 4px;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    .icon-wrapper {
                      width: 100% !important;
                      height: 100% !important;
                      img {
                        width:15px !important;
                        height:15px !important;
                      }
                    }
                  }
                  .pm-wrapper {
                    flex:1;
                    border-radius: 0 4px 4px 0;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    .icon-wrapper {
                      width: 100% !important;
                      height: 100% !important;
                      img {
                        width:15px !important;
                        height:15px !important;
                      }
                    }
                  }
                  .slot-text {
                    font-size: 8px;
                  }
                  .slot-type {
                    .icon-wrapper {
                      width: 20px;
                      height: 20px;
                      img {
                        width: 10px;
                        height: 10px;
                      }
                    }
                    &.thirdspace-fill {
                      background-color: var(--thirdspace-fill);
                    }
                    &.home-fill {
                      background-color: var(--home-fill);
                    }
                    &.office-fill {
                      background-color: var(--office-fill);
                    }
                    &.nonworking-fill {
                      background: var(--nonworking-fill);
                    }
                    &.notset-fill {
                      background-color: var(--notset-fill);
                    }
                  }
                }
              }
            }
          }
          &.animate {
            opacity: 1;
          }
        }
        .empty-grid {
          height: 10px;
          position: relative;
          background: #fcfcfc;
          &:before {
            content: '';
            position: absolute;
            font-size: 20px;
            height: 100%;
            width: 25px;
            z-index: 333333333;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.1254) 0%, rgba(109, 109, 109, 0) 27.6%);
          }
          &:after {
            content: '';
            position: absolute;
            font-size: 20px;
            height: 100%;
            width: 25px;
            right: 0;
            z-index: 333333333;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.1254) 0%, rgba(109, 109, 109, 0) 27.6%);
          }
        }
      }
    }
  }
}
</style>
