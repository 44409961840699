<template>
  <div ref="colorPalette" class="color-palette-wrapper" v-bind:class="colorPaletteFor" v-if="showColorPalette">
    <div role="menu" class="palette-menu">
      <div role="menu-item" v-for="color in colorPalette" :key="color.name + colorPaletteFor" class="palette-menu-item" @click="selectColor(color)" :class="{'disabled': checkDisabled(color) }">
        <span class="color-circle" :title="color.name"  :style="{'background-color': color.hexColor }"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { colorPalette } from '../../utils/colors'
export default {
  name: 'ColorPalette',
  components: {},
  props: {
    disableColors: {
      type: Array,
      default: null
    },
    subscribed: {
      type: Boolean,
      default: false
    },
    showColorPalette: {
      type: Boolean,
      default: false
    },
    colorPaletteFor: {
      type: String,
      default: ''
    },
    paletteId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  beforeDestroy () {},
  created () {
    this.colorPalette = colorPalette()
  },
  mounted () {},
  computed: {
    ...mapState([
    ]),
    ...mapState({
    })
  },
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    }),
    checkDisabled (color) {
      if (this.disableColors && this.disableColors.length > 0) {
        const disColorIndex = this.disableColors.findIndex(disColor => disColor.hexColor === color.hexColor)
        return disColorIndex >= 0
      }
    },
    handleClickOutside (event) {
      if (!(this.$el === event.target || this.$el.contains(event.target))) {
        this.$emit('emitClose')
      }
    },
    selectColor (colorDetails) {
      this.$emit('emitSelect', colorDetails, this.paletteId, this.colorPaletteFor, this.subscribed)
    }
  },
  watch: {
    showColorPalette: {
      handler () {
        if (this.showColorPalette) {
          const self = this
          setTimeout(function () {
            document.addEventListener('click', self.handleClickOutside)
          }, 250)
        } else {
          const self = this
          setTimeout(function () {
            document.removeEventListener('click', self.handleClickOutside)
          }, 250)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.color-palette-wrapper {
  position: absolute;
  width: 115px;
  max-width: 200px;
  left: 90%;
  right: 0;
  top: 18px;
  transform: translateZ(0);
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  outline: 1px solid transparent;
  z-index: 2000;
  .palette-menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    .palette-menu-item {
      display: flex;
      cursor: pointer;
      .color-circle {
        margin: 5px;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        &:hover:not(.disabled) {
          box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%), 0px 1px 3px 0px rgb(0 0 0 / 20%);
          transition: all ease-in-out 0.3s;
          transform: scale(1.15);
        }
      }
      &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        .color-circle {
          opacity: 0.1;
        }
      }
    }
  }
}
</style>
