import firebase from 'firebase/app'
import { functionsUrl, appName } from '../settings'
import 'firebase/auth'

const callFunction = async (funcType, func, payload) => {
  payload.token = ''
  if (firebase.auth().currentUser) {
    const token = await firebase.auth().currentUser.getIdToken(true)
    if (token) {
      payload.token = token
    }
  }
  let result = null
  let res = {}
  let url
  let funcPath
  let body
  try {
    url = (funcType === 'onboarding')
      ? `https://backend-dot-${appName}.appspot.com`
      : functionsUrl
    funcPath = (funcType && funcType !== 'onboarding')
      ? `${funcType}/${func}`
      : `${func}`
    body = JSON.stringify(payload)
    result = await fetch(`${url}/${funcPath}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body
    })
    // console.log('backend.callFunction', url + '/' + funcPath)
    res = await result.json()
  } catch (err) {
    console.error('backend.callFunction error', url + '/' + funcPath, body)
    console.error(err)
    console.error(result)
    res.error = err
  }
  return res
}

export const mail = async (func, payload) => {
  return callFunction('mailer', func, payload)
}

export const appFunction = async (func, payload) => {
  return callFunction('app', func, payload)
}

export const onboarding = async (func, payload) => {
  return callFunction('onboarding', func, payload)
}

export const calendar = async (func, payload) => {
  return callFunction('calendar', func, payload)
}

export const cloudFunction = async (func, payload) => {
  return callFunction(false, func, payload)
}
