<template>
  <div class="team-invite-approve" :class="displayFor" v-if="hasTeamInvite && showTeamInvite" @click="showTeamInviteModal" role="banner">
    <mdc-text class="request-status" tag="span" typo="caption">NEW</mdc-text>
    <mdc-text class="request-text" tag="span" typo="caption">You have been invited to join a new team</mdc-text>
    <mdc-button v-if="displayFor !== 'schedule-search'" class="request-action" tag="span" typo="caption">Click here to review</mdc-button>
    <base-svg-icon :name="displayFor === 'schedule-search' ? 'cross-thin-red' : 'cross-thin'" class="team-invite" @click.native="closeTeamInvite" clickable/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
export default {
  name: 'TeamInviteNote',
  components: {
    BaseSvgIcon
  },
  props: {
    displayFor: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      showTeamInvite: true
    }
  },
  beforeDestroy () {},
  created () {},
  mounted () {
  },
  methods: {
    ...mapActions([
      'setTeamInvitesDisplay'
    ]),
    ...mapActions({
    }),
    closeTeamInvite (event) {
      event.stopPropagation()
      this.showTeamInvite = false
    },
    showTeamInviteModal () {
      console.log('TeamInviteNote.showTeamInviteModal')
      this.setTeamInvitesDisplay(true)
    }
  },
  watch: {
  },
  computed: {
    ...mapState([
    ]),
    ...mapState({
      workspace: state => state.workspace,
      myInvites: state => state.myInvites
    }),
    hasTeamInvite () {
      if (this.myInvites) {
        const filteredInvites = this.myInvites.filter((inv) => inv.status !== 'accepted' && inv.status !== 'declined' && inv.workspace === this.workspace.id)
        return filteredInvites.length > 0
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.team-invite-approve {
  cursor: pointer;
  &.schedule-search {
    margin-left: 0px;
    background: none;
    .request-status {
      background: #DAC0FC;
      font-weight: 800;
      margin: 0 5px 0 4px;
      padding: 1px 4px;
      width: unset;
    }
    .request-text {
      flex: unset;
      color: #000;
      font-weight: 700;
    }
    .icon-wrapper {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  margin: 3px 20px 0 20px;
  display: flex;
  align-items: center;
  background: rgba(218, 192, 252, 0.15);
  border-radius: 10px;
  padding: 3px 0;
  .request-status {
    font-weight: 900;
    font-size: 9px;
    color: #000;
    padding: 4px;
    border-radius: 12px;
    min-width: 30px;
    text-align: center;
    line-height: 14px;
    margin-right: 5px;
  }
  .request-text{
    color: #000;
    flex: 1;
  }
  .icon-wrapper {
    margin-left: auto;
    margin-right: 10px;
    img {}
  }
  .mdc-button {
    font-size: 12px;
    text-transform: none;
    height: 10px;
    color: #404040;
    margin-right: 15px;
    font-weight: 600;
  }
}
</style>
