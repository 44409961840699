import { Node, mergeAttributes } from '@tiptap/core'
import Suggestion from '@tiptap/suggestion'

const Hashtag = Node.create({
  name: 'hashtag',

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addOptions () {
    return {
      HTMLAttributes: {},
      suggestion: {
        char: '#',
        command: ({ editor, range, props }) => {
          // console.log('Hashtag.command', props)
          editor
            .chain()
            .focus()
            .insertContentAt(range, { type: 'hashtag', attrs: props })
            .insertContent(' ')
            .run()
        }
      }
    }
  },

  addAttributes () {
    return {
      'data-hashtag': {
        default: null
      },
      'data-hashtagid': {
        default: null
      }
    }
  },

  parseHTML () {
    return [
      {
        tag: 'span[data-hashtag]'
      }
    ]
  },

  renderHTML ({ node, HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), `#${node.attrs['data-hashtag']}`]
  },

  renderText ({ node }) {
    return `#${node.attrs['data-hashtag']}`
  },

  addKeyboardShortcuts () {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isMention = false
        const { selection } = state
        const { empty, anchor } = selection

        if (!empty) {
          return false
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === 'hashtag') {
            isMention = true
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)

            return false
          }
        })

        return isMention
      })
    }
  },

  addProseMirrorPlugins () {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion
      })
    ]
  }
})

export default Hashtag
