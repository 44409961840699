<template>
  <div class="daily-page" v-bind:class="{ 'fullscreen-mode': expandedView }">
    <tiptap-menu-dialog
      v-if="!externalMenu"
      :formatIconPosition="formatIconPosition"
      @closeFormatControlMenu="closeFormatControlMenu"
      :showFormatControlsMenu="!externalMenu && openFormatControls && (composerView === 'default')"
      :editor="composerEditor"
      noteBlocksEnabled />
    <tiptap-settings-dialog
      v-if="!externalMenu"
      :settingsIconPosition="settingsIconPosition"
      @closeSettingsMenu="closeSettingsMenu"
      :showSettingsMenu="!externalMenu && openSettings && (composerView === 'default')"
      :editor="composerEditor"
      noteBlocksEnabled />
    <mdc-text typo="caption" tag="span" class="date"> {{dateFormat(forDate)}}</mdc-text>
    <span class="page-sharing-icon" @click="showSharingMenu()">
      <img src="/static/img/icons/flexibly/sharing-icon.svg" />
    </span>
    <tiptap-editor
        :disableNoteBlockSharing="disableNoteBlockSharing"
        v-if="notePages"
        class="dailypage-tiptap page-body"
        :noteBlocks="dailyPage.noteBlocks"
        :doc="'daily/' + dailyPage.id"
        @editorReady="setEditor"
        :hashtags="initiativeTags"
        :user="user"
        :mentionSuggestions="teamMentions"
        :content="dailyPage.html"
        :title="'dailyPageBody'"
        @focus="$emit('editorReady', composerEditor)"
        externalMenu
        @onTextUpdate="dailyPageUpdate"
        usePageLinkSuggestions
        :pagelinks="pageLinks"
        noteBlocksEnabled
        addDefaultNoteBlock
        :teamId="profile.team"
      ></tiptap-editor>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as Y from 'yjs'
import TiptapMenuDialog from '../../components/flexibly/TiptapMenuDialog.vue'
import TiptapSettingsDialog from '../../components/flexibly/TiptapSettingsDialog.vue'
import { dateCustomFormat } from '../../utils/time'

export default {
  name: 'DailyPage',
  components: {
    TiptapEditor: () => import('../../components/Tiptap'),
    TiptapMenuDialog,
    TiptapSettingsDialog
  },
  data () {
    return {
      composerEditor: null,
      debouncedDailyPageUpdate: null,
      showNoteBlockMenu: false,
      updateText: null
    }
  },
  props: {
    disableNoteBlockSharing: {
      type: Boolean,
      default: true
    },
    formatIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    settingsIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    openFormatControls: {
      type: Boolean,
      default: false
    },
    openSettings: {
      type: Boolean,
      default: false
    },
    composerView: {
      type: String,
      default: ''
    },
    expandedView: {
      type: Boolean,
      default: false
    },
    teamMentions: {
      type: Array,
      default: () => {
        return []
      }
    },
    dailyPage: {
      type: Object,
      default: () => {
        return {}
      }
    },
    forDate: {
    },
    externalMenu: {
      type: Boolean,
      default: false
    }
  },
  created () {},
  beforeDestroy () {
    if (this.hasUpdate) {
      if (this.updateText) {
        const jsonContent = this.composerEditor.getJSON()
        const htmlContent = this.updateText
        const doc = this.composerEditor.options.ydoc
        // console.log('Composer.dailyPageUpdate', this.dailyPage.id, htmlContent, jsonContent, doc, this.composerEditor, this.dailyPage)
        this.saveDailyPageUpdate(this.dailyPage.id, htmlContent, jsonContent, doc)
        this.hasUpdate = false
        this.updateText = null
      }
    }
  },
  mounted () {
    // console.log('DailyPage.mounted')
  },
  computed: {
    ...mapState([
      'user',
      'profile'
    ]),
    ...mapState({
      personalInitiatives: state => state.teams.personalInitiatives,
      notePages: state => state.teams.notePages,
      dailyPagesLinks: state => state.teams.pageLinks,
      teams: state => state.teams.teams
    }),
    pageLinks () {
      const pageLinks = [...this.notePages]
      for (const dailyp of this.dailyPagesLinks) {
        pageLinks.push({ ...dailyp })
      }
      return pageLinks
    },
    initiativeTags () {
      let tags = []
      for (const init of this.personalInitiatives) {
        if (init.teamId !== this.profile.team) {
          const tagName = `${this.getTeamName(init.teamId)}${init.name.replace(' ', '-')}`
          tags.push({ ...init, tagName })
        } else {
          const tagName = `${init.name.replace(' ', '-')}`
          tags.push({ ...init, tagName })
        }
      }
      tags = tags.sort((a, b) => a.tagName.localeCompare(b.tagName))
      return tags
    }
  },
  methods: {
    ...mapActions({
      updateDailyPage: 'teams/updateDailyPage'
    }),
    getTeamName (teamId) {
      if (teamId !== this.profile.team) {
        if (this.teams) {
          const ft = this.teams.filter((t) => {
            return t.id === teamId
          })[0] || {}
          return `${ft.name || ''}/`
        }
      }
      return ''
    },
    closeFormatControlMenu (value) {
      this.$emit('closeFormatControlsMenu', value)
    },
    closeSettingsMenu (value) {
      // console.log('DailyPage.closeSettingsMenu', value)
      this.$emit('closeSettingsMenu', value)
    },
    setEditor (editor) {
      this.composerEditor = editor
      if (this.expandedView) {
        this.$emit('setEditor', this.composerEditor)
      }
    },
    showSharingMenu () {
      // console.log('toggle sharing menu')
      this.showNoteBlockMenu = true
    },
    dateFormat (forDate) {
      return dateCustomFormat(forDate, 'MMMM DD, YYYY')
    },
    saveDailyPageUpdate (dailyPageId, html, json, doc) {
      let docState
      const dailyPage = { html, json }
      if (doc) {
        docState = Y.encodeStateAsUpdate(doc)
        // console.log('Composer.saveDailyPageUpdate.docState', docState)
        dailyPage.docState = docState
      }
      // console.log('saveDailyPageUpdate', { teamId: this.profile.team, dailyPageId, dailyPage })
      this.updateDailyPage({ teamId: this.profile.team, dailyPageId, dailyPage })
    },
    dailyPageUpdate (text, key) {
      const self = this
      this.hasUpdate = true
      this.updateText = text
      if (this.debouncedDailyPageUpdate) {
        clearTimeout(this.debouncedDailyPageUpdate)
      }
      this.debouncedDailyPageUpdate = setTimeout(() => {
        const jsonContent = self.composerEditor.getJSON()
        const htmlContent = text
        const doc = self.composerEditor.options.ydoc
        // console.log('Composer.dailyPageUpdate', this.dailyPage.id, htmlContent, jsonContent, doc, this.composerEditor, this.dailyPage)
        self.saveDailyPageUpdate(this.dailyPage.id, htmlContent, jsonContent, doc)
        self.hasUpdate = false
        self.updateText = null
      }, 2000)
    }
  }
}
</script>

<style lang="scss">
.daily-page {
  padding: 30px 30px 30px 40px;
  position: relative;
  .date {
    text-transform: uppercase;
    color: #333333;
    mix-blend-mode: normal;
    opacity: 0.6;
  }
  .userlist {
    display: none;
  }
  .menubar {
    justify-content: center;
  }
  .page-sharing-icon {
    position: absolute;
    z-index: 10;
    left: 8px;
    top: 25%;
    display: none;
    cursor: pointer;
    &:hover {
      display: block;
    }
  }
  .dailypage-tiptap {
    &.heading {
      display: block;
      &:focus-within {
        + .page-sharing-icon {
          display: block;
        }
      }
      &:hover {
        + .page-sharing-icon {
          display: block;
        }
      }
      .editor__content {
        overflow: visible;
        p {
          font-style: normal;
          font-size: 25px;
          font-weight: bold;
          color: #4F4F4F;
          &.is-empty:first-child::before {
            content: 'Untitled Page';
            color: #C8C8C8;
            font-style: normal;
          }
          &::after {
            content: ''
          }
        }
      }
    }
    &.page-body {
      min-height: 90px;
      margin-top: 25px;
      .editor__content {
        overflow: visible;
        p {
          font-size: 14px;
          color: #333333;
          line-height: 23px;
          &.is-empty:first-child:only-child::before {
            content: 'Start typing or type / for commands';
            color: #C3C2C0;
            font-style: normal;
          }
          &::after {
            content: '';
          }
          .hashtag {
            margin-bottom: 0;
            height: 15px;
            font-size: 14px;
            flex: unset;
            display: inline-flex;
            padding: 1px 2px;
            color: var(--hashtagtext-color);
            position: relative;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            line-height: 17px;  /* height + padding(2) + border(1) */
            font-weight: normal;
            &::before {
              content: '#';
              color: var(--hashtag-color);
              font-weight: 400;
              position: absolute;
            }
          }
          .mention {
            vertical-align: middle;
            top: 0;
            line-height: 18px;
          }
        }
      }
    }
  }
  .template-selection {
    margin-top: 30px;
    .title {
      text-transform: uppercase;
      color: #C3C2C0;
      margin-bottom: 20px;
    }
    .template-options {
      * {
        cursor: pointer;
      }
      .option {
        color: #C3C2C0;
        margin: 3px 0;
        border: 1px solid transparent;
        &:hover {
          background: #EBF3FC;
          border-radius: 2px;
        }
      }
    }
  }
  &.fullscreen-mode {
    .page-sharing-icon {
      left: -30px;
    }
    .dialog-menu {
      margin-left: -120px;
    }
  }
}
</style>
