<template>
<div :draggable="false" v-if="name" :id="iconId" class="icon-wrapper" v-bind:class="{ 'clickable': clickable }" role="image" :aria-label="label || name">
  <img :role="clickable ? 'button' : ''" :draggable="false" v-if="logo" :src="require(`../../assets/flexibly/logo/${name}.svg`)" />
  <img :role="clickable ? 'button' : ''" :draggable="false" v-else :src="require(`../../assets/flexibly/icons/${name}.svg`)" />
</div>
</template>

<script>
export default {
  name: 'BaseSvgIcon',
  inheritAttrs: false,
  props: {
    iconId: {
      type: String,
      default: ''
    },
    logo: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: '',
      required: true
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    clickable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss">
.icon-wrapper {
  display: flex;
  align-items: center;
  img {
    margin: auto;
  }
  &.clickable {
    cursor: pointer;
  }
}
</style>
