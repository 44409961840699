<template>
<div class="settings-side-headings-content">
  <section class="profile-settings settings-type">
    <header class="heading">
      <mdc-text tag="span" :class="sideHeadingClicked.type === 'profile' ? 'clicked' : ''" typo="body2">PROFILE SETTINGS</mdc-text>
    </header>
    <div class="sub-headings">
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'info' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('profile', 'info')">Profile information</mdc-text>
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'notifications' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('profile', 'notifications')">Notifications</mdc-text>
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'calendarconnect' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('profile', 'calendarconnect')">Calendar Connect</mdc-text>
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'consent' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('profile', 'consent')">Consent</mdc-text>
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'cookies' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('profile', 'cookies')">Cookies</mdc-text>
    </div>
  </section>
  <section class="team-admin-settings settings-type" v-if="$teamLeader()">
    <header class="heading">
      <mdc-text tag="span" :class="sideHeadingClicked.type === 'teamAdmin' ? 'clicked' : ''" typo="body2">TEAM ADMIN SETTINGS</mdc-text>
    </header>
    <div class="sub-headings">
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'teamInfo' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('teamAdmin', 'teamInfo')">Team Information</mdc-text>
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'locationSchedules' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('teamAdmin', 'locationSchedules')">Location Schedules</mdc-text>
    </div>
  </section>
  <section class="workspace-admin-settings settings-type" v-if="$workspaceAdmin()">
    <header class="heading">
      <mdc-text tag="span" :class="sideHeadingClicked.type === 'workspaceAdmin' ? 'clicked' : ''" typo="body2">WORKSPACE ADMIN SETTINGS</mdc-text>
    </header>
    <div class="sub-headings">
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'wsInfo' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('workspaceAdmin', 'wsInfo')">Workspace Information</mdc-text>
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'manageTeams' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('workspaceAdmin', 'manageTeams')">Manage Teams</mdc-text>
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'asscEmailDomains' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('workspaceAdmin', 'asscEmailDomains')">Approved Email Domains</mdc-text>
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'officeLocations' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('workspaceAdmin', 'officeLocations')">Office Locations</mdc-text>
      <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'workspaceLocationSchedules' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('workspaceAdmin', 'workspaceLocationSchedules')">Location Schedules by Workspace</mdc-text>

      <!-- <mdc-text tag="span" typo="body2" :class="sideHeadingClicked.subType === 'teamPrivacySettings' ? 'clicked' : ''" class="sub-heading-type" @click.native="settingsTypeClicked('workspaceAdmin', 'teamPrivacySettings')">Team privacy settings</mdc-text> -->
    </div>
  </section>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'SettingsSideHeadings',
  data () {
    return {
    }
  },
  components: {
  },
  computed: {
    ...mapState([
    ])
  },
  methods: {
    ...mapActions([
    ]),
    settingsTypeClicked (type, subType) {
      this.$emit('sideHeadingClick', { type, subType })
    }
  },
  beforeDestroy () {
  },
  mounted () {
    if (this.sideHeadingClicked === {}) {
      this.settingsTypeClicked('profile', 'info') // default
    } else if (!this.sideHeadingClicked.type) {
      this.settingsTypeClicked('profile', 'info') // default
    } else {
      this.settingsTypeClicked(this.sideHeadingClicked.type, this.sideHeadingClicked.subType)
    }
  },
  watch: {},
  props: {
    sideHeadingClicked: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="scss">
.settings-side-headings-content {
  .settings-type {
    margin-bottom: 20px;
    .heading {
      margin-bottom: 7px;
      .mdc-text {
        text-transform: uppercase;
        font-weight: 600;
        color: #6184AD;
        &.clicked {
          color: #404040;
        }
      }
    }
    .sub-headings {
      display: flex;
      flex-direction: column;
      .sub-heading-type {
        color: #6184AD;
        font-weight: 400;
        line-height: 25px;
        cursor: pointer;
        width: max-content;
        &.clicked {
          color: #404040;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
