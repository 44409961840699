<template>
  <div class="recommendations-wrapper" :class="{ 'full-view': expandedView }">
    <div v-if="(schedules === null && preferences === null && meetings === null)" class="loading-recommendations schedule-calculating">
      <div class="date-rec-wrapper">
      <div class="recommendations-list">
        <no-recommendation-card :key="'no-rec-type-loading1'" />
      </div>
      </div>
    </div>
    <!-- ((no schedule and no pref submitted) or (no recomm for submitted schedule )) and notLoading) -->
    <section role="section" aria-label="no-rec" class="not-submitted-wrapper rec-type-wrapper" v-else-if="((!mySchedule && !myWeekPreferences) || (noRecDates && noRecDates.length === 5)) && !bestDaysCalculating">
      <template v-for="recDate, dateIndex in Object.keys(allRecommendationsByDate)">
        <div class="date-rec-wrapper" :key="'no-rec-set' + recDate" v-if="showRecRow(dateIndex, recDate)">
          <div class="date-details">
            <mdc-text class="rec-date" tag="span" typo="caption" :class="{'rec-today': checkToday(recDate), 'rec-tomo': checkTomorrow(recDate)}">
              {{ checkToday(recDate) ? 'TODAY' : '' }}
              {{ checkTomorrow(recDate) ? 'TOMORROW' : '' }}
              <mdc-text tag="span" class="date-format" typo="caption">{{ checkToday(recDate) || checkTomorrow(recDate) ? getDayDate(recDate, 'ddd D') : getDayDate(recDate, 'dddd D') }}</mdc-text>
            </mdc-text>
          </div>
          <div v-if="dateIndex === 0 && !onboarding" :key="'open-pref-link' + dateIndex" class="no-bod-content">
            <mdc-text class="no-bod-text" tag="span" typo="heading5">No Best Office Days to show!</mdc-text>
            <mdc-text class="set-pref-text" tag="span" typo="body1">{{ (!mySchedule && !myWeekPreferences) ? 'Set your preferences for this week ' : noRecDates && noRecDates.length === 5 ? 'Update your preferences with more people or initiative ' : '' }} to see best day suggestions here!</mdc-text>
            <mdc-text role="link" aria-label="open-pref" @click.native="openWeeklyPreferences()" class="open-pref-link" tag="span" typo="body2">Open preferences >></mdc-text>
          </div>
          <div v-if="onboarding" class="recommendations-list dummy-card" ref="dummyRecWrapper">
            <template v-for="index in noOfRecCardsToDisplay">
              <base-svg-icon v-if="dateIndex === 0"
                :name="noOfRecCardsToDisplay === 2 ? 'dummy-card' + (index * 2 - 1) : 'dummy-card'+index"
                :key="'dummy-rec-type' + index"
              />
              <no-recommendation-card
                v-else
                :fullView="expandedView"
                :key="'skele-rec-type-' + index"
                :skeleton="onboarding"
                :skeletonType="index <= Math.ceil(noOfRecCardsToDisplay / 2) ? 'initiative' : 'people'"
              />
            </template>
          </div>
          <div v-else class="recommendations-list" :class="[ dateIndex === 0 && !pastInitAndUserSuggestions.every(sug => sug.id === 'blank') ? 'past' : 'blank' ]" ref="noRecWrapper">
            <template v-for="(recommendation, index) in pastInitAndUserSuggestions.slice(0, noOfRecCardsToDisplay)">
              <template v-if="dateIndex === 0 && recommendation">
                <recommendation-card noHover v-if="recommendation.id !== 'blank'" :recommendation="recommendation" :preferenceId="preferenceId" :recType="recommendation.type" :fullView="expandedView" :key="'past-rec-type-' + recommendation.id" />
                <no-recommendation-card v-else :fullView="expandedView" :key="'no-rec-type-' + index" />
              </template>
              <no-recommendation-card v-else :fullView="expandedView" :key="'no-rec-type-' + index" />
            </template>
          </div>
        </div>
      </template>

      <div v-if="hasAnyInterest" class="has-interest-message">
        <mdc-text class="used-up-text" tag="span" typo="heading5">There are people that are interested in seeing you</mdc-text>
      </div>

    </section>
    <!-- (if no future recom and notLoading) -->
    <section role="section" aria-label="past-rec" class="past-submitted-wrapper rec-type-wrapper" v-else-if="!bestDaysCalculating && (futureRecDates && futureRecDates.length === 0)">

      <div class="past-rec-message">
        <mdc-text class="used-up-text" tag="span" typo="heading5">You have used up all of this week’s suggestions!</mdc-text>
        <mdc-text class="used-up-support-text" tag="span" typo="body1">Navigate to next week for recommendations. </mdc-text>
      </div>

      <template v-for="(recDate, dateIndex) in Object.keys(allRecommendationsByDate)">

        <div v-if="hasRec(recDate, allRecommendationsByDate[recDate], dateIndex)" class="date-rec-wrapper" :key="'past-rec' + recDate">
          <div class="date-details" @click.prevent.stop="toggleRecOpen(recDate)">
            <mdc-text class="rec-date used" tag="span" typo="caption" :class="{'rec-today': checkToday(recDate), 'rec-tomo': checkTomorrow(recDate)}">
              {{ checkToday(recDate) ? 'TODAY' : '' }}
              {{ checkTomorrow(recDate) ? 'TOMORROW' : '' }}
              <mdc-text tag="span" class="date-format" typo="caption">{{ checkToday(recDate) || checkTomorrow(recDate) ? getDayDate(recDate, 'ddd D') : getDayDate(recDate, 'dddd D') }}</mdc-text>
            </mdc-text>
            <div class="interest-section" v-if="interestForDate(recDate, '1')">
              <base-svg-icon :name="'interest'" class="interest-icon"/>
              <tooltip v-if="interest" :tooltipText="'People are in the office interested in seeing you'" class="selected-interest-tooltip" />
            </div>
          </div>

          <div class="recommendations-list past" ref="noRecWrapper">
            <template v-for="(recommendation, index) in allRecommendationsByDate[recDate].recommendations">
              <recommendation-card noHover v-if="recommendation.id !== 'blank'" :recommendation="recommendation" :preferenceId="preferenceId" :recType="recommendation.type" :fullView="expandedView" :key="'past-rec-type-' + recommendation.id" />
              <no-recommendation-card v-else :key="'no-rec-type-' + index" :skeleton="onboarding" :skeletonType="'people'"/>
            </template>
          </div>
        </div>
      </template>
      <div class="next-week-dates" v-if="nextWeekDates.length > 0">
        <div class="next-dates">
          <div class="next-week-date-row" v-for="date in nextWeekDates" :key="'past-rec' + date">
            <mdc-text class="date-text"  tag="span" typo="caption">{{getDayDate(date, 'dddd D')}}</mdc-text>
            <div class="interest-section" v-if="interestForDate(date, '2')">
              <base-svg-icon :name="'interest'" class="interest-icon" />
              <tooltip v-if="interest" :tooltipText="'People are in the office interested in seeing you'" class="selected-interest-tooltip" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="hasAnyInterest || !(futureRecDates && futureRecDates.length === 0)" role="section" aria-label="all-rec" class="submitted-wrapper rec-type-wrapper">

      <template v-for="recDate in Object.keys(allRecommendationsByDate)">
        <div v-if="onboarding || (hasRecOrInterest(recDate, allRecommendationsByDate[recDate]) && !isPastDate(recDate))" class="date-rec-wrapper" :key="allRecommendationsByDate[recDate] + allRecommendationsByDate[recDate].weekDate">
          <div v-if="!onboarding || (onboarding && showDaysFromHasRec().includes(getDayDate(recDate, 'ddd').toLowerCase()))" class="date-details" @click.prevent.stop="toggleRecOpen(recDate)">
            <mdc-text class="rec-date" tag="span" typo="caption" :class="{'rec-today': checkToday(recDate), 'rec-tomo': checkTomorrow(recDate)}">
              {{ checkToday(recDate) ? 'TODAY' : '' }}
              {{ checkTomorrow(recDate) ? 'TOMORROW' : '' }}
              <mdc-text tag="span" class="date-format" typo="caption">{{ checkToday(recDate) || checkTomorrow(recDate) ? getDayDate(recDate, 'ddd D') : getDayDate(recDate, 'dddd D') }}</mdc-text>
            </mdc-text>
            <div class="interest-section" v-if="interestForDate(recDate, '3')">
              <base-svg-icon :name="'interest'" class="interest-icon" />
              <tooltip v-if="interest" :tooltipText="'People are in the office interested in seeing you'" class="selected-interest-tooltip" />
            </div>

            <div class="rec-count" v-if="!isRecOpen(recDate) && !onboarding">
              <mdc-text tag="span" typo="caption" class="count-type init" :class="{'count-zero' : allRecommendationsByDate[recDate] && allRecommendationsByDate[recDate].initiativeRec === 0 }">
                {{ allRecommendationsByDate[recDate].initiativeRec }}
                <mdc-text tag="span" typo="caption" class="rec-type-count">{{ allRecommendationsByDate[recDate].initiativeRec === 1 ? 'initiative,' : 'initiatives,' }}</mdc-text>
              </mdc-text>
              <mdc-text tag="span" typo="caption" class="count-type people" :class="{'count-zero' : allRecommendationsByDate[recDate].peopleRec === 0 }">
                {{ allRecommendationsByDate[recDate].peopleRec }}
                <mdc-text tag="span" typo="caption" class="rec-type-count">people</mdc-text>
              </mdc-text>
            </div>
          </div>
          <div class="recommendations-list full" v-if="onboarding || datesOpened.length > 0 && isRecOpen(recDate)">
            <template v-for="(recommendation) in allRecommendationsByDate[recDate].recommendations">
              <recommendation-card v-if="recommendation.id !== 'blank'" :recommendation="recommendation" :preferenceId="preferenceId" :recType="recommendation.type" :fullView="expandedView" :key="'rec-type-' + recommendation.id" />
            </template>
            <template v-if="showDaysFromHasRec().includes(getDayDate(recDate, 'ddd').toLowerCase()) && onboarding && allRecommendationsByDate[recDate].recommendations.length < noOfRecCardsToDisplay">
              <no-recommendation-card v-for="index in (noOfRecCardsToDisplay - allRecommendationsByDate[recDate].recommendations.length)" :key="'no-rec-type-' + index" :skeleton="onboarding"                 :skeletonType="index <= Math.ceil(noOfRecCardsToDisplay / 2) ? 'initiative' : 'people'" />
            </template>
          </div>
        </div>
      </template>
    </section>
    <span v-if="myWeekPreferences && !bestDaysCalculating && !onboarding" class="loading-recommendations">
      <mdc-text typo="body2" tag="span"  v-if="preferences[0] && preferences[0].lastSuggestionDate">
      Recommendations calculated on: {{preferences[0].lastSuggestionDate.toDate() | datetimeFormat }}
      </mdc-text>
    </span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { sameDay, dayDateFormat, isToday, isTomorrow, inFuture } from '../../utils/time'
import { getMeetingForDate } from '../../utils/meetings'
import RecommendationCard from './RecommendationCard.vue'
import NoRecommendationCard from './NoRecommendationCard.vue'
import Tooltip from './Tooltip'
import BaseSvgIcon from './BaseSvgIcon'

import moment from 'moment'
export default {
  name: 'Recommendations',
  components: {
    RecommendationCard,
    NoRecommendationCard,
    BaseSvgIcon,
    Tooltip
  },
  props: {
    onboarding: {
      type: Boolean,
      default: false
    },
    expandedView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pastInitAndUserSuggestions: [],
      initiativeRecommendations: [],
      peopleRecommendations: [],
      bestDayForInitiatives: [],
      bestDayForPeople: [],
      pausedNotifications: [],
      allRecommendationsByDate: [], // all recommendations by date
      allRecommendationsList: [], // all recommendations list
      datesOpened: [],
      noRecDates: null,
      futureRecDates: null,
      nextWeekDates: [],
      windowWidth: window.innerWidth,
      noOfRecCardsToDisplay: 4,
      showNextWeekEmpty: false
    }
  },
  mounted () {
    this.setBestDaysCalculating(true)
    this.framePastPrefRec()
    window.addEventListener('resize', this.handleResize)
  },
  created () {},
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState([
      'scheduleSearchWeekDetail',
      'profile',
      'profiles',
      'appDate'
    ]),
    ...mapState({
      preferences: state => state.teams.preferences,
      initiatives: state => state.teams.initiatives,
      locations: state => state.teams.locations,
      bestDaysCalculating: state => state.teams.bestDaysCalculating,
      schedules: state => state.teams.schedules,
      meetings: state => state.teams.meetings,
      meetingsRec: state => state.teams.meetingsRec,
      pastPrefRec: state => state.teams.pastPrefRec,
      interest: state => state.teams.interest
    }),
    hasAnyInterest () {
      if (this.interest) {
        const dateKeys = Object.keys(this.allRecommendationsByDate)
        for (const dk of dateKeys) {
          const hasInterest = this.interestForDate(dk)
          if (hasInterest) {
            return true
          }
        }
      }
      return false
    },
    mySchedule () {
      if (this.schedules && this.schedules.length > 0) {
        const mySchedules = this.schedules.filter(sc => sc.fromId === this.profile.id)
        return mySchedules[0] || false
      }
      return false
    },
    myWeekPreferences () {
      if (this.preferences && this.preferences.length > 0) {
        return { ...this.preferences[0] }
      }
      return false
    },
    preferenceId () {
      if (this.myWeekPreferences) {
        return this.myWeekPreferences.id
      }
      return false
    }

  },
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
      setRecommendationNotificationPaused: 'teams/setRecommendationNotificationPaused',
      setBestDaysCalculating: 'teams/setBestDaysCalculating'
    }),
    showDaysFromHasRec () {
      const days = ['mon', 'tue', 'wed', 'thu', 'fri']
      let startDay

      for (const date in this.allRecommendationsByDate) {
        if (this.allRecommendationsByDate[date].recommendations.length > 0) {
          startDay = days.findIndex(day => day === this.getDayDate(date, 'ddd').toLowerCase())
          break
        }
      }

      const successiveDays = []
      for (let i = startDay; i < days.length; i++) {
        successiveDays.push(days[i])
      }
      return successiveDays
    },
    showRecRow (rowIdx) {
      if (rowIdx === 0) {
        return true
      }
      if (!this.hasAnyInterest) {
        return true
      }
      return false
    },
    interestForDate (date, from) {
      if (this.isPastDate(date)) {
        return false
      }
      if (this.interest && this.interest.length) {
        for (const interest of this.interest) {
          if (interest.userSuggestions) {
            if (interest.userSuggestions[this.profile.id]) {
              const usersRecommendation = interest.userSuggestions[this.profile.id]
              if (usersRecommendation.date) {
                const usersRecommendationDate = usersRecommendation.date.toDate ? usersRecommendation.date.toDate() : usersRecommendation.date
                const interestDay = sameDay(usersRecommendationDate, date)
                if (interestDay) {
                  // console.log('interestForDate.interest.date', from, usersRecommendationDate, date, sameDay(usersRecommendationDate, date))
                  return true
                }
              }
            }
          }
        }
      }
      return false
    },
    hasRec (recDate, recs) {
      return recs.recommendations.length > 0
    },
    hasRecOrInterest (recDate, recs) {
      // console.log('hasRecOrInterest', recDate, recs)
      return recs.recommendations.length > 0 || this.interestForDate(recDate)
    },
    framePastPrefRec () {
      if (this.pastPrefRec) {
        const pastRecs = { ...this.pastPrefRec }
        const finalInitSuggestions = []
        const initSuggestions = Object.values(pastRecs.initSuggestions)
        for (let i = 0; i < initSuggestions.length; i++) {
          const recommendation = this.getInitiativeRecommendation(initSuggestions[i])
          if (recommendation) {
            finalInitSuggestions.push(recommendation)
          }
        }
        const finalUserSuggestions = []
        const userSuggestions = Object.values(pastRecs.userSuggestions)
        for (let i = 0; i < userSuggestions.length; i++) {
          const recommendation = this.getPeopleRecommendation(userSuggestions[i])
          if (recommendation) {
            finalUserSuggestions.push(recommendation)
          }
        }
        this.pastInitAndUserSuggestions = []
        const allPastInitAndUserSuggestions = [...finalInitSuggestions, ...finalUserSuggestions]
        this.pastInitAndUserSuggestions = allPastInitAndUserSuggestions.filter(rec => rec.date)
        const allRecommendationsLength = this.pastInitAndUserSuggestions.length
        if (allRecommendationsLength < this.noOfRecCardsToDisplay) {
          const cardToAppend = this.noOfRecCardsToDisplay - allRecommendationsLength
          for (let i = 0; i <= cardToAppend; i++) {
            this.pastInitAndUserSuggestions.push({ id: 'blank', type: 'pastRec' })
          }
        }
      } else {
        for (let i = 0; i <= this.noOfRecCardsToDisplay; i++) {
          this.pastInitAndUserSuggestions.push({ id: 'blank', type: 'pastRec' })
        }
      }
    },
    frameBlankRec () {
      const allRecommendationsLength = this.allRecommendationsList.length
      if (allRecommendationsLength < this.noOfRecCardsToDisplay) {
        const cardToAppend = this.noOfRecCardsToDisplay - allRecommendationsLength
        for (let i = 0; i <= cardToAppend; i++) {
          this.allRecommendationsList.push({ id: 'blank', type: 'pastRec' })
        }
      }
    },
    calcNoAndFutureRecDates (dateKeys) {
      const noRecDates = []
      const futureRecDates = []
      dateKeys.forEach(date => {
        if (this.allRecommendationsByDate[date].recommendations.length === 0) {
          noRecDates.push(date)
        }
        if (((this.isRecDateInFuture(date)) || this.checkToday(date)) && (this.allRecommendationsByDate[date].recommendations.length > 0)) {
          futureRecDates.push(date)
        }
      })
      this.noRecDates = []
      this.noRecDates = noRecDates
      this.futureRecDates = []
      this.futureRecDates = futureRecDates
      const showFutureDates = true
      if (futureRecDates.length === 0 || showFutureDates) {
        this.nextWeekDates = []
        for (let i = 0; i <= 4; i++) {
          const nextWeekStartDate = moment(this.scheduleSearchWeekDetail.startOfWeek).startOf('days').add(1, 'weeks')
          this.nextWeekDates.push(nextWeekStartDate.add(i, 'days').toDate())
        }
        this.frameBlankRec()
      }
    },
    handleResize (event) {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 1200) {
        this.noOfRecCardsToDisplay = 2
        return
      }
      if (this.windowWidth > 1400) {
        this.noOfRecCardsToDisplay = 4
        return
      }
      this.noOfRecCardsToDisplay = 3
    },
    isRecDateInFuture (date) {
      if (date) {
        return inFuture(date)
      }
    },
    openWeeklyPreferences () {
      this.$emit('emitWeeklyPreferences', { section: 'weeklyPreferences', weeklyPreferences: true })
    },
    toggleRecOpen (date) {
      const dateIndex = this.datesOpened.findIndex(dateD => sameDay(dateD, date))
      if (dateIndex !== -1) {
        this.datesOpened.splice(dateIndex, 1)
      } else {
        this.datesOpened.push(date)
      }
    },
    isRecOpen (date) {
      const openDate = this.datesOpened.filter(dateD => sameDay(dateD, date))
      return openDate.length > 0
    },
    frameRecommendationsByDate () {
      const recommendations = [...this.initiativeRecommendations, ...this.peopleRecommendations]
      this.allRecommendationsList = recommendations.filter(rec => rec.date)
      const precMap = {}
      if (this.scheduleSearchWeekDetail) {
        for (const weekDate of this.scheduleSearchWeekDetail.datesOfWeek) {
          const recommendationsByDate = recommendations.filter(recommendation => {
            const recommendationDate = recommendation.date ? recommendation.date.seconds ? recommendation.date.toDate() : recommendation.date : null
            if (recommendation.id && sameDay(recommendationDate, weekDate)) {
              return recommendation
            }
            return null
          })
          if (!precMap[weekDate]) {
            precMap[weekDate] = {
              weekDate,
              recommendations: [],
              initiativeRec: recommendationsByDate.filter(rec => rec.type === 'initiative').length,
              peopleRec: recommendationsByDate.filter(rec => rec.type === 'people').length
            }
          }
          precMap[weekDate].recommendations.push(...recommendationsByDate)
        }
        this.allRecommendationsByDate = []
        this.allRecommendationsByDate = precMap
      }
    },
    getInitiativeRecommendations () {
      this.initiativeRecommendations = []
      // console.log('recommendations.getInitiativeRecommendations', this.bestDayForInitiatives)
      for (let i = 0; i < this.bestDayForInitiatives.length; i++) {
        const recommendation = this.getInitiativeRecommendation(this.bestDayForInitiatives[i])
        if (recommendation) {
          this.initiativeRecommendations.push(recommendation)
        }
      }
    },
    getPeopleRecommendations () {
      const peopleRecs = []
      for (let i = 0; i < this.bestDayToMeetPeople.length; i++) {
        const recommendation = this.getPeopleRecommendation(this.bestDayToMeetPeople[i])
        if (recommendation) {
          peopleRecs.push(recommendation)
        }
      }
      this.peopleRecommendations = peopleRecs
    },
    pauseNotification (recommendation, val) {
      // console.log('pauseNotification', recommendation, val)
      recommendation.pauseNotification = val
      if (this.preferences) {
        const preferences = { ...this.preferences[0] }
        const preferenceId = preferences.id
        const params = {
          value: val
        }
        let pausedNotifications = []
        if (recommendation.initiativeId) {
          params.initiativeId = recommendation.initiativeId
          pausedNotifications = this.pausedNotifications.filter(pn => pn.initiativeId !== recommendation.initiativeId)
          if (val) {
            pausedNotifications.push(params)
          }
        } else {
          params.userId = recommendation.userId
          pausedNotifications = this.pausedNotifications.filter(pn => pn.userId !== recommendation.userId)
          if (val) {
            pausedNotifications.push(params)
          }
        }
        this.setRecommendationNotificationPaused({ preferenceId, pausedNotifications })
      }
    },
    getDayDate (date, format) {
      return dayDateFormat(date, format)
    },
    isCurrentDate (date) {
      if (date) {
        return moment(date).isSame(this.appDate, 'day')
      }
      return false
    },
    isPastDate (date) {
      if (date) {
        return moment(date).isBefore(this.appDate, 'day')
      }
      return false
    },
    checkToday (date) {
      if (date) {
        return isToday(date)
      }
      return false
    },
    checkTomorrow (date) {
      if (date) {
        return isTomorrow(date)
      }
      return false
    },
    isInOffice (date) {
      if (this.mySchedule) {
        const dayAbv = moment(date).format('ddd').toUpperCase()
        if (this.mySchedule.schedules) {
          if (this.mySchedule.schedules[dayAbv]) {
            const daySch = this.mySchedule.schedules[dayAbv]
            if (daySch.split) {
              if (daySch.amResponse === 'office' || daySch.pmResponse === 'office') {
                return true
              }
            } else {
              if (daySch.response === 'office') {
                return true
              }
            }
          }
        }
      }
      return false
    },
    getInitiativeRecommendation (bestDay) {
      if (bestDay) {
        const date = bestDay.date ? bestDay.date.toDate() ? bestDay.date.toDate() : bestDay.date : false
        const initiative = this.initiatives.filter(init => init.id === bestDay.initiativeId)[0]
        // console.log('getInitiativeRecommendation.initiative', initiative)
        let locationDetails = false
        let inOffice = []
        if (date) {
          locationDetails = bestDay.topLocation ? this.locations && this.locations.filter(loc => loc.id === bestDay.topLocation)[0] : false
          inOffice = bestDay.inOffice.filter(profile => this.profile.id !== profile)
        }

        let pauseNotification = false
        if (this.pausedNotifications) {
          for (const pn of this.pausedNotifications) {
            if (pn.initiativeId === bestDay.initiativeId) {
              pauseNotification = true
            }
          }
        }

        return {
          id: bestDay.id,
          initiativeId: initiative.id,
          tag: initiative.name,
          percentInOffice: Math.ceil((bestDay.inOffice?.length / initiative.members.length) * 100),
          totalMembers: initiative.members.length,
          date,
          locationDetails,
          pauseNotification,
          current: this.isCurrentDate(date),
          past: this.isPastDate(date),
          office: this.isInOffice(date),
          inOffice,
          state: bestDay.state,
          type: 'initiative'
        }
      } else {
        return {}
      }
    },
    getPeopleRecommendation (bestDay) {
      // console.log('getPeopleRecommendation', bestDay.userId, bestDay.date)
      if (bestDay) {
        let locationDetails = false
        if (bestDay.date) {
          if (bestDay.topExLocation) {
            locationDetails = this.locations.filter(loc => loc.id === bestDay.topExLocation)[0]
          } else {
            locationDetails = bestDay.topLocation ? this.locations.filter(loc => loc.id === bestDay.topLocation)[0] : false
          }
        }

        let pauseNotification = false
        if (this.pausedNotifications) {
          for (const pn of this.pausedNotifications) {
            if (pn.userId === bestDay.userId) {
              pauseNotification = true
            }
          }
        }

        let meetingTime = false
        let meetingDetails = null
        let allMeetings = null
        if (bestDay.date) {
          if (this.meetings) {
            const profile = this.profiles.filter(prof => prof.id === bestDay.userId)[0]

            if (profile) {
              const datesMeetings = getMeetingForDate(bestDay.date.toDate(), this.meetings, this.meetingsRec)
              const meetingsWithUser = datesMeetings.filter(meet => {
                if (meet.status !== 'cancelled') {
                  let inMeeting = false
                  if (profile.email) {
                    inMeeting = meet.attendeeEmails.includes(profile.email)
                  }
                  if (profile.msEmail) {
                    inMeeting = inMeeting || meet.attendeeEmails.includes(profile.msEmail)
                  }
                  if (profile.googleEmail) {
                    inMeeting = inMeeting || meet.attendeeEmails.includes(profile.googleEmail)
                  }
                  return inMeeting
                }
                return false
              })
              allMeetings = meetingsWithUser
              if (meetingsWithUser.length > 0 && meetingsWithUser[0]) {
                meetingDetails = meetingsWithUser[0]
                let sdt = meetingDetails.startDateTime
                if (sdt.toDate) {
                  sdt = sdt.toDate()
                }
                meetingTime = moment.tz(sdt, this.profile.timezone).format('HH:mm')
              }
            }
          } else {
            meetingTime = '-'
          }
        }

        return {
          allMeetings,
          id: bestDay.id,
          am: bestDay.split ? bestDay.slot === 'AM' : false,
          pm: bestDay.split ? bestDay.slot === 'PM' : false,
          userId: bestDay.userId,
          meetingDetails,
          locationDetails,
          meetingTime,
          date: bestDay.date,
          pauseNotification,
          state: bestDay.state,
          type: 'people'
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    'scheduleSearchWeekDetail.starOfWeek': {
      handler () {
        this.setBestDaysCalculating(true)
      },
      immediate: true
    },
    pastPrefRec: {
      handler () {
        this.framePastPrefRec()
      }
    },
    preferences: {
      handler () {
        this.bestDayForInitiatives = []
        this.bestDayToMeetPeople = []
        if (this.preferences) {
          const preferences = { ...this.preferences[0] }
          // console.log('Recommendations.watch preferences', preferences)
          if (preferences.initSuggestions) {
            this.bestDayForInitiatives = Object.values(preferences.initSuggestions)
            this.bestDayForInitiatives.sort((a, b) => b.date - a.date)
          }
          if (preferences.userSuggestions) {
            this.bestDayToMeetPeople = Object.values(preferences.userSuggestions)
          }
          if (preferences.pausedNotifications) {
            this.pausedNotifications = Object.values(preferences.pausedNotifications)
          }
          this.getInitiativeRecommendations()
          this.getPeopleRecommendations()
          const self = this
          this.$nextTick(() => {
            self.frameRecommendationsByDate()
          })
        }
      },
      immediate: true,
      deep: true
    },
    meetings: {
      handler () {
        if (this.meetings && this.meetingsRec && this.preferences) {
          this.getPeopleRecommendations()
          const self = this
          this.$nextTick(() => {
            self.frameRecommendationsByDate()
          })
        }
      }
    },
    meetingsRec: {
      handler () {
        if (this.meetings && this.meetingsRec && this.preferences) {
          this.getPeopleRecommendations()
          const self = this
          this.$nextTick(() => {
            self.frameRecommendationsByDate()
          })
        }
      }
    },
    allRecommendationsByDate: {
      handler () {
        if (Object.keys(this.allRecommendationsByDate).length > 0) {
          const dateKeys = Object.keys(this.allRecommendationsByDate)
          this.datesOpened = []
          for (const dk of dateKeys) {
            if (this.allRecommendationsByDate[dk].recommendations.length > 0) {
              this.datesOpened.push(dk)
            }
          }
          this.calcNoAndFutureRecDates(dateKeys)
          this.handleResize()
          setTimeout(() => { this.setBestDaysCalculating(false) }, 300)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.recommendations-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .interest-section {
    position:relative;
    .interest-icon > img {
      width:18px;
    }
    .tool-tip {
      display: none;
    }
    &:hover {
      > .tool-tip {
        display: flex;
        bottom: 0;
        transform: translate(-45px, -20px);
        flex-direction: column;
        width: 100px;
      }
    }
  }

  .no-suggestions {
    color: #959EA7;
    margin-left: 18px;
  }

  .loading-recommendations {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 25px;
    .recommendations-list {
      margin: 10px 0;
      opacity: 0.24;
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-start;

      .no-rec-card-wrapper {
        margin-right:20px;
      }
    }
    .mdc-text {
      color: #959EA7;
    }
    &.schedule-calculating {
      &:after {
        margin-top: 10px;
        content: '  .';
        animation: dots 1s steps(5, end) infinite;
        font-weight: 600;
        font-size: 18px;
        color: #959EA7;
      }
    }
  }
  &.full-view {
    .rec-type-wrapper {
      .date-rec-wrapper {
        padding: 10px;
        .recommendations-list {
          // grid-template-columns: repeat( auto-fit, minmax(140px, auto));
        }
      }
    }
  }

  .rec-type-wrapper {
    &.submitted-wrapper {
      .date-rec-wrapper {
        .date-details {
          cursor: pointer;
        }
        &:hover {
          background: #F1F8FF;
          .rec-card-wrapper {
            background: white;
          }
        }
      }
    }
    &.not-submitted-wrapper {
      .date-rec-wrapper {
        .date-details {
          .rec-date {
            color: #cbcbcb;
            .date-format {
              font-weight: 400;
            }
          }
        }
      }
      .next-week-dates {
        padding: 25px 25px 10px 30px;
        border-top: 0.5px solid #f1f1f1;
        .next-dates {
          display: flex;
          flex-direction: column;
          .next-week-date-row {
            display:flex;
            .mdc-text {
              margin-right:10px;
            }
          }
          .date-text {
            text-transform: uppercase;
            margin-bottom: 10px;
          }
        }
      }
      .has-interest-message {
        position: relative;
        padding: 25px 25px 10px 30px;
        display: flex;
        flex-direction: column;
        z-index: 10;
      }
    }
    &.past-submitted-wrapper {
      .date-rec-wrapper {
        position: relative;
      }
      .past-rec-message {
        position: absolute;
        left: 15%;
        padding: 75px 25px 10px 30px;
        width:350px;
        display: flex;
        flex-direction: column;
        z-index: 10;
        .used-up-text {
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 18px;
        }
        .used-up-support-text {
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
      .next-week-dates {
        padding: 25px 25px 10px 30px;
        border-top: 0.5px solid #f1f1f1;
        .next-dates {
          display: flex;
          flex-direction: column;
          .next-week-date-row {
            display:flex;
            .mdc-text {
              margin-right:10px;
            }
          }
          .date-text {
            text-transform: uppercase;
            margin-bottom: 10px;
          }
        }
      }
    }
    .date-rec-wrapper {
      padding: 10px 25px 10px 30px;
      .date-details {
        display: flex;
        align-items: center;
        .rec-date {
          min-width: 130px;
          color: #404040;
          &.used {
            color: #cbcbcb;
          }
          .date-format {
            text-transform: uppercase;
            font-weight: 700;
          }
          &.rec-today {
            color: #C44040;
            font-weight: 700;
            font-size: 16px;
            .date-format {
              font-size: 14px;
              font-style: italic;
              font-weight: 400;
              text-transform: initial;
            }
          }
          &.rec-tomo {
            font-weight: 700;
            font-size: 12px;
            .date-format {
              font-style: italic;
              font-weight: 400;
              text-transform: initial;
            }
          }
        }
        .rec-count {
          margin-left: 20px;
          display: flex;
          align-items: center;
          .count-type {
            color:#2B8AFB;
            font-weight: 600;
            margin-left: 5px;
            .rec-type-count {
              font-weight: 300;
            }
            &.count-zero {
              color: #828F9C;
              font-weight: 300;
            }
          }
        }
      }
      .no-bod-content {
        position: absolute;
        width: 350px;
        display: flex;
        flex-direction: column;
        height: 200px;
        left: 15%;
        padding: 40px 25px 10px 30px;
        color: #404040;
        z-index: 10;
        .no-bod-text {
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 18px;
        }
        .set-pref-text {
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .open-pref-link {
          font-weight: 700;
          font-size: 18px;
          color: #4175B1;
          cursor: pointer;
        }
      }
      .recommendations-list:not(:empty) {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(140px, 145px) );
        grid-gap: 14px;
        row-gap: 25px;
        margin: 10px 0;
        .days-list {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: flex-start;
        }
        &.past {
          opacity: 0.18;
        }
        &.blank {
          opacity: 1;
        }
      }
    }
  }
}
</style>
