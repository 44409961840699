<template>
  <div></div>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch'
import { connectStats } from 'instantsearch.js/es/connectors'

export default {
  mixins: [
    createWidgetMixin({ connector: connectStats })
  ],
  computed: {
    nbHits () {
      return this.state && this.state.nbHits
    }
  },
  watch: {
    nbHits () {
      this.$emit('nbHitsChange', this.nbHits)
    }
  }
}
</script>
