<template>
  <section class="pinned-details-content" v-if="((profile.pinnedTeams && profile.pinnedTeams.length > 0) || (profile.pinnedInitiatives && profile.pinnedInitiatives.length > 0) || (profile.pinnedProfiles && profile.pinnedProfiles.length > 0))">
    <template v-for="teamId in profile.pinnedTeams">
      <pinned-team :minProfilesDisplay="8" v-if="scheduleSearchQuery !== teamId" :key="'pinnedTeam' + teamId" :teamId="teamId" :forDate="scheduleSearchWeekDetail.selectedDay" />
     </template>
    <template v-for="initiativeId in profile.pinnedInitiatives">
      <pinned-team :minProfilesDisplay="8" v-if="scheduleSearchQuery !== initiativeId" :key="'pinnedTeam' + initiativeId" :initiativeId="initiativeId" :forDate="scheduleSearchWeekDetail.selectedDay" />
    </template>
    <template v-for="profileId in profile.pinnedProfiles">
      <pinned-profile-details v-if="scheduleSearchQuery !== profileId" :key="'pinnedProfile' + profileId" :profileId="profileId" :forDate="scheduleSearchWeekDetail.selectedDay" />
    </template>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PinnedProfileDetails from './PinnedProfileDetails.vue'
import PinnedTeam from './PinnedTeam.vue'
export default {
  name: 'PinnedSchedules',
  components: {
    PinnedProfileDetails,
    PinnedTeam
  },
  props: {
    scheduleSearchQuery: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  beforeDestroy () {},
  created () {},
  mounted () {},
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
    })
  },
  watch: {},
  computed: {
    ...mapState([
      'profile',
      'scheduleSearchWeekDetail'
    ]),
    ...mapState({
    })
  }
}
</script>

<style lang="scss">
.pinned-details-content {
}
</style>
