import TaskItem from '@tiptap/extension-task-item'

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

const CustomTaskItem = TaskItem.extend({

  addAttributes () {
    return {
      'data-task-id': {
        default: uuidv4()
      },
      checked: {
        default: false,
        parseHTML: element => ({
          checked: element.getAttribute('data-checked') === 'true'
        }),
        renderHTML: attributes => ({
          'data-checked': attributes.checked
        }),
        keepOnSplit: false
      }
    }
  },

  addKeyboardShortcuts () {
    const shortcuts = {
      Enter: () => {
        // console.log('CustomTaskItem.Enter')
        const textSelection = this.editor.view.state.selection
        const parent = textSelection.$anchor.parent
        // Test if its enter inside a noteblock
        const parentParent = textSelection.$anchor.path[textSelection.$anchor.path.length - 6]
        // console.log('CustomTaskItem.Enter', textSelection, parentParent)
        if (parentParent.type.name === 'taskItem') {
          // this.editor.commands.selectParentNode()
          // console.log('CustomTaskItem.enter', textSelection.$anchor.depth)
          if (this.editor.commands.insertNoteBlock) {
            if (parent.content.size === 0) {
              // console.log('CustomTaskItem.enter removing taskItem')
              return this.editor.chain()
                .lift()
                .run()
            } else if (textSelection.$anchor.depth <= 4) {
              // console.log('CustomTaskItem.enter no depth wrapping noteblock', textSelection)
              const offset = 8
              return this.editor.chain()
                .splitListItem('taskItem')
                .lift()
                .lift()
                .insertNoteBlock()
                .toggleTaskList()
                .selectParentNode()
                .selectParentNode()
                .selectParentNode()
                .selectParentNode()
                .lift()
                .focus(textSelection.$anchor.pos + offset)
                .run()
            } else {
              // console.log('CustomTaskItem.enter depth wrapping noteblock ')
              return this.editor.chain()
                .splitListItem('taskItem')
                .lift()
                .lift()
                .insertNoteBlock()
                .toggleTaskList()
                .run()
            }
          } else {
            return this.editor.chain()
              .splitListItem('taskItem')
              .run()
          }
        }
      },
      Backspace: () => {
        const selection = this.editor.view.state.selection
        const parent = selection.$anchor.parent
        const parentParent = selection.$anchor.path[selection.$anchor.path.length - 6]
        // console.log('CustomTaskItem', parentParent, parent.content.size)
        if (parentParent.type.name === 'taskItem') {
          if (parent.content.size === 0) {
            return this.editor.chain()
              .lift()
              .run()
          } else if (selection.$anchor.parentOffset === 0) {
            // console.log('CustomTaskItem.Backspace at start', selection)
            let focusPos = selection.$anchor.path[selection.$anchor.path.length - 7] - 4
            if (selection.$anchor.path[selection.$anchor.path.length - 8] === 1) {
              focusPos = focusPos + 1
            }
            this.editor.chain()
              .selectParentNode()
              .lift()
              .run()
            const parentSelection = this.editor.view.state.selection
            this.editor.commands.selectParentNode() // out to NoteBlock

            if (this.editor.state.selection.$anchor.nodeBefore) {
              let nodeBefore = this.editor.state.selection.$anchor.nodeBefore
              if (nodeBefore.lastChild.type.name === 'noteblock') {
                while (nodeBefore.lastChild && nodeBefore.lastChild.type.name === 'noteblock') {
                  focusPos = focusPos - 1
                  nodeBefore = nodeBefore.lastChild
                }
              }
              if (this.editor.state.selection.$anchor.nodeBefore.type.name === 'noteblock') {
                const text = nodeBefore.textContent
                if (!('' + text).endsWith(' ')) {
                  focusPos = focusPos + 1
                }
              }
            }

            this.editor.commands.joinNoteBlockContentBackwards(selection, parentSelection)
            this.editor.chain()
              .focus(focusPos)
              .run()
            return true
          }
        }
        return false
      }
    }
    return shortcuts
  },
  addNodeView () {
    return ({
      node,
      HTMLAttributes,
      getPos,
      editor
    }) => {
      const { view } = editor
      const listItem = document.createElement('li')
      const checkbox = document.createElement('input')
      const content = document.createElement('div')

      checkbox.type = 'checkbox'
      checkbox.contentEditable = 'false'
      checkbox.addEventListener('change', event => {
        const { checked } = event.target

        if (typeof getPos === 'function') {
          // console.log('CustomTaskIteam.addNodeView.checked.change', checked, node.attrs)
          view.dispatch(view.state.tr.setNodeMarkup(getPos(), undefined, {
            'data-task-id': node.attrs['data-task-id'],
            checked
          }))
          editor.commands.focus()
        }
      })

      if (node.attrs.checked) {
        // console.log('CustomTaskIteam.addNodeView.checked')
        checkbox.setAttribute('checked', 'checked')
      }

      listItem.append(checkbox, content)

      Object.entries(HTMLAttributes).forEach(([key, value]) => {
        listItem.setAttribute(key, value)
      })

      return {
        dom: listItem,
        contentDOM: content,
        update: updatedNode => {
          if (updatedNode.type !== this.type) {
            return false
          }
          // console.log('CustomTaskIteam.addNodeView.checked.update', updatedNode.attrs.checked)
          if (updatedNode.attrs.checked) {
            checkbox.setAttribute('checked', 'checked')
            listItem.setAttribute('data-checked', 'true')
          } else {
            checkbox.removeAttribute('checked')
            listItem.removeAttribute('data-checked')
          }

          return true
        }
      }
    }
  }
})

export default CustomTaskItem
