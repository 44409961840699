<template>
  <div class="composer">
    <section class="sub-section-types">
      <!-- <mdc-button :class="composerView === 'default' || composerView === 'dailyPage' ? 'clicked' : ''" class="notes-header header-link" @click.native="changeComposerSection('default')">
        <base-svg-icon :name="composerView === 'default' || composerView === 'dailyPage' ? 'notes-icon-404040' : 'notes-icon-6184ad'" />
        <mdc-text tag="span" typo="body2">NOTES</mdc-text>
      </mdc-button> -->
      <mdc-button :class="composerView === 'allTasks' || composerView === 'default' ? 'clicked' : ''" class="alltasks-header header-link" @click.native="changeComposerSection('allTasks')">
        <base-svg-icon :name="composerView === 'allTasks' ? 'tasks-icon-404040' : 'tasks-icon-6184ad'" />
        <mdc-text tag="span" typo="body2">TASKS</mdc-text>
      </mdc-button>
    </section>
    <template v-if="composerView === 'allTasks' || composerView === 'default'">
      <tasks-count-details :plannedTasks="plannedTasks" :unplannedTasks="unplannedTasks" />
      <planned-unplanned-tasks @passPlannedTasks="plannedTasksToCountDetails" @passUnplannedTasks="unplannedTasksToCountDetails" class="all-tasks" :date="date" v-bind:class="{ 'fullscreen-mode': expandedView }" />
    </template>
    <div v-else-if="composerView === 'dailyPage'">
      <div>
        <daily-page
          disableNoteBlockSharing
          :formatIconPosition="formatIconPosition"
          :settingsIconPosition="settingsIconPosition"
          @closeFormatControlsMenu="closeFormatControlsMenu"
          @closeSettingsMenu="closeSettingsMenu"
          v-if="dailyPage"
          :composerView="composerView"
          :openFormatControls="openFormatControls"
          :openSettings="openSettings"
          :key="dailyPage.id"
          :teamMentions="teamMentions"
          :forDate="selectedDate"
          :dailyPage="dailyPage"
          :expandedView="expandedView"
          />
      </div>
    </div>
    <div v-else-if="composerView === 'notesPage'">
      <note-page
      v-if="notePage"
      disableNoteBlockSharing
      :formatIconPosition="formatIconPosition"
      :settingsIconPosition="settingsIconPosition"
      @closeFormatControlsMenu="closeFormatControlsMenu"
      @closeSettingsMenu="closeSettingsMenu"
      :composerView="composerView"
      :openFormatControls="openFormatControls"
      :openSettings="openSettings"
      :teamMentions="teamMentions"
      :notePage="notePage"
      :noteLabel="composerData.label"
      :expandedView="expandedView" />
    </div>
    <div v-else-if="composerView === 'meetingPage'">
      <meeting-notes
      v-if="meetingPage"
      disableNoteBlockSharing
      :formatIconPosition="formatIconPosition"
      :settingsIconPosition="settingsIconPosition"
      @closeFormatControlsMenu="closeFormatControlsMenu"
      @closeSettingsMenu="closeSettingsMenu"
      :composerView="composerView"
      :openFormatControls="openFormatControls"
      :openSettings="openSettings"
      :teamMentions="teamMentions"
      :meetingPage="meetingPage"
      :expandedView="expandedView"
      :meetingDetails="meetingDetails[0]" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NotePage from '../../components/flexibly/NotePage.vue'
import DailyPage from '../../components/flexibly/DailyPage.vue'
import MeetingNotes from './MeetingNotes.vue'
import { sameDay } from '../../utils/time'
import PlannedUnplannedTasks from '../../components/flexibly/PlannedUnplannedTasks'
import BaseSvgIcon from '../../components/flexibly/BaseSvgIcon.vue'
import TasksCountDetails from './TasksCountDetails.vue'
export default {
  name: 'Composer',
  components: {
    NotePage,
    DailyPage,
    MeetingNotes,
    PlannedUnplannedTasks,
    BaseSvgIcon,
    TasksCountDetails
  },
  props: {
    showMentionsSideBar: {
      type: Boolean,
      default: false
    },
    formatIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    settingsIconPosition: {
      type: Object,
      default: () => {
        return {}
      }
    },
    composerView: {
      type: String,
      default: 'default'
    },
    composerData: {
      type: Object,
      default: () => {}
    },
    date: {
      type: Object,
      default: () => {}
    },
    expandedView: {
      type: Boolean,
      default: false
    },
    openFormatControls: {
      type: Boolean,
      default: false
    },
    openSettings: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      composerEditor: null,
      notePage: null,
      dailyPage: null,
      meetingPage: null,
      debouncedNotePageUpdate: null,
      selectedDate: new Date(),
      defaultViewEnabled: false,
      meetingDetails: null,
      plannedTasks: null,
      unplannedTasks: null
    }
  },
  beforeDestroy () {
  },
  mounted () {
    // console.log('Composer.mounted')
    if (this.composerView === 'default') {
      this.dailyPage = null
      // load daily page
      this.$nextTick(async () => {
        // console.log('composer.mounted.dailyPage', this.profile.team, this.selectedDate)
        this.dailyPage = await this.getDailyPage({ teamId: this.profile.team, date: this.selectedDate })
      })
    }
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'tasks'
    ]),
    ...mapState({
      teamNotes: state => state.teams.teamNotes,
      teamProfiles: state => state.teams.teamProfiles,
      tempos: state => state.teams.tempos
    }),
    ...mapGetters({
      getLinkedMentions: 'teams/getLinkedMentions'
    }),
    teamMentions () {
      if (this.teamProfiles) {
        return this.teamProfiles.map((prof) => {
          return { ...prof, name: prof.displayName, initials: this.initials(prof) }
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      getNotePage: 'teams/getNotePage',
      getDailyPage: 'teams/getDailyPage',
      getMeetingPage: 'teams/getMeetingPage'
    }),
    plannedTasksToCountDetails (taskGroup) {
      // console.log('composer', taskGroup)
      this.plannedTasks = taskGroup
    },
    unplannedTasksToCountDetails (taskGroup) {
      this.unplannedTasks = taskGroup
    },
    changeComposerSection (type) {
      this.$root.$emit('changeComposerView', type)
    },
    setComposerEditor (editor) {
      this.composerEditor = editor
    },
    closeFormatControlsMenu (value) {
      this.$emit('closeFormatMenu', value)
    },
    closeSettingsMenu (value) {
      // console.log('Composer.closeSettingsMenu', value)
      this.$emit('closeSettingsMenu', value)
    },
    initials (profile) {
      if (profile) {
        let initialFirst = ''
        let initialLast = ''
        let displayNameParts = ['', '']
        displayNameParts = profile.displayName.split(' ')
        if (profile.firstName) {
          initialFirst = profile.firstName.trim().substring(0, 1)
        } else {
          initialFirst = displayNameParts[0].substring(0, 1)
        }
        if (profile.lastName) {
          initialLast = profile.lastName.substring(0, 1)
        } else {
          if (displayNameParts.length > 1) {
            initialLast = displayNameParts[1].substring(0, 1)
          }
        }
        return `${initialFirst}${initialLast}`.toUpperCase()
      }
      return 'U'
    },
    closeTempo () {
      this.$root.$emit('changeComposerView', 'default', 'COMPOSER')
    }
  },
  watch: {
    teamNotes: {
      handler (newData, oldData) {
        if (!oldData) {
          if (this.teamNotes) {
            const self = this
            setTimeout(() => {
              self.defaultViewEnabled = true
            }, 200)
          }
        }
      }
    },
    composerData: {
      handler (newData, oldData) {
        // set mentions and comments to blank for new composer view / data
        let composerData = { linkedMentions: [], comments: [] }
        if (this.composerData) {
          composerData = { ...this.composerData, ...composerData }
        }
        this.$emit('setComposerData', composerData)

        // console.log('Composer.watch composerData', this.composerView, this.composerData)
        if (this.composerView === 'default' || this.composerView === 'dailyPage') {
          // console.log('Composer.watch composerData.isDefault')
          if (this.composerData) {
            // console.log('Composer.watch composerData.date', this.selectedDate, this.composerData.date)
            if (this.composerData.date) {
              if (!sameDay(this.selectedDate, this.composerData.date)) {
                this.selectedDate = this.composerData.date
                this.dailyPage = null
                // load daily page
                this.$nextTick(async () => {
                  // console.log('composer.composerData.getDailyPage', this.profile.team, this.selectedDate)
                  this.dailyPage = await this.getDailyPage({ teamId: this.profile.team, date: this.selectedDate })
                })
              }
            }
          }
        } else if (this.composerView === 'notesPage') {
          this.notePage = null
          this.getNotePage({ teamId: this.profile.team, notePageName: this.composerData.label, notePageLink: this.composerData.link }).then((notePage) => {
            this.notePage = notePage
            const linkedMentions = this.getLinkedMentions(this.notePage)
            this.$emit('setComposerData', { linkedMentions, notesSearch: true })
          })
        } else if (this.composerView === 'meetingPage') {
          this.meetingPage = null
          // console.log('Composer.watch.composerData meetingPage', this.composerData)
          this.getMeetingPage({
            teamId: this.profile.team,
            meetingPageName: this.composerData.label,
            meetingPageLink: this.composerData.link
          }).then((meetingPage) => {
            this.meetingPage = meetingPage
            this.meetingDetails = this.composerData.meetingDetails
            const linkedMentions = this.getLinkedMentions(this.meetingPage)
            this.$emit('setComposerData', { linkedMentions })
          })
        }
      },
      deep: true
    },
    composerView: {
      handler (newView, oldView) {
        // set mentions and comments to blank for new composer view / data
        let composerData = { linkedMentions: [], comments: [] }
        if (this.composerData) {
          composerData = { ...this.composerData, ...composerData }
        }
        this.$emit('setComposerData', composerData)

        // console.log('Composer.watch composerView', this.composerView, this.composerData)
        if (newView === 'default') {
          if (this.composerData) {
            if (this.composerData.date) {
              if (!sameDay(this.selectedDate, this.composerData.date)) {
                this.selectedDate = this.composerData.date
                this.dailyPage = null
                // load daily page
                this.$nextTick(async () => {
                  // console.log('composer.composerView.dailyPage', this.profile.team, this.selectedDate)
                  this.dailyPage = await this.getDailyPage({ teamId: this.profile.team, date: this.selectedDate })
                })
              }
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.composer {
  .sub-section-types {
    display: flex;
    margin: 0 30px 0 22px;
    border-bottom: 0.5px solid #f1f1f1;
    align-items: center;
    max-width: 220px;
    min-width: 120px;
    width: max-content;
    .header-link {
      height: 30px;
      padding: 0;
      min-width: 100px;
      .icon-wrapper {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .mdc-text {
        color: #6184AD;
        font-weight: 600;
      }
      &.clicked {
        background: #F1F1F1;
        border-radius: 4px 4px 0px 0px;
        .mdc-text {
          color: #404040;
        }
      }
    }
  }
}
</style>
