<template>
  <div class="panel-content-wrapper" :class="{
    'closed': !panelClicked,
    'opened': panelClicked
    }" role="content">
    <div class="header-wrapper" @click.stop="togglePanel()" role="panel">
      <span class="new-notification-icon-text">
        <base-svg-icon name="new-notification" />
        <mdc-text v-if="allUnreadNotifications.length > 0" tag="span" typo="caption" class="new-text">New!</mdc-text>
      </span>
      <header class="panel-header">
        <mdc-text tag="span" class="notification-centre text" typo="caption">NOTIFICATIONS</mdc-text>
        <span class="settings-icon-text" @click.stop="settingsClick()" role="figure" aria-labelledby="settings">
          <base-svg-icon name="settings-black" />
        </span>
      </header>
    </div>
    <template v-if="showSection === 'notification'">
      <section class="header-tabs">
        <mdc-tab-bar class="mobile-vertical tab-types">
          <mdc-tab :class="{clicked: tabSelected === section.all}" @click.native.stop="tabSelection(section.all)">All</mdc-tab>
          <mdc-tab :class="{clicked: tabSelected === section.location}" @click.native.stop="tabSelection(section.location)">Location</mdc-tab>
          <mdc-tab :class="{clicked: tabSelected === section.tasks}" @click.native.stop="tabSelection(section.tasks)">Tasks</mdc-tab>
          <mdc-tab :class="{clicked: tabSelected === section.requests}" @click.native.stop="tabSelection(section.requests)">Requests</mdc-tab>
        </mdc-tab-bar>
      </section>
      <template v-if="tabSelected === 'all'">
        <div class="wrapper-all" style="max-height: 600px;overflow-y: auto;">
          <section class="notification-type no-notifications" v-if="unreadNotifications.length === 0 && readNotifications.length === 0">
            <header class="no-notif-header">
              <mdc-text tag="span" typo="body2">No notifications</mdc-text>
            </header>
          </section>
          <section class="notification-type" v-if="unreadNotifications.length > 0">
            <header class="new-header" >
                <mdc-text tag="span" typo="caption" class="new-label">
                  NEW
                  <mdc-text tag="span" typo="body2"> {{unreadNotifications.length}} of {{allUnreadNotifications.length}}</mdc-text>
                </mdc-text>
                <mdc-text tag="span" typo="caption" class="mark-all-label" @click.native="markAllAsRead('all')">Mark all as read</mdc-text>
            </header>
            <div class="all unread-notif-wrapper notifications-wrapper">
              <template v-for="notification in unreadNotifications">
                <div v-if="notification.type === 'task'" class="notification-details new" :key="'all-task-' + notification.id" @click.stop="navigateTo('tasks', notification)">
                  <base-svg-icon name="round-bg-task" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                        <mdc-text tag="span" typo="body2" class="text notification-title-line task-type" v-html="notification.title">
                        </mdc-text>
                        <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line" >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-if="notification.type === 'teamupdate'" class="notification-details new" :key="'all-teamupdates-' + notification.id" @click.stop="navigateTo('teamUpdates', notification)">
                  <base-svg-icon name="round-bg-task" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                        <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                        </mdc-text>
                        <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'invite'" class="notification-details new team-request" :key="'all-invites-' + notification.id" @click.stop="navigateTo('invites', notification)">
                  <base-svg-icon name="jointeam-request" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                        <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                        </mdc-text>
                        <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'inviteResponse'" class="notification-details new team-request" :key="'all-inviteResponses-' + notification.id" @click.stop>
                  <base-svg-icon name="jointeam-request" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                        <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                        </mdc-text>
                        <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'request'" class="notification-details new team-request" :key="'all-requests-' + notification.id" @click.stop="navigateTo('requests', notification)">
                  <base-svg-icon name="jointeam-request" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                        <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                        </mdc-text>
                        <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'schedule' || notification.type === 'schedule-confirmation'" class="notification-details new" :key="'all-schedule-' + notification.id" @click.stop="navigateTo('preferencesInput', notification)">
                  <base-svg-icon name="update-gray" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                        <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                        </mdc-text>
                        <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'teamupdate-request'" class="notification-details update-request new" :key="'all-teamupdate-request-' + notification.id" @click.stop="navigateTo('teamUpdates', notification)">
                  <base-svg-icon name="update-gray" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                        <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                        </mdc-text>
                        <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'recommendation'" class="notification-details" :class="{new: !notification.read, previous: notification.read}" :key="notification.id" @click.stop="navigateTo('location', notification)">
                  <base-svg-icon name="round-bg-t" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                        <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                        </mdc-text>
                        <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
              </template>
            </div>
          </section>
          <section class="notification-type" v-if="readNotifications.length > 0">
            <header class="previous-header">
              <mdc-text tag="span" typo="caption">PREVIOUS</mdc-text>
            </header>
            <div class="all read-notif-wrapper notifications-wrapper">
              <template v-for="notification in readNotifications">
                <div v-if="notification.type === 'task'" class="notification-details previous" :key="notification.id" @click.stop="navigateTo('tasks', notification)">
                  <base-svg-icon name="round-bg-task" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                      <mdc-text tag="span" typo="body2" class="text notification-title-line task-type" v-html="notification.title" >
                      </mdc-text>
                      <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'teamupdate'" class="notification-details previous" :key="'all-teamupdates-' + notification.id" @click.stop="navigateTo('teamUpdates', notification)">
                  <base-svg-icon name="round-bg-task" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                      <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                        {{ getPlainText(notification.title) }}
                      </mdc-text>
                      <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'request'" class="notification-details previous" :key="'all-requests-' + notification.id" @click.stop="navigateTo('requests', notification)">
                  <base-svg-icon name="jointeam-request" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                      <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                      </mdc-text>
                      <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'schedule' || notification.type === 'schedule-confirmation'" class="notification-details previous" :key="'all-schedule-' + notification.id" @click.stop="navigateTo('preferencesInput', notification)">
                  <base-svg-icon name="update-gray" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                      <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                      </mdc-text>
                      <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'teamupdate-request'" class="notification-details update-request previous" :key="'all-teamupdate-request-' + notification.id" @click.stop="navigateTo('teamUpdates', notification)">
                  <base-svg-icon name="update-gray" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                      <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                          {{ getPlainText(notification.title) }}
                      </mdc-text>
                      <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
                <div v-else-if="notification.type === 'recommendation'" class="notification-details previous" :class="{new: !notification.read, previous: notification.read}" :key="'recN-' + notification.id" @click.stop="navigateTo('location', notification)">
                  <base-svg-icon name="round-bg-t" />
                  <div class="notification-text">
                    <div class="title-time-wrapper">
                      <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                        {{ getPlainText(notification.title) }}
                      </mdc-text>
                      <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                    </div>
                    <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
                  </div>
                  <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
                  <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
                </div>
              </template>
            </div>
          </section>
          <span class="see-button" v-if="allUnreadNotifications.length > unreadNotificationsDisplay || allReadNotifications.length > readNotificationsDisplay" @click.stop="newShowMore('all')">
            <mdc-button class="see-more text" >Show More</mdc-button>
          </span>
        </div>
      </template>
    </template>
    <template v-if="tabSelected === 'tasks'">
      <div class="notification-types-wrapper">
        <section class="notification-type tasks no-tasks" v-if="unreadTaskNotifications.length === 0 && readTaskNotifications.length === 0">
          <div class="no-notif-header">
            <mdc-text tag="span" typo="body2">No tasks notifications</mdc-text>
          </div>
        </section>
        <section class="notification-type tasks unread-tasks" v-if="unreadTaskNotifications.length > 0">
          <header class="new-header">
            <mdc-text tag="span" typo="caption" class="new-label">
              NEW
              <mdc-text tag="span" typo="body2"> {{unreadTaskNotifications.length}} of {{allUnreadTaskNotifications.length}}</mdc-text>
            </mdc-text>
            <mdc-text tag="span" typo="caption" class="mark-all-label" @click.native="markAllAsRead('tasks')">Mark all as read</mdc-text>
          </header>
          <div class="tasks unread-notif-wrapper notifications-wrapper">
            <div v-for="notification in unreadTaskNotifications" class="notification-details" :class="{new: !notification.read, previous: notification.read}" :key="notification.id" @click.stop="navigateTo('tasks', notification)">
              <base-svg-icon name="round-bg-task" />
              <div class="notification-text">
                <div class="title-time-wrapper">
                  <mdc-text v-if="notification.title" tag="span" typo="body2" class="text notification-title-line task-type" v-html="notification.title"></mdc-text>
                  <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                </div>
                <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.title) }}</mdc-text>
              </div>
              <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
              <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
            </div>
          </div>
        </section>
        <section class="notification-type tasks read-tasks" v-if="readTaskNotifications.length > 0">
          <header class="previous-header" v-if="readTaskNotifications.length > 0">
            <mdc-text tag="span" typo="caption">PREVIOUS</mdc-text>
          </header>
          <div class="tasks read-notif-wrapper notifications-wrapper">
            <div v-for="notification in readTaskNotifications" class="notification-details" :class="{new: !notification.read, previous: notification.read}" :key="notification.id" @click.stop="navigateTo('tasks', notification)">
              <base-svg-icon name="round-bg-task" />
              <div class="notification-text">
                <div class="title-time-wrapper">
                  <mdc-text v-if="notification.title" tag="span" typo="body2" class="text notification-title-line task-type" v-html="notification.title"></mdc-text>
                  <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                </div>
                <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.title) }}</mdc-text>
              </div>
              <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
              <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
            </div>
          </div>
        </section>
        <span class="see-button" v-if="allReadTaskNotifications.length > readNotificationsDisplay || allUnreadTaskNotifications.length > unreadNotificationsDisplay" @click.stop="newShowMore('tasks')">
          <mdc-button class="see-more text" >Show More</mdc-button>
        </span>
      </div>
    </template>
    <template v-if="tabSelected === 'updates'">
      <div class="notification-types">
        <section class="notification-type updates no-updates" v-if="unreadUpdatesNotifications.length === 0 && readUpdatesNotifications.length === 0">
          <div class="no-notif-header">
            <mdc-text tag="span" typo="body2">No updates notifications</mdc-text>
          </div>
        </section>
        <section class="notification-type updates unread-updates" v-if="unreadUpdatesNotifications.length > 0">
          <header class="new-header">
            <mdc-text tag="span" typo="caption" class="new-label">
              NEW
              <mdc-text tag="span" typo="body2"> {{unreadUpdatesNotifications.length}} of {{allUnreadUpdatesNotifications.length}}</mdc-text>
            </mdc-text>
            <mdc-text tag="span" typo="caption" class="mark-all-label" @click.native="markAllAsRead('updates')">Mark all as read</mdc-text>
          </header>
          <div class="updates unread-notif-wrapper notifications-wrapper">
            <div v-for="notification in unreadUpdatesNotifications" class="notification-details" :class="{new: !notification.read, previous: notification.read}" :key="notification.id" @click.stop="navigateTo('teamUpdates', notification)">
              <base-svg-icon v-if="notification.type === 'teamupdate'" name="round-bg-task" />
              <base-svg-icon v-else name="update-gray" />
              <div class="notification-text">
                <div class="title-time-wrapper">
                  <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                      {{ getPlainText(notification.title) }}
                  </mdc-text>
                  <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                </div>
                <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
              </div>
              <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
              <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
            </div>
          </div>
        </section>
        <section class="notification-type updates read-updates" v-if="readUpdatesNotifications.length > 0">
          <header class="previous-header">
            <mdc-text tag="span" typo="caption">PREVIOUS</mdc-text>
          </header>
          <div class="updates read-notif-wrapper notifications-wrapper">
            <div v-for="notification in readUpdatesNotifications" class="notification-details" :class="{new: !notification.read, previous: notification.read}" :key="notification.id" @click.stop="navigateTo('teamUpdates', notification)">
              <base-svg-icon v-if="notification.type === 'teamupdate'" name="round-bg-task" />
              <base-svg-icon v-else name="update-gray" />
              <div class="notification-text">
                <div class="title-time-wrapper">
                  <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                      {{ getPlainText(notification.title) }}
                  </mdc-text>
                  <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                </div>
                <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
              </div>
              <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
              <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
            </div>
          </div>
        </section>
        <span class="see-button" v-if="allUnreadUpdatesNotifications.length > unreadNotificationsDisplay || allReadUpdatesNotifications.length > readNotificationsDisplay" @click.stop="newShowMore('updates')">
          <mdc-button class="see-more text" >Show More</mdc-button>
        </span>
      </div>
    </template>
    <template v-if="tabSelected === 'location'">
      <div class="notification-types">
        <section class="notification-type location no-recommendations" v-if="allUnreadRecommendationsNotifications.length === 0 && allReadRecommendationsNotifications.length === 0">
          <div class="no-notif-header">
            <mdc-text tag="span" typo="body2">No location notifications</mdc-text>
          </div>
        </section>
        <section class="notification-type location unread-location" v-if="unreadRecommendationsNotifications.length > 0">
          <header class="new-header">
            <mdc-text tag="span" typo="caption" class="new-label">
              NEW
              <mdc-text tag="span" typo="body2"> {{unreadRecommendationsNotifications.length}} of {{allUnreadRecommendationsNotifications.length}}</mdc-text>
            </mdc-text>
            <mdc-text tag="span" typo="caption" class="mark-all-label" @click.native="markAllAsRead('location')">Mark all as read</mdc-text>
          </header>
          <div class="location unread-notif-wrapper notifications-wrapper">
            <div v-for="notification in unreadRecommendationsNotifications" class="notification-details" :class="{new: !notification.read, previous: notification.read}" :key="notification.id" @click.stop="navigateTo('location', notification)">
              <base-svg-icon name="round-bg-t" />
              <div class="notification-text">
                <div class="title-time-wrapper">
                  <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                    {{ getPlainText(notification.title) }}
                  </mdc-text>
                  <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                </div>
                <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
              </div>
              <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
              <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
            </div>
          </div>
        </section>
        <section class="notification-type location read-location" v-if="readRecommendationsNotifications.length > 0">
          <header class="previous-header">
            <mdc-text tag="span" typo="caption">PREVIOUS</mdc-text>
          </header>
          <div class="location read-notif-wrapper notifications-wrapper">
            <div v-for="notification in readRecommendationsNotifications" class="notification-details" :class="{new: !notification.read, previous: notification.read}" :key="notification.id" @click.stop="navigateTo('location', notification)">
              <base-svg-icon name="round-bg-t" />
              <div class="notification-text">
                <div class="title-time-wrapper">
                  <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                    {{ getPlainText(notification.title) }}
                  </mdc-text>
                  <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                </div>
                <mdc-text tag="span" typo="body2" class="text  notification-detail-line">{{ getPlainText(notification.detail) }}</mdc-text>
              </div>
              <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
              <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
            </div>
          </div>
        </section>
        <span class="see-button" v-if="allUnreadRecommendationsNotifications.length > unreadNotificationsDisplay || allReadRecommendationsNotifications.length > readNotificationsDisplay" @click.stop="newShowMore('location')">
          <mdc-button class="see-more text" >Show More</mdc-button>
        </span>
      </div>
    </template>
    <template v-if="tabSelected === 'requests'">
      <div class="notification-types">
        <section class="notification-type requests no-requests" v-if="allUnreadRequestsNotifications.length === 0 && allReadRequestsNotifications.length === 0">
          <div class="no-notif-header">
            <mdc-text tag="span" typo="body2">No requests notifications</mdc-text>
          </div>
        </section>
        <section class="notification-type requests unread-requests" v-if="unreadRequestsNotifications.length > 0">
          <header class="new-header" v-if="unreadRequestsNotifications.length > 0">
            <mdc-text tag="span" typo="caption" class="new-label">
              NEW
              <mdc-text tag="span" typo="body2"> {{unreadRequestsNotifications.length}} of {{allUnreadRequestsNotifications.length}}</mdc-text>
            </mdc-text>
            <mdc-text tag="span" typo="caption" class="mark-all-label" @click.native="markAllAsRead('requests')">Mark all as read</mdc-text>
          </header>
          <div class="requests unread-notif-wrapper notifications-wrapper">
            <div v-for="notification in unreadRequestsNotifications" class="team-request notification-details" :class="{new: !notification.read, previous: notification.read}" :key="notification.id" @click.stop="navigateTo('requests', notification)">
              <base-svg-icon name="jointeam-request" />
              <div class="notification-text">
                <div class="title-time-wrapper">
                  <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                      {{ getPlainText(notification.title) }}
                  </mdc-text>
                  <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                </div>
                <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
              </div>
              <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
              <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
            </div>
          </div>
        </section>
        <section class="notification-type requests read-requests" v-if="readRequestsNotifications.length > 0">
          <header class="previous-header" >
            <mdc-text tag="span" typo="caption">PREVIOUS</mdc-text>
          </header>
          <div class="requests read-notif-wrapper notifications-wrapper">
            <div v-for="notification in readRequestsNotifications" class="team-request notification-details" :class="{new: !notification.read, previous: notification.read}" :key="notification.id" @click.stop="navigateTo('requests', notification)">
              <base-svg-icon name="jointeam-request" />
              <div class="notification-text">
                <div class="title-time-wrapper">
                  <mdc-text tag="span" typo="body2" class="text notification-title-line" >
                      {{ getPlainText(notification.title) }}
                  </mdc-text>
                  <time-ago v-if="notification.activityDate" refresh long :datetime="getDate(notification.activityDate)"></time-ago>
                </div>
                <mdc-text tag="span" typo="body2" class="text  notification-detail-line"  >{{ getPlainText(notification.detail) }}</mdc-text>
              </div>
              <mdc-button v-if="notification.read" class="read-unread-button" @click.prevent.stop="markAsUnRead(notification)" raised>Mark as unread</mdc-button>
              <mdc-button v-else class="read-unread-button" @click.prevent.stop="markAsRead(notification)" raised>Mark as read</mdc-button>
            </div>
          </div>
        </section>
        <span class="see-button" v-if="allUnreadRequestsNotifications.length > unreadNotificationsDisplay || allReadRequestsNotifications.length > readNotificationsDisplay" @click.stop="newShowMore('requests')">
          <mdc-button class="see-more text" >Show More</mdc-button>
        </span>
      </div>
    </template>
    <template v-if="showSection === 'settings'">
      <notification-settings @emitCloseSettings="closeSettings" displayFor="panel" role="contentinfo" />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import BaseSvgIcon from '../flexibly/BaseSvgIcon'
import NotificationSettings from '../flexibly/NotificationSettings.vue'
import TimeAgo from '../TimeAgo.vue'
import { datesBetween } from '../../utils/time'
import moment from 'moment'

export default {
  name: 'NotificationPanel',
  components: {
    BaseSvgIcon,
    NotificationSettings,
    TimeAgo
  },
  created () {
  },
  beforeDestroy () {
    // remove any listeners
    document.removeEventListener('click', this.handleClickOutside)
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
      notifications: state => state.teams.notifications
    }),
    ...mapGetters({
      getTaskById: 'teams/getTaskById'
    }),
    unreadNotifications () {
      return this.notifications.filter((notif) => !notif.read).slice(0, this.unreadNotificationsDisplay)
    },
    readNotifications () {
      return this.notifications.filter((notif) => notif.read).slice(0, this.readNotificationsDisplay)
    },
    allUnreadNotifications () {
      return this.notifications.filter((notif) => !notif.read)
    },
    allReadNotifications () {
      return this.notifications.filter((notif) => notif.read)
    },
    unreadTaskNotifications () {
      return this.notifications.filter((notif) => notif.type === 'task' && !notif.read).slice(0, this.unreadNotificationsDisplay)
    },
    readTaskNotifications () {
      return this.notifications.filter((notif) => notif.type === 'task' && notif.read).slice(0, this.readNotificationsDisplay)
    },
    allUnreadTaskNotifications () {
      return this.notifications.filter((notif) => notif.type === 'task' && !notif.read)
    },
    allReadTaskNotifications () {
      return this.notifications.filter((notif) => notif.type === 'task' && notif.read)
    },
    unreadUpdatesNotifications () {
      return this.notifications.filter((notif) => (notif.type === 'teamupdate' || notif.type === 'teamupdate-request') && !notif.read).slice(0, this.unreadNotificationsDisplay)
    },
    readUpdatesNotifications () {
      return this.notifications.filter((notif) => (notif.type === 'teamupdate' || notif.type === 'teamupdate-request') && notif.read).slice(0, this.readNotificationsDisplay)
    },
    allUnreadUpdatesNotifications () {
      return this.notifications.filter((notif) => (notif.type === 'teamupdate' || notif.type === 'teamupdate-request') && !notif.read)
    },
    allReadUpdatesNotifications () {
      return this.notifications.filter((notif) => (notif.type === 'teamupdate' || notif.type === 'teamupdate-request') && notif.read)
    },
    unreadRecommendationsNotifications () {
      return this.notifications.filter((notif) => (notif.type === 'recommendation' || notif.type === 'schedule' || notif.type === 'schedule-confirmation') && !notif.read).slice(0, this.unreadNotificationsDisplay)
    },
    readRecommendationsNotifications () {
      return this.notifications.filter((notif) => (notif.type === 'recommendation' || notif.type === 'schedule' || notif.type === 'schedule-confirmation') && notif.read).slice(0, this.readNotificationsDisplay)
    },
    allUnreadRecommendationsNotifications () {
      return this.notifications.filter((notif) => (notif.type === 'recommendation' || notif.type === 'schedule' || notif.type === 'schedule-confirmation') && !notif.read)
    },
    allReadRecommendationsNotifications () {
      return this.notifications.filter((notif) => (notif.type === 'recommendation' || notif.type === 'schedule' || notif.type === 'schedule-confirmation') && notif.read)
    },
    unreadRequestsNotifications () {
      return this.notifications.filter((notif) => notif.type === 'request' && !notif.read).slice(0, this.unreadNotificationsDisplay)
    },
    readRequestsNotifications () {
      return this.notifications.filter((notif) => notif.type === 'request' && notif.read).slice(0, this.readNotificationsDisplay)
    },
    allUnreadRequestsNotifications () {
      return this.notifications.filter((notif) => notif.type === 'request' && !notif.read)
    },
    allReadRequestsNotifications () {
      return this.notifications.filter((notif) => notif.type === 'request' && notif.read)
    }
  },
  data () {
    return {
      readNotificationsDisplay: 30,
      unreadNotificationsDisplay: 30,
      notificationsSettings: {},
      showSection: 'notification',
      settingsClicked: false,
      panelClicked: false,
      tabSelected: 'all',
      section: {
        all: 'all',
        updates: 'updates',
        tasks: 'tasks',
        location: 'location',
        requests: 'requests'
      }
    }
  },
  watch: {
    tabSelected: {
      handler () {
        this.resetData()
      }
    }
  },
  methods: {
    ...mapActions([
      'setDisplayTask',
      'setTeamInvitesDisplay',
      'setDisplayUpdateComments',
      'setMemberRequestDisplay'
    ]),
    ...mapActions({
      updateNotification: 'teams/updateNotification',
      loadLookingForwardBlockComments: 'teams/loadLookingForwardBlockComments',
      getLookingForwardPageById: 'teams/getLookingForwardPageById',
      getNotePageById: 'teams/getNotePageById',
      setScheduleSearchWeekDetail: 'setScheduleSearchWeekDetail'
    }),
    getDate (dateObj) {
      if (dateObj) {
        if (dateObj.toDate) {
          return dateObj.toDate()
        }
      }
      return dateObj
    },
    resetData () {
      this.readNotificationsDisplay = 30
      this.unreadNotificationsDisplay = 30
    },
    getPlainText (text) {
      if (text) {
        const html = text
        const plainText = html.replace(/<[^>]+>/g, '')
        return plainText
      }
      return ''
    },
    newShowMore (subtype) {
      let unreadNotifications = []
      let readNotifications = []
      let allUnread = []
      let allRead = []
      switch (subtype) {
        case 'all':
          unreadNotifications = [...this.unreadNotifications]
          readNotifications = [...this.readNotifications]
          allUnread = [...this.allUnreadNotifications]
          allRead = [...this.allReadNotifications]
          break
        case 'location':
          unreadNotifications = [...this.unreadRecommendationsNotifications]
          readNotifications = [...this.readRecommendationsNotifications]
          allUnread = [...this.allUnreadRecommendationsNotifications]
          allRead = [...this.allReadRecommendationsNotifications]
          break
        case 'requests':
          unreadNotifications = [...this.unreadRequestsNotifications]
          readNotifications = [...this.readRequestsNotifications]
          allUnread = [...this.allUnreadRequestsNotifications]
          allRead = [...this.allReadRequestsNotifications]
          break
        case 'tasks':
          unreadNotifications = [...this.unreadTaskNotifications]
          readNotifications = [...this.readTaskNotifications]
          allUnread = [...this.allUnreadTaskNotifications]
          allRead = [...this.allReadTaskNotifications]
          break
        case 'updates':
          unreadNotifications = [...this.unreadUpdatesNotifications]
          readNotifications = [...this.readUpdatesNotifications]
          allUnread = [...this.allUnreadUpdatesNotifications]
          allRead = [...this.allReadUpdatesNotifications]
          break
        default:
          unreadNotifications = []
          readNotifications = []
          allUnread = []
          allRead = []
          break
      }
      let wrapperDom = null
      if (allUnread.length > unreadNotifications.length) {
        this.unreadNotificationsDisplay = this.unreadNotificationsDisplay + 30
        wrapperDom = document.querySelectorAll('.' + subtype + '.unread-notif-wrapper')
      } else if (allRead.length > readNotifications.length) {
        this.readNotificationsDisplay = this.readNotificationsDisplay + 30
        wrapperDom = document.querySelectorAll('.' + subtype + '.read-notif-wrapper')
      }
      if (wrapperDom && wrapperDom[0]) {
        setTimeout(() => {
          wrapperDom[0].scrollTo({ top: wrapperDom[0].scrollHeight, behavior: 'smooth' })
        }, 500)
      }
    },
    markAllAsRead (from) { // to revisit?
      if (from) {
        let notifications = []
        switch (from) {
          case 'all':
            notifications = [...this.unreadNotifications]
            break
          case 'location':
            notifications = [...this.unreadRecommendationsNotifications]
            break
          case 'requests':
            notifications = [...this.unreadRequestsNotifications]
            break
          case 'tasks':
            notifications = [...this.unreadTaskNotifications]
            break
          case 'updates':
            notifications = [...this.unreadUpdatesNotifications]
            break
          default:
            notifications = []
            break
        }
        notifications.forEach(element => {
          this.markAsRead(element)
        })
      }
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.panelClicked = false
        if (this.settingsClicked) {
          this.settingsClicked = false
          this.showSection = 'notification'
        }
      }
    },
    closeSettings () {
      this.settingsClicked = false
      this.showSection = 'notification'
      this.tabSelected = 'all'
    },
    settingsClick () {
      this.settingsClicked = true
      this.showSection = 'settings'
      this.tabSelected = ''
    },
    markAsUnRead (notification) {
      this.updateNotification({ notificationId: notification.id, update: { read: false } })
    },
    markAsRead (notification) {
      this.updateNotification({ notificationId: notification.id, update: { read: true } })
    },
    showMore (subtype, type) {
      let wrapperDom = null
      if (type === 'read') {
        this.readNotificationsDisplay = this.readNotificationsDisplay + 30
        wrapperDom = document.querySelectorAll('.' + subtype + '.read-notif-wrapper')
      } else if (type === 'unread') {
        this.unreadNotificationsDisplay = this.unreadNotificationsDisplay + 30
        wrapperDom = document.querySelectorAll('.' + subtype + '.unread-notif-wrapper')
      }
      if (wrapperDom && wrapperDom[0]) {
        setTimeout(() => {
          wrapperDom[0].scrollTo({ top: wrapperDom[0].scrollHeight, behavior: 'smooth' })
        }, 500)
      }
    },
    async getUpdateCommentsFromNotification (notification) {
      console.log('getUpdateCommentsFromNotification', notification)
      const noteBlockId = notification.noteBlockId
      const updateId = notification.updateId
      const update = { id: updateId }
      this.loadLookingForwardBlockComments({ noteBlockId })
      return { noteBlockId, update }
    },
    async getTaskFromNotification (notification) {
      if (notification.taskId) {
        const task = await this.getTaskById(notification.taskId)
        // console.log('getTaskFromNotification', notification.taskId, task)
        return task
      }
      return false
    },
    async navigateTo (view, notification) {
      console.log('navigateTo', view, notification.type)
      if (notification.read === false) {
        await this.markAsRead(notification)
      }
      this.togglePanel()
      if (view === 'requests') {
        this.setMemberRequestDisplay(true)
      } else if (view === 'invites') {
        this.setTeamInvitesDisplay(true)
      } else if (notification.type === 'teamupdate') {
        const updateComments = await this.getUpdateCommentsFromNotification(notification)
        this.setDisplayUpdateComments(updateComments)
      } else if (notification.type === 'schedule') {
        console.log(notification.weekCommencing)
        let editSchedule = false
        if (notification.weekCommencing) {
          try {
            const wc = moment(notification.weekCommencing.toDate()).add(1, 'days').startOf('isoWeek')
            const eow = moment(notification.weekCommencing.toDate()).add(1, 'days').endOf('isoWeek').startOf('day')
            const weekNumber = moment(notification.weekCommencing.toDate()).add(1, 'days').week()
            const selectedDay = moment(notification.weekCommencing.toDate()).add(1, 'days').startOf('isoWeek')
            const datesOfWeek = datesBetween(wc, eow)
            if (eow.isAfter(moment())) {
              // This notification is for a future week
              // Open right view as edit schedule
              editSchedule = true
            }
            const params = { startOfWeek: wc, endOfWeek: eow, weekNumber, selectedDay, datesOfWeek }
            console.log('navigateTo.schedule', JSON.stringify(params))
            this.setScheduleSearchWeekDetail(params)
          } catch (err) {
            console.log('navigateTo.schedule error wc', notification.weekCommencing, err)
          }
        }
        let rightView = 'weeklyPreferences'
        if (editSchedule) {
          rightView = { section: 'weeklyPreferences', weeklyPreferences: true }
        }
        if (this.$route.name === 'People') {
          this.$root.$emit('setRightSectionType', rightView)
        } else {
          this.$router.push({ name: 'People', params: { changeView: rightView } })
        }
      } else if (notification.type === 'schedule-confirmation') {
        if (notification.weekCommencing) {
          try {
            const wc = moment(notification.weekCommencing.toDate()).add(1, 'days').startOf('isoWeek')
            const eow = moment(notification.weekCommencing.toDate()).add(1, 'days').endOf('isoWeek').startOf('day')
            const weekNumber = moment(notification.weekCommencing.toDate()).add(1, 'days').week()
            const selectedDay = moment(notification.weekCommencing.toDate()).add(1, 'days').startOf('isoWeek')
            const datesOfWeek = datesBetween(wc, eow)
            const params = { startOfWeek: wc, endOfWeek: eow, weekNumber, selectedDay, datesOfWeek }
            console.log('navigateTo.schedule-confirmation', JSON.stringify(params))
            this.setScheduleSearchWeekDetail(params)
          } catch (err) {
            console.log('navigateTo.schedule-confirm error wc', notification.weekCommencing, err)
          }
        }
        if (this.$route.name === 'People') {
          this.$root.$emit('setRightSectionType', { section: 'bestOfficeDays' })
        } else {
          this.$router.push({ name: 'People', params: { changeView: 'bestOfficeDays' } })
        }
      } else if (view === 'tasks') {
        // this.$root.$emit('changeComposerView', 'allTasks', 'ALL TASKS')
        const task = await this.getTaskFromNotification(notification)
        console.log('navigateTo.task', notification.taskId, task)
        this.setDisplayTask(task)
      } else {
        const viewParam = notification.type === 'recommendation' ? 'bestOfficeDays' : view
        if (this.$route.name === 'People') {
          this.$root.$emit('setRightSectionType', viewParam)
        } else {
          this.$router.push({ name: 'People', params: { changeView: viewParam } })
        }
      }
    },
    togglePanel () {
      if (this.panelClicked) {
        this.panelClicked = false
        if (this.settingsClicked) {
          this.settingsClicked = false
          this.showSection = 'notification'
        }
        return
      }
      this.panelClicked = true
      this.tabSelected = 'all'
    },
    tabSelection (type) {
      this.tabSelected = type
    }
  }
}
</script>

<style lang="scss">
.panel-content-wrapper {
  &.closed {
    height: 5px;
    cursor: pointer;
    > *:not(.header-wrapper) {
      display: none;
    }
  }
  &.opened {
    min-height: 400px;
  }
  border-radius: 8px 8px 0 0;
  background: #fff;
  position: relative;
  .header-wrapper {
    .new-notification-icon-text {
      display: flex;
      position: absolute;
      right: 0;
      top: -16px;
      .new-text {
        color: #FFDE32;
      }
    }
    .panel-header {
      padding: 10px;
      display: flex;
      align-items: center;
      .settings-icon-text {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        .icon-wrapper {
          height: 16px;
          width: 16px;
          margin-left: 10px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        * {
          margin-right: 5px;
        }
      }
      .text {
        color: #828282;
        &.notification-centre {
          font-weight: 600;
          text-transform: uppercase;
        }
        &.focus-mode {
          background: #F4F4F4;
          border-radius: 4px;
          padding: 5px;
          font-weight: 600;
        }
      }
    }
  }
  .header-tabs {
    position: relative;
    height: 25px;
    border-bottom: 1px solid #CFDBE9;
    display: flex;
    align-items: center;
    .mdc-tab-bar {
      margin: 0;
      position: absolute;
      .mdc-tab {
        height: 27px;
        font-size: 12px;
        min-width: unset;
        margin-right: 0px;
        margin-left: 20px;
        flex: 0;
        padding: 0 6px;

        .mdc-tab__text-label {
          color: #00498D;
          font-size: 14px;
          text-transform: capitalize;
        }
        .mdc-tab-indicator {
          &--active {
            .mdc-tab-indicator__content {
              opacity: 0;
            }
          }
        }
        &:first-child {
          padding-left: 0;
        }
        &.clicked {
          .mdc-tab__text-label {
            color: #404040;
            font-weight: 700;
          }
        }
        &:first-of-type {
          margin-left: 20px;
        }
      }
    }
  }
  .notification-title-line {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 50px;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      margin:0;
      margin-left:10px;
    }
    &.task-type {
      white-space: nowrap;
      display: flex;
      * {
        white-space: nowrap;
      }
      b {
        margin-right: 4px !important;
        font-weight: 700;
      }
      p {
        margin-left: 4px;
        &::after,
        &::before {
          content: '\'';
          font-weight: 700;
        }
      }
    }
  }
  .notification-detail-line {
    margin-top: 5px;
    display:flex;
    p {
      margin:0;
    }
  }
  .panel-filters {
    display: flex;
    align-items: center;
    padding: 10px 30px;
    justify-content: space-between;
    .type {
      display: flex;
      align-items: center;
      .text {
        font-weight: 700;
      }
      *:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  .notification-type {
    header {
      padding: 10px 10px 10px 20px;
      &.new-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mark-all-label {
          color: #6184AD;
          cursor: pointer;
        }
        .new-label {
          font-weight: bold;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #FFDE32;
          .mdc-typography--body2 {
            color: #828282;
            padding-left:10px;
            text-transform: none;
            font-weight: 600;
          }
        }
      }
      &.previous-header {
        cursor: pointer;
        .mdc-text {
          color: #828282;
          font-weight: 600;
        }
      }
      &.no-notif-header {
        text-align: center;
        .mdc-text {
          color: #404040;
          font-weight: 600;
        }
      }
    }
    .no-notif-header {
      padding: 10px 10px 10px 20px;
      text-align: center;
      .mdc-text {
        color: #404040;
        font-weight: 600;
      }
    }
    .notification-details {
      display: flex;
      align-items: center;
      padding: 10px 25px 10px 25px;
      border-bottom: 2px solid white;
      background: rgba(255, 252, 225, 0.59);
      cursor: pointer;
      position: relative;
      &.team-request.new {
        background: rgba(242, 225, 255, 0.59);
      }
      *:not(:last-child) {
        margin-right: 10px;
      }
      .mdc-text {
        color: #404040;
        font-weight: 600;
      }
      .notification-text {
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 90%;
        .title-time-wrapper {
          display: flex;
          .timeago {
            opacity: 0.6;
            padding: 0;
          }
        }
      }
      .read-unread-button {
        opacity: 0;
        color: #6184AD;
        background: #FFFFFF;
        height: 20px;
        min-width: 110px;
        max-width: 160px;
        font-size: 10px;
        margin-left: auto;
        position: absolute;
        right: 0;
        margin-right: 20px;
      }
      &.previous {
        background: rgba(238, 246, 255, 0.33);
        .notification-text {
          .mdc-text {
            font-weight: 400;
          }
        }
        &:hover {
          background: #EEF6FF;
        }
      }
      &:hover {
        .read-unread-button {
          opacity: 1;
        }
      }
      .icon-wrapper {
        width: 24px;
        height: 24px;
        min-width: 24px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .notification-types {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .see-button {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.035) 0%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
    .text {
      color: #959EA7;
      mix-blend-mode: normal;
      font-weight: 700;
      text-transform: none;
      opacity: 0.53;
    }
  }
}
</style>
