<template>
  <div :id="'meeting-' + meeting.id + meeting.meetingSource" class="meeting"
    :style="{ 'background': gradientColor ? gradientColor : backgroundColor, 'border-color': borderColor }"
    @click.prevent.stop="openDisplayMeeting(meeting)"
    v-bind:class="{'clicked': displayMeeting.id === meeting.id || (ongoingMeetings.length > 0 && filterOngoingMeeting() && clickedMeeting === meeting.id), 'deleted': meeting.deleted, 'ongoing': filterOngoingMeeting(meeting.id), 'all-day-event' : allDay }"
    :title="getTitle()">
    <mdc-text tag="span" typo="caption" class="all-day-text" v-if="allDay">ALL DAY</mdc-text>
    <div class="subject-timings" :class="meetingDeclined ? 'strike' : ''" :style="windowWidth > 1180 ? 'min-width:300px' : 'min-width:200px'">
      <mdc-text @click.native.stop="redirectToCalendar()" :style="{ 'color': textColor }" v-if="!meeting.isAllDay" typo="caption" tag="span" class="meeting-time detail-type">{{ getTimeFormat(meeting.startDateTime) }} - {{ getTimeFormat(meeting.endDateTime) }}</mdc-text>
      <mdc-text :style="{ 'color': textColor, 'max-width': windowWidth > 1180 ? '300px' : '200px' }" typo="body2" @click.native.stop="redirectToCalendar()" tag="span" class="meeting-subject detail-type">{{meeting.subject}}</mdc-text>
      <base-svg-icon class="open-new-window" name="newwindow" />
    </div>
    <span class="location-status-bar" v-if="!allDay">
      <template v-if="othersInOffice === 0 && !userInOffice">
        <span class="status only-me show-full-radius"></span>
      </template>
      <template v-else>
        <span class="office status" v-if="percentInOffice !== 0" :style="{ 'width': percentInOffice + '%' }" :class="{'show-full-radius' : percentInOffice === 100 }"></span>
        <span class="remote status" v-if="percentNotInOffice !== 0" :style="{ 'width': percentNotInOffice + '%'}" :class="{'show-full-radius' : percentNotInOffice === 100 }"></span>
        <span class="nonworking status" v-if="percentNonWorking !== 0" :style="{ 'width': percentNonWorking + '%' }" :class="{ 'show-full-radius' : percentNonWorking === 100 }"></span>
        <span class="attendees" :class="{ first }" :id="meeting.id + 'attendees'" v-if="getMeetingAttendeesSchedule().length > 0">
          {{ getAttendeesNameInToolTip() }}
        </span>
      </template>
    </span>
    <div class="meeting-status" v-if="meeting.joinUrl && !meeting.isAllDay">
      <mdc-button class="join-button" v-if="showJoinIcon() || filterOngoingMeeting(meeting.id)"  @click.native="redirectToCalendar(meeting.joinUrl)">Join</mdc-button>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import { dateCustomFormat, inPastTime } from '../../utils/time'
import { getBGColor, getGradientColor } from '../../utils/colors'
export default {
  name: 'MeetingDetails',
  components: {
    BaseSvgIcon
  },
  data () {
    return {
      userInOffice: false,
      othersInOffice: 0,
      percentInOffice: 0,
      percentNotInOffice: 0,
      percentNonWorking: 0,
      clickedMeeting: '',
      attendeesSchedules: [],
      eventColor: null,
      backgroundColor: null,
      borderColor: null,
      gradientColor: null,
      textColor: null
    }
  },
  props: {
    first: {
      type: Boolean,
      default: false
    },
    allDay: {
      type: Boolean,
      default: false
    },
    windowWidth: {
      type: Number,
      default: 0
    },
    ongoingMeetings: {
      type: Array,
      default: () => {
        return []
      }
    },
    meeting: {
      type: Object,
      default: () => {
        return {}
      }
    },
    scheduleDate: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  beforeDestroy () {
  },
  mounted () {
  },
  computed: {
    ...mapState({
      profiles: state => state.profiles,
      profile: state => state.profile,
      displayMeeting: state => state.displayMeeting,
      schedules: state => state.teams.schedules,
      defaultSchedules: state => state.teams.defaultSchedules
    }),
    ...mapGetters([
      'getCalendarColor'
    ]),
    meetingAttendeesEmailAddress () {
      if (this.meeting) {
        const meeting = this.meeting
        const orgEmail = meeting.meetingSource === 'Google' ? meeting.organizer?.email : meeting.organizer?.address
        if (meeting.attendeeEmails) {
          if (!meeting.attendeeEmails.includes(orgEmail)) {
            const emailsWithOrganizer = [...meeting.attendeeEmails, orgEmail]
            return emailsWithOrganizer
          }
        }
        return meeting.attendeeEmails || []
      }
      return []
    },
    meetingAttendeesProfileIds () {
      if (this.meeting) {
        const meetingProfiles = []
        const meetingEmails = this.meetingAttendeesEmailAddress
        for (const meetingEmail of meetingEmails) {
          if (meetingEmail) {
            const meetingProfile = this.getMeetingAttendeesProfile(meetingEmail.toLowerCase())
            if (meetingProfile) {
              meetingProfiles.push(meetingProfile)
            }
          }
        }
        return meetingProfiles.map(pr => pr.id)
      }
      return []
    },
    meetingDeclined () {
      if (this.meeting) {
        if (this.meeting.meetingSource === 'Google') {
          if (this.meeting.attendees?.length > 0) {
            const selfStatus = this.meeting.attendees.find(meeting => meeting.self)
            return selfStatus?.responseStatus === 'declined'
          }
          return false
        } else {
          return this.meeting.responseStatus?.response === 'declined'
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      setDisplayMeeting: 'setDisplayMeeting'
    }),
    openDisplayMeeting (meeting) {
      if (this.displayMeeting) {
        this.setDisplayMeeting(false)
      }
      const self = this
      // const cAttendees = structuredClone(meeting.attendees)
      // const cMeeting = { ...meeting, attendees: cAttendees }
      setTimeout(() => {
        self.setDisplayMeeting(meeting)
      }, 10)
    },
    isMeetingPast () {
      return inPastTime(moment(this.meeting.endDateTime.toDate()))
    },
    isSubjectWithFlowFocus (meeting) {
      if ((/\b(focus)\b/i).test(meeting.subject) || (/\b(flow)\b/i).test(meeting.subject)) {
        return true
      }
      return false
    },
    isAttendeeEmailsSelf () {
      if (this.meeting.attendeeEmails.length > 0) {
        const googleEmail = this.profile.googleEmail ? this.profile.googleEmail.toLowerCase() : false
        const msEmail = this.profile.msEmail ? this.profile.msEmail.toLowerCase() : false
        const selfEmails = [googleEmail, msEmail]
        return this.meeting.attendeeEmails.every(email => selfEmails.includes(email.toLowerCase()))
      }
    },
    setLocationStatusBar () {
      if (this.attendeesSchedules.length > 0) {
        const allAttendees = [...this.attendeesSchedules]
        const attendeesInRemoteOrOffice = allAttendees.filter(attendee => attendee.workingAt === 'home' || attendee.workingAt === 'thirdspace' || attendee.workingAt === 'office' || attendee.workingAt === 'nonworking')
        const totalAttendees = attendeesInRemoteOrOffice.length
        const officeAttendees = allAttendees.filter(attendee => attendee.workingAt === 'office')
        const remoteAttendees = allAttendees.filter(attendee => attendee.workingAt === 'thirdspace' || attendee.workingAt === 'home')
        const nonworkingAttendees = allAttendees.filter(attendee => attendee.workingAt === 'nonworking')
        let inOffice, inRemote, nonworking
        if (totalAttendees === 0) {
          inOffice = 0
          inRemote = 0
          nonworking = 0
        } else {
          inOffice = Math.ceil((officeAttendees.length / totalAttendees) * 100)
          inRemote = Math.ceil((remoteAttendees.length / totalAttendees) * 100)
          nonworking = Math.ceil((nonworkingAttendees.length / totalAttendees) * 100)
        }
        this.percentInOffice = inOffice
        this.percentNotInOffice = inRemote
        this.percentNonWorking = nonworking
      }
    },
    async getProfileScheduleByDate (member) {
      if (this.meeting && this.meeting.startDateTime && this.scheduleDate) {
        let schDate = this.scheduleDate
        if (!schDate.format) {
          schDate = moment(schDate)
        }
        const dateDay = schDate.format('ddd').toUpperCase()
        const meetingTimeSlotAM = moment(this.meeting.startDateTime.toDate()).format('a') === 'am'
        const scheduleResponse = [...this.schedules].filter(schedule => schedule.fromId === member)
        let dayResponse = ''
        if (scheduleResponse.length > 0) {
          const memberDatedSchedule = scheduleResponse[0].schedules
          const daySchedule = memberDatedSchedule[dateDay]
          if (daySchedule) {
            if (daySchedule.split) {
              if (meetingTimeSlotAM) {
                dayResponse = daySchedule.amResponse || ''
              } else {
                dayResponse = daySchedule.pmResponse || ''
              }
            } else {
              dayResponse = daySchedule.response || ''
            }
          } else {
            dayResponse = ''
          }
        }
        if ((!dayResponse) && this.defaultSchedules) {
          const memberDefaultSchedules = [...this.defaultSchedules].filter(schedule => schedule.fromId === member && schedule.teamId === this.profile.team)
          if (memberDefaultSchedules.length > 0) {
            const memberDefaultSchedule = memberDefaultSchedules[0].schedules
            const defaultDaySchedule = memberDefaultSchedule[dateDay]
            if (defaultDaySchedule.split) {
              if (meetingTimeSlotAM) {
                dayResponse = defaultDaySchedule.amResponse || ''
              } else {
                dayResponse = defaultDaySchedule.pmResponse || ''
              }
            } else {
              dayResponse = defaultDaySchedule.response || ''
            }
          }
        }
        return dayResponse
      }
      return ''
    },
    getAttendeesSchedules () {
      this.othersInOffice = 0
      this.userInOffice = false
      if (this.meetingAttendeesProfileIds.length > 0) {
        const promises = this.meetingAttendeesProfileIds.map(async member => {
          const memberResponse = {}
          const response = await this.getProfileScheduleByDate(member).then((response) => {
            memberResponse.profileId = member
            memberResponse.workingAt = response
            if (member === this.profile.id) {
              this.userInOffice = response === 'office'
            } else if (response === 'office') {
              this.othersInOffice += 1
            }
            return memberResponse
          })
          return response
        })
        Promise.all(promises).then(responses => {
          this.attendeesSchedules = [] // clearing when adding everytime
          this.attendeesSchedules.push(...responses)
          this.setLocationStatusBar()
          this.getMeetingEventColors()
          return responses
        })
      } else {
        // No attendees
        this.attendeesSchedules = []
        this.setLocationStatusBar()
        this.getMeetingEventColors()
      }
    },
    async getMeetingEventColors () {
      const meeting = this.meeting
      if (this.filterOngoingMeeting()) {
        this.backgroundColor = '#FFF7F7'
        this.borderColor = '#FC5555'
        this.textColor = '#404040'
        this.gradientColor = null
        return
      }
      const calColor = await this.getCalendarColor(this.meeting.calendarId, this.meeting.meetingSource, this.meeting.subject)
      const bgColor = getBGColor(calColor)
      const gradientColor = getGradientColor(calColor)
      let selfStatus = meeting.attendees && meeting.attendees.find(attendee => attendee.self)
      if (meeting.meetingSource === 'Google') {
        selfStatus = selfStatus || (meeting.attendees?.length === 0 && meeting.organizer.self ? { responseStatus: 'accepted' } : '')
        if (selfStatus && selfStatus.responseStatus) {
          switch (selfStatus.responseStatus) {
            case 'accepted':
              this.backgroundColor = bgColor
              this.borderColor = 'transparent'
              this.textColor = '#404040'
              this.gradientColor = null
              break
            case 'tentative':
              this.backgroundColor = bgColor
              this.borderColor = 'transparent'
              this.textColor = '#404040'
              this.gradientColor = 'repeating-linear-gradient(45deg,' + gradientColor + ',' + gradientColor + ' 7px,' + bgColor + ' 7px,' + bgColor + ' 15px)'
              break
            case 'needsAction':
            case 'declined':
            default:
              this.backgroundColor = '#fff'
              this.borderColor = calColor
              this.textColor = calColor
              this.gradientColor = null
              break
          }
        }
      } else {
        if (meeting.responseStatus && meeting.responseStatus.response) {
          switch (meeting.responseStatus.response) {
            case 'organizer':
            case 'accepted':
              this.backgroundColor = bgColor
              this.borderColor = 'transparent'
              this.textColor = '#404040'
              this.gradientColor = null
              break
            case 'tentativelyAccepted':
              this.backgroundColor = bgColor
              this.borderColor = 'transparent'
              this.textColor = '#404040'
              this.gradientColor = 'repeating-linear-gradient(45deg,' + gradientColor + ',' + gradientColor + ' 7px,' + bgColor + ' 7px,' + bgColor + ' 15px)'
              break
            case 'declined':
            case 'notResponded':
            case 'none':
            default:
              this.backgroundColor = '#fff'
              this.borderColor = calColor
              this.textColor = calColor
              this.gradientColor = null
              break
          }
        }
      }
    },
    checkOtherConnectedUsers () {
      const meeting = this.meeting
      if (meeting && meeting.connectedUsers && meeting.connectedUsers.length > 0) {
        const otherUser = meeting.connectedUsers.find(profile => profile !== this.profile.id)
        if (otherUser) {
          return true
        }
        return false
      }
      return false
    },
    showJoinIcon () {
      const meeting = this.meeting
      return moment().isBetween(moment(meeting.startDateTime.toDate()).subtract(10, 'minutes'), moment(meeting.startDateTime.toDate())) || moment().isBetween(moment(meeting.startDateTime.toDate()), moment(meeting.endDateTime.toDate()))
    },
    redirectToCalendar (link) {
      // console.log(link, this.meeting.subject, this.meeting.joinUrl)
      if (link) {
        window.open(link, '_blank')
      } else {
        window.open(this.meeting.webLink, '_blank')
      }
    },
    getTimeFormat (timestamp) {
      return moment(timestamp.toDate()).format('HH:mm')
    },
    getTitle () {
      const meeting = this.meeting
      if (meeting.deleted) {
        return 'This Meeting has been removed'
      }
      return ''
    },
    getAttendeesNameInToolTip () {
      const attendeesSchedule = this.getMeetingAttendeesSchedule().map(schedule => schedule.profileId)
      const allProfiles = [this.profile, ...this.profiles]
      const names = allProfiles.filter(profile => attendeesSchedule.includes(profile.id)).map(profile => profile.displayName)
      // to revisit
      switch (names.length) {
        case 0:
          return ''
        case 1:
          if (names.includes(this.profile.displayName)) {
            return 'Only you are in the office'
          } else {
            return names[0] + ' is in the office'
          }
        case 2:
          if (names.includes(this.profile.displayName)) {
            const anotherAttendee = names.find(name => name !== this.profile.displayName)
            return anotherAttendee + ' is also in the office'
          } else {
            return names[0] + ' and ' + names[1] + ' are in the office'
          }
        case 3:
          if (names.includes(this.profile.displayName)) {
            const otherAttendees = names.filter(name => name !== this.profile.displayName)
            return otherAttendees[0] + ' and ' + otherAttendees[1] + ' are also in the office'
          } else {
            return names[0] + ', ' + names[1] + ' and ' + names[2] + ' are in the office'
          }
        case 4:
          if (names.includes(this.profile.displayName)) {
            const otherAttendees = names.filter(name => name !== this.profile.displayName)
            return otherAttendees[0] + ', ' + otherAttendees[1] + ' and ' + otherAttendees[2] + ' are also in the office'
          } else {
            return names[0] + ', ' + names[1] + ', ' + names[2] + ' and ' + names[3] + ' are in the office'
          }
        default:
          if (names.includes(this.profile.displayName)) {
            return 'More than ' + `${names.length - 1}` + ' other attendees are also in the office'
          } else {
            return 'More than ' + `${names.length - 1}` + ' other attendees are in the office'
          }
      }
    },
    getMeetingAttendeesSchedule () {
      return this.attendeesSchedules.filter(schedule => {
        return this.meetingAttendeesProfileIds.includes(schedule.profileId) && schedule.workingAt === 'office'
      })
    },
    getMeetingAttendeesProfile (attendeeEmail) {
      const allProfiles = [this.profile, ...this.profiles]
      const mainProfile = allProfiles.filter(pr => {
        return pr.email === attendeeEmail
      })
      if (mainProfile.length) {
        return mainProfile[0]
      }
      const calendarProfiles = allProfiles.filter(pr => {
        let msMatch = false
        if (pr.msEmail) {
          msMatch = pr.msEmail?.toLowerCase() === attendeeEmail
        }
        let googleMatch = false
        if (pr.googleEmail) {
          googleMatch = pr.googleEmail?.toLowerCase() === attendeeEmail
        }
        return msMatch || googleMatch
      })
      if (calendarProfiles.length) {
        return calendarProfiles[0]
      }
      return null
    },
    filterOngoingMeeting () {
      const ongoingMeeting = this.ongoingMeetings.filter(ongoingMeeting => ongoingMeeting.id === this.meeting.id)
      if (ongoingMeeting.length > 0) {
        return ongoingMeeting[0]
      }
    },
    routeToMeetingDoc () {
      const meeting = this.meeting
      this.clickedMeeting = meeting.id
      const uid = meeting.iCalUId
      const scheduleDate = this.scheduleDate.toDate()
      const formattedDate = dateCustomFormat(scheduleDate, 'MMMM DD, YYYY')
      const meetingLabel = `${formattedDate} ${meeting.subject}`
      const meetingLink = 'meetingpage/' + uid
      this.$root.$emit('changeComposerView', 'meetingPage', meetingLabel, { meetingId: meeting.id, link: meetingLink, label: meetingLabel, subject: meeting.subject, when: meeting.date, meetingDetails: [meeting] })
    }
  },
  watch: {
    ongoingMeetings: {
      handler () {
        this.getMeetingEventColors()
      }
    },
    meeting: {
      handler () {
        this.getAttendeesSchedules()
        // console.log('MeetingEvent.watch.meeting', this.displayMeeting, this.meeting)
        const disableUpdateOfDispayMeeting = false
        if (this.displayMeeting && !disableUpdateOfDispayMeeting) {
          if (this.displayMeeting.id === this.meeting.id) {
            let meeting = this.meeting
            if (this.displayMeeting.showFor) {
              meeting = { ...meeting, showFor: this.displayMeeting.showFor }
            }
            Object.assign(this.displayMeeting, meeting)
            // this.setDisplayMeeting(meeting)
          }
        }
      }
    },
    scheduleDate: {
      handler () {
        this.getAttendeesSchedules()
      },
      immediate: true
    },
    schedules: {
      handler () {
        this.getAttendeesSchedules()
      }
    }
  }
}
</script>

<style lang="scss">
.meeting {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 10px;
  border: 1px solid transparent;
  height: auto;
  position: relative;
  margin: 0 20px 5px 20px;
  cursor: pointer;
  > span {
    z-index: 21;
  }
  > div {
    z-index:21;
  }
  &:first-of-type:not(.all-day-event) {
    margin-top: 10px;
  }
  &.all-day-event {
    padding: 2px 10px 2px 4px;
    margin-bottom: 3px;
    .all-day-text {
      text-transform: capitalize;
      font-size: 9px;
      font-weight: 600;
      color: #404040;
      margin-right: 5px;
      padding: 1px 4px;
    }
    .subject-timings {
      min-width: max-content !important;
      .meeting-subject {
        font-weight: 600;
      }
      .open-new-window {
        left: 100%;
        margin-left: 0;
      }
    }
  }
  &.clicked {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.31);
    transition: box-shadow ease-in-out 0.2s;
  }
  .subject-timings {
    position: relative;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    &.strike {
      .detail-type {
        text-decoration: line-through;
      }
    }
    .open-new-window {
      visibility: hidden;
      position: absolute;
      margin-left: 80px;
    }
    .detail-type {
      color: #404040;
    }
    .meeting-subject {
      font-size: 13px;
      font-weight: 700;
      white-space: nowrap;
      width: max-content;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      &:first-letter {
        text-transform: uppercase;
      }
      &:hover {
        +.open-new-window {
          visibility: visible;
        }
      }
    }
    .meeting-time {
      font-size: 12px;
      font-weight: 400;
      width: max-content;
      cursor: pointer;
      &:hover {
        ~ .open-new-window {
          visibility: visible;
        }
      }
    }
  }
  .location-status-bar {
    display: flex;
    height: 12px;
    min-width: 63px;
    max-width: 63px;
    position: relative;
    z-index: unset;
    .attendees {
      font-size: 12px;
      visibility: hidden;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      padding: 5px;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 100;
      width: 90px;
      bottom: 100%;
      left: -20px;
      right: 0;
      pointer-events: none;
      margin-bottom: 6px;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
      }

      &.first {
        bottom: -78px;
        &:after {
        content: "";
          position: absolute;
          top: -10px;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
        }
      }
    }
    .status {
      z-index: 40;
      &:first-of-type {
        border-radius: 8px 0 0 8px;
      }
      &:nth-last-child(2):not(:first-of-type) {
        border-radius: 0 8px 8px 0;
      }
      &:last-of-type {
        border-radius: 0 8px 8px 0;
      }
    }
    .only-me {
      background: #aaa !important;
      border-radius: 8px !important;
      width: 100% !important;
    }
    .remote {
      background: #00BE13;
      &.show-full-radius {
        border-radius: 8px;
      }
    }
    .office {
      background: #2B8AFB;
      &.show-full-radius {
        border-radius: 8px;
      }
    }
    .nonworking {
      background:#989898;
      &.show-full-radius {
        border-radius: 8px;
      }
    }
    &:hover {
      .attendees {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &.deleted {
    opacity: 0.8;
  }
  .meeting-notes-status {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    .notes-icon {
      margin-right: 10px;
    }
  }
  .meeting-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 185px;
    margin-left: auto;
    flex-direction: row;
    div:only-child {
      margin-left: auto;
      margin-right: 0;
    }
    button:only-child {
      margin-left: auto;
    }
    .join-button {
      justify-self: flex-end;
      font-weight: 700;
      background: #4175B1;
      border: 0.5px solid #4175B1;
      border-radius: 4px;
      height: 27px;
      width: 60px;
      color: #FFFFFF;
      font-size: 13px;
      text-transform: initial;
    }
  }
  &:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.31);
    transition: box-shadow ease-in-out 0.2s;
  }
}
</style>
