<template>
  <div v-if="profile && profile.id" class="people-wrapper onboarding">
    <div v-if="!introPage" class="scrim" :class="{ 'calendar-scrim': calendarOpened }"></div>
    <app-layout>
      <div v-if="introPage" class="flex-col-layout full-view intro" slot="layout-content">
        <div class="intro-wrapper">
        <div class="intro-back">
          <img style="width:150px;margin:40px;position:absolute;" alt="duome logo" src="static/img/duome-logo.svg" />
          <mdc-button class="back-button" v-if="introPage === 2" @click="introPage = 1">Go Back</mdc-button>
        </div>
        <section class="intro-content">
          <mdc-text typo="headline5" tag="div"  class="intro-heading">Welcome {{ profile.firstName || '' }}</mdc-text>
          <mdc-text class="intro-text" v-show="introPage === 1"  typo="body2">
            With DuoMe you can easily find in which location people and teams are planning to work...
          </mdc-text>
          <mdc-text class="intro-text" v-show="introPage === 2" typo="body2">
            ... and get recommendations for the best office days based on the people and initiatives you work on.
          </mdc-text>
          <div class="img-wrapper">
            <img style="max-width:650px;" v-show="introPage === 1" alt="schedule" src="static/img/onboarding/schedule.png" />
            <img style="max-width:650px;" v-show="introPage === 2" alt="recommendations" src="static/img/onboarding/recommendations.png" />
          </div>
        </section>
        <div class="intro-next">
          <mdc-button class="next-button" v-if="introPage === 1" @click="introPage = 2">Next</mdc-button>
          <mdc-button class="start-button" v-else @click="introPage = false">Let’s set you up</mdc-button>
        </div>
        </div>
      </div>
      <div v-else class="flex-col-layout" slot="layout-content"
          v-bind:class="{
          'full-view': rightSideFullView || leftSideFullView,
          'rightside-full-view': rightSideFullView,
          'leftside-full-view': leftSideFullView,
          'notification-mode': notificationMode === 'on'
        }">
        <app-section
          class="whoswherewhen-section-layout left-side"
          style="min-height:350px;position:relative;"
          :expandedView="leftSideFullView"
          noSettings
          :fullscreenEnabled="!rightSideFullView"
          :showSettingsCogMenu="showSettingsCogMenu && showSettingsMenuFor === 'people-schedules'"
          :showSettingsMenuFor="showSettingsMenuFor"
          :editable="false">

          <onboarding-progress v-if="!rightSided" @changeStage="changeStage" @exit="exitOnboarding" class="above-scrim ob-progress"/>

          <onboarding-tooltip
            v-show="!rightSided && !showEmbedded"
            class="above-scrim ob-tooltip"
            @change="stepChange"
            :steps="stepsForStage"
            :step="onboardingStageStep"
            :closeable="false"
            style="z-index:25;"
            :style="tooltipPosition"
            direction="right"
            :header="tooltipHeader"
            :text="tooltipText">
          </onboarding-tooltip>

          <schedule-search
            v-if="teamProfiles !== null"
            v-show="rightSided"
            class="above-scrim ob-schedule"
            onboarding
            setWeekDetail
            :showDate="showDate"
            :useSearchClient="false"
            @setQuerySearch="setScheduleSearchQuery"
            @enableSetupUpdates="enableSetupUpdates"
            @enableSetupSchedules="enableSetupSchedules"
            @closeSetup="closeSetupSchedules"
            :displaySchedulesSetup="showSetupSchedules"
            @emitLeftSideCog="leftSettingsCogClick"
            @enableAddUser="addUserIconClick"
            :expandedView="leftSideFullView"
            :isMobile="isMobile"
            :isTablet="isTablet" />

        </app-section>
        <app-section
          :updatesPreferencesSubmitted="updatesPreferencesSubmitted"
          @fullscreen="setRightSideFullView"
          class="updates-section-layout right-side"
          :editable="false">
          <template>

            <onboarding-progress v-if="rightSided" @changeStage="changeStage" @exit="exitOnboarding" class="above-scrim ob-progress right"/>

            <onboarding-tooltip
            v-show="rightSided"
            class="above-scrim ob-tooltip"
            @change="stepChange"
            :steps="stepsForStage"
            :step="onboardingStageStep"
            :closeable="false"
            style="z-index:25;"
            :style="tooltipPosition"
            direction="left"
            :header="tooltipHeader"
            :text="tooltipText">
          </onboarding-tooltip>

            <div v-show="rightSectionType === 'weeklyPreferences'">
              <!-- <weekly-preferences :openDate="rightSectionDate" @preferencesOpened="preferencesOpened = $event" @enableAddUser="addUserIconClick" @close="closeWeeklyPreferences" :expandedView="rightSideFullView" v-show="rightSectionType !== 'teamUpdates'" v-if="scheduleSearchWeekDetail" :openLocation="openLocation" @goToBestOfficeDays="setRightSectionType"/> -->
              <workplan
                ref="workplan"
                v-show="!rightSided"
                :style="{ position: calendarOpened ? 'unset' : 'relative' }"
                onboarding
                @deskShowing="deskShowing = $event"
                @stageChange="stageChange"
                @stepAction="stepAction"
                @hasMeeting="setHasMeeting"
                class="above-scrim"
                :showDate="showDate"
                :dateInvalid="dateInvalid"
                :openDate="rightSectionDate"
                @preferencesOpened="preferencesOpened = $event"
                @enableAddUser="addUserIconClick"
                @close="closeWeeklyPreferences"
                @calClose="calendarClosed"
                @calOpen="calendarOpen"
                :expandedView="rightSideFullView"
                v-if="scheduleSearchWeekDetail"
                :openLocation="openLocation"
                @goToBestOfficeDays="setRightSectionType">
                  <template v-slot:[tooltipSlot]>
                    <onboarding-tooltip
                      v-show="showEmbedded"
                      class="above-scrim ob-tooltip"
                      @change="stepChange"
                      :steps="stepsForStage"
                      :step="onboardingStageStep"
                      :closeable="false"
                      style="z-index:25;"
                      :style="tooltipPosition"
                      direction="right"
                      :header="tooltipHeader"
                      :text="tooltipText">
                    </onboarding-tooltip>
                  </template>
                </workplan>
            </div>
            <div v-show="rightSectionType === 'bestOfficeDays'">
              <recommendations
                onboarding
                v-show="!rightSided"
                class="above-scrim"
                :expandedView="rightSideFullView" @emitWeeklyPreferences="setRightSectionType"/>
            </div>
          </template>
        </app-section>
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AppLayout from '../../components/flexibly/AppLayout'
import AppSection from '../../components/flexibly/AppSection'
import OnboardingProgress from '../../components/flexibly/OnboardingProgress'
import OnboardingTooltip from '../../components/flexibly/OnboardingTooltip'
import ScheduleSearch from '../../components/flexibly/ScheduleSearch'

import Recommendations from '../../components/flexibly/Recommendations.vue'
import Workplan from '../../components/flexibly/Workplan.vue'
import moment from 'moment'

export default {
  name: 'Onboarding',
  computed: {
    ...mapState([
      'appDate',
      'workspace',
      'profile',
      'profiles',
      'user',
      'notificationMode',
      'scheduleSearchWeekDetail',
      'onboardingStage',
      'onboardingStageStep'
    ]),
    ...mapState({
      lookingForwardPages: state => state.teams.lookingForwardPages,
      teamProfiles: state => state.teams.teamProfiles,
      requests: state => state.teams.requests,
      tempos: state => state.teams.tempos,
      historySchedules: state => state.historySchedules,
      myTeam: state => state.teams.team,
      teams: state => state.teams.teams,
      teamsLoaded: state => state.teams.teamsLoaded,
      initiatives: state => state.teams.initiatives,
      preferences: state => state.teams.preferences,
      schedules: state => state.teams.schedules,
      bestDaysCalculating: state => state.teams.bestDaysCalculating,
      subscriptions: state => state.teams.subscriptions
    }),
    rightSided () {
      return this.onboardingStage === 4 && this.onboardingStageStep === 1
    },
    profilesFiltered () {
      if (this.profiles && this.myTeam) {
        if (this.myTeam.members) {
          return this.profiles.filter(profile => !this.myTeam.members.includes(profile.id))
        }
      }
      return []
    },
    mySchedule () {
      if (this.schedules) {
        const mySchedules = this.schedules.filter(sc => sc.fromId === this.profile.id)
        return mySchedules[0]
      }
      return false
    },
    myWeekPreferences () {
      if (this.preferences && this.preferences.length > 0) {
        return { ...this.preferences[0] }
      }
      return false
    },
    connectCalendar () {
      if (!this.profile.msEmail && !this.profile.googleEmail) {
        // No ms or google emails on profile - not connected
        return true
      }
      if (this.subscriptions && this.subscriptions.length === 0) {
        // No active calendar subscriptions
        return true
      }
      if (this.msNeedsReconnect || this.googleNeedsReconnect) {
        // one or more calendar subscriptions needs to be reconnected
        return true
      }
      return false
    },
    msNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type !== 'Google') {
              // type MS or blank
              if (sub.msNeedsReconnect) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    googleNeedsReconnect () {
      if (this.subscriptions) {
        if (this.subscriptions.length) {
          for (const sub of this.subscriptions) {
            if (sub.type === 'Google') {
              // type Google
              if (sub.googleNeedsReconnect) {
                return true
              }
            }
          }
        }
      }
      return false
    },
    stepsForStage () {
      if (this.onboardingStage) {
        if (this.onboardingStage === 1) {
          if (this.deskShowing) {
            return 0
          }
        }
        if (this.onboardingStage === 2) {
          if (!this.connectCalendar || this.hasMeeting) {
            return 3
          }
        }
        if (this.onboardingStage === 3) {
          if (!this.newInitiativeOpen) {
            return 3
          } else {
            return 6
          }
        }
        return this.steps[this.onboardingStage]
      }
      return false
    },
    tooltipText () {
      const stageStep = this.getStageStep(this.onboardingStageStep)
      const text = this.text[this.onboardingStage][stageStep]
      if (text) {
        return text.replace('<displayWeek>', this.displayWeek)
      }
      return ''
    },
    tooltipHeader () {
      const stageStep = this.getStageStep(this.onboardingStageStep)
      const value = this.headers[this.onboardingStage][stageStep]
      if (value) {
        return value
      }
      return ''
    },
    showEmbedded () {
      const stageStep = this.getStageStep(this.onboardingStageStep)
      const value = this.positions[this.onboardingStage][stageStep]
      if (value) {
        if (value.includes('anchor:')) {
          return true
        }
      }
      return false
    },
    tooltipSlot () {
      const stageStep = this.getStageStep(this.onboardingStageStep)
      const value = this.positions[this.onboardingStage][stageStep]
      if (value) {
        if (value.includes('anchor:')) {
          return value.replace('anchor:', '').split(';')[0]
        }
      }
      return 'none'
    },
    tooltipPosition () {
      const stageStep = this.getStageStep(this.onboardingStageStep)
      const value = this.positions[this.onboardingStage][stageStep]
      if (value.includes('anchor:')) {
        return value.substring(value.indexOf(';') + 1)
      }
      if (value) {
        return value
      }
      return ''
    },
    newInitiativeOpen () {
      if (this.currentAction === 'openNewInitiative') {
        return true
      }
      return false
    }
  },
  mounted () {
    this.$root.$emit('enableSearch', false)
    this.$emit('setNavActive', 'People')
    const action = 'workplan'
    const week = this.appDate.format('YYYY-MM-DD')
    const nextWeek = moment(week).add(1, 'week')
    const setWeekDetail = {
      startOfWeek: moment(nextWeek).startOf('isoWeek'),
      endOfWeek: moment(nextWeek).endOf('isoWeek'),
      weekNumber: moment(nextWeek).week(),
      selectedDay: moment(nextWeek).startOf('isoWeek')
    }
    if (setWeekDetail.startOfWeek.month === setWeekDetail.endOfWeek.month) {
      this.displayWeek = `${setWeekDetail.startOfWeek.format('DD')} - ${setWeekDetail.endOfWeek.format('DD MMM')}`
    } else {
      this.displayWeek = `${setWeekDetail.startOfWeek.format('DD MMM')} - ${setWeekDetail.endOfWeek.format('DD MMM')}`
    }
    setWeekDetail.datesOfWeek = this.calculateTableWeekDetails(setWeekDetail)
    this.setScheduleSearchWeekDetail(setWeekDetail)
    if (action) {
      if (action === 'workplan') {
        this.setRightSectionType({ section: 'weeklyPreferences', weeklyPreferences: true })
        if (nextWeek) {
          this.setRightSectionDate(nextWeek)
          this.showDate = nextWeek
        }
      } else if (action === 'bestdays') {
        this.setRightSectionType({ section: 'bestOfficeDays', weeklyPreferences: true })
        if (nextWeek) {
          this.setRightSectionDate(nextWeek)
          this.showDate = nextWeek
        }
      }
      // window.history.pushState({ urlPath: '/people' }, '', '/people')
    } else if (this.$route) {
      if (this.$route.params) {
        if (this.$route.params.showDate) {
          this.setRightSectionDate(this.$route.params.showDate)
        }
        if (this.$route.params.weeklyPreferences) {
          this.setRightSectionType({ section: this.$route.params.changeView, weeklyPreferences: true })
        } else if (this.$route.params.changeView) {
          this.setRightSectionType(this.$route.params.changeView)
        }
        if (this.$route.params.openModal === 'addNewUserModal') {
          this.addUserIconClick()
        }
      }
    }
  },
  created () {
    this.$root.$on('setRightSectionType', (viewType) => {
      this.setRightSectionType(viewType)
    })
    this.$root.$on('setRightSectionDate', (date) => {
      this.setRightSectionDate(date)
    })
  },

  beforeDestroy () {
    this.$root.$off('setRightSectionType', (viewType) => {
      this.setRightSectionType(viewType)
    })
    this.$root.$off('setRightSectionDate', (date) => {
      this.setRightSectionDate(date)
    })
  },
  components: {
    AppLayout,
    AppSection,
    OnboardingProgress,
    OnboardingTooltip,
    Recommendations,
    Workplan,
    ScheduleSearch
  },
  data () {
    return {
      deskShowing: false,
      scheduleSearchQuery: '',
      rightSideFullView: null,
      leftSideFullView: null,
      showSetupUpdates: true,
      showSettingsCogMenu: false,
      showSetupSchedules: false,
      showSettingsMenuFor: '',
      updatesPreferencesSubmitted: false,
      updatesPreferences: {},
      sendingInvite: false,
      inviteSent: false,
      teamInviteValid: true,
      inviteMessage: '',
      teamInviteEmail: '',
      teamInviteValue: false,
      showEditMembers: false,
      bottomProfileFirst: { displayName: '?', cardDesign: 'profile-colour-cyan', cardDesignBackground: 'profile-bg-bottom' },
      bottomProfileSecond: { displayName: '?', cardDesign: 'profile-colour-cyan', cardDesignBackground: 'profile-bg-bottom' },
      rightSectionType: 'bestOfficeDays',
      helpText: '',
      rightSectionDate: false,
      openLocation: false,
      preferencesOpened: false,
      showDate: false,
      useDefault: false,
      useLast: false,
      dateInvalid: false,
      displayWeek: '',
      introPage: 1,
      currentAction: '',
      hasMeeting: false,
      calendarOpened: false,
      steps: {
        1: 5,
        2: 4,
        3: 7,
        4: 2
      },
      headers: {
        1: {
          1: 'Set your Workstyle',
          alt1: 'Choose where to sit',
          2: 'Select a day',
          3: 'Select where you plan to work',
          4: 'Make this your default',
          5: 'Click NEXT'
        },
        2: {
          1: 'Add people you’d like to be in the office with',
          2: 'Search & add people you want to be in the office with',
          3: 'Connect your calendar',
          4: 'Click NEXT'
        },
        3: {
          1: 'Use initiatives for your work with others',
          2: 'Create initatives you plan to work on',
          alt2: 'Add initatives you plan to work on',
          3: 'Name your initiative',
          4: 'Add people to your initiative',
          5: 'Make your initiative private or public',
          6: 'Click to add your new initiative',
          7: 'Happy with your choices?'
        },
        4: {
          1: 'See when and where people are planning to work',
          2: 'Recommendation cards'
        }
      },
      text: {
        1: {
          1: 'Select your office and remote days for next week (<displayWeek>).',
          alt1: 'Select your desk.',
          2: '',
          3: 'Populate for all days.',
          4: 'This way you won’t have to set this again unless you need to change it.',
          5: ''
        },
        2: {
          1: 'Find, select, or invite the people you want to work with.',
          admin1: 'Select people via their name or email address, or connect your calendar to select people you are meeting with.',
          2: 'Select a name or invite someone by email if they are not in the list.',
          3: 'You can connect your calendar to see people you are set to meet.',
          4: ''
        },
        3: {
          1: 'Initiatives are groups that tell you the best office days with most group members',
          2: 'Initiatives are topics and groups of people you work with.',
          alt2: 'Select or create initiatives you plan to work on.',
          added2: 'Select initiatives you plan to work to see who else is working where.',
          3: '',
          4: '',
          5: '',
          6: '',
          7: 'Submit your workplan, you can always make changes later if you need to.'
        },
        4: {
          1: 'Your team will appear here  by default. You can navigate the days and weeks ahead and get more details about which office or zone people plan to work in.',
          2: 'Know which are best offices days for the people or initiatives you work on'
        }
      },
      positions: {
        1: {
          1: 'top:68px;right:-20px;margin-right:0;',
          alt1: 'anchor:tooltipDesk;left:-250px;top:240px;margin-right:0px;',
          2: 'top: 140px;right: -165px;',
          3: 'top: 245px;right: -170px;',
          4: 'anchor:tooltipDefault;right:0px;top:-25px;margin-right:0px;',
          5: 'anchor:tooltipNext1;right:10px;top:-10px;margin-right:0px;'
        },
        2: {
          1: 'top:130px;right:-20px;',
          2: 'top:185px;right:-180px;',
          3: 'anchor:tooltipCalendar;right:10px;top:-25px;margin-right:0px;',
          4: 'anchor:tooltipNext2;right:10px;top:-25px;margin-right:0px;'
        },
        3: {
          1: 'top:180px;right:-20px;',
          2: 'anchor:tooltipInitiativeAdd;right:10px;top:-10px;margin-right:0px;',
          alt2: 'anchor:tooltipInitiativeAdd;right:10px;top:-10px;margin-right:0px;',
          3: 'anchor:tooltipInitiativeName;right:10px;top:-10px;margin-right:0px;',
          4: 'anchor:tooltipInitiativePeople;right:10px;top:-10px;margin-right:0px;',
          5: 'anchor:tooltipInitiativeVisibility;right:15px;top:-28px;margin-right:0px;',
          6: 'anchor:tooltipInitiativeCreate;right:10px;top:-28px;margin-right:0px;',
          7: 'anchor:tooltipSubmit;right:10px;top:-100px;margin-right:0px;'
        },
        4: {
          1: 'top:115px;left:-10px;',
          2: 'top:115px;right:-20px'
        }
      },
      directions: {
        4: {
          1: 'left'
        }
      }
    }
  },
  props: {
    desktop: {
      type: Boolean
    },
    isMobile: {
      type: Boolean
    },
    isTablet: {
      type: Boolean
    },
    isDesktopLarge: {
      type: Boolean
    },
    isTabletLarge: {
      type: Boolean
    },
    dynamicStyle: {}
  },
  methods: {
    ...mapActions([
      'updateProfile',
      'setLocationIndicatorDialogDate',
      'setOnboardingStage',
      'setOnboardingStageStep'
    ]),
    ...mapActions({
      setScheduleSearchWeekDetail: 'setScheduleSearchWeekDetail'
    }),
    calendarOpen () {
      this.calendarOpened = true
    },
    calendarClosed () {
      this.calendarOpened = false
    },
    setHasMeeting (val) {
      this.hasMeeting = val
    },
    stepAction (action) {
      this.currentAction = action
    },
    getStageStep (value) {
      // Conditional change in steps
      if (this.deskShowing) {
        return 'alt1'
      }
      if (this.onboardingStage === 2 && value === 3) {
        if (!this.connectCalendar || this.hasMeeting) {
          value = 4
        }
      }
      if (this.onboardingStage === 3 && value === 2) {
        if (this.newInitiativeOpen) {
          return 3
        }
        if (this.initiatives && this.initiatives.length) {
          value = 'alt2'
        }
      }
      if (this.onboardingStage === 3 && value > 2) {
        if (!this.newInitiativeOpen) {
          if (this.onboardingStageStep > 3) {
            this.setOnboardingStageStep(3)
          }
          return 7
        } else {
          return value + 1
        }
      }
      return value
    },
    changeStage (stage) {
      // From progress
      this.setOnboardingStage(stage)
      this.setOnboardingStageStep(1)
      if (stage === 1) {
        this.$refs.workplan.openPreferencesInput('locations')
      } else if (stage === 2) {
        this.$refs.workplan.openPreferencesInput('people')
      } else if (stage === 3) {
        this.$refs.workplan.openPreferencesInput('topics')
      }
    },
    stageChange (stage) {
      const stageMap = {
        locations: 1,
        people: 2,
        topics: 3,
        submit: 4
      }
      const newStage = stageMap[stage]

      if (this.onboardingStage <= 3) {
        if (newStage && newStage !== this.onboardingStage) {
          let newStep = 1
          if (newStage < this.onboardingStage) {
            newStep = this.steps[newStage] || 1
          }
          this.setOnboardingStage(newStage)
          this.setOnboardingStageStep(newStep)
        }
      }
    },
    stepChange (step) {
      this.setOnboardingStageStep(step)
    },
    parseWeek (week) {
      try {
        const parsed = moment(week, 'YYYY-MM-DD')
        if (parsed.isValid()) {
          return parsed
        }
      } catch (ex) {
        console.error(`Date format not recognized ${week}`, ex)
      }
      return false
    },
    calculateTableWeekDetails (weekDetail) {
      // console.log('calculateTableWeekDetails', this.weekDetail.startOfWeek)
      const tableWeekDetails = []
      for (let i = 0; i <= 4; i++) {
        tableWeekDetails.push(moment(weekDetail.startOfWeek).startOf('day').add(i, 'days').toDate())
      }
      return tableWeekDetails
    },
    async exitOnboarding () {
      if (this.$router) {
        this.profile.displayOnboarding = false
        await this.updateProfile({ profile: { displayOnboarding: false }, noSnack: true })
        const nextWeek = moment(this.appDate).add(1, 'week')
        const startOfWeek = moment(nextWeek).startOf('isoWeek').format('YYYY-MM-DD')
        this.$router.push(`/people/bestdays/${startOfWeek}`)
      }
    },
    isWeekEditable (week) {
      return moment().isBefore(week)
    },
    closeWeeklyPreferences () {
      this.rightSectionType = 'bestOfficeDays'
    },
    setScheduleSearchQuery (query) {
      this.scheduleSearchQuery = query || ''
    },
    resetErrorState () {
      this.helpText = ''
      this.teamInviteEmail = ''
    },
    closeTempo () {
      this.$root.$emit('changePeopleView', 'teamUpdates')
    },
    setRightSectionDate (date) {
      this.rightSectionDate = date
    },
    setRightSectionType (type) {
      this.openLocation = false
      if (type === 'addNewUserModal') {
        this.addUserIconClick()
        return
      }
      if (type.section) {
        this.rightSectionType = type.section
      } else {
        this.rightSectionType = type
      }
      if (type.weeklyPreferences) {
        setTimeout(() => {
          if (type.openLocation === false) {
            this.openLocation = false
          } else {
            this.openLocation = true
            this.setLocationIndicatorDialogDate(false)
            if (type.openDate) {
              this.rightSectionDate = this.scheduleSearchWeekDetail.selectedDay
            } else {
              this.rightSectionDate = false
            }
          }
        }, 30)
      }
    },
    setTeamInviteEmail (val) {
      this.teamInviteEmail = val
    },
    getUpdatesDetailsFromTempo () {
      if (this.tempos !== null && this.tempos.length > 0 && this.profile) {
        const updates = this.tempos.filter(tempo => tempo.name === 'Looking Forward' && tempo.teamId === this.profile.team)
        if (updates.length > 0) {
          this.updatesPreferences = updates[0]
          this.$nextTick(() => {
            if (this.updatesPreferences.preferencesSubmitted) {
              this.updatesPreferencesSubmitted = true
              this.showSetupUpdates = false
            } else {
              this.updatesPreferencesSubmitted = false
              this.showSetupUpdates = true
            }
          })
        }
      }
    },
    addUserIconClick () {
      this.showEditMembers = true
    },
    leftSettingsCogClick () {
      this.showSettingsCogMenu = true
      this.showSettingsMenuFor = 'people-schedules'
    },
    setLeftSideFullView (value) {
      this.leftSideFullView = value
    },
    setRightSideFullView (value) {
      this.rightSideFullView = value
    },
    closeSetupUpdates () {
      this.showSetupUpdates = false
    },
    closeSetupSchedules () {
      this.showSetupSchedules = false
    },
    enableSetupUpdates () { // from menu item click emit -- settings cog
      this.showSetupUpdates = true
      this.showSettingsCogMenu = false
      this.showSettingsMenuFor = ''
      this.setRightSectionType('teamUpdates')
    },
    enableSetupSchedules () {
      this.showSetupSchedules = true
      this.showSettingsCogMenu = false
      this.showSettingsMenuFor = ''
    },
    closeSettingsCogMenu () { // menu close -- settings cog
      this.showSettingsCogMenu = false
    },
    onSearch (str, val) {
      this.teamInviteValue = val.email
      this.teamInviteEmail = val.email
    },
    onDelete () {
    },
    onClear () {
    }
  },
  watch: {
    onboardingStageStep: {
      handler () {
        if (this.onboardingStage === 4) {
          if (this.onboardingStageStep === 2) {
            this.setRightSectionType('bestOfficeDays')
          } else {
            if (this.rightSectionType !== 'weeklyPreferences') {
              this.setRightSectionType('weeklyPreferences')
            }
          }
        } else {
          if (this.rightSectionType !== 'weeklyPreferences') {
            this.setRightSectionType('weeklyPreferences')
          }
        }
      }
    },
    teamProfiles: {
      handler () {
        // console.log('people.watch.teamProfiles', this.teamProfiles)
      }
    },
    historySchedules: {
      handler () {
        // console.log('people.watch.historySchedules', this.historySchedules)
      }
    },
    tempos: {
      handler (newTempos, oldTempos) {
        this.getUpdatesDetailsFromTempo()
      }
    },
    teamInviteEmail: {
      handler () {
        if (this.teamInviteEmail) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          if (this.teamInviteEmail.match(emailRegex)) {
            this.helpText = ''
          }
        }
      }
    },
    scheduleSearchWeekDetail: {
      handler () {
        this.openLocation = false
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

.intro {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  margin: 0;
  max-width: unset;
  display: flex;
}

.intro-wrapper {
  max-width: unset;
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  max-height: 1000px;
  overflow:hidden;
}

.intro-heading {
  font-weight: 700;
  line-height: 33px;
  text-align: center;
  margin-top:120px;
  margin-bottom:20px;
}

.intro-text {
  max-width:450px;
  text-align: center;
  margin-bottom:20px;
}

.intro-back {
  flex:0.6;
  position: relative;
  max-height: 100vh;
}
.intro-back > .mdc-button {
  left:calc(100% - 140px);
  top: 80%;
  color: #BDBDBD;
  font-weight: 700;
}

.intro-next {
  flex:0.6;
  position: relative;
  max-height: 100vh;
}

.intro-next > .mdc-button {
  left:40px;
  top: 80%;
}

.intro-next .next-button,
.intro-next .start-button {
  background: #0055A3;
  color:white;
}

.intro-content {
  max-width:700px;
  width:100%;
  display:flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  position:relative;
  flex:1;
  height:100%;
}

.intro-content > .img-wrapper {
  position:relative;
  width:100%;
  margin-top: auto;
  min-height: 600px;
}

.intro-content > .img-wrapper > img {
  width:100%;
  left: 0;
  top: 0;
  position:absolute;
}

@media (max-width: 1350px) {
  .intro-next {
    flex:0.5;
  }
  .intro-back {
    flex:0.5;
  }
}

@media (max-width: 1070px) {
  .intro-next {
    flex:0.3;
  }
  .intro-back {
    flex:0.3;
  }
}

@media (max-width: 720px){
  .intro-next {
    flex:0.1;
  }
  .intro-back {
    flex:0.1;
  }
}

.scrim {
  position:absolute;
  top:-60px;
  bottom:0;
  width:100%;
  height:100%;
  height: calc(100% + 60px);
  z-index: 23;
  background-color:rgba(0,0,0,0.3);
}
.calendar-scrim {
  z-index: 27;
}
.above-scrim {
    position:absolute;
    z-index:24;
}

.ob-progress {
  left:20px;
  top:66px;
}

.ob-progress.right {
  right: 20px;
  left: auto;
}

.people-wrapper .helptext {
  color: #b00020;
}
.people-wrapper .team-tree-wrapper {
  display:flex;
}
.people-wrapper .team-tree {
  flex-flow: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  margin-left: auto;
  margin-right: auto;
}
.people-wrapper .tree-row {
  height: 1px;
  background-color: #666;
  width: 50%;
  margin: 16px;
  position: relative;
}
.people-wrapper .tree-row:before {
  content: '|';
  color:#666;
  position:absolute;
  top:-18px;
  left:50%;
}
.people-wrapper .tree-row:after {
  content: '';
  border-left:1px solid #666;
  border-right:1px solid #666;
  height:18px;
  display:block;
}
.people-wrapper .bottom-row {
  display:flex;
  min-width:290px;
}
</style>

<style lang="scss">

@keyframes rotate {
  to {
    stroke-dashoffset: 150;
  }
}

@media (max-width: 1070px) {
  // Workplan contents shift
  .ob-tooltip {
    margin-right: 30px;
  }
}

@media (max-width: 950px) {
  // Workplan contents shift
  .ob-tooltip {
    margin-right: 50px;
  }
}

@media (max-width: 720px){
  .app-layout {
    padding-top: 1px;
  }
  .ob-progress {
    left:20px !important;
    top:20px !important;
    width:calc(100% - 70px) !important;
    min-width:200px !important;
  }
  .workplan-wrapper {
    margin-top:10px !important;
  }

}

.onboarding {
.sticky-footer {
  background: rgba(178, 178, 178) !important;
  border: none;
}

.ob-schedule {
  margin:20px;
  position: relative;
  .whos-where-when-title {
    background: #ffffff;
    width: max-content;
    border-radius: 12px 40px 0 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display:block;
      height:24px;
      width:24px;
      background: white;
      right: -20px;
      top: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: -34px;
      display: inline-block;
      background: rgb(179, 179, 179);
      border-bottom-left-radius: 60px;
      border-bottom-right-radius: 60px;
      height: 30px;
      width: 60px;
      transform: rotate(45deg);
      transform-origin: 50% 100%;
    }
  }

  .recommendations-content {
    .member-request-approve {
      margin: 0 10px 0 15px;
    }
    margin:0;
    background: #ffffff;
  }
  .location-schedule-nav-wrapper {
    background: #ffffff;
    margin-top:0 !important;
    padding-top: 25px !important;
    margin-left: 0 !important;
    padding-left: 20px !important;
    border-radius: 0 12px 0 0;
  }
  .whos-where-when-content {
    padding-bottom: 20px;
    border-radius: 0 0 12px 12px;
  }
}
.recommendations-wrapper  {
    margin: 10px;
    width: calc(100% - 20px);
    position: sticky; // to test -- overriding absolute(above-scrim)
    .rec-type-wrapper {
      background: white;
      border-radius: 12px;
      .date-rec-wrapper {
        padding: 10px 10px 10px 20px;
      }
    }
  }

&.people-wrapper {
  height: 100%;
  margin-bottom: 30px;
  .app-layout {
    .flex-col-layout {
      .app-section {
        .section-title {
          z-index: 20;
          min-height: 16px;
        }
      }
      .whoswherewhen-section-layout {
        .section-title:not(:empty) {
          flex-direction: row-reverse;
          width: max-content;
          .title-content {
            flex-direction: row-reverse;
            .section-controls {
              .settings-icon {
                margin-right: auto;
              }
            }
          }
        }
      }
      .updates-section-layout {
        .section-title:not(:empty) {
          flex-direction: row-reverse;
        }
        .sub-section-types {
          display: flex;
          margin: 0 30px 0 22px;
          border-bottom: 0.5px solid #f1f1f1;
          align-items: center;
          &.fullscreen-mode {
           margin: 0 0 0 10px;
          }
          .header-link {
            height: 30px;
            margin-right: 40px;
            @media only screen and (max-width: 1200px) {
              margin-right: 10px;
            }
            &.location-pref-header {
              position: relative;
              .svg-dashed-box {
                display: none;
              }
              &.set-preferences {
                box-shadow: 0px 0px 1px rgb(0 0 0 / 25%);
                border-radius: 20px;
                .svg-dashed-box {
                  display: block;
                  width: 105px;
                  height: 31px;
                  overflow: visible;
                  position: absolute;
                  top: -6px;
                  bottom: 0;
                  left: -5px;
                  right: 0;
                  rect {
                    stroke: #ffde32;
                    fill: transparent;
                    stroke-dasharray: 3, 3;
                    animation: rotate 7s linear infinite;
                  }
                }
              }
            }
            &:last-of-type {
              margin-left: 10px;
            }
            .icon-wrapper {
              min-width: 12px;
              min-height: 12px;
              margin-right: 5px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .mdc-text {
              color: #6184AD;
              font-weight: 600;
              white-space: nowrap;
              @media only screen and (max-width: 1100px) {
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            &.clicked {
              background: #F1F1F1;
              border-radius: 4px 4px 0px 0px;
              .mdc-text {
                color: #404040;
              }
            }
          }
        }
      }
      &.leftside-full-view {
        grid-template-columns: 1fr 40px;
        .whoswherewhen-section-layout {
          #autocomplete-people {
            max-width: 720px;
          }
          .section-content {
            max-width: 720px;
            margin: auto;
          }
        }
      }
      &.rightside-full-view {
        grid-template-columns: 40px 1fr;
        .updates-section-layout {
          .section-content {
            max-width: 650px;
            margin: auto;
          }
          .settings-cog-menu.people-updates {
            left: 650px;
            right: unset;
            margin-left: 130px;
          }
        }
      }
      .title-content {
        .info-icon {
          display: none;
        }
      }
    }
  }
}
}
</style>
