<template>
    <Line-Ch
      :chart-options="options"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="chartPlugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </template>

<script>
import { Line as LineCh } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, Filler, ChartDataLabels)

export default {
  name: 'LineChart',
  components: { LineCh },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    enableDataLabels: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    chartOptions: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    options () {
      let options = { plugins: {} }
      if (this.chartOptions) {
        options = this.chartOptions
      }
      if (!this.enableDataLabels) {
        options.plugins.datalabels = {
          display: false
        }
      }
      return options
    },
    chartPlugins () {
      let chartPlugins = []
      if (this.plugins) {
        chartPlugins = this.plugins
      }
      if (this.enableDataLabels) {
        chartPlugins.push(ChartDataLabels)
      }
      return chartPlugins
    }
  },
  data () {
    return {
    }
  }
}
</script>
