<template>
  <div class="tasks-lists-details">
    <div class="tasklists-scrim" v-if="showTaskListMenu" @click="closeScrim()"></div>
    <div class="add-sort-wrapper">
      <div class="add-sort-actions">
        <mdc-button role="button" aria-label="add-task-list" outlined class="add-list-button" @click.native="addTaskListClick">ADD A TASK LIST</mdc-button>
      </div>
    </div>
    <draggable
        class="tasklist-wrapper"
        :delay="200"
        :delay-on-touch-only="true"
        @update="onDragUpdate"
        :group="{ name: 'tasklists', put: false, pull: false }"
        dragClass="dragging"
        ghostClass="ghost"
        :draggable="'.tasklist-tasks-details'"
        :handle="'.title'"
        >
    <div class="tasklist-tasks-details" v-for="taskList in tasksLists" :key="taskList.id" :id="taskList.id" v-bind:class="{ closed: !taskListToToggle.includes(taskList.id) }">
      <div class="details-wrapper">
        <div class="tasklist-details" v-bind:class="{ closed: !taskListToToggle.includes(taskList.id) }">
          <modal-dialog
            enableCancel
            disableClose
            :iconName="'assignment_turned_in'"
            v-model="deleteTaskListClicked"
            :pauseFocus="isMobile"
            class="delete-tasklist-modal tasklist-modal"
            @save="deleteFromTaskList"
            :saveText="'DELETE LIST'">
            <mdc-text tag="span" typo="body1" class="sure-delete-text">Are you sure you want to delete this task list?</mdc-text>
          </modal-dialog>
          <modal-dialog
            :iconName="'assignment_turned_in'"
            v-model="editTaskListClicked"
            :pauseFocus="isMobile"
            class="edit-tasklist-modal tasklist-modal"
            @save="saveEditedTaskList(taskList.id, taskList)"
            :saveText="'EDIT TASK LIST'"
            :title="'Edit task list'"
            :disableSave="taskList.title === ''"
            >
            <div class="fields">
              <div class="name input-wrapper">
                <label for="new-list-name">NAME OF TASK LIST</label>
                <input id="new-list-name" v-model="taskListCopy.title" type="text" placeholder="Type a name for your task list"/>
              </div>
              <div class="description input-wrapper">
                <label for="new-list-desc">DESCRIPTION</label>
                <textarea id="new-list-desc" v-model="taskListCopy.description" type="text" placeholder="Type a description for your task list, so others can understand what it is about."/>
              </div>
            </div>
          </modal-dialog>
          <div class="task-list-menu" role="list" aria-label="menu-list" v-if="showTaskListMenu && showTaskListMenuFor === taskList.id">
            <mdc-text v-if="!taskList.default" tag="span" typo="body2" @click.native="deleteTaskListClick(taskList.id, taskList)">Delete task list</mdc-text>
            <mdc-text tag="span" typo="body2" @click.native="editTaskListClick(taskList.id, taskList)">Edit task list</mdc-text>
          </div>
          <header class="list-header">
            <mdc-text typo="subtitle2" @click.native="toggleTaskList(taskList.id)" tag="span" class="title">{{ taskList.title }}</mdc-text>
            <base-svg-icon class="menu-icon" name="task-ellipse" clickable :ref="'taskListMenu' + taskList.id " @click.native="toggleTaskDetailsMenu(taskList.id)" />
          </header>
          <span class="progress" v-if="taskList.tasks.length > 0 && !taskListToToggle.includes(taskList.id)" :data-label="setTasksProgress(taskList)">
            <span class="value tasklistprogressbar" :id="'progress' + taskList.id" style="width:0%"></span>
          </span>
          <div v-if="taskList.dueDate" class="task-list-due">
            <mdc-icon :style="'color' + ':' + getDiffOfDue(taskList.dueDate).color" icon="timer" outlined/>
            <mdc-text typo="caption" v-if="taskList.dueDate" tag="span" :style="'color' + ':' + getDiffOfDue(taskList.dueDate).color">{{ getDiffOfDue(taskList.dueDate).text }}</mdc-text>
          </div>
          <div v-else class="task-list-due"></div> <!-- empty div to show blank -->
        </div>
        <template v-if="taskListToToggle.includes(taskList.id)">
          <div class="task-list-desc">
            <mdc-text typo="subtitle2" tag="span" class="title">{{ taskList.description }}</mdc-text>
          </div>
          <div class="task-list-tasks">
            <task-group
              class="tasklist-group"
              ordered
              showTaskTabs
              :tasks="taskList.tasks"
              :fromInitiative="taskList.initiativeId"
              :taskListId="[taskList.id]" />
          </div>
        </template>
      </div>
    </div>
    </draggable>
    <modal-dialog
      :iconName="'assignment_turned_in'"
      v-model="addTaskListClicked"
      :pauseFocus="isMobile"
      class="add-tasklist-modal tasklist-modal"
      @save="addTaskList"
      :saveText="'CREATE TASK LIST'"
      :title="'Add a new task list'"
      :disableSave="newTaskList.title === ''"
      @close="resetNewTaskList"
      >
      <div class="fields">
        <div class="name input-wrapper">
          <label for="new-list-name">NAME OF TASK LIST</label>
          <input id="new-list-name" @keypress.enter="addTaskList" v-model="newTaskList.title" type="text" placeholder="Type a name for your task list"/>
        </div>
        <div class="description input-wrapper">
          <label for="new-list-desc">DESCRIPTION</label>
          <textarea id="new-list-desc" @keypress.enter="addTaskList" v-model="newTaskList.description" type="text" placeholder="Type a description for your task list, so others can understand what it is about."/>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TaskGroup from '../../components/flexibly/TaskGroup'
import ModalDialog from '../../components/flexibly/ModalDialog.vue'
import moment from 'moment'
import BaseSvgIcon from './BaseSvgIcon.vue'

export default {
  name: 'TasksListsDetails',
  components: {
    TaskGroup,
    ModalDialog,
    BaseSvgIcon,
    draggable: () => import('vuedraggable')
  },
  props: {
    tasksLists: {
      type: Array,
      default: () => {
        return []
      }
    },
    initiativeId: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.taskListToToggle = this.tasksLists.map(tasklist => tasklist.id)
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  computed: {
    ...mapState([
      'profile'
    ]),
    ...mapState({
      teamProfiles: state => state.teams.teamProfiles,
      profiles: state => state.profiles
    })
  },
  data () {
    return {
      showTaskListMenuFor: '',
      taskListCopy: {},
      deleteTaskListClicked: false,
      deleteTaskListClickedFor: {},
      editTaskListClicked: false,
      editTaskListClikedFor: '',
      showTaskListMenu: false,
      taskListToToggle: [],
      sortByClicked: '',
      addTaskListClicked: false,
      newTaskList: {
        title: '',
        description: '',
        dueDate: '',
        members: []
      }
    }
  },
  methods: {
    ...mapActions({
      saveTaskList: 'teams/saveTaskList',
      saveTaskListsBatch: 'teams/saveTaskListsBatch',
      deleteTaskList: 'teams/deleteTaskList',
      updateTasksTaskListId: 'teams/updateTasksTaskListId'
    }),
    move (arr, from, to) {
      arr.splice(to, 0, arr.splice(from, 1)[0])
      return arr
    },
    onDragUpdate (event) {
      const oldIndex = event.oldDraggableIndex
      const newIndex = event.newDraggableIndex
      const from = oldIndex > newIndex ? newIndex : oldIndex
      const to = oldIndex < newIndex ? newIndex : oldIndex
      const taskLists = [...this.tasksLists]
      const tasksLists = this.move(taskLists, oldIndex, newIndex)
      for (let index = from; index <= to; index++) {
        const taskList = tasksLists[index]
        taskList.order = index
      }
      this.saveTaskListsBatch({ tasklists: taskLists })
    },
    closeScrim () {
      this.showTaskListMenu = false
      this.showTaskListMenuFor = ''
    },
    editTaskListClick (id, taskList) {
      console.log(id, taskList)
      this.editTaskListClicked = true
      this.editTaskListClikedFor = taskList.id
      this.taskListCopy = { ...taskList }
      if (this.showTaskListMenu) {
        this.showTaskListMenu = false
        this.showTaskListMenuFor = ''
      }
    },
    deleteTaskListClick (id, taskList) {
      this.deleteTaskListClicked = true
      this.deleteTaskListClickedFor = { ...taskList }
      if (this.showTaskListMenu) {
        this.showTaskListMenu = false
        this.showTaskListMenuFor = ''
      }
    },
    saveEditedTaskList (id) {
      const taskListToSave = { ...this.taskListCopy }
      this.saveTaskList({ teamId: this.profile.team, initiativeIds: [this.initiativeId], taskListId: id, taskList: taskListToSave })
    },
    deleteFromTaskList () {
      const defaultTaskList = this.tasksLists.filter(taskList => taskList.default)
      this.deleteTaskList({ taskListId: this.deleteTaskListClickedFor.id }).then(() => {
        if (this.deleteTaskListClickedFor.tasks.length > 0 && defaultTaskList.length > 0 && defaultTaskList[0]) {
          this.updateTasksTaskListId({ tasks: this.deleteTaskListClickedFor.tasks, defaultTaskListId: defaultTaskList[0].id })
        } else {
          console.log('tasklist id not updated')
        }
      })
    },
    toggleTaskDetailsMenu (id) {
      this.showTaskListMenu = !this.showTaskListMenu
      this.showTaskListMenuFor = id
      this.$nextTick(() => {
        const menuIconRect = this.$refs['taskListMenu' + id][0].$el
        if (menuIconRect) {
          const menuElement = document.querySelectorAll('.task-list-menu')
          if (menuElement && menuElement[0]) {
            menuElement[0].style.left = this.$refs['taskListMenu' + id][0].$el.offsetLeft + 5 + 'px'
          }
        }
      })
    },
    setSelectedMember (profileId) {
      const memberAtIndex = this.newTaskList.members.findIndex(teamMateId => teamMateId === profileId)
      if (memberAtIndex !== -1) {
        this.newTaskList.members.splice(memberAtIndex, 1)
      } else {
        this.newTaskList.members.push(profileId)
      }
    },
    setSearchedMember (value) {
      if (value) {
        this.setSelectedMember(value)
        this.$refs.memberSelect.clearSelection()
      }
    },
    resetNewTaskList () {
      this.newTaskList = {
        title: '',
        description: '',
        dueDate: '',
        members: []
      }
      this.addTaskListClicked = false
    },
    dateSelected (dated) {
      if (dated) {
        this.newTaskList.dueDate = dated.toDate()
      }
    },
    getDate (date) {
      return moment(date).format('ddd D MMMM')
    },
    setTasksProgress (taskList) {
      if (taskList.tasks) {
        const total = taskList.tasks.length
        const completedTasks = taskList.tasks.filter(task => task.completed).length
        const percent = Math.round(completedTasks / total * 10) * 10
        this.$nextTick(() => {
          const progressBarElement = document.querySelectorAll('#progress' + taskList.id)
          if (progressBarElement && progressBarElement[0]) {
            progressBarElement[0].style.width = percent + '%'
            if (percent === 100) {
              progressBarElement[0].style.borderTopRightRadius = 10 + 'px'
              progressBarElement[0].style.borderBottomRightRadius = 10 + 'px'
            } else {
              progressBarElement[0].style.borderTopRightRadius = 0 + 'px'
              progressBarElement[0].style.borderBottomRightRadius = 0 + 'px'
            }
          }
        })
        return completedTasks + '/' + total + ' completed'
      }
    },
    toggleTaskList (id) {
      const taskListIndex = this.taskListToToggle.findIndex(taskList => taskList === id)
      if (taskListIndex !== -1) {
        this.taskListToToggle.splice(taskListIndex, 1)
      } else {
        this.taskListToToggle.push(id)
      }
    },
    sortBy (type) {
      // console.log(type)
      this.sortByClicked = type
    },
    addTaskListClick () {
      this.addTaskListClicked = true
    },
    addTaskList () {
      const taskList = { ...this.newTaskList, order: this.tasksLists.length }
      if (this.newTaskList.title !== '') {
        this.saveTaskList({ teamId: this.profile.team, initiativeIds: [this.initiativeId], taskListId: false, taskList }).then((doc) => {
          this.toggleTaskList(doc.id)
          this.resetNewTaskList()
        })
      }
    },
    reducedTeamProfiles () {
      if (this.newTaskList.members) {
        return this.teamProfiles.filter(profile => !this.newTaskList.members.includes(profile.id))
      }
      return this.teamProfiles
    },
    checkIsToday (date) {
      return moment(date).isSame(moment(), 'day')
    },
    getDiffOfDue (dueDate) {
      let diffInDays = 0
      let today = true
      if (dueDate) {
        diffInDays = moment(dueDate.toDate()).diff(moment(), 'days')
        today = this.checkIsToday(dueDate.toDate())
      }
      let dateText = {}
      switch (true) {
        case (diffInDays === 0):
          dateText = {
            text: today ? 'Today' : 'Tomorrow',
            color: '#cc0000'
          }
          return dateText
        case (diffInDays === 1):
          dateText = {
            text: diffInDays + ' day',
            color: '#cc0000'
          }
          return dateText
        case (diffInDays > 1 && diffInDays <= 5):
          dateText = {
            text: diffInDays + ' days',
            color: '#FC5555'
          }
          return dateText
        case (diffInDays > 5 && diffInDays <= 14):
          dateText = {
            text: diffInDays + ' days',
            color: '#8C9ABD'
          }
          return dateText
        case (diffInDays < 0):
          dateText = {
            text: 'Overdue',
            color: '#cc0000'
          }
          return dateText
        default:
          dateText = {
            text: diffInDays + ' days',
            color: '#333333'
          }
          return dateText
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tasks-lists-details {
  .tasklists-scrim {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 25;
  }
  .add-sort-wrapper {
    background: #fafafa;
    height: 60px;
    margin-bottom: 30px;
    .add-sort-actions {
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      width: 65%;
      margin: auto;
      .add-list-button {
        color: #4F4F4F;
        font-size: 14px;
        border: 0.5px solid #A0A0A0;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        height: 30px;
        min-width: 130px;
        max-width: max-content;
        padding: 0 10px;
        align-self: flex-end;
        &:before {
          content: '+';
          font-size: 25px;
          color: #4F4F4F;
          margin-right: 5px;
          margin-top: -4px;
          opacity: 0.5;
        }
      }
    }
  }
  .tasklist-tasks-details {
    border-bottom: 0.5px dashed #E0E0E0;
    padding-bottom: 20px;
    margin-top: 30px;
    &.closed {
      padding-bottom: 0px;
      margin-top: 5px;
    }
    .details-wrapper {
      width: 65%;
      margin: auto;
      .tasklist-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 0.5px solid #E0E0E0;
        padding-bottom: 5px;
        position: relative;
        &.closed {
          border-bottom: 0.5px solid transparent;
        }
        .title { /*title - tasklist*/
          font-weight: 600;
          cursor: pointer;
        }
        .mdc-icon {
          font-size: 15px;
          color: #8C9ABD;
          line-height: 20px;
          text-align: end;
        }
        .progress {
          height: 14px;
          width: 100px;
          background-color: rgba(186, 141, 248, 0.48);
          border-radius: 10px;
          position: relative;
          max-width: 120px !important;
          position: relative;
          &:before {
            content: attr(data-label);
            font-size: 11px;
            position: absolute;
            text-align: center;
            top: 2px;
            left: 0;
            right: 0;
            color: white;
            font-weight: 600;
          }
          .value {
            background: #BA8DF8;
            border-radius: 10px 0px 0px 10px;
            display: inline-block;
            height: 14px;
          }
        }
        .task-list-commentcount {
          display: flex;
          align-items: center;
          color: #8C9ABD;
          .mdc-text {
            margin-left: 2px;
            margin-top: -2px;
          }
        }
        .task-list-due {
          display: flex;
          font-size: 12px;
          color: #cc0000;
          align-items: center;
          .mdc-icon {
            font-size: 13px;
            color: #cc0000;
            margin-right: 3px;
          }
        }
        header {
          display: flex;
          align-items: center;
          flex: 1;
          .menu-icon {
            padding: 10px 5px 10px 10px;
          }
        }
        .task-list-menu {
          position: absolute;
          display: flex;
          min-width: 100px;
          max-width: 320px;
          top: 23px;
          z-index: 40;
          background: white;
          flex-direction: column;
          background: #FEFEFE;
          box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 20%);
          border-radius: 6px;
          span {
            padding: 7px 10px;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              background: #EBF3FC;
              &:first-of-type {
                border-radius: 4px 4px 0 0;
              }
              &:last-of-type {
                border-radius: 0 0 4px 4px;
              }
            }
          }
        }
      }
      .task-list-desc {
        margin-top: 10px;
        color: #000000;
      }
    }
  }
}
</style>
<style lang="scss">
.add-tasklist-modal,
.tasklist-modal {
  .mdc-dialog__surface {
    width: 650px;
    max-width: 680px;
    @media only screen and (max-width: 645px) {
      max-width: calc(100vw - 32px);
    }
  }
  .mdc-dialog .mdc-dialog__content {
    padding-bottom: 30px;
  }
  .fields {
    .input-wrapper {
      display: flex;
      flex-direction: column;
      border-bottom: 0.5px dashed #BDBDBD;
      margin-bottom: 30px;
      label {
        font-size: 14px;
        color: #404040;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-weight: 600;
      }
      input {
        border: none;
        padding: 0;
        color: #404040;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 20px;
        &::placeholder {
          color: #A0A0A0;
          font-weight: 500;
        }
      }
      textarea {
        resize: none;
        color: #404040;
        border: none;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 20px;
        height: 80px;
        max-height: 80px;
        &::placeholder {
          color: #A0A0A0;
          font-weight: 500;
        }
      }
      &.description {
        border-bottom: none;
      }
    }
  }
  &.delete-tasklist-modal {
    .mdc-dialog__surface {
      width: 400px;
      max-width: 450px;
    }
    .mdc-dialog__content {
      border-bottom: none;
      text-align: center;
    }
  }
}
.taskslists-calendar {
  opacity: 1;
}
.task-list-tasks {
  .task-line {
    margin: 0 0 3px 0;
    .task-content {
      p {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>
