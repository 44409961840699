<template>
  <div class="team-admin-settings-wrapper">
    <div class="edit-modal-scrim" v-if="editGuestMode || editMemberMode || editNameMode" @click.prevent="closeEdit"></div>
    <section v-if="editGuestMode || editMemberMode || editNameMode" class="edit-modal">
      <header>
        <mdc-text tag="span" typo="body1" class="planned-header">Edit Team
          <template v-if="editNameMode">Name</template>
          <template v-else-if="editGuestMode">Guests</template>
          <template v-else>Members</template>
        </mdc-text>
        <base-svg-icon name="cross-thin" clickable @click.native="closeEdit" />
      </header>
      <span class="divider" />
      <template v-if="editGuestMode">
        <div class="user-list-wrapper">
          <div class="user-list" v-if="teamRoles && teamRoles.length > 0">
            <div class="user-detail" v-for="member in teamRolesFiltered" :key="'mem-' + member.userId">
              <div class="user-row">
                <profile-initials  :profileId="member.userId" :date="appDate" showDisplayName showYou />
                <div class="remove-prompt" v-if="promptRemove === member.userId">
                  <mdc-button class="remove-button-cancel" :disabled="removing" outlined @click="promptRemove = false">No, Cancel</mdc-button>
                  <mdc-button class="remove-button" :disabled="removing" outlined @click="removeMember(member.userId)">Yes, remove</mdc-button>
                </div>
                <mdc-button v-else-if="!promptRemove" class="remove-button" outlined @click="promptRemoveUser(member.userId)">Remove Guest</mdc-button>
              </div>
              <div class="remove-prompt-wrapper">
                <div v-if="promptRemove === member.userId && removing" class="removing-member"><mdc-text typo="body2">Removing</mdc-text></div>
                <mdc-text v-else-if="promptRemove === member.userId" class="prompt-remove-text" typo="body2">Are you sure you wish to remove this user from the team?</mdc-text>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="editNameMode">
        <div class="name-edit-wrapper">
          <input
            type="text"
            class="teamname-field"
            v-model="myTeamName"
            placeholder="Team name"
          />
          <div class="edit-actions">
            <mdc-button @click.native="saveTeamName()" raised :class="{ 'disabled' : myTeamName.toLowerCase() === myTeam.name.toLowerCase() }">Save</mdc-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="user-list-wrapper" v-if="myTeam.members && myTeam.members.length > 0">
          <div class="user-add">
            <mdc-text typo="body2" class="member-invite-heading">ADD NEW TEAM MEMBERS</mdc-text>
            <div class="user-detail add-member-row">
              <auto-complete-outline
                @keyup.escape="onClear"
                @keyup.backspace="onDelete"
                @keyup.delete="onDelete"
                @set="onSearch"
                autocompleteId="'teaminvite'"
                style="width:100%;"
                class="member-invite-autocomplete"
                v-model="teamInviteEmail"
                field="displayName"
                prompt="Invite member to join your team"
                promptMobileOverlay="Invite member to join your team"
                placeholder="Enter name or email address*"
                :items="profilesFiltered"
                autocomplete="new-password"
                autofill="off"
                :noNotched="false"
                ariaLabelledBy="teamInviteEmail"
                ref="teamName"
                :onEnterSelectTop="false"
                :min="1">
              </auto-complete-outline>
              <mdc-button outlined @click="addInvite" class="add-member-btn" :class="sendingInvite ? 'disabled' : ''">
                <span>Add Member</span>
              </mdc-button>
            </div>
            <div class="invite-message">
              <mdc-text v-if="sendingInvite" tag="span" typo="body2" class="sending-status">Sending invite</mdc-text>
              <mdc-text v-if="inviteSent" tag="span" typo="body2">Invite sent</mdc-text>
              <mdc-text v-if="helpTextAdd" typo="body2" class="helptext-add" v-html="helpTextAdd"></mdc-text>
            </div>
          </div>
          <div class="user-list">
            <div class="user-detail" v-for="member in myTeam.members" :key="'gst-' + member">
              <div class="user-row">
                <profile-initials :profileId="member" :date="appDate" showDisplayName showYou/>
                <div class="remove-prompt" v-if="promptRemove === member">
                <mdc-button class="remove-button-cancel" :disabled="removing" outlined @click="promptRemove = false">No, Cancel</mdc-button>
                <mdc-button class="remove-button" v-if="member === profile.id && profile.team === myTeam.id" :disabled="removing" outlined @click="removeMemberSwitch(member)">Yes, Remove</mdc-button>
                <mdc-button class="remove-button" v-else :disabled="removing" outlined @click="removeMember(member)">Yes, Remove</mdc-button>
                </div>
                <mdc-button v-else-if="!promptRemove" class="remove-button" outlined @click="promptRemoveUser(member)">Remove Member</mdc-button>
              </div>
              <div v-if="member === profile.id && showUserSwtich && !removing" class="switch-prompt-wrapper">
                <div v-if="hasUserTeams" class="switch-teams">
                  <div class="switch-details-wrapper" v-if="teams">
                    <div class="team-name-options" role="menu" name="team-options-menu">
                      <mdc-text @click.native="switchRemoveTeam(team)" tag="span" typo="body2" class="option-type clickable" v-for="(team, index) in userTeamsDetailFiltered" :key="index" >
                        {{ team.name }}
                      </mdc-text>
                    </div>
                  </div>
                </div>
              </div>
              <div class="remove-prompt-wrapper">
                <div v-if="promptRemove === member && removing" class="removing-member"><mdc-text typo="body2">Removing</mdc-text></div>
                <mdc-text v-else-if="promptRemove === member && showUserSwtich" typo="body2" class="prompt-remove-text" >Please select a team you wish to switch to</mdc-text>
                <mdc-text v-else-if="promptRemove === member" typo="body2" class="prompt-remove-text" >Are you sure you wish to remove this user from the team?</mdc-text>
              </div>
            </div>
          </div>
        </div>
      </template>
    </section>
    <section v-if="settingHeadingType.subType === 'teamInfo'" class="team-info sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Team information</mdc-text>
      </header>
      <div class="team-info-content type-content">
        <div class="name-wrapper field-wrapper">
          <span class="name-label label">
            <mdc-text tag="span" typo="caption">NAME</mdc-text>
            <base-svg-icon name="pen-edit" clickable class="edit-icon" @click.native="editName()"/>
          </span>
          <span class="profile-name">
            <mdc-text tag="span" typo="body2" class="name">{{ myTeam.name }}</mdc-text>
          </span>
        </div>
        <div class="members-wrapper field-wrapper">
          <span class="members-label label">
            <mdc-text tag="span" typo="caption">MEMBERS</mdc-text>
            <base-svg-icon name="pen-edit" clickable class="edit-icon" @click.native="editMembers()"/>
          </span>
          <span class="members-list field-lists" v-if="myTeam.members.length > 0">
            <profile-initials v-for="member in myTeam.members" :key="'m-' + member" :profileId="member" :date="appDate" showDisplayName showYou/>
          </span>
          <span v-else class="no-member-text no-list">
            <mdc-text tag="span" typo="body2">No team members</mdc-text>
          </span>
        </div>
        <div class="guests-wrapper field-wrapper">
          <span class="guests-label label">
            <mdc-text tag="span" typo="caption">GUESTS</mdc-text>
            <base-svg-icon name="pen-edit" clickable class="edit-icon" @click.native="editGuests()" v-if="teamRolesFiltered.length > 0"/>
          </span>
          <span class="guests-list field-lists" v-if="teamRolesFiltered && teamRolesFiltered.length > 0">
            <profile-initials v-for="member in teamRolesFiltered" :key="'g-' + member.userId" :profileId="member.userId" :date="appDate" showDisplayName showYou/>
          </span>
          <span v-else class="no-guest-text no-list">
            <mdc-text tag="span" typo="body2">No guest members</mdc-text>
          </span>
        </div>
        <div class="display-admin-wrapper field-wrapper">
          <span class="admins-label label">
            <mdc-text tag="span" typo="caption">ADMINS</mdc-text>
          </span>
          <span class="admins-list">
            <div v-for="admin in myTeam.admins" :key="'a-' + admin" class="admin-name-wrapper">
              <profile-initials :profileId="admin" :date="today" showDisplayName showYou/>
              <mdc-button v-if="admin !== profile.id" :class="myTeam.admins && myTeam.admins.length === 1 ? 'disabled' : ''" :key="admin + 'button'" class="remove-button" outlined @click.native="removeAdmin(admin)">Remove admin</mdc-button>
            </div>
          </span>
        </div>
        <div class="add-admin-wrapper field-wrapper">
          <span class="add-admins-label label">
            <mdc-text tag="span" typo="caption">ADD ADMIN</mdc-text>
          </span>
          <div class="search-wrapper">
            <div class="admin-name-search" v-if="teamProfiles">
              <v-select class="team-admin-select" :dropdown-should-open="dropdownShouldOpen" v-model="nameInput" :options="[...teamProfiles].filter(teamProfile => teamProfile.id !== profile.id)" :reduce="teamProfile => teamProfile.id" label="displayName" @input="setNewTeamAdmin" placeholder="Start typing a name">
                <div slot="open-indicator">
                </div>
                <template #option="{ displayName }">
                  <base-svg-icon name="taskassignee-on"/>
                  <mdc-text typo="body2" tag="span">{{ displayName }}</mdc-text>
                </template>
              </v-select>
            </div>
            <mdc-button :class="!teamAdmin ? 'disabled' : ''" class="add-admin-button" @click.native="addTeamAdmin">Add admin</mdc-button>
          </div>
          <mdc-text class="help-text" tag="span" typo="caption">{{ helpText }}</mdc-text>
        </div>
      </div>
    </section>
    <section v-if="settingHeadingType.subType === 'locationSchedules'" class="location-schedules sub-type">
      <header class="type-heading">
        <mdc-text tag="span" typo="body1">Location schedules</mdc-text>
      </header>
      <div class="location-schedules-content type-content">
        <setup-schedules :from="'teamAdmin'"/>
      </div>
    </section>
  </div>
</template>
<script>
import moment from 'moment'
import fb from '../../store/firebase'
import { mapState, mapActions } from 'vuex'
import ProfileInitials from '../flexibly/ProfileInitials.vue'
import BaseSvgIcon from '../flexibly/BaseSvgIcon.vue'
import SetupSchedules from '../flexibly/SetupSchedules.vue'
import AutoCompleteOutline from '../../components/AutoCompleteOutline.vue'
import { onboarding } from '../../utils/backend'

export default {
  name: 'TeamAdminSettings',
  props: {
    team: {
    },
    settingHeadingType: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    ProfileInitials,
    BaseSvgIcon,
    SetupSchedules,
    AutoCompleteOutline
  },
  computed: {
    ...mapState([
      'user',
      'userTeams',
      'profiles',
      'profile',
      'appDate',
      'workspace'
    ]),
    ...mapState({
      teamProfiles: state => state.teams.teamProfiles,
      currentTeam: state => state.teams.team,
      tempos: state => state.teams.tempos,
      teamRoles: state => state.teams.teamRoles,
      teams: state => state.teams.teams,
      requests: state => state.teams.requests
    }),
    hasUserTeams () {
      if (this.userTeams) {
        if (this.userTeams.length > 1) {
          return true
        }
      }
      return false
    },
    userTeamsDetail () {
      const teamDetails = []
      if (this.userTeams) {
        for (const ut of this.userTeams) {
          const team = this.teams.filter(t => t.id === ut)
          if (team.length) {
            teamDetails.push({ id: team[0].id, name: team[0].name })
          }
        }
      }
      return teamDetails
    },
    userTeamsDetailFiltered () {
      return this.userTeamsDetail.filter(team => team.id !== this.myTeam.id)
    },
    profilesFiltered () {
      if (this.profiles && this.myTeam) {
        return this.profiles.filter(profile => !this.myTeam.members.includes(profile.id))
      }
      return []
    },
    myTeam () {
      if (this.team) {
        return this.team
      }
      return this.currentTeam
    },
    teamRolesFiltered () {
      if (this.teamRoles && this.teamRoles.length > 0) {
        return [...this.teamRoles].filter(user => {
          return user.role === 'guest' && this.myTeam.members.includes(user.userId)
        })
      }
      return []
    }
  },
  data () {
    return {
      teamAdmin: '',
      today: moment(),
      nameInput: '',
      editNameMode: false,
      editGuestMode: false,
      editMemberMode: false,
      promptRemove: false,
      removing: false,
      myTeamName: '',
      helpText: '',
      helpTextAdd: '',
      teamInviteEmail: '',
      sendingInvite: false,
      inviteSent: false,
      showUserSwtich: false,
      userSwtichTeam: false
    }
  },
  methods: {
    ...mapActions([
      'updateUser'
    ]),
    ...mapActions({
      updateTeam: 'teams/updateTeam'
    }),
    handleKeyDown (event) {
      if (event.key === 'Escape') {
        this.closeEdit()
      }
    },
    getConfirmScheduleText (update) {
      if (update.confirmWhen.includes('timing')) {
        if (update.confirmWhenTimings) {
          return 'at specific time regardless of who has submitted.'
        }
      }
      if (update.confirmWhen.includes('allSchedules')) {
        if (update.confirmWhenTimings) {
          return 'when all schedules are submitted.'
        }
      }
    },
    async saveTeamName () {
      if (this.myTeamName !== this.myTeam.name) {
        await this.updateTeam({ teamId: this.myTeam.id, updates: { name: this.myTeamName } }).then(() => {
          this.editNameMode = false
        })
      }
    },
    async removeAdmin (member) {
      if (this.myTeam.admins.includes(member)) {
        const memberIndex = this.myTeam.admins.findIndex(admin => admin === member)
        if (memberIndex !== -1) {
          const admins = [...this.myTeam.admins]
          admins.splice(memberIndex, 1)
          await this.updateTeam({ teamId: this.myTeam.id, updates: { admins } })
        }
      }
    },
    async removeMemberSwitch (userId) {
      if (this.hasUserTeams) {
        this.showUserSwtich = true
      } else {
        this.removeMember(userId)
      }
    },
    async switchRemoveTeam (team) {
      this.removeMember(this.profile.id, team)
    },
    async removeMember (userId, team) {
      // console.log('removeMember', this.myTeam, userId, this.myTeam.members.includes(userId))
      if (this.myTeam.members.includes(userId)) {
        this.removing = true
        const teamName = this.myTeam.name
        const teamId = this.myTeam.id
        const memberIndex = this.myTeam.members.findIndex(member => member === userId)
        const removeFromTeam = fb.functions.httpsCallable('removeFromTeam')
        const params = { userId, workspaceId: this.workspace.id, teamName, teamId }
        if (team) {
          params.switchTeamId = team
        }
        // console.log('removeFromTeam params', params)
        await removeFromTeam(params).then(async (res) => {
          // console.log('removeFromTeam res', res)
          if (userId === this.profile.id) {
            // self remove
            await this.updateUser()
            setTimeout(() => {
              this.removing = false
              this.$router.replace('/')
            }, 1000)
          } else {
            this.closeEdit()
            this.removing = false
          }
        })
        if (memberIndex !== -1) {
          const members = [...this.myTeam.members]
          members.splice(memberIndex, 1)
          await this.updateTeam({ teamId: this.myTeam.id, updates: { members } })
        }
      } else {
        this.closeEdit()
        this.removing = false
      }
    },
    async addTeamAdmin () {
      if (!this.myTeam.admins.includes(this.teamAdmin)) {
        const admins = [...this.myTeam.admins, this.teamAdmin]
        await this.updateTeam({ teamId: this.myTeam.id, updates: { admins } })
      } else {
        this.helpText = 'Person you are trying to add is already an admin'
      }
    },
    editName () {
      this.initialize()
      this.editNameMode = true
      document.addEventListener('keydown', this.handleKeyDown)
    },
    editMembers () {
      // console.log('editMembers')
      this.editMemberMode = true
      document.addEventListener('keydown', this.handleKeyDown)
    },
    editGuests () {
      // console.log('editGuests')
      this.editGuestMode = true
      document.addEventListener('keydown', this.handleKeyDown)
    },
    promptRemoveUser (userId) {
      this.promptRemove = userId
    },
    closeEdit () {
      // console.log('closeEdit')
      this.editGuestMode = false
      this.editMemberMode = false
      this.editNameMode = false
      this.promptRemove = false
      document.removeEventListener('keydown', this.handleKeyDown)
    },
    initialize () {
      this.myTeamName = this.myTeam.name
    },
    dropdownShouldOpen (VueSelect) {
      if (this.nameInput !== '') {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    setNewTeamAdmin (val) {
      this.teamAdmin = val
    },
    resetData () {
      this.teamAdmin = ''
      this.nameInput = ''
      this.helpText = ''
      this.helpTextAdd = ''
      this.teamInviteEmail = ''
    },
    inDomains (email) {
      const emailDomain = email.split('@')[1].toLowerCase()
      if (this.workspace) {
        if (this.workspace.domains) {
          for (const domain of this.workspace.domainUrls) {
            if (emailDomain === domain) {
              return true
            }
          }
        } else {
          return this.workspace.domainUrl === emailDomain
        }
      }
      return false
    },
    async addInvite () {
      this.helpTextAdd = ''
      const guestsAllowed = this.workspace.guestAccessWorkspace
      const self = this
      setTimeout(() => {
        self.sendingInvite = false
      }, 2000)
      if (this.teamInviteEmail) {
        const teamAddArr = []
        const newInviteArr = []
        this.teamInviteEmail = this.teamInviteEmail.toLowerCase()
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const emailArr = this.teamInviteEmail.replaceAll(' ', '').split(',')

        for (const emailPart of emailArr) {
          if (!emailPart.match(emailRegex)) {
            this.helpTextAdd = `Please enter a valid email: ${emailPart}`
            return
          }
          // console.log('People.addInvite.workspace', this.workspace)

          if (!guestsAllowed) {
            if (!this.inDomains(emailPart)) {
              this.helpTextAdd = `Your workspace does not allow adding of guests. Please enabled this option or enter a valid email: ${emailPart}`
              return
            }
          }

          let inWorkspace = false
          if (this.workspace && this.workspace.members) {
            inWorkspace = this.workspace.members.includes(this.teamInviteEmail)
          }
          if (inWorkspace) {
            teamAddArr.push(emailPart)
            const inviteProfiles = this.profiles.filter(pr => pr.email.toLowerCase() === emailPart)
            if (inviteProfiles.length) {
              const inviteProfileId = inviteProfiles[0]
              if (this.myTeam.members.includes(inviteProfileId)) {
                this.helpTextAdd = 'This user already belongs to this team'
                return
              }
            }
          } else {
            newInviteArr.push(emailPart)
          }
        }
        this.sendingInvite = true
        // console.log('TAS.addInvite.send-invites', teamAddArr, teamAddArr.length, newInviteArr, newInviteArr.length)
        try {
          if (teamAddArr.length) {
            // Already members of this workspace add invite to team
            const params = {
              userId: this.user.uid,
              emails: teamAddArr
            }
            // eslint-disable-next-line no-unused-vars
            const response = await onboarding('invite-to-team', params)
            console.log('TAS.addInvite.invite-to-team', response)
          }
          if (newInviteArr.length) {
            // Not members of this workspace - add invite to workspace / team
            // eslint-disable-next-line no-unused-vars
            const params = {
              userId: this.user.uid,
              invites: newInviteArr
            }
            // eslint-disable-next-line no-unused-vars
            const response = await onboarding('send-invites', params)
            console.log('TAS.addInvite.send-invites', response)
          }

          for (const email in emailArr) {
            const matchingRequests = this.requests.filter((req) => req.email === email)
            if (matchingRequests) {
              for (const mRequest in matchingRequests) {
                if (!mRequest.completed) {
                  this.updateRequest({ requestId: mRequest.id, request: { completed: true } })
                }
              }
            }
          }
        } catch (error) {
          console.error('TAS.addInvite.send-invites', error)
        }
        this.$nextTick(() => {
          const self = this
          this.teamInviteEmail = ''
          this.sendingInvite = false
          this.inviteSent = true
          setTimeout(() => {
            self.inviteSent = false
          }, 3000)
        })
      } else {
        this.helpTextAdd = 'Please enter an email address to send the invite.'
      }
    },
    onSearch (str, val) {
      this.teamInviteEmail = val.email
    },
    onDelete () {
    },
    onClear () {
    }
  },
  mounted () {
    this.initialize()
  },
  beforeDestroy () {},
  watch: {
    teamInviteEmail: {
      handler () {
        this.helpTextAdd = ''
      }
    },
    nameInput: {
      handler () {
        if (!this.nameInput) {
          this.helpText = ''
        }
      }
    },
    myTeam: {
      handler () {
        if (!this.myTeam) {
          this.resetData()
        } else {
          this.initialize()
        }
      }
    }
  }
}
</script>
<style lang="scss">
.team-admin-settings-wrapper {
  .edit-modal-scrim {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.2);
    z-index: 20;
  }
  .edit-modal {
    position: fixed;
    width: 100%;
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 24%);
    background: white;
    border-radius: 10px;
    top: 0;
    left: 0;
    right: 0;
    margin: 60px auto;
    min-width: 300px;
    z-index: 25;
    max-width: 500px;
    max-height: 500px;
    // height: 100%;
    display: flex;
    flex-direction: column;
    header {
      margin: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .planned-header {
        font-size: 18px;
        color: #4f4f4f;
        font-weight: 600;
      }
    }
    .divider {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(255, 255, 255, 0) 38.54%);
      height: 10px;
    }
    .invite-message {
      margin-left:2px;
      margin-bottom:0px;
    }
    .name-edit-wrapper {
      display: flex;
      align-items: center;
      margin: 10px 20px 20px;
      input {
        border-radius: 4px;
        border: 0.5px solid #959EA7;
        padding: 5px 10px;
        font-size: 16px;
        color: #404040;
        height: 30px;
        width: 100%;
        font-weight: 500;
      }
      .edit-actions {
        display: flex;
        align-items: center;
        margin-left: 20px;
        .mdc-button {
          max-width: 50px;
          background: #00498d;
          color: #fff;
          font-size: 12px;
          height: 40px;
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
    .user-list-wrapper {
      overflow-y: visible;
      margin-bottom: 5px;
      padding: 15px;
      .user-add {
        .member-invite-heading {
          margin-top: 0;
        }
        .helptext-add {
          margin-top:-10px;
          color: #FF0F00;
          font-size:14px;
        }
        .member-invite-autocomplete {
          height:34px;
          margin-bottom:2px;
        }
        .member-invite-autocomplete.mdc-notched-outline {
          display:none;
        }
        .member-invite-autocomplete .autocomplete {
          min-height: 34px;
          margin-top:0 !important;
          padding-top:0 !important;
        }
        .member-invite-autocomplete .autocomplete.mdc-text-field.outlined .mdc-text-field__input,
        .member-invite-autocomplete .autocomplete .autocomplete.mdc-text-field.mdc-text-field--upgraded.outlined {
            height:34px;
            min-height: 20px;
            margin-top:0 !important;
            padding-top:0 !important;
        }
        .member-invite-autocomplete .autocomplete .autocomplete-results {
          margin-top: 38px;
        }
        .autocomplete {
          margin-top:-6px !important;
          min-height:34px;
          .autocomplete.mdc-text-field {
            .autocomplete-input {
              height:34px;
              padding-top:0;
            }
            .autocomplete-input::placeholder {
              color: #979797 !important;
              opacity:1 !important;
            }
          }
          .autocomplete-results {
            position: absolute;
            max-width: 311px;
            margin-left: 0px;
          }
          input::placeholder {
            opacity: 1 !important;
            color: #BDBDBD !important;
            font-size: 14px;
            font-weight: 600;
          }
          .clear-search {
            margin-top: 8px;
            transform:none;
          }
          .autocomplete-results {
            position:absolute;
            margin-left: 0px;
            width: 100%;
            margin-top: 45px;
            left: 0;
            border-radius: 6px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);
            border: none;
            li.autocomplete-result {
              color: #4175B1;
              font-weight: 600;
              font-size: 14px;
              padding: 9px 15px;
              border-left: none !important;
              &:hover {
                background-color: #EBF3FC !important;
                border: none !important;
                border-left: none !important;
              }
            }
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-track {
              background: transparent;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: #828282;
            }
          }
          .autocomplete.mdc-textfield-wrapper {
            margin: 0px !important;
            padding-top: 0;
            min-height: 40px !important;
            .mdc-text-field {
              margin: 0 !important;
              min-height: 40px !important;
              .autocomplete-input {
                height: 38px;
                margin: 0;
                caret-color: rgba(0, 0, 0, 0.54);
                background-color: #F8F7F7;
                padding-left: 12px;
                padding-right: 25px;
                max-width: calc(100% - 37px);
                border-radius: 4px;
              }
            }
          }
        }

        .user-detail {
          margin-bottom: 10px;
          &.add-member-row {
            display:flex;
          }
          .mdc-button {
            text-transform: none;
            margin-left:5px;
            color: #6184Ad;
            font-size: 12px;
            height: 32px;
            line-height: 32px;
          }
          .add-member-btn {
            color: #6184Ad;
            text-transform: uppercase;
            font-size: 12px;
            height: 32px;
            line-height: 32px;
            min-width: 135px;
            margin-left: 10px;
            margin-right: 10px;
            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }
      }
      .user-list {
        overflow-y: auto;
        max-height: 310px;

        .user-detail {
          margin-bottom: 10px;
          margin-left: 10px;
          margin-right: 5px;
          .user-row {
            display:flex;
            align-items: center;
            justify-content: space-between;
            .remove-prompt {
              .mdc-text {
                margin:10px;
                color: #4f4f4f;
              }
              .mdc-button {
                text-transform: none;
                margin-left:5px;
                color: #6184Ad;
                font-size: 12px;
                height: 32px;
                line-height: 32px;
              }
            }
          }
          .remove-prompt-wrapper:not(:empty) {
            margin: 5px 0;
            border-bottom:.5px solid rgba(149,162,176,.5);
            .prompt-remove-text {
              margin: 0;
              text-align: right;
              color: #4f4f4f;
            }
            .removing-member {
              position: relative;
              display: flex;
              justify-content: flex-end;
              align-items: baseline;
              .mdc-text {
                color: #959EA7;
                margin: 0;
                margin-right:4px;
              }
              &:after {
                content: '  .';
                animation: dots 1s steps(5, end) infinite;
                font-weight: 600;
                font-size: 18px;
                color: #959EA7;
                margin-right:15px;
              }
            }
          }
        }
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #828282;
        }
        .remove-button {
          color: #6184Ad;
          font-size: 12px;
          height: 32px;
          line-height: 32px;
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
  }
  .help-text {
    color: #FF0F00;
    margin-top: 5px;
  }
  .sub-type {
    max-width: 600px;
    .type-heading {
      margin-bottom: 20px;
      .mdc-text {
        color: #959EA7;
        font-weight: 700;
      }
    }
    .type-content {
      &.team-info-content {
        .field-wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          .field-lists {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .profile-redirect {
              margin-bottom: 10px;
            }
          }
          .label {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            text-transform: uppercase;
            .mdc-text {
              margin-right: 5px;
              color: #828282;
            }
            .icon-wrapper {
              &.edit-icon {
                height: 12px;
                width: 12px;
                img {
                  height: 100%;
                  width: 100%;
                }
              }
            }
          }
          &.members-wrapper {
          }
          &.name-wrapper {
          }
          &.display-admin-wrapper {
            .admins-list {
              .admin-name-wrapper:not(:last-of-type) {
                margin-bottom: 15px
              }
              .admin-name-wrapper {
                display: flex;
              }
            }
            .remove-button {
              text-transform: none;
              color: #6184Ad;
              margin-right: 30px;
              font-size: 12px;
              height: 25px;
              &.disabled {
                opacity: 0.5;
                pointer-events: none;
              }
            }
          }
          &.add-admin-wrapper {
            display: flex;
            flex-direction: column;
            .search-wrapper {
              display: flex;
              align-items: center;
              .admin-name-search {
                margin: 0;
                background: #f2f2f2;
                width: 200px;
                border-radius: 4px;
                margin-right: 20px;
                .v-select {
                  .vs__selected {
                    color: #404040;
                    font-size: 14px;
                  }
                  .vs__dropdown-toggle .vs__selected-options {
                    padding: 0;
                    input {
                      font-size: 14px;
                      color: #404040;
                      height: 32px;
                      &::-webkit-input-placeholder {
                        color: #959EA7;
                        font-size: 14px;
                        padding-left: 5px;
                      }
                      &::-webkit-textfield-decoration-container {
                        padding-left: 5px;
                      }
                    }
                  }
                  .vs__dropdown-menu {
                    margin: 0;
                    list-style: none;
                    z-index: 11;
                    background: #fff;
                    border-radius: 6px;
                    color: #6184ad;
                    position: absolute;
                    border: none;
                    padding: 0;
                    .vs__dropdown-option {
                      padding: 8px 12px;
                      display: inline-flex;
                      align-items: baseline;
                      width: 100%;
                      cursor: pointer;
                      color: #6184ad;
                      white-space: normal;
                      .icon-wrapper {
                        margin-right: 8px;
                        height: 10px;
                        width: 10px;
                        img {
                          height: 100%;
                          width: 100%;
                        }
                      }
                    }
                    .vs__dropdown-option:hover {
                      background: #EBF3FC;
                    }
                    .vs__dropdown-option--highlight {
                      background: #EBF3FC;
                    }
                  }
                }
              }
              .add-admin-button {
                text-transform: none;
                min-width: 100px;
                background: #00498D;
                color: white;
                height: 40px;
                &.disabled {
                  opacity: 0.5;
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
      &.location-schedules-content {
        .setup-schedules-wrapper:not(:empty) {
          margin: 30px 30px 30px 0;
          .setup-preferences-section {
            background: transparent;
            .section-header {
              justify-content: unset;
            }
            .preferences-section {
              margin: 30px 30px 30px 0;
            }
          }
        }
        .field-wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          .label {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            text-transform: uppercase;
            .mdc-text {
              margin-right: 5px;
              color: #828282;
            }
            .icon-wrapper {
              &.edit-icon {
                height: 12px;
                width: 12px;
                img {
                  height: 100%;
                  width: 100%;
                }
              }
            }
          }
          &.schedules-details-wrapper {
          }
          &.confirm-when-wrapper {}
        }
      }
      &.people-updates-content {
        .setup-updates-wrapper {
          margin: 0 0 90px 0;
          .setup-preferences-section {
            margin: 0 90px 0 0;
          }
        }
        .field-wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          .label {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            text-transform: uppercase;
            .mdc-text {
              margin-right: 5px;
              color: #828282;
            }
            .icon-wrapper {
              &.edit-icon {
                height: 12px;
                width: 12px;
                img {
                  height: 100%;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .team-name-options {
    display:flex;
    flex-wrap: wrap;
    padding:4px;
    .option-type {
      padding:4px;
      cursor:pointer;
      border: 1px solid silver;
      border-radius: 4px;
      margin:4px;
    }
  }
}
</style>
