<template>
  <div class="create-init-wrapper">
    <div class="name-wrapper form-input" role="form">
      <mdc-text tag="span" typo="caption" class="input-heading">Initiative name</mdc-text>
      <div class="input-wrapper">
        <div class="tooltip-anchor" style="position:relative;"><slot name="tooltipInitiativeName"></slot></div>
        <input id="new-init-name" v-model="newInitiative.name" type="text" placeholder="e.g. Development, Marketing" role="input" aria-label="init-name"/>
      </div>
      <mdc-text v-if="helpText" class="help-text" tag="span" typo="caption">{{ helpText }}</mdc-text>
    </div>
    <div class="description-wrapper form-input" role="form">
      <mdc-text tag="span" typo="caption" class="input-heading">Description</mdc-text>
      <div class="input-wrapper">
        <textarea id="new-init-desc" v-model="newInitiative.description" type="text" placeholder="Type a description for your initiative, so others can understand what it is about." role="textarea" aria-label="init-desc"/>
      </div>
    </div>
    <div class="people-input-wrapper form-input" role="form">
      <mdc-text tag="span" typo="caption" class="input-heading">People</mdc-text>
      <div class="input-wrapper">
        <div class="tooltip-anchor" style="position:relative;"><slot name="tooltipInitiativePeople"></slot></div>
        <v-select :dropdown-should-open="dropdownShouldOpen" v-model="memberInput" ref="memberSelect" placeholder="Start typing and select from list" class="members-select" :options="profiles.filter(profile => !newInitiative.members.includes(profile.id))" :reduce="profile => profile.id" label="displayName" @input="setSearchedMember">
          <template slot="open-indicator">
            <mdc-icon class="members-search-icon" icon="search" filled/>
          </template>
          <template #selected-option="{ displayName }">
            <strong>{{ displayName }}</strong>
          </template>
        </v-select>
      </div>
    </div>
    <div class="selected-members-wrapper">
      <button-with-image v-for="(memberId) in newInitiative.members" :key="memberId + 'newInitiative'" tickSelect :profileId="memberId" :selected="newInitiative.members.includes(memberId)" :buttonType="'profile'" @click.native="setSelectedMember(memberId)" onlyMe />
      <button-with-image v-for="(member) in reducedTeamProfiles()" :key="member.id + 'tp newInitiative'" tickSelect :profileId="member.id" :selected="newInitiative.members.includes(member.id)" :buttonType="'profile'" @click.native="setSelectedMember(member.id)" onlyMe/>
    </div>
    <div class="switch-wrapper form-input" role="form">
      <div class="tooltip-anchor" style="position:relative;"><slot name="tooltipInitiativeVisibility"></slot></div>

      <mdc-text tag="span" class="switch-text" typo="caption">{{ newInitiative.visibility === 'Workspace' ? 'Initiative is visible' : 'Initiative is private'}}</mdc-text>
      <label role="checkbox" aria-label="visibility-switch" class="switch" for="initiative-switch">
        <input v-model="initiativeVisible" type="checkbox" id="initiative-switch" @change="toggleVisibilitySwitch" />
        <div class="slider round"></div>
      </label>
    </div>
    <div class="action-buttons" role="status">
      <mdc-button role="button" aria-label="back-btn" class="back-actionbtn" :class="{ 'disabled': addingInitiative }" v-if="showBack" @click.native="closeCreateInitiative">Go Back</mdc-button>
      <div class="tooltip-anchor" style="margin-left:auto;position:relative;"><slot name="tooltipInitiativeCreate"></slot></div>
      <mdc-button role="button" aria-label="create-btn" class="create-actionbtn" :class="{ 'disabled': disableCreateNew() }" @click.native="addInitiative">
        {{ addingInitiative ? 'Creating Initiative' : 'Create initiative' }}
        <span class="loader" v-if="addingInitiative"></span>
      </mdc-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import fb from '../../store/firebase'
import ButtonWithImage from './ButtonWithImage.vue'
export default {
  name: 'CreateInitiative',
  components: {
    ButtonWithImage
  },
  props: {
    showBack: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      helpText: null,
      disableCreate: false,
      newInitiative: {
        name: '',
        description: '',
        visibility: 'Workspace',
        members: [],
        due: '',
        created: new Date()
      },
      memberInput: null,
      initiativeVisible: true,
      addingInitiative: false
    }
  },
  beforeDestroy () {},
  created () {
    this.setSelectedMember(this.profile.id) // add self
  },
  computed: {
    ...mapState([
      'profile',
      'workspace',
      'appDate'
    ]),
    ...mapState({
      initiatives: state => state.teams.initiatives,
      teamProfiles: state => state.teams.teamProfiles,
      profiles: state => state.profiles
    }),
    teamInitiatives () {
      return this.initiatives.filter((initiative) => {
        return initiative.teamId === this.profile.team
      })
    }
  },
  mounted () {},
  methods: {
    ...mapActions([
    ]),
    ...mapActions({
      saveInitiative: 'teams/saveInitiative',
      saveTaskList: 'teams/saveTaskList',
      setSnackMessage: 'setSnackMessage'
    }),
    disableCreateNew () {
      return this.newInitiative.name === '' || this.disableCreate || this.addingInitiative
    },
    dropdownShouldOpen (VueSelect) {
      if (this.memberInput !== null) {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    reducedTeamProfiles () {
      return this.teamProfiles.filter(profile => !this.newInitiative.members.includes(profile.id))
    },
    toggleVisibilitySwitch () {
      if (this.newInitiative.visibility === 'Workspace') {
        this.newInitiative.visibility = 'User'
        this.initiativeVisible = false
      } else {
        this.newInitiative.visibility = 'Workspace'
        this.initiativeVisible = true
      }
    },
    setSelectedMember (profileId) {
      this.helpText = ''
      const memberAtIndex = this.newInitiative.members.findIndex(teamMateId => teamMateId === profileId)
      if (memberAtIndex !== -1) {
        if (profileId === this.profile.id) {
          return
        }
        this.newInitiative.members.splice(memberAtIndex, 1)
      } else {
        this.newInitiative.members.push(profileId)
      }
      if (this.newInitiative.members.length === 0) {
        this.disableCreate = true
        return
      }
      this.disableCreate = false
    },
    setSearchedMember (value) {
      if (value) {
        this.setSelectedMember(value)
        this.$refs.memberSelect.clearSelection()
      }
    },
    closeCreateInitiative () {
      this.$emit('closeInit')
    },
    async addInitiative () {
      if (!this.disableCreateNew()) {
        this.addingInitiative = true
        this.helpText = null
        const initiative = { ...this.newInitiative }
        initiative.tag = `#${initiative.name.replace(' ', '-')}`
        initiative.key = `${this.profile.team}-${initiative.name.replace(' ', '-').toLowerCase()}`
        const self = this

        // Check for existing
        const isInitiativeUnique = fb.functions.httpsCallable('isInitiativeUnique')
        const params = { initiativeKey: initiative.key, workspaceId: this.workspace.id }
        console.log('InitiativeCards.isInitiativeUnique params', params)
        await isInitiativeUnique(params).then((res) => {
          console.log('isInitiativeUnique', res)
          if (res.data.result === 'unique') {
            this.saveInitiative({ teamId: this.profile.team, initiative }).then((doc) => {
              const initiativeId = doc.id
              // console.log('saveInitiative new - add default list', initiativeId)
              // add default taskList
              const taskList = {
                title: 'To Do',
                name: 'To Do',
                description: '',
                default: true,
                order: 0
              }
              self.saveTaskList({ teamId: self.profile.team, initiativeIds: [initiativeId], taskListId: false, taskList }).then((doc) => {
                this.saveInitiative({ initiativeId, initiative: { defaultTaskListId: doc.id } })
                setTimeout(() => {
                  self.addingInitiative = false
                  self.newInitiative = {
                    name: '',
                    description: '',
                    visibility: 'Workspace',
                    members: [],
                    due: '',
                    created: new Date()
                  }
                }, 700)
              })
            })
          } else {
            // reopen
            self.setSnackMessage('Initiative names need to be unique for a team')
            setTimeout(() => {
              self.addingInitiative = false
            }, 700)
          }
        })
        this.closeCreateInitiative()
      } else {
        this.helpText = 'Please fill fields to create an initiative'
      }
    }
  },
  watch: {
    'newInitiative.name': {
      handler () {
        const teamNames = this.teamInitiatives.map(initiative => initiative.name.toLowerCase())
        if (teamNames.includes(this.newInitiative.name.toLowerCase())) {
          this.helpText = 'Initiative name already in use by team, try something different'
          this.disableCreate = true
        } else {
          this.helpText = null
          this.disableCreate = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.create-init-wrapper {
  .selected-members-wrapper {
    margin: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 10px;
    .mdc-button {
      .you-text {
        font-size: 12px;
        color: #404040;
        font-weight: 600;
      }
    }
  }
  .action-buttons {
    margin: 0 50px 15px 10px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    .mdc-button {
      color: #4175B1;
      font-weight: 700;
      font-size: 14px;
      text-transform: none;
      &.disabled {
        color: #9CA4AE;
        pointer-events: none;
      }
      &.create-actionbtn {
        .loader {
          border: 2px solid #9CA4AE;
          animation: spin 1s linear infinite;
          border-top: 2px solid transparent;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          position: absolute;
          left: 100%;
        }
      }
    }
  }
  .help-text {
    color: #b10000;
    margin-left: 20px;
    margin-top: -15px;
  }
  .form-input {
    display: flex;
    flex-direction: column;
    .input-heading {
      color: #404040;
      font-weight: 600;
      margin-bottom: 5px;
      margin-left: 20px;
    }
    &.switch-wrapper {
      min-width: 150px;
      display: flex;
      margin: 10px 20px 0 20px;
      flex-direction: row;
      align-items: center;
      .switch-text {
        color: #4F4F4F;
      }
      .switch {
        margin-left: 10px;
        display: inline-block;
        height: 10px;
        position: relative;
        width: 40px;
        vertical-align: middle;
        margin-bottom: unset;
        input {
          display:none;
          &:checked {
            + .slider {
              background-color: #4F4F4F;
              &:before {
                transform: translateX(26px);
                background-image: url('/static/img/icons/flexibly/opened-eye.svg');
                background-position: center;
                background-repeat: no-repeat;
              }
            }
          }
        }
        .slider {
          background-color: #ccc;
          bottom: 0;
          cursor: pointer;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: .2s;
          &:before {
            background-color: #fff;
            bottom: -3px;
            content: "";
            height: 18px;
            position: absolute;
            transition: .2s;
            width: 18px;
          }
          &.round {
            border-radius: 18px;
            &:before {
              border-radius: 50%;
              background-image: url('/static/img/icons/flexibly/closed-eye.svg'), url('/static/img/icons/flexibly/closed-eye-part2.svg');
              background-repeat: no-repeat;
              background-position: center, 6.5px 6px;
              box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
    .input-wrapper {
      display:flex;

      #new-init-name,
      #new-init-desc {
        width: 320px;
        border: 0.5px solid #E0E0E0;
        border-radius: 2px;
        color: #404040;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 10px;
        margin: 0 20px 18px 20px;
        &::placeholder {
          color: #828282;
          font-size: 14px;
          font-weight: 400;
        }
      }
      #new-init-desc {
        resize: none;
        height: 70px;
      }
      .members-select {
        width: 342px;
        margin-left: 20px;
        border: 0.5px solid #E0E0E0;
        border-radius: 2px;
        .vs__dropdown-toggle {
          // border-radius: 20px;
          border: 2px solid transparent;
          .vs__search {
            padding: 0px 10px;
            font-size: 14px;
            color: #404040;
            font-weight: 600;
            &::placeholder {
              color: #828282;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .vs__actions {
            display: none;
          }
        }
        .vs__dropdown-menu {
          background: #FEFEFE;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);
          border-radius: 6px;
          padding: 0;
          transform: translateY(-100%);
          margin-top: -31px;
          li {
            color: #4175B1;
            &.vs__dropdown-option--highlight {
              background: #EBF3FC;
            }
          }
        }
      }
    }
  }
}
</style>
