<template>
  <div class="pinned-profile-wrapper" v-if="schedules">
    <div class="pin-profile-details">
      <header>
        <div class="profile-info">
          <base-svg-icon v-if="profileId" name="person-404040" class="member-icon" />
          <mdc-text v-if="profileDetails" class="profile-name" tag="span" typo="body1">{{profileDetails.displayName}}</mdc-text>
        </div>
        <base-svg-icon class="remove-profile-pin" name="remove_minus" clickable @click.native="removePinnedProfile(profileId)"/>
      </header>
      <section class="weekly-schedules">
        <div class="profile-content">
          <profile-initials :key="profileId + 'pinned-profiles'" :profileId="profileId" :date="appDate" showNameAndInitial :noProfileDetailClick="profile.id === profileId"/>
        </div>
        <div class="responses-days-grid" v-bind:class="{ 'animate': allSchedulesLoaded }">
          <div v-for="(profile,index) in allProfilesSchedulesWeekly" :key="profile.id + 'response' + index">
            <div v-for="(response, index) in profile.responses" :key="response.id + response.day + response.response + index" v-bind:class="[getBoxFillClass(response), isGridDayToday(response.dated) ? 'today' : '']">
              <template v-if="response.type === 'full'">
                <mdc-text class="not-set-text" tag="span" typo="caption" v-if="response.response === 'notset'">Not set</mdc-text>
                <base-svg-icon v-else :name="getResponseIcon(response.response)"/>
              </template>
              <template v-else-if="response.type === 'split'">
                <span class="answer split-day">
                  <div class="split-day-row">
                    <div class="am-wrapper slot-type" :class="getBoxFillClassResponse(response.amResponse, response.default)">
                      <template v-if="response.amResponse">
                        <base-svg-icon :name="response.amResponse" />
                      </template>
                    </div>
                    <div class="pm-wrapper slot-type" :class="getBoxFillClassResponse(response.pmResponse, response.default)">
                      <template v-if="response.pmResponse">
                        <base-svg-icon :name="response.pmResponse" />
                      </template>
                    </div>
                  </div>
                </span>
              </template>
              <template v-else>
                <mdc-text class="not-set-text" tag="span" typo="caption">Not set</mdc-text>
              </template>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseSvgIcon from './BaseSvgIcon.vue'
import { inFuture, inPast, isToday } from '../../utils/time'
import moment from 'moment'
import ProfileInitials from './ProfileInitials.vue'
import { getResponseIconName } from '../../utils/profile'
export default {
  name: 'PinnedProfileDetails',
  components: {
    BaseSvgIcon,
    ProfileInitials
  },
  props: {
    profileId: {
      type: String,
      default: ''
    },
    forDate: {
      type: Object,
      defailt: () => {
        return {}
      }
    }
  },
  data () {
    return {
      allSchedulesLoaded: false,
      allProfilesSchedulesWeekly: [],
      scheduleDays: [
        'MON',
        'TUE',
        'WED',
        'THU',
        'FRI'
      ],
      tableWeekDetails: [],
      profileDetails: false
    }
  },
  beforeDestroy () {
  },
  created () {
    this.initialize()
  },
  mounted () {
    this.calculateTableWeekDetails()
    if (this.schedules) {
      this.allProfilesSchedulesWeekly = []
      const framedProfileSchedule = this.frameProfileSchedule(this.profileId)
      this.allProfilesSchedulesWeekly.push({
        id: this.profileId,
        responses: [...framedProfileSchedule]
      })
    }
    this.allSchedulesLoaded = true
  },
  methods: {
    ...mapActions({
    }),
    ...mapActions([
      'updateProfile'
    ]),
    isSelectedDayPast () {
      return inPast(this.forDate)
    },
    isSelectedDayFuture () {
      return inFuture(this.forDate)
    },
    isToday () {
      return isToday(this.forDate)
    },
    isGridDayToday (date) {
      return isToday(date)
    },
    getBoxFillClass (resp) {
      if (resp.default) {
        return 'notset-fill'
      }
      if (resp.addedByConfirmation) {
        return 'notset-fill'
      }
      if (resp.type === 'split') {
        return 'split-fill'
      }
      const response = resp.response
      if (response && response !== null && response !== '' && response !== undefined) {
        switch (response.toLowerCase()) {
          case 'home':
            return 'home-fill'
          case 'thirdspace':
            return 'thirdspace-fill'
          case 'office':
            return 'office-fill'
          case 'nonworking':
            return 'nonworking-fill'
          case 'notset':
            return 'notset-fill'
          default:
            return ''
        }
      } else {
        return 'notset-fill'
      }
    },
    getBoxFillClassResponse (response, fromDefault) {
      if (fromDefault) {
        return 'notset-fill'
      }
      switch (response) {
        case 'home':
          return 'home-fill'
        case 'thirdspace':
          return 'thirdspace-fill'
        case 'office':
          return 'office-fill'
        case 'nonworking':
          return 'nonworking-fill'
        case 'notset':
          return 'notset-fill'
        default:
          return ''
      }
    },
    getResponseIcon (response) {
      return getResponseIconName(response)
    },
    calculateTableWeekDetails () {
      this.tableWeekDetails = []
      for (let i = 1; i <= 5; i++) {
        this.tableWeekDetails.push(moment(this.scheduleSearchWeekDetail.startOfWeek).startOf('day').add(i - 1, 'days').toDate())
      }
    },
    frameProfileSchedule (profileId) {
      let profileDefaultSchedule = []
      if (this.defaultSchedules) {
        profileDefaultSchedule = [...this.defaultSchedules].filter((ds) => { return ds.fromId === this.profileId })
      }
      const defaultSchedulesWeekly = profileDefaultSchedule[0] ? profileDefaultSchedule[0].schedules : {}
      const profileSchedulesWeekly = this.schedules || []
      if (profileSchedulesWeekly) {
        const profilesDatedResponse = []
        const myDatedSchedule = profileSchedulesWeekly.filter(schedule => {
          if (schedule.fromId === profileId) {
            return true
          }
          return false
        })
        // console.log('frameProfileSchedule', profileId, myDatedSchedule, defaultSchedulesWeekly)

        this.tableWeekDetails.map(date => {
          const day = moment(date).format('ddd').toUpperCase()
          const datedSchedule = myDatedSchedule[0] || {}
          const scheduleForDay = datedSchedule.schedules ? datedSchedule.schedules[day] : false
          const defaultSchedule = defaultSchedulesWeekly ? defaultSchedulesWeekly[day] || {} : {}
          profilesDatedResponse.push({
            response: scheduleForDay ? scheduleForDay.response : defaultSchedule.response || 'notset',
            amResponse: scheduleForDay ? scheduleForDay.amResponse : defaultSchedule.amResponse || 'notset',
            pmResponse: scheduleForDay ? scheduleForDay.pmResponse : defaultSchedule.pmResponse || 'notset',
            dated: date,
            id: profileId,
            default: scheduleForDay ? false : !!defaultSchedule.type,
            type: scheduleForDay ? scheduleForDay.type : defaultSchedule.type || 'full',
            day: moment(date).format('dddd')
          })
          return profilesDatedResponse
        })
        return profilesDatedResponse
      } else {
        return []
      }
    },
    removePinnedProfile () {
      if (this.profileId) {
        this.updateProfile({ profile: { pinnedProfiles: this.profile.pinnedProfiles.filter(profile => profile !== this.profileId) }, noSnack: true })
      }
    },
    async initialize () {
      // const allProfiles = [...this.profiles, this.profile]
      if (this.profiles) {
        this.profileDetails = [...this.profiles].filter(prof => prof.id === this.profileId)[0] || false
      }
    },
    ...mapActions({
    })
  },
  watch: {
    async schedules () {
      this.allProfilesSchedulesWeekly = []
      if (this.schedules) {
        const framedProfileSchedule = await this.frameProfileSchedule(this.profileId)
        this.allProfilesSchedulesWeekly.push({
          id: this.profileDetails.profileId,
          responses: [...framedProfileSchedule]
        })
        const self = this
        setTimeout(function () {
          self.allSchedulesLoaded = true // to paint the schedules after all the responses are loaded - check.
        }, 500)
      } else {
        self.allSchedulesLoaded = true
      }
    }
  },
  computed: {
    ...mapState([
      'profiles',
      'profile',
      'scheduleSearchWeekDetail',
      'appDate'
    ]),
    ...mapState({
      schedules: state => state.teams.schedules,
      defaultSchedules: state => state.teams.defaultSchedules
    })
  }
}
</script>

<style lang="scss">
.pinned-profile-wrapper {
  display: flex;
  min-height: 54px;
  margin-top: 10px;
  &.default-pinned {
    margin: 0;
  }
  &.future {
    .date-text {
      color: #404040;
    }
  }
  &.past {
    .date-text {
      color: #828282;
    }
  }
  &.today {
    .date-text {
      color: #b10000;
    }
  }
  .pin-profile-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    &:hover {
      background: #F4F9FF;
      .remove-profile-pin {
        display: flex;
      }
    }
    header {
      margin-left: 25px;
      margin-right: 25px;
      display: flex;
      margin-bottom: 5px;
      min-width: 150px;
      justify-content: space-between;
      margin-top: 7px;
      @media (max-width: 1195px) {
        min-width: 120px;
      }
      .profile-info {
        display: flex;
        align-items: center;
        .member-icon {
          height: 10px;
          width: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .profile-name {
          &::first-letter {
            text-transform: capitalize;
          }
          text-transform: lowercase;
          color: #404040;
          margin: 0 0px 0 5px;
          white-space: nowrap;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 600;
        }
      }
      .remove-profile-pin {
        z-index: 5;
        display: none;
      }
    }
    .weekly-schedules {
      display: grid;
      grid-template-columns: 135px auto;
      margin: 5px 25px 10px 25px;
      .profile-content {
        z-index: 4;
        padding: 0;
        .profile-redirect {
          height: 36px;
          max-height: 36px;
          background: #f8f8f8;
          margin: 0;
          padding-left: 15px;
          padding-right: 15px;
          width: calc(100% - 30px);
          .profile-firstname-initial {
            color: #4f4f4f !important;
            font-weight: 400;
            white-space: nowrap;
            max-width: 75px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .responses-days-grid {
        display: grid;
        grid-gap: 4px;
        position: relative;
        min-height: 36px;
        opacity: 0.5;
        > div {
        display: grid;
        margin-left:4px;
        margin-right:8px;
        grid-template-columns: repeat(5, minmax(auto, 1fr));
        height: 36px;
        grid-gap: 4px;
        background: #fcfcfc;
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            box-sizing: border-box;
            margin-left:1px;
            &.today {
              position:relative;
              background: #FFF9F9;
              box-shadow: 0 0 5px 0 #ffeded;
            }
            .icon-wrapper {
              height: 13px;
              width: 13px;
            }
            &.thirdspace-fill {
              background-color: var(--thirdspace-fill);
            }
            &.home-fill {
              background-color: var(--home-fill);
            }
            &.office-fill {
              background-color: var(--office-fill);
            }
            &.nonworking-fill {
              background: var(--nonworking-fill);
            }
            &.split-fill {
              width: 100%;
              height: 100%;
              background: white;
              .response-icons-wrapper {
                  width: 100%;
                  height: 100%;
              }
            }
            &.notset-fill {
              background-color: var(--notset-fill);
            }
            .not-set-text {
              color: #bdbdbd;
              text-align: center;
              font-style: italic;
              text-transform: uppercase;
              font-size: 10px;
              font-weight: 600;
            }
            .split-day {
              display: flex;
              flex-flow: row;
              align-items: baseline;
              width:100%;
              height:100%;
              .split-day-row {
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
                .am-wrapper {
                  flex:1;
                  border-radius: 4px 0 0 4px;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                  .icon-wrapper {
                      width: 100% !important;
                      height: 100% !important;
                      img {
                      width:15px !important;
                      height:15px !important;
                      }
                  }
                }
                .pm-wrapper {
                  flex:1;
                  border-radius: 0 4px 4px 0;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                  .icon-wrapper {
                      width: 100% !important;
                      height: 100% !important;
                      img {
                      width:15px !important;
                      height:15px !important;
                      }
                  }
                }
                .slot-text {
                  font-size: 8px;
                }
                .slot-type {
                  .icon-wrapper {
                      width: 20px;
                      height: 20px;
                      img {
                      width: 10px;
                      height: 10px;
                      }
                  }
                  &.thirdspace-fill {
                      background-color: var(--thirdspace-fill);
                  }
                  &.home-fill {
                      background-color: var(--home-fill);
                  }
                  &.office-fill {
                      background-color: var(--office-fill);
                  }
                  &.nonworking-fill {
                      background: var(--nonworking-fill);
                  }
                  &.notset-fill {
                      background-color: var(--notset-fill);
                  }
                }
              }
            }
          }
        }
        &.animate {
        opacity: 1;
        }
    }
  }
}
</style>
